import { useState, useEffect } from "react";
import { themes } from "src/global/themes";
import useApp from "./useAppContext";

const useThemeSwitcher = () => {

  const { isLoadingApp, app } = useApp();
  
  const [theme, setTheme] = useState<string>("");

  const findTheme = (name: string) => {
    return themes.find((theme) => theme.name === name);
  };

  useEffect(() => {
    if (!isLoadingApp) {
      let initialTheme = localStorage.getItem("theme") || app?.defaultTheme || "legacy";
      
      if (app?.hasThemes === false) {
        initialTheme = app?.defaultTheme || "legacy";
        localStorage.setItem("theme", initialTheme);
      }

      setTheme(initialTheme);
    }
  }, [isLoadingApp, app?.defaultTheme, app?.hasThemes]);

  useEffect(() => {
    if (theme) {
      document.body.dataset.theme = theme;
      const themeObject = findTheme(theme);
      if (themeObject) {
        const { tailwind }: any = themeObject;
        Object.keys(tailwind).forEach((key) => {
          document.documentElement.style.setProperty(`--${key}`, tailwind[key]);
        });
      }
      localStorage.setItem("theme", theme);
    }
  }, [theme]);

  return [theme, setTheme] as const;
};

export default useThemeSwitcher;
