import React, {
  Dispatch,
  PropsWithChildren,
  ReactChildren,
  SetStateAction,
} from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressCard,
  faEnvelope,
  faEnvelopesBulk,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ActiveSequenceTabs } from "src/pages/private/Sequences";
import CampaignToolbarButton from "./campaigns/CampaignToolbarButton";

interface ToolbarButtonProps {
  title: string;
  icon: IconProp;
  isActive: boolean;
  onClick: () => void;
  children?: ReactChildren;
}

const ToolbarButton = ({
  title,
  icon,
  isActive,
  onClick,
  children,
}: PropsWithChildren<ToolbarButtonProps>) => {
  return (
    <div
      className={
        isActive
        ? "btn join-item btn-sm  z-50 flex cursor-pointer select-none items-center justify-center gap-2 border-[1px] border-zinc-300 bg-secondary px-1.5 text-xxs text-text-light hover:bg-secondary hover:dark:bg-secondary hover:text-text-light dark:border-black sm:text-xs md:text-base w-1/4"
        : "btn join-item btn-sm  z-50 flex  w-1/4 cursor-pointer select-none items-center justify-center gap-2 border-[1px] border-zinc-300 bg-card-light px-1.5 text-xxs text-text-dark hover:bg-secondary hover:dark:bg-secondary hover:text-text-light dark:border-black dark:bg-card-dark sm:text-xs md:text-base dark:text-text-light"
      }
      onClick={onClick}
    >
      <div>
        <FontAwesomeIcon
          icon={icon}
          className="mb-[2px] text-xxs sm:text-xs md:text-base"
        />
      </div>
      <div className="text-xxs sm:text-xs md:text-base">{title}</div>
      {children}
    </div>
  );
};

interface SequencesToolbarProps {
  activeTabs: ActiveSequenceTabs;
  setActiveTabs: Dispatch<SetStateAction<ActiveSequenceTabs>>;
}

const SequencesToolbar = ({
  activeTabs,
  setActiveTabs,
}: SequencesToolbarProps) => {
  const setOnlyActiveTab = (tabKey: keyof ActiveSequenceTabs) => {
    setActiveTabs({
      sequences: false,
      postcards: false,
      letters: false,
      campaigns: false,
      [tabKey]: true,
    });
  };

  const navigate = useNavigate();

  const handleTabClick = (
    tabName: "sequences" | "postcards" | "letters" | "campaigns"
  ) => {
    setOnlyActiveTab(tabName);
    if (tabName === "sequences") {
      navigate("/sequences");
    } else {
      navigate(`/sequences/${tabName}`);
    }
  };

  return (
    <>
      <div className=" left-0 z-50 bg-back-light dark:bg-back-dark">
        <div className="join z-50 flex h-[32px] w-full cursor-pointer items-center justify-center md:h-[40px]">
          <ToolbarButton
            title={"Sequences"}
            icon={faEnvelope}
            isActive={activeTabs.sequences}
            onClick={() => handleTabClick("sequences")}
          />
          <ToolbarButton
            title={"Postcards"}
            icon={faAddressCard}
            isActive={activeTabs.postcards}
            onClick={() => handleTabClick("postcards")}
          />
          <ToolbarButton
            title={"Letters"}
            icon={faEnvelopesBulk}
            isActive={activeTabs.letters}
            onClick={() => handleTabClick("letters")}
          />
          <CampaignToolbarButton
            onClick={() => handleTabClick("campaigns")}
            isActive={activeTabs.campaigns}
          />
        </div>
      </div>
    </>
  );
};

export default SequencesToolbar;
