const getDateStr = (dayIncrement: number, numDays: number): string => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let date = new Date();
  date.setDate(date.getDate() + dayIncrement * numDays);
  return `${monthNames[date.getMonth()]} ${date.getDate()}`;
};

export default getDateStr;