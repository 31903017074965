import React, { PropsWithChildren } from "react";
import "./Spinner.css";
import useSpinner from "src/hooks/useSpinner";

interface SpinnerProps {
  spin?: boolean;
}

const Spinner = ({ children, spin }: PropsWithChildren<SpinnerProps>) => {
  const { isSpinning } = useSpinner();

  return (
    <>
      {(isSpinning) ? (
        <>
          <div className="absolute top-0 left-0 bottom-0 right-0 z-[60]">
            <div
              id="background-transition"
              className="flex w-full h-full items-center 
        justify-center"
            >
              <div
                style={{
                  paddingTop: "100%",
                }}
                id="rings"
                className="relative  z-[70]"
              >
                <div className="spin" id="loader"></div>
                <div className="spin" id="loader2"></div>
                <div className="spin" id="loader3"></div>
                <div className="spin" id="loader4"></div>
              </div>
            </div>
          </div>
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default Spinner;
