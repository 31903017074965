import { ReactNode } from "react";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import SwiperSlideCanvas from "./SwiperSlideCanvas";
import SwiperCore, { Keyboard } from "swiper";
import useViewport from "src/hooks/useViewport";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Slide {
  id: string;
  title: string;
  content: ReactNode;
  icon?: IconProp;
}

interface SwiperTableProps extends SwiperProps {
  slides: Slide[];
}

const SwiperTable = ({ slides, ...props }: SwiperTableProps) => {
  const { isMobile } = useViewport();

  SwiperCore.use([Keyboard]);

  return (
    <>
      <Swiper
        spaceBetween={30}
        allowTouchMove={isMobile ? true : false}
        speed={700}
        keyboard={true}
        modules={[Keyboard]}
        {...props}
      >
        {slides.map((slide: Slide) => {
          return (
            <SwiperSlide className="swiper-slide" key={slide.id}>
              <SwiperSlideCanvas
                slides={slides}
                title={slide.title}
                content={slide.content}
                icon={slide.icon}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default SwiperTable;
