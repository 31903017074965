import { useState, useEffect } from "react";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import AnimatedNumber from "react-awesome-animated-number";
import "react-awesome-animated-number/dist/index.css";
import FuseCoinsHelp from "src/components/help/FuseCoinsHelp";
import PurchaseCoinsModal from "src/components/modals/PurchaseCoinsModal";
import useTeamContext from "src/hooks/private/useTeamContext";
import FuseCoin from "src/components/icons/FuseCoin";
import useApp from "src/hooks/useAppContext";
import VacationModal from "src/components/modals/VacationModal";
import VacationModeHelp from "src/components/help/VacationModeHelp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faCoins,
  faScaleUnbalanced,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import UsageModal from "src/components/modals/UsageModal";
import UsageHelp from "src/components/help/UsageHelp";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

interface CoinBalanceProps {
  isDisabled?: boolean;
}

const CoinBalance = ({ isDisabled = false }: CoinBalanceProps) => {
  const { team, teammates } = useTeamContext();

  const { app } = useApp();

  const { width } = useViewport();

  const { openModalWith } = useModal();

  const { subscription } = useCurrentSubscriptionContext();

  const navigate = useNavigate();

  let dropDownProps = { tabIndex: 0 };

  const [coinBalance, setCoinBalance] = useState<number>(
    team ? team?.coinsPurchased + team?.coinsSubscription + 50 : 0
  );

  const handleAddCoins = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    if (team?.isVacation) {
      openModalWith({
        title: "Turn Off Vacation Mode",
        hideButtons: true,
        body: <VacationModal />,
        helpTitle: "Vacation Mode",
        helpBody: <VacationModeHelp />,
      });
    } else {
      openModalWith({
        title: `Add ${app?.coinName}`,
        hideButtons: true,
        body: <PurchaseCoinsModal />,
        helpTitle: "Purchasing Coins",
        helpBody: <FuseCoinsHelp />,
      });
    }
  };

  const handleCoinHistory = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    navigate("/coinhistory");
  };

  const handleUsageClick = () => {
    if (team && subscription) {
      openModalWith({
        title: "Usage",
        hideButtons: true,
        body: (
          <UsageModal
            numLeads={team.numLeads}
            subscription={subscription}
            teamSize={teammates.length + 1}
          />
        ),
        helpTitle: "Usage",
        helpBody: <UsageHelp />,
      });
    }
  };

  useEffect(() => {
    if (team) {
      setCoinBalance(team?.coinsPurchased + team?.coinsSubscription);
    }
  }, [team]);

  return (
    <>
      <div
        className={
          width < 1024
            ? "dropdown dropdown-hover my-0 cursor-pointer py-0 shadow-2xl"
            : "dropdown dropdown-end dropdown-hover my-0 cursor-pointer py-0"
        }
      >
        <label
          {...dropDownProps}
          className="mt-0.5 flex h-8 w-auto min-w-[80px] cursor-pointer select-none items-center justify-center rounded-lg border-[1px] border-primary bg-card-light px-1 dark:bg-card-dark "
        >
          <FuseCoin />
          <span className="select-none pl-1 text-sm font-extrabold text-text-dark dark:text-text-light">
            <AnimatedNumber
              className="mr-0.5 lg:mr-1"
              value={coinBalance}
              size={14}
              duration={200}
              hasComma={coinBalance > 9999 ? true : false}
            />
          </span>
        </label>
        <ul
          {...dropDownProps}
          className="menu dropdown-content mt-0.5 w-52 rounded-box  bg-card-light p-2 text-primary shadow-2xl drop-shadow-xl dark:bg-card-dark"
        >
          {!team?.isDemo && (
            <li className="">
              <button
                onClick={handleAddCoins}
                className="btn btn-ghost flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
              >
                <FontAwesomeIcon icon={faCartShopping} className="w-4" />
                <span>Add {app?.coinName}</span>
              </button>
            </li>
          )}
          {!isDisabled && (
            <li className="">
              <button
                className="btn btn-ghost flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
                onClick={handleCoinHistory}
              >
                <FontAwesomeIcon icon={faCoins} className="w-4" />
                <span>Transactions</span>
              </button>
            </li>
          )}
          {app?.hasLeadLimits && (
            <li className="">
              <button
                className="btn btn-ghost flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
                onClick={handleUsageClick}
              >
                <FontAwesomeIcon icon={faScaleUnbalanced} className="w-4" />
                <span>Usage</span>
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default CoinBalance;
