import React from "react";
import ProofOfFundsLetterHelp from "src/components/help/ProofOfFundsLetterHelp";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useModal from "src/hooks/useModal";

interface WhatsNew3Props {
  props?: any;
}

const WhatsNew3 = ({ props }: WhatsNew3Props) => {
  const { openHelpDrawerWith } = useHelpDrawer();

  const { setShowModal } = useModal();

  const handleHelp = () => {
    setShowModal(false);
    openHelpDrawerWith({
      title: "Proof Of Funds Letters",
      body: <ProofOfFundsLetterHelp />,
    });
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div className="px-4">
          <ul className="text-left">
            <li>
              Are you a Broker? You can now create{" "}
              <span
                onClick={handleHelp}
                className="cursor-pointer font-bold text-secondary underline"
              >
                Proof Of Funds Letters
              </span>{" "}
              for any of your clients for any property with your own company
              branding.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhatsNew3;
