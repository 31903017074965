/* eslint-disable import/no-anonymous-default-export */
import { NotificationParams, NotificationResponse, ReadNotification } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class NotificationAPI extends ApiTokenSupport {
  /**
   * @getUserNotifications function use for get all notifications array object.
   * @data parameter is object which is dynamic.
   * @return array object all notifications list.
   */
  async getUserNotifications(data?: NotificationParams): Promise<NotificationResponse> {
    const resp = await axios.post<NotificationResponse>(
      `/api/notification/list`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getUserNotificationCount(): Promise<number> {
    const resp = await axios.get<number>(
      `api/notification/get-user-count`,
      this.withAuthorization()
    );
    return resp.data;
  }
  
  async resetUserNotificationCount(): Promise<void> {
    const resp = await axios.post(
      `api/notification/reset-user-count`,
      null,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * @readNotification function use for set notifications read by user
   * @notificationId is the notification unique id. When user read all notifications that time pass null.
   * @isreadAll is boolean value which indicates user read all notifications that time true pass otherwise false.
   */
  async readNotification(
    notificationId: number | null,
    isreadAll?: boolean
  ): Promise<ReadNotification> {
    let data = {
      notificationID: notificationId,
      makeAllRead: isreadAll,
    };
    const resp = await axios.post<ReadNotification>(
      `/api/notification/update-to-read`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new NotificationAPI();
