import React from "react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import parseAddress, { trimCountry } from "src/helpers/parseAddress";
import useViewport from "src/hooks/useViewport";
import { faMap } from "@fortawesome/pro-regular-svg-icons";
import CircularActionButton from "./CircularActionButton";
import classnames from "classnames";

interface ResarchAddressButtonsProps {
  address: string;
  placeID?: string;
  isSmall?: boolean;
  className?: string;
}

const ResearchAddressButton = ({
  address,
  placeID,
  isSmall = false,
  className,
}: ResarchAddressButtonsProps) => {
  const { isMobile, width } = useViewport();

  const handleCopyClick = (str: string) => {
    return navigator.clipboard.writeText(str);
  };

  const trimLongAddress = (str: string) => {
    return str.split("").slice(0, 38);
  };

  const defaultClassNames = isSmall
    ? " cursor-default text-xxs font-semibold font-medium normal-case text-text-dark dark:text-text-light sm:text-xs"
    : "cursor-default  text-xs font-medium normal-case text-text-dark dark:text-text-light  xs:text-sm";

  const classNames = classnames(defaultClassNames, className);

  return (
    <div className="flex flex-row items-center gap-1 ">
      <div className={classNames}>
        {isMobile && parseAddress(trimCountry(address)).length > 40
          ? trimLongAddress(parseAddress(trimCountry(address)))
          : address.length > 40
          ? parseAddress(trimCountry(address))
          : trimCountry(address)}
      </div>

      {width > 440 && (
        <div className="ml-1">
          <CircularActionButton
            icon={faCopy}
            onClick={() => handleCopyClick(address)}
            isSmall={isMobile}
          />
          {placeID && (
            <a
              target="_blank"
              rel="noreferrer"
              className="ml-1"
              href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeID}`}
            >
              <CircularActionButton icon={faMap} isSmall={isMobile} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default ResearchAddressButton;
