import { useCallback } from "react";
import useAlert from "../useAlert";

const useErrorHandler = () => {
  const { setAlert } = useAlert();

  const handleError = useCallback(
    (err: any) => {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    },
    [setAlert]
  );

  return { handleError };
};

export default useErrorHandler;
