import React, { useState } from "react";
import Property, {
  RealeflowRawProperty,
  RealeflowRawPropertyData,
} from "src/interfaces/property";
import ButtonBlock from "./ButtonBlock";
import {
  faCircleCheck,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { Location } from "src/hooks/private/useCurrentLocation";
import calculateDistanceFeet from "src/helpers/calculateDistanceFeet";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useUserContext from "src/hooks/private/useUserContext";
import useModal from "src/hooks/useModal";
import PurchaseCoinsModal from "./PurchaseCoinsModal";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";
import { capitalize, shortenString } from "src/helpers/parseStrings";
import { AnimatePresence, motion } from "framer-motion";
import delay from "src/helpers/delay";
import LoadingSpinner from "../loading/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { propertyApi } from "src/api";
import useViewport from "src/hooks/useViewport";
import usePropertiesContext from "src/hooks/private/usePropertiesContext";
import { PropertyDrawerOptionsProps } from "src/contexts/private/DrawerContext";
import useAlert from "src/hooks/useAlert";

const createFullAddress = (
  data: RealeflowRawPropertyData,
  shortenString: boolean
): string => {
  const addressComponents = [
    data.address_number,
    data.address_direction_pre,
    capitalize(data.address_street.toLowerCase()),
    capitalize(data.address_suffix.toLowerCase()),
    data.address_unit_type
      ? `${data.address_unit_type} ${data.address_unit}`
      : "",
    !shortenString && data.address_city,
    !shortenString && data.address_zip,
  ]
    .filter((component) => component && component.trim() !== "")
    .join(" ");

  return addressComponents;
};

interface PropertyNearMeRowProps {
  property: RealeflowRawProperty;
  location: Location;
  onToggleSelection: () => void;
  isSelected: boolean;
  isResearching: boolean;
  status: "waiting" | "sending" | "complete";
}

const PropertyNearMeRow = ({
  property,
  location,
  onToggleSelection,
  isSelected,
  isResearching,
  status = "waiting",
}: PropertyNearMeRowProps) => {
  const { isResearched } = property;
  const { isMobile } = useViewport();
  return (
    <motion.div
      className="my-1 flex cursor-pointer flex-row items-center gap-3 rounded-lg bg-card-light px-4 py-1 dark:bg-back-dark"
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={isResearched ? () => {} : onToggleSelection}
    >
      <div className="mb-1 mt-2 flex items-center justify-center">
        <div className="flex h-[29px] w-[29px] items-center justify-center ">
          {isResearched ? (
            <div>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="mt-0.5 text-xl text-success"
              />
            </div>
          ) : status === "waiting" ? (
            <div className="">
              <input
                type="checkbox"
                className="checkbox-secondary checkbox mt-1 disabled:dark:border-none"
                checked={isSelected}
                disabled={isResearching}
                onChange={() => {}}
              />
            </div>
          ) : status === "sending" ? (
            <div>
              <LoadingSpinner className={"spin-fast text-secondary"} />
            </div>
          ) : (
            <div>
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="mt-0.5 text-xl text-success"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex w-12 select-none gap-0.5">
        <span className="text-sm font-medium text-text-dark dark:text-text-light sm:text-base">
          {calculateDistanceFeet(
            property.data.location.lat,
            property.data.location.lon,
            location.latitude,
            location.longitude
          )}
        </span>
        <span className="text-sm text-zinc-500 dark:text-icon-dark sm:text-base">
          ft
        </span>
      </div>
      <div>
        <button className="cursor-default text-sm sm:text-base">
          <span className="font-medium">
            {shortenString(
              createFullAddress(property.data, true),
              isMobile ? 25 : 100
            )}
          </span>
        </button>
      </div>
    </motion.div>
  );
};

interface PropertiesNearMeModalProps {
  properties: RealeflowRawProperty[];
  location: Location;
  openPropertyDrawerWith: (o: PropertyDrawerOptionsProps) => void;
}

const PropertiesNearMeModal = ({
  properties,
  location,
  openPropertyDrawerWith,
}: PropertiesNearMeModalProps) => {
  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const { checkCoinBalance } = useTeamContext();

  const { fetchProperties } = usePropertiesContext();

  const { setAlert } = useAlert();

  const { user } = useUserContext();

  const { app } = useApp();

  const { openModalWith, setShowModal } = useModal();

  const [isResearching, setIsResearching] = useState<boolean>(false);

  const [filteredProperties, setFilteredProperties] =
    useState<RealeflowRawProperty[]>(properties);

  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);

  const [propertyStatuses, setPropertyStatuses] = useState<{
    [key: string]: "waiting" | "sending" | "complete";
  }>({});

  const initializePropertyStatuses = () => {
    const initialStatuses = selectedProperties.reduce((acc, hash) => {
      acc[hash] = "waiting";
      return acc;
    }, {} as { [key: string]: "waiting" | "sending" | "complete" });

    setPropertyStatuses(initialStatuses);
  };

  const updatePropertyStatus = (
    hash: string,
    status: "waiting" | "sending" | "complete"
  ) => {
    setPropertyStatuses((prevStatuses) => ({
      ...prevStatuses,
      [hash]: status,
    }));
  };

  const handleResearchSubmit = async () => {
    const selectedPropertiesData = properties.filter((property) =>
      selectedProperties.includes(property.data.address_hash)
    );
    setFilteredProperties(selectedPropertiesData);

    await delay(700);

    setIsResearching(true);

    await delay(700);

    initializePropertyStatuses();

    let res;

    for (const hash of selectedProperties) {
      const property = properties.find((p) => p.data.address_hash === hash);
      if (!property) continue;

      updatePropertyStatus(hash, "sending");

      const fullAddress = createFullAddress(property.data, false);

      await delay(700);

      try {
        res = await propertyApi.researchNear({
          hash,
          fullAddress,
        });
      } catch (err: any) {
        console.error(err);
        break;
      }

      updatePropertyStatus(hash, "complete");
      await delay(300);
    }
    setIsResearching(false);
    setShowModal(false);
    fetchProperties();
    if (selectedProperties.length === 1) {
      console.log("Opening drawer");
      return openPropertyDrawerWith({
        property: { id: res?.id } as Property,
      });
    } else {
      setAlert({
        display: true,
        type: "success",
        message: `${selectedProperties.length} properties were added.`,
      });
      return;
    }
  };

  const togglePropertySelection = (hash: string) => {
    setSelectedProperties((prevSelected: string[]) =>
      prevSelected.includes(hash)
        ? prevSelected.filter((h) => h !== hash)
        : [...prevSelected, hash]
    );
  };

  const handleAddCoins = () => {
    openModalWith({
      title: "Add FuseCoins",
      body: <PurchaseCoinsModal />,
      hideButtons: true,
    });
  };

  function findTeamLeaderEmail() {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <AnimatePresence>
        <div className="flex columns-1 flex-col items-center justify-center">
          <div className="mb-4">
            {filteredProperties.map((property: RealeflowRawProperty) => (
              <PropertyNearMeRow
                property={property}
                key={`nearMe-${property.data.address_hash}`}
                location={location}
                onToggleSelection={() =>
                  togglePropertySelection(property.data.address_hash)
                }
                isSelected={selectedProperties.includes(
                  property.data.address_hash
                )}
                isResearching={isResearching}
                status={propertyStatuses[property.data.address_hash]}
              />
            ))}
          </div>

          {checkCoinBalance(
            (subscriptionFeatures?.["Research"].coinCost || 1) *
              selectedProperties.length
          ) ? (
            <ButtonBlock
              submitIcon={faMagnifyingGlass}
              submitLabel="Research"
              handleSubmit={handleResearchSubmit}
              disabled={selectedProperties.length < 1}
              submitting={isResearching}
            />
          ) : user?.canPurchase || user?.isLeader ? (
            <div className=" flex items-center justify-center">
              <button
                className="btn btn-secondary ml-1 w-48 border-none text-center text-lg font-bold text-text-light hover:bg-primary hover:drop-shadow-lg dark:text-text-dark"
                onClick={handleAddCoins}
              >
                Add {app?.coinName}
              </button>
            </div>
          ) : (
            <>
              <p className="mt-4 text-center text-text-dark dark:text-text-light">
                Please{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${findTeamLeaderEmail()}?subject=Purchase Coins for ${
                    app?.name || getHostName()
                  } account`}
                  className="text-secondary hover:underline"
                  onClick={() => setShowModal(false)}
                >
                  contact
                </a>{" "}
                your team leader to purchase more {app?.name || getHostName()}.
              </p>
            </>
          )}
        </div>
      </AnimatePresence>
    </>
  );
};

export default PropertiesNearMeModal;
