import {
  faDollarSign,
  faEnvelope,
  faFileInvoiceDollar,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Property from "src/interfaces/property";
import ActionButton from "src/components/buttons/ActionButtons";
import CogoCapitalButton from "src/components/buttons/CogoCapitalButton";
import useManageModals from "src/hooks/private/useManageModal";
import detectInternationalOwner from "src/helpers/detectInternationOwner";

interface ActionPanelProps {
  property: Property;
  isFullWidth: boolean;
}

const ActionPanel = ({ property, isFullWidth }: ActionPanelProps) => {

  const { handleAction } = useManageModals();

  return (
    <>
      <div className="h-full w-full rounded-lg ">
        <div
          className={
            isFullWidth
              ? "flex w-full items-center justify-between gap-0.5"
              : "grid grid-cols-2 grid-rows-3 gap-0.5"
          }
        >
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faFileInvoiceDollar}
              label={"Debt Stack"}
              onClick={() => handleAction("debtstack", property)}
              className={
                "btn-sm h-12 w-full  border-none bg-theme5 font-bold text-text-light hover:bg-theme5 dark:text-text-dark "
              }
            />
          </div>
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faPhone}
              label={"Skip Trace"}
              onClick={() => handleAction("skiptrace", property)}
              className={
                "btn-sm h-12 w-full border-none bg-theme4 font-bold text-text-light hover:bg-theme4 dark:text-text-dark "
              }
            />
          </div>
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faEnvelope}
              label={"Sequence"}
              onClick={() => handleAction("sequence", property)}
              className={
                "btn-sm h-12 w-full border-none  bg-theme2 font-bold text-text-light hover:bg-theme2 dark:text-text-dark "
              }
              disabled={detectInternationalOwner(property)}
            />
          </div>
          <div className={isFullWidth ? "w-1/5" : "w-full"}>
            <ActionButton
              icon={faDollarSign}
              label={"Offer"}
              onClick={() => handleAction("offer", property)}
              className={
                "btn-sm h-12 w-full border-none bg-theme3 font-bold text-text-light hover:bg-theme3 dark:text-text-dark"
              }
              disabled={detectInternationalOwner(property)}
            />
          </div>
          <CogoCapitalButton
            className={isFullWidth ? "w-1/5" : "col-span-2 w-full"}
          />
        </div>
      </div>
    </>
  );
};

export default ActionPanel;
