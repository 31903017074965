import { useCallback, useEffect, useRef, useState } from "react";
import useModal from "src/hooks/useModal";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAuth from "src/hooks/useAuth";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import useDrawer from "src/hooks/private/useDrawer";
import { useNavigate } from "react-router-dom";

// Modal components and helpers
import InsufficientFundsModal from "src/components/modals/InsufficientFundsModal";
import UpgradeModal from "src/components/modals/UpgradeModal";
import DebtStackModal from "src/components/modals/DebtStackModal";
import SkipTraceModal from "src/components/modals/SkipTraceModal";
import SequenceModal from "src/components/modals/SequenceModal";
import OfferModal from "src/components/modals/OfferModal";
import SkipTraceResults from "src/components/modals/SkipTraceResultsModal";

// Help components
import DebtStacksHelp from "src/components/help/DebtStacksHelp";
import FuseCoinsHelp from "src/components/help/FuseCoinsHelp";
import SkipTracesHelp from "src/components/help/SkipTracesHelp";
import SequencesHelp from "src/components/help/SequencesHelp";
import OffersHelp from "src/components/help/OffersHelp";
import SubscriptionsHelp from "src/components/help/SubscriptionsHelp";
import Property from "src/interfaces/property";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import { Campaign } from "src/interfaces/campaign";
import {
  faFileInvoiceDollar,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import detectInternationalOwner from "src/helpers/detectInternationOwner";
import useApp from "../useAppContext";

const useManageModals = () => {

  const { openModalWith } = useModal();

  const { team, checkCoinBalance, isLoadingTeam } = useTeamContext();

  const { currentUser } = useAuth();

  const { subscriptionFeatures, isLoadingSubscriptionFeatures } =
    useCurrentSubscriptionContext();

  const { campaigns } = useCampaignContext();

  const navigate = useNavigate();

  const { setShowPropertyDrawer } = useDrawer();

  const { app } = useApp();

  const [queuedAction, setQueuedAction] = useState<(() => void) | null>(null);

  const isAbsenteeCampaign = campaigns.filter(
    (campaign: Campaign) => campaign.target === "absentee"
  ).length;

  const isOccupiedCampaign = campaigns.filter(
    (campaign: Campaign) => campaign.target === "occupied"
  ).length;

  const subscriptionFeaturesRef = useRef(subscriptionFeatures);
  const teamRef = useRef(team);

  useEffect(() => {
    subscriptionFeaturesRef.current = subscriptionFeatures;
    teamRef.current = team;
  }, [subscriptionFeatures, team]);

  useEffect(() => {
    // Check if the subscription features have loaded and there is an action queued
    if (!isLoadingSubscriptionFeatures && !isLoadingTeam && queuedAction) {
      queuedAction(); // Execute the queued action
      setQueuedAction(null); // Reset the queue
    }
  }, [isLoadingSubscriptionFeatures, queuedAction, isLoadingTeam]);

  const openModalBasedOnFeature = useCallback(
    (featureName: string, modalProps: ModalOptionsProps) => {
      const attemptOpenModal = () => {
        const currentSubscriptionFeatures = subscriptionFeaturesRef.current;
        const currentTeam = teamRef.current;
        if (!currentSubscriptionFeatures || !currentTeam) {
          return;
        }
        const feature = currentSubscriptionFeatures?.[featureName];
        if (!feature || feature.accessType === "none") {
          openModalWith({
            title: "Upgrade Subscription",
            body: (
              <UpgradeModal
                hideExplanation={true}
                featureClicked={featureName}
              />
            ),
            hideButtons: true,
            helpTitle: "Subscriptions",
            helpBody: <SubscriptionsHelp />,
          });
        } else if (!checkCoinBalance(feature.coinCost, teamRef.current)) {
          openModalWith({
            title: `Insufficient ${app?.coinName}`,
            body: <InsufficientFundsModal />,
            hideButtons: true,
            helpTitle: `${app?.coinName}`,
            helpBody: <FuseCoinsHelp />,
          });
        } else {
          openModalWith(modalProps);
        }
      };

      if (isLoadingSubscriptionFeatures || isLoadingTeam) {
        // If subscription features are still loading, queue the modal opening attempt
        setQueuedAction(() => attemptOpenModal);
      } else {
        // If subscription features are already loaded, attempt to open the modal immediately
        attemptOpenModal();
      }
    },
    [
      checkCoinBalance,
      currentUser?.coinName,
      isLoadingSubscriptionFeatures,
      isLoadingTeam,
      openModalWith,
    ]
  );

  const handleAction = useCallback(
    (actionType: string, property: Property) => {
      // Define modal opening logic based on the actionType and property
      // Similar to the logic you previously had in the ActionPanel component

      switch (actionType) {
        case "debtstack":
          openModalBasedOnFeature("DebtStack", {
            title: "Order Debt Stack",
            property,
            body: <DebtStackModal property={property} />,
            hideButtons: true,
            helpTitle: "Debt Stacks",
            helpBody: <DebtStacksHelp />,
          });
          break;

        case "skiptrace":
          property.isSkipTrace
            ? openModalBasedOnFeature("SkipTrace", {
                title: "Skip Trace Results",
                property,
                body: <SkipTraceResults property={property} />,
                hideButtons: true,
                helpTitle: "Skip Traces",
                helpBody: <SkipTracesHelp />,
              })
            : openModalBasedOnFeature("SkipTrace", {
                title: "Request Skip Trace",
                property,
                body: <SkipTraceModal property={property} />,
                hideButtons: true,
                helpTitle: "Skip Traces",
                helpBody: <SkipTracesHelp />,
              });
          break;

        case "sequence":
          if (detectInternationalOwner(property)) {
            openModalWith({
              title: "Unable to Sequence",
              hideButtons: true,
              body: "This property is owned by an international based owner and is unable to be sequenced.",
              helpTitle: "Sequences",
              helpBody: <SequencesHelp />,
            });
          } else if (
            (property.ownerOccupied && isOccupiedCampaign) ||
            (!property.ownerOccupied && isAbsenteeCampaign)
          ) {
            openModalBasedOnFeature("Sequence", {
              title: "Start Sequence",
              property,
              hideButtons: true,
              body: <SequenceModal property={property} />,
              helpTitle: "Sequences",
              helpBody: <SequencesHelp />,
            });
          } else {
            navigate(`/sequences/new/${property.id}`);
            return setShowPropertyDrawer(false);
          }
          break;

        case "offer":
          openModalBasedOnFeature("Offer", {
            title: "Create An Offer",
            property,
            hideButtons: true,
            body: <OfferModal property={property} />,
            helpTitle: "Offers",
            helpBody: <OffersHelp />,
          });
          break;
      }
    },
    [
      isAbsenteeCampaign,
      isOccupiedCampaign,
      navigate,
      openModalBasedOnFeature,
      setShowPropertyDrawer,
    ]
  );

  return { handleAction };
};

export default useManageModals;
