/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useTokenGuard from "../hooks/useTokenGuard";

const DemoInvitationGuard = () => {
    const { token, email, onChecking, redirection } = useTokenGuard("invite-to-demo");
    const { setIsDemo, logout } = useAuth();

    useEffect(() => {
        if(token && email) {
            setIsDemo(true);
        }
    }, [token, email, setIsDemo]);
    
    useEffect(() => {
        logout();
    }, [])

    if (onChecking) {
        return <div className='w-full justify-center flex'><span className='loading'>...</span></div>;
    }

    if (redirection && token) {
        return <Navigate to={redirection} />
    }

    return (<Outlet />)
}

export default DemoInvitationGuard;