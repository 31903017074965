import React, { useContext, ComponentType, Component as ReactComponent } from 'react';
import { StripeContext } from 'src/contexts/StripeContext';
import { StripeProvider } from "src/contexts/StripeContext";

const useStripeOptions = () => useContext(StripeContext);

export const withStripeProvider = <P extends object>(Component: ComponentType<P>) =>
  class WithStripe extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <StripeProvider>
          <Component {...(props as P)} />
        </StripeProvider>
      );
    }
};

export default useStripeOptions;
