import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import CartItem from "./CartItem";
import { AnimatePresence } from "framer-motion";

interface CartDrawerProps {
  props?: any;
}

const CartDrawer = ({ props }: CartDrawerProps) => {
  const cartState = useSelector((state: RootState) => state.cart);

  const cartLength =
    cartState.debtStacks.length +
    cartState.offers.length +
    cartState.proofOfFunds.length;

  return (
    <>
      <div className="px-1">
        {cartLength === 0 ? (
          <div>
            <div className="mb-1.5 flex items-center justify-center rounded-lg bg-card-light p-4 text-text-neutral-light shadow-sm dark:bg-card-dark dark:text-text-neutral-dark">
              Your cart is currently empty.
            </div>
          </div>
        ) : (
          <AnimatePresence initial={false}>
            {cartState.debtStacks.map((item, index) => (
              <CartItem key={`debtStack-${item.id}-${index}`} item={item} />
            ))}
            {cartState.offers.map((item, index) => (
              <CartItem key={`offer-${item.id}-${index}`} item={item} />
            ))}
            {cartState.proofOfFunds.map((item, index) => (
              <CartItem key={`proofOfFunds-${item.id}-${index}`} item={item} />
            ))}
          </AnimatePresence>
        )}
      </div>
    </>
  );
};

export default CartDrawer;
