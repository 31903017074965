import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { callsEmailsApi } from "src/api";
import { CallsEmails } from "src/interfaces/callsEmails";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface CallsEmailsContextInterface {
  callsEmails: CallsEmails[];
  fetchCallsEmails: () => void;
}

const callsEmailsContextDefaults: CallsEmailsContextInterface = {
  callsEmails: [],
  fetchCallsEmails: () => {},
};

const CallsEmailsContext = React.createContext<CallsEmailsContextInterface>(
  callsEmailsContextDefaults
);

interface CallsEmailsProviderProps {}

export const CallsEmailsProvider = ({
  children,
}: PropsWithChildren<CallsEmailsProviderProps>) => {
  const [callsEmails, setCallsEmailss] = useState<CallsEmails[]>([]);

  const { handleError } = useErrorHandler();

  const fetchCallsEmails = useCallback(async () => {
    try {
      const callsEmails = await callsEmailsApi.getCallsEmails();
      setCallsEmailss(callsEmails);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchCallsEmails();
  }, [fetchCallsEmails]);

  return (
    <CallsEmailsContext.Provider
      value={{
        callsEmails,
        fetchCallsEmails,
      }}
    >
      {children}
    </CallsEmailsContext.Provider>
  );
};

export default CallsEmailsContext;
