import { ReactNode } from "react";
import { Outlet } from 'react-router-dom';

interface PublicLayoutProps {
  children?: ReactNode;
}

const PublicLayout = ( { children } : PublicLayoutProps ) => {
  return (
      <div className='min-w-full min-h-full'>
        {children || <Outlet />}
      </div>      
  )

}

export default PublicLayout;

