import React from "react";

interface MarketOfferHelpProps {
  props?: any;
}

const MarketOfferHelp = ({ props }: MarketOfferHelpProps) => {
  return (
    <div>
      <h1 className="text-xl font-bold">Offer Types</h1>
      <p>
        In SendFuse Market, there are two main types of offers that you can
        present when acquiring a property: All Cash Offer and the Three-Tiered
        Offer. Each type of offer has its own unique strategy and benefits,
        allowing you to tailor your approach based on the seller’s needs and
        your investment strategy.
      </p>
      <br></br>
      <h2 className="text-lg font-semibold">All Cash Offer</h2>
      <p>
        The All Cash Offer is straightforward and appealing to sellers who
        prefer a quick and clean sale. In this scenario, you offer the seller a
        lump sum payment, which is typically lower than the property’s market
        value. The benefit of this offer is that it can expedite the closing
        process since there are no financing contingencies or delays associated
        with securing a loan. This type of offer is most effective when the
        seller is motivated to sell quickly and is willing to accept a lower
        price in exchange for the convenience of an all-cash deal.
      </p>
      <br></br>
      <h2 className="text-lg font-semibold">Three-Tiered Offer</h2>
      <p>
        The Three-Tiered Offer is a strategic approach that provides the seller
        with three different options to choose from, making it a flexible and
        appealing choice for sellers with varying financial needs. This method
        involves structuring three distinct offers into one, each with its own
        set of terms and benefits.
      </p>
      <br></br>
      <h3 className="text-lg font-semibold">1. Low-Ball Cash Offer</h3>
      <p>
        This is the lowest of the three offers and is an all-cash deal. The goal
        of this offer is to anchor the negotiation at a low starting point. It
        is expected that this offer may initially offend the seller due to its
        lower value, but it sets the stage for the next two offers, which are
        structured to be more appealing.
      </p>
      <br></br>
      <h3 className="text-lg font-semibold">
        2. Small Down Payment with Seller Carry
      </h3>
      <p>
        This offer combines a smaller cash payment upfront with a
        seller-financed second mortgage. It’s designed to appeal to sellers who
        need some immediate cash but are open to receiving the balance over
        time. This structure allows you to offer more than a pure cash deal,
        making it attractive to sellers who want some liquidity now but do not
        need the full amount immediately.
      </p>
      <br></br>
      <h3 className="text-lg font-semibold">3. All Seller Financing</h3>
      <p>
        The final offer involves 100% seller financing, where the seller agrees
        to finance the entire purchase price. This option typically allows you
        to offer the highest amount since there’s no immediate cash outlay
        required from your side. It’s a win-win situation: the seller sells the
        property and gains a steady monthly income from the financing
        arrangement, while you, as the investor, control a new property with no
        money down.
      </p>
      <br></br>
      <h2 className="text-lg font-semibold">
        Benefits of the Three-Tiered Offer
      </h2>
      <p>
        The Three-Tiered Offer is highly effective because it provides the
        seller with multiple choices, each tailored to different financial
        needs. By offering a range of options, you increase the chances of
        meeting the seller’s expectations and successfully closing the deal.
        It’s a versatile strategy that can be adjusted based on the seller’s
        priorities, making it a powerful tool in real estate negotiations.
      </p>
    </div>
  );
};

export default MarketOfferHelp;
