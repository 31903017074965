import { generateId } from "./string"

/**
 * It's a simple custom hash
 * Just generate a random string and concat with the base64 of the target number
 * 
 * @param num 
 * @returns 
 */
export const customHash = (num: number) => {
  const randomUid = generateId(8);
  const base64 = btoa(num.toString());
  return randomUid.concat(base64);
}