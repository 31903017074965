import React from "react";
import { faHome } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import MarketTooltip from "../MarketTooltip";

interface HomeButtonProps {
  props?: any;
}

const HomeButton = ({ props }: HomeButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      data-tip
      data-for={"home-market-button"}
      className="btn btn-ghost hover:bg-hover-card-light hover:dark:bg-hover-card-dark"
      onClick={() => navigate("/market")}
    >
      <FontAwesomeIcon icon={faHome} className="text-[20px] text-secondary" />
      <MarketTooltip id={`home-market-button`} content={"Home"} />
    </button>
  );
};

export default HomeButton;
