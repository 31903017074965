/* eslint-disable import/no-anonymous-default-export */
import Drive from "src/interfaces/drive";
import { DriveProperty } from "../interfaces/driveProperty";
import axios from "../utils/axios";
import ApiTokenSupport from "./token.support";
import Property from "src/interfaces/property";

class DashApi extends ApiTokenSupport {
  async delete(driveID: number) {
    const resp = await axios.delete<any>(
      `/api/drive/${driveID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getUserDrives(): Promise<Drive[]> {
    const resp = await axios.get<Drive[]>(
      "/api/drive/user",
      this.withAuthorization()
    );
    return resp.data;
  }

  async getPropertiesByDriveID(driveID: number): Promise<DriveProperty[]> {
    const resp = await axios.get<DriveProperty[]>(
      `/api/drive_property/drive/${driveID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getPropertyByID(propertyID: number): Promise<DriveProperty> {
    const resp = await axios.get<DriveProperty>(
      `/api/drive_property/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async deleteProperty(propertyID: number): Promise<DriveProperty> {
    const resp = await axios.delete<DriveProperty>(
      `/api/drive_property/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addToSendFuse(propertyID: number): Promise<Property> {
    const resp = await axios.post<Property>(
      "/api/drive_property/add-to-properties",
      { propertyID },
      this.withAuthorization()
    );
    return resp.data;
  }

  async getPaidZipCodes(): Promise<string[]> {
    const resp = await axios.get<string[]>(
      `/api/drive/zip-codes`,
      this.withAuthorization()
    );
    return resp.data;
  }

  propertyImg(propertyID: number, view: string) {
    return `${process.env.REACT_APP_BACKEND_URL}/api/property-photos/view/${view}/${propertyID}?isDrive=true`;
  }
}

export default new DashApi();
