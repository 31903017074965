import React from "react";
import useTemplatePostcardsContext from "src/hooks/private/useTemplatePostcardsContext";
import TemplatePostcardHolder from "./TemplatePostcardHolder";
import TemplateLetterHolder from "./TemplateLetterHolder";
import { TemplatePostcard } from "src/interfaces/templatePostcard";
import { TemplateLetter } from "src/interfaces/templateLetter";
import { motion, AnimatePresence } from "framer-motion";
import { TemplateID } from "src/interfaces/campaign";
import useTemplateLettersContext from "src/hooks/private/useTemplateLettersContext";

interface TemplateSummaryProps {
  templateIDs: TemplateID[];
  sendDays: string[] | Date[];
  showSummaryHeader?: boolean;
  propertyID?: number;
}

const TemplateSummary = ({
  templateIDs,
  sendDays,
  showSummaryHeader = true,
  propertyID
}: TemplateSummaryProps) => {

  const { templatePostcards } = useTemplatePostcardsContext();

  const { templateLetters } = useTemplateLettersContext();

  return (
    <>
      <div className="relative mb-2 rounded-md bg-card-light py-2 px-4 shadow-xl dark:bg-card-dark">
        {showSummaryHeader && (
          <div className="flex items-center justify-center gap-2">
            <span className="text-sm font-bold text-secondary xs:text-base sm:text-xl">
              Summary
            </span>
          </div>
        )}
        <div className="mt-2 grid w-full auto-cols-max grid-flow-col items-center justify-start gap-2 overflow-x-auto">
          <AnimatePresence>
            {Object.keys(templateIDs).map((key) => {
              const sequenceIndex = parseInt(key);
              const { id, type } = templateIDs[sequenceIndex];
              const selectedTemplate = ([...templatePostcards, ...templateLetters]).find(
                (template: TemplatePostcard | TemplateLetter) =>
                  template.id === id && template.type === type
              );

              return (
                selectedTemplate && (
                  <motion.div
                    key={`$-temp-${key}-${selectedTemplate.id}`}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="px-2">
                      <span className="text-neutral-400 dark:text-neutral-400">
                        Mail #
                      </span>
                      <span className="font-semibold text-text-dark dark:text-text-light">
                        {sequenceIndex + 1}
                      </span>
                    </div>

                    {type === "postcard" && <TemplatePostcardHolder template={selectedTemplate as TemplatePostcard} propertyID={propertyID} pointLoader />}
                    {type === "letter" && <TemplateLetterHolder template={selectedTemplate as TemplateLetter} className="" propertyID={propertyID} pointLoader />}
                    <div className="flex w-full items-center justify-center">
                      <div>
                        <span className="text-neutral-400 dark:text-neutral-400">
                          Send Date:{" "}
                        </span>
                        <span className="font-semibold text-text-dark dark:text-text-light">
                          {sendDays[sequenceIndex]}
                        </span>
                      </div>
                    </div>
                  </motion.div>
                )
              );
            })}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default TemplateSummary;
