import useViewport from "src/hooks/useViewport";

interface SwiperInvisibleBlockProps {
  num: number;
}

const SwiperInvisibleBlock = ({ num }: SwiperInvisibleBlockProps) => {
  const { isMobile, height } = useViewport();

  const getHeight = () => {
    let blockHeight: number = 0;
    if (num >= 8) {
      return blockHeight;
    }
    if (isMobile) {
      blockHeight = height - 360 - num * (65 + num);
    } else if (height > 768 && height < 1024) {
      blockHeight = height - 370 - num * (79 + num );
    } else {
      blockHeight = height - 220 - num * (75 + num);
    }
    return blockHeight;
  };

  return (
    <>
      <div
        style={{
          height: `${getHeight()}px`,
        }}
        className="bg-back-light dark:bg-back-dark w-full overscroll-y-none"
      ></div>
    </>
  );
};

export default SwiperInvisibleBlock;
