import React, { ChangeEventHandler } from "react";
import Property from "src/interfaces/property";
import { Option } from "./SelectInput";
import "src/css/removeSafariInputOutline.css";

interface SelectOwnerProps {
  property: Property;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
}

const SelectOwner = ({ property, onChange }: SelectOwnerProps) => {
  const { owner1, owner2 } = property;

  const createOptions = () => {
    let options: Option[] = [];

    if (owner1 !== undefined && owner1.length > 1) {
      options.push({ label: owner1, value: owner1 });
    }

    if (owner2 !== undefined && owner2.length > 1) {
      options.push({ label: owner2, value: owner2 });
    }
    return options.map((opt: Option) => (
      <option key={`op-${opt.value}`} value={opt.value}>
        {opt.label}
      </option>
    ));
  };

  return (
    <div className="relative">
      <div
        className={
          "absolute -top-2 left-2 h-[10px] px-1 text-text-dark dark:text-text-light bg-back-light dark:bg-base-100"
        }
      >
        <p className="select-none text-xxs font-medium dark:font-medium">
          Mail To
        </p>
      </div>
      <select
        name={"owner"}
        className="select select-secondary h-[55px] w-36 rounded-lg bg-back-light font-semibold text-text-dark dark:bg-base-100 dark:text-text-light outline-none"
        onChange={onChange}
      >
        {createOptions()}
      </select>
    </div>
  );
};

export default SelectOwner;
