import { faGear, faLock } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  DebtStackItem,
  OfferItem,
  ProofOfFundsItem,
} from "src/redux/slices/cartSlice";
import { RootState } from "src/redux/store";
import { withStripeProvider } from "src/hooks/useStripeOptions";
import { marketApi } from "src/api";
import { loadStripe } from "@stripe/stripe-js";
import useApp from "src/hooks/useAppContext";
import ButtonLoader from "src/components/loading/ButtonLoader";
import useTheme from "src/hooks/useTheme";
import { useNavigate } from "react-router-dom";

interface CartFooterProps {
  setCartDrawerOpen: (value: boolean) => void;
}

export const marketPrices = {
  debtStacks: 497,
  allCashOffers: 1497,
  threeTieredOffers: 2497,
  proofOfFunds: 1497,
};

const CartFooter = ({ setCartDrawerOpen }: CartFooterProps) => {
  const cartState = useSelector((state: RootState) => state.cart);

  const { app } = useApp();

  const { tailwindTheme } = useTheme();

  const stripeKey = `${process.env[`REACT_APP_STRIPE_PK_${app?.id}`]}`;

  const stripePromise = loadStripe(stripeKey as string);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const subtotal = (
    (cartState.debtStacks.length * marketPrices.debtStacks +
      cartState.offers.filter(
        (offer: OfferItem) => offer.isThreeTiered === false
      ).length *
        marketPrices.allCashOffers +
      cartState.offers.filter(
        (offer: OfferItem) => offer.isThreeTiered === true
      ).length *
        marketPrices.allCashOffers +
      cartState.proofOfFunds.length * marketPrices.proofOfFunds) /
    100
  ).toFixed(2);

  const isCartEmpty =
    cartState.debtStacks.length === 0 &&
    cartState.offers.length === 0 &&
    cartState.proofOfFunds.length === 0;

  const line_items = [
    ...cartState.debtStacks.map((item: DebtStackItem) => ({
      price_data: {
        currency: "usd",
        product_data: {
          name: `Debt Stack: ${item.address.description}`,
        },
        unit_amount: marketPrices.debtStacks,
      },
      quantity: 1,
    })),
    ...cartState.offers.map((offer: OfferItem) => ({
      price_data: {
        currency: "usd",
        product_data: {
          name: offer.isThreeTiered
            ? `Three-Tiered Offer: ${offer.address.description}`
            : `All-Cash Offer: ${offer.address.description}`,
        },
        unit_amount: offer.isThreeTiered
          ? marketPrices.threeTieredOffers
          : marketPrices.allCashOffers,
      },
      quantity: 1,
    })),
    ...cartState.proofOfFunds.map((item: ProofOfFundsItem) => ({
      price_data: {
        currency: "usd",
        product_data: {
          name: `Proof of Funds: ${item.address.description}`,
        },
        unit_amount: marketPrices.proofOfFunds,
      },
      quantity: 1,
    })),
  ];

  const sessionData = {
    payment_method_types: ["card"],
    line_items,
    mode: "payment",
    success_url: `${app?.url}/market/success/{CHECKOUT_SESSION_ID}`,
    cancel_url: `${app?.url}/market`,
  };

  const handleCheckoutClick = async () => {
    const stripe = await stripePromise;
    if (!stripe) {
      console.error("Stripe failed to load");
      return;
    }
    try {
      setIsLoading(true);
      const session = await marketApi.createCheckoutSession(sessionData);
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating checkout session", error);
    } finally {
      setIsLoading(false);
    }
  };

  const marketSettings = useSelector(
    (state: RootState) => state.marketOrders.marketSettings
  );

  const isAllRequiredMarketSettingsFilled = () => {
    return (
      marketSettings.name?.trim() !== "" &&
      marketSettings.email?.trim() !== "" &&
      marketSettings.phone?.trim() !== "" &&
      marketSettings.address !== null
    );
  };

  const handleRouteToSettings = () => {
    setCartDrawerOpen(false);
    navigate("/market/settings");
  };

  return (
    <>
      <div className="absolute bottom-0 right-0 w-full p-1.5">
        <div className="flex w-full flex-row gap-1.5">
          <div className="flex basis-1/2 items-center justify-center rounded-lg bg-card-light dark:bg-card-dark sm:basis-1/3">
            <div className="flex items-center justify-center gap-1.5">
              <span className="text-sm font-medium text-gray-800 dark:text-gray-200">
                Subtotal
              </span>
              <div>
                <span className="text-sm font-bold text-text-dark dark:text-text-light">
                  ${subtotal}
                </span>
              </div>
            </div>
          </div>
          <div className="basis-1/2 sm:basis-2/3">
            <button
              className="btn btn-secondary w-full"
              disabled={isCartEmpty}
              onClick={
                !isAllRequiredMarketSettingsFilled()
                  ? handleRouteToSettings
                  : handleCheckoutClick
              }
            >
              {isLoading ? (
                <ButtonLoader className="h-6 w-6" fill={tailwindTheme.theme1} />
              ) : (
                <>
                  {!isAllRequiredMarketSettingsFilled() ? (
                    <>
                      <FontAwesomeIcon icon={faGear} />
                      Settings
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faLock} />
                      Checkout
                    </>
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withStripeProvider(CartFooter);
