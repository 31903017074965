import { PropsWithChildren, createContext } from "react";
import stripeJs, { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useApp from "src/hooks/useAppContext";

export type StripeElementOptions =
  | stripeJs.StripeCardNumberElementOptions
  | stripeJs.StripeCardCvcElementOptions
  | stripeJs.StripeCardExpiryElementOptions;

interface StripeContextValue {
  options: StripeElementOptions;
}

// default stripe element options
// can be override inside each component
const options: StripeElementOptions = {
  classes: {
    base: "px-3 py-3 placeholder-gray-400 text-purple font-bold placeholder:font-normal bg-white rounded text-sm shadow w-full",
    focus: "ring ring-purple",
  },
  style: {
    base: {
      // color: '#7760F0',
      fontWeight: "bold",
      letterSpacing: "0.025em",
      "::placeholder": {
        // color: '#cfd7df',
        fontWeight: "400",
      },
    },
  },
};

export const StripeContext = createContext<StripeContextValue>({
  options,
});

interface StripeContextProps {}

export const StripeProvider = ({
  children,
}: PropsWithChildren<StripeContextProps>) => {

  const { app } = useApp();

  const stripeKey = `${process.env[`REACT_APP_STRIPE_PK_${app?.id}`]}`;

  const stripePromise = loadStripe(stripeKey as string);

  return (
    <StripeContext.Provider value={{ options }}>
      <Elements stripe={stripePromise}>{children}</Elements>
    </StripeContext.Provider>
  );

}

export default StripeContext;