import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "src/hooks/useModal";
import UpgradeModal from "src/components/modals/UpgradeModal";
import SubscriptionsHelp from "src/components/help/SubscriptionsHelp";
import useSubscriptionContext from "src/hooks/useSubscription";
import { useState, useEffect } from "react";
import useTeamContext from "src/hooks/private/useTeamContext";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useApp from "src/hooks/useAppContext";

const Upgrade = () => {
  const { openModalWith } = useModal();

  const { team } = useTeamContext();

  const { app } = useApp();

  const { appSubscriptions } = useSubscriptionContext();

  const { subscription } = useCurrentSubscriptionContext();

  const [isUpgradeEligible, setIsUpgradeEligible] = useState(false);

  useEffect(() => {
    const determineUpgradeEligibility = () => {
      if (!appSubscriptions || !subscription?.sortOrder) return false;

      let highestSortOrder = Math.max(
        ...appSubscriptions.map((sub) => sub.sortOrder)
      );

      return subscription.sortOrder < highestSortOrder;
    };

    setIsUpgradeEligible(determineUpgradeEligibility());
  }, [appSubscriptions, subscription]);

  const nextSubscription = appSubscriptions?.find(
    (sub) =>
      sub.sortOrder ===
      (subscription?.sortOrder ? subscription?.sortOrder : 0) + 1
  );

  const handleClick = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: (
        <UpgradeModal
          hideExplanation={true}
          defaultSub={nextSubscription?.id}
        />
      ),
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  return (
    <>
      {team &&
        !team.isDemo &&
        isUpgradeEligible &&
        !subscription?.isDeprecated && (
          <div
            onClick={handleClick}
            className="flex cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-primary bg-primary py-4 text-text-dark"
          >
            <div className="relative flex items-center justify-center">
              <div className="absolute -top-[40px] flex h-12 w-12 items-center justify-center rounded-full bg-card-light dark:bg-card-dark">
                <div className="absolute flex h-10 w-10 items-center justify-center rounded-full bg-primary">
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    className="text-lg text-card-dark"
                  />
                </div>
              </div>
            </div>
            <div className="p-2 text-center text-lg font-semibold">
              Upgrade
              {nextSubscription?.name ? " to " + nextSubscription?.name : ""}
            </div>
            {app?.id === 1 && (
              <div className="font-medium">
                {subscription?.id === 1 ? (
                  <>
                    <div>
                      <FontAwesomeIcon icon={faCircleCheck} className="mr-1" />
                      <span>Send Offers</span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faCircleCheck} className="mr-1" />
                      <span>Unlimited Users</span>
                    </div>
                  </>
                ) : (
                  <div>
                    <FontAwesomeIcon icon={faCircleCheck} className="mr-1" />
                    <span>Three Tiered Offers</span>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default Upgrade;
