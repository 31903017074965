import classnames from "classnames";
import { Notification } from "src/global/types";
import { keyEvents } from "src/helpers/constants";
import useNotification from "src/hooks/private/useNotification";
import useTimeAgo from "src/hooks/useTimeAgo";
import StaticActionBadge from "../badges/StaticActionBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

interface InlineNotificationProps {
  notification: Notification;
  readBy?: string;
}

const InlineNotification = ({
  notification,
  readBy,
}: InlineNotificationProps) => {
  const { timeAgo } = useTimeAgo();
  const { onClickNotification } = useNotification();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    onClickNotification(notification)
  };

  return (
    <>
      <li
        className={classnames(
          "mb-1 flex w-full cursor-pointer flex-wrap  justify-between rounded-lg px-2 text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
        )}
        key={notification.id}
        onClick={handleClick}
      >
        <div
          className={classnames("w-full px-4 py-2", {
            "my-1 cursor-pointer ": !notification.isRead,
            "tooltip tooltip-top": !!readBy,
          })}
          data-tip={readBy}
        >
          <div className="flex w-full flex-wrap justify-between">
            <label className="text-md relative font-bold italic">
              {!notification.isRead && (
                <div className="absolute -bottom-[8px] -left-[18px] flex h-full columns-1 flex-col items-center justify-center">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="h-[15px] w-[15px] text-accent"
                  />
                </div>
              )}
              <StaticActionBadge
                title={
                  keyEvents[notification.event] === "Offer"
                    ? notification?.notification.trim().startsWith("A Three")
                      ? "Three Tiered Offer"
                      : "Offer"
                    : keyEvents[notification.event]
                }
              />
            </label>
            <small
              className={
                notification.isRead
                  ? "italic"
                  : "font-semibold italic text-accent"
              }
            >
              {timeAgo(notification.createdAt)}
            </small>
          </div>
          <div className={"flex w-full text-xs"}>
            {notification?.notification.trim()}
          </div>
        </div>
      </li>
      <div className="w-full px-4 mb-1">
        <div className="w-4/10 mx-auto h-[1.5px] bg-back-light dark:bg-back-dark"></div>
      </div>
    </>
  );
};

export default InlineNotification;
