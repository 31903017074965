import {
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ToggleDrawerProps {
  isToggled: boolean;
  onClick: () => void;
}

const ToggleDrawer = ({
  isToggled,
  onClick,
}: ToggleDrawerProps) => {
  return (
    <>
      <button
        onClick={onClick}
        className="sm:btn sm:btn-ghost dark:text-text-dark md:hover:dark:bg-back-dark md:hover:bg-hover-card-light"
      >
        <FontAwesomeIcon
          className={
            isToggled
              ? "text-[34px] text-secondary -rotate-90 duration-[380ms] "
              : "text-[34px] text-secondary duration-[380ms]"
          }
          icon={faCaretLeft}
        />
      </button>
    </>
  );
};

export default ToggleDrawer;
