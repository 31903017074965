import Property from "src/interfaces/property";

const propertyDefault: Property = {
  id: 0,
  createdAt: 0,
  updatedAt: 0,
  teamID: 0,
  userID: 0,
  isActive: false,
  status: "researched",
  isWatchList: false,
  isTitle: false,
  isSkipTrace: false,
  isSequence: false,
  isOffer: false,
  isCogo: false,
  isPhone1: false,
  isPhone2: false,
  isEmail1: false,
  isEmail2: false,
  fullAddress: "",
  houseNumber: "",
  street: "",
  city: "",
  state: "",
  zipcode: 0,
  xCoord: 0,
  yCoord: 0,
  fipsState: "",
  fipsCounty: "",
  bed: 0,
  bath: 0,
  sqft: 0,
  yearBuilt: 1900,
  appraisedValue: 0,
};

export default propertyDefault;
