import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { brokersApi } from "src/api";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import { BrokerProofOfFunds, BrokerOffer } from "src/interfaces/brokers";

interface BrokersContextInterface {
  proofOfFunds: BrokerProofOfFunds[];
  fetchProofOfFunds: () => void;
  offers: BrokerOffer[];
  fetchOffers: () => void;
}

const brokersContextDefaults: BrokersContextInterface = {
  proofOfFunds: [],
  fetchProofOfFunds: () => {},
  offers: [],
  fetchOffers: () => {},
};

const BrokersContext = createContext<BrokersContextInterface>(
  brokersContextDefaults
);

interface BrokersProviderProps {}

export const BrokersProvider = ({
  children,
}: PropsWithChildren<BrokersProviderProps>) => {
  const [proofOfFunds, setProofOfFunds] = useState<BrokerProofOfFunds[]>([]);

  const [offers, setOffers] = useState<BrokerOffer[]>([]);

  const { handleError } = useErrorHandler();

  const fetchProofOfFunds = useCallback(async () => {
    try {
      const proofOfFunds = await brokersApi.getTeamProofOfFunds();
      setProofOfFunds(proofOfFunds);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  const fetchOffers = useCallback(async () => {
    try {
      const offers = await brokersApi.getTeamOffers();
      setOffers(offers);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchProofOfFunds();
  }, [fetchProofOfFunds]);

  useEffect(() => {
    fetchOffers();
  }, [fetchOffers]);

  return (
    <BrokersContext.Provider
      value={{
        proofOfFunds,
        fetchProofOfFunds,
        offers,
        fetchOffers,
      }}
    >
      {children}
    </BrokersContext.Provider>
  );
};

export default BrokersContext;
