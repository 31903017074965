/* eslint-disable import/no-anonymous-default-export */
import { SkipTrace } from "src/interfaces/skipTrace";
import { FilterParams } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class SkiptraceApi extends ApiTokenSupport {
  async getSkiptraces(filter?: FilterParams): Promise<SkipTrace[]> {
    const resp = await axios.post<SkipTrace[]>(
      `/api/skiptrace/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  // what is the exact usage of this
  /* async requestSkipTrace(propertyID: number): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/skiptrace`, { propertyID } , this.withAuthorization());
    return resp.data;
  } */

  async orderSkipTrace(propertyID: number): Promise<SkipTrace> {
    const resp = await axios.post<SkipTrace>(
      `/api/skiptrace/order`,
      { propertyID },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new SkiptraceApi();
