/* eslint-disable import/no-anonymous-default-export */
import { Sequence, SequenceResponse } from 'src/interfaces/sequence';
import { Mailing } from 'src/interfaces/mailing';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';
import { FilterParams } from 'src/global/types';

class SequenceApi extends ApiTokenSupport {

  async listSequences(data?: FilterParams): Promise<SequenceResponse> {
    const resp = await axios.post<SequenceResponse>(
      `/api/sequence/list-sequences`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get team sequences
   * @param teamID 
   * @returns 
   */
  async getSequences(teamID: number): Promise<Sequence[]> {
    const resp = await axios.get<Sequence[]>(`/api/sequence/all/${teamID}`, this.withAuthorization());
    return resp.data;
  }
  
  /**
   * Get Letters
   * 
   * @returns 
   */
  async getMailings(teamID: number): Promise<Mailing[]> {
    const resp = await axios.get<Mailing[]>(`/api/mailing/all/${teamID}`, this.withAuthorization());
    return resp.data;
  }

  /**
   * Start a sequence
   * @param data 
   * @returns 
   */
  async createSequence(data: any): Promise<Sequence> {
    const resp = await axios.post<Sequence>(`/api/sequence/start`, data, this.withAuthorization());
    return resp.data;
  }
  
  /**
   * Update sequence
   * 
   * @param sequenceID 
   * @param data 
   * @returns 
   */
  async updateSequence(sequenceID: number, data: any): Promise<Sequence> {
    const resp = await axios.put<Sequence>(`/api/sequence/${sequenceID}`, data, this.withAuthorization());
    return resp.data;
  }
  
}

export default new SequenceApi();
