import { faSwatchbook } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme, themes } from "src/global/themes";
import { capitalize } from "src/helpers/parseStrings";
import useTheme from "src/hooks/useTheme";

interface ThemeOptionProps {
  theme: Theme;
}

const ThemeOptions = ({ theme }: ThemeOptionProps) => {
  const { setTheme } = useTheme();

  const handleThemeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    event.stopPropagation(); // Use the event object passed to the function
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    setTheme(name);
  };

  return (
    <>
      <li className="w-full">
        <button
          onClick={(event) => handleThemeChange(event, theme.name)}
          className="btn btn-ghost w-full font-bold hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
        >
          <div className="flex w-full items-center justify-between">
            <div
              style={{
                color: theme.daisyUI[0],
              }}
              className="font-semibold"
            >
              {capitalize(theme.name)}
            </div>
            <div className="flex items-center justify-end gap-1">
              {theme.daisyUI.map((color: any, index: number) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: color,
                  }}
                  className="h-5 w-2 gap-2 rounded-b-full rounded-t-full"
                ></div>
              ))}
            </div>
          </div>
        </button>
      </li>
    </>
  );
};

const ThemeChooser = () => {
  return (
    <>
      <div className="dropdown dropdown-end dropdown-hover">
        <label
          tabIndex={0}
          className="btn btn-ghost hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
        >
          <FontAwesomeIcon
            icon={faSwatchbook}
            className="cursor-pointer text-xl text-primary"
          />
        </label>
        <ul
          tabIndex={0}
          className="menu dropdown-content mt-0.5 w-52 rounded-box bg-card-light p-2 text-primary shadow-2xl drop-shadow-xl dark:bg-card-dark md:dark:bg-card-dark"
        >
          {themes.map((theme: Theme, index: number) => (
            <ThemeOptions key={index} theme={theme} />
          ))}
        </ul>
      </div>
    </>
  );
};

export default ThemeChooser;
