export const zipcodes = {
  "10001": { state: "NY", city: "NEW YORK" },
  "10002": { state: "NY", city: "NEW YORK" },
  "10003": { state: "NY", city: "NEW YORK" },
  "10004": { state: "NY", city: "NEW YORK" },
  "10005": { state: "NY", city: "NEW YORK" },
  "10006": { state: "NY", city: "NEW YORK" },
  "10007": { state: "NY", city: "NEW YORK" },
  "10008": { state: "NY", city: "NEW YORK" },
  "10009": { state: "NY", city: "NEW YORK" },
  "10010": { state: "NY", city: "NEW YORK" },
  "10011": { state: "NY", city: "NEW YORK" },
  "10012": { state: "NY", city: "NEW YORK" },
  "10013": { state: "NY", city: "NEW YORK" },
  "10014": { state: "NY", city: "NEW YORK" },
  "10016": { state: "NY", city: "NEW YORK" },
  "10017": { state: "NY", city: "NEW YORK" },
  "10018": { state: "NY", city: "NEW YORK" },
  "10019": { state: "NY", city: "NEW YORK" },
  "10020": { state: "NY", city: "NEW YORK" },
  "10021": { state: "NY", city: "NEW YORK" },
  "10022": { state: "NY", city: "NEW YORK" },
  "10023": { state: "NY", city: "NEW YORK" },
  "10024": { state: "NY", city: "NEW YORK" },
  "10025": { state: "NY", city: "NEW YORK" },
  "10026": { state: "NY", city: "NEW YORK" },
  "10027": { state: "NY", city: "NEW YORK" },
  "10028": { state: "NY", city: "NEW YORK" },
  "10029": { state: "NY", city: "NEW YORK" },
  "10030": { state: "NY", city: "NEW YORK" },
  "10031": { state: "NY", city: "NEW YORK" },
  "10032": { state: "NY", city: "NEW YORK" },
  "10033": { state: "NY", city: "NEW YORK" },
  "10034": { state: "NY", city: "NEW YORK" },
  "10035": { state: "NY", city: "NEW YORK" },
  "10036": { state: "NY", city: "NEW YORK" },
  "10037": { state: "NY", city: "NEW YORK" },
  "10038": { state: "NY", city: "NEW YORK" },
  "10039": { state: "NY", city: "NEW YORK" },
  "10040": { state: "NY", city: "NEW YORK" },
  "10041": { state: "NY", city: "NEW YORK" },
  "10043": { state: "NY", city: "NEW YORK" },
  "10044": { state: "NY", city: "NEW YORK" },
  "10045": { state: "NY", city: "NEW YORK" },
  "10055": { state: "NY", city: "NEW YORK" },
  "10060": { state: "NY", city: "NEW YORK" },
  "10065": { state: "NY", city: "NEW YORK" },
  "10069": { state: "NY", city: "NEW YORK" },
  "10075": { state: "NY", city: "NEW YORK" },
  "10080": { state: "NY", city: "NEW YORK" },
  "10081": { state: "NY", city: "NEW YORK" },
  "10087": { state: "NY", city: "NEW YORK" },
  "10090": { state: "NY", city: "NEW YORK" },
  "10101": { state: "NY", city: "NEW YORK" },
  "10102": { state: "NY", city: "NEW YORK" },
  "10103": { state: "NY", city: "NEW YORK" },
  "10104": { state: "NY", city: "NEW YORK" },
  "10105": { state: "NY", city: "NEW YORK" },
  "10106": { state: "NY", city: "NEW YORK" },
  "10107": { state: "NY", city: "NEW YORK" },
  "10108": { state: "NY", city: "NEW YORK" },
  "10109": { state: "NY", city: "NEW YORK" },
  "10110": { state: "NY", city: "NEW YORK" },
  "10111": { state: "NY", city: "NEW YORK" },
  "10112": { state: "NY", city: "NEW YORK" },
  "10113": { state: "NY", city: "NEW YORK" },
  "10114": { state: "NY", city: "NEW YORK" },
  "10115": { state: "NY", city: "NEW YORK" },
  "10116": { state: "NY", city: "NEW YORK" },
  "10117": { state: "NY", city: "NEW YORK" },
  "10118": { state: "NY", city: "NEW YORK" },
  "10119": { state: "NY", city: "NEW YORK" },
  "10120": { state: "NY", city: "NEW YORK" },
  "10121": { state: "NY", city: "NEW YORK" },
  "10122": { state: "NY", city: "NEW YORK" },
  "10123": { state: "NY", city: "NEW YORK" },
  "10124": { state: "NY", city: "NEW YORK" },
  "10125": { state: "NY", city: "NEW YORK" },
  "10126": { state: "NY", city: "NEW YORK" },
  "10128": { state: "NY", city: "NEW YORK" },
  "10129": { state: "NY", city: "NEW YORK" },
  "10130": { state: "NY", city: "NEW YORK" },
  "10131": { state: "NY", city: "NEW YORK" },
  "10132": { state: "NY", city: "NEW YORK" },
  "10133": { state: "NY", city: "NEW YORK" },
  "10138": { state: "NY", city: "NEW YORK" },
  "10150": { state: "NY", city: "NEW YORK" },
  "10151": { state: "NY", city: "NEW YORK" },
  "10152": { state: "NY", city: "NEW YORK" },
  "10153": { state: "NY", city: "NEW YORK" },
  "10154": { state: "NY", city: "NEW YORK" },
  "10155": { state: "NY", city: "NEW YORK" },
  "10156": { state: "NY", city: "NEW YORK" },
  "10157": { state: "NY", city: "NEW YORK" },
  "10158": { state: "NY", city: "NEW YORK" },
  "10159": { state: "NY", city: "NEW YORK" },
  "10160": { state: "NY", city: "NEW YORK" },
  "10162": { state: "NY", city: "NEW YORK" },
  "10163": { state: "NY", city: "NEW YORK" },
  "10164": { state: "NY", city: "NEW YORK" },
  "10165": { state: "NY", city: "NEW YORK" },
  "10166": { state: "NY", city: "NEW YORK" },
  "10167": { state: "NY", city: "NEW YORK" },
  "10168": { state: "NY", city: "NEW YORK" },
  "10169": { state: "NY", city: "NEW YORK" },
  "10170": { state: "NY", city: "NEW YORK" },
  "10171": { state: "NY", city: "NEW YORK" },
  "10172": { state: "NY", city: "NEW YORK" },
  "10173": { state: "NY", city: "NEW YORK" },
  "10174": { state: "NY", city: "NEW YORK" },
  "10175": { state: "NY", city: "NEW YORK" },
  "10176": { state: "NY", city: "NEW YORK" },
  "10177": { state: "NY", city: "NEW YORK" },
  "10178": { state: "NY", city: "NEW YORK" },
  "10179": { state: "NY", city: "NEW YORK" },
  "10185": { state: "NY", city: "NEW YORK" },
  "10199": { state: "NY", city: "NEW YORK" },
  "10203": { state: "NY", city: "NEW YORK" },
  "10211": { state: "NY", city: "NEW YORK" },
  "10212": { state: "NY", city: "NEW YORK" },
  "10213": { state: "NY", city: "NEW YORK" },
  "10242": { state: "NY", city: "NEW YORK" },
  "10249": { state: "NY", city: "NEW YORK" },
  "10256": { state: "NY", city: "NEW YORK" },
  "10258": { state: "NY", city: "NEW YORK" },
  "10259": { state: "NY", city: "NEW YORK" },
  "10260": { state: "NY", city: "NEW YORK" },
  "10261": { state: "NY", city: "NEW YORK" },
  "10265": { state: "NY", city: "NEW YORK" },
  "10268": { state: "NY", city: "NEW YORK" },
  "10269": { state: "NY", city: "NEW YORK" },
  "10270": { state: "NY", city: "NEW YORK" },
  "10271": { state: "NY", city: "NEW YORK" },
  "10272": { state: "NY", city: "NEW YORK" },
  "10273": { state: "NY", city: "NEW YORK" },
  "10274": { state: "NY", city: "NEW YORK" },
  "10275": { state: "NY", city: "NEW YORK" },
  "10276": { state: "NY", city: "NEW YORK" },
  "10277": { state: "NY", city: "NEW YORK" },
  "10278": { state: "NY", city: "NEW YORK" },
  "10279": { state: "NY", city: "NEW YORK" },
  "10280": { state: "NY", city: "NEW YORK" },
  "10281": { state: "NY", city: "NEW YORK" },
  "10282": { state: "NY", city: "NEW YORK" },
  "10285": { state: "NY", city: "NEW YORK" },
  "10286": { state: "NY", city: "NEW YORK" },
  "10301": { state: "NY", city: "STATEN ISLAND" },
  "10302": { state: "NY", city: "STATEN ISLAND" },
  "10303": { state: "NY", city: "STATEN ISLAND" },
  "10304": { state: "NY", city: "STATEN ISLAND" },
  "10305": { state: "NY", city: "STATEN ISLAND" },
  "10306": { state: "NY", city: "STATEN ISLAND" },
  "10307": { state: "NY", city: "STATEN ISLAND" },
  "10308": { state: "NY", city: "STATEN ISLAND" },
  "10309": { state: "NY", city: "STATEN ISLAND" },
  "10310": { state: "NY", city: "STATEN ISLAND" },
  "10311": { state: "NY", city: "STATEN ISLAND" },
  "10312": { state: "NY", city: "STATEN ISLAND" },
  "10313": { state: "NY", city: "STATEN ISLAND" },
  "10314": { state: "NY", city: "STATEN ISLAND" },
  "10451": { state: "NY", city: "BRONX" },
  "10452": { state: "NY", city: "BRONX" },
  "10453": { state: "NY", city: "BRONX" },
  "10454": { state: "NY", city: "BRONX" },
  "10455": { state: "NY", city: "BRONX" },
  "10456": { state: "NY", city: "BRONX" },
  "10457": { state: "NY", city: "BRONX" },
  "10458": { state: "NY", city: "BRONX" },
  "10459": { state: "NY", city: "BRONX" },
  "10460": { state: "NY", city: "BRONX" },
  "10461": { state: "NY", city: "BRONX" },
  "10462": { state: "NY", city: "BRONX" },
  "10463": { state: "NY", city: "BRONX" },
  "10464": { state: "NY", city: "BRONX" },
  "10465": { state: "NY", city: "BRONX" },
  "10466": { state: "NY", city: "BRONX" },
  "10467": { state: "NY", city: "BRONX" },
  "10468": { state: "NY", city: "BRONX" },
  "10469": { state: "NY", city: "BRONX" },
  "10470": { state: "NY", city: "BRONX" },
  "10471": { state: "NY", city: "BRONX" },
  "10472": { state: "NY", city: "BRONX" },
  "10473": { state: "NY", city: "BRONX" },
  "10474": { state: "NY", city: "BRONX" },
  "10475": { state: "NY", city: "BRONX" },
  "10501": { state: "NY", city: "AMAWALK" },
  "10502": { state: "NY", city: "ARDSLEY" },
  "10503": { state: "NY", city: "ARDSLEY ON HUDSON" },
  "10504": { state: "NY", city: "ARMONK" },
  "10505": { state: "NY", city: "BALDWIN PLACE" },
  "10506": { state: "NY", city: "BEDFORD" },
  "10507": { state: "NY", city: "BEDFORD HILLS" },
  "10509": { state: "NY", city: "BREWSTER" },
  "10510": { state: "NY", city: "BRIARCLIFF MANOR" },
  "10511": { state: "NY", city: "BUCHANAN" },
  "10512": { state: "NY", city: "CARMEL" },
  "10514": { state: "NY", city: "CHAPPAQUA" },
  "10516": { state: "NY", city: "COLD SPRING" },
  "10517": { state: "NY", city: "CROMPOND" },
  "10518": { state: "NY", city: "CROSS RIVER" },
  "10519": { state: "NY", city: "CROTON FALLS" },
  "10520": { state: "NY", city: "CROTON ON HUDSON" },
  "10521": { state: "NY", city: "CROTON ON HUDSON" },
  "10522": { state: "NY", city: "DOBBS FERRY" },
  "10523": { state: "NY", city: "ELMSFORD" },
  "10524": { state: "NY", city: "GARRISON" },
  "10526": { state: "NY", city: "GOLDENS BRIDGE" },
  "10527": { state: "NY", city: "GRANITE SPRINGS" },
  "10528": { state: "NY", city: "HARRISON" },
  "10530": { state: "NY", city: "HARTSDALE" },
  "10532": { state: "NY", city: "HAWTHORNE" },
  "10533": { state: "NY", city: "IRVINGTON" },
  "10535": { state: "NY", city: "JEFFERSON VALLEY" },
  "10536": { state: "NY", city: "KATONAH" },
  "10537": { state: "NY", city: "LAKE PEEKSKILL" },
  "10538": { state: "NY", city: "LARCHMONT" },
  "10540": { state: "NY", city: "LINCOLNDALE" },
  "10541": { state: "NY", city: "MAHOPAC" },
  "10542": { state: "NY", city: "MAHOPAC FALLS" },
  "10543": { state: "NY", city: "MAMARONECK" },
  "10545": { state: "NY", city: "MARYKNOLL" },
  "10546": { state: "NY", city: "MILLWOOD" },
  "10547": { state: "NY", city: "MOHEGAN LAKE" },
  "10548": { state: "NY", city: "MONTROSE" },
  "10549": { state: "NY", city: "MOUNT KISCO" },
  "10550": { state: "NY", city: "MOUNT VERNON" },
  "10551": { state: "NY", city: "MOUNT VERNON" },
  "10552": { state: "NY", city: "MOUNT VERNON" },
  "10553": { state: "NY", city: "MOUNT VERNON" },
  "10560": { state: "NY", city: "NORTH SALEM" },
  "10562": { state: "NY", city: "OSSINING" },
  "10566": { state: "NY", city: "PEEKSKILL" },
  "10567": { state: "NY", city: "CORTLANDT MANOR" },
  "10570": { state: "NY", city: "PLEASANTVILLE" },
  "10573": { state: "NY", city: "PORT CHESTER" },
  "10576": { state: "NY", city: "POUND RIDGE" },
  "10577": { state: "NY", city: "PURCHASE" },
  "10578": { state: "NY", city: "PURDYS" },
  "10579": { state: "NY", city: "PUTNAM VALLEY" },
  "10580": { state: "NY", city: "RYE" },
  "10583": { state: "NY", city: "SCARSDALE" },
  "10587": { state: "NY", city: "SHENOROCK" },
  "10588": { state: "NY", city: "SHRUB OAK" },
  "10589": { state: "NY", city: "SOMERS" },
  "10590": { state: "NY", city: "SOUTH SALEM" },
  "10591": { state: "NY", city: "TARRYTOWN" },
  "10594": { state: "NY", city: "THORNWOOD" },
  "10595": { state: "NY", city: "VALHALLA" },
  "10596": { state: "NY", city: "VERPLANCK" },
  "10597": { state: "NY", city: "WACCABUC" },
  "10598": { state: "NY", city: "YORKTOWN HEIGHTS" },
  "10601": { state: "NY", city: "WHITE PLAINS" },
  "10602": { state: "NY", city: "WHITE PLAINS" },
  "10603": { state: "NY", city: "WHITE PLAINS" },
  "10604": { state: "NY", city: "WEST HARRISON" },
  "10605": { state: "NY", city: "WHITE PLAINS" },
  "10606": { state: "NY", city: "WHITE PLAINS" },
  "10607": { state: "NY", city: "WHITE PLAINS" },
  "10610": { state: "NY", city: "WHITE PLAINS" },
  "10701": { state: "NY", city: "YONKERS" },
  "10702": { state: "NY", city: "YONKERS" },
  "10703": { state: "NY", city: "YONKERS" },
  "10704": { state: "NY", city: "YONKERS" },
  "10705": { state: "NY", city: "YONKERS" },
  "10706": { state: "NY", city: "HASTINGS ON HUDSON" },
  "10707": { state: "NY", city: "TUCKAHOE" },
  "10708": { state: "NY", city: "BRONXVILLE" },
  "10709": { state: "NY", city: "EASTCHESTER" },
  "10710": { state: "NY", city: "YONKERS" },
  "10801": { state: "NY", city: "NEW ROCHELLE" },
  "10802": { state: "NY", city: "NEW ROCHELLE" },
  "10803": { state: "NY", city: "PELHAM" },
  "10804": { state: "NY", city: "NEW ROCHELLE" },
  "10805": { state: "NY", city: "NEW ROCHELLE" },
  "10901": { state: "NY", city: "SUFFERN" },
  "10910": { state: "NY", city: "ARDEN" },
  "10911": { state: "NY", city: "BEAR MOUNTAIN" },
  "10912": { state: "NY", city: "BELLVALE" },
  "10913": { state: "NY", city: "BLAUVELT" },
  "10914": { state: "NY", city: "BLOOMING GROVE" },
  "10915": { state: "NY", city: "BULLVILLE" },
  "10916": { state: "NY", city: "CAMPBELL HALL" },
  "10917": { state: "NY", city: "CENTRAL VALLEY" },
  "10918": { state: "NY", city: "CHESTER" },
  "10919": { state: "NY", city: "CIRCLEVILLE" },
  "10920": { state: "NY", city: "CONGERS" },
  "10921": { state: "NY", city: "FLORIDA" },
  "10922": { state: "NY", city: "FORT MONTGOMERY" },
  "10923": { state: "NY", city: "GARNERVILLE" },
  "10924": { state: "NY", city: "GOSHEN" },
  "10925": { state: "NY", city: "GREENWOOD LAKE" },
  "10926": { state: "NY", city: "HARRIMAN" },
  "10927": { state: "NY", city: "HAVERSTRAW" },
  "10928": { state: "NY", city: "HIGHLAND FALLS" },
  "10930": { state: "NY", city: "HIGHLAND MILLS" },
  "10931": { state: "NY", city: "HILLBURN" },
  "10932": { state: "NY", city: "HOWELLS" },
  "10933": { state: "NY", city: "JOHNSON" },
  "10940": { state: "NY", city: "MIDDLETOWN" },
  "10941": { state: "NY", city: "MIDDLETOWN" },
  "10949": { state: "NY", city: "MONROE" },
  "10950": { state: "NY", city: "MONROE" },
  "10952": { state: "NY", city: "MONSEY" },
  "10953": { state: "NY", city: "MOUNTAINVILLE" },
  "10954": { state: "NY", city: "NANUET" },
  "10956": { state: "NY", city: "NEW CITY" },
  "10958": { state: "NY", city: "NEW HAMPTON" },
  "10959": { state: "NY", city: "NEW MILFORD" },
  "10960": { state: "NY", city: "NYACK" },
  "10962": { state: "NY", city: "ORANGEBURG" },
  "10963": { state: "NY", city: "OTISVILLE" },
  "10964": { state: "NY", city: "PALISADES" },
  "10965": { state: "NY", city: "PEARL RIVER" },
  "10968": { state: "NY", city: "PIERMONT" },
  "10969": { state: "NY", city: "PINE ISLAND" },
  "10970": { state: "NY", city: "POMONA" },
  "10973": { state: "NY", city: "SLATE HILL" },
  "10974": { state: "NY", city: "SLOATSBURG" },
  "10975": { state: "NY", city: "SOUTHFIELDS" },
  "10976": { state: "NY", city: "SPARKILL" },
  "10977": { state: "NY", city: "SPRING VALLEY" },
  "10979": { state: "NY", city: "STERLING FOREST" },
  "10980": { state: "NY", city: "STONY POINT" },
  "10981": { state: "NY", city: "SUGAR LOAF" },
  "10982": { state: "NY", city: "TALLMAN" },
  "10983": { state: "NY", city: "TAPPAN" },
  "10984": { state: "NY", city: "THIELLS" },
  "10985": { state: "NY", city: "THOMPSON RIDGE" },
  "10986": { state: "NY", city: "TOMKINS COVE" },
  "10987": { state: "NY", city: "TUXEDO PARK" },
  "10988": { state: "NY", city: "UNIONVILLE" },
  "10989": { state: "NY", city: "VALLEY COTTAGE" },
  "10990": { state: "NY", city: "WARWICK" },
  "10992": { state: "NY", city: "WASHINGTONVILLE" },
  "10993": { state: "NY", city: "WEST HAVERSTRAW" },
  "10994": { state: "NY", city: "WEST NYACK" },
  "10996": { state: "NY", city: "WEST POINT" },
  "10997": { state: "NY", city: "WEST POINT" },
  "10998": { state: "NY", city: "WESTTOWN" },
  "11001": { state: "NY", city: "FLORAL PARK" },
  "11002": { state: "NY", city: "FLORAL PARK" },
  "11003": { state: "NY", city: "ELMONT" },
  "11004": { state: "NY", city: "GLEN OAKS" },
  "11005": { state: "NY", city: "FLORAL PARK" },
  "11010": { state: "NY", city: "FRANKLIN SQUARE" },
  "11020": { state: "NY", city: "GREAT NECK" },
  "11021": { state: "NY", city: "GREAT NECK" },
  "11022": { state: "NY", city: "GREAT NECK" },
  "11023": { state: "NY", city: "GREAT NECK" },
  "11024": { state: "NY", city: "GREAT NECK" },
  "11026": { state: "NY", city: "GREAT NECK" },
  "11027": { state: "NY", city: "GREAT NECK" },
  "11030": { state: "NY", city: "MANHASSET" },
  "11040": { state: "NY", city: "NEW HYDE PARK" },
  "11042": { state: "NY", city: "NEW HYDE PARK" },
  "11050": { state: "NY", city: "PORT WASHINGTON" },
  "11051": { state: "NY", city: "PORT WASHINGTON" },
  "11052": { state: "NY", city: "PORT WASHINGTON" },
  "11053": { state: "NY", city: "PORT WASHINGTON" },
  "11054": { state: "NY", city: "PORT WASHINGTON" },
  "11055": { state: "NY", city: "PORT WASHINGTON" },
  "11096": { state: "NY", city: "INWOOD" },
  "11101": { state: "NY", city: "LONG ISLAND CITY" },
  "11102": { state: "NY", city: "ASTORIA" },
  "11103": { state: "NY", city: "ASTORIA" },
  "11104": { state: "NY", city: "SUNNYSIDE" },
  "11105": { state: "NY", city: "ASTORIA" },
  "11106": { state: "NY", city: "ASTORIA" },
  "11109": { state: "NY", city: "LONG ISLAND CITY" },
  "11120": { state: "NY", city: "LONG ISLAND CITY" },
  "11201": { state: "NY", city: "BROOKLYN" },
  "11202": { state: "NY", city: "BROOKLYN" },
  "11203": { state: "NY", city: "BROOKLYN" },
  "11204": { state: "NY", city: "BROOKLYN" },
  "11205": { state: "NY", city: "BROOKLYN" },
  "11206": { state: "NY", city: "BROOKLYN" },
  "11207": { state: "NY", city: "BROOKLYN" },
  "11208": { state: "NY", city: "BROOKLYN" },
  "11209": { state: "NY", city: "BROOKLYN" },
  "11210": { state: "NY", city: "BROOKLYN" },
  "11211": { state: "NY", city: "BROOKLYN" },
  "11212": { state: "NY", city: "BROOKLYN" },
  "11213": { state: "NY", city: "BROOKLYN" },
  "11214": { state: "NY", city: "BROOKLYN" },
  "11215": { state: "NY", city: "BROOKLYN" },
  "11216": { state: "NY", city: "BROOKLYN" },
  "11217": { state: "NY", city: "BROOKLYN" },
  "11218": { state: "NY", city: "BROOKLYN" },
  "11219": { state: "NY", city: "BROOKLYN" },
  "11220": { state: "NY", city: "BROOKLYN" },
  "11221": { state: "NY", city: "BROOKLYN" },
  "11222": { state: "NY", city: "BROOKLYN" },
  "11223": { state: "NY", city: "BROOKLYN" },
  "11224": { state: "NY", city: "BROOKLYN" },
  "11225": { state: "NY", city: "BROOKLYN" },
  "11226": { state: "NY", city: "BROOKLYN" },
  "11228": { state: "NY", city: "BROOKLYN" },
  "11229": { state: "NY", city: "BROOKLYN" },
  "11230": { state: "NY", city: "BROOKLYN" },
  "11231": { state: "NY", city: "BROOKLYN" },
  "11232": { state: "NY", city: "BROOKLYN" },
  "11233": { state: "NY", city: "BROOKLYN" },
  "11234": { state: "NY", city: "BROOKLYN" },
  "11235": { state: "NY", city: "BROOKLYN" },
  "11236": { state: "NY", city: "BROOKLYN" },
  "11237": { state: "NY", city: "BROOKLYN" },
  "11238": { state: "NY", city: "BROOKLYN" },
  "11239": { state: "NY", city: "BROOKLYN" },
  "11241": { state: "NY", city: "BROOKLYN" },
  "11242": { state: "NY", city: "BROOKLYN" },
  "11243": { state: "NY", city: "BROOKLYN" },
  "11245": { state: "NY", city: "BROOKLYN" },
  "11247": { state: "NY", city: "BROOKLYN" },
  "11249": { state: "NY", city: "BROOKLYN" },
  "11251": { state: "NY", city: "BROOKLYN" },
  "11252": { state: "NY", city: "BROOKLYN" },
  "11256": { state: "NY", city: "BROOKLYN" },
  "11351": { state: "NY", city: "FLUSHING" },
  "11352": { state: "NY", city: "FLUSHING" },
  "11354": { state: "NY", city: "FLUSHING" },
  "11355": { state: "NY", city: "FLUSHING" },
  "11356": { state: "NY", city: "COLLEGE POINT" },
  "11357": { state: "NY", city: "WHITESTONE" },
  "11358": { state: "NY", city: "FLUSHING" },
  "11359": { state: "NY", city: "BAYSIDE" },
  "11360": { state: "NY", city: "BAYSIDE" },
  "11361": { state: "NY", city: "BAYSIDE" },
  "11362": { state: "NY", city: "LITTLE NECK" },
  "11363": { state: "NY", city: "LITTLE NECK" },
  "11364": { state: "NY", city: "OAKLAND GARDENS" },
  "11365": { state: "NY", city: "FRESH MEADOWS" },
  "11366": { state: "NY", city: "FRESH MEADOWS" },
  "11367": { state: "NY", city: "FLUSHING" },
  "11368": { state: "NY", city: "CORONA" },
  "11369": { state: "NY", city: "EAST ELMHURST" },
  "11370": { state: "NY", city: "EAST ELMHURST" },
  "11371": { state: "NY", city: "FLUSHING" },
  "11372": { state: "NY", city: "JACKSON HEIGHTS" },
  "11373": { state: "NY", city: "ELMHURST" },
  "11374": { state: "NY", city: "REGO PARK" },
  "11375": { state: "NY", city: "FOREST HILLS" },
  "11377": { state: "NY", city: "WOODSIDE" },
  "11378": { state: "NY", city: "MASPETH" },
  "11379": { state: "NY", city: "MIDDLE VILLAGE" },
  "11380": { state: "NY", city: "ELMHURST" },
  "11385": { state: "NY", city: "RIDGEWOOD" },
  "11386": { state: "NY", city: "RIDGEWOOD" },
  "11405": { state: "NY", city: "JAMAICA" },
  "11411": { state: "NY", city: "CAMBRIA HEIGHTS" },
  "11412": { state: "NY", city: "SAINT ALBANS" },
  "11413": { state: "NY", city: "SPRINGFIELD GARDENS" },
  "11414": { state: "NY", city: "HOWARD BEACH" },
  "11415": { state: "NY", city: "KEW GARDENS" },
  "11416": { state: "NY", city: "OZONE PARK" },
  "11417": { state: "NY", city: "OZONE PARK" },
  "11418": { state: "NY", city: "RICHMOND HILL" },
  "11419": { state: "NY", city: "SOUTH RICHMOND HILL" },
  "11420": { state: "NY", city: "SOUTH OZONE PARK" },
  "11421": { state: "NY", city: "WOODHAVEN" },
  "11422": { state: "NY", city: "ROSEDALE" },
  "11423": { state: "NY", city: "HOLLIS" },
  "11424": { state: "NY", city: "JAMAICA" },
  "11425": { state: "NY", city: "JAMAICA" },
  "11426": { state: "NY", city: "BELLEROSE" },
  "11427": { state: "NY", city: "QUEENS VILLAGE" },
  "11428": { state: "NY", city: "QUEENS VILLAGE" },
  "11429": { state: "NY", city: "QUEENS VILLAGE" },
  "11430": { state: "NY", city: "JAMAICA" },
  "11431": { state: "NY", city: "JAMAICA" },
  "11432": { state: "NY", city: "JAMAICA" },
  "11433": { state: "NY", city: "JAMAICA" },
  "11434": { state: "NY", city: "JAMAICA" },
  "11435": { state: "NY", city: "JAMAICA" },
  "11436": { state: "NY", city: "JAMAICA" },
  "11437": { state: "NY", city: "JAMAICA" },
  "11439": { state: "NY", city: "JAMAICA" },
  "11451": { state: "NY", city: "JAMAICA" },
  "11499": { state: "NY", city: "JAMAICA" },
  "11501": { state: "NY", city: "MINEOLA" },
  "11507": { state: "NY", city: "ALBERTSON" },
  "11509": { state: "NY", city: "ATLANTIC BEACH" },
  "11510": { state: "NY", city: "BALDWIN" },
  "11514": { state: "NY", city: "CARLE PLACE" },
  "11516": { state: "NY", city: "CEDARHURST" },
  "11518": { state: "NY", city: "EAST ROCKAWAY" },
  "11520": { state: "NY", city: "FREEPORT" },
  "11530": { state: "NY", city: "GARDEN CITY" },
  "11531": { state: "NY", city: "GARDEN CITY" },
  "11542": { state: "NY", city: "GLEN COVE" },
  "11545": { state: "NY", city: "GLEN HEAD" },
  "11547": { state: "NY", city: "GLENWOOD LANDING" },
  "11548": { state: "NY", city: "GREENVALE" },
  "11549": { state: "NY", city: "HEMPSTEAD" },
  "11550": { state: "NY", city: "HEMPSTEAD" },
  "11551": { state: "NY", city: "HEMPSTEAD" },
  "11552": { state: "NY", city: "WEST HEMPSTEAD" },
  "11553": { state: "NY", city: "UNIONDALE" },
  "11554": { state: "NY", city: "EAST MEADOW" },
  "11555": { state: "NY", city: "UNIONDALE" },
  "11556": { state: "NY", city: "UNIONDALE" },
  "11557": { state: "NY", city: "HEWLETT" },
  "11558": { state: "NY", city: "ISLAND PARK" },
  "11559": { state: "NY", city: "LAWRENCE" },
  "11560": { state: "NY", city: "LOCUST VALLEY" },
  "11561": { state: "NY", city: "LONG BEACH" },
  "11563": { state: "NY", city: "LYNBROOK" },
  "11565": { state: "NY", city: "MALVERNE" },
  "11566": { state: "NY", city: "MERRICK" },
  "11568": { state: "NY", city: "OLD WESTBURY" },
  "11569": { state: "NY", city: "POINT LOOKOUT" },
  "11570": { state: "NY", city: "ROCKVILLE CENTRE" },
  "11571": { state: "NY", city: "ROCKVILLE CENTRE" },
  "11572": { state: "NY", city: "OCEANSIDE" },
  "11575": { state: "NY", city: "ROOSEVELT" },
  "11576": { state: "NY", city: "ROSLYN" },
  "11577": { state: "NY", city: "ROSLYN HEIGHTS" },
  "11579": { state: "NY", city: "SEA CLIFF" },
  "11580": { state: "NY", city: "VALLEY STREAM" },
  "11581": { state: "NY", city: "VALLEY STREAM" },
  "11582": { state: "NY", city: "VALLEY STREAM" },
  "11590": { state: "NY", city: "WESTBURY" },
  "11596": { state: "NY", city: "WILLISTON PARK" },
  "11598": { state: "NY", city: "WOODMERE" },
  "11599": { state: "NY", city: "GARDEN CITY" },
  "11690": { state: "NY", city: "FAR ROCKAWAY" },
  "11691": { state: "NY", city: "FAR ROCKAWAY" },
  "11692": { state: "NY", city: "ARVERNE" },
  "11693": { state: "NY", city: "FAR ROCKAWAY" },
  "11694": { state: "NY", city: "ROCKAWAY PARK" },
  "11695": { state: "NY", city: "FAR ROCKAWAY" },
  "11697": { state: "NY", city: "BREEZY POINT" },
  "11701": { state: "NY", city: "AMITYVILLE" },
  "11702": { state: "NY", city: "BABYLON" },
  "11703": { state: "NY", city: "NORTH BABYLON" },
  "11704": { state: "NY", city: "WEST BABYLON" },
  "11705": { state: "NY", city: "BAYPORT" },
  "11706": { state: "NY", city: "BAY SHORE" },
  "11707": { state: "NY", city: "WEST BABYLON" },
  "11709": { state: "NY", city: "BAYVILLE" },
  "11710": { state: "NY", city: "BELLMORE" },
  "11713": { state: "NY", city: "BELLPORT" },
  "11714": { state: "NY", city: "BETHPAGE" },
  "11715": { state: "NY", city: "BLUE POINT" },
  "11716": { state: "NY", city: "BOHEMIA" },
  "11717": { state: "NY", city: "BRENTWOOD" },
  "11718": { state: "NY", city: "BRIGHTWATERS" },
  "11719": { state: "NY", city: "BROOKHAVEN" },
  "11720": { state: "NY", city: "CENTEREACH" },
  "11721": { state: "NY", city: "CENTERPORT" },
  "11722": { state: "NY", city: "CENTRAL ISLIP" },
  "11724": { state: "NY", city: "COLD SPRING HARBOR" },
  "11725": { state: "NY", city: "COMMACK" },
  "11726": { state: "NY", city: "COPIAGUE" },
  "11727": { state: "NY", city: "CORAM" },
  "11729": { state: "NY", city: "DEER PARK" },
  "11730": { state: "NY", city: "EAST ISLIP" },
  "11731": { state: "NY", city: "EAST NORTHPORT" },
  "11732": { state: "NY", city: "EAST NORWICH" },
  "11733": { state: "NY", city: "EAST SETAUKET" },
  "11735": { state: "NY", city: "FARMINGDALE" },
  "11737": { state: "NY", city: "FARMINGDALE" },
  "11738": { state: "NY", city: "FARMINGVILLE" },
  "11739": { state: "NY", city: "GREAT RIVER" },
  "11740": { state: "NY", city: "GREENLAWN" },
  "11741": { state: "NY", city: "HOLBROOK" },
  "11742": { state: "NY", city: "HOLTSVILLE" },
  "11743": { state: "NY", city: "HUNTINGTON" },
  "11746": { state: "NY", city: "HUNTINGTON STATION" },
  "11747": { state: "NY", city: "MELVILLE" },
  "11749": { state: "NY", city: "ISLANDIA" },
  "11751": { state: "NY", city: "ISLIP" },
  "11752": { state: "NY", city: "ISLIP TERRACE" },
  "11753": { state: "NY", city: "JERICHO" },
  "11754": { state: "NY", city: "KINGS PARK" },
  "11755": { state: "NY", city: "LAKE GROVE" },
  "11756": { state: "NY", city: "LEVITTOWN" },
  "11757": { state: "NY", city: "LINDENHURST" },
  "11758": { state: "NY", city: "MASSAPEQUA" },
  "11760": { state: "NY", city: "MELVILLE" },
  "11762": { state: "NY", city: "MASSAPEQUA PARK" },
  "11763": { state: "NY", city: "MEDFORD" },
  "11764": { state: "NY", city: "MILLER PLACE" },
  "11765": { state: "NY", city: "MILL NECK" },
  "11766": { state: "NY", city: "MOUNT SINAI" },
  "11767": { state: "NY", city: "NESCONSET" },
  "11768": { state: "NY", city: "NORTHPORT" },
  "11769": { state: "NY", city: "OAKDALE" },
  "11770": { state: "NY", city: "OCEAN BEACH" },
  "11771": { state: "NY", city: "OYSTER BAY" },
  "11772": { state: "NY", city: "PATCHOGUE" },
  "11773": { state: "NY", city: "MELVILLE" },
  "11775": { state: "NY", city: "MELVILLE" },
  "11776": { state: "NY", city: "PORT JEFFERSON STATION" },
  "11777": { state: "NY", city: "PORT JEFFERSON" },
  "11778": { state: "NY", city: "ROCKY POINT" },
  "11779": { state: "NY", city: "RONKONKOMA" },
  "11780": { state: "NY", city: "SAINT JAMES" },
  "11782": { state: "NY", city: "SAYVILLE" },
  "11783": { state: "NY", city: "SEAFORD" },
  "11784": { state: "NY", city: "SELDEN" },
  "11786": { state: "NY", city: "SHOREHAM" },
  "11787": { state: "NY", city: "SMITHTOWN" },
  "11788": { state: "NY", city: "HAUPPAUGE" },
  "11789": { state: "NY", city: "SOUND BEACH" },
  "11790": { state: "NY", city: "STONY BROOK" },
  "11791": { state: "NY", city: "SYOSSET" },
  "11792": { state: "NY", city: "WADING RIVER" },
  "11793": { state: "NY", city: "WANTAGH" },
  "11794": { state: "NY", city: "STONY BROOK" },
  "11795": { state: "NY", city: "WEST ISLIP" },
  "11796": { state: "NY", city: "WEST SAYVILLE" },
  "11797": { state: "NY", city: "WOODBURY" },
  "11798": { state: "NY", city: "WYANDANCH" },
  "11801": { state: "NY", city: "HICKSVILLE" },
  "11802": { state: "NY", city: "HICKSVILLE" },
  "11803": { state: "NY", city: "PLAINVIEW" },
  "11804": { state: "NY", city: "OLD BETHPAGE" },
  "11815": { state: "NY", city: "HICKSVILLE" },
  "11853": { state: "NY", city: "JERICHO" },
  "11901": { state: "NY", city: "RIVERHEAD" },
  "11930": { state: "NY", city: "AMAGANSETT" },
  "11931": { state: "NY", city: "AQUEBOGUE" },
  "11932": { state: "NY", city: "BRIDGEHAMPTON" },
  "11933": { state: "NY", city: "CALVERTON" },
  "11934": { state: "NY", city: "CENTER MORICHES" },
  "11935": { state: "NY", city: "CUTCHOGUE" },
  "11937": { state: "NY", city: "EAST HAMPTON" },
  "11939": { state: "NY", city: "EAST MARION" },
  "11940": { state: "NY", city: "EAST MORICHES" },
  "11941": { state: "NY", city: "EASTPORT" },
  "11942": { state: "NY", city: "EAST QUOGUE" },
  "11944": { state: "NY", city: "GREENPORT" },
  "11946": { state: "NY", city: "HAMPTON BAYS" },
  "11947": { state: "NY", city: "JAMESPORT" },
  "11948": { state: "NY", city: "LAUREL" },
  "11949": { state: "NY", city: "MANORVILLE" },
  "11950": { state: "NY", city: "MASTIC" },
  "11951": { state: "NY", city: "MASTIC BEACH" },
  "11952": { state: "NY", city: "MATTITUCK" },
  "11953": { state: "NY", city: "MIDDLE ISLAND" },
  "11954": { state: "NY", city: "MONTAUK" },
  "11955": { state: "NY", city: "MORICHES" },
  "11956": { state: "NY", city: "NEW SUFFOLK" },
  "11957": { state: "NY", city: "ORIENT" },
  "11958": { state: "NY", city: "PECONIC" },
  "11959": { state: "NY", city: "QUOGUE" },
  "11960": { state: "NY", city: "REMSENBURG" },
  "11961": { state: "NY", city: "RIDGE" },
  "11962": { state: "NY", city: "SAGAPONACK" },
  "11963": { state: "NY", city: "SAG HARBOR" },
  "11964": { state: "NY", city: "SHELTER ISLAND" },
  "11965": { state: "NY", city: "SHELTER ISLAND HEIGHTS" },
  "11967": { state: "NY", city: "SHIRLEY" },
  "11968": { state: "NY", city: "SOUTHAMPTON" },
  "11969": { state: "NY", city: "SOUTHAMPTON" },
  "11970": { state: "NY", city: "SOUTH JAMESPORT" },
  "11971": { state: "NY", city: "SOUTHOLD" },
  "11972": { state: "NY", city: "SPEONK" },
  "11973": { state: "NY", city: "UPTON" },
  "11975": { state: "NY", city: "WAINSCOTT" },
  "11976": { state: "NY", city: "WATER MILL" },
  "11977": { state: "NY", city: "WESTHAMPTON" },
  "11978": { state: "NY", city: "WESTHAMPTON BEACH" },
  "11980": { state: "NY", city: "YAPHANK" },
  "12007": { state: "NY", city: "ALCOVE" },
  "12008": { state: "NY", city: "ALPLAUS" },
  "12009": { state: "NY", city: "ALTAMONT" },
  "12010": { state: "NY", city: "AMSTERDAM" },
  "12015": { state: "NY", city: "ATHENS" },
  "12016": { state: "NY", city: "AURIESVILLE" },
  "12017": { state: "NY", city: "AUSTERLITZ" },
  "12018": { state: "NY", city: "AVERILL PARK" },
  "12019": { state: "NY", city: "BALLSTON LAKE" },
  "12020": { state: "NY", city: "BALLSTON SPA" },
  "12022": { state: "NY", city: "BERLIN" },
  "12023": { state: "NY", city: "BERNE" },
  "12024": { state: "NY", city: "BRAINARD" },
  "12025": { state: "NY", city: "BROADALBIN" },
  "12027": { state: "NY", city: "BURNT HILLS" },
  "12028": { state: "NY", city: "BUSKIRK" },
  "12029": { state: "NY", city: "CANAAN" },
  "12031": { state: "NY", city: "CARLISLE" },
  "12032": { state: "NY", city: "CAROGA LAKE" },
  "12033": { state: "NY", city: "CASTLETON ON HUDSON" },
  "12035": { state: "NY", city: "CENTRAL BRIDGE" },
  "12036": { state: "NY", city: "CHARLOTTEVILLE" },
  "12037": { state: "NY", city: "CHATHAM" },
  "12040": { state: "NY", city: "CHERRY PLAIN" },
  "12041": { state: "NY", city: "CLARKSVILLE" },
  "12042": { state: "NY", city: "CLIMAX" },
  "12043": { state: "NY", city: "COBLESKILL" },
  "12045": { state: "NY", city: "COEYMANS" },
  "12046": { state: "NY", city: "COEYMANS HOLLOW" },
  "12047": { state: "NY", city: "COHOES" },
  "12050": { state: "NY", city: "COLUMBIAVILLE" },
  "12051": { state: "NY", city: "COXSACKIE" },
  "12052": { state: "NY", city: "CROPSEYVILLE" },
  "12053": { state: "NY", city: "DELANSON" },
  "12054": { state: "NY", city: "DELMAR" },
  "12055": { state: "NY", city: "DORMANSVILLE" },
  "12056": { state: "NY", city: "DUANESBURG" },
  "12057": { state: "NY", city: "EAGLE BRIDGE" },
  "12058": { state: "NY", city: "EARLTON" },
  "12059": { state: "NY", city: "EAST BERNE" },
  "12060": { state: "NY", city: "EAST CHATHAM" },
  "12061": { state: "NY", city: "EAST GREENBUSH" },
  "12062": { state: "NY", city: "EAST NASSAU" },
  "12063": { state: "NY", city: "EAST SCHODACK" },
  "12064": { state: "NY", city: "EAST WORCESTER" },
  "12065": { state: "NY", city: "CLIFTON PARK" },
  "12066": { state: "NY", city: "ESPERANCE" },
  "12067": { state: "NY", city: "FEURA BUSH" },
  "12068": { state: "NY", city: "FONDA" },
  "12069": { state: "NY", city: "FORT HUNTER" },
  "12070": { state: "NY", city: "FORT JOHNSON" },
  "12071": { state: "NY", city: "FULTONHAM" },
  "12072": { state: "NY", city: "FULTONVILLE" },
  "12073": { state: "NY", city: "GALLUPVILLE" },
  "12074": { state: "NY", city: "GALWAY" },
  "12075": { state: "NY", city: "GHENT" },
  "12076": { state: "NY", city: "GILBOA" },
  "12077": { state: "NY", city: "GLENMONT" },
  "12078": { state: "NY", city: "GLOVERSVILLE" },
  "12082": { state: "NY", city: "GRAFTON" },
  "12083": { state: "NY", city: "GREENVILLE" },
  "12084": { state: "NY", city: "GUILDERLAND" },
  "12085": { state: "NY", city: "GUILDERLAND CENTER" },
  "12086": { state: "NY", city: "HAGAMAN" },
  "12087": { state: "NY", city: "HANNACROIX" },
  "12089": { state: "NY", city: "HOOSICK" },
  "12090": { state: "NY", city: "HOOSICK FALLS" },
  "12092": { state: "NY", city: "HOWES CAVE" },
  "12093": { state: "NY", city: "JEFFERSON" },
  "12094": { state: "NY", city: "JOHNSONVILLE" },
  "12095": { state: "NY", city: "JOHNSTOWN" },
  "12106": { state: "NY", city: "KINDERHOOK" },
  "12107": { state: "NY", city: "KNOX" },
  "12108": { state: "NY", city: "LAKE PLEASANT" },
  "12110": { state: "NY", city: "LATHAM" },
  "12115": { state: "NY", city: "MALDEN BRIDGE" },
  "12116": { state: "NY", city: "MARYLAND" },
  "12117": { state: "NY", city: "MAYFIELD" },
  "12118": { state: "NY", city: "MECHANICVILLE" },
  "12120": { state: "NY", city: "MEDUSA" },
  "12121": { state: "NY", city: "MELROSE" },
  "12122": { state: "NY", city: "MIDDLEBURGH" },
  "12123": { state: "NY", city: "NASSAU" },
  "12124": { state: "NY", city: "NEW BALTIMORE" },
  "12125": { state: "NY", city: "NEW LEBANON" },
  "12128": { state: "NY", city: "NEWTONVILLE" },
  "12130": { state: "NY", city: "NIVERVILLE" },
  "12131": { state: "NY", city: "NORTH BLENHEIM" },
  "12132": { state: "NY", city: "NORTH CHATHAM" },
  "12133": { state: "NY", city: "NORTH HOOSICK" },
  "12134": { state: "NY", city: "NORTHVILLE" },
  "12136": { state: "NY", city: "OLD CHATHAM" },
  "12137": { state: "NY", city: "PATTERSONVILLE" },
  "12138": { state: "NY", city: "PETERSBURG" },
  "12139": { state: "NY", city: "PISECO" },
  "12140": { state: "NY", city: "POESTENKILL" },
  "12141": { state: "NY", city: "QUAKER STREET" },
  "12143": { state: "NY", city: "RAVENA" },
  "12144": { state: "NY", city: "RENSSELAER" },
  "12147": { state: "NY", city: "RENSSELAERVILLE" },
  "12148": { state: "NY", city: "REXFORD" },
  "12149": { state: "NY", city: "RICHMONDVILLE" },
  "12150": { state: "NY", city: "ROTTERDAM JUNCTION" },
  "12151": { state: "NY", city: "ROUND LAKE" },
  "12153": { state: "NY", city: "SAND LAKE" },
  "12154": { state: "NY", city: "SCHAGHTICOKE" },
  "12155": { state: "NY", city: "SCHENEVUS" },
  "12156": { state: "NY", city: "SCHODACK LANDING" },
  "12157": { state: "NY", city: "SCHOHARIE" },
  "12158": { state: "NY", city: "SELKIRK" },
  "12159": { state: "NY", city: "SLINGERLANDS" },
  "12160": { state: "NY", city: "SLOANSVILLE" },
  "12161": { state: "NY", city: "SOUTH BETHLEHEM" },
  "12164": { state: "NY", city: "SPECULATOR" },
  "12165": { state: "NY", city: "SPENCERTOWN" },
  "12166": { state: "NY", city: "SPRAKERS" },
  "12167": { state: "NY", city: "STAMFORD" },
  "12168": { state: "NY", city: "STEPHENTOWN" },
  "12169": { state: "NY", city: "STEPHENTOWN" },
  "12170": { state: "NY", city: "STILLWATER" },
  "12172": { state: "NY", city: "STOTTVILLE" },
  "12173": { state: "NY", city: "STUYVESANT" },
  "12174": { state: "NY", city: "STUYVESANT FALLS" },
  "12175": { state: "NY", city: "SUMMIT" },
  "12176": { state: "NY", city: "SURPRISE" },
  "12177": { state: "NY", city: "TRIBES HILL" },
  "12180": { state: "NY", city: "TROY" },
  "12181": { state: "NY", city: "TROY" },
  "12182": { state: "NY", city: "TROY" },
  "12183": { state: "NY", city: "TROY" },
  "12184": { state: "NY", city: "VALATIE" },
  "12185": { state: "NY", city: "VALLEY FALLS" },
  "12186": { state: "NY", city: "VOORHEESVILLE" },
  "12187": { state: "NY", city: "WARNERVILLE" },
  "12188": { state: "NY", city: "WATERFORD" },
  "12189": { state: "NY", city: "WATERVLIET" },
  "12190": { state: "NY", city: "WELLS" },
  "12192": { state: "NY", city: "WEST COXSACKIE" },
  "12193": { state: "NY", city: "WESTERLO" },
  "12194": { state: "NY", city: "WEST FULTON" },
  "12195": { state: "NY", city: "WEST LEBANON" },
  "12196": { state: "NY", city: "WEST SAND LAKE" },
  "12197": { state: "NY", city: "WORCESTER" },
  "12198": { state: "NY", city: "WYNANTSKILL" },
  "12201": { state: "NY", city: "ALBANY" },
  "12202": { state: "NY", city: "ALBANY" },
  "12203": { state: "NY", city: "ALBANY" },
  "12204": { state: "NY", city: "ALBANY" },
  "12205": { state: "NY", city: "ALBANY" },
  "12206": { state: "NY", city: "ALBANY" },
  "12207": { state: "NY", city: "ALBANY" },
  "12208": { state: "NY", city: "ALBANY" },
  "12209": { state: "NY", city: "ALBANY" },
  "12210": { state: "NY", city: "ALBANY" },
  "12211": { state: "NY", city: "ALBANY" },
  "12212": { state: "NY", city: "ALBANY" },
  "12214": { state: "NY", city: "ALBANY" },
  "12220": { state: "NY", city: "ALBANY" },
  "12222": { state: "NY", city: "ALBANY" },
  "12223": { state: "NY", city: "ALBANY" },
  "12224": { state: "NY", city: "ALBANY" },
  "12225": { state: "NY", city: "ALBANY" },
  "12226": { state: "NY", city: "ALBANY" },
  "12227": { state: "NY", city: "ALBANY" },
  "12228": { state: "NY", city: "ALBANY" },
  "12229": { state: "NY", city: "ALBANY" },
  "12230": { state: "NY", city: "ALBANY" },
  "12231": { state: "NY", city: "ALBANY" },
  "12232": { state: "NY", city: "ALBANY" },
  "12233": { state: "NY", city: "ALBANY" },
  "12234": { state: "NY", city: "ALBANY" },
  "12235": { state: "NY", city: "ALBANY" },
  "12236": { state: "NY", city: "ALBANY" },
  "12237": { state: "NY", city: "ALBANY" },
  "12238": { state: "NY", city: "ALBANY" },
  "12239": { state: "NY", city: "ALBANY" },
  "12240": { state: "NY", city: "ALBANY" },
  "12241": { state: "NY", city: "ALBANY" },
  "12242": { state: "NY", city: "ALBANY" },
  "12243": { state: "NY", city: "ALBANY" },
  "12244": { state: "NY", city: "ALBANY" },
  "12245": { state: "NY", city: "ALBANY" },
  "12246": { state: "NY", city: "ALBANY" },
  "12247": { state: "NY", city: "ALBANY" },
  "12248": { state: "NY", city: "ALBANY" },
  "12249": { state: "NY", city: "ALBANY" },
  "12250": { state: "NY", city: "ALBANY" },
  "12255": { state: "NY", city: "ALBANY" },
  "12257": { state: "NY", city: "ALBANY" },
  "12260": { state: "NY", city: "ALBANY" },
  "12261": { state: "NY", city: "ALBANY" },
  "12288": { state: "NY", city: "ALBANY" },
  "12301": { state: "NY", city: "SCHENECTADY" },
  "12302": { state: "NY", city: "SCHENECTADY" },
  "12303": { state: "NY", city: "SCHENECTADY" },
  "12304": { state: "NY", city: "SCHENECTADY" },
  "12305": { state: "NY", city: "SCHENECTADY" },
  "12306": { state: "NY", city: "SCHENECTADY" },
  "12307": { state: "NY", city: "SCHENECTADY" },
  "12308": { state: "NY", city: "SCHENECTADY" },
  "12309": { state: "NY", city: "SCHENECTADY" },
  "12325": { state: "NY", city: "SCHENECTADY" },
  "12345": { state: "NY", city: "SCHENECTADY" },
  "12401": { state: "NY", city: "KINGSTON" },
  "12402": { state: "NY", city: "KINGSTON" },
  "12404": { state: "NY", city: "ACCORD" },
  "12405": { state: "NY", city: "ACRA" },
  "12406": { state: "NY", city: "ARKVILLE" },
  "12407": { state: "NY", city: "ASHLAND" },
  "12409": { state: "NY", city: "BEARSVILLE" },
  "12410": { state: "NY", city: "BIG INDIAN" },
  "12411": { state: "NY", city: "BLOOMINGTON" },
  "12412": { state: "NY", city: "BOICEVILLE" },
  "12413": { state: "NY", city: "CAIRO" },
  "12414": { state: "NY", city: "CATSKILL" },
  "12416": { state: "NY", city: "CHICHESTER" },
  "12417": { state: "NY", city: "CONNELLY" },
  "12418": { state: "NY", city: "CORNWALLVILLE" },
  "12419": { state: "NY", city: "COTTEKILL" },
  "12420": { state: "NY", city: "CRAGSMOOR" },
  "12421": { state: "NY", city: "DENVER" },
  "12422": { state: "NY", city: "DURHAM" },
  "12423": { state: "NY", city: "EAST DURHAM" },
  "12424": { state: "NY", city: "EAST JEWETT" },
  "12427": { state: "NY", city: "ELKA PARK" },
  "12428": { state: "NY", city: "ELLENVILLE" },
  "12429": { state: "NY", city: "ESOPUS" },
  "12430": { state: "NY", city: "FLEISCHMANNS" },
  "12431": { state: "NY", city: "FREEHOLD" },
  "12432": { state: "NY", city: "GLASCO" },
  "12433": { state: "NY", city: "GLENFORD" },
  "12434": { state: "NY", city: "GRAND GORGE" },
  "12435": { state: "NY", city: "GREENFIELD PARK" },
  "12436": { state: "NY", city: "HAINES FALLS" },
  "12438": { state: "NY", city: "HALCOTTSVILLE" },
  "12439": { state: "NY", city: "HENSONVILLE" },
  "12440": { state: "NY", city: "HIGH FALLS" },
  "12441": { state: "NY", city: "HIGHMOUNT" },
  "12442": { state: "NY", city: "HUNTER" },
  "12443": { state: "NY", city: "HURLEY" },
  "12444": { state: "NY", city: "JEWETT" },
  "12446": { state: "NY", city: "KERHONKSON" },
  "12448": { state: "NY", city: "LAKE HILL" },
  "12449": { state: "NY", city: "LAKE KATRINE" },
  "12450": { state: "NY", city: "LANESVILLE" },
  "12451": { state: "NY", city: "LEEDS" },
  "12452": { state: "NY", city: "LEXINGTON" },
  "12453": { state: "NY", city: "MALDEN ON HUDSON" },
  "12454": { state: "NY", city: "MAPLECREST" },
  "12455": { state: "NY", city: "MARGARETVILLE" },
  "12456": { state: "NY", city: "MOUNT MARION" },
  "12457": { state: "NY", city: "MOUNT TREMPER" },
  "12458": { state: "NY", city: "NAPANOCH" },
  "12459": { state: "NY", city: "NEW KINGSTON" },
  "12460": { state: "NY", city: "OAK HILL" },
  "12461": { state: "NY", city: "OLIVEBRIDGE" },
  "12463": { state: "NY", city: "PALENVILLE" },
  "12464": { state: "NY", city: "PHOENICIA" },
  "12465": { state: "NY", city: "PINE HILL" },
  "12466": { state: "NY", city: "PORT EWEN" },
  "12468": { state: "NY", city: "PRATTSVILLE" },
  "12469": { state: "NY", city: "PRESTON HOLLOW" },
  "12470": { state: "NY", city: "PURLING" },
  "12471": { state: "NY", city: "RIFTON" },
  "12472": { state: "NY", city: "ROSENDALE" },
  "12473": { state: "NY", city: "ROUND TOP" },
  "12474": { state: "NY", city: "ROXBURY" },
  "12475": { state: "NY", city: "RUBY" },
  "12477": { state: "NY", city: "SAUGERTIES" },
  "12480": { state: "NY", city: "SHANDAKEN" },
  "12481": { state: "NY", city: "SHOKAN" },
  "12482": { state: "NY", city: "SOUTH CAIRO" },
  "12483": { state: "NY", city: "SPRING GLEN" },
  "12484": { state: "NY", city: "STONE RIDGE" },
  "12485": { state: "NY", city: "TANNERSVILLE" },
  "12486": { state: "NY", city: "TILLSON" },
  "12487": { state: "NY", city: "ULSTER PARK" },
  "12489": { state: "NY", city: "WAWARSING" },
  "12490": { state: "NY", city: "WEST CAMP" },
  "12491": { state: "NY", city: "WEST HURLEY" },
  "12492": { state: "NY", city: "WEST KILL" },
  "12493": { state: "NY", city: "WEST PARK" },
  "12494": { state: "NY", city: "WEST SHOKAN" },
  "12495": { state: "NY", city: "WILLOW" },
  "12496": { state: "NY", city: "WINDHAM" },
  "12498": { state: "NY", city: "WOODSTOCK" },
  "12501": { state: "NY", city: "AMENIA" },
  "12502": { state: "NY", city: "ANCRAM" },
  "12503": { state: "NY", city: "ANCRAMDALE" },
  "12504": { state: "NY", city: "ANNANDALE ON HUDSON" },
  "12506": { state: "NY", city: "BANGALL" },
  "12507": { state: "NY", city: "BARRYTOWN" },
  "12508": { state: "NY", city: "BEACON" },
  "12510": { state: "NY", city: "BILLINGS" },
  "12511": { state: "NY", city: "CASTLE POINT" },
  "12512": { state: "NY", city: "CHELSEA" },
  "12513": { state: "NY", city: "CLAVERACK" },
  "12514": { state: "NY", city: "CLINTON CORNERS" },
  "12515": { state: "NY", city: "CLINTONDALE" },
  "12516": { state: "NY", city: "COPAKE" },
  "12517": { state: "NY", city: "COPAKE FALLS" },
  "12518": { state: "NY", city: "CORNWALL" },
  "12520": { state: "NY", city: "CORNWALL ON HUDSON" },
  "12521": { state: "NY", city: "CRARYVILLE" },
  "12522": { state: "NY", city: "DOVER PLAINS" },
  "12523": { state: "NY", city: "ELIZAVILLE" },
  "12524": { state: "NY", city: "FISHKILL" },
  "12525": { state: "NY", city: "GARDINER" },
  "12526": { state: "NY", city: "GERMANTOWN" },
  "12527": { state: "NY", city: "GLENHAM" },
  "12528": { state: "NY", city: "HIGHLAND" },
  "12529": { state: "NY", city: "HILLSDALE" },
  "12530": { state: "NY", city: "HOLLOWVILLE" },
  "12531": { state: "NY", city: "HOLMES" },
  "12533": { state: "NY", city: "HOPEWELL JUNCTION" },
  "12534": { state: "NY", city: "HUDSON" },
  "12537": { state: "NY", city: "HUGHSONVILLE" },
  "12538": { state: "NY", city: "HYDE PARK" },
  "12540": { state: "NY", city: "LAGRANGEVILLE" },
  "12541": { state: "NY", city: "LIVINGSTON" },
  "12542": { state: "NY", city: "MARLBORO" },
  "12543": { state: "NY", city: "MAYBROOK" },
  "12544": { state: "NY", city: "MELLENVILLE" },
  "12545": { state: "NY", city: "MILLBROOK" },
  "12546": { state: "NY", city: "MILLERTON" },
  "12547": { state: "NY", city: "MILTON" },
  "12548": { state: "NY", city: "MODENA" },
  "12549": { state: "NY", city: "MONTGOMERY" },
  "12550": { state: "NY", city: "NEWBURGH" },
  "12551": { state: "NY", city: "NEWBURGH" },
  "12552": { state: "NY", city: "NEWBURGH" },
  "12553": { state: "NY", city: "NEW WINDSOR" },
  "12555": { state: "NY", city: "NEWBURGH" },
  "12561": { state: "NY", city: "NEW PALTZ" },
  "12563": { state: "NY", city: "PATTERSON" },
  "12564": { state: "NY", city: "PAWLING" },
  "12565": { state: "NY", city: "PHILMONT" },
  "12566": { state: "NY", city: "PINE BUSH" },
  "12567": { state: "NY", city: "PINE PLAINS" },
  "12568": { state: "NY", city: "PLATTEKILL" },
  "12569": { state: "NY", city: "PLEASANT VALLEY" },
  "12570": { state: "NY", city: "POUGHQUAG" },
  "12571": { state: "NY", city: "RED HOOK" },
  "12572": { state: "NY", city: "RHINEBECK" },
  "12574": { state: "NY", city: "RHINECLIFF" },
  "12575": { state: "NY", city: "ROCK TAVERN" },
  "12577": { state: "NY", city: "SALISBURY MILLS" },
  "12578": { state: "NY", city: "SALT POINT" },
  "12580": { state: "NY", city: "STAATSBURG" },
  "12581": { state: "NY", city: "STANFORDVILLE" },
  "12582": { state: "NY", city: "STORMVILLE" },
  "12583": { state: "NY", city: "TIVOLI" },
  "12584": { state: "NY", city: "VAILS GATE" },
  "12585": { state: "NY", city: "VERBANK" },
  "12586": { state: "NY", city: "WALDEN" },
  "12588": { state: "NY", city: "WALKER VALLEY" },
  "12589": { state: "NY", city: "WALLKILL" },
  "12590": { state: "NY", city: "WAPPINGERS FALLS" },
  "12592": { state: "NY", city: "WASSAIC" },
  "12594": { state: "NY", city: "WINGDALE" },
  "12601": { state: "NY", city: "POUGHKEEPSIE" },
  "12602": { state: "NY", city: "POUGHKEEPSIE" },
  "12603": { state: "NY", city: "POUGHKEEPSIE" },
  "12604": { state: "NY", city: "POUGHKEEPSIE" },
  "12701": { state: "NY", city: "MONTICELLO" },
  "12719": { state: "NY", city: "BARRYVILLE" },
  "12720": { state: "NY", city: "BETHEL" },
  "12721": { state: "NY", city: "BLOOMINGBURG" },
  "12722": { state: "NY", city: "BURLINGHAM" },
  "12723": { state: "NY", city: "CALLICOON" },
  "12724": { state: "NY", city: "CALLICOON CENTER" },
  "12725": { state: "NY", city: "CLARYVILLE" },
  "12726": { state: "NY", city: "COCHECTON" },
  "12727": { state: "NY", city: "COCHECTON" },
  "12729": { state: "NY", city: "CUDDEBACKVILLE" },
  "12732": { state: "NY", city: "ELDRED" },
  "12733": { state: "NY", city: "FALLSBURG" },
  "12734": { state: "NY", city: "FERNDALE" },
  "12736": { state: "NY", city: "FREMONT CENTER" },
  "12737": { state: "NY", city: "GLEN SPEY" },
  "12738": { state: "NY", city: "GLEN WILD" },
  "12740": { state: "NY", city: "GRAHAMSVILLE" },
  "12741": { state: "NY", city: "HANKINS" },
  "12742": { state: "NY", city: "HARRIS" },
  "12743": { state: "NY", city: "HIGHLAND LAKE" },
  "12745": { state: "NY", city: "HORTONVILLE" },
  "12746": { state: "NY", city: "HUGUENOT" },
  "12747": { state: "NY", city: "HURLEYVILLE" },
  "12748": { state: "NY", city: "JEFFERSONVILLE" },
  "12749": { state: "NY", city: "KAUNEONGA LAKE" },
  "12750": { state: "NY", city: "KENOZA LAKE" },
  "12751": { state: "NY", city: "KIAMESHA LAKE" },
  "12752": { state: "NY", city: "LAKE HUNTINGTON" },
  "12754": { state: "NY", city: "LIBERTY" },
  "12758": { state: "NY", city: "LIVINGSTON MANOR" },
  "12759": { state: "NY", city: "LOCH SHELDRAKE" },
  "12760": { state: "NY", city: "LONG EDDY" },
  "12762": { state: "NY", city: "MONGAUP VALLEY" },
  "12763": { state: "NY", city: "MOUNTAIN DALE" },
  "12764": { state: "NY", city: "NARROWSBURG" },
  "12765": { state: "NY", city: "NEVERSINK" },
  "12766": { state: "NY", city: "NORTH BRANCH" },
  "12767": { state: "NY", city: "OBERNBURG" },
  "12768": { state: "NY", city: "PARKSVILLE" },
  "12769": { state: "NY", city: "PHILLIPSPORT" },
  "12770": { state: "NY", city: "POND EDDY" },
  "12771": { state: "NY", city: "PORT JERVIS" },
  "12775": { state: "NY", city: "ROCK HILL" },
  "12776": { state: "NY", city: "ROSCOE" },
  "12777": { state: "NY", city: "FORESTBURGH" },
  "12778": { state: "NY", city: "SMALLWOOD" },
  "12779": { state: "NY", city: "SOUTH FALLSBURG" },
  "12780": { state: "NY", city: "SPARROW BUSH" },
  "12781": { state: "NY", city: "SUMMITVILLE" },
  "12783": { state: "NY", city: "SWAN LAKE" },
  "12784": { state: "NY", city: "THOMPSONVILLE" },
  "12785": { state: "NY", city: "WESTBROOKVILLE" },
  "12786": { state: "NY", city: "WHITE LAKE" },
  "12787": { state: "NY", city: "WHITE SULPHUR SPRINGS" },
  "12788": { state: "NY", city: "WOODBOURNE" },
  "12789": { state: "NY", city: "WOODRIDGE" },
  "12790": { state: "NY", city: "WURTSBORO" },
  "12791": { state: "NY", city: "YOUNGSVILLE" },
  "12792": { state: "NY", city: "YULAN" },
  "12801": { state: "NY", city: "GLENS FALLS" },
  "12803": { state: "NY", city: "SOUTH GLENS FALLS" },
  "12804": { state: "NY", city: "QUEENSBURY" },
  "12808": { state: "NY", city: "ADIRONDACK" },
  "12809": { state: "NY", city: "ARGYLE" },
  "12810": { state: "NY", city: "ATHOL" },
  "12811": { state: "NY", city: "BAKERS MILLS" },
  "12812": { state: "NY", city: "BLUE MOUNTAIN LAKE" },
  "12814": { state: "NY", city: "BOLTON LANDING" },
  "12815": { state: "NY", city: "BRANT LAKE" },
  "12816": { state: "NY", city: "CAMBRIDGE" },
  "12817": { state: "NY", city: "CHESTERTOWN" },
  "12819": { state: "NY", city: "CLEMONS" },
  "12820": { state: "NY", city: "CLEVERDALE" },
  "12821": { state: "NY", city: "COMSTOCK" },
  "12822": { state: "NY", city: "CORINTH" },
  "12823": { state: "NY", city: "COSSAYUNA" },
  "12824": { state: "NY", city: "DIAMOND POINT" },
  "12827": { state: "NY", city: "FORT ANN" },
  "12828": { state: "NY", city: "FORT EDWARD" },
  "12831": { state: "NY", city: "GANSEVOORT" },
  "12832": { state: "NY", city: "GRANVILLE" },
  "12833": { state: "NY", city: "GREENFIELD CENTER" },
  "12834": { state: "NY", city: "GREENWICH" },
  "12835": { state: "NY", city: "HADLEY" },
  "12836": { state: "NY", city: "HAGUE" },
  "12837": { state: "NY", city: "HAMPTON" },
  "12838": { state: "NY", city: "HARTFORD" },
  "12839": { state: "NY", city: "HUDSON FALLS" },
  "12841": { state: "NY", city: "HULETTS LANDING" },
  "12842": { state: "NY", city: "INDIAN LAKE" },
  "12843": { state: "NY", city: "JOHNSBURG" },
  "12844": { state: "NY", city: "KATTSKILL BAY" },
  "12845": { state: "NY", city: "LAKE GEORGE" },
  "12846": { state: "NY", city: "LAKE LUZERNE" },
  "12847": { state: "NY", city: "LONG LAKE" },
  "12848": { state: "NY", city: "MIDDLE FALLS" },
  "12849": { state: "NY", city: "MIDDLE GRANVILLE" },
  "12850": { state: "NY", city: "MIDDLE GROVE" },
  "12851": { state: "NY", city: "MINERVA" },
  "12852": { state: "NY", city: "NEWCOMB" },
  "12853": { state: "NY", city: "NORTH CREEK" },
  "12854": { state: "NY", city: "NORTH GRANVILLE" },
  "12855": { state: "NY", city: "NORTH HUDSON" },
  "12856": { state: "NY", city: "NORTH RIVER" },
  "12857": { state: "NY", city: "OLMSTEDVILLE" },
  "12858": { state: "NY", city: "PARADOX" },
  "12859": { state: "NY", city: "PORTER CORNERS" },
  "12860": { state: "NY", city: "POTTERSVILLE" },
  "12861": { state: "NY", city: "PUTNAM STATION" },
  "12862": { state: "NY", city: "RIPARIUS" },
  "12863": { state: "NY", city: "ROCK CITY FALLS" },
  "12864": { state: "NY", city: "SABAEL" },
  "12865": { state: "NY", city: "SALEM" },
  "12866": { state: "NY", city: "SARATOGA SPRINGS" },
  "12870": { state: "NY", city: "SCHROON LAKE" },
  "12871": { state: "NY", city: "SCHUYLERVILLE" },
  "12872": { state: "NY", city: "SEVERANCE" },
  "12873": { state: "NY", city: "SHUSHAN" },
  "12874": { state: "NY", city: "SILVER BAY" },
  "12878": { state: "NY", city: "STONY CREEK" },
  "12879": { state: "NY", city: "NEWCOMB" },
  "12883": { state: "NY", city: "TICONDEROGA" },
  "12884": { state: "NY", city: "VICTORY MILLS" },
  "12885": { state: "NY", city: "WARRENSBURG" },
  "12886": { state: "NY", city: "WEVERTOWN" },
  "12887": { state: "NY", city: "WHITEHALL" },
  "12901": { state: "NY", city: "PLATTSBURGH" },
  "12903": { state: "NY", city: "PLATTSBURGH" },
  "12910": { state: "NY", city: "ALTONA" },
  "12911": { state: "NY", city: "KEESEVILLE" },
  "12912": { state: "NY", city: "AU SABLE FORKS" },
  "12913": { state: "NY", city: "BLOOMINGDALE" },
  "12914": { state: "NY", city: "BOMBAY" },
  "12915": { state: "NY", city: "BRAINARDSVILLE" },
  "12916": { state: "NY", city: "BRUSHTON" },
  "12917": { state: "NY", city: "BURKE" },
  "12918": { state: "NY", city: "CADYVILLE" },
  "12919": { state: "NY", city: "CHAMPLAIN" },
  "12920": { state: "NY", city: "CHATEAUGAY" },
  "12921": { state: "NY", city: "CHAZY" },
  "12922": { state: "NY", city: "CHILDWOLD" },
  "12923": { state: "NY", city: "CHURUBUSCO" },
  "12924": { state: "NY", city: "KEESEVILLE" },
  "12926": { state: "NY", city: "CONSTABLE" },
  "12927": { state: "NY", city: "CRANBERRY LAKE" },
  "12928": { state: "NY", city: "CROWN POINT" },
  "12929": { state: "NY", city: "DANNEMORA" },
  "12930": { state: "NY", city: "DICKINSON CENTER" },
  "12932": { state: "NY", city: "ELIZABETHTOWN" },
  "12933": { state: "NY", city: "ELLENBURG" },
  "12934": { state: "NY", city: "ELLENBURG CENTER" },
  "12935": { state: "NY", city: "ELLENBURG DEPOT" },
  "12936": { state: "NY", city: "ESSEX" },
  "12937": { state: "NY", city: "FORT COVINGTON" },
  "12939": { state: "NY", city: "GABRIELS" },
  "12941": { state: "NY", city: "JAY" },
  "12942": { state: "NY", city: "KEENE" },
  "12943": { state: "NY", city: "KEENE VALLEY" },
  "12944": { state: "NY", city: "KEESEVILLE" },
  "12945": { state: "NY", city: "LAKE CLEAR" },
  "12946": { state: "NY", city: "LAKE PLACID" },
  "12949": { state: "NY", city: "LAWRENCEVILLE" },
  "12950": { state: "NY", city: "LEWIS" },
  "12952": { state: "NY", city: "LYON MOUNTAIN" },
  "12953": { state: "NY", city: "MALONE" },
  "12955": { state: "NY", city: "LYON MOUNTAIN" },
  "12956": { state: "NY", city: "MINEVILLE" },
  "12957": { state: "NY", city: "MOIRA" },
  "12958": { state: "NY", city: "MOOERS" },
  "12959": { state: "NY", city: "MOOERS FORKS" },
  "12960": { state: "NY", city: "MORIAH" },
  "12961": { state: "NY", city: "MORIAH CENTER" },
  "12962": { state: "NY", city: "MORRISONVILLE" },
  "12964": { state: "NY", city: "NEW RUSSIA" },
  "12965": { state: "NY", city: "NICHOLVILLE" },
  "12966": { state: "NY", city: "NORTH BANGOR" },
  "12967": { state: "NY", city: "NORTH LAWRENCE" },
  "12969": { state: "NY", city: "OWLS HEAD" },
  "12970": { state: "NY", city: "PAUL SMITHS" },
  "12972": { state: "NY", city: "PERU" },
  "12973": { state: "NY", city: "PIERCEFIELD" },
  "12974": { state: "NY", city: "PORT HENRY" },
  "12975": { state: "NY", city: "PORT KENT" },
  "12976": { state: "NY", city: "RAINBOW LAKE" },
  "12977": { state: "NY", city: "RAY BROOK" },
  "12978": { state: "NY", city: "REDFORD" },
  "12979": { state: "NY", city: "ROUSES POINT" },
  "12980": { state: "NY", city: "SAINT REGIS FALLS" },
  "12981": { state: "NY", city: "SARANAC" },
  "12983": { state: "NY", city: "SARANAC LAKE" },
  "12985": { state: "NY", city: "SCHUYLER FALLS" },
  "12986": { state: "NY", city: "TUPPER LAKE" },
  "12987": { state: "NY", city: "UPPER JAY" },
  "12989": { state: "NY", city: "VERMONTVILLE" },
  "12992": { state: "NY", city: "WEST CHAZY" },
  "12993": { state: "NY", city: "WESTPORT" },
  "12995": { state: "NY", city: "WHIPPLEVILLE" },
  "12996": { state: "NY", city: "WILLSBORO" },
  "12997": { state: "NY", city: "WILMINGTON" },
  "12998": { state: "NY", city: "WITHERBEE" },
  "13020": { state: "NY", city: "APULIA STATION" },
  "13021": { state: "NY", city: "AUBURN" },
  "13022": { state: "NY", city: "AUBURN" },
  "13024": { state: "NY", city: "AUBURN" },
  "13026": { state: "NY", city: "AURORA" },
  "13027": { state: "NY", city: "BALDWINSVILLE" },
  "13028": { state: "NY", city: "BERNHARDS BAY" },
  "13029": { state: "NY", city: "BREWERTON" },
  "13030": { state: "NY", city: "BRIDGEPORT" },
  "13031": { state: "NY", city: "CAMILLUS" },
  "13032": { state: "NY", city: "CANASTOTA" },
  "13033": { state: "NY", city: "CATO" },
  "13034": { state: "NY", city: "CAYUGA" },
  "13035": { state: "NY", city: "CAZENOVIA" },
  "13036": { state: "NY", city: "CENTRAL SQUARE" },
  "13037": { state: "NY", city: "CHITTENANGO" },
  "13039": { state: "NY", city: "CICERO" },
  "13040": { state: "NY", city: "CINCINNATUS" },
  "13041": { state: "NY", city: "CLAY" },
  "13042": { state: "NY", city: "CLEVELAND" },
  "13043": { state: "NY", city: "CLOCKVILLE" },
  "13044": { state: "NY", city: "CONSTANTIA" },
  "13045": { state: "NY", city: "CORTLAND" },
  "13051": { state: "NY", city: "DELPHI FALLS" },
  "13052": { state: "NY", city: "DE RUYTER" },
  "13053": { state: "NY", city: "DRYDEN" },
  "13054": { state: "NY", city: "DURHAMVILLE" },
  "13056": { state: "NY", city: "EAST HOMER" },
  "13057": { state: "NY", city: "EAST SYRACUSE" },
  "13060": { state: "NY", city: "ELBRIDGE" },
  "13061": { state: "NY", city: "ERIEVILLE" },
  "13062": { state: "NY", city: "ETNA" },
  "13063": { state: "NY", city: "FABIUS" },
  "13064": { state: "NY", city: "FAIR HAVEN" },
  "13065": { state: "NY", city: "FAYETTE" },
  "13066": { state: "NY", city: "FAYETTEVILLE" },
  "13068": { state: "NY", city: "FREEVILLE" },
  "13069": { state: "NY", city: "FULTON" },
  "13071": { state: "NY", city: "GENOA" },
  "13072": { state: "NY", city: "GEORGETOWN" },
  "13073": { state: "NY", city: "GROTON" },
  "13074": { state: "NY", city: "HANNIBAL" },
  "13076": { state: "NY", city: "HASTINGS" },
  "13077": { state: "NY", city: "HOMER" },
  "13078": { state: "NY", city: "JAMESVILLE" },
  "13080": { state: "NY", city: "JORDAN" },
  "13081": { state: "NY", city: "KING FERRY" },
  "13082": { state: "NY", city: "KIRKVILLE" },
  "13083": { state: "NY", city: "LACONA" },
  "13084": { state: "NY", city: "LA FAYETTE" },
  "13087": { state: "NY", city: "LITTLE YORK" },
  "13088": { state: "NY", city: "LIVERPOOL" },
  "13089": { state: "NY", city: "LIVERPOOL" },
  "13090": { state: "NY", city: "LIVERPOOL" },
  "13092": { state: "NY", city: "LOCKE" },
  "13093": { state: "NY", city: "LYCOMING" },
  "13101": { state: "NY", city: "MC GRAW" },
  "13102": { state: "NY", city: "MC LEAN" },
  "13103": { state: "NY", city: "MALLORY" },
  "13104": { state: "NY", city: "MANLIUS" },
  "13107": { state: "NY", city: "MAPLE VIEW" },
  "13108": { state: "NY", city: "MARCELLUS" },
  "13110": { state: "NY", city: "MARIETTA" },
  "13111": { state: "NY", city: "MARTVILLE" },
  "13112": { state: "NY", city: "MEMPHIS" },
  "13113": { state: "NY", city: "MERIDIAN" },
  "13114": { state: "NY", city: "MEXICO" },
  "13115": { state: "NY", city: "MINETTO" },
  "13116": { state: "NY", city: "MINOA" },
  "13117": { state: "NY", city: "MONTEZUMA" },
  "13118": { state: "NY", city: "MORAVIA" },
  "13119": { state: "NY", city: "MOTTVILLE" },
  "13120": { state: "NY", city: "NEDROW" },
  "13121": { state: "NY", city: "NEW HAVEN" },
  "13122": { state: "NY", city: "NEW WOODSTOCK" },
  "13123": { state: "NY", city: "NORTH BAY" },
  "13124": { state: "NY", city: "NORTH PITCHER" },
  "13126": { state: "NY", city: "OSWEGO" },
  "13131": { state: "NY", city: "PARISH" },
  "13132": { state: "NY", city: "PENNELLVILLE" },
  "13134": { state: "NY", city: "PETERBORO" },
  "13135": { state: "NY", city: "PHOENIX" },
  "13136": { state: "NY", city: "PITCHER" },
  "13137": { state: "NY", city: "PLAINVILLE" },
  "13138": { state: "NY", city: "POMPEY" },
  "13139": { state: "NY", city: "POPLAR RIDGE" },
  "13140": { state: "NY", city: "PORT BYRON" },
  "13141": { state: "NY", city: "PREBLE" },
  "13142": { state: "NY", city: "PULASKI" },
  "13143": { state: "NY", city: "RED CREEK" },
  "13144": { state: "NY", city: "RICHLAND" },
  "13145": { state: "NY", city: "SANDY CREEK" },
  "13146": { state: "NY", city: "SAVANNAH" },
  "13147": { state: "NY", city: "SCIPIO CENTER" },
  "13148": { state: "NY", city: "SENECA FALLS" },
  "13152": { state: "NY", city: "SKANEATELES" },
  "13153": { state: "NY", city: "SKANEATELES FALLS" },
  "13154": { state: "NY", city: "SOUTH BUTLER" },
  "13155": { state: "NY", city: "SOUTH OTSELIC" },
  "13156": { state: "NY", city: "STERLING" },
  "13157": { state: "NY", city: "SYLVAN BEACH" },
  "13158": { state: "NY", city: "TRUXTON" },
  "13159": { state: "NY", city: "TULLY" },
  "13160": { state: "NY", city: "UNION SPRINGS" },
  "13162": { state: "NY", city: "VERONA BEACH" },
  "13163": { state: "NY", city: "WAMPSVILLE" },
  "13164": { state: "NY", city: "WARNERS" },
  "13165": { state: "NY", city: "WATERLOO" },
  "13166": { state: "NY", city: "WEEDSPORT" },
  "13167": { state: "NY", city: "WEST MONROE" },
  "13201": { state: "NY", city: "SYRACUSE" },
  "13202": { state: "NY", city: "SYRACUSE" },
  "13203": { state: "NY", city: "SYRACUSE" },
  "13204": { state: "NY", city: "SYRACUSE" },
  "13205": { state: "NY", city: "SYRACUSE" },
  "13206": { state: "NY", city: "SYRACUSE" },
  "13207": { state: "NY", city: "SYRACUSE" },
  "13208": { state: "NY", city: "SYRACUSE" },
  "13209": { state: "NY", city: "SYRACUSE" },
  "13210": { state: "NY", city: "SYRACUSE" },
  "13211": { state: "NY", city: "SYRACUSE" },
  "13212": { state: "NY", city: "SYRACUSE" },
  "13214": { state: "NY", city: "SYRACUSE" },
  "13215": { state: "NY", city: "SYRACUSE" },
  "13217": { state: "NY", city: "SYRACUSE" },
  "13218": { state: "NY", city: "SYRACUSE" },
  "13219": { state: "NY", city: "SYRACUSE" },
  "13220": { state: "NY", city: "SYRACUSE" },
  "13221": { state: "NY", city: "SYRACUSE" },
  "13224": { state: "NY", city: "SYRACUSE" },
  "13225": { state: "NY", city: "SYRACUSE" },
  "13235": { state: "NY", city: "SYRACUSE" },
  "13244": { state: "NY", city: "SYRACUSE" },
  "13250": { state: "NY", city: "SYRACUSE" },
  "13251": { state: "NY", city: "SYRACUSE" },
  "13252": { state: "NY", city: "SYRACUSE" },
  "13261": { state: "NY", city: "SYRACUSE" },
  "13290": { state: "NY", city: "SYRACUSE" },
  "13301": { state: "NY", city: "ALDER CREEK" },
  "13302": { state: "NY", city: "ALTMAR" },
  "13303": { state: "NY", city: "AVA" },
  "13304": { state: "NY", city: "BARNEVELD" },
  "13305": { state: "NY", city: "BEAVER FALLS" },
  "13308": { state: "NY", city: "BLOSSVALE" },
  "13309": { state: "NY", city: "BOONVILLE" },
  "13310": { state: "NY", city: "BOUCKVILLE" },
  "13312": { state: "NY", city: "BRANTINGHAM" },
  "13313": { state: "NY", city: "BRIDGEWATER" },
  "13314": { state: "NY", city: "BROOKFIELD" },
  "13315": { state: "NY", city: "BURLINGTON FLATS" },
  "13316": { state: "NY", city: "CAMDEN" },
  "13317": { state: "NY", city: "CANAJOHARIE" },
  "13318": { state: "NY", city: "CASSVILLE" },
  "13319": { state: "NY", city: "CHADWICKS" },
  "13320": { state: "NY", city: "CHERRY VALLEY" },
  "13321": { state: "NY", city: "CLARK MILLS" },
  "13322": { state: "NY", city: "CLAYVILLE" },
  "13323": { state: "NY", city: "CLINTON" },
  "13324": { state: "NY", city: "COLD BROOK" },
  "13325": { state: "NY", city: "CONSTABLEVILLE" },
  "13326": { state: "NY", city: "COOPERSTOWN" },
  "13327": { state: "NY", city: "CROGHAN" },
  "13328": { state: "NY", city: "DEANSBORO" },
  "13329": { state: "NY", city: "DOLGEVILLE" },
  "13331": { state: "NY", city: "EAGLE BAY" },
  "13332": { state: "NY", city: "EARLVILLE" },
  "13333": { state: "NY", city: "EAST SPRINGFIELD" },
  "13334": { state: "NY", city: "EATON" },
  "13335": { state: "NY", city: "EDMESTON" },
  "13337": { state: "NY", city: "FLY CREEK" },
  "13338": { state: "NY", city: "FORESTPORT" },
  "13339": { state: "NY", city: "FORT PLAIN" },
  "13340": { state: "NY", city: "FRANKFORT" },
  "13341": { state: "NY", city: "FRANKLIN SPRINGS" },
  "13342": { state: "NY", city: "GARRATTSVILLE" },
  "13343": { state: "NY", city: "GLENFIELD" },
  "13345": { state: "NY", city: "GREIG" },
  "13346": { state: "NY", city: "HAMILTON" },
  "13348": { state: "NY", city: "HARTWICK" },
  "13350": { state: "NY", city: "HERKIMER" },
  "13352": { state: "NY", city: "HINCKLEY" },
  "13353": { state: "NY", city: "HOFFMEISTER" },
  "13354": { state: "NY", city: "HOLLAND PATENT" },
  "13355": { state: "NY", city: "HUBBARDSVILLE" },
  "13357": { state: "NY", city: "ILION" },
  "13360": { state: "NY", city: "INLET" },
  "13361": { state: "NY", city: "JORDANVILLE" },
  "13362": { state: "NY", city: "KNOXBORO" },
  "13363": { state: "NY", city: "LEE CENTER" },
  "13364": { state: "NY", city: "LEONARDSVILLE" },
  "13365": { state: "NY", city: "LITTLE FALLS" },
  "13367": { state: "NY", city: "LOWVILLE" },
  "13368": { state: "NY", city: "LYONS FALLS" },
  "13401": { state: "NY", city: "MC CONNELLSVILLE" },
  "13402": { state: "NY", city: "MADISON" },
  "13403": { state: "NY", city: "MARCY" },
  "13404": { state: "NY", city: "MARTINSBURG" },
  "13406": { state: "NY", city: "MIDDLEVILLE" },
  "13407": { state: "NY", city: "MOHAWK" },
  "13408": { state: "NY", city: "MORRISVILLE" },
  "13409": { state: "NY", city: "MUNNSVILLE" },
  "13410": { state: "NY", city: "NELLISTON" },
  "13411": { state: "NY", city: "NEW BERLIN" },
  "13413": { state: "NY", city: "NEW HARTFORD" },
  "13415": { state: "NY", city: "NEW LISBON" },
  "13416": { state: "NY", city: "NEWPORT" },
  "13417": { state: "NY", city: "NEW YORK MILLS" },
  "13418": { state: "NY", city: "NORTH BROOKFIELD" },
  "13420": { state: "NY", city: "OLD FORGE" },
  "13421": { state: "NY", city: "ONEIDA" },
  "13424": { state: "NY", city: "ORISKANY" },
  "13425": { state: "NY", city: "ORISKANY FALLS" },
  "13426": { state: "NY", city: "ORWELL" },
  "13428": { state: "NY", city: "PALATINE BRIDGE" },
  "13431": { state: "NY", city: "POLAND" },
  "13433": { state: "NY", city: "PORT LEYDEN" },
  "13435": { state: "NY", city: "PROSPECT" },
  "13436": { state: "NY", city: "RAQUETTE LAKE" },
  "13437": { state: "NY", city: "REDFIELD" },
  "13438": { state: "NY", city: "REMSEN" },
  "13439": { state: "NY", city: "RICHFIELD SPRINGS" },
  "13440": { state: "NY", city: "ROME" },
  "13441": { state: "NY", city: "ROME" },
  "13442": { state: "NY", city: "ROME" },
  "13449": { state: "NY", city: "ROME" },
  "13450": { state: "NY", city: "ROSEBOOM" },
  "13452": { state: "NY", city: "SAINT JOHNSVILLE" },
  "13454": { state: "NY", city: "SALISBURY CENTER" },
  "13455": { state: "NY", city: "SANGERFIELD" },
  "13456": { state: "NY", city: "SAUQUOIT" },
  "13457": { state: "NY", city: "SCHUYLER LAKE" },
  "13459": { state: "NY", city: "SHARON SPRINGS" },
  "13460": { state: "NY", city: "SHERBURNE" },
  "13461": { state: "NY", city: "SHERRILL" },
  "13464": { state: "NY", city: "SMYRNA" },
  "13465": { state: "NY", city: "SOLSVILLE" },
  "13468": { state: "NY", city: "SPRINGFIELD CENTER" },
  "13469": { state: "NY", city: "STITTVILLE" },
  "13470": { state: "NY", city: "STRATFORD" },
  "13471": { state: "NY", city: "TABERG" },
  "13472": { state: "NY", city: "THENDARA" },
  "13473": { state: "NY", city: "TURIN" },
  "13475": { state: "NY", city: "VAN HORNESVILLE" },
  "13476": { state: "NY", city: "VERNON" },
  "13477": { state: "NY", city: "VERNON CENTER" },
  "13478": { state: "NY", city: "VERONA" },
  "13479": { state: "NY", city: "WASHINGTON MILLS" },
  "13480": { state: "NY", city: "WATERVILLE" },
  "13482": { state: "NY", city: "WEST BURLINGTON" },
  "13483": { state: "NY", city: "WESTDALE" },
  "13484": { state: "NY", city: "WEST EATON" },
  "13485": { state: "NY", city: "WEST EDMESTON" },
  "13486": { state: "NY", city: "WESTERNVILLE" },
  "13488": { state: "NY", city: "WESTFORD" },
  "13489": { state: "NY", city: "WEST LEYDEN" },
  "13490": { state: "NY", city: "WESTMORELAND" },
  "13491": { state: "NY", city: "WEST WINFIELD" },
  "13492": { state: "NY", city: "WHITESBORO" },
  "13493": { state: "NY", city: "WILLIAMSTOWN" },
  "13494": { state: "NY", city: "WOODGATE" },
  "13495": { state: "NY", city: "YORKVILLE" },
  "13501": { state: "NY", city: "UTICA" },
  "13502": { state: "NY", city: "UTICA" },
  "13503": { state: "NY", city: "UTICA" },
  "13504": { state: "NY", city: "UTICA" },
  "13505": { state: "NY", city: "UTICA" },
  "13599": { state: "NY", city: "UTICA" },
  "13601": { state: "NY", city: "WATERTOWN" },
  "13602": { state: "NY", city: "FORT DRUM" },
  "13603": { state: "NY", city: "WATERTOWN" },
  "13605": { state: "NY", city: "ADAMS" },
  "13606": { state: "NY", city: "ADAMS CENTER" },
  "13607": { state: "NY", city: "ALEXANDRIA BAY" },
  "13608": { state: "NY", city: "ANTWERP" },
  "13611": { state: "NY", city: "BELLEVILLE" },
  "13612": { state: "NY", city: "BLACK RIVER" },
  "13613": { state: "NY", city: "BRASHER FALLS" },
  "13614": { state: "NY", city: "BRIER HILL" },
  "13615": { state: "NY", city: "BROWNVILLE" },
  "13616": { state: "NY", city: "CALCIUM" },
  "13617": { state: "NY", city: "CANTON" },
  "13618": { state: "NY", city: "CAPE VINCENT" },
  "13619": { state: "NY", city: "CARTHAGE" },
  "13620": { state: "NY", city: "CASTORLAND" },
  "13621": { state: "NY", city: "CHASE MILLS" },
  "13622": { state: "NY", city: "CHAUMONT" },
  "13623": { state: "NY", city: "CHIPPEWA BAY" },
  "13624": { state: "NY", city: "CLAYTON" },
  "13625": { state: "NY", city: "COLTON" },
  "13626": { state: "NY", city: "COPENHAGEN" },
  "13627": { state: "NY", city: "DEER RIVER" },
  "13628": { state: "NY", city: "DEFERIET" },
  "13630": { state: "NY", city: "DE KALB JUNCTION" },
  "13631": { state: "NY", city: "DENMARK" },
  "13632": { state: "NY", city: "DEPAUVILLE" },
  "13633": { state: "NY", city: "DE PEYSTER" },
  "13634": { state: "NY", city: "DEXTER" },
  "13635": { state: "NY", city: "EDWARDS" },
  "13636": { state: "NY", city: "ELLISBURG" },
  "13637": { state: "NY", city: "EVANS MILLS" },
  "13638": { state: "NY", city: "FELTS MILLS" },
  "13639": { state: "NY", city: "FINE" },
  "13640": { state: "NY", city: "WELLESLEY ISLAND" },
  "13641": { state: "NY", city: "FISHERS LANDING" },
  "13642": { state: "NY", city: "GOUVERNEUR" },
  "13643": { state: "NY", city: "GREAT BEND" },
  "13645": { state: "NY", city: "HAILESBORO" },
  "13646": { state: "NY", city: "HAMMOND" },
  "13647": { state: "NY", city: "HANNAWA FALLS" },
  "13648": { state: "NY", city: "HARRISVILLE" },
  "13649": { state: "NY", city: "HELENA" },
  "13650": { state: "NY", city: "HENDERSON" },
  "13651": { state: "NY", city: "HENDERSON HARBOR" },
  "13652": { state: "NY", city: "HERMON" },
  "13654": { state: "NY", city: "HEUVELTON" },
  "13655": { state: "NY", city: "HOGANSBURG" },
  "13656": { state: "NY", city: "LA FARGEVILLE" },
  "13657": { state: "NY", city: "LIMERICK" },
  "13658": { state: "NY", city: "LISBON" },
  "13659": { state: "NY", city: "LORRAINE" },
  "13660": { state: "NY", city: "MADRID" },
  "13661": { state: "NY", city: "MANNSVILLE" },
  "13662": { state: "NY", city: "MASSENA" },
  "13664": { state: "NY", city: "MORRISTOWN" },
  "13665": { state: "NY", city: "NATURAL BRIDGE" },
  "13666": { state: "NY", city: "NEWTON FALLS" },
  "13667": { state: "NY", city: "NORFOLK" },
  "13668": { state: "NY", city: "NORWOOD" },
  "13669": { state: "NY", city: "OGDENSBURG" },
  "13670": { state: "NY", city: "OSWEGATCHIE" },
  "13671": { state: "NY", city: "ANTWERP" },
  "13672": { state: "NY", city: "PARISHVILLE" },
  "13673": { state: "NY", city: "PHILADELPHIA" },
  "13674": { state: "NY", city: "PIERREPONT MANOR" },
  "13675": { state: "NY", city: "PLESSIS" },
  "13676": { state: "NY", city: "POTSDAM" },
  "13677": { state: "NY", city: "PYRITES" },
  "13678": { state: "NY", city: "RAYMONDVILLE" },
  "13679": { state: "NY", city: "REDWOOD" },
  "13680": { state: "NY", city: "RENSSELAER FALLS" },
  "13681": { state: "NY", city: "RICHVILLE" },
  "13682": { state: "NY", city: "RODMAN" },
  "13683": { state: "NY", city: "ROOSEVELTOWN" },
  "13684": { state: "NY", city: "RUSSELL" },
  "13685": { state: "NY", city: "SACKETS HARBOR" },
  "13687": { state: "NY", city: "SOUTH COLTON" },
  "13690": { state: "NY", city: "STAR LAKE" },
  "13691": { state: "NY", city: "THERESA" },
  "13692": { state: "NY", city: "THOUSAND ISLAND PARK" },
  "13693": { state: "NY", city: "THREE MILE BAY" },
  "13694": { state: "NY", city: "WADDINGTON" },
  "13695": { state: "NY", city: "WANAKENA" },
  "13696": { state: "NY", city: "WEST STOCKHOLM" },
  "13697": { state: "NY", city: "WINTHROP" },
  "13699": { state: "NY", city: "POTSDAM" },
  "13730": { state: "NY", city: "AFTON" },
  "13731": { state: "NY", city: "ANDES" },
  "13732": { state: "NY", city: "APALACHIN" },
  "13733": { state: "NY", city: "BAINBRIDGE" },
  "13734": { state: "NY", city: "BARTON" },
  "13736": { state: "NY", city: "BERKSHIRE" },
  "13737": { state: "NY", city: "BIBLE SCHOOL PARK" },
  "13738": { state: "NY", city: "BLODGETT MILLS" },
  "13739": { state: "NY", city: "BLOOMVILLE" },
  "13740": { state: "NY", city: "BOVINA CENTER" },
  "13743": { state: "NY", city: "CANDOR" },
  "13744": { state: "NY", city: "CASTLE CREEK" },
  "13745": { state: "NY", city: "CHENANGO BRIDGE" },
  "13746": { state: "NY", city: "CHENANGO FORKS" },
  "13747": { state: "NY", city: "COLLIERSVILLE" },
  "13748": { state: "NY", city: "CONKLIN" },
  "13749": { state: "NY", city: "CORBETTSVILLE" },
  "13750": { state: "NY", city: "DAVENPORT" },
  "13751": { state: "NY", city: "DAVENPORT CENTER" },
  "13752": { state: "NY", city: "DELANCEY" },
  "13753": { state: "NY", city: "DELHI" },
  "13754": { state: "NY", city: "DEPOSIT" },
  "13755": { state: "NY", city: "DOWNSVILLE" },
  "13756": { state: "NY", city: "EAST BRANCH" },
  "13757": { state: "NY", city: "EAST MEREDITH" },
  "13758": { state: "NY", city: "EAST PHARSALIA" },
  "13760": { state: "NY", city: "ENDICOTT" },
  "13761": { state: "NY", city: "ENDICOTT" },
  "13762": { state: "NY", city: "ENDWELL" },
  "13763": { state: "NY", city: "ENDICOTT" },
  "13774": { state: "NY", city: "FISHS EDDY" },
  "13775": { state: "NY", city: "FRANKLIN" },
  "13776": { state: "NY", city: "GILBERTSVILLE" },
  "13777": { state: "NY", city: "GLEN AUBREY" },
  "13778": { state: "NY", city: "GREENE" },
  "13780": { state: "NY", city: "GUILFORD" },
  "13782": { state: "NY", city: "HAMDEN" },
  "13783": { state: "NY", city: "HANCOCK" },
  "13784": { state: "NY", city: "HARFORD" },
  "13786": { state: "NY", city: "HARPERSFIELD" },
  "13787": { state: "NY", city: "HARPURSVILLE" },
  "13788": { state: "NY", city: "HOBART" },
  "13790": { state: "NY", city: "JOHNSON CITY" },
  "13794": { state: "NY", city: "KILLAWOG" },
  "13795": { state: "NY", city: "KIRKWOOD" },
  "13796": { state: "NY", city: "LAURENS" },
  "13797": { state: "NY", city: "LISLE" },
  "13801": { state: "NY", city: "MC DONOUGH" },
  "13802": { state: "NY", city: "MAINE" },
  "13803": { state: "NY", city: "MARATHON" },
  "13804": { state: "NY", city: "MASONVILLE" },
  "13806": { state: "NY", city: "MERIDALE" },
  "13807": { state: "NY", city: "MILFORD" },
  "13808": { state: "NY", city: "MORRIS" },
  "13809": { state: "NY", city: "MOUNT UPTON" },
  "13810": { state: "NY", city: "MOUNT VISION" },
  "13811": { state: "NY", city: "NEWARK VALLEY" },
  "13812": { state: "NY", city: "NICHOLS" },
  "13813": { state: "NY", city: "NINEVEH" },
  "13814": { state: "NY", city: "NORTH NORWICH" },
  "13815": { state: "NY", city: "NORWICH" },
  "13820": { state: "NY", city: "ONEONTA" },
  "13825": { state: "NY", city: "OTEGO" },
  "13826": { state: "NY", city: "OUAQUAGA" },
  "13827": { state: "NY", city: "OWEGO" },
  "13830": { state: "NY", city: "OXFORD" },
  "13832": { state: "NY", city: "PLYMOUTH" },
  "13833": { state: "NY", city: "PORT CRANE" },
  "13834": { state: "NY", city: "PORTLANDVILLE" },
  "13835": { state: "NY", city: "RICHFORD" },
  "13838": { state: "NY", city: "SIDNEY" },
  "13839": { state: "NY", city: "SIDNEY CENTER" },
  "13840": { state: "NY", city: "SMITHBORO" },
  "13841": { state: "NY", city: "SMITHVILLE FLATS" },
  "13842": { state: "NY", city: "SOUTH KORTRIGHT" },
  "13843": { state: "NY", city: "SOUTH NEW BERLIN" },
  "13844": { state: "NY", city: "SOUTH PLYMOUTH" },
  "13845": { state: "NY", city: "TIOGA CENTER" },
  "13846": { state: "NY", city: "TREADWELL" },
  "13847": { state: "NY", city: "TROUT CREEK" },
  "13848": { state: "NY", city: "TUNNEL" },
  "13849": { state: "NY", city: "UNADILLA" },
  "13850": { state: "NY", city: "VESTAL" },
  "13851": { state: "NY", city: "VESTAL" },
  "13856": { state: "NY", city: "WALTON" },
  "13859": { state: "NY", city: "WELLS BRIDGE" },
  "13860": { state: "NY", city: "WEST DAVENPORT" },
  "13861": { state: "NY", city: "WEST ONEONTA" },
  "13862": { state: "NY", city: "WHITNEY POINT" },
  "13863": { state: "NY", city: "WILLET" },
  "13864": { state: "NY", city: "WILLSEYVILLE" },
  "13865": { state: "NY", city: "WINDSOR" },
  "13901": { state: "NY", city: "BINGHAMTON" },
  "13902": { state: "NY", city: "BINGHAMTON" },
  "13903": { state: "NY", city: "BINGHAMTON" },
  "13904": { state: "NY", city: "BINGHAMTON" },
  "13905": { state: "NY", city: "BINGHAMTON" },
  "14001": { state: "NY", city: "AKRON" },
  "14004": { state: "NY", city: "ALDEN" },
  "14005": { state: "NY", city: "ALEXANDER" },
  "14006": { state: "NY", city: "ANGOLA" },
  "14008": { state: "NY", city: "APPLETON" },
  "14009": { state: "NY", city: "ARCADE" },
  "14010": { state: "NY", city: "ATHOL SPRINGS" },
  "14011": { state: "NY", city: "ATTICA" },
  "14012": { state: "NY", city: "BARKER" },
  "14013": { state: "NY", city: "BASOM" },
  "14020": { state: "NY", city: "BATAVIA" },
  "14021": { state: "NY", city: "BATAVIA" },
  "14024": { state: "NY", city: "BLISS" },
  "14025": { state: "NY", city: "BOSTON" },
  "14026": { state: "NY", city: "BOWMANSVILLE" },
  "14027": { state: "NY", city: "BRANT" },
  "14028": { state: "NY", city: "BURT" },
  "14029": { state: "NY", city: "CENTERVILLE" },
  "14030": { state: "NY", city: "CHAFFEE" },
  "14031": { state: "NY", city: "CLARENCE" },
  "14032": { state: "NY", city: "CLARENCE CENTER" },
  "14033": { state: "NY", city: "COLDEN" },
  "14034": { state: "NY", city: "COLLINS" },
  "14035": { state: "NY", city: "COLLINS CENTER" },
  "14036": { state: "NY", city: "CORFU" },
  "14037": { state: "NY", city: "COWLESVILLE" },
  "14038": { state: "NY", city: "CRITTENDEN" },
  "14039": { state: "NY", city: "DALE" },
  "14040": { state: "NY", city: "DARIEN CENTER" },
  "14041": { state: "NY", city: "DAYTON" },
  "14042": { state: "NY", city: "DELEVAN" },
  "14043": { state: "NY", city: "DEPEW" },
  "14047": { state: "NY", city: "DERBY" },
  "14048": { state: "NY", city: "DUNKIRK" },
  "14051": { state: "NY", city: "EAST AMHERST" },
  "14052": { state: "NY", city: "EAST AURORA" },
  "14054": { state: "NY", city: "EAST BETHANY" },
  "14055": { state: "NY", city: "EAST CONCORD" },
  "14056": { state: "NY", city: "EAST PEMBROKE" },
  "14057": { state: "NY", city: "EDEN" },
  "14058": { state: "NY", city: "ELBA" },
  "14059": { state: "NY", city: "ELMA" },
  "14060": { state: "NY", city: "FARMERSVILLE STATION" },
  "14061": { state: "NY", city: "FARNHAM" },
  "14062": { state: "NY", city: "FORESTVILLE" },
  "14063": { state: "NY", city: "FREDONIA" },
  "14065": { state: "NY", city: "FREEDOM" },
  "14066": { state: "NY", city: "GAINESVILLE" },
  "14067": { state: "NY", city: "GASPORT" },
  "14068": { state: "NY", city: "GETZVILLE" },
  "14069": { state: "NY", city: "GLENWOOD" },
  "14070": { state: "NY", city: "GOWANDA" },
  "14072": { state: "NY", city: "GRAND ISLAND" },
  "14075": { state: "NY", city: "HAMBURG" },
  "14080": { state: "NY", city: "HOLLAND" },
  "14081": { state: "NY", city: "IRVING" },
  "14082": { state: "NY", city: "JAVA CENTER" },
  "14083": { state: "NY", city: "JAVA VILLAGE" },
  "14085": { state: "NY", city: "LAKE VIEW" },
  "14086": { state: "NY", city: "LANCASTER" },
  "14091": { state: "NY", city: "LAWTONS" },
  "14092": { state: "NY", city: "LEWISTON" },
  "14094": { state: "NY", city: "LOCKPORT" },
  "14095": { state: "NY", city: "LOCKPORT" },
  "14098": { state: "NY", city: "LYNDONVILLE" },
  "14101": { state: "NY", city: "MACHIAS" },
  "14102": { state: "NY", city: "MARILLA" },
  "14103": { state: "NY", city: "MEDINA" },
  "14105": { state: "NY", city: "MIDDLEPORT" },
  "14107": { state: "NY", city: "MODEL CITY" },
  "14108": { state: "NY", city: "NEWFANE" },
  "14109": { state: "NY", city: "NIAGARA UNIVERSITY" },
  "14110": { state: "NY", city: "NORTH BOSTON" },
  "14111": { state: "NY", city: "NORTH COLLINS" },
  "14112": { state: "NY", city: "NORTH EVANS" },
  "14113": { state: "NY", city: "NORTH JAVA" },
  "14120": { state: "NY", city: "NORTH TONAWANDA" },
  "14125": { state: "NY", city: "OAKFIELD" },
  "14126": { state: "NY", city: "OLCOTT" },
  "14127": { state: "NY", city: "ORCHARD PARK" },
  "14129": { state: "NY", city: "PERRYSBURG" },
  "14130": { state: "NY", city: "PIKE" },
  "14131": { state: "NY", city: "RANSOMVILLE" },
  "14132": { state: "NY", city: "SANBORN" },
  "14133": { state: "NY", city: "SANDUSKY" },
  "14134": { state: "NY", city: "SARDINIA" },
  "14135": { state: "NY", city: "SHERIDAN" },
  "14136": { state: "NY", city: "SILVER CREEK" },
  "14138": { state: "NY", city: "SOUTH DAYTON" },
  "14139": { state: "NY", city: "SOUTH WALES" },
  "14140": { state: "NY", city: "SPRING BROOK" },
  "14141": { state: "NY", city: "SPRINGVILLE" },
  "14143": { state: "NY", city: "STAFFORD" },
  "14144": { state: "NY", city: "STELLA NIAGARA" },
  "14145": { state: "NY", city: "STRYKERSVILLE" },
  "14150": { state: "NY", city: "TONAWANDA" },
  "14151": { state: "NY", city: "TONAWANDA" },
  "14166": { state: "NY", city: "VAN BUREN POINT" },
  "14167": { state: "NY", city: "VARYSBURG" },
  "14168": { state: "NY", city: "VERSAILLES" },
  "14169": { state: "NY", city: "WALES CENTER" },
  "14170": { state: "NY", city: "WEST FALLS" },
  "14171": { state: "NY", city: "WEST VALLEY" },
  "14172": { state: "NY", city: "WILSON" },
  "14173": { state: "NY", city: "YORKSHIRE" },
  "14174": { state: "NY", city: "YOUNGSTOWN" },
  "14201": { state: "NY", city: "BUFFALO" },
  "14202": { state: "NY", city: "BUFFALO" },
  "14203": { state: "NY", city: "BUFFALO" },
  "14204": { state: "NY", city: "BUFFALO" },
  "14205": { state: "NY", city: "BUFFALO" },
  "14206": { state: "NY", city: "BUFFALO" },
  "14207": { state: "NY", city: "BUFFALO" },
  "14208": { state: "NY", city: "BUFFALO" },
  "14209": { state: "NY", city: "BUFFALO" },
  "14210": { state: "NY", city: "BUFFALO" },
  "14211": { state: "NY", city: "BUFFALO" },
  "14212": { state: "NY", city: "BUFFALO" },
  "14213": { state: "NY", city: "BUFFALO" },
  "14214": { state: "NY", city: "BUFFALO" },
  "14215": { state: "NY", city: "BUFFALO" },
  "14216": { state: "NY", city: "BUFFALO" },
  "14217": { state: "NY", city: "BUFFALO" },
  "14218": { state: "NY", city: "BUFFALO" },
  "14219": { state: "NY", city: "BUFFALO" },
  "14220": { state: "NY", city: "BUFFALO" },
  "14221": { state: "NY", city: "BUFFALO" },
  "14222": { state: "NY", city: "BUFFALO" },
  "14223": { state: "NY", city: "BUFFALO" },
  "14224": { state: "NY", city: "BUFFALO" },
  "14225": { state: "NY", city: "BUFFALO" },
  "14226": { state: "NY", city: "BUFFALO" },
  "14227": { state: "NY", city: "BUFFALO" },
  "14228": { state: "NY", city: "BUFFALO" },
  "14231": { state: "NY", city: "BUFFALO" },
  "14233": { state: "NY", city: "BUFFALO" },
  "14240": { state: "NY", city: "BUFFALO" },
  "14241": { state: "NY", city: "BUFFALO" },
  "14260": { state: "NY", city: "BUFFALO" },
  "14261": { state: "NY", city: "BUFFALO" },
  "14263": { state: "NY", city: "BUFFALO" },
  "14264": { state: "NY", city: "BUFFALO" },
  "14265": { state: "NY", city: "BUFFALO" },
  "14267": { state: "NY", city: "BUFFALO" },
  "14269": { state: "NY", city: "BUFFALO" },
  "14270": { state: "NY", city: "BUFFALO" },
  "14272": { state: "NY", city: "BUFFALO" },
  "14273": { state: "NY", city: "BUFFALO" },
  "14276": { state: "NY", city: "BUFFALO" },
  "14280": { state: "NY", city: "BUFFALO" },
  "14301": { state: "NY", city: "NIAGARA FALLS" },
  "14302": { state: "NY", city: "NIAGARA FALLS" },
  "14303": { state: "NY", city: "NIAGARA FALLS" },
  "14304": { state: "NY", city: "NIAGARA FALLS" },
  "14305": { state: "NY", city: "NIAGARA FALLS" },
  "14410": { state: "NY", city: "ADAMS BASIN" },
  "14411": { state: "NY", city: "ALBION" },
  "14413": { state: "NY", city: "ALTON" },
  "14414": { state: "NY", city: "AVON" },
  "14415": { state: "NY", city: "BELLONA" },
  "14416": { state: "NY", city: "BERGEN" },
  "14418": { state: "NY", city: "BRANCHPORT" },
  "14420": { state: "NY", city: "BROCKPORT" },
  "14422": { state: "NY", city: "BYRON" },
  "14423": { state: "NY", city: "CALEDONIA" },
  "14424": { state: "NY", city: "CANANDAIGUA" },
  "14425": { state: "NY", city: "FARMINGTON" },
  "14427": { state: "NY", city: "CASTILE" },
  "14428": { state: "NY", city: "CHURCHVILLE" },
  "14429": { state: "NY", city: "CLARENDON" },
  "14430": { state: "NY", city: "CLARKSON" },
  "14432": { state: "NY", city: "CLIFTON SPRINGS" },
  "14433": { state: "NY", city: "CLYDE" },
  "14435": { state: "NY", city: "CONESUS" },
  "14437": { state: "NY", city: "DANSVILLE" },
  "14441": { state: "NY", city: "DRESDEN" },
  "14443": { state: "NY", city: "EAST BLOOMFIELD" },
  "14445": { state: "NY", city: "EAST ROCHESTER" },
  "14449": { state: "NY", city: "EAST WILLIAMSON" },
  "14450": { state: "NY", city: "FAIRPORT" },
  "14452": { state: "NY", city: "FANCHER" },
  "14453": { state: "NY", city: "FISHERS" },
  "14454": { state: "NY", city: "GENESEO" },
  "14456": { state: "NY", city: "GENEVA" },
  "14461": { state: "NY", city: "GORHAM" },
  "14462": { state: "NY", city: "GROVELAND" },
  "14463": { state: "NY", city: "HALL" },
  "14464": { state: "NY", city: "HAMLIN" },
  "14466": { state: "NY", city: "HEMLOCK" },
  "14467": { state: "NY", city: "HENRIETTA" },
  "14468": { state: "NY", city: "HILTON" },
  "14469": { state: "NY", city: "BLOOMFIELD" },
  "14470": { state: "NY", city: "HOLLEY" },
  "14471": { state: "NY", city: "HONEOYE" },
  "14472": { state: "NY", city: "HONEOYE FALLS" },
  "14475": { state: "NY", city: "IONIA" },
  "14476": { state: "NY", city: "KENDALL" },
  "14477": { state: "NY", city: "KENT" },
  "14478": { state: "NY", city: "KEUKA PARK" },
  "14479": { state: "NY", city: "KNOWLESVILLE" },
  "14480": { state: "NY", city: "LAKEVILLE" },
  "14481": { state: "NY", city: "LEICESTER" },
  "14482": { state: "NY", city: "LE ROY" },
  "14485": { state: "NY", city: "LIMA" },
  "14486": { state: "NY", city: "LINWOOD" },
  "14487": { state: "NY", city: "LIVONIA" },
  "14488": { state: "NY", city: "LIVONIA CENTER" },
  "14489": { state: "NY", city: "LYONS" },
  "14502": { state: "NY", city: "MACEDON" },
  "14504": { state: "NY", city: "MANCHESTER" },
  "14505": { state: "NY", city: "MARION" },
  "14506": { state: "NY", city: "MENDON" },
  "14507": { state: "NY", city: "MIDDLESEX" },
  "14508": { state: "NY", city: "MORTON" },
  "14510": { state: "NY", city: "MOUNT MORRIS" },
  "14511": { state: "NY", city: "MUMFORD" },
  "14512": { state: "NY", city: "NAPLES" },
  "14513": { state: "NY", city: "NEWARK" },
  "14514": { state: "NY", city: "NORTH CHILI" },
  "14515": { state: "NY", city: "NORTH GREECE" },
  "14516": { state: "NY", city: "NORTH ROSE" },
  "14517": { state: "NY", city: "NUNDA" },
  "14518": { state: "NY", city: "OAKS CORNERS" },
  "14519": { state: "NY", city: "ONTARIO" },
  "14520": { state: "NY", city: "ONTARIO CENTER" },
  "14521": { state: "NY", city: "OVID" },
  "14522": { state: "NY", city: "PALMYRA" },
  "14525": { state: "NY", city: "PAVILION" },
  "14526": { state: "NY", city: "PENFIELD" },
  "14527": { state: "NY", city: "PENN YAN" },
  "14529": { state: "NY", city: "PERKINSVILLE" },
  "14530": { state: "NY", city: "PERRY" },
  "14532": { state: "NY", city: "PHELPS" },
  "14533": { state: "NY", city: "PIFFARD" },
  "14534": { state: "NY", city: "PITTSFORD" },
  "14536": { state: "NY", city: "PORTAGEVILLE" },
  "14537": { state: "NY", city: "PORT GIBSON" },
  "14538": { state: "NY", city: "PULTNEYVILLE" },
  "14539": { state: "NY", city: "RETSOF" },
  "14541": { state: "NY", city: "ROMULUS" },
  "14542": { state: "NY", city: "ROSE" },
  "14543": { state: "NY", city: "RUSH" },
  "14544": { state: "NY", city: "RUSHVILLE" },
  "14545": { state: "NY", city: "SCOTTSBURG" },
  "14546": { state: "NY", city: "SCOTTSVILLE" },
  "14547": { state: "NY", city: "SENECA CASTLE" },
  "14548": { state: "NY", city: "SHORTSVILLE" },
  "14549": { state: "NY", city: "SILVER LAKE" },
  "14550": { state: "NY", city: "SILVER SPRINGS" },
  "14551": { state: "NY", city: "SODUS" },
  "14555": { state: "NY", city: "SODUS POINT" },
  "14556": { state: "NY", city: "SONYEA" },
  "14557": { state: "NY", city: "SOUTH BYRON" },
  "14558": { state: "NY", city: "SOUTH LIMA" },
  "14559": { state: "NY", city: "SPENCERPORT" },
  "14560": { state: "NY", city: "SPRINGWATER" },
  "14561": { state: "NY", city: "STANLEY" },
  "14563": { state: "NY", city: "UNION HILL" },
  "14564": { state: "NY", city: "VICTOR" },
  "14568": { state: "NY", city: "WALWORTH" },
  "14569": { state: "NY", city: "WARSAW" },
  "14571": { state: "NY", city: "WATERPORT" },
  "14572": { state: "NY", city: "WAYLAND" },
  "14580": { state: "NY", city: "WEBSTER" },
  "14585": { state: "NY", city: "WEST BLOOMFIELD" },
  "14586": { state: "NY", city: "WEST HENRIETTA" },
  "14588": { state: "NY", city: "WILLARD" },
  "14589": { state: "NY", city: "WILLIAMSON" },
  "14590": { state: "NY", city: "WOLCOTT" },
  "14591": { state: "NY", city: "WYOMING" },
  "14592": { state: "NY", city: "YORK" },
  "14602": { state: "NY", city: "ROCHESTER" },
  "14603": { state: "NY", city: "ROCHESTER" },
  "14604": { state: "NY", city: "ROCHESTER" },
  "14605": { state: "NY", city: "ROCHESTER" },
  "14606": { state: "NY", city: "ROCHESTER" },
  "14607": { state: "NY", city: "ROCHESTER" },
  "14608": { state: "NY", city: "ROCHESTER" },
  "14609": { state: "NY", city: "ROCHESTER" },
  "14610": { state: "NY", city: "ROCHESTER" },
  "14611": { state: "NY", city: "ROCHESTER" },
  "14612": { state: "NY", city: "ROCHESTER" },
  "14613": { state: "NY", city: "ROCHESTER" },
  "14614": { state: "NY", city: "ROCHESTER" },
  "14615": { state: "NY", city: "ROCHESTER" },
  "14616": { state: "NY", city: "ROCHESTER" },
  "14617": { state: "NY", city: "ROCHESTER" },
  "14618": { state: "NY", city: "ROCHESTER" },
  "14619": { state: "NY", city: "ROCHESTER" },
  "14620": { state: "NY", city: "ROCHESTER" },
  "14621": { state: "NY", city: "ROCHESTER" },
  "14622": { state: "NY", city: "ROCHESTER" },
  "14623": { state: "NY", city: "ROCHESTER" },
  "14624": { state: "NY", city: "ROCHESTER" },
  "14625": { state: "NY", city: "ROCHESTER" },
  "14626": { state: "NY", city: "ROCHESTER" },
  "14627": { state: "NY", city: "ROCHESTER" },
  "14638": { state: "NY", city: "ROCHESTER" },
  "14639": { state: "NY", city: "ROCHESTER" },
  "14642": { state: "NY", city: "ROCHESTER" },
  "14643": { state: "NY", city: "ROCHESTER" },
  "14644": { state: "NY", city: "ROCHESTER" },
  "14646": { state: "NY", city: "ROCHESTER" },
  "14647": { state: "NY", city: "ROCHESTER" },
  "14649": { state: "NY", city: "ROCHESTER" },
  "14650": { state: "NY", city: "ROCHESTER" },
  "14651": { state: "NY", city: "ROCHESTER" },
  "14652": { state: "NY", city: "ROCHESTER" },
  "14653": { state: "NY", city: "ROCHESTER" },
  "14692": { state: "NY", city: "ROCHESTER" },
  "14694": { state: "NY", city: "ROCHESTER" },
  "14701": { state: "NY", city: "JAMESTOWN" },
  "14702": { state: "NY", city: "JAMESTOWN" },
  "14706": { state: "NY", city: "ALLEGANY" },
  "14707": { state: "NY", city: "ALLENTOWN" },
  "14708": { state: "NY", city: "ALMA" },
  "14709": { state: "NY", city: "ANGELICA" },
  "14710": { state: "NY", city: "ASHVILLE" },
  "14711": { state: "NY", city: "BELFAST" },
  "14712": { state: "NY", city: "BEMUS POINT" },
  "14714": { state: "NY", city: "BLACK CREEK" },
  "14715": { state: "NY", city: "BOLIVAR" },
  "14716": { state: "NY", city: "BROCTON" },
  "14717": { state: "NY", city: "CANEADEA" },
  "14718": { state: "NY", city: "CASSADAGA" },
  "14719": { state: "NY", city: "CATTARAUGUS" },
  "14720": { state: "NY", city: "CELORON" },
  "14721": { state: "NY", city: "CERES" },
  "14722": { state: "NY", city: "CHAUTAUQUA" },
  "14723": { state: "NY", city: "CHERRY CREEK" },
  "14724": { state: "NY", city: "CLYMER" },
  "14726": { state: "NY", city: "CONEWANGO VALLEY" },
  "14727": { state: "NY", city: "CUBA" },
  "14728": { state: "NY", city: "DEWITTVILLE" },
  "14729": { state: "NY", city: "EAST OTTO" },
  "14730": { state: "NY", city: "EAST RANDOLPH" },
  "14731": { state: "NY", city: "ELLICOTTVILLE" },
  "14732": { state: "NY", city: "ELLINGTON" },
  "14733": { state: "NY", city: "FALCONER" },
  "14735": { state: "NY", city: "FILLMORE" },
  "14736": { state: "NY", city: "FINDLEY LAKE" },
  "14737": { state: "NY", city: "FRANKLINVILLE" },
  "14738": { state: "NY", city: "FREWSBURG" },
  "14739": { state: "NY", city: "FRIENDSHIP" },
  "14740": { state: "NY", city: "GERRY" },
  "14741": { state: "NY", city: "GREAT VALLEY" },
  "14742": { state: "NY", city: "GREENHURST" },
  "14743": { state: "NY", city: "HINSDALE" },
  "14744": { state: "NY", city: "HOUGHTON" },
  "14745": { state: "NY", city: "HUME" },
  "14747": { state: "NY", city: "KENNEDY" },
  "14748": { state: "NY", city: "KILL BUCK" },
  "14750": { state: "NY", city: "LAKEWOOD" },
  "14751": { state: "NY", city: "LEON" },
  "14752": { state: "NY", city: "LILY DALE" },
  "14753": { state: "NY", city: "LIMESTONE" },
  "14754": { state: "NY", city: "LITTLE GENESEE" },
  "14755": { state: "NY", city: "LITTLE VALLEY" },
  "14756": { state: "NY", city: "MAPLE SPRINGS" },
  "14757": { state: "NY", city: "MAYVILLE" },
  "14758": { state: "NY", city: "NIOBE" },
  "14760": { state: "NY", city: "OLEAN" },
  "14766": { state: "NY", city: "OTTO" },
  "14767": { state: "NY", city: "PANAMA" },
  "14769": { state: "NY", city: "PORTLAND" },
  "14770": { state: "NY", city: "PORTVILLE" },
  "14772": { state: "NY", city: "RANDOLPH" },
  "14774": { state: "NY", city: "RICHBURG" },
  "14775": { state: "NY", city: "RIPLEY" },
  "14777": { state: "NY", city: "RUSHFORD" },
  "14778": { state: "NY", city: "SAINT BONAVENTURE" },
  "14779": { state: "NY", city: "SALAMANCA" },
  "14781": { state: "NY", city: "SHERMAN" },
  "14782": { state: "NY", city: "SINCLAIRVILLE" },
  "14783": { state: "NY", city: "STEAMBURG" },
  "14784": { state: "NY", city: "STOCKTON" },
  "14785": { state: "NY", city: "STOW" },
  "14786": { state: "NY", city: "WEST CLARKSVILLE" },
  "14787": { state: "NY", city: "WESTFIELD" },
  "14788": { state: "NY", city: "WESTONS MILLS" },
  "14801": { state: "NY", city: "ADDISON" },
  "14802": { state: "NY", city: "ALFRED" },
  "14803": { state: "NY", city: "ALFRED STATION" },
  "14804": { state: "NY", city: "ALMOND" },
  "14805": { state: "NY", city: "ALPINE" },
  "14806": { state: "NY", city: "ANDOVER" },
  "14807": { state: "NY", city: "ARKPORT" },
  "14808": { state: "NY", city: "ATLANTA" },
  "14809": { state: "NY", city: "AVOCA" },
  "14810": { state: "NY", city: "BATH" },
  "14812": { state: "NY", city: "BEAVER DAMS" },
  "14813": { state: "NY", city: "BELMONT" },
  "14814": { state: "NY", city: "BIG FLATS" },
  "14815": { state: "NY", city: "BRADFORD" },
  "14816": { state: "NY", city: "BREESPORT" },
  "14817": { state: "NY", city: "BROOKTONDALE" },
  "14818": { state: "NY", city: "BURDETT" },
  "14819": { state: "NY", city: "CAMERON" },
  "14820": { state: "NY", city: "CAMERON MILLS" },
  "14821": { state: "NY", city: "CAMPBELL" },
  "14822": { state: "NY", city: "CANASERAGA" },
  "14823": { state: "NY", city: "CANISTEO" },
  "14824": { state: "NY", city: "CAYUTA" },
  "14825": { state: "NY", city: "CHEMUNG" },
  "14826": { state: "NY", city: "COHOCTON" },
  "14827": { state: "NY", city: "COOPERS PLAINS" },
  "14830": { state: "NY", city: "CORNING" },
  "14831": { state: "NY", city: "CORNING" },
  "14836": { state: "NY", city: "DALTON" },
  "14837": { state: "NY", city: "DUNDEE" },
  "14838": { state: "NY", city: "ERIN" },
  "14839": { state: "NY", city: "GREENWOOD" },
  "14840": { state: "NY", city: "HAMMONDSPORT" },
  "14841": { state: "NY", city: "HECTOR" },
  "14842": { state: "NY", city: "HIMROD" },
  "14843": { state: "NY", city: "HORNELL" },
  "14845": { state: "NY", city: "HORSEHEADS" },
  "14846": { state: "NY", city: "HUNT" },
  "14847": { state: "NY", city: "INTERLAKEN" },
  "14850": { state: "NY", city: "ITHACA" },
  "14851": { state: "NY", city: "ITHACA" },
  "14852": { state: "NY", city: "ITHACA" },
  "14853": { state: "NY", city: "ITHACA" },
  "14854": { state: "NY", city: "JACKSONVILLE" },
  "14855": { state: "NY", city: "JASPER" },
  "14856": { state: "NY", city: "KANONA" },
  "14857": { state: "NY", city: "LAKEMONT" },
  "14858": { state: "NY", city: "LINDLEY" },
  "14859": { state: "NY", city: "LOCKWOOD" },
  "14860": { state: "NY", city: "LODI" },
  "14861": { state: "NY", city: "LOWMAN" },
  "14863": { state: "NY", city: "MECKLENBURG" },
  "14864": { state: "NY", city: "MILLPORT" },
  "14865": { state: "NY", city: "MONTOUR FALLS" },
  "14867": { state: "NY", city: "NEWFIELD" },
  "14869": { state: "NY", city: "ODESSA" },
  "14870": { state: "NY", city: "PAINTED POST" },
  "14871": { state: "NY", city: "PINE CITY" },
  "14872": { state: "NY", city: "PINE VALLEY" },
  "14873": { state: "NY", city: "PRATTSBURGH" },
  "14874": { state: "NY", city: "PULTENEY" },
  "14876": { state: "NY", city: "READING CENTER" },
  "14877": { state: "NY", city: "REXVILLE" },
  "14878": { state: "NY", city: "ROCK STREAM" },
  "14879": { state: "NY", city: "SAVONA" },
  "14880": { state: "NY", city: "SCIO" },
  "14881": { state: "NY", city: "SLATERVILLE SPRINGS" },
  "14882": { state: "NY", city: "LANSING" },
  "14883": { state: "NY", city: "SPENCER" },
  "14884": { state: "NY", city: "SWAIN" },
  "14885": { state: "NY", city: "TROUPSBURG" },
  "14886": { state: "NY", city: "TRUMANSBURG" },
  "14887": { state: "NY", city: "TYRONE" },
  "14889": { state: "NY", city: "VAN ETTEN" },
  "14891": { state: "NY", city: "WATKINS GLEN" },
  "14892": { state: "NY", city: "WAVERLY" },
  "14893": { state: "NY", city: "WAYNE" },
  "14894": { state: "NY", city: "WELLSBURG" },
  "14895": { state: "NY", city: "WELLSVILLE" },
  "14897": { state: "NY", city: "WHITESVILLE" },
  "14898": { state: "NY", city: "WOODHULL" },
  "14901": { state: "NY", city: "ELMIRA" },
  "14902": { state: "NY", city: "ELMIRA" },
  "14903": { state: "NY", city: "ELMIRA" },
  "14904": { state: "NY", city: "ELMIRA" },
  "14905": { state: "NY", city: "ELMIRA" },
  "15001": { state: "PA", city: "ALIQUIPPA" },
  "15003": { state: "PA", city: "AMBRIDGE" },
  "15004": { state: "PA", city: "ATLASBURG" },
  "15005": { state: "PA", city: "BADEN" },
  "15006": { state: "PA", city: "BAIRDFORD" },
  "15007": { state: "PA", city: "BAKERSTOWN" },
  "15009": { state: "PA", city: "BEAVER" },
  "15010": { state: "PA", city: "BEAVER FALLS" },
  "15012": { state: "PA", city: "BELLE VERNON" },
  "15014": { state: "PA", city: "BRACKENRIDGE" },
  "15015": { state: "PA", city: "BRADFORDWOODS" },
  "15017": { state: "PA", city: "BRIDGEVILLE" },
  "15018": { state: "PA", city: "BUENA VISTA" },
  "15019": { state: "PA", city: "BULGER" },
  "15020": { state: "PA", city: "BUNOLA" },
  "15021": { state: "PA", city: "BURGETTSTOWN" },
  "15022": { state: "PA", city: "CHARLEROI" },
  "15024": { state: "PA", city: "CHESWICK" },
  "15025": { state: "PA", city: "CLAIRTON" },
  "15026": { state: "PA", city: "CLINTON" },
  "15027": { state: "PA", city: "CONWAY" },
  "15028": { state: "PA", city: "COULTERS" },
  "15030": { state: "PA", city: "CREIGHTON" },
  "15031": { state: "PA", city: "CUDDY" },
  "15032": { state: "PA", city: "CURTISVILLE" },
  "15033": { state: "PA", city: "DONORA" },
  "15034": { state: "PA", city: "DRAVOSBURG" },
  "15035": { state: "PA", city: "EAST MC KEESPORT" },
  "15037": { state: "PA", city: "ELIZABETH" },
  "15038": { state: "PA", city: "ELRAMA" },
  "15042": { state: "PA", city: "FREEDOM" },
  "15043": { state: "PA", city: "GEORGETOWN" },
  "15044": { state: "PA", city: "GIBSONIA" },
  "15045": { state: "PA", city: "GLASSPORT" },
  "15046": { state: "PA", city: "CRESCENT" },
  "15047": { state: "PA", city: "GREENOCK" },
  "15049": { state: "PA", city: "HARWICK" },
  "15050": { state: "PA", city: "HOOKSTOWN" },
  "15051": { state: "PA", city: "INDIANOLA" },
  "15052": { state: "PA", city: "INDUSTRY" },
  "15053": { state: "PA", city: "JOFFRE" },
  "15054": { state: "PA", city: "LANGELOTH" },
  "15055": { state: "PA", city: "LAWRENCE" },
  "15056": { state: "PA", city: "LEETSDALE" },
  "15057": { state: "PA", city: "MC DONALD" },
  "15059": { state: "PA", city: "MIDLAND" },
  "15060": { state: "PA", city: "MIDWAY" },
  "15061": { state: "PA", city: "MONACA" },
  "15062": { state: "PA", city: "MONESSEN" },
  "15063": { state: "PA", city: "MONONGAHELA" },
  "15064": { state: "PA", city: "MORGAN" },
  "15065": { state: "PA", city: "NATRONA HEIGHTS" },
  "15066": { state: "PA", city: "NEW BRIGHTON" },
  "15067": { state: "PA", city: "NEW EAGLE" },
  "15068": { state: "PA", city: "NEW KENSINGTON" },
  "15069": { state: "PA", city: "NEW KENSINGTON" },
  "15071": { state: "PA", city: "OAKDALE" },
  "15072": { state: "PA", city: "PRICEDALE" },
  "15074": { state: "PA", city: "ROCHESTER" },
  "15075": { state: "PA", city: "RURAL RIDGE" },
  "15076": { state: "PA", city: "RUSSELLTON" },
  "15077": { state: "PA", city: "SHIPPINGPORT" },
  "15078": { state: "PA", city: "SLOVAN" },
  "15081": { state: "PA", city: "SOUTH HEIGHTS" },
  "15082": { state: "PA", city: "STURGEON" },
  "15083": { state: "PA", city: "SUTERSVILLE" },
  "15084": { state: "PA", city: "TARENTUM" },
  "15085": { state: "PA", city: "TRAFFORD" },
  "15086": { state: "PA", city: "WARRENDALE" },
  "15087": { state: "PA", city: "WEBSTER" },
  "15088": { state: "PA", city: "WEST ELIZABETH" },
  "15089": { state: "PA", city: "WEST NEWTON" },
  "15090": { state: "PA", city: "WEXFORD" },
  "15091": { state: "PA", city: "WILDWOOD" },
  "15095": { state: "PA", city: "WARRENDALE" },
  "15096": { state: "PA", city: "WARRENDALE" },
  "15101": { state: "PA", city: "ALLISON PARK" },
  "15102": { state: "PA", city: "BETHEL PARK" },
  "15104": { state: "PA", city: "BRADDOCK" },
  "15106": { state: "PA", city: "CARNEGIE" },
  "15108": { state: "PA", city: "CORAOPOLIS" },
  "15110": { state: "PA", city: "DUQUESNE" },
  "15112": { state: "PA", city: "EAST PITTSBURGH" },
  "15116": { state: "PA", city: "GLENSHAW" },
  "15120": { state: "PA", city: "HOMESTEAD" },
  "15122": { state: "PA", city: "WEST MIFFLIN" },
  "15123": { state: "PA", city: "WEST MIFFLIN" },
  "15126": { state: "PA", city: "IMPERIAL" },
  "15127": { state: "PA", city: "INGOMAR" },
  "15129": { state: "PA", city: "SOUTH PARK" },
  "15131": { state: "PA", city: "MCKEESPORT" },
  "15132": { state: "PA", city: "MCKEESPORT" },
  "15133": { state: "PA", city: "MCKEESPORT" },
  "15134": { state: "PA", city: "MCKEESPORT" },
  "15135": { state: "PA", city: "MCKEESPORT" },
  "15136": { state: "PA", city: "MC KEES ROCKS" },
  "15137": { state: "PA", city: "NORTH VERSAILLES" },
  "15139": { state: "PA", city: "OAKMONT" },
  "15140": { state: "PA", city: "PITCAIRN" },
  "15142": { state: "PA", city: "PRESTO" },
  "15143": { state: "PA", city: "SEWICKLEY" },
  "15144": { state: "PA", city: "SPRINGDALE" },
  "15145": { state: "PA", city: "TURTLE CREEK" },
  "15146": { state: "PA", city: "MONROEVILLE" },
  "15147": { state: "PA", city: "VERONA" },
  "15148": { state: "PA", city: "WILMERDING" },
  "15201": { state: "PA", city: "PITTSBURGH" },
  "15202": { state: "PA", city: "PITTSBURGH" },
  "15203": { state: "PA", city: "PITTSBURGH" },
  "15204": { state: "PA", city: "PITTSBURGH" },
  "15205": { state: "PA", city: "PITTSBURGH" },
  "15206": { state: "PA", city: "PITTSBURGH" },
  "15207": { state: "PA", city: "PITTSBURGH" },
  "15208": { state: "PA", city: "PITTSBURGH" },
  "15209": { state: "PA", city: "PITTSBURGH" },
  "15210": { state: "PA", city: "PITTSBURGH" },
  "15211": { state: "PA", city: "PITTSBURGH" },
  "15212": { state: "PA", city: "PITTSBURGH" },
  "15213": { state: "PA", city: "PITTSBURGH" },
  "15214": { state: "PA", city: "PITTSBURGH" },
  "15215": { state: "PA", city: "PITTSBURGH" },
  "15216": { state: "PA", city: "PITTSBURGH" },
  "15217": { state: "PA", city: "PITTSBURGH" },
  "15218": { state: "PA", city: "PITTSBURGH" },
  "15219": { state: "PA", city: "PITTSBURGH" },
  "15220": { state: "PA", city: "PITTSBURGH" },
  "15221": { state: "PA", city: "PITTSBURGH" },
  "15222": { state: "PA", city: "PITTSBURGH" },
  "15223": { state: "PA", city: "PITTSBURGH" },
  "15224": { state: "PA", city: "PITTSBURGH" },
  "15225": { state: "PA", city: "PITTSBURGH" },
  "15226": { state: "PA", city: "PITTSBURGH" },
  "15227": { state: "PA", city: "PITTSBURGH" },
  "15228": { state: "PA", city: "PITTSBURGH" },
  "15229": { state: "PA", city: "PITTSBURGH" },
  "15230": { state: "PA", city: "PITTSBURGH" },
  "15231": { state: "PA", city: "PITTSBURGH" },
  "15232": { state: "PA", city: "PITTSBURGH" },
  "15233": { state: "PA", city: "PITTSBURGH" },
  "15234": { state: "PA", city: "PITTSBURGH" },
  "15235": { state: "PA", city: "PITTSBURGH" },
  "15236": { state: "PA", city: "PITTSBURGH" },
  "15237": { state: "PA", city: "PITTSBURGH" },
  "15238": { state: "PA", city: "PITTSBURGH" },
  "15239": { state: "PA", city: "PITTSBURGH" },
  "15240": { state: "PA", city: "PITTSBURGH" },
  "15241": { state: "PA", city: "PITTSBURGH" },
  "15242": { state: "PA", city: "PITTSBURGH" },
  "15243": { state: "PA", city: "PITTSBURGH" },
  "15244": { state: "PA", city: "PITTSBURGH" },
  "15250": { state: "PA", city: "PITTSBURGH" },
  "15251": { state: "PA", city: "PITTSBURGH" },
  "15252": { state: "PA", city: "PITTSBURGH" },
  "15253": { state: "PA", city: "PITTSBURGH" },
  "15254": { state: "PA", city: "PITTSBURGH" },
  "15255": { state: "PA", city: "PITTSBURGH" },
  "15257": { state: "PA", city: "PITTSBURGH" },
  "15258": { state: "PA", city: "PITTSBURGH" },
  "15259": { state: "PA", city: "PITTSBURGH" },
  "15260": { state: "PA", city: "PITTSBURGH" },
  "15261": { state: "PA", city: "PITTSBURGH" },
  "15262": { state: "PA", city: "PITTSBURGH" },
  "15264": { state: "PA", city: "PITTSBURGH" },
  "15265": { state: "PA", city: "PITTSBURGH" },
  "15267": { state: "PA", city: "PITTSBURGH" },
  "15268": { state: "PA", city: "PITTSBURGH" },
  "15270": { state: "PA", city: "PITTSBURGH" },
  "15272": { state: "PA", city: "PITTSBURGH" },
  "15274": { state: "PA", city: "PITTSBURGH" },
  "15275": { state: "PA", city: "PITTSBURGH" },
  "15276": { state: "PA", city: "PITTSBURGH" },
  "15277": { state: "PA", city: "PITTSBURGH" },
  "15278": { state: "PA", city: "PITTSBURGH" },
  "15279": { state: "PA", city: "PITTSBURGH" },
  "15281": { state: "PA", city: "PITTSBURGH" },
  "15282": { state: "PA", city: "PITTSBURGH" },
  "15283": { state: "PA", city: "PITTSBURGH" },
  "15286": { state: "PA", city: "PITTSBURGH" },
  "15289": { state: "PA", city: "PITTSBURGH" },
  "15290": { state: "PA", city: "PITTSBURGH" },
  "15295": { state: "PA", city: "PITTSBURGH" },
  "15301": { state: "PA", city: "WASHINGTON" },
  "15310": { state: "PA", city: "ALEPPO" },
  "15311": { state: "PA", city: "AMITY" },
  "15312": { state: "PA", city: "AVELLA" },
  "15313": { state: "PA", city: "BEALLSVILLE" },
  "15314": { state: "PA", city: "BENTLEYVILLE" },
  "15315": { state: "PA", city: "BOBTOWN" },
  "15316": { state: "PA", city: "BRAVE" },
  "15317": { state: "PA", city: "CANONSBURG" },
  "15320": { state: "PA", city: "CARMICHAELS" },
  "15321": { state: "PA", city: "CECIL" },
  "15322": { state: "PA", city: "CLARKSVILLE" },
  "15323": { state: "PA", city: "CLAYSVILLE" },
  "15324": { state: "PA", city: "COKEBURG" },
  "15325": { state: "PA", city: "CRUCIBLE" },
  "15327": { state: "PA", city: "DILLINER" },
  "15329": { state: "PA", city: "PROSPERITY" },
  "15330": { state: "PA", city: "EIGHTY FOUR" },
  "15331": { state: "PA", city: "ELLSWORTH" },
  "15332": { state: "PA", city: "FINLEYVILLE" },
  "15333": { state: "PA", city: "FREDERICKTOWN" },
  "15334": { state: "PA", city: "GARARDS FORT" },
  "15336": { state: "PA", city: "GASTONVILLE" },
  "15337": { state: "PA", city: "GRAYSVILLE" },
  "15338": { state: "PA", city: "GREENSBORO" },
  "15339": { state: "PA", city: "HENDERSONVILLE" },
  "15340": { state: "PA", city: "HICKORY" },
  "15341": { state: "PA", city: "HOLBROOK" },
  "15342": { state: "PA", city: "HOUSTON" },
  "15344": { state: "PA", city: "JEFFERSON" },
  "15345": { state: "PA", city: "MARIANNA" },
  "15346": { state: "PA", city: "MATHER" },
  "15347": { state: "PA", city: "MEADOW LANDS" },
  "15348": { state: "PA", city: "MILLSBORO" },
  "15349": { state: "PA", city: "MOUNT MORRIS" },
  "15350": { state: "PA", city: "MUSE" },
  "15351": { state: "PA", city: "NEMACOLIN" },
  "15352": { state: "PA", city: "NEW FREEPORT" },
  "15353": { state: "PA", city: "NINEVEH" },
  "15357": { state: "PA", city: "RICES LANDING" },
  "15358": { state: "PA", city: "RICHEYVILLE" },
  "15359": { state: "PA", city: "ROGERSVILLE" },
  "15360": { state: "PA", city: "SCENERY HILL" },
  "15361": { state: "PA", city: "SOUTHVIEW" },
  "15362": { state: "PA", city: "SPRAGGS" },
  "15363": { state: "PA", city: "STRABANE" },
  "15364": { state: "PA", city: "SYCAMORE" },
  "15365": { state: "PA", city: "TAYLORSTOWN" },
  "15366": { state: "PA", city: "VAN VOORHIS" },
  "15367": { state: "PA", city: "VENETIA" },
  "15368": { state: "PA", city: "VESTABURG" },
  "15370": { state: "PA", city: "WAYNESBURG" },
  "15376": { state: "PA", city: "WEST ALEXANDER" },
  "15377": { state: "PA", city: "WEST FINLEY" },
  "15378": { state: "PA", city: "WESTLAND" },
  "15379": { state: "PA", city: "WEST MIDDLETOWN" },
  "15380": { state: "PA", city: "WIND RIDGE" },
  "15401": { state: "PA", city: "UNIONTOWN" },
  "15410": { state: "PA", city: "ADAH" },
  "15411": { state: "PA", city: "ADDISON" },
  "15412": { state: "PA", city: "ALLENPORT" },
  "15413": { state: "PA", city: "ALLISON" },
  "15415": { state: "PA", city: "BRIER HILL" },
  "15416": { state: "PA", city: "BROWNFIELD" },
  "15417": { state: "PA", city: "BROWNSVILLE" },
  "15419": { state: "PA", city: "CALIFORNIA" },
  "15420": { state: "PA", city: "CARDALE" },
  "15421": { state: "PA", city: "CHALK HILL" },
  "15422": { state: "PA", city: "CHESTNUT RIDGE" },
  "15423": { state: "PA", city: "COAL CENTER" },
  "15424": { state: "PA", city: "CONFLUENCE" },
  "15425": { state: "PA", city: "CONNELLSVILLE" },
  "15427": { state: "PA", city: "DAISYTOWN" },
  "15428": { state: "PA", city: "DAWSON" },
  "15429": { state: "PA", city: "DENBO" },
  "15430": { state: "PA", city: "DICKERSON RUN" },
  "15431": { state: "PA", city: "DUNBAR" },
  "15432": { state: "PA", city: "DUNLEVY" },
  "15433": { state: "PA", city: "EAST MILLSBORO" },
  "15434": { state: "PA", city: "ELCO" },
  "15435": { state: "PA", city: "FAIRBANK" },
  "15436": { state: "PA", city: "FAIRCHANCE" },
  "15437": { state: "PA", city: "FARMINGTON" },
  "15438": { state: "PA", city: "FAYETTE CITY" },
  "15439": { state: "PA", city: "GANS" },
  "15440": { state: "PA", city: "GIBBON GLADE" },
  "15442": { state: "PA", city: "GRINDSTONE" },
  "15443": { state: "PA", city: "HIBBS" },
  "15444": { state: "PA", city: "HILLER" },
  "15445": { state: "PA", city: "HOPWOOD" },
  "15446": { state: "PA", city: "INDIAN HEAD" },
  "15447": { state: "PA", city: "ISABELLA" },
  "15448": { state: "PA", city: "JACOBS CREEK" },
  "15449": { state: "PA", city: "KEISTERVILLE" },
  "15450": { state: "PA", city: "LA BELLE" },
  "15451": { state: "PA", city: "LAKE LYNN" },
  "15454": { state: "PA", city: "LECKRONE" },
  "15455": { state: "PA", city: "LEISENRING" },
  "15456": { state: "PA", city: "LEMONT FURNACE" },
  "15458": { state: "PA", city: "MC CLELLANDTOWN" },
  "15459": { state: "PA", city: "MARKLEYSBURG" },
  "15460": { state: "PA", city: "MARTIN" },
  "15461": { state: "PA", city: "MASONTOWN" },
  "15462": { state: "PA", city: "MELCROFT" },
  "15463": { state: "PA", city: "MERRITTSTOWN" },
  "15464": { state: "PA", city: "MILL RUN" },
  "15465": { state: "PA", city: "MOUNT BRADDOCK" },
  "15466": { state: "PA", city: "NEWELL" },
  "15467": { state: "PA", city: "NEW GENEVA" },
  "15468": { state: "PA", city: "NEW SALEM" },
  "15469": { state: "PA", city: "NORMALVILLE" },
  "15470": { state: "PA", city: "OHIOPYLE" },
  "15472": { state: "PA", city: "OLIVER" },
  "15473": { state: "PA", city: "PERRYOPOLIS" },
  "15474": { state: "PA", city: "POINT MARION" },
  "15475": { state: "PA", city: "REPUBLIC" },
  "15476": { state: "PA", city: "RONCO" },
  "15477": { state: "PA", city: "ROSCOE" },
  "15478": { state: "PA", city: "SMITHFIELD" },
  "15479": { state: "PA", city: "SMITHTON" },
  "15480": { state: "PA", city: "SMOCK" },
  "15482": { state: "PA", city: "STAR JUNCTION" },
  "15483": { state: "PA", city: "STOCKDALE" },
  "15484": { state: "PA", city: "ULEDI" },
  "15485": { state: "PA", city: "URSINA" },
  "15486": { state: "PA", city: "VANDERBILT" },
  "15488": { state: "PA", city: "WALTERSBURG" },
  "15489": { state: "PA", city: "WEST LEISENRING" },
  "15490": { state: "PA", city: "WHITE" },
  "15492": { state: "PA", city: "WICKHAVEN" },
  "15501": { state: "PA", city: "SOMERSET" },
  "15502": { state: "PA", city: "HIDDEN VALLEY" },
  "15510": { state: "PA", city: "SOMERSET" },
  "15520": { state: "PA", city: "ACOSTA" },
  "15521": { state: "PA", city: "ALUM BANK" },
  "15522": { state: "PA", city: "BEDFORD" },
  "15530": { state: "PA", city: "BERLIN" },
  "15531": { state: "PA", city: "BOSWELL" },
  "15532": { state: "PA", city: "BOYNTON" },
  "15533": { state: "PA", city: "BREEZEWOOD" },
  "15534": { state: "PA", city: "BUFFALO MILLS" },
  "15535": { state: "PA", city: "CLEARVILLE" },
  "15536": { state: "PA", city: "CRYSTAL SPRING" },
  "15537": { state: "PA", city: "EVERETT" },
  "15538": { state: "PA", city: "FAIRHOPE" },
  "15539": { state: "PA", city: "FISHERTOWN" },
  "15540": { state: "PA", city: "FORT HILL" },
  "15541": { state: "PA", city: "FRIEDENS" },
  "15542": { state: "PA", city: "GARRETT" },
  "15544": { state: "PA", city: "GRAY" },
  "15545": { state: "PA", city: "HYNDMAN" },
  "15546": { state: "PA", city: "JENNERS" },
  "15547": { state: "PA", city: "JENNERSTOWN" },
  "15548": { state: "PA", city: "KANTNER" },
  "15549": { state: "PA", city: "LISTIE" },
  "15550": { state: "PA", city: "MANNS CHOICE" },
  "15551": { state: "PA", city: "MARKLETON" },
  "15552": { state: "PA", city: "MEYERSDALE" },
  "15553": { state: "PA", city: "NEW BALTIMORE" },
  "15554": { state: "PA", city: "NEW PARIS" },
  "15555": { state: "PA", city: "QUECREEK" },
  "15557": { state: "PA", city: "ROCKWOOD" },
  "15558": { state: "PA", city: "SALISBURY" },
  "15559": { state: "PA", city: "SCHELLSBURG" },
  "15560": { state: "PA", city: "SHANKSVILLE" },
  "15561": { state: "PA", city: "SIPESVILLE" },
  "15562": { state: "PA", city: "SPRINGS" },
  "15563": { state: "PA", city: "STOYSTOWN" },
  "15564": { state: "PA", city: "WELLERSBURG" },
  "15565": { state: "PA", city: "WEST SALISBURY" },
  "15601": { state: "PA", city: "GREENSBURG" },
  "15605": { state: "PA", city: "GREENSBURG" },
  "15606": { state: "PA", city: "GREENSBURG" },
  "15610": { state: "PA", city: "ACME" },
  "15611": { state: "PA", city: "ADAMSBURG" },
  "15612": { state: "PA", city: "ALVERTON" },
  "15613": { state: "PA", city: "APOLLO" },
  "15615": { state: "PA", city: "ARDARA" },
  "15616": { state: "PA", city: "ARMBRUST" },
  "15617": { state: "PA", city: "ARONA" },
  "15618": { state: "PA", city: "AVONMORE" },
  "15619": { state: "PA", city: "BOVARD" },
  "15620": { state: "PA", city: "BRADENVILLE" },
  "15621": { state: "PA", city: "CALUMET" },
  "15622": { state: "PA", city: "CHAMPION" },
  "15623": { state: "PA", city: "CLARIDGE" },
  "15624": { state: "PA", city: "CRABTREE" },
  "15625": { state: "PA", city: "DARRAGH" },
  "15626": { state: "PA", city: "DELMONT" },
  "15627": { state: "PA", city: "DERRY" },
  "15628": { state: "PA", city: "DONEGAL" },
  "15629": { state: "PA", city: "EAST VANDERGRIFT" },
  "15631": { state: "PA", city: "EVERSON" },
  "15632": { state: "PA", city: "EXPORT" },
  "15633": { state: "PA", city: "FORBES ROAD" },
  "15634": { state: "PA", city: "GRAPEVILLE" },
  "15635": { state: "PA", city: "HANNASTOWN" },
  "15636": { state: "PA", city: "HARRISON CITY" },
  "15637": { state: "PA", city: "HERMINIE" },
  "15638": { state: "PA", city: "HOSTETTER" },
  "15639": { state: "PA", city: "HUNKER" },
  "15640": { state: "PA", city: "HUTCHINSON" },
  "15641": { state: "PA", city: "HYDE PARK" },
  "15642": { state: "PA", city: "IRWIN" },
  "15644": { state: "PA", city: "JEANNETTE" },
  "15646": { state: "PA", city: "JONES MILLS" },
  "15647": { state: "PA", city: "LARIMER" },
  "15650": { state: "PA", city: "LATROBE" },
  "15655": { state: "PA", city: "LAUGHLINTOWN" },
  "15656": { state: "PA", city: "LEECHBURG" },
  "15658": { state: "PA", city: "LIGONIER" },
  "15660": { state: "PA", city: "LOWBER" },
  "15661": { state: "PA", city: "LOYALHANNA" },
  "15662": { state: "PA", city: "LUXOR" },
  "15663": { state: "PA", city: "MADISON" },
  "15664": { state: "PA", city: "MAMMOTH" },
  "15665": { state: "PA", city: "MANOR" },
  "15666": { state: "PA", city: "MOUNT PLEASANT" },
  "15668": { state: "PA", city: "MURRYSVILLE" },
  "15670": { state: "PA", city: "NEW ALEXANDRIA" },
  "15671": { state: "PA", city: "NEW DERRY" },
  "15672": { state: "PA", city: "NEW STANTON" },
  "15673": { state: "PA", city: "NORTH APOLLO" },
  "15674": { state: "PA", city: "NORVELT" },
  "15675": { state: "PA", city: "PENN" },
  "15676": { state: "PA", city: "PLEASANT UNITY" },
  "15677": { state: "PA", city: "RECTOR" },
  "15678": { state: "PA", city: "RILLTON" },
  "15679": { state: "PA", city: "RUFFS DALE" },
  "15680": { state: "PA", city: "SALINA" },
  "15681": { state: "PA", city: "SALTSBURG" },
  "15682": { state: "PA", city: "SCHENLEY" },
  "15683": { state: "PA", city: "SCOTTDALE" },
  "15684": { state: "PA", city: "SLICKVILLE" },
  "15685": { state: "PA", city: "SOUTHWEST" },
  "15686": { state: "PA", city: "SPRING CHURCH" },
  "15687": { state: "PA", city: "STAHLSTOWN" },
  "15688": { state: "PA", city: "TARRS" },
  "15689": { state: "PA", city: "UNITED" },
  "15690": { state: "PA", city: "VANDERGRIFT" },
  "15691": { state: "PA", city: "WENDEL" },
  "15692": { state: "PA", city: "WESTMORELAND CITY" },
  "15693": { state: "PA", city: "WHITNEY" },
  "15695": { state: "PA", city: "WYANO" },
  "15696": { state: "PA", city: "YOUNGSTOWN" },
  "15697": { state: "PA", city: "YOUNGWOOD" },
  "15698": { state: "PA", city: "YUKON" },
  "15701": { state: "PA", city: "INDIANA" },
  "15705": { state: "PA", city: "INDIANA" },
  "15710": { state: "PA", city: "ALVERDA" },
  "15711": { state: "PA", city: "ANITA" },
  "15712": { state: "PA", city: "ARCADIA" },
  "15713": { state: "PA", city: "AULTMAN" },
  "15714": { state: "PA", city: "NORTHERN CAMBRIA" },
  "15715": { state: "PA", city: "BIG RUN" },
  "15716": { state: "PA", city: "BLACK LICK" },
  "15717": { state: "PA", city: "BLAIRSVILLE" },
  "15720": { state: "PA", city: "BRUSH VALLEY" },
  "15721": { state: "PA", city: "BURNSIDE" },
  "15722": { state: "PA", city: "CARROLLTOWN" },
  "15723": { state: "PA", city: "CHAMBERSVILLE" },
  "15724": { state: "PA", city: "CHERRY TREE" },
  "15725": { state: "PA", city: "CLARKSBURG" },
  "15727": { state: "PA", city: "CLUNE" },
  "15728": { state: "PA", city: "CLYMER" },
  "15729": { state: "PA", city: "COMMODORE" },
  "15730": { state: "PA", city: "COOLSPRING" },
  "15731": { state: "PA", city: "CORAL" },
  "15732": { state: "PA", city: "CREEKSIDE" },
  "15733": { state: "PA", city: "DE LANCEY" },
  "15734": { state: "PA", city: "DIXONVILLE" },
  "15736": { state: "PA", city: "ELDERTON" },
  "15737": { state: "PA", city: "ELMORA" },
  "15738": { state: "PA", city: "EMEIGH" },
  "15739": { state: "PA", city: "ERNEST" },
  "15741": { state: "PA", city: "GIPSY" },
  "15742": { state: "PA", city: "GLEN CAMPBELL" },
  "15744": { state: "PA", city: "HAMILTON" },
  "15745": { state: "PA", city: "HEILWOOD" },
  "15746": { state: "PA", city: "HILLSDALE" },
  "15747": { state: "PA", city: "HOME" },
  "15748": { state: "PA", city: "HOMER CITY" },
  "15750": { state: "PA", city: "JOSEPHINE" },
  "15752": { state: "PA", city: "KENT" },
  "15753": { state: "PA", city: "LA JOSE" },
  "15754": { state: "PA", city: "LUCERNEMINES" },
  "15756": { state: "PA", city: "MC INTYRE" },
  "15757": { state: "PA", city: "MAHAFFEY" },
  "15758": { state: "PA", city: "MARCHAND" },
  "15759": { state: "PA", city: "MARION CENTER" },
  "15760": { state: "PA", city: "MARSTELLER" },
  "15761": { state: "PA", city: "MENTCLE" },
  "15762": { state: "PA", city: "NICKTOWN" },
  "15763": { state: "PA", city: "NORTHPOINT" },
  "15764": { state: "PA", city: "OLIVEBURG" },
  "15765": { state: "PA", city: "PENN RUN" },
  "15767": { state: "PA", city: "PUNXSUTAWNEY" },
  "15770": { state: "PA", city: "RINGGOLD" },
  "15771": { state: "PA", city: "ROCHESTER MILLS" },
  "15772": { state: "PA", city: "ROSSITER" },
  "15773": { state: "PA", city: "SAINT BENEDICT" },
  "15774": { state: "PA", city: "SHELOCTA" },
  "15775": { state: "PA", city: "SPANGLER" },
  "15776": { state: "PA", city: "SPRANKLE MILLS" },
  "15777": { state: "PA", city: "STARFORD" },
  "15778": { state: "PA", city: "TIMBLIN" },
  "15779": { state: "PA", city: "TORRANCE" },
  "15780": { state: "PA", city: "VALIER" },
  "15781": { state: "PA", city: "WALSTON" },
  "15783": { state: "PA", city: "WEST LEBANON" },
  "15784": { state: "PA", city: "WORTHVILLE" },
  "15801": { state: "PA", city: "DU BOIS" },
  "15821": { state: "PA", city: "BENEZETT" },
  "15822": { state: "PA", city: "BRANDY CAMP" },
  "15823": { state: "PA", city: "BROCKPORT" },
  "15824": { state: "PA", city: "BROCKWAY" },
  "15825": { state: "PA", city: "BROOKVILLE" },
  "15827": { state: "PA", city: "BYRNEDALE" },
  "15828": { state: "PA", city: "CLARINGTON" },
  "15829": { state: "PA", city: "CORSICA" },
  "15831": { state: "PA", city: "DAGUS MINES" },
  "15832": { state: "PA", city: "DRIFTWOOD" },
  "15834": { state: "PA", city: "EMPORIUM" },
  "15840": { state: "PA", city: "FALLS CREEK" },
  "15841": { state: "PA", city: "FORCE" },
  "15845": { state: "PA", city: "JOHNSONBURG" },
  "15846": { state: "PA", city: "KERSEY" },
  "15847": { state: "PA", city: "KNOX DALE" },
  "15848": { state: "PA", city: "LUTHERSBURG" },
  "15849": { state: "PA", city: "PENFIELD" },
  "15851": { state: "PA", city: "REYNOLDSVILLE" },
  "15853": { state: "PA", city: "RIDGWAY" },
  "15856": { state: "PA", city: "ROCKTON" },
  "15857": { state: "PA", city: "SAINT MARYS" },
  "15860": { state: "PA", city: "SIGEL" },
  "15861": { state: "PA", city: "SINNAMAHONING" },
  "15863": { state: "PA", city: "STUMP CREEK" },
  "15864": { state: "PA", city: "SUMMERVILLE" },
  "15865": { state: "PA", city: "SYKESVILLE" },
  "15866": { state: "PA", city: "TROUTVILLE" },
  "15868": { state: "PA", city: "WEEDVILLE" },
  "15870": { state: "PA", city: "WILCOX" },
  "15901": { state: "PA", city: "JOHNSTOWN" },
  "15902": { state: "PA", city: "JOHNSTOWN" },
  "15904": { state: "PA", city: "JOHNSTOWN" },
  "15905": { state: "PA", city: "JOHNSTOWN" },
  "15906": { state: "PA", city: "JOHNSTOWN" },
  "15907": { state: "PA", city: "JOHNSTOWN" },
  "15909": { state: "PA", city: "JOHNSTOWN" },
  "15915": { state: "PA", city: "JOHNSTOWN" },
  "15920": { state: "PA", city: "ARMAGH" },
  "15921": { state: "PA", city: "BEAVERDALE" },
  "15922": { state: "PA", city: "BELSANO" },
  "15923": { state: "PA", city: "BOLIVAR" },
  "15924": { state: "PA", city: "CAIRNBROOK" },
  "15925": { state: "PA", city: "CASSANDRA" },
  "15926": { state: "PA", city: "CENTRAL CITY" },
  "15927": { state: "PA", city: "COLVER" },
  "15928": { state: "PA", city: "DAVIDSVILLE" },
  "15929": { state: "PA", city: "DILLTOWN" },
  "15930": { state: "PA", city: "DUNLO" },
  "15931": { state: "PA", city: "EBENSBURG" },
  "15934": { state: "PA", city: "ELTON" },
  "15935": { state: "PA", city: "HOLLSOPPLE" },
  "15936": { state: "PA", city: "HOOVERSVILLE" },
  "15937": { state: "PA", city: "JEROME" },
  "15938": { state: "PA", city: "LILLY" },
  "15940": { state: "PA", city: "LORETTO" },
  "15942": { state: "PA", city: "MINERAL POINT" },
  "15943": { state: "PA", city: "NANTY GLO" },
  "15944": { state: "PA", city: "NEW FLORENCE" },
  "15945": { state: "PA", city: "PARKHILL" },
  "15946": { state: "PA", city: "PORTAGE" },
  "15948": { state: "PA", city: "REVLOC" },
  "15949": { state: "PA", city: "ROBINSON" },
  "15951": { state: "PA", city: "SAINT MICHAEL" },
  "15952": { state: "PA", city: "SALIX" },
  "15953": { state: "PA", city: "SEANOR" },
  "15954": { state: "PA", city: "SEWARD" },
  "15955": { state: "PA", city: "SIDMAN" },
  "15956": { state: "PA", city: "SOUTH FORK" },
  "15957": { state: "PA", city: "STRONGSTOWN" },
  "15958": { state: "PA", city: "SUMMERHILL" },
  "15959": { state: "PA", city: "TIRE HILL" },
  "15960": { state: "PA", city: "TWIN ROCKS" },
  "15961": { state: "PA", city: "VINTONDALE" },
  "15962": { state: "PA", city: "WILMORE" },
  "15963": { state: "PA", city: "WINDBER" },
  "16001": { state: "PA", city: "BUTLER" },
  "16002": { state: "PA", city: "BUTLER" },
  "16003": { state: "PA", city: "BUTLER" },
  "16016": { state: "PA", city: "BOYERS" },
  "16017": { state: "PA", city: "BOYERS" },
  "16018": { state: "PA", city: "BOYERS" },
  "16020": { state: "PA", city: "BOYERS" },
  "16021": { state: "PA", city: "BRANCHTON" },
  "16022": { state: "PA", city: "BRUIN" },
  "16023": { state: "PA", city: "CABOT" },
  "16024": { state: "PA", city: "CALLERY" },
  "16025": { state: "PA", city: "CHICORA" },
  "16027": { state: "PA", city: "CONNOQUENESSING" },
  "16028": { state: "PA", city: "EAST BRADY" },
  "16029": { state: "PA", city: "EAST BUTLER" },
  "16030": { state: "PA", city: "EAU CLAIRE" },
  "16033": { state: "PA", city: "EVANS CITY" },
  "16034": { state: "PA", city: "FENELTON" },
  "16035": { state: "PA", city: "FORESTVILLE" },
  "16036": { state: "PA", city: "FOXBURG" },
  "16037": { state: "PA", city: "HARMONY" },
  "16038": { state: "PA", city: "HARRISVILLE" },
  "16039": { state: "PA", city: "HERMAN" },
  "16040": { state: "PA", city: "HILLIARDS" },
  "16041": { state: "PA", city: "KARNS CITY" },
  "16045": { state: "PA", city: "LYNDORA" },
  "16046": { state: "PA", city: "MARS" },
  "16048": { state: "PA", city: "NORTH WASHINGTON" },
  "16049": { state: "PA", city: "PARKER" },
  "16050": { state: "PA", city: "PETROLIA" },
  "16051": { state: "PA", city: "PORTERSVILLE" },
  "16052": { state: "PA", city: "PROSPECT" },
  "16053": { state: "PA", city: "RENFREW" },
  "16054": { state: "PA", city: "SAINT PETERSBURG" },
  "16055": { state: "PA", city: "SARVER" },
  "16056": { state: "PA", city: "SAXONBURG" },
  "16057": { state: "PA", city: "SLIPPERY ROCK" },
  "16058": { state: "PA", city: "TURKEY CITY" },
  "16059": { state: "PA", city: "VALENCIA" },
  "16061": { state: "PA", city: "WEST SUNBURY" },
  "16063": { state: "PA", city: "ZELIENOPLE" },
  "16066": { state: "PA", city: "CRANBERRY TOWNSHIP" },
  "16101": { state: "PA", city: "NEW CASTLE" },
  "16102": { state: "PA", city: "NEW CASTLE" },
  "16103": { state: "PA", city: "NEW CASTLE" },
  "16105": { state: "PA", city: "NEW CASTLE" },
  "16107": { state: "PA", city: "NEW CASTLE" },
  "16108": { state: "PA", city: "NEW CASTLE" },
  "16110": { state: "PA", city: "ADAMSVILLE" },
  "16111": { state: "PA", city: "ATLANTIC" },
  "16112": { state: "PA", city: "BESSEMER" },
  "16113": { state: "PA", city: "CLARK" },
  "16114": { state: "PA", city: "CLARKS MILLS" },
  "16115": { state: "PA", city: "DARLINGTON" },
  "16116": { state: "PA", city: "EDINBURG" },
  "16117": { state: "PA", city: "ELLWOOD CITY" },
  "16120": { state: "PA", city: "ENON VALLEY" },
  "16121": { state: "PA", city: "FARRELL" },
  "16123": { state: "PA", city: "FOMBELL" },
  "16124": { state: "PA", city: "FREDONIA" },
  "16125": { state: "PA", city: "GREENVILLE" },
  "16127": { state: "PA", city: "GROVE CITY" },
  "16130": { state: "PA", city: "HADLEY" },
  "16131": { state: "PA", city: "HARTSTOWN" },
  "16132": { state: "PA", city: "HILLSVILLE" },
  "16133": { state: "PA", city: "JACKSON CENTER" },
  "16134": { state: "PA", city: "JAMESTOWN" },
  "16136": { state: "PA", city: "KOPPEL" },
  "16137": { state: "PA", city: "MERCER" },
  "16140": { state: "PA", city: "NEW BEDFORD" },
  "16141": { state: "PA", city: "NEW GALILEE" },
  "16142": { state: "PA", city: "NEW WILMINGTON" },
  "16143": { state: "PA", city: "PULASKI" },
  "16145": { state: "PA", city: "SANDY LAKE" },
  "16146": { state: "PA", city: "SHARON" },
  "16148": { state: "PA", city: "HERMITAGE" },
  "16150": { state: "PA", city: "SHARPSVILLE" },
  "16151": { state: "PA", city: "SHEAKLEYVILLE" },
  "16153": { state: "PA", city: "STONEBORO" },
  "16154": { state: "PA", city: "TRANSFER" },
  "16155": { state: "PA", city: "VILLA MARIA" },
  "16156": { state: "PA", city: "VOLANT" },
  "16157": { state: "PA", city: "WAMPUM" },
  "16159": { state: "PA", city: "WEST MIDDLESEX" },
  "16160": { state: "PA", city: "WEST PITTSBURG" },
  "16161": { state: "PA", city: "WHEATLAND" },
  "16172": { state: "PA", city: "NEW WILMINGTON" },
  "16201": { state: "PA", city: "KITTANNING" },
  "16210": { state: "PA", city: "ADRIAN" },
  "16211": { state: "PA", city: "BEYER" },
  "16212": { state: "PA", city: "CADOGAN" },
  "16213": { state: "PA", city: "CALLENSBURG" },
  "16214": { state: "PA", city: "CLARION" },
  "16217": { state: "PA", city: "COOKSBURG" },
  "16218": { state: "PA", city: "COWANSVILLE" },
  "16220": { state: "PA", city: "CROWN" },
  "16221": { state: "PA", city: "CURLLSVILLE" },
  "16222": { state: "PA", city: "DAYTON" },
  "16223": { state: "PA", city: "DISTANT" },
  "16224": { state: "PA", city: "FAIRMOUNT CITY" },
  "16225": { state: "PA", city: "FISHER" },
  "16226": { state: "PA", city: "FORD CITY" },
  "16228": { state: "PA", city: "FORD CLIFF" },
  "16229": { state: "PA", city: "FREEPORT" },
  "16230": { state: "PA", city: "HAWTHORN" },
  "16232": { state: "PA", city: "KNOX" },
  "16233": { state: "PA", city: "LEEPER" },
  "16234": { state: "PA", city: "LIMESTONE" },
  "16235": { state: "PA", city: "LUCINDA" },
  "16236": { state: "PA", city: "MC GRANN" },
  "16238": { state: "PA", city: "MANORVILLE" },
  "16239": { state: "PA", city: "MARIENVILLE" },
  "16240": { state: "PA", city: "MAYPORT" },
  "16242": { state: "PA", city: "NEW BETHLEHEM" },
  "16244": { state: "PA", city: "NU MINE" },
  "16245": { state: "PA", city: "OAK RIDGE" },
  "16246": { state: "PA", city: "PLUMVILLE" },
  "16248": { state: "PA", city: "RIMERSBURG" },
  "16249": { state: "PA", city: "RURAL VALLEY" },
  "16250": { state: "PA", city: "SAGAMORE" },
  "16253": { state: "PA", city: "SEMINOLE" },
  "16254": { state: "PA", city: "SHIPPENVILLE" },
  "16255": { state: "PA", city: "SLIGO" },
  "16256": { state: "PA", city: "SMICKSBURG" },
  "16257": { state: "PA", city: "SNYDERSBURG" },
  "16258": { state: "PA", city: "STRATTANVILLE" },
  "16259": { state: "PA", city: "TEMPLETON" },
  "16260": { state: "PA", city: "VOWINCKEL" },
  "16261": { state: "PA", city: "WIDNOON" },
  "16262": { state: "PA", city: "WORTHINGTON" },
  "16263": { state: "PA", city: "YATESBORO" },
  "16301": { state: "PA", city: "OIL CITY" },
  "16311": { state: "PA", city: "CARLTON" },
  "16312": { state: "PA", city: "CHANDLERS VALLEY" },
  "16313": { state: "PA", city: "CLARENDON" },
  "16314": { state: "PA", city: "COCHRANTON" },
  "16316": { state: "PA", city: "CONNEAUT LAKE" },
  "16317": { state: "PA", city: "COOPERSTOWN" },
  "16319": { state: "PA", city: "CRANBERRY" },
  "16321": { state: "PA", city: "EAST HICKORY" },
  "16322": { state: "PA", city: "ENDEAVOR" },
  "16323": { state: "PA", city: "FRANKLIN" },
  "16326": { state: "PA", city: "FRYBURG" },
  "16327": { state: "PA", city: "GUYS MILLS" },
  "16328": { state: "PA", city: "HYDETOWN" },
  "16329": { state: "PA", city: "IRVINE" },
  "16331": { state: "PA", city: "KOSSUTH" },
  "16332": { state: "PA", city: "LICKINGVILLE" },
  "16333": { state: "PA", city: "LUDLOW" },
  "16334": { state: "PA", city: "MARBLE" },
  "16335": { state: "PA", city: "MEADVILLE" },
  "16340": { state: "PA", city: "PITTSFIELD" },
  "16341": { state: "PA", city: "PLEASANTVILLE" },
  "16342": { state: "PA", city: "POLK" },
  "16343": { state: "PA", city: "RENO" },
  "16344": { state: "PA", city: "ROUSEVILLE" },
  "16345": { state: "PA", city: "RUSSELL" },
  "16346": { state: "PA", city: "SENECA" },
  "16347": { state: "PA", city: "SHEFFIELD" },
  "16350": { state: "PA", city: "SUGAR GROVE" },
  "16351": { state: "PA", city: "TIDIOUTE" },
  "16352": { state: "PA", city: "TIONA" },
  "16353": { state: "PA", city: "TIONESTA" },
  "16354": { state: "PA", city: "TITUSVILLE" },
  "16360": { state: "PA", city: "TOWNVILLE" },
  "16361": { state: "PA", city: "TYLERSBURG" },
  "16362": { state: "PA", city: "UTICA" },
  "16364": { state: "PA", city: "VENUS" },
  "16365": { state: "PA", city: "WARREN" },
  "16366": { state: "PA", city: "WARREN" },
  "16367": { state: "PA", city: "WARREN" },
  "16368": { state: "PA", city: "WARREN" },
  "16369": { state: "PA", city: "WARREN" },
  "16370": { state: "PA", city: "WEST HICKORY" },
  "16371": { state: "PA", city: "YOUNGSVILLE" },
  "16372": { state: "PA", city: "CLINTONVILLE" },
  "16373": { state: "PA", city: "EMLENTON" },
  "16374": { state: "PA", city: "KENNERDELL" },
  "16375": { state: "PA", city: "LAMARTINE" },
  "16388": { state: "PA", city: "MEADVILLE" },
  "16401": { state: "PA", city: "ALBION" },
  "16402": { state: "PA", city: "BEAR LAKE" },
  "16403": { state: "PA", city: "CAMBRIDGE SPRINGS" },
  "16404": { state: "PA", city: "CENTERVILLE" },
  "16405": { state: "PA", city: "COLUMBUS" },
  "16406": { state: "PA", city: "CONNEAUTVILLE" },
  "16407": { state: "PA", city: "CORRY" },
  "16410": { state: "PA", city: "CRANESVILLE" },
  "16411": { state: "PA", city: "EAST SPRINGFIELD" },
  "16412": { state: "PA", city: "EDINBORO" },
  "16413": { state: "PA", city: "ELGIN" },
  "16415": { state: "PA", city: "FAIRVIEW" },
  "16416": { state: "PA", city: "GARLAND" },
  "16417": { state: "PA", city: "GIRARD" },
  "16420": { state: "PA", city: "GRAND VALLEY" },
  "16421": { state: "PA", city: "HARBORCREEK" },
  "16422": { state: "PA", city: "HARMONSBURG" },
  "16423": { state: "PA", city: "LAKE CITY" },
  "16424": { state: "PA", city: "LINESVILLE" },
  "16426": { state: "PA", city: "MC KEAN" },
  "16427": { state: "PA", city: "MILL VILLAGE" },
  "16428": { state: "PA", city: "NORTH EAST" },
  "16430": { state: "PA", city: "NORTH SPRINGFIELD" },
  "16432": { state: "PA", city: "RICEVILLE" },
  "16433": { state: "PA", city: "SAEGERTOWN" },
  "16434": { state: "PA", city: "SPARTANSBURG" },
  "16435": { state: "PA", city: "SPRINGBORO" },
  "16436": { state: "PA", city: "SPRING CREEK" },
  "16438": { state: "PA", city: "UNION CITY" },
  "16440": { state: "PA", city: "VENANGO" },
  "16441": { state: "PA", city: "WATERFORD" },
  "16442": { state: "PA", city: "WATTSBURG" },
  "16443": { state: "PA", city: "WEST SPRINGFIELD" },
  "16444": { state: "PA", city: "EDINBORO" },
  "16475": { state: "PA", city: "ALBION" },
  "16501": { state: "PA", city: "ERIE" },
  "16502": { state: "PA", city: "ERIE" },
  "16503": { state: "PA", city: "ERIE" },
  "16504": { state: "PA", city: "ERIE" },
  "16505": { state: "PA", city: "ERIE" },
  "16506": { state: "PA", city: "ERIE" },
  "16507": { state: "PA", city: "ERIE" },
  "16508": { state: "PA", city: "ERIE" },
  "16509": { state: "PA", city: "ERIE" },
  "16510": { state: "PA", city: "ERIE" },
  "16511": { state: "PA", city: "ERIE" },
  "16512": { state: "PA", city: "ERIE" },
  "16514": { state: "PA", city: "ERIE" },
  "16515": { state: "PA", city: "ERIE" },
  "16522": { state: "PA", city: "ERIE" },
  "16530": { state: "PA", city: "ERIE" },
  "16531": { state: "PA", city: "ERIE" },
  "16534": { state: "PA", city: "ERIE" },
  "16538": { state: "PA", city: "ERIE" },
  "16541": { state: "PA", city: "ERIE" },
  "16544": { state: "PA", city: "ERIE" },
  "16546": { state: "PA", city: "ERIE" },
  "16550": { state: "PA", city: "ERIE" },
  "16553": { state: "PA", city: "ERIE" },
  "16563": { state: "PA", city: "ERIE" },
  "16565": { state: "PA", city: "ERIE" },
  "16601": { state: "PA", city: "ALTOONA" },
  "16602": { state: "PA", city: "ALTOONA" },
  "16603": { state: "PA", city: "ALTOONA" },
  "16611": { state: "PA", city: "ALEXANDRIA" },
  "16613": { state: "PA", city: "ASHVILLE" },
  "16616": { state: "PA", city: "BECCARIA" },
  "16617": { state: "PA", city: "BELLWOOD" },
  "16619": { state: "PA", city: "BLANDBURG" },
  "16620": { state: "PA", city: "BRISBIN" },
  "16621": { state: "PA", city: "BROAD TOP" },
  "16622": { state: "PA", city: "CALVIN" },
  "16623": { state: "PA", city: "CASSVILLE" },
  "16624": { state: "PA", city: "CHEST SPRINGS" },
  "16625": { state: "PA", city: "CLAYSBURG" },
  "16627": { state: "PA", city: "COALPORT" },
  "16629": { state: "PA", city: "COUPON" },
  "16630": { state: "PA", city: "CRESSON" },
  "16631": { state: "PA", city: "CURRYVILLE" },
  "16633": { state: "PA", city: "DEFIANCE" },
  "16634": { state: "PA", city: "DUDLEY" },
  "16635": { state: "PA", city: "DUNCANSVILLE" },
  "16636": { state: "PA", city: "DYSART" },
  "16637": { state: "PA", city: "EAST FREEDOM" },
  "16638": { state: "PA", city: "ENTRIKEN" },
  "16639": { state: "PA", city: "FALLENTIMBER" },
  "16640": { state: "PA", city: "FLINTON" },
  "16641": { state: "PA", city: "GALLITZIN" },
  "16644": { state: "PA", city: "GLASGOW" },
  "16645": { state: "PA", city: "GLEN HOPE" },
  "16646": { state: "PA", city: "HASTINGS" },
  "16647": { state: "PA", city: "HESSTON" },
  "16648": { state: "PA", city: "HOLLIDAYSBURG" },
  "16650": { state: "PA", city: "HOPEWELL" },
  "16651": { state: "PA", city: "HOUTZDALE" },
  "16652": { state: "PA", city: "HUNTINGDON" },
  "16654": { state: "PA", city: "HUNTINGDON" },
  "16655": { state: "PA", city: "IMLER" },
  "16656": { state: "PA", city: "IRVONA" },
  "16657": { state: "PA", city: "JAMES CREEK" },
  "16659": { state: "PA", city: "LOYSBURG" },
  "16660": { state: "PA", city: "MC CONNELLSTOWN" },
  "16661": { state: "PA", city: "MADERA" },
  "16662": { state: "PA", city: "MARTINSBURG" },
  "16663": { state: "PA", city: "MORANN" },
  "16664": { state: "PA", city: "NEW ENTERPRISE" },
  "16665": { state: "PA", city: "NEWRY" },
  "16666": { state: "PA", city: "OSCEOLA MILLS" },
  "16667": { state: "PA", city: "OSTERBURG" },
  "16668": { state: "PA", city: "PATTON" },
  "16669": { state: "PA", city: "PETERSBURG" },
  "16670": { state: "PA", city: "QUEEN" },
  "16671": { state: "PA", city: "RAMEY" },
  "16672": { state: "PA", city: "RIDDLESBURG" },
  "16673": { state: "PA", city: "ROARING SPRING" },
  "16674": { state: "PA", city: "ROBERTSDALE" },
  "16675": { state: "PA", city: "SAINT BONIFACE" },
  "16677": { state: "PA", city: "SANDY RIDGE" },
  "16678": { state: "PA", city: "SAXTON" },
  "16679": { state: "PA", city: "SIX MILE RUN" },
  "16680": { state: "PA", city: "SMITHMILL" },
  "16681": { state: "PA", city: "SMOKERUN" },
  "16682": { state: "PA", city: "SPROUL" },
  "16683": { state: "PA", city: "SPRUCE CREEK" },
  "16684": { state: "PA", city: "TIPTON" },
  "16685": { state: "PA", city: "TODD" },
  "16686": { state: "PA", city: "TYRONE" },
  "16689": { state: "PA", city: "WATERFALL" },
  "16691": { state: "PA", city: "WELLS TANNERY" },
  "16692": { state: "PA", city: "WESTOVER" },
  "16693": { state: "PA", city: "WILLIAMSBURG" },
  "16694": { state: "PA", city: "WOOD" },
  "16695": { state: "PA", city: "WOODBURY" },
  "16698": { state: "PA", city: "HOUTZDALE" },
  "16699": { state: "PA", city: "CRESSON" },
  "16701": { state: "PA", city: "BRADFORD" },
  "16720": { state: "PA", city: "AUSTIN" },
  "16724": { state: "PA", city: "CROSBY" },
  "16725": { state: "PA", city: "CUSTER CITY" },
  "16726": { state: "PA", city: "CYCLONE" },
  "16727": { state: "PA", city: "DERRICK CITY" },
  "16728": { state: "PA", city: "DE YOUNG" },
  "16729": { state: "PA", city: "DUKE CENTER" },
  "16730": { state: "PA", city: "EAST SMETHPORT" },
  "16731": { state: "PA", city: "ELDRED" },
  "16732": { state: "PA", city: "GIFFORD" },
  "16733": { state: "PA", city: "HAZEL HURST" },
  "16734": { state: "PA", city: "JAMES CITY" },
  "16735": { state: "PA", city: "KANE" },
  "16738": { state: "PA", city: "LEWIS RUN" },
  "16740": { state: "PA", city: "MOUNT JEWETT" },
  "16743": { state: "PA", city: "PORT ALLEGANY" },
  "16744": { state: "PA", city: "REW" },
  "16745": { state: "PA", city: "RIXFORD" },
  "16746": { state: "PA", city: "ROULETTE" },
  "16748": { state: "PA", city: "SHINGLEHOUSE" },
  "16749": { state: "PA", city: "SMETHPORT" },
  "16750": { state: "PA", city: "TURTLEPOINT" },
  "16801": { state: "PA", city: "STATE COLLEGE" },
  "16802": { state: "PA", city: "UNIVERSITY PARK" },
  "16803": { state: "PA", city: "STATE COLLEGE" },
  "16804": { state: "PA", city: "STATE COLLEGE" },
  "16805": { state: "PA", city: "STATE COLLEGE" },
  "16820": { state: "PA", city: "AARONSBURG" },
  "16821": { state: "PA", city: "ALLPORT" },
  "16822": { state: "PA", city: "BEECH CREEK" },
  "16823": { state: "PA", city: "BELLEFONTE" },
  "16825": { state: "PA", city: "BIGLER" },
  "16826": { state: "PA", city: "BLANCHARD" },
  "16827": { state: "PA", city: "BOALSBURG" },
  "16828": { state: "PA", city: "CENTRE HALL" },
  "16829": { state: "PA", city: "CLARENCE" },
  "16830": { state: "PA", city: "CLEARFIELD" },
  "16832": { state: "PA", city: "COBURN" },
  "16833": { state: "PA", city: "CURWENSVILLE" },
  "16834": { state: "PA", city: "DRIFTING" },
  "16835": { state: "PA", city: "FLEMING" },
  "16836": { state: "PA", city: "FRENCHVILLE" },
  "16837": { state: "PA", city: "GLEN RICHEY" },
  "16838": { state: "PA", city: "GRAMPIAN" },
  "16839": { state: "PA", city: "GRASSFLAT" },
  "16840": { state: "PA", city: "HAWK RUN" },
  "16841": { state: "PA", city: "HOWARD" },
  "16843": { state: "PA", city: "HYDE" },
  "16844": { state: "PA", city: "JULIAN" },
  "16845": { state: "PA", city: "KARTHAUS" },
  "16847": { state: "PA", city: "KYLERTOWN" },
  "16848": { state: "PA", city: "LAMAR" },
  "16849": { state: "PA", city: "LANSE" },
  "16850": { state: "PA", city: "LECONTES MILLS" },
  "16851": { state: "PA", city: "LEMONT" },
  "16852": { state: "PA", city: "MADISONBURG" },
  "16853": { state: "PA", city: "MILESBURG" },
  "16854": { state: "PA", city: "MILLHEIM" },
  "16855": { state: "PA", city: "MINERAL SPRINGS" },
  "16856": { state: "PA", city: "MINGOVILLE" },
  "16858": { state: "PA", city: "MORRISDALE" },
  "16859": { state: "PA", city: "MOSHANNON" },
  "16860": { state: "PA", city: "MUNSON" },
  "16861": { state: "PA", city: "NEW MILLPORT" },
  "16863": { state: "PA", city: "OLANTA" },
  "16864": { state: "PA", city: "ORVISTON" },
  "16865": { state: "PA", city: "PENNSYLVANIA FURNACE" },
  "16866": { state: "PA", city: "PHILIPSBURG" },
  "16868": { state: "PA", city: "PINE GROVE MILLS" },
  "16870": { state: "PA", city: "PORT MATILDA" },
  "16871": { state: "PA", city: "POTTERSDALE" },
  "16872": { state: "PA", city: "REBERSBURG" },
  "16873": { state: "PA", city: "SHAWVILLE" },
  "16874": { state: "PA", city: "SNOW SHOE" },
  "16875": { state: "PA", city: "SPRING MILLS" },
  "16876": { state: "PA", city: "WALLACETON" },
  "16877": { state: "PA", city: "WARRIORS MARK" },
  "16878": { state: "PA", city: "WEST DECATUR" },
  "16879": { state: "PA", city: "WINBURNE" },
  "16881": { state: "PA", city: "WOODLAND" },
  "16882": { state: "PA", city: "WOODWARD" },
  "16901": { state: "PA", city: "WELLSBORO" },
  "16910": { state: "PA", city: "ALBA" },
  "16911": { state: "PA", city: "ARNOT" },
  "16912": { state: "PA", city: "BLOSSBURG" },
  "16914": { state: "PA", city: "COLUMBIA CROSS ROADS" },
  "16915": { state: "PA", city: "COUDERSPORT" },
  "16917": { state: "PA", city: "COVINGTON" },
  "16920": { state: "PA", city: "ELKLAND" },
  "16921": { state: "PA", city: "GAINES" },
  "16922": { state: "PA", city: "GALETON" },
  "16923": { state: "PA", city: "GENESEE" },
  "16925": { state: "PA", city: "GILLETT" },
  "16926": { state: "PA", city: "GRANVILLE SUMMIT" },
  "16927": { state: "PA", city: "HARRISON VALLEY" },
  "16928": { state: "PA", city: "KNOXVILLE" },
  "16929": { state: "PA", city: "LAWRENCEVILLE" },
  "16930": { state: "PA", city: "LIBERTY" },
  "16932": { state: "PA", city: "MAINESBURG" },
  "16933": { state: "PA", city: "MANSFIELD" },
  "16935": { state: "PA", city: "MIDDLEBURY CENTER" },
  "16936": { state: "PA", city: "MILLERTON" },
  "16937": { state: "PA", city: "MILLS" },
  "16938": { state: "PA", city: "MORRIS" },
  "16939": { state: "PA", city: "MORRIS RUN" },
  "16940": { state: "PA", city: "NELSON" },
  "16941": { state: "PA", city: "GENESEE" },
  "16942": { state: "PA", city: "OSCEOLA" },
  "16943": { state: "PA", city: "SABINSVILLE" },
  "16945": { state: "PA", city: "SYLVANIA" },
  "16946": { state: "PA", city: "TIOGA" },
  "16947": { state: "PA", city: "TROY" },
  "16948": { state: "PA", city: "ULYSSES" },
  "16950": { state: "PA", city: "WESTFIELD" },
  "17001": { state: "PA", city: "CAMP HILL" },
  "17002": { state: "PA", city: "ALLENSVILLE" },
  "17003": { state: "PA", city: "ANNVILLE" },
  "17004": { state: "PA", city: "BELLEVILLE" },
  "17005": { state: "PA", city: "BERRYSBURG" },
  "17006": { state: "PA", city: "BLAIN" },
  "17007": { state: "PA", city: "BOILING SPRINGS" },
  "17009": { state: "PA", city: "BURNHAM" },
  "17010": { state: "PA", city: "CAMPBELLTOWN" },
  "17011": { state: "PA", city: "CAMP HILL" },
  "17013": { state: "PA", city: "CARLISLE" },
  "17014": { state: "PA", city: "COCOLAMUS" },
  "17015": { state: "PA", city: "CARLISLE" },
  "17016": { state: "PA", city: "CORNWALL" },
  "17017": { state: "PA", city: "DALMATIA" },
  "17018": { state: "PA", city: "DAUPHIN" },
  "17019": { state: "PA", city: "DILLSBURG" },
  "17020": { state: "PA", city: "DUNCANNON" },
  "17021": { state: "PA", city: "EAST WATERFORD" },
  "17022": { state: "PA", city: "ELIZABETHTOWN" },
  "17023": { state: "PA", city: "ELIZABETHVILLE" },
  "17024": { state: "PA", city: "ELLIOTTSBURG" },
  "17025": { state: "PA", city: "ENOLA" },
  "17026": { state: "PA", city: "FREDERICKSBURG" },
  "17027": { state: "PA", city: "GRANTHAM" },
  "17028": { state: "PA", city: "GRANTVILLE" },
  "17029": { state: "PA", city: "GRANVILLE" },
  "17030": { state: "PA", city: "GRATZ" },
  "17032": { state: "PA", city: "HALIFAX" },
  "17033": { state: "PA", city: "HERSHEY" },
  "17034": { state: "PA", city: "HIGHSPIRE" },
  "17035": { state: "PA", city: "HONEY GROVE" },
  "17036": { state: "PA", city: "HUMMELSTOWN" },
  "17037": { state: "PA", city: "ICKESBURG" },
  "17038": { state: "PA", city: "JONESTOWN" },
  "17039": { state: "PA", city: "KLEINFELTERSVILLE" },
  "17040": { state: "PA", city: "LANDISBURG" },
  "17041": { state: "PA", city: "LAWN" },
  "17042": { state: "PA", city: "LEBANON" },
  "17043": { state: "PA", city: "LEMOYNE" },
  "17044": { state: "PA", city: "LEWISTOWN" },
  "17045": { state: "PA", city: "LIVERPOOL" },
  "17046": { state: "PA", city: "LEBANON" },
  "17047": { state: "PA", city: "LOYSVILLE" },
  "17048": { state: "PA", city: "LYKENS" },
  "17049": { state: "PA", city: "MC ALISTERVILLE" },
  "17050": { state: "PA", city: "MECHANICSBURG" },
  "17051": { state: "PA", city: "MC VEYTOWN" },
  "17052": { state: "PA", city: "MAPLETON DEPOT" },
  "17053": { state: "PA", city: "MARYSVILLE" },
  "17054": { state: "PA", city: "MATTAWANA" },
  "17055": { state: "PA", city: "MECHANICSBURG" },
  "17056": { state: "PA", city: "MEXICO" },
  "17057": { state: "PA", city: "MIDDLETOWN" },
  "17058": { state: "PA", city: "MIFFLIN" },
  "17059": { state: "PA", city: "MIFFLINTOWN" },
  "17060": { state: "PA", city: "MILL CREEK" },
  "17061": { state: "PA", city: "MILLERSBURG" },
  "17062": { state: "PA", city: "MILLERSTOWN" },
  "17063": { state: "PA", city: "MILROY" },
  "17064": { state: "PA", city: "MOUNT GRETNA" },
  "17065": { state: "PA", city: "MOUNT HOLLY SPRINGS" },
  "17066": { state: "PA", city: "MOUNT UNION" },
  "17067": { state: "PA", city: "MYERSTOWN" },
  "17068": { state: "PA", city: "NEW BLOOMFIELD" },
  "17069": { state: "PA", city: "NEW BUFFALO" },
  "17070": { state: "PA", city: "NEW CUMBERLAND" },
  "17071": { state: "PA", city: "NEW GERMANTOWN" },
  "17072": { state: "PA", city: "NEW KINGSTOWN" },
  "17073": { state: "PA", city: "NEWMANSTOWN" },
  "17074": { state: "PA", city: "NEWPORT" },
  "17075": { state: "PA", city: "NEWTON HAMILTON" },
  "17076": { state: "PA", city: "OAKLAND MILLS" },
  "17077": { state: "PA", city: "ONO" },
  "17078": { state: "PA", city: "PALMYRA" },
  "17080": { state: "PA", city: "PILLOW" },
  "17081": { state: "PA", city: "PLAINFIELD" },
  "17082": { state: "PA", city: "PORT ROYAL" },
  "17083": { state: "PA", city: "QUENTIN" },
  "17084": { state: "PA", city: "REEDSVILLE" },
  "17085": { state: "PA", city: "REXMONT" },
  "17086": { state: "PA", city: "RICHFIELD" },
  "17087": { state: "PA", city: "RICHLAND" },
  "17088": { state: "PA", city: "SCHAEFFERSTOWN" },
  "17089": { state: "PA", city: "CAMP HILL" },
  "17090": { state: "PA", city: "SHERMANS DALE" },
  "17093": { state: "PA", city: "SUMMERDALE" },
  "17094": { state: "PA", city: "THOMPSONTOWN" },
  "17097": { state: "PA", city: "WICONISCO" },
  "17098": { state: "PA", city: "WILLIAMSTOWN" },
  "17099": { state: "PA", city: "YEAGERTOWN" },
  "17101": { state: "PA", city: "HARRISBURG" },
  "17102": { state: "PA", city: "HARRISBURG" },
  "17103": { state: "PA", city: "HARRISBURG" },
  "17104": { state: "PA", city: "HARRISBURG" },
  "17105": { state: "PA", city: "HARRISBURG" },
  "17106": { state: "PA", city: "HARRISBURG" },
  "17107": { state: "PA", city: "HARRISBURG" },
  "17108": { state: "PA", city: "HARRISBURG" },
  "17109": { state: "PA", city: "HARRISBURG" },
  "17110": { state: "PA", city: "HARRISBURG" },
  "17111": { state: "PA", city: "HARRISBURG" },
  "17112": { state: "PA", city: "HARRISBURG" },
  "17113": { state: "PA", city: "HARRISBURG" },
  "17120": { state: "PA", city: "HARRISBURG" },
  "17121": { state: "PA", city: "HARRISBURG" },
  "17122": { state: "PA", city: "HARRISBURG" },
  "17123": { state: "PA", city: "HARRISBURG" },
  "17124": { state: "PA", city: "HARRISBURG" },
  "17125": { state: "PA", city: "HARRISBURG" },
  "17126": { state: "PA", city: "HARRISBURG" },
  "17127": { state: "PA", city: "HARRISBURG" },
  "17128": { state: "PA", city: "HARRISBURG" },
  "17129": { state: "PA", city: "HARRISBURG" },
  "17130": { state: "PA", city: "HARRISBURG" },
  "17140": { state: "PA", city: "HARRISBURG" },
  "17177": { state: "PA", city: "HARRISBURG" },
  "17201": { state: "PA", city: "CHAMBERSBURG" },
  "17202": { state: "PA", city: "CHAMBERSBURG" },
  "17210": { state: "PA", city: "AMBERSON" },
  "17211": { state: "PA", city: "ARTEMAS" },
  "17212": { state: "PA", city: "BIG COVE TANNERY" },
  "17213": { state: "PA", city: "BLAIRS MILLS" },
  "17214": { state: "PA", city: "BLUE RIDGE SUMMIT" },
  "17215": { state: "PA", city: "BURNT CABINS" },
  "17217": { state: "PA", city: "CONCORD" },
  "17219": { state: "PA", city: "DOYLESBURG" },
  "17220": { state: "PA", city: "DRY RUN" },
  "17221": { state: "PA", city: "FANNETTSBURG" },
  "17222": { state: "PA", city: "FAYETTEVILLE" },
  "17223": { state: "PA", city: "FORT LITTLETON" },
  "17224": { state: "PA", city: "FORT LOUDON" },
  "17225": { state: "PA", city: "GREENCASTLE" },
  "17228": { state: "PA", city: "HARRISONVILLE" },
  "17229": { state: "PA", city: "HUSTONTOWN" },
  "17231": { state: "PA", city: "LEMASTERS" },
  "17232": { state: "PA", city: "LURGAN" },
  "17233": { state: "PA", city: "MC CONNELLSBURG" },
  "17235": { state: "PA", city: "MARION" },
  "17236": { state: "PA", city: "MERCERSBURG" },
  "17237": { state: "PA", city: "MONT ALTO" },
  "17238": { state: "PA", city: "NEEDMORE" },
  "17239": { state: "PA", city: "NEELYTON" },
  "17240": { state: "PA", city: "NEWBURG" },
  "17241": { state: "PA", city: "NEWVILLE" },
  "17243": { state: "PA", city: "ORBISONIA" },
  "17244": { state: "PA", city: "ORRSTOWN" },
  "17246": { state: "PA", city: "PLEASANT HALL" },
  "17247": { state: "PA", city: "QUINCY" },
  "17249": { state: "PA", city: "ROCKHILL FURNACE" },
  "17250": { state: "PA", city: "ROUZERVILLE" },
  "17251": { state: "PA", city: "ROXBURY" },
  "17252": { state: "PA", city: "SAINT THOMAS" },
  "17253": { state: "PA", city: "SALTILLO" },
  "17254": { state: "PA", city: "SCOTLAND" },
  "17255": { state: "PA", city: "SHADE GAP" },
  "17256": { state: "PA", city: "SHADY GROVE" },
  "17257": { state: "PA", city: "SHIPPENSBURG" },
  "17260": { state: "PA", city: "SHIRLEYSBURG" },
  "17261": { state: "PA", city: "SOUTH MOUNTAIN" },
  "17262": { state: "PA", city: "SPRING RUN" },
  "17263": { state: "PA", city: "STATE LINE" },
  "17264": { state: "PA", city: "THREE SPRINGS" },
  "17265": { state: "PA", city: "UPPERSTRASBURG" },
  "17266": { state: "PA", city: "WALNUT BOTTOM" },
  "17267": { state: "PA", city: "WARFORDSBURG" },
  "17268": { state: "PA", city: "WAYNESBORO" },
  "17271": { state: "PA", city: "WILLOW HILL" },
  "17272": { state: "PA", city: "ZULLINGER" },
  "17301": { state: "PA", city: "ABBOTTSTOWN" },
  "17302": { state: "PA", city: "AIRVILLE" },
  "17303": { state: "PA", city: "ARENDTSVILLE" },
  "17304": { state: "PA", city: "ASPERS" },
  "17306": { state: "PA", city: "BENDERSVILLE" },
  "17307": { state: "PA", city: "BIGLERVILLE" },
  "17309": { state: "PA", city: "BROGUE" },
  "17310": { state: "PA", city: "CASHTOWN" },
  "17311": { state: "PA", city: "CODORUS" },
  "17312": { state: "PA", city: "CRALEY" },
  "17313": { state: "PA", city: "DALLASTOWN" },
  "17314": { state: "PA", city: "DELTA" },
  "17315": { state: "PA", city: "DOVER" },
  "17316": { state: "PA", city: "EAST BERLIN" },
  "17317": { state: "PA", city: "EAST PROSPECT" },
  "17318": { state: "PA", city: "EMIGSVILLE" },
  "17319": { state: "PA", city: "ETTERS" },
  "17320": { state: "PA", city: "FAIRFIELD" },
  "17321": { state: "PA", city: "FAWN GROVE" },
  "17322": { state: "PA", city: "FELTON" },
  "17323": { state: "PA", city: "FRANKLINTOWN" },
  "17324": { state: "PA", city: "GARDNERS" },
  "17325": { state: "PA", city: "GETTYSBURG" },
  "17327": { state: "PA", city: "GLEN ROCK" },
  "17329": { state: "PA", city: "GLENVILLE" },
  "17331": { state: "PA", city: "HANOVER" },
  "17332": { state: "PA", city: "HANOVER" },
  "17333": { state: "PA", city: "HANOVER" },
  "17334": { state: "PA", city: "HANOVER" },
  "17335": { state: "PA", city: "HANOVER" },
  "17337": { state: "PA", city: "IDAVILLE" },
  "17339": { state: "PA", city: "LEWISBERRY" },
  "17340": { state: "PA", city: "LITTLESTOWN" },
  "17342": { state: "PA", city: "LOGANVILLE" },
  "17343": { state: "PA", city: "MC KNIGHTSTOWN" },
  "17344": { state: "PA", city: "MC SHERRYSTOWN" },
  "17345": { state: "PA", city: "MANCHESTER" },
  "17347": { state: "PA", city: "MOUNT WOLF" },
  "17349": { state: "PA", city: "NEW FREEDOM" },
  "17350": { state: "PA", city: "NEW OXFORD" },
  "17352": { state: "PA", city: "NEW PARK" },
  "17353": { state: "PA", city: "ORRTANNA" },
  "17355": { state: "PA", city: "RAILROAD" },
  "17356": { state: "PA", city: "RED LION" },
  "17358": { state: "PA", city: "ROSSVILLE" },
  "17360": { state: "PA", city: "SEVEN VALLEYS" },
  "17361": { state: "PA", city: "SHREWSBURY" },
  "17362": { state: "PA", city: "SPRING GROVE" },
  "17363": { state: "PA", city: "STEWARTSTOWN" },
  "17364": { state: "PA", city: "THOMASVILLE" },
  "17365": { state: "PA", city: "WELLSVILLE" },
  "17366": { state: "PA", city: "WINDSOR" },
  "17368": { state: "PA", city: "WRIGHTSVILLE" },
  "17370": { state: "PA", city: "YORK HAVEN" },
  "17371": { state: "PA", city: "YORK NEW SALEM" },
  "17372": { state: "PA", city: "YORK SPRINGS" },
  "17375": { state: "PA", city: "PEACH GLEN" },
  "17401": { state: "PA", city: "YORK" },
  "17402": { state: "PA", city: "YORK" },
  "17403": { state: "PA", city: "YORK" },
  "17404": { state: "PA", city: "YORK" },
  "17405": { state: "PA", city: "YORK" },
  "17406": { state: "PA", city: "YORK" },
  "17407": { state: "PA", city: "YORK" },
  "17408": { state: "PA", city: "YORK" },
  "17501": { state: "PA", city: "AKRON" },
  "17502": { state: "PA", city: "BAINBRIDGE" },
  "17503": { state: "PA", city: "BART" },
  "17504": { state: "PA", city: "BAUSMAN" },
  "17505": { state: "PA", city: "BIRD IN HAND" },
  "17506": { state: "PA", city: "BLUE BALL" },
  "17507": { state: "PA", city: "BOWMANSVILLE" },
  "17508": { state: "PA", city: "BROWNSTOWN" },
  "17509": { state: "PA", city: "CHRISTIANA" },
  "17512": { state: "PA", city: "COLUMBIA" },
  "17516": { state: "PA", city: "CONESTOGA" },
  "17517": { state: "PA", city: "DENVER" },
  "17518": { state: "PA", city: "DRUMORE" },
  "17519": { state: "PA", city: "EAST EARL" },
  "17520": { state: "PA", city: "EAST PETERSBURG" },
  "17521": { state: "PA", city: "ELM" },
  "17522": { state: "PA", city: "EPHRATA" },
  "17527": { state: "PA", city: "GAP" },
  "17528": { state: "PA", city: "GOODVILLE" },
  "17529": { state: "PA", city: "GORDONVILLE" },
  "17532": { state: "PA", city: "HOLTWOOD" },
  "17533": { state: "PA", city: "HOPELAND" },
  "17534": { state: "PA", city: "INTERCOURSE" },
  "17535": { state: "PA", city: "KINZERS" },
  "17536": { state: "PA", city: "KIRKWOOD" },
  "17537": { state: "PA", city: "LAMPETER" },
  "17538": { state: "PA", city: "LANDISVILLE" },
  "17540": { state: "PA", city: "LEOLA" },
  "17543": { state: "PA", city: "LITITZ" },
  "17545": { state: "PA", city: "MANHEIM" },
  "17547": { state: "PA", city: "MARIETTA" },
  "17549": { state: "PA", city: "MARTINDALE" },
  "17550": { state: "PA", city: "MAYTOWN" },
  "17551": { state: "PA", city: "MILLERSVILLE" },
  "17552": { state: "PA", city: "MOUNT JOY" },
  "17554": { state: "PA", city: "MOUNTVILLE" },
  "17555": { state: "PA", city: "NARVON" },
  "17557": { state: "PA", city: "NEW HOLLAND" },
  "17560": { state: "PA", city: "NEW PROVIDENCE" },
  "17562": { state: "PA", city: "PARADISE" },
  "17563": { state: "PA", city: "PEACH BOTTOM" },
  "17564": { state: "PA", city: "PENRYN" },
  "17565": { state: "PA", city: "PEQUEA" },
  "17566": { state: "PA", city: "QUARRYVILLE" },
  "17567": { state: "PA", city: "REAMSTOWN" },
  "17568": { state: "PA", city: "REFTON" },
  "17569": { state: "PA", city: "REINHOLDS" },
  "17570": { state: "PA", city: "RHEEMS" },
  "17572": { state: "PA", city: "RONKS" },
  "17573": { state: "PA", city: "LANCASTER" },
  "17575": { state: "PA", city: "SILVER SPRING" },
  "17576": { state: "PA", city: "SMOKETOWN" },
  "17578": { state: "PA", city: "STEVENS" },
  "17579": { state: "PA", city: "STRASBURG" },
  "17580": { state: "PA", city: "TALMAGE" },
  "17581": { state: "PA", city: "TERRE HILL" },
  "17582": { state: "PA", city: "WASHINGTON BORO" },
  "17584": { state: "PA", city: "WILLOW STREET" },
  "17585": { state: "PA", city: "WITMER" },
  "17601": { state: "PA", city: "LANCASTER" },
  "17602": { state: "PA", city: "LANCASTER" },
  "17603": { state: "PA", city: "LANCASTER" },
  "17604": { state: "PA", city: "LANCASTER" },
  "17605": { state: "PA", city: "LANCASTER" },
  "17606": { state: "PA", city: "LANCASTER" },
  "17607": { state: "PA", city: "LANCASTER" },
  "17608": { state: "PA", city: "LANCASTER" },
  "17611": { state: "PA", city: "LANCASTER" },
  "17622": { state: "PA", city: "LANCASTER" },
  "17699": { state: "PA", city: "LANCASTER" },
  "17701": { state: "PA", city: "WILLIAMSPORT" },
  "17702": { state: "PA", city: "WILLIAMSPORT" },
  "17703": { state: "PA", city: "WILLIAMSPORT" },
  "17705": { state: "PA", city: "WILLIAMSPORT" },
  "17720": { state: "PA", city: "ANTES FORT" },
  "17721": { state: "PA", city: "AVIS" },
  "17723": { state: "PA", city: "CAMMAL" },
  "17724": { state: "PA", city: "CANTON" },
  "17726": { state: "PA", city: "CASTANEA" },
  "17727": { state: "PA", city: "CEDAR RUN" },
  "17728": { state: "PA", city: "COGAN STATION" },
  "17729": { state: "PA", city: "CROSS FORK" },
  "17730": { state: "PA", city: "DEWART" },
  "17731": { state: "PA", city: "EAGLES MERE" },
  "17735": { state: "PA", city: "GROVER" },
  "17737": { state: "PA", city: "HUGHESVILLE" },
  "17739": { state: "PA", city: "JERSEY MILLS" },
  "17740": { state: "PA", city: "JERSEY SHORE" },
  "17742": { state: "PA", city: "LAIRDSVILLE" },
  "17744": { state: "PA", city: "LINDEN" },
  "17745": { state: "PA", city: "LOCK HAVEN" },
  "17747": { state: "PA", city: "LOGANTON" },
  "17748": { state: "PA", city: "MC ELHATTAN" },
  "17749": { state: "PA", city: "MC EWENSVILLE" },
  "17750": { state: "PA", city: "MACKEYVILLE" },
  "17751": { state: "PA", city: "MILL HALL" },
  "17752": { state: "PA", city: "MONTGOMERY" },
  "17754": { state: "PA", city: "MONTOURSVILLE" },
  "17756": { state: "PA", city: "MUNCY" },
  "17758": { state: "PA", city: "MUNCY VALLEY" },
  "17760": { state: "PA", city: "NORTH BEND" },
  "17762": { state: "PA", city: "PICTURE ROCKS" },
  "17763": { state: "PA", city: "RALSTON" },
  "17764": { state: "PA", city: "RENOVO" },
  "17765": { state: "PA", city: "ROARING BRANCH" },
  "17768": { state: "PA", city: "SHUNK" },
  "17769": { state: "PA", city: "SLATE RUN" },
  "17771": { state: "PA", city: "TROUT RUN" },
  "17772": { state: "PA", city: "TURBOTVILLE" },
  "17774": { state: "PA", city: "UNITYVILLE" },
  "17776": { state: "PA", city: "WATERVILLE" },
  "17777": { state: "PA", city: "WATSONTOWN" },
  "17778": { state: "PA", city: "WESTPORT" },
  "17779": { state: "PA", city: "WOOLRICH" },
  "17801": { state: "PA", city: "SUNBURY" },
  "17810": { state: "PA", city: "ALLENWOOD" },
  "17812": { state: "PA", city: "BEAVER SPRINGS" },
  "17813": { state: "PA", city: "BEAVERTOWN" },
  "17814": { state: "PA", city: "BENTON" },
  "17815": { state: "PA", city: "BLOOMSBURG" },
  "17820": { state: "PA", city: "CATAWISSA" },
  "17821": { state: "PA", city: "DANVILLE" },
  "17822": { state: "PA", city: "DANVILLE" },
  "17823": { state: "PA", city: "DORNSIFE" },
  "17824": { state: "PA", city: "ELYSBURG" },
  "17827": { state: "PA", city: "FREEBURG" },
  "17829": { state: "PA", city: "HARTLETON" },
  "17830": { state: "PA", city: "HERNDON" },
  "17831": { state: "PA", city: "HUMMELS WHARF" },
  "17832": { state: "PA", city: "MARION HEIGHTS" },
  "17833": { state: "PA", city: "KREAMER" },
  "17834": { state: "PA", city: "KULPMONT" },
  "17835": { state: "PA", city: "LAURELTON" },
  "17836": { state: "PA", city: "LECK KILL" },
  "17837": { state: "PA", city: "LEWISBURG" },
  "17840": { state: "PA", city: "LOCUST GAP" },
  "17841": { state: "PA", city: "MC CLURE" },
  "17842": { state: "PA", city: "MIDDLEBURG" },
  "17843": { state: "PA", city: "BEAVER SPRINGS" },
  "17844": { state: "PA", city: "MIFFLINBURG" },
  "17845": { state: "PA", city: "MILLMONT" },
  "17846": { state: "PA", city: "MILLVILLE" },
  "17847": { state: "PA", city: "MILTON" },
  "17850": { state: "PA", city: "MONTANDON" },
  "17851": { state: "PA", city: "MOUNT CARMEL" },
  "17853": { state: "PA", city: "MOUNT PLEASANT MILLS" },
  "17855": { state: "PA", city: "NEW BERLIN" },
  "17856": { state: "PA", city: "NEW COLUMBIA" },
  "17857": { state: "PA", city: "NORTHUMBERLAND" },
  "17858": { state: "PA", city: "NUMIDIA" },
  "17859": { state: "PA", city: "ORANGEVILLE" },
  "17860": { state: "PA", city: "PAXINOS" },
  "17861": { state: "PA", city: "PAXTONVILLE" },
  "17862": { state: "PA", city: "PENNS CREEK" },
  "17864": { state: "PA", city: "PORT TREVORTON" },
  "17865": { state: "PA", city: "POTTS GROVE" },
  "17866": { state: "PA", city: "COAL TOWNSHIP" },
  "17867": { state: "PA", city: "REBUCK" },
  "17868": { state: "PA", city: "RIVERSIDE" },
  "17870": { state: "PA", city: "SELINSGROVE" },
  "17872": { state: "PA", city: "SHAMOKIN" },
  "17876": { state: "PA", city: "SHAMOKIN DAM" },
  "17877": { state: "PA", city: "SNYDERTOWN" },
  "17878": { state: "PA", city: "STILLWATER" },
  "17880": { state: "PA", city: "SWENGEL" },
  "17881": { state: "PA", city: "TREVORTON" },
  "17882": { state: "PA", city: "TROXELVILLE" },
  "17884": { state: "PA", city: "WASHINGTONVILLE" },
  "17885": { state: "PA", city: "WEIKERT" },
  "17886": { state: "PA", city: "WEST MILTON" },
  "17887": { state: "PA", city: "WHITE DEER" },
  "17888": { state: "PA", city: "WILBURTON" },
  "17889": { state: "PA", city: "WINFIELD" },
  "17901": { state: "PA", city: "POTTSVILLE" },
  "17920": { state: "PA", city: "ARISTES" },
  "17921": { state: "PA", city: "ASHLAND" },
  "17922": { state: "PA", city: "AUBURN" },
  "17923": { state: "PA", city: "BRANCHDALE" },
  "17925": { state: "PA", city: "BROCKTON" },
  "17929": { state: "PA", city: "CRESSONA" },
  "17930": { state: "PA", city: "CUMBOLA" },
  "17931": { state: "PA", city: "FRACKVILLE" },
  "17932": { state: "PA", city: "FRACKVILLE" },
  "17933": { state: "PA", city: "FRIEDENSBURG" },
  "17934": { state: "PA", city: "GILBERTON" },
  "17935": { state: "PA", city: "GIRARDVILLE" },
  "17936": { state: "PA", city: "GORDON" },
  "17938": { state: "PA", city: "HEGINS" },
  "17941": { state: "PA", city: "KLINGERSTOWN" },
  "17943": { state: "PA", city: "LAVELLE" },
  "17944": { state: "PA", city: "LLEWELLYN" },
  "17945": { state: "PA", city: "LOCUSTDALE" },
  "17946": { state: "PA", city: "LOST CREEK" },
  "17948": { state: "PA", city: "MAHANOY CITY" },
  "17949": { state: "PA", city: "MAHANOY PLANE" },
  "17951": { state: "PA", city: "MAR LIN" },
  "17952": { state: "PA", city: "MARY D" },
  "17953": { state: "PA", city: "MIDDLEPORT" },
  "17954": { state: "PA", city: "MINERSVILLE" },
  "17957": { state: "PA", city: "MUIR" },
  "17959": { state: "PA", city: "NEW PHILADELPHIA" },
  "17960": { state: "PA", city: "NEW RINGGOLD" },
  "17961": { state: "PA", city: "ORWIGSBURG" },
  "17963": { state: "PA", city: "PINE GROVE" },
  "17964": { state: "PA", city: "PITMAN" },
  "17965": { state: "PA", city: "PORT CARBON" },
  "17966": { state: "PA", city: "RAVINE" },
  "17967": { state: "PA", city: "RINGTOWN" },
  "17968": { state: "PA", city: "SACRAMENTO" },
  "17970": { state: "PA", city: "SAINT CLAIR" },
  "17972": { state: "PA", city: "SCHUYLKILL HAVEN" },
  "17974": { state: "PA", city: "SELTZER" },
  "17976": { state: "PA", city: "SHENANDOAH" },
  "17978": { state: "PA", city: "SPRING GLEN" },
  "17979": { state: "PA", city: "SUMMIT STATION" },
  "17980": { state: "PA", city: "TOWER CITY" },
  "17981": { state: "PA", city: "TREMONT" },
  "17982": { state: "PA", city: "TUSCARORA" },
  "17983": { state: "PA", city: "VALLEY VIEW" },
  "17985": { state: "PA", city: "ZION GROVE" },
  "18001": { state: "PA", city: "LEHIGH VALLEY" },
  "18002": { state: "PA", city: "LEHIGH VALLEY" },
  "18003": { state: "PA", city: "LEHIGH VALLEY" },
  "18011": { state: "PA", city: "ALBURTIS" },
  "18012": { state: "PA", city: "AQUASHICOLA" },
  "18013": { state: "PA", city: "BANGOR" },
  "18014": { state: "PA", city: "BATH" },
  "18015": { state: "PA", city: "BETHLEHEM" },
  "18016": { state: "PA", city: "BETHLEHEM" },
  "18017": { state: "PA", city: "BETHLEHEM" },
  "18018": { state: "PA", city: "BETHLEHEM" },
  "18020": { state: "PA", city: "BETHLEHEM" },
  "18025": { state: "PA", city: "BETHLEHEM" },
  "18030": { state: "PA", city: "BOWMANSTOWN" },
  "18031": { state: "PA", city: "BREINIGSVILLE" },
  "18032": { state: "PA", city: "CATASAUQUA" },
  "18034": { state: "PA", city: "CENTER VALLEY" },
  "18035": { state: "PA", city: "CHERRYVILLE" },
  "18036": { state: "PA", city: "COOPERSBURG" },
  "18037": { state: "PA", city: "COPLAY" },
  "18038": { state: "PA", city: "DANIELSVILLE" },
  "18039": { state: "PA", city: "DURHAM" },
  "18040": { state: "PA", city: "EASTON" },
  "18041": { state: "PA", city: "EAST GREENVILLE" },
  "18042": { state: "PA", city: "EASTON" },
  "18043": { state: "PA", city: "EASTON" },
  "18044": { state: "PA", city: "EASTON" },
  "18045": { state: "PA", city: "EASTON" },
  "18046": { state: "PA", city: "EAST TEXAS" },
  "18049": { state: "PA", city: "EMMAUS" },
  "18051": { state: "PA", city: "FOGELSVILLE" },
  "18052": { state: "PA", city: "WHITEHALL" },
  "18053": { state: "PA", city: "GERMANSVILLE" },
  "18054": { state: "PA", city: "GREEN LANE" },
  "18055": { state: "PA", city: "HELLERTOWN" },
  "18056": { state: "PA", city: "HEREFORD" },
  "18058": { state: "PA", city: "KUNKLETOWN" },
  "18059": { state: "PA", city: "LAURYS STATION" },
  "18060": { state: "PA", city: "LIMEPORT" },
  "18062": { state: "PA", city: "MACUNGIE" },
  "18063": { state: "PA", city: "MARTINS CREEK" },
  "18064": { state: "PA", city: "NAZARETH" },
  "18065": { state: "PA", city: "NEFFS" },
  "18066": { state: "PA", city: "NEW TRIPOLI" },
  "18067": { state: "PA", city: "NORTHAMPTON" },
  "18068": { state: "PA", city: "OLD ZIONSVILLE" },
  "18069": { state: "PA", city: "OREFIELD" },
  "18070": { state: "PA", city: "PALM" },
  "18071": { state: "PA", city: "PALMERTON" },
  "18072": { state: "PA", city: "PEN ARGYL" },
  "18073": { state: "PA", city: "PENNSBURG" },
  "18074": { state: "PA", city: "PERKIOMENVILLE" },
  "18076": { state: "PA", city: "RED HILL" },
  "18077": { state: "PA", city: "RIEGELSVILLE" },
  "18078": { state: "PA", city: "SCHNECKSVILLE" },
  "18079": { state: "PA", city: "SLATEDALE" },
  "18080": { state: "PA", city: "SLATINGTON" },
  "18081": { state: "PA", city: "SPRINGTOWN" },
  "18083": { state: "PA", city: "STOCKERTOWN" },
  "18084": { state: "PA", city: "SUMNEYTOWN" },
  "18085": { state: "PA", city: "TATAMY" },
  "18086": { state: "PA", city: "TREICHLERS" },
  "18087": { state: "PA", city: "TREXLERTOWN" },
  "18088": { state: "PA", city: "WALNUTPORT" },
  "18091": { state: "PA", city: "WIND GAP" },
  "18092": { state: "PA", city: "ZIONSVILLE" },
  "18098": { state: "PA", city: "EMMAUS" },
  "18099": { state: "PA", city: "EMMAUS" },
  "18101": { state: "PA", city: "ALLENTOWN" },
  "18102": { state: "PA", city: "ALLENTOWN" },
  "18103": { state: "PA", city: "ALLENTOWN" },
  "18104": { state: "PA", city: "ALLENTOWN" },
  "18105": { state: "PA", city: "ALLENTOWN" },
  "18106": { state: "PA", city: "ALLENTOWN" },
  "18109": { state: "PA", city: "ALLENTOWN" },
  "18195": { state: "PA", city: "ALLENTOWN" },
  "18201": { state: "PA", city: "HAZLETON" },
  "18202": { state: "PA", city: "HAZLETON" },
  "18210": { state: "PA", city: "ALBRIGHTSVILLE" },
  "18211": { state: "PA", city: "ANDREAS" },
  "18212": { state: "PA", city: "ASHFIELD" },
  "18214": { state: "PA", city: "BARNESVILLE" },
  "18216": { state: "PA", city: "BEAVER MEADOWS" },
  "18218": { state: "PA", city: "COALDALE" },
  "18219": { state: "PA", city: "CONYNGHAM" },
  "18220": { state: "PA", city: "DELANO" },
  "18221": { state: "PA", city: "DRIFTON" },
  "18222": { state: "PA", city: "DRUMS" },
  "18223": { state: "PA", city: "EBERVALE" },
  "18224": { state: "PA", city: "FREELAND" },
  "18225": { state: "PA", city: "HARLEIGH" },
  "18229": { state: "PA", city: "JIM THORPE" },
  "18230": { state: "PA", city: "JUNEDALE" },
  "18231": { state: "PA", city: "KELAYRES" },
  "18232": { state: "PA", city: "LANSFORD" },
  "18234": { state: "PA", city: "LATTIMER MINES" },
  "18235": { state: "PA", city: "LEHIGHTON" },
  "18237": { state: "PA", city: "MCADOO" },
  "18239": { state: "PA", city: "MILNESVILLE" },
  "18240": { state: "PA", city: "NESQUEHONING" },
  "18241": { state: "PA", city: "NUREMBERG" },
  "18242": { state: "PA", city: "ONEIDA" },
  "18244": { state: "PA", city: "PARRYVILLE" },
  "18245": { state: "PA", city: "QUAKAKE" },
  "18246": { state: "PA", city: "ROCK GLEN" },
  "18247": { state: "PA", city: "SAINT JOHNS" },
  "18248": { state: "PA", city: "SHEPPTON" },
  "18249": { state: "PA", city: "SUGARLOAF" },
  "18250": { state: "PA", city: "SUMMIT HILL" },
  "18251": { state: "PA", city: "SYBERTSVILLE" },
  "18252": { state: "PA", city: "TAMAQUA" },
  "18254": { state: "PA", city: "TRESCKOW" },
  "18255": { state: "PA", city: "WEATHERLY" },
  "18256": { state: "PA", city: "WESTON" },
  "18301": { state: "PA", city: "EAST STROUDSBURG" },
  "18302": { state: "PA", city: "EAST STROUDSBURG" },
  "18320": { state: "PA", city: "ANALOMINK" },
  "18321": { state: "PA", city: "BARTONSVILLE" },
  "18322": { state: "PA", city: "BRODHEADSVILLE" },
  "18323": { state: "PA", city: "BUCK HILL FALLS" },
  "18324": { state: "PA", city: "BUSHKILL" },
  "18325": { state: "PA", city: "CANADENSIS" },
  "18326": { state: "PA", city: "CRESCO" },
  "18327": { state: "PA", city: "DELAWARE WATER GAP" },
  "18328": { state: "PA", city: "DINGMANS FERRY" },
  "18330": { state: "PA", city: "EFFORT" },
  "18331": { state: "PA", city: "GILBERT" },
  "18332": { state: "PA", city: "HENRYVILLE" },
  "18333": { state: "PA", city: "KRESGEVILLE" },
  "18334": { state: "PA", city: "LONG POND" },
  "18335": { state: "PA", city: "MARSHALLS CREEK" },
  "18336": { state: "PA", city: "MATAMORAS" },
  "18337": { state: "PA", city: "MILFORD" },
  "18340": { state: "PA", city: "MILLRIFT" },
  "18341": { state: "PA", city: "MINISINK HILLS" },
  "18342": { state: "PA", city: "MOUNTAINHOME" },
  "18343": { state: "PA", city: "MOUNT BETHEL" },
  "18344": { state: "PA", city: "MOUNT POCONO" },
  "18346": { state: "PA", city: "POCONO SUMMIT" },
  "18347": { state: "PA", city: "POCONO LAKE" },
  "18348": { state: "PA", city: "POCONO LAKE PRESERVE" },
  "18349": { state: "PA", city: "POCONO MANOR" },
  "18350": { state: "PA", city: "POCONO PINES" },
  "18351": { state: "PA", city: "PORTLAND" },
  "18352": { state: "PA", city: "REEDERS" },
  "18353": { state: "PA", city: "SAYLORSBURG" },
  "18354": { state: "PA", city: "SCIOTA" },
  "18355": { state: "PA", city: "SCOTRUN" },
  "18356": { state: "PA", city: "SHAWNEE ON DELAWARE" },
  "18357": { state: "PA", city: "SKYTOP" },
  "18360": { state: "PA", city: "STROUDSBURG" },
  "18370": { state: "PA", city: "SWIFTWATER" },
  "18371": { state: "PA", city: "TAMIMENT" },
  "18372": { state: "PA", city: "TANNERSVILLE" },
  "18403": { state: "PA", city: "ARCHBALD" },
  "18405": { state: "PA", city: "BEACH LAKE" },
  "18407": { state: "PA", city: "CARBONDALE" },
  "18410": { state: "PA", city: "CHINCHILLA" },
  "18411": { state: "PA", city: "CLARKS SUMMIT" },
  "18413": { state: "PA", city: "CLIFFORD" },
  "18414": { state: "PA", city: "DALTON" },
  "18415": { state: "PA", city: "DAMASCUS" },
  "18416": { state: "PA", city: "ELMHURST" },
  "18417": { state: "PA", city: "EQUINUNK" },
  "18419": { state: "PA", city: "FACTORYVILLE" },
  "18420": { state: "PA", city: "FLEETVILLE" },
  "18421": { state: "PA", city: "FOREST CITY" },
  "18424": { state: "PA", city: "GOULDSBORO" },
  "18425": { state: "PA", city: "GREELEY" },
  "18426": { state: "PA", city: "GREENTOWN" },
  "18427": { state: "PA", city: "HAMLIN" },
  "18428": { state: "PA", city: "HAWLEY" },
  "18430": { state: "PA", city: "HERRICK CENTER" },
  "18431": { state: "PA", city: "HONESDALE" },
  "18433": { state: "PA", city: "JERMYN" },
  "18434": { state: "PA", city: "JESSUP" },
  "18435": { state: "PA", city: "LACKAWAXEN" },
  "18436": { state: "PA", city: "LAKE ARIEL" },
  "18437": { state: "PA", city: "LAKE COMO" },
  "18438": { state: "PA", city: "LAKEVILLE" },
  "18439": { state: "PA", city: "LAKEWOOD" },
  "18440": { state: "PA", city: "LA PLUME" },
  "18441": { state: "PA", city: "LENOXVILLE" },
  "18443": { state: "PA", city: "MILANVILLE" },
  "18444": { state: "PA", city: "MOSCOW" },
  "18445": { state: "PA", city: "NEWFOUNDLAND" },
  "18446": { state: "PA", city: "NICHOLSON" },
  "18447": { state: "PA", city: "OLYPHANT" },
  "18448": { state: "PA", city: "OLYPHANT" },
  "18449": { state: "PA", city: "ORSON" },
  "18451": { state: "PA", city: "PAUPACK" },
  "18452": { state: "PA", city: "PECKVILLE" },
  "18453": { state: "PA", city: "PLEASANT MOUNT" },
  "18454": { state: "PA", city: "POYNTELLE" },
  "18455": { state: "PA", city: "PRESTON PARK" },
  "18456": { state: "PA", city: "PROMPTON" },
  "18457": { state: "PA", city: "ROWLAND" },
  "18458": { state: "PA", city: "SHOHOLA" },
  "18459": { state: "PA", city: "SOUTH CANAAN" },
  "18460": { state: "PA", city: "SOUTH STERLING" },
  "18461": { state: "PA", city: "STARLIGHT" },
  "18462": { state: "PA", city: "STARRUCCA" },
  "18463": { state: "PA", city: "STERLING" },
  "18464": { state: "PA", city: "TAFTON" },
  "18465": { state: "PA", city: "THOMPSON" },
  "18466": { state: "PA", city: "TOBYHANNA" },
  "18469": { state: "PA", city: "TYLER HILL" },
  "18470": { state: "PA", city: "UNION DALE" },
  "18471": { state: "PA", city: "WAVERLY" },
  "18472": { state: "PA", city: "WAYMART" },
  "18473": { state: "PA", city: "WHITE MILLS" },
  "18501": { state: "PA", city: "SCRANTON" },
  "18502": { state: "PA", city: "SCRANTON" },
  "18503": { state: "PA", city: "SCRANTON" },
  "18504": { state: "PA", city: "SCRANTON" },
  "18505": { state: "PA", city: "SCRANTON" },
  "18507": { state: "PA", city: "MOOSIC" },
  "18508": { state: "PA", city: "SCRANTON" },
  "18509": { state: "PA", city: "SCRANTON" },
  "18510": { state: "PA", city: "SCRANTON" },
  "18512": { state: "PA", city: "SCRANTON" },
  "18515": { state: "PA", city: "SCRANTON" },
  "18517": { state: "PA", city: "TAYLOR" },
  "18518": { state: "PA", city: "OLD FORGE" },
  "18519": { state: "PA", city: "SCRANTON" },
  "18540": { state: "PA", city: "SCRANTON" },
  "18577": { state: "PA", city: "SCRANTON" },
  "18601": { state: "PA", city: "BEACH HAVEN" },
  "18602": { state: "PA", city: "BEAR CREEK" },
  "18603": { state: "PA", city: "BERWICK" },
  "18610": { state: "PA", city: "BLAKESLEE" },
  "18611": { state: "PA", city: "CAMBRA" },
  "18612": { state: "PA", city: "DALLAS" },
  "18614": { state: "PA", city: "DUSHORE" },
  "18615": { state: "PA", city: "FALLS" },
  "18616": { state: "PA", city: "FORKSVILLE" },
  "18617": { state: "PA", city: "GLEN LYON" },
  "18618": { state: "PA", city: "HARVEYS LAKE" },
  "18619": { state: "PA", city: "HILLSGROVE" },
  "18621": { state: "PA", city: "HUNLOCK CREEK" },
  "18622": { state: "PA", city: "HUNTINGTON MILLS" },
  "18623": { state: "PA", city: "LACEYVILLE" },
  "18624": { state: "PA", city: "LAKE HARMONY" },
  "18625": { state: "PA", city: "LAKE WINOLA" },
  "18626": { state: "PA", city: "LAPORTE" },
  "18627": { state: "PA", city: "LEHMAN" },
  "18628": { state: "PA", city: "LOPEZ" },
  "18629": { state: "PA", city: "MEHOOPANY" },
  "18630": { state: "PA", city: "MESHOPPEN" },
  "18631": { state: "PA", city: "MIFFLINVILLE" },
  "18632": { state: "PA", city: "MILDRED" },
  "18634": { state: "PA", city: "NANTICOKE" },
  "18635": { state: "PA", city: "NESCOPECK" },
  "18636": { state: "PA", city: "NOXEN" },
  "18640": { state: "PA", city: "PITTSTON" },
  "18641": { state: "PA", city: "PITTSTON" },
  "18642": { state: "PA", city: "DURYEA" },
  "18643": { state: "PA", city: "PITTSTON" },
  "18644": { state: "PA", city: "WYOMING" },
  "18651": { state: "PA", city: "PLYMOUTH" },
  "18653": { state: "PA", city: "RANSOM" },
  "18654": { state: "PA", city: "SHAWANESE" },
  "18655": { state: "PA", city: "SHICKSHINNY" },
  "18656": { state: "PA", city: "SWEET VALLEY" },
  "18657": { state: "PA", city: "TUNKHANNOCK" },
  "18660": { state: "PA", city: "WAPWALLOPEN" },
  "18661": { state: "PA", city: "WHITE HAVEN" },
  "18701": { state: "PA", city: "WILKES BARRE" },
  "18702": { state: "PA", city: "WILKES BARRE" },
  "18703": { state: "PA", city: "WILKES BARRE" },
  "18704": { state: "PA", city: "KINGSTON" },
  "18705": { state: "PA", city: "WILKES BARRE" },
  "18706": { state: "PA", city: "WILKES BARRE" },
  "18707": { state: "PA", city: "MOUNTAIN TOP" },
  "18708": { state: "PA", city: "SHAVERTOWN" },
  "18709": { state: "PA", city: "LUZERNE" },
  "18710": { state: "PA", city: "WILKES BARRE" },
  "18711": { state: "PA", city: "WILKES BARRE" },
  "18762": { state: "PA", city: "WILKES BARRE" },
  "18764": { state: "PA", city: "WILKES BARRE" },
  "18765": { state: "PA", city: "WILKES BARRE" },
  "18766": { state: "PA", city: "WILKES BARRE" },
  "18767": { state: "PA", city: "WILKES BARRE" },
  "18769": { state: "PA", city: "WILKES BARRE" },
  "18773": { state: "PA", city: "WILKES BARRE" },
  "18801": { state: "PA", city: "MONTROSE" },
  "18810": { state: "PA", city: "ATHENS" },
  "18812": { state: "PA", city: "BRACKNEY" },
  "18813": { state: "PA", city: "BROOKLYN" },
  "18814": { state: "PA", city: "BURLINGTON" },
  "18815": { state: "PA", city: "CAMPTOWN" },
  "18816": { state: "PA", city: "DIMOCK" },
  "18817": { state: "PA", city: "EAST SMITHFIELD" },
  "18818": { state: "PA", city: "FRIENDSVILLE" },
  "18820": { state: "PA", city: "GIBSON" },
  "18821": { state: "PA", city: "GREAT BEND" },
  "18822": { state: "PA", city: "HALLSTEAD" },
  "18823": { state: "PA", city: "HARFORD" },
  "18824": { state: "PA", city: "HOP BOTTOM" },
  "18825": { state: "PA", city: "JACKSON" },
  "18826": { state: "PA", city: "KINGSLEY" },
  "18827": { state: "PA", city: "LANESBORO" },
  "18828": { state: "PA", city: "LAWTON" },
  "18829": { state: "PA", city: "LE RAYSVILLE" },
  "18830": { state: "PA", city: "LITTLE MEADOWS" },
  "18831": { state: "PA", city: "MILAN" },
  "18832": { state: "PA", city: "MONROETON" },
  "18833": { state: "PA", city: "NEW ALBANY" },
  "18834": { state: "PA", city: "NEW MILFORD" },
  "18837": { state: "PA", city: "ROME" },
  "18840": { state: "PA", city: "SAYRE" },
  "18842": { state: "PA", city: "SOUTH GIBSON" },
  "18843": { state: "PA", city: "SOUTH MONTROSE" },
  "18844": { state: "PA", city: "SPRINGVILLE" },
  "18845": { state: "PA", city: "STEVENSVILLE" },
  "18846": { state: "PA", city: "SUGAR RUN" },
  "18847": { state: "PA", city: "SUSQUEHANNA" },
  "18848": { state: "PA", city: "TOWANDA" },
  "18850": { state: "PA", city: "ULSTER" },
  "18851": { state: "PA", city: "WARREN CENTER" },
  "18853": { state: "PA", city: "WYALUSING" },
  "18854": { state: "PA", city: "WYSOX" },
  "18901": { state: "PA", city: "DOYLESTOWN" },
  "18902": { state: "PA", city: "DOYLESTOWN" },
  "18910": { state: "PA", city: "BEDMINSTER" },
  "18911": { state: "PA", city: "BLOOMING GLEN" },
  "18912": { state: "PA", city: "BUCKINGHAM" },
  "18913": { state: "PA", city: "CARVERSVILLE" },
  "18914": { state: "PA", city: "CHALFONT" },
  "18915": { state: "PA", city: "COLMAR" },
  "18916": { state: "PA", city: "DANBORO" },
  "18917": { state: "PA", city: "DUBLIN" },
  "18918": { state: "PA", city: "EARLINGTON" },
  "18920": { state: "PA", city: "ERWINNA" },
  "18921": { state: "PA", city: "FERNDALE" },
  "18922": { state: "PA", city: "FOREST GROVE" },
  "18923": { state: "PA", city: "FOUNTAINVILLE" },
  "18925": { state: "PA", city: "FURLONG" },
  "18927": { state: "PA", city: "HILLTOWN" },
  "18928": { state: "PA", city: "HOLICONG" },
  "18929": { state: "PA", city: "JAMISON" },
  "18930": { state: "PA", city: "KINTNERSVILLE" },
  "18931": { state: "PA", city: "LAHASKA" },
  "18932": { state: "PA", city: "LINE LEXINGTON" },
  "18933": { state: "PA", city: "LUMBERVILLE" },
  "18934": { state: "PA", city: "MECHANICSVILLE" },
  "18935": { state: "PA", city: "MILFORD SQUARE" },
  "18936": { state: "PA", city: "MONTGOMERYVILLE" },
  "18938": { state: "PA", city: "NEW HOPE" },
  "18940": { state: "PA", city: "NEWTOWN" },
  "18942": { state: "PA", city: "OTTSVILLE" },
  "18943": { state: "PA", city: "PENNS PARK" },
  "18944": { state: "PA", city: "PERKASIE" },
  "18946": { state: "PA", city: "PINEVILLE" },
  "18947": { state: "PA", city: "PIPERSVILLE" },
  "18949": { state: "PA", city: "PLUMSTEADVILLE" },
  "18950": { state: "PA", city: "POINT PLEASANT" },
  "18951": { state: "PA", city: "QUAKERTOWN" },
  "18953": { state: "PA", city: "REVERE" },
  "18954": { state: "PA", city: "RICHBORO" },
  "18955": { state: "PA", city: "RICHLANDTOWN" },
  "18956": { state: "PA", city: "RUSHLAND" },
  "18957": { state: "PA", city: "SALFORD" },
  "18958": { state: "PA", city: "SALFORDVILLE" },
  "18960": { state: "PA", city: "SELLERSVILLE" },
  "18962": { state: "PA", city: "SILVERDALE" },
  "18963": { state: "PA", city: "SOLEBURY" },
  "18964": { state: "PA", city: "SOUDERTON" },
  "18966": { state: "PA", city: "SOUTHAMPTON" },
  "18968": { state: "PA", city: "SPINNERSTOWN" },
  "18969": { state: "PA", city: "TELFORD" },
  "18970": { state: "PA", city: "TRUMBAUERSVILLE" },
  "18971": { state: "PA", city: "TYLERSPORT" },
  "18972": { state: "PA", city: "UPPER BLACK EDDY" },
  "18974": { state: "PA", city: "WARMINSTER" },
  "18976": { state: "PA", city: "WARRINGTON" },
  "18977": { state: "PA", city: "WASHINGTON CROSSING" },
  "18979": { state: "PA", city: "WOXALL" },
  "18980": { state: "PA", city: "WYCOMBE" },
  "18981": { state: "PA", city: "ZIONHILL" },
  "18991": { state: "PA", city: "WARMINSTER" },
  "19001": { state: "PA", city: "ABINGTON" },
  "19002": { state: "PA", city: "AMBLER" },
  "19003": { state: "PA", city: "ARDMORE" },
  "19004": { state: "PA", city: "BALA CYNWYD" },
  "19006": { state: "PA", city: "HUNTINGDON VALLEY" },
  "19007": { state: "PA", city: "BRISTOL" },
  "19008": { state: "PA", city: "BROOMALL" },
  "19009": { state: "PA", city: "BRYN ATHYN" },
  "19010": { state: "PA", city: "BRYN MAWR" },
  "19012": { state: "PA", city: "CHELTENHAM" },
  "19013": { state: "PA", city: "CHESTER" },
  "19014": { state: "PA", city: "ASTON" },
  "19015": { state: "PA", city: "BROOKHAVEN" },
  "19016": { state: "PA", city: "CHESTER" },
  "19017": { state: "PA", city: "CHESTER HEIGHTS" },
  "19018": { state: "PA", city: "CLIFTON HEIGHTS" },
  "19019": { state: "PA", city: "PHILADELPHIA" },
  "19020": { state: "PA", city: "BENSALEM" },
  "19021": { state: "PA", city: "CROYDON" },
  "19022": { state: "PA", city: "CRUM LYNNE" },
  "19023": { state: "PA", city: "DARBY" },
  "19025": { state: "PA", city: "DRESHER" },
  "19026": { state: "PA", city: "DREXEL HILL" },
  "19027": { state: "PA", city: "ELKINS PARK" },
  "19028": { state: "PA", city: "EDGEMONT" },
  "19029": { state: "PA", city: "ESSINGTON" },
  "19030": { state: "PA", city: "FAIRLESS HILLS" },
  "19031": { state: "PA", city: "FLOURTOWN" },
  "19032": { state: "PA", city: "FOLCROFT" },
  "19033": { state: "PA", city: "FOLSOM" },
  "19034": { state: "PA", city: "FORT WASHINGTON" },
  "19035": { state: "PA", city: "GLADWYNE" },
  "19036": { state: "PA", city: "GLENOLDEN" },
  "19037": { state: "PA", city: "GLEN RIDDLE LIMA" },
  "19038": { state: "PA", city: "GLENSIDE" },
  "19039": { state: "PA", city: "GRADYVILLE" },
  "19040": { state: "PA", city: "HATBORO" },
  "19041": { state: "PA", city: "HAVERFORD" },
  "19043": { state: "PA", city: "HOLMES" },
  "19044": { state: "PA", city: "HORSHAM" },
  "19046": { state: "PA", city: "JENKINTOWN" },
  "19047": { state: "PA", city: "LANGHORNE" },
  "19048": { state: "PA", city: "FORT WASHINGTON" },
  "19049": { state: "PA", city: "FORT WASHINGTON" },
  "19050": { state: "PA", city: "LANSDOWNE" },
  "19052": { state: "PA", city: "LENNI" },
  "19053": { state: "PA", city: "FEASTERVILLE TREVOSE" },
  "19054": { state: "PA", city: "LEVITTOWN" },
  "19055": { state: "PA", city: "LEVITTOWN" },
  "19056": { state: "PA", city: "LEVITTOWN" },
  "19057": { state: "PA", city: "LEVITTOWN" },
  "19058": { state: "PA", city: "LEVITTOWN" },
  "19060": { state: "PA", city: "GARNET VALLEY" },
  "19061": { state: "PA", city: "MARCUS HOOK" },
  "19063": { state: "PA", city: "MEDIA" },
  "19064": { state: "PA", city: "SPRINGFIELD" },
  "19065": { state: "PA", city: "MEDIA" },
  "19066": { state: "PA", city: "MERION STATION" },
  "19067": { state: "PA", city: "MORRISVILLE" },
  "19070": { state: "PA", city: "MORTON" },
  "19072": { state: "PA", city: "NARBERTH" },
  "19073": { state: "PA", city: "NEWTOWN SQUARE" },
  "19074": { state: "PA", city: "NORWOOD" },
  "19075": { state: "PA", city: "ORELAND" },
  "19076": { state: "PA", city: "PROSPECT PARK" },
  "19078": { state: "PA", city: "RIDLEY PARK" },
  "19079": { state: "PA", city: "SHARON HILL" },
  "19081": { state: "PA", city: "SWARTHMORE" },
  "19082": { state: "PA", city: "UPPER DARBY" },
  "19083": { state: "PA", city: "HAVERTOWN" },
  "19085": { state: "PA", city: "VILLANOVA" },
  "19086": { state: "PA", city: "WALLINGFORD" },
  "19087": { state: "PA", city: "WAYNE" },
  "19090": { state: "PA", city: "WILLOW GROVE" },
  "19091": { state: "PA", city: "MEDIA" },
  "19092": { state: "PA", city: "PHILADELPHIA" },
  "19093": { state: "PA", city: "PHILADELPHIA" },
  "19094": { state: "PA", city: "WOODLYN" },
  "19095": { state: "PA", city: "WYNCOTE" },
  "19096": { state: "PA", city: "WYNNEWOOD" },
  "19098": { state: "PA", city: "HOLMES" },
  "19099": { state: "PA", city: "PHILADELPHIA" },
  "19101": { state: "PA", city: "PHILADELPHIA" },
  "19102": { state: "PA", city: "PHILADELPHIA" },
  "19103": { state: "PA", city: "PHILADELPHIA" },
  "19104": { state: "PA", city: "PHILADELPHIA" },
  "19105": { state: "PA", city: "PHILADELPHIA" },
  "19106": { state: "PA", city: "PHILADELPHIA" },
  "19107": { state: "PA", city: "PHILADELPHIA" },
  "19108": { state: "PA", city: "PHILADELPHIA" },
  "19109": { state: "PA", city: "PHILADELPHIA" },
  "19110": { state: "PA", city: "PHILADELPHIA" },
  "19111": { state: "PA", city: "PHILADELPHIA" },
  "19112": { state: "PA", city: "PHILADELPHIA" },
  "19113": { state: "PA", city: "PHILADELPHIA" },
  "19114": { state: "PA", city: "PHILADELPHIA" },
  "19115": { state: "PA", city: "PHILADELPHIA" },
  "19116": { state: "PA", city: "PHILADELPHIA" },
  "19118": { state: "PA", city: "PHILADELPHIA" },
  "19119": { state: "PA", city: "PHILADELPHIA" },
  "19120": { state: "PA", city: "PHILADELPHIA" },
  "19121": { state: "PA", city: "PHILADELPHIA" },
  "19122": { state: "PA", city: "PHILADELPHIA" },
  "19123": { state: "PA", city: "PHILADELPHIA" },
  "19124": { state: "PA", city: "PHILADELPHIA" },
  "19125": { state: "PA", city: "PHILADELPHIA" },
  "19126": { state: "PA", city: "PHILADELPHIA" },
  "19127": { state: "PA", city: "PHILADELPHIA" },
  "19128": { state: "PA", city: "PHILADELPHIA" },
  "19129": { state: "PA", city: "PHILADELPHIA" },
  "19130": { state: "PA", city: "PHILADELPHIA" },
  "19131": { state: "PA", city: "PHILADELPHIA" },
  "19132": { state: "PA", city: "PHILADELPHIA" },
  "19133": { state: "PA", city: "PHILADELPHIA" },
  "19134": { state: "PA", city: "PHILADELPHIA" },
  "19135": { state: "PA", city: "PHILADELPHIA" },
  "19136": { state: "PA", city: "PHILADELPHIA" },
  "19137": { state: "PA", city: "PHILADELPHIA" },
  "19138": { state: "PA", city: "PHILADELPHIA" },
  "19139": { state: "PA", city: "PHILADELPHIA" },
  "19140": { state: "PA", city: "PHILADELPHIA" },
  "19141": { state: "PA", city: "PHILADELPHIA" },
  "19142": { state: "PA", city: "PHILADELPHIA" },
  "19143": { state: "PA", city: "PHILADELPHIA" },
  "19144": { state: "PA", city: "PHILADELPHIA" },
  "19145": { state: "PA", city: "PHILADELPHIA" },
  "19146": { state: "PA", city: "PHILADELPHIA" },
  "19147": { state: "PA", city: "PHILADELPHIA" },
  "19148": { state: "PA", city: "PHILADELPHIA" },
  "19149": { state: "PA", city: "PHILADELPHIA" },
  "19150": { state: "PA", city: "PHILADELPHIA" },
  "19151": { state: "PA", city: "PHILADELPHIA" },
  "19152": { state: "PA", city: "PHILADELPHIA" },
  "19153": { state: "PA", city: "PHILADELPHIA" },
  "19154": { state: "PA", city: "PHILADELPHIA" },
  "19155": { state: "PA", city: "PHILADELPHIA" },
  "19160": { state: "PA", city: "PHILADELPHIA" },
  "19161": { state: "PA", city: "PHILADELPHIA" },
  "19162": { state: "PA", city: "PHILADELPHIA" },
  "19170": { state: "PA", city: "PHILADELPHIA" },
  "19171": { state: "PA", city: "PHILADELPHIA" },
  "19172": { state: "PA", city: "PHILADELPHIA" },
  "19173": { state: "PA", city: "PHILADELPHIA" },
  "19175": { state: "PA", city: "PHILADELPHIA" },
  "19176": { state: "PA", city: "PHILADELPHIA" },
  "19177": { state: "PA", city: "PHILADELPHIA" },
  "19178": { state: "PA", city: "PHILADELPHIA" },
  "19179": { state: "PA", city: "PHILADELPHIA" },
  "19181": { state: "PA", city: "PHILADELPHIA" },
  "19182": { state: "PA", city: "PHILADELPHIA" },
  "19183": { state: "PA", city: "PHILADELPHIA" },
  "19184": { state: "PA", city: "PHILADELPHIA" },
  "19185": { state: "PA", city: "PHILADELPHIA" },
  "19187": { state: "PA", city: "PHILADELPHIA" },
  "19188": { state: "PA", city: "PHILADELPHIA" },
  "19190": { state: "PA", city: "PHILADELPHIA" },
  "19191": { state: "PA", city: "PHILADELPHIA" },
  "19192": { state: "PA", city: "PHILADELPHIA" },
  "19193": { state: "PA", city: "PHILADELPHIA" },
  "19194": { state: "PA", city: "PHILADELPHIA" },
  "19195": { state: "PA", city: "PHILADELPHIA" },
  "19196": { state: "PA", city: "PHILADELPHIA" },
  "19197": { state: "PA", city: "PHILADELPHIA" },
  "19244": { state: "PA", city: "PHILADELPHIA" },
  "19255": { state: "PA", city: "PHILADELPHIA" },
  "19301": { state: "PA", city: "PAOLI" },
  "19310": { state: "PA", city: "ATGLEN" },
  "19311": { state: "PA", city: "AVONDALE" },
  "19312": { state: "PA", city: "BERWYN" },
  "19316": { state: "PA", city: "BRANDAMORE" },
  "19317": { state: "PA", city: "CHADDS FORD" },
  "19318": { state: "PA", city: "CHATHAM" },
  "19319": { state: "PA", city: "CHEYNEY" },
  "19320": { state: "PA", city: "COATESVILLE" },
  "19330": { state: "PA", city: "COCHRANVILLE" },
  "19331": { state: "PA", city: "CONCORDVILLE" },
  "19333": { state: "PA", city: "DEVON" },
  "19335": { state: "PA", city: "DOWNINGTOWN" },
  "19339": { state: "PA", city: "CONCORDVILLE" },
  "19340": { state: "PA", city: "CONCORDVILLE" },
  "19341": { state: "PA", city: "EXTON" },
  "19342": { state: "PA", city: "GLEN MILLS" },
  "19343": { state: "PA", city: "GLENMOORE" },
  "19344": { state: "PA", city: "HONEY BROOK" },
  "19345": { state: "PA", city: "IMMACULATA" },
  "19346": { state: "PA", city: "KELTON" },
  "19347": { state: "PA", city: "KEMBLESVILLE" },
  "19348": { state: "PA", city: "KENNETT SQUARE" },
  "19350": { state: "PA", city: "LANDENBERG" },
  "19351": { state: "PA", city: "LEWISVILLE" },
  "19352": { state: "PA", city: "LINCOLN UNIVERSITY" },
  "19353": { state: "PA", city: "LIONVILLE" },
  "19354": { state: "PA", city: "LYNDELL" },
  "19355": { state: "PA", city: "MALVERN" },
  "19357": { state: "PA", city: "MENDENHALL" },
  "19358": { state: "PA", city: "MODENA" },
  "19360": { state: "PA", city: "NEW LONDON" },
  "19362": { state: "PA", city: "NOTTINGHAM" },
  "19363": { state: "PA", city: "OXFORD" },
  "19365": { state: "PA", city: "PARKESBURG" },
  "19366": { state: "PA", city: "POCOPSON" },
  "19367": { state: "PA", city: "POMEROY" },
  "19369": { state: "PA", city: "SADSBURYVILLE" },
  "19372": { state: "PA", city: "THORNDALE" },
  "19373": { state: "PA", city: "THORNTON" },
  "19374": { state: "PA", city: "TOUGHKENAMON" },
  "19375": { state: "PA", city: "UNIONVILLE" },
  "19376": { state: "PA", city: "WAGONTOWN" },
  "19380": { state: "PA", city: "WEST CHESTER" },
  "19381": { state: "PA", city: "WEST CHESTER" },
  "19382": { state: "PA", city: "WEST CHESTER" },
  "19383": { state: "PA", city: "WEST CHESTER" },
  "19390": { state: "PA", city: "WEST GROVE" },
  "19395": { state: "PA", city: "WESTTOWN" },
  "19397": { state: "PA", city: "SOUTHEASTERN" },
  "19398": { state: "PA", city: "SOUTHEASTERN" },
  "19399": { state: "PA", city: "SOUTHEASTERN" },
  "19401": { state: "PA", city: "NORRISTOWN" },
  "19403": { state: "PA", city: "NORRISTOWN" },
  "19404": { state: "PA", city: "NORRISTOWN" },
  "19405": { state: "PA", city: "BRIDGEPORT" },
  "19406": { state: "PA", city: "KING OF PRUSSIA" },
  "19407": { state: "PA", city: "AUDUBON" },
  "19408": { state: "PA", city: "EAGLEVILLE" },
  "19409": { state: "PA", city: "FAIRVIEW VILLAGE" },
  "19415": { state: "PA", city: "EAGLEVILLE" },
  "19421": { state: "PA", city: "BIRCHRUNVILLE" },
  "19422": { state: "PA", city: "BLUE BELL" },
  "19423": { state: "PA", city: "CEDARS" },
  "19424": { state: "PA", city: "BLUE BELL" },
  "19425": { state: "PA", city: "CHESTER SPRINGS" },
  "19426": { state: "PA", city: "COLLEGEVILLE" },
  "19428": { state: "PA", city: "CONSHOHOCKEN" },
  "19429": { state: "PA", city: "CONSHOHOCKEN" },
  "19430": { state: "PA", city: "CREAMERY" },
  "19432": { state: "PA", city: "DEVAULT" },
  "19435": { state: "PA", city: "FREDERICK" },
  "19436": { state: "PA", city: "GWYNEDD" },
  "19437": { state: "PA", city: "GWYNEDD VALLEY" },
  "19438": { state: "PA", city: "HARLEYSVILLE" },
  "19440": { state: "PA", city: "HATFIELD" },
  "19441": { state: "PA", city: "HARLEYSVILLE" },
  "19442": { state: "PA", city: "KIMBERTON" },
  "19443": { state: "PA", city: "KULPSVILLE" },
  "19444": { state: "PA", city: "LAFAYETTE HILL" },
  "19446": { state: "PA", city: "LANSDALE" },
  "19450": { state: "PA", city: "LEDERACH" },
  "19451": { state: "PA", city: "MAINLAND" },
  "19453": { state: "PA", city: "MONT CLARE" },
  "19454": { state: "PA", city: "NORTH WALES" },
  "19455": { state: "PA", city: "NORTH WALES" },
  "19456": { state: "PA", city: "OAKS" },
  "19457": { state: "PA", city: "PARKER FORD" },
  "19460": { state: "PA", city: "PHOENIXVILLE" },
  "19462": { state: "PA", city: "PLYMOUTH MEETING" },
  "19464": { state: "PA", city: "POTTSTOWN" },
  "19465": { state: "PA", city: "POTTSTOWN" },
  "19468": { state: "PA", city: "ROYERSFORD" },
  "19470": { state: "PA", city: "SAINT PETERS" },
  "19472": { state: "PA", city: "SASSAMANSVILLE" },
  "19473": { state: "PA", city: "SCHWENKSVILLE" },
  "19474": { state: "PA", city: "SKIPPACK" },
  "19475": { state: "PA", city: "SPRING CITY" },
  "19477": { state: "PA", city: "SPRING HOUSE" },
  "19478": { state: "PA", city: "SPRING MOUNT" },
  "19480": { state: "PA", city: "UWCHLAND" },
  "19481": { state: "PA", city: "VALLEY FORGE" },
  "19482": { state: "PA", city: "VALLEY FORGE" },
  "19484": { state: "PA", city: "VALLEY FORGE" },
  "19486": { state: "PA", city: "WEST POINT" },
  "19490": { state: "PA", city: "WORCESTER" },
  "19492": { state: "PA", city: "ZIEGLERVILLE" },
  "19493": { state: "PA", city: "VALLEY FORGE" },
  "19494": { state: "PA", city: "VALLEY FORGE" },
  "19495": { state: "PA", city: "VALLEY FORGE" },
  "19496": { state: "PA", city: "VALLEY FORGE" },
  "19501": { state: "PA", city: "ADAMSTOWN" },
  "19503": { state: "PA", city: "BALLY" },
  "19504": { state: "PA", city: "BARTO" },
  "19505": { state: "PA", city: "BECHTELSVILLE" },
  "19506": { state: "PA", city: "BERNVILLE" },
  "19507": { state: "PA", city: "BETHEL" },
  "19508": { state: "PA", city: "BIRDSBORO" },
  "19510": { state: "PA", city: "BLANDON" },
  "19511": { state: "PA", city: "BOWERS" },
  "19512": { state: "PA", city: "BOYERTOWN" },
  "19516": { state: "PA", city: "CENTERPORT" },
  "19518": { state: "PA", city: "DOUGLASSVILLE" },
  "19519": { state: "PA", city: "EARLVILLE" },
  "19520": { state: "PA", city: "ELVERSON" },
  "19522": { state: "PA", city: "FLEETWOOD" },
  "19523": { state: "PA", city: "GEIGERTOWN" },
  "19525": { state: "PA", city: "GILBERTSVILLE" },
  "19526": { state: "PA", city: "HAMBURG" },
  "19529": { state: "PA", city: "KEMPTON" },
  "19530": { state: "PA", city: "KUTZTOWN" },
  "19533": { state: "PA", city: "LEESPORT" },
  "19534": { state: "PA", city: "LENHARTSVILLE" },
  "19535": { state: "PA", city: "LIMEKILN" },
  "19536": { state: "PA", city: "LYON STATION" },
  "19538": { state: "PA", city: "MAXATAWNY" },
  "19539": { state: "PA", city: "MERTZTOWN" },
  "19540": { state: "PA", city: "MOHNTON" },
  "19541": { state: "PA", city: "MOHRSVILLE" },
  "19543": { state: "PA", city: "MORGANTOWN" },
  "19544": { state: "PA", city: "MOUNT AETNA" },
  "19545": { state: "PA", city: "NEW BERLINVILLE" },
  "19547": { state: "PA", city: "OLEY" },
  "19548": { state: "PA", city: "PINE FORGE" },
  "19549": { state: "PA", city: "PORT CLINTON" },
  "19550": { state: "PA", city: "REHRERSBURG" },
  "19551": { state: "PA", city: "ROBESONIA" },
  "19554": { state: "PA", city: "SHARTLESVILLE" },
  "19555": { state: "PA", city: "SHOEMAKERSVILLE" },
  "19559": { state: "PA", city: "STRAUSSTOWN" },
  "19560": { state: "PA", city: "TEMPLE" },
  "19562": { state: "PA", city: "TOPTON" },
  "19564": { state: "PA", city: "VIRGINVILLE" },
  "19565": { state: "PA", city: "WERNERSVILLE" },
  "19567": { state: "PA", city: "WOMELSDORF" },
  "19601": { state: "PA", city: "READING" },
  "19602": { state: "PA", city: "READING" },
  "19603": { state: "PA", city: "READING" },
  "19604": { state: "PA", city: "READING" },
  "19605": { state: "PA", city: "READING" },
  "19606": { state: "PA", city: "READING" },
  "19607": { state: "PA", city: "READING" },
  "19608": { state: "PA", city: "READING" },
  "19609": { state: "PA", city: "READING" },
  "19610": { state: "PA", city: "READING" },
  "19611": { state: "PA", city: "READING" },
  "19612": { state: "PA", city: "READING" },
  "19701": { state: "DE", city: "BEAR" },
  "19702": { state: "DE", city: "NEWARK" },
  "19703": { state: "DE", city: "CLAYMONT" },
  "19706": { state: "DE", city: "DELAWARE CITY" },
  "19707": { state: "DE", city: "HOCKESSIN" },
  "19708": { state: "DE", city: "KIRKWOOD" },
  "19709": { state: "DE", city: "MIDDLETOWN" },
  "19710": { state: "DE", city: "MONTCHANIN" },
  "19711": { state: "DE", city: "NEWARK" },
  "19712": { state: "DE", city: "NEWARK" },
  "19713": { state: "DE", city: "NEWARK" },
  "19714": { state: "DE", city: "NEWARK" },
  "19715": { state: "DE", city: "NEWARK" },
  "19716": { state: "DE", city: "NEWARK" },
  "19717": { state: "DE", city: "NEWARK" },
  "19718": { state: "DE", city: "NEWARK" },
  "19720": { state: "DE", city: "NEW CASTLE" },
  "19721": { state: "DE", city: "NEW CASTLE" },
  "19725": { state: "DE", city: "NEWARK" },
  "19726": { state: "DE", city: "NEW CASTLE" },
  "19730": { state: "DE", city: "ODESSA" },
  "19731": { state: "DE", city: "PORT PENN" },
  "19732": { state: "DE", city: "ROCKLAND" },
  "19733": { state: "DE", city: "SAINT GEORGES" },
  "19734": { state: "DE", city: "TOWNSEND" },
  "19735": { state: "DE", city: "WINTERTHUR" },
  "19736": { state: "DE", city: "YORKLYN" },
  "19801": { state: "DE", city: "WILMINGTON" },
  "19802": { state: "DE", city: "WILMINGTON" },
  "19803": { state: "DE", city: "WILMINGTON" },
  "19804": { state: "DE", city: "WILMINGTON" },
  "19805": { state: "DE", city: "WILMINGTON" },
  "19806": { state: "DE", city: "WILMINGTON" },
  "19807": { state: "DE", city: "WILMINGTON" },
  "19808": { state: "DE", city: "WILMINGTON" },
  "19809": { state: "DE", city: "WILMINGTON" },
  "19810": { state: "DE", city: "WILMINGTON" },
  "19850": { state: "DE", city: "WILMINGTON" },
  "19880": { state: "DE", city: "WILMINGTON" },
  "19884": { state: "DE", city: "WILMINGTON" },
  "19885": { state: "DE", city: "WILMINGTON" },
  "19886": { state: "DE", city: "WILMINGTON" },
  "19890": { state: "DE", city: "WILMINGTON" },
  "19891": { state: "DE", city: "WILMINGTON" },
  "19892": { state: "DE", city: "WILMINGTON" },
  "19893": { state: "DE", city: "WILMINGTON" },
  "19894": { state: "DE", city: "WILMINGTON" },
  "19895": { state: "DE", city: "WILMINGTON" },
  "19896": { state: "DE", city: "WILMINGTON" },
  "19897": { state: "DE", city: "WILMINGTON" },
  "19898": { state: "DE", city: "WILMINGTON" },
  "19899": { state: "DE", city: "WILMINGTON" },
  "19901": { state: "DE", city: "DOVER" },
  "19902": { state: "DE", city: "DOVER AFB" },
  "19903": { state: "DE", city: "DOVER" },
  "19904": { state: "DE", city: "DOVER" },
  "19905": { state: "DE", city: "DOVER" },
  "19906": { state: "DE", city: "DOVER" },
  "19930": { state: "DE", city: "BETHANY BEACH" },
  "19931": { state: "DE", city: "BETHEL" },
  "19933": { state: "DE", city: "BRIDGEVILLE" },
  "19934": { state: "DE", city: "CAMDEN WYOMING" },
  "19936": { state: "DE", city: "CHESWOLD" },
  "19938": { state: "DE", city: "CLAYTON" },
  "19939": { state: "DE", city: "DAGSBORO" },
  "19940": { state: "DE", city: "DELMAR" },
  "19941": { state: "DE", city: "ELLENDALE" },
  "19943": { state: "DE", city: "FELTON" },
  "19944": { state: "DE", city: "FENWICK ISLAND" },
  "19945": { state: "DE", city: "FRANKFORD" },
  "19946": { state: "DE", city: "FREDERICA" },
  "19947": { state: "DE", city: "GEORGETOWN" },
  "19950": { state: "DE", city: "GREENWOOD" },
  "19951": { state: "DE", city: "HARBESON" },
  "19952": { state: "DE", city: "HARRINGTON" },
  "19953": { state: "DE", city: "HARTLY" },
  "19954": { state: "DE", city: "HOUSTON" },
  "19955": { state: "DE", city: "KENTON" },
  "19956": { state: "DE", city: "LAUREL" },
  "19958": { state: "DE", city: "LEWES" },
  "19960": { state: "DE", city: "LINCOLN" },
  "19961": { state: "DE", city: "LITTLE CREEK" },
  "19962": { state: "DE", city: "MAGNOLIA" },
  "19963": { state: "DE", city: "MILFORD" },
  "19964": { state: "DE", city: "MARYDEL" },
  "19966": { state: "DE", city: "MILLSBORO" },
  "19967": { state: "DE", city: "MILLVILLE" },
  "19968": { state: "DE", city: "MILTON" },
  "19969": { state: "DE", city: "NASSAU" },
  "19970": { state: "DE", city: "OCEAN VIEW" },
  "19971": { state: "DE", city: "REHOBOTH BEACH" },
  "19973": { state: "DE", city: "SEAFORD" },
  "19975": { state: "DE", city: "SELBYVILLE" },
  "19977": { state: "DE", city: "SMYRNA" },
  "19979": { state: "DE", city: "VIOLA" },
  "19980": { state: "DE", city: "WOODSIDE" },
  "20001": { state: "DC", city: "WASHINGTON" },
  "20002": { state: "DC", city: "WASHINGTON" },
  "20003": { state: "DC", city: "WASHINGTON" },
  "20004": { state: "DC", city: "WASHINGTON" },
  "20005": { state: "DC", city: "WASHINGTON" },
  "20006": { state: "DC", city: "WASHINGTON" },
  "20007": { state: "DC", city: "WASHINGTON" },
  "20008": { state: "DC", city: "WASHINGTON" },
  "20009": { state: "DC", city: "WASHINGTON" },
  "20010": { state: "DC", city: "WASHINGTON" },
  "20011": { state: "DC", city: "WASHINGTON" },
  "20012": { state: "DC", city: "WASHINGTON" },
  "20013": { state: "DC", city: "WASHINGTON" },
  "20015": { state: "DC", city: "WASHINGTON" },
  "20016": { state: "DC", city: "WASHINGTON" },
  "20017": { state: "DC", city: "WASHINGTON" },
  "20018": { state: "DC", city: "WASHINGTON" },
  "20019": { state: "DC", city: "WASHINGTON" },
  "20020": { state: "DC", city: "WASHINGTON" },
  "20022": { state: "DC", city: "WASHINGTON" },
  "20024": { state: "DC", city: "WASHINGTON" },
  "20026": { state: "DC", city: "WASHINGTON" },
  "20027": { state: "DC", city: "WASHINGTON" },
  "20029": { state: "DC", city: "WASHINGTON" },
  "20030": { state: "DC", city: "WASHINGTON" },
  "20032": { state: "DC", city: "WASHINGTON" },
  "20033": { state: "DC", city: "WASHINGTON" },
  "20035": { state: "DC", city: "WASHINGTON" },
  "20036": { state: "DC", city: "WASHINGTON" },
  "20037": { state: "DC", city: "WASHINGTON" },
  "20038": { state: "DC", city: "WASHINGTON" },
  "20039": { state: "DC", city: "WASHINGTON" },
  "20040": { state: "DC", city: "WASHINGTON" },
  "20041": { state: "DC", city: "WASHINGTON" },
  "20042": { state: "DC", city: "WASHINGTON" },
  "20043": { state: "DC", city: "WASHINGTON" },
  "20044": { state: "DC", city: "WASHINGTON" },
  "20045": { state: "DC", city: "WASHINGTON" },
  "20047": { state: "DC", city: "WASHINGTON" },
  "20049": { state: "DC", city: "WASHINGTON" },
  "20050": { state: "DC", city: "WASHINGTON" },
  "20052": { state: "DC", city: "WASHINGTON" },
  "20053": { state: "DC", city: "WASHINGTON" },
  "20055": { state: "DC", city: "WASHINGTON" },
  "20056": { state: "DC", city: "WASHINGTON" },
  "20057": { state: "DC", city: "WASHINGTON" },
  "20058": { state: "DC", city: "WASHINGTON" },
  "20059": { state: "DC", city: "WASHINGTON" },
  "20060": { state: "DC", city: "WASHINGTON" },
  "20061": { state: "DC", city: "WASHINGTON" },
  "20062": { state: "DC", city: "WASHINGTON" },
  "20063": { state: "DC", city: "WASHINGTON" },
  "20064": { state: "DC", city: "WASHINGTON" },
  "20065": { state: "DC", city: "WASHINGTON" },
  "20066": { state: "DC", city: "WASHINGTON" },
  "20067": { state: "DC", city: "WASHINGTON" },
  "20068": { state: "DC", city: "WASHINGTON" },
  "20069": { state: "DC", city: "WASHINGTON" },
  "20070": { state: "DC", city: "WASHINGTON" },
  "20071": { state: "DC", city: "WASHINGTON" },
  "20073": { state: "DC", city: "WASHINGTON" },
  "20074": { state: "DC", city: "WASHINGTON" },
  "20075": { state: "DC", city: "WASHINGTON" },
  "20076": { state: "DC", city: "WASHINGTON" },
  "20077": { state: "DC", city: "WASHINGTON" },
  "20078": { state: "DC", city: "WASHINGTON" },
  "20080": { state: "DC", city: "WASHINGTON" },
  "20081": { state: "DC", city: "WASHINGTON" },
  "20082": { state: "DC", city: "WASHINGTON" },
  "20090": { state: "DC", city: "WASHINGTON" },
  "20091": { state: "DC", city: "WASHINGTON" },
  "20101": { state: "VA", city: "DULLES" },
  "20102": { state: "VA", city: "DULLES" },
  "20103": { state: "VA", city: "DULLES" },
  "20104": { state: "VA", city: "DULLES" },
  "20105": { state: "VA", city: "ALDIE" },
  "20106": { state: "VA", city: "AMISSVILLE" },
  "20108": { state: "VA", city: "MANASSAS" },
  "20109": { state: "VA", city: "MANASSAS" },
  "20110": { state: "VA", city: "MANASSAS" },
  "20111": { state: "VA", city: "MANASSAS" },
  "20112": { state: "VA", city: "MANASSAS" },
  "20113": { state: "VA", city: "MANASSAS" },
  "20115": { state: "VA", city: "MARSHALL" },
  "20116": { state: "VA", city: "MARSHALL" },
  "20117": { state: "VA", city: "MIDDLEBURG" },
  "20118": { state: "VA", city: "MIDDLEBURG" },
  "20119": { state: "VA", city: "CATLETT" },
  "20120": { state: "VA", city: "CENTREVILLE" },
  "20121": { state: "VA", city: "CENTREVILLE" },
  "20122": { state: "VA", city: "CENTREVILLE" },
  "20124": { state: "VA", city: "CLIFTON" },
  "20128": { state: "VA", city: "ORLEAN" },
  "20129": { state: "VA", city: "PAEONIAN SPRINGS" },
  "20130": { state: "VA", city: "PARIS" },
  "20131": { state: "VA", city: "PHILOMONT" },
  "20132": { state: "VA", city: "PURCELLVILLE" },
  "20134": { state: "VA", city: "PURCELLVILLE" },
  "20135": { state: "VA", city: "BLUEMONT" },
  "20136": { state: "VA", city: "BRISTOW" },
  "20137": { state: "VA", city: "BROAD RUN" },
  "20138": { state: "VA", city: "CALVERTON" },
  "20139": { state: "VA", city: "CASANOVA" },
  "20140": { state: "VA", city: "RECTORTOWN" },
  "20141": { state: "VA", city: "ROUND HILL" },
  "20142": { state: "VA", city: "ROUND HILL" },
  "20143": { state: "VA", city: "CATHARPIN" },
  "20144": { state: "VA", city: "DELAPLANE" },
  "20146": { state: "VA", city: "ASHBURN" },
  "20147": { state: "VA", city: "ASHBURN" },
  "20148": { state: "VA", city: "ASHBURN" },
  "20149": { state: "VA", city: "ASHBURN" },
  "20151": { state: "VA", city: "CHANTILLY" },
  "20152": { state: "VA", city: "CHANTILLY" },
  "20153": { state: "VA", city: "CHANTILLY" },
  "20155": { state: "VA", city: "GAINESVILLE" },
  "20156": { state: "VA", city: "GAINESVILLE" },
  "20158": { state: "VA", city: "HAMILTON" },
  "20159": { state: "VA", city: "HAMILTON" },
  "20160": { state: "VA", city: "LINCOLN" },
  "20163": { state: "VA", city: "STERLING" },
  "20164": { state: "VA", city: "STERLING" },
  "20165": { state: "VA", city: "STERLING" },
  "20166": { state: "VA", city: "STERLING" },
  "20167": { state: "VA", city: "STERLING" },
  "20168": { state: "VA", city: "HAYMARKET" },
  "20169": { state: "VA", city: "HAYMARKET" },
  "20170": { state: "VA", city: "HERNDON" },
  "20171": { state: "VA", city: "HERNDON" },
  "20172": { state: "VA", city: "HERNDON" },
  "20175": { state: "VA", city: "LEESBURG" },
  "20176": { state: "VA", city: "LEESBURG" },
  "20177": { state: "VA", city: "LEESBURG" },
  "20178": { state: "VA", city: "LEESBURG" },
  "20180": { state: "VA", city: "LOVETTSVILLE" },
  "20181": { state: "VA", city: "NOKESVILLE" },
  "20182": { state: "VA", city: "NOKESVILLE" },
  "20184": { state: "VA", city: "UPPERVILLE" },
  "20185": { state: "VA", city: "UPPERVILLE" },
  "20186": { state: "VA", city: "WARRENTON" },
  "20187": { state: "VA", city: "WARRENTON" },
  "20188": { state: "VA", city: "WARRENTON" },
  "20189": { state: "VA", city: "DULLES" },
  "20190": { state: "VA", city: "RESTON" },
  "20191": { state: "VA", city: "RESTON" },
  "20192": { state: "VA", city: "HERNDON" },
  "20194": { state: "VA", city: "RESTON" },
  "20195": { state: "VA", city: "RESTON" },
  "20196": { state: "VA", city: "RESTON" },
  "20197": { state: "VA", city: "WATERFORD" },
  "20198": { state: "VA", city: "THE PLAINS" },
  "20201": { state: "DC", city: "WASHINGTON" },
  "20202": { state: "DC", city: "WASHINGTON" },
  "20203": { state: "DC", city: "WASHINGTON" },
  "20204": { state: "DC", city: "WASHINGTON" },
  "20206": { state: "DC", city: "WASHINGTON" },
  "20207": { state: "DC", city: "WASHINGTON" },
  "20208": { state: "DC", city: "WASHINGTON" },
  "20210": { state: "DC", city: "WASHINGTON" },
  "20211": { state: "DC", city: "WASHINGTON" },
  "20212": { state: "DC", city: "WASHINGTON" },
  "20213": { state: "DC", city: "WASHINGTON" },
  "20214": { state: "DC", city: "WASHINGTON" },
  "20215": { state: "DC", city: "WASHINGTON" },
  "20216": { state: "DC", city: "WASHINGTON" },
  "20217": { state: "DC", city: "WASHINGTON" },
  "20218": { state: "DC", city: "WASHINGTON" },
  "20219": { state: "DC", city: "WASHINGTON" },
  "20220": { state: "DC", city: "WASHINGTON" },
  "20221": { state: "DC", city: "WASHINGTON" },
  "20222": { state: "DC", city: "WASHINGTON" },
  "20223": { state: "DC", city: "WASHINGTON" },
  "20224": { state: "DC", city: "WASHINGTON" },
  "20226": { state: "DC", city: "WASHINGTON" },
  "20227": { state: "DC", city: "WASHINGTON" },
  "20228": { state: "DC", city: "WASHINGTON" },
  "20229": { state: "DC", city: "WASHINGTON" },
  "20230": { state: "DC", city: "WASHINGTON" },
  "20232": { state: "DC", city: "WASHINGTON" },
  "20233": { state: "DC", city: "WASHINGTON" },
  "20235": { state: "DC", city: "WASHINGTON" },
  "20237": { state: "DC", city: "WASHINGTON" },
  "20238": { state: "DC", city: "WASHINGTON" },
  "20239": { state: "DC", city: "WASHINGTON" },
  "20240": { state: "DC", city: "WASHINGTON" },
  "20241": { state: "DC", city: "WASHINGTON" },
  "20242": { state: "DC", city: "WASHINGTON" },
  "20244": { state: "DC", city: "WASHINGTON" },
  "20245": { state: "DC", city: "WASHINGTON" },
  "20250": { state: "DC", city: "WASHINGTON" },
  "20251": { state: "DC", city: "WASHINGTON" },
  "20252": { state: "DC", city: "WASHINGTON" },
  "20254": { state: "DC", city: "WASHINGTON" },
  "20260": { state: "DC", city: "WASHINGTON" },
  "20261": { state: "DC", city: "WASHINGTON" },
  "20262": { state: "DC", city: "WASHINGTON" },
  "20265": { state: "DC", city: "WASHINGTON" },
  "20266": { state: "DC", city: "WASHINGTON" },
  "20268": { state: "DC", city: "WASHINGTON" },
  "20270": { state: "DC", city: "WASHINGTON" },
  "20277": { state: "DC", city: "WASHINGTON" },
  "20289": { state: "DC", city: "WASHINGTON" },
  "20299": { state: "DC", city: "WASHINGTON" },
  "20301": { state: "DC", city: "WASHINGTON" },
  "20303": { state: "DC", city: "WASHINGTON" },
  "20306": { state: "DC", city: "WASHINGTON" },
  "20310": { state: "DC", city: "WASHINGTON" },
  "20314": { state: "DC", city: "WASHINGTON" },
  "20317": { state: "DC", city: "WASHINGTON" },
  "20318": { state: "DC", city: "WASHINGTON" },
  "20319": { state: "DC", city: "WASHINGTON" },
  "20330": { state: "DC", city: "WASHINGTON" },
  "20340": { state: "DC", city: "WASHINGTON" },
  "20350": { state: "DC", city: "WASHINGTON" },
  "20355": { state: "DC", city: "WASHINGTON" },
  "20370": { state: "DC", city: "WASHINGTON" },
  "20372": { state: "DC", city: "WASHINGTON" },
  "20373": { state: "DC", city: "NAVAL ANACOST ANNEX" },
  "20374": { state: "DC", city: "WASHINGTON NAVY YARD" },
  "20375": { state: "DC", city: "WASHINGTON" },
  "20376": { state: "DC", city: "WASHINGTON NAVY YARD" },
  "20380": { state: "DC", city: "WASHINGTON" },
  "20388": { state: "DC", city: "WASHINGTON NAVY YARD" },
  "20389": { state: "DC", city: "WASHINGTON" },
  "20390": { state: "DC", city: "WASHINGTON" },
  "20391": { state: "DC", city: "WASHINGTON NAVY YARD" },
  "20392": { state: "DC", city: "WASHINGTON" },
  "20393": { state: "DC", city: "WASHINGTON" },
  "20394": { state: "DC", city: "WASHINGTON" },
  "20395": { state: "DC", city: "WASHINGTON" },
  "20398": { state: "DC", city: "WASHINGTON NAVY YARD" },
  "20401": { state: "DC", city: "WASHINGTON" },
  "20402": { state: "DC", city: "WASHINGTON" },
  "20403": { state: "DC", city: "WASHINGTON" },
  "20404": { state: "DC", city: "WASHINGTON" },
  "20405": { state: "DC", city: "WASHINGTON" },
  "20406": { state: "DC", city: "WASHINGTON" },
  "20407": { state: "DC", city: "WASHINGTON" },
  "20408": { state: "DC", city: "WASHINGTON" },
  "20410": { state: "DC", city: "WASHINGTON" },
  "20411": { state: "DC", city: "WASHINGTON" },
  "20412": { state: "DC", city: "WASHINGTON" },
  "20413": { state: "DC", city: "WASHINGTON" },
  "20414": { state: "DC", city: "WASHINGTON" },
  "20415": { state: "DC", city: "WASHINGTON" },
  "20416": { state: "DC", city: "WASHINGTON" },
  "20417": { state: "DC", city: "WASHINGTON" },
  "20418": { state: "DC", city: "WASHINGTON" },
  "20419": { state: "DC", city: "WASHINGTON" },
  "20420": { state: "DC", city: "WASHINGTON" },
  "20421": { state: "DC", city: "WASHINGTON" },
  "20422": { state: "DC", city: "WASHINGTON" },
  "20423": { state: "DC", city: "WASHINGTON" },
  "20424": { state: "DC", city: "WASHINGTON" },
  "20425": { state: "DC", city: "WASHINGTON" },
  "20426": { state: "DC", city: "WASHINGTON" },
  "20427": { state: "DC", city: "WASHINGTON" },
  "20428": { state: "DC", city: "WASHINGTON" },
  "20429": { state: "DC", city: "WASHINGTON" },
  "20431": { state: "DC", city: "WASHINGTON" },
  "20433": { state: "DC", city: "WASHINGTON" },
  "20434": { state: "DC", city: "WASHINGTON" },
  "20435": { state: "DC", city: "WASHINGTON" },
  "20436": { state: "DC", city: "WASHINGTON" },
  "20437": { state: "DC", city: "WASHINGTON" },
  "20439": { state: "DC", city: "WASHINGTON" },
  "20440": { state: "DC", city: "WASHINGTON" },
  "20441": { state: "DC", city: "WASHINGTON" },
  "20442": { state: "DC", city: "WASHINGTON" },
  "20444": { state: "DC", city: "WASHINGTON" },
  "20447": { state: "DC", city: "WASHINGTON" },
  "20451": { state: "DC", city: "WASHINGTON" },
  "20453": { state: "DC", city: "WASHINGTON" },
  "20456": { state: "DC", city: "WASHINGTON" },
  "20460": { state: "DC", city: "WASHINGTON" },
  "20463": { state: "DC", city: "WASHINGTON" },
  "20468": { state: "DC", city: "WASHINGTON" },
  "20469": { state: "DC", city: "WASHINGTON" },
  "20470": { state: "DC", city: "WASHINGTON" },
  "20472": { state: "DC", city: "WASHINGTON" },
  "20500": { state: "DC", city: "WASHINGTON" },
  "20501": { state: "DC", city: "WASHINGTON" },
  "20502": { state: "DC", city: "WASHINGTON" },
  "20503": { state: "DC", city: "WASHINGTON" },
  "20504": { state: "DC", city: "WASHINGTON" },
  "20505": { state: "DC", city: "WASHINGTON" },
  "20506": { state: "DC", city: "WASHINGTON" },
  "20507": { state: "DC", city: "WASHINGTON" },
  "20508": { state: "DC", city: "WASHINGTON" },
  "20509": { state: "DC", city: "WASHINGTON" },
  "20510": { state: "DC", city: "WASHINGTON" },
  "20511": { state: "DC", city: "WASHINGTON" },
  "20515": { state: "DC", city: "WASHINGTON" },
  "20520": { state: "DC", city: "WASHINGTON" },
  "20521": { state: "DC", city: "WASHINGTON" },
  "20522": { state: "DC", city: "WASHINGTON" },
  "20523": { state: "DC", city: "WASHINGTON" },
  "20524": { state: "DC", city: "WASHINGTON" },
  "20525": { state: "DC", city: "WASHINGTON" },
  "20526": { state: "DC", city: "WASHINGTON" },
  "20527": { state: "DC", city: "WASHINGTON" },
  "20528": { state: "DC", city: "WASHINGTON" },
  "20529": { state: "DC", city: "WASHINGTON" },
  "20530": { state: "DC", city: "WASHINGTON" },
  "20531": { state: "DC", city: "WASHINGTON" },
  "20533": { state: "DC", city: "WASHINGTON" },
  "20534": { state: "DC", city: "WASHINGTON" },
  "20535": { state: "DC", city: "WASHINGTON" },
  "20536": { state: "DC", city: "WASHINGTON" },
  "20537": { state: "DC", city: "WASHINGTON" },
  "20538": { state: "DC", city: "WASHINGTON" },
  "20539": { state: "DC", city: "WASHINGTON" },
  "20540": { state: "DC", city: "WASHINGTON" },
  "20541": { state: "DC", city: "WASHINGTON" },
  "20542": { state: "DC", city: "WASHINGTON" },
  "20543": { state: "DC", city: "WASHINGTON" },
  "20544": { state: "DC", city: "WASHINGTON" },
  "20546": { state: "DC", city: "WASHINGTON" },
  "20547": { state: "DC", city: "WASHINGTON" },
  "20548": { state: "DC", city: "WASHINGTON" },
  "20549": { state: "DC", city: "WASHINGTON" },
  "20551": { state: "DC", city: "WASHINGTON" },
  "20552": { state: "DC", city: "WASHINGTON" },
  "20553": { state: "DC", city: "WASHINGTON" },
  "20554": { state: "DC", city: "WASHINGTON" },
  "20555": { state: "DC", city: "WASHINGTON" },
  "20557": { state: "DC", city: "WASHINGTON" },
  "20559": { state: "DC", city: "WASHINGTON" },
  "20560": { state: "DC", city: "WASHINGTON" },
  "20565": { state: "DC", city: "WASHINGTON" },
  "20566": { state: "DC", city: "WASHINGTON" },
  "20570": { state: "DC", city: "WASHINGTON" },
  "20571": { state: "DC", city: "WASHINGTON" },
  "20572": { state: "DC", city: "WASHINGTON" },
  "20573": { state: "DC", city: "WASHINGTON" },
  "20575": { state: "DC", city: "WASHINGTON" },
  "20576": { state: "DC", city: "WASHINGTON" },
  "20577": { state: "DC", city: "WASHINGTON" },
  "20578": { state: "DC", city: "WASHINGTON" },
  "20579": { state: "DC", city: "WASHINGTON" },
  "20580": { state: "DC", city: "WASHINGTON" },
  "20581": { state: "DC", city: "WASHINGTON" },
  "20585": { state: "DC", city: "WASHINGTON" },
  "20586": { state: "DC", city: "WASHINGTON" },
  "20588": { state: "MD", city: "DHS" },
  "20590": { state: "DC", city: "WASHINGTON" },
  "20591": { state: "DC", city: "WASHINGTON" },
  "20593": { state: "DC", city: "WASHINGTON" },
  "20594": { state: "DC", city: "WASHINGTON" },
  "20597": { state: "DC", city: "WASHINGTON" },
  "20598": { state: "VA", city: "DHS" },
  "20599": { state: "DC", city: "WASHINGTON" },
  "20601": { state: "MD", city: "WALDORF" },
  "20602": { state: "MD", city: "WALDORF" },
  "20603": { state: "MD", city: "WALDORF" },
  "20604": { state: "MD", city: "WALDORF" },
  "20606": { state: "MD", city: "ABELL" },
  "20607": { state: "MD", city: "ACCOKEEK" },
  "20608": { state: "MD", city: "AQUASCO" },
  "20609": { state: "MD", city: "AVENUE" },
  "20610": { state: "MD", city: "BARSTOW" },
  "20611": { state: "MD", city: "BEL ALTON" },
  "20612": { state: "MD", city: "BENEDICT" },
  "20613": { state: "MD", city: "BRANDYWINE" },
  "20615": { state: "MD", city: "BROOMES ISLAND" },
  "20616": { state: "MD", city: "BRYANS ROAD" },
  "20617": { state: "MD", city: "BRYANTOWN" },
  "20618": { state: "MD", city: "BUSHWOOD" },
  "20619": { state: "MD", city: "CALIFORNIA" },
  "20620": { state: "MD", city: "CALLAWAY" },
  "20621": { state: "MD", city: "CHAPTICO" },
  "20622": { state: "MD", city: "CHARLOTTE HALL" },
  "20623": { state: "MD", city: "CHELTENHAM" },
  "20624": { state: "MD", city: "CLEMENTS" },
  "20625": { state: "MD", city: "COBB ISLAND" },
  "20626": { state: "MD", city: "COLTONS POINT" },
  "20627": { state: "MD", city: "COMPTON" },
  "20628": { state: "MD", city: "DAMERON" },
  "20629": { state: "MD", city: "DOWELL" },
  "20630": { state: "MD", city: "DRAYDEN" },
  "20632": { state: "MD", city: "FAULKNER" },
  "20634": { state: "MD", city: "GREAT MILLS" },
  "20636": { state: "MD", city: "HOLLYWOOD" },
  "20637": { state: "MD", city: "HUGHESVILLE" },
  "20639": { state: "MD", city: "HUNTINGTOWN" },
  "20640": { state: "MD", city: "INDIAN HEAD" },
  "20643": { state: "MD", city: "IRONSIDES" },
  "20645": { state: "MD", city: "ISSUE" },
  "20646": { state: "MD", city: "LA PLATA" },
  "20650": { state: "MD", city: "LEONARDTOWN" },
  "20653": { state: "MD", city: "LEXINGTON PARK" },
  "20656": { state: "MD", city: "LOVEVILLE" },
  "20657": { state: "MD", city: "LUSBY" },
  "20658": { state: "MD", city: "MARBURY" },
  "20659": { state: "MD", city: "MECHANICSVILLE" },
  "20660": { state: "MD", city: "MORGANZA" },
  "20661": { state: "MD", city: "MOUNT VICTORIA" },
  "20662": { state: "MD", city: "NANJEMOY" },
  "20664": { state: "MD", city: "NEWBURG" },
  "20667": { state: "MD", city: "PARK HALL" },
  "20670": { state: "MD", city: "PATUXENT RIVER" },
  "20674": { state: "MD", city: "PINEY POINT" },
  "20675": { state: "MD", city: "POMFRET" },
  "20676": { state: "MD", city: "PORT REPUBLIC" },
  "20677": { state: "MD", city: "PORT TOBACCO" },
  "20678": { state: "MD", city: "PRINCE FREDERICK" },
  "20680": { state: "MD", city: "RIDGE" },
  "20682": { state: "MD", city: "ROCK POINT" },
  "20684": { state: "MD", city: "SAINT INIGOES" },
  "20685": { state: "MD", city: "SAINT LEONARD" },
  "20686": { state: "MD", city: "SAINT MARYS CITY" },
  "20687": { state: "MD", city: "SCOTLAND" },
  "20688": { state: "MD", city: "SOLOMONS" },
  "20689": { state: "MD", city: "SUNDERLAND" },
  "20690": { state: "MD", city: "TALL TIMBERS" },
  "20692": { state: "MD", city: "VALLEY LEE" },
  "20693": { state: "MD", city: "WELCOME" },
  "20695": { state: "MD", city: "WHITE PLAINS" },
  "20697": { state: "MD", city: "SOUTHERN MD FACILITY" },
  "20701": { state: "MD", city: "ANNAPOLIS JUNCTION" },
  "20703": { state: "MD", city: "LANHAM" },
  "20704": { state: "MD", city: "BELTSVILLE" },
  "20705": { state: "MD", city: "BELTSVILLE" },
  "20706": { state: "MD", city: "LANHAM" },
  "20707": { state: "MD", city: "LAUREL" },
  "20708": { state: "MD", city: "LAUREL" },
  "20709": { state: "MD", city: "LAUREL" },
  "20710": { state: "MD", city: "BLADENSBURG" },
  "20711": { state: "MD", city: "LOTHIAN" },
  "20712": { state: "MD", city: "MOUNT RAINIER" },
  "20714": { state: "MD", city: "NORTH BEACH" },
  "20715": { state: "MD", city: "BOWIE" },
  "20716": { state: "MD", city: "BOWIE" },
  "20717": { state: "MD", city: "BOWIE" },
  "20718": { state: "MD", city: "BOWIE" },
  "20719": { state: "MD", city: "BOWIE" },
  "20720": { state: "MD", city: "BOWIE" },
  "20721": { state: "MD", city: "BOWIE" },
  "20722": { state: "MD", city: "BRENTWOOD" },
  "20723": { state: "MD", city: "LAUREL" },
  "20724": { state: "MD", city: "LAUREL" },
  "20725": { state: "MD", city: "LAUREL" },
  "20726": { state: "MD", city: "LAUREL" },
  "20731": { state: "MD", city: "CAPITOL HEIGHTS" },
  "20732": { state: "MD", city: "CHESAPEAKE BEACH" },
  "20733": { state: "MD", city: "CHURCHTON" },
  "20735": { state: "MD", city: "CLINTON" },
  "20736": { state: "MD", city: "OWINGS" },
  "20737": { state: "MD", city: "RIVERDALE" },
  "20738": { state: "MD", city: "RIVERDALE" },
  "20740": { state: "MD", city: "COLLEGE PARK" },
  "20741": { state: "MD", city: "COLLEGE PARK" },
  "20742": { state: "MD", city: "COLLEGE PARK" },
  "20743": { state: "MD", city: "CAPITOL HEIGHTS" },
  "20744": { state: "MD", city: "FORT WASHINGTON" },
  "20745": { state: "MD", city: "OXON HILL" },
  "20746": { state: "MD", city: "SUITLAND" },
  "20747": { state: "MD", city: "DISTRICT HEIGHTS" },
  "20748": { state: "MD", city: "TEMPLE HILLS" },
  "20749": { state: "MD", city: "FORT WASHINGTON" },
  "20750": { state: "MD", city: "OXON HILL" },
  "20751": { state: "MD", city: "DEALE" },
  "20752": { state: "MD", city: "SUITLAND" },
  "20753": { state: "MD", city: "DISTRICT HEIGHTS" },
  "20754": { state: "MD", city: "DUNKIRK" },
  "20755": { state: "MD", city: "FORT GEORGE G MEADE" },
  "20757": { state: "MD", city: "TEMPLE HILLS" },
  "20758": { state: "MD", city: "FRIENDSHIP" },
  "20759": { state: "MD", city: "FULTON" },
  "20762": { state: "MD", city: "ANDREWS AIR FORCE BASE" },
  "20763": { state: "MD", city: "SAVAGE" },
  "20764": { state: "MD", city: "SHADY SIDE" },
  "20765": { state: "MD", city: "GALESVILLE" },
  "20768": { state: "MD", city: "GREENBELT" },
  "20769": { state: "MD", city: "GLENN DALE" },
  "20770": { state: "MD", city: "GREENBELT" },
  "20771": { state: "MD", city: "GREENBELT" },
  "20772": { state: "MD", city: "UPPER MARLBORO" },
  "20773": { state: "MD", city: "UPPER MARLBORO" },
  "20774": { state: "MD", city: "UPPER MARLBORO" },
  "20775": { state: "MD", city: "UPPER MARLBORO" },
  "20776": { state: "MD", city: "HARWOOD" },
  "20777": { state: "MD", city: "HIGHLAND" },
  "20778": { state: "MD", city: "WEST RIVER" },
  "20779": { state: "MD", city: "TRACYS LANDING" },
  "20781": { state: "MD", city: "HYATTSVILLE" },
  "20782": { state: "MD", city: "HYATTSVILLE" },
  "20783": { state: "MD", city: "HYATTSVILLE" },
  "20784": { state: "MD", city: "HYATTSVILLE" },
  "20785": { state: "MD", city: "HYATTSVILLE" },
  "20787": { state: "MD", city: "HYATTSVILLE" },
  "20788": { state: "MD", city: "HYATTSVILLE" },
  "20790": { state: "MD", city: "CAPITOL HEIGHTS" },
  "20791": { state: "MD", city: "CAPITOL HEIGHTS" },
  "20792": { state: "MD", city: "UPPER MARLBORO" },
  "20794": { state: "MD", city: "JESSUP" },
  "20797": { state: "MD", city: "SOUTHERN MD FACILITY" },
  "20799": { state: "MD", city: "CAPITOL HEIGHTS" },
  "20810": { state: "MD", city: "BETHESDA" },
  "20811": { state: "MD", city: "BETHESDA" },
  "20812": { state: "MD", city: "GLEN ECHO" },
  "20813": { state: "MD", city: "BETHESDA" },
  "20814": { state: "MD", city: "BETHESDA" },
  "20815": { state: "MD", city: "CHEVY CHASE" },
  "20816": { state: "MD", city: "BETHESDA" },
  "20817": { state: "MD", city: "BETHESDA" },
  "20818": { state: "MD", city: "CABIN JOHN" },
  "20824": { state: "MD", city: "BETHESDA" },
  "20825": { state: "MD", city: "CHEVY CHASE" },
  "20827": { state: "MD", city: "BETHESDA" },
  "20830": { state: "MD", city: "OLNEY" },
  "20832": { state: "MD", city: "OLNEY" },
  "20833": { state: "MD", city: "BROOKEVILLE" },
  "20837": { state: "MD", city: "POOLESVILLE" },
  "20838": { state: "MD", city: "BARNESVILLE" },
  "20839": { state: "MD", city: "BEALLSVILLE" },
  "20841": { state: "MD", city: "BOYDS" },
  "20842": { state: "MD", city: "DICKERSON" },
  "20847": { state: "MD", city: "ROCKVILLE" },
  "20848": { state: "MD", city: "ROCKVILLE" },
  "20849": { state: "MD", city: "ROCKVILLE" },
  "20850": { state: "MD", city: "ROCKVILLE" },
  "20851": { state: "MD", city: "ROCKVILLE" },
  "20852": { state: "MD", city: "ROCKVILLE" },
  "20853": { state: "MD", city: "ROCKVILLE" },
  "20854": { state: "MD", city: "POTOMAC" },
  "20855": { state: "MD", city: "DERWOOD" },
  "20857": { state: "MD", city: "ROCKVILLE" },
  "20859": { state: "MD", city: "POTOMAC" },
  "20860": { state: "MD", city: "SANDY SPRING" },
  "20861": { state: "MD", city: "ASHTON" },
  "20862": { state: "MD", city: "BRINKLOW" },
  "20866": { state: "MD", city: "BURTONSVILLE" },
  "20868": { state: "MD", city: "SPENCERVILLE" },
  "20871": { state: "MD", city: "CLARKSBURG" },
  "20872": { state: "MD", city: "DAMASCUS" },
  "20874": { state: "MD", city: "GERMANTOWN" },
  "20875": { state: "MD", city: "GERMANTOWN" },
  "20876": { state: "MD", city: "GERMANTOWN" },
  "20877": { state: "MD", city: "GAITHERSBURG" },
  "20878": { state: "MD", city: "GAITHERSBURG" },
  "20879": { state: "MD", city: "GAITHERSBURG" },
  "20880": { state: "MD", city: "WASHINGTON GROVE" },
  "20882": { state: "MD", city: "GAITHERSBURG" },
  "20883": { state: "MD", city: "GAITHERSBURG" },
  "20884": { state: "MD", city: "GAITHERSBURG" },
  "20885": { state: "MD", city: "GAITHERSBURG" },
  "20886": { state: "MD", city: "MONTGOMERY VILLAGE" },
  "20889": { state: "MD", city: "BETHESDA" },
  "20891": { state: "MD", city: "KENSINGTON" },
  "20892": { state: "MD", city: "BETHESDA" },
  "20894": { state: "MD", city: "BETHESDA" },
  "20895": { state: "MD", city: "KENSINGTON" },
  "20896": { state: "MD", city: "GARRETT PARK" },
  "20897": { state: "MD", city: "SUBURB MARYLAND FAC" },
  "20898": { state: "MD", city: "GAITHERSBURG" },
  "20899": { state: "MD", city: "GAITHERSBURG" },
  "20901": { state: "MD", city: "SILVER SPRING" },
  "20902": { state: "MD", city: "SILVER SPRING" },
  "20903": { state: "MD", city: "SILVER SPRING" },
  "20904": { state: "MD", city: "SILVER SPRING" },
  "20905": { state: "MD", city: "SILVER SPRING" },
  "20906": { state: "MD", city: "SILVER SPRING" },
  "20907": { state: "MD", city: "SILVER SPRING" },
  "20908": { state: "MD", city: "SILVER SPRING" },
  "20910": { state: "MD", city: "SILVER SPRING" },
  "20911": { state: "MD", city: "SILVER SPRING" },
  "20912": { state: "MD", city: "TAKOMA PARK" },
  "20913": { state: "MD", city: "TAKOMA PARK" },
  "20914": { state: "MD", city: "SILVER SPRING" },
  "20915": { state: "MD", city: "SILVER SPRING" },
  "20916": { state: "MD", city: "SILVER SPRING" },
  "20918": { state: "MD", city: "SILVER SPRING" },
  "20993": { state: "MD", city: "SILVER SPRING" },
  "20997": { state: "MD", city: "SILVER SPRING" },
  "21001": { state: "MD", city: "ABERDEEN" },
  "21005": { state: "MD", city: "ABERDEEN PROVING GROUND" },
  "21009": { state: "MD", city: "ABINGDON" },
  "21010": { state: "MD", city: "GUNPOWDER" },
  "21012": { state: "MD", city: "ARNOLD" },
  "21013": { state: "MD", city: "BALDWIN" },
  "21014": { state: "MD", city: "BEL AIR" },
  "21015": { state: "MD", city: "BEL AIR" },
  "21017": { state: "MD", city: "BELCAMP" },
  "21018": { state: "MD", city: "BENSON" },
  "21020": { state: "MD", city: "BORING" },
  "21022": { state: "MD", city: "BROOKLANDVILLE" },
  "21023": { state: "MD", city: "BUTLER" },
  "21027": { state: "MD", city: "CHASE" },
  "21028": { state: "MD", city: "CHURCHVILLE" },
  "21029": { state: "MD", city: "CLARKSVILLE" },
  "21030": { state: "MD", city: "COCKEYSVILLE" },
  "21031": { state: "MD", city: "HUNT VALLEY" },
  "21032": { state: "MD", city: "CROWNSVILLE" },
  "21034": { state: "MD", city: "DARLINGTON" },
  "21035": { state: "MD", city: "DAVIDSONVILLE" },
  "21036": { state: "MD", city: "DAYTON" },
  "21037": { state: "MD", city: "EDGEWATER" },
  "21040": { state: "MD", city: "EDGEWOOD" },
  "21041": { state: "MD", city: "ELLICOTT CITY" },
  "21042": { state: "MD", city: "ELLICOTT CITY" },
  "21043": { state: "MD", city: "ELLICOTT CITY" },
  "21044": { state: "MD", city: "COLUMBIA" },
  "21045": { state: "MD", city: "COLUMBIA" },
  "21046": { state: "MD", city: "COLUMBIA" },
  "21047": { state: "MD", city: "FALLSTON" },
  "21048": { state: "MD", city: "FINKSBURG" },
  "21050": { state: "MD", city: "FOREST HILL" },
  "21051": { state: "MD", city: "FORK" },
  "21052": { state: "MD", city: "FORT HOWARD" },
  "21053": { state: "MD", city: "FREELAND" },
  "21054": { state: "MD", city: "GAMBRILLS" },
  "21056": { state: "MD", city: "GIBSON ISLAND" },
  "21057": { state: "MD", city: "GLEN ARM" },
  "21060": { state: "MD", city: "GLEN BURNIE" },
  "21061": { state: "MD", city: "GLEN BURNIE" },
  "21062": { state: "MD", city: "GLEN BURNIE" },
  "21065": { state: "MD", city: "HUNT VALLEY" },
  "21071": { state: "MD", city: "GLYNDON" },
  "21074": { state: "MD", city: "HAMPSTEAD" },
  "21075": { state: "MD", city: "ELKRIDGE" },
  "21076": { state: "MD", city: "HANOVER" },
  "21077": { state: "MD", city: "HARMANS" },
  "21078": { state: "MD", city: "HAVRE DE GRACE" },
  "21082": { state: "MD", city: "HYDES" },
  "21084": { state: "MD", city: "JARRETTSVILLE" },
  "21085": { state: "MD", city: "JOPPA" },
  "21087": { state: "MD", city: "KINGSVILLE" },
  "21088": { state: "MD", city: "LINEBORO" },
  "21090": { state: "MD", city: "LINTHICUM HEIGHTS" },
  "21092": { state: "MD", city: "LONG GREEN" },
  "21093": { state: "MD", city: "LUTHERVILLE TIMONIUM" },
  "21094": { state: "MD", city: "LUTHERVILLE TIMONIUM" },
  "21102": { state: "MD", city: "MANCHESTER" },
  "21104": { state: "MD", city: "MARRIOTTSVILLE" },
  "21105": { state: "MD", city: "MARYLAND LINE" },
  "21106": { state: "MD", city: "MAYO" },
  "21108": { state: "MD", city: "MILLERSVILLE" },
  "21111": { state: "MD", city: "MONKTON" },
  "21113": { state: "MD", city: "ODENTON" },
  "21114": { state: "MD", city: "CROFTON" },
  "21117": { state: "MD", city: "OWINGS MILLS" },
  "21120": { state: "MD", city: "PARKTON" },
  "21122": { state: "MD", city: "PASADENA" },
  "21123": { state: "MD", city: "PASADENA" },
  "21128": { state: "MD", city: "PERRY HALL" },
  "21130": { state: "MD", city: "PERRYMAN" },
  "21131": { state: "MD", city: "PHOENIX" },
  "21132": { state: "MD", city: "PYLESVILLE" },
  "21133": { state: "MD", city: "RANDALLSTOWN" },
  "21136": { state: "MD", city: "REISTERSTOWN" },
  "21139": { state: "MD", city: "RIDERWOOD" },
  "21140": { state: "MD", city: "RIVA" },
  "21144": { state: "MD", city: "SEVERN" },
  "21146": { state: "MD", city: "SEVERNA PARK" },
  "21150": { state: "MD", city: "SIMPSONVILLE" },
  "21152": { state: "MD", city: "SPARKS GLENCOE" },
  "21153": { state: "MD", city: "STEVENSON" },
  "21154": { state: "MD", city: "STREET" },
  "21155": { state: "MD", city: "UPPERCO" },
  "21156": { state: "MD", city: "UPPER FALLS" },
  "21157": { state: "MD", city: "WESTMINSTER" },
  "21158": { state: "MD", city: "WESTMINSTER" },
  "21160": { state: "MD", city: "WHITEFORD" },
  "21161": { state: "MD", city: "WHITE HALL" },
  "21162": { state: "MD", city: "WHITE MARSH" },
  "21163": { state: "MD", city: "WOODSTOCK" },
  "21201": { state: "MD", city: "BALTIMORE" },
  "21202": { state: "MD", city: "BALTIMORE" },
  "21203": { state: "MD", city: "BALTIMORE" },
  "21204": { state: "MD", city: "TOWSON" },
  "21205": { state: "MD", city: "BALTIMORE" },
  "21206": { state: "MD", city: "BALTIMORE" },
  "21207": { state: "MD", city: "GWYNN OAK" },
  "21208": { state: "MD", city: "PIKESVILLE" },
  "21209": { state: "MD", city: "BALTIMORE" },
  "21210": { state: "MD", city: "BALTIMORE" },
  "21211": { state: "MD", city: "BALTIMORE" },
  "21212": { state: "MD", city: "BALTIMORE" },
  "21213": { state: "MD", city: "BALTIMORE" },
  "21214": { state: "MD", city: "BALTIMORE" },
  "21215": { state: "MD", city: "BALTIMORE" },
  "21216": { state: "MD", city: "BALTIMORE" },
  "21217": { state: "MD", city: "BALTIMORE" },
  "21218": { state: "MD", city: "BALTIMORE" },
  "21219": { state: "MD", city: "SPARROWS POINT" },
  "21220": { state: "MD", city: "MIDDLE RIVER" },
  "21221": { state: "MD", city: "ESSEX" },
  "21222": { state: "MD", city: "DUNDALK" },
  "21223": { state: "MD", city: "BALTIMORE" },
  "21224": { state: "MD", city: "BALTIMORE" },
  "21225": { state: "MD", city: "BROOKLYN" },
  "21226": { state: "MD", city: "CURTIS BAY" },
  "21227": { state: "MD", city: "HALETHORPE" },
  "21228": { state: "MD", city: "CATONSVILLE" },
  "21229": { state: "MD", city: "BALTIMORE" },
  "21230": { state: "MD", city: "BALTIMORE" },
  "21231": { state: "MD", city: "BALTIMORE" },
  "21233": { state: "MD", city: "BALTIMORE" },
  "21234": { state: "MD", city: "PARKVILLE" },
  "21235": { state: "MD", city: "BALTIMORE" },
  "21236": { state: "MD", city: "NOTTINGHAM" },
  "21237": { state: "MD", city: "ROSEDALE" },
  "21239": { state: "MD", city: "BALTIMORE" },
  "21240": { state: "MD", city: "BALTIMORE" },
  "21241": { state: "MD", city: "BALTIMORE" },
  "21244": { state: "MD", city: "WINDSOR MILL" },
  "21250": { state: "MD", city: "BALTIMORE" },
  "21251": { state: "MD", city: "BALTIMORE" },
  "21252": { state: "MD", city: "TOWSON" },
  "21263": { state: "MD", city: "BALTIMORE" },
  "21264": { state: "MD", city: "BALTIMORE" },
  "21270": { state: "MD", city: "BALTIMORE" },
  "21273": { state: "MD", city: "BALTIMORE" },
  "21275": { state: "MD", city: "BALTIMORE" },
  "21278": { state: "MD", city: "BALTIMORE" },
  "21279": { state: "MD", city: "BALTIMORE" },
  "21281": { state: "MD", city: "BALTIMORE" },
  "21282": { state: "MD", city: "PIKESVILLE" },
  "21284": { state: "MD", city: "TOWSON" },
  "21285": { state: "MD", city: "TOWSON" },
  "21286": { state: "MD", city: "TOWSON" },
  "21287": { state: "MD", city: "BALTIMORE" },
  "21289": { state: "MD", city: "BALTIMORE" },
  "21290": { state: "MD", city: "BALTIMORE" },
  "21297": { state: "MD", city: "BALTIMORE" },
  "21298": { state: "MD", city: "BALTIMORE" },
  "21401": { state: "MD", city: "ANNAPOLIS" },
  "21402": { state: "MD", city: "ANNAPOLIS" },
  "21403": { state: "MD", city: "ANNAPOLIS" },
  "21404": { state: "MD", city: "ANNAPOLIS" },
  "21405": { state: "MD", city: "ANNAPOLIS" },
  "21409": { state: "MD", city: "ANNAPOLIS" },
  "21411": { state: "MD", city: "ANNAPOLIS" },
  "21412": { state: "MD", city: "ANNAPOLIS" },
  "21501": { state: "MD", city: "CUMBERLAND" },
  "21502": { state: "MD", city: "CUMBERLAND" },
  "21503": { state: "MD", city: "CUMBERLAND" },
  "21504": { state: "MD", city: "CUMBERLAND" },
  "21505": { state: "MD", city: "CUMBERLAND" },
  "21520": { state: "MD", city: "ACCIDENT" },
  "21521": { state: "MD", city: "BARTON" },
  "21522": { state: "MD", city: "BITTINGER" },
  "21523": { state: "MD", city: "BLOOMINGTON" },
  "21524": { state: "MD", city: "CORRIGANVILLE" },
  "21528": { state: "MD", city: "ECKHART MINES" },
  "21529": { state: "MD", city: "ELLERSLIE" },
  "21530": { state: "MD", city: "FLINTSTONE" },
  "21531": { state: "MD", city: "FRIENDSVILLE" },
  "21532": { state: "MD", city: "FROSTBURG" },
  "21536": { state: "MD", city: "GRANTSVILLE" },
  "21538": { state: "MD", city: "KITZMILLER" },
  "21539": { state: "MD", city: "LONACONING" },
  "21540": { state: "MD", city: "LUKE" },
  "21541": { state: "MD", city: "MC HENRY" },
  "21542": { state: "MD", city: "MIDLAND" },
  "21543": { state: "MD", city: "MIDLOTHIAN" },
  "21545": { state: "MD", city: "MOUNT SAVAGE" },
  "21550": { state: "MD", city: "OAKLAND" },
  "21555": { state: "MD", city: "OLDTOWN" },
  "21556": { state: "MD", city: "PINTO" },
  "21557": { state: "MD", city: "RAWLINGS" },
  "21560": { state: "MD", city: "SPRING GAP" },
  "21561": { state: "MD", city: "SWANTON" },
  "21562": { state: "MD", city: "WESTERNPORT" },
  "21601": { state: "MD", city: "EASTON" },
  "21607": { state: "MD", city: "BARCLAY" },
  "21609": { state: "MD", city: "BETHLEHEM" },
  "21610": { state: "MD", city: "BETTERTON" },
  "21612": { state: "MD", city: "BOZMAN" },
  "21613": { state: "MD", city: "CAMBRIDGE" },
  "21617": { state: "MD", city: "CENTREVILLE" },
  "21619": { state: "MD", city: "CHESTER" },
  "21620": { state: "MD", city: "CHESTERTOWN" },
  "21622": { state: "MD", city: "CHURCH CREEK" },
  "21623": { state: "MD", city: "CHURCH HILL" },
  "21624": { state: "MD", city: "CLAIBORNE" },
  "21625": { state: "MD", city: "CORDOVA" },
  "21626": { state: "MD", city: "CRAPO" },
  "21627": { state: "MD", city: "CROCHERON" },
  "21628": { state: "MD", city: "CRUMPTON" },
  "21629": { state: "MD", city: "DENTON" },
  "21631": { state: "MD", city: "EAST NEW MARKET" },
  "21632": { state: "MD", city: "FEDERALSBURG" },
  "21634": { state: "MD", city: "FISHING CREEK" },
  "21635": { state: "MD", city: "GALENA" },
  "21636": { state: "MD", city: "GOLDSBORO" },
  "21638": { state: "MD", city: "GRASONVILLE" },
  "21639": { state: "MD", city: "GREENSBORO" },
  "21640": { state: "MD", city: "HENDERSON" },
  "21641": { state: "MD", city: "HILLSBORO" },
  "21643": { state: "MD", city: "HURLOCK" },
  "21644": { state: "MD", city: "INGLESIDE" },
  "21645": { state: "MD", city: "KENNEDYVILLE" },
  "21647": { state: "MD", city: "MCDANIEL" },
  "21648": { state: "MD", city: "MADISON" },
  "21649": { state: "MD", city: "MARYDEL" },
  "21650": { state: "MD", city: "MASSEY" },
  "21651": { state: "MD", city: "MILLINGTON" },
  "21652": { state: "MD", city: "NEAVITT" },
  "21653": { state: "MD", city: "NEWCOMB" },
  "21654": { state: "MD", city: "OXFORD" },
  "21655": { state: "MD", city: "PRESTON" },
  "21656": { state: "MD", city: "PRICE" },
  "21657": { state: "MD", city: "QUEEN ANNE" },
  "21658": { state: "MD", city: "QUEENSTOWN" },
  "21659": { state: "MD", city: "RHODESDALE" },
  "21660": { state: "MD", city: "RIDGELY" },
  "21661": { state: "MD", city: "ROCK HALL" },
  "21662": { state: "MD", city: "ROYAL OAK" },
  "21663": { state: "MD", city: "SAINT MICHAELS" },
  "21664": { state: "MD", city: "SECRETARY" },
  "21665": { state: "MD", city: "SHERWOOD" },
  "21666": { state: "MD", city: "STEVENSVILLE" },
  "21667": { state: "MD", city: "STILL POND" },
  "21668": { state: "MD", city: "SUDLERSVILLE" },
  "21669": { state: "MD", city: "TAYLORS ISLAND" },
  "21670": { state: "MD", city: "TEMPLEVILLE" },
  "21671": { state: "MD", city: "TILGHMAN" },
  "21672": { state: "MD", city: "TODDVILLE" },
  "21673": { state: "MD", city: "TRAPPE" },
  "21675": { state: "MD", city: "WINGATE" },
  "21676": { state: "MD", city: "WITTMAN" },
  "21677": { state: "MD", city: "WOOLFORD" },
  "21678": { state: "MD", city: "WORTON" },
  "21679": { state: "MD", city: "WYE MILLS" },
  "21690": { state: "MD", city: "CHESTERTOWN" },
  "21701": { state: "MD", city: "FREDERICK" },
  "21702": { state: "MD", city: "FREDERICK" },
  "21703": { state: "MD", city: "FREDERICK" },
  "21704": { state: "MD", city: "FREDERICK" },
  "21705": { state: "MD", city: "FREDERICK" },
  "21709": { state: "MD", city: "FREDERICK" },
  "21710": { state: "MD", city: "ADAMSTOWN" },
  "21711": { state: "MD", city: "BIG POOL" },
  "21713": { state: "MD", city: "BOONSBORO" },
  "21714": { state: "MD", city: "BRADDOCK HEIGHTS" },
  "21715": { state: "MD", city: "BROWNSVILLE" },
  "21716": { state: "MD", city: "BRUNSWICK" },
  "21717": { state: "MD", city: "BUCKEYSTOWN" },
  "21718": { state: "MD", city: "BURKITTSVILLE" },
  "21719": { state: "MD", city: "CASCADE" },
  "21720": { state: "MD", city: "CAVETOWN" },
  "21721": { state: "MD", city: "CHEWSVILLE" },
  "21722": { state: "MD", city: "CLEAR SPRING" },
  "21723": { state: "MD", city: "COOKSVILLE" },
  "21727": { state: "MD", city: "EMMITSBURG" },
  "21733": { state: "MD", city: "FAIRPLAY" },
  "21734": { state: "MD", city: "FUNKSTOWN" },
  "21737": { state: "MD", city: "GLENELG" },
  "21738": { state: "MD", city: "GLENWOOD" },
  "21740": { state: "MD", city: "HAGERSTOWN" },
  "21741": { state: "MD", city: "HAGERSTOWN" },
  "21742": { state: "MD", city: "HAGERSTOWN" },
  "21746": { state: "MD", city: "HAGERSTOWN" },
  "21747": { state: "MD", city: "HAGERSTOWN" },
  "21749": { state: "MD", city: "HAGERSTOWN" },
  "21750": { state: "MD", city: "HANCOCK" },
  "21754": { state: "MD", city: "IJAMSVILLE" },
  "21755": { state: "MD", city: "JEFFERSON" },
  "21756": { state: "MD", city: "KEEDYSVILLE" },
  "21757": { state: "MD", city: "KEYMAR" },
  "21758": { state: "MD", city: "KNOXVILLE" },
  "21759": { state: "MD", city: "LADIESBURG" },
  "21762": { state: "MD", city: "LIBERTYTOWN" },
  "21765": { state: "MD", city: "LISBON" },
  "21766": { state: "MD", city: "LITTLE ORLEANS" },
  "21767": { state: "MD", city: "MAUGANSVILLE" },
  "21769": { state: "MD", city: "MIDDLETOWN" },
  "21770": { state: "MD", city: "MONROVIA" },
  "21771": { state: "MD", city: "MOUNT AIRY" },
  "21773": { state: "MD", city: "MYERSVILLE" },
  "21774": { state: "MD", city: "NEW MARKET" },
  "21775": { state: "MD", city: "NEW MIDWAY" },
  "21776": { state: "MD", city: "NEW WINDSOR" },
  "21777": { state: "MD", city: "POINT OF ROCKS" },
  "21778": { state: "MD", city: "ROCKY RIDGE" },
  "21779": { state: "MD", city: "ROHRERSVILLE" },
  "21780": { state: "MD", city: "SABILLASVILLE" },
  "21781": { state: "MD", city: "SAINT JAMES" },
  "21782": { state: "MD", city: "SHARPSBURG" },
  "21783": { state: "MD", city: "SMITHSBURG" },
  "21784": { state: "MD", city: "SYKESVILLE" },
  "21787": { state: "MD", city: "TANEYTOWN" },
  "21788": { state: "MD", city: "THURMONT" },
  "21790": { state: "MD", city: "TUSCARORA" },
  "21791": { state: "MD", city: "UNION BRIDGE" },
  "21792": { state: "MD", city: "UNIONVILLE" },
  "21793": { state: "MD", city: "WALKERSVILLE" },
  "21794": { state: "MD", city: "WEST FRIENDSHIP" },
  "21795": { state: "MD", city: "WILLIAMSPORT" },
  "21797": { state: "MD", city: "WOODBINE" },
  "21798": { state: "MD", city: "WOODSBORO" },
  "21801": { state: "MD", city: "SALISBURY" },
  "21802": { state: "MD", city: "SALISBURY" },
  "21803": { state: "MD", city: "SALISBURY" },
  "21804": { state: "MD", city: "SALISBURY" },
  "21810": { state: "MD", city: "ALLEN" },
  "21811": { state: "MD", city: "BERLIN" },
  "21813": { state: "MD", city: "BISHOPVILLE" },
  "21814": { state: "MD", city: "BIVALVE" },
  "21817": { state: "MD", city: "CRISFIELD" },
  "21821": { state: "MD", city: "DEAL ISLAND" },
  "21822": { state: "MD", city: "EDEN" },
  "21824": { state: "MD", city: "EWELL" },
  "21826": { state: "MD", city: "FRUITLAND" },
  "21829": { state: "MD", city: "GIRDLETREE" },
  "21830": { state: "MD", city: "HEBRON" },
  "21835": { state: "MD", city: "LINKWOOD" },
  "21836": { state: "MD", city: "MANOKIN" },
  "21837": { state: "MD", city: "MARDELA SPRINGS" },
  "21838": { state: "MD", city: "MARION STATION" },
  "21840": { state: "MD", city: "NANTICOKE" },
  "21841": { state: "MD", city: "NEWARK" },
  "21842": { state: "MD", city: "OCEAN CITY" },
  "21843": { state: "MD", city: "OCEAN CITY" },
  "21849": { state: "MD", city: "PARSONSBURG" },
  "21850": { state: "MD", city: "PITTSVILLE" },
  "21851": { state: "MD", city: "POCOMOKE CITY" },
  "21852": { state: "MD", city: "POWELLVILLE" },
  "21853": { state: "MD", city: "PRINCESS ANNE" },
  "21856": { state: "MD", city: "QUANTICO" },
  "21857": { state: "MD", city: "REHOBETH" },
  "21861": { state: "MD", city: "SHARPTOWN" },
  "21862": { state: "MD", city: "SHOWELL" },
  "21863": { state: "MD", city: "SNOW HILL" },
  "21864": { state: "MD", city: "STOCKTON" },
  "21865": { state: "MD", city: "TYASKIN" },
  "21866": { state: "MD", city: "TYLERTON" },
  "21867": { state: "MD", city: "UPPER FAIRMOUNT" },
  "21869": { state: "MD", city: "VIENNA" },
  "21871": { state: "MD", city: "WESTOVER" },
  "21872": { state: "MD", city: "WHALEYVILLE" },
  "21874": { state: "MD", city: "WILLARDS" },
  "21875": { state: "MD", city: "DELMAR" },
  "21890": { state: "MD", city: "WESTOVER" },
  "21901": { state: "MD", city: "NORTH EAST" },
  "21902": { state: "MD", city: "PERRY POINT" },
  "21903": { state: "MD", city: "PERRYVILLE" },
  "21904": { state: "MD", city: "PORT DEPOSIT" },
  "21911": { state: "MD", city: "RISING SUN" },
  "21912": { state: "MD", city: "WARWICK" },
  "21913": { state: "MD", city: "CECILTON" },
  "21914": { state: "MD", city: "CHARLESTOWN" },
  "21915": { state: "MD", city: "CHESAPEAKE CITY" },
  "21916": { state: "MD", city: "CHILDS" },
  "21917": { state: "MD", city: "COLORA" },
  "21918": { state: "MD", city: "CONOWINGO" },
  "21919": { state: "MD", city: "EARLEVILLE" },
  "21920": { state: "MD", city: "ELK MILLS" },
  "21921": { state: "MD", city: "ELKTON" },
  "21922": { state: "MD", city: "ELKTON" },
  "21930": { state: "MD", city: "GEORGETOWN" },
  "22003": { state: "VA", city: "ANNANDALE" },
  "22009": { state: "VA", city: "BURKE" },
  "22015": { state: "VA", city: "BURKE" },
  "22025": { state: "VA", city: "DUMFRIES" },
  "22026": { state: "VA", city: "DUMFRIES" },
  "22027": { state: "VA", city: "DUNN LORING" },
  "22030": { state: "VA", city: "FAIRFAX" },
  "22031": { state: "VA", city: "FAIRFAX" },
  "22032": { state: "VA", city: "FAIRFAX" },
  "22033": { state: "VA", city: "FAIRFAX" },
  "22034": { state: "VA", city: "FAIRFAX" },
  "22035": { state: "VA", city: "FAIRFAX" },
  "22036": { state: "VA", city: "FAIRFAX" },
  "22037": { state: "VA", city: "FAIRFAX" },
  "22038": { state: "VA", city: "FAIRFAX" },
  "22039": { state: "VA", city: "FAIRFAX STATION" },
  "22040": { state: "VA", city: "FALLS CHURCH" },
  "22041": { state: "VA", city: "FALLS CHURCH" },
  "22042": { state: "VA", city: "FALLS CHURCH" },
  "22043": { state: "VA", city: "FALLS CHURCH" },
  "22044": { state: "VA", city: "FALLS CHURCH" },
  "22046": { state: "VA", city: "FALLS CHURCH" },
  "22060": { state: "VA", city: "FORT BELVOIR" },
  "22066": { state: "VA", city: "GREAT FALLS" },
  "22067": { state: "VA", city: "GREENWAY" },
  "22079": { state: "VA", city: "LORTON" },
  "22081": { state: "VA", city: "MERRIFIELD" },
  "22082": { state: "VA", city: "MERRIFIELD" },
  "22095": { state: "VA", city: "HERNDON" },
  "22096": { state: "VA", city: "RESTON" },
  "22101": { state: "VA", city: "MC LEAN" },
  "22102": { state: "VA", city: "MC LEAN" },
  "22103": { state: "VA", city: "WEST MCLEAN" },
  "22106": { state: "VA", city: "MC LEAN" },
  "22107": { state: "VA", city: "MC LEAN" },
  "22108": { state: "VA", city: "MC LEAN" },
  "22109": { state: "VA", city: "MC LEAN" },
  "22116": { state: "VA", city: "MERRIFIELD" },
  "22118": { state: "VA", city: "MERRIFIELD" },
  "22119": { state: "VA", city: "MERRIFIELD" },
  "22121": { state: "VA", city: "MOUNT VERNON" },
  "22122": { state: "VA", city: "NEWINGTON" },
  "22124": { state: "VA", city: "OAKTON" },
  "22125": { state: "VA", city: "OCCOQUAN" },
  "22134": { state: "VA", city: "QUANTICO" },
  "22135": { state: "VA", city: "QUANTICO" },
  "22150": { state: "VA", city: "SPRINGFIELD" },
  "22151": { state: "VA", city: "SPRINGFIELD" },
  "22152": { state: "VA", city: "SPRINGFIELD" },
  "22153": { state: "VA", city: "SPRINGFIELD" },
  "22156": { state: "VA", city: "SPRINGFIELD" },
  "22158": { state: "VA", city: "SPRINGFIELD" },
  "22159": { state: "VA", city: "SPRINGFIELD" },
  "22160": { state: "VA", city: "SPRINGFIELD" },
  "22161": { state: "VA", city: "SPRINGFIELD" },
  "22172": { state: "VA", city: "TRIANGLE" },
  "22180": { state: "VA", city: "VIENNA" },
  "22181": { state: "VA", city: "VIENNA" },
  "22182": { state: "VA", city: "VIENNA" },
  "22183": { state: "VA", city: "VIENNA" },
  "22185": { state: "VA", city: "VIENNA" },
  "22191": { state: "VA", city: "WOODBRIDGE" },
  "22192": { state: "VA", city: "WOODBRIDGE" },
  "22193": { state: "VA", city: "WOODBRIDGE" },
  "22194": { state: "VA", city: "WOODBRIDGE" },
  "22195": { state: "VA", city: "WOODBRIDGE" },
  "22199": { state: "VA", city: "LORTON" },
  "22201": { state: "VA", city: "ARLINGTON" },
  "22202": { state: "VA", city: "ARLINGTON" },
  "22203": { state: "VA", city: "ARLINGTON" },
  "22204": { state: "VA", city: "ARLINGTON" },
  "22205": { state: "VA", city: "ARLINGTON" },
  "22206": { state: "VA", city: "ARLINGTON" },
  "22207": { state: "VA", city: "ARLINGTON" },
  "22209": { state: "VA", city: "ARLINGTON" },
  "22210": { state: "VA", city: "ARLINGTON" },
  "22211": { state: "VA", city: "FORT MYER" },
  "22212": { state: "VA", city: "ARLINGTON" },
  "22213": { state: "VA", city: "ARLINGTON" },
  "22214": { state: "VA", city: "ARLINGTON" },
  "22215": { state: "VA", city: "ARLINGTON" },
  "22216": { state: "VA", city: "ARLINGTON" },
  "22217": { state: "VA", city: "ARLINGTON" },
  "22219": { state: "VA", city: "ARLINGTON" },
  "22225": { state: "VA", city: "ARLINGTON" },
  "22226": { state: "VA", city: "ARLINGTON" },
  "22227": { state: "VA", city: "ARLINGTON" },
  "22230": { state: "VA", city: "ARLINGTON" },
  "22240": { state: "VA", city: "ARLINGTON" },
  "22241": { state: "VA", city: "ARLINGTON" },
  "22242": { state: "VA", city: "ARLINGTON" },
  "22243": { state: "VA", city: "ARLINGTON" },
  "22244": { state: "VA", city: "ARLINGTON" },
  "22245": { state: "VA", city: "ARLINGTON" },
  "22246": { state: "VA", city: "ARLINGTON" },
  "22301": { state: "VA", city: "ALEXANDRIA" },
  "22302": { state: "VA", city: "ALEXANDRIA" },
  "22303": { state: "VA", city: "ALEXANDRIA" },
  "22304": { state: "VA", city: "ALEXANDRIA" },
  "22305": { state: "VA", city: "ALEXANDRIA" },
  "22306": { state: "VA", city: "ALEXANDRIA" },
  "22307": { state: "VA", city: "ALEXANDRIA" },
  "22308": { state: "VA", city: "ALEXANDRIA" },
  "22309": { state: "VA", city: "ALEXANDRIA" },
  "22310": { state: "VA", city: "ALEXANDRIA" },
  "22311": { state: "VA", city: "ALEXANDRIA" },
  "22312": { state: "VA", city: "ALEXANDRIA" },
  "22313": { state: "VA", city: "ALEXANDRIA" },
  "22314": { state: "VA", city: "ALEXANDRIA" },
  "22315": { state: "VA", city: "ALEXANDRIA" },
  "22320": { state: "VA", city: "ALEXANDRIA" },
  "22331": { state: "VA", city: "ALEXANDRIA" },
  "22332": { state: "VA", city: "ALEXANDRIA" },
  "22333": { state: "VA", city: "ALEXANDRIA" },
  "22334": { state: "VA", city: "ALEXANDRIA" },
  "22350": { state: "VA", city: "ALEXANDRIA" },
  "22401": { state: "VA", city: "FREDERICKSBURG" },
  "22402": { state: "VA", city: "FREDERICKSBURG" },
  "22403": { state: "VA", city: "FREDERICKSBURG" },
  "22404": { state: "VA", city: "FREDERICKSBURG" },
  "22405": { state: "VA", city: "FREDERICKSBURG" },
  "22406": { state: "VA", city: "FREDERICKSBURG" },
  "22407": { state: "VA", city: "FREDERICKSBURG" },
  "22408": { state: "VA", city: "FREDERICKSBURG" },
  "22412": { state: "VA", city: "FREDERICKSBURG" },
  "22427": { state: "VA", city: "BOWLING GREEN" },
  "22428": { state: "VA", city: "BOWLING GREEN" },
  "22430": { state: "VA", city: "BROOKE" },
  "22432": { state: "VA", city: "BURGESS" },
  "22433": { state: "VA", city: "BURR HILL" },
  "22435": { state: "VA", city: "CALLAO" },
  "22436": { state: "VA", city: "CARET" },
  "22437": { state: "VA", city: "CENTER CROSS" },
  "22438": { state: "VA", city: "CHAMPLAIN" },
  "22442": { state: "VA", city: "COLES POINT" },
  "22443": { state: "VA", city: "COLONIAL BEACH" },
  "22446": { state: "VA", city: "CORBIN" },
  "22448": { state: "VA", city: "DAHLGREN" },
  "22451": { state: "VA", city: "DOGUE" },
  "22454": { state: "VA", city: "DUNNSVILLE" },
  "22456": { state: "VA", city: "EDWARDSVILLE" },
  "22460": { state: "VA", city: "FARNHAM" },
  "22463": { state: "VA", city: "GARRISONVILLE" },
  "22469": { state: "VA", city: "HAGUE" },
  "22471": { state: "VA", city: "HARTWOOD" },
  "22472": { state: "VA", city: "HAYNESVILLE" },
  "22473": { state: "VA", city: "HEATHSVILLE" },
  "22476": { state: "VA", city: "HUSTLE" },
  "22480": { state: "VA", city: "IRVINGTON" },
  "22481": { state: "VA", city: "JERSEY" },
  "22482": { state: "VA", city: "KILMARNOCK" },
  "22485": { state: "VA", city: "KING GEORGE" },
  "22488": { state: "VA", city: "KINSALE" },
  "22501": { state: "VA", city: "LADYSMITH" },
  "22503": { state: "VA", city: "LANCASTER" },
  "22504": { state: "VA", city: "LANEVIEW" },
  "22507": { state: "VA", city: "LIVELY" },
  "22508": { state: "VA", city: "LOCUST GROVE" },
  "22509": { state: "VA", city: "LORETTO" },
  "22511": { state: "VA", city: "LOTTSBURG" },
  "22513": { state: "VA", city: "MERRY POINT" },
  "22514": { state: "VA", city: "MILFORD" },
  "22517": { state: "VA", city: "MOLLUSK" },
  "22520": { state: "VA", city: "MONTROSS" },
  "22523": { state: "VA", city: "MORATTICO" },
  "22524": { state: "VA", city: "MOUNT HOLLY" },
  "22526": { state: "VA", city: "NINDE" },
  "22528": { state: "VA", city: "NUTTSVILLE" },
  "22529": { state: "VA", city: "OLDHAMS" },
  "22530": { state: "VA", city: "OPHELIA" },
  "22534": { state: "VA", city: "PARTLOW" },
  "22535": { state: "VA", city: "PORT ROYAL" },
  "22538": { state: "VA", city: "RAPPAHANNOCK ACADEMY" },
  "22539": { state: "VA", city: "REEDVILLE" },
  "22542": { state: "VA", city: "RHOADESVILLE" },
  "22544": { state: "VA", city: "ROLLINS FORK" },
  "22545": { state: "VA", city: "RUBY" },
  "22546": { state: "VA", city: "RUTHER GLEN" },
  "22547": { state: "VA", city: "SEALSTON" },
  "22548": { state: "VA", city: "SHARPS" },
  "22551": { state: "VA", city: "SPOTSYLVANIA" },
  "22552": { state: "VA", city: "SPARTA" },
  "22553": { state: "VA", city: "SPOTSYLVANIA" },
  "22554": { state: "VA", city: "STAFFORD" },
  "22555": { state: "VA", city: "STAFFORD" },
  "22556": { state: "VA", city: "STAFFORD" },
  "22558": { state: "VA", city: "STRATFORD" },
  "22560": { state: "VA", city: "TAPPAHANNOCK" },
  "22565": { state: "VA", city: "THORNBURG" },
  "22567": { state: "VA", city: "UNIONVILLE" },
  "22570": { state: "VA", city: "VILLAGE" },
  "22572": { state: "VA", city: "WARSAW" },
  "22576": { state: "VA", city: "WEEMS" },
  "22577": { state: "VA", city: "SANDY POINT" },
  "22578": { state: "VA", city: "WHITE STONE" },
  "22579": { state: "VA", city: "WICOMICO CHURCH" },
  "22580": { state: "VA", city: "WOODFORD" },
  "22581": { state: "VA", city: "ZACATA" },
  "22601": { state: "VA", city: "WINCHESTER" },
  "22602": { state: "VA", city: "WINCHESTER" },
  "22603": { state: "VA", city: "WINCHESTER" },
  "22604": { state: "VA", city: "WINCHESTER" },
  "22610": { state: "VA", city: "BENTONVILLE" },
  "22611": { state: "VA", city: "BERRYVILLE" },
  "22620": { state: "VA", city: "BOYCE" },
  "22622": { state: "VA", city: "BRUCETOWN" },
  "22623": { state: "VA", city: "CHESTER GAP" },
  "22624": { state: "VA", city: "CLEAR BROOK" },
  "22625": { state: "VA", city: "CROSS JUNCTION" },
  "22626": { state: "VA", city: "FISHERS HILL" },
  "22627": { state: "VA", city: "FLINT HILL" },
  "22630": { state: "VA", city: "FRONT ROYAL" },
  "22637": { state: "VA", city: "GORE" },
  "22639": { state: "VA", city: "HUME" },
  "22640": { state: "VA", city: "HUNTLY" },
  "22641": { state: "VA", city: "STRASBURG" },
  "22642": { state: "VA", city: "LINDEN" },
  "22643": { state: "VA", city: "MARKHAM" },
  "22644": { state: "VA", city: "MAURERTOWN" },
  "22645": { state: "VA", city: "MIDDLETOWN" },
  "22646": { state: "VA", city: "MILLWOOD" },
  "22649": { state: "VA", city: "MIDDLETOWN" },
  "22650": { state: "VA", city: "RILEYVILLE" },
  "22652": { state: "VA", city: "FORT VALLEY" },
  "22654": { state: "VA", city: "STAR TANNERY" },
  "22655": { state: "VA", city: "STEPHENS CITY" },
  "22656": { state: "VA", city: "STEPHENSON" },
  "22657": { state: "VA", city: "STRASBURG" },
  "22660": { state: "VA", city: "TOMS BROOK" },
  "22663": { state: "VA", city: "WHITE POST" },
  "22664": { state: "VA", city: "WOODSTOCK" },
  "22701": { state: "VA", city: "CULPEPER" },
  "22709": { state: "VA", city: "ARODA" },
  "22711": { state: "VA", city: "BANCO" },
  "22712": { state: "VA", city: "BEALETON" },
  "22713": { state: "VA", city: "BOSTON" },
  "22714": { state: "VA", city: "BRANDY STATION" },
  "22715": { state: "VA", city: "BRIGHTWOOD" },
  "22716": { state: "VA", city: "CASTLETON" },
  "22718": { state: "VA", city: "ELKWOOD" },
  "22719": { state: "VA", city: "ETLAN" },
  "22720": { state: "VA", city: "GOLDVEIN" },
  "22722": { state: "VA", city: "HAYWOOD" },
  "22723": { state: "VA", city: "HOOD" },
  "22724": { state: "VA", city: "JEFFERSONTON" },
  "22725": { state: "VA", city: "LEON" },
  "22726": { state: "VA", city: "LIGNUM" },
  "22727": { state: "VA", city: "MADISON" },
  "22728": { state: "VA", city: "MIDLAND" },
  "22729": { state: "VA", city: "MITCHELLS" },
  "22730": { state: "VA", city: "OAKPARK" },
  "22731": { state: "VA", city: "PRATTS" },
  "22732": { state: "VA", city: "RADIANT" },
  "22733": { state: "VA", city: "RAPIDAN" },
  "22734": { state: "VA", city: "REMINGTON" },
  "22735": { state: "VA", city: "REVA" },
  "22736": { state: "VA", city: "RICHARDSVILLE" },
  "22737": { state: "VA", city: "RIXEYVILLE" },
  "22738": { state: "VA", city: "ROCHELLE" },
  "22739": { state: "VA", city: "SOMERVILLE" },
  "22740": { state: "VA", city: "SPERRYVILLE" },
  "22741": { state: "VA", city: "STEVENSBURG" },
  "22742": { state: "VA", city: "SUMERDUCK" },
  "22743": { state: "VA", city: "SYRIA" },
  "22746": { state: "VA", city: "VIEWTOWN" },
  "22747": { state: "VA", city: "WASHINGTON" },
  "22748": { state: "VA", city: "WOLFTOWN" },
  "22749": { state: "VA", city: "WOODVILLE" },
  "22801": { state: "VA", city: "HARRISONBURG" },
  "22802": { state: "VA", city: "HARRISONBURG" },
  "22803": { state: "VA", city: "HARRISONBURG" },
  "22807": { state: "VA", city: "HARRISONBURG" },
  "22810": { state: "VA", city: "BASYE" },
  "22811": { state: "VA", city: "BERGTON" },
  "22812": { state: "VA", city: "BRIDGEWATER" },
  "22815": { state: "VA", city: "BROADWAY" },
  "22820": { state: "VA", city: "CRIDERS" },
  "22821": { state: "VA", city: "DAYTON" },
  "22824": { state: "VA", city: "EDINBURG" },
  "22827": { state: "VA", city: "ELKTON" },
  "22830": { state: "VA", city: "FULKS RUN" },
  "22831": { state: "VA", city: "HINTON" },
  "22832": { state: "VA", city: "KEEZLETOWN" },
  "22833": { state: "VA", city: "LACEY SPRING" },
  "22834": { state: "VA", city: "LINVILLE" },
  "22835": { state: "VA", city: "LURAY" },
  "22840": { state: "VA", city: "MC GAHEYSVILLE" },
  "22841": { state: "VA", city: "MOUNT CRAWFORD" },
  "22842": { state: "VA", city: "MOUNT JACKSON" },
  "22843": { state: "VA", city: "MOUNT SOLON" },
  "22844": { state: "VA", city: "NEW MARKET" },
  "22845": { state: "VA", city: "ORKNEY SPRINGS" },
  "22846": { state: "VA", city: "PENN LAIRD" },
  "22847": { state: "VA", city: "QUICKSBURG" },
  "22848": { state: "VA", city: "PLEASANT VALLEY" },
  "22849": { state: "VA", city: "SHENANDOAH" },
  "22850": { state: "VA", city: "SINGERS GLEN" },
  "22851": { state: "VA", city: "STANLEY" },
  "22853": { state: "VA", city: "TIMBERVILLE" },
  "22901": { state: "VA", city: "CHARLOTTESVILLE" },
  "22902": { state: "VA", city: "CHARLOTTESVILLE" },
  "22903": { state: "VA", city: "CHARLOTTESVILLE" },
  "22904": { state: "VA", city: "CHARLOTTESVILLE" },
  "22905": { state: "VA", city: "CHARLOTTESVILLE" },
  "22906": { state: "VA", city: "CHARLOTTESVILLE" },
  "22907": { state: "VA", city: "CHARLOTTESVILLE" },
  "22908": { state: "VA", city: "CHARLOTTESVILLE" },
  "22909": { state: "VA", city: "CHARLOTTESVILLE" },
  "22910": { state: "VA", city: "CHARLOTTESVILLE" },
  "22911": { state: "VA", city: "CHARLOTTESVILLE" },
  "22920": { state: "VA", city: "AFTON" },
  "22922": { state: "VA", city: "ARRINGTON" },
  "22923": { state: "VA", city: "BARBOURSVILLE" },
  "22924": { state: "VA", city: "BATESVILLE" },
  "22931": { state: "VA", city: "COVESVILLE" },
  "22932": { state: "VA", city: "CROZET" },
  "22935": { state: "VA", city: "DYKE" },
  "22936": { state: "VA", city: "EARLYSVILLE" },
  "22937": { state: "VA", city: "ESMONT" },
  "22938": { state: "VA", city: "FABER" },
  "22939": { state: "VA", city: "FISHERSVILLE" },
  "22940": { state: "VA", city: "FREE UNION" },
  "22942": { state: "VA", city: "GORDONSVILLE" },
  "22943": { state: "VA", city: "GREENWOOD" },
  "22945": { state: "VA", city: "IVY" },
  "22946": { state: "VA", city: "KEENE" },
  "22947": { state: "VA", city: "KESWICK" },
  "22948": { state: "VA", city: "LOCUST DALE" },
  "22949": { state: "VA", city: "LOVINGSTON" },
  "22952": { state: "VA", city: "LYNDHURST" },
  "22957": { state: "VA", city: "MONTPELIER STATION" },
  "22958": { state: "VA", city: "NELLYSFORD" },
  "22959": { state: "VA", city: "NORTH GARDEN" },
  "22960": { state: "VA", city: "ORANGE" },
  "22963": { state: "VA", city: "PALMYRA" },
  "22964": { state: "VA", city: "PINEY RIVER" },
  "22965": { state: "VA", city: "QUINQUE" },
  "22967": { state: "VA", city: "ROSELAND" },
  "22968": { state: "VA", city: "RUCKERSVILLE" },
  "22969": { state: "VA", city: "SCHUYLER" },
  "22971": { state: "VA", city: "SHIPMAN" },
  "22972": { state: "VA", city: "SOMERSET" },
  "22973": { state: "VA", city: "STANARDSVILLE" },
  "22974": { state: "VA", city: "TROY" },
  "22976": { state: "VA", city: "TYRO" },
  "22980": { state: "VA", city: "WAYNESBORO" },
  "22987": { state: "VA", city: "WHITE HALL" },
  "22989": { state: "VA", city: "WOODBERRY FOREST" },
  "23001": { state: "VA", city: "ACHILLES" },
  "23002": { state: "VA", city: "AMELIA COURT HOUSE" },
  "23003": { state: "VA", city: "ARK" },
  "23004": { state: "VA", city: "ARVONIA" },
  "23005": { state: "VA", city: "ASHLAND" },
  "23009": { state: "VA", city: "AYLETT" },
  "23011": { state: "VA", city: "BARHAMSVILLE" },
  "23014": { state: "VA", city: "BEAUMONT" },
  "23015": { state: "VA", city: "BEAVERDAM" },
  "23018": { state: "VA", city: "BENA" },
  "23021": { state: "VA", city: "BOHANNON" },
  "23022": { state: "VA", city: "BREMO BLUFF" },
  "23023": { state: "VA", city: "BRUINGTON" },
  "23024": { state: "VA", city: "BUMPASS" },
  "23025": { state: "VA", city: "CARDINAL" },
  "23027": { state: "VA", city: "CARTERSVILLE" },
  "23030": { state: "VA", city: "CHARLES CITY" },
  "23031": { state: "VA", city: "CHRISTCHURCH" },
  "23032": { state: "VA", city: "CHURCH VIEW" },
  "23035": { state: "VA", city: "COBBS CREEK" },
  "23038": { state: "VA", city: "COLUMBIA" },
  "23039": { state: "VA", city: "CROZIER" },
  "23040": { state: "VA", city: "CUMBERLAND" },
  "23043": { state: "VA", city: "DELTAVILLE" },
  "23045": { state: "VA", city: "DIGGS" },
  "23047": { state: "VA", city: "DOSWELL" },
  "23050": { state: "VA", city: "DUTTON" },
  "23055": { state: "VA", city: "FORK UNION" },
  "23056": { state: "VA", city: "FOSTER" },
  "23058": { state: "VA", city: "GLEN ALLEN" },
  "23059": { state: "VA", city: "GLEN ALLEN" },
  "23060": { state: "VA", city: "GLEN ALLEN" },
  "23061": { state: "VA", city: "GLOUCESTER" },
  "23062": { state: "VA", city: "GLOUCESTER POINT" },
  "23063": { state: "VA", city: "GOOCHLAND" },
  "23064": { state: "VA", city: "GRIMSTEAD" },
  "23065": { state: "VA", city: "GUM SPRING" },
  "23066": { state: "VA", city: "GWYNN" },
  "23067": { state: "VA", city: "HADENSVILLE" },
  "23068": { state: "VA", city: "HALLIEFORD" },
  "23069": { state: "VA", city: "HANOVER" },
  "23070": { state: "VA", city: "HARDYVILLE" },
  "23071": { state: "VA", city: "HARTFIELD" },
  "23072": { state: "VA", city: "HAYES" },
  "23075": { state: "VA", city: "HENRICO" },
  "23076": { state: "VA", city: "HUDGINS" },
  "23079": { state: "VA", city: "JAMAICA" },
  "23081": { state: "VA", city: "JAMESTOWN" },
  "23083": { state: "VA", city: "JETERSVILLE" },
  "23084": { state: "VA", city: "KENTS STORE" },
  "23085": { state: "VA", city: "KING AND QUEEN COURT HOUSE" },
  "23086": { state: "VA", city: "KING WILLIAM" },
  "23089": { state: "VA", city: "LANEXA" },
  "23090": { state: "VA", city: "LIGHTFOOT" },
  "23091": { state: "VA", city: "LITTLE PLYMOUTH" },
  "23092": { state: "VA", city: "LOCUST HILL" },
  "23093": { state: "VA", city: "LOUISA" },
  "23102": { state: "VA", city: "MAIDENS" },
  "23103": { state: "VA", city: "MANAKIN SABOT" },
  "23105": { state: "VA", city: "MANNBORO" },
  "23106": { state: "VA", city: "MANQUIN" },
  "23107": { state: "VA", city: "MARYUS" },
  "23108": { state: "VA", city: "MASCOT" },
  "23109": { state: "VA", city: "MATHEWS" },
  "23110": { state: "VA", city: "MATTAPONI" },
  "23111": { state: "VA", city: "MECHANICSVILLE" },
  "23112": { state: "VA", city: "MIDLOTHIAN" },
  "23113": { state: "VA", city: "MIDLOTHIAN" },
  "23114": { state: "VA", city: "MIDLOTHIAN" },
  "23115": { state: "VA", city: "MILLERS TAVERN" },
  "23116": { state: "VA", city: "MECHANICSVILLE" },
  "23117": { state: "VA", city: "MINERAL" },
  "23119": { state: "VA", city: "MOON" },
  "23120": { state: "VA", city: "MOSELEY" },
  "23123": { state: "VA", city: "NEW CANTON" },
  "23124": { state: "VA", city: "NEW KENT" },
  "23125": { state: "VA", city: "NEW POINT" },
  "23126": { state: "VA", city: "NEWTOWN" },
  "23127": { state: "VA", city: "NORGE" },
  "23128": { state: "VA", city: "NORTH" },
  "23129": { state: "VA", city: "OILVILLE" },
  "23130": { state: "VA", city: "ONEMO" },
  "23131": { state: "VA", city: "ORDINARY" },
  "23138": { state: "VA", city: "PORT HAYWOOD" },
  "23139": { state: "VA", city: "POWHATAN" },
  "23140": { state: "VA", city: "PROVIDENCE FORGE" },
  "23141": { state: "VA", city: "QUINTON" },
  "23146": { state: "VA", city: "ROCKVILLE" },
  "23147": { state: "VA", city: "RUTHVILLE" },
  "23148": { state: "VA", city: "SAINT STEPHENS CHURCH" },
  "23149": { state: "VA", city: "SALUDA" },
  "23150": { state: "VA", city: "SANDSTON" },
  "23153": { state: "VA", city: "SANDY HOOK" },
  "23154": { state: "VA", city: "SCHLEY" },
  "23155": { state: "VA", city: "SEVERN" },
  "23156": { state: "VA", city: "SHACKLEFORDS" },
  "23160": { state: "VA", city: "STATE FARM" },
  "23161": { state: "VA", city: "STEVENSVILLE" },
  "23162": { state: "VA", city: "STUDLEY" },
  "23163": { state: "VA", city: "SUSAN" },
  "23168": { state: "VA", city: "TOANO" },
  "23169": { state: "VA", city: "TOPPING" },
  "23170": { state: "VA", city: "TREVILIANS" },
  "23173": { state: "VA", city: "RICHMOND" },
  "23175": { state: "VA", city: "URBANNA" },
  "23176": { state: "VA", city: "WAKE" },
  "23177": { state: "VA", city: "WALKERTON" },
  "23178": { state: "VA", city: "WARE NECK" },
  "23180": { state: "VA", city: "WATER VIEW" },
  "23181": { state: "VA", city: "WEST POINT" },
  "23183": { state: "VA", city: "WHITE MARSH" },
  "23184": { state: "VA", city: "WICOMICO" },
  "23185": { state: "VA", city: "WILLIAMSBURG" },
  "23186": { state: "VA", city: "WILLIAMSBURG" },
  "23187": { state: "VA", city: "WILLIAMSBURG" },
  "23188": { state: "VA", city: "WILLIAMSBURG" },
  "23190": { state: "VA", city: "WOODS CROSS ROADS" },
  "23192": { state: "VA", city: "MONTPELIER" },
  "23218": { state: "VA", city: "RICHMOND" },
  "23219": { state: "VA", city: "RICHMOND" },
  "23220": { state: "VA", city: "RICHMOND" },
  "23221": { state: "VA", city: "RICHMOND" },
  "23222": { state: "VA", city: "RICHMOND" },
  "23223": { state: "VA", city: "RICHMOND" },
  "23224": { state: "VA", city: "RICHMOND" },
  "23225": { state: "VA", city: "RICHMOND" },
  "23226": { state: "VA", city: "RICHMOND" },
  "23227": { state: "VA", city: "RICHMOND" },
  "23228": { state: "VA", city: "HENRICO" },
  "23229": { state: "VA", city: "HENRICO" },
  "23230": { state: "VA", city: "RICHMOND" },
  "23231": { state: "VA", city: "HENRICO" },
  "23232": { state: "VA", city: "RICHMOND" },
  "23233": { state: "VA", city: "HENRICO" },
  "23234": { state: "VA", city: "RICHMOND" },
  "23235": { state: "VA", city: "RICHMOND" },
  "23236": { state: "VA", city: "RICHMOND" },
  "23237": { state: "VA", city: "RICHMOND" },
  "23238": { state: "VA", city: "HENRICO" },
  "23241": { state: "VA", city: "RICHMOND" },
  "23242": { state: "VA", city: "HENRICO" },
  "23249": { state: "VA", city: "RICHMOND" },
  "23250": { state: "VA", city: "RICHMOND" },
  "23255": { state: "VA", city: "HENRICO" },
  "23260": { state: "VA", city: "RICHMOND" },
  "23261": { state: "VA", city: "RICHMOND" },
  "23269": { state: "VA", city: "RICHMOND" },
  "23273": { state: "VA", city: "HENRICO" },
  "23274": { state: "VA", city: "RICHMOND" },
  "23276": { state: "VA", city: "RICHMOND" },
  "23278": { state: "VA", city: "RICHMOND" },
  "23279": { state: "VA", city: "RICHMOND" },
  "23282": { state: "VA", city: "RICHMOND" },
  "23284": { state: "VA", city: "RICHMOND" },
  "23285": { state: "VA", city: "RICHMOND" },
  "23286": { state: "VA", city: "RICHMOND" },
  "23288": { state: "VA", city: "HENRICO" },
  "23289": { state: "VA", city: "RICHMOND" },
  "23290": { state: "VA", city: "RICHMOND" },
  "23291": { state: "VA", city: "RICHMOND" },
  "23292": { state: "VA", city: "RICHMOND" },
  "23293": { state: "VA", city: "RICHMOND" },
  "23294": { state: "VA", city: "HENRICO" },
  "23295": { state: "VA", city: "RICHMOND" },
  "23297": { state: "VA", city: "RICHMOND" },
  "23298": { state: "VA", city: "RICHMOND" },
  "23301": { state: "VA", city: "ACCOMAC" },
  "23302": { state: "VA", city: "ASSAWOMAN" },
  "23303": { state: "VA", city: "ATLANTIC" },
  "23304": { state: "VA", city: "BATTERY PARK" },
  "23306": { state: "VA", city: "BELLE HAVEN" },
  "23307": { state: "VA", city: "BIRDSNEST" },
  "23308": { state: "VA", city: "BLOXOM" },
  "23310": { state: "VA", city: "CAPE CHARLES" },
  "23313": { state: "VA", city: "CAPEVILLE" },
  "23314": { state: "VA", city: "CARROLLTON" },
  "23315": { state: "VA", city: "CARRSVILLE" },
  "23316": { state: "VA", city: "CHERITON" },
  "23320": { state: "VA", city: "CHESAPEAKE" },
  "23321": { state: "VA", city: "CHESAPEAKE" },
  "23322": { state: "VA", city: "CHESAPEAKE" },
  "23323": { state: "VA", city: "CHESAPEAKE" },
  "23324": { state: "VA", city: "CHESAPEAKE" },
  "23325": { state: "VA", city: "CHESAPEAKE" },
  "23326": { state: "VA", city: "CHESAPEAKE" },
  "23327": { state: "VA", city: "CHESAPEAKE" },
  "23328": { state: "VA", city: "CHESAPEAKE" },
  "23336": { state: "VA", city: "CHINCOTEAGUE ISLAND" },
  "23337": { state: "VA", city: "WALLOPS ISLAND" },
  "23341": { state: "VA", city: "CRADDOCKVILLE" },
  "23345": { state: "VA", city: "DAVIS WHARF" },
  "23347": { state: "VA", city: "EASTVILLE" },
  "23350": { state: "VA", city: "EXMORE" },
  "23354": { state: "VA", city: "FRANKTOWN" },
  "23356": { state: "VA", city: "GREENBACKVILLE" },
  "23357": { state: "VA", city: "GREENBUSH" },
  "23358": { state: "VA", city: "HACKSNECK" },
  "23359": { state: "VA", city: "HALLWOOD" },
  "23389": { state: "VA", city: "HARBORTON" },
  "23395": { state: "VA", city: "HORNTOWN" },
  "23396": { state: "VA", city: "OAK HALL" },
  "23397": { state: "VA", city: "ISLE OF WIGHT" },
  "23398": { state: "VA", city: "JAMESVILLE" },
  "23399": { state: "VA", city: "JENKINS BRIDGE" },
  "23401": { state: "VA", city: "KELLER" },
  "23404": { state: "VA", city: "LOCUSTVILLE" },
  "23405": { state: "VA", city: "MACHIPONGO" },
  "23407": { state: "VA", city: "MAPPSVILLE" },
  "23408": { state: "VA", city: "MARIONVILLE" },
  "23409": { state: "VA", city: "MEARS" },
  "23410": { state: "VA", city: "MELFA" },
  "23412": { state: "VA", city: "MODEST TOWN" },
  "23413": { state: "VA", city: "NASSAWADOX" },
  "23414": { state: "VA", city: "NELSONIA" },
  "23415": { state: "VA", city: "NEW CHURCH" },
  "23416": { state: "VA", city: "OAK HALL" },
  "23417": { state: "VA", city: "ONANCOCK" },
  "23418": { state: "VA", city: "ONLEY" },
  "23419": { state: "VA", city: "OYSTER" },
  "23420": { state: "VA", city: "PAINTER" },
  "23421": { state: "VA", city: "PARKSLEY" },
  "23422": { state: "VA", city: "PUNGOTEAGUE" },
  "23423": { state: "VA", city: "QUINBY" },
  "23424": { state: "VA", city: "RESCUE" },
  "23426": { state: "VA", city: "SANFORD" },
  "23427": { state: "VA", city: "SAXIS" },
  "23429": { state: "VA", city: "SEAVIEW" },
  "23430": { state: "VA", city: "SMITHFIELD" },
  "23431": { state: "VA", city: "SMITHFIELD" },
  "23432": { state: "VA", city: "SUFFOLK" },
  "23433": { state: "VA", city: "SUFFOLK" },
  "23434": { state: "VA", city: "SUFFOLK" },
  "23435": { state: "VA", city: "SUFFOLK" },
  "23436": { state: "VA", city: "SUFFOLK" },
  "23437": { state: "VA", city: "SUFFOLK" },
  "23438": { state: "VA", city: "SUFFOLK" },
  "23439": { state: "VA", city: "SUFFOLK" },
  "23440": { state: "VA", city: "TANGIER" },
  "23441": { state: "VA", city: "TASLEY" },
  "23442": { state: "VA", city: "TEMPERANCEVILLE" },
  "23443": { state: "VA", city: "TOWNSEND" },
  "23450": { state: "VA", city: "VIRGINIA BEACH" },
  "23451": { state: "VA", city: "VIRGINIA BEACH" },
  "23452": { state: "VA", city: "VIRGINIA BEACH" },
  "23453": { state: "VA", city: "VIRGINIA BEACH" },
  "23454": { state: "VA", city: "VIRGINIA BEACH" },
  "23455": { state: "VA", city: "VIRGINIA BEACH" },
  "23456": { state: "VA", city: "VIRGINIA BEACH" },
  "23457": { state: "VA", city: "VIRGINIA BEACH" },
  "23458": { state: "VA", city: "VIRGINIA BEACH" },
  "23459": { state: "VA", city: "VIRGINIA BEACH" },
  "23460": { state: "VA", city: "VIRGINIA BEACH" },
  "23461": { state: "VA", city: "VIRGINIA BEACH" },
  "23462": { state: "VA", city: "VIRGINIA BEACH" },
  "23463": { state: "VA", city: "VIRGINIA BEACH" },
  "23464": { state: "VA", city: "VIRGINIA BEACH" },
  "23465": { state: "VA", city: "VIRGINIA BEACH" },
  "23466": { state: "VA", city: "VIRGINIA BEACH" },
  "23467": { state: "VA", city: "VIRGINIA BEACH" },
  "23471": { state: "VA", city: "VIRGINIA BEACH" },
  "23479": { state: "VA", city: "VIRGINIA BEACH" },
  "23480": { state: "VA", city: "WACHAPREAGUE" },
  "23482": { state: "VA", city: "WARDTOWN" },
  "23483": { state: "VA", city: "WATTSVILLE" },
  "23486": { state: "VA", city: "WILLIS WHARF" },
  "23487": { state: "VA", city: "WINDSOR" },
  "23488": { state: "VA", city: "WITHAMS" },
  "23501": { state: "VA", city: "NORFOLK" },
  "23502": { state: "VA", city: "NORFOLK" },
  "23503": { state: "VA", city: "NORFOLK" },
  "23504": { state: "VA", city: "NORFOLK" },
  "23505": { state: "VA", city: "NORFOLK" },
  "23506": { state: "VA", city: "NORFOLK" },
  "23507": { state: "VA", city: "NORFOLK" },
  "23508": { state: "VA", city: "NORFOLK" },
  "23509": { state: "VA", city: "NORFOLK" },
  "23510": { state: "VA", city: "NORFOLK" },
  "23511": { state: "VA", city: "NORFOLK" },
  "23513": { state: "VA", city: "NORFOLK" },
  "23514": { state: "VA", city: "NORFOLK" },
  "23515": { state: "VA", city: "NORFOLK" },
  "23517": { state: "VA", city: "NORFOLK" },
  "23518": { state: "VA", city: "NORFOLK" },
  "23519": { state: "VA", city: "NORFOLK" },
  "23523": { state: "VA", city: "NORFOLK" },
  "23529": { state: "VA", city: "NORFOLK" },
  "23541": { state: "VA", city: "NORFOLK" },
  "23551": { state: "VA", city: "NORFOLK" },
  "23601": { state: "VA", city: "NEWPORT NEWS" },
  "23602": { state: "VA", city: "NEWPORT NEWS" },
  "23603": { state: "VA", city: "NEWPORT NEWS" },
  "23604": { state: "VA", city: "FORT EUSTIS" },
  "23605": { state: "VA", city: "NEWPORT NEWS" },
  "23606": { state: "VA", city: "NEWPORT NEWS" },
  "23607": { state: "VA", city: "NEWPORT NEWS" },
  "23608": { state: "VA", city: "NEWPORT NEWS" },
  "23609": { state: "VA", city: "NEWPORT NEWS" },
  "23612": { state: "VA", city: "NEWPORT NEWS" },
  "23628": { state: "VA", city: "NEWPORT NEWS" },
  "23630": { state: "VA", city: "HAMPTON" },
  "23651": { state: "VA", city: "FORT MONROE" },
  "23661": { state: "VA", city: "HAMPTON" },
  "23662": { state: "VA", city: "POQUOSON" },
  "23663": { state: "VA", city: "HAMPTON" },
  "23664": { state: "VA", city: "HAMPTON" },
  "23665": { state: "VA", city: "HAMPTON" },
  "23666": { state: "VA", city: "HAMPTON" },
  "23667": { state: "VA", city: "HAMPTON" },
  "23668": { state: "VA", city: "HAMPTON" },
  "23669": { state: "VA", city: "HAMPTON" },
  "23670": { state: "VA", city: "HAMPTON" },
  "23681": { state: "VA", city: "HAMPTON" },
  "23690": { state: "VA", city: "YORKTOWN" },
  "23691": { state: "VA", city: "YORKTOWN" },
  "23692": { state: "VA", city: "YORKTOWN" },
  "23693": { state: "VA", city: "YORKTOWN" },
  "23694": { state: "VA", city: "LACKEY" },
  "23696": { state: "VA", city: "SEAFORD" },
  "23701": { state: "VA", city: "PORTSMOUTH" },
  "23702": { state: "VA", city: "PORTSMOUTH" },
  "23703": { state: "VA", city: "PORTSMOUTH" },
  "23704": { state: "VA", city: "PORTSMOUTH" },
  "23705": { state: "VA", city: "PORTSMOUTH" },
  "23707": { state: "VA", city: "PORTSMOUTH" },
  "23708": { state: "VA", city: "PORTSMOUTH" },
  "23709": { state: "VA", city: "PORTSMOUTH" },
  "23801": { state: "VA", city: "FORT GREGG ADAMS" },
  "23803": { state: "VA", city: "PETERSBURG" },
  "23804": { state: "VA", city: "PETERSBURG" },
  "23805": { state: "VA", city: "PETERSBURG" },
  "23806": { state: "VA", city: "VIRGINIA STATE UNIVERSITY" },
  "23821": { state: "VA", city: "ALBERTA" },
  "23822": { state: "VA", city: "AMMON" },
  "23824": { state: "VA", city: "BLACKSTONE" },
  "23827": { state: "VA", city: "BOYKINS" },
  "23828": { state: "VA", city: "BRANCHVILLE" },
  "23829": { state: "VA", city: "CAPRON" },
  "23830": { state: "VA", city: "CARSON" },
  "23831": { state: "VA", city: "CHESTER" },
  "23832": { state: "VA", city: "CHESTERFIELD" },
  "23833": { state: "VA", city: "CHURCH ROAD" },
  "23834": { state: "VA", city: "COLONIAL HEIGHTS" },
  "23836": { state: "VA", city: "CHESTER" },
  "23837": { state: "VA", city: "COURTLAND" },
  "23838": { state: "VA", city: "CHESTERFIELD" },
  "23839": { state: "VA", city: "DENDRON" },
  "23840": { state: "VA", city: "DEWITT" },
  "23841": { state: "VA", city: "DINWIDDIE" },
  "23842": { state: "VA", city: "DISPUTANTA" },
  "23843": { state: "VA", city: "DOLPHIN" },
  "23844": { state: "VA", city: "DREWRYVILLE" },
  "23845": { state: "VA", city: "EBONY" },
  "23846": { state: "VA", city: "ELBERON" },
  "23847": { state: "VA", city: "EMPORIA" },
  "23850": { state: "VA", city: "FORD" },
  "23851": { state: "VA", city: "FRANKLIN" },
  "23856": { state: "VA", city: "FREEMAN" },
  "23857": { state: "VA", city: "GASBURG" },
  "23860": { state: "VA", city: "HOPEWELL" },
  "23866": { state: "VA", city: "IVOR" },
  "23867": { state: "VA", city: "JARRATT" },
  "23868": { state: "VA", city: "LAWRENCEVILLE" },
  "23870": { state: "VA", city: "JARRATT" },
  "23872": { state: "VA", city: "MC KENNEY" },
  "23873": { state: "VA", city: "MEREDITHVILLE" },
  "23874": { state: "VA", city: "NEWSOMS" },
  "23875": { state: "VA", city: "PRINCE GEORGE" },
  "23876": { state: "VA", city: "RAWLINGS" },
  "23878": { state: "VA", city: "SEDLEY" },
  "23879": { state: "VA", city: "SKIPPERS" },
  "23881": { state: "VA", city: "SPRING GROVE" },
  "23882": { state: "VA", city: "STONY CREEK" },
  "23883": { state: "VA", city: "SURRY" },
  "23884": { state: "VA", city: "SUSSEX" },
  "23885": { state: "VA", city: "SUTHERLAND" },
  "23887": { state: "VA", city: "VALENTINES" },
  "23888": { state: "VA", city: "WAKEFIELD" },
  "23889": { state: "VA", city: "WARFIELD" },
  "23890": { state: "VA", city: "WAVERLY" },
  "23891": { state: "VA", city: "WAVERLY" },
  "23893": { state: "VA", city: "WHITE PLAINS" },
  "23894": { state: "VA", city: "WILSONS" },
  "23897": { state: "VA", city: "YALE" },
  "23898": { state: "VA", city: "ZUNI" },
  "23899": { state: "VA", city: "CLAREMONT" },
  "23901": { state: "VA", city: "FARMVILLE" },
  "23909": { state: "VA", city: "FARMVILLE" },
  "23915": { state: "VA", city: "BASKERVILLE" },
  "23917": { state: "VA", city: "BOYDTON" },
  "23919": { state: "VA", city: "BRACEY" },
  "23920": { state: "VA", city: "BRODNAX" },
  "23921": { state: "VA", city: "BUCKINGHAM" },
  "23922": { state: "VA", city: "BURKEVILLE" },
  "23923": { state: "VA", city: "CHARLOTTE COURT HOUSE" },
  "23924": { state: "VA", city: "CHASE CITY" },
  "23927": { state: "VA", city: "CLARKSVILLE" },
  "23930": { state: "VA", city: "CREWE" },
  "23934": { state: "VA", city: "CULLEN" },
  "23936": { state: "VA", city: "DILLWYN" },
  "23937": { state: "VA", city: "DRAKES BRANCH" },
  "23938": { state: "VA", city: "DUNDAS" },
  "23939": { state: "VA", city: "EVERGREEN" },
  "23941": { state: "VA", city: "FORT MITCHELL" },
  "23942": { state: "VA", city: "GREEN BAY" },
  "23943": { state: "VA", city: "HAMPDEN SYDNEY" },
  "23944": { state: "VA", city: "KENBRIDGE" },
  "23947": { state: "VA", city: "KEYSVILLE" },
  "23950": { state: "VA", city: "LA CROSSE" },
  "23952": { state: "VA", city: "LUNENBURG" },
  "23954": { state: "VA", city: "MEHERRIN" },
  "23955": { state: "VA", city: "NOTTOWAY" },
  "23958": { state: "VA", city: "PAMPLIN" },
  "23959": { state: "VA", city: "PHENIX" },
  "23960": { state: "VA", city: "PROSPECT" },
  "23962": { state: "VA", city: "RANDOLPH" },
  "23963": { state: "VA", city: "RED HOUSE" },
  "23964": { state: "VA", city: "RED OAK" },
  "23966": { state: "VA", city: "RICE" },
  "23967": { state: "VA", city: "SAXE" },
  "23968": { state: "VA", city: "SKIPWITH" },
  "23970": { state: "VA", city: "SOUTH HILL" },
  "23974": { state: "VA", city: "VICTORIA" },
  "23976": { state: "VA", city: "WYLLIESBURG" },
  "24001": { state: "VA", city: "ROANOKE" },
  "24002": { state: "VA", city: "ROANOKE" },
  "24003": { state: "VA", city: "ROANOKE" },
  "24004": { state: "VA", city: "ROANOKE" },
  "24005": { state: "VA", city: "ROANOKE" },
  "24006": { state: "VA", city: "ROANOKE" },
  "24007": { state: "VA", city: "ROANOKE" },
  "24008": { state: "VA", city: "ROANOKE" },
  "24009": { state: "VA", city: "ROANOKE" },
  "24010": { state: "VA", city: "ROANOKE" },
  "24011": { state: "VA", city: "ROANOKE" },
  "24012": { state: "VA", city: "ROANOKE" },
  "24013": { state: "VA", city: "ROANOKE" },
  "24014": { state: "VA", city: "ROANOKE" },
  "24015": { state: "VA", city: "ROANOKE" },
  "24016": { state: "VA", city: "ROANOKE" },
  "24017": { state: "VA", city: "ROANOKE" },
  "24018": { state: "VA", city: "ROANOKE" },
  "24019": { state: "VA", city: "ROANOKE" },
  "24020": { state: "VA", city: "ROANOKE" },
  "24022": { state: "VA", city: "ROANOKE" },
  "24023": { state: "VA", city: "ROANOKE" },
  "24024": { state: "VA", city: "ROANOKE" },
  "24025": { state: "VA", city: "ROANOKE" },
  "24026": { state: "VA", city: "ROANOKE" },
  "24027": { state: "VA", city: "ROANOKE" },
  "24028": { state: "VA", city: "ROANOKE" },
  "24029": { state: "VA", city: "ROANOKE" },
  "24030": { state: "VA", city: "ROANOKE" },
  "24031": { state: "VA", city: "ROANOKE" },
  "24032": { state: "VA", city: "ROANOKE" },
  "24033": { state: "VA", city: "ROANOKE" },
  "24034": { state: "VA", city: "ROANOKE" },
  "24035": { state: "VA", city: "ROANOKE" },
  "24036": { state: "VA", city: "ROANOKE" },
  "24037": { state: "VA", city: "ROANOKE" },
  "24038": { state: "VA", city: "ROANOKE" },
  "24040": { state: "VA", city: "ROANOKE" },
  "24042": { state: "VA", city: "ROANOKE" },
  "24043": { state: "VA", city: "ROANOKE" },
  "24050": { state: "VA", city: "ROANOKE" },
  "24053": { state: "VA", city: "ARARAT" },
  "24054": { state: "VA", city: "AXTON" },
  "24055": { state: "VA", city: "BASSETT" },
  "24058": { state: "VA", city: "BELSPRING" },
  "24059": { state: "VA", city: "BENT MOUNTAIN" },
  "24060": { state: "VA", city: "BLACKSBURG" },
  "24061": { state: "VA", city: "BLACKSBURG" },
  "24062": { state: "VA", city: "BLACKSBURG" },
  "24063": { state: "VA", city: "BLACKSBURG" },
  "24064": { state: "VA", city: "BLUE RIDGE" },
  "24065": { state: "VA", city: "BOONES MILL" },
  "24066": { state: "VA", city: "BUCHANAN" },
  "24067": { state: "VA", city: "CALLAWAY" },
  "24068": { state: "VA", city: "CHRISTIANSBURG" },
  "24069": { state: "VA", city: "CASCADE" },
  "24070": { state: "VA", city: "CATAWBA" },
  "24072": { state: "VA", city: "CHECK" },
  "24073": { state: "VA", city: "CHRISTIANSBURG" },
  "24076": { state: "VA", city: "CLAUDVILLE" },
  "24077": { state: "VA", city: "CLOVERDALE" },
  "24078": { state: "VA", city: "COLLINSVILLE" },
  "24079": { state: "VA", city: "COPPER HILL" },
  "24082": { state: "VA", city: "CRITZ" },
  "24083": { state: "VA", city: "DALEVILLE" },
  "24084": { state: "VA", city: "DUBLIN" },
  "24085": { state: "VA", city: "EAGLE ROCK" },
  "24086": { state: "VA", city: "EGGLESTON" },
  "24087": { state: "VA", city: "ELLISTON" },
  "24088": { state: "VA", city: "FERRUM" },
  "24089": { state: "VA", city: "FIELDALE" },
  "24090": { state: "VA", city: "FINCASTLE" },
  "24091": { state: "VA", city: "FLOYD" },
  "24092": { state: "VA", city: "GLADE HILL" },
  "24093": { state: "VA", city: "GLEN LYN" },
  "24095": { state: "VA", city: "GOODVIEW" },
  "24101": { state: "VA", city: "HARDY" },
  "24102": { state: "VA", city: "HENRY" },
  "24104": { state: "VA", city: "HUDDLESTON" },
  "24105": { state: "VA", city: "INDIAN VALLEY" },
  "24111": { state: "VA", city: "MC COY" },
  "24112": { state: "VA", city: "MARTINSVILLE" },
  "24113": { state: "VA", city: "MARTINSVILLE" },
  "24114": { state: "VA", city: "MARTINSVILLE" },
  "24115": { state: "VA", city: "MARTINSVILLE" },
  "24120": { state: "VA", city: "MEADOWS OF DAN" },
  "24121": { state: "VA", city: "MONETA" },
  "24122": { state: "VA", city: "MONTVALE" },
  "24124": { state: "VA", city: "NARROWS" },
  "24126": { state: "VA", city: "NEWBERN" },
  "24127": { state: "VA", city: "NEW CASTLE" },
  "24128": { state: "VA", city: "NEWPORT" },
  "24129": { state: "VA", city: "NEW RIVER" },
  "24130": { state: "VA", city: "ORISKANY" },
  "24131": { state: "VA", city: "PAINT BANK" },
  "24132": { state: "VA", city: "PARROTT" },
  "24133": { state: "VA", city: "PATRICK SPRINGS" },
  "24134": { state: "VA", city: "PEARISBURG" },
  "24136": { state: "VA", city: "PEMBROKE" },
  "24137": { state: "VA", city: "PENHOOK" },
  "24138": { state: "VA", city: "PILOT" },
  "24139": { state: "VA", city: "PITTSVILLE" },
  "24141": { state: "VA", city: "RADFORD" },
  "24142": { state: "VA", city: "RADFORD" },
  "24143": { state: "VA", city: "RADFORD" },
  "24146": { state: "VA", city: "REDWOOD" },
  "24147": { state: "VA", city: "RICH CREEK" },
  "24148": { state: "VA", city: "RIDGEWAY" },
  "24149": { state: "VA", city: "RINER" },
  "24150": { state: "VA", city: "RIPPLEMEAD" },
  "24151": { state: "VA", city: "ROCKY MOUNT" },
  "24153": { state: "VA", city: "SALEM" },
  "24155": { state: "VA", city: "ROANOKE" },
  "24157": { state: "VA", city: "ROANOKE" },
  "24161": { state: "VA", city: "SANDY LEVEL" },
  "24162": { state: "VA", city: "SHAWSVILLE" },
  "24165": { state: "VA", city: "SPENCER" },
  "24167": { state: "VA", city: "STAFFORDSVILLE" },
  "24168": { state: "VA", city: "STANLEYTOWN" },
  "24171": { state: "VA", city: "STUART" },
  "24174": { state: "VA", city: "THAXTON" },
  "24175": { state: "VA", city: "TROUTVILLE" },
  "24176": { state: "VA", city: "UNION HALL" },
  "24177": { state: "VA", city: "VESTA" },
  "24178": { state: "VA", city: "VILLAMONT" },
  "24179": { state: "VA", city: "VINTON" },
  "24184": { state: "VA", city: "WIRTZ" },
  "24185": { state: "VA", city: "WOOLWINE" },
  "24201": { state: "VA", city: "BRISTOL" },
  "24202": { state: "VA", city: "BRISTOL" },
  "24203": { state: "VA", city: "BRISTOL" },
  "24205": { state: "VA", city: "BRISTOL" },
  "24209": { state: "VA", city: "BRISTOL" },
  "24210": { state: "VA", city: "ABINGDON" },
  "24211": { state: "VA", city: "ABINGDON" },
  "24212": { state: "VA", city: "ABINGDON" },
  "24215": { state: "VA", city: "ANDOVER" },
  "24216": { state: "VA", city: "APPALACHIA" },
  "24217": { state: "VA", city: "BEE" },
  "24218": { state: "VA", city: "BEN HUR" },
  "24219": { state: "VA", city: "BIG STONE GAP" },
  "24220": { state: "VA", city: "BIRCHLEAF" },
  "24221": { state: "VA", city: "BLACKWATER" },
  "24224": { state: "VA", city: "CASTLEWOOD" },
  "24225": { state: "VA", city: "CLEVELAND" },
  "24226": { state: "VA", city: "CLINCHCO" },
  "24228": { state: "VA", city: "CLINTWOOD" },
  "24230": { state: "VA", city: "COEBURN" },
  "24236": { state: "VA", city: "DAMASCUS" },
  "24237": { state: "VA", city: "DANTE" },
  "24239": { state: "VA", city: "DAVENPORT" },
  "24243": { state: "VA", city: "DRYDEN" },
  "24244": { state: "VA", city: "DUFFIELD" },
  "24245": { state: "VA", city: "DUNGANNON" },
  "24246": { state: "VA", city: "EAST STONE GAP" },
  "24248": { state: "VA", city: "EWING" },
  "24250": { state: "VA", city: "FORT BLACKMORE" },
  "24251": { state: "VA", city: "GATE CITY" },
  "24256": { state: "VA", city: "HAYSI" },
  "24258": { state: "VA", city: "HILTONS" },
  "24260": { state: "VA", city: "HONAKER" },
  "24263": { state: "VA", city: "JONESVILLE" },
  "24265": { state: "VA", city: "KEOKEE" },
  "24266": { state: "VA", city: "LEBANON" },
  "24269": { state: "VA", city: "MC CLURE" },
  "24270": { state: "VA", city: "MENDOTA" },
  "24271": { state: "VA", city: "NICKELSVILLE" },
  "24272": { state: "VA", city: "NORA" },
  "24273": { state: "VA", city: "NORTON" },
  "24277": { state: "VA", city: "PENNINGTON GAP" },
  "24279": { state: "VA", city: "POUND" },
  "24280": { state: "VA", city: "ROSEDALE" },
  "24281": { state: "VA", city: "ROSE HILL" },
  "24282": { state: "VA", city: "SAINT CHARLES" },
  "24283": { state: "VA", city: "SAINT PAUL" },
  "24290": { state: "VA", city: "WEBER CITY" },
  "24292": { state: "VA", city: "WHITETOP" },
  "24293": { state: "VA", city: "WISE" },
  "24301": { state: "VA", city: "PULASKI" },
  "24311": { state: "VA", city: "ATKINS" },
  "24312": { state: "VA", city: "AUSTINVILLE" },
  "24313": { state: "VA", city: "BARREN SPRINGS" },
  "24314": { state: "VA", city: "BASTIAN" },
  "24315": { state: "VA", city: "BLAND" },
  "24316": { state: "VA", city: "BROADFORD" },
  "24317": { state: "VA", city: "CANA" },
  "24318": { state: "VA", city: "CERES" },
  "24319": { state: "VA", city: "CHILHOWIE" },
  "24322": { state: "VA", city: "CRIPPLE CREEK" },
  "24323": { state: "VA", city: "CROCKETT" },
  "24324": { state: "VA", city: "DRAPER" },
  "24325": { state: "VA", city: "DUGSPUR" },
  "24326": { state: "VA", city: "ELK CREEK" },
  "24327": { state: "VA", city: "EMORY" },
  "24328": { state: "VA", city: "FANCY GAP" },
  "24330": { state: "VA", city: "FRIES" },
  "24333": { state: "VA", city: "GALAX" },
  "24340": { state: "VA", city: "GLADE SPRING" },
  "24343": { state: "VA", city: "HILLSVILLE" },
  "24347": { state: "VA", city: "HIWASSEE" },
  "24348": { state: "VA", city: "INDEPENDENCE" },
  "24350": { state: "VA", city: "IVANHOE" },
  "24351": { state: "VA", city: "LAMBSBURG" },
  "24352": { state: "VA", city: "LAUREL FORK" },
  "24354": { state: "VA", city: "MARION" },
  "24360": { state: "VA", city: "MAX MEADOWS" },
  "24361": { state: "VA", city: "MEADOWVIEW" },
  "24363": { state: "VA", city: "MOUTH OF WILSON" },
  "24366": { state: "VA", city: "ROCKY GAP" },
  "24368": { state: "VA", city: "RURAL RETREAT" },
  "24370": { state: "VA", city: "SALTVILLE" },
  "24374": { state: "VA", city: "SPEEDWELL" },
  "24375": { state: "VA", city: "SUGAR GROVE" },
  "24377": { state: "VA", city: "TANNERSVILLE" },
  "24378": { state: "VA", city: "TROUTDALE" },
  "24380": { state: "VA", city: "WILLIS" },
  "24381": { state: "VA", city: "WOODLAWN" },
  "24382": { state: "VA", city: "WYTHEVILLE" },
  "24401": { state: "VA", city: "STAUNTON" },
  "24402": { state: "VA", city: "STAUNTON" },
  "24411": { state: "VA", city: "AUGUSTA SPRINGS" },
  "24412": { state: "VA", city: "BACOVA" },
  "24413": { state: "VA", city: "BLUE GRASS" },
  "24415": { state: "VA", city: "BROWNSBURG" },
  "24416": { state: "VA", city: "BUENA VISTA" },
  "24421": { state: "VA", city: "CHURCHVILLE" },
  "24422": { state: "VA", city: "CLIFTON FORGE" },
  "24426": { state: "VA", city: "COVINGTON" },
  "24430": { state: "VA", city: "CRAIGSVILLE" },
  "24431": { state: "VA", city: "CRIMORA" },
  "24432": { state: "VA", city: "DEERFIELD" },
  "24433": { state: "VA", city: "DOE HILL" },
  "24435": { state: "VA", city: "FAIRFIELD" },
  "24437": { state: "VA", city: "FORT DEFIANCE" },
  "24438": { state: "VA", city: "GLEN WILTON" },
  "24439": { state: "VA", city: "GOSHEN" },
  "24440": { state: "VA", city: "GREENVILLE" },
  "24441": { state: "VA", city: "GROTTOES" },
  "24442": { state: "VA", city: "HEAD WATERS" },
  "24445": { state: "VA", city: "HOT SPRINGS" },
  "24448": { state: "VA", city: "IRON GATE" },
  "24450": { state: "VA", city: "LEXINGTON" },
  "24457": { state: "VA", city: "LOW MOOR" },
  "24458": { state: "VA", city: "MC DOWELL" },
  "24459": { state: "VA", city: "MIDDLEBROOK" },
  "24460": { state: "VA", city: "MILLBORO" },
  "24463": { state: "VA", city: "MINT SPRING" },
  "24464": { state: "VA", city: "MONTEBELLO" },
  "24465": { state: "VA", city: "MONTEREY" },
  "24467": { state: "VA", city: "MOUNT SIDNEY" },
  "24469": { state: "VA", city: "NEW HOPE" },
  "24471": { state: "VA", city: "PORT REPUBLIC" },
  "24472": { state: "VA", city: "RAPHINE" },
  "24473": { state: "VA", city: "ROCKBRIDGE BATHS" },
  "24474": { state: "VA", city: "SELMA" },
  "24476": { state: "VA", city: "STEELES TAVERN" },
  "24477": { state: "VA", city: "STUARTS DRAFT" },
  "24479": { state: "VA", city: "SWOOPE" },
  "24482": { state: "VA", city: "VERONA" },
  "24483": { state: "VA", city: "VESUVIUS" },
  "24484": { state: "VA", city: "WARM SPRINGS" },
  "24485": { state: "VA", city: "WEST AUGUSTA" },
  "24486": { state: "VA", city: "WEYERS CAVE" },
  "24487": { state: "VA", city: "WILLIAMSVILLE" },
  "24501": { state: "VA", city: "LYNCHBURG" },
  "24502": { state: "VA", city: "LYNCHBURG" },
  "24503": { state: "VA", city: "LYNCHBURG" },
  "24504": { state: "VA", city: "LYNCHBURG" },
  "24505": { state: "VA", city: "LYNCHBURG" },
  "24506": { state: "VA", city: "LYNCHBURG" },
  "24513": { state: "VA", city: "LYNCHBURG" },
  "24514": { state: "VA", city: "LYNCHBURG" },
  "24515": { state: "VA", city: "LYNCHBURG" },
  "24517": { state: "VA", city: "ALTAVISTA" },
  "24520": { state: "VA", city: "ALTON" },
  "24521": { state: "VA", city: "AMHERST" },
  "24522": { state: "VA", city: "APPOMATTOX" },
  "24523": { state: "VA", city: "BEDFORD" },
  "24526": { state: "VA", city: "BIG ISLAND" },
  "24527": { state: "VA", city: "BLAIRS" },
  "24528": { state: "VA", city: "BROOKNEAL" },
  "24529": { state: "VA", city: "BUFFALO JUNCTION" },
  "24530": { state: "VA", city: "CALLANDS" },
  "24531": { state: "VA", city: "CHATHAM" },
  "24533": { state: "VA", city: "CLIFFORD" },
  "24534": { state: "VA", city: "CLOVER" },
  "24535": { state: "VA", city: "CLUSTER SPRINGS" },
  "24536": { state: "VA", city: "COLEMAN FALLS" },
  "24538": { state: "VA", city: "CONCORD" },
  "24539": { state: "VA", city: "CRYSTAL HILL" },
  "24540": { state: "VA", city: "DANVILLE" },
  "24541": { state: "VA", city: "DANVILLE" },
  "24543": { state: "VA", city: "DANVILLE" },
  "24549": { state: "VA", city: "DRY FORK" },
  "24550": { state: "VA", city: "EVINGTON" },
  "24551": { state: "VA", city: "FOREST" },
  "24553": { state: "VA", city: "GLADSTONE" },
  "24554": { state: "VA", city: "GLADYS" },
  "24555": { state: "VA", city: "GLASGOW" },
  "24556": { state: "VA", city: "GOODE" },
  "24557": { state: "VA", city: "GRETNA" },
  "24558": { state: "VA", city: "HALIFAX" },
  "24562": { state: "VA", city: "HOWARDSVILLE" },
  "24563": { state: "VA", city: "HURT" },
  "24565": { state: "VA", city: "JAVA" },
  "24566": { state: "VA", city: "KEELING" },
  "24569": { state: "VA", city: "LONG ISLAND" },
  "24570": { state: "VA", city: "LOWRY" },
  "24571": { state: "VA", city: "LYNCH STATION" },
  "24572": { state: "VA", city: "MADISON HEIGHTS" },
  "24574": { state: "VA", city: "MONROE" },
  "24576": { state: "VA", city: "NARUNA" },
  "24577": { state: "VA", city: "NATHALIE" },
  "24578": { state: "VA", city: "NATURAL BRIDGE" },
  "24579": { state: "VA", city: "NATURAL BRIDGE STATION" },
  "24580": { state: "VA", city: "NELSON" },
  "24581": { state: "VA", city: "NORWOOD" },
  "24586": { state: "VA", city: "RINGGOLD" },
  "24588": { state: "VA", city: "RUSTBURG" },
  "24589": { state: "VA", city: "SCOTTSBURG" },
  "24590": { state: "VA", city: "SCOTTSVILLE" },
  "24592": { state: "VA", city: "SOUTH BOSTON" },
  "24593": { state: "VA", city: "SPOUT SPRING" },
  "24594": { state: "VA", city: "SUTHERLIN" },
  "24595": { state: "VA", city: "SWEET BRIAR" },
  "24597": { state: "VA", city: "VERNON HILL" },
  "24598": { state: "VA", city: "VIRGILINA" },
  "24599": { state: "VA", city: "WINGINA" },
  "24601": { state: "VA", city: "AMONATE" },
  "24602": { state: "VA", city: "BANDY" },
  "24603": { state: "VA", city: "BIG ROCK" },
  "24604": { state: "VA", city: "BISHOP" },
  "24605": { state: "VA", city: "BLUEFIELD" },
  "24606": { state: "VA", city: "BOISSEVAIN" },
  "24607": { state: "VA", city: "BREAKS" },
  "24608": { state: "VA", city: "BURKES GARDEN" },
  "24609": { state: "VA", city: "CEDAR BLUFF" },
  "24612": { state: "VA", city: "DORAN" },
  "24613": { state: "VA", city: "FALLS MILLS" },
  "24614": { state: "VA", city: "GRUNDY" },
  "24619": { state: "VA", city: "HORSEPEN" },
  "24620": { state: "VA", city: "HURLEY" },
  "24622": { state: "VA", city: "JEWELL RIDGE" },
  "24624": { state: "VA", city: "KEEN MOUNTAIN" },
  "24627": { state: "VA", city: "MAVISDALE" },
  "24628": { state: "VA", city: "MAXIE" },
  "24630": { state: "VA", city: "NORTH TAZEWELL" },
  "24631": { state: "VA", city: "OAKWOOD" },
  "24634": { state: "VA", city: "PILGRIMS KNOB" },
  "24635": { state: "VA", city: "POCAHONTAS" },
  "24637": { state: "VA", city: "POUNDING MILL" },
  "24639": { state: "VA", city: "RAVEN" },
  "24640": { state: "VA", city: "RED ASH" },
  "24641": { state: "VA", city: "RICHLANDS" },
  "24646": { state: "VA", city: "ROWE" },
  "24647": { state: "VA", city: "SHORTT GAP" },
  "24649": { state: "VA", city: "SWORDS CREEK" },
  "24651": { state: "VA", city: "TAZEWELL" },
  "24656": { state: "VA", city: "VANSANT" },
  "24657": { state: "VA", city: "WHITEWOOD" },
  "24658": { state: "VA", city: "WOLFORD" },
  "24701": { state: "WV", city: "BLUEFIELD" },
  "24712": { state: "WV", city: "ATHENS" },
  "24714": { state: "WV", city: "BEESON" },
  "24715": { state: "WV", city: "BRAMWELL" },
  "24716": { state: "WV", city: "BUD" },
  "24719": { state: "WV", city: "COVEL" },
  "24724": { state: "WV", city: "FREEMAN" },
  "24726": { state: "WV", city: "HERNDON" },
  "24729": { state: "WV", city: "HIAWATHA" },
  "24731": { state: "WV", city: "KEGLEY" },
  "24732": { state: "WV", city: "KELLYSVILLE" },
  "24733": { state: "WV", city: "LASHMEET" },
  "24736": { state: "WV", city: "MATOAKA" },
  "24737": { state: "WV", city: "MONTCALM" },
  "24738": { state: "WV", city: "NEMOURS" },
  "24739": { state: "WV", city: "PRINCETON" },
  "24740": { state: "WV", city: "PRINCETON" },
  "24747": { state: "WV", city: "ROCK" },
  "24751": { state: "WV", city: "WOLFE" },
  "24801": { state: "WV", city: "WELCH" },
  "24808": { state: "WV", city: "ANAWALT" },
  "24811": { state: "WV", city: "AVONDALE" },
  "24813": { state: "WV", city: "BARTLEY" },
  "24815": { state: "WV", city: "BERWIND" },
  "24816": { state: "WV", city: "BIG SANDY" },
  "24817": { state: "WV", city: "BRADSHAW" },
  "24818": { state: "WV", city: "BRENTON" },
  "24822": { state: "WV", city: "CLEAR FORK" },
  "24823": { state: "WV", city: "COAL MOUNTAIN" },
  "24826": { state: "WV", city: "CUCUMBER" },
  "24827": { state: "WV", city: "CYCLONE" },
  "24828": { state: "WV", city: "DAVY" },
  "24829": { state: "WV", city: "ECKMAN" },
  "24830": { state: "WV", city: "ELBERT" },
  "24831": { state: "WV", city: "ELKHORN" },
  "24834": { state: "WV", city: "FANROCK" },
  "24836": { state: "WV", city: "GARY" },
  "24839": { state: "WV", city: "HANOVER" },
  "24843": { state: "WV", city: "HENSLEY" },
  "24844": { state: "WV", city: "IAEGER" },
  "24845": { state: "WV", city: "IKES FORK" },
  "24846": { state: "WV", city: "ISABAN" },
  "24847": { state: "WV", city: "ITMANN" },
  "24848": { state: "WV", city: "JENKINJONES" },
  "24849": { state: "WV", city: "JESSE" },
  "24850": { state: "WV", city: "JOLO" },
  "24851": { state: "WV", city: "JUSTICE" },
  "24853": { state: "WV", city: "KIMBALL" },
  "24854": { state: "WV", city: "KOPPERSTON" },
  "24855": { state: "WV", city: "KYLE" },
  "24857": { state: "WV", city: "LYNCO" },
  "24859": { state: "WV", city: "MARIANNA" },
  "24860": { state: "WV", city: "MATHENY" },
  "24861": { state: "WV", city: "MAYBEURY" },
  "24862": { state: "WV", city: "MOHAWK" },
  "24866": { state: "WV", city: "NEWHALL" },
  "24867": { state: "WV", city: "NEW RICHMOND" },
  "24868": { state: "WV", city: "NORTHFORK" },
  "24869": { state: "WV", city: "NORTH SPRING" },
  "24870": { state: "WV", city: "OCEANA" },
  "24871": { state: "WV", city: "PAGETON" },
  "24872": { state: "WV", city: "PANTHER" },
  "24873": { state: "WV", city: "PAYNESVILLE" },
  "24874": { state: "WV", city: "PINEVILLE" },
  "24878": { state: "WV", city: "PREMIER" },
  "24879": { state: "WV", city: "RAYSAL" },
  "24880": { state: "WV", city: "ROCK VIEW" },
  "24881": { state: "WV", city: "RODERFIELD" },
  "24882": { state: "WV", city: "SIMON" },
  "24884": { state: "WV", city: "SQUIRE" },
  "24887": { state: "WV", city: "SWITCHBACK" },
  "24888": { state: "WV", city: "THORPE" },
  "24892": { state: "WV", city: "WAR" },
  "24894": { state: "WV", city: "WARRIORMINE" },
  "24895": { state: "WV", city: "WILCOE" },
  "24898": { state: "WV", city: "WYOMING" },
  "24901": { state: "WV", city: "LEWISBURG" },
  "24902": { state: "WV", city: "FAIRLEA" },
  "24910": { state: "WV", city: "ALDERSON" },
  "24915": { state: "WV", city: "ARBOVALE" },
  "24916": { state: "WV", city: "ASBURY" },
  "24918": { state: "WV", city: "BALLARD" },
  "24920": { state: "WV", city: "BARTOW" },
  "24924": { state: "WV", city: "BUCKEYE" },
  "24925": { state: "WV", city: "CALDWELL" },
  "24927": { state: "WV", city: "CASS" },
  "24931": { state: "WV", city: "CRAWLEY" },
  "24934": { state: "WV", city: "DUNMORE" },
  "24935": { state: "WV", city: "FOREST HILL" },
  "24938": { state: "WV", city: "FRANKFORD" },
  "24941": { state: "WV", city: "GAP MILLS" },
  "24943": { state: "WV", city: "GRASSY MEADOWS" },
  "24944": { state: "WV", city: "GREEN BANK" },
  "24945": { state: "WV", city: "GREENVILLE" },
  "24946": { state: "WV", city: "HILLSBORO" },
  "24951": { state: "WV", city: "LINDSIDE" },
  "24954": { state: "WV", city: "MARLINTON" },
  "24957": { state: "WV", city: "MAXWELTON" },
  "24962": { state: "WV", city: "PENCE SPRINGS" },
  "24963": { state: "WV", city: "PETERSTOWN" },
  "24966": { state: "WV", city: "RENICK" },
  "24970": { state: "WV", city: "RONCEVERTE" },
  "24974": { state: "WV", city: "SECONDCREEK" },
  "24976": { state: "WV", city: "SINKS GROVE" },
  "24977": { state: "WV", city: "SMOOT" },
  "24981": { state: "WV", city: "TALCOTT" },
  "24983": { state: "WV", city: "UNION" },
  "24984": { state: "WV", city: "WAITEVILLE" },
  "24985": { state: "WV", city: "WAYSIDE" },
  "24986": { state: "WV", city: "WHITE SULPHUR SPRINGS" },
  "24991": { state: "WV", city: "WILLIAMSBURG" },
  "24993": { state: "WV", city: "WOLFCREEK" },
  "25002": { state: "WV", city: "ALLOY" },
  "25003": { state: "WV", city: "ALUM CREEK" },
  "25005": { state: "WV", city: "AMMA" },
  "25007": { state: "WV", city: "ARNETT" },
  "25008": { state: "WV", city: "ARTIE" },
  "25009": { state: "WV", city: "ASHFORD" },
  "25011": { state: "WV", city: "BANCROFT" },
  "25015": { state: "WV", city: "BELLE" },
  "25019": { state: "WV", city: "BICKMORE" },
  "25021": { state: "WV", city: "BIM" },
  "25022": { state: "WV", city: "BLAIR" },
  "25024": { state: "WV", city: "BLOOMINGROSE" },
  "25025": { state: "WV", city: "BLOUNT" },
  "25026": { state: "WV", city: "BLUE CREEK" },
  "25028": { state: "WV", city: "BOB WHITE" },
  "25030": { state: "WV", city: "BOMONT" },
  "25031": { state: "WV", city: "BOOMER" },
  "25033": { state: "WV", city: "BUFFALO" },
  "25035": { state: "WV", city: "CABIN CREEK" },
  "25036": { state: "WV", city: "CANNELTON" },
  "25039": { state: "WV", city: "CEDAR GROVE" },
  "25040": { state: "WV", city: "CHARLTON HEIGHTS" },
  "25043": { state: "WV", city: "CLAY" },
  "25044": { state: "WV", city: "CLEAR CREEK" },
  "25045": { state: "WV", city: "CLENDENIN" },
  "25047": { state: "WV", city: "CLOTHIER" },
  "25048": { state: "WV", city: "COLCORD" },
  "25049": { state: "WV", city: "COMFORT" },
  "25051": { state: "WV", city: "COSTA" },
  "25053": { state: "WV", city: "DANVILLE" },
  "25054": { state: "WV", city: "DAWES" },
  "25057": { state: "WV", city: "DEEP WATER" },
  "25059": { state: "WV", city: "DIXIE" },
  "25060": { state: "WV", city: "DOROTHY" },
  "25061": { state: "WV", city: "DRYBRANCH" },
  "25062": { state: "WV", city: "DRY CREEK" },
  "25063": { state: "WV", city: "DUCK" },
  "25064": { state: "WV", city: "DUNBAR" },
  "25067": { state: "WV", city: "EAST BANK" },
  "25070": { state: "WV", city: "ELEANOR" },
  "25071": { state: "WV", city: "ELKVIEW" },
  "25075": { state: "WV", city: "ESKDALE" },
  "25076": { state: "WV", city: "ETHEL" },
  "25079": { state: "WV", city: "FALLING ROCK" },
  "25081": { state: "WV", city: "FOSTER" },
  "25082": { state: "WV", city: "FRAZIERS BOTTOM" },
  "25083": { state: "WV", city: "GALLAGHER" },
  "25085": { state: "WV", city: "GAULEY BRIDGE" },
  "25086": { state: "WV", city: "GLASGOW" },
  "25088": { state: "WV", city: "GLEN" },
  "25090": { state: "WV", city: "GLEN FERRIS" },
  "25093": { state: "WV", city: "GORDON" },
  "25102": { state: "WV", city: "HANDLEY" },
  "25103": { state: "WV", city: "HANSFORD" },
  "25106": { state: "WV", city: "HENDERSON" },
  "25107": { state: "WV", city: "HERNSHAW" },
  "25108": { state: "WV", city: "HEWETT" },
  "25109": { state: "WV", city: "HOMETOWN" },
  "25110": { state: "WV", city: "HUGHESTON" },
  "25111": { state: "WV", city: "INDORE" },
  "25112": { state: "WV", city: "INSTITUTE" },
  "25113": { state: "WV", city: "IVYDALE" },
  "25114": { state: "WV", city: "JEFFREY" },
  "25115": { state: "WV", city: "KANAWHA FALLS" },
  "25118": { state: "WV", city: "KIMBERLY" },
  "25119": { state: "WV", city: "KINCAID" },
  "25121": { state: "WV", city: "LAKE" },
  "25123": { state: "WV", city: "LEON" },
  "25124": { state: "WV", city: "LIBERTY" },
  "25125": { state: "WV", city: "LIZEMORES" },
  "25126": { state: "WV", city: "LONDON" },
  "25130": { state: "WV", city: "MADISON" },
  "25132": { state: "WV", city: "MAMMOTH" },
  "25133": { state: "WV", city: "MAYSEL" },
  "25134": { state: "WV", city: "MIAMI" },
  "25136": { state: "WV", city: "MONTGOMERY" },
  "25139": { state: "WV", city: "MOUNT CARBON" },
  "25140": { state: "WV", city: "NAOMA" },
  "25141": { state: "WV", city: "NEBO" },
  "25142": { state: "WV", city: "NELLIS" },
  "25143": { state: "WV", city: "NITRO" },
  "25148": { state: "WV", city: "ORGAS" },
  "25149": { state: "WV", city: "OTTAWA" },
  "25152": { state: "WV", city: "PAGE" },
  "25154": { state: "WV", city: "PEYTONA" },
  "25156": { state: "WV", city: "PINCH" },
  "25159": { state: "WV", city: "POCA" },
  "25160": { state: "WV", city: "POND GAP" },
  "25161": { state: "WV", city: "POWELLTON" },
  "25162": { state: "WV", city: "PRATT" },
  "25164": { state: "WV", city: "PROCIOUS" },
  "25165": { state: "WV", city: "RACINE" },
  "25168": { state: "WV", city: "RED HOUSE" },
  "25169": { state: "WV", city: "RIDGEVIEW" },
  "25173": { state: "WV", city: "ROBSON" },
  "25174": { state: "WV", city: "ROCK CREEK" },
  "25177": { state: "WV", city: "SAINT ALBANS" },
  "25180": { state: "WV", city: "SAXON" },
  "25181": { state: "WV", city: "SETH" },
  "25183": { state: "WV", city: "SHARPLES" },
  "25185": { state: "WV", city: "MOUNT OLIVE" },
  "25186": { state: "WV", city: "SMITHERS" },
  "25187": { state: "WV", city: "SOUTHSIDE" },
  "25193": { state: "WV", city: "SYLVESTER" },
  "25201": { state: "WV", city: "TAD" },
  "25202": { state: "WV", city: "TORNADO" },
  "25203": { state: "WV", city: "TURTLE CREEK" },
  "25204": { state: "WV", city: "TWILIGHT" },
  "25205": { state: "WV", city: "UNEEDA" },
  "25206": { state: "WV", city: "VAN" },
  "25208": { state: "WV", city: "WHARTON" },
  "25209": { state: "WV", city: "WHITESVILLE" },
  "25211": { state: "WV", city: "WIDEN" },
  "25213": { state: "WV", city: "WINFIELD" },
  "25214": { state: "WV", city: "WINIFREDE" },
  "25231": { state: "WV", city: "ADVENT" },
  "25234": { state: "WV", city: "ARNOLDSBURG" },
  "25235": { state: "WV", city: "CHLOE" },
  "25239": { state: "WV", city: "COTTAGEVILLE" },
  "25241": { state: "WV", city: "EVANS" },
  "25243": { state: "WV", city: "GANDEEVILLE" },
  "25244": { state: "WV", city: "GAY" },
  "25245": { state: "WV", city: "GIVEN" },
  "25247": { state: "WV", city: "HARTFORD" },
  "25248": { state: "WV", city: "KENNA" },
  "25251": { state: "WV", city: "LEFT HAND" },
  "25252": { state: "WV", city: "LE ROY" },
  "25253": { state: "WV", city: "LETART" },
  "25259": { state: "WV", city: "LOONEYVILLE" },
  "25260": { state: "WV", city: "MASON" },
  "25261": { state: "WV", city: "MILLSTONE" },
  "25262": { state: "WV", city: "MILLWOOD" },
  "25264": { state: "WV", city: "MOUNT ALTO" },
  "25265": { state: "WV", city: "NEW HAVEN" },
  "25266": { state: "WV", city: "NEWTON" },
  "25267": { state: "WV", city: "NORMANTOWN" },
  "25268": { state: "WV", city: "ORMA" },
  "25270": { state: "WV", city: "REEDY" },
  "25271": { state: "WV", city: "RIPLEY" },
  "25275": { state: "WV", city: "SANDYVILLE" },
  "25276": { state: "WV", city: "SPENCER" },
  "25285": { state: "WV", city: "WALLBACK" },
  "25286": { state: "WV", city: "WALTON" },
  "25287": { state: "WV", city: "WEST COLUMBIA" },
  "25301": { state: "WV", city: "CHARLESTON" },
  "25302": { state: "WV", city: "CHARLESTON" },
  "25303": { state: "WV", city: "SOUTH CHARLESTON" },
  "25304": { state: "WV", city: "CHARLESTON" },
  "25305": { state: "WV", city: "CHARLESTON" },
  "25306": { state: "WV", city: "CHARLESTON" },
  "25309": { state: "WV", city: "SOUTH CHARLESTON" },
  "25311": { state: "WV", city: "CHARLESTON" },
  "25312": { state: "WV", city: "CHARLESTON" },
  "25313": { state: "WV", city: "CHARLESTON" },
  "25314": { state: "WV", city: "CHARLESTON" },
  "25315": { state: "WV", city: "CHARLESTON" },
  "25317": { state: "WV", city: "CHARLESTON" },
  "25320": { state: "WV", city: "CHARLESTON" },
  "25321": { state: "WV", city: "CHARLESTON" },
  "25322": { state: "WV", city: "CHARLESTON" },
  "25323": { state: "WV", city: "CHARLESTON" },
  "25324": { state: "WV", city: "CHARLESTON" },
  "25325": { state: "WV", city: "CHARLESTON" },
  "25326": { state: "WV", city: "CHARLESTON" },
  "25327": { state: "WV", city: "CHARLESTON" },
  "25328": { state: "WV", city: "CHARLESTON" },
  "25329": { state: "WV", city: "CHARLESTON" },
  "25330": { state: "WV", city: "CHARLESTON" },
  "25331": { state: "WV", city: "CHARLESTON" },
  "25332": { state: "WV", city: "CHARLESTON" },
  "25333": { state: "WV", city: "CHARLESTON" },
  "25334": { state: "WV", city: "CHARLESTON" },
  "25335": { state: "WV", city: "CHARLESTON" },
  "25336": { state: "WV", city: "CHARLESTON" },
  "25337": { state: "WV", city: "CHARLESTON" },
  "25338": { state: "WV", city: "CHARLESTON" },
  "25339": { state: "WV", city: "CHARLESTON" },
  "25350": { state: "WV", city: "CHARLESTON" },
  "25356": { state: "WV", city: "CHARLESTON" },
  "25357": { state: "WV", city: "CHARLESTON" },
  "25358": { state: "WV", city: "CHARLESTON" },
  "25360": { state: "WV", city: "CHARLESTON" },
  "25361": { state: "WV", city: "CHARLESTON" },
  "25362": { state: "WV", city: "CHARLESTON" },
  "25364": { state: "WV", city: "CHARLESTON" },
  "25365": { state: "WV", city: "CHARLESTON" },
  "25375": { state: "WV", city: "CHARLESTON" },
  "25387": { state: "WV", city: "CHARLESTON" },
  "25389": { state: "WV", city: "CHARLESTON" },
  "25392": { state: "WV", city: "CHARLESTON" },
  "25396": { state: "WV", city: "CHARLESTON" },
  "25401": { state: "WV", city: "MARTINSBURG" },
  "25402": { state: "WV", city: "MARTINSBURG" },
  "25403": { state: "WV", city: "MARTINSBURG" },
  "25404": { state: "WV", city: "MARTINSBURG" },
  "25405": { state: "WV", city: "MARTINSBURG" },
  "25410": { state: "WV", city: "BAKERTON" },
  "25411": { state: "WV", city: "BERKELEY SPRINGS" },
  "25413": { state: "WV", city: "BUNKER HILL" },
  "25414": { state: "WV", city: "CHARLES TOWN" },
  "25419": { state: "WV", city: "FALLING WATERS" },
  "25420": { state: "WV", city: "GERRARDSTOWN" },
  "25421": { state: "WV", city: "GLENGARY" },
  "25422": { state: "WV", city: "GREAT CACAPON" },
  "25423": { state: "WV", city: "HALLTOWN" },
  "25425": { state: "WV", city: "HARPERS FERRY" },
  "25427": { state: "WV", city: "HEDGESVILLE" },
  "25428": { state: "WV", city: "INWOOD" },
  "25430": { state: "WV", city: "KEARNEYSVILLE" },
  "25431": { state: "WV", city: "LEVELS" },
  "25432": { state: "WV", city: "MILLVILLE" },
  "25434": { state: "WV", city: "PAW PAW" },
  "25437": { state: "WV", city: "POINTS" },
  "25438": { state: "WV", city: "RANSON" },
  "25440": { state: "WV", city: "RIDGEWAY" },
  "25441": { state: "WV", city: "RIPPON" },
  "25442": { state: "WV", city: "SHENANDOAH JUNCTION" },
  "25443": { state: "WV", city: "SHEPHERDSTOWN" },
  "25444": { state: "WV", city: "SLANESVILLE" },
  "25446": { state: "WV", city: "SUMMIT POINT" },
  "25501": { state: "WV", city: "ALKOL" },
  "25502": { state: "WV", city: "APPLE GROVE" },
  "25503": { state: "WV", city: "ASHTON" },
  "25504": { state: "WV", city: "BARBOURSVILLE" },
  "25505": { state: "WV", city: "BIG CREEK" },
  "25506": { state: "WV", city: "BRANCHLAND" },
  "25507": { state: "WV", city: "CEREDO" },
  "25508": { state: "WV", city: "CHAPMANVILLE" },
  "25510": { state: "WV", city: "CULLODEN" },
  "25511": { state: "WV", city: "DUNLOW" },
  "25512": { state: "WV", city: "EAST LYNN" },
  "25514": { state: "WV", city: "FORT GAY" },
  "25515": { state: "WV", city: "GALLIPOLIS FERRY" },
  "25517": { state: "WV", city: "GENOA" },
  "25520": { state: "WV", city: "GLENWOOD" },
  "25521": { state: "WV", city: "GRIFFITHSVILLE" },
  "25523": { state: "WV", city: "HAMLIN" },
  "25524": { state: "WV", city: "HARTS" },
  "25526": { state: "WV", city: "HURRICANE" },
  "25529": { state: "WV", city: "JULIAN" },
  "25530": { state: "WV", city: "KENOVA" },
  "25534": { state: "WV", city: "KIAHSVILLE" },
  "25535": { state: "WV", city: "LAVALETTE" },
  "25537": { state: "WV", city: "LESAGE" },
  "25540": { state: "WV", city: "MIDKIFF" },
  "25541": { state: "WV", city: "MILTON" },
  "25544": { state: "WV", city: "MYRA" },
  "25545": { state: "WV", city: "ONA" },
  "25547": { state: "WV", city: "PECKS MILL" },
  "25550": { state: "WV", city: "POINT PLEASANT" },
  "25555": { state: "WV", city: "PRICHARD" },
  "25557": { state: "WV", city: "RANGER" },
  "25559": { state: "WV", city: "SALT ROCK" },
  "25560": { state: "WV", city: "SCOTT DEPOT" },
  "25562": { state: "WV", city: "SHOALS" },
  "25564": { state: "WV", city: "SOD" },
  "25565": { state: "WV", city: "SPURLOCKVILLE" },
  "25567": { state: "WV", city: "SUMERCO" },
  "25569": { state: "WV", city: "TEAYS" },
  "25570": { state: "WV", city: "WAYNE" },
  "25571": { state: "WV", city: "WEST HAMLIN" },
  "25572": { state: "WV", city: "WOODVILLE" },
  "25573": { state: "WV", city: "YAWKEY" },
  "25601": { state: "WV", city: "LOGAN" },
  "25606": { state: "WV", city: "ACCOVILLE" },
  "25607": { state: "WV", city: "AMHERSTDALE" },
  "25608": { state: "WV", city: "BAISDEN" },
  "25611": { state: "WV", city: "BRUNO" },
  "25612": { state: "WV", city: "CHAUNCEY" },
  "25614": { state: "WV", city: "CORA" },
  "25617": { state: "WV", city: "DAVIN" },
  "25621": { state: "WV", city: "GILBERT" },
  "25624": { state: "WV", city: "HENLAWSON" },
  "25625": { state: "WV", city: "HOLDEN" },
  "25628": { state: "WV", city: "KISTLER" },
  "25630": { state: "WV", city: "LORADO" },
  "25632": { state: "WV", city: "LYBURN" },
  "25634": { state: "WV", city: "MALLORY" },
  "25635": { state: "WV", city: "MAN" },
  "25637": { state: "WV", city: "MOUNT GAY" },
  "25638": { state: "WV", city: "OMAR" },
  "25639": { state: "WV", city: "PEACH CREEK" },
  "25644": { state: "WV", city: "SARAH ANN" },
  "25646": { state: "WV", city: "STOLLINGS" },
  "25647": { state: "WV", city: "SWITZER" },
  "25649": { state: "WV", city: "VERDUNVILLE" },
  "25650": { state: "WV", city: "VERNER" },
  "25651": { state: "WV", city: "WHARNCLIFFE" },
  "25652": { state: "WV", city: "WHITMAN" },
  "25653": { state: "WV", city: "WILKINSON" },
  "25654": { state: "WV", city: "YOLYN" },
  "25661": { state: "WV", city: "WILLIAMSON" },
  "25665": { state: "WV", city: "BORDERLAND" },
  "25666": { state: "WV", city: "BREEDEN" },
  "25667": { state: "WV", city: "CHATTAROY" },
  "25669": { state: "WV", city: "CRUM" },
  "25670": { state: "WV", city: "DELBARTON" },
  "25671": { state: "WV", city: "DINGESS" },
  "25672": { state: "WV", city: "EDGARTON" },
  "25674": { state: "WV", city: "KERMIT" },
  "25676": { state: "WV", city: "LENORE" },
  "25678": { state: "WV", city: "MATEWAN" },
  "25685": { state: "WV", city: "NAUGATUCK" },
  "25686": { state: "WV", city: "NEWTOWN" },
  "25688": { state: "WV", city: "NORTH MATEWAN" },
  "25690": { state: "WV", city: "RAGLAND" },
  "25691": { state: "WV", city: "RAWL" },
  "25692": { state: "WV", city: "RED JACKET" },
  "25696": { state: "WV", city: "VARNEY" },
  "25699": { state: "WV", city: "WILSONDALE" },
  "25701": { state: "WV", city: "HUNTINGTON" },
  "25702": { state: "WV", city: "HUNTINGTON" },
  "25703": { state: "WV", city: "HUNTINGTON" },
  "25704": { state: "WV", city: "HUNTINGTON" },
  "25705": { state: "WV", city: "HUNTINGTON" },
  "25706": { state: "WV", city: "HUNTINGTON" },
  "25707": { state: "WV", city: "HUNTINGTON" },
  "25708": { state: "WV", city: "HUNTINGTON" },
  "25709": { state: "WV", city: "HUNTINGTON" },
  "25710": { state: "WV", city: "HUNTINGTON" },
  "25711": { state: "WV", city: "HUNTINGTON" },
  "25712": { state: "WV", city: "HUNTINGTON" },
  "25713": { state: "WV", city: "HUNTINGTON" },
  "25714": { state: "WV", city: "HUNTINGTON" },
  "25715": { state: "WV", city: "HUNTINGTON" },
  "25716": { state: "WV", city: "HUNTINGTON" },
  "25717": { state: "WV", city: "HUNTINGTON" },
  "25718": { state: "WV", city: "HUNTINGTON" },
  "25719": { state: "WV", city: "HUNTINGTON" },
  "25720": { state: "WV", city: "HUNTINGTON" },
  "25721": { state: "WV", city: "HUNTINGTON" },
  "25722": { state: "WV", city: "HUNTINGTON" },
  "25723": { state: "WV", city: "HUNTINGTON" },
  "25724": { state: "WV", city: "HUNTINGTON" },
  "25725": { state: "WV", city: "HUNTINGTON" },
  "25726": { state: "WV", city: "HUNTINGTON" },
  "25727": { state: "WV", city: "HUNTINGTON" },
  "25728": { state: "WV", city: "HUNTINGTON" },
  "25729": { state: "WV", city: "HUNTINGTON" },
  "25755": { state: "WV", city: "HUNTINGTON" },
  "25770": { state: "WV", city: "HUNTINGTON" },
  "25771": { state: "WV", city: "HUNTINGTON" },
  "25772": { state: "WV", city: "HUNTINGTON" },
  "25773": { state: "WV", city: "HUNTINGTON" },
  "25774": { state: "WV", city: "HUNTINGTON" },
  "25775": { state: "WV", city: "HUNTINGTON" },
  "25776": { state: "WV", city: "HUNTINGTON" },
  "25777": { state: "WV", city: "HUNTINGTON" },
  "25778": { state: "WV", city: "HUNTINGTON" },
  "25779": { state: "WV", city: "HUNTINGTON" },
  "25801": { state: "WV", city: "BECKLEY" },
  "25802": { state: "WV", city: "BECKLEY" },
  "25810": { state: "WV", city: "ALLEN JUNCTION" },
  "25811": { state: "WV", city: "AMIGO" },
  "25812": { state: "WV", city: "ANSTED" },
  "25813": { state: "WV", city: "BEAVER" },
  "25817": { state: "WV", city: "BOLT" },
  "25818": { state: "WV", city: "BRADLEY" },
  "25820": { state: "WV", city: "CAMP CREEK" },
  "25823": { state: "WV", city: "COAL CITY" },
  "25825": { state: "WV", city: "COOL RIDGE" },
  "25826": { state: "WV", city: "CORINNE" },
  "25827": { state: "WV", city: "CRAB ORCHARD" },
  "25831": { state: "WV", city: "DANESE" },
  "25832": { state: "WV", city: "DANIELS" },
  "25833": { state: "WV", city: "DOTHAN" },
  "25836": { state: "WV", city: "ECCLES" },
  "25837": { state: "WV", city: "EDMOND" },
  "25839": { state: "WV", city: "FAIRDALE" },
  "25840": { state: "WV", city: "FAYETTEVILLE" },
  "25841": { state: "WV", city: "FLAT TOP" },
  "25843": { state: "WV", city: "GHENT" },
  "25844": { state: "WV", city: "GLEN DANIEL" },
  "25845": { state: "WV", city: "GLEN FORK" },
  "25846": { state: "WV", city: "GLEN JEAN" },
  "25848": { state: "WV", city: "GLEN ROGERS" },
  "25849": { state: "WV", city: "GLEN WHITE" },
  "25851": { state: "WV", city: "HARPER" },
  "25853": { state: "WV", city: "HELEN" },
  "25854": { state: "WV", city: "HICO" },
  "25855": { state: "WV", city: "HILLTOP" },
  "25857": { state: "WV", city: "JOSEPHINE" },
  "25860": { state: "WV", city: "LANARK" },
  "25862": { state: "WV", city: "LANSING" },
  "25864": { state: "WV", city: "LAYLAND" },
  "25865": { state: "WV", city: "LESTER" },
  "25866": { state: "WV", city: "LOCHGELLY" },
  "25868": { state: "WV", city: "LOOKOUT" },
  "25870": { state: "WV", city: "MABEN" },
  "25871": { state: "WV", city: "MABSCOTT" },
  "25873": { state: "WV", city: "MAC ARTHUR" },
  "25875": { state: "WV", city: "MC GRAWS" },
  "25876": { state: "WV", city: "SAULSVILLE" },
  "25878": { state: "WV", city: "MIDWAY" },
  "25879": { state: "WV", city: "MINDEN" },
  "25880": { state: "WV", city: "MOUNT HOPE" },
  "25882": { state: "WV", city: "MULLENS" },
  "25888": { state: "WV", city: "MOUNT HOPE" },
  "25901": { state: "WV", city: "OAK HILL" },
  "25902": { state: "WV", city: "ODD" },
  "25904": { state: "WV", city: "PAX" },
  "25906": { state: "WV", city: "PINEY VIEW" },
  "25907": { state: "WV", city: "PRINCE" },
  "25908": { state: "WV", city: "PRINCEWICK" },
  "25909": { state: "WV", city: "PROSPERITY" },
  "25911": { state: "WV", city: "RALEIGH" },
  "25913": { state: "WV", city: "RAVENCLIFF" },
  "25915": { state: "WV", city: "RHODELL" },
  "25916": { state: "WV", city: "SABINE" },
  "25917": { state: "WV", city: "SCARBRO" },
  "25918": { state: "WV", city: "SHADY SPRING" },
  "25919": { state: "WV", city: "SKELTON" },
  "25920": { state: "WV", city: "SLAB FORK" },
  "25921": { state: "WV", city: "SOPHIA" },
  "25922": { state: "WV", city: "SPANISHBURG" },
  "25927": { state: "WV", city: "STANAFORD" },
  "25928": { state: "WV", city: "STEPHENSON" },
  "25932": { state: "WV", city: "SURVEYOR" },
  "25936": { state: "WV", city: "THURMOND" },
  "25938": { state: "WV", city: "VICTOR" },
  "25942": { state: "WV", city: "WINONA" },
  "25943": { state: "WV", city: "WYCO" },
  "25951": { state: "WV", city: "HINTON" },
  "25958": { state: "WV", city: "CHARMCO" },
  "25962": { state: "WV", city: "RAINELLE" },
  "25966": { state: "WV", city: "GREEN SULPHUR SPRINGS" },
  "25969": { state: "WV", city: "JUMPING BRANCH" },
  "25971": { state: "WV", city: "LERONA" },
  "25972": { state: "WV", city: "LESLIE" },
  "25976": { state: "WV", city: "MEADOW BRIDGE" },
  "25977": { state: "WV", city: "MEADOW CREEK" },
  "25978": { state: "WV", city: "NIMITZ" },
  "25979": { state: "WV", city: "PIPESTEM" },
  "25981": { state: "WV", city: "QUINWOOD" },
  "25984": { state: "WV", city: "RUPERT" },
  "25985": { state: "WV", city: "SANDSTONE" },
  "25986": { state: "WV", city: "SPRING DALE" },
  "25989": { state: "WV", city: "WHITE OAK" },
  "26003": { state: "WV", city: "WHEELING" },
  "26030": { state: "WV", city: "BEECH BOTTOM" },
  "26031": { state: "WV", city: "BENWOOD" },
  "26032": { state: "WV", city: "BETHANY" },
  "26033": { state: "WV", city: "CAMERON" },
  "26034": { state: "WV", city: "CHESTER" },
  "26035": { state: "WV", city: "COLLIERS" },
  "26036": { state: "WV", city: "DALLAS" },
  "26037": { state: "WV", city: "FOLLANSBEE" },
  "26038": { state: "WV", city: "GLEN DALE" },
  "26039": { state: "WV", city: "GLEN EASTON" },
  "26040": { state: "WV", city: "MCMECHEN" },
  "26041": { state: "WV", city: "MOUNDSVILLE" },
  "26047": { state: "WV", city: "NEW CUMBERLAND" },
  "26050": { state: "WV", city: "NEWELL" },
  "26055": { state: "WV", city: "PROCTOR" },
  "26056": { state: "WV", city: "NEW MANCHESTER" },
  "26058": { state: "WV", city: "SHORT CREEK" },
  "26059": { state: "WV", city: "TRIADELPHIA" },
  "26060": { state: "WV", city: "VALLEY GROVE" },
  "26062": { state: "WV", city: "WEIRTON" },
  "26070": { state: "WV", city: "WELLSBURG" },
  "26074": { state: "WV", city: "WEST LIBERTY" },
  "26075": { state: "WV", city: "WINDSOR HEIGHTS" },
  "26101": { state: "WV", city: "PARKERSBURG" },
  "26102": { state: "WV", city: "PARKERSBURG" },
  "26103": { state: "WV", city: "PARKERSBURG" },
  "26104": { state: "WV", city: "PARKERSBURG" },
  "26105": { state: "WV", city: "VIENNA" },
  "26106": { state: "WV", city: "PARKERSBURG" },
  "26120": { state: "WV", city: "MINERAL WELLS" },
  "26121": { state: "WV", city: "MINERAL WELLS" },
  "26133": { state: "WV", city: "BELLEVILLE" },
  "26134": { state: "WV", city: "BELMONT" },
  "26136": { state: "WV", city: "BIG BEND" },
  "26137": { state: "WV", city: "BIG SPRINGS" },
  "26138": { state: "WV", city: "BROHARD" },
  "26141": { state: "WV", city: "CRESTON" },
  "26142": { state: "WV", city: "DAVISVILLE" },
  "26143": { state: "WV", city: "ELIZABETH" },
  "26146": { state: "WV", city: "FRIENDLY" },
  "26147": { state: "WV", city: "GRANTSVILLE" },
  "26148": { state: "WV", city: "MACFARLAN" },
  "26149": { state: "WV", city: "MIDDLEBOURNE" },
  "26150": { state: "WV", city: "MINERAL WELLS" },
  "26151": { state: "WV", city: "MOUNT ZION" },
  "26152": { state: "WV", city: "MUNDAY" },
  "26155": { state: "WV", city: "NEW MARTINSVILLE" },
  "26159": { state: "WV", city: "PADEN CITY" },
  "26160": { state: "WV", city: "PALESTINE" },
  "26161": { state: "WV", city: "PETROLEUM" },
  "26162": { state: "WV", city: "PORTERS FALLS" },
  "26164": { state: "WV", city: "RAVENSWOOD" },
  "26167": { state: "WV", city: "READER" },
  "26169": { state: "WV", city: "ROCKPORT" },
  "26170": { state: "WV", city: "SAINT MARYS" },
  "26175": { state: "WV", city: "SISTERSVILLE" },
  "26178": { state: "WV", city: "SMITHVILLE" },
  "26180": { state: "WV", city: "WALKER" },
  "26181": { state: "WV", city: "WASHINGTON" },
  "26184": { state: "WV", city: "WAVERLY" },
  "26187": { state: "WV", city: "WILLIAMSTOWN" },
  "26201": { state: "WV", city: "BUCKHANNON" },
  "26202": { state: "WV", city: "FENWICK" },
  "26203": { state: "WV", city: "ERBACON" },
  "26205": { state: "WV", city: "CRAIGSVILLE" },
  "26206": { state: "WV", city: "COWEN" },
  "26208": { state: "WV", city: "CAMDEN ON GAULEY" },
  "26209": { state: "WV", city: "SNOWSHOE" },
  "26210": { state: "WV", city: "ADRIAN" },
  "26215": { state: "WV", city: "CLEVELAND" },
  "26217": { state: "WV", city: "DIANA" },
  "26218": { state: "WV", city: "FRENCH CREEK" },
  "26219": { state: "WV", city: "FRENCHTON" },
  "26222": { state: "WV", city: "HACKER VALLEY" },
  "26224": { state: "WV", city: "HELVETIA" },
  "26228": { state: "WV", city: "KANAWHA HEAD" },
  "26229": { state: "WV", city: "LORENTZ" },
  "26230": { state: "WV", city: "PICKENS" },
  "26234": { state: "WV", city: "ROCK CAVE" },
  "26236": { state: "WV", city: "SELBYVILLE" },
  "26237": { state: "WV", city: "TALLMANSVILLE" },
  "26238": { state: "WV", city: "VOLGA" },
  "26241": { state: "WV", city: "ELKINS" },
  "26250": { state: "WV", city: "BELINGTON" },
  "26253": { state: "WV", city: "BEVERLY" },
  "26254": { state: "WV", city: "BOWDEN" },
  "26257": { state: "WV", city: "COALTON" },
  "26259": { state: "WV", city: "DAILEY" },
  "26260": { state: "WV", city: "DAVIS" },
  "26261": { state: "WV", city: "RICHWOOD" },
  "26263": { state: "WV", city: "DRYFORK" },
  "26264": { state: "WV", city: "DURBIN" },
  "26266": { state: "WV", city: "UPPERGLADE" },
  "26267": { state: "WV", city: "ELLAMORE" },
  "26268": { state: "WV", city: "GLADY" },
  "26269": { state: "WV", city: "HAMBLETON" },
  "26270": { state: "WV", city: "HARMAN" },
  "26271": { state: "WV", city: "HENDRICKS" },
  "26273": { state: "WV", city: "HUTTONSVILLE" },
  "26275": { state: "WV", city: "JUNIOR" },
  "26276": { state: "WV", city: "KERENS" },
  "26278": { state: "WV", city: "MABIE" },
  "26280": { state: "WV", city: "MILL CREEK" },
  "26282": { state: "WV", city: "MONTERVILLE" },
  "26283": { state: "WV", city: "MONTROSE" },
  "26285": { state: "WV", city: "NORTON" },
  "26287": { state: "WV", city: "PARSONS" },
  "26288": { state: "WV", city: "WEBSTER SPRINGS" },
  "26289": { state: "WV", city: "RED CREEK" },
  "26291": { state: "WV", city: "SLATYFORK" },
  "26292": { state: "WV", city: "THOMAS" },
  "26293": { state: "WV", city: "VALLEY BEND" },
  "26294": { state: "WV", city: "VALLEY HEAD" },
  "26296": { state: "WV", city: "WHITMER" },
  "26298": { state: "WV", city: "BERGOO" },
  "26301": { state: "WV", city: "CLARKSBURG" },
  "26302": { state: "WV", city: "CLARKSBURG" },
  "26306": { state: "WV", city: "CLARKSBURG" },
  "26320": { state: "WV", city: "ALMA" },
  "26321": { state: "WV", city: "ALUM BRIDGE" },
  "26323": { state: "WV", city: "ANMOORE" },
  "26325": { state: "WV", city: "AUBURN" },
  "26327": { state: "WV", city: "BEREA" },
  "26330": { state: "WV", city: "BRIDGEPORT" },
  "26335": { state: "WV", city: "BURNSVILLE" },
  "26337": { state: "WV", city: "CAIRO" },
  "26338": { state: "WV", city: "CAMDEN" },
  "26339": { state: "WV", city: "CENTER POINT" },
  "26342": { state: "WV", city: "COXS MILLS" },
  "26343": { state: "WV", city: "CRAWFORD" },
  "26346": { state: "WV", city: "ELLENBORO" },
  "26347": { state: "WV", city: "FLEMINGTON" },
  "26348": { state: "WV", city: "FOLSOM" },
  "26349": { state: "WV", city: "GALLOWAY" },
  "26351": { state: "WV", city: "GLENVILLE" },
  "26354": { state: "WV", city: "GRAFTON" },
  "26361": { state: "WV", city: "GYPSY" },
  "26362": { state: "WV", city: "HARRISVILLE" },
  "26366": { state: "WV", city: "HAYWOOD" },
  "26369": { state: "WV", city: "HEPZIBAH" },
  "26372": { state: "WV", city: "HORNER" },
  "26374": { state: "WV", city: "INDEPENDENCE" },
  "26376": { state: "WV", city: "IRELAND" },
  "26377": { state: "WV", city: "JACKSONBURG" },
  "26378": { state: "WV", city: "JANE LEW" },
  "26384": { state: "WV", city: "LINN" },
  "26385": { state: "WV", city: "LOST CREEK" },
  "26386": { state: "WV", city: "LUMBERPORT" },
  "26404": { state: "WV", city: "MEADOWBROOK" },
  "26405": { state: "WV", city: "MOATSVILLE" },
  "26408": { state: "WV", city: "MOUNT CLARE" },
  "26410": { state: "WV", city: "NEWBURG" },
  "26411": { state: "WV", city: "NEW MILTON" },
  "26412": { state: "WV", city: "ORLANDO" },
  "26415": { state: "WV", city: "PENNSBORO" },
  "26416": { state: "WV", city: "PHILIPPI" },
  "26419": { state: "WV", city: "PINE GROVE" },
  "26421": { state: "WV", city: "PULLMAN" },
  "26422": { state: "WV", city: "REYNOLDSVILLE" },
  "26424": { state: "WV", city: "ROSEMONT" },
  "26425": { state: "WV", city: "ROWLESBURG" },
  "26426": { state: "WV", city: "SALEM" },
  "26430": { state: "WV", city: "SAND FORK" },
  "26431": { state: "WV", city: "SHINNSTON" },
  "26434": { state: "WV", city: "SHIRLEY" },
  "26435": { state: "WV", city: "SIMPSON" },
  "26436": { state: "WV", city: "SMITHBURG" },
  "26437": { state: "WV", city: "SMITHFIELD" },
  "26438": { state: "WV", city: "SPELTER" },
  "26440": { state: "WV", city: "THORNTON" },
  "26443": { state: "WV", city: "TROY" },
  "26444": { state: "WV", city: "TUNNELTON" },
  "26447": { state: "WV", city: "WALKERSVILLE" },
  "26448": { state: "WV", city: "WALLACE" },
  "26451": { state: "WV", city: "WEST MILFORD" },
  "26452": { state: "WV", city: "WESTON" },
  "26456": { state: "WV", city: "WEST UNION" },
  "26463": { state: "WV", city: "WYATT" },
  "26501": { state: "WV", city: "MORGANTOWN" },
  "26502": { state: "WV", city: "MORGANTOWN" },
  "26504": { state: "WV", city: "MORGANTOWN" },
  "26505": { state: "WV", city: "MORGANTOWN" },
  "26506": { state: "WV", city: "MORGANTOWN" },
  "26507": { state: "WV", city: "MORGANTOWN" },
  "26508": { state: "WV", city: "MORGANTOWN" },
  "26519": { state: "WV", city: "ALBRIGHT" },
  "26520": { state: "WV", city: "ARTHURDALE" },
  "26521": { state: "WV", city: "BLACKSVILLE" },
  "26524": { state: "WV", city: "BRETZ" },
  "26525": { state: "WV", city: "BRUCETON MILLS" },
  "26527": { state: "WV", city: "CASSVILLE" },
  "26531": { state: "WV", city: "DELLSLOW" },
  "26534": { state: "WV", city: "GRANVILLE" },
  "26537": { state: "WV", city: "KINGWOOD" },
  "26541": { state: "WV", city: "MAIDSVILLE" },
  "26542": { state: "WV", city: "MASONTOWN" },
  "26543": { state: "WV", city: "OSAGE" },
  "26544": { state: "WV", city: "PENTRESS" },
  "26546": { state: "WV", city: "PURSGLOVE" },
  "26547": { state: "WV", city: "REEDSVILLE" },
  "26554": { state: "WV", city: "FAIRMONT" },
  "26555": { state: "WV", city: "FAIRMONT" },
  "26559": { state: "WV", city: "BARRACKVILLE" },
  "26560": { state: "WV", city: "BAXTER" },
  "26561": { state: "WV", city: "BIG RUN" },
  "26562": { state: "WV", city: "BURTON" },
  "26563": { state: "WV", city: "CAROLINA" },
  "26566": { state: "WV", city: "COLFAX" },
  "26568": { state: "WV", city: "ENTERPRISE" },
  "26570": { state: "WV", city: "FAIRVIEW" },
  "26571": { state: "WV", city: "FARMINGTON" },
  "26572": { state: "WV", city: "FOUR STATES" },
  "26574": { state: "WV", city: "GRANT TOWN" },
  "26575": { state: "WV", city: "HUNDRED" },
  "26576": { state: "WV", city: "IDAMAY" },
  "26578": { state: "WV", city: "KINGMONT" },
  "26581": { state: "WV", city: "LITTLETON" },
  "26582": { state: "WV", city: "MANNINGTON" },
  "26585": { state: "WV", city: "METZ" },
  "26586": { state: "WV", city: "MONTANA MINES" },
  "26587": { state: "WV", city: "RACHEL" },
  "26588": { state: "WV", city: "RIVESVILLE" },
  "26590": { state: "WV", city: "WANA" },
  "26591": { state: "WV", city: "WORTHINGTON" },
  "26601": { state: "WV", city: "SUTTON" },
  "26610": { state: "WV", city: "BIRCH RIVER" },
  "26611": { state: "WV", city: "CEDARVILLE" },
  "26615": { state: "WV", city: "COPEN" },
  "26617": { state: "WV", city: "DILLE" },
  "26619": { state: "WV", city: "EXCHANGE" },
  "26621": { state: "WV", city: "FLATWOODS" },
  "26623": { state: "WV", city: "FRAMETOWN" },
  "26624": { state: "WV", city: "GASSAWAY" },
  "26627": { state: "WV", city: "HEATERS" },
  "26629": { state: "WV", city: "LITTLE BIRCH" },
  "26631": { state: "WV", city: "NAPIER" },
  "26636": { state: "WV", city: "ROSEDALE" },
  "26638": { state: "WV", city: "SHOCK" },
  "26651": { state: "WV", city: "SUMMERSVILLE" },
  "26656": { state: "WV", city: "BELVA" },
  "26660": { state: "WV", city: "CALVIN" },
  "26662": { state: "WV", city: "CANVAS" },
  "26667": { state: "WV", city: "DRENNEN" },
  "26671": { state: "WV", city: "GILBOA" },
  "26675": { state: "WV", city: "KESLERS CROSS LANES" },
  "26676": { state: "WV", city: "LEIVASY" },
  "26678": { state: "WV", city: "MOUNT LOOKOUT" },
  "26679": { state: "WV", city: "MOUNT NEBO" },
  "26680": { state: "WV", city: "NALLEN" },
  "26681": { state: "WV", city: "NETTIE" },
  "26684": { state: "WV", city: "POOL" },
  "26690": { state: "WV", city: "SWISS" },
  "26691": { state: "WV", city: "TIOGA" },
  "26704": { state: "WV", city: "AUGUSTA" },
  "26705": { state: "WV", city: "AURORA" },
  "26707": { state: "WV", city: "BAYARD" },
  "26710": { state: "WV", city: "BURLINGTON" },
  "26711": { state: "WV", city: "CAPON BRIDGE" },
  "26714": { state: "WV", city: "DELRAY" },
  "26716": { state: "WV", city: "EGLON" },
  "26717": { state: "WV", city: "ELK GARDEN" },
  "26719": { state: "WV", city: "FORT ASHBY" },
  "26720": { state: "WV", city: "GORMANIA" },
  "26722": { state: "WV", city: "GREEN SPRING" },
  "26726": { state: "WV", city: "KEYSER" },
  "26731": { state: "WV", city: "LAHMANSVILLE" },
  "26739": { state: "WV", city: "MOUNT STORM" },
  "26743": { state: "WV", city: "NEW CREEK" },
  "26750": { state: "WV", city: "PIEDMONT" },
  "26753": { state: "WV", city: "RIDGELEY" },
  "26755": { state: "WV", city: "RIO" },
  "26757": { state: "WV", city: "ROMNEY" },
  "26761": { state: "WV", city: "SHANKS" },
  "26763": { state: "WV", city: "SPRINGFIELD" },
  "26764": { state: "WV", city: "TERRA ALTA" },
  "26767": { state: "WV", city: "WILEY FORD" },
  "26801": { state: "WV", city: "BAKER" },
  "26802": { state: "WV", city: "BRANDYWINE" },
  "26804": { state: "WV", city: "CIRCLEVILLE" },
  "26807": { state: "WV", city: "FRANKLIN" },
  "26808": { state: "WV", city: "HIGH VIEW" },
  "26810": { state: "WV", city: "LOST CITY" },
  "26812": { state: "WV", city: "MATHIAS" },
  "26814": { state: "WV", city: "RIVERTON" },
  "26815": { state: "WV", city: "SUGAR GROVE" },
  "26817": { state: "WV", city: "BLOOMERY" },
  "26818": { state: "WV", city: "FISHER" },
  "26823": { state: "WV", city: "CAPON SPRINGS" },
  "26833": { state: "WV", city: "MAYSVILLE" },
  "26836": { state: "WV", city: "MOOREFIELD" },
  "26838": { state: "WV", city: "MILAM" },
  "26845": { state: "WV", city: "OLD FIELDS" },
  "26847": { state: "WV", city: "PETERSBURG" },
  "26851": { state: "WV", city: "WARDENSVILLE" },
  "26852": { state: "WV", city: "PURGITSVILLE" },
  "26855": { state: "WV", city: "CABINS" },
  "26865": { state: "WV", city: "YELLOW SPRING" },
  "26866": { state: "WV", city: "UPPER TRACT" },
  "26884": { state: "WV", city: "SENECA ROCKS" },
  "26886": { state: "WV", city: "ONEGO" },
  "27006": { state: "NC", city: "ADVANCE" },
  "27007": { state: "NC", city: "ARARAT" },
  "27009": { state: "NC", city: "BELEWS CREEK" },
  "27010": { state: "NC", city: "BETHANIA" },
  "27011": { state: "NC", city: "BOONVILLE" },
  "27012": { state: "NC", city: "CLEMMONS" },
  "27013": { state: "NC", city: "CLEVELAND" },
  "27014": { state: "NC", city: "COOLEEMEE" },
  "27016": { state: "NC", city: "DANBURY" },
  "27017": { state: "NC", city: "DOBSON" },
  "27018": { state: "NC", city: "EAST BEND" },
  "27019": { state: "NC", city: "GERMANTON" },
  "27020": { state: "NC", city: "HAMPTONVILLE" },
  "27021": { state: "NC", city: "KING" },
  "27022": { state: "NC", city: "LAWSONVILLE" },
  "27023": { state: "NC", city: "LEWISVILLE" },
  "27024": { state: "NC", city: "LOWGAP" },
  "27025": { state: "NC", city: "MADISON" },
  "27027": { state: "NC", city: "MAYODAN" },
  "27028": { state: "NC", city: "MOCKSVILLE" },
  "27030": { state: "NC", city: "MOUNT AIRY" },
  "27031": { state: "NC", city: "MOUNT AIRY" },
  "27040": { state: "NC", city: "PFAFFTOWN" },
  "27041": { state: "NC", city: "PILOT MOUNTAIN" },
  "27042": { state: "NC", city: "PINE HALL" },
  "27043": { state: "NC", city: "PINNACLE" },
  "27045": { state: "NC", city: "RURAL HALL" },
  "27046": { state: "NC", city: "SANDY RIDGE" },
  "27047": { state: "NC", city: "SILOAM" },
  "27048": { state: "NC", city: "STONEVILLE" },
  "27049": { state: "NC", city: "TOAST" },
  "27050": { state: "NC", city: "TOBACCOVILLE" },
  "27051": { state: "NC", city: "WALKERTOWN" },
  "27052": { state: "NC", city: "WALNUT COVE" },
  "27053": { state: "NC", city: "WESTFIELD" },
  "27054": { state: "NC", city: "WOODLEAF" },
  "27055": { state: "NC", city: "YADKINVILLE" },
  "27094": { state: "NC", city: "RURAL HALL" },
  "27098": { state: "NC", city: "RURAL HALL" },
  "27099": { state: "NC", city: "RURAL HALL" },
  "27101": { state: "NC", city: "WINSTON SALEM" },
  "27102": { state: "NC", city: "WINSTON SALEM" },
  "27103": { state: "NC", city: "WINSTON SALEM" },
  "27104": { state: "NC", city: "WINSTON SALEM" },
  "27105": { state: "NC", city: "WINSTON SALEM" },
  "27106": { state: "NC", city: "WINSTON SALEM" },
  "27107": { state: "NC", city: "WINSTON SALEM" },
  "27108": { state: "NC", city: "WINSTON SALEM" },
  "27109": { state: "NC", city: "WINSTON SALEM" },
  "27110": { state: "NC", city: "WINSTON SALEM" },
  "27111": { state: "NC", city: "WINSTON SALEM" },
  "27113": { state: "NC", city: "WINSTON SALEM" },
  "27114": { state: "NC", city: "WINSTON SALEM" },
  "27115": { state: "NC", city: "WINSTON SALEM" },
  "27116": { state: "NC", city: "WINSTON SALEM" },
  "27117": { state: "NC", city: "WINSTON SALEM" },
  "27120": { state: "NC", city: "WINSTON SALEM" },
  "27127": { state: "NC", city: "WINSTON SALEM" },
  "27130": { state: "NC", city: "WINSTON SALEM" },
  "27150": { state: "NC", city: "WINSTON SALEM" },
  "27152": { state: "NC", city: "WINSTON SALEM" },
  "27155": { state: "NC", city: "WINSTON SALEM" },
  "27157": { state: "NC", city: "WINSTON SALEM" },
  "27198": { state: "NC", city: "WINSTON SALEM" },
  "27199": { state: "NC", city: "WINSTON SALEM" },
  "27201": { state: "NC", city: "ALAMANCE" },
  "27202": { state: "NC", city: "ALTAMAHAW" },
  "27203": { state: "NC", city: "ASHEBORO" },
  "27204": { state: "NC", city: "ASHEBORO" },
  "27205": { state: "NC", city: "ASHEBORO" },
  "27207": { state: "NC", city: "BEAR CREEK" },
  "27208": { state: "NC", city: "BENNETT" },
  "27209": { state: "NC", city: "BISCOE" },
  "27212": { state: "NC", city: "BLANCH" },
  "27213": { state: "NC", city: "BONLEE" },
  "27214": { state: "NC", city: "BROWNS SUMMIT" },
  "27215": { state: "NC", city: "BURLINGTON" },
  "27216": { state: "NC", city: "BURLINGTON" },
  "27217": { state: "NC", city: "BURLINGTON" },
  "27228": { state: "NC", city: "BYNUM" },
  "27229": { state: "NC", city: "CANDOR" },
  "27230": { state: "NC", city: "CEDAR FALLS" },
  "27231": { state: "NC", city: "CEDAR GROVE" },
  "27233": { state: "NC", city: "CLIMAX" },
  "27235": { state: "NC", city: "COLFAX" },
  "27237": { state: "NC", city: "CUMNOCK" },
  "27239": { state: "NC", city: "DENTON" },
  "27242": { state: "NC", city: "EAGLE SPRINGS" },
  "27243": { state: "NC", city: "EFLAND" },
  "27244": { state: "NC", city: "ELON" },
  "27247": { state: "NC", city: "ETHER" },
  "27248": { state: "NC", city: "FRANKLINVILLE" },
  "27249": { state: "NC", city: "GIBSONVILLE" },
  "27252": { state: "NC", city: "GOLDSTON" },
  "27253": { state: "NC", city: "GRAHAM" },
  "27256": { state: "NC", city: "GULF" },
  "27258": { state: "NC", city: "HAW RIVER" },
  "27259": { state: "NC", city: "HIGHFALLS" },
  "27260": { state: "NC", city: "HIGH POINT" },
  "27261": { state: "NC", city: "HIGH POINT" },
  "27262": { state: "NC", city: "HIGH POINT" },
  "27263": { state: "NC", city: "HIGH POINT" },
  "27264": { state: "NC", city: "HIGH POINT" },
  "27265": { state: "NC", city: "HIGH POINT" },
  "27268": { state: "NC", city: "HIGH POINT" },
  "27278": { state: "NC", city: "HILLSBOROUGH" },
  "27281": { state: "NC", city: "JACKSON SPRINGS" },
  "27282": { state: "NC", city: "JAMESTOWN" },
  "27283": { state: "NC", city: "JULIAN" },
  "27284": { state: "NC", city: "KERNERSVILLE" },
  "27285": { state: "NC", city: "KERNERSVILLE" },
  "27288": { state: "NC", city: "EDEN" },
  "27289": { state: "NC", city: "EDEN" },
  "27291": { state: "NC", city: "LEASBURG" },
  "27292": { state: "NC", city: "LEXINGTON" },
  "27293": { state: "NC", city: "LEXINGTON" },
  "27294": { state: "NC", city: "LEXINGTON" },
  "27295": { state: "NC", city: "LEXINGTON" },
  "27298": { state: "NC", city: "LIBERTY" },
  "27299": { state: "NC", city: "LINWOOD" },
  "27301": { state: "NC", city: "MC LEANSVILLE" },
  "27302": { state: "NC", city: "MEBANE" },
  "27305": { state: "NC", city: "MILTON" },
  "27306": { state: "NC", city: "MOUNT GILEAD" },
  "27310": { state: "NC", city: "OAK RIDGE" },
  "27311": { state: "NC", city: "PELHAM" },
  "27312": { state: "NC", city: "PITTSBORO" },
  "27313": { state: "NC", city: "PLEASANT GARDEN" },
  "27314": { state: "NC", city: "PROSPECT HILL" },
  "27315": { state: "NC", city: "PROVIDENCE" },
  "27316": { state: "NC", city: "RAMSEUR" },
  "27317": { state: "NC", city: "RANDLEMAN" },
  "27320": { state: "NC", city: "REIDSVILLE" },
  "27323": { state: "NC", city: "REIDSVILLE" },
  "27325": { state: "NC", city: "ROBBINS" },
  "27326": { state: "NC", city: "RUFFIN" },
  "27330": { state: "NC", city: "SANFORD" },
  "27331": { state: "NC", city: "SANFORD" },
  "27332": { state: "NC", city: "SANFORD" },
  "27340": { state: "NC", city: "SAXAPAHAW" },
  "27341": { state: "NC", city: "SEAGROVE" },
  "27342": { state: "NC", city: "SEDALIA" },
  "27343": { state: "NC", city: "SEMORA" },
  "27344": { state: "NC", city: "SILER CITY" },
  "27349": { state: "NC", city: "SNOW CAMP" },
  "27350": { state: "NC", city: "SOPHIA" },
  "27351": { state: "NC", city: "SOUTHMONT" },
  "27355": { state: "NC", city: "STALEY" },
  "27356": { state: "NC", city: "STAR" },
  "27357": { state: "NC", city: "STOKESDALE" },
  "27358": { state: "NC", city: "SUMMERFIELD" },
  "27359": { state: "NC", city: "SWEPSONVILLE" },
  "27360": { state: "NC", city: "THOMASVILLE" },
  "27361": { state: "NC", city: "THOMASVILLE" },
  "27370": { state: "NC", city: "TRINITY" },
  "27371": { state: "NC", city: "TROY" },
  "27373": { state: "NC", city: "WALLBURG" },
  "27374": { state: "NC", city: "WELCOME" },
  "27375": { state: "NC", city: "WENTWORTH" },
  "27376": { state: "NC", city: "WEST END" },
  "27377": { state: "NC", city: "WHITSETT" },
  "27379": { state: "NC", city: "YANCEYVILLE" },
  "27401": { state: "NC", city: "GREENSBORO" },
  "27402": { state: "NC", city: "GREENSBORO" },
  "27403": { state: "NC", city: "GREENSBORO" },
  "27404": { state: "NC", city: "GREENSBORO" },
  "27405": { state: "NC", city: "GREENSBORO" },
  "27406": { state: "NC", city: "GREENSBORO" },
  "27407": { state: "NC", city: "GREENSBORO" },
  "27408": { state: "NC", city: "GREENSBORO" },
  "27409": { state: "NC", city: "GREENSBORO" },
  "27410": { state: "NC", city: "GREENSBORO" },
  "27411": { state: "NC", city: "GREENSBORO" },
  "27412": { state: "NC", city: "GREENSBORO" },
  "27413": { state: "NC", city: "GREENSBORO" },
  "27415": { state: "NC", city: "GREENSBORO" },
  "27416": { state: "NC", city: "GREENSBORO" },
  "27417": { state: "NC", city: "GREENSBORO" },
  "27419": { state: "NC", city: "GREENSBORO" },
  "27420": { state: "NC", city: "GREENSBORO" },
  "27425": { state: "NC", city: "GREENSBORO" },
  "27427": { state: "NC", city: "GREENSBORO" },
  "27429": { state: "NC", city: "GREENSBORO" },
  "27435": { state: "NC", city: "GREENSBORO" },
  "27438": { state: "NC", city: "GREENSBORO" },
  "27455": { state: "NC", city: "GREENSBORO" },
  "27495": { state: "NC", city: "GREENSBORO" },
  "27497": { state: "NC", city: "GREENSBORO" },
  "27498": { state: "NC", city: "GREENSBORO" },
  "27499": { state: "NC", city: "GREENSBORO" },
  "27501": { state: "NC", city: "ANGIER" },
  "27502": { state: "NC", city: "APEX" },
  "27503": { state: "NC", city: "BAHAMA" },
  "27504": { state: "NC", city: "BENSON" },
  "27505": { state: "NC", city: "BROADWAY" },
  "27506": { state: "NC", city: "BUIES CREEK" },
  "27507": { state: "NC", city: "BULLOCK" },
  "27508": { state: "NC", city: "BUNN" },
  "27509": { state: "NC", city: "BUTNER" },
  "27510": { state: "NC", city: "CARRBORO" },
  "27511": { state: "NC", city: "CARY" },
  "27512": { state: "NC", city: "CARY" },
  "27513": { state: "NC", city: "CARY" },
  "27514": { state: "NC", city: "CHAPEL HILL" },
  "27515": { state: "NC", city: "CHAPEL HILL" },
  "27516": { state: "NC", city: "CHAPEL HILL" },
  "27517": { state: "NC", city: "CHAPEL HILL" },
  "27518": { state: "NC", city: "CARY" },
  "27519": { state: "NC", city: "CARY" },
  "27520": { state: "NC", city: "CLAYTON" },
  "27521": { state: "NC", city: "COATS" },
  "27522": { state: "NC", city: "CREEDMOOR" },
  "27523": { state: "NC", city: "APEX" },
  "27524": { state: "NC", city: "FOUR OAKS" },
  "27525": { state: "NC", city: "FRANKLINTON" },
  "27526": { state: "NC", city: "FUQUAY VARINA" },
  "27527": { state: "NC", city: "CLAYTON" },
  "27528": { state: "NC", city: "CLAYTON" },
  "27529": { state: "NC", city: "GARNER" },
  "27530": { state: "NC", city: "GOLDSBORO" },
  "27531": { state: "NC", city: "GOLDSBORO" },
  "27532": { state: "NC", city: "GOLDSBORO" },
  "27533": { state: "NC", city: "GOLDSBORO" },
  "27534": { state: "NC", city: "GOLDSBORO" },
  "27536": { state: "NC", city: "HENDERSON" },
  "27537": { state: "NC", city: "HENDERSON" },
  "27539": { state: "NC", city: "APEX" },
  "27540": { state: "NC", city: "HOLLY SPRINGS" },
  "27541": { state: "NC", city: "HURDLE MILLS" },
  "27542": { state: "NC", city: "KENLY" },
  "27543": { state: "NC", city: "KIPLING" },
  "27544": { state: "NC", city: "KITTRELL" },
  "27545": { state: "NC", city: "KNIGHTDALE" },
  "27546": { state: "NC", city: "LILLINGTON" },
  "27549": { state: "NC", city: "LOUISBURG" },
  "27551": { state: "NC", city: "MACON" },
  "27552": { state: "NC", city: "MAMERS" },
  "27553": { state: "NC", city: "MANSON" },
  "27555": { state: "NC", city: "MICRO" },
  "27556": { state: "NC", city: "MIDDLEBURG" },
  "27557": { state: "NC", city: "MIDDLESEX" },
  "27559": { state: "NC", city: "MONCURE" },
  "27560": { state: "NC", city: "MORRISVILLE" },
  "27562": { state: "NC", city: "NEW HILL" },
  "27563": { state: "NC", city: "NORLINA" },
  "27565": { state: "NC", city: "OXFORD" },
  "27568": { state: "NC", city: "PINE LEVEL" },
  "27569": { state: "NC", city: "PRINCETON" },
  "27570": { state: "NC", city: "RIDGEWAY" },
  "27571": { state: "NC", city: "ROLESVILLE" },
  "27572": { state: "NC", city: "ROUGEMONT" },
  "27573": { state: "NC", city: "ROXBORO" },
  "27574": { state: "NC", city: "ROXBORO" },
  "27576": { state: "NC", city: "SELMA" },
  "27577": { state: "NC", city: "SMITHFIELD" },
  "27581": { state: "NC", city: "STEM" },
  "27582": { state: "NC", city: "STOVALL" },
  "27583": { state: "NC", city: "TIMBERLAKE" },
  "27584": { state: "NC", city: "TOWNSVILLE" },
  "27586": { state: "NC", city: "VAUGHAN" },
  "27587": { state: "NC", city: "WAKE FOREST" },
  "27588": { state: "NC", city: "WAKE FOREST" },
  "27589": { state: "NC", city: "WARRENTON" },
  "27591": { state: "NC", city: "WENDELL" },
  "27592": { state: "NC", city: "WILLOW SPRING" },
  "27593": { state: "NC", city: "WILSONS MILLS" },
  "27594": { state: "NC", city: "WISE" },
  "27596": { state: "NC", city: "YOUNGSVILLE" },
  "27597": { state: "NC", city: "ZEBULON" },
  "27599": { state: "NC", city: "CHAPEL HILL" },
  "27601": { state: "NC", city: "RALEIGH" },
  "27602": { state: "NC", city: "RALEIGH" },
  "27603": { state: "NC", city: "RALEIGH" },
  "27604": { state: "NC", city: "RALEIGH" },
  "27605": { state: "NC", city: "RALEIGH" },
  "27606": { state: "NC", city: "RALEIGH" },
  "27607": { state: "NC", city: "RALEIGH" },
  "27608": { state: "NC", city: "RALEIGH" },
  "27609": { state: "NC", city: "RALEIGH" },
  "27610": { state: "NC", city: "RALEIGH" },
  "27611": { state: "NC", city: "RALEIGH" },
  "27612": { state: "NC", city: "RALEIGH" },
  "27613": { state: "NC", city: "RALEIGH" },
  "27614": { state: "NC", city: "RALEIGH" },
  "27615": { state: "NC", city: "RALEIGH" },
  "27616": { state: "NC", city: "RALEIGH" },
  "27617": { state: "NC", city: "RALEIGH" },
  "27619": { state: "NC", city: "RALEIGH" },
  "27620": { state: "NC", city: "RALEIGH" },
  "27622": { state: "NC", city: "RALEIGH" },
  "27623": { state: "NC", city: "RALEIGH" },
  "27624": { state: "NC", city: "RALEIGH" },
  "27625": { state: "NC", city: "RALEIGH" },
  "27626": { state: "NC", city: "RALEIGH" },
  "27627": { state: "NC", city: "RALEIGH" },
  "27628": { state: "NC", city: "RALEIGH" },
  "27629": { state: "NC", city: "RALEIGH" },
  "27634": { state: "NC", city: "RALEIGH" },
  "27635": { state: "NC", city: "RALEIGH" },
  "27636": { state: "NC", city: "RALEIGH" },
  "27640": { state: "NC", city: "RALEIGH" },
  "27650": { state: "NC", city: "RALEIGH" },
  "27656": { state: "NC", city: "RALEIGH" },
  "27658": { state: "NC", city: "RALEIGH" },
  "27661": { state: "NC", city: "RALEIGH" },
  "27668": { state: "NC", city: "RALEIGH" },
  "27675": { state: "NC", city: "RALEIGH" },
  "27676": { state: "NC", city: "RALEIGH" },
  "27690": { state: "NC", city: "RALEIGH" },
  "27695": { state: "NC", city: "RALEIGH" },
  "27697": { state: "NC", city: "RALEIGH" },
  "27698": { state: "NC", city: "RALEIGH" },
  "27699": { state: "NC", city: "RALEIGH" },
  "27701": { state: "NC", city: "DURHAM" },
  "27702": { state: "NC", city: "DURHAM" },
  "27703": { state: "NC", city: "DURHAM" },
  "27704": { state: "NC", city: "DURHAM" },
  "27705": { state: "NC", city: "DURHAM" },
  "27706": { state: "NC", city: "DURHAM" },
  "27707": { state: "NC", city: "DURHAM" },
  "27708": { state: "NC", city: "DURHAM" },
  "27709": { state: "NC", city: "DURHAM" },
  "27710": { state: "NC", city: "DURHAM" },
  "27711": { state: "NC", city: "DURHAM" },
  "27712": { state: "NC", city: "DURHAM" },
  "27713": { state: "NC", city: "DURHAM" },
  "27715": { state: "NC", city: "DURHAM" },
  "27717": { state: "NC", city: "DURHAM" },
  "27722": { state: "NC", city: "DURHAM" },
  "27801": { state: "NC", city: "ROCKY MOUNT" },
  "27802": { state: "NC", city: "ROCKY MOUNT" },
  "27803": { state: "NC", city: "ROCKY MOUNT" },
  "27804": { state: "NC", city: "ROCKY MOUNT" },
  "27805": { state: "NC", city: "AULANDER" },
  "27806": { state: "NC", city: "AURORA" },
  "27807": { state: "NC", city: "BAILEY" },
  "27808": { state: "NC", city: "BATH" },
  "27809": { state: "NC", city: "BATTLEBORO" },
  "27810": { state: "NC", city: "BELHAVEN" },
  "27811": { state: "NC", city: "BELLARTHUR" },
  "27812": { state: "NC", city: "BETHEL" },
  "27813": { state: "NC", city: "BLACK CREEK" },
  "27814": { state: "NC", city: "BLOUNTS CREEK" },
  "27815": { state: "NC", city: "ROCKY MOUNT" },
  "27816": { state: "NC", city: "CASTALIA" },
  "27817": { state: "NC", city: "CHOCOWINITY" },
  "27818": { state: "NC", city: "COMO" },
  "27819": { state: "NC", city: "CONETOE" },
  "27820": { state: "NC", city: "CONWAY" },
  "27821": { state: "NC", city: "EDWARD" },
  "27822": { state: "NC", city: "ELM CITY" },
  "27823": { state: "NC", city: "ENFIELD" },
  "27824": { state: "NC", city: "ENGELHARD" },
  "27825": { state: "NC", city: "EVERETTS" },
  "27826": { state: "NC", city: "FAIRFIELD" },
  "27827": { state: "NC", city: "FALKLAND" },
  "27828": { state: "NC", city: "FARMVILLE" },
  "27829": { state: "NC", city: "FOUNTAIN" },
  "27830": { state: "NC", city: "FREMONT" },
  "27831": { state: "NC", city: "GARYSBURG" },
  "27832": { state: "NC", city: "GASTON" },
  "27833": { state: "NC", city: "GREENVILLE" },
  "27834": { state: "NC", city: "GREENVILLE" },
  "27835": { state: "NC", city: "GREENVILLE" },
  "27836": { state: "NC", city: "GREENVILLE" },
  "27837": { state: "NC", city: "GRIMESLAND" },
  "27839": { state: "NC", city: "HALIFAX" },
  "27840": { state: "NC", city: "HAMILTON" },
  "27841": { state: "NC", city: "HASSELL" },
  "27842": { state: "NC", city: "HENRICO" },
  "27843": { state: "NC", city: "HOBGOOD" },
  "27844": { state: "NC", city: "HOLLISTER" },
  "27845": { state: "NC", city: "JACKSON" },
  "27846": { state: "NC", city: "JAMESVILLE" },
  "27847": { state: "NC", city: "KELFORD" },
  "27849": { state: "NC", city: "LEWISTON WOODVILLE" },
  "27850": { state: "NC", city: "LITTLETON" },
  "27851": { state: "NC", city: "LUCAMA" },
  "27852": { state: "NC", city: "MACCLESFIELD" },
  "27853": { state: "NC", city: "MARGARETTSVILLE" },
  "27855": { state: "NC", city: "MURFREESBORO" },
  "27856": { state: "NC", city: "NASHVILLE" },
  "27857": { state: "NC", city: "OAK CITY" },
  "27858": { state: "NC", city: "GREENVILLE" },
  "27860": { state: "NC", city: "PANTEGO" },
  "27861": { state: "NC", city: "PARMELE" },
  "27862": { state: "NC", city: "PENDLETON" },
  "27863": { state: "NC", city: "PIKEVILLE" },
  "27864": { state: "NC", city: "PINETOPS" },
  "27865": { state: "NC", city: "PINETOWN" },
  "27866": { state: "NC", city: "PLEASANT HILL" },
  "27867": { state: "NC", city: "POTECASI" },
  "27868": { state: "NC", city: "RED OAK" },
  "27869": { state: "NC", city: "RICH SQUARE" },
  "27870": { state: "NC", city: "ROANOKE RAPIDS" },
  "27871": { state: "NC", city: "ROBERSONVILLE" },
  "27872": { state: "NC", city: "ROXOBEL" },
  "27873": { state: "NC", city: "SARATOGA" },
  "27874": { state: "NC", city: "SCOTLAND NECK" },
  "27875": { state: "NC", city: "SCRANTON" },
  "27876": { state: "NC", city: "SEABOARD" },
  "27877": { state: "NC", city: "SEVERN" },
  "27878": { state: "NC", city: "SHARPSBURG" },
  "27879": { state: "NC", city: "SIMPSON" },
  "27880": { state: "NC", city: "SIMS" },
  "27881": { state: "NC", city: "SPEED" },
  "27882": { state: "NC", city: "SPRING HOPE" },
  "27883": { state: "NC", city: "STANTONSBURG" },
  "27884": { state: "NC", city: "STOKES" },
  "27885": { state: "NC", city: "SWANQUARTER" },
  "27886": { state: "NC", city: "TARBORO" },
  "27887": { state: "NC", city: "TILLERY" },
  "27888": { state: "NC", city: "WALSTONBURG" },
  "27889": { state: "NC", city: "WASHINGTON" },
  "27890": { state: "NC", city: "WELDON" },
  "27891": { state: "NC", city: "WHITAKERS" },
  "27892": { state: "NC", city: "WILLIAMSTON" },
  "27893": { state: "NC", city: "WILSON" },
  "27894": { state: "NC", city: "WILSON" },
  "27895": { state: "NC", city: "WILSON" },
  "27896": { state: "NC", city: "WILSON" },
  "27897": { state: "NC", city: "WOODLAND" },
  "27906": { state: "NC", city: "ELIZABETH CITY" },
  "27907": { state: "NC", city: "ELIZABETH CITY" },
  "27909": { state: "NC", city: "ELIZABETH CITY" },
  "27910": { state: "NC", city: "AHOSKIE" },
  "27915": { state: "NC", city: "AVON" },
  "27916": { state: "NC", city: "AYDLETT" },
  "27917": { state: "NC", city: "BARCO" },
  "27919": { state: "NC", city: "BELVIDERE" },
  "27920": { state: "NC", city: "BUXTON" },
  "27921": { state: "NC", city: "CAMDEN" },
  "27922": { state: "NC", city: "COFIELD" },
  "27923": { state: "NC", city: "COINJOCK" },
  "27924": { state: "NC", city: "COLERAIN" },
  "27925": { state: "NC", city: "COLUMBIA" },
  "27926": { state: "NC", city: "CORAPEAKE" },
  "27927": { state: "NC", city: "COROLLA" },
  "27928": { state: "NC", city: "CRESWELL" },
  "27929": { state: "NC", city: "CURRITUCK" },
  "27930": { state: "NC", city: "HERTFORD" },
  "27932": { state: "NC", city: "EDENTON" },
  "27935": { state: "NC", city: "EURE" },
  "27936": { state: "NC", city: "FRISCO" },
  "27937": { state: "NC", city: "GATES" },
  "27938": { state: "NC", city: "GATESVILLE" },
  "27939": { state: "NC", city: "GRANDY" },
  "27941": { state: "NC", city: "HARBINGER" },
  "27942": { state: "NC", city: "HARRELLSVILLE" },
  "27943": { state: "NC", city: "HATTERAS" },
  "27944": { state: "NC", city: "HERTFORD" },
  "27946": { state: "NC", city: "HOBBSVILLE" },
  "27947": { state: "NC", city: "JARVISBURG" },
  "27948": { state: "NC", city: "KILL DEVIL HILLS" },
  "27949": { state: "NC", city: "KITTY HAWK" },
  "27950": { state: "NC", city: "KNOTTS ISLAND" },
  "27953": { state: "NC", city: "MANNS HARBOR" },
  "27954": { state: "NC", city: "MANTEO" },
  "27956": { state: "NC", city: "MAPLE" },
  "27957": { state: "NC", city: "MERRY HILL" },
  "27958": { state: "NC", city: "MOYOCK" },
  "27959": { state: "NC", city: "NAGS HEAD" },
  "27960": { state: "NC", city: "OCRACOKE" },
  "27962": { state: "NC", city: "PLYMOUTH" },
  "27964": { state: "NC", city: "POINT HARBOR" },
  "27965": { state: "NC", city: "POPLAR BRANCH" },
  "27966": { state: "NC", city: "POWELLS POINT" },
  "27967": { state: "NC", city: "POWELLSVILLE" },
  "27968": { state: "NC", city: "RODANTHE" },
  "27969": { state: "NC", city: "RODUCO" },
  "27970": { state: "NC", city: "ROPER" },
  "27972": { state: "NC", city: "SALVO" },
  "27973": { state: "NC", city: "SHAWBORO" },
  "27974": { state: "NC", city: "SHILOH" },
  "27976": { state: "NC", city: "SOUTH MILLS" },
  "27978": { state: "NC", city: "STUMPY POINT" },
  "27979": { state: "NC", city: "SUNBURY" },
  "27980": { state: "NC", city: "TYNER" },
  "27981": { state: "NC", city: "WANCHESE" },
  "27982": { state: "NC", city: "WAVES" },
  "27983": { state: "NC", city: "WINDSOR" },
  "27985": { state: "NC", city: "WINFALL" },
  "27986": { state: "NC", city: "WINTON" },
  "28001": { state: "NC", city: "ALBEMARLE" },
  "28002": { state: "NC", city: "ALBEMARLE" },
  "28006": { state: "NC", city: "ALEXIS" },
  "28007": { state: "NC", city: "ANSONVILLE" },
  "28009": { state: "NC", city: "BADIN" },
  "28010": { state: "NC", city: "BARIUM SPRINGS" },
  "28012": { state: "NC", city: "BELMONT" },
  "28016": { state: "NC", city: "BESSEMER CITY" },
  "28017": { state: "NC", city: "BOILING SPRINGS" },
  "28018": { state: "NC", city: "BOSTIC" },
  "28019": { state: "NC", city: "CAROLEEN" },
  "28020": { state: "NC", city: "CASAR" },
  "28021": { state: "NC", city: "CHERRYVILLE" },
  "28023": { state: "NC", city: "CHINA GROVE" },
  "28024": { state: "NC", city: "CLIFFSIDE" },
  "28025": { state: "NC", city: "CONCORD" },
  "28026": { state: "NC", city: "CONCORD" },
  "28027": { state: "NC", city: "CONCORD" },
  "28031": { state: "NC", city: "CORNELIUS" },
  "28032": { state: "NC", city: "CRAMERTON" },
  "28033": { state: "NC", city: "CROUSE" },
  "28034": { state: "NC", city: "DALLAS" },
  "28035": { state: "NC", city: "DAVIDSON" },
  "28036": { state: "NC", city: "DAVIDSON" },
  "28037": { state: "NC", city: "DENVER" },
  "28038": { state: "NC", city: "EARL" },
  "28039": { state: "NC", city: "EAST SPENCER" },
  "28040": { state: "NC", city: "ELLENBORO" },
  "28041": { state: "NC", city: "FAITH" },
  "28042": { state: "NC", city: "FALLSTON" },
  "28043": { state: "NC", city: "FOREST CITY" },
  "28052": { state: "NC", city: "GASTONIA" },
  "28053": { state: "NC", city: "GASTONIA" },
  "28054": { state: "NC", city: "GASTONIA" },
  "28055": { state: "NC", city: "GASTONIA" },
  "28056": { state: "NC", city: "GASTONIA" },
  "28070": { state: "NC", city: "HUNTERSVILLE" },
  "28071": { state: "NC", city: "GOLD HILL" },
  "28072": { state: "NC", city: "GRANITE QUARRY" },
  "28073": { state: "NC", city: "GROVER" },
  "28074": { state: "NC", city: "HARRIS" },
  "28075": { state: "NC", city: "HARRISBURG" },
  "28076": { state: "NC", city: "HENRIETTA" },
  "28077": { state: "NC", city: "HIGH SHOALS" },
  "28078": { state: "NC", city: "HUNTERSVILLE" },
  "28079": { state: "NC", city: "INDIAN TRAIL" },
  "28080": { state: "NC", city: "IRON STATION" },
  "28081": { state: "NC", city: "KANNAPOLIS" },
  "28082": { state: "NC", city: "KANNAPOLIS" },
  "28083": { state: "NC", city: "KANNAPOLIS" },
  "28086": { state: "NC", city: "KINGS MOUNTAIN" },
  "28088": { state: "NC", city: "LANDIS" },
  "28089": { state: "NC", city: "LATTIMORE" },
  "28090": { state: "NC", city: "LAWNDALE" },
  "28091": { state: "NC", city: "LILESVILLE" },
  "28092": { state: "NC", city: "LINCOLNTON" },
  "28093": { state: "NC", city: "LINCOLNTON" },
  "28097": { state: "NC", city: "LOCUST" },
  "28098": { state: "NC", city: "LOWELL" },
  "28101": { state: "NC", city: "MC ADENVILLE" },
  "28102": { state: "NC", city: "MC FARLAN" },
  "28103": { state: "NC", city: "MARSHVILLE" },
  "28104": { state: "NC", city: "MATTHEWS" },
  "28105": { state: "NC", city: "MATTHEWS" },
  "28106": { state: "NC", city: "MATTHEWS" },
  "28107": { state: "NC", city: "MIDLAND" },
  "28108": { state: "NC", city: "MINERAL SPRINGS" },
  "28109": { state: "NC", city: "MISENHEIMER" },
  "28110": { state: "NC", city: "MONROE" },
  "28111": { state: "NC", city: "MONROE" },
  "28112": { state: "NC", city: "MONROE" },
  "28114": { state: "NC", city: "MOORESBORO" },
  "28115": { state: "NC", city: "MOORESVILLE" },
  "28117": { state: "NC", city: "MOORESVILLE" },
  "28119": { state: "NC", city: "MORVEN" },
  "28120": { state: "NC", city: "MOUNT HOLLY" },
  "28123": { state: "NC", city: "MOUNT MOURNE" },
  "28124": { state: "NC", city: "MOUNT PLEASANT" },
  "28125": { state: "NC", city: "MOUNT ULLA" },
  "28126": { state: "NC", city: "NEWELL" },
  "28127": { state: "NC", city: "NEW LONDON" },
  "28128": { state: "NC", city: "NORWOOD" },
  "28129": { state: "NC", city: "OAKBORO" },
  "28130": { state: "NC", city: "PAW CREEK" },
  "28133": { state: "NC", city: "PEACHLAND" },
  "28134": { state: "NC", city: "PINEVILLE" },
  "28135": { state: "NC", city: "POLKTON" },
  "28136": { state: "NC", city: "POLKVILLE" },
  "28137": { state: "NC", city: "RICHFIELD" },
  "28138": { state: "NC", city: "ROCKWELL" },
  "28139": { state: "NC", city: "RUTHERFORDTON" },
  "28144": { state: "NC", city: "SALISBURY" },
  "28145": { state: "NC", city: "SALISBURY" },
  "28146": { state: "NC", city: "SALISBURY" },
  "28147": { state: "NC", city: "SALISBURY" },
  "28150": { state: "NC", city: "SHELBY" },
  "28151": { state: "NC", city: "SHELBY" },
  "28152": { state: "NC", city: "SHELBY" },
  "28159": { state: "NC", city: "SPENCER" },
  "28160": { state: "NC", city: "SPINDALE" },
  "28163": { state: "NC", city: "STANFIELD" },
  "28164": { state: "NC", city: "STANLEY" },
  "28166": { state: "NC", city: "TROUTMAN" },
  "28167": { state: "NC", city: "UNION MILLS" },
  "28168": { state: "NC", city: "VALE" },
  "28169": { state: "NC", city: "WACO" },
  "28170": { state: "NC", city: "WADESBORO" },
  "28173": { state: "NC", city: "WAXHAW" },
  "28174": { state: "NC", city: "WINGATE" },
  "28201": { state: "NC", city: "CHARLOTTE" },
  "28202": { state: "NC", city: "CHARLOTTE" },
  "28203": { state: "NC", city: "CHARLOTTE" },
  "28204": { state: "NC", city: "CHARLOTTE" },
  "28205": { state: "NC", city: "CHARLOTTE" },
  "28206": { state: "NC", city: "CHARLOTTE" },
  "28207": { state: "NC", city: "CHARLOTTE" },
  "28208": { state: "NC", city: "CHARLOTTE" },
  "28209": { state: "NC", city: "CHARLOTTE" },
  "28210": { state: "NC", city: "CHARLOTTE" },
  "28211": { state: "NC", city: "CHARLOTTE" },
  "28212": { state: "NC", city: "CHARLOTTE" },
  "28213": { state: "NC", city: "CHARLOTTE" },
  "28214": { state: "NC", city: "CHARLOTTE" },
  "28215": { state: "NC", city: "CHARLOTTE" },
  "28216": { state: "NC", city: "CHARLOTTE" },
  "28217": { state: "NC", city: "CHARLOTTE" },
  "28218": { state: "NC", city: "CHARLOTTE" },
  "28219": { state: "NC", city: "CHARLOTTE" },
  "28220": { state: "NC", city: "CHARLOTTE" },
  "28221": { state: "NC", city: "CHARLOTTE" },
  "28222": { state: "NC", city: "CHARLOTTE" },
  "28223": { state: "NC", city: "CHARLOTTE" },
  "28224": { state: "NC", city: "CHARLOTTE" },
  "28226": { state: "NC", city: "CHARLOTTE" },
  "28227": { state: "NC", city: "CHARLOTTE" },
  "28228": { state: "NC", city: "CHARLOTTE" },
  "28229": { state: "NC", city: "CHARLOTTE" },
  "28230": { state: "NC", city: "CHARLOTTE" },
  "28231": { state: "NC", city: "CHARLOTTE" },
  "28232": { state: "NC", city: "CHARLOTTE" },
  "28233": { state: "NC", city: "CHARLOTTE" },
  "28234": { state: "NC", city: "CHARLOTTE" },
  "28235": { state: "NC", city: "CHARLOTTE" },
  "28236": { state: "NC", city: "CHARLOTTE" },
  "28237": { state: "NC", city: "CHARLOTTE" },
  "28241": { state: "NC", city: "CHARLOTTE" },
  "28242": { state: "NC", city: "CHARLOTTE" },
  "28243": { state: "NC", city: "CHARLOTTE" },
  "28244": { state: "NC", city: "CHARLOTTE" },
  "28246": { state: "NC", city: "CHARLOTTE" },
  "28247": { state: "NC", city: "CHARLOTTE" },
  "28253": { state: "NC", city: "CHARLOTTE" },
  "28254": { state: "NC", city: "CHARLOTTE" },
  "28255": { state: "NC", city: "CHARLOTTE" },
  "28256": { state: "NC", city: "CHARLOTTE" },
  "28258": { state: "NC", city: "CHARLOTTE" },
  "28260": { state: "NC", city: "CHARLOTTE" },
  "28262": { state: "NC", city: "CHARLOTTE" },
  "28263": { state: "NC", city: "CHARLOTTE" },
  "28265": { state: "NC", city: "CHARLOTTE" },
  "28266": { state: "NC", city: "CHARLOTTE" },
  "28269": { state: "NC", city: "CHARLOTTE" },
  "28270": { state: "NC", city: "CHARLOTTE" },
  "28271": { state: "NC", city: "CHARLOTTE" },
  "28272": { state: "NC", city: "CHARLOTTE" },
  "28273": { state: "NC", city: "CHARLOTTE" },
  "28274": { state: "NC", city: "CHARLOTTE" },
  "28275": { state: "NC", city: "CHARLOTTE" },
  "28277": { state: "NC", city: "CHARLOTTE" },
  "28278": { state: "NC", city: "CHARLOTTE" },
  "28280": { state: "NC", city: "CHARLOTTE" },
  "28281": { state: "NC", city: "CHARLOTTE" },
  "28282": { state: "NC", city: "CHARLOTTE" },
  "28284": { state: "NC", city: "CHARLOTTE" },
  "28285": { state: "NC", city: "CHARLOTTE" },
  "28287": { state: "NC", city: "CHARLOTTE" },
  "28288": { state: "NC", city: "CHARLOTTE" },
  "28289": { state: "NC", city: "CHARLOTTE" },
  "28290": { state: "NC", city: "CHARLOTTE" },
  "28296": { state: "NC", city: "CHARLOTTE" },
  "28297": { state: "NC", city: "CHARLOTTE" },
  "28299": { state: "NC", city: "CHARLOTTE" },
  "28301": { state: "NC", city: "FAYETTEVILLE" },
  "28302": { state: "NC", city: "FAYETTEVILLE" },
  "28303": { state: "NC", city: "FAYETTEVILLE" },
  "28304": { state: "NC", city: "FAYETTEVILLE" },
  "28305": { state: "NC", city: "FAYETTEVILLE" },
  "28306": { state: "NC", city: "FAYETTEVILLE" },
  "28307": { state: "NC", city: "FORT LIBERTY" },
  "28308": { state: "NC", city: "POPE ARMY AIRFIELD" },
  "28309": { state: "NC", city: "FAYETTEVILLE" },
  "28310": { state: "NC", city: "FORT LIBERTY" },
  "28311": { state: "NC", city: "FAYETTEVILLE" },
  "28312": { state: "NC", city: "FAYETTEVILLE" },
  "28314": { state: "NC", city: "FAYETTEVILLE" },
  "28315": { state: "NC", city: "ABERDEEN" },
  "28318": { state: "NC", city: "AUTRYVILLE" },
  "28319": { state: "NC", city: "BARNESVILLE" },
  "28320": { state: "NC", city: "BLADENBORO" },
  "28323": { state: "NC", city: "BUNNLEVEL" },
  "28325": { state: "NC", city: "CALYPSO" },
  "28326": { state: "NC", city: "CAMERON" },
  "28327": { state: "NC", city: "CARTHAGE" },
  "28328": { state: "NC", city: "CLINTON" },
  "28329": { state: "NC", city: "CLINTON" },
  "28330": { state: "NC", city: "CORDOVA" },
  "28331": { state: "NC", city: "CUMBERLAND" },
  "28332": { state: "NC", city: "DUBLIN" },
  "28333": { state: "NC", city: "DUDLEY" },
  "28334": { state: "NC", city: "DUNN" },
  "28335": { state: "NC", city: "DUNN" },
  "28337": { state: "NC", city: "ELIZABETHTOWN" },
  "28338": { state: "NC", city: "ELLERBE" },
  "28339": { state: "NC", city: "ERWIN" },
  "28340": { state: "NC", city: "FAIRMONT" },
  "28341": { state: "NC", city: "FAISON" },
  "28342": { state: "NC", city: "FALCON" },
  "28343": { state: "NC", city: "GIBSON" },
  "28344": { state: "NC", city: "GODWIN" },
  "28345": { state: "NC", city: "HAMLET" },
  "28347": { state: "NC", city: "HOFFMAN" },
  "28348": { state: "NC", city: "HOPE MILLS" },
  "28349": { state: "NC", city: "KENANSVILLE" },
  "28350": { state: "NC", city: "LAKEVIEW" },
  "28351": { state: "NC", city: "LAUREL HILL" },
  "28352": { state: "NC", city: "LAURINBURG" },
  "28353": { state: "NC", city: "LAURINBURG" },
  "28355": { state: "NC", city: "LEMON SPRINGS" },
  "28356": { state: "NC", city: "LINDEN" },
  "28357": { state: "NC", city: "LUMBER BRIDGE" },
  "28358": { state: "NC", city: "LUMBERTON" },
  "28359": { state: "NC", city: "LUMBERTON" },
  "28360": { state: "NC", city: "LUMBERTON" },
  "28362": { state: "NC", city: "MARIETTA" },
  "28363": { state: "NC", city: "MARSTON" },
  "28364": { state: "NC", city: "MAXTON" },
  "28365": { state: "NC", city: "MOUNT OLIVE" },
  "28366": { state: "NC", city: "NEWTON GROVE" },
  "28367": { state: "NC", city: "NORMAN" },
  "28368": { state: "NC", city: "OLIVIA" },
  "28369": { state: "NC", city: "ORRUM" },
  "28370": { state: "NC", city: "PINEHURST" },
  "28371": { state: "NC", city: "PARKTON" },
  "28372": { state: "NC", city: "PEMBROKE" },
  "28373": { state: "NC", city: "PINEBLUFF" },
  "28374": { state: "NC", city: "PINEHURST" },
  "28375": { state: "NC", city: "PROCTORVILLE" },
  "28376": { state: "NC", city: "RAEFORD" },
  "28377": { state: "NC", city: "RED SPRINGS" },
  "28378": { state: "NC", city: "REX" },
  "28379": { state: "NC", city: "ROCKINGHAM" },
  "28380": { state: "NC", city: "ROCKINGHAM" },
  "28382": { state: "NC", city: "ROSEBORO" },
  "28383": { state: "NC", city: "ROWLAND" },
  "28384": { state: "NC", city: "SAINT PAULS" },
  "28385": { state: "NC", city: "SALEMBURG" },
  "28386": { state: "NC", city: "SHANNON" },
  "28387": { state: "NC", city: "SOUTHERN PINES" },
  "28388": { state: "NC", city: "SOUTHERN PINES" },
  "28390": { state: "NC", city: "SPRING LAKE" },
  "28391": { state: "NC", city: "STEDMAN" },
  "28392": { state: "NC", city: "TAR HEEL" },
  "28393": { state: "NC", city: "TURKEY" },
  "28394": { state: "NC", city: "VASS" },
  "28395": { state: "NC", city: "WADE" },
  "28396": { state: "NC", city: "WAGRAM" },
  "28398": { state: "NC", city: "WARSAW" },
  "28399": { state: "NC", city: "WHITE OAK" },
  "28401": { state: "NC", city: "WILMINGTON" },
  "28402": { state: "NC", city: "WILMINGTON" },
  "28403": { state: "NC", city: "WILMINGTON" },
  "28404": { state: "NC", city: "WILMINGTON" },
  "28405": { state: "NC", city: "WILMINGTON" },
  "28406": { state: "NC", city: "WILMINGTON" },
  "28407": { state: "NC", city: "WILMINGTON" },
  "28408": { state: "NC", city: "WILMINGTON" },
  "28409": { state: "NC", city: "WILMINGTON" },
  "28410": { state: "NC", city: "WILMINGTON" },
  "28411": { state: "NC", city: "WILMINGTON" },
  "28412": { state: "NC", city: "WILMINGTON" },
  "28420": { state: "NC", city: "ASH" },
  "28421": { state: "NC", city: "ATKINSON" },
  "28422": { state: "NC", city: "BOLIVIA" },
  "28423": { state: "NC", city: "BOLTON" },
  "28424": { state: "NC", city: "BRUNSWICK" },
  "28425": { state: "NC", city: "BURGAW" },
  "28428": { state: "NC", city: "CAROLINA BEACH" },
  "28429": { state: "NC", city: "CASTLE HAYNE" },
  "28430": { state: "NC", city: "CERRO GORDO" },
  "28431": { state: "NC", city: "CHADBOURN" },
  "28432": { state: "NC", city: "CLARENDON" },
  "28433": { state: "NC", city: "CLARKTON" },
  "28434": { state: "NC", city: "COUNCIL" },
  "28435": { state: "NC", city: "CURRIE" },
  "28436": { state: "NC", city: "DELCO" },
  "28438": { state: "NC", city: "EVERGREEN" },
  "28439": { state: "NC", city: "FAIR BLUFF" },
  "28441": { state: "NC", city: "GARLAND" },
  "28442": { state: "NC", city: "HALLSBORO" },
  "28443": { state: "NC", city: "HAMPSTEAD" },
  "28444": { state: "NC", city: "HARRELLS" },
  "28445": { state: "NC", city: "HOLLY RIDGE" },
  "28447": { state: "NC", city: "IVANHOE" },
  "28448": { state: "NC", city: "KELLY" },
  "28449": { state: "NC", city: "KURE BEACH" },
  "28450": { state: "NC", city: "LAKE WACCAMAW" },
  "28451": { state: "NC", city: "LELAND" },
  "28452": { state: "NC", city: "LONGWOOD" },
  "28453": { state: "NC", city: "MAGNOLIA" },
  "28454": { state: "NC", city: "MAPLE HILL" },
  "28455": { state: "NC", city: "NAKINA" },
  "28456": { state: "NC", city: "RIEGELWOOD" },
  "28457": { state: "NC", city: "ROCKY POINT" },
  "28458": { state: "NC", city: "ROSE HILL" },
  "28459": { state: "NC", city: "SHALLOTTE" },
  "28460": { state: "NC", city: "SNEADS FERRY" },
  "28461": { state: "NC", city: "SOUTHPORT" },
  "28462": { state: "NC", city: "SUPPLY" },
  "28463": { state: "NC", city: "TABOR CITY" },
  "28464": { state: "NC", city: "TEACHEY" },
  "28465": { state: "NC", city: "OAK ISLAND" },
  "28466": { state: "NC", city: "WALLACE" },
  "28467": { state: "NC", city: "CALABASH" },
  "28468": { state: "NC", city: "SUNSET BEACH" },
  "28469": { state: "NC", city: "OCEAN ISLE BEACH" },
  "28470": { state: "NC", city: "SHALLOTTE" },
  "28472": { state: "NC", city: "WHITEVILLE" },
  "28478": { state: "NC", city: "WILLARD" },
  "28479": { state: "NC", city: "WINNABOW" },
  "28480": { state: "NC", city: "WRIGHTSVILLE BEACH" },
  "28501": { state: "NC", city: "KINSTON" },
  "28502": { state: "NC", city: "KINSTON" },
  "28503": { state: "NC", city: "KINSTON" },
  "28504": { state: "NC", city: "KINSTON" },
  "28508": { state: "NC", city: "ALBERTSON" },
  "28509": { state: "NC", city: "ALLIANCE" },
  "28510": { state: "NC", city: "ARAPAHOE" },
  "28511": { state: "NC", city: "ATLANTIC" },
  "28512": { state: "NC", city: "ATLANTIC BEACH" },
  "28513": { state: "NC", city: "AYDEN" },
  "28515": { state: "NC", city: "BAYBORO" },
  "28516": { state: "NC", city: "BEAUFORT" },
  "28518": { state: "NC", city: "BEULAVILLE" },
  "28519": { state: "NC", city: "BRIDGETON" },
  "28520": { state: "NC", city: "CEDAR ISLAND" },
  "28521": { state: "NC", city: "CHINQUAPIN" },
  "28522": { state: "NC", city: "COMFORT" },
  "28523": { state: "NC", city: "COVE CITY" },
  "28524": { state: "NC", city: "DAVIS" },
  "28525": { state: "NC", city: "DEEP RUN" },
  "28526": { state: "NC", city: "DOVER" },
  "28527": { state: "NC", city: "ERNUL" },
  "28528": { state: "NC", city: "GLOUCESTER" },
  "28529": { state: "NC", city: "GRANTSBORO" },
  "28530": { state: "NC", city: "GRIFTON" },
  "28531": { state: "NC", city: "HARKERS ISLAND" },
  "28532": { state: "NC", city: "HAVELOCK" },
  "28533": { state: "NC", city: "CHERRY POINT" },
  "28537": { state: "NC", city: "HOBUCKEN" },
  "28538": { state: "NC", city: "HOOKERTON" },
  "28539": { state: "NC", city: "HUBERT" },
  "28540": { state: "NC", city: "JACKSONVILLE" },
  "28541": { state: "NC", city: "JACKSONVILLE" },
  "28542": { state: "NC", city: "CAMP LEJEUNE" },
  "28543": { state: "NC", city: "TARAWA TERRACE" },
  "28544": { state: "NC", city: "MIDWAY PARK" },
  "28545": { state: "NC", city: "MCCUTCHEON FIELD" },
  "28546": { state: "NC", city: "JACKSONVILLE" },
  "28547": { state: "NC", city: "CAMP LEJEUNE" },
  "28551": { state: "NC", city: "LA GRANGE" },
  "28552": { state: "NC", city: "LOWLAND" },
  "28553": { state: "NC", city: "MARSHALLBERG" },
  "28554": { state: "NC", city: "MAURY" },
  "28555": { state: "NC", city: "MAYSVILLE" },
  "28556": { state: "NC", city: "MERRITT" },
  "28557": { state: "NC", city: "MOREHEAD CITY" },
  "28560": { state: "NC", city: "NEW BERN" },
  "28561": { state: "NC", city: "NEW BERN" },
  "28562": { state: "NC", city: "NEW BERN" },
  "28563": { state: "NC", city: "NEW BERN" },
  "28564": { state: "NC", city: "NEW BERN" },
  "28570": { state: "NC", city: "NEWPORT" },
  "28571": { state: "NC", city: "ORIENTAL" },
  "28572": { state: "NC", city: "PINK HILL" },
  "28573": { state: "NC", city: "POLLOCKSVILLE" },
  "28574": { state: "NC", city: "RICHLANDS" },
  "28575": { state: "NC", city: "SALTER PATH" },
  "28577": { state: "NC", city: "SEALEVEL" },
  "28578": { state: "NC", city: "SEVEN SPRINGS" },
  "28579": { state: "NC", city: "SMYRNA" },
  "28580": { state: "NC", city: "SNOW HILL" },
  "28581": { state: "NC", city: "STACY" },
  "28582": { state: "NC", city: "STELLA" },
  "28583": { state: "NC", city: "STONEWALL" },
  "28584": { state: "NC", city: "SWANSBORO" },
  "28585": { state: "NC", city: "TRENTON" },
  "28586": { state: "NC", city: "VANCEBORO" },
  "28587": { state: "NC", city: "VANDEMERE" },
  "28589": { state: "NC", city: "WILLISTON" },
  "28590": { state: "NC", city: "WINTERVILLE" },
  "28594": { state: "NC", city: "EMERALD ISLE" },
  "28601": { state: "NC", city: "HICKORY" },
  "28602": { state: "NC", city: "HICKORY" },
  "28603": { state: "NC", city: "HICKORY" },
  "28604": { state: "NC", city: "BANNER ELK" },
  "28605": { state: "NC", city: "BLOWING ROCK" },
  "28606": { state: "NC", city: "BOOMER" },
  "28607": { state: "NC", city: "BOONE" },
  "28608": { state: "NC", city: "BOONE" },
  "28609": { state: "NC", city: "CATAWBA" },
  "28610": { state: "NC", city: "CLAREMONT" },
  "28611": { state: "NC", city: "COLLETTSVILLE" },
  "28612": { state: "NC", city: "CONNELLY SPRINGS" },
  "28613": { state: "NC", city: "CONOVER" },
  "28615": { state: "NC", city: "CRESTON" },
  "28616": { state: "NC", city: "CROSSNORE" },
  "28617": { state: "NC", city: "CRUMPLER" },
  "28618": { state: "NC", city: "DEEP GAP" },
  "28619": { state: "NC", city: "DREXEL" },
  "28621": { state: "NC", city: "ELKIN" },
  "28622": { state: "NC", city: "ELK PARK" },
  "28623": { state: "NC", city: "ENNICE" },
  "28624": { state: "NC", city: "FERGUSON" },
  "28625": { state: "NC", city: "STATESVILLE" },
  "28626": { state: "NC", city: "FLEETWOOD" },
  "28627": { state: "NC", city: "GLADE VALLEY" },
  "28628": { state: "NC", city: "GLEN ALPINE" },
  "28629": { state: "NC", city: "GLENDALE SPRINGS" },
  "28630": { state: "NC", city: "GRANITE FALLS" },
  "28631": { state: "NC", city: "GRASSY CREEK" },
  "28633": { state: "NC", city: "LENOIR" },
  "28634": { state: "NC", city: "HARMONY" },
  "28635": { state: "NC", city: "HAYS" },
  "28636": { state: "NC", city: "HIDDENITE" },
  "28637": { state: "NC", city: "HILDEBRAN" },
  "28638": { state: "NC", city: "HUDSON" },
  "28640": { state: "NC", city: "JEFFERSON" },
  "28641": { state: "NC", city: "JONAS RIDGE" },
  "28642": { state: "NC", city: "JONESVILLE" },
  "28643": { state: "NC", city: "LANSING" },
  "28644": { state: "NC", city: "LAUREL SPRINGS" },
  "28645": { state: "NC", city: "LENOIR" },
  "28646": { state: "NC", city: "LINVILLE" },
  "28647": { state: "NC", city: "LINVILLE FALLS" },
  "28649": { state: "NC", city: "MC GRADY" },
  "28650": { state: "NC", city: "MAIDEN" },
  "28651": { state: "NC", city: "MILLERS CREEK" },
  "28652": { state: "NC", city: "MINNEAPOLIS" },
  "28653": { state: "NC", city: "MONTEZUMA" },
  "28654": { state: "NC", city: "MORAVIAN FALLS" },
  "28655": { state: "NC", city: "MORGANTON" },
  "28656": { state: "NC", city: "NORTH WILKESBORO" },
  "28657": { state: "NC", city: "NEWLAND" },
  "28658": { state: "NC", city: "NEWTON" },
  "28659": { state: "NC", city: "NORTH WILKESBORO" },
  "28660": { state: "NC", city: "OLIN" },
  "28661": { state: "NC", city: "PATTERSON" },
  "28662": { state: "NC", city: "PINEOLA" },
  "28663": { state: "NC", city: "PINEY CREEK" },
  "28664": { state: "NC", city: "PLUMTREE" },
  "28665": { state: "NC", city: "PURLEAR" },
  "28666": { state: "NC", city: "ICARD" },
  "28667": { state: "NC", city: "RHODHISS" },
  "28668": { state: "NC", city: "ROARING GAP" },
  "28669": { state: "NC", city: "ROARING RIVER" },
  "28670": { state: "NC", city: "RONDA" },
  "28671": { state: "NC", city: "RUTHERFORD COLLEGE" },
  "28672": { state: "NC", city: "SCOTTVILLE" },
  "28673": { state: "NC", city: "SHERRILLS FORD" },
  "28675": { state: "NC", city: "SPARTA" },
  "28676": { state: "NC", city: "STATE ROAD" },
  "28677": { state: "NC", city: "STATESVILLE" },
  "28678": { state: "NC", city: "STONY POINT" },
  "28679": { state: "NC", city: "SUGAR GROVE" },
  "28680": { state: "NC", city: "MORGANTON" },
  "28681": { state: "NC", city: "TAYLORSVILLE" },
  "28682": { state: "NC", city: "TERRELL" },
  "28683": { state: "NC", city: "THURMOND" },
  "28684": { state: "NC", city: "TODD" },
  "28685": { state: "NC", city: "TRAPHILL" },
  "28687": { state: "NC", city: "STATESVILLE" },
  "28688": { state: "NC", city: "TURNERSBURG" },
  "28689": { state: "NC", city: "UNION GROVE" },
  "28690": { state: "NC", city: "VALDESE" },
  "28691": { state: "NC", city: "VALLE CRUCIS" },
  "28692": { state: "NC", city: "VILAS" },
  "28693": { state: "NC", city: "WARRENSVILLE" },
  "28694": { state: "NC", city: "WEST JEFFERSON" },
  "28697": { state: "NC", city: "WILKESBORO" },
  "28698": { state: "NC", city: "ZIONVILLE" },
  "28699": { state: "NC", city: "SCOTTS" },
  "28701": { state: "NC", city: "ALEXANDER" },
  "28702": { state: "NC", city: "ALMOND" },
  "28704": { state: "NC", city: "ARDEN" },
  "28705": { state: "NC", city: "BAKERSVILLE" },
  "28707": { state: "NC", city: "BALSAM" },
  "28708": { state: "NC", city: "BALSAM GROVE" },
  "28709": { state: "NC", city: "BARNARDSVILLE" },
  "28710": { state: "NC", city: "BAT CAVE" },
  "28711": { state: "NC", city: "BLACK MOUNTAIN" },
  "28712": { state: "NC", city: "BREVARD" },
  "28713": { state: "NC", city: "BRYSON CITY" },
  "28714": { state: "NC", city: "BURNSVILLE" },
  "28715": { state: "NC", city: "CANDLER" },
  "28716": { state: "NC", city: "CANTON" },
  "28717": { state: "NC", city: "CASHIERS" },
  "28718": { state: "NC", city: "CEDAR MOUNTAIN" },
  "28719": { state: "NC", city: "CHEROKEE" },
  "28720": { state: "NC", city: "CHIMNEY ROCK" },
  "28721": { state: "NC", city: "CLYDE" },
  "28722": { state: "NC", city: "COLUMBUS" },
  "28723": { state: "NC", city: "CULLOWHEE" },
  "28724": { state: "NC", city: "DANA" },
  "28725": { state: "NC", city: "DILLSBORO" },
  "28726": { state: "NC", city: "EAST FLAT ROCK" },
  "28727": { state: "NC", city: "EDNEYVILLE" },
  "28728": { state: "NC", city: "ENKA" },
  "28729": { state: "NC", city: "ETOWAH" },
  "28730": { state: "NC", city: "FAIRVIEW" },
  "28731": { state: "NC", city: "FLAT ROCK" },
  "28732": { state: "NC", city: "FLETCHER" },
  "28733": { state: "NC", city: "FONTANA DAM" },
  "28734": { state: "NC", city: "FRANKLIN" },
  "28735": { state: "NC", city: "GERTON" },
  "28736": { state: "NC", city: "GLENVILLE" },
  "28737": { state: "NC", city: "GLENWOOD" },
  "28738": { state: "NC", city: "HAZELWOOD" },
  "28739": { state: "NC", city: "HENDERSONVILLE" },
  "28740": { state: "NC", city: "GREEN MOUNTAIN" },
  "28741": { state: "NC", city: "HIGHLANDS" },
  "28742": { state: "NC", city: "HORSE SHOE" },
  "28743": { state: "NC", city: "HOT SPRINGS" },
  "28744": { state: "NC", city: "FRANKLIN" },
  "28745": { state: "NC", city: "LAKE JUNALUSKA" },
  "28746": { state: "NC", city: "LAKE LURE" },
  "28747": { state: "NC", city: "LAKE TOXAWAY" },
  "28748": { state: "NC", city: "LEICESTER" },
  "28749": { state: "NC", city: "LITTLE SWITZERLAND" },
  "28750": { state: "NC", city: "LYNN" },
  "28751": { state: "NC", city: "MAGGIE VALLEY" },
  "28752": { state: "NC", city: "MARION" },
  "28753": { state: "NC", city: "MARSHALL" },
  "28754": { state: "NC", city: "MARS HILL" },
  "28755": { state: "NC", city: "MICAVILLE" },
  "28756": { state: "NC", city: "MILL SPRING" },
  "28757": { state: "NC", city: "MONTREAT" },
  "28758": { state: "NC", city: "MOUNTAIN HOME" },
  "28759": { state: "NC", city: "MILLS RIVER" },
  "28760": { state: "NC", city: "NAPLES" },
  "28761": { state: "NC", city: "NEBO" },
  "28762": { state: "NC", city: "OLD FORT" },
  "28763": { state: "NC", city: "OTTO" },
  "28765": { state: "NC", city: "PENLAND" },
  "28766": { state: "NC", city: "PENROSE" },
  "28768": { state: "NC", city: "PISGAH FOREST" },
  "28770": { state: "NC", city: "RIDGECREST" },
  "28771": { state: "NC", city: "ROBBINSVILLE" },
  "28772": { state: "NC", city: "ROSMAN" },
  "28773": { state: "NC", city: "SALUDA" },
  "28774": { state: "NC", city: "SAPPHIRE" },
  "28775": { state: "NC", city: "SCALY MOUNTAIN" },
  "28776": { state: "NC", city: "SKYLAND" },
  "28777": { state: "NC", city: "SPRUCE PINE" },
  "28778": { state: "NC", city: "SWANNANOA" },
  "28779": { state: "NC", city: "SYLVA" },
  "28781": { state: "NC", city: "TOPTON" },
  "28782": { state: "NC", city: "TRYON" },
  "28783": { state: "NC", city: "TUCKASEGEE" },
  "28784": { state: "NC", city: "TUXEDO" },
  "28785": { state: "NC", city: "WAYNESVILLE" },
  "28786": { state: "NC", city: "WAYNESVILLE" },
  "28787": { state: "NC", city: "WEAVERVILLE" },
  "28788": { state: "NC", city: "WEBSTER" },
  "28789": { state: "NC", city: "WHITTIER" },
  "28790": { state: "NC", city: "ZIRCONIA" },
  "28791": { state: "NC", city: "HENDERSONVILLE" },
  "28792": { state: "NC", city: "HENDERSONVILLE" },
  "28793": { state: "NC", city: "HENDERSONVILLE" },
  "28801": { state: "NC", city: "ASHEVILLE" },
  "28802": { state: "NC", city: "ASHEVILLE" },
  "28803": { state: "NC", city: "ASHEVILLE" },
  "28804": { state: "NC", city: "ASHEVILLE" },
  "28805": { state: "NC", city: "ASHEVILLE" },
  "28806": { state: "NC", city: "ASHEVILLE" },
  "28810": { state: "NC", city: "ASHEVILLE" },
  "28813": { state: "NC", city: "ASHEVILLE" },
  "28814": { state: "NC", city: "ASHEVILLE" },
  "28815": { state: "NC", city: "ASHEVILLE" },
  "28816": { state: "NC", city: "ASHEVILLE" },
  "28901": { state: "NC", city: "ANDREWS" },
  "28902": { state: "NC", city: "BRASSTOWN" },
  "28903": { state: "NC", city: "CULBERSON" },
  "28904": { state: "NC", city: "HAYESVILLE" },
  "28905": { state: "NC", city: "MARBLE" },
  "28906": { state: "NC", city: "MURPHY" },
  "28909": { state: "NC", city: "WARNE" },
  "29001": { state: "SC", city: "ALCOLU" },
  "29002": { state: "SC", city: "BALLENTINE" },
  "29003": { state: "SC", city: "BAMBERG" },
  "29006": { state: "SC", city: "BATESBURG" },
  "29009": { state: "SC", city: "BETHUNE" },
  "29010": { state: "SC", city: "BISHOPVILLE" },
  "29014": { state: "SC", city: "BLACKSTOCK" },
  "29015": { state: "SC", city: "BLAIR" },
  "29016": { state: "SC", city: "BLYTHEWOOD" },
  "29018": { state: "SC", city: "BOWMAN" },
  "29020": { state: "SC", city: "CAMDEN" },
  "29021": { state: "SC", city: "CAMDEN" },
  "29030": { state: "SC", city: "CAMERON" },
  "29031": { state: "SC", city: "CARLISLE" },
  "29032": { state: "SC", city: "CASSATT" },
  "29033": { state: "SC", city: "CAYCE" },
  "29036": { state: "SC", city: "CHAPIN" },
  "29037": { state: "SC", city: "CHAPPELLS" },
  "29038": { state: "SC", city: "COPE" },
  "29039": { state: "SC", city: "CORDOVA" },
  "29040": { state: "SC", city: "DALZELL" },
  "29041": { state: "SC", city: "DAVIS STATION" },
  "29042": { state: "SC", city: "DENMARK" },
  "29044": { state: "SC", city: "EASTOVER" },
  "29045": { state: "SC", city: "ELGIN" },
  "29046": { state: "SC", city: "ELLIOTT" },
  "29047": { state: "SC", city: "ELLOREE" },
  "29048": { state: "SC", city: "EUTAWVILLE" },
  "29051": { state: "SC", city: "GABLE" },
  "29052": { state: "SC", city: "GADSDEN" },
  "29053": { state: "SC", city: "GASTON" },
  "29054": { state: "SC", city: "GILBERT" },
  "29055": { state: "SC", city: "GREAT FALLS" },
  "29056": { state: "SC", city: "GREELEYVILLE" },
  "29058": { state: "SC", city: "HEATH SPRINGS" },
  "29059": { state: "SC", city: "HOLLY HILL" },
  "29061": { state: "SC", city: "HOPKINS" },
  "29062": { state: "SC", city: "HORATIO" },
  "29063": { state: "SC", city: "IRMO" },
  "29065": { state: "SC", city: "JENKINSVILLE" },
  "29067": { state: "SC", city: "KERSHAW" },
  "29069": { state: "SC", city: "LAMAR" },
  "29070": { state: "SC", city: "LEESVILLE" },
  "29071": { state: "SC", city: "LEXINGTON" },
  "29072": { state: "SC", city: "LEXINGTON" },
  "29073": { state: "SC", city: "LEXINGTON" },
  "29074": { state: "SC", city: "LIBERTY HILL" },
  "29075": { state: "SC", city: "LITTLE MOUNTAIN" },
  "29078": { state: "SC", city: "LUGOFF" },
  "29079": { state: "SC", city: "LYDIA" },
  "29080": { state: "SC", city: "LYNCHBURG" },
  "29081": { state: "SC", city: "EHRHARDT" },
  "29082": { state: "SC", city: "LODGE" },
  "29101": { state: "SC", city: "MC BEE" },
  "29102": { state: "SC", city: "MANNING" },
  "29104": { state: "SC", city: "MAYESVILLE" },
  "29105": { state: "SC", city: "MONETTA" },
  "29107": { state: "SC", city: "NEESES" },
  "29108": { state: "SC", city: "NEWBERRY" },
  "29111": { state: "SC", city: "NEW ZION" },
  "29112": { state: "SC", city: "NORTH" },
  "29113": { state: "SC", city: "NORWAY" },
  "29114": { state: "SC", city: "OLANTA" },
  "29115": { state: "SC", city: "ORANGEBURG" },
  "29116": { state: "SC", city: "ORANGEBURG" },
  "29117": { state: "SC", city: "ORANGEBURG" },
  "29118": { state: "SC", city: "ORANGEBURG" },
  "29122": { state: "SC", city: "PEAK" },
  "29123": { state: "SC", city: "PELION" },
  "29125": { state: "SC", city: "PINEWOOD" },
  "29126": { state: "SC", city: "POMARIA" },
  "29127": { state: "SC", city: "PROSPERITY" },
  "29128": { state: "SC", city: "REMBERT" },
  "29129": { state: "SC", city: "RIDGE SPRING" },
  "29130": { state: "SC", city: "RIDGEWAY" },
  "29132": { state: "SC", city: "RION" },
  "29133": { state: "SC", city: "ROWESVILLE" },
  "29135": { state: "SC", city: "SAINT MATTHEWS" },
  "29137": { state: "SC", city: "SALLEY" },
  "29138": { state: "SC", city: "SALUDA" },
  "29142": { state: "SC", city: "SANTEE" },
  "29143": { state: "SC", city: "SARDINIA" },
  "29145": { state: "SC", city: "SILVERSTREET" },
  "29146": { state: "SC", city: "SPRINGFIELD" },
  "29147": { state: "SC", city: "STATE PARK" },
  "29148": { state: "SC", city: "SUMMERTON" },
  "29150": { state: "SC", city: "SUMTER" },
  "29151": { state: "SC", city: "SUMTER" },
  "29152": { state: "SC", city: "SHAW AFB" },
  "29153": { state: "SC", city: "SUMTER" },
  "29154": { state: "SC", city: "SUMTER" },
  "29160": { state: "SC", city: "SWANSEA" },
  "29161": { state: "SC", city: "TIMMONSVILLE" },
  "29162": { state: "SC", city: "TURBEVILLE" },
  "29163": { state: "SC", city: "VANCE" },
  "29164": { state: "SC", city: "WAGENER" },
  "29166": { state: "SC", city: "WARD" },
  "29168": { state: "SC", city: "WEDGEFIELD" },
  "29169": { state: "SC", city: "WEST COLUMBIA" },
  "29170": { state: "SC", city: "WEST COLUMBIA" },
  "29171": { state: "SC", city: "WEST COLUMBIA" },
  "29172": { state: "SC", city: "WEST COLUMBIA" },
  "29175": { state: "SC", city: "WESTVILLE" },
  "29177": { state: "SC", city: "WHITE ROCK" },
  "29178": { state: "SC", city: "WHITMIRE" },
  "29180": { state: "SC", city: "WINNSBORO" },
  "29201": { state: "SC", city: "COLUMBIA" },
  "29202": { state: "SC", city: "COLUMBIA" },
  "29203": { state: "SC", city: "COLUMBIA" },
  "29204": { state: "SC", city: "COLUMBIA" },
  "29205": { state: "SC", city: "COLUMBIA" },
  "29206": { state: "SC", city: "COLUMBIA" },
  "29207": { state: "SC", city: "COLUMBIA" },
  "29208": { state: "SC", city: "COLUMBIA" },
  "29209": { state: "SC", city: "COLUMBIA" },
  "29210": { state: "SC", city: "COLUMBIA" },
  "29211": { state: "SC", city: "COLUMBIA" },
  "29212": { state: "SC", city: "COLUMBIA" },
  "29214": { state: "SC", city: "COLUMBIA" },
  "29215": { state: "SC", city: "COLUMBIA" },
  "29216": { state: "SC", city: "COLUMBIA" },
  "29217": { state: "SC", city: "COLUMBIA" },
  "29218": { state: "SC", city: "COLUMBIA" },
  "29219": { state: "SC", city: "COLUMBIA" },
  "29220": { state: "SC", city: "COLUMBIA" },
  "29221": { state: "SC", city: "COLUMBIA" },
  "29222": { state: "SC", city: "COLUMBIA" },
  "29223": { state: "SC", city: "COLUMBIA" },
  "29224": { state: "SC", city: "COLUMBIA" },
  "29225": { state: "SC", city: "COLUMBIA" },
  "29226": { state: "SC", city: "COLUMBIA" },
  "29227": { state: "SC", city: "COLUMBIA" },
  "29228": { state: "SC", city: "COLUMBIA" },
  "29229": { state: "SC", city: "COLUMBIA" },
  "29230": { state: "SC", city: "COLUMBIA" },
  "29240": { state: "SC", city: "COLUMBIA" },
  "29250": { state: "SC", city: "COLUMBIA" },
  "29260": { state: "SC", city: "COLUMBIA" },
  "29290": { state: "SC", city: "COLUMBIA" },
  "29292": { state: "SC", city: "COLUMBIA" },
  "29301": { state: "SC", city: "SPARTANBURG" },
  "29302": { state: "SC", city: "SPARTANBURG" },
  "29303": { state: "SC", city: "SPARTANBURG" },
  "29304": { state: "SC", city: "SPARTANBURG" },
  "29305": { state: "SC", city: "SPARTANBURG" },
  "29306": { state: "SC", city: "SPARTANBURG" },
  "29307": { state: "SC", city: "SPARTANBURG" },
  "29316": { state: "SC", city: "BOILING SPRINGS" },
  "29319": { state: "SC", city: "SPARTANBURG" },
  "29320": { state: "SC", city: "ARCADIA" },
  "29321": { state: "SC", city: "BUFFALO" },
  "29322": { state: "SC", city: "CAMPOBELLO" },
  "29323": { state: "SC", city: "CHESNEE" },
  "29324": { state: "SC", city: "CLIFTON" },
  "29325": { state: "SC", city: "CLINTON" },
  "29329": { state: "SC", city: "CONVERSE" },
  "29330": { state: "SC", city: "COWPENS" },
  "29331": { state: "SC", city: "CROSS ANCHOR" },
  "29332": { state: "SC", city: "CROSS HILL" },
  "29333": { state: "SC", city: "DRAYTON" },
  "29334": { state: "SC", city: "DUNCAN" },
  "29335": { state: "SC", city: "ENOREE" },
  "29336": { state: "SC", city: "FAIRFOREST" },
  "29338": { state: "SC", city: "FINGERVILLE" },
  "29340": { state: "SC", city: "GAFFNEY" },
  "29341": { state: "SC", city: "GAFFNEY" },
  "29342": { state: "SC", city: "GAFFNEY" },
  "29346": { state: "SC", city: "GLENDALE" },
  "29348": { state: "SC", city: "GRAMLING" },
  "29349": { state: "SC", city: "INMAN" },
  "29351": { state: "SC", city: "JOANNA" },
  "29353": { state: "SC", city: "JONESVILLE" },
  "29355": { state: "SC", city: "KINARDS" },
  "29356": { state: "SC", city: "LANDRUM" },
  "29360": { state: "SC", city: "LAURENS" },
  "29364": { state: "SC", city: "LOCKHART" },
  "29365": { state: "SC", city: "LYMAN" },
  "29368": { state: "SC", city: "MAYO" },
  "29369": { state: "SC", city: "MOORE" },
  "29370": { state: "SC", city: "MOUNTVILLE" },
  "29372": { state: "SC", city: "PACOLET" },
  "29373": { state: "SC", city: "PACOLET MILLS" },
  "29374": { state: "SC", city: "PAULINE" },
  "29375": { state: "SC", city: "REIDVILLE" },
  "29376": { state: "SC", city: "ROEBUCK" },
  "29377": { state: "SC", city: "STARTEX" },
  "29378": { state: "SC", city: "UNA" },
  "29379": { state: "SC", city: "UNION" },
  "29384": { state: "SC", city: "WATERLOO" },
  "29385": { state: "SC", city: "WELLFORD" },
  "29386": { state: "SC", city: "WHITE STONE" },
  "29388": { state: "SC", city: "WOODRUFF" },
  "29395": { state: "SC", city: "JONESVILLE" },
  "29401": { state: "SC", city: "CHARLESTON" },
  "29402": { state: "SC", city: "CHARLESTON" },
  "29403": { state: "SC", city: "CHARLESTON" },
  "29404": { state: "SC", city: "CHARLESTON AFB" },
  "29405": { state: "SC", city: "NORTH CHARLESTON" },
  "29406": { state: "SC", city: "CHARLESTON" },
  "29407": { state: "SC", city: "CHARLESTON" },
  "29409": { state: "SC", city: "CHARLESTON" },
  "29410": { state: "SC", city: "HANAHAN" },
  "29412": { state: "SC", city: "CHARLESTON" },
  "29413": { state: "SC", city: "CHARLESTON" },
  "29414": { state: "SC", city: "CHARLESTON" },
  "29415": { state: "SC", city: "NORTH CHARLESTON" },
  "29416": { state: "SC", city: "CHARLESTON" },
  "29417": { state: "SC", city: "CHARLESTON" },
  "29418": { state: "SC", city: "NORTH CHARLESTON" },
  "29419": { state: "SC", city: "NORTH CHARLESTON" },
  "29420": { state: "SC", city: "NORTH CHARLESTON" },
  "29422": { state: "SC", city: "CHARLESTON" },
  "29423": { state: "SC", city: "CHARLESTON" },
  "29424": { state: "SC", city: "CHARLESTON" },
  "29425": { state: "SC", city: "CHARLESTON" },
  "29426": { state: "SC", city: "ADAMS RUN" },
  "29429": { state: "SC", city: "AWENDAW" },
  "29431": { state: "SC", city: "BONNEAU" },
  "29432": { state: "SC", city: "BRANCHVILLE" },
  "29433": { state: "SC", city: "CANADYS" },
  "29434": { state: "SC", city: "CORDESVILLE" },
  "29435": { state: "SC", city: "COTTAGEVILLE" },
  "29436": { state: "SC", city: "CROSS" },
  "29437": { state: "SC", city: "DORCHESTER" },
  "29438": { state: "SC", city: "EDISTO ISLAND" },
  "29439": { state: "SC", city: "FOLLY BEACH" },
  "29440": { state: "SC", city: "GEORGETOWN" },
  "29442": { state: "SC", city: "GEORGETOWN" },
  "29445": { state: "SC", city: "GOOSE CREEK" },
  "29446": { state: "SC", city: "GREEN POND" },
  "29447": { state: "SC", city: "GROVER" },
  "29448": { state: "SC", city: "HARLEYVILLE" },
  "29449": { state: "SC", city: "HOLLYWOOD" },
  "29450": { state: "SC", city: "HUGER" },
  "29451": { state: "SC", city: "ISLE OF PALMS" },
  "29452": { state: "SC", city: "JACKSONBORO" },
  "29453": { state: "SC", city: "JAMESTOWN" },
  "29455": { state: "SC", city: "JOHNS ISLAND" },
  "29456": { state: "SC", city: "LADSON" },
  "29457": { state: "SC", city: "JOHNS ISLAND" },
  "29458": { state: "SC", city: "MC CLELLANVILLE" },
  "29461": { state: "SC", city: "MONCKS CORNER" },
  "29464": { state: "SC", city: "MOUNT PLEASANT" },
  "29465": { state: "SC", city: "MOUNT PLEASANT" },
  "29466": { state: "SC", city: "MOUNT PLEASANT" },
  "29468": { state: "SC", city: "PINEVILLE" },
  "29469": { state: "SC", city: "PINOPOLIS" },
  "29470": { state: "SC", city: "RAVENEL" },
  "29471": { state: "SC", city: "REEVESVILLE" },
  "29472": { state: "SC", city: "RIDGEVILLE" },
  "29474": { state: "SC", city: "ROUND O" },
  "29475": { state: "SC", city: "RUFFIN" },
  "29476": { state: "SC", city: "RUSSELLVILLE" },
  "29477": { state: "SC", city: "SAINT GEORGE" },
  "29479": { state: "SC", city: "SAINT STEPHEN" },
  "29481": { state: "SC", city: "SMOAKS" },
  "29482": { state: "SC", city: "SULLIVANS ISLAND" },
  "29483": { state: "SC", city: "SUMMERVILLE" },
  "29484": { state: "SC", city: "SUMMERVILLE" },
  "29485": { state: "SC", city: "SUMMERVILLE" },
  "29486": { state: "SC", city: "SUMMERVILLE" },
  "29487": { state: "SC", city: "WADMALAW ISLAND" },
  "29488": { state: "SC", city: "WALTERBORO" },
  "29492": { state: "SC", city: "CHARLESTON" },
  "29493": { state: "SC", city: "WILLIAMS" },
  "29501": { state: "SC", city: "FLORENCE" },
  "29502": { state: "SC", city: "FLORENCE" },
  "29503": { state: "SC", city: "FLORENCE" },
  "29504": { state: "SC", city: "FLORENCE" },
  "29505": { state: "SC", city: "FLORENCE" },
  "29506": { state: "SC", city: "FLORENCE" },
  "29510": { state: "SC", city: "ANDREWS" },
  "29511": { state: "SC", city: "AYNOR" },
  "29512": { state: "SC", city: "BENNETTSVILLE" },
  "29516": { state: "SC", city: "BLENHEIM" },
  "29518": { state: "SC", city: "CADES" },
  "29519": { state: "SC", city: "CENTENARY" },
  "29520": { state: "SC", city: "CHERAW" },
  "29525": { state: "SC", city: "CLIO" },
  "29526": { state: "SC", city: "CONWAY" },
  "29527": { state: "SC", city: "CONWAY" },
  "29528": { state: "SC", city: "CONWAY" },
  "29530": { state: "SC", city: "COWARD" },
  "29532": { state: "SC", city: "DARLINGTON" },
  "29536": { state: "SC", city: "DILLON" },
  "29540": { state: "SC", city: "DARLINGTON" },
  "29541": { state: "SC", city: "EFFINGHAM" },
  "29543": { state: "SC", city: "FORK" },
  "29544": { state: "SC", city: "GALIVANTS FERRY" },
  "29545": { state: "SC", city: "GREEN SEA" },
  "29546": { state: "SC", city: "GRESHAM" },
  "29547": { state: "SC", city: "HAMER" },
  "29550": { state: "SC", city: "HARTSVILLE" },
  "29551": { state: "SC", city: "HARTSVILLE" },
  "29554": { state: "SC", city: "HEMINGWAY" },
  "29555": { state: "SC", city: "JOHNSONVILLE" },
  "29556": { state: "SC", city: "KINGSTREE" },
  "29560": { state: "SC", city: "LAKE CITY" },
  "29563": { state: "SC", city: "LAKE VIEW" },
  "29564": { state: "SC", city: "LANE" },
  "29565": { state: "SC", city: "LATTA" },
  "29566": { state: "SC", city: "LITTLE RIVER" },
  "29567": { state: "SC", city: "LITTLE ROCK" },
  "29568": { state: "SC", city: "LONGS" },
  "29569": { state: "SC", city: "LORIS" },
  "29570": { state: "SC", city: "MC COLL" },
  "29571": { state: "SC", city: "MARION" },
  "29572": { state: "SC", city: "MYRTLE BEACH" },
  "29574": { state: "SC", city: "MULLINS" },
  "29575": { state: "SC", city: "MYRTLE BEACH" },
  "29576": { state: "SC", city: "MURRELLS INLET" },
  "29577": { state: "SC", city: "MYRTLE BEACH" },
  "29578": { state: "SC", city: "MYRTLE BEACH" },
  "29579": { state: "SC", city: "MYRTLE BEACH" },
  "29580": { state: "SC", city: "NESMITH" },
  "29581": { state: "SC", city: "NICHOLS" },
  "29582": { state: "SC", city: "NORTH MYRTLE BEACH" },
  "29583": { state: "SC", city: "PAMPLICO" },
  "29584": { state: "SC", city: "PATRICK" },
  "29585": { state: "SC", city: "PAWLEYS ISLAND" },
  "29587": { state: "SC", city: "MYRTLE BEACH" },
  "29588": { state: "SC", city: "MYRTLE BEACH" },
  "29589": { state: "SC", city: "RAINS" },
  "29590": { state: "SC", city: "SALTERS" },
  "29591": { state: "SC", city: "SCRANTON" },
  "29592": { state: "SC", city: "SELLERS" },
  "29593": { state: "SC", city: "SOCIETY HILL" },
  "29594": { state: "SC", city: "TATUM" },
  "29596": { state: "SC", city: "WALLACE" },
  "29597": { state: "SC", city: "NORTH MYRTLE BEACH" },
  "29598": { state: "SC", city: "NORTH MYRTLE BEACH" },
  "29601": { state: "SC", city: "GREENVILLE" },
  "29602": { state: "SC", city: "GREENVILLE" },
  "29603": { state: "SC", city: "GREENVILLE" },
  "29604": { state: "SC", city: "GREENVILLE" },
  "29605": { state: "SC", city: "GREENVILLE" },
  "29606": { state: "SC", city: "GREENVILLE" },
  "29607": { state: "SC", city: "GREENVILLE" },
  "29608": { state: "SC", city: "GREENVILLE" },
  "29609": { state: "SC", city: "GREENVILLE" },
  "29610": { state: "SC", city: "GREENVILLE" },
  "29611": { state: "SC", city: "GREENVILLE" },
  "29612": { state: "SC", city: "GREENVILLE" },
  "29613": { state: "SC", city: "GREENVILLE" },
  "29614": { state: "SC", city: "GREENVILLE" },
  "29615": { state: "SC", city: "GREENVILLE" },
  "29616": { state: "SC", city: "GREENVILLE" },
  "29617": { state: "SC", city: "GREENVILLE" },
  "29620": { state: "SC", city: "ABBEVILLE" },
  "29621": { state: "SC", city: "ANDERSON" },
  "29622": { state: "SC", city: "ANDERSON" },
  "29623": { state: "SC", city: "ANDERSON" },
  "29624": { state: "SC", city: "ANDERSON" },
  "29625": { state: "SC", city: "ANDERSON" },
  "29626": { state: "SC", city: "ANDERSON" },
  "29627": { state: "SC", city: "BELTON" },
  "29628": { state: "SC", city: "CALHOUN FALLS" },
  "29630": { state: "SC", city: "CENTRAL" },
  "29631": { state: "SC", city: "CLEMSON" },
  "29632": { state: "SC", city: "CLEMSON" },
  "29633": { state: "SC", city: "CLEMSON" },
  "29634": { state: "SC", city: "CLEMSON" },
  "29635": { state: "SC", city: "CLEVELAND" },
  "29636": { state: "SC", city: "CONESTEE" },
  "29638": { state: "SC", city: "DONALDS" },
  "29639": { state: "SC", city: "DUE WEST" },
  "29640": { state: "SC", city: "EASLEY" },
  "29641": { state: "SC", city: "EASLEY" },
  "29642": { state: "SC", city: "EASLEY" },
  "29643": { state: "SC", city: "FAIR PLAY" },
  "29644": { state: "SC", city: "FOUNTAIN INN" },
  "29645": { state: "SC", city: "GRAY COURT" },
  "29646": { state: "SC", city: "GREENWOOD" },
  "29647": { state: "SC", city: "GREENWOOD" },
  "29648": { state: "SC", city: "GREENWOOD" },
  "29649": { state: "SC", city: "GREENWOOD" },
  "29650": { state: "SC", city: "GREER" },
  "29651": { state: "SC", city: "GREER" },
  "29652": { state: "SC", city: "GREER" },
  "29653": { state: "SC", city: "HODGES" },
  "29654": { state: "SC", city: "HONEA PATH" },
  "29655": { state: "SC", city: "IVA" },
  "29656": { state: "SC", city: "LA FRANCE" },
  "29657": { state: "SC", city: "LIBERTY" },
  "29658": { state: "SC", city: "LONG CREEK" },
  "29659": { state: "SC", city: "LOWNDESVILLE" },
  "29661": { state: "SC", city: "MARIETTA" },
  "29662": { state: "SC", city: "MAULDIN" },
  "29664": { state: "SC", city: "MOUNTAIN REST" },
  "29665": { state: "SC", city: "NEWRY" },
  "29666": { state: "SC", city: "NINETY SIX" },
  "29667": { state: "SC", city: "NORRIS" },
  "29669": { state: "SC", city: "PELZER" },
  "29670": { state: "SC", city: "PENDLETON" },
  "29671": { state: "SC", city: "PICKENS" },
  "29672": { state: "SC", city: "SENECA" },
  "29673": { state: "SC", city: "PIEDMONT" },
  "29675": { state: "SC", city: "RICHLAND" },
  "29676": { state: "SC", city: "SALEM" },
  "29677": { state: "SC", city: "SANDY SPRINGS" },
  "29678": { state: "SC", city: "SENECA" },
  "29679": { state: "SC", city: "SENECA" },
  "29680": { state: "SC", city: "SIMPSONVILLE" },
  "29681": { state: "SC", city: "SIMPSONVILLE" },
  "29682": { state: "SC", city: "SIX MILE" },
  "29683": { state: "SC", city: "SLATER" },
  "29684": { state: "SC", city: "STARR" },
  "29685": { state: "SC", city: "SUNSET" },
  "29686": { state: "SC", city: "TAMASSEE" },
  "29687": { state: "SC", city: "TAYLORS" },
  "29688": { state: "SC", city: "TIGERVILLE" },
  "29689": { state: "SC", city: "TOWNVILLE" },
  "29690": { state: "SC", city: "TRAVELERS REST" },
  "29691": { state: "SC", city: "WALHALLA" },
  "29692": { state: "SC", city: "WARE SHOALS" },
  "29693": { state: "SC", city: "WESTMINSTER" },
  "29695": { state: "SC", city: "HODGES" },
  "29696": { state: "SC", city: "WEST UNION" },
  "29697": { state: "SC", city: "WILLIAMSTON" },
  "29702": { state: "SC", city: "BLACKSBURG" },
  "29703": { state: "SC", city: "BOWLING GREEN" },
  "29704": { state: "SC", city: "CATAWBA" },
  "29706": { state: "SC", city: "CHESTER" },
  "29707": { state: "SC", city: "FORT MILL" },
  "29708": { state: "SC", city: "FORT MILL" },
  "29709": { state: "SC", city: "CHESTERFIELD" },
  "29710": { state: "SC", city: "CLOVER" },
  "29712": { state: "SC", city: "EDGEMOOR" },
  "29714": { state: "SC", city: "FORT LAWN" },
  "29715": { state: "SC", city: "FORT MILL" },
  "29716": { state: "SC", city: "FORT MILL" },
  "29717": { state: "SC", city: "HICKORY GROVE" },
  "29718": { state: "SC", city: "JEFFERSON" },
  "29720": { state: "SC", city: "LANCASTER" },
  "29721": { state: "SC", city: "LANCASTER" },
  "29722": { state: "SC", city: "LANCASTER" },
  "29724": { state: "SC", city: "LANDO" },
  "29726": { state: "SC", city: "MC CONNELLS" },
  "29727": { state: "SC", city: "MOUNT CROGHAN" },
  "29728": { state: "SC", city: "PAGELAND" },
  "29729": { state: "SC", city: "RICHBURG" },
  "29730": { state: "SC", city: "ROCK HILL" },
  "29731": { state: "SC", city: "ROCK HILL" },
  "29732": { state: "SC", city: "ROCK HILL" },
  "29733": { state: "SC", city: "ROCK HILL" },
  "29734": { state: "SC", city: "ROCK HILL" },
  "29741": { state: "SC", city: "RUBY" },
  "29742": { state: "SC", city: "SHARON" },
  "29743": { state: "SC", city: "SMYRNA" },
  "29744": { state: "SC", city: "VAN WYCK" },
  "29745": { state: "SC", city: "YORK" },
  "29801": { state: "SC", city: "AIKEN" },
  "29802": { state: "SC", city: "AIKEN" },
  "29803": { state: "SC", city: "AIKEN" },
  "29804": { state: "SC", city: "AIKEN" },
  "29805": { state: "SC", city: "AIKEN" },
  "29808": { state: "SC", city: "AIKEN" },
  "29809": { state: "SC", city: "NEW ELLENTON" },
  "29810": { state: "SC", city: "ALLENDALE" },
  "29812": { state: "SC", city: "BARNWELL" },
  "29813": { state: "SC", city: "HILDA" },
  "29816": { state: "SC", city: "BATH" },
  "29817": { state: "SC", city: "BLACKVILLE" },
  "29819": { state: "SC", city: "BRADLEY" },
  "29821": { state: "SC", city: "CLARKS HILL" },
  "29822": { state: "SC", city: "CLEARWATER" },
  "29824": { state: "SC", city: "EDGEFIELD" },
  "29826": { state: "SC", city: "ELKO" },
  "29827": { state: "SC", city: "FAIRFAX" },
  "29828": { state: "SC", city: "GLOVERVILLE" },
  "29829": { state: "SC", city: "GRANITEVILLE" },
  "29831": { state: "SC", city: "JACKSON" },
  "29832": { state: "SC", city: "JOHNSTON" },
  "29834": { state: "SC", city: "LANGLEY" },
  "29835": { state: "SC", city: "MC CORMICK" },
  "29836": { state: "SC", city: "MARTIN" },
  "29838": { state: "SC", city: "MODOC" },
  "29839": { state: "SC", city: "MONTMORENCI" },
  "29840": { state: "SC", city: "MOUNT CARMEL" },
  "29841": { state: "SC", city: "NORTH AUGUSTA" },
  "29842": { state: "SC", city: "BEECH ISLAND" },
  "29843": { state: "SC", city: "OLAR" },
  "29844": { state: "SC", city: "PARKSVILLE" },
  "29845": { state: "SC", city: "PLUM BRANCH" },
  "29846": { state: "SC", city: "SYCAMORE" },
  "29847": { state: "SC", city: "TRENTON" },
  "29848": { state: "SC", city: "TROY" },
  "29849": { state: "SC", city: "ULMER" },
  "29850": { state: "SC", city: "VAUCLUSE" },
  "29851": { state: "SC", city: "WARRENVILLE" },
  "29853": { state: "SC", city: "WILLISTON" },
  "29856": { state: "SC", city: "WINDSOR" },
  "29860": { state: "SC", city: "NORTH AUGUSTA" },
  "29861": { state: "SC", city: "NORTH AUGUSTA" },
  "29899": { state: "SC", city: "MC CORMICK" },
  "29901": { state: "SC", city: "BEAUFORT" },
  "29902": { state: "SC", city: "BEAUFORT" },
  "29903": { state: "SC", city: "BEAUFORT" },
  "29904": { state: "SC", city: "BEAUFORT" },
  "29905": { state: "SC", city: "PARRIS ISLAND" },
  "29906": { state: "SC", city: "BEAUFORT" },
  "29907": { state: "SC", city: "BEAUFORT" },
  "29909": { state: "SC", city: "OKATIE" },
  "29910": { state: "SC", city: "BLUFFTON" },
  "29911": { state: "SC", city: "BRUNSON" },
  "29912": { state: "SC", city: "COOSAWHATCHIE" },
  "29913": { state: "SC", city: "CROCKETVILLE" },
  "29914": { state: "SC", city: "DALE" },
  "29915": { state: "SC", city: "DAUFUSKIE ISLAND" },
  "29916": { state: "SC", city: "EARLY BRANCH" },
  "29918": { state: "SC", city: "ESTILL" },
  "29920": { state: "SC", city: "SAINT HELENA ISLAND" },
  "29921": { state: "SC", city: "FURMAN" },
  "29922": { state: "SC", city: "GARNETT" },
  "29923": { state: "SC", city: "GIFFORD" },
  "29924": { state: "SC", city: "HAMPTON" },
  "29925": { state: "SC", city: "HILTON HEAD ISLAND" },
  "29926": { state: "SC", city: "HILTON HEAD ISLAND" },
  "29927": { state: "SC", city: "HARDEEVILLE" },
  "29928": { state: "SC", city: "HILTON HEAD ISLAND" },
  "29929": { state: "SC", city: "ISLANDTON" },
  "29931": { state: "SC", city: "LOBECO" },
  "29932": { state: "SC", city: "LURAY" },
  "29933": { state: "SC", city: "MILEY" },
  "29934": { state: "SC", city: "PINELAND" },
  "29935": { state: "SC", city: "PORT ROYAL" },
  "29936": { state: "SC", city: "RIDGELAND" },
  "29938": { state: "SC", city: "HILTON HEAD ISLAND" },
  "29939": { state: "SC", city: "SCOTIA" },
  "29940": { state: "SC", city: "SEABROOK" },
  "29941": { state: "SC", city: "SHELDON" },
  "29943": { state: "SC", city: "TILLMAN" },
  "29944": { state: "SC", city: "VARNVILLE" },
  "29945": { state: "SC", city: "YEMASSEE" },
  "30002": { state: "GA", city: "AVONDALE ESTATES" },
  "30003": { state: "GA", city: "NORCROSS" },
  "30004": { state: "GA", city: "ALPHARETTA" },
  "30005": { state: "GA", city: "ALPHARETTA" },
  "30006": { state: "GA", city: "MARIETTA" },
  "30007": { state: "GA", city: "MARIETTA" },
  "30008": { state: "GA", city: "MARIETTA" },
  "30009": { state: "GA", city: "ALPHARETTA" },
  "30010": { state: "GA", city: "NORCROSS" },
  "30011": { state: "GA", city: "AUBURN" },
  "30012": { state: "GA", city: "CONYERS" },
  "30013": { state: "GA", city: "CONYERS" },
  "30014": { state: "GA", city: "COVINGTON" },
  "30015": { state: "GA", city: "COVINGTON" },
  "30016": { state: "GA", city: "COVINGTON" },
  "30017": { state: "GA", city: "GRAYSON" },
  "30018": { state: "GA", city: "JERSEY" },
  "30019": { state: "GA", city: "DACULA" },
  "30021": { state: "GA", city: "CLARKSTON" },
  "30022": { state: "GA", city: "ALPHARETTA" },
  "30023": { state: "GA", city: "ALPHARETTA" },
  "30024": { state: "GA", city: "SUWANEE" },
  "30025": { state: "GA", city: "SOCIAL CIRCLE" },
  "30026": { state: "GA", city: "NORTH METRO" },
  "30028": { state: "GA", city: "CUMMING" },
  "30029": { state: "GA", city: "NORTH METRO" },
  "30030": { state: "GA", city: "DECATUR" },
  "30031": { state: "GA", city: "DECATUR" },
  "30032": { state: "GA", city: "DECATUR" },
  "30033": { state: "GA", city: "DECATUR" },
  "30034": { state: "GA", city: "DECATUR" },
  "30035": { state: "GA", city: "DECATUR" },
  "30036": { state: "GA", city: "DECATUR" },
  "30037": { state: "GA", city: "DECATUR" },
  "30038": { state: "GA", city: "LITHONIA" },
  "30039": { state: "GA", city: "SNELLVILLE" },
  "30040": { state: "GA", city: "CUMMING" },
  "30041": { state: "GA", city: "CUMMING" },
  "30042": { state: "GA", city: "LAWRENCEVILLE" },
  "30043": { state: "GA", city: "LAWRENCEVILLE" },
  "30044": { state: "GA", city: "LAWRENCEVILLE" },
  "30045": { state: "GA", city: "LAWRENCEVILLE" },
  "30046": { state: "GA", city: "LAWRENCEVILLE" },
  "30047": { state: "GA", city: "LILBURN" },
  "30048": { state: "GA", city: "LILBURN" },
  "30049": { state: "GA", city: "LAWRENCEVILLE" },
  "30052": { state: "GA", city: "LOGANVILLE" },
  "30054": { state: "GA", city: "OXFORD" },
  "30055": { state: "GA", city: "MANSFIELD" },
  "30056": { state: "GA", city: "NEWBORN" },
  "30058": { state: "GA", city: "LITHONIA" },
  "30060": { state: "GA", city: "MARIETTA" },
  "30061": { state: "GA", city: "MARIETTA" },
  "30062": { state: "GA", city: "MARIETTA" },
  "30063": { state: "GA", city: "MARIETTA" },
  "30064": { state: "GA", city: "MARIETTA" },
  "30065": { state: "GA", city: "MARIETTA" },
  "30066": { state: "GA", city: "MARIETTA" },
  "30067": { state: "GA", city: "MARIETTA" },
  "30068": { state: "GA", city: "MARIETTA" },
  "30069": { state: "GA", city: "MARIETTA" },
  "30070": { state: "GA", city: "PORTERDALE" },
  "30071": { state: "GA", city: "NORCROSS" },
  "30072": { state: "GA", city: "PINE LAKE" },
  "30074": { state: "GA", city: "REDAN" },
  "30075": { state: "GA", city: "ROSWELL" },
  "30076": { state: "GA", city: "ROSWELL" },
  "30077": { state: "GA", city: "ROSWELL" },
  "30078": { state: "GA", city: "SNELLVILLE" },
  "30079": { state: "GA", city: "SCOTTDALE" },
  "30080": { state: "GA", city: "SMYRNA" },
  "30081": { state: "GA", city: "SMYRNA" },
  "30082": { state: "GA", city: "SMYRNA" },
  "30083": { state: "GA", city: "STONE MOUNTAIN" },
  "30084": { state: "GA", city: "TUCKER" },
  "30085": { state: "GA", city: "TUCKER" },
  "30086": { state: "GA", city: "STONE MOUNTAIN" },
  "30087": { state: "GA", city: "STONE MOUNTAIN" },
  "30088": { state: "GA", city: "STONE MOUNTAIN" },
  "30090": { state: "GA", city: "MARIETTA" },
  "30091": { state: "GA", city: "NORCROSS" },
  "30092": { state: "GA", city: "PEACHTREE CORNERS" },
  "30093": { state: "GA", city: "NORCROSS" },
  "30094": { state: "GA", city: "CONYERS" },
  "30095": { state: "GA", city: "DULUTH" },
  "30096": { state: "GA", city: "DULUTH" },
  "30097": { state: "GA", city: "DULUTH" },
  "30098": { state: "GA", city: "DULUTH" },
  "30099": { state: "GA", city: "DULUTH" },
  "30101": { state: "GA", city: "ACWORTH" },
  "30102": { state: "GA", city: "ACWORTH" },
  "30103": { state: "GA", city: "ADAIRSVILLE" },
  "30104": { state: "GA", city: "ARAGON" },
  "30105": { state: "GA", city: "ARMUCHEE" },
  "30106": { state: "GA", city: "AUSTELL" },
  "30107": { state: "GA", city: "BALL GROUND" },
  "30108": { state: "GA", city: "BOWDON" },
  "30109": { state: "GA", city: "BOWDON JUNCTION" },
  "30110": { state: "GA", city: "BREMEN" },
  "30111": { state: "GA", city: "CLARKDALE" },
  "30112": { state: "GA", city: "CARROLLTON" },
  "30113": { state: "GA", city: "BUCHANAN" },
  "30114": { state: "GA", city: "CANTON" },
  "30115": { state: "GA", city: "CANTON" },
  "30116": { state: "GA", city: "CARROLLTON" },
  "30117": { state: "GA", city: "CARROLLTON" },
  "30118": { state: "GA", city: "CARROLLTON" },
  "30119": { state: "GA", city: "CARROLLTON" },
  "30120": { state: "GA", city: "CARTERSVILLE" },
  "30121": { state: "GA", city: "CARTERSVILLE" },
  "30122": { state: "GA", city: "LITHIA SPRINGS" },
  "30123": { state: "GA", city: "CASSVILLE" },
  "30124": { state: "GA", city: "CAVE SPRING" },
  "30125": { state: "GA", city: "CEDARTOWN" },
  "30126": { state: "GA", city: "MABLETON" },
  "30127": { state: "GA", city: "POWDER SPRINGS" },
  "30129": { state: "GA", city: "COOSA" },
  "30132": { state: "GA", city: "DALLAS" },
  "30133": { state: "GA", city: "DOUGLASVILLE" },
  "30134": { state: "GA", city: "DOUGLASVILLE" },
  "30135": { state: "GA", city: "DOUGLASVILLE" },
  "30137": { state: "GA", city: "EMERSON" },
  "30138": { state: "GA", city: "ESOM HILL" },
  "30139": { state: "GA", city: "FAIRMOUNT" },
  "30140": { state: "GA", city: "FELTON" },
  "30141": { state: "GA", city: "HIRAM" },
  "30142": { state: "GA", city: "HOLLY SPRINGS" },
  "30143": { state: "GA", city: "JASPER" },
  "30144": { state: "GA", city: "KENNESAW" },
  "30145": { state: "GA", city: "KINGSTON" },
  "30146": { state: "GA", city: "LEBANON" },
  "30147": { state: "GA", city: "LINDALE" },
  "30148": { state: "GA", city: "MARBLE HILL" },
  "30149": { state: "GA", city: "MOUNT BERRY" },
  "30150": { state: "GA", city: "MOUNT ZION" },
  "30151": { state: "GA", city: "NELSON" },
  "30152": { state: "GA", city: "KENNESAW" },
  "30153": { state: "GA", city: "ROCKMART" },
  "30154": { state: "GA", city: "DOUGLASVILLE" },
  "30156": { state: "GA", city: "KENNESAW" },
  "30157": { state: "GA", city: "DALLAS" },
  "30160": { state: "GA", city: "KENNESAW" },
  "30161": { state: "GA", city: "ROME" },
  "30162": { state: "GA", city: "ROME" },
  "30164": { state: "GA", city: "ROME" },
  "30165": { state: "GA", city: "ROME" },
  "30168": { state: "GA", city: "AUSTELL" },
  "30169": { state: "GA", city: "CANTON" },
  "30170": { state: "GA", city: "ROOPVILLE" },
  "30171": { state: "GA", city: "RYDAL" },
  "30172": { state: "GA", city: "SHANNON" },
  "30173": { state: "GA", city: "SILVER CREEK" },
  "30175": { state: "GA", city: "TALKING ROCK" },
  "30176": { state: "GA", city: "TALLAPOOSA" },
  "30177": { state: "GA", city: "TATE" },
  "30178": { state: "GA", city: "TAYLORSVILLE" },
  "30179": { state: "GA", city: "TEMPLE" },
  "30180": { state: "GA", city: "VILLA RICA" },
  "30182": { state: "GA", city: "WACO" },
  "30183": { state: "GA", city: "WALESKA" },
  "30184": { state: "GA", city: "WHITE" },
  "30185": { state: "GA", city: "WHITESBURG" },
  "30187": { state: "GA", city: "WINSTON" },
  "30188": { state: "GA", city: "WOODSTOCK" },
  "30189": { state: "GA", city: "WOODSTOCK" },
  "30204": { state: "GA", city: "BARNESVILLE" },
  "30205": { state: "GA", city: "BROOKS" },
  "30206": { state: "GA", city: "CONCORD" },
  "30212": { state: "GA", city: "EXPERIMENT" },
  "30213": { state: "GA", city: "FAIRBURN" },
  "30214": { state: "GA", city: "FAYETTEVILLE" },
  "30215": { state: "GA", city: "FAYETTEVILLE" },
  "30216": { state: "GA", city: "FLOVILLA" },
  "30217": { state: "GA", city: "FRANKLIN" },
  "30218": { state: "GA", city: "GAY" },
  "30220": { state: "GA", city: "GRANTVILLE" },
  "30222": { state: "GA", city: "GREENVILLE" },
  "30223": { state: "GA", city: "GRIFFIN" },
  "30224": { state: "GA", city: "GRIFFIN" },
  "30228": { state: "GA", city: "HAMPTON" },
  "30229": { state: "GA", city: "HARALSON" },
  "30230": { state: "GA", city: "HOGANSVILLE" },
  "30233": { state: "GA", city: "JACKSON" },
  "30234": { state: "GA", city: "JENKINSBURG" },
  "30236": { state: "GA", city: "JONESBORO" },
  "30237": { state: "GA", city: "JONESBORO" },
  "30238": { state: "GA", city: "JONESBORO" },
  "30240": { state: "GA", city: "LAGRANGE" },
  "30241": { state: "GA", city: "LAGRANGE" },
  "30248": { state: "GA", city: "LOCUST GROVE" },
  "30250": { state: "GA", city: "LOVEJOY" },
  "30251": { state: "GA", city: "LUTHERSVILLE" },
  "30252": { state: "GA", city: "MCDONOUGH" },
  "30253": { state: "GA", city: "MCDONOUGH" },
  "30256": { state: "GA", city: "MEANSVILLE" },
  "30257": { state: "GA", city: "MILNER" },
  "30258": { state: "GA", city: "MOLENA" },
  "30259": { state: "GA", city: "MORELAND" },
  "30260": { state: "GA", city: "MORROW" },
  "30261": { state: "GA", city: "LAGRANGE" },
  "30263": { state: "GA", city: "NEWNAN" },
  "30264": { state: "GA", city: "NEWNAN" },
  "30265": { state: "GA", city: "NEWNAN" },
  "30266": { state: "GA", city: "ORCHARD HILL" },
  "30268": { state: "GA", city: "PALMETTO" },
  "30269": { state: "GA", city: "PEACHTREE CITY" },
  "30270": { state: "GA", city: "PEACHTREE CITY" },
  "30271": { state: "GA", city: "NEWNAN" },
  "30272": { state: "GA", city: "RED OAK" },
  "30273": { state: "GA", city: "REX" },
  "30274": { state: "GA", city: "RIVERDALE" },
  "30275": { state: "GA", city: "SARGENT" },
  "30276": { state: "GA", city: "SENOIA" },
  "30277": { state: "GA", city: "SHARPSBURG" },
  "30281": { state: "GA", city: "STOCKBRIDGE" },
  "30284": { state: "GA", city: "SUNNY SIDE" },
  "30285": { state: "GA", city: "THE ROCK" },
  "30286": { state: "GA", city: "THOMASTON" },
  "30287": { state: "GA", city: "MORROW" },
  "30288": { state: "GA", city: "CONLEY" },
  "30289": { state: "GA", city: "TURIN" },
  "30290": { state: "GA", city: "TYRONE" },
  "30291": { state: "GA", city: "UNION CITY" },
  "30292": { state: "GA", city: "WILLIAMSON" },
  "30293": { state: "GA", city: "WOODBURY" },
  "30294": { state: "GA", city: "ELLENWOOD" },
  "30295": { state: "GA", city: "ZEBULON" },
  "30296": { state: "GA", city: "RIVERDALE" },
  "30297": { state: "GA", city: "FOREST PARK" },
  "30298": { state: "GA", city: "FOREST PARK" },
  "30301": { state: "GA", city: "ATLANTA" },
  "30302": { state: "GA", city: "ATLANTA" },
  "30303": { state: "GA", city: "ATLANTA" },
  "30304": { state: "GA", city: "ATLANTA" },
  "30305": { state: "GA", city: "ATLANTA" },
  "30306": { state: "GA", city: "ATLANTA" },
  "30307": { state: "GA", city: "ATLANTA" },
  "30308": { state: "GA", city: "ATLANTA" },
  "30309": { state: "GA", city: "ATLANTA" },
  "30310": { state: "GA", city: "ATLANTA" },
  "30311": { state: "GA", city: "ATLANTA" },
  "30312": { state: "GA", city: "ATLANTA" },
  "30313": { state: "GA", city: "ATLANTA" },
  "30314": { state: "GA", city: "ATLANTA" },
  "30315": { state: "GA", city: "ATLANTA" },
  "30316": { state: "GA", city: "ATLANTA" },
  "30317": { state: "GA", city: "ATLANTA" },
  "30318": { state: "GA", city: "ATLANTA" },
  "30319": { state: "GA", city: "ATLANTA" },
  "30320": { state: "GA", city: "ATLANTA" },
  "30321": { state: "GA", city: "ATLANTA" },
  "30322": { state: "GA", city: "ATLANTA" },
  "30324": { state: "GA", city: "ATLANTA" },
  "30325": { state: "GA", city: "ATLANTA" },
  "30326": { state: "GA", city: "ATLANTA" },
  "30327": { state: "GA", city: "ATLANTA" },
  "30328": { state: "GA", city: "ATLANTA" },
  "30329": { state: "GA", city: "ATLANTA" },
  "30331": { state: "GA", city: "ATLANTA" },
  "30332": { state: "GA", city: "ATLANTA" },
  "30333": { state: "GA", city: "ATLANTA" },
  "30334": { state: "GA", city: "ATLANTA" },
  "30336": { state: "GA", city: "ATLANTA" },
  "30337": { state: "GA", city: "ATLANTA" },
  "30338": { state: "GA", city: "ATLANTA" },
  "30339": { state: "GA", city: "ATLANTA" },
  "30340": { state: "GA", city: "ATLANTA" },
  "30341": { state: "GA", city: "ATLANTA" },
  "30342": { state: "GA", city: "ATLANTA" },
  "30343": { state: "GA", city: "ATLANTA" },
  "30344": { state: "GA", city: "ATLANTA" },
  "30345": { state: "GA", city: "ATLANTA" },
  "30346": { state: "GA", city: "ATLANTA" },
  "30348": { state: "GA", city: "ATLANTA" },
  "30349": { state: "GA", city: "ATLANTA" },
  "30350": { state: "GA", city: "ATLANTA" },
  "30353": { state: "GA", city: "ATLANTA" },
  "30354": { state: "GA", city: "ATLANTA" },
  "30355": { state: "GA", city: "ATLANTA" },
  "30356": { state: "GA", city: "ATLANTA" },
  "30357": { state: "GA", city: "ATLANTA" },
  "30358": { state: "GA", city: "ATLANTA" },
  "30359": { state: "GA", city: "ATLANTA" },
  "30360": { state: "GA", city: "ATLANTA" },
  "30361": { state: "GA", city: "ATLANTA" },
  "30362": { state: "GA", city: "ATLANTA" },
  "30363": { state: "GA", city: "ATLANTA" },
  "30364": { state: "GA", city: "ATLANTA" },
  "30366": { state: "GA", city: "ATLANTA" },
  "30368": { state: "GA", city: "ATLANTA" },
  "30369": { state: "GA", city: "ATLANTA" },
  "30370": { state: "GA", city: "ATLANTA" },
  "30371": { state: "GA", city: "ATLANTA" },
  "30374": { state: "GA", city: "ATLANTA" },
  "30375": { state: "GA", city: "ATLANTA" },
  "30377": { state: "GA", city: "ATLANTA" },
  "30378": { state: "GA", city: "ATLANTA" },
  "30380": { state: "GA", city: "ATLANTA" },
  "30384": { state: "GA", city: "ATLANTA" },
  "30385": { state: "GA", city: "ATLANTA" },
  "30388": { state: "GA", city: "ATLANTA" },
  "30392": { state: "GA", city: "ATLANTA" },
  "30394": { state: "GA", city: "ATLANTA" },
  "30396": { state: "GA", city: "ATLANTA" },
  "30398": { state: "GA", city: "ATLANTA" },
  "30401": { state: "GA", city: "SWAINSBORO" },
  "30410": { state: "GA", city: "AILEY" },
  "30411": { state: "GA", city: "ALAMO" },
  "30412": { state: "GA", city: "ALSTON" },
  "30413": { state: "GA", city: "BARTOW" },
  "30414": { state: "GA", city: "BELLVILLE" },
  "30415": { state: "GA", city: "BROOKLET" },
  "30417": { state: "GA", city: "CLAXTON" },
  "30420": { state: "GA", city: "COBBTOWN" },
  "30421": { state: "GA", city: "COLLINS" },
  "30423": { state: "GA", city: "DAISY" },
  "30424": { state: "GA", city: "DOVER" },
  "30425": { state: "GA", city: "GARFIELD" },
  "30426": { state: "GA", city: "GIRARD" },
  "30427": { state: "GA", city: "GLENNVILLE" },
  "30428": { state: "GA", city: "GLENWOOD" },
  "30429": { state: "GA", city: "HAGAN" },
  "30434": { state: "GA", city: "LOUISVILLE" },
  "30436": { state: "GA", city: "LYONS" },
  "30438": { state: "GA", city: "MANASSAS" },
  "30439": { state: "GA", city: "METTER" },
  "30441": { state: "GA", city: "MIDVILLE" },
  "30442": { state: "GA", city: "MILLEN" },
  "30445": { state: "GA", city: "MOUNT VERNON" },
  "30446": { state: "GA", city: "NEWINGTON" },
  "30447": { state: "GA", city: "NORRISTOWN" },
  "30448": { state: "GA", city: "NUNEZ" },
  "30449": { state: "GA", city: "OLIVER" },
  "30450": { state: "GA", city: "PORTAL" },
  "30451": { state: "GA", city: "PULASKI" },
  "30452": { state: "GA", city: "REGISTER" },
  "30453": { state: "GA", city: "REIDSVILLE" },
  "30454": { state: "GA", city: "ROCKLEDGE" },
  "30455": { state: "GA", city: "ROCKY FORD" },
  "30456": { state: "GA", city: "SARDIS" },
  "30457": { state: "GA", city: "SOPERTON" },
  "30458": { state: "GA", city: "STATESBORO" },
  "30459": { state: "GA", city: "STATESBORO" },
  "30460": { state: "GA", city: "STATESBORO" },
  "30461": { state: "GA", city: "STATESBORO" },
  "30464": { state: "GA", city: "STILLMORE" },
  "30467": { state: "GA", city: "SYLVANIA" },
  "30470": { state: "GA", city: "TARRYTOWN" },
  "30471": { state: "GA", city: "TWIN CITY" },
  "30473": { state: "GA", city: "UVALDA" },
  "30474": { state: "GA", city: "VIDALIA" },
  "30475": { state: "GA", city: "VIDALIA" },
  "30477": { state: "GA", city: "WADLEY" },
  "30499": { state: "GA", city: "REIDSVILLE" },
  "30501": { state: "GA", city: "GAINESVILLE" },
  "30502": { state: "GA", city: "CHESTNUT MOUNTAIN" },
  "30503": { state: "GA", city: "GAINESVILLE" },
  "30504": { state: "GA", city: "GAINESVILLE" },
  "30506": { state: "GA", city: "GAINESVILLE" },
  "30507": { state: "GA", city: "GAINESVILLE" },
  "30510": { state: "GA", city: "ALTO" },
  "30511": { state: "GA", city: "BALDWIN" },
  "30512": { state: "GA", city: "BLAIRSVILLE" },
  "30513": { state: "GA", city: "BLUE RIDGE" },
  "30514": { state: "GA", city: "BLAIRSVILLE" },
  "30515": { state: "GA", city: "BUFORD" },
  "30516": { state: "GA", city: "BOWERSVILLE" },
  "30517": { state: "GA", city: "BRASELTON" },
  "30518": { state: "GA", city: "BUFORD" },
  "30519": { state: "GA", city: "BUFORD" },
  "30520": { state: "GA", city: "CANON" },
  "30521": { state: "GA", city: "CARNESVILLE" },
  "30522": { state: "GA", city: "CHERRY LOG" },
  "30523": { state: "GA", city: "CLARKESVILLE" },
  "30525": { state: "GA", city: "CLAYTON" },
  "30527": { state: "GA", city: "CLERMONT" },
  "30528": { state: "GA", city: "CLEVELAND" },
  "30529": { state: "GA", city: "COMMERCE" },
  "30530": { state: "GA", city: "COMMERCE" },
  "30531": { state: "GA", city: "CORNELIA" },
  "30533": { state: "GA", city: "DAHLONEGA" },
  "30534": { state: "GA", city: "DAWSONVILLE" },
  "30535": { state: "GA", city: "DEMOREST" },
  "30536": { state: "GA", city: "ELLIJAY" },
  "30537": { state: "GA", city: "DILLARD" },
  "30538": { state: "GA", city: "EASTANOLLEE" },
  "30539": { state: "GA", city: "EAST ELLIJAY" },
  "30540": { state: "GA", city: "ELLIJAY" },
  "30541": { state: "GA", city: "EPWORTH" },
  "30542": { state: "GA", city: "FLOWERY BRANCH" },
  "30543": { state: "GA", city: "GILLSVILLE" },
  "30545": { state: "GA", city: "HELEN" },
  "30546": { state: "GA", city: "HIAWASSEE" },
  "30547": { state: "GA", city: "HOMER" },
  "30548": { state: "GA", city: "HOSCHTON" },
  "30549": { state: "GA", city: "JEFFERSON" },
  "30552": { state: "GA", city: "LAKEMONT" },
  "30553": { state: "GA", city: "LAVONIA" },
  "30554": { state: "GA", city: "LULA" },
  "30555": { state: "GA", city: "MC CAYSVILLE" },
  "30557": { state: "GA", city: "MARTIN" },
  "30558": { state: "GA", city: "MAYSVILLE" },
  "30559": { state: "GA", city: "MINERAL BLUFF" },
  "30560": { state: "GA", city: "MORGANTON" },
  "30562": { state: "GA", city: "MOUNTAIN CITY" },
  "30563": { state: "GA", city: "MOUNT AIRY" },
  "30564": { state: "GA", city: "MURRAYVILLE" },
  "30565": { state: "GA", city: "NICHOLSON" },
  "30566": { state: "GA", city: "OAKWOOD" },
  "30567": { state: "GA", city: "PENDERGRASS" },
  "30568": { state: "GA", city: "RABUN GAP" },
  "30571": { state: "GA", city: "SAUTEE NACOOCHEE" },
  "30572": { state: "GA", city: "SUCHES" },
  "30573": { state: "GA", city: "TALLULAH FALLS" },
  "30575": { state: "GA", city: "TALMO" },
  "30576": { state: "GA", city: "TIGER" },
  "30577": { state: "GA", city: "TOCCOA" },
  "30580": { state: "GA", city: "TURNERVILLE" },
  "30581": { state: "GA", city: "WILEY" },
  "30582": { state: "GA", city: "YOUNG HARRIS" },
  "30597": { state: "GA", city: "DAHLONEGA" },
  "30598": { state: "GA", city: "TOCCOA FALLS" },
  "30599": { state: "GA", city: "COMMERCE" },
  "30601": { state: "GA", city: "ATHENS" },
  "30602": { state: "GA", city: "ATHENS" },
  "30603": { state: "GA", city: "ATHENS" },
  "30604": { state: "GA", city: "ATHENS" },
  "30605": { state: "GA", city: "ATHENS" },
  "30606": { state: "GA", city: "ATHENS" },
  "30607": { state: "GA", city: "ATHENS" },
  "30608": { state: "GA", city: "ATHENS" },
  "30609": { state: "GA", city: "ATHENS" },
  "30612": { state: "GA", city: "ATHENS" },
  "30619": { state: "GA", city: "ARNOLDSVILLE" },
  "30620": { state: "GA", city: "BETHLEHEM" },
  "30621": { state: "GA", city: "BISHOP" },
  "30622": { state: "GA", city: "BOGART" },
  "30623": { state: "GA", city: "BOSTWICK" },
  "30624": { state: "GA", city: "BOWMAN" },
  "30625": { state: "GA", city: "BUCKHEAD" },
  "30627": { state: "GA", city: "CARLTON" },
  "30628": { state: "GA", city: "COLBERT" },
  "30629": { state: "GA", city: "COMER" },
  "30630": { state: "GA", city: "CRAWFORD" },
  "30631": { state: "GA", city: "CRAWFORDVILLE" },
  "30633": { state: "GA", city: "DANIELSVILLE" },
  "30634": { state: "GA", city: "DEWY ROSE" },
  "30635": { state: "GA", city: "ELBERTON" },
  "30638": { state: "GA", city: "FARMINGTON" },
  "30639": { state: "GA", city: "FRANKLIN SPRINGS" },
  "30641": { state: "GA", city: "GOOD HOPE" },
  "30642": { state: "GA", city: "GREENSBORO" },
  "30643": { state: "GA", city: "HARTWELL" },
  "30645": { state: "GA", city: "HIGH SHOALS" },
  "30646": { state: "GA", city: "HULL" },
  "30647": { state: "GA", city: "ILA" },
  "30648": { state: "GA", city: "LEXINGTON" },
  "30650": { state: "GA", city: "MADISON" },
  "30655": { state: "GA", city: "MONROE" },
  "30656": { state: "GA", city: "MONROE" },
  "30660": { state: "GA", city: "RAYLE" },
  "30662": { state: "GA", city: "ROYSTON" },
  "30663": { state: "GA", city: "RUTLEDGE" },
  "30664": { state: "GA", city: "SHARON" },
  "30665": { state: "GA", city: "SILOAM" },
  "30666": { state: "GA", city: "STATHAM" },
  "30667": { state: "GA", city: "STEPHENS" },
  "30668": { state: "GA", city: "TIGNALL" },
  "30669": { state: "GA", city: "UNION POINT" },
  "30671": { state: "GA", city: "MAXEYS" },
  "30673": { state: "GA", city: "WASHINGTON" },
  "30677": { state: "GA", city: "WATKINSVILLE" },
  "30678": { state: "GA", city: "WHITE PLAINS" },
  "30680": { state: "GA", city: "WINDER" },
  "30683": { state: "GA", city: "WINTERVILLE" },
  "30701": { state: "GA", city: "CALHOUN" },
  "30703": { state: "GA", city: "CALHOUN" },
  "30705": { state: "GA", city: "CHATSWORTH" },
  "30707": { state: "GA", city: "CHICKAMAUGA" },
  "30708": { state: "GA", city: "CISCO" },
  "30710": { state: "GA", city: "COHUTTA" },
  "30711": { state: "GA", city: "CRANDALL" },
  "30719": { state: "GA", city: "DALTON" },
  "30720": { state: "GA", city: "DALTON" },
  "30721": { state: "GA", city: "DALTON" },
  "30722": { state: "GA", city: "DALTON" },
  "30724": { state: "GA", city: "ETON" },
  "30725": { state: "GA", city: "FLINTSTONE" },
  "30726": { state: "GA", city: "GRAYSVILLE" },
  "30728": { state: "GA", city: "LA FAYETTE" },
  "30730": { state: "GA", city: "LYERLY" },
  "30731": { state: "GA", city: "MENLO" },
  "30732": { state: "GA", city: "OAKMAN" },
  "30733": { state: "GA", city: "PLAINVILLE" },
  "30734": { state: "GA", city: "RANGER" },
  "30735": { state: "GA", city: "RESACA" },
  "30736": { state: "GA", city: "RINGGOLD" },
  "30738": { state: "GA", city: "RISING FAWN" },
  "30739": { state: "GA", city: "ROCK SPRING" },
  "30740": { state: "GA", city: "ROCKY FACE" },
  "30741": { state: "GA", city: "ROSSVILLE" },
  "30742": { state: "GA", city: "FORT OGLETHORPE" },
  "30746": { state: "GA", city: "SUGAR VALLEY" },
  "30747": { state: "GA", city: "SUMMERVILLE" },
  "30750": { state: "GA", city: "LOOKOUT MOUNTAIN" },
  "30751": { state: "GA", city: "TENNGA" },
  "30752": { state: "GA", city: "TRENTON" },
  "30753": { state: "GA", city: "TRION" },
  "30755": { state: "GA", city: "TUNNEL HILL" },
  "30756": { state: "GA", city: "VARNELL" },
  "30757": { state: "GA", city: "WILDWOOD" },
  "30802": { state: "GA", city: "APPLING" },
  "30803": { state: "GA", city: "AVERA" },
  "30805": { state: "GA", city: "BLYTHE" },
  "30806": { state: "GA", city: "BONEVILLE" },
  "30807": { state: "GA", city: "CAMAK" },
  "30808": { state: "GA", city: "DEARING" },
  "30809": { state: "GA", city: "EVANS" },
  "30810": { state: "GA", city: "GIBSON" },
  "30811": { state: "GA", city: "GOUGH" },
  "30812": { state: "GA", city: "GRACEWOOD" },
  "30813": { state: "GA", city: "GROVETOWN" },
  "30814": { state: "GA", city: "HARLEM" },
  "30815": { state: "GA", city: "HEPHZIBAH" },
  "30816": { state: "GA", city: "KEYSVILLE" },
  "30817": { state: "GA", city: "LINCOLNTON" },
  "30818": { state: "GA", city: "MATTHEWS" },
  "30819": { state: "GA", city: "MESENA" },
  "30820": { state: "GA", city: "MITCHELL" },
  "30821": { state: "GA", city: "NORWOOD" },
  "30822": { state: "GA", city: "PERKINS" },
  "30823": { state: "GA", city: "STAPLETON" },
  "30824": { state: "GA", city: "THOMSON" },
  "30828": { state: "GA", city: "WARRENTON" },
  "30830": { state: "GA", city: "WAYNESBORO" },
  "30833": { state: "GA", city: "WRENS" },
  "30901": { state: "GA", city: "AUGUSTA" },
  "30903": { state: "GA", city: "AUGUSTA" },
  "30904": { state: "GA", city: "AUGUSTA" },
  "30905": { state: "GA", city: "AUGUSTA" },
  "30906": { state: "GA", city: "AUGUSTA" },
  "30907": { state: "GA", city: "AUGUSTA" },
  "30909": { state: "GA", city: "AUGUSTA" },
  "30912": { state: "GA", city: "AUGUSTA" },
  "30914": { state: "GA", city: "AUGUSTA" },
  "30916": { state: "GA", city: "AUGUSTA" },
  "30917": { state: "GA", city: "AUGUSTA" },
  "30919": { state: "GA", city: "AUGUSTA" },
  "30999": { state: "GA", city: "AUGUSTA" },
  "31001": { state: "GA", city: "ABBEVILLE" },
  "31002": { state: "GA", city: "ADRIAN" },
  "31003": { state: "GA", city: "ALLENTOWN" },
  "31004": { state: "GA", city: "BOLINGBROKE" },
  "31005": { state: "GA", city: "BONAIRE" },
  "31006": { state: "GA", city: "BUTLER" },
  "31007": { state: "GA", city: "BYROMVILLE" },
  "31008": { state: "GA", city: "BYRON" },
  "31009": { state: "GA", city: "CADWELL" },
  "31010": { state: "GA", city: "CORDELE" },
  "31011": { state: "GA", city: "CHAUNCEY" },
  "31012": { state: "GA", city: "CHESTER" },
  "31013": { state: "GA", city: "CLINCHFIELD" },
  "31014": { state: "GA", city: "COCHRAN" },
  "31015": { state: "GA", city: "CORDELE" },
  "31016": { state: "GA", city: "CULLODEN" },
  "31017": { state: "GA", city: "DANVILLE" },
  "31018": { state: "GA", city: "DAVISBORO" },
  "31019": { state: "GA", city: "DEXTER" },
  "31020": { state: "GA", city: "DRY BRANCH" },
  "31021": { state: "GA", city: "DUBLIN" },
  "31022": { state: "GA", city: "DUDLEY" },
  "31023": { state: "GA", city: "EASTMAN" },
  "31024": { state: "GA", city: "EATONTON" },
  "31025": { state: "GA", city: "ELKO" },
  "31026": { state: "GA", city: "EATONTON" },
  "31027": { state: "GA", city: "EAST DUBLIN" },
  "31028": { state: "GA", city: "CENTERVILLE" },
  "31029": { state: "GA", city: "FORSYTH" },
  "31030": { state: "GA", city: "FORT VALLEY" },
  "31031": { state: "GA", city: "GORDON" },
  "31032": { state: "GA", city: "GRAY" },
  "31033": { state: "GA", city: "HADDOCK" },
  "31034": { state: "GA", city: "HARDWICK" },
  "31035": { state: "GA", city: "HARRISON" },
  "31036": { state: "GA", city: "HAWKINSVILLE" },
  "31037": { state: "GA", city: "MC RAE HELENA" },
  "31038": { state: "GA", city: "HILLSBORO" },
  "31039": { state: "GA", city: "HOWARD" },
  "31040": { state: "GA", city: "DUBLIN" },
  "31041": { state: "GA", city: "IDEAL" },
  "31042": { state: "GA", city: "IRWINTON" },
  "31044": { state: "GA", city: "JEFFERSONVILLE" },
  "31045": { state: "GA", city: "JEWELL" },
  "31046": { state: "GA", city: "JULIETTE" },
  "31047": { state: "GA", city: "KATHLEEN" },
  "31049": { state: "GA", city: "KITE" },
  "31050": { state: "GA", city: "KNOXVILLE" },
  "31051": { state: "GA", city: "LILLY" },
  "31052": { state: "GA", city: "LIZELLA" },
  "31054": { state: "GA", city: "MC INTYRE" },
  "31055": { state: "GA", city: "MC RAE HELENA" },
  "31057": { state: "GA", city: "MARSHALLVILLE" },
  "31058": { state: "GA", city: "MAUK" },
  "31059": { state: "GA", city: "MILLEDGEVILLE" },
  "31060": { state: "GA", city: "MILAN" },
  "31061": { state: "GA", city: "MILLEDGEVILLE" },
  "31062": { state: "GA", city: "MILLEDGEVILLE" },
  "31063": { state: "GA", city: "MONTEZUMA" },
  "31064": { state: "GA", city: "MONTICELLO" },
  "31065": { state: "GA", city: "MONTROSE" },
  "31066": { state: "GA", city: "MUSELLA" },
  "31067": { state: "GA", city: "OCONEE" },
  "31068": { state: "GA", city: "OGLETHORPE" },
  "31069": { state: "GA", city: "PERRY" },
  "31070": { state: "GA", city: "PINEHURST" },
  "31071": { state: "GA", city: "PINEVIEW" },
  "31072": { state: "GA", city: "PITTS" },
  "31075": { state: "GA", city: "RENTZ" },
  "31076": { state: "GA", city: "REYNOLDS" },
  "31077": { state: "GA", city: "RHINE" },
  "31078": { state: "GA", city: "ROBERTA" },
  "31079": { state: "GA", city: "ROCHELLE" },
  "31081": { state: "GA", city: "RUPERT" },
  "31082": { state: "GA", city: "SANDERSVILLE" },
  "31083": { state: "GA", city: "SCOTLAND" },
  "31084": { state: "GA", city: "SEVILLE" },
  "31085": { state: "GA", city: "SHADY DALE" },
  "31086": { state: "GA", city: "SMARR" },
  "31087": { state: "GA", city: "SPARTA" },
  "31088": { state: "GA", city: "WARNER ROBINS" },
  "31089": { state: "GA", city: "TENNILLE" },
  "31090": { state: "GA", city: "TOOMSBORO" },
  "31091": { state: "GA", city: "UNADILLA" },
  "31092": { state: "GA", city: "VIENNA" },
  "31093": { state: "GA", city: "WARNER ROBINS" },
  "31094": { state: "GA", city: "WARTHEN" },
  "31095": { state: "GA", city: "WARNER ROBINS" },
  "31096": { state: "GA", city: "WRIGHTSVILLE" },
  "31097": { state: "GA", city: "YATESVILLE" },
  "31098": { state: "GA", city: "WARNER ROBINS" },
  "31099": { state: "GA", city: "WARNER ROBINS" },
  "31106": { state: "GA", city: "ATLANTA" },
  "31107": { state: "GA", city: "ATLANTA" },
  "31119": { state: "GA", city: "ATLANTA" },
  "31126": { state: "GA", city: "ATLANTA" },
  "31131": { state: "GA", city: "ATLANTA" },
  "31136": { state: "GA", city: "ATLANTA" },
  "31139": { state: "GA", city: "ATLANTA" },
  "31141": { state: "GA", city: "ATLANTA" },
  "31145": { state: "GA", city: "ATLANTA" },
  "31146": { state: "GA", city: "ATLANTA" },
  "31150": { state: "GA", city: "ATLANTA" },
  "31156": { state: "GA", city: "ATLANTA" },
  "31169": { state: "GA", city: "PEACHTREE CITY" },
  "31192": { state: "GA", city: "ATLANTA" },
  "31193": { state: "GA", city: "ATLANTA" },
  "31195": { state: "GA", city: "ATLANTA" },
  "31196": { state: "GA", city: "ATLANTA" },
  "31201": { state: "GA", city: "MACON" },
  "31202": { state: "GA", city: "MACON" },
  "31203": { state: "GA", city: "MACON" },
  "31204": { state: "GA", city: "MACON" },
  "31205": { state: "GA", city: "MACON" },
  "31206": { state: "GA", city: "MACON" },
  "31207": { state: "GA", city: "MACON" },
  "31208": { state: "GA", city: "MACON" },
  "31209": { state: "GA", city: "MACON" },
  "31210": { state: "GA", city: "MACON" },
  "31211": { state: "GA", city: "MACON" },
  "31213": { state: "GA", city: "MACON" },
  "31216": { state: "GA", city: "MACON" },
  "31217": { state: "GA", city: "MACON" },
  "31220": { state: "GA", city: "MACON" },
  "31221": { state: "GA", city: "MACON" },
  "31294": { state: "GA", city: "MACON" },
  "31295": { state: "GA", city: "MACON" },
  "31296": { state: "GA", city: "MACON" },
  "31297": { state: "GA", city: "MACON" },
  "31301": { state: "GA", city: "ALLENHURST" },
  "31302": { state: "GA", city: "BLOOMINGDALE" },
  "31303": { state: "GA", city: "CLYO" },
  "31304": { state: "GA", city: "CRESCENT" },
  "31305": { state: "GA", city: "DARIEN" },
  "31307": { state: "GA", city: "EDEN" },
  "31308": { state: "GA", city: "ELLABELL" },
  "31309": { state: "GA", city: "FLEMING" },
  "31310": { state: "GA", city: "HINESVILLE" },
  "31312": { state: "GA", city: "GUYTON" },
  "31313": { state: "GA", city: "HINESVILLE" },
  "31314": { state: "GA", city: "FORT STEWART" },
  "31315": { state: "GA", city: "FORT STEWART" },
  "31316": { state: "GA", city: "LUDOWICI" },
  "31318": { state: "GA", city: "MELDRIM" },
  "31319": { state: "GA", city: "MERIDIAN" },
  "31320": { state: "GA", city: "MIDWAY" },
  "31321": { state: "GA", city: "PEMBROKE" },
  "31322": { state: "GA", city: "POOLER" },
  "31323": { state: "GA", city: "RICEBORO" },
  "31324": { state: "GA", city: "RICHMOND HILL" },
  "31326": { state: "GA", city: "RINCON" },
  "31327": { state: "GA", city: "SAPELO ISLAND" },
  "31328": { state: "GA", city: "TYBEE ISLAND" },
  "31329": { state: "GA", city: "SPRINGFIELD" },
  "31331": { state: "GA", city: "TOWNSEND" },
  "31333": { state: "GA", city: "WALTHOURVILLE" },
  "31401": { state: "GA", city: "SAVANNAH" },
  "31402": { state: "GA", city: "SAVANNAH" },
  "31403": { state: "GA", city: "SAVANNAH" },
  "31404": { state: "GA", city: "SAVANNAH" },
  "31405": { state: "GA", city: "SAVANNAH" },
  "31406": { state: "GA", city: "SAVANNAH" },
  "31407": { state: "GA", city: "PORT WENTWORTH" },
  "31408": { state: "GA", city: "SAVANNAH" },
  "31409": { state: "GA", city: "SAVANNAH" },
  "31410": { state: "GA", city: "SAVANNAH" },
  "31411": { state: "GA", city: "SAVANNAH" },
  "31412": { state: "GA", city: "SAVANNAH" },
  "31414": { state: "GA", city: "SAVANNAH" },
  "31415": { state: "GA", city: "SAVANNAH" },
  "31416": { state: "GA", city: "SAVANNAH" },
  "31418": { state: "GA", city: "SAVANNAH" },
  "31419": { state: "GA", city: "SAVANNAH" },
  "31420": { state: "GA", city: "SAVANNAH" },
  "31421": { state: "GA", city: "SAVANNAH" },
  "31501": { state: "GA", city: "WAYCROSS" },
  "31502": { state: "GA", city: "WAYCROSS" },
  "31503": { state: "GA", city: "WAYCROSS" },
  "31510": { state: "GA", city: "ALMA" },
  "31512": { state: "GA", city: "AMBROSE" },
  "31513": { state: "GA", city: "BAXLEY" },
  "31515": { state: "GA", city: "BAXLEY" },
  "31516": { state: "GA", city: "BLACKSHEAR" },
  "31518": { state: "GA", city: "BRISTOL" },
  "31519": { state: "GA", city: "BROXTON" },
  "31520": { state: "GA", city: "BRUNSWICK" },
  "31521": { state: "GA", city: "BRUNSWICK" },
  "31522": { state: "GA", city: "SAINT SIMONS ISLAND" },
  "31523": { state: "GA", city: "BRUNSWICK" },
  "31524": { state: "GA", city: "BRUNSWICK" },
  "31525": { state: "GA", city: "BRUNSWICK" },
  "31527": { state: "GA", city: "JEKYLL ISLAND" },
  "31532": { state: "GA", city: "DENTON" },
  "31533": { state: "GA", city: "DOUGLAS" },
  "31534": { state: "GA", city: "DOUGLAS" },
  "31535": { state: "GA", city: "DOUGLAS" },
  "31537": { state: "GA", city: "FOLKSTON" },
  "31539": { state: "GA", city: "HAZLEHURST" },
  "31542": { state: "GA", city: "HOBOKEN" },
  "31543": { state: "GA", city: "HORTENSE" },
  "31544": { state: "GA", city: "JACKSONVILLE" },
  "31545": { state: "GA", city: "JESUP" },
  "31546": { state: "GA", city: "JESUP" },
  "31547": { state: "GA", city: "KINGS BAY" },
  "31548": { state: "GA", city: "KINGSLAND" },
  "31549": { state: "GA", city: "LUMBER CITY" },
  "31550": { state: "GA", city: "MANOR" },
  "31551": { state: "GA", city: "MERSHON" },
  "31552": { state: "GA", city: "MILLWOOD" },
  "31553": { state: "GA", city: "NAHUNTA" },
  "31554": { state: "GA", city: "NICHOLLS" },
  "31555": { state: "GA", city: "ODUM" },
  "31556": { state: "GA", city: "OFFERMAN" },
  "31557": { state: "GA", city: "PATTERSON" },
  "31558": { state: "GA", city: "SAINT MARYS" },
  "31560": { state: "GA", city: "SCREVEN" },
  "31561": { state: "GA", city: "SEA ISLAND" },
  "31562": { state: "GA", city: "SAINT GEORGE" },
  "31563": { state: "GA", city: "SURRENCY" },
  "31564": { state: "GA", city: "WARESBORO" },
  "31565": { state: "GA", city: "WAVERLY" },
  "31566": { state: "GA", city: "WAYNESVILLE" },
  "31567": { state: "GA", city: "WEST GREEN" },
  "31568": { state: "GA", city: "WHITE OAK" },
  "31569": { state: "GA", city: "WOODBINE" },
  "31598": { state: "GA", city: "JESUP" },
  "31599": { state: "GA", city: "JESUP" },
  "31601": { state: "GA", city: "VALDOSTA" },
  "31602": { state: "GA", city: "VALDOSTA" },
  "31603": { state: "GA", city: "VALDOSTA" },
  "31604": { state: "GA", city: "VALDOSTA" },
  "31605": { state: "GA", city: "VALDOSTA" },
  "31606": { state: "GA", city: "VALDOSTA" },
  "31620": { state: "GA", city: "ADEL" },
  "31622": { state: "GA", city: "ALAPAHA" },
  "31623": { state: "GA", city: "ARGYLE" },
  "31624": { state: "GA", city: "AXSON" },
  "31625": { state: "GA", city: "BARNEY" },
  "31626": { state: "GA", city: "BOSTON" },
  "31627": { state: "GA", city: "CECIL" },
  "31629": { state: "GA", city: "DIXIE" },
  "31630": { state: "GA", city: "DU PONT" },
  "31631": { state: "GA", city: "FARGO" },
  "31632": { state: "GA", city: "HAHIRA" },
  "31634": { state: "GA", city: "HOMERVILLE" },
  "31635": { state: "GA", city: "LAKELAND" },
  "31636": { state: "GA", city: "LAKE PARK" },
  "31637": { state: "GA", city: "LENOX" },
  "31638": { state: "GA", city: "MORVEN" },
  "31639": { state: "GA", city: "NASHVILLE" },
  "31641": { state: "GA", city: "NAYLOR" },
  "31642": { state: "GA", city: "PEARSON" },
  "31643": { state: "GA", city: "QUITMAN" },
  "31645": { state: "GA", city: "RAY CITY" },
  "31647": { state: "GA", city: "SPARKS" },
  "31648": { state: "GA", city: "STATENVILLE" },
  "31649": { state: "GA", city: "STOCKTON" },
  "31650": { state: "GA", city: "WILLACOOCHEE" },
  "31698": { state: "GA", city: "VALDOSTA" },
  "31699": { state: "GA", city: "MOODY AFB" },
  "31701": { state: "GA", city: "ALBANY" },
  "31702": { state: "GA", city: "ALBANY" },
  "31703": { state: "GA", city: "ALBANY" },
  "31704": { state: "GA", city: "ALBANY" },
  "31705": { state: "GA", city: "ALBANY" },
  "31706": { state: "GA", city: "ALBANY" },
  "31707": { state: "GA", city: "ALBANY" },
  "31708": { state: "GA", city: "ALBANY" },
  "31709": { state: "GA", city: "AMERICUS" },
  "31711": { state: "GA", city: "ANDERSONVILLE" },
  "31712": { state: "GA", city: "ARABI" },
  "31714": { state: "GA", city: "ASHBURN" },
  "31716": { state: "GA", city: "BACONTON" },
  "31719": { state: "GA", city: "AMERICUS" },
  "31720": { state: "GA", city: "BARWICK" },
  "31721": { state: "GA", city: "ALBANY" },
  "31722": { state: "GA", city: "BERLIN" },
  "31727": { state: "GA", city: "BROOKFIELD" },
  "31730": { state: "GA", city: "CAMILLA" },
  "31733": { state: "GA", city: "CHULA" },
  "31735": { state: "GA", city: "COBB" },
  "31738": { state: "GA", city: "COOLIDGE" },
  "31739": { state: "GA", city: "COTTON" },
  "31743": { state: "GA", city: "DE SOTO" },
  "31744": { state: "GA", city: "DOERUN" },
  "31747": { state: "GA", city: "ELLENTON" },
  "31749": { state: "GA", city: "ENIGMA" },
  "31750": { state: "GA", city: "FITZGERALD" },
  "31753": { state: "GA", city: "FUNSTON" },
  "31756": { state: "GA", city: "HARTSFIELD" },
  "31757": { state: "GA", city: "THOMASVILLE" },
  "31758": { state: "GA", city: "THOMASVILLE" },
  "31760": { state: "GA", city: "IRWINVILLE" },
  "31763": { state: "GA", city: "LEESBURG" },
  "31764": { state: "GA", city: "LESLIE" },
  "31765": { state: "GA", city: "MEIGS" },
  "31768": { state: "GA", city: "MOULTRIE" },
  "31769": { state: "GA", city: "MYSTIC" },
  "31771": { state: "GA", city: "NORMAN PARK" },
  "31772": { state: "GA", city: "OAKFIELD" },
  "31773": { state: "GA", city: "OCHLOCKNEE" },
  "31774": { state: "GA", city: "OCILLA" },
  "31775": { state: "GA", city: "OMEGA" },
  "31776": { state: "GA", city: "MOULTRIE" },
  "31778": { state: "GA", city: "PAVO" },
  "31779": { state: "GA", city: "PELHAM" },
  "31780": { state: "GA", city: "PLAINS" },
  "31781": { state: "GA", city: "POULAN" },
  "31782": { state: "GA", city: "PUTNEY" },
  "31783": { state: "GA", city: "REBECCA" },
  "31784": { state: "GA", city: "SALE CITY" },
  "31787": { state: "GA", city: "SMITHVILLE" },
  "31788": { state: "GA", city: "MOULTRIE" },
  "31789": { state: "GA", city: "SUMNER" },
  "31790": { state: "GA", city: "SYCAMORE" },
  "31791": { state: "GA", city: "SYLVESTER" },
  "31792": { state: "GA", city: "THOMASVILLE" },
  "31793": { state: "GA", city: "TIFTON" },
  "31794": { state: "GA", city: "TIFTON" },
  "31795": { state: "GA", city: "TY TY" },
  "31796": { state: "GA", city: "WARWICK" },
  "31798": { state: "GA", city: "WRAY" },
  "31799": { state: "GA", city: "THOMASVILLE" },
  "31801": { state: "GA", city: "BOX SPRINGS" },
  "31803": { state: "GA", city: "BUENA VISTA" },
  "31804": { state: "GA", city: "CATAULA" },
  "31805": { state: "GA", city: "CUSSETA" },
  "31806": { state: "GA", city: "ELLAVILLE" },
  "31807": { state: "GA", city: "ELLERSLIE" },
  "31808": { state: "GA", city: "FORTSON" },
  "31810": { state: "GA", city: "GENEVA" },
  "31811": { state: "GA", city: "HAMILTON" },
  "31812": { state: "GA", city: "JUNCTION CITY" },
  "31814": { state: "GA", city: "LOUVALE" },
  "31815": { state: "GA", city: "LUMPKIN" },
  "31816": { state: "GA", city: "MANCHESTER" },
  "31820": { state: "GA", city: "MIDLAND" },
  "31821": { state: "GA", city: "OMAHA" },
  "31822": { state: "GA", city: "PINE MOUNTAIN" },
  "31823": { state: "GA", city: "PINE MOUNTAIN VALLEY" },
  "31824": { state: "GA", city: "PRESTON" },
  "31825": { state: "GA", city: "RICHLAND" },
  "31826": { state: "GA", city: "SHILOH" },
  "31827": { state: "GA", city: "TALBOTTON" },
  "31829": { state: "GA", city: "UPATOI" },
  "31830": { state: "GA", city: "WARM SPRINGS" },
  "31831": { state: "GA", city: "WAVERLY HALL" },
  "31832": { state: "GA", city: "WESTON" },
  "31833": { state: "GA", city: "WEST POINT" },
  "31836": { state: "GA", city: "WOODLAND" },
  "31901": { state: "GA", city: "COLUMBUS" },
  "31902": { state: "GA", city: "COLUMBUS" },
  "31903": { state: "GA", city: "COLUMBUS" },
  "31904": { state: "GA", city: "COLUMBUS" },
  "31905": { state: "GA", city: "FORT MOORE" },
  "31906": { state: "GA", city: "COLUMBUS" },
  "31907": { state: "GA", city: "COLUMBUS" },
  "31908": { state: "GA", city: "COLUMBUS" },
  "31909": { state: "GA", city: "COLUMBUS" },
  "31914": { state: "GA", city: "COLUMBUS" },
  "31917": { state: "GA", city: "COLUMBUS" },
  "31993": { state: "GA", city: "COLUMBUS" },
  "31995": { state: "GA", city: "FORT MOORE" },
  "31997": { state: "GA", city: "COLUMBUS" },
  "31998": { state: "GA", city: "COLUMBUS" },
  "31999": { state: "GA", city: "COLUMBUS" },
  "32003": { state: "FL", city: "FLEMING ISLAND" },
  "32004": { state: "FL", city: "PONTE VEDRA BEACH" },
  "32006": { state: "FL", city: "FLEMING ISLAND" },
  "32007": { state: "FL", city: "BOSTWICK" },
  "32008": { state: "FL", city: "BRANFORD" },
  "32009": { state: "FL", city: "BRYCEVILLE" },
  "32011": { state: "FL", city: "CALLAHAN" },
  "32013": { state: "FL", city: "DAY" },
  "32024": { state: "FL", city: "LAKE CITY" },
  "32025": { state: "FL", city: "LAKE CITY" },
  "32030": { state: "FL", city: "DOCTORS INLET" },
  "32033": { state: "FL", city: "ELKTON" },
  "32034": { state: "FL", city: "FERNANDINA BEACH" },
  "32035": { state: "FL", city: "FERNANDINA BEACH" },
  "32038": { state: "FL", city: "FORT WHITE" },
  "32040": { state: "FL", city: "GLEN SAINT MARY" },
  "32041": { state: "FL", city: "YULEE" },
  "32042": { state: "FL", city: "GRAHAM" },
  "32043": { state: "FL", city: "GREEN COVE SPRINGS" },
  "32044": { state: "FL", city: "HAMPTON" },
  "32046": { state: "FL", city: "HILLIARD" },
  "32050": { state: "FL", city: "MIDDLEBURG" },
  "32052": { state: "FL", city: "JASPER" },
  "32053": { state: "FL", city: "JENNINGS" },
  "32054": { state: "FL", city: "LAKE BUTLER" },
  "32055": { state: "FL", city: "LAKE CITY" },
  "32056": { state: "FL", city: "LAKE CITY" },
  "32058": { state: "FL", city: "LAWTEY" },
  "32059": { state: "FL", city: "LEE" },
  "32060": { state: "FL", city: "LIVE OAK" },
  "32061": { state: "FL", city: "LULU" },
  "32062": { state: "FL", city: "MC ALPIN" },
  "32063": { state: "FL", city: "MACCLENNY" },
  "32064": { state: "FL", city: "LIVE OAK" },
  "32065": { state: "FL", city: "ORANGE PARK" },
  "32066": { state: "FL", city: "MAYO" },
  "32067": { state: "FL", city: "ORANGE PARK" },
  "32068": { state: "FL", city: "MIDDLEBURG" },
  "32071": { state: "FL", city: "O BRIEN" },
  "32073": { state: "FL", city: "ORANGE PARK" },
  "32079": { state: "FL", city: "PENNEY FARMS" },
  "32080": { state: "FL", city: "SAINT AUGUSTINE" },
  "32081": { state: "FL", city: "PONTE VEDRA" },
  "32082": { state: "FL", city: "PONTE VEDRA BEACH" },
  "32083": { state: "FL", city: "RAIFORD" },
  "32084": { state: "FL", city: "SAINT AUGUSTINE" },
  "32085": { state: "FL", city: "SAINT AUGUSTINE" },
  "32086": { state: "FL", city: "SAINT AUGUSTINE" },
  "32087": { state: "FL", city: "SANDERSON" },
  "32091": { state: "FL", city: "STARKE" },
  "32092": { state: "FL", city: "SAINT AUGUSTINE" },
  "32094": { state: "FL", city: "WELLBORN" },
  "32095": { state: "FL", city: "SAINT AUGUSTINE" },
  "32096": { state: "FL", city: "WHITE SPRINGS" },
  "32097": { state: "FL", city: "YULEE" },
  "32099": { state: "FL", city: "JACKSONVILLE" },
  "32102": { state: "FL", city: "ASTOR" },
  "32105": { state: "FL", city: "BARBERVILLE" },
  "32110": { state: "FL", city: "BUNNELL" },
  "32111": { state: "FL", city: "CANDLER" },
  "32112": { state: "FL", city: "CRESCENT CITY" },
  "32113": { state: "FL", city: "CITRA" },
  "32114": { state: "FL", city: "DAYTONA BEACH" },
  "32115": { state: "FL", city: "DAYTONA BEACH" },
  "32116": { state: "FL", city: "DAYTONA BEACH" },
  "32117": { state: "FL", city: "DAYTONA BEACH" },
  "32118": { state: "FL", city: "DAYTONA BEACH" },
  "32119": { state: "FL", city: "DAYTONA BEACH" },
  "32120": { state: "FL", city: "DAYTONA BEACH" },
  "32121": { state: "FL", city: "DAYTONA BEACH" },
  "32122": { state: "FL", city: "DAYTONA BEACH" },
  "32123": { state: "FL", city: "PORT ORANGE" },
  "32124": { state: "FL", city: "DAYTONA BEACH" },
  "32125": { state: "FL", city: "DAYTONA BEACH" },
  "32126": { state: "FL", city: "DAYTONA BEACH" },
  "32127": { state: "FL", city: "PORT ORANGE" },
  "32128": { state: "FL", city: "PORT ORANGE" },
  "32129": { state: "FL", city: "PORT ORANGE" },
  "32130": { state: "FL", city: "DE LEON SPRINGS" },
  "32131": { state: "FL", city: "EAST PALATKA" },
  "32132": { state: "FL", city: "EDGEWATER" },
  "32133": { state: "FL", city: "EASTLAKE WEIR" },
  "32134": { state: "FL", city: "FORT MC COY" },
  "32135": { state: "FL", city: "PALM COAST" },
  "32136": { state: "FL", city: "FLAGLER BEACH" },
  "32137": { state: "FL", city: "PALM COAST" },
  "32138": { state: "FL", city: "GRANDIN" },
  "32139": { state: "FL", city: "GEORGETOWN" },
  "32140": { state: "FL", city: "FLORAHOME" },
  "32141": { state: "FL", city: "EDGEWATER" },
  "32142": { state: "FL", city: "PALM COAST" },
  "32143": { state: "FL", city: "PALM COAST" },
  "32145": { state: "FL", city: "HASTINGS" },
  "32147": { state: "FL", city: "HOLLISTER" },
  "32148": { state: "FL", city: "INTERLACHEN" },
  "32149": { state: "FL", city: "INTERLACHEN" },
  "32157": { state: "FL", city: "LAKE COMO" },
  "32158": { state: "FL", city: "LADY LAKE" },
  "32159": { state: "FL", city: "LADY LAKE" },
  "32160": { state: "FL", city: "LAKE GENEVA" },
  "32162": { state: "FL", city: "THE VILLAGES" },
  "32163": { state: "FL", city: "THE VILLAGES" },
  "32164": { state: "FL", city: "PALM COAST" },
  "32168": { state: "FL", city: "NEW SMYRNA BEACH" },
  "32169": { state: "FL", city: "NEW SMYRNA BEACH" },
  "32170": { state: "FL", city: "NEW SMYRNA BEACH" },
  "32173": { state: "FL", city: "ORMOND BEACH" },
  "32174": { state: "FL", city: "ORMOND BEACH" },
  "32175": { state: "FL", city: "ORMOND BEACH" },
  "32176": { state: "FL", city: "ORMOND BEACH" },
  "32177": { state: "FL", city: "PALATKA" },
  "32178": { state: "FL", city: "PALATKA" },
  "32179": { state: "FL", city: "OCKLAWAHA" },
  "32180": { state: "FL", city: "PIERSON" },
  "32181": { state: "FL", city: "POMONA PARK" },
  "32182": { state: "FL", city: "ORANGE SPRINGS" },
  "32183": { state: "FL", city: "OCKLAWAHA" },
  "32185": { state: "FL", city: "PUTNAM HALL" },
  "32187": { state: "FL", city: "SAN MATEO" },
  "32189": { state: "FL", city: "SATSUMA" },
  "32190": { state: "FL", city: "SEVILLE" },
  "32192": { state: "FL", city: "SPARR" },
  "32193": { state: "FL", city: "WELAKA" },
  "32195": { state: "FL", city: "WEIRSDALE" },
  "32198": { state: "FL", city: "DAYTONA BEACH" },
  "32201": { state: "FL", city: "JACKSONVILLE" },
  "32202": { state: "FL", city: "JACKSONVILLE" },
  "32203": { state: "FL", city: "JACKSONVILLE" },
  "32204": { state: "FL", city: "JACKSONVILLE" },
  "32205": { state: "FL", city: "JACKSONVILLE" },
  "32206": { state: "FL", city: "JACKSONVILLE" },
  "32207": { state: "FL", city: "JACKSONVILLE" },
  "32208": { state: "FL", city: "JACKSONVILLE" },
  "32209": { state: "FL", city: "JACKSONVILLE" },
  "32210": { state: "FL", city: "JACKSONVILLE" },
  "32211": { state: "FL", city: "JACKSONVILLE" },
  "32212": { state: "FL", city: "JACKSONVILLE" },
  "32214": { state: "FL", city: "JACKSONVILLE" },
  "32216": { state: "FL", city: "JACKSONVILLE" },
  "32217": { state: "FL", city: "JACKSONVILLE" },
  "32218": { state: "FL", city: "JACKSONVILLE" },
  "32219": { state: "FL", city: "JACKSONVILLE" },
  "32220": { state: "FL", city: "JACKSONVILLE" },
  "32221": { state: "FL", city: "JACKSONVILLE" },
  "32222": { state: "FL", city: "JACKSONVILLE" },
  "32223": { state: "FL", city: "JACKSONVILLE" },
  "32224": { state: "FL", city: "JACKSONVILLE" },
  "32225": { state: "FL", city: "JACKSONVILLE" },
  "32226": { state: "FL", city: "JACKSONVILLE" },
  "32227": { state: "FL", city: "JACKSONVILLE" },
  "32228": { state: "FL", city: "JACKSONVILLE" },
  "32229": { state: "FL", city: "JACKSONVILLE" },
  "32231": { state: "FL", city: "JACKSONVILLE" },
  "32232": { state: "FL", city: "JACKSONVILLE" },
  "32233": { state: "FL", city: "ATLANTIC BEACH" },
  "32234": { state: "FL", city: "JACKSONVILLE" },
  "32235": { state: "FL", city: "JACKSONVILLE" },
  "32236": { state: "FL", city: "JACKSONVILLE" },
  "32238": { state: "FL", city: "JACKSONVILLE" },
  "32239": { state: "FL", city: "JACKSONVILLE" },
  "32240": { state: "FL", city: "JACKSONVILLE BEACH" },
  "32241": { state: "FL", city: "JACKSONVILLE" },
  "32244": { state: "FL", city: "JACKSONVILLE" },
  "32245": { state: "FL", city: "JACKSONVILLE" },
  "32246": { state: "FL", city: "JACKSONVILLE" },
  "32247": { state: "FL", city: "JACKSONVILLE" },
  "32250": { state: "FL", city: "JACKSONVILLE BEACH" },
  "32254": { state: "FL", city: "JACKSONVILLE" },
  "32255": { state: "FL", city: "JACKSONVILLE" },
  "32256": { state: "FL", city: "JACKSONVILLE" },
  "32257": { state: "FL", city: "JACKSONVILLE" },
  "32258": { state: "FL", city: "JACKSONVILLE" },
  "32259": { state: "FL", city: "SAINT JOHNS" },
  "32260": { state: "FL", city: "JACKSONVILLE" },
  "32266": { state: "FL", city: "NEPTUNE BEACH" },
  "32277": { state: "FL", city: "JACKSONVILLE" },
  "32301": { state: "FL", city: "TALLAHASSEE" },
  "32302": { state: "FL", city: "TALLAHASSEE" },
  "32303": { state: "FL", city: "TALLAHASSEE" },
  "32304": { state: "FL", city: "TALLAHASSEE" },
  "32305": { state: "FL", city: "TALLAHASSEE" },
  "32306": { state: "FL", city: "TALLAHASSEE" },
  "32307": { state: "FL", city: "TALLAHASSEE" },
  "32308": { state: "FL", city: "TALLAHASSEE" },
  "32309": { state: "FL", city: "TALLAHASSEE" },
  "32310": { state: "FL", city: "TALLAHASSEE" },
  "32311": { state: "FL", city: "TALLAHASSEE" },
  "32312": { state: "FL", city: "TALLAHASSEE" },
  "32313": { state: "FL", city: "TALLAHASSEE" },
  "32314": { state: "FL", city: "TALLAHASSEE" },
  "32315": { state: "FL", city: "TALLAHASSEE" },
  "32316": { state: "FL", city: "TALLAHASSEE" },
  "32317": { state: "FL", city: "TALLAHASSEE" },
  "32318": { state: "FL", city: "TALLAHASSEE" },
  "32320": { state: "FL", city: "APALACHICOLA" },
  "32321": { state: "FL", city: "BRISTOL" },
  "32322": { state: "FL", city: "CARRABELLE" },
  "32323": { state: "FL", city: "LANARK VILLAGE" },
  "32324": { state: "FL", city: "CHATTAHOOCHEE" },
  "32326": { state: "FL", city: "CRAWFORDVILLE" },
  "32327": { state: "FL", city: "CRAWFORDVILLE" },
  "32328": { state: "FL", city: "EASTPOINT" },
  "32329": { state: "FL", city: "APALACHICOLA" },
  "32330": { state: "FL", city: "GREENSBORO" },
  "32331": { state: "FL", city: "GREENVILLE" },
  "32332": { state: "FL", city: "GRETNA" },
  "32333": { state: "FL", city: "HAVANA" },
  "32334": { state: "FL", city: "HOSFORD" },
  "32335": { state: "FL", city: "SUMATRA" },
  "32336": { state: "FL", city: "LAMONT" },
  "32337": { state: "FL", city: "LLOYD" },
  "32340": { state: "FL", city: "MADISON" },
  "32341": { state: "FL", city: "MADISON" },
  "32343": { state: "FL", city: "MIDWAY" },
  "32344": { state: "FL", city: "MONTICELLO" },
  "32345": { state: "FL", city: "MONTICELLO" },
  "32346": { state: "FL", city: "PANACEA" },
  "32347": { state: "FL", city: "PERRY" },
  "32348": { state: "FL", city: "PERRY" },
  "32350": { state: "FL", city: "PINETTA" },
  "32351": { state: "FL", city: "QUINCY" },
  "32352": { state: "FL", city: "QUINCY" },
  "32353": { state: "FL", city: "QUINCY" },
  "32355": { state: "FL", city: "SAINT MARKS" },
  "32356": { state: "FL", city: "SALEM" },
  "32357": { state: "FL", city: "SHADY GROVE" },
  "32358": { state: "FL", city: "SOPCHOPPY" },
  "32359": { state: "FL", city: "STEINHATCHEE" },
  "32360": { state: "FL", city: "TELOGIA" },
  "32361": { state: "FL", city: "WACISSA" },
  "32362": { state: "FL", city: "WOODVILLE" },
  "32399": { state: "FL", city: "TALLAHASSEE" },
  "32401": { state: "FL", city: "PANAMA CITY" },
  "32402": { state: "FL", city: "PANAMA CITY" },
  "32403": { state: "FL", city: "PANAMA CITY" },
  "32404": { state: "FL", city: "PANAMA CITY" },
  "32405": { state: "FL", city: "PANAMA CITY" },
  "32406": { state: "FL", city: "PANAMA CITY" },
  "32407": { state: "FL", city: "PANAMA CITY BEACH" },
  "32408": { state: "FL", city: "PANAMA CITY" },
  "32409": { state: "FL", city: "PANAMA CITY" },
  "32410": { state: "FL", city: "MEXICO BEACH" },
  "32411": { state: "FL", city: "PANAMA CITY" },
  "32412": { state: "FL", city: "PANAMA CITY" },
  "32413": { state: "FL", city: "PANAMA CITY BEACH" },
  "32417": { state: "FL", city: "PANAMA CITY" },
  "32420": { state: "FL", city: "ALFORD" },
  "32421": { state: "FL", city: "ALTHA" },
  "32422": { state: "FL", city: "ARGYLE" },
  "32423": { state: "FL", city: "BASCOM" },
  "32424": { state: "FL", city: "BLOUNTSTOWN" },
  "32425": { state: "FL", city: "BONIFAY" },
  "32426": { state: "FL", city: "CAMPBELLTON" },
  "32427": { state: "FL", city: "CARYVILLE" },
  "32428": { state: "FL", city: "CHIPLEY" },
  "32430": { state: "FL", city: "CLARKSVILLE" },
  "32431": { state: "FL", city: "COTTONDALE" },
  "32432": { state: "FL", city: "CYPRESS" },
  "32433": { state: "FL", city: "DEFUNIAK SPRINGS" },
  "32434": { state: "FL", city: "MOSSY HEAD" },
  "32435": { state: "FL", city: "DEFUNIAK SPRINGS" },
  "32437": { state: "FL", city: "EBRO" },
  "32438": { state: "FL", city: "FOUNTAIN" },
  "32439": { state: "FL", city: "FREEPORT" },
  "32440": { state: "FL", city: "GRACEVILLE" },
  "32442": { state: "FL", city: "GRAND RIDGE" },
  "32443": { state: "FL", city: "GREENWOOD" },
  "32444": { state: "FL", city: "LYNN HAVEN" },
  "32445": { state: "FL", city: "MALONE" },
  "32446": { state: "FL", city: "MARIANNA" },
  "32447": { state: "FL", city: "MARIANNA" },
  "32448": { state: "FL", city: "MARIANNA" },
  "32449": { state: "FL", city: "WEWAHITCHKA" },
  "32452": { state: "FL", city: "NOMA" },
  "32455": { state: "FL", city: "PONCE DE LEON" },
  "32456": { state: "FL", city: "PORT SAINT JOE" },
  "32457": { state: "FL", city: "PORT SAINT JOE" },
  "32459": { state: "FL", city: "SANTA ROSA BEACH" },
  "32460": { state: "FL", city: "SNEADS" },
  "32461": { state: "FL", city: "INLET BEACH" },
  "32462": { state: "FL", city: "VERNON" },
  "32463": { state: "FL", city: "WAUSAU" },
  "32464": { state: "FL", city: "WESTVILLE" },
  "32465": { state: "FL", city: "WEWAHITCHKA" },
  "32466": { state: "FL", city: "YOUNGSTOWN" },
  "32501": { state: "FL", city: "PENSACOLA" },
  "32502": { state: "FL", city: "PENSACOLA" },
  "32503": { state: "FL", city: "PENSACOLA" },
  "32504": { state: "FL", city: "PENSACOLA" },
  "32505": { state: "FL", city: "PENSACOLA" },
  "32506": { state: "FL", city: "PENSACOLA" },
  "32507": { state: "FL", city: "PENSACOLA" },
  "32508": { state: "FL", city: "PENSACOLA" },
  "32509": { state: "FL", city: "PENSACOLA" },
  "32511": { state: "FL", city: "PENSACOLA" },
  "32512": { state: "FL", city: "PENSACOLA" },
  "32513": { state: "FL", city: "PENSACOLA" },
  "32514": { state: "FL", city: "PENSACOLA" },
  "32516": { state: "FL", city: "PENSACOLA" },
  "32520": { state: "FL", city: "PENSACOLA" },
  "32521": { state: "FL", city: "PENSACOLA" },
  "32522": { state: "FL", city: "PENSACOLA" },
  "32523": { state: "FL", city: "PENSACOLA" },
  "32524": { state: "FL", city: "PENSACOLA" },
  "32526": { state: "FL", city: "PENSACOLA" },
  "32530": { state: "FL", city: "BAGDAD" },
  "32531": { state: "FL", city: "BAKER" },
  "32533": { state: "FL", city: "CANTONMENT" },
  "32534": { state: "FL", city: "PENSACOLA" },
  "32535": { state: "FL", city: "CENTURY" },
  "32536": { state: "FL", city: "CRESTVIEW" },
  "32537": { state: "FL", city: "MILLIGAN" },
  "32538": { state: "FL", city: "PAXTON" },
  "32539": { state: "FL", city: "CRESTVIEW" },
  "32540": { state: "FL", city: "DESTIN" },
  "32541": { state: "FL", city: "DESTIN" },
  "32542": { state: "FL", city: "EGLIN AFB" },
  "32544": { state: "FL", city: "HURLBURT FIELD" },
  "32547": { state: "FL", city: "FORT WALTON BEACH" },
  "32548": { state: "FL", city: "FORT WALTON BEACH" },
  "32549": { state: "FL", city: "FORT WALTON BEACH" },
  "32550": { state: "FL", city: "MIRAMAR BEACH" },
  "32559": { state: "FL", city: "PENSACOLA" },
  "32560": { state: "FL", city: "GONZALEZ" },
  "32561": { state: "FL", city: "GULF BREEZE" },
  "32562": { state: "FL", city: "GULF BREEZE" },
  "32563": { state: "FL", city: "GULF BREEZE" },
  "32564": { state: "FL", city: "HOLT" },
  "32565": { state: "FL", city: "JAY" },
  "32566": { state: "FL", city: "NAVARRE" },
  "32567": { state: "FL", city: "LAUREL HILL" },
  "32568": { state: "FL", city: "MC DAVID" },
  "32569": { state: "FL", city: "MARY ESTHER" },
  "32570": { state: "FL", city: "MILTON" },
  "32571": { state: "FL", city: "MILTON" },
  "32572": { state: "FL", city: "MILTON" },
  "32577": { state: "FL", city: "MOLINO" },
  "32578": { state: "FL", city: "NICEVILLE" },
  "32579": { state: "FL", city: "SHALIMAR" },
  "32580": { state: "FL", city: "VALPARAISO" },
  "32583": { state: "FL", city: "MILTON" },
  "32588": { state: "FL", city: "NICEVILLE" },
  "32591": { state: "FL", city: "PENSACOLA" },
  "32601": { state: "FL", city: "GAINESVILLE" },
  "32602": { state: "FL", city: "GAINESVILLE" },
  "32603": { state: "FL", city: "GAINESVILLE" },
  "32604": { state: "FL", city: "GAINESVILLE" },
  "32605": { state: "FL", city: "GAINESVILLE" },
  "32606": { state: "FL", city: "GAINESVILLE" },
  "32607": { state: "FL", city: "GAINESVILLE" },
  "32608": { state: "FL", city: "GAINESVILLE" },
  "32609": { state: "FL", city: "GAINESVILLE" },
  "32610": { state: "FL", city: "GAINESVILLE" },
  "32611": { state: "FL", city: "GAINESVILLE" },
  "32612": { state: "FL", city: "GAINESVILLE" },
  "32614": { state: "FL", city: "GAINESVILLE" },
  "32615": { state: "FL", city: "ALACHUA" },
  "32616": { state: "FL", city: "ALACHUA" },
  "32617": { state: "FL", city: "ANTHONY" },
  "32618": { state: "FL", city: "ARCHER" },
  "32619": { state: "FL", city: "BELL" },
  "32621": { state: "FL", city: "BRONSON" },
  "32622": { state: "FL", city: "BROOKER" },
  "32625": { state: "FL", city: "CEDAR KEY" },
  "32626": { state: "FL", city: "CHIEFLAND" },
  "32627": { state: "FL", city: "GAINESVILLE" },
  "32628": { state: "FL", city: "CROSS CITY" },
  "32631": { state: "FL", city: "EARLETON" },
  "32633": { state: "FL", city: "EVINSTON" },
  "32634": { state: "FL", city: "FAIRFIELD" },
  "32635": { state: "FL", city: "GAINESVILLE" },
  "32639": { state: "FL", city: "GULF HAMMOCK" },
  "32640": { state: "FL", city: "HAWTHORNE" },
  "32641": { state: "FL", city: "GAINESVILLE" },
  "32643": { state: "FL", city: "HIGH SPRINGS" },
  "32644": { state: "FL", city: "CHIEFLAND" },
  "32648": { state: "FL", city: "HORSESHOE BEACH" },
  "32653": { state: "FL", city: "GAINESVILLE" },
  "32654": { state: "FL", city: "ISLAND GROVE" },
  "32655": { state: "FL", city: "HIGH SPRINGS" },
  "32656": { state: "FL", city: "KEYSTONE HEIGHTS" },
  "32658": { state: "FL", city: "LA CROSSE" },
  "32662": { state: "FL", city: "LOCHLOOSA" },
  "32663": { state: "FL", city: "LOWELL" },
  "32664": { state: "FL", city: "MC INTOSH" },
  "32666": { state: "FL", city: "MELROSE" },
  "32667": { state: "FL", city: "MICANOPY" },
  "32668": { state: "FL", city: "MORRISTON" },
  "32669": { state: "FL", city: "NEWBERRY" },
  "32680": { state: "FL", city: "OLD TOWN" },
  "32681": { state: "FL", city: "ORANGE LAKE" },
  "32683": { state: "FL", city: "OTTER CREEK" },
  "32686": { state: "FL", city: "REDDICK" },
  "32692": { state: "FL", city: "SUWANNEE" },
  "32693": { state: "FL", city: "TRENTON" },
  "32694": { state: "FL", city: "WALDO" },
  "32696": { state: "FL", city: "WILLISTON" },
  "32697": { state: "FL", city: "WORTHINGTON SPRINGS" },
  "32701": { state: "FL", city: "ALTAMONTE SPRINGS" },
  "32702": { state: "FL", city: "ALTOONA" },
  "32703": { state: "FL", city: "APOPKA" },
  "32704": { state: "FL", city: "APOPKA" },
  "32706": { state: "FL", city: "CASSADAGA" },
  "32707": { state: "FL", city: "CASSELBERRY" },
  "32708": { state: "FL", city: "WINTER SPRINGS" },
  "32709": { state: "FL", city: "CHRISTMAS" },
  "32710": { state: "FL", city: "CLARCONA" },
  "32712": { state: "FL", city: "APOPKA" },
  "32713": { state: "FL", city: "DEBARY" },
  "32714": { state: "FL", city: "ALTAMONTE SPRINGS" },
  "32715": { state: "FL", city: "ALTAMONTE SPRINGS" },
  "32716": { state: "FL", city: "ALTAMONTE SPRINGS" },
  "32718": { state: "FL", city: "CASSELBERRY" },
  "32719": { state: "FL", city: "WINTER SPRINGS" },
  "32720": { state: "FL", city: "DELAND" },
  "32721": { state: "FL", city: "DELAND" },
  "32722": { state: "FL", city: "GLENWOOD" },
  "32723": { state: "FL", city: "DELAND" },
  "32724": { state: "FL", city: "DELAND" },
  "32725": { state: "FL", city: "DELTONA" },
  "32726": { state: "FL", city: "EUSTIS" },
  "32727": { state: "FL", city: "EUSTIS" },
  "32728": { state: "FL", city: "DELTONA" },
  "32730": { state: "FL", city: "CASSELBERRY" },
  "32732": { state: "FL", city: "GENEVA" },
  "32733": { state: "FL", city: "GOLDENROD" },
  "32735": { state: "FL", city: "GRAND ISLAND" },
  "32736": { state: "FL", city: "EUSTIS" },
  "32738": { state: "FL", city: "DELTONA" },
  "32739": { state: "FL", city: "DELTONA" },
  "32744": { state: "FL", city: "LAKE HELEN" },
  "32745": { state: "FL", city: "MID FLORIDA" },
  "32746": { state: "FL", city: "LAKE MARY" },
  "32747": { state: "FL", city: "LAKE MONROE" },
  "32750": { state: "FL", city: "LONGWOOD" },
  "32751": { state: "FL", city: "MAITLAND" },
  "32752": { state: "FL", city: "LONGWOOD" },
  "32753": { state: "FL", city: "DEBARY" },
  "32754": { state: "FL", city: "MIMS" },
  "32756": { state: "FL", city: "MOUNT DORA" },
  "32757": { state: "FL", city: "MOUNT DORA" },
  "32759": { state: "FL", city: "OAK HILL" },
  "32762": { state: "FL", city: "OVIEDO" },
  "32763": { state: "FL", city: "ORANGE CITY" },
  "32764": { state: "FL", city: "OSTEEN" },
  "32765": { state: "FL", city: "OVIEDO" },
  "32766": { state: "FL", city: "OVIEDO" },
  "32767": { state: "FL", city: "PAISLEY" },
  "32768": { state: "FL", city: "PLYMOUTH" },
  "32771": { state: "FL", city: "SANFORD" },
  "32772": { state: "FL", city: "SANFORD" },
  "32773": { state: "FL", city: "SANFORD" },
  "32774": { state: "FL", city: "ORANGE CITY" },
  "32775": { state: "FL", city: "SCOTTSMOOR" },
  "32776": { state: "FL", city: "SORRENTO" },
  "32777": { state: "FL", city: "TANGERINE" },
  "32778": { state: "FL", city: "TAVARES" },
  "32779": { state: "FL", city: "LONGWOOD" },
  "32780": { state: "FL", city: "TITUSVILLE" },
  "32781": { state: "FL", city: "TITUSVILLE" },
  "32783": { state: "FL", city: "TITUSVILLE" },
  "32784": { state: "FL", city: "UMATILLA" },
  "32789": { state: "FL", city: "WINTER PARK" },
  "32790": { state: "FL", city: "WINTER PARK" },
  "32791": { state: "FL", city: "LONGWOOD" },
  "32792": { state: "FL", city: "WINTER PARK" },
  "32793": { state: "FL", city: "WINTER PARK" },
  "32794": { state: "FL", city: "MAITLAND" },
  "32795": { state: "FL", city: "LAKE MARY" },
  "32796": { state: "FL", city: "TITUSVILLE" },
  "32798": { state: "FL", city: "ZELLWOOD" },
  "32799": { state: "FL", city: "MID FLORIDA" },
  "32801": { state: "FL", city: "ORLANDO" },
  "32802": { state: "FL", city: "ORLANDO" },
  "32803": { state: "FL", city: "ORLANDO" },
  "32804": { state: "FL", city: "ORLANDO" },
  "32805": { state: "FL", city: "ORLANDO" },
  "32806": { state: "FL", city: "ORLANDO" },
  "32807": { state: "FL", city: "ORLANDO" },
  "32808": { state: "FL", city: "ORLANDO" },
  "32809": { state: "FL", city: "ORLANDO" },
  "32810": { state: "FL", city: "ORLANDO" },
  "32811": { state: "FL", city: "ORLANDO" },
  "32812": { state: "FL", city: "ORLANDO" },
  "32814": { state: "FL", city: "ORLANDO" },
  "32815": { state: "FL", city: "ORLANDO" },
  "32816": { state: "FL", city: "ORLANDO" },
  "32817": { state: "FL", city: "ORLANDO" },
  "32818": { state: "FL", city: "ORLANDO" },
  "32819": { state: "FL", city: "ORLANDO" },
  "32820": { state: "FL", city: "ORLANDO" },
  "32821": { state: "FL", city: "ORLANDO" },
  "32822": { state: "FL", city: "ORLANDO" },
  "32824": { state: "FL", city: "ORLANDO" },
  "32825": { state: "FL", city: "ORLANDO" },
  "32826": { state: "FL", city: "ORLANDO" },
  "32827": { state: "FL", city: "ORLANDO" },
  "32828": { state: "FL", city: "ORLANDO" },
  "32829": { state: "FL", city: "ORLANDO" },
  "32830": { state: "FL", city: "ORLANDO" },
  "32831": { state: "FL", city: "ORLANDO" },
  "32832": { state: "FL", city: "ORLANDO" },
  "32833": { state: "FL", city: "ORLANDO" },
  "32834": { state: "FL", city: "ORLANDO" },
  "32835": { state: "FL", city: "ORLANDO" },
  "32836": { state: "FL", city: "ORLANDO" },
  "32837": { state: "FL", city: "ORLANDO" },
  "32839": { state: "FL", city: "ORLANDO" },
  "32853": { state: "FL", city: "ORLANDO" },
  "32854": { state: "FL", city: "ORLANDO" },
  "32855": { state: "FL", city: "ORLANDO" },
  "32856": { state: "FL", city: "ORLANDO" },
  "32857": { state: "FL", city: "ORLANDO" },
  "32858": { state: "FL", city: "ORLANDO" },
  "32859": { state: "FL", city: "ORLANDO" },
  "32860": { state: "FL", city: "ORLANDO" },
  "32861": { state: "FL", city: "ORLANDO" },
  "32862": { state: "FL", city: "ORLANDO" },
  "32867": { state: "FL", city: "ORLANDO" },
  "32868": { state: "FL", city: "ORLANDO" },
  "32869": { state: "FL", city: "ORLANDO" },
  "32872": { state: "FL", city: "ORLANDO" },
  "32877": { state: "FL", city: "ORLANDO" },
  "32878": { state: "FL", city: "ORLANDO" },
  "32885": { state: "FL", city: "ORLANDO" },
  "32886": { state: "FL", city: "ORLANDO" },
  "32887": { state: "FL", city: "ORLANDO" },
  "32891": { state: "FL", city: "ORLANDO" },
  "32896": { state: "FL", city: "ORLANDO" },
  "32897": { state: "FL", city: "ORLANDO" },
  "32899": { state: "FL", city: "ORLANDO" },
  "32901": { state: "FL", city: "MELBOURNE" },
  "32902": { state: "FL", city: "MELBOURNE" },
  "32903": { state: "FL", city: "INDIALANTIC" },
  "32904": { state: "FL", city: "MELBOURNE" },
  "32905": { state: "FL", city: "PALM BAY" },
  "32906": { state: "FL", city: "PALM BAY" },
  "32907": { state: "FL", city: "PALM BAY" },
  "32908": { state: "FL", city: "PALM BAY" },
  "32909": { state: "FL", city: "PALM BAY" },
  "32910": { state: "FL", city: "PALM BAY" },
  "32911": { state: "FL", city: "PALM BAY" },
  "32912": { state: "FL", city: "MELBOURNE" },
  "32919": { state: "FL", city: "MELBOURNE" },
  "32920": { state: "FL", city: "CAPE CANAVERAL" },
  "32922": { state: "FL", city: "COCOA" },
  "32923": { state: "FL", city: "COCOA" },
  "32924": { state: "FL", city: "COCOA" },
  "32925": { state: "FL", city: "PATRICK AFB" },
  "32926": { state: "FL", city: "COCOA" },
  "32927": { state: "FL", city: "COCOA" },
  "32931": { state: "FL", city: "COCOA BEACH" },
  "32932": { state: "FL", city: "COCOA BEACH" },
  "32934": { state: "FL", city: "MELBOURNE" },
  "32935": { state: "FL", city: "MELBOURNE" },
  "32936": { state: "FL", city: "MELBOURNE" },
  "32937": { state: "FL", city: "SATELLITE BEACH" },
  "32940": { state: "FL", city: "MELBOURNE" },
  "32941": { state: "FL", city: "MELBOURNE" },
  "32948": { state: "FL", city: "FELLSMERE" },
  "32949": { state: "FL", city: "GRANT" },
  "32950": { state: "FL", city: "MALABAR" },
  "32951": { state: "FL", city: "MELBOURNE BEACH" },
  "32952": { state: "FL", city: "MERRITT ISLAND" },
  "32953": { state: "FL", city: "MERRITT ISLAND" },
  "32954": { state: "FL", city: "MERRITT ISLAND" },
  "32955": { state: "FL", city: "ROCKLEDGE" },
  "32956": { state: "FL", city: "ROCKLEDGE" },
  "32957": { state: "FL", city: "ROSELAND" },
  "32958": { state: "FL", city: "SEBASTIAN" },
  "32959": { state: "FL", city: "SHARPES" },
  "32960": { state: "FL", city: "VERO BEACH" },
  "32961": { state: "FL", city: "VERO BEACH" },
  "32962": { state: "FL", city: "VERO BEACH" },
  "32963": { state: "FL", city: "VERO BEACH" },
  "32964": { state: "FL", city: "VERO BEACH" },
  "32965": { state: "FL", city: "VERO BEACH" },
  "32966": { state: "FL", city: "VERO BEACH" },
  "32967": { state: "FL", city: "VERO BEACH" },
  "32968": { state: "FL", city: "VERO BEACH" },
  "32969": { state: "FL", city: "VERO BEACH" },
  "32970": { state: "FL", city: "WABASSO" },
  "32971": { state: "FL", city: "WINTER BEACH" },
  "32976": { state: "FL", city: "SEBASTIAN" },
  "32978": { state: "FL", city: "SEBASTIAN" },
  "33001": { state: "FL", city: "LONG KEY" },
  "33002": { state: "FL", city: "HIALEAH" },
  "33004": { state: "FL", city: "DANIA" },
  "33008": { state: "FL", city: "HALLANDALE" },
  "33009": { state: "FL", city: "HALLANDALE" },
  "33010": { state: "FL", city: "HIALEAH" },
  "33011": { state: "FL", city: "HIALEAH" },
  "33012": { state: "FL", city: "HIALEAH" },
  "33013": { state: "FL", city: "HIALEAH" },
  "33014": { state: "FL", city: "HIALEAH" },
  "33015": { state: "FL", city: "HIALEAH" },
  "33016": { state: "FL", city: "HIALEAH" },
  "33017": { state: "FL", city: "HIALEAH" },
  "33018": { state: "FL", city: "HIALEAH" },
  "33019": { state: "FL", city: "HOLLYWOOD" },
  "33020": { state: "FL", city: "HOLLYWOOD" },
  "33021": { state: "FL", city: "HOLLYWOOD" },
  "33022": { state: "FL", city: "HOLLYWOOD" },
  "33023": { state: "FL", city: "HOLLYWOOD" },
  "33024": { state: "FL", city: "HOLLYWOOD" },
  "33025": { state: "FL", city: "HOLLYWOOD" },
  "33026": { state: "FL", city: "HOLLYWOOD" },
  "33027": { state: "FL", city: "HOLLYWOOD" },
  "33028": { state: "FL", city: "PEMBROKE PINES" },
  "33029": { state: "FL", city: "HOLLYWOOD" },
  "33030": { state: "FL", city: "HOMESTEAD" },
  "33031": { state: "FL", city: "HOMESTEAD" },
  "33032": { state: "FL", city: "HOMESTEAD" },
  "33033": { state: "FL", city: "HOMESTEAD" },
  "33034": { state: "FL", city: "HOMESTEAD" },
  "33035": { state: "FL", city: "HOMESTEAD" },
  "33036": { state: "FL", city: "ISLAMORADA" },
  "33037": { state: "FL", city: "KEY LARGO" },
  "33039": { state: "FL", city: "HOMESTEAD" },
  "33040": { state: "FL", city: "KEY WEST" },
  "33041": { state: "FL", city: "KEY WEST" },
  "33042": { state: "FL", city: "SUMMERLAND KEY" },
  "33043": { state: "FL", city: "BIG PINE KEY" },
  "33045": { state: "FL", city: "KEY WEST" },
  "33050": { state: "FL", city: "MARATHON" },
  "33051": { state: "FL", city: "KEY COLONY BEACH" },
  "33052": { state: "FL", city: "MARATHON SHORES" },
  "33054": { state: "FL", city: "OPA LOCKA" },
  "33055": { state: "FL", city: "OPA LOCKA" },
  "33056": { state: "FL", city: "MIAMI GARDENS" },
  "33060": { state: "FL", city: "POMPANO BEACH" },
  "33061": { state: "FL", city: "POMPANO BEACH" },
  "33062": { state: "FL", city: "POMPANO BEACH" },
  "33063": { state: "FL", city: "POMPANO BEACH" },
  "33064": { state: "FL", city: "POMPANO BEACH" },
  "33065": { state: "FL", city: "CORAL SPRINGS" },
  "33066": { state: "FL", city: "POMPANO BEACH" },
  "33067": { state: "FL", city: "POMPANO BEACH" },
  "33068": { state: "FL", city: "POMPANO BEACH" },
  "33069": { state: "FL", city: "POMPANO BEACH" },
  "33070": { state: "FL", city: "TAVERNIER" },
  "33071": { state: "FL", city: "CORAL SPRINGS" },
  "33072": { state: "FL", city: "POMPANO BEACH" },
  "33073": { state: "FL", city: "POMPANO BEACH" },
  "33074": { state: "FL", city: "POMPANO BEACH" },
  "33075": { state: "FL", city: "CORAL SPRINGS" },
  "33076": { state: "FL", city: "POMPANO BEACH" },
  "33077": { state: "FL", city: "POMPANO BEACH" },
  "33081": { state: "FL", city: "HOLLYWOOD" },
  "33082": { state: "FL", city: "PEMBROKE PINES" },
  "33083": { state: "FL", city: "HOLLYWOOD" },
  "33084": { state: "FL", city: "HOLLYWOOD" },
  "33090": { state: "FL", city: "HOMESTEAD" },
  "33092": { state: "FL", city: "HOMESTEAD" },
  "33093": { state: "FL", city: "MARGATE" },
  "33097": { state: "FL", city: "COCONUT CREEK" },
  "33101": { state: "FL", city: "MIAMI" },
  "33102": { state: "FL", city: "MIAMI" },
  "33106": { state: "FL", city: "MIAMI" },
  "33109": { state: "FL", city: "MIAMI BEACH" },
  "33111": { state: "FL", city: "MIAMI" },
  "33112": { state: "FL", city: "MIAMI" },
  "33114": { state: "FL", city: "CORAL GABLES" },
  "33116": { state: "FL", city: "MIAMI" },
  "33119": { state: "FL", city: "MIAMI BEACH" },
  "33122": { state: "FL", city: "MIAMI" },
  "33124": { state: "FL", city: "MIAMI" },
  "33125": { state: "FL", city: "MIAMI" },
  "33126": { state: "FL", city: "MIAMI" },
  "33127": { state: "FL", city: "MIAMI" },
  "33128": { state: "FL", city: "MIAMI" },
  "33129": { state: "FL", city: "MIAMI" },
  "33130": { state: "FL", city: "MIAMI" },
  "33131": { state: "FL", city: "MIAMI" },
  "33132": { state: "FL", city: "MIAMI" },
  "33133": { state: "FL", city: "MIAMI" },
  "33134": { state: "FL", city: "MIAMI" },
  "33135": { state: "FL", city: "MIAMI" },
  "33136": { state: "FL", city: "MIAMI" },
  "33137": { state: "FL", city: "MIAMI" },
  "33138": { state: "FL", city: "MIAMI" },
  "33139": { state: "FL", city: "MIAMI BEACH" },
  "33140": { state: "FL", city: "MIAMI BEACH" },
  "33141": { state: "FL", city: "MIAMI BEACH" },
  "33142": { state: "FL", city: "MIAMI" },
  "33143": { state: "FL", city: "MIAMI" },
  "33144": { state: "FL", city: "MIAMI" },
  "33145": { state: "FL", city: "MIAMI" },
  "33146": { state: "FL", city: "MIAMI" },
  "33147": { state: "FL", city: "MIAMI" },
  "33149": { state: "FL", city: "KEY BISCAYNE" },
  "33150": { state: "FL", city: "MIAMI" },
  "33151": { state: "FL", city: "MIAMI" },
  "33152": { state: "FL", city: "MIAMI" },
  "33153": { state: "FL", city: "MIAMI" },
  "33154": { state: "FL", city: "MIAMI BEACH" },
  "33155": { state: "FL", city: "MIAMI" },
  "33156": { state: "FL", city: "MIAMI" },
  "33157": { state: "FL", city: "MIAMI" },
  "33158": { state: "FL", city: "MIAMI" },
  "33160": { state: "FL", city: "NORTH MIAMI BEACH" },
  "33161": { state: "FL", city: "MIAMI" },
  "33162": { state: "FL", city: "MIAMI" },
  "33163": { state: "FL", city: "MIAMI" },
  "33164": { state: "FL", city: "MIAMI" },
  "33165": { state: "FL", city: "MIAMI" },
  "33166": { state: "FL", city: "MIAMI" },
  "33167": { state: "FL", city: "MIAMI" },
  "33168": { state: "FL", city: "MIAMI" },
  "33169": { state: "FL", city: "MIAMI" },
  "33170": { state: "FL", city: "MIAMI" },
  "33172": { state: "FL", city: "MIAMI" },
  "33173": { state: "FL", city: "MIAMI" },
  "33174": { state: "FL", city: "MIAMI" },
  "33175": { state: "FL", city: "MIAMI" },
  "33176": { state: "FL", city: "MIAMI" },
  "33177": { state: "FL", city: "MIAMI" },
  "33178": { state: "FL", city: "MIAMI" },
  "33179": { state: "FL", city: "MIAMI" },
  "33180": { state: "FL", city: "MIAMI" },
  "33181": { state: "FL", city: "MIAMI" },
  "33182": { state: "FL", city: "MIAMI" },
  "33183": { state: "FL", city: "MIAMI" },
  "33184": { state: "FL", city: "MIAMI" },
  "33185": { state: "FL", city: "MIAMI" },
  "33186": { state: "FL", city: "MIAMI" },
  "33187": { state: "FL", city: "MIAMI" },
  "33188": { state: "FL", city: "MIAMI" },
  "33189": { state: "FL", city: "MIAMI" },
  "33190": { state: "FL", city: "MIAMI" },
  "33191": { state: "FL", city: "MIAMI" },
  "33192": { state: "FL", city: "MIAMI" },
  "33193": { state: "FL", city: "MIAMI" },
  "33194": { state: "FL", city: "MIAMI" },
  "33195": { state: "FL", city: "MIAMI" },
  "33196": { state: "FL", city: "MIAMI" },
  "33197": { state: "FL", city: "MIAMI" },
  "33198": { state: "FL", city: "MIAMI" },
  "33199": { state: "FL", city: "MIAMI" },
  "33206": { state: "FL", city: "MIAMI" },
  "33222": { state: "FL", city: "MIAMI" },
  "33231": { state: "FL", city: "MIAMI" },
  "33233": { state: "FL", city: "MIAMI" },
  "33234": { state: "FL", city: "MIAMI" },
  "33238": { state: "FL", city: "MIAMI" },
  "33239": { state: "FL", city: "MIAMI BEACH" },
  "33242": { state: "FL", city: "MIAMI" },
  "33243": { state: "FL", city: "MIAMI" },
  "33245": { state: "FL", city: "MIAMI" },
  "33247": { state: "FL", city: "MIAMI" },
  "33255": { state: "FL", city: "MIAMI" },
  "33256": { state: "FL", city: "MIAMI" },
  "33257": { state: "FL", city: "MIAMI" },
  "33261": { state: "FL", city: "MIAMI" },
  "33265": { state: "FL", city: "MIAMI" },
  "33266": { state: "FL", city: "MIAMI" },
  "33269": { state: "FL", city: "MIAMI" },
  "33280": { state: "FL", city: "MIAMI" },
  "33283": { state: "FL", city: "MIAMI" },
  "33296": { state: "FL", city: "MIAMI" },
  "33299": { state: "FL", city: "MIAMI" },
  "33301": { state: "FL", city: "FORT LAUDERDALE" },
  "33302": { state: "FL", city: "FORT LAUDERDALE" },
  "33303": { state: "FL", city: "FORT LAUDERDALE" },
  "33304": { state: "FL", city: "FORT LAUDERDALE" },
  "33305": { state: "FL", city: "FORT LAUDERDALE" },
  "33306": { state: "FL", city: "FORT LAUDERDALE" },
  "33307": { state: "FL", city: "FORT LAUDERDALE" },
  "33308": { state: "FL", city: "FORT LAUDERDALE" },
  "33309": { state: "FL", city: "FORT LAUDERDALE" },
  "33310": { state: "FL", city: "FORT LAUDERDALE" },
  "33311": { state: "FL", city: "FORT LAUDERDALE" },
  "33312": { state: "FL", city: "FORT LAUDERDALE" },
  "33313": { state: "FL", city: "FORT LAUDERDALE" },
  "33314": { state: "FL", city: "FORT LAUDERDALE" },
  "33315": { state: "FL", city: "FORT LAUDERDALE" },
  "33316": { state: "FL", city: "FORT LAUDERDALE" },
  "33317": { state: "FL", city: "FORT LAUDERDALE" },
  "33318": { state: "FL", city: "FORT LAUDERDALE" },
  "33319": { state: "FL", city: "FORT LAUDERDALE" },
  "33320": { state: "FL", city: "FORT LAUDERDALE" },
  "33321": { state: "FL", city: "FORT LAUDERDALE" },
  "33322": { state: "FL", city: "FORT LAUDERDALE" },
  "33323": { state: "FL", city: "FORT LAUDERDALE" },
  "33324": { state: "FL", city: "FORT LAUDERDALE" },
  "33325": { state: "FL", city: "FORT LAUDERDALE" },
  "33326": { state: "FL", city: "FORT LAUDERDALE" },
  "33327": { state: "FL", city: "FORT LAUDERDALE" },
  "33328": { state: "FL", city: "FORT LAUDERDALE" },
  "33329": { state: "FL", city: "FORT LAUDERDALE" },
  "33330": { state: "FL", city: "FORT LAUDERDALE" },
  "33331": { state: "FL", city: "FORT LAUDERDALE" },
  "33332": { state: "FL", city: "FORT LAUDERDALE" },
  "33334": { state: "FL", city: "FORT LAUDERDALE" },
  "33335": { state: "FL", city: "FORT LAUDERDALE" },
  "33336": { state: "FL", city: "FORT LAUDERDALE" },
  "33337": { state: "FL", city: "FORT LAUDERDALE" },
  "33338": { state: "FL", city: "FORT LAUDERDALE" },
  "33339": { state: "FL", city: "FORT LAUDERDALE" },
  "33340": { state: "FL", city: "FORT LAUDERDALE" },
  "33345": { state: "FL", city: "FORT LAUDERDALE" },
  "33346": { state: "FL", city: "FORT LAUDERDALE" },
  "33348": { state: "FL", city: "FORT LAUDERDALE" },
  "33349": { state: "FL", city: "FORT LAUDERDALE" },
  "33351": { state: "FL", city: "FORT LAUDERDALE" },
  "33355": { state: "FL", city: "FORT LAUDERDALE" },
  "33359": { state: "FL", city: "FORT LAUDERDALE" },
  "33388": { state: "FL", city: "PLANTATION" },
  "33394": { state: "FL", city: "FORT LAUDERDALE" },
  "33401": { state: "FL", city: "WEST PALM BEACH" },
  "33402": { state: "FL", city: "WEST PALM BEACH" },
  "33403": { state: "FL", city: "WEST PALM BEACH" },
  "33404": { state: "FL", city: "WEST PALM BEACH" },
  "33405": { state: "FL", city: "WEST PALM BEACH" },
  "33406": { state: "FL", city: "WEST PALM BEACH" },
  "33407": { state: "FL", city: "WEST PALM BEACH" },
  "33408": { state: "FL", city: "NORTH PALM BEACH" },
  "33409": { state: "FL", city: "WEST PALM BEACH" },
  "33410": { state: "FL", city: "PALM BEACH GARDENS" },
  "33411": { state: "FL", city: "WEST PALM BEACH" },
  "33412": { state: "FL", city: "WEST PALM BEACH" },
  "33413": { state: "FL", city: "WEST PALM BEACH" },
  "33414": { state: "FL", city: "WELLINGTON" },
  "33415": { state: "FL", city: "WEST PALM BEACH" },
  "33416": { state: "FL", city: "WEST PALM BEACH" },
  "33417": { state: "FL", city: "WEST PALM BEACH" },
  "33418": { state: "FL", city: "PALM BEACH GARDENS" },
  "33419": { state: "FL", city: "WEST PALM BEACH" },
  "33420": { state: "FL", city: "WEST PALM BEACH" },
  "33421": { state: "FL", city: "ROYAL PALM BEACH" },
  "33422": { state: "FL", city: "WEST PALM BEACH" },
  "33424": { state: "FL", city: "BOYNTON BEACH" },
  "33425": { state: "FL", city: "BOYNTON BEACH" },
  "33426": { state: "FL", city: "BOYNTON BEACH" },
  "33427": { state: "FL", city: "BOCA RATON" },
  "33428": { state: "FL", city: "BOCA RATON" },
  "33429": { state: "FL", city: "BOCA RATON" },
  "33430": { state: "FL", city: "BELLE GLADE" },
  "33431": { state: "FL", city: "BOCA RATON" },
  "33432": { state: "FL", city: "BOCA RATON" },
  "33433": { state: "FL", city: "BOCA RATON" },
  "33434": { state: "FL", city: "BOCA RATON" },
  "33435": { state: "FL", city: "BOYNTON BEACH" },
  "33436": { state: "FL", city: "BOYNTON BEACH" },
  "33437": { state: "FL", city: "BOYNTON BEACH" },
  "33438": { state: "FL", city: "CANAL POINT" },
  "33440": { state: "FL", city: "CLEWISTON" },
  "33441": { state: "FL", city: "DEERFIELD BEACH" },
  "33442": { state: "FL", city: "DEERFIELD BEACH" },
  "33443": { state: "FL", city: "DEERFIELD BEACH" },
  "33444": { state: "FL", city: "DELRAY BEACH" },
  "33445": { state: "FL", city: "DELRAY BEACH" },
  "33446": { state: "FL", city: "DELRAY BEACH" },
  "33448": { state: "FL", city: "DELRAY BEACH" },
  "33449": { state: "FL", city: "LAKE WORTH" },
  "33454": { state: "FL", city: "GREENACRES" },
  "33455": { state: "FL", city: "HOBE SOUND" },
  "33458": { state: "FL", city: "JUPITER" },
  "33459": { state: "FL", city: "LAKE HARBOR" },
  "33460": { state: "FL", city: "LAKE WORTH BEACH" },
  "33461": { state: "FL", city: "LAKE WORTH" },
  "33462": { state: "FL", city: "LAKE WORTH" },
  "33463": { state: "FL", city: "LAKE WORTH" },
  "33464": { state: "FL", city: "BOCA RATON" },
  "33465": { state: "FL", city: "LAKE WORTH" },
  "33466": { state: "FL", city: "LAKE WORTH" },
  "33467": { state: "FL", city: "LAKE WORTH" },
  "33468": { state: "FL", city: "JUPITER" },
  "33469": { state: "FL", city: "JUPITER" },
  "33470": { state: "FL", city: "LOXAHATCHEE" },
  "33471": { state: "FL", city: "MOORE HAVEN" },
  "33472": { state: "FL", city: "BOYNTON BEACH" },
  "33473": { state: "FL", city: "BOYNTON BEACH" },
  "33474": { state: "FL", city: "BOYNTON BEACH" },
  "33475": { state: "FL", city: "HOBE SOUND" },
  "33476": { state: "FL", city: "PAHOKEE" },
  "33477": { state: "FL", city: "JUPITER" },
  "33478": { state: "FL", city: "JUPITER" },
  "33480": { state: "FL", city: "PALM BEACH" },
  "33481": { state: "FL", city: "BOCA RATON" },
  "33482": { state: "FL", city: "DELRAY BEACH" },
  "33483": { state: "FL", city: "DELRAY BEACH" },
  "33484": { state: "FL", city: "DELRAY BEACH" },
  "33486": { state: "FL", city: "BOCA RATON" },
  "33487": { state: "FL", city: "BOCA RATON" },
  "33488": { state: "FL", city: "BOCA RATON" },
  "33493": { state: "FL", city: "SOUTH BAY" },
  "33496": { state: "FL", city: "BOCA RATON" },
  "33497": { state: "FL", city: "BOCA RATON" },
  "33498": { state: "FL", city: "BOCA RATON" },
  "33499": { state: "FL", city: "BOCA RATON" },
  "33503": { state: "FL", city: "BALM" },
  "33508": { state: "FL", city: "BRANDON" },
  "33509": { state: "FL", city: "BRANDON" },
  "33510": { state: "FL", city: "BRANDON" },
  "33511": { state: "FL", city: "BRANDON" },
  "33513": { state: "FL", city: "BUSHNELL" },
  "33514": { state: "FL", city: "CENTER HILL" },
  "33521": { state: "FL", city: "COLEMAN" },
  "33523": { state: "FL", city: "DADE CITY" },
  "33524": { state: "FL", city: "CRYSTAL SPRINGS" },
  "33525": { state: "FL", city: "DADE CITY" },
  "33526": { state: "FL", city: "DADE CITY" },
  "33527": { state: "FL", city: "DOVER" },
  "33530": { state: "FL", city: "DURANT" },
  "33534": { state: "FL", city: "GIBSONTON" },
  "33537": { state: "FL", city: "LACOOCHEE" },
  "33538": { state: "FL", city: "LAKE PANASOFFKEE" },
  "33539": { state: "FL", city: "ZEPHYRHILLS" },
  "33540": { state: "FL", city: "ZEPHYRHILLS" },
  "33541": { state: "FL", city: "ZEPHYRHILLS" },
  "33542": { state: "FL", city: "ZEPHYRHILLS" },
  "33543": { state: "FL", city: "WESLEY CHAPEL" },
  "33544": { state: "FL", city: "WESLEY CHAPEL" },
  "33545": { state: "FL", city: "WESLEY CHAPEL" },
  "33547": { state: "FL", city: "LITHIA" },
  "33548": { state: "FL", city: "LUTZ" },
  "33549": { state: "FL", city: "LUTZ" },
  "33550": { state: "FL", city: "MANGO" },
  "33556": { state: "FL", city: "ODESSA" },
  "33558": { state: "FL", city: "LUTZ" },
  "33559": { state: "FL", city: "LUTZ" },
  "33563": { state: "FL", city: "PLANT CITY" },
  "33564": { state: "FL", city: "PLANT CITY" },
  "33565": { state: "FL", city: "PLANT CITY" },
  "33566": { state: "FL", city: "PLANT CITY" },
  "33567": { state: "FL", city: "PLANT CITY" },
  "33568": { state: "FL", city: "RIVERVIEW" },
  "33569": { state: "FL", city: "RIVERVIEW" },
  "33570": { state: "FL", city: "RUSKIN" },
  "33571": { state: "FL", city: "SUN CITY CENTER" },
  "33572": { state: "FL", city: "APOLLO BEACH" },
  "33573": { state: "FL", city: "SUN CITY CENTER" },
  "33574": { state: "FL", city: "SAINT LEO" },
  "33575": { state: "FL", city: "RUSKIN" },
  "33576": { state: "FL", city: "SAN ANTONIO" },
  "33578": { state: "FL", city: "RIVERVIEW" },
  "33579": { state: "FL", city: "RIVERVIEW" },
  "33583": { state: "FL", city: "SEFFNER" },
  "33584": { state: "FL", city: "SEFFNER" },
  "33585": { state: "FL", city: "SUMTERVILLE" },
  "33586": { state: "FL", city: "SUN CITY" },
  "33587": { state: "FL", city: "SYDNEY" },
  "33592": { state: "FL", city: "THONOTOSASSA" },
  "33593": { state: "FL", city: "TRILBY" },
  "33594": { state: "FL", city: "VALRICO" },
  "33595": { state: "FL", city: "VALRICO" },
  "33596": { state: "FL", city: "VALRICO" },
  "33597": { state: "FL", city: "WEBSTER" },
  "33598": { state: "FL", city: "WIMAUMA" },
  "33601": { state: "FL", city: "TAMPA" },
  "33602": { state: "FL", city: "TAMPA" },
  "33603": { state: "FL", city: "TAMPA" },
  "33604": { state: "FL", city: "TAMPA" },
  "33605": { state: "FL", city: "TAMPA" },
  "33606": { state: "FL", city: "TAMPA" },
  "33607": { state: "FL", city: "TAMPA" },
  "33608": { state: "FL", city: "TAMPA" },
  "33609": { state: "FL", city: "TAMPA" },
  "33610": { state: "FL", city: "TAMPA" },
  "33611": { state: "FL", city: "TAMPA" },
  "33612": { state: "FL", city: "TAMPA" },
  "33613": { state: "FL", city: "TAMPA" },
  "33614": { state: "FL", city: "TAMPA" },
  "33615": { state: "FL", city: "TAMPA" },
  "33616": { state: "FL", city: "TAMPA" },
  "33617": { state: "FL", city: "TAMPA" },
  "33618": { state: "FL", city: "TAMPA" },
  "33619": { state: "FL", city: "TAMPA" },
  "33620": { state: "FL", city: "TAMPA" },
  "33621": { state: "FL", city: "TAMPA" },
  "33622": { state: "FL", city: "TAMPA" },
  "33623": { state: "FL", city: "TAMPA" },
  "33624": { state: "FL", city: "TAMPA" },
  "33625": { state: "FL", city: "TAMPA" },
  "33626": { state: "FL", city: "TAMPA" },
  "33629": { state: "FL", city: "TAMPA" },
  "33630": { state: "FL", city: "TAMPA" },
  "33631": { state: "FL", city: "TAMPA" },
  "33633": { state: "FL", city: "TAMPA" },
  "33634": { state: "FL", city: "TAMPA" },
  "33635": { state: "FL", city: "TAMPA" },
  "33637": { state: "FL", city: "TAMPA" },
  "33646": { state: "FL", city: "TAMPA" },
  "33647": { state: "FL", city: "TAMPA" },
  "33650": { state: "FL", city: "TAMPA" },
  "33655": { state: "FL", city: "TAMPA" },
  "33660": { state: "FL", city: "TAMPA" },
  "33664": { state: "FL", city: "TAMPA" },
  "33672": { state: "FL", city: "TAMPA" },
  "33673": { state: "FL", city: "TAMPA" },
  "33674": { state: "FL", city: "TAMPA" },
  "33675": { state: "FL", city: "TAMPA" },
  "33677": { state: "FL", city: "TAMPA" },
  "33679": { state: "FL", city: "TAMPA" },
  "33680": { state: "FL", city: "TAMPA" },
  "33681": { state: "FL", city: "TAMPA" },
  "33682": { state: "FL", city: "TAMPA" },
  "33684": { state: "FL", city: "TAMPA" },
  "33685": { state: "FL", city: "TAMPA" },
  "33686": { state: "FL", city: "TAMPA" },
  "33687": { state: "FL", city: "TAMPA" },
  "33688": { state: "FL", city: "TAMPA" },
  "33689": { state: "FL", city: "TAMPA" },
  "33694": { state: "FL", city: "TAMPA" },
  "33701": { state: "FL", city: "SAINT PETERSBURG" },
  "33702": { state: "FL", city: "SAINT PETERSBURG" },
  "33703": { state: "FL", city: "SAINT PETERSBURG" },
  "33704": { state: "FL", city: "SAINT PETERSBURG" },
  "33705": { state: "FL", city: "SAINT PETERSBURG" },
  "33706": { state: "FL", city: "SAINT PETERSBURG" },
  "33707": { state: "FL", city: "SAINT PETERSBURG" },
  "33708": { state: "FL", city: "SAINT PETERSBURG" },
  "33709": { state: "FL", city: "SAINT PETERSBURG" },
  "33710": { state: "FL", city: "SAINT PETERSBURG" },
  "33711": { state: "FL", city: "SAINT PETERSBURG" },
  "33712": { state: "FL", city: "SAINT PETERSBURG" },
  "33713": { state: "FL", city: "SAINT PETERSBURG" },
  "33714": { state: "FL", city: "SAINT PETERSBURG" },
  "33715": { state: "FL", city: "SAINT PETERSBURG" },
  "33716": { state: "FL", city: "SAINT PETERSBURG" },
  "33729": { state: "FL", city: "SAINT PETERSBURG" },
  "33730": { state: "FL", city: "SAINT PETERSBURG" },
  "33731": { state: "FL", city: "SAINT PETERSBURG" },
  "33732": { state: "FL", city: "SAINT PETERSBURG" },
  "33733": { state: "FL", city: "SAINT PETERSBURG" },
  "33734": { state: "FL", city: "SAINT PETERSBURG" },
  "33736": { state: "FL", city: "SAINT PETERSBURG" },
  "33738": { state: "FL", city: "SAINT PETERSBURG" },
  "33740": { state: "FL", city: "SAINT PETERSBURG" },
  "33741": { state: "FL", city: "SAINT PETERSBURG" },
  "33742": { state: "FL", city: "SAINT PETERSBURG" },
  "33743": { state: "FL", city: "SAINT PETERSBURG" },
  "33744": { state: "FL", city: "BAY PINES" },
  "33747": { state: "FL", city: "SAINT PETERSBURG" },
  "33755": { state: "FL", city: "CLEARWATER" },
  "33756": { state: "FL", city: "CLEARWATER" },
  "33757": { state: "FL", city: "CLEARWATER" },
  "33758": { state: "FL", city: "CLEARWATER" },
  "33759": { state: "FL", city: "CLEARWATER" },
  "33760": { state: "FL", city: "CLEARWATER" },
  "33761": { state: "FL", city: "CLEARWATER" },
  "33762": { state: "FL", city: "CLEARWATER" },
  "33763": { state: "FL", city: "CLEARWATER" },
  "33764": { state: "FL", city: "CLEARWATER" },
  "33765": { state: "FL", city: "CLEARWATER" },
  "33766": { state: "FL", city: "CLEARWATER" },
  "33767": { state: "FL", city: "CLEARWATER BEACH" },
  "33769": { state: "FL", city: "CLEARWATER" },
  "33770": { state: "FL", city: "LARGO" },
  "33771": { state: "FL", city: "LARGO" },
  "33772": { state: "FL", city: "SEMINOLE" },
  "33773": { state: "FL", city: "LARGO" },
  "33774": { state: "FL", city: "LARGO" },
  "33775": { state: "FL", city: "SEMINOLE" },
  "33776": { state: "FL", city: "SEMINOLE" },
  "33777": { state: "FL", city: "SEMINOLE" },
  "33778": { state: "FL", city: "LARGO" },
  "33779": { state: "FL", city: "LARGO" },
  "33780": { state: "FL", city: "PINELLAS PARK" },
  "33781": { state: "FL", city: "PINELLAS PARK" },
  "33782": { state: "FL", city: "PINELLAS PARK" },
  "33784": { state: "FL", city: "SAINT PETERSBURG" },
  "33785": { state: "FL", city: "INDIAN ROCKS BEACH" },
  "33786": { state: "FL", city: "BELLEAIR BEACH" },
  "33801": { state: "FL", city: "LAKELAND" },
  "33802": { state: "FL", city: "LAKELAND" },
  "33803": { state: "FL", city: "LAKELAND" },
  "33804": { state: "FL", city: "LAKELAND" },
  "33805": { state: "FL", city: "LAKELAND" },
  "33806": { state: "FL", city: "LAKELAND" },
  "33807": { state: "FL", city: "LAKELAND" },
  "33809": { state: "FL", city: "LAKELAND" },
  "33810": { state: "FL", city: "LAKELAND" },
  "33811": { state: "FL", city: "LAKELAND" },
  "33812": { state: "FL", city: "LAKELAND" },
  "33813": { state: "FL", city: "LAKELAND" },
  "33815": { state: "FL", city: "LAKELAND" },
  "33820": { state: "FL", city: "ALTURAS" },
  "33823": { state: "FL", city: "AUBURNDALE" },
  "33825": { state: "FL", city: "AVON PARK" },
  "33826": { state: "FL", city: "AVON PARK" },
  "33827": { state: "FL", city: "BABSON PARK" },
  "33830": { state: "FL", city: "BARTOW" },
  "33831": { state: "FL", city: "BARTOW" },
  "33834": { state: "FL", city: "BOWLING GREEN" },
  "33835": { state: "FL", city: "BRADLEY" },
  "33836": { state: "FL", city: "DAVENPORT" },
  "33837": { state: "FL", city: "DAVENPORT" },
  "33838": { state: "FL", city: "DUNDEE" },
  "33839": { state: "FL", city: "EAGLE LAKE" },
  "33840": { state: "FL", city: "EATON PARK" },
  "33841": { state: "FL", city: "FORT MEADE" },
  "33843": { state: "FL", city: "FROSTPROOF" },
  "33844": { state: "FL", city: "HAINES CITY" },
  "33845": { state: "FL", city: "HAINES CITY" },
  "33846": { state: "FL", city: "HIGHLAND CITY" },
  "33847": { state: "FL", city: "HOMELAND" },
  "33848": { state: "FL", city: "INTERCESSION CITY" },
  "33849": { state: "FL", city: "KATHLEEN" },
  "33850": { state: "FL", city: "LAKE ALFRED" },
  "33851": { state: "FL", city: "LAKE HAMILTON" },
  "33852": { state: "FL", city: "LAKE PLACID" },
  "33853": { state: "FL", city: "LAKE WALES" },
  "33854": { state: "FL", city: "LAKESHORE" },
  "33855": { state: "FL", city: "INDIAN LAKE ESTATES" },
  "33856": { state: "FL", city: "NALCREST" },
  "33857": { state: "FL", city: "LORIDA" },
  "33858": { state: "FL", city: "LOUGHMAN" },
  "33859": { state: "FL", city: "LAKE WALES" },
  "33860": { state: "FL", city: "MULBERRY" },
  "33862": { state: "FL", city: "LAKE PLACID" },
  "33863": { state: "FL", city: "NICHOLS" },
  "33865": { state: "FL", city: "ONA" },
  "33867": { state: "FL", city: "RIVER RANCH" },
  "33868": { state: "FL", city: "POLK CITY" },
  "33870": { state: "FL", city: "SEBRING" },
  "33871": { state: "FL", city: "SEBRING" },
  "33872": { state: "FL", city: "SEBRING" },
  "33873": { state: "FL", city: "WAUCHULA" },
  "33875": { state: "FL", city: "SEBRING" },
  "33876": { state: "FL", city: "SEBRING" },
  "33877": { state: "FL", city: "WAVERLY" },
  "33880": { state: "FL", city: "WINTER HAVEN" },
  "33881": { state: "FL", city: "WINTER HAVEN" },
  "33882": { state: "FL", city: "WINTER HAVEN" },
  "33883": { state: "FL", city: "WINTER HAVEN" },
  "33884": { state: "FL", city: "WINTER HAVEN" },
  "33885": { state: "FL", city: "WINTER HAVEN" },
  "33888": { state: "FL", city: "WINTER HAVEN" },
  "33890": { state: "FL", city: "ZOLFO SPRINGS" },
  "33896": { state: "FL", city: "DAVENPORT" },
  "33897": { state: "FL", city: "DAVENPORT" },
  "33898": { state: "FL", city: "LAKE WALES" },
  "33901": { state: "FL", city: "FORT MYERS" },
  "33902": { state: "FL", city: "FORT MYERS" },
  "33903": { state: "FL", city: "NORTH FORT MYERS" },
  "33904": { state: "FL", city: "CAPE CORAL" },
  "33905": { state: "FL", city: "FORT MYERS" },
  "33906": { state: "FL", city: "FORT MYERS" },
  "33907": { state: "FL", city: "FORT MYERS" },
  "33908": { state: "FL", city: "FORT MYERS" },
  "33909": { state: "FL", city: "CAPE CORAL" },
  "33910": { state: "FL", city: "CAPE CORAL" },
  "33912": { state: "FL", city: "FORT MYERS" },
  "33913": { state: "FL", city: "FORT MYERS" },
  "33914": { state: "FL", city: "CAPE CORAL" },
  "33915": { state: "FL", city: "CAPE CORAL" },
  "33916": { state: "FL", city: "FORT MYERS" },
  "33917": { state: "FL", city: "NORTH FORT MYERS" },
  "33918": { state: "FL", city: "NORTH FORT MYERS" },
  "33919": { state: "FL", city: "FORT MYERS" },
  "33920": { state: "FL", city: "ALVA" },
  "33921": { state: "FL", city: "BOCA GRANDE" },
  "33922": { state: "FL", city: "BOKEELIA" },
  "33924": { state: "FL", city: "CAPTIVA" },
  "33927": { state: "FL", city: "EL JOBEAN" },
  "33928": { state: "FL", city: "ESTERO" },
  "33929": { state: "FL", city: "ESTERO" },
  "33930": { state: "FL", city: "FELDA" },
  "33931": { state: "FL", city: "FORT MYERS BEACH" },
  "33932": { state: "FL", city: "FORT MYERS BEACH" },
  "33935": { state: "FL", city: "LABELLE" },
  "33936": { state: "FL", city: "LEHIGH ACRES" },
  "33938": { state: "FL", city: "MURDOCK" },
  "33944": { state: "FL", city: "PALMDALE" },
  "33945": { state: "FL", city: "PINELAND" },
  "33946": { state: "FL", city: "PLACIDA" },
  "33947": { state: "FL", city: "ROTONDA WEST" },
  "33948": { state: "FL", city: "PORT CHARLOTTE" },
  "33949": { state: "FL", city: "PORT CHARLOTTE" },
  "33950": { state: "FL", city: "PUNTA GORDA" },
  "33951": { state: "FL", city: "PUNTA GORDA" },
  "33952": { state: "FL", city: "PORT CHARLOTTE" },
  "33953": { state: "FL", city: "PORT CHARLOTTE" },
  "33954": { state: "FL", city: "PORT CHARLOTTE" },
  "33955": { state: "FL", city: "PUNTA GORDA" },
  "33956": { state: "FL", city: "SAINT JAMES CITY" },
  "33957": { state: "FL", city: "SANIBEL" },
  "33960": { state: "FL", city: "VENUS" },
  "33965": { state: "FL", city: "FORT MYERS" },
  "33966": { state: "FL", city: "FORT MYERS" },
  "33967": { state: "FL", city: "FORT MYERS" },
  "33970": { state: "FL", city: "LEHIGH ACRES" },
  "33971": { state: "FL", city: "LEHIGH ACRES" },
  "33972": { state: "FL", city: "LEHIGH ACRES" },
  "33973": { state: "FL", city: "LEHIGH ACRES" },
  "33974": { state: "FL", city: "LEHIGH ACRES" },
  "33975": { state: "FL", city: "LABELLE" },
  "33976": { state: "FL", city: "LEHIGH ACRES" },
  "33980": { state: "FL", city: "PUNTA GORDA" },
  "33981": { state: "FL", city: "PORT CHARLOTTE" },
  "33982": { state: "FL", city: "PUNTA GORDA" },
  "33983": { state: "FL", city: "PUNTA GORDA" },
  "33990": { state: "FL", city: "CAPE CORAL" },
  "33991": { state: "FL", city: "CAPE CORAL" },
  "33993": { state: "FL", city: "CAPE CORAL" },
  "33994": { state: "FL", city: "FORT MYERS" },
  "34001": { state: "AA", city: "DPO" },
  "34002": { state: "AA", city: "DPO" },
  "34004": { state: "AA", city: "DPO" },
  "34007": { state: "AA", city: "FPO" },
  "34008": { state: "AA", city: "DPO" },
  "34009": { state: "AA", city: "FPO" },
  "34010": { state: "AA", city: "FPO" },
  "34011": { state: "AA", city: "DPO" },
  "34020": { state: "AA", city: "DPO" },
  "34021": { state: "AA", city: "DPO" },
  "34022": { state: "AA", city: "DPO" },
  "34023": { state: "AA", city: "DPO" },
  "34024": { state: "AA", city: "DPO" },
  "34025": { state: "AA", city: "DPO" },
  "34030": { state: "AA", city: "DPO" },
  "34031": { state: "AA", city: "DPO" },
  "34032": { state: "AA", city: "DPO" },
  "34033": { state: "AA", city: "DPO" },
  "34034": { state: "AA", city: "DPO" },
  "34035": { state: "AA", city: "DPO" },
  "34036": { state: "AA", city: "DPO" },
  "34037": { state: "AA", city: "DPO" },
  "34038": { state: "AA", city: "APO" },
  "34039": { state: "AA", city: "DPO" },
  "34041": { state: "AA", city: "DPO" },
  "34042": { state: "AA", city: "APO" },
  "34044": { state: "AA", city: "APO" },
  "34055": { state: "AA", city: "DPO" },
  "34058": { state: "AA", city: "FPO" },
  "34060": { state: "AA", city: "DPO" },
  "34066": { state: "AA", city: "DPO" },
  "34067": { state: "AA", city: "DPO" },
  "34068": { state: "AA", city: "DPO" },
  "34069": { state: "AA", city: "DPO" },
  "34071": { state: "AA", city: "DPO" },
  "34072": { state: "AA", city: "DPO" },
  "34078": { state: "AA", city: "APO" },
  "34080": { state: "AA", city: "FPO" },
  "34081": { state: "AA", city: "FPO" },
  "34082": { state: "AA", city: "FPO" },
  "34083": { state: "AA", city: "FPO" },
  "34084": { state: "AA", city: "FPO" },
  "34085": { state: "AA", city: "FPO" },
  "34086": { state: "AA", city: "FPO" },
  "34087": { state: "AA", city: "FPO" },
  "34088": { state: "AA", city: "FPO" },
  "34089": { state: "AA", city: "FPO" },
  "34090": { state: "AA", city: "FPO" },
  "34091": { state: "AA", city: "FPO" },
  "34092": { state: "AA", city: "FPO" },
  "34093": { state: "AA", city: "FPO" },
  "34094": { state: "AA", city: "FPO" },
  "34095": { state: "AA", city: "FPO" },
  "34101": { state: "FL", city: "NAPLES" },
  "34102": { state: "FL", city: "NAPLES" },
  "34103": { state: "FL", city: "NAPLES" },
  "34104": { state: "FL", city: "NAPLES" },
  "34105": { state: "FL", city: "NAPLES" },
  "34106": { state: "FL", city: "NAPLES" },
  "34107": { state: "FL", city: "NAPLES" },
  "34108": { state: "FL", city: "NAPLES" },
  "34109": { state: "FL", city: "NAPLES" },
  "34110": { state: "FL", city: "NAPLES" },
  "34112": { state: "FL", city: "NAPLES" },
  "34113": { state: "FL", city: "NAPLES" },
  "34114": { state: "FL", city: "NAPLES" },
  "34116": { state: "FL", city: "NAPLES" },
  "34117": { state: "FL", city: "NAPLES" },
  "34119": { state: "FL", city: "NAPLES" },
  "34120": { state: "FL", city: "NAPLES" },
  "34133": { state: "FL", city: "BONITA SPRINGS" },
  "34134": { state: "FL", city: "BONITA SPRINGS" },
  "34135": { state: "FL", city: "BONITA SPRINGS" },
  "34136": { state: "FL", city: "BONITA SPRINGS" },
  "34137": { state: "FL", city: "COPELAND" },
  "34138": { state: "FL", city: "CHOKOLOSKEE" },
  "34139": { state: "FL", city: "EVERGLADES CITY" },
  "34140": { state: "FL", city: "GOODLAND" },
  "34141": { state: "FL", city: "OCHOPEE" },
  "34142": { state: "FL", city: "IMMOKALEE" },
  "34143": { state: "FL", city: "IMMOKALEE" },
  "34145": { state: "FL", city: "MARCO ISLAND" },
  "34146": { state: "FL", city: "MARCO ISLAND" },
  "34201": { state: "FL", city: "BRADENTON" },
  "34202": { state: "FL", city: "BRADENTON" },
  "34203": { state: "FL", city: "BRADENTON" },
  "34204": { state: "FL", city: "BRADENTON" },
  "34205": { state: "FL", city: "BRADENTON" },
  "34206": { state: "FL", city: "BRADENTON" },
  "34207": { state: "FL", city: "BRADENTON" },
  "34208": { state: "FL", city: "BRADENTON" },
  "34209": { state: "FL", city: "BRADENTON" },
  "34210": { state: "FL", city: "BRADENTON" },
  "34211": { state: "FL", city: "BRADENTON" },
  "34212": { state: "FL", city: "BRADENTON" },
  "34215": { state: "FL", city: "CORTEZ" },
  "34216": { state: "FL", city: "ANNA MARIA" },
  "34217": { state: "FL", city: "BRADENTON BEACH" },
  "34218": { state: "FL", city: "HOLMES BEACH" },
  "34219": { state: "FL", city: "PARRISH" },
  "34220": { state: "FL", city: "PALMETTO" },
  "34221": { state: "FL", city: "PALMETTO" },
  "34222": { state: "FL", city: "ELLENTON" },
  "34223": { state: "FL", city: "ENGLEWOOD" },
  "34224": { state: "FL", city: "ENGLEWOOD" },
  "34228": { state: "FL", city: "LONGBOAT KEY" },
  "34229": { state: "FL", city: "OSPREY" },
  "34230": { state: "FL", city: "SARASOTA" },
  "34231": { state: "FL", city: "SARASOTA" },
  "34232": { state: "FL", city: "SARASOTA" },
  "34233": { state: "FL", city: "SARASOTA" },
  "34234": { state: "FL", city: "SARASOTA" },
  "34235": { state: "FL", city: "SARASOTA" },
  "34236": { state: "FL", city: "SARASOTA" },
  "34237": { state: "FL", city: "SARASOTA" },
  "34238": { state: "FL", city: "SARASOTA" },
  "34239": { state: "FL", city: "SARASOTA" },
  "34240": { state: "FL", city: "SARASOTA" },
  "34241": { state: "FL", city: "SARASOTA" },
  "34242": { state: "FL", city: "SARASOTA" },
  "34243": { state: "FL", city: "SARASOTA" },
  "34249": { state: "FL", city: "SARASOTA" },
  "34250": { state: "FL", city: "TERRA CEIA" },
  "34251": { state: "FL", city: "MYAKKA CITY" },
  "34260": { state: "FL", city: "SARASOTA" },
  "34264": { state: "FL", city: "ONECO" },
  "34265": { state: "FL", city: "ARCADIA" },
  "34266": { state: "FL", city: "ARCADIA" },
  "34267": { state: "FL", city: "FORT OGDEN" },
  "34268": { state: "FL", city: "NOCATEE" },
  "34269": { state: "FL", city: "ARCADIA" },
  "34270": { state: "FL", city: "TALLEVAST" },
  "34272": { state: "FL", city: "LAUREL" },
  "34274": { state: "FL", city: "NOKOMIS" },
  "34275": { state: "FL", city: "NOKOMIS" },
  "34276": { state: "FL", city: "SARASOTA" },
  "34277": { state: "FL", city: "SARASOTA" },
  "34280": { state: "FL", city: "BRADENTON" },
  "34281": { state: "FL", city: "BRADENTON" },
  "34282": { state: "FL", city: "BRADENTON" },
  "34284": { state: "FL", city: "VENICE" },
  "34285": { state: "FL", city: "VENICE" },
  "34286": { state: "FL", city: "NORTH PORT" },
  "34287": { state: "FL", city: "NORTH PORT" },
  "34288": { state: "FL", city: "NORTH PORT" },
  "34289": { state: "FL", city: "NORTH PORT" },
  "34290": { state: "FL", city: "NORTH PORT" },
  "34291": { state: "FL", city: "NORTH PORT" },
  "34292": { state: "FL", city: "VENICE" },
  "34293": { state: "FL", city: "VENICE" },
  "34295": { state: "FL", city: "ENGLEWOOD" },
  "34420": { state: "FL", city: "BELLEVIEW" },
  "34421": { state: "FL", city: "BELLEVIEW" },
  "34423": { state: "FL", city: "CRYSTAL RIVER" },
  "34428": { state: "FL", city: "CRYSTAL RIVER" },
  "34429": { state: "FL", city: "CRYSTAL RIVER" },
  "34430": { state: "FL", city: "DUNNELLON" },
  "34431": { state: "FL", city: "DUNNELLON" },
  "34432": { state: "FL", city: "DUNNELLON" },
  "34433": { state: "FL", city: "DUNNELLON" },
  "34434": { state: "FL", city: "DUNNELLON" },
  "34436": { state: "FL", city: "FLORAL CITY" },
  "34441": { state: "FL", city: "HERNANDO" },
  "34442": { state: "FL", city: "HERNANDO" },
  "34445": { state: "FL", city: "HOLDER" },
  "34446": { state: "FL", city: "HOMOSASSA" },
  "34447": { state: "FL", city: "HOMOSASSA SPRINGS" },
  "34448": { state: "FL", city: "HOMOSASSA" },
  "34449": { state: "FL", city: "INGLIS" },
  "34450": { state: "FL", city: "INVERNESS" },
  "34451": { state: "FL", city: "INVERNESS" },
  "34452": { state: "FL", city: "INVERNESS" },
  "34453": { state: "FL", city: "INVERNESS" },
  "34460": { state: "FL", city: "LECANTO" },
  "34461": { state: "FL", city: "LECANTO" },
  "34464": { state: "FL", city: "BEVERLY HILLS" },
  "34465": { state: "FL", city: "BEVERLY HILLS" },
  "34470": { state: "FL", city: "OCALA" },
  "34471": { state: "FL", city: "OCALA" },
  "34472": { state: "FL", city: "OCALA" },
  "34473": { state: "FL", city: "OCALA" },
  "34474": { state: "FL", city: "OCALA" },
  "34475": { state: "FL", city: "OCALA" },
  "34476": { state: "FL", city: "OCALA" },
  "34477": { state: "FL", city: "OCALA" },
  "34478": { state: "FL", city: "OCALA" },
  "34479": { state: "FL", city: "OCALA" },
  "34480": { state: "FL", city: "OCALA" },
  "34481": { state: "FL", city: "OCALA" },
  "34482": { state: "FL", city: "OCALA" },
  "34483": { state: "FL", city: "OCALA" },
  "34484": { state: "FL", city: "OXFORD" },
  "34487": { state: "FL", city: "HOMOSASSA" },
  "34488": { state: "FL", city: "SILVER SPRINGS" },
  "34489": { state: "FL", city: "SILVER SPRINGS" },
  "34491": { state: "FL", city: "SUMMERFIELD" },
  "34492": { state: "FL", city: "SUMMERFIELD" },
  "34498": { state: "FL", city: "YANKEETOWN" },
  "34601": { state: "FL", city: "BROOKSVILLE" },
  "34602": { state: "FL", city: "BROOKSVILLE" },
  "34603": { state: "FL", city: "BROOKSVILLE" },
  "34604": { state: "FL", city: "BROOKSVILLE" },
  "34605": { state: "FL", city: "BROOKSVILLE" },
  "34606": { state: "FL", city: "SPRING HILL" },
  "34607": { state: "FL", city: "SPRING HILL" },
  "34608": { state: "FL", city: "SPRING HILL" },
  "34609": { state: "FL", city: "SPRING HILL" },
  "34610": { state: "FL", city: "SPRING HILL" },
  "34611": { state: "FL", city: "SPRING HILL" },
  "34613": { state: "FL", city: "BROOKSVILLE" },
  "34614": { state: "FL", city: "BROOKSVILLE" },
  "34636": { state: "FL", city: "ISTACHATTA" },
  "34637": { state: "FL", city: "LAND O LAKES" },
  "34638": { state: "FL", city: "LAND O LAKES" },
  "34639": { state: "FL", city: "LAND O LAKES" },
  "34652": { state: "FL", city: "NEW PORT RICHEY" },
  "34653": { state: "FL", city: "NEW PORT RICHEY" },
  "34654": { state: "FL", city: "NEW PORT RICHEY" },
  "34655": { state: "FL", city: "NEW PORT RICHEY" },
  "34656": { state: "FL", city: "NEW PORT RICHEY" },
  "34660": { state: "FL", city: "OZONA" },
  "34661": { state: "FL", city: "NOBLETON" },
  "34667": { state: "FL", city: "HUDSON" },
  "34668": { state: "FL", city: "PORT RICHEY" },
  "34669": { state: "FL", city: "HUDSON" },
  "34673": { state: "FL", city: "PORT RICHEY" },
  "34674": { state: "FL", city: "HUDSON" },
  "34677": { state: "FL", city: "OLDSMAR" },
  "34679": { state: "FL", city: "ARIPEKA" },
  "34680": { state: "FL", city: "ELFERS" },
  "34681": { state: "FL", city: "CRYSTAL BEACH" },
  "34682": { state: "FL", city: "PALM HARBOR" },
  "34683": { state: "FL", city: "PALM HARBOR" },
  "34684": { state: "FL", city: "PALM HARBOR" },
  "34685": { state: "FL", city: "PALM HARBOR" },
  "34688": { state: "FL", city: "TARPON SPRINGS" },
  "34689": { state: "FL", city: "TARPON SPRINGS" },
  "34690": { state: "FL", city: "HOLIDAY" },
  "34691": { state: "FL", city: "HOLIDAY" },
  "34692": { state: "FL", city: "HOLIDAY" },
  "34695": { state: "FL", city: "SAFETY HARBOR" },
  "34697": { state: "FL", city: "DUNEDIN" },
  "34698": { state: "FL", city: "DUNEDIN" },
  "34705": { state: "FL", city: "ASTATULA" },
  "34711": { state: "FL", city: "CLERMONT" },
  "34712": { state: "FL", city: "CLERMONT" },
  "34713": { state: "FL", city: "CLERMONT" },
  "34714": { state: "FL", city: "CLERMONT" },
  "34715": { state: "FL", city: "CLERMONT" },
  "34729": { state: "FL", city: "FERNDALE" },
  "34731": { state: "FL", city: "FRUITLAND PARK" },
  "34734": { state: "FL", city: "GOTHA" },
  "34736": { state: "FL", city: "GROVELAND" },
  "34737": { state: "FL", city: "HOWEY IN THE HILLS" },
  "34739": { state: "FL", city: "KENANSVILLE" },
  "34740": { state: "FL", city: "KILLARNEY" },
  "34741": { state: "FL", city: "KISSIMMEE" },
  "34742": { state: "FL", city: "KISSIMMEE" },
  "34743": { state: "FL", city: "KISSIMMEE" },
  "34744": { state: "FL", city: "KISSIMMEE" },
  "34745": { state: "FL", city: "KISSIMMEE" },
  "34746": { state: "FL", city: "KISSIMMEE" },
  "34747": { state: "FL", city: "KISSIMMEE" },
  "34748": { state: "FL", city: "LEESBURG" },
  "34749": { state: "FL", city: "LEESBURG" },
  "34753": { state: "FL", city: "MASCOTTE" },
  "34755": { state: "FL", city: "MINNEOLA" },
  "34756": { state: "FL", city: "MONTVERDE" },
  "34758": { state: "FL", city: "KISSIMMEE" },
  "34759": { state: "FL", city: "KISSIMMEE" },
  "34760": { state: "FL", city: "OAKLAND" },
  "34761": { state: "FL", city: "OCOEE" },
  "34762": { state: "FL", city: "THE VILLAGES" },
  "34769": { state: "FL", city: "SAINT CLOUD" },
  "34770": { state: "FL", city: "SAINT CLOUD" },
  "34771": { state: "FL", city: "SAINT CLOUD" },
  "34772": { state: "FL", city: "SAINT CLOUD" },
  "34773": { state: "FL", city: "SAINT CLOUD" },
  "34777": { state: "FL", city: "WINTER GARDEN" },
  "34778": { state: "FL", city: "WINTER GARDEN" },
  "34785": { state: "FL", city: "WILDWOOD" },
  "34786": { state: "FL", city: "WINDERMERE" },
  "34787": { state: "FL", city: "WINTER GARDEN" },
  "34788": { state: "FL", city: "LEESBURG" },
  "34789": { state: "FL", city: "LEESBURG" },
  "34797": { state: "FL", city: "YALAHA" },
  "34945": { state: "FL", city: "FORT PIERCE" },
  "34946": { state: "FL", city: "FORT PIERCE" },
  "34947": { state: "FL", city: "FORT PIERCE" },
  "34948": { state: "FL", city: "FORT PIERCE" },
  "34949": { state: "FL", city: "FORT PIERCE" },
  "34950": { state: "FL", city: "FORT PIERCE" },
  "34951": { state: "FL", city: "FORT PIERCE" },
  "34952": { state: "FL", city: "PORT SAINT LUCIE" },
  "34953": { state: "FL", city: "PORT SAINT LUCIE" },
  "34954": { state: "FL", city: "FORT PIERCE" },
  "34956": { state: "FL", city: "INDIANTOWN" },
  "34957": { state: "FL", city: "JENSEN BEACH" },
  "34958": { state: "FL", city: "JENSEN BEACH" },
  "34972": { state: "FL", city: "OKEECHOBEE" },
  "34973": { state: "FL", city: "OKEECHOBEE" },
  "34974": { state: "FL", city: "OKEECHOBEE" },
  "34979": { state: "FL", city: "FORT PIERCE" },
  "34981": { state: "FL", city: "FORT PIERCE" },
  "34982": { state: "FL", city: "FORT PIERCE" },
  "34983": { state: "FL", city: "PORT SAINT LUCIE" },
  "34984": { state: "FL", city: "PORT SAINT LUCIE" },
  "34985": { state: "FL", city: "PORT SAINT LUCIE" },
  "34986": { state: "FL", city: "PORT SAINT LUCIE" },
  "34987": { state: "FL", city: "PORT SAINT LUCIE" },
  "34988": { state: "FL", city: "PORT SAINT LUCIE" },
  "34990": { state: "FL", city: "PALM CITY" },
  "34991": { state: "FL", city: "PALM CITY" },
  "34992": { state: "FL", city: "PORT SALERNO" },
  "34994": { state: "FL", city: "STUART" },
  "34995": { state: "FL", city: "STUART" },
  "34996": { state: "FL", city: "STUART" },
  "34997": { state: "FL", city: "STUART" },
  "35004": { state: "AL", city: "MOODY" },
  "35005": { state: "AL", city: "ADAMSVILLE" },
  "35006": { state: "AL", city: "ADGER" },
  "35007": { state: "AL", city: "ALABASTER" },
  "35010": { state: "AL", city: "ALEXANDER CITY" },
  "35011": { state: "AL", city: "ALEXANDER CITY" },
  "35013": { state: "AL", city: "ALLGOOD" },
  "35014": { state: "AL", city: "ALPINE" },
  "35015": { state: "AL", city: "ALTON" },
  "35016": { state: "AL", city: "ARAB" },
  "35019": { state: "AL", city: "BAILEYTON" },
  "35020": { state: "AL", city: "BESSEMER" },
  "35021": { state: "AL", city: "BESSEMER" },
  "35022": { state: "AL", city: "BESSEMER" },
  "35023": { state: "AL", city: "BESSEMER" },
  "35031": { state: "AL", city: "BLOUNTSVILLE" },
  "35032": { state: "AL", city: "BON AIR" },
  "35033": { state: "AL", city: "BREMEN" },
  "35034": { state: "AL", city: "BRENT" },
  "35035": { state: "AL", city: "BRIERFIELD" },
  "35036": { state: "AL", city: "BROOKSIDE" },
  "35038": { state: "AL", city: "BURNWELL" },
  "35040": { state: "AL", city: "CALERA" },
  "35042": { state: "AL", city: "CENTREVILLE" },
  "35043": { state: "AL", city: "CHELSEA" },
  "35044": { state: "AL", city: "CHILDERSBURG" },
  "35045": { state: "AL", city: "CLANTON" },
  "35046": { state: "AL", city: "CLANTON" },
  "35048": { state: "AL", city: "CLAY" },
  "35049": { state: "AL", city: "CLEVELAND" },
  "35051": { state: "AL", city: "COLUMBIANA" },
  "35052": { state: "AL", city: "COOK SPRINGS" },
  "35053": { state: "AL", city: "CRANE HILL" },
  "35054": { state: "AL", city: "CROPWELL" },
  "35055": { state: "AL", city: "CULLMAN" },
  "35056": { state: "AL", city: "CULLMAN" },
  "35057": { state: "AL", city: "CULLMAN" },
  "35058": { state: "AL", city: "CULLMAN" },
  "35060": { state: "AL", city: "DOCENA" },
  "35061": { state: "AL", city: "DOLOMITE" },
  "35062": { state: "AL", city: "DORA" },
  "35063": { state: "AL", city: "EMPIRE" },
  "35064": { state: "AL", city: "FAIRFIELD" },
  "35068": { state: "AL", city: "FULTONDALE" },
  "35070": { state: "AL", city: "GARDEN CITY" },
  "35071": { state: "AL", city: "GARDENDALE" },
  "35072": { state: "AL", city: "GOODWATER" },
  "35073": { state: "AL", city: "GRAYSVILLE" },
  "35074": { state: "AL", city: "GREEN POND" },
  "35077": { state: "AL", city: "HANCEVILLE" },
  "35078": { state: "AL", city: "HARPERSVILLE" },
  "35079": { state: "AL", city: "HAYDEN" },
  "35080": { state: "AL", city: "HELENA" },
  "35082": { state: "AL", city: "HOLLINS" },
  "35083": { state: "AL", city: "HOLLY POND" },
  "35085": { state: "AL", city: "JEMISON" },
  "35087": { state: "AL", city: "JOPPA" },
  "35089": { state: "AL", city: "KELLYTON" },
  "35091": { state: "AL", city: "KIMBERLY" },
  "35094": { state: "AL", city: "LEEDS" },
  "35096": { state: "AL", city: "LINCOLN" },
  "35097": { state: "AL", city: "LOCUST FORK" },
  "35098": { state: "AL", city: "LOGAN" },
  "35111": { state: "AL", city: "MC CALLA" },
  "35112": { state: "AL", city: "MARGARET" },
  "35114": { state: "AL", city: "ALABASTER" },
  "35115": { state: "AL", city: "MONTEVALLO" },
  "35116": { state: "AL", city: "MORRIS" },
  "35117": { state: "AL", city: "MOUNT OLIVE" },
  "35118": { state: "AL", city: "MULGA" },
  "35119": { state: "AL", city: "NEW CASTLE" },
  "35120": { state: "AL", city: "ODENVILLE" },
  "35121": { state: "AL", city: "ONEONTA" },
  "35123": { state: "AL", city: "PALMERDALE" },
  "35124": { state: "AL", city: "PELHAM" },
  "35125": { state: "AL", city: "PELL CITY" },
  "35126": { state: "AL", city: "PINSON" },
  "35127": { state: "AL", city: "PLEASANT GROVE" },
  "35128": { state: "AL", city: "PELL CITY" },
  "35130": { state: "AL", city: "QUINTON" },
  "35131": { state: "AL", city: "RAGLAND" },
  "35133": { state: "AL", city: "REMLAP" },
  "35135": { state: "AL", city: "RIVERSIDE" },
  "35136": { state: "AL", city: "ROCKFORD" },
  "35137": { state: "AL", city: "SAGINAW" },
  "35139": { state: "AL", city: "SAYRE" },
  "35142": { state: "AL", city: "SHANNON" },
  "35143": { state: "AL", city: "SHELBY" },
  "35144": { state: "AL", city: "SILURIA" },
  "35146": { state: "AL", city: "SPRINGVILLE" },
  "35147": { state: "AL", city: "STERRETT" },
  "35148": { state: "AL", city: "SUMITON" },
  "35149": { state: "AL", city: "SYCAMORE" },
  "35150": { state: "AL", city: "SYLACAUGA" },
  "35151": { state: "AL", city: "SYLACAUGA" },
  "35160": { state: "AL", city: "TALLADEGA" },
  "35161": { state: "AL", city: "TALLADEGA" },
  "35171": { state: "AL", city: "THORSBY" },
  "35172": { state: "AL", city: "TRAFFORD" },
  "35173": { state: "AL", city: "TRUSSVILLE" },
  "35175": { state: "AL", city: "UNION GROVE" },
  "35176": { state: "AL", city: "VANDIVER" },
  "35178": { state: "AL", city: "VINCENT" },
  "35179": { state: "AL", city: "VINEMONT" },
  "35180": { state: "AL", city: "WARRIOR" },
  "35181": { state: "AL", city: "WATSON" },
  "35182": { state: "AL", city: "WATTSVILLE" },
  "35183": { state: "AL", city: "WEOGUFKA" },
  "35184": { state: "AL", city: "WEST BLOCTON" },
  "35185": { state: "AL", city: "WESTOVER" },
  "35186": { state: "AL", city: "WILSONVILLE" },
  "35187": { state: "AL", city: "WILTON" },
  "35188": { state: "AL", city: "WOODSTOCK" },
  "35201": { state: "AL", city: "BIRMINGHAM" },
  "35202": { state: "AL", city: "BIRMINGHAM" },
  "35203": { state: "AL", city: "BIRMINGHAM" },
  "35204": { state: "AL", city: "BIRMINGHAM" },
  "35205": { state: "AL", city: "BIRMINGHAM" },
  "35206": { state: "AL", city: "BIRMINGHAM" },
  "35207": { state: "AL", city: "BIRMINGHAM" },
  "35208": { state: "AL", city: "BIRMINGHAM" },
  "35209": { state: "AL", city: "BIRMINGHAM" },
  "35210": { state: "AL", city: "BIRMINGHAM" },
  "35211": { state: "AL", city: "BIRMINGHAM" },
  "35212": { state: "AL", city: "BIRMINGHAM" },
  "35213": { state: "AL", city: "BIRMINGHAM" },
  "35214": { state: "AL", city: "BIRMINGHAM" },
  "35215": { state: "AL", city: "BIRMINGHAM" },
  "35216": { state: "AL", city: "BIRMINGHAM" },
  "35217": { state: "AL", city: "BIRMINGHAM" },
  "35218": { state: "AL", city: "BIRMINGHAM" },
  "35219": { state: "AL", city: "BIRMINGHAM" },
  "35220": { state: "AL", city: "BIRMINGHAM" },
  "35221": { state: "AL", city: "BIRMINGHAM" },
  "35222": { state: "AL", city: "BIRMINGHAM" },
  "35223": { state: "AL", city: "BIRMINGHAM" },
  "35224": { state: "AL", city: "BIRMINGHAM" },
  "35226": { state: "AL", city: "BIRMINGHAM" },
  "35228": { state: "AL", city: "BIRMINGHAM" },
  "35229": { state: "AL", city: "BIRMINGHAM" },
  "35231": { state: "AL", city: "BIRMINGHAM" },
  "35232": { state: "AL", city: "BIRMINGHAM" },
  "35233": { state: "AL", city: "BIRMINGHAM" },
  "35234": { state: "AL", city: "BIRMINGHAM" },
  "35235": { state: "AL", city: "BIRMINGHAM" },
  "35236": { state: "AL", city: "BIRMINGHAM" },
  "35237": { state: "AL", city: "BIRMINGHAM" },
  "35238": { state: "AL", city: "BIRMINGHAM" },
  "35242": { state: "AL", city: "BIRMINGHAM" },
  "35243": { state: "AL", city: "BIRMINGHAM" },
  "35244": { state: "AL", city: "BIRMINGHAM" },
  "35246": { state: "AL", city: "BIRMINGHAM" },
  "35249": { state: "AL", city: "BIRMINGHAM" },
  "35253": { state: "AL", city: "BIRMINGHAM" },
  "35254": { state: "AL", city: "BIRMINGHAM" },
  "35255": { state: "AL", city: "BIRMINGHAM" },
  "35259": { state: "AL", city: "BIRMINGHAM" },
  "35260": { state: "AL", city: "BIRMINGHAM" },
  "35261": { state: "AL", city: "BIRMINGHAM" },
  "35266": { state: "AL", city: "BIRMINGHAM" },
  "35270": { state: "AL", city: "BIRMINGHAM" },
  "35282": { state: "AL", city: "BIRMINGHAM" },
  "35283": { state: "AL", city: "BIRMINGHAM" },
  "35285": { state: "AL", city: "BIRMINGHAM" },
  "35287": { state: "AL", city: "BIRMINGHAM" },
  "35288": { state: "AL", city: "BIRMINGHAM" },
  "35290": { state: "AL", city: "BIRMINGHAM" },
  "35291": { state: "AL", city: "BIRMINGHAM" },
  "35292": { state: "AL", city: "BIRMINGHAM" },
  "35293": { state: "AL", city: "BIRMINGHAM" },
  "35294": { state: "AL", city: "BIRMINGHAM" },
  "35295": { state: "AL", city: "BIRMINGHAM" },
  "35296": { state: "AL", city: "BIRMINGHAM" },
  "35297": { state: "AL", city: "BIRMINGHAM" },
  "35298": { state: "AL", city: "BIRMINGHAM" },
  "35401": { state: "AL", city: "TUSCALOOSA" },
  "35402": { state: "AL", city: "TUSCALOOSA" },
  "35403": { state: "AL", city: "TUSCALOOSA" },
  "35404": { state: "AL", city: "TUSCALOOSA" },
  "35405": { state: "AL", city: "TUSCALOOSA" },
  "35406": { state: "AL", city: "TUSCALOOSA" },
  "35407": { state: "AL", city: "TUSCALOOSA" },
  "35440": { state: "AL", city: "ABERNANT" },
  "35441": { state: "AL", city: "AKRON" },
  "35442": { state: "AL", city: "ALICEVILLE" },
  "35443": { state: "AL", city: "BOLIGEE" },
  "35444": { state: "AL", city: "BROOKWOOD" },
  "35446": { state: "AL", city: "BUHL" },
  "35447": { state: "AL", city: "CARROLLTON" },
  "35448": { state: "AL", city: "CLINTON" },
  "35449": { state: "AL", city: "COALING" },
  "35452": { state: "AL", city: "COKER" },
  "35453": { state: "AL", city: "COTTONDALE" },
  "35456": { state: "AL", city: "DUNCANVILLE" },
  "35457": { state: "AL", city: "ECHOLA" },
  "35458": { state: "AL", city: "ELROD" },
  "35459": { state: "AL", city: "EMELLE" },
  "35460": { state: "AL", city: "EPES" },
  "35461": { state: "AL", city: "ETHELSVILLE" },
  "35462": { state: "AL", city: "EUTAW" },
  "35463": { state: "AL", city: "FOSTERS" },
  "35464": { state: "AL", city: "GAINESVILLE" },
  "35466": { state: "AL", city: "GORDO" },
  "35469": { state: "AL", city: "KNOXVILLE" },
  "35470": { state: "AL", city: "LIVINGSTON" },
  "35471": { state: "AL", city: "MC SHAN" },
  "35473": { state: "AL", city: "NORTHPORT" },
  "35474": { state: "AL", city: "MOUNDVILLE" },
  "35475": { state: "AL", city: "NORTHPORT" },
  "35476": { state: "AL", city: "NORTHPORT" },
  "35477": { state: "AL", city: "PANOLA" },
  "35478": { state: "AL", city: "PETERSON" },
  "35480": { state: "AL", city: "RALPH" },
  "35481": { state: "AL", city: "REFORM" },
  "35482": { state: "AL", city: "SAMANTHA" },
  "35486": { state: "AL", city: "TUSCALOOSA" },
  "35487": { state: "AL", city: "TUSCALOOSA" },
  "35490": { state: "AL", city: "VANCE" },
  "35491": { state: "AL", city: "WEST GREENE" },
  "35501": { state: "AL", city: "JASPER" },
  "35502": { state: "AL", city: "JASPER" },
  "35503": { state: "AL", city: "JASPER" },
  "35504": { state: "AL", city: "JASPER" },
  "35540": { state: "AL", city: "ADDISON" },
  "35541": { state: "AL", city: "ARLEY" },
  "35542": { state: "AL", city: "BANKSTON" },
  "35543": { state: "AL", city: "BEAR CREEK" },
  "35544": { state: "AL", city: "BEAVERTON" },
  "35545": { state: "AL", city: "BELK" },
  "35546": { state: "AL", city: "BERRY" },
  "35548": { state: "AL", city: "BRILLIANT" },
  "35549": { state: "AL", city: "CARBON HILL" },
  "35550": { state: "AL", city: "CORDOVA" },
  "35551": { state: "AL", city: "DELMAR" },
  "35552": { state: "AL", city: "DETROIT" },
  "35553": { state: "AL", city: "DOUBLE SPRINGS" },
  "35554": { state: "AL", city: "ELDRIDGE" },
  "35555": { state: "AL", city: "FAYETTE" },
  "35559": { state: "AL", city: "GLEN ALLEN" },
  "35560": { state: "AL", city: "GOODSPRINGS" },
  "35563": { state: "AL", city: "GUIN" },
  "35564": { state: "AL", city: "HACKLEBURG" },
  "35565": { state: "AL", city: "HALEYVILLE" },
  "35570": { state: "AL", city: "HAMILTON" },
  "35571": { state: "AL", city: "HODGES" },
  "35572": { state: "AL", city: "HOUSTON" },
  "35573": { state: "AL", city: "KANSAS" },
  "35574": { state: "AL", city: "KENNEDY" },
  "35575": { state: "AL", city: "LYNN" },
  "35576": { state: "AL", city: "MILLPORT" },
  "35577": { state: "AL", city: "NATURAL BRIDGE" },
  "35578": { state: "AL", city: "NAUVOO" },
  "35579": { state: "AL", city: "OAKMAN" },
  "35580": { state: "AL", city: "PARRISH" },
  "35581": { state: "AL", city: "PHIL CAMPBELL" },
  "35582": { state: "AL", city: "RED BAY" },
  "35584": { state: "AL", city: "SIPSEY" },
  "35585": { state: "AL", city: "SPRUCE PINE" },
  "35586": { state: "AL", city: "SULLIGENT" },
  "35587": { state: "AL", city: "TOWNLEY" },
  "35592": { state: "AL", city: "VERNON" },
  "35593": { state: "AL", city: "VINA" },
  "35594": { state: "AL", city: "WINFIELD" },
  "35601": { state: "AL", city: "DECATUR" },
  "35602": { state: "AL", city: "DECATUR" },
  "35603": { state: "AL", city: "DECATUR" },
  "35609": { state: "AL", city: "DECATUR" },
  "35610": { state: "AL", city: "ANDERSON" },
  "35611": { state: "AL", city: "ATHENS" },
  "35612": { state: "AL", city: "ATHENS" },
  "35613": { state: "AL", city: "ATHENS" },
  "35614": { state: "AL", city: "ATHENS" },
  "35615": { state: "AL", city: "BELLE MINA" },
  "35616": { state: "AL", city: "CHEROKEE" },
  "35617": { state: "AL", city: "CLOVERDALE" },
  "35618": { state: "AL", city: "COURTLAND" },
  "35619": { state: "AL", city: "DANVILLE" },
  "35620": { state: "AL", city: "ELKMONT" },
  "35621": { state: "AL", city: "EVA" },
  "35622": { state: "AL", city: "FALKVILLE" },
  "35630": { state: "AL", city: "FLORENCE" },
  "35631": { state: "AL", city: "FLORENCE" },
  "35632": { state: "AL", city: "FLORENCE" },
  "35633": { state: "AL", city: "FLORENCE" },
  "35634": { state: "AL", city: "FLORENCE" },
  "35640": { state: "AL", city: "HARTSELLE" },
  "35643": { state: "AL", city: "HILLSBORO" },
  "35645": { state: "AL", city: "KILLEN" },
  "35646": { state: "AL", city: "LEIGHTON" },
  "35647": { state: "AL", city: "LESTER" },
  "35648": { state: "AL", city: "LEXINGTON" },
  "35649": { state: "AL", city: "MOORESVILLE" },
  "35650": { state: "AL", city: "MOULTON" },
  "35651": { state: "AL", city: "MOUNT HOPE" },
  "35652": { state: "AL", city: "ROGERSVILLE" },
  "35653": { state: "AL", city: "RUSSELLVILLE" },
  "35654": { state: "AL", city: "RUSSELLVILLE" },
  "35660": { state: "AL", city: "SHEFFIELD" },
  "35661": { state: "AL", city: "MUSCLE SHOALS" },
  "35662": { state: "AL", city: "MUSCLE SHOALS" },
  "35670": { state: "AL", city: "SOMERVILLE" },
  "35671": { state: "AL", city: "TANNER" },
  "35672": { state: "AL", city: "TOWN CREEK" },
  "35673": { state: "AL", city: "TRINITY" },
  "35674": { state: "AL", city: "TUSCUMBIA" },
  "35677": { state: "AL", city: "WATERLOO" },
  "35699": { state: "AL", city: "DECATUR" },
  "35739": { state: "AL", city: "ARDMORE" },
  "35740": { state: "AL", city: "BRIDGEPORT" },
  "35741": { state: "AL", city: "BROWNSBORO" },
  "35742": { state: "AL", city: "CAPSHAW" },
  "35744": { state: "AL", city: "DUTTON" },
  "35745": { state: "AL", city: "ESTILLFORK" },
  "35746": { state: "AL", city: "FACKLER" },
  "35747": { state: "AL", city: "GRANT" },
  "35748": { state: "AL", city: "GURLEY" },
  "35749": { state: "AL", city: "HARVEST" },
  "35750": { state: "AL", city: "HAZEL GREEN" },
  "35751": { state: "AL", city: "HOLLYTREE" },
  "35752": { state: "AL", city: "HOLLYWOOD" },
  "35754": { state: "AL", city: "LACEYS SPRING" },
  "35755": { state: "AL", city: "LANGSTON" },
  "35756": { state: "AL", city: "MADISON" },
  "35757": { state: "AL", city: "MADISON" },
  "35758": { state: "AL", city: "MADISON" },
  "35759": { state: "AL", city: "MERIDIANVILLE" },
  "35760": { state: "AL", city: "NEW HOPE" },
  "35761": { state: "AL", city: "NEW MARKET" },
  "35762": { state: "AL", city: "NORMAL" },
  "35763": { state: "AL", city: "OWENS CROSS ROADS" },
  "35764": { state: "AL", city: "PAINT ROCK" },
  "35765": { state: "AL", city: "PISGAH" },
  "35766": { state: "AL", city: "PRINCETON" },
  "35767": { state: "AL", city: "RYLAND" },
  "35768": { state: "AL", city: "SCOTTSBORO" },
  "35769": { state: "AL", city: "SCOTTSBORO" },
  "35771": { state: "AL", city: "SECTION" },
  "35772": { state: "AL", city: "STEVENSON" },
  "35773": { state: "AL", city: "TONEY" },
  "35774": { state: "AL", city: "TRENTON" },
  "35775": { state: "AL", city: "VALHERMOSO SPRINGS" },
  "35776": { state: "AL", city: "WOODVILLE" },
  "35801": { state: "AL", city: "HUNTSVILLE" },
  "35802": { state: "AL", city: "HUNTSVILLE" },
  "35803": { state: "AL", city: "HUNTSVILLE" },
  "35804": { state: "AL", city: "HUNTSVILLE" },
  "35805": { state: "AL", city: "HUNTSVILLE" },
  "35806": { state: "AL", city: "HUNTSVILLE" },
  "35807": { state: "AL", city: "HUNTSVILLE" },
  "35808": { state: "AL", city: "HUNTSVILLE" },
  "35809": { state: "AL", city: "HUNTSVILLE" },
  "35810": { state: "AL", city: "HUNTSVILLE" },
  "35811": { state: "AL", city: "HUNTSVILLE" },
  "35812": { state: "AL", city: "HUNTSVILLE" },
  "35813": { state: "AL", city: "HUNTSVILLE" },
  "35814": { state: "AL", city: "HUNTSVILLE" },
  "35815": { state: "AL", city: "HUNTSVILLE" },
  "35816": { state: "AL", city: "HUNTSVILLE" },
  "35824": { state: "AL", city: "HUNTSVILLE" },
  "35893": { state: "AL", city: "HUNTSVILLE" },
  "35894": { state: "AL", city: "HUNTSVILLE" },
  "35895": { state: "AL", city: "HUNTSVILLE" },
  "35896": { state: "AL", city: "HUNTSVILLE" },
  "35897": { state: "AL", city: "HUNTSVILLE" },
  "35898": { state: "AL", city: "HUNTSVILLE" },
  "35899": { state: "AL", city: "HUNTSVILLE" },
  "35901": { state: "AL", city: "GADSDEN" },
  "35902": { state: "AL", city: "GADSDEN" },
  "35903": { state: "AL", city: "GADSDEN" },
  "35904": { state: "AL", city: "GADSDEN" },
  "35905": { state: "AL", city: "GADSDEN" },
  "35906": { state: "AL", city: "RAINBOW CITY" },
  "35907": { state: "AL", city: "GADSDEN" },
  "35950": { state: "AL", city: "ALBERTVILLE" },
  "35951": { state: "AL", city: "ALBERTVILLE" },
  "35952": { state: "AL", city: "ALTOONA" },
  "35953": { state: "AL", city: "ASHVILLE" },
  "35954": { state: "AL", city: "ATTALLA" },
  "35956": { state: "AL", city: "BOAZ" },
  "35957": { state: "AL", city: "BOAZ" },
  "35958": { state: "AL", city: "BRYANT" },
  "35959": { state: "AL", city: "CEDAR BLUFF" },
  "35960": { state: "AL", city: "CENTRE" },
  "35961": { state: "AL", city: "COLLINSVILLE" },
  "35962": { state: "AL", city: "CROSSVILLE" },
  "35963": { state: "AL", city: "DAWSON" },
  "35964": { state: "AL", city: "DOUGLAS" },
  "35966": { state: "AL", city: "FLAT ROCK" },
  "35967": { state: "AL", city: "FORT PAYNE" },
  "35968": { state: "AL", city: "FORT PAYNE" },
  "35971": { state: "AL", city: "FYFFE" },
  "35972": { state: "AL", city: "GALLANT" },
  "35973": { state: "AL", city: "GAYLESVILLE" },
  "35974": { state: "AL", city: "GERALDINE" },
  "35975": { state: "AL", city: "GROVEOAK" },
  "35976": { state: "AL", city: "GUNTERSVILLE" },
  "35978": { state: "AL", city: "HENAGAR" },
  "35979": { state: "AL", city: "HIGDON" },
  "35980": { state: "AL", city: "HORTON" },
  "35981": { state: "AL", city: "IDER" },
  "35983": { state: "AL", city: "LEESBURG" },
  "35984": { state: "AL", city: "MENTONE" },
  "35986": { state: "AL", city: "RAINSVILLE" },
  "35987": { state: "AL", city: "STEELE" },
  "35988": { state: "AL", city: "SYLVANIA" },
  "35989": { state: "AL", city: "VALLEY HEAD" },
  "35990": { state: "AL", city: "WALNUT GROVE" },
  "36003": { state: "AL", city: "AUTAUGAVILLE" },
  "36005": { state: "AL", city: "BANKS" },
  "36006": { state: "AL", city: "BILLINGSLEY" },
  "36008": { state: "AL", city: "BOOTH" },
  "36009": { state: "AL", city: "BRANTLEY" },
  "36010": { state: "AL", city: "BRUNDIDGE" },
  "36013": { state: "AL", city: "CECIL" },
  "36015": { state: "AL", city: "CHAPMAN" },
  "36016": { state: "AL", city: "CLAYTON" },
  "36017": { state: "AL", city: "CLIO" },
  "36020": { state: "AL", city: "COOSADA" },
  "36022": { state: "AL", city: "DEATSVILLE" },
  "36023": { state: "AL", city: "EAST TALLASSEE" },
  "36024": { state: "AL", city: "ECLECTIC" },
  "36025": { state: "AL", city: "ELMORE" },
  "36026": { state: "AL", city: "EQUALITY" },
  "36027": { state: "AL", city: "EUFAULA" },
  "36028": { state: "AL", city: "DOZIER" },
  "36029": { state: "AL", city: "FITZPATRICK" },
  "36030": { state: "AL", city: "FOREST HOME" },
  "36031": { state: "AL", city: "FORT DAVIS" },
  "36032": { state: "AL", city: "FORT DEPOSIT" },
  "36033": { state: "AL", city: "GEORGIANA" },
  "36034": { state: "AL", city: "GLENWOOD" },
  "36035": { state: "AL", city: "GOSHEN" },
  "36036": { state: "AL", city: "GRADY" },
  "36037": { state: "AL", city: "GREENVILLE" },
  "36038": { state: "AL", city: "GANTT" },
  "36039": { state: "AL", city: "HARDAWAY" },
  "36040": { state: "AL", city: "HAYNEVILLE" },
  "36041": { state: "AL", city: "HIGHLAND HOME" },
  "36042": { state: "AL", city: "HONORAVILLE" },
  "36043": { state: "AL", city: "HOPE HULL" },
  "36045": { state: "AL", city: "KENT" },
  "36046": { state: "AL", city: "LAPINE" },
  "36047": { state: "AL", city: "LETOHATCHEE" },
  "36048": { state: "AL", city: "LOUISVILLE" },
  "36049": { state: "AL", city: "LUVERNE" },
  "36051": { state: "AL", city: "MARBURY" },
  "36052": { state: "AL", city: "MATHEWS" },
  "36053": { state: "AL", city: "MIDWAY" },
  "36054": { state: "AL", city: "MILLBROOK" },
  "36057": { state: "AL", city: "MOUNT MEIGS" },
  "36061": { state: "AL", city: "PEROTE" },
  "36062": { state: "AL", city: "PETREY" },
  "36064": { state: "AL", city: "PIKE ROAD" },
  "36065": { state: "AL", city: "PINE LEVEL" },
  "36066": { state: "AL", city: "PRATTVILLE" },
  "36067": { state: "AL", city: "PRATTVILLE" },
  "36068": { state: "AL", city: "PRATTVILLE" },
  "36069": { state: "AL", city: "RAMER" },
  "36071": { state: "AL", city: "RUTLEDGE" },
  "36072": { state: "AL", city: "EUFAULA" },
  "36075": { state: "AL", city: "SHORTER" },
  "36078": { state: "AL", city: "TALLASSEE" },
  "36079": { state: "AL", city: "TROY" },
  "36080": { state: "AL", city: "TITUS" },
  "36081": { state: "AL", city: "TROY" },
  "36082": { state: "AL", city: "TROY" },
  "36083": { state: "AL", city: "TUSKEGEE" },
  "36087": { state: "AL", city: "TUSKEGEE INSTITUTE" },
  "36088": { state: "AL", city: "TUSKEGEE INSTITUTE" },
  "36089": { state: "AL", city: "UNION SPRINGS" },
  "36091": { state: "AL", city: "VERBENA" },
  "36092": { state: "AL", city: "WETUMPKA" },
  "36093": { state: "AL", city: "WETUMPKA" },
  "36101": { state: "AL", city: "MONTGOMERY" },
  "36102": { state: "AL", city: "MONTGOMERY" },
  "36103": { state: "AL", city: "MONTGOMERY" },
  "36104": { state: "AL", city: "MONTGOMERY" },
  "36105": { state: "AL", city: "MONTGOMERY" },
  "36106": { state: "AL", city: "MONTGOMERY" },
  "36107": { state: "AL", city: "MONTGOMERY" },
  "36108": { state: "AL", city: "MONTGOMERY" },
  "36109": { state: "AL", city: "MONTGOMERY" },
  "36110": { state: "AL", city: "MONTGOMERY" },
  "36111": { state: "AL", city: "MONTGOMERY" },
  "36112": { state: "AL", city: "MONTGOMERY" },
  "36113": { state: "AL", city: "MONTGOMERY" },
  "36114": { state: "AL", city: "MONTGOMERY" },
  "36115": { state: "AL", city: "MONTGOMERY" },
  "36116": { state: "AL", city: "MONTGOMERY" },
  "36117": { state: "AL", city: "MONTGOMERY" },
  "36118": { state: "AL", city: "MONTGOMERY" },
  "36119": { state: "AL", city: "MONTGOMERY" },
  "36120": { state: "AL", city: "MONTGOMERY" },
  "36121": { state: "AL", city: "MONTGOMERY" },
  "36123": { state: "AL", city: "MONTGOMERY" },
  "36124": { state: "AL", city: "MONTGOMERY" },
  "36125": { state: "AL", city: "MONTGOMERY" },
  "36130": { state: "AL", city: "MONTGOMERY" },
  "36131": { state: "AL", city: "MONTGOMERY" },
  "36132": { state: "AL", city: "MONTGOMERY" },
  "36135": { state: "AL", city: "MONTGOMERY" },
  "36140": { state: "AL", city: "MONTGOMERY" },
  "36141": { state: "AL", city: "MONTGOMERY" },
  "36142": { state: "AL", city: "MONTGOMERY" },
  "36177": { state: "AL", city: "MONTGOMERY" },
  "36191": { state: "AL", city: "MONTGOMERY" },
  "36201": { state: "AL", city: "ANNISTON" },
  "36202": { state: "AL", city: "ANNISTON" },
  "36203": { state: "AL", city: "OXFORD" },
  "36204": { state: "AL", city: "ANNISTON" },
  "36205": { state: "AL", city: "ANNISTON" },
  "36206": { state: "AL", city: "ANNISTON" },
  "36207": { state: "AL", city: "ANNISTON" },
  "36250": { state: "AL", city: "ALEXANDRIA" },
  "36251": { state: "AL", city: "ASHLAND" },
  "36253": { state: "AL", city: "BYNUM" },
  "36254": { state: "AL", city: "CHOCCOLOCCO" },
  "36255": { state: "AL", city: "CRAGFORD" },
  "36256": { state: "AL", city: "DAVISTON" },
  "36257": { state: "AL", city: "DE ARMANVILLE" },
  "36258": { state: "AL", city: "DELTA" },
  "36260": { state: "AL", city: "EASTABOGA" },
  "36261": { state: "AL", city: "EDWARDSVILLE" },
  "36262": { state: "AL", city: "FRUITHURST" },
  "36263": { state: "AL", city: "GRAHAM" },
  "36264": { state: "AL", city: "HEFLIN" },
  "36265": { state: "AL", city: "JACKSONVILLE" },
  "36266": { state: "AL", city: "LINEVILLE" },
  "36267": { state: "AL", city: "MILLERVILLE" },
  "36268": { state: "AL", city: "MUNFORD" },
  "36269": { state: "AL", city: "MUSCADINE" },
  "36271": { state: "AL", city: "OHATCHEE" },
  "36272": { state: "AL", city: "PIEDMONT" },
  "36273": { state: "AL", city: "RANBURNE" },
  "36274": { state: "AL", city: "ROANOKE" },
  "36275": { state: "AL", city: "SPRING GARDEN" },
  "36276": { state: "AL", city: "WADLEY" },
  "36277": { state: "AL", city: "WEAVER" },
  "36278": { state: "AL", city: "WEDOWEE" },
  "36279": { state: "AL", city: "WELLINGTON" },
  "36280": { state: "AL", city: "WOODLAND" },
  "36301": { state: "AL", city: "DOTHAN" },
  "36302": { state: "AL", city: "DOTHAN" },
  "36303": { state: "AL", city: "DOTHAN" },
  "36304": { state: "AL", city: "DOTHAN" },
  "36305": { state: "AL", city: "DOTHAN" },
  "36310": { state: "AL", city: "ABBEVILLE" },
  "36311": { state: "AL", city: "ARITON" },
  "36312": { state: "AL", city: "ASHFORD" },
  "36313": { state: "AL", city: "BELLWOOD" },
  "36314": { state: "AL", city: "BLACK" },
  "36316": { state: "AL", city: "CHANCELLOR" },
  "36317": { state: "AL", city: "CLOPTON" },
  "36318": { state: "AL", city: "COFFEE SPRINGS" },
  "36319": { state: "AL", city: "COLUMBIA" },
  "36320": { state: "AL", city: "COTTONWOOD" },
  "36321": { state: "AL", city: "COWARTS" },
  "36322": { state: "AL", city: "DALEVILLE" },
  "36323": { state: "AL", city: "ELBA" },
  "36330": { state: "AL", city: "ENTERPRISE" },
  "36331": { state: "AL", city: "ENTERPRISE" },
  "36340": { state: "AL", city: "GENEVA" },
  "36343": { state: "AL", city: "GORDON" },
  "36344": { state: "AL", city: "HARTFORD" },
  "36345": { state: "AL", city: "HEADLAND" },
  "36346": { state: "AL", city: "JACK" },
  "36349": { state: "AL", city: "MALVERN" },
  "36350": { state: "AL", city: "MIDLAND CITY" },
  "36351": { state: "AL", city: "NEW BROCKTON" },
  "36352": { state: "AL", city: "NEWTON" },
  "36353": { state: "AL", city: "NEWVILLE" },
  "36360": { state: "AL", city: "OZARK" },
  "36361": { state: "AL", city: "OZARK" },
  "36362": { state: "AL", city: "FORT NOVOSEL" },
  "36370": { state: "AL", city: "PANSEY" },
  "36371": { state: "AL", city: "PINCKARD" },
  "36373": { state: "AL", city: "SHORTERVILLE" },
  "36374": { state: "AL", city: "SKIPPERVILLE" },
  "36375": { state: "AL", city: "SLOCOMB" },
  "36376": { state: "AL", city: "WEBB" },
  "36401": { state: "AL", city: "EVERGREEN" },
  "36420": { state: "AL", city: "ANDALUSIA" },
  "36421": { state: "AL", city: "ANDALUSIA" },
  "36425": { state: "AL", city: "BEATRICE" },
  "36426": { state: "AL", city: "BREWTON" },
  "36427": { state: "AL", city: "BREWTON" },
  "36429": { state: "AL", city: "BROOKLYN" },
  "36432": { state: "AL", city: "CASTLEBERRY" },
  "36435": { state: "AL", city: "COY" },
  "36436": { state: "AL", city: "DICKINSON" },
  "36439": { state: "AL", city: "EXCEL" },
  "36441": { state: "AL", city: "FLOMATON" },
  "36442": { state: "AL", city: "FLORALA" },
  "36444": { state: "AL", city: "FRANKLIN" },
  "36445": { state: "AL", city: "FRISCO CITY" },
  "36446": { state: "AL", city: "FULTON" },
  "36449": { state: "AL", city: "URIAH" },
  "36451": { state: "AL", city: "GROVE HILL" },
  "36453": { state: "AL", city: "KINSTON" },
  "36454": { state: "AL", city: "LENOX" },
  "36455": { state: "AL", city: "LOCKHART" },
  "36456": { state: "AL", city: "MC KENZIE" },
  "36457": { state: "AL", city: "MEGARGEL" },
  "36458": { state: "AL", city: "MEXIA" },
  "36460": { state: "AL", city: "MONROEVILLE" },
  "36461": { state: "AL", city: "MONROEVILLE" },
  "36467": { state: "AL", city: "OPP" },
  "36470": { state: "AL", city: "PERDUE HILL" },
  "36471": { state: "AL", city: "PETERMAN" },
  "36473": { state: "AL", city: "RANGE" },
  "36474": { state: "AL", city: "RED LEVEL" },
  "36475": { state: "AL", city: "REPTON" },
  "36476": { state: "AL", city: "RIVER FALLS" },
  "36477": { state: "AL", city: "SAMSON" },
  "36480": { state: "AL", city: "URIAH" },
  "36481": { state: "AL", city: "VREDENBURGH" },
  "36482": { state: "AL", city: "WHATLEY" },
  "36483": { state: "AL", city: "WING" },
  "36502": { state: "AL", city: "ATMORE" },
  "36503": { state: "AL", city: "ATMORE" },
  "36504": { state: "AL", city: "ATMORE" },
  "36505": { state: "AL", city: "AXIS" },
  "36507": { state: "AL", city: "BAY MINETTE" },
  "36509": { state: "AL", city: "BAYOU LA BATRE" },
  "36511": { state: "AL", city: "BON SECOUR" },
  "36512": { state: "AL", city: "BUCKS" },
  "36513": { state: "AL", city: "CALVERT" },
  "36518": { state: "AL", city: "CHATOM" },
  "36521": { state: "AL", city: "CHUNCHULA" },
  "36522": { state: "AL", city: "CITRONELLE" },
  "36523": { state: "AL", city: "CODEN" },
  "36524": { state: "AL", city: "COFFEEVILLE" },
  "36525": { state: "AL", city: "CREOLA" },
  "36526": { state: "AL", city: "DAPHNE" },
  "36527": { state: "AL", city: "DAPHNE" },
  "36528": { state: "AL", city: "DAUPHIN ISLAND" },
  "36529": { state: "AL", city: "DEER PARK" },
  "36530": { state: "AL", city: "ELBERTA" },
  "36532": { state: "AL", city: "FAIRHOPE" },
  "36533": { state: "AL", city: "FAIRHOPE" },
  "36535": { state: "AL", city: "FOLEY" },
  "36536": { state: "AL", city: "FOLEY" },
  "36538": { state: "AL", city: "FRANKVILLE" },
  "36539": { state: "AL", city: "FRUITDALE" },
  "36540": { state: "AL", city: "GAINESTOWN" },
  "36541": { state: "AL", city: "GRAND BAY" },
  "36542": { state: "AL", city: "GULF SHORES" },
  "36543": { state: "AL", city: "HUXFORD" },
  "36544": { state: "AL", city: "IRVINGTON" },
  "36545": { state: "AL", city: "JACKSON" },
  "36547": { state: "AL", city: "GULF SHORES" },
  "36548": { state: "AL", city: "LEROY" },
  "36549": { state: "AL", city: "LILLIAN" },
  "36550": { state: "AL", city: "LITTLE RIVER" },
  "36551": { state: "AL", city: "LOXLEY" },
  "36553": { state: "AL", city: "MC INTOSH" },
  "36555": { state: "AL", city: "MAGNOLIA SPRINGS" },
  "36556": { state: "AL", city: "MALCOLM" },
  "36558": { state: "AL", city: "MILLRY" },
  "36559": { state: "AL", city: "MONTROSE" },
  "36560": { state: "AL", city: "MOUNT VERNON" },
  "36561": { state: "AL", city: "ORANGE BEACH" },
  "36562": { state: "AL", city: "PERDIDO" },
  "36564": { state: "AL", city: "POINT CLEAR" },
  "36567": { state: "AL", city: "ROBERTSDALE" },
  "36568": { state: "AL", city: "SAINT ELMO" },
  "36569": { state: "AL", city: "SAINT STEPHENS" },
  "36571": { state: "AL", city: "SARALAND" },
  "36572": { state: "AL", city: "SATSUMA" },
  "36574": { state: "AL", city: "SEMINOLE" },
  "36575": { state: "AL", city: "SEMMES" },
  "36576": { state: "AL", city: "SILVERHILL" },
  "36577": { state: "AL", city: "SPANISH FORT" },
  "36578": { state: "AL", city: "STAPLETON" },
  "36579": { state: "AL", city: "STOCKTON" },
  "36580": { state: "AL", city: "SUMMERDALE" },
  "36581": { state: "AL", city: "SUNFLOWER" },
  "36582": { state: "AL", city: "THEODORE" },
  "36583": { state: "AL", city: "TIBBIE" },
  "36584": { state: "AL", city: "VINEGAR BEND" },
  "36585": { state: "AL", city: "WAGARVILLE" },
  "36587": { state: "AL", city: "WILMER" },
  "36590": { state: "AL", city: "THEODORE" },
  "36601": { state: "AL", city: "MOBILE" },
  "36602": { state: "AL", city: "MOBILE" },
  "36603": { state: "AL", city: "MOBILE" },
  "36604": { state: "AL", city: "MOBILE" },
  "36605": { state: "AL", city: "MOBILE" },
  "36606": { state: "AL", city: "MOBILE" },
  "36607": { state: "AL", city: "MOBILE" },
  "36608": { state: "AL", city: "MOBILE" },
  "36609": { state: "AL", city: "MOBILE" },
  "36610": { state: "AL", city: "MOBILE" },
  "36611": { state: "AL", city: "MOBILE" },
  "36612": { state: "AL", city: "MOBILE" },
  "36613": { state: "AL", city: "EIGHT MILE" },
  "36615": { state: "AL", city: "MOBILE" },
  "36616": { state: "AL", city: "MOBILE" },
  "36617": { state: "AL", city: "MOBILE" },
  "36618": { state: "AL", city: "MOBILE" },
  "36619": { state: "AL", city: "MOBILE" },
  "36628": { state: "AL", city: "MOBILE" },
  "36633": { state: "AL", city: "MOBILE" },
  "36640": { state: "AL", city: "MOBILE" },
  "36641": { state: "AL", city: "MOBILE" },
  "36644": { state: "AL", city: "MOBILE" },
  "36652": { state: "AL", city: "MOBILE" },
  "36660": { state: "AL", city: "MOBILE" },
  "36663": { state: "AL", city: "MOBILE" },
  "36670": { state: "AL", city: "MOBILE" },
  "36671": { state: "AL", city: "MOBILE" },
  "36675": { state: "AL", city: "MOBILE" },
  "36685": { state: "AL", city: "MOBILE" },
  "36688": { state: "AL", city: "MOBILE" },
  "36689": { state: "AL", city: "MOBILE" },
  "36691": { state: "AL", city: "MOBILE" },
  "36693": { state: "AL", city: "MOBILE" },
  "36695": { state: "AL", city: "MOBILE" },
  "36701": { state: "AL", city: "SELMA" },
  "36702": { state: "AL", city: "SELMA" },
  "36703": { state: "AL", city: "SELMA" },
  "36720": { state: "AL", city: "ALBERTA" },
  "36722": { state: "AL", city: "ARLINGTON" },
  "36723": { state: "AL", city: "BOYKIN" },
  "36726": { state: "AL", city: "CAMDEN" },
  "36727": { state: "AL", city: "CAMPBELL" },
  "36728": { state: "AL", city: "CATHERINE" },
  "36732": { state: "AL", city: "DEMOPOLIS" },
  "36736": { state: "AL", city: "DIXONS MILLS" },
  "36738": { state: "AL", city: "FAUNSDALE" },
  "36740": { state: "AL", city: "FORKLAND" },
  "36741": { state: "AL", city: "FURMAN" },
  "36742": { state: "AL", city: "GALLION" },
  "36744": { state: "AL", city: "GREENSBORO" },
  "36745": { state: "AL", city: "JEFFERSON" },
  "36748": { state: "AL", city: "LINDEN" },
  "36749": { state: "AL", city: "JONES" },
  "36750": { state: "AL", city: "MAPLESVILLE" },
  "36751": { state: "AL", city: "LOWER PEACH TREE" },
  "36752": { state: "AL", city: "LOWNDESBORO" },
  "36753": { state: "AL", city: "MC WILLIAMS" },
  "36754": { state: "AL", city: "MAGNOLIA" },
  "36756": { state: "AL", city: "MARION" },
  "36758": { state: "AL", city: "PLANTERSVILLE" },
  "36759": { state: "AL", city: "MARION JUNCTION" },
  "36761": { state: "AL", city: "MINTER" },
  "36763": { state: "AL", city: "MYRTLEWOOD" },
  "36764": { state: "AL", city: "NANAFALIA" },
  "36765": { state: "AL", city: "NEWBERN" },
  "36766": { state: "AL", city: "OAK HILL" },
  "36767": { state: "AL", city: "ORRVILLE" },
  "36768": { state: "AL", city: "PINE APPLE" },
  "36769": { state: "AL", city: "PINE HILL" },
  "36773": { state: "AL", city: "SAFFORD" },
  "36775": { state: "AL", city: "SARDIS" },
  "36776": { state: "AL", city: "SAWYERVILLE" },
  "36782": { state: "AL", city: "SWEET WATER" },
  "36783": { state: "AL", city: "THOMASTON" },
  "36784": { state: "AL", city: "THOMASVILLE" },
  "36785": { state: "AL", city: "TYLER" },
  "36786": { state: "AL", city: "UNIONTOWN" },
  "36790": { state: "AL", city: "STANTON" },
  "36792": { state: "AL", city: "RANDOLPH" },
  "36793": { state: "AL", city: "LAWLEY" },
  "36801": { state: "AL", city: "OPELIKA" },
  "36802": { state: "AL", city: "OPELIKA" },
  "36803": { state: "AL", city: "OPELIKA" },
  "36804": { state: "AL", city: "OPELIKA" },
  "36830": { state: "AL", city: "AUBURN" },
  "36831": { state: "AL", city: "AUBURN" },
  "36832": { state: "AL", city: "AUBURN" },
  "36849": { state: "AL", city: "AUBURN UNIVERSITY" },
  "36850": { state: "AL", city: "CAMP HILL" },
  "36851": { state: "AL", city: "COTTONTON" },
  "36852": { state: "AL", city: "CUSSETA" },
  "36853": { state: "AL", city: "DADEVILLE" },
  "36854": { state: "AL", city: "VALLEY" },
  "36855": { state: "AL", city: "FIVE POINTS" },
  "36856": { state: "AL", city: "FORT MITCHELL" },
  "36858": { state: "AL", city: "HATCHECHUBBEE" },
  "36859": { state: "AL", city: "HOLY TRINITY" },
  "36860": { state: "AL", city: "HURTSBORO" },
  "36861": { state: "AL", city: "JACKSONS GAP" },
  "36862": { state: "AL", city: "LAFAYETTE" },
  "36863": { state: "AL", city: "LANETT" },
  "36865": { state: "AL", city: "LOACHAPOKA" },
  "36866": { state: "AL", city: "NOTASULGA" },
  "36867": { state: "AL", city: "PHENIX CITY" },
  "36868": { state: "AL", city: "PHENIX CITY" },
  "36869": { state: "AL", city: "PHENIX CITY" },
  "36870": { state: "AL", city: "PHENIX CITY" },
  "36871": { state: "AL", city: "PITTSVIEW" },
  "36872": { state: "AL", city: "VALLEY" },
  "36874": { state: "AL", city: "SALEM" },
  "36875": { state: "AL", city: "SEALE" },
  "36877": { state: "AL", city: "SMITHS STATION" },
  "36879": { state: "AL", city: "WAVERLY" },
  "36901": { state: "AL", city: "BELLAMY" },
  "36904": { state: "AL", city: "BUTLER" },
  "36907": { state: "AL", city: "CUBA" },
  "36908": { state: "AL", city: "GILBERTOWN" },
  "36910": { state: "AL", city: "JACHIN" },
  "36912": { state: "AL", city: "LISMAN" },
  "36913": { state: "AL", city: "MELVIN" },
  "36915": { state: "AL", city: "NEEDHAM" },
  "36916": { state: "AL", city: "PENNINGTON" },
  "36919": { state: "AL", city: "SILAS" },
  "36921": { state: "AL", city: "TOXEY" },
  "36922": { state: "AL", city: "WARD" },
  "36925": { state: "AL", city: "YORK" },
  "37010": { state: "TN", city: "ADAMS" },
  "37011": { state: "TN", city: "ANTIOCH" },
  "37012": { state: "TN", city: "ALEXANDRIA" },
  "37013": { state: "TN", city: "ANTIOCH" },
  "37014": { state: "TN", city: "ARRINGTON" },
  "37015": { state: "TN", city: "ASHLAND CITY" },
  "37016": { state: "TN", city: "AUBURNTOWN" },
  "37018": { state: "TN", city: "BEECHGROVE" },
  "37019": { state: "TN", city: "BELFAST" },
  "37020": { state: "TN", city: "BELL BUCKLE" },
  "37022": { state: "TN", city: "BETHPAGE" },
  "37023": { state: "TN", city: "BIG ROCK" },
  "37024": { state: "TN", city: "BRENTWOOD" },
  "37025": { state: "TN", city: "BON AQUA" },
  "37026": { state: "TN", city: "BRADYVILLE" },
  "37027": { state: "TN", city: "BRENTWOOD" },
  "37028": { state: "TN", city: "BUMPUS MILLS" },
  "37029": { state: "TN", city: "BURNS" },
  "37030": { state: "TN", city: "CARTHAGE" },
  "37031": { state: "TN", city: "CASTALIAN SPRINGS" },
  "37032": { state: "TN", city: "CEDAR HILL" },
  "37033": { state: "TN", city: "CENTERVILLE" },
  "37034": { state: "TN", city: "CHAPEL HILL" },
  "37035": { state: "TN", city: "CHAPMANSBORO" },
  "37036": { state: "TN", city: "CHARLOTTE" },
  "37037": { state: "TN", city: "CHRISTIANA" },
  "37040": { state: "TN", city: "CLARKSVILLE" },
  "37041": { state: "TN", city: "CLARKSVILLE" },
  "37042": { state: "TN", city: "CLARKSVILLE" },
  "37043": { state: "TN", city: "CLARKSVILLE" },
  "37044": { state: "TN", city: "CLARKSVILLE" },
  "37046": { state: "TN", city: "COLLEGE GROVE" },
  "37047": { state: "TN", city: "CORNERSVILLE" },
  "37048": { state: "TN", city: "COTTONTOWN" },
  "37049": { state: "TN", city: "CROSS PLAINS" },
  "37050": { state: "TN", city: "CUMBERLAND CITY" },
  "37051": { state: "TN", city: "CUMBERLAND FURNACE" },
  "37052": { state: "TN", city: "CUNNINGHAM" },
  "37055": { state: "TN", city: "DICKSON" },
  "37056": { state: "TN", city: "DICKSON" },
  "37057": { state: "TN", city: "DIXON SPRINGS" },
  "37058": { state: "TN", city: "DOVER" },
  "37059": { state: "TN", city: "DOWELLTOWN" },
  "37060": { state: "TN", city: "EAGLEVILLE" },
  "37061": { state: "TN", city: "ERIN" },
  "37062": { state: "TN", city: "FAIRVIEW" },
  "37063": { state: "TN", city: "FOSTERVILLE" },
  "37064": { state: "TN", city: "FRANKLIN" },
  "37065": { state: "TN", city: "FRANKLIN" },
  "37066": { state: "TN", city: "GALLATIN" },
  "37067": { state: "TN", city: "FRANKLIN" },
  "37068": { state: "TN", city: "FRANKLIN" },
  "37069": { state: "TN", city: "FRANKLIN" },
  "37070": { state: "TN", city: "GOODLETTSVILLE" },
  "37071": { state: "TN", city: "GLADEVILLE" },
  "37072": { state: "TN", city: "GOODLETTSVILLE" },
  "37073": { state: "TN", city: "GREENBRIER" },
  "37074": { state: "TN", city: "HARTSVILLE" },
  "37075": { state: "TN", city: "HENDERSONVILLE" },
  "37076": { state: "TN", city: "HERMITAGE" },
  "37077": { state: "TN", city: "HENDERSONVILLE" },
  "37078": { state: "TN", city: "HURRICANE MILLS" },
  "37079": { state: "TN", city: "INDIAN MOUND" },
  "37080": { state: "TN", city: "JOELTON" },
  "37082": { state: "TN", city: "KINGSTON SPRINGS" },
  "37083": { state: "TN", city: "LAFAYETTE" },
  "37085": { state: "TN", city: "LASCASSAS" },
  "37086": { state: "TN", city: "LA VERGNE" },
  "37087": { state: "TN", city: "LEBANON" },
  "37088": { state: "TN", city: "LEBANON" },
  "37089": { state: "TN", city: "LA VERGNE" },
  "37090": { state: "TN", city: "LEBANON" },
  "37091": { state: "TN", city: "LEWISBURG" },
  "37095": { state: "TN", city: "LIBERTY" },
  "37096": { state: "TN", city: "LINDEN" },
  "37097": { state: "TN", city: "LOBELVILLE" },
  "37098": { state: "TN", city: "LYLES" },
  "37101": { state: "TN", city: "MC EWEN" },
  "37110": { state: "TN", city: "MCMINNVILLE" },
  "37111": { state: "TN", city: "MCMINNVILLE" },
  "37115": { state: "TN", city: "MADISON" },
  "37116": { state: "TN", city: "MADISON" },
  "37118": { state: "TN", city: "MILTON" },
  "37119": { state: "TN", city: "MITCHELLVILLE" },
  "37121": { state: "TN", city: "MOUNT JULIET" },
  "37122": { state: "TN", city: "MOUNT JULIET" },
  "37127": { state: "TN", city: "MURFREESBORO" },
  "37128": { state: "TN", city: "MURFREESBORO" },
  "37129": { state: "TN", city: "MURFREESBORO" },
  "37130": { state: "TN", city: "MURFREESBORO" },
  "37131": { state: "TN", city: "MURFREESBORO" },
  "37132": { state: "TN", city: "MURFREESBORO" },
  "37133": { state: "TN", city: "MURFREESBORO" },
  "37134": { state: "TN", city: "NEW JOHNSONVILLE" },
  "37135": { state: "TN", city: "NOLENSVILLE" },
  "37136": { state: "TN", city: "NORENE" },
  "37137": { state: "TN", city: "NUNNELLY" },
  "37138": { state: "TN", city: "OLD HICKORY" },
  "37140": { state: "TN", city: "ONLY" },
  "37141": { state: "TN", city: "ORLINDA" },
  "37142": { state: "TN", city: "PALMYRA" },
  "37143": { state: "TN", city: "PEGRAM" },
  "37144": { state: "TN", city: "PETERSBURG" },
  "37145": { state: "TN", city: "PLEASANT SHADE" },
  "37146": { state: "TN", city: "PLEASANT VIEW" },
  "37148": { state: "TN", city: "PORTLAND" },
  "37149": { state: "TN", city: "READYVILLE" },
  "37150": { state: "TN", city: "RED BOILING SPRINGS" },
  "37151": { state: "TN", city: "RIDDLETON" },
  "37152": { state: "TN", city: "RIDGETOP" },
  "37153": { state: "TN", city: "ROCKVALE" },
  "37160": { state: "TN", city: "SHELBYVILLE" },
  "37161": { state: "TN", city: "SHELBYVILLE" },
  "37162": { state: "TN", city: "SHELBYVILLE" },
  "37165": { state: "TN", city: "SLAYDEN" },
  "37166": { state: "TN", city: "SMITHVILLE" },
  "37167": { state: "TN", city: "SMYRNA" },
  "37171": { state: "TN", city: "SOUTHSIDE" },
  "37172": { state: "TN", city: "SPRINGFIELD" },
  "37174": { state: "TN", city: "SPRING HILL" },
  "37175": { state: "TN", city: "STEWART" },
  "37178": { state: "TN", city: "TENNESSEE RIDGE" },
  "37179": { state: "TN", city: "THOMPSONS STATION" },
  "37180": { state: "TN", city: "UNIONVILLE" },
  "37181": { state: "TN", city: "VANLEER" },
  "37183": { state: "TN", city: "WARTRACE" },
  "37184": { state: "TN", city: "WATERTOWN" },
  "37185": { state: "TN", city: "WAVERLY" },
  "37186": { state: "TN", city: "WESTMORELAND" },
  "37187": { state: "TN", city: "WHITE BLUFF" },
  "37188": { state: "TN", city: "WHITE HOUSE" },
  "37189": { state: "TN", city: "WHITES CREEK" },
  "37190": { state: "TN", city: "WOODBURY" },
  "37191": { state: "TN", city: "WOODLAWN" },
  "37201": { state: "TN", city: "NASHVILLE" },
  "37202": { state: "TN", city: "NASHVILLE" },
  "37203": { state: "TN", city: "NASHVILLE" },
  "37204": { state: "TN", city: "NASHVILLE" },
  "37205": { state: "TN", city: "NASHVILLE" },
  "37206": { state: "TN", city: "NASHVILLE" },
  "37207": { state: "TN", city: "NASHVILLE" },
  "37208": { state: "TN", city: "NASHVILLE" },
  "37209": { state: "TN", city: "NASHVILLE" },
  "37210": { state: "TN", city: "NASHVILLE" },
  "37211": { state: "TN", city: "NASHVILLE" },
  "37212": { state: "TN", city: "NASHVILLE" },
  "37213": { state: "TN", city: "NASHVILLE" },
  "37214": { state: "TN", city: "NASHVILLE" },
  "37215": { state: "TN", city: "NASHVILLE" },
  "37216": { state: "TN", city: "NASHVILLE" },
  "37217": { state: "TN", city: "NASHVILLE" },
  "37218": { state: "TN", city: "NASHVILLE" },
  "37219": { state: "TN", city: "NASHVILLE" },
  "37220": { state: "TN", city: "NASHVILLE" },
  "37221": { state: "TN", city: "NASHVILLE" },
  "37222": { state: "TN", city: "NASHVILLE" },
  "37224": { state: "TN", city: "NASHVILLE" },
  "37227": { state: "TN", city: "NASHVILLE" },
  "37228": { state: "TN", city: "NASHVILLE" },
  "37229": { state: "TN", city: "NASHVILLE" },
  "37230": { state: "TN", city: "NASHVILLE" },
  "37232": { state: "TN", city: "NASHVILLE" },
  "37234": { state: "TN", city: "NASHVILLE" },
  "37235": { state: "TN", city: "NASHVILLE" },
  "37236": { state: "TN", city: "NASHVILLE" },
  "37238": { state: "TN", city: "NASHVILLE" },
  "37240": { state: "TN", city: "NASHVILLE" },
  "37241": { state: "TN", city: "NASHVILLE" },
  "37242": { state: "TN", city: "NASHVILLE" },
  "37243": { state: "TN", city: "NASHVILLE" },
  "37244": { state: "TN", city: "NASHVILLE" },
  "37246": { state: "TN", city: "NASHVILLE" },
  "37250": { state: "TN", city: "NASHVILLE" },
  "37301": { state: "TN", city: "ALTAMONT" },
  "37302": { state: "TN", city: "APISON" },
  "37303": { state: "TN", city: "ATHENS" },
  "37304": { state: "TN", city: "BAKEWELL" },
  "37305": { state: "TN", city: "BEERSHEBA SPRINGS" },
  "37306": { state: "TN", city: "BELVIDERE" },
  "37307": { state: "TN", city: "BENTON" },
  "37308": { state: "TN", city: "BIRCHWOOD" },
  "37309": { state: "TN", city: "CALHOUN" },
  "37310": { state: "TN", city: "CHARLESTON" },
  "37311": { state: "TN", city: "CLEVELAND" },
  "37312": { state: "TN", city: "CLEVELAND" },
  "37313": { state: "TN", city: "COALMONT" },
  "37314": { state: "TN", city: "COKER CREEK" },
  "37315": { state: "TN", city: "COLLEGEDALE" },
  "37316": { state: "TN", city: "CONASAUGA" },
  "37317": { state: "TN", city: "COPPERHILL" },
  "37318": { state: "TN", city: "COWAN" },
  "37320": { state: "TN", city: "CLEVELAND" },
  "37321": { state: "TN", city: "DAYTON" },
  "37322": { state: "TN", city: "DECATUR" },
  "37323": { state: "TN", city: "CLEVELAND" },
  "37324": { state: "TN", city: "DECHERD" },
  "37325": { state: "TN", city: "DELANO" },
  "37326": { state: "TN", city: "DUCKTOWN" },
  "37327": { state: "TN", city: "DUNLAP" },
  "37328": { state: "TN", city: "ELORA" },
  "37329": { state: "TN", city: "ENGLEWOOD" },
  "37330": { state: "TN", city: "ESTILL SPRINGS" },
  "37331": { state: "TN", city: "ETOWAH" },
  "37332": { state: "TN", city: "EVENSVILLE" },
  "37333": { state: "TN", city: "FARNER" },
  "37334": { state: "TN", city: "FAYETTEVILLE" },
  "37335": { state: "TN", city: "FLINTVILLE" },
  "37336": { state: "TN", city: "GEORGETOWN" },
  "37337": { state: "TN", city: "GRANDVIEW" },
  "37338": { state: "TN", city: "GRAYSVILLE" },
  "37339": { state: "TN", city: "GRUETLI LAAGER" },
  "37340": { state: "TN", city: "GUILD" },
  "37341": { state: "TN", city: "HARRISON" },
  "37342": { state: "TN", city: "HILLSBORO" },
  "37343": { state: "TN", city: "HIXSON" },
  "37345": { state: "TN", city: "HUNTLAND" },
  "37347": { state: "TN", city: "JASPER" },
  "37348": { state: "TN", city: "KELSO" },
  "37349": { state: "TN", city: "MANCHESTER" },
  "37350": { state: "TN", city: "LOOKOUT MOUNTAIN" },
  "37351": { state: "TN", city: "LUPTON CITY" },
  "37352": { state: "TN", city: "LYNCHBURG" },
  "37353": { state: "TN", city: "MC DONALD" },
  "37354": { state: "TN", city: "MADISONVILLE" },
  "37355": { state: "TN", city: "MANCHESTER" },
  "37356": { state: "TN", city: "MONTEAGLE" },
  "37357": { state: "TN", city: "MORRISON" },
  "37359": { state: "TN", city: "MULBERRY" },
  "37360": { state: "TN", city: "NORMANDY" },
  "37361": { state: "TN", city: "OCOEE" },
  "37362": { state: "TN", city: "OLD FORT" },
  "37363": { state: "TN", city: "OOLTEWAH" },
  "37364": { state: "TN", city: "CLEVELAND" },
  "37365": { state: "TN", city: "PALMER" },
  "37366": { state: "TN", city: "PELHAM" },
  "37367": { state: "TN", city: "PIKEVILLE" },
  "37369": { state: "TN", city: "RELIANCE" },
  "37370": { state: "TN", city: "RICEVILLE" },
  "37371": { state: "TN", city: "ATHENS" },
  "37373": { state: "TN", city: "SALE CREEK" },
  "37374": { state: "TN", city: "SEQUATCHIE" },
  "37375": { state: "TN", city: "SEWANEE" },
  "37376": { state: "TN", city: "SHERWOOD" },
  "37377": { state: "TN", city: "SIGNAL MOUNTAIN" },
  "37378": { state: "TN", city: "SMARTT" },
  "37379": { state: "TN", city: "SODDY DAISY" },
  "37380": { state: "TN", city: "SOUTH PITTSBURG" },
  "37381": { state: "TN", city: "SPRING CITY" },
  "37382": { state: "TN", city: "SUMMITVILLE" },
  "37383": { state: "TN", city: "SEWANEE" },
  "37384": { state: "TN", city: "SODDY DAISY" },
  "37385": { state: "TN", city: "TELLICO PLAINS" },
  "37387": { state: "TN", city: "TRACY CITY" },
  "37388": { state: "TN", city: "TULLAHOMA" },
  "37389": { state: "TN", city: "ARNOLD AFB" },
  "37391": { state: "TN", city: "TURTLETOWN" },
  "37394": { state: "TN", city: "VIOLA" },
  "37396": { state: "TN", city: "WHITESIDE" },
  "37397": { state: "TN", city: "WHITWELL" },
  "37398": { state: "TN", city: "WINCHESTER" },
  "37401": { state: "TN", city: "CHATTANOOGA" },
  "37402": { state: "TN", city: "CHATTANOOGA" },
  "37403": { state: "TN", city: "CHATTANOOGA" },
  "37404": { state: "TN", city: "CHATTANOOGA" },
  "37405": { state: "TN", city: "CHATTANOOGA" },
  "37406": { state: "TN", city: "CHATTANOOGA" },
  "37407": { state: "TN", city: "CHATTANOOGA" },
  "37408": { state: "TN", city: "CHATTANOOGA" },
  "37409": { state: "TN", city: "CHATTANOOGA" },
  "37410": { state: "TN", city: "CHATTANOOGA" },
  "37411": { state: "TN", city: "CHATTANOOGA" },
  "37412": { state: "TN", city: "CHATTANOOGA" },
  "37414": { state: "TN", city: "CHATTANOOGA" },
  "37415": { state: "TN", city: "CHATTANOOGA" },
  "37416": { state: "TN", city: "CHATTANOOGA" },
  "37419": { state: "TN", city: "CHATTANOOGA" },
  "37421": { state: "TN", city: "CHATTANOOGA" },
  "37422": { state: "TN", city: "CHATTANOOGA" },
  "37424": { state: "TN", city: "CHATTANOOGA" },
  "37450": { state: "TN", city: "CHATTANOOGA" },
  "37501": { state: "TN", city: "MEMPHIS" },
  "37544": { state: "TN", city: "MEMPHIS" },
  "37601": { state: "TN", city: "JOHNSON CITY" },
  "37602": { state: "TN", city: "JOHNSON CITY" },
  "37604": { state: "TN", city: "JOHNSON CITY" },
  "37605": { state: "TN", city: "JOHNSON CITY" },
  "37614": { state: "TN", city: "JOHNSON CITY" },
  "37615": { state: "TN", city: "JOHNSON CITY" },
  "37616": { state: "TN", city: "AFTON" },
  "37617": { state: "TN", city: "BLOUNTVILLE" },
  "37618": { state: "TN", city: "BLUFF CITY" },
  "37620": { state: "TN", city: "BRISTOL" },
  "37621": { state: "TN", city: "BRISTOL" },
  "37625": { state: "TN", city: "BRISTOL" },
  "37640": { state: "TN", city: "BUTLER" },
  "37641": { state: "TN", city: "CHUCKEY" },
  "37642": { state: "TN", city: "CHURCH HILL" },
  "37643": { state: "TN", city: "ELIZABETHTON" },
  "37644": { state: "TN", city: "ELIZABETHTON" },
  "37645": { state: "TN", city: "MOUNT CARMEL" },
  "37650": { state: "TN", city: "ERWIN" },
  "37656": { state: "TN", city: "FALL BRANCH" },
  "37657": { state: "TN", city: "FLAG POND" },
  "37658": { state: "TN", city: "HAMPTON" },
  "37659": { state: "TN", city: "JONESBOROUGH" },
  "37660": { state: "TN", city: "KINGSPORT" },
  "37662": { state: "TN", city: "KINGSPORT" },
  "37663": { state: "TN", city: "KINGSPORT" },
  "37664": { state: "TN", city: "KINGSPORT" },
  "37665": { state: "TN", city: "KINGSPORT" },
  "37669": { state: "TN", city: "KINGSPORT" },
  "37680": { state: "TN", city: "LAUREL BLOOMERY" },
  "37681": { state: "TN", city: "LIMESTONE" },
  "37682": { state: "TN", city: "MILLIGAN COLLEGE" },
  "37683": { state: "TN", city: "MOUNTAIN CITY" },
  "37684": { state: "TN", city: "MOUNTAIN HOME" },
  "37686": { state: "TN", city: "PINEY FLATS" },
  "37687": { state: "TN", city: "ROAN MOUNTAIN" },
  "37688": { state: "TN", city: "SHADY VALLEY" },
  "37690": { state: "TN", city: "TELFORD" },
  "37691": { state: "TN", city: "TRADE" },
  "37692": { state: "TN", city: "UNICOI" },
  "37694": { state: "TN", city: "WATAUGA" },
  "37699": { state: "TN", city: "PINEY FLATS" },
  "37701": { state: "TN", city: "ALCOA" },
  "37705": { state: "TN", city: "ANDERSONVILLE" },
  "37707": { state: "TN", city: "ARTHUR" },
  "37708": { state: "TN", city: "BEAN STATION" },
  "37709": { state: "TN", city: "BLAINE" },
  "37710": { state: "TN", city: "BRICEVILLE" },
  "37711": { state: "TN", city: "BULLS GAP" },
  "37713": { state: "TN", city: "BYBEE" },
  "37714": { state: "TN", city: "CARYVILLE" },
  "37715": { state: "TN", city: "CLAIRFIELD" },
  "37716": { state: "TN", city: "CLINTON" },
  "37717": { state: "TN", city: "CLINTON" },
  "37719": { state: "TN", city: "COALFIELD" },
  "37721": { state: "TN", city: "CORRYTON" },
  "37722": { state: "TN", city: "COSBY" },
  "37723": { state: "TN", city: "CRAB ORCHARD" },
  "37724": { state: "TN", city: "CUMBERLAND GAP" },
  "37725": { state: "TN", city: "DANDRIDGE" },
  "37726": { state: "TN", city: "DEER LODGE" },
  "37727": { state: "TN", city: "DEL RIO" },
  "37729": { state: "TN", city: "DUFF" },
  "37730": { state: "TN", city: "EAGAN" },
  "37731": { state: "TN", city: "EIDSON" },
  "37732": { state: "TN", city: "ELGIN" },
  "37733": { state: "TN", city: "RUGBY" },
  "37737": { state: "TN", city: "FRIENDSVILLE" },
  "37738": { state: "TN", city: "GATLINBURG" },
  "37742": { state: "TN", city: "GREENBACK" },
  "37743": { state: "TN", city: "GREENEVILLE" },
  "37744": { state: "TN", city: "GREENEVILLE" },
  "37745": { state: "TN", city: "GREENEVILLE" },
  "37748": { state: "TN", city: "HARRIMAN" },
  "37752": { state: "TN", city: "HARROGATE" },
  "37753": { state: "TN", city: "HARTFORD" },
  "37754": { state: "TN", city: "HEISKELL" },
  "37755": { state: "TN", city: "HELENWOOD" },
  "37756": { state: "TN", city: "HUNTSVILLE" },
  "37757": { state: "TN", city: "JACKSBORO" },
  "37760": { state: "TN", city: "JEFFERSON CITY" },
  "37762": { state: "TN", city: "JELLICO" },
  "37763": { state: "TN", city: "KINGSTON" },
  "37764": { state: "TN", city: "KODAK" },
  "37765": { state: "TN", city: "KYLES FORD" },
  "37766": { state: "TN", city: "LA FOLLETTE" },
  "37769": { state: "TN", city: "ROCKY TOP" },
  "37770": { state: "TN", city: "LANCING" },
  "37771": { state: "TN", city: "LENOIR CITY" },
  "37772": { state: "TN", city: "LENOIR CITY" },
  "37773": { state: "TN", city: "LONE MOUNTAIN" },
  "37774": { state: "TN", city: "LOUDON" },
  "37777": { state: "TN", city: "LOUISVILLE" },
  "37778": { state: "TN", city: "LOWLAND" },
  "37779": { state: "TN", city: "LUTTRELL" },
  "37801": { state: "TN", city: "MARYVILLE" },
  "37802": { state: "TN", city: "MARYVILLE" },
  "37803": { state: "TN", city: "MARYVILLE" },
  "37804": { state: "TN", city: "MARYVILLE" },
  "37806": { state: "TN", city: "MASCOT" },
  "37807": { state: "TN", city: "MAYNARDVILLE" },
  "37809": { state: "TN", city: "MIDWAY" },
  "37810": { state: "TN", city: "MOHAWK" },
  "37811": { state: "TN", city: "MOORESBURG" },
  "37813": { state: "TN", city: "MORRISTOWN" },
  "37814": { state: "TN", city: "MORRISTOWN" },
  "37815": { state: "TN", city: "MORRISTOWN" },
  "37816": { state: "TN", city: "MORRISTOWN" },
  "37818": { state: "TN", city: "MOSHEIM" },
  "37819": { state: "TN", city: "NEWCOMB" },
  "37820": { state: "TN", city: "NEW MARKET" },
  "37821": { state: "TN", city: "NEWPORT" },
  "37822": { state: "TN", city: "NEWPORT" },
  "37824": { state: "TN", city: "NEW TAZEWELL" },
  "37825": { state: "TN", city: "NEW TAZEWELL" },
  "37826": { state: "TN", city: "NIOTA" },
  "37828": { state: "TN", city: "NORRIS" },
  "37829": { state: "TN", city: "OAKDALE" },
  "37830": { state: "TN", city: "OAK RIDGE" },
  "37831": { state: "TN", city: "OAK RIDGE" },
  "37840": { state: "TN", city: "OLIVER SPRINGS" },
  "37841": { state: "TN", city: "ONEIDA" },
  "37843": { state: "TN", city: "PARROTTSVILLE" },
  "37845": { state: "TN", city: "PETROS" },
  "37846": { state: "TN", city: "PHILADELPHIA" },
  "37847": { state: "TN", city: "PIONEER" },
  "37848": { state: "TN", city: "POWDER SPRINGS" },
  "37849": { state: "TN", city: "POWELL" },
  "37851": { state: "TN", city: "PRUDEN" },
  "37852": { state: "TN", city: "ROBBINS" },
  "37853": { state: "TN", city: "ROCKFORD" },
  "37854": { state: "TN", city: "ROCKWOOD" },
  "37857": { state: "TN", city: "ROGERSVILLE" },
  "37860": { state: "TN", city: "RUSSELLVILLE" },
  "37861": { state: "TN", city: "RUTLEDGE" },
  "37862": { state: "TN", city: "SEVIERVILLE" },
  "37863": { state: "TN", city: "PIGEON FORGE" },
  "37864": { state: "TN", city: "SEVIERVILLE" },
  "37865": { state: "TN", city: "SEYMOUR" },
  "37866": { state: "TN", city: "SHARPS CHAPEL" },
  "37867": { state: "TN", city: "SHAWANEE" },
  "37868": { state: "TN", city: "PIGEON FORGE" },
  "37869": { state: "TN", city: "SNEEDVILLE" },
  "37870": { state: "TN", city: "SPEEDWELL" },
  "37871": { state: "TN", city: "STRAWBERRY PLAINS" },
  "37872": { state: "TN", city: "SUNBRIGHT" },
  "37873": { state: "TN", city: "SURGOINSVILLE" },
  "37874": { state: "TN", city: "SWEETWATER" },
  "37876": { state: "TN", city: "SEVIERVILLE" },
  "37877": { state: "TN", city: "TALBOTT" },
  "37878": { state: "TN", city: "TALLASSEE" },
  "37879": { state: "TN", city: "TAZEWELL" },
  "37880": { state: "TN", city: "TEN MILE" },
  "37881": { state: "TN", city: "THORN HILL" },
  "37882": { state: "TN", city: "TOWNSEND" },
  "37885": { state: "TN", city: "VONORE" },
  "37886": { state: "TN", city: "WALLAND" },
  "37887": { state: "TN", city: "WARTBURG" },
  "37888": { state: "TN", city: "WASHBURN" },
  "37890": { state: "TN", city: "WHITE PINE" },
  "37891": { state: "TN", city: "WHITESBURG" },
  "37892": { state: "TN", city: "WINFIELD" },
  "37901": { state: "TN", city: "KNOXVILLE" },
  "37902": { state: "TN", city: "KNOXVILLE" },
  "37909": { state: "TN", city: "KNOXVILLE" },
  "37912": { state: "TN", city: "KNOXVILLE" },
  "37914": { state: "TN", city: "KNOXVILLE" },
  "37915": { state: "TN", city: "KNOXVILLE" },
  "37916": { state: "TN", city: "KNOXVILLE" },
  "37917": { state: "TN", city: "KNOXVILLE" },
  "37918": { state: "TN", city: "KNOXVILLE" },
  "37919": { state: "TN", city: "KNOXVILLE" },
  "37920": { state: "TN", city: "KNOXVILLE" },
  "37921": { state: "TN", city: "KNOXVILLE" },
  "37922": { state: "TN", city: "KNOXVILLE" },
  "37923": { state: "TN", city: "KNOXVILLE" },
  "37924": { state: "TN", city: "KNOXVILLE" },
  "37927": { state: "TN", city: "KNOXVILLE" },
  "37928": { state: "TN", city: "KNOXVILLE" },
  "37929": { state: "TN", city: "KNOXVILLE" },
  "37930": { state: "TN", city: "KNOXVILLE" },
  "37931": { state: "TN", city: "KNOXVILLE" },
  "37932": { state: "TN", city: "KNOXVILLE" },
  "37933": { state: "TN", city: "KNOXVILLE" },
  "37934": { state: "TN", city: "FARRAGUT" },
  "37938": { state: "TN", city: "KNOXVILLE" },
  "37939": { state: "TN", city: "KNOXVILLE" },
  "37940": { state: "TN", city: "KNOXVILLE" },
  "37950": { state: "TN", city: "KNOXVILLE" },
  "37995": { state: "TN", city: "KNOXVILLE" },
  "37996": { state: "TN", city: "KNOXVILLE" },
  "37997": { state: "TN", city: "KNOXVILLE" },
  "37998": { state: "TN", city: "KNOXVILLE" },
  "38001": { state: "TN", city: "ALAMO" },
  "38002": { state: "TN", city: "ARLINGTON" },
  "38004": { state: "TN", city: "ATOKA" },
  "38006": { state: "TN", city: "BELLS" },
  "38007": { state: "TN", city: "BOGOTA" },
  "38008": { state: "TN", city: "BOLIVAR" },
  "38010": { state: "TN", city: "BRADEN" },
  "38011": { state: "TN", city: "BRIGHTON" },
  "38012": { state: "TN", city: "BROWNSVILLE" },
  "38014": { state: "TN", city: "BRUNSWICK" },
  "38015": { state: "TN", city: "BURLISON" },
  "38016": { state: "TN", city: "CORDOVA" },
  "38017": { state: "TN", city: "COLLIERVILLE" },
  "38018": { state: "TN", city: "CORDOVA" },
  "38019": { state: "TN", city: "COVINGTON" },
  "38021": { state: "TN", city: "CROCKETT MILLS" },
  "38023": { state: "TN", city: "DRUMMONDS" },
  "38024": { state: "TN", city: "DYERSBURG" },
  "38025": { state: "TN", city: "DYERSBURG" },
  "38027": { state: "TN", city: "COLLIERVILLE" },
  "38028": { state: "TN", city: "EADS" },
  "38029": { state: "TN", city: "ELLENDALE" },
  "38030": { state: "TN", city: "FINLEY" },
  "38034": { state: "TN", city: "FRIENDSHIP" },
  "38036": { state: "TN", city: "GALLAWAY" },
  "38037": { state: "TN", city: "GATES" },
  "38039": { state: "TN", city: "GRAND JUNCTION" },
  "38040": { state: "TN", city: "HALLS" },
  "38041": { state: "TN", city: "HENNING" },
  "38042": { state: "TN", city: "HICKORY VALLEY" },
  "38044": { state: "TN", city: "HORNSBY" },
  "38045": { state: "TN", city: "LACONIA" },
  "38046": { state: "TN", city: "LA GRANGE" },
  "38047": { state: "TN", city: "LENOX" },
  "38048": { state: "TN", city: "MACON" },
  "38049": { state: "TN", city: "MASON" },
  "38050": { state: "TN", city: "MAURY CITY" },
  "38052": { state: "TN", city: "MIDDLETON" },
  "38053": { state: "TN", city: "MILLINGTON" },
  "38054": { state: "TN", city: "MILLINGTON" },
  "38055": { state: "TN", city: "MILLINGTON" },
  "38057": { state: "TN", city: "MOSCOW" },
  "38058": { state: "TN", city: "MUNFORD" },
  "38059": { state: "TN", city: "NEWBERN" },
  "38060": { state: "TN", city: "OAKLAND" },
  "38061": { state: "TN", city: "POCAHONTAS" },
  "38063": { state: "TN", city: "RIPLEY" },
  "38066": { state: "TN", city: "ROSSVILLE" },
  "38067": { state: "TN", city: "SAULSBURY" },
  "38068": { state: "TN", city: "SOMERVILLE" },
  "38069": { state: "TN", city: "STANTON" },
  "38070": { state: "TN", city: "TIGRETT" },
  "38071": { state: "TN", city: "TIPTON" },
  "38075": { state: "TN", city: "WHITEVILLE" },
  "38076": { state: "TN", city: "WILLISTON" },
  "38077": { state: "TN", city: "WYNNBURG" },
  "38079": { state: "TN", city: "TIPTONVILLE" },
  "38080": { state: "TN", city: "RIDGELY" },
  "38083": { state: "TN", city: "MILLINGTON" },
  "38088": { state: "TN", city: "CORDOVA" },
  "38101": { state: "TN", city: "MEMPHIS" },
  "38103": { state: "TN", city: "MEMPHIS" },
  "38104": { state: "TN", city: "MEMPHIS" },
  "38105": { state: "TN", city: "MEMPHIS" },
  "38106": { state: "TN", city: "MEMPHIS" },
  "38107": { state: "TN", city: "MEMPHIS" },
  "38108": { state: "TN", city: "MEMPHIS" },
  "38109": { state: "TN", city: "MEMPHIS" },
  "38111": { state: "TN", city: "MEMPHIS" },
  "38112": { state: "TN", city: "MEMPHIS" },
  "38113": { state: "TN", city: "MEMPHIS" },
  "38114": { state: "TN", city: "MEMPHIS" },
  "38115": { state: "TN", city: "MEMPHIS" },
  "38116": { state: "TN", city: "MEMPHIS" },
  "38117": { state: "TN", city: "MEMPHIS" },
  "38118": { state: "TN", city: "MEMPHIS" },
  "38119": { state: "TN", city: "MEMPHIS" },
  "38120": { state: "TN", city: "MEMPHIS" },
  "38122": { state: "TN", city: "MEMPHIS" },
  "38124": { state: "TN", city: "MEMPHIS" },
  "38125": { state: "TN", city: "MEMPHIS" },
  "38126": { state: "TN", city: "MEMPHIS" },
  "38127": { state: "TN", city: "MEMPHIS" },
  "38128": { state: "TN", city: "MEMPHIS" },
  "38130": { state: "TN", city: "MEMPHIS" },
  "38131": { state: "TN", city: "MEMPHIS" },
  "38132": { state: "TN", city: "MEMPHIS" },
  "38133": { state: "TN", city: "MEMPHIS" },
  "38134": { state: "TN", city: "MEMPHIS" },
  "38135": { state: "TN", city: "MEMPHIS" },
  "38136": { state: "TN", city: "MEMPHIS" },
  "38137": { state: "TN", city: "MEMPHIS" },
  "38138": { state: "TN", city: "GERMANTOWN" },
  "38139": { state: "TN", city: "GERMANTOWN" },
  "38141": { state: "TN", city: "MEMPHIS" },
  "38145": { state: "TN", city: "MEMPHIS" },
  "38148": { state: "TN", city: "MEMPHIS" },
  "38150": { state: "TN", city: "MEMPHIS" },
  "38151": { state: "TN", city: "MEMPHIS" },
  "38152": { state: "TN", city: "MEMPHIS" },
  "38157": { state: "TN", city: "MEMPHIS" },
  "38159": { state: "TN", city: "MEMPHIS" },
  "38161": { state: "TN", city: "MEMPHIS" },
  "38163": { state: "TN", city: "MEMPHIS" },
  "38166": { state: "TN", city: "MEMPHIS" },
  "38167": { state: "TN", city: "MEMPHIS" },
  "38168": { state: "TN", city: "MEMPHIS" },
  "38173": { state: "TN", city: "MEMPHIS" },
  "38174": { state: "TN", city: "MEMPHIS" },
  "38175": { state: "TN", city: "MEMPHIS" },
  "38177": { state: "TN", city: "MEMPHIS" },
  "38181": { state: "TN", city: "MEMPHIS" },
  "38182": { state: "TN", city: "MEMPHIS" },
  "38183": { state: "TN", city: "GERMANTOWN" },
  "38184": { state: "TN", city: "MEMPHIS" },
  "38186": { state: "TN", city: "MEMPHIS" },
  "38187": { state: "TN", city: "MEMPHIS" },
  "38188": { state: "TN", city: "MEMPHIS" },
  "38190": { state: "TN", city: "MEMPHIS" },
  "38193": { state: "TN", city: "MEMPHIS" },
  "38194": { state: "TN", city: "MEMPHIS" },
  "38197": { state: "TN", city: "MEMPHIS" },
  "38201": { state: "TN", city: "MC KENZIE" },
  "38220": { state: "TN", city: "ATWOOD" },
  "38221": { state: "TN", city: "BIG SANDY" },
  "38222": { state: "TN", city: "BUCHANAN" },
  "38223": { state: "TN", city: "COMO" },
  "38224": { state: "TN", city: "COTTAGE GROVE" },
  "38225": { state: "TN", city: "DRESDEN" },
  "38226": { state: "TN", city: "DUKEDOM" },
  "38229": { state: "TN", city: "GLEASON" },
  "38230": { state: "TN", city: "GREENFIELD" },
  "38231": { state: "TN", city: "HENRY" },
  "38232": { state: "TN", city: "HORNBEAK" },
  "38233": { state: "TN", city: "KENTON" },
  "38235": { state: "TN", city: "MC LEMORESVILLE" },
  "38236": { state: "TN", city: "MANSFIELD" },
  "38237": { state: "TN", city: "MARTIN" },
  "38238": { state: "TN", city: "MARTIN" },
  "38240": { state: "TN", city: "OBION" },
  "38241": { state: "TN", city: "PALMERSVILLE" },
  "38242": { state: "TN", city: "PARIS" },
  "38251": { state: "TN", city: "PURYEAR" },
  "38253": { state: "TN", city: "RIVES" },
  "38254": { state: "TN", city: "SAMBURG" },
  "38255": { state: "TN", city: "SHARON" },
  "38256": { state: "TN", city: "SPRINGVILLE" },
  "38257": { state: "TN", city: "SOUTH FULTON" },
  "38258": { state: "TN", city: "TREZEVANT" },
  "38259": { state: "TN", city: "TRIMBLE" },
  "38260": { state: "TN", city: "TROY" },
  "38261": { state: "TN", city: "UNION CITY" },
  "38271": { state: "TN", city: "WOODLAND MILLS" },
  "38281": { state: "TN", city: "UNION CITY" },
  "38301": { state: "TN", city: "JACKSON" },
  "38302": { state: "TN", city: "JACKSON" },
  "38303": { state: "TN", city: "JACKSON" },
  "38305": { state: "TN", city: "JACKSON" },
  "38308": { state: "TN", city: "JACKSON" },
  "38310": { state: "TN", city: "ADAMSVILLE" },
  "38311": { state: "TN", city: "BATH SPRINGS" },
  "38313": { state: "TN", city: "BEECH BLUFF" },
  "38314": { state: "TN", city: "JACKSON" },
  "38315": { state: "TN", city: "BETHEL SPRINGS" },
  "38316": { state: "TN", city: "BRADFORD" },
  "38317": { state: "TN", city: "BRUCETON" },
  "38318": { state: "TN", city: "BUENA VISTA" },
  "38320": { state: "TN", city: "CAMDEN" },
  "38321": { state: "TN", city: "CEDAR GROVE" },
  "38324": { state: "TN", city: "CLARKSBURG" },
  "38326": { state: "TN", city: "COUNCE" },
  "38327": { state: "TN", city: "CRUMP" },
  "38328": { state: "TN", city: "DARDEN" },
  "38329": { state: "TN", city: "DECATURVILLE" },
  "38330": { state: "TN", city: "DYER" },
  "38331": { state: "TN", city: "EATON" },
  "38332": { state: "TN", city: "ENVILLE" },
  "38333": { state: "TN", city: "EVA" },
  "38334": { state: "TN", city: "FINGER" },
  "38336": { state: "TN", city: "FRUITVALE" },
  "38337": { state: "TN", city: "GADSDEN" },
  "38338": { state: "TN", city: "GIBSON" },
  "38339": { state: "TN", city: "GUYS" },
  "38340": { state: "TN", city: "HENDERSON" },
  "38341": { state: "TN", city: "HOLLADAY" },
  "38342": { state: "TN", city: "HOLLOW ROCK" },
  "38343": { state: "TN", city: "HUMBOLDT" },
  "38344": { state: "TN", city: "HUNTINGDON" },
  "38345": { state: "TN", city: "HURON" },
  "38346": { state: "TN", city: "IDLEWILD" },
  "38347": { state: "TN", city: "JACKS CREEK" },
  "38348": { state: "TN", city: "LAVINIA" },
  "38351": { state: "TN", city: "LEXINGTON" },
  "38352": { state: "TN", city: "LURAY" },
  "38355": { state: "TN", city: "MEDINA" },
  "38356": { state: "TN", city: "MEDON" },
  "38357": { state: "TN", city: "MICHIE" },
  "38358": { state: "TN", city: "MILAN" },
  "38359": { state: "TN", city: "MILLEDGEVILLE" },
  "38361": { state: "TN", city: "MORRIS CHAPEL" },
  "38362": { state: "TN", city: "OAKFIELD" },
  "38363": { state: "TN", city: "PARSONS" },
  "38365": { state: "TN", city: "PICKWICK DAM" },
  "38366": { state: "TN", city: "PINSON" },
  "38367": { state: "TN", city: "RAMER" },
  "38368": { state: "TN", city: "REAGAN" },
  "38369": { state: "TN", city: "RUTHERFORD" },
  "38370": { state: "TN", city: "SALTILLO" },
  "38371": { state: "TN", city: "SARDIS" },
  "38372": { state: "TN", city: "SAVANNAH" },
  "38374": { state: "TN", city: "SCOTTS HILL" },
  "38375": { state: "TN", city: "SELMER" },
  "38376": { state: "TN", city: "SHILOH" },
  "38377": { state: "TN", city: "SILERTON" },
  "38378": { state: "TN", city: "SPRING CREEK" },
  "38379": { state: "TN", city: "STANTONVILLE" },
  "38380": { state: "TN", city: "SUGAR TREE" },
  "38381": { state: "TN", city: "TOONE" },
  "38382": { state: "TN", city: "TRENTON" },
  "38387": { state: "TN", city: "WESTPORT" },
  "38388": { state: "TN", city: "WILDERSVILLE" },
  "38389": { state: "TN", city: "YORKVILLE" },
  "38390": { state: "TN", city: "YUMA" },
  "38391": { state: "TN", city: "DENMARK" },
  "38392": { state: "TN", city: "MERCER" },
  "38393": { state: "TN", city: "CHEWALLA" },
  "38401": { state: "TN", city: "COLUMBIA" },
  "38402": { state: "TN", city: "COLUMBIA" },
  "38425": { state: "TN", city: "CLIFTON" },
  "38449": { state: "TN", city: "ARDMORE" },
  "38450": { state: "TN", city: "COLLINWOOD" },
  "38451": { state: "TN", city: "CULLEOKA" },
  "38452": { state: "TN", city: "CYPRESS INN" },
  "38453": { state: "TN", city: "DELLROSE" },
  "38454": { state: "TN", city: "DUCK RIVER" },
  "38455": { state: "TN", city: "ELKTON" },
  "38456": { state: "TN", city: "ETHRIDGE" },
  "38457": { state: "TN", city: "FIVE POINTS" },
  "38459": { state: "TN", city: "FRANKEWING" },
  "38460": { state: "TN", city: "GOODSPRING" },
  "38461": { state: "TN", city: "HAMPSHIRE" },
  "38462": { state: "TN", city: "HOHENWALD" },
  "38463": { state: "TN", city: "IRON CITY" },
  "38464": { state: "TN", city: "LAWRENCEBURG" },
  "38468": { state: "TN", city: "LEOMA" },
  "38469": { state: "TN", city: "LORETTO" },
  "38471": { state: "TN", city: "LUTTS" },
  "38472": { state: "TN", city: "LYNNVILLE" },
  "38473": { state: "TN", city: "MINOR HILL" },
  "38474": { state: "TN", city: "MOUNT PLEASANT" },
  "38475": { state: "TN", city: "OLIVEHILL" },
  "38476": { state: "TN", city: "PRIMM SPRINGS" },
  "38477": { state: "TN", city: "PROSPECT" },
  "38478": { state: "TN", city: "PULASKI" },
  "38481": { state: "TN", city: "SAINT JOSEPH" },
  "38482": { state: "TN", city: "SANTA FE" },
  "38483": { state: "TN", city: "SUMMERTOWN" },
  "38485": { state: "TN", city: "WAYNESBORO" },
  "38486": { state: "TN", city: "WESTPOINT" },
  "38487": { state: "TN", city: "WILLIAMSPORT" },
  "38488": { state: "TN", city: "TAFT" },
  "38501": { state: "TN", city: "COOKEVILLE" },
  "38502": { state: "TN", city: "COOKEVILLE" },
  "38503": { state: "TN", city: "COOKEVILLE" },
  "38504": { state: "TN", city: "ALLARDT" },
  "38505": { state: "TN", city: "COOKEVILLE" },
  "38506": { state: "TN", city: "COOKEVILLE" },
  "38541": { state: "TN", city: "ALLONS" },
  "38542": { state: "TN", city: "ALLRED" },
  "38543": { state: "TN", city: "ALPINE" },
  "38544": { state: "TN", city: "BAXTER" },
  "38545": { state: "TN", city: "BLOOMINGTON SPRINGS" },
  "38547": { state: "TN", city: "BRUSH CREEK" },
  "38548": { state: "TN", city: "BUFFALO VALLEY" },
  "38549": { state: "TN", city: "BYRDSTOWN" },
  "38550": { state: "TN", city: "CAMPAIGN" },
  "38551": { state: "TN", city: "CELINA" },
  "38552": { state: "TN", city: "CHESTNUT MOUND" },
  "38553": { state: "TN", city: "CLARKRANGE" },
  "38554": { state: "TN", city: "CRAWFORD" },
  "38555": { state: "TN", city: "CROSSVILLE" },
  "38556": { state: "TN", city: "JAMESTOWN" },
  "38557": { state: "TN", city: "CROSSVILLE" },
  "38558": { state: "TN", city: "CROSSVILLE" },
  "38559": { state: "TN", city: "DOYLE" },
  "38560": { state: "TN", city: "ELMWOOD" },
  "38562": { state: "TN", city: "GAINESBORO" },
  "38563": { state: "TN", city: "GORDONSVILLE" },
  "38564": { state: "TN", city: "GRANVILLE" },
  "38565": { state: "TN", city: "GRIMSLEY" },
  "38567": { state: "TN", city: "HICKMAN" },
  "38568": { state: "TN", city: "HILHAM" },
  "38569": { state: "TN", city: "LANCASTER" },
  "38570": { state: "TN", city: "LIVINGSTON" },
  "38571": { state: "TN", city: "CROSSVILLE" },
  "38572": { state: "TN", city: "CROSSVILLE" },
  "38573": { state: "TN", city: "MONROE" },
  "38574": { state: "TN", city: "MONTEREY" },
  "38575": { state: "TN", city: "MOSS" },
  "38577": { state: "TN", city: "PALL MALL" },
  "38578": { state: "TN", city: "PLEASANT HILL" },
  "38579": { state: "TN", city: "QUEBECK" },
  "38580": { state: "TN", city: "RICKMAN" },
  "38581": { state: "TN", city: "ROCK ISLAND" },
  "38582": { state: "TN", city: "SILVER POINT" },
  "38583": { state: "TN", city: "SPARTA" },
  "38585": { state: "TN", city: "SPENCER" },
  "38587": { state: "TN", city: "WALLING" },
  "38588": { state: "TN", city: "WHITLEYVILLE" },
  "38589": { state: "TN", city: "WILDER" },
  "38601": { state: "MS", city: "ABBEVILLE" },
  "38602": { state: "MS", city: "ARKABUTLA" },
  "38603": { state: "MS", city: "ASHLAND" },
  "38606": { state: "MS", city: "BATESVILLE" },
  "38609": { state: "MS", city: "MARKS" },
  "38610": { state: "MS", city: "BLUE MOUNTAIN" },
  "38611": { state: "MS", city: "BYHALIA" },
  "38614": { state: "MS", city: "CLARKSDALE" },
  "38617": { state: "MS", city: "COAHOMA" },
  "38618": { state: "MS", city: "COLDWATER" },
  "38619": { state: "MS", city: "COMO" },
  "38620": { state: "MS", city: "COURTLAND" },
  "38621": { state: "MS", city: "CRENSHAW" },
  "38622": { state: "MS", city: "CROWDER" },
  "38623": { state: "MS", city: "DARLING" },
  "38625": { state: "MS", city: "DUMAS" },
  "38626": { state: "MS", city: "DUNDEE" },
  "38627": { state: "MS", city: "ETTA" },
  "38628": { state: "MS", city: "SLEDGE" },
  "38629": { state: "MS", city: "FALKNER" },
  "38630": { state: "MS", city: "FARRELL" },
  "38631": { state: "MS", city: "FRIARS POINT" },
  "38632": { state: "MS", city: "HERNANDO" },
  "38633": { state: "MS", city: "HICKORY FLAT" },
  "38634": { state: "MS", city: "HOLLY SPRINGS" },
  "38635": { state: "MS", city: "HOLLY SPRINGS" },
  "38637": { state: "MS", city: "HORN LAKE" },
  "38638": { state: "MS", city: "INDEPENDENCE" },
  "38639": { state: "MS", city: "JONESTOWN" },
  "38641": { state: "MS", city: "LAKE CORMORANT" },
  "38642": { state: "MS", city: "LAMAR" },
  "38643": { state: "MS", city: "LAMBERT" },
  "38644": { state: "MS", city: "LULA" },
  "38645": { state: "MS", city: "LYON" },
  "38646": { state: "MS", city: "MARKS" },
  "38647": { state: "MS", city: "MICHIGAN CITY" },
  "38649": { state: "MS", city: "MOUNT PLEASANT" },
  "38650": { state: "MS", city: "MYRTLE" },
  "38651": { state: "MS", city: "NESBIT" },
  "38652": { state: "MS", city: "NEW ALBANY" },
  "38654": { state: "MS", city: "OLIVE BRANCH" },
  "38655": { state: "MS", city: "OXFORD" },
  "38658": { state: "MS", city: "POPE" },
  "38659": { state: "MS", city: "POTTS CAMP" },
  "38661": { state: "MS", city: "RED BANKS" },
  "38663": { state: "MS", city: "RIPLEY" },
  "38664": { state: "MS", city: "ROBINSONVILLE" },
  "38665": { state: "MS", city: "SARAH" },
  "38666": { state: "MS", city: "SARDIS" },
  "38668": { state: "MS", city: "SENATOBIA" },
  "38669": { state: "MS", city: "SHERARD" },
  "38670": { state: "MS", city: "SLEDGE" },
  "38671": { state: "MS", city: "SOUTHAVEN" },
  "38672": { state: "MS", city: "SOUTHAVEN" },
  "38673": { state: "MS", city: "TAYLOR" },
  "38674": { state: "MS", city: "TIPLERSVILLE" },
  "38675": { state: "MS", city: "MEMPHIS STC" },
  "38676": { state: "MS", city: "TUNICA" },
  "38677": { state: "MS", city: "UNIVERSITY" },
  "38679": { state: "MS", city: "VICTORIA" },
  "38680": { state: "MS", city: "WALLS" },
  "38683": { state: "MS", city: "WALNUT" },
  "38685": { state: "MS", city: "WATERFORD" },
  "38686": { state: "MS", city: "WALLS" },
  "38701": { state: "MS", city: "GREENVILLE" },
  "38702": { state: "MS", city: "GREENVILLE" },
  "38703": { state: "MS", city: "GREENVILLE" },
  "38704": { state: "MS", city: "GREENVILLE" },
  "38720": { state: "MS", city: "ALLIGATOR" },
  "38721": { state: "MS", city: "ANGUILLA" },
  "38722": { state: "MS", city: "ARCOLA" },
  "38723": { state: "MS", city: "AVON" },
  "38725": { state: "MS", city: "BENOIT" },
  "38726": { state: "MS", city: "BEULAH" },
  "38730": { state: "MS", city: "BOYLE" },
  "38731": { state: "MS", city: "CHATHAM" },
  "38732": { state: "MS", city: "CLEVELAND" },
  "38733": { state: "MS", city: "CLEVELAND" },
  "38736": { state: "MS", city: "DODDSVILLE" },
  "38737": { state: "MS", city: "DREW" },
  "38738": { state: "MS", city: "PARCHMAN" },
  "38739": { state: "MS", city: "DUBLIN" },
  "38740": { state: "MS", city: "DUNCAN" },
  "38744": { state: "MS", city: "GLEN ALLAN" },
  "38745": { state: "MS", city: "GRACE" },
  "38746": { state: "MS", city: "GUNNISON" },
  "38748": { state: "MS", city: "HOLLANDALE" },
  "38749": { state: "MS", city: "INDIANOLA" },
  "38751": { state: "MS", city: "INDIANOLA" },
  "38753": { state: "MS", city: "INVERNESS" },
  "38754": { state: "MS", city: "ISOLA" },
  "38756": { state: "MS", city: "LELAND" },
  "38759": { state: "MS", city: "MERIGOLD" },
  "38760": { state: "MS", city: "METCALFE" },
  "38761": { state: "MS", city: "MOORHEAD" },
  "38762": { state: "MS", city: "MOUND BAYOU" },
  "38764": { state: "MS", city: "PACE" },
  "38765": { state: "MS", city: "PANTHER BURN" },
  "38767": { state: "MS", city: "RENA LARA" },
  "38768": { state: "MS", city: "ROME" },
  "38769": { state: "MS", city: "ROSEDALE" },
  "38771": { state: "MS", city: "RULEVILLE" },
  "38772": { state: "MS", city: "SCOTT" },
  "38773": { state: "MS", city: "SHAW" },
  "38774": { state: "MS", city: "SHELBY" },
  "38776": { state: "MS", city: "STONEVILLE" },
  "38778": { state: "MS", city: "SUNFLOWER" },
  "38780": { state: "MS", city: "WAYSIDE" },
  "38781": { state: "MS", city: "WINSTONVILLE" },
  "38782": { state: "MS", city: "WINTERVILLE" },
  "38801": { state: "MS", city: "TUPELO" },
  "38802": { state: "MS", city: "TUPELO" },
  "38803": { state: "MS", city: "TUPELO" },
  "38804": { state: "MS", city: "TUPELO" },
  "38820": { state: "MS", city: "ALGOMA" },
  "38821": { state: "MS", city: "AMORY" },
  "38824": { state: "MS", city: "BALDWYN" },
  "38825": { state: "MS", city: "BECKER" },
  "38826": { state: "MS", city: "BELDEN" },
  "38827": { state: "MS", city: "BELMONT" },
  "38828": { state: "MS", city: "BLUE SPRINGS" },
  "38829": { state: "MS", city: "BOONEVILLE" },
  "38833": { state: "MS", city: "BURNSVILLE" },
  "38834": { state: "MS", city: "CORINTH" },
  "38835": { state: "MS", city: "CORINTH" },
  "38838": { state: "MS", city: "DENNIS" },
  "38839": { state: "MS", city: "DERMA" },
  "38841": { state: "MS", city: "ECRU" },
  "38843": { state: "MS", city: "FULTON" },
  "38844": { state: "MS", city: "GATTMAN" },
  "38846": { state: "MS", city: "GLEN" },
  "38847": { state: "MS", city: "GOLDEN" },
  "38848": { state: "MS", city: "GREENWOOD SPRINGS" },
  "38849": { state: "MS", city: "GUNTOWN" },
  "38850": { state: "MS", city: "HOULKA" },
  "38851": { state: "MS", city: "HOUSTON" },
  "38852": { state: "MS", city: "IUKA" },
  "38855": { state: "MS", city: "MANTACHIE" },
  "38856": { state: "MS", city: "MARIETTA" },
  "38857": { state: "MS", city: "MOOREVILLE" },
  "38858": { state: "MS", city: "NETTLETON" },
  "38859": { state: "MS", city: "NEW SITE" },
  "38860": { state: "MS", city: "OKOLONA" },
  "38862": { state: "MS", city: "PLANTERSVILLE" },
  "38863": { state: "MS", city: "PONTOTOC" },
  "38864": { state: "MS", city: "RANDOLPH" },
  "38865": { state: "MS", city: "RIENZI" },
  "38866": { state: "MS", city: "SALTILLO" },
  "38868": { state: "MS", city: "SHANNON" },
  "38869": { state: "MS", city: "SHERMAN" },
  "38870": { state: "MS", city: "SMITHVILLE" },
  "38871": { state: "MS", city: "THAXTON" },
  "38873": { state: "MS", city: "TISHOMINGO" },
  "38874": { state: "MS", city: "TOCCOPOLA" },
  "38875": { state: "MS", city: "TREBLOC" },
  "38876": { state: "MS", city: "TREMONT" },
  "38877": { state: "MS", city: "VAN VLEET" },
  "38878": { state: "MS", city: "VARDAMAN" },
  "38879": { state: "MS", city: "VERONA" },
  "38880": { state: "MS", city: "WHEELER" },
  "38901": { state: "MS", city: "GRENADA" },
  "38902": { state: "MS", city: "GRENADA" },
  "38913": { state: "MS", city: "BANNER" },
  "38914": { state: "MS", city: "BIG CREEK" },
  "38915": { state: "MS", city: "BRUCE" },
  "38916": { state: "MS", city: "CALHOUN CITY" },
  "38917": { state: "MS", city: "CARROLLTON" },
  "38920": { state: "MS", city: "CASCILLA" },
  "38921": { state: "MS", city: "CHARLESTON" },
  "38922": { state: "MS", city: "COFFEEVILLE" },
  "38923": { state: "MS", city: "COILA" },
  "38924": { state: "MS", city: "CRUGER" },
  "38925": { state: "MS", city: "DUCK HILL" },
  "38926": { state: "MS", city: "ELLIOTT" },
  "38927": { state: "MS", city: "ENID" },
  "38928": { state: "MS", city: "GLENDORA" },
  "38929": { state: "MS", city: "GORE SPRINGS" },
  "38930": { state: "MS", city: "GREENWOOD" },
  "38935": { state: "MS", city: "GREENWOOD" },
  "38940": { state: "MS", city: "HOLCOMB" },
  "38941": { state: "MS", city: "ITTA BENA" },
  "38943": { state: "MS", city: "MC CARLEY" },
  "38944": { state: "MS", city: "MINTER CITY" },
  "38945": { state: "MS", city: "GREENWOOD" },
  "38946": { state: "MS", city: "MORGAN CITY" },
  "38947": { state: "MS", city: "NORTH CARROLLTON" },
  "38948": { state: "MS", city: "OAKLAND" },
  "38949": { state: "MS", city: "PARIS" },
  "38950": { state: "MS", city: "PHILIPP" },
  "38951": { state: "MS", city: "PITTSBORO" },
  "38952": { state: "MS", city: "SCHLATER" },
  "38953": { state: "MS", city: "SCOBEY" },
  "38954": { state: "MS", city: "SIDON" },
  "38955": { state: "MS", city: "SLATE SPRING" },
  "38957": { state: "MS", city: "SUMNER" },
  "38958": { state: "MS", city: "SWAN LAKE" },
  "38959": { state: "MS", city: "SWIFTOWN" },
  "38960": { state: "MS", city: "TIE PLANT" },
  "38961": { state: "MS", city: "TILLATOBA" },
  "38962": { state: "MS", city: "TIPPO" },
  "38963": { state: "MS", city: "TUTWILER" },
  "38964": { state: "MS", city: "VANCE" },
  "38965": { state: "MS", city: "WATER VALLEY" },
  "38966": { state: "MS", city: "WEBB" },
  "38967": { state: "MS", city: "WINONA" },
  "39038": { state: "MS", city: "BELZONI" },
  "39039": { state: "MS", city: "BENTON" },
  "39040": { state: "MS", city: "BENTONIA" },
  "39041": { state: "MS", city: "BOLTON" },
  "39042": { state: "MS", city: "BRANDON" },
  "39043": { state: "MS", city: "BRANDON" },
  "39044": { state: "MS", city: "BRAXTON" },
  "39045": { state: "MS", city: "CAMDEN" },
  "39046": { state: "MS", city: "CANTON" },
  "39047": { state: "MS", city: "BRANDON" },
  "39051": { state: "MS", city: "CARTHAGE" },
  "39054": { state: "MS", city: "CARY" },
  "39056": { state: "MS", city: "CLINTON" },
  "39057": { state: "MS", city: "CONEHATTA" },
  "39058": { state: "MS", city: "CLINTON" },
  "39059": { state: "MS", city: "CRYSTAL SPRINGS" },
  "39060": { state: "MS", city: "CLINTON" },
  "39061": { state: "MS", city: "DELTA CITY" },
  "39062": { state: "MS", city: "D LO" },
  "39063": { state: "MS", city: "DURANT" },
  "39066": { state: "MS", city: "EDWARDS" },
  "39067": { state: "MS", city: "ETHEL" },
  "39069": { state: "MS", city: "FAYETTE" },
  "39071": { state: "MS", city: "FLORA" },
  "39073": { state: "MS", city: "FLORENCE" },
  "39074": { state: "MS", city: "FOREST" },
  "39077": { state: "MS", city: "GALLMAN" },
  "39078": { state: "MS", city: "GEORGETOWN" },
  "39079": { state: "MS", city: "GOODMAN" },
  "39080": { state: "MS", city: "HARPERVILLE" },
  "39081": { state: "MS", city: "FAYETTE" },
  "39082": { state: "MS", city: "HARRISVILLE" },
  "39083": { state: "MS", city: "HAZLEHURST" },
  "39086": { state: "MS", city: "HERMANVILLE" },
  "39087": { state: "MS", city: "HILLSBORO" },
  "39088": { state: "MS", city: "HOLLY BLUFF" },
  "39090": { state: "MS", city: "KOSCIUSKO" },
  "39092": { state: "MS", city: "LAKE" },
  "39094": { state: "MS", city: "LENA" },
  "39095": { state: "MS", city: "LEXINGTON" },
  "39096": { state: "MS", city: "LORMAN" },
  "39097": { state: "MS", city: "LOUISE" },
  "39098": { state: "MS", city: "LUDLOW" },
  "39107": { state: "MS", city: "MC ADAMS" },
  "39108": { state: "MS", city: "MC COOL" },
  "39109": { state: "MS", city: "MADDEN" },
  "39110": { state: "MS", city: "MADISON" },
  "39111": { state: "MS", city: "MAGEE" },
  "39113": { state: "MS", city: "MAYERSVILLE" },
  "39114": { state: "MS", city: "MENDENHALL" },
  "39115": { state: "MS", city: "MIDNIGHT" },
  "39116": { state: "MS", city: "MIZE" },
  "39117": { state: "MS", city: "MORTON" },
  "39119": { state: "MS", city: "MOUNT OLIVE" },
  "39120": { state: "MS", city: "NATCHEZ" },
  "39121": { state: "MS", city: "NATCHEZ" },
  "39122": { state: "MS", city: "NATCHEZ" },
  "39130": { state: "MS", city: "MADISON" },
  "39140": { state: "MS", city: "NEWHEBRON" },
  "39144": { state: "MS", city: "PATTISON" },
  "39145": { state: "MS", city: "PELAHATCHIE" },
  "39146": { state: "MS", city: "PICKENS" },
  "39148": { state: "MS", city: "PINEY WOODS" },
  "39149": { state: "MS", city: "PINOLA" },
  "39150": { state: "MS", city: "PORT GIBSON" },
  "39151": { state: "MS", city: "PUCKETT" },
  "39152": { state: "MS", city: "PULASKI" },
  "39153": { state: "MS", city: "RALEIGH" },
  "39154": { state: "MS", city: "RAYMOND" },
  "39156": { state: "MS", city: "REDWOOD" },
  "39157": { state: "MS", city: "RIDGELAND" },
  "39158": { state: "MS", city: "RIDGELAND" },
  "39159": { state: "MS", city: "ROLLING FORK" },
  "39160": { state: "MS", city: "SALLIS" },
  "39161": { state: "MS", city: "SANDHILL" },
  "39162": { state: "MS", city: "SATARTIA" },
  "39163": { state: "MS", city: "SHARON" },
  "39165": { state: "MS", city: "SIBLEY" },
  "39166": { state: "MS", city: "SILVER CITY" },
  "39167": { state: "MS", city: "STAR" },
  "39168": { state: "MS", city: "TAYLORSVILLE" },
  "39169": { state: "MS", city: "TCHULA" },
  "39170": { state: "MS", city: "TERRY" },
  "39171": { state: "MS", city: "THOMASTOWN" },
  "39173": { state: "MS", city: "TINSLEY" },
  "39174": { state: "MS", city: "TOUGALOO" },
  "39175": { state: "MS", city: "UTICA" },
  "39176": { state: "MS", city: "VAIDEN" },
  "39177": { state: "MS", city: "VALLEY PARK" },
  "39179": { state: "MS", city: "VAUGHAN" },
  "39180": { state: "MS", city: "VICKSBURG" },
  "39181": { state: "MS", city: "VICKSBURG" },
  "39182": { state: "MS", city: "VICKSBURG" },
  "39183": { state: "MS", city: "VICKSBURG" },
  "39189": { state: "MS", city: "WALNUT GROVE" },
  "39190": { state: "MS", city: "WASHINGTON" },
  "39191": { state: "MS", city: "WESSON" },
  "39192": { state: "MS", city: "WEST" },
  "39193": { state: "MS", city: "WHITFIELD" },
  "39194": { state: "MS", city: "YAZOO CITY" },
  "39201": { state: "MS", city: "JACKSON" },
  "39202": { state: "MS", city: "JACKSON" },
  "39203": { state: "MS", city: "JACKSON" },
  "39204": { state: "MS", city: "JACKSON" },
  "39205": { state: "MS", city: "JACKSON" },
  "39206": { state: "MS", city: "JACKSON" },
  "39207": { state: "MS", city: "JACKSON" },
  "39208": { state: "MS", city: "PEARL" },
  "39209": { state: "MS", city: "JACKSON" },
  "39210": { state: "MS", city: "JACKSON" },
  "39211": { state: "MS", city: "JACKSON" },
  "39212": { state: "MS", city: "JACKSON" },
  "39213": { state: "MS", city: "JACKSON" },
  "39215": { state: "MS", city: "JACKSON" },
  "39216": { state: "MS", city: "JACKSON" },
  "39217": { state: "MS", city: "JACKSON" },
  "39218": { state: "MS", city: "RICHLAND" },
  "39225": { state: "MS", city: "JACKSON" },
  "39232": { state: "MS", city: "FLOWOOD" },
  "39236": { state: "MS", city: "JACKSON" },
  "39250": { state: "MS", city: "JACKSON" },
  "39269": { state: "MS", city: "JACKSON" },
  "39271": { state: "MS", city: "JACKSON" },
  "39272": { state: "MS", city: "BYRAM" },
  "39282": { state: "MS", city: "JACKSON" },
  "39283": { state: "MS", city: "JACKSON" },
  "39284": { state: "MS", city: "JACKSON" },
  "39286": { state: "MS", city: "JACKSON" },
  "39288": { state: "MS", city: "PEARL" },
  "39289": { state: "MS", city: "JACKSON" },
  "39296": { state: "MS", city: "JACKSON" },
  "39298": { state: "MS", city: "JACKSON" },
  "39301": { state: "MS", city: "MERIDIAN" },
  "39302": { state: "MS", city: "MERIDIAN" },
  "39303": { state: "MS", city: "MERIDIAN" },
  "39304": { state: "MS", city: "MERIDIAN" },
  "39305": { state: "MS", city: "MERIDIAN" },
  "39307": { state: "MS", city: "MERIDIAN" },
  "39309": { state: "MS", city: "MERIDIAN" },
  "39320": { state: "MS", city: "BAILEY" },
  "39322": { state: "MS", city: "BUCKATUNNA" },
  "39323": { state: "MS", city: "CHUNKY" },
  "39324": { state: "MS", city: "CLARA" },
  "39325": { state: "MS", city: "COLLINSVILLE" },
  "39326": { state: "MS", city: "DALEVILLE" },
  "39327": { state: "MS", city: "DECATUR" },
  "39328": { state: "MS", city: "DE KALB" },
  "39330": { state: "MS", city: "ENTERPRISE" },
  "39332": { state: "MS", city: "HICKORY" },
  "39335": { state: "MS", city: "LAUDERDALE" },
  "39336": { state: "MS", city: "LAWRENCE" },
  "39337": { state: "MS", city: "LITTLE ROCK" },
  "39338": { state: "MS", city: "LOUIN" },
  "39339": { state: "MS", city: "LOUISVILLE" },
  "39341": { state: "MS", city: "MACON" },
  "39342": { state: "MS", city: "MARION" },
  "39345": { state: "MS", city: "NEWTON" },
  "39346": { state: "MS", city: "NOXAPATER" },
  "39347": { state: "MS", city: "PACHUTA" },
  "39348": { state: "MS", city: "PAULDING" },
  "39350": { state: "MS", city: "PHILADELPHIA" },
  "39352": { state: "MS", city: "PORTERVILLE" },
  "39354": { state: "MS", city: "PRESTON" },
  "39355": { state: "MS", city: "QUITMAN" },
  "39356": { state: "MS", city: "ROSE HILL" },
  "39358": { state: "MS", city: "SCOOBA" },
  "39359": { state: "MS", city: "SEBASTOPOL" },
  "39360": { state: "MS", city: "SHUBUTA" },
  "39361": { state: "MS", city: "SHUQUALAK" },
  "39362": { state: "MS", city: "STATE LINE" },
  "39363": { state: "MS", city: "STONEWALL" },
  "39364": { state: "MS", city: "TOOMSUBA" },
  "39365": { state: "MS", city: "UNION" },
  "39366": { state: "MS", city: "VOSSBURG" },
  "39367": { state: "MS", city: "WAYNESBORO" },
  "39401": { state: "MS", city: "HATTIESBURG" },
  "39402": { state: "MS", city: "HATTIESBURG" },
  "39403": { state: "MS", city: "HATTIESBURG" },
  "39404": { state: "MS", city: "HATTIESBURG" },
  "39406": { state: "MS", city: "HATTIESBURG" },
  "39407": { state: "MS", city: "HATTIESBURG" },
  "39421": { state: "MS", city: "BASSFIELD" },
  "39422": { state: "MS", city: "BAY SPRINGS" },
  "39423": { state: "MS", city: "BEAUMONT" },
  "39425": { state: "MS", city: "BROOKLYN" },
  "39426": { state: "MS", city: "CARRIERE" },
  "39427": { state: "MS", city: "CARSON" },
  "39428": { state: "MS", city: "COLLINS" },
  "39429": { state: "MS", city: "COLUMBIA" },
  "39436": { state: "MS", city: "EASTABUCHIE" },
  "39437": { state: "MS", city: "ELLISVILLE" },
  "39439": { state: "MS", city: "HEIDELBERG" },
  "39440": { state: "MS", city: "LAUREL" },
  "39441": { state: "MS", city: "LAUREL" },
  "39442": { state: "MS", city: "LAUREL" },
  "39443": { state: "MS", city: "LAUREL" },
  "39451": { state: "MS", city: "LEAKESVILLE" },
  "39452": { state: "MS", city: "LUCEDALE" },
  "39455": { state: "MS", city: "LUMBERTON" },
  "39456": { state: "MS", city: "MC LAIN" },
  "39457": { state: "MS", city: "MC NEILL" },
  "39459": { state: "MS", city: "MOSELLE" },
  "39460": { state: "MS", city: "MOSS" },
  "39461": { state: "MS", city: "NEELY" },
  "39462": { state: "MS", city: "NEW AUGUSTA" },
  "39463": { state: "MS", city: "NICHOLSON" },
  "39464": { state: "MS", city: "OVETT" },
  "39465": { state: "MS", city: "PETAL" },
  "39466": { state: "MS", city: "PICAYUNE" },
  "39470": { state: "MS", city: "POPLARVILLE" },
  "39474": { state: "MS", city: "PRENTISS" },
  "39475": { state: "MS", city: "PURVIS" },
  "39476": { state: "MS", city: "RICHTON" },
  "39477": { state: "MS", city: "SANDERSVILLE" },
  "39478": { state: "MS", city: "SANDY HOOK" },
  "39479": { state: "MS", city: "SEMINARY" },
  "39480": { state: "MS", city: "SOSO" },
  "39481": { state: "MS", city: "STRINGER" },
  "39482": { state: "MS", city: "SUMRALL" },
  "39483": { state: "MS", city: "FOXWORTH" },
  "39501": { state: "MS", city: "GULFPORT" },
  "39502": { state: "MS", city: "GULFPORT" },
  "39503": { state: "MS", city: "GULFPORT" },
  "39505": { state: "MS", city: "GULFPORT" },
  "39506": { state: "MS", city: "GULFPORT" },
  "39507": { state: "MS", city: "GULFPORT" },
  "39520": { state: "MS", city: "BAY SAINT LOUIS" },
  "39521": { state: "MS", city: "BAY SAINT LOUIS" },
  "39522": { state: "MS", city: "STENNIS SPACE CENTER" },
  "39525": { state: "MS", city: "DIAMONDHEAD" },
  "39529": { state: "MS", city: "STENNIS SPACE CENTER" },
  "39530": { state: "MS", city: "BILOXI" },
  "39531": { state: "MS", city: "BILOXI" },
  "39532": { state: "MS", city: "BILOXI" },
  "39533": { state: "MS", city: "BILOXI" },
  "39534": { state: "MS", city: "BILOXI" },
  "39535": { state: "MS", city: "BILOXI" },
  "39540": { state: "MS", city: "DIBERVILLE" },
  "39552": { state: "MS", city: "ESCATAWPA" },
  "39553": { state: "MS", city: "GAUTIER" },
  "39555": { state: "MS", city: "HURLEY" },
  "39556": { state: "MS", city: "KILN" },
  "39558": { state: "MS", city: "LAKESHORE" },
  "39560": { state: "MS", city: "LONG BEACH" },
  "39561": { state: "MS", city: "MC HENRY" },
  "39562": { state: "MS", city: "MOSS POINT" },
  "39563": { state: "MS", city: "MOSS POINT" },
  "39564": { state: "MS", city: "OCEAN SPRINGS" },
  "39565": { state: "MS", city: "VANCLEAVE" },
  "39566": { state: "MS", city: "OCEAN SPRINGS" },
  "39567": { state: "MS", city: "PASCAGOULA" },
  "39568": { state: "MS", city: "PASCAGOULA" },
  "39569": { state: "MS", city: "PASCAGOULA" },
  "39571": { state: "MS", city: "PASS CHRISTIAN" },
  "39572": { state: "MS", city: "PEARLINGTON" },
  "39573": { state: "MS", city: "PERKINSTON" },
  "39574": { state: "MS", city: "SAUCIER" },
  "39576": { state: "MS", city: "WAVELAND" },
  "39577": { state: "MS", city: "WIGGINS" },
  "39581": { state: "MS", city: "PASCAGOULA" },
  "39595": { state: "MS", city: "PASCAGOULA" },
  "39601": { state: "MS", city: "BROOKHAVEN" },
  "39602": { state: "MS", city: "BROOKHAVEN" },
  "39603": { state: "MS", city: "BROOKHAVEN" },
  "39629": { state: "MS", city: "BOGUE CHITTO" },
  "39630": { state: "MS", city: "BUDE" },
  "39631": { state: "MS", city: "CENTREVILLE" },
  "39632": { state: "MS", city: "CHATAWA" },
  "39633": { state: "MS", city: "CROSBY" },
  "39635": { state: "MS", city: "FERNWOOD" },
  "39638": { state: "MS", city: "GLOSTER" },
  "39641": { state: "MS", city: "JAYESS" },
  "39643": { state: "MS", city: "KOKOMO" },
  "39645": { state: "MS", city: "LIBERTY" },
  "39647": { state: "MS", city: "MC CALL CREEK" },
  "39648": { state: "MS", city: "MCCOMB" },
  "39649": { state: "MS", city: "MCCOMB" },
  "39652": { state: "MS", city: "MAGNOLIA" },
  "39653": { state: "MS", city: "MEADVILLE" },
  "39654": { state: "MS", city: "MONTICELLO" },
  "39656": { state: "MS", city: "OAK VALE" },
  "39657": { state: "MS", city: "OSYKA" },
  "39661": { state: "MS", city: "ROXIE" },
  "39662": { state: "MS", city: "RUTH" },
  "39663": { state: "MS", city: "SILVER CREEK" },
  "39664": { state: "MS", city: "SMITHDALE" },
  "39665": { state: "MS", city: "SONTAG" },
  "39666": { state: "MS", city: "SUMMIT" },
  "39667": { state: "MS", city: "TYLERTOWN" },
  "39668": { state: "MS", city: "UNION CHURCH" },
  "39669": { state: "MS", city: "WOODVILLE" },
  "39701": { state: "MS", city: "COLUMBUS" },
  "39702": { state: "MS", city: "COLUMBUS" },
  "39703": { state: "MS", city: "COLUMBUS" },
  "39704": { state: "MS", city: "COLUMBUS" },
  "39705": { state: "MS", city: "COLUMBUS" },
  "39710": { state: "MS", city: "COLUMBUS" },
  "39730": { state: "MS", city: "ABERDEEN" },
  "39735": { state: "MS", city: "ACKERMAN" },
  "39736": { state: "MS", city: "ARTESIA" },
  "39737": { state: "MS", city: "BELLEFONTAINE" },
  "39739": { state: "MS", city: "BROOKSVILLE" },
  "39740": { state: "MS", city: "CALEDONIA" },
  "39741": { state: "MS", city: "CEDARBLUFF" },
  "39743": { state: "MS", city: "CRAWFORD" },
  "39744": { state: "MS", city: "EUPORA" },
  "39745": { state: "MS", city: "FRENCH CAMP" },
  "39746": { state: "MS", city: "HAMILTON" },
  "39747": { state: "MS", city: "KILMICHAEL" },
  "39750": { state: "MS", city: "MABEN" },
  "39751": { state: "MS", city: "MANTEE" },
  "39752": { state: "MS", city: "MATHISTON" },
  "39753": { state: "MS", city: "MAYHEW" },
  "39754": { state: "MS", city: "MONTPELIER" },
  "39755": { state: "MS", city: "PHEBA" },
  "39756": { state: "MS", city: "PRAIRIE" },
  "39759": { state: "MS", city: "STARKVILLE" },
  "39760": { state: "MS", city: "STARKVILLE" },
  "39762": { state: "MS", city: "MISSISSIPPI STATE" },
  "39766": { state: "MS", city: "STEENS" },
  "39767": { state: "MS", city: "STEWART" },
  "39769": { state: "MS", city: "STURGIS" },
  "39771": { state: "MS", city: "WALTHALL" },
  "39772": { state: "MS", city: "WEIR" },
  "39773": { state: "MS", city: "WEST POINT" },
  "39776": { state: "MS", city: "WOODLAND" },
  "39813": { state: "GA", city: "ARLINGTON" },
  "39815": { state: "GA", city: "ATTAPULGUS" },
  "39817": { state: "GA", city: "BAINBRIDGE" },
  "39818": { state: "GA", city: "BAINBRIDGE" },
  "39819": { state: "GA", city: "BAINBRIDGE" },
  "39823": { state: "GA", city: "BLAKELY" },
  "39824": { state: "GA", city: "BLUFFTON" },
  "39825": { state: "GA", city: "BRINSON" },
  "39826": { state: "GA", city: "BRONWOOD" },
  "39827": { state: "GA", city: "CAIRO" },
  "39828": { state: "GA", city: "CAIRO" },
  "39829": { state: "GA", city: "CALVARY" },
  "39832": { state: "GA", city: "CEDAR SPRINGS" },
  "39834": { state: "GA", city: "CLIMAX" },
  "39836": { state: "GA", city: "COLEMAN" },
  "39837": { state: "GA", city: "COLQUITT" },
  "39840": { state: "GA", city: "CUTHBERT" },
  "39841": { state: "GA", city: "DAMASCUS" },
  "39842": { state: "GA", city: "DAWSON" },
  "39845": { state: "GA", city: "DONALSONVILLE" },
  "39846": { state: "GA", city: "EDISON" },
  "39851": { state: "GA", city: "FORT GAINES" },
  "39852": { state: "GA", city: "FOWLSTOWN" },
  "39854": { state: "GA", city: "GEORGETOWN" },
  "39859": { state: "GA", city: "IRON CITY" },
  "39861": { state: "GA", city: "JAKIN" },
  "39862": { state: "GA", city: "LEARY" },
  "39866": { state: "GA", city: "MORGAN" },
  "39867": { state: "GA", city: "MORRIS" },
  "39870": { state: "GA", city: "NEWTON" },
  "39877": { state: "GA", city: "PARROTT" },
  "39885": { state: "GA", city: "SASSER" },
  "39886": { state: "GA", city: "SHELLMAN" },
  "39897": { state: "GA", city: "WHIGHAM" },
  "39901": { state: "GA", city: "ATLANTA" },
  "40003": { state: "KY", city: "BAGDAD" },
  "40004": { state: "KY", city: "BARDSTOWN" },
  "40006": { state: "KY", city: "BEDFORD" },
  "40007": { state: "KY", city: "BETHLEHEM" },
  "40008": { state: "KY", city: "BLOOMFIELD" },
  "40009": { state: "KY", city: "BRADFORDSVILLE" },
  "40010": { state: "KY", city: "BUCKNER" },
  "40011": { state: "KY", city: "CAMPBELLSBURG" },
  "40012": { state: "KY", city: "CHAPLIN" },
  "40013": { state: "KY", city: "COXS CREEK" },
  "40014": { state: "KY", city: "CRESTWOOD" },
  "40018": { state: "KY", city: "EASTWOOD" },
  "40019": { state: "KY", city: "EMINENCE" },
  "40020": { state: "KY", city: "FAIRFIELD" },
  "40022": { state: "KY", city: "FINCHVILLE" },
  "40023": { state: "KY", city: "FISHERVILLE" },
  "40025": { state: "KY", city: "GLENVIEW" },
  "40026": { state: "KY", city: "GOSHEN" },
  "40027": { state: "KY", city: "HARRODS CREEK" },
  "40031": { state: "KY", city: "LA GRANGE" },
  "40032": { state: "KY", city: "LA GRANGE" },
  "40033": { state: "KY", city: "LEBANON" },
  "40036": { state: "KY", city: "LOCKPORT" },
  "40037": { state: "KY", city: "LORETTO" },
  "40040": { state: "KY", city: "MACKVILLE" },
  "40041": { state: "KY", city: "MASONIC HOME" },
  "40045": { state: "KY", city: "MILTON" },
  "40046": { state: "KY", city: "MOUNT EDEN" },
  "40047": { state: "KY", city: "MOUNT WASHINGTON" },
  "40048": { state: "KY", city: "NAZARETH" },
  "40049": { state: "KY", city: "NERINX" },
  "40050": { state: "KY", city: "NEW CASTLE" },
  "40051": { state: "KY", city: "NEW HAVEN" },
  "40052": { state: "KY", city: "NEW HOPE" },
  "40055": { state: "KY", city: "PENDLETON" },
  "40056": { state: "KY", city: "PEWEE VALLEY" },
  "40057": { state: "KY", city: "PLEASUREVILLE" },
  "40058": { state: "KY", city: "PORT ROYAL" },
  "40059": { state: "KY", city: "PROSPECT" },
  "40060": { state: "KY", city: "RAYWICK" },
  "40061": { state: "KY", city: "SAINT CATHARINE" },
  "40062": { state: "KY", city: "SAINT FRANCIS" },
  "40063": { state: "KY", city: "SAINT MARY" },
  "40065": { state: "KY", city: "SHELBYVILLE" },
  "40066": { state: "KY", city: "SHELBYVILLE" },
  "40067": { state: "KY", city: "SIMPSONVILLE" },
  "40068": { state: "KY", city: "SMITHFIELD" },
  "40069": { state: "KY", city: "SPRINGFIELD" },
  "40070": { state: "KY", city: "SULPHUR" },
  "40071": { state: "KY", city: "TAYLORSVILLE" },
  "40075": { state: "KY", city: "TURNERS STATION" },
  "40076": { state: "KY", city: "WADDY" },
  "40077": { state: "KY", city: "WESTPORT" },
  "40078": { state: "KY", city: "WILLISBURG" },
  "40104": { state: "KY", city: "BATTLETOWN" },
  "40107": { state: "KY", city: "BOSTON" },
  "40108": { state: "KY", city: "BRANDENBURG" },
  "40109": { state: "KY", city: "BROOKS" },
  "40110": { state: "KY", city: "CLERMONT" },
  "40111": { state: "KY", city: "CLOVERPORT" },
  "40115": { state: "KY", city: "CUSTER" },
  "40117": { state: "KY", city: "EKRON" },
  "40118": { state: "KY", city: "FAIRDALE" },
  "40119": { state: "KY", city: "FALLS OF ROUGH" },
  "40121": { state: "KY", city: "FORT KNOX" },
  "40122": { state: "KY", city: "FORT KNOX" },
  "40129": { state: "KY", city: "HILLVIEW" },
  "40140": { state: "KY", city: "GARFIELD" },
  "40142": { state: "KY", city: "GUSTON" },
  "40143": { state: "KY", city: "HARDINSBURG" },
  "40144": { state: "KY", city: "HARNED" },
  "40145": { state: "KY", city: "HUDSON" },
  "40146": { state: "KY", city: "IRVINGTON" },
  "40150": { state: "KY", city: "LEBANON JUNCTION" },
  "40152": { state: "KY", city: "MC DANIELS" },
  "40153": { state: "KY", city: "MC QUADY" },
  "40155": { state: "KY", city: "MULDRAUGH" },
  "40157": { state: "KY", city: "PAYNEVILLE" },
  "40159": { state: "KY", city: "RADCLIFF" },
  "40160": { state: "KY", city: "RADCLIFF" },
  "40161": { state: "KY", city: "RHODELIA" },
  "40162": { state: "KY", city: "RINEYVILLE" },
  "40165": { state: "KY", city: "SHEPHERDSVILLE" },
  "40166": { state: "KY", city: "SHEPHERDSVILLE" },
  "40170": { state: "KY", city: "STEPHENSPORT" },
  "40171": { state: "KY", city: "UNION STAR" },
  "40175": { state: "KY", city: "VINE GROVE" },
  "40176": { state: "KY", city: "WEBSTER" },
  "40177": { state: "KY", city: "WEST POINT" },
  "40178": { state: "KY", city: "WESTVIEW" },
  "40201": { state: "KY", city: "LOUISVILLE" },
  "40202": { state: "KY", city: "LOUISVILLE" },
  "40203": { state: "KY", city: "LOUISVILLE" },
  "40204": { state: "KY", city: "LOUISVILLE" },
  "40205": { state: "KY", city: "LOUISVILLE" },
  "40206": { state: "KY", city: "LOUISVILLE" },
  "40207": { state: "KY", city: "LOUISVILLE" },
  "40208": { state: "KY", city: "LOUISVILLE" },
  "40209": { state: "KY", city: "LOUISVILLE" },
  "40210": { state: "KY", city: "LOUISVILLE" },
  "40211": { state: "KY", city: "LOUISVILLE" },
  "40212": { state: "KY", city: "LOUISVILLE" },
  "40213": { state: "KY", city: "LOUISVILLE" },
  "40214": { state: "KY", city: "LOUISVILLE" },
  "40215": { state: "KY", city: "LOUISVILLE" },
  "40216": { state: "KY", city: "LOUISVILLE" },
  "40217": { state: "KY", city: "LOUISVILLE" },
  "40218": { state: "KY", city: "LOUISVILLE" },
  "40219": { state: "KY", city: "LOUISVILLE" },
  "40220": { state: "KY", city: "LOUISVILLE" },
  "40221": { state: "KY", city: "LOUISVILLE" },
  "40222": { state: "KY", city: "LOUISVILLE" },
  "40223": { state: "KY", city: "LOUISVILLE" },
  "40224": { state: "KY", city: "LOUISVILLE" },
  "40225": { state: "KY", city: "LOUISVILLE" },
  "40228": { state: "KY", city: "LOUISVILLE" },
  "40229": { state: "KY", city: "LOUISVILLE" },
  "40231": { state: "KY", city: "LOUISVILLE" },
  "40232": { state: "KY", city: "LOUISVILLE" },
  "40233": { state: "KY", city: "LOUISVILLE" },
  "40241": { state: "KY", city: "LOUISVILLE" },
  "40242": { state: "KY", city: "LOUISVILLE" },
  "40243": { state: "KY", city: "LOUISVILLE" },
  "40245": { state: "KY", city: "LOUISVILLE" },
  "40250": { state: "KY", city: "LOUISVILLE" },
  "40251": { state: "KY", city: "LOUISVILLE" },
  "40252": { state: "KY", city: "LOUISVILLE" },
  "40253": { state: "KY", city: "LOUISVILLE" },
  "40255": { state: "KY", city: "LOUISVILLE" },
  "40256": { state: "KY", city: "LOUISVILLE" },
  "40257": { state: "KY", city: "LOUISVILLE" },
  "40258": { state: "KY", city: "LOUISVILLE" },
  "40259": { state: "KY", city: "LOUISVILLE" },
  "40261": { state: "KY", city: "LOUISVILLE" },
  "40266": { state: "KY", city: "LOUISVILLE" },
  "40268": { state: "KY", city: "LOUISVILLE" },
  "40269": { state: "KY", city: "LOUISVILLE" },
  "40270": { state: "KY", city: "LOUISVILLE" },
  "40272": { state: "KY", city: "LOUISVILLE" },
  "40280": { state: "KY", city: "LOUISVILLE" },
  "40281": { state: "KY", city: "LOUISVILLE" },
  "40282": { state: "KY", city: "LOUISVILLE" },
  "40283": { state: "KY", city: "LOUISVILLE" },
  "40285": { state: "KY", city: "LOUISVILLE" },
  "40287": { state: "KY", city: "LOUISVILLE" },
  "40289": { state: "KY", city: "LOUISVILLE" },
  "40290": { state: "KY", city: "LOUISVILLE" },
  "40291": { state: "KY", city: "LOUISVILLE" },
  "40292": { state: "KY", city: "LOUISVILLE" },
  "40293": { state: "KY", city: "LOUISVILLE" },
  "40294": { state: "KY", city: "LOUISVILLE" },
  "40295": { state: "KY", city: "LOUISVILLE" },
  "40296": { state: "KY", city: "LOUISVILLE" },
  "40297": { state: "KY", city: "LOUISVILLE" },
  "40298": { state: "KY", city: "LOUISVILLE" },
  "40299": { state: "KY", city: "LOUISVILLE" },
  "40310": { state: "KY", city: "BURGIN" },
  "40311": { state: "KY", city: "CARLISLE" },
  "40312": { state: "KY", city: "CLAY CITY" },
  "40313": { state: "KY", city: "CLEARFIELD" },
  "40316": { state: "KY", city: "DENNISTON" },
  "40317": { state: "KY", city: "ELLIOTTVILLE" },
  "40319": { state: "KY", city: "FARMERS" },
  "40322": { state: "KY", city: "FRENCHBURG" },
  "40324": { state: "KY", city: "GEORGETOWN" },
  "40328": { state: "KY", city: "GRAVEL SWITCH" },
  "40330": { state: "KY", city: "HARRODSBURG" },
  "40334": { state: "KY", city: "HOPE" },
  "40336": { state: "KY", city: "IRVINE" },
  "40337": { state: "KY", city: "JEFFERSONVILLE" },
  "40339": { state: "KY", city: "KEENE" },
  "40340": { state: "KY", city: "NICHOLASVILLE" },
  "40342": { state: "KY", city: "LAWRENCEBURG" },
  "40346": { state: "KY", city: "MEANS" },
  "40347": { state: "KY", city: "MIDWAY" },
  "40348": { state: "KY", city: "MILLERSBURG" },
  "40350": { state: "KY", city: "MOOREFIELD" },
  "40351": { state: "KY", city: "MOREHEAD" },
  "40353": { state: "KY", city: "MOUNT STERLING" },
  "40355": { state: "KY", city: "NEW LIBERTY" },
  "40356": { state: "KY", city: "NICHOLASVILLE" },
  "40357": { state: "KY", city: "NORTH MIDDLETOWN" },
  "40358": { state: "KY", city: "OLYMPIA" },
  "40359": { state: "KY", city: "OWENTON" },
  "40360": { state: "KY", city: "OWINGSVILLE" },
  "40361": { state: "KY", city: "PARIS" },
  "40362": { state: "KY", city: "PARIS" },
  "40363": { state: "KY", city: "PERRY PARK" },
  "40370": { state: "KY", city: "SADIEVILLE" },
  "40371": { state: "KY", city: "SALT LICK" },
  "40372": { state: "KY", city: "SALVISA" },
  "40374": { state: "KY", city: "SHARPSBURG" },
  "40376": { state: "KY", city: "SLADE" },
  "40379": { state: "KY", city: "STAMPING GROUND" },
  "40380": { state: "KY", city: "STANTON" },
  "40383": { state: "KY", city: "VERSAILLES" },
  "40384": { state: "KY", city: "VERSAILLES" },
  "40385": { state: "KY", city: "WACO" },
  "40387": { state: "KY", city: "WELLINGTON" },
  "40390": { state: "KY", city: "WILMORE" },
  "40391": { state: "KY", city: "WINCHESTER" },
  "40392": { state: "KY", city: "WINCHESTER" },
  "40402": { state: "KY", city: "ANNVILLE" },
  "40403": { state: "KY", city: "BEREA" },
  "40404": { state: "KY", city: "BEREA" },
  "40405": { state: "KY", city: "BIGHILL" },
  "40409": { state: "KY", city: "BRODHEAD" },
  "40410": { state: "KY", city: "BRYANTSVILLE" },
  "40419": { state: "KY", city: "CRAB ORCHARD" },
  "40422": { state: "KY", city: "DANVILLE" },
  "40423": { state: "KY", city: "DANVILLE" },
  "40434": { state: "KY", city: "GRAY HAWK" },
  "40437": { state: "KY", city: "HUSTONVILLE" },
  "40440": { state: "KY", city: "JUNCTION CITY" },
  "40442": { state: "KY", city: "KINGS MOUNTAIN" },
  "40444": { state: "KY", city: "LANCASTER" },
  "40445": { state: "KY", city: "LIVINGSTON" },
  "40447": { state: "KY", city: "MC KEE" },
  "40448": { state: "KY", city: "MC KINNEY" },
  "40452": { state: "KY", city: "MITCHELLSBURG" },
  "40456": { state: "KY", city: "MOUNT VERNON" },
  "40460": { state: "KY", city: "ORLANDO" },
  "40461": { state: "KY", city: "PAINT LICK" },
  "40464": { state: "KY", city: "PARKSVILLE" },
  "40468": { state: "KY", city: "PERRYVILLE" },
  "40472": { state: "KY", city: "RAVENNA" },
  "40473": { state: "KY", city: "RENFRO VALLEY" },
  "40475": { state: "KY", city: "RICHMOND" },
  "40476": { state: "KY", city: "RICHMOND" },
  "40481": { state: "KY", city: "SANDGAP" },
  "40484": { state: "KY", city: "STANFORD" },
  "40486": { state: "KY", city: "TYNER" },
  "40488": { state: "KY", city: "WANETA" },
  "40489": { state: "KY", city: "WAYNESBURG" },
  "40492": { state: "KY", city: "WILDIE" },
  "40502": { state: "KY", city: "LEXINGTON" },
  "40503": { state: "KY", city: "LEXINGTON" },
  "40504": { state: "KY", city: "LEXINGTON" },
  "40505": { state: "KY", city: "LEXINGTON" },
  "40506": { state: "KY", city: "LEXINGTON" },
  "40507": { state: "KY", city: "LEXINGTON" },
  "40508": { state: "KY", city: "LEXINGTON" },
  "40509": { state: "KY", city: "LEXINGTON" },
  "40510": { state: "KY", city: "LEXINGTON" },
  "40511": { state: "KY", city: "LEXINGTON" },
  "40512": { state: "KY", city: "LEXINGTON" },
  "40513": { state: "KY", city: "LEXINGTON" },
  "40514": { state: "KY", city: "LEXINGTON" },
  "40515": { state: "KY", city: "LEXINGTON" },
  "40516": { state: "KY", city: "LEXINGTON" },
  "40517": { state: "KY", city: "LEXINGTON" },
  "40522": { state: "KY", city: "LEXINGTON" },
  "40523": { state: "KY", city: "LEXINGTON" },
  "40524": { state: "KY", city: "LEXINGTON" },
  "40526": { state: "KY", city: "LEXINGTON" },
  "40533": { state: "KY", city: "LEXINGTON" },
  "40536": { state: "KY", city: "LEXINGTON" },
  "40544": { state: "KY", city: "LEXINGTON" },
  "40546": { state: "KY", city: "LEXINGTON" },
  "40550": { state: "KY", city: "LEXINGTON" },
  "40555": { state: "KY", city: "LEXINGTON" },
  "40574": { state: "KY", city: "LEXINGTON" },
  "40575": { state: "KY", city: "LEXINGTON" },
  "40576": { state: "KY", city: "LEXINGTON" },
  "40577": { state: "KY", city: "LEXINGTON" },
  "40578": { state: "KY", city: "LEXINGTON" },
  "40579": { state: "KY", city: "LEXINGTON" },
  "40580": { state: "KY", city: "LEXINGTON" },
  "40581": { state: "KY", city: "LEXINGTON" },
  "40582": { state: "KY", city: "LEXINGTON" },
  "40583": { state: "KY", city: "LEXINGTON" },
  "40588": { state: "KY", city: "LEXINGTON" },
  "40591": { state: "KY", city: "LEXINGTON" },
  "40598": { state: "KY", city: "LEXINGTON" },
  "40601": { state: "KY", city: "FRANKFORT" },
  "40602": { state: "KY", city: "FRANKFORT" },
  "40603": { state: "KY", city: "FRANKFORT" },
  "40604": { state: "KY", city: "FRANKFORT" },
  "40618": { state: "KY", city: "FRANKFORT" },
  "40619": { state: "KY", city: "FRANKFORT" },
  "40620": { state: "KY", city: "FRANKFORT" },
  "40621": { state: "KY", city: "FRANKFORT" },
  "40622": { state: "KY", city: "FRANKFORT" },
  "40701": { state: "KY", city: "CORBIN" },
  "40702": { state: "KY", city: "CORBIN" },
  "40724": { state: "KY", city: "BUSH" },
  "40729": { state: "KY", city: "EAST BERNSTADT" },
  "40730": { state: "KY", city: "EMLYN" },
  "40734": { state: "KY", city: "GRAY" },
  "40737": { state: "KY", city: "KEAVY" },
  "40740": { state: "KY", city: "LILY" },
  "40741": { state: "KY", city: "LONDON" },
  "40742": { state: "KY", city: "LONDON" },
  "40743": { state: "KY", city: "LONDON" },
  "40744": { state: "KY", city: "LONDON" },
  "40745": { state: "KY", city: "LONDON" },
  "40750": { state: "KY", city: "LONDON" },
  "40755": { state: "KY", city: "PITTSBURG" },
  "40759": { state: "KY", city: "ROCKHOLDS" },
  "40763": { state: "KY", city: "SILER" },
  "40769": { state: "KY", city: "WILLIAMSBURG" },
  "40771": { state: "KY", city: "WOODBINE" },
  "40801": { state: "KY", city: "AGES BROOKSIDE" },
  "40803": { state: "KY", city: "ASHER" },
  "40806": { state: "KY", city: "BAXTER" },
  "40807": { state: "KY", city: "BENHAM" },
  "40808": { state: "KY", city: "BIG LAUREL" },
  "40810": { state: "KY", city: "BLEDSOE" },
  "40813": { state: "KY", city: "CALVIN" },
  "40815": { state: "KY", city: "CAWOOD" },
  "40816": { state: "KY", city: "CHAPPELL" },
  "40818": { state: "KY", city: "COALGOOD" },
  "40819": { state: "KY", city: "COLDIRON" },
  "40820": { state: "KY", city: "CRANKS" },
  "40823": { state: "KY", city: "CUMBERLAND" },
  "40824": { state: "KY", city: "DAYHOIT" },
  "40826": { state: "KY", city: "EOLIA" },
  "40827": { state: "KY", city: "ESSIE" },
  "40828": { state: "KY", city: "EVARTS" },
  "40829": { state: "KY", city: "GRAYS KNOB" },
  "40830": { state: "KY", city: "GULSTON" },
  "40831": { state: "KY", city: "HARLAN" },
  "40840": { state: "KY", city: "HELTON" },
  "40843": { state: "KY", city: "HOLMES MILL" },
  "40844": { state: "KY", city: "HOSKINSTON" },
  "40845": { state: "KY", city: "HULEN" },
  "40847": { state: "KY", city: "KENVIR" },
  "40849": { state: "KY", city: "LEJUNIOR" },
  "40854": { state: "KY", city: "LOYALL" },
  "40855": { state: "KY", city: "LYNCH" },
  "40856": { state: "KY", city: "MIRACLE" },
  "40858": { state: "KY", city: "MOZELLE" },
  "40862": { state: "KY", city: "PARTRIDGE" },
  "40863": { state: "KY", city: "PATHFORK" },
  "40865": { state: "KY", city: "PUTNEY" },
  "40868": { state: "KY", city: "STINNETT" },
  "40870": { state: "KY", city: "TOTZ" },
  "40873": { state: "KY", city: "WALLINS CREEK" },
  "40874": { state: "KY", city: "WARBRANCH" },
  "40902": { state: "KY", city: "ARJAY" },
  "40903": { state: "KY", city: "ARTEMUS" },
  "40906": { state: "KY", city: "BARBOURVILLE" },
  "40913": { state: "KY", city: "BEVERLY" },
  "40914": { state: "KY", city: "BIG CREEK" },
  "40915": { state: "KY", city: "BIMBLE" },
  "40921": { state: "KY", city: "BRYANTS STORE" },
  "40923": { state: "KY", city: "CANNON" },
  "40927": { state: "KY", city: "CLOSPLINT" },
  "40930": { state: "KY", city: "DEWITT" },
  "40932": { state: "KY", city: "FALL ROCK" },
  "40935": { state: "KY", city: "FLAT LICK" },
  "40939": { state: "KY", city: "FOURMILE" },
  "40940": { state: "KY", city: "FRAKES" },
  "40941": { state: "KY", city: "GARRARD" },
  "40943": { state: "KY", city: "GIRDLER" },
  "40944": { state: "KY", city: "GOOSE ROCK" },
  "40946": { state: "KY", city: "GREEN ROAD" },
  "40949": { state: "KY", city: "HEIDRICK" },
  "40951": { state: "KY", city: "HIMA" },
  "40953": { state: "KY", city: "HINKLE" },
  "40955": { state: "KY", city: "INGRAM" },
  "40958": { state: "KY", city: "KETTLE ISLAND" },
  "40962": { state: "KY", city: "MANCHESTER" },
  "40964": { state: "KY", city: "MARY ALICE" },
  "40965": { state: "KY", city: "MIDDLESBORO" },
  "40972": { state: "KY", city: "ONEIDA" },
  "40977": { state: "KY", city: "PINEVILLE" },
  "40979": { state: "KY", city: "ROARK" },
  "40981": { state: "KY", city: "SAUL" },
  "40982": { state: "KY", city: "SCALF" },
  "40983": { state: "KY", city: "SEXTONS CREEK" },
  "40988": { state: "KY", city: "STONEY FORK" },
  "40995": { state: "KY", city: "TROSPER" },
  "40997": { state: "KY", city: "WALKER" },
  "41001": { state: "KY", city: "ALEXANDRIA" },
  "41002": { state: "KY", city: "AUGUSTA" },
  "41003": { state: "KY", city: "BERRY" },
  "41004": { state: "KY", city: "BROOKSVILLE" },
  "41005": { state: "KY", city: "BURLINGTON" },
  "41006": { state: "KY", city: "BUTLER" },
  "41007": { state: "KY", city: "CALIFORNIA" },
  "41008": { state: "KY", city: "CARROLLTON" },
  "41010": { state: "KY", city: "CORINTH" },
  "41011": { state: "KY", city: "COVINGTON" },
  "41012": { state: "KY", city: "COVINGTON" },
  "41014": { state: "KY", city: "COVINGTON" },
  "41015": { state: "KY", city: "LATONIA" },
  "41016": { state: "KY", city: "COVINGTON" },
  "41017": { state: "KY", city: "FT MITCHELL" },
  "41018": { state: "KY", city: "ERLANGER" },
  "41019": { state: "KY", city: "COVINGTON" },
  "41021": { state: "KY", city: "HEBRON" },
  "41022": { state: "KY", city: "FLORENCE" },
  "41025": { state: "KY", city: "ERLANGER" },
  "41030": { state: "KY", city: "CRITTENDEN" },
  "41031": { state: "KY", city: "CYNTHIANA" },
  "41033": { state: "KY", city: "DE MOSSVILLE" },
  "41034": { state: "KY", city: "DOVER" },
  "41035": { state: "KY", city: "DRY RIDGE" },
  "41037": { state: "KY", city: "ELIZAVILLE" },
  "41039": { state: "KY", city: "EWING" },
  "41040": { state: "KY", city: "FALMOUTH" },
  "41041": { state: "KY", city: "FLEMINGSBURG" },
  "41042": { state: "KY", city: "FLORENCE" },
  "41043": { state: "KY", city: "FOSTER" },
  "41044": { state: "KY", city: "GERMANTOWN" },
  "41045": { state: "KY", city: "GHENT" },
  "41046": { state: "KY", city: "GLENCOE" },
  "41048": { state: "KY", city: "HEBRON" },
  "41049": { state: "KY", city: "HILLSBORO" },
  "41051": { state: "KY", city: "INDEPENDENCE" },
  "41052": { state: "KY", city: "JONESVILLE" },
  "41053": { state: "KY", city: "KENTON" },
  "41054": { state: "KY", city: "MASON" },
  "41055": { state: "KY", city: "MAYSLICK" },
  "41056": { state: "KY", city: "MAYSVILLE" },
  "41059": { state: "KY", city: "MELBOURNE" },
  "41061": { state: "KY", city: "MILFORD" },
  "41062": { state: "KY", city: "MINERVA" },
  "41063": { state: "KY", city: "MORNING VIEW" },
  "41064": { state: "KY", city: "MOUNT OLIVET" },
  "41071": { state: "KY", city: "NEWPORT" },
  "41072": { state: "KY", city: "NEWPORT" },
  "41073": { state: "KY", city: "BELLEVUE" },
  "41074": { state: "KY", city: "DAYTON" },
  "41075": { state: "KY", city: "FORT THOMAS" },
  "41076": { state: "KY", city: "NEWPORT" },
  "41080": { state: "KY", city: "PETERSBURG" },
  "41081": { state: "KY", city: "PLUMMERS LANDING" },
  "41083": { state: "KY", city: "SANDERS" },
  "41085": { state: "KY", city: "SILVER GROVE" },
  "41086": { state: "KY", city: "SPARTA" },
  "41091": { state: "KY", city: "UNION" },
  "41092": { state: "KY", city: "VERONA" },
  "41093": { state: "KY", city: "WALLINGFORD" },
  "41094": { state: "KY", city: "WALTON" },
  "41095": { state: "KY", city: "WARSAW" },
  "41096": { state: "KY", city: "WASHINGTON" },
  "41097": { state: "KY", city: "WILLIAMSTOWN" },
  "41098": { state: "KY", city: "WORTHVILLE" },
  "41099": { state: "KY", city: "NEWPORT" },
  "41101": { state: "KY", city: "ASHLAND" },
  "41102": { state: "KY", city: "ASHLAND" },
  "41105": { state: "KY", city: "ASHLAND" },
  "41114": { state: "KY", city: "ASHLAND" },
  "41121": { state: "KY", city: "ARGILLITE" },
  "41124": { state: "KY", city: "BLAINE" },
  "41128": { state: "KY", city: "CARTER" },
  "41129": { state: "KY", city: "CATLETTSBURG" },
  "41132": { state: "KY", city: "DENTON" },
  "41135": { state: "KY", city: "EMERSON" },
  "41139": { state: "KY", city: "FLATWOODS" },
  "41141": { state: "KY", city: "GARRISON" },
  "41142": { state: "KY", city: "GRAHN" },
  "41143": { state: "KY", city: "GRAYSON" },
  "41144": { state: "KY", city: "GREENUP" },
  "41146": { state: "KY", city: "HITCHINS" },
  "41149": { state: "KY", city: "ISONVILLE" },
  "41159": { state: "KY", city: "MARTHA" },
  "41160": { state: "KY", city: "MAZIE" },
  "41164": { state: "KY", city: "OLIVE HILL" },
  "41166": { state: "KY", city: "QUINCY" },
  "41168": { state: "KY", city: "RUSH" },
  "41169": { state: "KY", city: "RUSSELL" },
  "41171": { state: "KY", city: "SANDY HOOK" },
  "41173": { state: "KY", city: "SOLDIER" },
  "41174": { state: "KY", city: "SOUTH PORTSMOUTH" },
  "41175": { state: "KY", city: "SOUTH SHORE" },
  "41179": { state: "KY", city: "VANCEBURG" },
  "41180": { state: "KY", city: "WEBBVILLE" },
  "41181": { state: "KY", city: "WILLARD" },
  "41183": { state: "KY", city: "WORTHINGTON" },
  "41189": { state: "KY", city: "TOLLESBORO" },
  "41201": { state: "KY", city: "ADAMS" },
  "41203": { state: "KY", city: "BEAUTY" },
  "41204": { state: "KY", city: "BOONS CAMP" },
  "41214": { state: "KY", city: "DEBORD" },
  "41216": { state: "KY", city: "EAST POINT" },
  "41219": { state: "KY", city: "FLATGAP" },
  "41222": { state: "KY", city: "HAGERHILL" },
  "41224": { state: "KY", city: "INEZ" },
  "41226": { state: "KY", city: "KEATON" },
  "41230": { state: "KY", city: "LOUISA" },
  "41231": { state: "KY", city: "LOVELY" },
  "41232": { state: "KY", city: "LOWMANSVILLE" },
  "41234": { state: "KY", city: "MEALLY" },
  "41238": { state: "KY", city: "OIL SPRINGS" },
  "41240": { state: "KY", city: "PAINTSVILLE" },
  "41250": { state: "KY", city: "PILGRIM" },
  "41254": { state: "KY", city: "RIVER" },
  "41255": { state: "KY", city: "SITKA" },
  "41256": { state: "KY", city: "STAFFORDSVILLE" },
  "41257": { state: "KY", city: "STAMBAUGH" },
  "41260": { state: "KY", city: "THELMA" },
  "41262": { state: "KY", city: "TOMAHAWK" },
  "41263": { state: "KY", city: "TUTOR KEY" },
  "41264": { state: "KY", city: "ULYSSES" },
  "41265": { state: "KY", city: "VAN LEAR" },
  "41267": { state: "KY", city: "WARFIELD" },
  "41268": { state: "KY", city: "WEST VAN LEAR" },
  "41271": { state: "KY", city: "WILLIAMSPORT" },
  "41274": { state: "KY", city: "WITTENSVILLE" },
  "41301": { state: "KY", city: "CAMPTON" },
  "41310": { state: "KY", city: "BAYS" },
  "41311": { state: "KY", city: "BEATTYVILLE" },
  "41314": { state: "KY", city: "BOONEVILLE" },
  "41317": { state: "KY", city: "CLAYHOLE" },
  "41332": { state: "KY", city: "HAZEL GREEN" },
  "41339": { state: "KY", city: "JACKSON" },
  "41347": { state: "KY", city: "LONE" },
  "41348": { state: "KY", city: "LOST CREEK" },
  "41351": { state: "KY", city: "MISTLETOE" },
  "41352": { state: "KY", city: "MIZE" },
  "41360": { state: "KY", city: "PINE RIDGE" },
  "41364": { state: "KY", city: "RICETOWN" },
  "41365": { state: "KY", city: "ROGERS" },
  "41366": { state: "KY", city: "ROUSSEAU" },
  "41367": { state: "KY", city: "ROWDY" },
  "41368": { state: "KY", city: "SAINT HELENS" },
  "41385": { state: "KY", city: "VANCLEVE" },
  "41386": { state: "KY", city: "VINCENT" },
  "41390": { state: "KY", city: "WHICK" },
  "41397": { state: "KY", city: "ZOE" },
  "41408": { state: "KY", city: "CANNEL CITY" },
  "41413": { state: "KY", city: "CROCKETT" },
  "41421": { state: "KY", city: "ELKFORK" },
  "41425": { state: "KY", city: "EZEL" },
  "41426": { state: "KY", city: "FALCON" },
  "41451": { state: "KY", city: "MALONE" },
  "41464": { state: "KY", city: "ROYALTON" },
  "41465": { state: "KY", city: "SALYERSVILLE" },
  "41472": { state: "KY", city: "WEST LIBERTY" },
  "41477": { state: "KY", city: "WRIGLEY" },
  "41501": { state: "KY", city: "PIKEVILLE" },
  "41502": { state: "KY", city: "PIKEVILLE" },
  "41503": { state: "KY", city: "SOUTH WILLIAMSON" },
  "41512": { state: "KY", city: "ASHCAMP" },
  "41513": { state: "KY", city: "BELCHER" },
  "41514": { state: "KY", city: "BELFRY" },
  "41517": { state: "KY", city: "BURDINE" },
  "41519": { state: "KY", city: "CANADA" },
  "41520": { state: "KY", city: "DORTON" },
  "41522": { state: "KY", city: "ELKHORN CITY" },
  "41524": { state: "KY", city: "FEDSCREEK" },
  "41526": { state: "KY", city: "FORDS BRANCH" },
  "41527": { state: "KY", city: "FOREST HILLS" },
  "41528": { state: "KY", city: "FREEBURN" },
  "41531": { state: "KY", city: "HARDY" },
  "41534": { state: "KY", city: "HELLIER" },
  "41535": { state: "KY", city: "HUDDY" },
  "41537": { state: "KY", city: "JENKINS" },
  "41538": { state: "KY", city: "JONANCY" },
  "41539": { state: "KY", city: "KIMPER" },
  "41540": { state: "KY", city: "LICK CREEK" },
  "41542": { state: "KY", city: "LOOKOUT" },
  "41543": { state: "KY", city: "MC ANDREWS" },
  "41544": { state: "KY", city: "MC CARR" },
  "41547": { state: "KY", city: "MAJESTIC" },
  "41548": { state: "KY", city: "MOUTHCARD" },
  "41549": { state: "KY", city: "MYRA" },
  "41553": { state: "KY", city: "PHELPS" },
  "41554": { state: "KY", city: "PHYLLIS" },
  "41555": { state: "KY", city: "PINSONFORK" },
  "41557": { state: "KY", city: "RACCOON" },
  "41558": { state: "KY", city: "RANSOM" },
  "41559": { state: "KY", city: "REGINA" },
  "41560": { state: "KY", city: "ROBINSON CREEK" },
  "41561": { state: "KY", city: "ROCKHOUSE" },
  "41562": { state: "KY", city: "SHELBIANA" },
  "41563": { state: "KY", city: "SHELBY GAP" },
  "41564": { state: "KY", city: "SIDNEY" },
  "41566": { state: "KY", city: "STEELE" },
  "41567": { state: "KY", city: "STONE" },
  "41568": { state: "KY", city: "STOPOVER" },
  "41571": { state: "KY", city: "VARNEY" },
  "41572": { state: "KY", city: "VIRGIE" },
  "41601": { state: "KY", city: "ALLEN" },
  "41602": { state: "KY", city: "AUXIER" },
  "41603": { state: "KY", city: "BANNER" },
  "41604": { state: "KY", city: "BEAVER" },
  "41605": { state: "KY", city: "BETSY LAYNE" },
  "41606": { state: "KY", city: "BEVINSVILLE" },
  "41607": { state: "KY", city: "BLUE RIVER" },
  "41612": { state: "KY", city: "BYPRO" },
  "41615": { state: "KY", city: "DANA" },
  "41616": { state: "KY", city: "DAVID" },
  "41619": { state: "KY", city: "DRIFT" },
  "41621": { state: "KY", city: "DWALE" },
  "41622": { state: "KY", city: "EASTERN" },
  "41630": { state: "KY", city: "GARRETT" },
  "41631": { state: "KY", city: "GRETHEL" },
  "41632": { state: "KY", city: "GUNLOCK" },
  "41635": { state: "KY", city: "HAROLD" },
  "41636": { state: "KY", city: "HI HAT" },
  "41640": { state: "KY", city: "HUEYSVILLE" },
  "41642": { state: "KY", city: "IVEL" },
  "41643": { state: "KY", city: "LACKEY" },
  "41645": { state: "KY", city: "LANGLEY" },
  "41647": { state: "KY", city: "MC DOWELL" },
  "41649": { state: "KY", city: "MARTIN" },
  "41650": { state: "KY", city: "MELVIN" },
  "41651": { state: "KY", city: "MINNIE" },
  "41653": { state: "KY", city: "PRESTONSBURG" },
  "41655": { state: "KY", city: "PRINTER" },
  "41659": { state: "KY", city: "STANVILLE" },
  "41660": { state: "KY", city: "TEABERRY" },
  "41663": { state: "KY", city: "TRAM" },
  "41666": { state: "KY", city: "WAYLAND" },
  "41667": { state: "KY", city: "WEEKSBURY" },
  "41669": { state: "KY", city: "WHEELWRIGHT" },
  "41701": { state: "KY", city: "HAZARD" },
  "41702": { state: "KY", city: "HAZARD" },
  "41712": { state: "KY", city: "ARY" },
  "41713": { state: "KY", city: "AVAWAM" },
  "41714": { state: "KY", city: "BEAR BRANCH" },
  "41719": { state: "KY", city: "BONNYMAN" },
  "41721": { state: "KY", city: "BUCKHORN" },
  "41722": { state: "KY", city: "BULAN" },
  "41723": { state: "KY", city: "BUSY" },
  "41725": { state: "KY", city: "CARRIE" },
  "41727": { state: "KY", city: "CHAVIES" },
  "41729": { state: "KY", city: "COMBS" },
  "41731": { state: "KY", city: "CORNETTSVILLE" },
  "41735": { state: "KY", city: "DELPHIA" },
  "41736": { state: "KY", city: "DICE" },
  "41739": { state: "KY", city: "DWARF" },
  "41740": { state: "KY", city: "EMMALENA" },
  "41743": { state: "KY", city: "FISTY" },
  "41745": { state: "KY", city: "GAYS CREEK" },
  "41746": { state: "KY", city: "HAPPY" },
  "41749": { state: "KY", city: "HYDEN" },
  "41751": { state: "KY", city: "JEFF" },
  "41754": { state: "KY", city: "KRYPTON" },
  "41759": { state: "KY", city: "SASSAFRAS" },
  "41760": { state: "KY", city: "SCUDDY" },
  "41762": { state: "KY", city: "SIZEROCK" },
  "41763": { state: "KY", city: "SLEMP" },
  "41764": { state: "KY", city: "SMILAX" },
  "41766": { state: "KY", city: "THOUSANDSTICKS" },
  "41772": { state: "KY", city: "VEST" },
  "41773": { state: "KY", city: "VICCO" },
  "41774": { state: "KY", city: "VIPER" },
  "41775": { state: "KY", city: "WENDOVER" },
  "41776": { state: "KY", city: "WOOTON" },
  "41777": { state: "KY", city: "YEADDISS" },
  "41778": { state: "KY", city: "YERKES" },
  "41804": { state: "KY", city: "BLACKEY" },
  "41810": { state: "KY", city: "CROMONA" },
  "41812": { state: "KY", city: "DEANE" },
  "41815": { state: "KY", city: "ERMINE" },
  "41817": { state: "KY", city: "GARNER" },
  "41819": { state: "KY", city: "GORDON" },
  "41821": { state: "KY", city: "HALLIE" },
  "41822": { state: "KY", city: "HINDMAN" },
  "41824": { state: "KY", city: "ISOM" },
  "41825": { state: "KY", city: "JACKHORN" },
  "41826": { state: "KY", city: "JEREMIAH" },
  "41828": { state: "KY", city: "KITE" },
  "41831": { state: "KY", city: "LEBURN" },
  "41832": { state: "KY", city: "LETCHER" },
  "41833": { state: "KY", city: "LINEFORK" },
  "41834": { state: "KY", city: "LITTCARR" },
  "41835": { state: "KY", city: "MC ROBERTS" },
  "41836": { state: "KY", city: "MALLIE" },
  "41837": { state: "KY", city: "MAYKING" },
  "41838": { state: "KY", city: "MILLSTONE" },
  "41839": { state: "KY", city: "MOUSIE" },
  "41840": { state: "KY", city: "NEON" },
  "41843": { state: "KY", city: "PINE TOP" },
  "41844": { state: "KY", city: "PIPPA PASSES" },
  "41845": { state: "KY", city: "PREMIUM" },
  "41847": { state: "KY", city: "REDFOX" },
  "41848": { state: "KY", city: "ROXANA" },
  "41849": { state: "KY", city: "SECO" },
  "41855": { state: "KY", city: "THORNTON" },
  "41858": { state: "KY", city: "WHITESBURG" },
  "41859": { state: "KY", city: "DEMA" },
  "41861": { state: "KY", city: "RAVEN" },
  "41862": { state: "KY", city: "TOPMOST" },
  "42001": { state: "KY", city: "PADUCAH" },
  "42002": { state: "KY", city: "PADUCAH" },
  "42003": { state: "KY", city: "PADUCAH" },
  "42020": { state: "KY", city: "ALMO" },
  "42021": { state: "KY", city: "ARLINGTON" },
  "42022": { state: "KY", city: "BANDANA" },
  "42023": { state: "KY", city: "BARDWELL" },
  "42024": { state: "KY", city: "BARLOW" },
  "42025": { state: "KY", city: "BENTON" },
  "42027": { state: "KY", city: "BOAZ" },
  "42028": { state: "KY", city: "BURNA" },
  "42029": { state: "KY", city: "CALVERT CITY" },
  "42031": { state: "KY", city: "CLINTON" },
  "42032": { state: "KY", city: "COLUMBUS" },
  "42033": { state: "KY", city: "CRAYNE" },
  "42035": { state: "KY", city: "CUNNINGHAM" },
  "42036": { state: "KY", city: "DEXTER" },
  "42037": { state: "KY", city: "DYCUSBURG" },
  "42038": { state: "KY", city: "EDDYVILLE" },
  "42039": { state: "KY", city: "FANCY FARM" },
  "42040": { state: "KY", city: "FARMINGTON" },
  "42041": { state: "KY", city: "FULTON" },
  "42044": { state: "KY", city: "GILBERTSVILLE" },
  "42045": { state: "KY", city: "GRAND RIVERS" },
  "42047": { state: "KY", city: "HAMPTON" },
  "42048": { state: "KY", city: "HARDIN" },
  "42049": { state: "KY", city: "HAZEL" },
  "42050": { state: "KY", city: "HICKMAN" },
  "42051": { state: "KY", city: "HICKORY" },
  "42053": { state: "KY", city: "KEVIL" },
  "42054": { state: "KY", city: "KIRKSEY" },
  "42055": { state: "KY", city: "KUTTAWA" },
  "42056": { state: "KY", city: "LA CENTER" },
  "42058": { state: "KY", city: "LEDBETTER" },
  "42060": { state: "KY", city: "LOVELACEVILLE" },
  "42061": { state: "KY", city: "LOWES" },
  "42063": { state: "KY", city: "LYNNVILLE" },
  "42064": { state: "KY", city: "MARION" },
  "42066": { state: "KY", city: "MAYFIELD" },
  "42069": { state: "KY", city: "MELBER" },
  "42070": { state: "KY", city: "MILBURN" },
  "42071": { state: "KY", city: "MURRAY" },
  "42076": { state: "KY", city: "NEW CONCORD" },
  "42078": { state: "KY", city: "SALEM" },
  "42079": { state: "KY", city: "SEDALIA" },
  "42081": { state: "KY", city: "SMITHLAND" },
  "42082": { state: "KY", city: "SYMSONIA" },
  "42083": { state: "KY", city: "TILINE" },
  "42085": { state: "KY", city: "WATER VALLEY" },
  "42086": { state: "KY", city: "WEST PADUCAH" },
  "42087": { state: "KY", city: "WICKLIFFE" },
  "42088": { state: "KY", city: "WINGO" },
  "42101": { state: "KY", city: "BOWLING GREEN" },
  "42102": { state: "KY", city: "BOWLING GREEN" },
  "42103": { state: "KY", city: "BOWLING GREEN" },
  "42104": { state: "KY", city: "BOWLING GREEN" },
  "42120": { state: "KY", city: "ADOLPHUS" },
  "42122": { state: "KY", city: "ALVATON" },
  "42123": { state: "KY", city: "AUSTIN" },
  "42124": { state: "KY", city: "BEAUMONT" },
  "42127": { state: "KY", city: "CAVE CITY" },
  "42128": { state: "KY", city: "BOWLING GREEN" },
  "42129": { state: "KY", city: "EDMONTON" },
  "42130": { state: "KY", city: "EIGHTY EIGHT" },
  "42131": { state: "KY", city: "ETOILE" },
  "42133": { state: "KY", city: "FOUNTAIN RUN" },
  "42134": { state: "KY", city: "FRANKLIN" },
  "42135": { state: "KY", city: "FRANKLIN" },
  "42140": { state: "KY", city: "GAMALIEL" },
  "42141": { state: "KY", city: "GLASGOW" },
  "42142": { state: "KY", city: "GLASGOW" },
  "42151": { state: "KY", city: "HESTAND" },
  "42152": { state: "KY", city: "HISEVILLE" },
  "42153": { state: "KY", city: "HOLLAND" },
  "42154": { state: "KY", city: "KNOB LICK" },
  "42156": { state: "KY", city: "LUCAS" },
  "42157": { state: "KY", city: "MOUNT HERMON" },
  "42159": { state: "KY", city: "OAKLAND" },
  "42160": { state: "KY", city: "PARK CITY" },
  "42163": { state: "KY", city: "ROCKY HILL" },
  "42164": { state: "KY", city: "SCOTTSVILLE" },
  "42166": { state: "KY", city: "SUMMER SHADE" },
  "42167": { state: "KY", city: "TOMPKINSVILLE" },
  "42170": { state: "KY", city: "WOODBURN" },
  "42171": { state: "KY", city: "SMITHS GROVE" },
  "42201": { state: "KY", city: "ABERDEEN" },
  "42202": { state: "KY", city: "ADAIRVILLE" },
  "42204": { state: "KY", city: "ALLENSVILLE" },
  "42206": { state: "KY", city: "AUBURN" },
  "42207": { state: "KY", city: "BEE SPRING" },
  "42210": { state: "KY", city: "BROWNSVILLE" },
  "42211": { state: "KY", city: "CADIZ" },
  "42214": { state: "KY", city: "CENTER" },
  "42215": { state: "KY", city: "CERULEAN" },
  "42216": { state: "KY", city: "CLIFTY" },
  "42217": { state: "KY", city: "CROFTON" },
  "42219": { state: "KY", city: "DUNBAR" },
  "42220": { state: "KY", city: "ELKTON" },
  "42221": { state: "KY", city: "FAIRVIEW" },
  "42223": { state: "KY", city: "FORT CAMPBELL" },
  "42232": { state: "KY", city: "GRACEY" },
  "42234": { state: "KY", city: "GUTHRIE" },
  "42236": { state: "KY", city: "HERNDON" },
  "42240": { state: "KY", city: "HOPKINSVILLE" },
  "42241": { state: "KY", city: "HOPKINSVILLE" },
  "42252": { state: "KY", city: "JETSON" },
  "42254": { state: "KY", city: "LA FAYETTE" },
  "42256": { state: "KY", city: "LEWISBURG" },
  "42259": { state: "KY", city: "MAMMOTH CAVE" },
  "42261": { state: "KY", city: "MORGANTOWN" },
  "42262": { state: "KY", city: "OAK GROVE" },
  "42265": { state: "KY", city: "OLMSTEAD" },
  "42266": { state: "KY", city: "PEMBROKE" },
  "42273": { state: "KY", city: "ROCHESTER" },
  "42274": { state: "KY", city: "ROCKFIELD" },
  "42275": { state: "KY", city: "ROUNDHILL" },
  "42276": { state: "KY", city: "RUSSELLVILLE" },
  "42280": { state: "KY", city: "SHARON GROVE" },
  "42285": { state: "KY", city: "SWEEDEN" },
  "42286": { state: "KY", city: "TRENTON" },
  "42288": { state: "KY", city: "WOODBURY" },
  "42301": { state: "KY", city: "OWENSBORO" },
  "42302": { state: "KY", city: "OWENSBORO" },
  "42303": { state: "KY", city: "OWENSBORO" },
  "42304": { state: "KY", city: "OWENSBORO" },
  "42320": { state: "KY", city: "BEAVER DAM" },
  "42321": { state: "KY", city: "BEECH CREEK" },
  "42322": { state: "KY", city: "BEECH GROVE" },
  "42323": { state: "KY", city: "BEECHMONT" },
  "42324": { state: "KY", city: "BELTON" },
  "42325": { state: "KY", city: "BREMEN" },
  "42326": { state: "KY", city: "BROWDER" },
  "42327": { state: "KY", city: "CALHOUN" },
  "42328": { state: "KY", city: "CENTERTOWN" },
  "42330": { state: "KY", city: "CENTRAL CITY" },
  "42332": { state: "KY", city: "CLEATON" },
  "42333": { state: "KY", city: "CROMWELL" },
  "42334": { state: "KY", city: "CURDSVILLE" },
  "42337": { state: "KY", city: "DRAKESBORO" },
  "42338": { state: "KY", city: "DUNDEE" },
  "42339": { state: "KY", city: "DUNMOR" },
  "42343": { state: "KY", city: "FORDSVILLE" },
  "42344": { state: "KY", city: "GRAHAM" },
  "42345": { state: "KY", city: "GREENVILLE" },
  "42347": { state: "KY", city: "HARTFORD" },
  "42348": { state: "KY", city: "HAWESVILLE" },
  "42349": { state: "KY", city: "HORSE BRANCH" },
  "42350": { state: "KY", city: "ISLAND" },
  "42351": { state: "KY", city: "LEWISPORT" },
  "42352": { state: "KY", city: "LIVERMORE" },
  "42354": { state: "KY", city: "MC HENRY" },
  "42355": { state: "KY", city: "MACEO" },
  "42356": { state: "KY", city: "MAPLE MOUNT" },
  "42361": { state: "KY", city: "OLATON" },
  "42364": { state: "KY", city: "HAWESVILLE" },
  "42366": { state: "KY", city: "PHILPOT" },
  "42367": { state: "KY", city: "POWDERLY" },
  "42368": { state: "KY", city: "REYNOLDS STATION" },
  "42369": { state: "KY", city: "ROCKPORT" },
  "42370": { state: "KY", city: "ROSINE" },
  "42371": { state: "KY", city: "RUMSEY" },
  "42372": { state: "KY", city: "SACRAMENTO" },
  "42374": { state: "KY", city: "SOUTH CARROLLTON" },
  "42376": { state: "KY", city: "UTICA" },
  "42377": { state: "KY", city: "WEST LOUISVILLE" },
  "42378": { state: "KY", city: "WHITESVILLE" },
  "42402": { state: "KY", city: "BASKETT" },
  "42404": { state: "KY", city: "CLAY" },
  "42406": { state: "KY", city: "CORYDON" },
  "42408": { state: "KY", city: "DAWSON SPRINGS" },
  "42409": { state: "KY", city: "DIXON" },
  "42410": { state: "KY", city: "EARLINGTON" },
  "42411": { state: "KY", city: "FREDONIA" },
  "42413": { state: "KY", city: "HANSON" },
  "42419": { state: "KY", city: "HENDERSON" },
  "42420": { state: "KY", city: "HENDERSON" },
  "42431": { state: "KY", city: "MADISONVILLE" },
  "42436": { state: "KY", city: "MANITOU" },
  "42437": { state: "KY", city: "MORGANFIELD" },
  "42440": { state: "KY", city: "MORTONS GAP" },
  "42441": { state: "KY", city: "NEBO" },
  "42442": { state: "KY", city: "NORTONVILLE" },
  "42444": { state: "KY", city: "POOLE" },
  "42445": { state: "KY", city: "PRINCETON" },
  "42450": { state: "KY", city: "PROVIDENCE" },
  "42451": { state: "KY", city: "REED" },
  "42452": { state: "KY", city: "ROBARDS" },
  "42453": { state: "KY", city: "SAINT CHARLES" },
  "42455": { state: "KY", city: "SEBREE" },
  "42456": { state: "KY", city: "SLAUGHTERS" },
  "42457": { state: "KY", city: "SMITH MILLS" },
  "42458": { state: "KY", city: "SPOTTSVILLE" },
  "42459": { state: "KY", city: "STURGIS" },
  "42460": { state: "KY", city: "SULLIVAN" },
  "42461": { state: "KY", city: "UNIONTOWN" },
  "42462": { state: "KY", city: "WAVERLY" },
  "42463": { state: "KY", city: "WHEATCROFT" },
  "42464": { state: "KY", city: "WHITE PLAINS" },
  "42501": { state: "KY", city: "SOMERSET" },
  "42502": { state: "KY", city: "SOMERSET" },
  "42503": { state: "KY", city: "SOMERSET" },
  "42516": { state: "KY", city: "BETHELRIDGE" },
  "42518": { state: "KY", city: "BRONSTON" },
  "42519": { state: "KY", city: "BURNSIDE" },
  "42528": { state: "KY", city: "DUNNVILLE" },
  "42533": { state: "KY", city: "FERGUSON" },
  "42539": { state: "KY", city: "LIBERTY" },
  "42541": { state: "KY", city: "MIDDLEBURG" },
  "42544": { state: "KY", city: "NANCY" },
  "42553": { state: "KY", city: "SCIENCE HILL" },
  "42558": { state: "KY", city: "TATEVILLE" },
  "42564": { state: "KY", city: "WEST SOMERSET" },
  "42565": { state: "KY", city: "WINDSOR" },
  "42566": { state: "KY", city: "YOSEMITE" },
  "42567": { state: "KY", city: "EUBANK" },
  "42602": { state: "KY", city: "ALBANY" },
  "42603": { state: "KY", city: "ALPHA" },
  "42629": { state: "KY", city: "JAMESTOWN" },
  "42631": { state: "KY", city: "MARSHES SIDING" },
  "42633": { state: "KY", city: "MONTICELLO" },
  "42634": { state: "KY", city: "PARKERS LAKE" },
  "42635": { state: "KY", city: "PINE KNOT" },
  "42638": { state: "KY", city: "REVELO" },
  "42642": { state: "KY", city: "RUSSELL SPRINGS" },
  "42647": { state: "KY", city: "STEARNS" },
  "42649": { state: "KY", city: "STRUNK" },
  "42653": { state: "KY", city: "WHITLEY CITY" },
  "42701": { state: "KY", city: "ELIZABETHTOWN" },
  "42702": { state: "KY", city: "ELIZABETHTOWN" },
  "42712": { state: "KY", city: "BIG CLIFTY" },
  "42713": { state: "KY", city: "BONNIEVILLE" },
  "42715": { state: "KY", city: "BREEDING" },
  "42716": { state: "KY", city: "BUFFALO" },
  "42717": { state: "KY", city: "BURKESVILLE" },
  "42718": { state: "KY", city: "CAMPBELLSVILLE" },
  "42719": { state: "KY", city: "CAMPBELLSVILLE" },
  "42720": { state: "KY", city: "CANE VALLEY" },
  "42721": { state: "KY", city: "CANEYVILLE" },
  "42722": { state: "KY", city: "CANMER" },
  "42724": { state: "KY", city: "CECILIA" },
  "42726": { state: "KY", city: "CLARKSON" },
  "42728": { state: "KY", city: "COLUMBIA" },
  "42729": { state: "KY", city: "CUB RUN" },
  "42732": { state: "KY", city: "EASTVIEW" },
  "42733": { state: "KY", city: "ELK HORN" },
  "42740": { state: "KY", city: "GLENDALE" },
  "42741": { state: "KY", city: "GLENS FORK" },
  "42742": { state: "KY", city: "GRADYVILLE" },
  "42743": { state: "KY", city: "GREENSBURG" },
  "42746": { state: "KY", city: "HARDYVILLE" },
  "42748": { state: "KY", city: "HODGENVILLE" },
  "42749": { state: "KY", city: "HORSE CAVE" },
  "42753": { state: "KY", city: "KNIFLEY" },
  "42754": { state: "KY", city: "LEITCHFIELD" },
  "42755": { state: "KY", city: "LEITCHFIELD" },
  "42757": { state: "KY", city: "MAGNOLIA" },
  "42758": { state: "KY", city: "MANNSVILLE" },
  "42759": { state: "KY", city: "MARROWBONE" },
  "42762": { state: "KY", city: "MILLWOOD" },
  "42764": { state: "KY", city: "MOUNT SHERMAN" },
  "42765": { state: "KY", city: "MUNFORDVILLE" },
  "42776": { state: "KY", city: "SONORA" },
  "42782": { state: "KY", city: "SUMMERSVILLE" },
  "42784": { state: "KY", city: "UPTON" },
  "42788": { state: "KY", city: "WHITE MILLS" },
  "43001": { state: "OH", city: "ALEXANDRIA" },
  "43002": { state: "OH", city: "AMLIN" },
  "43003": { state: "OH", city: "ASHLEY" },
  "43004": { state: "OH", city: "BLACKLICK" },
  "43005": { state: "OH", city: "BLADENSBURG" },
  "43006": { state: "OH", city: "BRINKHAVEN" },
  "43007": { state: "OH", city: "BROADWAY" },
  "43008": { state: "OH", city: "BUCKEYE LAKE" },
  "43009": { state: "OH", city: "CABLE" },
  "43010": { state: "OH", city: "CATAWBA" },
  "43011": { state: "OH", city: "CENTERBURG" },
  "43013": { state: "OH", city: "CROTON" },
  "43014": { state: "OH", city: "DANVILLE" },
  "43015": { state: "OH", city: "DELAWARE" },
  "43016": { state: "OH", city: "DUBLIN" },
  "43017": { state: "OH", city: "DUBLIN" },
  "43018": { state: "OH", city: "ETNA" },
  "43019": { state: "OH", city: "FREDERICKTOWN" },
  "43021": { state: "OH", city: "GALENA" },
  "43022": { state: "OH", city: "GAMBIER" },
  "43023": { state: "OH", city: "GRANVILLE" },
  "43025": { state: "OH", city: "HEBRON" },
  "43026": { state: "OH", city: "HILLIARD" },
  "43027": { state: "OH", city: "HOMER" },
  "43028": { state: "OH", city: "HOWARD" },
  "43029": { state: "OH", city: "IRWIN" },
  "43030": { state: "OH", city: "JACKSONTOWN" },
  "43031": { state: "OH", city: "JOHNSTOWN" },
  "43032": { state: "OH", city: "KILBOURNE" },
  "43033": { state: "OH", city: "KIRKERSVILLE" },
  "43035": { state: "OH", city: "LEWIS CENTER" },
  "43036": { state: "OH", city: "MAGNETIC SPRINGS" },
  "43037": { state: "OH", city: "MARTINSBURG" },
  "43040": { state: "OH", city: "MARYSVILLE" },
  "43041": { state: "OH", city: "MARYSVILLE" },
  "43044": { state: "OH", city: "MECHANICSBURG" },
  "43045": { state: "OH", city: "MILFORD CENTER" },
  "43046": { state: "OH", city: "MILLERSPORT" },
  "43047": { state: "OH", city: "MINGO" },
  "43048": { state: "OH", city: "MOUNT LIBERTY" },
  "43050": { state: "OH", city: "MOUNT VERNON" },
  "43054": { state: "OH", city: "NEW ALBANY" },
  "43055": { state: "OH", city: "NEWARK" },
  "43056": { state: "OH", city: "HEATH" },
  "43058": { state: "OH", city: "NEWARK" },
  "43060": { state: "OH", city: "NORTH LEWISBURG" },
  "43061": { state: "OH", city: "OSTRANDER" },
  "43062": { state: "OH", city: "PATASKALA" },
  "43064": { state: "OH", city: "PLAIN CITY" },
  "43065": { state: "OH", city: "POWELL" },
  "43066": { state: "OH", city: "RADNOR" },
  "43067": { state: "OH", city: "RAYMOND" },
  "43068": { state: "OH", city: "REYNOLDSBURG" },
  "43069": { state: "OH", city: "REYNOLDSBURG" },
  "43070": { state: "OH", city: "ROSEWOOD" },
  "43071": { state: "OH", city: "SAINT LOUISVILLE" },
  "43072": { state: "OH", city: "SAINT PARIS" },
  "43073": { state: "OH", city: "SUMMIT STATION" },
  "43074": { state: "OH", city: "SUNBURY" },
  "43076": { state: "OH", city: "THORNVILLE" },
  "43077": { state: "OH", city: "UNIONVILLE CENTER" },
  "43078": { state: "OH", city: "URBANA" },
  "43080": { state: "OH", city: "UTICA" },
  "43081": { state: "OH", city: "WESTERVILLE" },
  "43082": { state: "OH", city: "WESTERVILLE" },
  "43083": { state: "OH", city: "WESTVILLE" },
  "43084": { state: "OH", city: "WOODSTOCK" },
  "43085": { state: "OH", city: "COLUMBUS" },
  "43086": { state: "OH", city: "WESTERVILLE" },
  "43093": { state: "OH", city: "NEWARK" },
  "43101": { state: "OH", city: "ADELPHI" },
  "43102": { state: "OH", city: "AMANDA" },
  "43103": { state: "OH", city: "ASHVILLE" },
  "43105": { state: "OH", city: "BALTIMORE" },
  "43106": { state: "OH", city: "BLOOMINGBURG" },
  "43107": { state: "OH", city: "BREMEN" },
  "43109": { state: "OH", city: "BRICE" },
  "43110": { state: "OH", city: "CANAL WINCHESTER" },
  "43111": { state: "OH", city: "CARBON HILL" },
  "43112": { state: "OH", city: "CARROLL" },
  "43113": { state: "OH", city: "CIRCLEVILLE" },
  "43115": { state: "OH", city: "CLARKSBURG" },
  "43116": { state: "OH", city: "COMMERCIAL POINT" },
  "43117": { state: "OH", city: "DERBY" },
  "43119": { state: "OH", city: "GALLOWAY" },
  "43123": { state: "OH", city: "GROVE CITY" },
  "43125": { state: "OH", city: "GROVEPORT" },
  "43126": { state: "OH", city: "HARRISBURG" },
  "43127": { state: "OH", city: "HAYDENVILLE" },
  "43128": { state: "OH", city: "JEFFERSONVILLE" },
  "43130": { state: "OH", city: "LANCASTER" },
  "43135": { state: "OH", city: "LAURELVILLE" },
  "43136": { state: "OH", city: "LITHOPOLIS" },
  "43137": { state: "OH", city: "LOCKBOURNE" },
  "43138": { state: "OH", city: "LOGAN" },
  "43140": { state: "OH", city: "LONDON" },
  "43142": { state: "OH", city: "MILLEDGEVILLE" },
  "43143": { state: "OH", city: "MOUNT STERLING" },
  "43144": { state: "OH", city: "MURRAY CITY" },
  "43145": { state: "OH", city: "NEW HOLLAND" },
  "43146": { state: "OH", city: "ORIENT" },
  "43147": { state: "OH", city: "PICKERINGTON" },
  "43148": { state: "OH", city: "PLEASANTVILLE" },
  "43149": { state: "OH", city: "ROCKBRIDGE" },
  "43150": { state: "OH", city: "RUSHVILLE" },
  "43151": { state: "OH", city: "SEDALIA" },
  "43152": { state: "OH", city: "SOUTH BLOOMINGVILLE" },
  "43153": { state: "OH", city: "SOUTH SOLON" },
  "43154": { state: "OH", city: "STOUTSVILLE" },
  "43155": { state: "OH", city: "SUGAR GROVE" },
  "43156": { state: "OH", city: "TARLTON" },
  "43157": { state: "OH", city: "THURSTON" },
  "43158": { state: "OH", city: "UNION FURNACE" },
  "43160": { state: "OH", city: "WASHINGTON COURT HOUSE" },
  "43162": { state: "OH", city: "WEST JEFFERSON" },
  "43164": { state: "OH", city: "WILLIAMSPORT" },
  "43194": { state: "OH", city: "LOCKBOURNE" },
  "43195": { state: "OH", city: "GROVEPORT" },
  "43199": { state: "OH", city: "GROVEPORT" },
  "43201": { state: "OH", city: "COLUMBUS" },
  "43202": { state: "OH", city: "COLUMBUS" },
  "43203": { state: "OH", city: "COLUMBUS" },
  "43204": { state: "OH", city: "COLUMBUS" },
  "43205": { state: "OH", city: "COLUMBUS" },
  "43206": { state: "OH", city: "COLUMBUS" },
  "43207": { state: "OH", city: "COLUMBUS" },
  "43209": { state: "OH", city: "COLUMBUS" },
  "43210": { state: "OH", city: "COLUMBUS" },
  "43211": { state: "OH", city: "COLUMBUS" },
  "43212": { state: "OH", city: "COLUMBUS" },
  "43213": { state: "OH", city: "COLUMBUS" },
  "43214": { state: "OH", city: "COLUMBUS" },
  "43215": { state: "OH", city: "COLUMBUS" },
  "43216": { state: "OH", city: "COLUMBUS" },
  "43217": { state: "OH", city: "COLUMBUS" },
  "43218": { state: "OH", city: "COLUMBUS" },
  "43219": { state: "OH", city: "COLUMBUS" },
  "43220": { state: "OH", city: "COLUMBUS" },
  "43221": { state: "OH", city: "COLUMBUS" },
  "43222": { state: "OH", city: "COLUMBUS" },
  "43223": { state: "OH", city: "COLUMBUS" },
  "43224": { state: "OH", city: "COLUMBUS" },
  "43226": { state: "OH", city: "COLUMBUS" },
  "43227": { state: "OH", city: "COLUMBUS" },
  "43228": { state: "OH", city: "COLUMBUS" },
  "43229": { state: "OH", city: "COLUMBUS" },
  "43230": { state: "OH", city: "COLUMBUS" },
  "43231": { state: "OH", city: "COLUMBUS" },
  "43232": { state: "OH", city: "COLUMBUS" },
  "43234": { state: "OH", city: "COLUMBUS" },
  "43235": { state: "OH", city: "COLUMBUS" },
  "43236": { state: "OH", city: "COLUMBUS" },
  "43240": { state: "OH", city: "COLUMBUS" },
  "43251": { state: "OH", city: "COLUMBUS" },
  "43260": { state: "OH", city: "COLUMBUS" },
  "43266": { state: "OH", city: "COLUMBUS" },
  "43268": { state: "OH", city: "COLUMBUS" },
  "43270": { state: "OH", city: "COLUMBUS" },
  "43271": { state: "OH", city: "COLUMBUS" },
  "43272": { state: "OH", city: "COLUMBUS" },
  "43279": { state: "OH", city: "COLUMBUS" },
  "43287": { state: "OH", city: "COLUMBUS" },
  "43291": { state: "OH", city: "COLUMBUS" },
  "43301": { state: "OH", city: "MARION" },
  "43302": { state: "OH", city: "MARION" },
  "43310": { state: "OH", city: "BELLE CENTER" },
  "43311": { state: "OH", city: "BELLEFONTAINE" },
  "43314": { state: "OH", city: "CALEDONIA" },
  "43315": { state: "OH", city: "CARDINGTON" },
  "43316": { state: "OH", city: "CAREY" },
  "43317": { state: "OH", city: "CHESTERVILLE" },
  "43318": { state: "OH", city: "DE GRAFF" },
  "43319": { state: "OH", city: "EAST LIBERTY" },
  "43320": { state: "OH", city: "EDISON" },
  "43321": { state: "OH", city: "FULTON" },
  "43322": { state: "OH", city: "GREEN CAMP" },
  "43323": { state: "OH", city: "HARPSTER" },
  "43324": { state: "OH", city: "HUNTSVILLE" },
  "43325": { state: "OH", city: "IBERIA" },
  "43326": { state: "OH", city: "KENTON" },
  "43330": { state: "OH", city: "KIRBY" },
  "43331": { state: "OH", city: "LAKEVIEW" },
  "43332": { state: "OH", city: "LA RUE" },
  "43333": { state: "OH", city: "LEWISTOWN" },
  "43334": { state: "OH", city: "MARENGO" },
  "43335": { state: "OH", city: "MARTEL" },
  "43336": { state: "OH", city: "MIDDLEBURG" },
  "43337": { state: "OH", city: "MORRAL" },
  "43338": { state: "OH", city: "MOUNT GILEAD" },
  "43340": { state: "OH", city: "MOUNT VICTORY" },
  "43341": { state: "OH", city: "NEW BLOOMINGTON" },
  "43342": { state: "OH", city: "PROSPECT" },
  "43343": { state: "OH", city: "QUINCY" },
  "43344": { state: "OH", city: "RICHWOOD" },
  "43345": { state: "OH", city: "RIDGEWAY" },
  "43346": { state: "OH", city: "ROUNDHEAD" },
  "43347": { state: "OH", city: "RUSHSYLVANIA" },
  "43348": { state: "OH", city: "RUSSELLS POINT" },
  "43349": { state: "OH", city: "SHAUCK" },
  "43350": { state: "OH", city: "SPARTA" },
  "43351": { state: "OH", city: "UPPER SANDUSKY" },
  "43356": { state: "OH", city: "WALDO" },
  "43357": { state: "OH", city: "WEST LIBERTY" },
  "43358": { state: "OH", city: "WEST MANSFIELD" },
  "43359": { state: "OH", city: "WHARTON" },
  "43360": { state: "OH", city: "ZANESFIELD" },
  "43402": { state: "OH", city: "BOWLING GREEN" },
  "43403": { state: "OH", city: "BOWLING GREEN" },
  "43406": { state: "OH", city: "BRADNER" },
  "43407": { state: "OH", city: "BURGOON" },
  "43408": { state: "OH", city: "CLAY CENTER" },
  "43410": { state: "OH", city: "CLYDE" },
  "43412": { state: "OH", city: "CURTICE" },
  "43413": { state: "OH", city: "CYGNET" },
  "43414": { state: "OH", city: "DUNBRIDGE" },
  "43416": { state: "OH", city: "ELMORE" },
  "43420": { state: "OH", city: "FREMONT" },
  "43430": { state: "OH", city: "GENOA" },
  "43431": { state: "OH", city: "GIBSONBURG" },
  "43432": { state: "OH", city: "GRAYTOWN" },
  "43433": { state: "OH", city: "GYPSUM" },
  "43434": { state: "OH", city: "HARBOR VIEW" },
  "43435": { state: "OH", city: "HELENA" },
  "43436": { state: "OH", city: "ISLE SAINT GEORGE" },
  "43437": { state: "OH", city: "JERRY CITY" },
  "43438": { state: "OH", city: "KELLEYS ISLAND" },
  "43439": { state: "OH", city: "LACARNE" },
  "43440": { state: "OH", city: "LAKESIDE MARBLEHEAD" },
  "43441": { state: "OH", city: "LEMOYNE" },
  "43442": { state: "OH", city: "LINDSEY" },
  "43443": { state: "OH", city: "LUCKEY" },
  "43445": { state: "OH", city: "MARTIN" },
  "43446": { state: "OH", city: "MIDDLE BASS" },
  "43447": { state: "OH", city: "MILLBURY" },
  "43449": { state: "OH", city: "OAK HARBOR" },
  "43450": { state: "OH", city: "PEMBERVILLE" },
  "43451": { state: "OH", city: "PORTAGE" },
  "43452": { state: "OH", city: "PORT CLINTON" },
  "43456": { state: "OH", city: "PUT IN BAY" },
  "43457": { state: "OH", city: "RISINGSUN" },
  "43458": { state: "OH", city: "ROCKY RIDGE" },
  "43460": { state: "OH", city: "ROSSFORD" },
  "43462": { state: "OH", city: "RUDOLPH" },
  "43463": { state: "OH", city: "STONY RIDGE" },
  "43464": { state: "OH", city: "VICKERY" },
  "43465": { state: "OH", city: "WALBRIDGE" },
  "43466": { state: "OH", city: "WAYNE" },
  "43467": { state: "OH", city: "WEST MILLGROVE" },
  "43468": { state: "OH", city: "WILLISTON" },
  "43469": { state: "OH", city: "WOODVILLE" },
  "43501": { state: "OH", city: "ALVORDTON" },
  "43502": { state: "OH", city: "ARCHBOLD" },
  "43504": { state: "OH", city: "BERKEY" },
  "43505": { state: "OH", city: "BLAKESLEE" },
  "43506": { state: "OH", city: "BRYAN" },
  "43510": { state: "OH", city: "COLTON" },
  "43511": { state: "OH", city: "CUSTAR" },
  "43512": { state: "OH", city: "DEFIANCE" },
  "43515": { state: "OH", city: "DELTA" },
  "43516": { state: "OH", city: "DESHLER" },
  "43517": { state: "OH", city: "EDGERTON" },
  "43518": { state: "OH", city: "EDON" },
  "43519": { state: "OH", city: "EVANSPORT" },
  "43520": { state: "OH", city: "FARMER" },
  "43521": { state: "OH", city: "FAYETTE" },
  "43522": { state: "OH", city: "GRAND RAPIDS" },
  "43523": { state: "OH", city: "GRELTON" },
  "43524": { state: "OH", city: "HAMLER" },
  "43525": { state: "OH", city: "HASKINS" },
  "43526": { state: "OH", city: "HICKSVILLE" },
  "43527": { state: "OH", city: "HOLGATE" },
  "43528": { state: "OH", city: "HOLLAND" },
  "43529": { state: "OH", city: "HOYTVILLE" },
  "43530": { state: "OH", city: "JEWELL" },
  "43531": { state: "OH", city: "KUNKLE" },
  "43532": { state: "OH", city: "LIBERTY CENTER" },
  "43533": { state: "OH", city: "LYONS" },
  "43534": { state: "OH", city: "MC CLURE" },
  "43535": { state: "OH", city: "MALINTA" },
  "43536": { state: "OH", city: "MARK CENTER" },
  "43537": { state: "OH", city: "MAUMEE" },
  "43540": { state: "OH", city: "METAMORA" },
  "43541": { state: "OH", city: "MILTON CENTER" },
  "43542": { state: "OH", city: "MONCLOVA" },
  "43543": { state: "OH", city: "MONTPELIER" },
  "43545": { state: "OH", city: "NAPOLEON" },
  "43547": { state: "OH", city: "NEAPOLIS" },
  "43548": { state: "OH", city: "NEW BAVARIA" },
  "43549": { state: "OH", city: "NEY" },
  "43551": { state: "OH", city: "PERRYSBURG" },
  "43552": { state: "OH", city: "PERRYSBURG" },
  "43553": { state: "OH", city: "PETTISVILLE" },
  "43554": { state: "OH", city: "PIONEER" },
  "43555": { state: "OH", city: "RIDGEVILLE CORNERS" },
  "43556": { state: "OH", city: "SHERWOOD" },
  "43557": { state: "OH", city: "STRYKER" },
  "43558": { state: "OH", city: "SWANTON" },
  "43560": { state: "OH", city: "SYLVANIA" },
  "43565": { state: "OH", city: "TONTOGANY" },
  "43566": { state: "OH", city: "WATERVILLE" },
  "43567": { state: "OH", city: "WAUSEON" },
  "43569": { state: "OH", city: "WESTON" },
  "43570": { state: "OH", city: "WEST UNITY" },
  "43571": { state: "OH", city: "WHITEHOUSE" },
  "43601": { state: "OH", city: "TOLEDO" },
  "43603": { state: "OH", city: "TOLEDO" },
  "43604": { state: "OH", city: "TOLEDO" },
  "43605": { state: "OH", city: "TOLEDO" },
  "43606": { state: "OH", city: "TOLEDO" },
  "43607": { state: "OH", city: "TOLEDO" },
  "43608": { state: "OH", city: "TOLEDO" },
  "43609": { state: "OH", city: "TOLEDO" },
  "43610": { state: "OH", city: "TOLEDO" },
  "43611": { state: "OH", city: "TOLEDO" },
  "43612": { state: "OH", city: "TOLEDO" },
  "43613": { state: "OH", city: "TOLEDO" },
  "43614": { state: "OH", city: "TOLEDO" },
  "43615": { state: "OH", city: "TOLEDO" },
  "43616": { state: "OH", city: "OREGON" },
  "43617": { state: "OH", city: "TOLEDO" },
  "43619": { state: "OH", city: "NORTHWOOD" },
  "43620": { state: "OH", city: "TOLEDO" },
  "43623": { state: "OH", city: "TOLEDO" },
  "43635": { state: "OH", city: "TOLEDO" },
  "43652": { state: "OH", city: "TOLEDO" },
  "43654": { state: "OH", city: "TOLEDO" },
  "43656": { state: "OH", city: "TOLEDO" },
  "43657": { state: "OH", city: "TOLEDO" },
  "43659": { state: "OH", city: "TOLEDO" },
  "43660": { state: "OH", city: "TOLEDO" },
  "43661": { state: "OH", city: "TOLEDO" },
  "43666": { state: "OH", city: "TOLEDO" },
  "43667": { state: "OH", city: "TOLEDO" },
  "43681": { state: "OH", city: "TOLEDO" },
  "43682": { state: "OH", city: "TOLEDO" },
  "43697": { state: "OH", city: "TOLEDO" },
  "43699": { state: "OH", city: "TOLEDO" },
  "43701": { state: "OH", city: "ZANESVILLE" },
  "43702": { state: "OH", city: "ZANESVILLE" },
  "43711": { state: "OH", city: "AVA" },
  "43713": { state: "OH", city: "BARNESVILLE" },
  "43716": { state: "OH", city: "BEALLSVILLE" },
  "43717": { state: "OH", city: "BELLE VALLEY" },
  "43718": { state: "OH", city: "BELMONT" },
  "43719": { state: "OH", city: "BETHESDA" },
  "43720": { state: "OH", city: "BLUE ROCK" },
  "43721": { state: "OH", city: "BROWNSVILLE" },
  "43722": { state: "OH", city: "BUFFALO" },
  "43723": { state: "OH", city: "BYESVILLE" },
  "43724": { state: "OH", city: "CALDWELL" },
  "43725": { state: "OH", city: "CAMBRIDGE" },
  "43727": { state: "OH", city: "CHANDLERSVILLE" },
  "43728": { state: "OH", city: "CHESTERHILL" },
  "43730": { state: "OH", city: "CORNING" },
  "43731": { state: "OH", city: "CROOKSVILLE" },
  "43732": { state: "OH", city: "CUMBERLAND" },
  "43733": { state: "OH", city: "DERWENT" },
  "43734": { state: "OH", city: "DUNCAN FALLS" },
  "43735": { state: "OH", city: "EAST FULTONHAM" },
  "43736": { state: "OH", city: "FAIRVIEW" },
  "43738": { state: "OH", city: "FULTONHAM" },
  "43739": { state: "OH", city: "GLENFORD" },
  "43740": { state: "OH", city: "GRATIOT" },
  "43746": { state: "OH", city: "HOPEWELL" },
  "43747": { state: "OH", city: "JERUSALEM" },
  "43748": { state: "OH", city: "JUNCTION CITY" },
  "43749": { state: "OH", city: "KIMBOLTON" },
  "43750": { state: "OH", city: "KIPLING" },
  "43752": { state: "OH", city: "LAINGS" },
  "43754": { state: "OH", city: "LEWISVILLE" },
  "43755": { state: "OH", city: "LORE CITY" },
  "43756": { state: "OH", city: "MCCONNELSVILLE" },
  "43757": { state: "OH", city: "MALAGA" },
  "43758": { state: "OH", city: "MALTA" },
  "43759": { state: "OH", city: "MORRISTOWN" },
  "43760": { state: "OH", city: "MOUNT PERRY" },
  "43761": { state: "OH", city: "MOXAHALA" },
  "43762": { state: "OH", city: "NEW CONCORD" },
  "43764": { state: "OH", city: "NEW LEXINGTON" },
  "43766": { state: "OH", city: "NEW STRAITSVILLE" },
  "43767": { state: "OH", city: "NORWICH" },
  "43768": { state: "OH", city: "OLD WASHINGTON" },
  "43771": { state: "OH", city: "PHILO" },
  "43772": { state: "OH", city: "PLEASANT CITY" },
  "43773": { state: "OH", city: "QUAKER CITY" },
  "43777": { state: "OH", city: "ROSEVILLE" },
  "43778": { state: "OH", city: "SALESVILLE" },
  "43779": { state: "OH", city: "SARAHSVILLE" },
  "43780": { state: "OH", city: "SENECAVILLE" },
  "43782": { state: "OH", city: "SHAWNEE" },
  "43783": { state: "OH", city: "SOMERSET" },
  "43786": { state: "OH", city: "STAFFORD" },
  "43787": { state: "OH", city: "STOCKPORT" },
  "43788": { state: "OH", city: "SUMMERFIELD" },
  "43791": { state: "OH", city: "WHITE COTTAGE" },
  "43793": { state: "OH", city: "WOODSFIELD" },
  "43802": { state: "OH", city: "ADAMSVILLE" },
  "43803": { state: "OH", city: "BAKERSVILLE" },
  "43804": { state: "OH", city: "BALTIC" },
  "43805": { state: "OH", city: "BLISSFIELD" },
  "43811": { state: "OH", city: "CONESVILLE" },
  "43812": { state: "OH", city: "COSHOCTON" },
  "43821": { state: "OH", city: "DRESDEN" },
  "43822": { state: "OH", city: "FRAZEYSBURG" },
  "43824": { state: "OH", city: "FRESNO" },
  "43828": { state: "OH", city: "KEENE" },
  "43830": { state: "OH", city: "NASHPORT" },
  "43832": { state: "OH", city: "NEWCOMERSTOWN" },
  "43836": { state: "OH", city: "PLAINFIELD" },
  "43837": { state: "OH", city: "PORT WASHINGTON" },
  "43840": { state: "OH", city: "STONE CREEK" },
  "43842": { state: "OH", city: "TRINWAY" },
  "43843": { state: "OH", city: "WALHONDING" },
  "43844": { state: "OH", city: "WARSAW" },
  "43845": { state: "OH", city: "WEST LAFAYETTE" },
  "43901": { state: "OH", city: "ADENA" },
  "43902": { state: "OH", city: "ALLEDONIA" },
  "43903": { state: "OH", city: "AMSTERDAM" },
  "43905": { state: "OH", city: "BARTON" },
  "43906": { state: "OH", city: "BELLAIRE" },
  "43907": { state: "OH", city: "CADIZ" },
  "43908": { state: "OH", city: "BERGHOLZ" },
  "43909": { state: "OH", city: "BLAINE" },
  "43910": { state: "OH", city: "BLOOMINGDALE" },
  "43912": { state: "OH", city: "BRIDGEPORT" },
  "43913": { state: "OH", city: "BRILLIANT" },
  "43914": { state: "OH", city: "CAMERON" },
  "43915": { state: "OH", city: "CLARINGTON" },
  "43916": { state: "OH", city: "COLERAIN" },
  "43917": { state: "OH", city: "DILLONVALE" },
  "43920": { state: "OH", city: "EAST LIVERPOOL" },
  "43925": { state: "OH", city: "EAST SPRINGFIELD" },
  "43926": { state: "OH", city: "EMPIRE" },
  "43927": { state: "OH", city: "FAIRPOINT" },
  "43928": { state: "OH", city: "GLENCOE" },
  "43930": { state: "OH", city: "HAMMONDSVILLE" },
  "43931": { state: "OH", city: "HANNIBAL" },
  "43932": { state: "OH", city: "IRONDALE" },
  "43933": { state: "OH", city: "JACOBSBURG" },
  "43934": { state: "OH", city: "LANSING" },
  "43935": { state: "OH", city: "MARTINS FERRY" },
  "43937": { state: "OH", city: "MAYNARD" },
  "43938": { state: "OH", city: "MINGO JUNCTION" },
  "43939": { state: "OH", city: "MOUNT PLEASANT" },
  "43940": { state: "OH", city: "NEFFS" },
  "43941": { state: "OH", city: "PINEY FORK" },
  "43942": { state: "OH", city: "POWHATAN POINT" },
  "43943": { state: "OH", city: "RAYLAND" },
  "43944": { state: "OH", city: "RICHMOND" },
  "43945": { state: "OH", city: "SALINEVILLE" },
  "43946": { state: "OH", city: "SARDIS" },
  "43947": { state: "OH", city: "SHADYSIDE" },
  "43948": { state: "OH", city: "SMITHFIELD" },
  "43950": { state: "OH", city: "SAINT CLAIRSVILLE" },
  "43951": { state: "OH", city: "LAFFERTY" },
  "43952": { state: "OH", city: "STEUBENVILLE" },
  "43953": { state: "OH", city: "STEUBENVILLE" },
  "43961": { state: "OH", city: "STRATTON" },
  "43962": { state: "OH", city: "SUMMITVILLE" },
  "43963": { state: "OH", city: "TILTONSVILLE" },
  "43964": { state: "OH", city: "TORONTO" },
  "43967": { state: "OH", city: "WARNOCK" },
  "43968": { state: "OH", city: "WELLSVILLE" },
  "43970": { state: "OH", city: "WOLF RUN" },
  "43971": { state: "OH", city: "YORKVILLE" },
  "43972": { state: "OH", city: "BANNOCK" },
  "43973": { state: "OH", city: "FREEPORT" },
  "43974": { state: "OH", city: "HARRISVILLE" },
  "43976": { state: "OH", city: "HOPEDALE" },
  "43977": { state: "OH", city: "FLUSHING" },
  "43981": { state: "OH", city: "NEW ATHENS" },
  "43983": { state: "OH", city: "PIEDMONT" },
  "43984": { state: "OH", city: "NEW RUMLEY" },
  "43985": { state: "OH", city: "HOLLOWAY" },
  "43986": { state: "OH", city: "JEWETT" },
  "43988": { state: "OH", city: "SCIO" },
  "44001": { state: "OH", city: "AMHERST" },
  "44003": { state: "OH", city: "ANDOVER" },
  "44004": { state: "OH", city: "ASHTABULA" },
  "44005": { state: "OH", city: "ASHTABULA" },
  "44010": { state: "OH", city: "AUSTINBURG" },
  "44011": { state: "OH", city: "AVON" },
  "44012": { state: "OH", city: "AVON LAKE" },
  "44017": { state: "OH", city: "BEREA" },
  "44021": { state: "OH", city: "BURTON" },
  "44022": { state: "OH", city: "CHAGRIN FALLS" },
  "44023": { state: "OH", city: "CHAGRIN FALLS" },
  "44024": { state: "OH", city: "CHARDON" },
  "44026": { state: "OH", city: "CHESTERLAND" },
  "44028": { state: "OH", city: "COLUMBIA STATION" },
  "44030": { state: "OH", city: "CONNEAUT" },
  "44032": { state: "OH", city: "DORSET" },
  "44033": { state: "OH", city: "EAST CLARIDON" },
  "44035": { state: "OH", city: "ELYRIA" },
  "44036": { state: "OH", city: "ELYRIA" },
  "44039": { state: "OH", city: "NORTH RIDGEVILLE" },
  "44040": { state: "OH", city: "GATES MILLS" },
  "44041": { state: "OH", city: "GENEVA" },
  "44044": { state: "OH", city: "GRAFTON" },
  "44045": { state: "OH", city: "GRAND RIVER" },
  "44046": { state: "OH", city: "HUNTSBURG" },
  "44047": { state: "OH", city: "JEFFERSON" },
  "44048": { state: "OH", city: "KINGSVILLE" },
  "44049": { state: "OH", city: "KIPTON" },
  "44050": { state: "OH", city: "LAGRANGE" },
  "44052": { state: "OH", city: "LORAIN" },
  "44053": { state: "OH", city: "LORAIN" },
  "44054": { state: "OH", city: "SHEFFIELD LAKE" },
  "44055": { state: "OH", city: "LORAIN" },
  "44056": { state: "OH", city: "MACEDONIA" },
  "44057": { state: "OH", city: "MADISON" },
  "44060": { state: "OH", city: "MENTOR" },
  "44061": { state: "OH", city: "MENTOR" },
  "44062": { state: "OH", city: "MIDDLEFIELD" },
  "44064": { state: "OH", city: "MONTVILLE" },
  "44065": { state: "OH", city: "NEWBURY" },
  "44067": { state: "OH", city: "NORTHFIELD" },
  "44068": { state: "OH", city: "NORTH KINGSVILLE" },
  "44070": { state: "OH", city: "NORTH OLMSTED" },
  "44072": { state: "OH", city: "NOVELTY" },
  "44073": { state: "OH", city: "NOVELTY" },
  "44074": { state: "OH", city: "OBERLIN" },
  "44076": { state: "OH", city: "ORWELL" },
  "44077": { state: "OH", city: "PAINESVILLE" },
  "44080": { state: "OH", city: "PARKMAN" },
  "44081": { state: "OH", city: "PERRY" },
  "44082": { state: "OH", city: "PIERPONT" },
  "44084": { state: "OH", city: "ROCK CREEK" },
  "44085": { state: "OH", city: "ROME" },
  "44086": { state: "OH", city: "THOMPSON" },
  "44087": { state: "OH", city: "TWINSBURG" },
  "44088": { state: "OH", city: "UNIONVILLE" },
  "44089": { state: "OH", city: "VERMILION" },
  "44090": { state: "OH", city: "WELLINGTON" },
  "44092": { state: "OH", city: "WICKLIFFE" },
  "44093": { state: "OH", city: "WILLIAMSFIELD" },
  "44094": { state: "OH", city: "WILLOUGHBY" },
  "44095": { state: "OH", city: "EASTLAKE" },
  "44096": { state: "OH", city: "WILLOUGHBY" },
  "44097": { state: "OH", city: "EASTLAKE" },
  "44099": { state: "OH", city: "WINDSOR" },
  "44101": { state: "OH", city: "CLEVELAND" },
  "44102": { state: "OH", city: "CLEVELAND" },
  "44103": { state: "OH", city: "CLEVELAND" },
  "44104": { state: "OH", city: "CLEVELAND" },
  "44105": { state: "OH", city: "CLEVELAND" },
  "44106": { state: "OH", city: "CLEVELAND" },
  "44107": { state: "OH", city: "LAKEWOOD" },
  "44108": { state: "OH", city: "CLEVELAND" },
  "44109": { state: "OH", city: "CLEVELAND" },
  "44110": { state: "OH", city: "CLEVELAND" },
  "44111": { state: "OH", city: "CLEVELAND" },
  "44112": { state: "OH", city: "CLEVELAND" },
  "44113": { state: "OH", city: "CLEVELAND" },
  "44114": { state: "OH", city: "CLEVELAND" },
  "44115": { state: "OH", city: "CLEVELAND" },
  "44116": { state: "OH", city: "ROCKY RIVER" },
  "44117": { state: "OH", city: "EUCLID" },
  "44118": { state: "OH", city: "CLEVELAND" },
  "44119": { state: "OH", city: "CLEVELAND" },
  "44120": { state: "OH", city: "CLEVELAND" },
  "44121": { state: "OH", city: "CLEVELAND" },
  "44122": { state: "OH", city: "BEACHWOOD" },
  "44123": { state: "OH", city: "EUCLID" },
  "44124": { state: "OH", city: "CLEVELAND" },
  "44125": { state: "OH", city: "CLEVELAND" },
  "44126": { state: "OH", city: "CLEVELAND" },
  "44127": { state: "OH", city: "CLEVELAND" },
  "44128": { state: "OH", city: "CLEVELAND" },
  "44129": { state: "OH", city: "CLEVELAND" },
  "44130": { state: "OH", city: "CLEVELAND" },
  "44131": { state: "OH", city: "INDEPENDENCE" },
  "44132": { state: "OH", city: "EUCLID" },
  "44133": { state: "OH", city: "NORTH ROYALTON" },
  "44134": { state: "OH", city: "CLEVELAND" },
  "44135": { state: "OH", city: "CLEVELAND" },
  "44136": { state: "OH", city: "STRONGSVILLE" },
  "44137": { state: "OH", city: "MAPLE HEIGHTS" },
  "44138": { state: "OH", city: "OLMSTED FALLS" },
  "44139": { state: "OH", city: "SOLON" },
  "44140": { state: "OH", city: "BAY VILLAGE" },
  "44141": { state: "OH", city: "BRECKSVILLE" },
  "44142": { state: "OH", city: "BROOKPARK" },
  "44143": { state: "OH", city: "CLEVELAND" },
  "44144": { state: "OH", city: "CLEVELAND" },
  "44145": { state: "OH", city: "WESTLAKE" },
  "44146": { state: "OH", city: "BEDFORD" },
  "44147": { state: "OH", city: "BROADVIEW HEIGHTS" },
  "44149": { state: "OH", city: "STRONGSVILLE" },
  "44181": { state: "OH", city: "CLEVELAND" },
  "44188": { state: "OH", city: "CLEVELAND" },
  "44190": { state: "OH", city: "CLEVELAND" },
  "44191": { state: "OH", city: "CLEVELAND" },
  "44192": { state: "OH", city: "CLEVELAND" },
  "44193": { state: "OH", city: "CLEVELAND" },
  "44194": { state: "OH", city: "CLEVELAND" },
  "44195": { state: "OH", city: "CLEVELAND" },
  "44197": { state: "OH", city: "CLEVELAND" },
  "44198": { state: "OH", city: "CLEVELAND" },
  "44199": { state: "OH", city: "CLEVELAND" },
  "44201": { state: "OH", city: "ATWATER" },
  "44202": { state: "OH", city: "AURORA" },
  "44203": { state: "OH", city: "BARBERTON" },
  "44210": { state: "OH", city: "BATH" },
  "44211": { state: "OH", city: "BRADY LAKE" },
  "44212": { state: "OH", city: "BRUNSWICK" },
  "44214": { state: "OH", city: "BURBANK" },
  "44215": { state: "OH", city: "CHIPPEWA LAKE" },
  "44216": { state: "OH", city: "CLINTON" },
  "44217": { state: "OH", city: "CRESTON" },
  "44221": { state: "OH", city: "CUYAHOGA FALLS" },
  "44222": { state: "OH", city: "CUYAHOGA FALLS" },
  "44223": { state: "OH", city: "CUYAHOGA FALLS" },
  "44224": { state: "OH", city: "STOW" },
  "44230": { state: "OH", city: "DOYLESTOWN" },
  "44231": { state: "OH", city: "GARRETTSVILLE" },
  "44232": { state: "OH", city: "GREEN" },
  "44233": { state: "OH", city: "HINCKLEY" },
  "44234": { state: "OH", city: "HIRAM" },
  "44235": { state: "OH", city: "HOMERVILLE" },
  "44236": { state: "OH", city: "HUDSON" },
  "44237": { state: "OH", city: "HUDSON" },
  "44240": { state: "OH", city: "KENT" },
  "44241": { state: "OH", city: "STREETSBORO" },
  "44242": { state: "OH", city: "KENT" },
  "44243": { state: "OH", city: "KENT" },
  "44250": { state: "OH", city: "LAKEMORE" },
  "44251": { state: "OH", city: "WESTFIELD CENTER" },
  "44253": { state: "OH", city: "LITCHFIELD" },
  "44254": { state: "OH", city: "LODI" },
  "44255": { state: "OH", city: "MANTUA" },
  "44256": { state: "OH", city: "MEDINA" },
  "44258": { state: "OH", city: "MEDINA" },
  "44260": { state: "OH", city: "MOGADORE" },
  "44262": { state: "OH", city: "MUNROE FALLS" },
  "44264": { state: "OH", city: "PENINSULA" },
  "44265": { state: "OH", city: "RANDOLPH" },
  "44266": { state: "OH", city: "RAVENNA" },
  "44270": { state: "OH", city: "RITTMAN" },
  "44272": { state: "OH", city: "ROOTSTOWN" },
  "44273": { state: "OH", city: "SEVILLE" },
  "44274": { state: "OH", city: "SHARON CENTER" },
  "44275": { state: "OH", city: "SPENCER" },
  "44276": { state: "OH", city: "STERLING" },
  "44278": { state: "OH", city: "TALLMADGE" },
  "44280": { state: "OH", city: "VALLEY CITY" },
  "44281": { state: "OH", city: "WADSWORTH" },
  "44282": { state: "OH", city: "WADSWORTH" },
  "44285": { state: "OH", city: "WAYLAND" },
  "44286": { state: "OH", city: "RICHFIELD" },
  "44287": { state: "OH", city: "WEST SALEM" },
  "44288": { state: "OH", city: "WINDHAM" },
  "44301": { state: "OH", city: "AKRON" },
  "44302": { state: "OH", city: "AKRON" },
  "44303": { state: "OH", city: "AKRON" },
  "44304": { state: "OH", city: "AKRON" },
  "44305": { state: "OH", city: "AKRON" },
  "44306": { state: "OH", city: "AKRON" },
  "44307": { state: "OH", city: "AKRON" },
  "44308": { state: "OH", city: "AKRON" },
  "44309": { state: "OH", city: "AKRON" },
  "44310": { state: "OH", city: "AKRON" },
  "44311": { state: "OH", city: "AKRON" },
  "44312": { state: "OH", city: "AKRON" },
  "44313": { state: "OH", city: "AKRON" },
  "44314": { state: "OH", city: "AKRON" },
  "44315": { state: "OH", city: "AKRON" },
  "44316": { state: "OH", city: "AKRON" },
  "44317": { state: "OH", city: "AKRON" },
  "44319": { state: "OH", city: "AKRON" },
  "44320": { state: "OH", city: "AKRON" },
  "44321": { state: "OH", city: "AKRON" },
  "44325": { state: "OH", city: "AKRON" },
  "44326": { state: "OH", city: "AKRON" },
  "44328": { state: "OH", city: "AKRON" },
  "44333": { state: "OH", city: "AKRON" },
  "44334": { state: "OH", city: "FAIRLAWN" },
  "44372": { state: "OH", city: "AKRON" },
  "44396": { state: "OH", city: "AKRON" },
  "44398": { state: "OH", city: "AKRON" },
  "44401": { state: "OH", city: "BERLIN CENTER" },
  "44402": { state: "OH", city: "BRISTOLVILLE" },
  "44403": { state: "OH", city: "BROOKFIELD" },
  "44404": { state: "OH", city: "BURGHILL" },
  "44405": { state: "OH", city: "CAMPBELL" },
  "44406": { state: "OH", city: "CANFIELD" },
  "44408": { state: "OH", city: "COLUMBIANA" },
  "44410": { state: "OH", city: "CORTLAND" },
  "44411": { state: "OH", city: "DEERFIELD" },
  "44412": { state: "OH", city: "DIAMOND" },
  "44413": { state: "OH", city: "EAST PALESTINE" },
  "44415": { state: "OH", city: "ELKTON" },
  "44416": { state: "OH", city: "ELLSWORTH" },
  "44417": { state: "OH", city: "FARMDALE" },
  "44418": { state: "OH", city: "FOWLER" },
  "44420": { state: "OH", city: "GIRARD" },
  "44422": { state: "OH", city: "GREENFORD" },
  "44423": { state: "OH", city: "HANOVERTON" },
  "44424": { state: "OH", city: "HARTFORD" },
  "44425": { state: "OH", city: "HUBBARD" },
  "44427": { state: "OH", city: "KENSINGTON" },
  "44428": { state: "OH", city: "KINSMAN" },
  "44429": { state: "OH", city: "LAKE MILTON" },
  "44430": { state: "OH", city: "LEAVITTSBURG" },
  "44431": { state: "OH", city: "LEETONIA" },
  "44432": { state: "OH", city: "LISBON" },
  "44436": { state: "OH", city: "LOWELLVILLE" },
  "44437": { state: "OH", city: "MC DONALD" },
  "44438": { state: "OH", city: "MASURY" },
  "44439": { state: "OH", city: "MESOPOTAMIA" },
  "44440": { state: "OH", city: "MINERAL RIDGE" },
  "44441": { state: "OH", city: "NEGLEY" },
  "44442": { state: "OH", city: "NEW MIDDLETOWN" },
  "44443": { state: "OH", city: "NEW SPRINGFIELD" },
  "44444": { state: "OH", city: "NEWTON FALLS" },
  "44445": { state: "OH", city: "NEW WATERFORD" },
  "44446": { state: "OH", city: "NILES" },
  "44449": { state: "OH", city: "NORTH BENTON" },
  "44450": { state: "OH", city: "NORTH BLOOMFIELD" },
  "44451": { state: "OH", city: "NORTH JACKSON" },
  "44452": { state: "OH", city: "NORTH LIMA" },
  "44453": { state: "OH", city: "ORANGEVILLE" },
  "44454": { state: "OH", city: "PETERSBURG" },
  "44455": { state: "OH", city: "ROGERS" },
  "44460": { state: "OH", city: "SALEM" },
  "44470": { state: "OH", city: "SOUTHINGTON" },
  "44471": { state: "OH", city: "STRUTHERS" },
  "44473": { state: "OH", city: "VIENNA" },
  "44481": { state: "OH", city: "WARREN" },
  "44482": { state: "OH", city: "WARREN" },
  "44483": { state: "OH", city: "WARREN" },
  "44484": { state: "OH", city: "WARREN" },
  "44485": { state: "OH", city: "WARREN" },
  "44486": { state: "OH", city: "WARREN" },
  "44490": { state: "OH", city: "WASHINGTONVILLE" },
  "44491": { state: "OH", city: "WEST FARMINGTON" },
  "44492": { state: "OH", city: "WEST POINT" },
  "44493": { state: "OH", city: "WINONA" },
  "44501": { state: "OH", city: "YOUNGSTOWN" },
  "44502": { state: "OH", city: "YOUNGSTOWN" },
  "44503": { state: "OH", city: "YOUNGSTOWN" },
  "44504": { state: "OH", city: "YOUNGSTOWN" },
  "44505": { state: "OH", city: "YOUNGSTOWN" },
  "44506": { state: "OH", city: "YOUNGSTOWN" },
  "44507": { state: "OH", city: "YOUNGSTOWN" },
  "44509": { state: "OH", city: "YOUNGSTOWN" },
  "44510": { state: "OH", city: "YOUNGSTOWN" },
  "44511": { state: "OH", city: "YOUNGSTOWN" },
  "44512": { state: "OH", city: "YOUNGSTOWN" },
  "44513": { state: "OH", city: "YOUNGSTOWN" },
  "44514": { state: "OH", city: "YOUNGSTOWN" },
  "44515": { state: "OH", city: "YOUNGSTOWN" },
  "44555": { state: "OH", city: "YOUNGSTOWN" },
  "44601": { state: "OH", city: "ALLIANCE" },
  "44606": { state: "OH", city: "APPLE CREEK" },
  "44607": { state: "OH", city: "AUGUSTA" },
  "44608": { state: "OH", city: "BEACH CITY" },
  "44609": { state: "OH", city: "BELOIT" },
  "44610": { state: "OH", city: "BERLIN" },
  "44611": { state: "OH", city: "BIG PRAIRIE" },
  "44612": { state: "OH", city: "BOLIVAR" },
  "44613": { state: "OH", city: "BREWSTER" },
  "44614": { state: "OH", city: "CANAL FULTON" },
  "44615": { state: "OH", city: "CARROLLTON" },
  "44617": { state: "OH", city: "CHARM" },
  "44618": { state: "OH", city: "DALTON" },
  "44619": { state: "OH", city: "DAMASCUS" },
  "44620": { state: "OH", city: "DELLROY" },
  "44621": { state: "OH", city: "DENNISON" },
  "44622": { state: "OH", city: "DOVER" },
  "44624": { state: "OH", city: "DUNDEE" },
  "44625": { state: "OH", city: "EAST ROCHESTER" },
  "44626": { state: "OH", city: "EAST SPARTA" },
  "44627": { state: "OH", city: "FREDERICKSBURG" },
  "44628": { state: "OH", city: "GLENMONT" },
  "44629": { state: "OH", city: "GNADENHUTTEN" },
  "44630": { state: "OH", city: "GREENTOWN" },
  "44632": { state: "OH", city: "HARTVILLE" },
  "44633": { state: "OH", city: "HOLMESVILLE" },
  "44634": { state: "OH", city: "HOMEWORTH" },
  "44636": { state: "OH", city: "KIDRON" },
  "44637": { state: "OH", city: "KILLBUCK" },
  "44638": { state: "OH", city: "LAKEVILLE" },
  "44639": { state: "OH", city: "LEESVILLE" },
  "44640": { state: "OH", city: "LIMAVILLE" },
  "44641": { state: "OH", city: "LOUISVILLE" },
  "44643": { state: "OH", city: "MAGNOLIA" },
  "44644": { state: "OH", city: "MALVERN" },
  "44645": { state: "OH", city: "MARSHALLVILLE" },
  "44646": { state: "OH", city: "MASSILLON" },
  "44647": { state: "OH", city: "MASSILLON" },
  "44648": { state: "OH", city: "MASSILLON" },
  "44650": { state: "OH", city: "MAXIMO" },
  "44651": { state: "OH", city: "MECHANICSTOWN" },
  "44652": { state: "OH", city: "MIDDLEBRANCH" },
  "44653": { state: "OH", city: "MIDVALE" },
  "44654": { state: "OH", city: "MILLERSBURG" },
  "44656": { state: "OH", city: "MINERAL CITY" },
  "44657": { state: "OH", city: "MINERVA" },
  "44659": { state: "OH", city: "MOUNT EATON" },
  "44660": { state: "OH", city: "MOUNT HOPE" },
  "44661": { state: "OH", city: "NASHVILLE" },
  "44662": { state: "OH", city: "NAVARRE" },
  "44663": { state: "OH", city: "NEW PHILADELPHIA" },
  "44665": { state: "OH", city: "NORTH GEORGETOWN" },
  "44666": { state: "OH", city: "NORTH LAWRENCE" },
  "44667": { state: "OH", city: "ORRVILLE" },
  "44669": { state: "OH", city: "PARIS" },
  "44670": { state: "OH", city: "ROBERTSVILLE" },
  "44671": { state: "OH", city: "SANDYVILLE" },
  "44672": { state: "OH", city: "SEBRING" },
  "44675": { state: "OH", city: "SHERRODSVILLE" },
  "44676": { state: "OH", city: "SHREVE" },
  "44677": { state: "OH", city: "SMITHVILLE" },
  "44678": { state: "OH", city: "SOMERDALE" },
  "44679": { state: "OH", city: "STILLWATER" },
  "44680": { state: "OH", city: "STRASBURG" },
  "44681": { state: "OH", city: "SUGARCREEK" },
  "44682": { state: "OH", city: "TUSCARAWAS" },
  "44683": { state: "OH", city: "UHRICHSVILLE" },
  "44685": { state: "OH", city: "UNIONTOWN" },
  "44687": { state: "OH", city: "WALNUT CREEK" },
  "44688": { state: "OH", city: "WAYNESBURG" },
  "44689": { state: "OH", city: "WILMOT" },
  "44690": { state: "OH", city: "WINESBURG" },
  "44691": { state: "OH", city: "WOOSTER" },
  "44693": { state: "OH", city: "DEERSVILLE" },
  "44695": { state: "OH", city: "BOWERSTON" },
  "44697": { state: "OH", city: "ZOAR" },
  "44699": { state: "OH", city: "TIPPECANOE" },
  "44701": { state: "OH", city: "CANTON" },
  "44702": { state: "OH", city: "CANTON" },
  "44703": { state: "OH", city: "CANTON" },
  "44704": { state: "OH", city: "CANTON" },
  "44705": { state: "OH", city: "CANTON" },
  "44706": { state: "OH", city: "CANTON" },
  "44707": { state: "OH", city: "CANTON" },
  "44708": { state: "OH", city: "CANTON" },
  "44709": { state: "OH", city: "CANTON" },
  "44710": { state: "OH", city: "CANTON" },
  "44711": { state: "OH", city: "CANTON" },
  "44714": { state: "OH", city: "CANTON" },
  "44718": { state: "OH", city: "CANTON" },
  "44720": { state: "OH", city: "NORTH CANTON" },
  "44721": { state: "OH", city: "CANTON" },
  "44730": { state: "OH", city: "EAST CANTON" },
  "44735": { state: "OH", city: "CANTON" },
  "44750": { state: "OH", city: "CANTON" },
  "44767": { state: "OH", city: "CANTON" },
  "44799": { state: "OH", city: "CANTON" },
  "44802": { state: "OH", city: "ALVADA" },
  "44804": { state: "OH", city: "ARCADIA" },
  "44805": { state: "OH", city: "ASHLAND" },
  "44807": { state: "OH", city: "ATTICA" },
  "44809": { state: "OH", city: "BASCOM" },
  "44811": { state: "OH", city: "BELLEVUE" },
  "44813": { state: "OH", city: "BELLVILLE" },
  "44814": { state: "OH", city: "BERLIN HEIGHTS" },
  "44815": { state: "OH", city: "BETTSVILLE" },
  "44816": { state: "OH", city: "BIRMINGHAM" },
  "44817": { state: "OH", city: "BLOOMDALE" },
  "44818": { state: "OH", city: "BLOOMVILLE" },
  "44820": { state: "OH", city: "BUCYRUS" },
  "44822": { state: "OH", city: "BUTLER" },
  "44824": { state: "OH", city: "CASTALIA" },
  "44825": { state: "OH", city: "CHATFIELD" },
  "44826": { state: "OH", city: "COLLINS" },
  "44827": { state: "OH", city: "CRESTLINE" },
  "44828": { state: "OH", city: "FLAT ROCK" },
  "44830": { state: "OH", city: "FOSTORIA" },
  "44833": { state: "OH", city: "GALION" },
  "44836": { state: "OH", city: "GREEN SPRINGS" },
  "44837": { state: "OH", city: "GREENWICH" },
  "44838": { state: "OH", city: "HAYESVILLE" },
  "44839": { state: "OH", city: "HURON" },
  "44840": { state: "OH", city: "JEROMESVILLE" },
  "44841": { state: "OH", city: "KANSAS" },
  "44842": { state: "OH", city: "LOUDONVILLE" },
  "44843": { state: "OH", city: "LUCAS" },
  "44844": { state: "OH", city: "MC CUTCHENVILLE" },
  "44845": { state: "OH", city: "MELMORE" },
  "44846": { state: "OH", city: "MILAN" },
  "44847": { state: "OH", city: "MONROEVILLE" },
  "44848": { state: "OH", city: "NANKIN" },
  "44849": { state: "OH", city: "NEVADA" },
  "44850": { state: "OH", city: "NEW HAVEN" },
  "44851": { state: "OH", city: "NEW LONDON" },
  "44853": { state: "OH", city: "NEW RIEGEL" },
  "44854": { state: "OH", city: "NEW WASHINGTON" },
  "44855": { state: "OH", city: "NORTH FAIRFIELD" },
  "44856": { state: "OH", city: "NORTH ROBINSON" },
  "44857": { state: "OH", city: "NORWALK" },
  "44859": { state: "OH", city: "NOVA" },
  "44860": { state: "OH", city: "OCEOLA" },
  "44861": { state: "OH", city: "OLD FORT" },
  "44862": { state: "OH", city: "ONTARIO" },
  "44864": { state: "OH", city: "PERRYSVILLE" },
  "44865": { state: "OH", city: "PLYMOUTH" },
  "44866": { state: "OH", city: "POLK" },
  "44867": { state: "OH", city: "REPUBLIC" },
  "44870": { state: "OH", city: "SANDUSKY" },
  "44871": { state: "OH", city: "SANDUSKY" },
  "44874": { state: "OH", city: "SAVANNAH" },
  "44875": { state: "OH", city: "SHELBY" },
  "44878": { state: "OH", city: "SHILOH" },
  "44880": { state: "OH", city: "SULLIVAN" },
  "44881": { state: "OH", city: "SULPHUR SPRINGS" },
  "44882": { state: "OH", city: "SYCAMORE" },
  "44883": { state: "OH", city: "TIFFIN" },
  "44887": { state: "OH", city: "TIRO" },
  "44888": { state: "OH", city: "WILLARD" },
  "44889": { state: "OH", city: "WAKEMAN" },
  "44890": { state: "OH", city: "WILLARD" },
  "44901": { state: "OH", city: "MANSFIELD" },
  "44902": { state: "OH", city: "MANSFIELD" },
  "44903": { state: "OH", city: "MANSFIELD" },
  "44904": { state: "OH", city: "MANSFIELD" },
  "44905": { state: "OH", city: "MANSFIELD" },
  "44906": { state: "OH", city: "MANSFIELD" },
  "44907": { state: "OH", city: "MANSFIELD" },
  "45001": { state: "OH", city: "ADDYSTON" },
  "45002": { state: "OH", city: "CLEVES" },
  "45003": { state: "OH", city: "COLLEGE CORNER" },
  "45004": { state: "OH", city: "COLLINSVILLE" },
  "45005": { state: "OH", city: "FRANKLIN" },
  "45011": { state: "OH", city: "HAMILTON" },
  "45012": { state: "OH", city: "HAMILTON" },
  "45013": { state: "OH", city: "HAMILTON" },
  "45014": { state: "OH", city: "FAIRFIELD" },
  "45015": { state: "OH", city: "HAMILTON" },
  "45018": { state: "OH", city: "FAIRFIELD" },
  "45030": { state: "OH", city: "HARRISON" },
  "45032": { state: "OH", city: "HARVEYSBURG" },
  "45033": { state: "OH", city: "HOOVEN" },
  "45034": { state: "OH", city: "KINGS MILLS" },
  "45036": { state: "OH", city: "LEBANON" },
  "45039": { state: "OH", city: "MAINEVILLE" },
  "45040": { state: "OH", city: "MASON" },
  "45041": { state: "OH", city: "MIAMITOWN" },
  "45042": { state: "OH", city: "MIDDLETOWN" },
  "45044": { state: "OH", city: "MIDDLETOWN" },
  "45050": { state: "OH", city: "MONROE" },
  "45051": { state: "OH", city: "MOUNT SAINT JOSEPH" },
  "45052": { state: "OH", city: "NORTH BEND" },
  "45053": { state: "OH", city: "OKEANA" },
  "45054": { state: "OH", city: "OREGONIA" },
  "45055": { state: "OH", city: "OVERPECK" },
  "45056": { state: "OH", city: "OXFORD" },
  "45061": { state: "OH", city: "ROSS" },
  "45062": { state: "OH", city: "SEVEN MILE" },
  "45063": { state: "OH", city: "SHANDON" },
  "45064": { state: "OH", city: "SOMERVILLE" },
  "45065": { state: "OH", city: "SOUTH LEBANON" },
  "45066": { state: "OH", city: "SPRINGBORO" },
  "45067": { state: "OH", city: "TRENTON" },
  "45068": { state: "OH", city: "WAYNESVILLE" },
  "45069": { state: "OH", city: "WEST CHESTER" },
  "45070": { state: "OH", city: "WEST ELKTON" },
  "45071": { state: "OH", city: "WEST CHESTER" },
  "45101": { state: "OH", city: "ABERDEEN" },
  "45102": { state: "OH", city: "AMELIA" },
  "45103": { state: "OH", city: "BATAVIA" },
  "45105": { state: "OH", city: "BENTONVILLE" },
  "45106": { state: "OH", city: "BETHEL" },
  "45107": { state: "OH", city: "BLANCHESTER" },
  "45111": { state: "OH", city: "CAMP DENNISON" },
  "45112": { state: "OH", city: "CHILO" },
  "45113": { state: "OH", city: "CLARKSVILLE" },
  "45114": { state: "OH", city: "CUBA" },
  "45115": { state: "OH", city: "DECATUR" },
  "45118": { state: "OH", city: "FAYETTEVILLE" },
  "45119": { state: "OH", city: "FEESBURG" },
  "45120": { state: "OH", city: "FELICITY" },
  "45121": { state: "OH", city: "GEORGETOWN" },
  "45122": { state: "OH", city: "GOSHEN" },
  "45123": { state: "OH", city: "GREENFIELD" },
  "45130": { state: "OH", city: "HAMERSVILLE" },
  "45131": { state: "OH", city: "HIGGINSPORT" },
  "45132": { state: "OH", city: "HIGHLAND" },
  "45133": { state: "OH", city: "HILLSBORO" },
  "45135": { state: "OH", city: "LEESBURG" },
  "45140": { state: "OH", city: "LOVELAND" },
  "45142": { state: "OH", city: "LYNCHBURG" },
  "45144": { state: "OH", city: "MANCHESTER" },
  "45146": { state: "OH", city: "MARTINSVILLE" },
  "45147": { state: "OH", city: "MIAMIVILLE" },
  "45148": { state: "OH", city: "MIDLAND" },
  "45150": { state: "OH", city: "MILFORD" },
  "45152": { state: "OH", city: "MORROW" },
  "45153": { state: "OH", city: "MOSCOW" },
  "45154": { state: "OH", city: "MOUNT ORAB" },
  "45155": { state: "OH", city: "MOWRYSTOWN" },
  "45156": { state: "OH", city: "NEVILLE" },
  "45157": { state: "OH", city: "NEW RICHMOND" },
  "45158": { state: "OH", city: "NEWTONSVILLE" },
  "45159": { state: "OH", city: "NEW VIENNA" },
  "45160": { state: "OH", city: "OWENSVILLE" },
  "45162": { state: "OH", city: "PLEASANT PLAIN" },
  "45164": { state: "OH", city: "PORT WILLIAM" },
  "45166": { state: "OH", city: "REESVILLE" },
  "45167": { state: "OH", city: "RIPLEY" },
  "45168": { state: "OH", city: "RUSSELLVILLE" },
  "45169": { state: "OH", city: "SABINA" },
  "45171": { state: "OH", city: "SARDINIA" },
  "45172": { state: "OH", city: "SINKING SPRING" },
  "45174": { state: "OH", city: "TERRACE PARK" },
  "45176": { state: "OH", city: "WILLIAMSBURG" },
  "45177": { state: "OH", city: "WILMINGTON" },
  "45201": { state: "OH", city: "CINCINNATI" },
  "45202": { state: "OH", city: "CINCINNATI" },
  "45203": { state: "OH", city: "CINCINNATI" },
  "45204": { state: "OH", city: "CINCINNATI" },
  "45205": { state: "OH", city: "CINCINNATI" },
  "45206": { state: "OH", city: "CINCINNATI" },
  "45207": { state: "OH", city: "CINCINNATI" },
  "45208": { state: "OH", city: "CINCINNATI" },
  "45209": { state: "OH", city: "CINCINNATI" },
  "45211": { state: "OH", city: "CINCINNATI" },
  "45212": { state: "OH", city: "CINCINNATI" },
  "45213": { state: "OH", city: "CINCINNATI" },
  "45214": { state: "OH", city: "CINCINNATI" },
  "45215": { state: "OH", city: "CINCINNATI" },
  "45216": { state: "OH", city: "CINCINNATI" },
  "45217": { state: "OH", city: "CINCINNATI" },
  "45218": { state: "OH", city: "CINCINNATI" },
  "45219": { state: "OH", city: "CINCINNATI" },
  "45220": { state: "OH", city: "CINCINNATI" },
  "45221": { state: "OH", city: "CINCINNATI" },
  "45222": { state: "OH", city: "CINCINNATI" },
  "45223": { state: "OH", city: "CINCINNATI" },
  "45224": { state: "OH", city: "CINCINNATI" },
  "45225": { state: "OH", city: "CINCINNATI" },
  "45226": { state: "OH", city: "CINCINNATI" },
  "45227": { state: "OH", city: "CINCINNATI" },
  "45229": { state: "OH", city: "CINCINNATI" },
  "45230": { state: "OH", city: "CINCINNATI" },
  "45231": { state: "OH", city: "CINCINNATI" },
  "45232": { state: "OH", city: "CINCINNATI" },
  "45233": { state: "OH", city: "CINCINNATI" },
  "45234": { state: "OH", city: "CINCINNATI" },
  "45235": { state: "OH", city: "CINCINNATI" },
  "45236": { state: "OH", city: "CINCINNATI" },
  "45237": { state: "OH", city: "CINCINNATI" },
  "45238": { state: "OH", city: "CINCINNATI" },
  "45239": { state: "OH", city: "CINCINNATI" },
  "45240": { state: "OH", city: "CINCINNATI" },
  "45241": { state: "OH", city: "CINCINNATI" },
  "45242": { state: "OH", city: "CINCINNATI" },
  "45243": { state: "OH", city: "CINCINNATI" },
  "45244": { state: "OH", city: "CINCINNATI" },
  "45245": { state: "OH", city: "CINCINNATI" },
  "45246": { state: "OH", city: "CINCINNATI" },
  "45247": { state: "OH", city: "CINCINNATI" },
  "45248": { state: "OH", city: "CINCINNATI" },
  "45249": { state: "OH", city: "CINCINNATI" },
  "45250": { state: "OH", city: "CINCINNATI" },
  "45251": { state: "OH", city: "CINCINNATI" },
  "45252": { state: "OH", city: "CINCINNATI" },
  "45253": { state: "OH", city: "CINCINNATI" },
  "45254": { state: "OH", city: "CINCINNATI" },
  "45255": { state: "OH", city: "CINCINNATI" },
  "45258": { state: "OH", city: "CINCINNATI" },
  "45262": { state: "OH", city: "CINCINNATI" },
  "45263": { state: "OH", city: "CINCINNATI" },
  "45264": { state: "OH", city: "CINCINNATI" },
  "45267": { state: "OH", city: "CINCINNATI" },
  "45268": { state: "OH", city: "CINCINNATI" },
  "45269": { state: "OH", city: "CINCINNATI" },
  "45270": { state: "OH", city: "CINCINNATI" },
  "45271": { state: "OH", city: "CINCINNATI" },
  "45273": { state: "OH", city: "CINCINNATI" },
  "45274": { state: "OH", city: "CINCINNATI" },
  "45275": { state: "OH", city: "CINCINNATI" },
  "45277": { state: "OH", city: "CINCINNATI" },
  "45280": { state: "OH", city: "CINCINNATI" },
  "45296": { state: "OH", city: "CINCINNATI" },
  "45298": { state: "OH", city: "CINCINNATI" },
  "45299": { state: "OH", city: "CINCINNATI" },
  "45301": { state: "OH", city: "ALPHA" },
  "45302": { state: "OH", city: "ANNA" },
  "45303": { state: "OH", city: "ANSONIA" },
  "45304": { state: "OH", city: "ARCANUM" },
  "45305": { state: "OH", city: "BELLBROOK" },
  "45306": { state: "OH", city: "BOTKINS" },
  "45307": { state: "OH", city: "BOWERSVILLE" },
  "45308": { state: "OH", city: "BRADFORD" },
  "45309": { state: "OH", city: "BROOKVILLE" },
  "45310": { state: "OH", city: "BURKETTSVILLE" },
  "45311": { state: "OH", city: "CAMDEN" },
  "45312": { state: "OH", city: "CASSTOWN" },
  "45314": { state: "OH", city: "CEDARVILLE" },
  "45315": { state: "OH", city: "CLAYTON" },
  "45316": { state: "OH", city: "CLIFTON" },
  "45317": { state: "OH", city: "CONOVER" },
  "45318": { state: "OH", city: "COVINGTON" },
  "45319": { state: "OH", city: "DONNELSVILLE" },
  "45320": { state: "OH", city: "EATON" },
  "45321": { state: "OH", city: "ELDORADO" },
  "45322": { state: "OH", city: "ENGLEWOOD" },
  "45323": { state: "OH", city: "ENON" },
  "45324": { state: "OH", city: "FAIRBORN" },
  "45325": { state: "OH", city: "FARMERSVILLE" },
  "45326": { state: "OH", city: "FLETCHER" },
  "45327": { state: "OH", city: "GERMANTOWN" },
  "45328": { state: "OH", city: "GETTYSBURG" },
  "45330": { state: "OH", city: "GRATIS" },
  "45331": { state: "OH", city: "GREENVILLE" },
  "45332": { state: "OH", city: "HOLLANSBURG" },
  "45333": { state: "OH", city: "HOUSTON" },
  "45334": { state: "OH", city: "JACKSON CENTER" },
  "45335": { state: "OH", city: "JAMESTOWN" },
  "45336": { state: "OH", city: "KETTLERSVILLE" },
  "45337": { state: "OH", city: "LAURA" },
  "45338": { state: "OH", city: "LEWISBURG" },
  "45339": { state: "OH", city: "LUDLOW FALLS" },
  "45340": { state: "OH", city: "MAPLEWOOD" },
  "45341": { state: "OH", city: "MEDWAY" },
  "45342": { state: "OH", city: "MIAMISBURG" },
  "45343": { state: "OH", city: "MIAMISBURG" },
  "45344": { state: "OH", city: "NEW CARLISLE" },
  "45345": { state: "OH", city: "NEW LEBANON" },
  "45346": { state: "OH", city: "NEW MADISON" },
  "45347": { state: "OH", city: "NEW PARIS" },
  "45348": { state: "OH", city: "NEW WESTON" },
  "45349": { state: "OH", city: "NORTH HAMPTON" },
  "45350": { state: "OH", city: "NORTH STAR" },
  "45351": { state: "OH", city: "OSGOOD" },
  "45352": { state: "OH", city: "PALESTINE" },
  "45353": { state: "OH", city: "PEMBERTON" },
  "45354": { state: "OH", city: "PHILLIPSBURG" },
  "45356": { state: "OH", city: "PIQUA" },
  "45358": { state: "OH", city: "PITSBURG" },
  "45359": { state: "OH", city: "PLEASANT HILL" },
  "45360": { state: "OH", city: "PORT JEFFERSON" },
  "45361": { state: "OH", city: "POTSDAM" },
  "45362": { state: "OH", city: "ROSSBURG" },
  "45363": { state: "OH", city: "RUSSIA" },
  "45365": { state: "OH", city: "SIDNEY" },
  "45367": { state: "OH", city: "SIDNEY" },
  "45368": { state: "OH", city: "SOUTH CHARLESTON" },
  "45369": { state: "OH", city: "SOUTH VIENNA" },
  "45370": { state: "OH", city: "SPRING VALLEY" },
  "45371": { state: "OH", city: "TIPP CITY" },
  "45372": { state: "OH", city: "TREMONT CITY" },
  "45373": { state: "OH", city: "TROY" },
  "45374": { state: "OH", city: "TROY" },
  "45377": { state: "OH", city: "VANDALIA" },
  "45378": { state: "OH", city: "VERONA" },
  "45380": { state: "OH", city: "VERSAILLES" },
  "45381": { state: "OH", city: "WEST ALEXANDRIA" },
  "45382": { state: "OH", city: "WEST MANCHESTER" },
  "45383": { state: "OH", city: "WEST MILTON" },
  "45384": { state: "OH", city: "WILBERFORCE" },
  "45385": { state: "OH", city: "XENIA" },
  "45387": { state: "OH", city: "YELLOW SPRINGS" },
  "45388": { state: "OH", city: "YORKSHIRE" },
  "45389": { state: "OH", city: "CHRISTIANSBURG" },
  "45390": { state: "OH", city: "UNION CITY" },
  "45401": { state: "OH", city: "DAYTON" },
  "45402": { state: "OH", city: "DAYTON" },
  "45403": { state: "OH", city: "DAYTON" },
  "45404": { state: "OH", city: "DAYTON" },
  "45405": { state: "OH", city: "DAYTON" },
  "45406": { state: "OH", city: "DAYTON" },
  "45409": { state: "OH", city: "DAYTON" },
  "45410": { state: "OH", city: "DAYTON" },
  "45412": { state: "OH", city: "DAYTON" },
  "45413": { state: "OH", city: "DAYTON" },
  "45414": { state: "OH", city: "DAYTON" },
  "45415": { state: "OH", city: "DAYTON" },
  "45416": { state: "OH", city: "DAYTON" },
  "45417": { state: "OH", city: "DAYTON" },
  "45419": { state: "OH", city: "DAYTON" },
  "45420": { state: "OH", city: "DAYTON" },
  "45422": { state: "OH", city: "DAYTON" },
  "45423": { state: "OH", city: "DAYTON" },
  "45424": { state: "OH", city: "DAYTON" },
  "45426": { state: "OH", city: "DAYTON" },
  "45428": { state: "OH", city: "DAYTON" },
  "45429": { state: "OH", city: "DAYTON" },
  "45430": { state: "OH", city: "DAYTON" },
  "45431": { state: "OH", city: "DAYTON" },
  "45432": { state: "OH", city: "DAYTON" },
  "45433": { state: "OH", city: "DAYTON" },
  "45434": { state: "OH", city: "DAYTON" },
  "45435": { state: "OH", city: "DAYTON" },
  "45437": { state: "OH", city: "DAYTON" },
  "45439": { state: "OH", city: "DAYTON" },
  "45440": { state: "OH", city: "DAYTON" },
  "45441": { state: "OH", city: "DAYTON" },
  "45448": { state: "OH", city: "DAYTON" },
  "45449": { state: "OH", city: "DAYTON" },
  "45458": { state: "OH", city: "DAYTON" },
  "45459": { state: "OH", city: "DAYTON" },
  "45469": { state: "OH", city: "DAYTON" },
  "45470": { state: "OH", city: "DAYTON" },
  "45475": { state: "OH", city: "DAYTON" },
  "45479": { state: "OH", city: "DAYTON" },
  "45481": { state: "OH", city: "DAYTON" },
  "45482": { state: "OH", city: "DAYTON" },
  "45490": { state: "OH", city: "DAYTON" },
  "45501": { state: "OH", city: "SPRINGFIELD" },
  "45502": { state: "OH", city: "SPRINGFIELD" },
  "45503": { state: "OH", city: "SPRINGFIELD" },
  "45504": { state: "OH", city: "SPRINGFIELD" },
  "45505": { state: "OH", city: "SPRINGFIELD" },
  "45506": { state: "OH", city: "SPRINGFIELD" },
  "45601": { state: "OH", city: "CHILLICOTHE" },
  "45612": { state: "OH", city: "BAINBRIDGE" },
  "45613": { state: "OH", city: "BEAVER" },
  "45614": { state: "OH", city: "BIDWELL" },
  "45616": { state: "OH", city: "BLUE CREEK" },
  "45617": { state: "OH", city: "BOURNEVILLE" },
  "45618": { state: "OH", city: "CHERRY FORK" },
  "45619": { state: "OH", city: "CHESAPEAKE" },
  "45620": { state: "OH", city: "CHESHIRE" },
  "45621": { state: "OH", city: "COALTON" },
  "45622": { state: "OH", city: "CREOLA" },
  "45623": { state: "OH", city: "CROWN CITY" },
  "45624": { state: "OH", city: "CYNTHIANA" },
  "45628": { state: "OH", city: "FRANKFORT" },
  "45629": { state: "OH", city: "FRANKLIN FURNACE" },
  "45630": { state: "OH", city: "FRIENDSHIP" },
  "45631": { state: "OH", city: "GALLIPOLIS" },
  "45633": { state: "OH", city: "HALLSVILLE" },
  "45634": { state: "OH", city: "HAMDEN" },
  "45636": { state: "OH", city: "HAVERHILL" },
  "45638": { state: "OH", city: "IRONTON" },
  "45640": { state: "OH", city: "JACKSON" },
  "45642": { state: "OH", city: "JASPER" },
  "45643": { state: "OH", city: "KERR" },
  "45644": { state: "OH", city: "KINGSTON" },
  "45645": { state: "OH", city: "KITTS HILL" },
  "45646": { state: "OH", city: "LATHAM" },
  "45647": { state: "OH", city: "LONDONDERRY" },
  "45648": { state: "OH", city: "LUCASVILLE" },
  "45650": { state: "OH", city: "LYNX" },
  "45651": { state: "OH", city: "MC ARTHUR" },
  "45652": { state: "OH", city: "MC DERMOTT" },
  "45653": { state: "OH", city: "MINFORD" },
  "45654": { state: "OH", city: "NEW PLYMOUTH" },
  "45656": { state: "OH", city: "OAK HILL" },
  "45657": { state: "OH", city: "OTWAY" },
  "45658": { state: "OH", city: "PATRIOT" },
  "45659": { state: "OH", city: "PEDRO" },
  "45660": { state: "OH", city: "PEEBLES" },
  "45661": { state: "OH", city: "PIKETON" },
  "45662": { state: "OH", city: "PORTSMOUTH" },
  "45663": { state: "OH", city: "WEST PORTSMOUTH" },
  "45669": { state: "OH", city: "PROCTORVILLE" },
  "45671": { state: "OH", city: "RARDEN" },
  "45672": { state: "OH", city: "RAY" },
  "45673": { state: "OH", city: "RICHMOND DALE" },
  "45674": { state: "OH", city: "RIO GRANDE" },
  "45675": { state: "OH", city: "ROCK CAMP" },
  "45677": { state: "OH", city: "SCIOTO FURNACE" },
  "45678": { state: "OH", city: "SCOTTOWN" },
  "45679": { state: "OH", city: "SEAMAN" },
  "45680": { state: "OH", city: "SOUTH POINT" },
  "45681": { state: "OH", city: "SOUTH SALEM" },
  "45682": { state: "OH", city: "SOUTH WEBSTER" },
  "45683": { state: "OH", city: "STOCKDALE" },
  "45684": { state: "OH", city: "STOUT" },
  "45685": { state: "OH", city: "THURMAN" },
  "45686": { state: "OH", city: "VINTON" },
  "45687": { state: "OH", city: "WAKEFIELD" },
  "45688": { state: "OH", city: "WATERLOO" },
  "45690": { state: "OH", city: "WAVERLY" },
  "45692": { state: "OH", city: "WELLSTON" },
  "45693": { state: "OH", city: "WEST UNION" },
  "45694": { state: "OH", city: "WHEELERSBURG" },
  "45695": { state: "OH", city: "WILKESVILLE" },
  "45696": { state: "OH", city: "WILLOW WOOD" },
  "45697": { state: "OH", city: "WINCHESTER" },
  "45698": { state: "OH", city: "ZALESKI" },
  "45699": { state: "OH", city: "LUCASVILLE" },
  "45701": { state: "OH", city: "ATHENS" },
  "45710": { state: "OH", city: "ALBANY" },
  "45711": { state: "OH", city: "AMESVILLE" },
  "45712": { state: "OH", city: "BARLOW" },
  "45713": { state: "OH", city: "BARTLETT" },
  "45714": { state: "OH", city: "BELPRE" },
  "45715": { state: "OH", city: "BEVERLY" },
  "45716": { state: "OH", city: "BUCHTEL" },
  "45717": { state: "OH", city: "CARBONDALE" },
  "45719": { state: "OH", city: "CHAUNCEY" },
  "45720": { state: "OH", city: "CHESTER" },
  "45721": { state: "OH", city: "COAL RUN" },
  "45723": { state: "OH", city: "COOLVILLE" },
  "45724": { state: "OH", city: "CUTLER" },
  "45727": { state: "OH", city: "DEXTER CITY" },
  "45729": { state: "OH", city: "FLEMING" },
  "45732": { state: "OH", city: "GLOUSTER" },
  "45734": { state: "OH", city: "GRAYSVILLE" },
  "45735": { state: "OH", city: "GUYSVILLE" },
  "45739": { state: "OH", city: "HOCKINGPORT" },
  "45740": { state: "OH", city: "JACKSONVILLE" },
  "45741": { state: "OH", city: "LANGSVILLE" },
  "45742": { state: "OH", city: "LITTLE HOCKING" },
  "45743": { state: "OH", city: "LONG BOTTOM" },
  "45744": { state: "OH", city: "LOWELL" },
  "45745": { state: "OH", city: "LOWER SALEM" },
  "45746": { state: "OH", city: "MACKSBURG" },
  "45750": { state: "OH", city: "MARIETTA" },
  "45760": { state: "OH", city: "MIDDLEPORT" },
  "45761": { state: "OH", city: "MILLFIELD" },
  "45764": { state: "OH", city: "NELSONVILLE" },
  "45766": { state: "OH", city: "NEW MARSHFIELD" },
  "45767": { state: "OH", city: "NEW MATAMORAS" },
  "45768": { state: "OH", city: "NEWPORT" },
  "45769": { state: "OH", city: "POMEROY" },
  "45770": { state: "OH", city: "PORTLAND" },
  "45771": { state: "OH", city: "RACINE" },
  "45772": { state: "OH", city: "REEDSVILLE" },
  "45773": { state: "OH", city: "RENO" },
  "45775": { state: "OH", city: "RUTLAND" },
  "45776": { state: "OH", city: "SHADE" },
  "45777": { state: "OH", city: "SHARPSBURG" },
  "45778": { state: "OH", city: "STEWART" },
  "45779": { state: "OH", city: "SYRACUSE" },
  "45780": { state: "OH", city: "THE PLAINS" },
  "45782": { state: "OH", city: "TRIMBLE" },
  "45783": { state: "OH", city: "TUPPERS PLAINS" },
  "45784": { state: "OH", city: "VINCENT" },
  "45786": { state: "OH", city: "WATERFORD" },
  "45787": { state: "OH", city: "WATERTOWN" },
  "45788": { state: "OH", city: "WHIPPLE" },
  "45789": { state: "OH", city: "WINGETT RUN" },
  "45801": { state: "OH", city: "LIMA" },
  "45802": { state: "OH", city: "LIMA" },
  "45804": { state: "OH", city: "LIMA" },
  "45805": { state: "OH", city: "LIMA" },
  "45806": { state: "OH", city: "LIMA" },
  "45807": { state: "OH", city: "LIMA" },
  "45808": { state: "OH", city: "BEAVERDAM" },
  "45809": { state: "OH", city: "GOMER" },
  "45810": { state: "OH", city: "ADA" },
  "45812": { state: "OH", city: "ALGER" },
  "45813": { state: "OH", city: "ANTWERP" },
  "45814": { state: "OH", city: "ARLINGTON" },
  "45815": { state: "OH", city: "BELMORE" },
  "45816": { state: "OH", city: "BENTON RIDGE" },
  "45817": { state: "OH", city: "BLUFFTON" },
  "45819": { state: "OH", city: "BUCKLAND" },
  "45820": { state: "OH", city: "CAIRO" },
  "45821": { state: "OH", city: "CECIL" },
  "45822": { state: "OH", city: "CELINA" },
  "45826": { state: "OH", city: "CHICKASAW" },
  "45827": { state: "OH", city: "CLOVERDALE" },
  "45828": { state: "OH", city: "COLDWATER" },
  "45830": { state: "OH", city: "COLUMBUS GROVE" },
  "45831": { state: "OH", city: "CONTINENTAL" },
  "45832": { state: "OH", city: "CONVOY" },
  "45833": { state: "OH", city: "DELPHOS" },
  "45835": { state: "OH", city: "DOLA" },
  "45836": { state: "OH", city: "DUNKIRK" },
  "45837": { state: "OH", city: "DUPONT" },
  "45838": { state: "OH", city: "ELGIN" },
  "45839": { state: "OH", city: "FINDLAY" },
  "45840": { state: "OH", city: "FINDLAY" },
  "45841": { state: "OH", city: "JENERA" },
  "45843": { state: "OH", city: "FOREST" },
  "45844": { state: "OH", city: "FORT JENNINGS" },
  "45845": { state: "OH", city: "FORT LORAMIE" },
  "45846": { state: "OH", city: "FORT RECOVERY" },
  "45848": { state: "OH", city: "GLANDORF" },
  "45849": { state: "OH", city: "GROVER HILL" },
  "45850": { state: "OH", city: "HARROD" },
  "45851": { state: "OH", city: "HAVILAND" },
  "45853": { state: "OH", city: "KALIDA" },
  "45854": { state: "OH", city: "LAFAYETTE" },
  "45855": { state: "OH", city: "LATTY" },
  "45856": { state: "OH", city: "LEIPSIC" },
  "45858": { state: "OH", city: "MC COMB" },
  "45859": { state: "OH", city: "MC GUFFEY" },
  "45860": { state: "OH", city: "MARIA STEIN" },
  "45861": { state: "OH", city: "MELROSE" },
  "45862": { state: "OH", city: "MENDON" },
  "45863": { state: "OH", city: "MIDDLE POINT" },
  "45864": { state: "OH", city: "MILLER CITY" },
  "45865": { state: "OH", city: "MINSTER" },
  "45866": { state: "OH", city: "MONTEZUMA" },
  "45867": { state: "OH", city: "MOUNT BLANCHARD" },
  "45868": { state: "OH", city: "MOUNT CORY" },
  "45869": { state: "OH", city: "NEW BREMEN" },
  "45870": { state: "OH", city: "NEW HAMPSHIRE" },
  "45871": { state: "OH", city: "NEW KNOXVILLE" },
  "45872": { state: "OH", city: "NORTH BALTIMORE" },
  "45873": { state: "OH", city: "OAKWOOD" },
  "45874": { state: "OH", city: "OHIO CITY" },
  "45875": { state: "OH", city: "OTTAWA" },
  "45876": { state: "OH", city: "OTTOVILLE" },
  "45877": { state: "OH", city: "PANDORA" },
  "45879": { state: "OH", city: "PAULDING" },
  "45880": { state: "OH", city: "PAYNE" },
  "45881": { state: "OH", city: "RAWSON" },
  "45882": { state: "OH", city: "ROCKFORD" },
  "45883": { state: "OH", city: "SAINT HENRY" },
  "45884": { state: "OH", city: "SAINT JOHNS" },
  "45885": { state: "OH", city: "SAINT MARYS" },
  "45886": { state: "OH", city: "SCOTT" },
  "45887": { state: "OH", city: "SPENCERVILLE" },
  "45888": { state: "OH", city: "UNIOPOLIS" },
  "45889": { state: "OH", city: "VAN BUREN" },
  "45890": { state: "OH", city: "VANLUE" },
  "45891": { state: "OH", city: "VAN WERT" },
  "45893": { state: "OH", city: "VAUGHNSVILLE" },
  "45894": { state: "OH", city: "VENEDOCIA" },
  "45895": { state: "OH", city: "WAPAKONETA" },
  "45896": { state: "OH", city: "WAYNESFIELD" },
  "45897": { state: "OH", city: "WILLIAMSTOWN" },
  "45898": { state: "OH", city: "WILLSHIRE" },
  "45899": { state: "OH", city: "WREN" },
  "45999": { state: "OH", city: "CINCINNATI" },
  "46001": { state: "IN", city: "ALEXANDRIA" },
  "46011": { state: "IN", city: "ANDERSON" },
  "46012": { state: "IN", city: "ANDERSON" },
  "46013": { state: "IN", city: "ANDERSON" },
  "46014": { state: "IN", city: "ANDERSON" },
  "46015": { state: "IN", city: "ANDERSON" },
  "46016": { state: "IN", city: "ANDERSON" },
  "46017": { state: "IN", city: "ANDERSON" },
  "46018": { state: "IN", city: "ANDERSON" },
  "46030": { state: "IN", city: "ARCADIA" },
  "46031": { state: "IN", city: "ATLANTA" },
  "46032": { state: "IN", city: "CARMEL" },
  "46033": { state: "IN", city: "CARMEL" },
  "46034": { state: "IN", city: "CICERO" },
  "46035": { state: "IN", city: "COLFAX" },
  "46036": { state: "IN", city: "ELWOOD" },
  "46037": { state: "IN", city: "FISHERS" },
  "46038": { state: "IN", city: "FISHERS" },
  "46039": { state: "IN", city: "FOREST" },
  "46040": { state: "IN", city: "FORTVILLE" },
  "46041": { state: "IN", city: "FRANKFORT" },
  "46044": { state: "IN", city: "FRANKTON" },
  "46045": { state: "IN", city: "GOLDSMITH" },
  "46047": { state: "IN", city: "HOBBS" },
  "46048": { state: "IN", city: "INGALLS" },
  "46049": { state: "IN", city: "KEMPTON" },
  "46050": { state: "IN", city: "KIRKLIN" },
  "46051": { state: "IN", city: "LAPEL" },
  "46052": { state: "IN", city: "LEBANON" },
  "46055": { state: "IN", city: "MCCORDSVILLE" },
  "46056": { state: "IN", city: "MARKLEVILLE" },
  "46057": { state: "IN", city: "MICHIGANTOWN" },
  "46058": { state: "IN", city: "MULBERRY" },
  "46060": { state: "IN", city: "NOBLESVILLE" },
  "46061": { state: "IN", city: "NOBLESVILLE" },
  "46062": { state: "IN", city: "NOBLESVILLE" },
  "46063": { state: "IN", city: "ORESTES" },
  "46064": { state: "IN", city: "PENDLETON" },
  "46065": { state: "IN", city: "ROSSVILLE" },
  "46067": { state: "IN", city: "SEDALIA" },
  "46068": { state: "IN", city: "SHARPSVILLE" },
  "46069": { state: "IN", city: "SHERIDAN" },
  "46070": { state: "IN", city: "SUMMITVILLE" },
  "46071": { state: "IN", city: "THORNTOWN" },
  "46072": { state: "IN", city: "TIPTON" },
  "46074": { state: "IN", city: "WESTFIELD" },
  "46075": { state: "IN", city: "WHITESTOWN" },
  "46076": { state: "IN", city: "WINDFALL" },
  "46077": { state: "IN", city: "ZIONSVILLE" },
  "46082": { state: "IN", city: "CARMEL" },
  "46085": { state: "IN", city: "FISHERS" },
  "46102": { state: "IN", city: "ADVANCE" },
  "46103": { state: "IN", city: "AMO" },
  "46104": { state: "IN", city: "ARLINGTON" },
  "46105": { state: "IN", city: "BAINBRIDGE" },
  "46106": { state: "IN", city: "BARGERSVILLE" },
  "46107": { state: "IN", city: "BEECH GROVE" },
  "46110": { state: "IN", city: "BOGGSTOWN" },
  "46111": { state: "IN", city: "BROOKLYN" },
  "46112": { state: "IN", city: "BROWNSBURG" },
  "46113": { state: "IN", city: "CAMBY" },
  "46115": { state: "IN", city: "CARTHAGE" },
  "46117": { state: "IN", city: "CHARLOTTESVILLE" },
  "46118": { state: "IN", city: "CLAYTON" },
  "46120": { state: "IN", city: "CLOVERDALE" },
  "46121": { state: "IN", city: "COATESVILLE" },
  "46122": { state: "IN", city: "DANVILLE" },
  "46123": { state: "IN", city: "AVON" },
  "46124": { state: "IN", city: "EDINBURGH" },
  "46125": { state: "IN", city: "EMINENCE" },
  "46126": { state: "IN", city: "FAIRLAND" },
  "46127": { state: "IN", city: "FALMOUTH" },
  "46128": { state: "IN", city: "FILLMORE" },
  "46129": { state: "IN", city: "FINLY" },
  "46130": { state: "IN", city: "FOUNTAINTOWN" },
  "46131": { state: "IN", city: "FRANKLIN" },
  "46133": { state: "IN", city: "GLENWOOD" },
  "46135": { state: "IN", city: "GREENCASTLE" },
  "46140": { state: "IN", city: "GREENFIELD" },
  "46142": { state: "IN", city: "GREENWOOD" },
  "46143": { state: "IN", city: "GREENWOOD" },
  "46144": { state: "IN", city: "GWYNNEVILLE" },
  "46146": { state: "IN", city: "HOMER" },
  "46147": { state: "IN", city: "JAMESTOWN" },
  "46148": { state: "IN", city: "KNIGHTSTOWN" },
  "46149": { state: "IN", city: "LIZTON" },
  "46150": { state: "IN", city: "MANILLA" },
  "46151": { state: "IN", city: "MARTINSVILLE" },
  "46154": { state: "IN", city: "MAXWELL" },
  "46155": { state: "IN", city: "MAYS" },
  "46156": { state: "IN", city: "MILROY" },
  "46157": { state: "IN", city: "MONROVIA" },
  "46158": { state: "IN", city: "MOORESVILLE" },
  "46160": { state: "IN", city: "MORGANTOWN" },
  "46161": { state: "IN", city: "MORRISTOWN" },
  "46162": { state: "IN", city: "NEEDHAM" },
  "46163": { state: "IN", city: "NEW PALESTINE" },
  "46164": { state: "IN", city: "NINEVEH" },
  "46165": { state: "IN", city: "NORTH SALEM" },
  "46166": { state: "IN", city: "PARAGON" },
  "46167": { state: "IN", city: "PITTSBORO" },
  "46168": { state: "IN", city: "PLAINFIELD" },
  "46170": { state: "IN", city: "PUTNAMVILLE" },
  "46171": { state: "IN", city: "REELSVILLE" },
  "46172": { state: "IN", city: "ROACHDALE" },
  "46173": { state: "IN", city: "RUSHVILLE" },
  "46175": { state: "IN", city: "RUSSELLVILLE" },
  "46176": { state: "IN", city: "SHELBYVILLE" },
  "46180": { state: "IN", city: "STILESVILLE" },
  "46181": { state: "IN", city: "TRAFALGAR" },
  "46182": { state: "IN", city: "WALDRON" },
  "46183": { state: "IN", city: "WEST NEWTON" },
  "46184": { state: "IN", city: "WHITELAND" },
  "46186": { state: "IN", city: "WILKINSON" },
  "46201": { state: "IN", city: "INDIANAPOLIS" },
  "46202": { state: "IN", city: "INDIANAPOLIS" },
  "46203": { state: "IN", city: "INDIANAPOLIS" },
  "46204": { state: "IN", city: "INDIANAPOLIS" },
  "46205": { state: "IN", city: "INDIANAPOLIS" },
  "46206": { state: "IN", city: "INDIANAPOLIS" },
  "46207": { state: "IN", city: "INDIANAPOLIS" },
  "46208": { state: "IN", city: "INDIANAPOLIS" },
  "46209": { state: "IN", city: "INDIANAPOLIS" },
  "46213": { state: "IN", city: "INDIANAPOLIS" },
  "46214": { state: "IN", city: "INDIANAPOLIS" },
  "46216": { state: "IN", city: "INDIANAPOLIS" },
  "46217": { state: "IN", city: "INDIANAPOLIS" },
  "46218": { state: "IN", city: "INDIANAPOLIS" },
  "46219": { state: "IN", city: "INDIANAPOLIS" },
  "46220": { state: "IN", city: "INDIANAPOLIS" },
  "46221": { state: "IN", city: "INDIANAPOLIS" },
  "46222": { state: "IN", city: "INDIANAPOLIS" },
  "46224": { state: "IN", city: "INDIANAPOLIS" },
  "46225": { state: "IN", city: "INDIANAPOLIS" },
  "46226": { state: "IN", city: "INDIANAPOLIS" },
  "46227": { state: "IN", city: "INDIANAPOLIS" },
  "46228": { state: "IN", city: "INDIANAPOLIS" },
  "46229": { state: "IN", city: "INDIANAPOLIS" },
  "46230": { state: "IN", city: "INDIANAPOLIS" },
  "46231": { state: "IN", city: "INDIANAPOLIS" },
  "46234": { state: "IN", city: "INDIANAPOLIS" },
  "46235": { state: "IN", city: "INDIANAPOLIS" },
  "46236": { state: "IN", city: "INDIANAPOLIS" },
  "46237": { state: "IN", city: "INDIANAPOLIS" },
  "46239": { state: "IN", city: "INDIANAPOLIS" },
  "46240": { state: "IN", city: "INDIANAPOLIS" },
  "46241": { state: "IN", city: "INDIANAPOLIS" },
  "46242": { state: "IN", city: "INDIANAPOLIS" },
  "46244": { state: "IN", city: "INDIANAPOLIS" },
  "46247": { state: "IN", city: "INDIANAPOLIS" },
  "46249": { state: "IN", city: "INDIANAPOLIS" },
  "46250": { state: "IN", city: "INDIANAPOLIS" },
  "46251": { state: "IN", city: "INDIANAPOLIS" },
  "46253": { state: "IN", city: "INDIANAPOLIS" },
  "46254": { state: "IN", city: "INDIANAPOLIS" },
  "46255": { state: "IN", city: "INDIANAPOLIS" },
  "46256": { state: "IN", city: "INDIANAPOLIS" },
  "46259": { state: "IN", city: "INDIANAPOLIS" },
  "46260": { state: "IN", city: "INDIANAPOLIS" },
  "46262": { state: "IN", city: "INDIANAPOLIS" },
  "46268": { state: "IN", city: "INDIANAPOLIS" },
  "46277": { state: "IN", city: "INDIANAPOLIS" },
  "46278": { state: "IN", city: "INDIANAPOLIS" },
  "46280": { state: "IN", city: "INDIANAPOLIS" },
  "46282": { state: "IN", city: "INDIANAPOLIS" },
  "46283": { state: "IN", city: "INDIANAPOLIS" },
  "46285": { state: "IN", city: "INDIANAPOLIS" },
  "46288": { state: "IN", city: "INDIANAPOLIS" },
  "46290": { state: "IN", city: "INDIANAPOLIS" },
  "46298": { state: "IN", city: "INDIANAPOLIS" },
  "46301": { state: "IN", city: "BEVERLY SHORES" },
  "46302": { state: "IN", city: "BOONE GROVE" },
  "46303": { state: "IN", city: "CEDAR LAKE" },
  "46304": { state: "IN", city: "CHESTERTON" },
  "46307": { state: "IN", city: "CROWN POINT" },
  "46308": { state: "IN", city: "CROWN POINT" },
  "46310": { state: "IN", city: "DEMOTTE" },
  "46311": { state: "IN", city: "DYER" },
  "46312": { state: "IN", city: "EAST CHICAGO" },
  "46319": { state: "IN", city: "GRIFFITH" },
  "46320": { state: "IN", city: "HAMMOND" },
  "46321": { state: "IN", city: "MUNSTER" },
  "46322": { state: "IN", city: "HIGHLAND" },
  "46323": { state: "IN", city: "HAMMOND" },
  "46324": { state: "IN", city: "HAMMOND" },
  "46325": { state: "IN", city: "HAMMOND" },
  "46327": { state: "IN", city: "HAMMOND" },
  "46340": { state: "IN", city: "HANNA" },
  "46341": { state: "IN", city: "HEBRON" },
  "46342": { state: "IN", city: "HOBART" },
  "46345": { state: "IN", city: "KINGSBURY" },
  "46346": { state: "IN", city: "KINGSFORD HEIGHTS" },
  "46347": { state: "IN", city: "KOUTS" },
  "46348": { state: "IN", city: "LA CROSSE" },
  "46349": { state: "IN", city: "LAKE VILLAGE" },
  "46350": { state: "IN", city: "LA PORTE" },
  "46352": { state: "IN", city: "LA PORTE" },
  "46355": { state: "IN", city: "LEROY" },
  "46356": { state: "IN", city: "LOWELL" },
  "46360": { state: "IN", city: "MICHIGAN CITY" },
  "46361": { state: "IN", city: "MICHIGAN CITY" },
  "46365": { state: "IN", city: "MILL CREEK" },
  "46366": { state: "IN", city: "NORTH JUDSON" },
  "46368": { state: "IN", city: "PORTAGE" },
  "46371": { state: "IN", city: "ROLLING PRAIRIE" },
  "46372": { state: "IN", city: "ROSELAWN" },
  "46373": { state: "IN", city: "SAINT JOHN" },
  "46374": { state: "IN", city: "SAN PIERRE" },
  "46375": { state: "IN", city: "SCHERERVILLE" },
  "46376": { state: "IN", city: "SCHNEIDER" },
  "46377": { state: "IN", city: "SHELBY" },
  "46379": { state: "IN", city: "SUMAVA RESORTS" },
  "46380": { state: "IN", city: "TEFFT" },
  "46381": { state: "IN", city: "THAYER" },
  "46382": { state: "IN", city: "UNION MILLS" },
  "46383": { state: "IN", city: "VALPARAISO" },
  "46384": { state: "IN", city: "VALPARAISO" },
  "46385": { state: "IN", city: "VALPARAISO" },
  "46390": { state: "IN", city: "WANATAH" },
  "46391": { state: "IN", city: "WESTVILLE" },
  "46392": { state: "IN", city: "WHEATFIELD" },
  "46393": { state: "IN", city: "WHEELER" },
  "46394": { state: "IN", city: "WHITING" },
  "46401": { state: "IN", city: "GARY" },
  "46402": { state: "IN", city: "GARY" },
  "46403": { state: "IN", city: "GARY" },
  "46404": { state: "IN", city: "GARY" },
  "46405": { state: "IN", city: "LAKE STATION" },
  "46406": { state: "IN", city: "GARY" },
  "46407": { state: "IN", city: "GARY" },
  "46408": { state: "IN", city: "GARY" },
  "46409": { state: "IN", city: "GARY" },
  "46410": { state: "IN", city: "MERRILLVILLE" },
  "46411": { state: "IN", city: "MERRILLVILLE" },
  "46501": { state: "IN", city: "ARGOS" },
  "46502": { state: "IN", city: "ATWOOD" },
  "46504": { state: "IN", city: "BOURBON" },
  "46506": { state: "IN", city: "BREMEN" },
  "46507": { state: "IN", city: "BRISTOL" },
  "46508": { state: "IN", city: "BURKET" },
  "46510": { state: "IN", city: "CLAYPOOL" },
  "46511": { state: "IN", city: "CULVER" },
  "46513": { state: "IN", city: "DONALDSON" },
  "46514": { state: "IN", city: "ELKHART" },
  "46515": { state: "IN", city: "ELKHART" },
  "46516": { state: "IN", city: "ELKHART" },
  "46517": { state: "IN", city: "ELKHART" },
  "46524": { state: "IN", city: "ETNA GREEN" },
  "46526": { state: "IN", city: "GOSHEN" },
  "46527": { state: "IN", city: "GOSHEN" },
  "46528": { state: "IN", city: "GOSHEN" },
  "46530": { state: "IN", city: "GRANGER" },
  "46531": { state: "IN", city: "GROVERTOWN" },
  "46532": { state: "IN", city: "HAMLET" },
  "46534": { state: "IN", city: "KNOX" },
  "46536": { state: "IN", city: "LAKEVILLE" },
  "46537": { state: "IN", city: "LAPAZ" },
  "46538": { state: "IN", city: "LEESBURG" },
  "46539": { state: "IN", city: "MENTONE" },
  "46540": { state: "IN", city: "MIDDLEBURY" },
  "46542": { state: "IN", city: "MILFORD" },
  "46543": { state: "IN", city: "MILLERSBURG" },
  "46544": { state: "IN", city: "MISHAWAKA" },
  "46545": { state: "IN", city: "MISHAWAKA" },
  "46546": { state: "IN", city: "MISHAWAKA" },
  "46550": { state: "IN", city: "NAPPANEE" },
  "46552": { state: "IN", city: "NEW CARLISLE" },
  "46553": { state: "IN", city: "NEW PARIS" },
  "46554": { state: "IN", city: "NORTH LIBERTY" },
  "46555": { state: "IN", city: "NORTH WEBSTER" },
  "46556": { state: "IN", city: "NOTRE DAME" },
  "46561": { state: "IN", city: "OSCEOLA" },
  "46562": { state: "IN", city: "PIERCETON" },
  "46563": { state: "IN", city: "PLYMOUTH" },
  "46565": { state: "IN", city: "SHIPSHEWANA" },
  "46567": { state: "IN", city: "SYRACUSE" },
  "46570": { state: "IN", city: "TIPPECANOE" },
  "46571": { state: "IN", city: "TOPEKA" },
  "46572": { state: "IN", city: "TYNER" },
  "46573": { state: "IN", city: "WAKARUSA" },
  "46574": { state: "IN", city: "WALKERTON" },
  "46580": { state: "IN", city: "WARSAW" },
  "46581": { state: "IN", city: "WARSAW" },
  "46582": { state: "IN", city: "WARSAW" },
  "46590": { state: "IN", city: "WINONA LAKE" },
  "46595": { state: "IN", city: "WYATT" },
  "46601": { state: "IN", city: "SOUTH BEND" },
  "46613": { state: "IN", city: "SOUTH BEND" },
  "46614": { state: "IN", city: "SOUTH BEND" },
  "46615": { state: "IN", city: "SOUTH BEND" },
  "46616": { state: "IN", city: "SOUTH BEND" },
  "46617": { state: "IN", city: "SOUTH BEND" },
  "46619": { state: "IN", city: "SOUTH BEND" },
  "46624": { state: "IN", city: "SOUTH BEND" },
  "46626": { state: "IN", city: "SOUTH BEND" },
  "46628": { state: "IN", city: "SOUTH BEND" },
  "46634": { state: "IN", city: "SOUTH BEND" },
  "46635": { state: "IN", city: "SOUTH BEND" },
  "46637": { state: "IN", city: "SOUTH BEND" },
  "46660": { state: "IN", city: "SOUTH BEND" },
  "46680": { state: "IN", city: "SOUTH BEND" },
  "46699": { state: "IN", city: "SOUTH BEND" },
  "46701": { state: "IN", city: "ALBION" },
  "46702": { state: "IN", city: "ANDREWS" },
  "46703": { state: "IN", city: "ANGOLA" },
  "46704": { state: "IN", city: "ARCOLA" },
  "46705": { state: "IN", city: "ASHLEY" },
  "46706": { state: "IN", city: "AUBURN" },
  "46710": { state: "IN", city: "AVILLA" },
  "46711": { state: "IN", city: "BERNE" },
  "46713": { state: "IN", city: "BIPPUS" },
  "46714": { state: "IN", city: "BLUFFTON" },
  "46721": { state: "IN", city: "BUTLER" },
  "46723": { state: "IN", city: "CHURUBUSCO" },
  "46725": { state: "IN", city: "COLUMBIA CITY" },
  "46730": { state: "IN", city: "CORUNNA" },
  "46731": { state: "IN", city: "CRAIGVILLE" },
  "46732": { state: "IN", city: "CROMWELL" },
  "46733": { state: "IN", city: "DECATUR" },
  "46737": { state: "IN", city: "FREMONT" },
  "46738": { state: "IN", city: "GARRETT" },
  "46740": { state: "IN", city: "GENEVA" },
  "46741": { state: "IN", city: "GRABILL" },
  "46742": { state: "IN", city: "HAMILTON" },
  "46743": { state: "IN", city: "HARLAN" },
  "46745": { state: "IN", city: "HOAGLAND" },
  "46746": { state: "IN", city: "HOWE" },
  "46747": { state: "IN", city: "HUDSON" },
  "46748": { state: "IN", city: "HUNTERTOWN" },
  "46750": { state: "IN", city: "HUNTINGTON" },
  "46755": { state: "IN", city: "KENDALLVILLE" },
  "46759": { state: "IN", city: "KEYSTONE" },
  "46760": { state: "IN", city: "KIMMELL" },
  "46761": { state: "IN", city: "LAGRANGE" },
  "46763": { state: "IN", city: "LAOTTO" },
  "46764": { state: "IN", city: "LARWILL" },
  "46765": { state: "IN", city: "LEO" },
  "46766": { state: "IN", city: "LIBERTY CENTER" },
  "46767": { state: "IN", city: "LIGONIER" },
  "46769": { state: "IN", city: "LINN GROVE" },
  "46770": { state: "IN", city: "MARKLE" },
  "46771": { state: "IN", city: "MONGO" },
  "46772": { state: "IN", city: "MONROE" },
  "46773": { state: "IN", city: "MONROEVILLE" },
  "46774": { state: "IN", city: "NEW HAVEN" },
  "46776": { state: "IN", city: "ORLAND" },
  "46777": { state: "IN", city: "OSSIAN" },
  "46778": { state: "IN", city: "PETROLEUM" },
  "46779": { state: "IN", city: "PLEASANT LAKE" },
  "46780": { state: "IN", city: "PLEASANT MILLS" },
  "46781": { state: "IN", city: "PONETO" },
  "46782": { state: "IN", city: "PREBLE" },
  "46783": { state: "IN", city: "ROANOKE" },
  "46784": { state: "IN", city: "ROME CITY" },
  "46785": { state: "IN", city: "SAINT JOE" },
  "46786": { state: "IN", city: "SOUTH MILFORD" },
  "46787": { state: "IN", city: "SOUTH WHITLEY" },
  "46788": { state: "IN", city: "SPENCERVILLE" },
  "46789": { state: "IN", city: "STROH" },
  "46791": { state: "IN", city: "UNIONDALE" },
  "46792": { state: "IN", city: "WARREN" },
  "46793": { state: "IN", city: "WATERLOO" },
  "46794": { state: "IN", city: "WAWAKA" },
  "46795": { state: "IN", city: "WOLCOTTVILLE" },
  "46796": { state: "IN", city: "WOLFLAKE" },
  "46797": { state: "IN", city: "WOODBURN" },
  "46798": { state: "IN", city: "YODER" },
  "46799": { state: "IN", city: "ZANESVILLE" },
  "46801": { state: "IN", city: "FORT WAYNE" },
  "46802": { state: "IN", city: "FORT WAYNE" },
  "46803": { state: "IN", city: "FORT WAYNE" },
  "46804": { state: "IN", city: "FORT WAYNE" },
  "46805": { state: "IN", city: "FORT WAYNE" },
  "46806": { state: "IN", city: "FORT WAYNE" },
  "46807": { state: "IN", city: "FORT WAYNE" },
  "46808": { state: "IN", city: "FORT WAYNE" },
  "46809": { state: "IN", city: "FORT WAYNE" },
  "46814": { state: "IN", city: "FORT WAYNE" },
  "46815": { state: "IN", city: "FORT WAYNE" },
  "46816": { state: "IN", city: "FORT WAYNE" },
  "46818": { state: "IN", city: "FORT WAYNE" },
  "46819": { state: "IN", city: "FORT WAYNE" },
  "46825": { state: "IN", city: "FORT WAYNE" },
  "46835": { state: "IN", city: "FORT WAYNE" },
  "46845": { state: "IN", city: "FORT WAYNE" },
  "46850": { state: "IN", city: "FORT WAYNE" },
  "46851": { state: "IN", city: "FORT WAYNE" },
  "46852": { state: "IN", city: "FORT WAYNE" },
  "46853": { state: "IN", city: "FORT WAYNE" },
  "46854": { state: "IN", city: "FORT WAYNE" },
  "46855": { state: "IN", city: "FORT WAYNE" },
  "46856": { state: "IN", city: "FORT WAYNE" },
  "46857": { state: "IN", city: "FORT WAYNE" },
  "46858": { state: "IN", city: "FORT WAYNE" },
  "46859": { state: "IN", city: "FORT WAYNE" },
  "46860": { state: "IN", city: "FORT WAYNE" },
  "46861": { state: "IN", city: "FORT WAYNE" },
  "46862": { state: "IN", city: "FORT WAYNE" },
  "46863": { state: "IN", city: "FORT WAYNE" },
  "46864": { state: "IN", city: "FORT WAYNE" },
  "46865": { state: "IN", city: "FORT WAYNE" },
  "46866": { state: "IN", city: "FORT WAYNE" },
  "46867": { state: "IN", city: "FORT WAYNE" },
  "46868": { state: "IN", city: "FORT WAYNE" },
  "46869": { state: "IN", city: "FORT WAYNE" },
  "46885": { state: "IN", city: "FORT WAYNE" },
  "46895": { state: "IN", city: "FORT WAYNE" },
  "46896": { state: "IN", city: "FORT WAYNE" },
  "46897": { state: "IN", city: "FORT WAYNE" },
  "46898": { state: "IN", city: "FORT WAYNE" },
  "46899": { state: "IN", city: "FORT WAYNE" },
  "46901": { state: "IN", city: "KOKOMO" },
  "46902": { state: "IN", city: "KOKOMO" },
  "46903": { state: "IN", city: "KOKOMO" },
  "46904": { state: "IN", city: "KOKOMO" },
  "46910": { state: "IN", city: "AKRON" },
  "46911": { state: "IN", city: "AMBOY" },
  "46912": { state: "IN", city: "ATHENS" },
  "46913": { state: "IN", city: "BRINGHURST" },
  "46914": { state: "IN", city: "BUNKER HILL" },
  "46915": { state: "IN", city: "BURLINGTON" },
  "46916": { state: "IN", city: "BURROWS" },
  "46917": { state: "IN", city: "CAMDEN" },
  "46919": { state: "IN", city: "CONVERSE" },
  "46920": { state: "IN", city: "CUTLER" },
  "46921": { state: "IN", city: "DEEDSVILLE" },
  "46922": { state: "IN", city: "DELONG" },
  "46923": { state: "IN", city: "DELPHI" },
  "46926": { state: "IN", city: "DENVER" },
  "46928": { state: "IN", city: "FAIRMOUNT" },
  "46929": { state: "IN", city: "FLORA" },
  "46930": { state: "IN", city: "FOWLERTON" },
  "46931": { state: "IN", city: "FULTON" },
  "46932": { state: "IN", city: "GALVESTON" },
  "46933": { state: "IN", city: "GAS CITY" },
  "46935": { state: "IN", city: "GRASS CREEK" },
  "46936": { state: "IN", city: "GREENTOWN" },
  "46937": { state: "IN", city: "HEMLOCK" },
  "46938": { state: "IN", city: "JONESBORO" },
  "46939": { state: "IN", city: "KEWANNA" },
  "46940": { state: "IN", city: "LA FONTAINE" },
  "46941": { state: "IN", city: "LAGRO" },
  "46942": { state: "IN", city: "LAKE CICOTT" },
  "46943": { state: "IN", city: "LAKETON" },
  "46945": { state: "IN", city: "LEITERS FORD" },
  "46946": { state: "IN", city: "LIBERTY MILLS" },
  "46947": { state: "IN", city: "LOGANSPORT" },
  "46950": { state: "IN", city: "LUCERNE" },
  "46951": { state: "IN", city: "MACY" },
  "46952": { state: "IN", city: "MARION" },
  "46953": { state: "IN", city: "MARION" },
  "46957": { state: "IN", city: "MATTHEWS" },
  "46958": { state: "IN", city: "MEXICO" },
  "46959": { state: "IN", city: "MIAMI" },
  "46960": { state: "IN", city: "MONTEREY" },
  "46961": { state: "IN", city: "NEW WAVERLY" },
  "46962": { state: "IN", city: "NORTH MANCHESTER" },
  "46965": { state: "IN", city: "OAKFORD" },
  "46967": { state: "IN", city: "ONWARD" },
  "46968": { state: "IN", city: "ORA" },
  "46970": { state: "IN", city: "PERU" },
  "46971": { state: "IN", city: "GRISSOM ARB" },
  "46974": { state: "IN", city: "ROANN" },
  "46975": { state: "IN", city: "ROCHESTER" },
  "46977": { state: "IN", city: "ROCKFIELD" },
  "46978": { state: "IN", city: "ROYAL CENTER" },
  "46979": { state: "IN", city: "RUSSIAVILLE" },
  "46980": { state: "IN", city: "SERVIA" },
  "46982": { state: "IN", city: "SILVER LAKE" },
  "46984": { state: "IN", city: "SOMERSET" },
  "46985": { state: "IN", city: "STAR CITY" },
  "46986": { state: "IN", city: "SWAYZEE" },
  "46987": { state: "IN", city: "SWEETSER" },
  "46988": { state: "IN", city: "TWELVE MILE" },
  "46989": { state: "IN", city: "UPLAND" },
  "46990": { state: "IN", city: "URBANA" },
  "46991": { state: "IN", city: "VAN BUREN" },
  "46992": { state: "IN", city: "WABASH" },
  "46994": { state: "IN", city: "WALTON" },
  "46995": { state: "IN", city: "WEST MIDDLETON" },
  "46996": { state: "IN", city: "WINAMAC" },
  "46998": { state: "IN", city: "YOUNG AMERICA" },
  "47001": { state: "IN", city: "AURORA" },
  "47003": { state: "IN", city: "WEST COLLEGE CORNER" },
  "47006": { state: "IN", city: "BATESVILLE" },
  "47010": { state: "IN", city: "BATH" },
  "47011": { state: "IN", city: "BENNINGTON" },
  "47012": { state: "IN", city: "BROOKVILLE" },
  "47016": { state: "IN", city: "CEDAR GROVE" },
  "47017": { state: "IN", city: "CROSS PLAINS" },
  "47018": { state: "IN", city: "DILLSBORO" },
  "47019": { state: "IN", city: "EAST ENTERPRISE" },
  "47020": { state: "IN", city: "FLORENCE" },
  "47021": { state: "IN", city: "FRIENDSHIP" },
  "47022": { state: "IN", city: "GUILFORD" },
  "47023": { state: "IN", city: "HOLTON" },
  "47024": { state: "IN", city: "LAUREL" },
  "47025": { state: "IN", city: "LAWRENCEBURG" },
  "47030": { state: "IN", city: "METAMORA" },
  "47031": { state: "IN", city: "MILAN" },
  "47032": { state: "IN", city: "MOORES HILL" },
  "47033": { state: "IN", city: "MORRIS" },
  "47034": { state: "IN", city: "NAPOLEON" },
  "47035": { state: "IN", city: "NEW TRENTON" },
  "47036": { state: "IN", city: "OLDENBURG" },
  "47037": { state: "IN", city: "OSGOOD" },
  "47038": { state: "IN", city: "PATRIOT" },
  "47039": { state: "IN", city: "PIERCEVILLE" },
  "47040": { state: "IN", city: "RISING SUN" },
  "47041": { state: "IN", city: "SUNMAN" },
  "47042": { state: "IN", city: "VERSAILLES" },
  "47043": { state: "IN", city: "VEVAY" },
  "47060": { state: "IN", city: "WEST HARRISON" },
  "47102": { state: "IN", city: "AUSTIN" },
  "47104": { state: "IN", city: "BETHLEHEM" },
  "47106": { state: "IN", city: "BORDEN" },
  "47107": { state: "IN", city: "BRADFORD" },
  "47108": { state: "IN", city: "CAMPBELLSBURG" },
  "47110": { state: "IN", city: "CENTRAL" },
  "47111": { state: "IN", city: "CHARLESTOWN" },
  "47112": { state: "IN", city: "CORYDON" },
  "47114": { state: "IN", city: "CRANDALL" },
  "47115": { state: "IN", city: "DEPAUW" },
  "47116": { state: "IN", city: "ECKERTY" },
  "47117": { state: "IN", city: "ELIZABETH" },
  "47118": { state: "IN", city: "ENGLISH" },
  "47119": { state: "IN", city: "FLOYDS KNOBS" },
  "47120": { state: "IN", city: "FREDERICKSBURG" },
  "47122": { state: "IN", city: "GEORGETOWN" },
  "47123": { state: "IN", city: "GRANTSBURG" },
  "47124": { state: "IN", city: "GREENVILLE" },
  "47125": { state: "IN", city: "HARDINSBURG" },
  "47126": { state: "IN", city: "HENRYVILLE" },
  "47129": { state: "IN", city: "CLARKSVILLE" },
  "47130": { state: "IN", city: "JEFFERSONVILLE" },
  "47131": { state: "IN", city: "JEFFERSONVILLE" },
  "47132": { state: "IN", city: "JEFFERSONVILLE" },
  "47133": { state: "IN", city: "JEFFERSONVILLE" },
  "47134": { state: "IN", city: "JEFFERSONVILLE" },
  "47135": { state: "IN", city: "LACONIA" },
  "47136": { state: "IN", city: "LANESVILLE" },
  "47137": { state: "IN", city: "LEAVENWORTH" },
  "47138": { state: "IN", city: "LEXINGTON" },
  "47140": { state: "IN", city: "MARENGO" },
  "47141": { state: "IN", city: "MARYSVILLE" },
  "47142": { state: "IN", city: "MAUCKPORT" },
  "47143": { state: "IN", city: "MEMPHIS" },
  "47144": { state: "IN", city: "JEFFERSONVILLE" },
  "47145": { state: "IN", city: "MILLTOWN" },
  "47146": { state: "IN", city: "MOUNT SAINT FRANCIS" },
  "47147": { state: "IN", city: "NABB" },
  "47150": { state: "IN", city: "NEW ALBANY" },
  "47151": { state: "IN", city: "NEW ALBANY" },
  "47160": { state: "IN", city: "NEW MIDDLETOWN" },
  "47161": { state: "IN", city: "NEW SALISBURY" },
  "47162": { state: "IN", city: "NEW WASHINGTON" },
  "47163": { state: "IN", city: "OTISCO" },
  "47164": { state: "IN", city: "PALMYRA" },
  "47165": { state: "IN", city: "PEKIN" },
  "47166": { state: "IN", city: "RAMSEY" },
  "47167": { state: "IN", city: "SALEM" },
  "47170": { state: "IN", city: "SCOTTSBURG" },
  "47172": { state: "IN", city: "SELLERSBURG" },
  "47174": { state: "IN", city: "SULPHUR" },
  "47175": { state: "IN", city: "TASWELL" },
  "47177": { state: "IN", city: "UNDERWOOD" },
  "47190": { state: "IN", city: "JEFFERSONVILLE" },
  "47199": { state: "IN", city: "JEFFERSONVILLE" },
  "47201": { state: "IN", city: "COLUMBUS" },
  "47202": { state: "IN", city: "COLUMBUS" },
  "47203": { state: "IN", city: "COLUMBUS" },
  "47220": { state: "IN", city: "BROWNSTOWN" },
  "47223": { state: "IN", city: "BUTLERVILLE" },
  "47224": { state: "IN", city: "CANAAN" },
  "47225": { state: "IN", city: "CLARKSBURG" },
  "47226": { state: "IN", city: "CLIFFORD" },
  "47227": { state: "IN", city: "COMMISKEY" },
  "47228": { state: "IN", city: "CORTLAND" },
  "47229": { state: "IN", city: "CROTHERSVILLE" },
  "47230": { state: "IN", city: "DEPUTY" },
  "47231": { state: "IN", city: "DUPONT" },
  "47232": { state: "IN", city: "ELIZABETHTOWN" },
  "47234": { state: "IN", city: "FLAT ROCK" },
  "47235": { state: "IN", city: "FREETOWN" },
  "47236": { state: "IN", city: "GRAMMER" },
  "47240": { state: "IN", city: "GREENSBURG" },
  "47243": { state: "IN", city: "HANOVER" },
  "47244": { state: "IN", city: "HARTSVILLE" },
  "47245": { state: "IN", city: "HAYDEN" },
  "47246": { state: "IN", city: "HOPE" },
  "47247": { state: "IN", city: "JONESVILLE" },
  "47249": { state: "IN", city: "KURTZ" },
  "47250": { state: "IN", city: "MADISON" },
  "47260": { state: "IN", city: "MEDORA" },
  "47263": { state: "IN", city: "NEW POINT" },
  "47264": { state: "IN", city: "NORMAN" },
  "47265": { state: "IN", city: "NORTH VERNON" },
  "47270": { state: "IN", city: "PARIS CROSSING" },
  "47272": { state: "IN", city: "SAINT PAUL" },
  "47273": { state: "IN", city: "SCIPIO" },
  "47274": { state: "IN", city: "SEYMOUR" },
  "47280": { state: "IN", city: "TAYLORSVILLE" },
  "47281": { state: "IN", city: "VALLONIA" },
  "47282": { state: "IN", city: "VERNON" },
  "47283": { state: "IN", city: "WESTPORT" },
  "47302": { state: "IN", city: "MUNCIE" },
  "47303": { state: "IN", city: "MUNCIE" },
  "47304": { state: "IN", city: "MUNCIE" },
  "47305": { state: "IN", city: "MUNCIE" },
  "47306": { state: "IN", city: "MUNCIE" },
  "47307": { state: "IN", city: "MUNCIE" },
  "47308": { state: "IN", city: "MUNCIE" },
  "47320": { state: "IN", city: "ALBANY" },
  "47322": { state: "IN", city: "BENTONVILLE" },
  "47324": { state: "IN", city: "BOSTON" },
  "47325": { state: "IN", city: "BROWNSVILLE" },
  "47326": { state: "IN", city: "BRYANT" },
  "47327": { state: "IN", city: "CAMBRIDGE CITY" },
  "47330": { state: "IN", city: "CENTERVILLE" },
  "47331": { state: "IN", city: "CONNERSVILLE" },
  "47334": { state: "IN", city: "DALEVILLE" },
  "47335": { state: "IN", city: "DUBLIN" },
  "47336": { state: "IN", city: "DUNKIRK" },
  "47337": { state: "IN", city: "DUNREITH" },
  "47338": { state: "IN", city: "EATON" },
  "47339": { state: "IN", city: "ECONOMY" },
  "47340": { state: "IN", city: "FARMLAND" },
  "47341": { state: "IN", city: "FOUNTAIN CITY" },
  "47342": { state: "IN", city: "GASTON" },
  "47344": { state: "IN", city: "GREENSBORO" },
  "47345": { state: "IN", city: "GREENS FORK" },
  "47346": { state: "IN", city: "HAGERSTOWN" },
  "47348": { state: "IN", city: "HARTFORD CITY" },
  "47351": { state: "IN", city: "KENNARD" },
  "47352": { state: "IN", city: "LEWISVILLE" },
  "47353": { state: "IN", city: "LIBERTY" },
  "47354": { state: "IN", city: "LOSANTVILLE" },
  "47355": { state: "IN", city: "LYNN" },
  "47356": { state: "IN", city: "MIDDLETOWN" },
  "47357": { state: "IN", city: "MILTON" },
  "47358": { state: "IN", city: "MODOC" },
  "47359": { state: "IN", city: "MONTPELIER" },
  "47360": { state: "IN", city: "MOORELAND" },
  "47361": { state: "IN", city: "MOUNT SUMMIT" },
  "47362": { state: "IN", city: "NEW CASTLE" },
  "47366": { state: "IN", city: "NEW LISBON" },
  "47367": { state: "IN", city: "OAKVILLE" },
  "47368": { state: "IN", city: "PARKER CITY" },
  "47369": { state: "IN", city: "PENNVILLE" },
  "47370": { state: "IN", city: "PERSHING" },
  "47371": { state: "IN", city: "PORTLAND" },
  "47373": { state: "IN", city: "REDKEY" },
  "47374": { state: "IN", city: "RICHMOND" },
  "47375": { state: "IN", city: "RICHMOND" },
  "47380": { state: "IN", city: "RIDGEVILLE" },
  "47381": { state: "IN", city: "SALAMONIA" },
  "47382": { state: "IN", city: "SARATOGA" },
  "47383": { state: "IN", city: "SELMA" },
  "47384": { state: "IN", city: "SHIRLEY" },
  "47385": { state: "IN", city: "SPICELAND" },
  "47386": { state: "IN", city: "SPRINGPORT" },
  "47387": { state: "IN", city: "STRAUGHN" },
  "47388": { state: "IN", city: "SULPHUR SPRINGS" },
  "47390": { state: "IN", city: "UNION CITY" },
  "47392": { state: "IN", city: "WEBSTER" },
  "47393": { state: "IN", city: "WILLIAMSBURG" },
  "47394": { state: "IN", city: "WINCHESTER" },
  "47396": { state: "IN", city: "YORKTOWN" },
  "47401": { state: "IN", city: "BLOOMINGTON" },
  "47402": { state: "IN", city: "BLOOMINGTON" },
  "47403": { state: "IN", city: "BLOOMINGTON" },
  "47404": { state: "IN", city: "BLOOMINGTON" },
  "47405": { state: "IN", city: "BLOOMINGTON" },
  "47406": { state: "IN", city: "BLOOMINGTON" },
  "47407": { state: "IN", city: "BLOOMINGTON" },
  "47408": { state: "IN", city: "BLOOMINGTON" },
  "47420": { state: "IN", city: "AVOCA" },
  "47421": { state: "IN", city: "BEDFORD" },
  "47424": { state: "IN", city: "BLOOMFIELD" },
  "47426": { state: "IN", city: "CLEAR CREEK" },
  "47427": { state: "IN", city: "COAL CITY" },
  "47429": { state: "IN", city: "ELLETTSVILLE" },
  "47431": { state: "IN", city: "FREEDOM" },
  "47432": { state: "IN", city: "FRENCH LICK" },
  "47433": { state: "IN", city: "GOSPORT" },
  "47434": { state: "IN", city: "HARRODSBURG" },
  "47435": { state: "IN", city: "HELMSBURG" },
  "47436": { state: "IN", city: "HELTONVILLE" },
  "47437": { state: "IN", city: "HURON" },
  "47438": { state: "IN", city: "JASONVILLE" },
  "47441": { state: "IN", city: "LINTON" },
  "47443": { state: "IN", city: "LYONS" },
  "47445": { state: "IN", city: "MIDLAND" },
  "47446": { state: "IN", city: "MITCHELL" },
  "47448": { state: "IN", city: "NASHVILLE" },
  "47449": { state: "IN", city: "NEWBERRY" },
  "47451": { state: "IN", city: "OOLITIC" },
  "47452": { state: "IN", city: "ORLEANS" },
  "47453": { state: "IN", city: "OWENSBURG" },
  "47454": { state: "IN", city: "PAOLI" },
  "47455": { state: "IN", city: "PATRICKSBURG" },
  "47456": { state: "IN", city: "QUINCY" },
  "47457": { state: "IN", city: "SCOTLAND" },
  "47458": { state: "IN", city: "SMITHVILLE" },
  "47459": { state: "IN", city: "SOLSBERRY" },
  "47460": { state: "IN", city: "SPENCER" },
  "47462": { state: "IN", city: "SPRINGVILLE" },
  "47463": { state: "IN", city: "STANFORD" },
  "47464": { state: "IN", city: "STINESVILLE" },
  "47465": { state: "IN", city: "SWITZ CITY" },
  "47467": { state: "IN", city: "TUNNELTON" },
  "47468": { state: "IN", city: "UNIONVILLE" },
  "47469": { state: "IN", city: "WEST BADEN SPRINGS" },
  "47470": { state: "IN", city: "WILLIAMS" },
  "47471": { state: "IN", city: "WORTHINGTON" },
  "47501": { state: "IN", city: "WASHINGTON" },
  "47512": { state: "IN", city: "BICKNELL" },
  "47513": { state: "IN", city: "BIRDSEYE" },
  "47514": { state: "IN", city: "BRANCHVILLE" },
  "47515": { state: "IN", city: "BRISTOW" },
  "47516": { state: "IN", city: "BRUCEVILLE" },
  "47519": { state: "IN", city: "CANNELBURG" },
  "47520": { state: "IN", city: "CANNELTON" },
  "47521": { state: "IN", city: "CELESTINE" },
  "47522": { state: "IN", city: "CRANE" },
  "47523": { state: "IN", city: "DALE" },
  "47524": { state: "IN", city: "DECKER" },
  "47525": { state: "IN", city: "DERBY" },
  "47527": { state: "IN", city: "DUBOIS" },
  "47528": { state: "IN", city: "EDWARDSPORT" },
  "47529": { state: "IN", city: "ELNORA" },
  "47531": { state: "IN", city: "EVANSTON" },
  "47532": { state: "IN", city: "FERDINAND" },
  "47535": { state: "IN", city: "FREELANDVILLE" },
  "47536": { state: "IN", city: "FULDA" },
  "47537": { state: "IN", city: "GENTRYVILLE" },
  "47541": { state: "IN", city: "HOLLAND" },
  "47542": { state: "IN", city: "HUNTINGBURG" },
  "47545": { state: "IN", city: "IRELAND" },
  "47546": { state: "IN", city: "JASPER" },
  "47547": { state: "IN", city: "JASPER" },
  "47549": { state: "IN", city: "JASPER" },
  "47550": { state: "IN", city: "LAMAR" },
  "47551": { state: "IN", city: "LEOPOLD" },
  "47552": { state: "IN", city: "LINCOLN CITY" },
  "47553": { state: "IN", city: "LOOGOOTEE" },
  "47556": { state: "IN", city: "MARIAH HILL" },
  "47557": { state: "IN", city: "MONROE CITY" },
  "47558": { state: "IN", city: "MONTGOMERY" },
  "47561": { state: "IN", city: "OAKTOWN" },
  "47562": { state: "IN", city: "ODON" },
  "47564": { state: "IN", city: "OTWELL" },
  "47567": { state: "IN", city: "PETERSBURG" },
  "47568": { state: "IN", city: "PLAINVILLE" },
  "47573": { state: "IN", city: "RAGSDALE" },
  "47574": { state: "IN", city: "ROME" },
  "47575": { state: "IN", city: "SAINT ANTHONY" },
  "47576": { state: "IN", city: "SAINT CROIX" },
  "47577": { state: "IN", city: "SAINT MEINRAD" },
  "47578": { state: "IN", city: "SANDBORN" },
  "47579": { state: "IN", city: "SANTA CLAUS" },
  "47580": { state: "IN", city: "SCHNELLVILLE" },
  "47581": { state: "IN", city: "SHOALS" },
  "47584": { state: "IN", city: "SPURGEON" },
  "47585": { state: "IN", city: "STENDAL" },
  "47586": { state: "IN", city: "TELL CITY" },
  "47588": { state: "IN", city: "TROY" },
  "47590": { state: "IN", city: "VELPEN" },
  "47591": { state: "IN", city: "VINCENNES" },
  "47596": { state: "IN", city: "WESTPHALIA" },
  "47597": { state: "IN", city: "WHEATLAND" },
  "47598": { state: "IN", city: "WINSLOW" },
  "47601": { state: "IN", city: "BOONVILLE" },
  "47610": { state: "IN", city: "CHANDLER" },
  "47611": { state: "IN", city: "CHRISNEY" },
  "47612": { state: "IN", city: "CYNTHIANA" },
  "47613": { state: "IN", city: "ELBERFELD" },
  "47615": { state: "IN", city: "GRANDVIEW" },
  "47616": { state: "IN", city: "GRIFFIN" },
  "47617": { state: "IN", city: "HATFIELD" },
  "47618": { state: "IN", city: "INGLEFIELD" },
  "47619": { state: "IN", city: "LYNNVILLE" },
  "47620": { state: "IN", city: "MOUNT VERNON" },
  "47629": { state: "IN", city: "NEWBURGH" },
  "47630": { state: "IN", city: "NEWBURGH" },
  "47631": { state: "IN", city: "NEW HARMONY" },
  "47633": { state: "IN", city: "POSEYVILLE" },
  "47634": { state: "IN", city: "RICHLAND" },
  "47635": { state: "IN", city: "ROCKPORT" },
  "47637": { state: "IN", city: "TENNYSON" },
  "47638": { state: "IN", city: "WADESVILLE" },
  "47639": { state: "IN", city: "HAUBSTADT" },
  "47640": { state: "IN", city: "HAZLETON" },
  "47647": { state: "IN", city: "BUCKSKIN" },
  "47648": { state: "IN", city: "FORT BRANCH" },
  "47649": { state: "IN", city: "FRANCISCO" },
  "47654": { state: "IN", city: "MACKEY" },
  "47660": { state: "IN", city: "OAKLAND CITY" },
  "47665": { state: "IN", city: "OWENSVILLE" },
  "47666": { state: "IN", city: "PATOKA" },
  "47670": { state: "IN", city: "PRINCETON" },
  "47683": { state: "IN", city: "SOMERVILLE" },
  "47701": { state: "IN", city: "EVANSVILLE" },
  "47702": { state: "IN", city: "EVANSVILLE" },
  "47703": { state: "IN", city: "EVANSVILLE" },
  "47704": { state: "IN", city: "EVANSVILLE" },
  "47705": { state: "IN", city: "EVANSVILLE" },
  "47706": { state: "IN", city: "EVANSVILLE" },
  "47708": { state: "IN", city: "EVANSVILLE" },
  "47710": { state: "IN", city: "EVANSVILLE" },
  "47711": { state: "IN", city: "EVANSVILLE" },
  "47712": { state: "IN", city: "EVANSVILLE" },
  "47713": { state: "IN", city: "EVANSVILLE" },
  "47714": { state: "IN", city: "EVANSVILLE" },
  "47715": { state: "IN", city: "EVANSVILLE" },
  "47716": { state: "IN", city: "EVANSVILLE" },
  "47719": { state: "IN", city: "EVANSVILLE" },
  "47720": { state: "IN", city: "EVANSVILLE" },
  "47721": { state: "IN", city: "EVANSVILLE" },
  "47722": { state: "IN", city: "EVANSVILLE" },
  "47724": { state: "IN", city: "EVANSVILLE" },
  "47725": { state: "IN", city: "EVANSVILLE" },
  "47728": { state: "IN", city: "EVANSVILLE" },
  "47730": { state: "IN", city: "EVANSVILLE" },
  "47731": { state: "IN", city: "EVANSVILLE" },
  "47732": { state: "IN", city: "EVANSVILLE" },
  "47733": { state: "IN", city: "EVANSVILLE" },
  "47734": { state: "IN", city: "EVANSVILLE" },
  "47735": { state: "IN", city: "EVANSVILLE" },
  "47736": { state: "IN", city: "EVANSVILLE" },
  "47737": { state: "IN", city: "EVANSVILLE" },
  "47740": { state: "IN", city: "EVANSVILLE" },
  "47747": { state: "IN", city: "EVANSVILLE" },
  "47750": { state: "IN", city: "EVANSVILLE" },
  "47801": { state: "IN", city: "TERRE HAUTE" },
  "47802": { state: "IN", city: "TERRE HAUTE" },
  "47803": { state: "IN", city: "TERRE HAUTE" },
  "47804": { state: "IN", city: "TERRE HAUTE" },
  "47805": { state: "IN", city: "TERRE HAUTE" },
  "47807": { state: "IN", city: "TERRE HAUTE" },
  "47808": { state: "IN", city: "TERRE HAUTE" },
  "47809": { state: "IN", city: "TERRE HAUTE" },
  "47831": { state: "IN", city: "BLANFORD" },
  "47832": { state: "IN", city: "BLOOMINGDALE" },
  "47833": { state: "IN", city: "BOWLING GREEN" },
  "47834": { state: "IN", city: "BRAZIL" },
  "47836": { state: "IN", city: "BRIDGETON" },
  "47837": { state: "IN", city: "CARBON" },
  "47838": { state: "IN", city: "CARLISLE" },
  "47840": { state: "IN", city: "CENTERPOINT" },
  "47841": { state: "IN", city: "CLAY CITY" },
  "47842": { state: "IN", city: "CLINTON" },
  "47845": { state: "IN", city: "COALMONT" },
  "47846": { state: "IN", city: "CORY" },
  "47847": { state: "IN", city: "DANA" },
  "47848": { state: "IN", city: "DUGGER" },
  "47849": { state: "IN", city: "FAIRBANKS" },
  "47850": { state: "IN", city: "FARMERSBURG" },
  "47851": { state: "IN", city: "FONTANET" },
  "47852": { state: "IN", city: "GRAYSVILLE" },
  "47853": { state: "IN", city: "HARMONY" },
  "47854": { state: "IN", city: "HILLSDALE" },
  "47855": { state: "IN", city: "HYMERA" },
  "47857": { state: "IN", city: "KNIGHTSVILLE" },
  "47858": { state: "IN", city: "LEWIS" },
  "47859": { state: "IN", city: "MARSHALL" },
  "47860": { state: "IN", city: "MECCA" },
  "47861": { state: "IN", city: "MEROM" },
  "47862": { state: "IN", city: "MONTEZUMA" },
  "47863": { state: "IN", city: "NEW GOSHEN" },
  "47865": { state: "IN", city: "PAXTON" },
  "47866": { state: "IN", city: "PIMENTO" },
  "47868": { state: "IN", city: "POLAND" },
  "47869": { state: "IN", city: "PRAIRIE CREEK" },
  "47870": { state: "IN", city: "PRAIRIETON" },
  "47871": { state: "IN", city: "RILEY" },
  "47872": { state: "IN", city: "ROCKVILLE" },
  "47874": { state: "IN", city: "ROSEDALE" },
  "47875": { state: "IN", city: "SAINT BERNICE" },
  "47876": { state: "IN", city: "SAINT MARY OF THE WOODS" },
  "47878": { state: "IN", city: "SEELYVILLE" },
  "47879": { state: "IN", city: "SHELBURN" },
  "47880": { state: "IN", city: "SHEPARDSVILLE" },
  "47881": { state: "IN", city: "STAUNTON" },
  "47882": { state: "IN", city: "SULLIVAN" },
  "47884": { state: "IN", city: "UNIVERSAL" },
  "47885": { state: "IN", city: "WEST TERRE HAUTE" },
  "47901": { state: "IN", city: "LAFAYETTE" },
  "47902": { state: "IN", city: "LAFAYETTE" },
  "47903": { state: "IN", city: "LAFAYETTE" },
  "47904": { state: "IN", city: "LAFAYETTE" },
  "47905": { state: "IN", city: "LAFAYETTE" },
  "47906": { state: "IN", city: "WEST LAFAYETTE" },
  "47907": { state: "IN", city: "WEST LAFAYETTE" },
  "47909": { state: "IN", city: "LAFAYETTE" },
  "47916": { state: "IN", city: "ALAMO" },
  "47917": { state: "IN", city: "AMBIA" },
  "47918": { state: "IN", city: "ATTICA" },
  "47920": { state: "IN", city: "BATTLE GROUND" },
  "47921": { state: "IN", city: "BOSWELL" },
  "47922": { state: "IN", city: "BROOK" },
  "47923": { state: "IN", city: "BROOKSTON" },
  "47924": { state: "IN", city: "BUCK CREEK" },
  "47925": { state: "IN", city: "BUFFALO" },
  "47926": { state: "IN", city: "BURNETTSVILLE" },
  "47928": { state: "IN", city: "CAYUGA" },
  "47929": { state: "IN", city: "CHALMERS" },
  "47930": { state: "IN", city: "CLARKS HILL" },
  "47932": { state: "IN", city: "COVINGTON" },
  "47933": { state: "IN", city: "CRAWFORDSVILLE" },
  "47940": { state: "IN", city: "DARLINGTON" },
  "47941": { state: "IN", city: "DAYTON" },
  "47942": { state: "IN", city: "EARL PARK" },
  "47943": { state: "IN", city: "FAIR OAKS" },
  "47944": { state: "IN", city: "FOWLER" },
  "47946": { state: "IN", city: "FRANCESVILLE" },
  "47948": { state: "IN", city: "GOODLAND" },
  "47949": { state: "IN", city: "HILLSBORO" },
  "47950": { state: "IN", city: "IDAVILLE" },
  "47951": { state: "IN", city: "KENTLAND" },
  "47952": { state: "IN", city: "KINGMAN" },
  "47954": { state: "IN", city: "LADOGA" },
  "47955": { state: "IN", city: "LINDEN" },
  "47957": { state: "IN", city: "MEDARYVILLE" },
  "47958": { state: "IN", city: "MELLOTT" },
  "47959": { state: "IN", city: "MONON" },
  "47960": { state: "IN", city: "MONTICELLO" },
  "47962": { state: "IN", city: "MONTMORENCI" },
  "47963": { state: "IN", city: "MOROCCO" },
  "47964": { state: "IN", city: "MOUNT AYR" },
  "47965": { state: "IN", city: "NEW MARKET" },
  "47966": { state: "IN", city: "NEWPORT" },
  "47967": { state: "IN", city: "NEW RICHMOND" },
  "47968": { state: "IN", city: "NEW ROSS" },
  "47969": { state: "IN", city: "NEWTOWN" },
  "47970": { state: "IN", city: "OTTERBEIN" },
  "47971": { state: "IN", city: "OXFORD" },
  "47974": { state: "IN", city: "PERRYSVILLE" },
  "47975": { state: "IN", city: "PINE VILLAGE" },
  "47977": { state: "IN", city: "REMINGTON" },
  "47978": { state: "IN", city: "RENSSELAER" },
  "47980": { state: "IN", city: "REYNOLDS" },
  "47981": { state: "IN", city: "ROMNEY" },
  "47982": { state: "IN", city: "STATE LINE" },
  "47983": { state: "IN", city: "STOCKWELL" },
  "47986": { state: "IN", city: "TEMPLETON" },
  "47987": { state: "IN", city: "VEEDERSBURG" },
  "47988": { state: "IN", city: "WALLACE" },
  "47989": { state: "IN", city: "WAVELAND" },
  "47990": { state: "IN", city: "WAYNETOWN" },
  "47991": { state: "IN", city: "WEST LEBANON" },
  "47992": { state: "IN", city: "WESTPOINT" },
  "47993": { state: "IN", city: "WILLIAMSPORT" },
  "47994": { state: "IN", city: "WINGATE" },
  "47995": { state: "IN", city: "WOLCOTT" },
  "47996": { state: "IN", city: "WEST LAFAYETTE" },
  "47997": { state: "IN", city: "YEOMAN" },
  "48001": { state: "MI", city: "ALGONAC" },
  "48002": { state: "MI", city: "ALLENTON" },
  "48003": { state: "MI", city: "ALMONT" },
  "48004": { state: "MI", city: "ANCHORVILLE" },
  "48005": { state: "MI", city: "ARMADA" },
  "48006": { state: "MI", city: "AVOCA" },
  "48007": { state: "MI", city: "TROY" },
  "48009": { state: "MI", city: "BIRMINGHAM" },
  "48012": { state: "MI", city: "BIRMINGHAM" },
  "48014": { state: "MI", city: "CAPAC" },
  "48015": { state: "MI", city: "CENTER LINE" },
  "48017": { state: "MI", city: "CLAWSON" },
  "48021": { state: "MI", city: "EASTPOINTE" },
  "48022": { state: "MI", city: "EMMETT" },
  "48023": { state: "MI", city: "FAIR HAVEN" },
  "48025": { state: "MI", city: "FRANKLIN" },
  "48026": { state: "MI", city: "FRASER" },
  "48027": { state: "MI", city: "GOODELLS" },
  "48028": { state: "MI", city: "HARSENS ISLAND" },
  "48030": { state: "MI", city: "HAZEL PARK" },
  "48032": { state: "MI", city: "JEDDO" },
  "48033": { state: "MI", city: "SOUTHFIELD" },
  "48034": { state: "MI", city: "SOUTHFIELD" },
  "48035": { state: "MI", city: "CLINTON TOWNSHIP" },
  "48036": { state: "MI", city: "CLINTON TOWNSHIP" },
  "48037": { state: "MI", city: "SOUTHFIELD" },
  "48038": { state: "MI", city: "CLINTON TOWNSHIP" },
  "48039": { state: "MI", city: "MARINE CITY" },
  "48040": { state: "MI", city: "MARYSVILLE" },
  "48041": { state: "MI", city: "MEMPHIS" },
  "48042": { state: "MI", city: "MACOMB" },
  "48043": { state: "MI", city: "MOUNT CLEMENS" },
  "48044": { state: "MI", city: "MACOMB" },
  "48045": { state: "MI", city: "HARRISON TOWNSHIP" },
  "48046": { state: "MI", city: "MOUNT CLEMENS" },
  "48047": { state: "MI", city: "NEW BALTIMORE" },
  "48048": { state: "MI", city: "NEW HAVEN" },
  "48049": { state: "MI", city: "NORTH STREET" },
  "48050": { state: "MI", city: "NEW HAVEN" },
  "48051": { state: "MI", city: "NEW BALTIMORE" },
  "48054": { state: "MI", city: "EAST CHINA" },
  "48059": { state: "MI", city: "FORT GRATIOT" },
  "48060": { state: "MI", city: "PORT HURON" },
  "48061": { state: "MI", city: "PORT HURON" },
  "48062": { state: "MI", city: "RICHMOND" },
  "48063": { state: "MI", city: "COLUMBUS" },
  "48064": { state: "MI", city: "CASCO" },
  "48065": { state: "MI", city: "ROMEO" },
  "48066": { state: "MI", city: "ROSEVILLE" },
  "48067": { state: "MI", city: "ROYAL OAK" },
  "48068": { state: "MI", city: "ROYAL OAK" },
  "48069": { state: "MI", city: "PLEASANT RIDGE" },
  "48070": { state: "MI", city: "HUNTINGTON WOODS" },
  "48071": { state: "MI", city: "MADISON HEIGHTS" },
  "48072": { state: "MI", city: "BERKLEY" },
  "48073": { state: "MI", city: "ROYAL OAK" },
  "48074": { state: "MI", city: "SMITHS CREEK" },
  "48075": { state: "MI", city: "SOUTHFIELD" },
  "48076": { state: "MI", city: "SOUTHFIELD" },
  "48079": { state: "MI", city: "SAINT CLAIR" },
  "48080": { state: "MI", city: "SAINT CLAIR SHORES" },
  "48081": { state: "MI", city: "SAINT CLAIR SHORES" },
  "48082": { state: "MI", city: "SAINT CLAIR SHORES" },
  "48083": { state: "MI", city: "TROY" },
  "48084": { state: "MI", city: "TROY" },
  "48085": { state: "MI", city: "TROY" },
  "48086": { state: "MI", city: "SOUTHFIELD" },
  "48088": { state: "MI", city: "WARREN" },
  "48089": { state: "MI", city: "WARREN" },
  "48090": { state: "MI", city: "WARREN" },
  "48091": { state: "MI", city: "WARREN" },
  "48092": { state: "MI", city: "WARREN" },
  "48093": { state: "MI", city: "WARREN" },
  "48094": { state: "MI", city: "WASHINGTON" },
  "48095": { state: "MI", city: "WASHINGTON" },
  "48096": { state: "MI", city: "RAY" },
  "48097": { state: "MI", city: "YALE" },
  "48098": { state: "MI", city: "TROY" },
  "48099": { state: "MI", city: "TROY" },
  "48101": { state: "MI", city: "ALLEN PARK" },
  "48103": { state: "MI", city: "ANN ARBOR" },
  "48104": { state: "MI", city: "ANN ARBOR" },
  "48105": { state: "MI", city: "ANN ARBOR" },
  "48106": { state: "MI", city: "ANN ARBOR" },
  "48107": { state: "MI", city: "ANN ARBOR" },
  "48108": { state: "MI", city: "ANN ARBOR" },
  "48109": { state: "MI", city: "ANN ARBOR" },
  "48110": { state: "MI", city: "AZALIA" },
  "48111": { state: "MI", city: "BELLEVILLE" },
  "48112": { state: "MI", city: "BELLEVILLE" },
  "48113": { state: "MI", city: "ANN ARBOR" },
  "48114": { state: "MI", city: "BRIGHTON" },
  "48115": { state: "MI", city: "BRIDGEWATER" },
  "48116": { state: "MI", city: "BRIGHTON" },
  "48117": { state: "MI", city: "CARLETON" },
  "48118": { state: "MI", city: "CHELSEA" },
  "48120": { state: "MI", city: "DEARBORN" },
  "48121": { state: "MI", city: "DEARBORN" },
  "48122": { state: "MI", city: "MELVINDALE" },
  "48123": { state: "MI", city: "DEARBORN" },
  "48124": { state: "MI", city: "DEARBORN" },
  "48125": { state: "MI", city: "DEARBORN HEIGHTS" },
  "48126": { state: "MI", city: "DEARBORN" },
  "48127": { state: "MI", city: "DEARBORN HEIGHTS" },
  "48128": { state: "MI", city: "DEARBORN" },
  "48130": { state: "MI", city: "DEXTER" },
  "48131": { state: "MI", city: "DUNDEE" },
  "48133": { state: "MI", city: "ERIE" },
  "48134": { state: "MI", city: "FLAT ROCK" },
  "48135": { state: "MI", city: "GARDEN CITY" },
  "48136": { state: "MI", city: "GARDEN CITY" },
  "48137": { state: "MI", city: "GREGORY" },
  "48138": { state: "MI", city: "GROSSE ILE" },
  "48139": { state: "MI", city: "HAMBURG" },
  "48140": { state: "MI", city: "IDA" },
  "48141": { state: "MI", city: "INKSTER" },
  "48143": { state: "MI", city: "LAKELAND" },
  "48144": { state: "MI", city: "LAMBERTVILLE" },
  "48145": { state: "MI", city: "LA SALLE" },
  "48146": { state: "MI", city: "LINCOLN PARK" },
  "48150": { state: "MI", city: "LIVONIA" },
  "48151": { state: "MI", city: "LIVONIA" },
  "48152": { state: "MI", city: "LIVONIA" },
  "48153": { state: "MI", city: "LIVONIA" },
  "48154": { state: "MI", city: "LIVONIA" },
  "48157": { state: "MI", city: "LUNA PIER" },
  "48158": { state: "MI", city: "MANCHESTER" },
  "48159": { state: "MI", city: "MAYBEE" },
  "48160": { state: "MI", city: "MILAN" },
  "48161": { state: "MI", city: "MONROE" },
  "48162": { state: "MI", city: "MONROE" },
  "48164": { state: "MI", city: "NEW BOSTON" },
  "48165": { state: "MI", city: "NEW HUDSON" },
  "48166": { state: "MI", city: "NEWPORT" },
  "48167": { state: "MI", city: "NORTHVILLE" },
  "48168": { state: "MI", city: "NORTHVILLE" },
  "48169": { state: "MI", city: "PINCKNEY" },
  "48170": { state: "MI", city: "PLYMOUTH" },
  "48173": { state: "MI", city: "ROCKWOOD" },
  "48174": { state: "MI", city: "ROMULUS" },
  "48175": { state: "MI", city: "SALEM" },
  "48176": { state: "MI", city: "SALINE" },
  "48177": { state: "MI", city: "SAMARIA" },
  "48178": { state: "MI", city: "SOUTH LYON" },
  "48179": { state: "MI", city: "SOUTH ROCKWOOD" },
  "48180": { state: "MI", city: "TAYLOR" },
  "48182": { state: "MI", city: "TEMPERANCE" },
  "48183": { state: "MI", city: "TRENTON" },
  "48184": { state: "MI", city: "WAYNE" },
  "48185": { state: "MI", city: "WESTLAND" },
  "48186": { state: "MI", city: "WESTLAND" },
  "48187": { state: "MI", city: "CANTON" },
  "48188": { state: "MI", city: "CANTON" },
  "48189": { state: "MI", city: "WHITMORE LAKE" },
  "48190": { state: "MI", city: "WHITTAKER" },
  "48191": { state: "MI", city: "WILLIS" },
  "48192": { state: "MI", city: "WYANDOTTE" },
  "48193": { state: "MI", city: "RIVERVIEW" },
  "48195": { state: "MI", city: "SOUTHGATE" },
  "48197": { state: "MI", city: "YPSILANTI" },
  "48198": { state: "MI", city: "YPSILANTI" },
  "48201": { state: "MI", city: "DETROIT" },
  "48202": { state: "MI", city: "DETROIT" },
  "48203": { state: "MI", city: "HIGHLAND PARK" },
  "48204": { state: "MI", city: "DETROIT" },
  "48205": { state: "MI", city: "DETROIT" },
  "48206": { state: "MI", city: "DETROIT" },
  "48207": { state: "MI", city: "DETROIT" },
  "48208": { state: "MI", city: "DETROIT" },
  "48209": { state: "MI", city: "DETROIT" },
  "48210": { state: "MI", city: "DETROIT" },
  "48211": { state: "MI", city: "DETROIT" },
  "48212": { state: "MI", city: "HAMTRAMCK" },
  "48213": { state: "MI", city: "DETROIT" },
  "48214": { state: "MI", city: "DETROIT" },
  "48215": { state: "MI", city: "DETROIT" },
  "48216": { state: "MI", city: "DETROIT" },
  "48217": { state: "MI", city: "DETROIT" },
  "48218": { state: "MI", city: "RIVER ROUGE" },
  "48219": { state: "MI", city: "DETROIT" },
  "48220": { state: "MI", city: "FERNDALE" },
  "48221": { state: "MI", city: "DETROIT" },
  "48222": { state: "MI", city: "DETROIT" },
  "48223": { state: "MI", city: "DETROIT" },
  "48224": { state: "MI", city: "DETROIT" },
  "48225": { state: "MI", city: "HARPER WOODS" },
  "48226": { state: "MI", city: "DETROIT" },
  "48227": { state: "MI", city: "DETROIT" },
  "48228": { state: "MI", city: "DETROIT" },
  "48229": { state: "MI", city: "ECORSE" },
  "48230": { state: "MI", city: "GROSSE POINTE" },
  "48231": { state: "MI", city: "DETROIT" },
  "48232": { state: "MI", city: "DETROIT" },
  "48233": { state: "MI", city: "DETROIT" },
  "48234": { state: "MI", city: "DETROIT" },
  "48235": { state: "MI", city: "DETROIT" },
  "48236": { state: "MI", city: "GROSSE POINTE" },
  "48237": { state: "MI", city: "OAK PARK" },
  "48238": { state: "MI", city: "DETROIT" },
  "48239": { state: "MI", city: "REDFORD" },
  "48240": { state: "MI", city: "REDFORD" },
  "48242": { state: "MI", city: "DETROIT" },
  "48243": { state: "MI", city: "DETROIT" },
  "48244": { state: "MI", city: "DETROIT" },
  "48255": { state: "MI", city: "DETROIT" },
  "48260": { state: "MI", city: "DETROIT" },
  "48264": { state: "MI", city: "DETROIT" },
  "48265": { state: "MI", city: "DETROIT" },
  "48266": { state: "MI", city: "DETROIT" },
  "48267": { state: "MI", city: "DETROIT" },
  "48268": { state: "MI", city: "DETROIT" },
  "48269": { state: "MI", city: "DETROIT" },
  "48272": { state: "MI", city: "DETROIT" },
  "48275": { state: "MI", city: "DETROIT" },
  "48277": { state: "MI", city: "DETROIT" },
  "48278": { state: "MI", city: "DETROIT" },
  "48279": { state: "MI", city: "DETROIT" },
  "48288": { state: "MI", city: "DETROIT" },
  "48301": { state: "MI", city: "BLOOMFIELD HILLS" },
  "48302": { state: "MI", city: "BLOOMFIELD HILLS" },
  "48303": { state: "MI", city: "BLOOMFIELD HILLS" },
  "48304": { state: "MI", city: "BLOOMFIELD HILLS" },
  "48306": { state: "MI", city: "ROCHESTER" },
  "48307": { state: "MI", city: "ROCHESTER" },
  "48308": { state: "MI", city: "ROCHESTER" },
  "48309": { state: "MI", city: "ROCHESTER" },
  "48310": { state: "MI", city: "STERLING HEIGHTS" },
  "48311": { state: "MI", city: "STERLING HEIGHTS" },
  "48312": { state: "MI", city: "STERLING HEIGHTS" },
  "48313": { state: "MI", city: "STERLING HEIGHTS" },
  "48314": { state: "MI", city: "STERLING HEIGHTS" },
  "48315": { state: "MI", city: "UTICA" },
  "48316": { state: "MI", city: "UTICA" },
  "48317": { state: "MI", city: "UTICA" },
  "48318": { state: "MI", city: "UTICA" },
  "48320": { state: "MI", city: "KEEGO HARBOR" },
  "48321": { state: "MI", city: "AUBURN HILLS" },
  "48322": { state: "MI", city: "WEST BLOOMFIELD" },
  "48323": { state: "MI", city: "WEST BLOOMFIELD" },
  "48324": { state: "MI", city: "WEST BLOOMFIELD" },
  "48325": { state: "MI", city: "WEST BLOOMFIELD" },
  "48326": { state: "MI", city: "AUBURN HILLS" },
  "48327": { state: "MI", city: "WATERFORD" },
  "48328": { state: "MI", city: "WATERFORD" },
  "48329": { state: "MI", city: "WATERFORD" },
  "48330": { state: "MI", city: "DRAYTON PLAINS" },
  "48331": { state: "MI", city: "FARMINGTON" },
  "48332": { state: "MI", city: "FARMINGTON" },
  "48333": { state: "MI", city: "FARMINGTON" },
  "48334": { state: "MI", city: "FARMINGTON" },
  "48335": { state: "MI", city: "FARMINGTON" },
  "48336": { state: "MI", city: "FARMINGTON" },
  "48340": { state: "MI", city: "PONTIAC" },
  "48341": { state: "MI", city: "PONTIAC" },
  "48342": { state: "MI", city: "PONTIAC" },
  "48343": { state: "MI", city: "PONTIAC" },
  "48346": { state: "MI", city: "CLARKSTON" },
  "48347": { state: "MI", city: "CLARKSTON" },
  "48348": { state: "MI", city: "CLARKSTON" },
  "48350": { state: "MI", city: "DAVISBURG" },
  "48353": { state: "MI", city: "HARTLAND" },
  "48356": { state: "MI", city: "HIGHLAND" },
  "48357": { state: "MI", city: "HIGHLAND" },
  "48359": { state: "MI", city: "LAKE ORION" },
  "48360": { state: "MI", city: "LAKE ORION" },
  "48361": { state: "MI", city: "LAKE ORION" },
  "48362": { state: "MI", city: "LAKE ORION" },
  "48363": { state: "MI", city: "OAKLAND" },
  "48366": { state: "MI", city: "LAKEVILLE" },
  "48367": { state: "MI", city: "LEONARD" },
  "48370": { state: "MI", city: "OXFORD" },
  "48371": { state: "MI", city: "OXFORD" },
  "48374": { state: "MI", city: "NOVI" },
  "48375": { state: "MI", city: "NOVI" },
  "48376": { state: "MI", city: "NOVI" },
  "48377": { state: "MI", city: "NOVI" },
  "48380": { state: "MI", city: "MILFORD" },
  "48381": { state: "MI", city: "MILFORD" },
  "48382": { state: "MI", city: "COMMERCE TOWNSHIP" },
  "48383": { state: "MI", city: "WHITE LAKE" },
  "48386": { state: "MI", city: "WHITE LAKE" },
  "48387": { state: "MI", city: "UNION LAKE" },
  "48390": { state: "MI", city: "WALLED LAKE" },
  "48393": { state: "MI", city: "WIXOM" },
  "48397": { state: "MI", city: "WARREN" },
  "48401": { state: "MI", city: "APPLEGATE" },
  "48410": { state: "MI", city: "ARGYLE" },
  "48411": { state: "MI", city: "ATLAS" },
  "48412": { state: "MI", city: "ATTICA" },
  "48413": { state: "MI", city: "BAD AXE" },
  "48414": { state: "MI", city: "BANCROFT" },
  "48415": { state: "MI", city: "BIRCH RUN" },
  "48416": { state: "MI", city: "BROWN CITY" },
  "48417": { state: "MI", city: "BURT" },
  "48418": { state: "MI", city: "BYRON" },
  "48419": { state: "MI", city: "CARSONVILLE" },
  "48420": { state: "MI", city: "CLIO" },
  "48421": { state: "MI", city: "COLUMBIAVILLE" },
  "48422": { state: "MI", city: "CROSWELL" },
  "48423": { state: "MI", city: "DAVISON" },
  "48426": { state: "MI", city: "DECKER" },
  "48427": { state: "MI", city: "DECKERVILLE" },
  "48428": { state: "MI", city: "DRYDEN" },
  "48429": { state: "MI", city: "DURAND" },
  "48430": { state: "MI", city: "FENTON" },
  "48432": { state: "MI", city: "FILION" },
  "48433": { state: "MI", city: "FLUSHING" },
  "48434": { state: "MI", city: "FORESTVILLE" },
  "48435": { state: "MI", city: "FOSTORIA" },
  "48436": { state: "MI", city: "GAINES" },
  "48437": { state: "MI", city: "GENESEE" },
  "48438": { state: "MI", city: "GOODRICH" },
  "48439": { state: "MI", city: "GRAND BLANC" },
  "48440": { state: "MI", city: "HADLEY" },
  "48441": { state: "MI", city: "HARBOR BEACH" },
  "48442": { state: "MI", city: "HOLLY" },
  "48444": { state: "MI", city: "IMLAY CITY" },
  "48445": { state: "MI", city: "KINDE" },
  "48446": { state: "MI", city: "LAPEER" },
  "48449": { state: "MI", city: "LENNON" },
  "48450": { state: "MI", city: "LEXINGTON" },
  "48451": { state: "MI", city: "LINDEN" },
  "48453": { state: "MI", city: "MARLETTE" },
  "48454": { state: "MI", city: "MELVIN" },
  "48455": { state: "MI", city: "METAMORA" },
  "48456": { state: "MI", city: "MINDEN CITY" },
  "48457": { state: "MI", city: "MONTROSE" },
  "48458": { state: "MI", city: "MOUNT MORRIS" },
  "48460": { state: "MI", city: "NEW LOTHROP" },
  "48461": { state: "MI", city: "NORTH BRANCH" },
  "48462": { state: "MI", city: "ORTONVILLE" },
  "48463": { state: "MI", city: "OTISVILLE" },
  "48464": { state: "MI", city: "OTTER LAKE" },
  "48465": { state: "MI", city: "PALMS" },
  "48466": { state: "MI", city: "PECK" },
  "48467": { state: "MI", city: "PORT AUSTIN" },
  "48468": { state: "MI", city: "PORT HOPE" },
  "48469": { state: "MI", city: "PORT SANILAC" },
  "48470": { state: "MI", city: "RUTH" },
  "48471": { state: "MI", city: "SANDUSKY" },
  "48472": { state: "MI", city: "SNOVER" },
  "48473": { state: "MI", city: "SWARTZ CREEK" },
  "48475": { state: "MI", city: "UBLY" },
  "48476": { state: "MI", city: "VERNON" },
  "48480": { state: "MI", city: "GRAND BLANC" },
  "48501": { state: "MI", city: "FLINT" },
  "48502": { state: "MI", city: "FLINT" },
  "48503": { state: "MI", city: "FLINT" },
  "48504": { state: "MI", city: "FLINT" },
  "48505": { state: "MI", city: "FLINT" },
  "48506": { state: "MI", city: "FLINT" },
  "48507": { state: "MI", city: "FLINT" },
  "48509": { state: "MI", city: "BURTON" },
  "48519": { state: "MI", city: "BURTON" },
  "48529": { state: "MI", city: "BURTON" },
  "48531": { state: "MI", city: "FLINT" },
  "48532": { state: "MI", city: "FLINT" },
  "48550": { state: "MI", city: "FLINT" },
  "48551": { state: "MI", city: "FLINT" },
  "48552": { state: "MI", city: "FLINT" },
  "48553": { state: "MI", city: "FLINT" },
  "48554": { state: "MI", city: "FLINT" },
  "48555": { state: "MI", city: "FLINT" },
  "48556": { state: "MI", city: "FLINT" },
  "48557": { state: "MI", city: "FLINT" },
  "48601": { state: "MI", city: "SAGINAW" },
  "48602": { state: "MI", city: "SAGINAW" },
  "48603": { state: "MI", city: "SAGINAW" },
  "48604": { state: "MI", city: "SAGINAW" },
  "48605": { state: "MI", city: "SAGINAW" },
  "48606": { state: "MI", city: "SAGINAW" },
  "48607": { state: "MI", city: "SAGINAW" },
  "48608": { state: "MI", city: "SAGINAW" },
  "48609": { state: "MI", city: "SAGINAW" },
  "48610": { state: "MI", city: "ALGER" },
  "48611": { state: "MI", city: "AUBURN" },
  "48612": { state: "MI", city: "BEAVERTON" },
  "48613": { state: "MI", city: "BENTLEY" },
  "48614": { state: "MI", city: "BRANT" },
  "48615": { state: "MI", city: "BRECKENRIDGE" },
  "48616": { state: "MI", city: "CHESANING" },
  "48617": { state: "MI", city: "CLARE" },
  "48618": { state: "MI", city: "COLEMAN" },
  "48619": { state: "MI", city: "COMINS" },
  "48620": { state: "MI", city: "EDENVILLE" },
  "48621": { state: "MI", city: "FAIRVIEW" },
  "48622": { state: "MI", city: "FARWELL" },
  "48623": { state: "MI", city: "FREELAND" },
  "48624": { state: "MI", city: "GLADWIN" },
  "48625": { state: "MI", city: "HARRISON" },
  "48626": { state: "MI", city: "HEMLOCK" },
  "48627": { state: "MI", city: "HIGGINS LAKE" },
  "48628": { state: "MI", city: "HOPE" },
  "48629": { state: "MI", city: "HOUGHTON LAKE" },
  "48630": { state: "MI", city: "HOUGHTON LAKE HEIGHTS" },
  "48631": { state: "MI", city: "KAWKAWLIN" },
  "48632": { state: "MI", city: "LAKE" },
  "48633": { state: "MI", city: "LAKE GEORGE" },
  "48634": { state: "MI", city: "LINWOOD" },
  "48635": { state: "MI", city: "LUPTON" },
  "48636": { state: "MI", city: "LUZERNE" },
  "48637": { state: "MI", city: "MERRILL" },
  "48638": { state: "MI", city: "SAGINAW" },
  "48640": { state: "MI", city: "MIDLAND" },
  "48641": { state: "MI", city: "MIDLAND" },
  "48642": { state: "MI", city: "MIDLAND" },
  "48647": { state: "MI", city: "MIO" },
  "48649": { state: "MI", city: "OAKLEY" },
  "48650": { state: "MI", city: "PINCONNING" },
  "48651": { state: "MI", city: "PRUDENVILLE" },
  "48652": { state: "MI", city: "RHODES" },
  "48653": { state: "MI", city: "ROSCOMMON" },
  "48654": { state: "MI", city: "ROSE CITY" },
  "48655": { state: "MI", city: "SAINT CHARLES" },
  "48656": { state: "MI", city: "SAINT HELEN" },
  "48657": { state: "MI", city: "SANFORD" },
  "48658": { state: "MI", city: "STANDISH" },
  "48659": { state: "MI", city: "STERLING" },
  "48661": { state: "MI", city: "WEST BRANCH" },
  "48662": { state: "MI", city: "WHEELER" },
  "48663": { state: "MI", city: "SAGINAW" },
  "48667": { state: "MI", city: "MIDLAND" },
  "48670": { state: "MI", city: "MIDLAND" },
  "48674": { state: "MI", city: "MIDLAND" },
  "48686": { state: "MI", city: "MIDLAND" },
  "48701": { state: "MI", city: "AKRON" },
  "48703": { state: "MI", city: "AU GRES" },
  "48705": { state: "MI", city: "BARTON CITY" },
  "48706": { state: "MI", city: "BAY CITY" },
  "48707": { state: "MI", city: "BAY CITY" },
  "48708": { state: "MI", city: "BAY CITY" },
  "48710": { state: "MI", city: "UNIVERSITY CENTER" },
  "48720": { state: "MI", city: "BAY PORT" },
  "48721": { state: "MI", city: "BLACK RIVER" },
  "48722": { state: "MI", city: "BRIDGEPORT" },
  "48723": { state: "MI", city: "CARO" },
  "48724": { state: "MI", city: "CARROLLTON" },
  "48725": { state: "MI", city: "CASEVILLE" },
  "48726": { state: "MI", city: "CASS CITY" },
  "48727": { state: "MI", city: "CLIFFORD" },
  "48728": { state: "MI", city: "CURRAN" },
  "48729": { state: "MI", city: "DEFORD" },
  "48730": { state: "MI", city: "EAST TAWAS" },
  "48731": { state: "MI", city: "ELKTON" },
  "48732": { state: "MI", city: "ESSEXVILLE" },
  "48733": { state: "MI", city: "FAIRGROVE" },
  "48734": { state: "MI", city: "FRANKENMUTH" },
  "48735": { state: "MI", city: "GAGETOWN" },
  "48737": { state: "MI", city: "GLENNIE" },
  "48738": { state: "MI", city: "GREENBUSH" },
  "48739": { state: "MI", city: "HALE" },
  "48740": { state: "MI", city: "HARRISVILLE" },
  "48741": { state: "MI", city: "KINGSTON" },
  "48742": { state: "MI", city: "LINCOLN" },
  "48743": { state: "MI", city: "LONG LAKE" },
  "48744": { state: "MI", city: "MAYVILLE" },
  "48745": { state: "MI", city: "MIKADO" },
  "48746": { state: "MI", city: "MILLINGTON" },
  "48747": { state: "MI", city: "MUNGER" },
  "48748": { state: "MI", city: "NATIONAL CITY" },
  "48749": { state: "MI", city: "OMER" },
  "48750": { state: "MI", city: "OSCODA" },
  "48754": { state: "MI", city: "OWENDALE" },
  "48755": { state: "MI", city: "PIGEON" },
  "48756": { state: "MI", city: "PRESCOTT" },
  "48757": { state: "MI", city: "REESE" },
  "48758": { state: "MI", city: "RICHVILLE" },
  "48759": { state: "MI", city: "SEBEWAING" },
  "48760": { state: "MI", city: "SILVERWOOD" },
  "48761": { state: "MI", city: "SOUTH BRANCH" },
  "48762": { state: "MI", city: "SPRUCE" },
  "48763": { state: "MI", city: "TAWAS CITY" },
  "48764": { state: "MI", city: "TAWAS CITY" },
  "48765": { state: "MI", city: "TURNER" },
  "48766": { state: "MI", city: "TWINING" },
  "48767": { state: "MI", city: "UNIONVILLE" },
  "48768": { state: "MI", city: "VASSAR" },
  "48770": { state: "MI", city: "WHITTEMORE" },
  "48787": { state: "MI", city: "FRANKENMUTH" },
  "48801": { state: "MI", city: "ALMA" },
  "48804": { state: "MI", city: "MOUNT PLEASANT" },
  "48805": { state: "MI", city: "OKEMOS" },
  "48806": { state: "MI", city: "ASHLEY" },
  "48807": { state: "MI", city: "BANNISTER" },
  "48808": { state: "MI", city: "BATH" },
  "48809": { state: "MI", city: "BELDING" },
  "48811": { state: "MI", city: "CARSON CITY" },
  "48812": { state: "MI", city: "CEDAR LAKE" },
  "48813": { state: "MI", city: "CHARLOTTE" },
  "48815": { state: "MI", city: "CLARKSVILLE" },
  "48816": { state: "MI", city: "COHOCTAH" },
  "48817": { state: "MI", city: "CORUNNA" },
  "48818": { state: "MI", city: "CRYSTAL" },
  "48819": { state: "MI", city: "DANSVILLE" },
  "48820": { state: "MI", city: "DEWITT" },
  "48821": { state: "MI", city: "DIMONDALE" },
  "48822": { state: "MI", city: "EAGLE" },
  "48823": { state: "MI", city: "EAST LANSING" },
  "48824": { state: "MI", city: "EAST LANSING" },
  "48825": { state: "MI", city: "EAST LANSING" },
  "48826": { state: "MI", city: "EAST LANSING" },
  "48827": { state: "MI", city: "EATON RAPIDS" },
  "48829": { state: "MI", city: "EDMORE" },
  "48830": { state: "MI", city: "ELM HALL" },
  "48831": { state: "MI", city: "ELSIE" },
  "48832": { state: "MI", city: "ELWELL" },
  "48833": { state: "MI", city: "EUREKA" },
  "48834": { state: "MI", city: "FENWICK" },
  "48835": { state: "MI", city: "FOWLER" },
  "48836": { state: "MI", city: "FOWLERVILLE" },
  "48837": { state: "MI", city: "GRAND LEDGE" },
  "48838": { state: "MI", city: "GREENVILLE" },
  "48840": { state: "MI", city: "HASLETT" },
  "48841": { state: "MI", city: "HENDERSON" },
  "48842": { state: "MI", city: "HOLT" },
  "48843": { state: "MI", city: "HOWELL" },
  "48844": { state: "MI", city: "HOWELL" },
  "48845": { state: "MI", city: "HUBBARDSTON" },
  "48846": { state: "MI", city: "IONIA" },
  "48847": { state: "MI", city: "ITHACA" },
  "48848": { state: "MI", city: "LAINGSBURG" },
  "48849": { state: "MI", city: "LAKE ODESSA" },
  "48850": { state: "MI", city: "LAKEVIEW" },
  "48851": { state: "MI", city: "LYONS" },
  "48852": { state: "MI", city: "MCBRIDES" },
  "48853": { state: "MI", city: "MAPLE RAPIDS" },
  "48854": { state: "MI", city: "MASON" },
  "48855": { state: "MI", city: "HOWELL" },
  "48856": { state: "MI", city: "MIDDLETON" },
  "48857": { state: "MI", city: "MORRICE" },
  "48858": { state: "MI", city: "MOUNT PLEASANT" },
  "48859": { state: "MI", city: "MOUNT PLEASANT" },
  "48860": { state: "MI", city: "MUIR" },
  "48861": { state: "MI", city: "MULLIKEN" },
  "48862": { state: "MI", city: "NORTH STAR" },
  "48864": { state: "MI", city: "OKEMOS" },
  "48865": { state: "MI", city: "ORLEANS" },
  "48866": { state: "MI", city: "OVID" },
  "48867": { state: "MI", city: "OWOSSO" },
  "48870": { state: "MI", city: "PALO" },
  "48871": { state: "MI", city: "PERRINTON" },
  "48872": { state: "MI", city: "PERRY" },
  "48873": { state: "MI", city: "PEWAMO" },
  "48874": { state: "MI", city: "POMPEII" },
  "48875": { state: "MI", city: "PORTLAND" },
  "48876": { state: "MI", city: "POTTERVILLE" },
  "48877": { state: "MI", city: "RIVERDALE" },
  "48878": { state: "MI", city: "ROSEBUSH" },
  "48879": { state: "MI", city: "SAINT JOHNS" },
  "48880": { state: "MI", city: "SAINT LOUIS" },
  "48881": { state: "MI", city: "SARANAC" },
  "48882": { state: "MI", city: "SHAFTSBURG" },
  "48883": { state: "MI", city: "SHEPHERD" },
  "48884": { state: "MI", city: "SHERIDAN" },
  "48885": { state: "MI", city: "SIDNEY" },
  "48886": { state: "MI", city: "SIX LAKES" },
  "48887": { state: "MI", city: "SMYRNA" },
  "48888": { state: "MI", city: "STANTON" },
  "48889": { state: "MI", city: "SUMNER" },
  "48890": { state: "MI", city: "SUNFIELD" },
  "48891": { state: "MI", city: "VESTABURG" },
  "48892": { state: "MI", city: "WEBBERVILLE" },
  "48893": { state: "MI", city: "WEIDMAN" },
  "48894": { state: "MI", city: "WESTPHALIA" },
  "48895": { state: "MI", city: "WILLIAMSTON" },
  "48896": { state: "MI", city: "WINN" },
  "48897": { state: "MI", city: "WOODLAND" },
  "48901": { state: "MI", city: "LANSING" },
  "48906": { state: "MI", city: "LANSING" },
  "48908": { state: "MI", city: "LANSING" },
  "48909": { state: "MI", city: "LANSING" },
  "48910": { state: "MI", city: "LANSING" },
  "48911": { state: "MI", city: "LANSING" },
  "48912": { state: "MI", city: "LANSING" },
  "48913": { state: "MI", city: "LANSING" },
  "48915": { state: "MI", city: "LANSING" },
  "48916": { state: "MI", city: "LANSING" },
  "48917": { state: "MI", city: "LANSING" },
  "48918": { state: "MI", city: "LANSING" },
  "48919": { state: "MI", city: "LANSING" },
  "48922": { state: "MI", city: "LANSING" },
  "48924": { state: "MI", city: "LANSING" },
  "48929": { state: "MI", city: "LANSING" },
  "48930": { state: "MI", city: "LANSING" },
  "48933": { state: "MI", city: "LANSING" },
  "48937": { state: "MI", city: "LANSING" },
  "48951": { state: "MI", city: "LANSING" },
  "48956": { state: "MI", city: "LANSING" },
  "48980": { state: "MI", city: "LANSING" },
  "49001": { state: "MI", city: "KALAMAZOO" },
  "49002": { state: "MI", city: "PORTAGE" },
  "49003": { state: "MI", city: "KALAMAZOO" },
  "49004": { state: "MI", city: "KALAMAZOO" },
  "49005": { state: "MI", city: "KALAMAZOO" },
  "49006": { state: "MI", city: "KALAMAZOO" },
  "49007": { state: "MI", city: "KALAMAZOO" },
  "49008": { state: "MI", city: "KALAMAZOO" },
  "49009": { state: "MI", city: "KALAMAZOO" },
  "49010": { state: "MI", city: "ALLEGAN" },
  "49011": { state: "MI", city: "ATHENS" },
  "49012": { state: "MI", city: "AUGUSTA" },
  "49013": { state: "MI", city: "BANGOR" },
  "49014": { state: "MI", city: "BATTLE CREEK" },
  "49015": { state: "MI", city: "BATTLE CREEK" },
  "49016": { state: "MI", city: "BATTLE CREEK" },
  "49017": { state: "MI", city: "BATTLE CREEK" },
  "49018": { state: "MI", city: "BATTLE CREEK" },
  "49019": { state: "MI", city: "KALAMAZOO" },
  "49020": { state: "MI", city: "BEDFORD" },
  "49021": { state: "MI", city: "BELLEVUE" },
  "49022": { state: "MI", city: "BENTON HARBOR" },
  "49023": { state: "MI", city: "BENTON HARBOR" },
  "49024": { state: "MI", city: "PORTAGE" },
  "49026": { state: "MI", city: "BLOOMINGDALE" },
  "49027": { state: "MI", city: "BREEDSVILLE" },
  "49028": { state: "MI", city: "BRONSON" },
  "49029": { state: "MI", city: "BURLINGTON" },
  "49030": { state: "MI", city: "BURR OAK" },
  "49031": { state: "MI", city: "CASSOPOLIS" },
  "49032": { state: "MI", city: "CENTREVILLE" },
  "49033": { state: "MI", city: "CERESCO" },
  "49034": { state: "MI", city: "CLIMAX" },
  "49035": { state: "MI", city: "CLOVERDALE" },
  "49036": { state: "MI", city: "COLDWATER" },
  "49037": { state: "MI", city: "BATTLE CREEK" },
  "49038": { state: "MI", city: "COLOMA" },
  "49039": { state: "MI", city: "HAGAR SHORES" },
  "49040": { state: "MI", city: "COLON" },
  "49041": { state: "MI", city: "COMSTOCK" },
  "49042": { state: "MI", city: "CONSTANTINE" },
  "49043": { state: "MI", city: "COVERT" },
  "49045": { state: "MI", city: "DECATUR" },
  "49046": { state: "MI", city: "DELTON" },
  "49047": { state: "MI", city: "DOWAGIAC" },
  "49048": { state: "MI", city: "KALAMAZOO" },
  "49050": { state: "MI", city: "DOWLING" },
  "49051": { state: "MI", city: "EAST LEROY" },
  "49052": { state: "MI", city: "FULTON" },
  "49053": { state: "MI", city: "GALESBURG" },
  "49055": { state: "MI", city: "GOBLES" },
  "49056": { state: "MI", city: "GRAND JUNCTION" },
  "49057": { state: "MI", city: "HARTFORD" },
  "49058": { state: "MI", city: "HASTINGS" },
  "49060": { state: "MI", city: "HICKORY CORNERS" },
  "49061": { state: "MI", city: "JONES" },
  "49062": { state: "MI", city: "KENDALL" },
  "49063": { state: "MI", city: "LACOTA" },
  "49064": { state: "MI", city: "LAWRENCE" },
  "49065": { state: "MI", city: "LAWTON" },
  "49066": { state: "MI", city: "LEONIDAS" },
  "49067": { state: "MI", city: "MARCELLUS" },
  "49068": { state: "MI", city: "MARSHALL" },
  "49070": { state: "MI", city: "MARTIN" },
  "49071": { state: "MI", city: "MATTAWAN" },
  "49072": { state: "MI", city: "MENDON" },
  "49073": { state: "MI", city: "NASHVILLE" },
  "49074": { state: "MI", city: "NAZARETH" },
  "49075": { state: "MI", city: "NOTTAWA" },
  "49076": { state: "MI", city: "OLIVET" },
  "49077": { state: "MI", city: "OSHTEMO" },
  "49078": { state: "MI", city: "OTSEGO" },
  "49079": { state: "MI", city: "PAW PAW" },
  "49080": { state: "MI", city: "PLAINWELL" },
  "49081": { state: "MI", city: "PORTAGE" },
  "49082": { state: "MI", city: "QUINCY" },
  "49083": { state: "MI", city: "RICHLAND" },
  "49084": { state: "MI", city: "RIVERSIDE" },
  "49085": { state: "MI", city: "SAINT JOSEPH" },
  "49087": { state: "MI", city: "SCHOOLCRAFT" },
  "49088": { state: "MI", city: "SCOTTS" },
  "49089": { state: "MI", city: "SHERWOOD" },
  "49090": { state: "MI", city: "SOUTH HAVEN" },
  "49091": { state: "MI", city: "STURGIS" },
  "49092": { state: "MI", city: "TEKONSHA" },
  "49093": { state: "MI", city: "THREE RIVERS" },
  "49094": { state: "MI", city: "UNION CITY" },
  "49095": { state: "MI", city: "VANDALIA" },
  "49096": { state: "MI", city: "VERMONTVILLE" },
  "49097": { state: "MI", city: "VICKSBURG" },
  "49098": { state: "MI", city: "WATERVLIET" },
  "49099": { state: "MI", city: "WHITE PIGEON" },
  "49101": { state: "MI", city: "BARODA" },
  "49102": { state: "MI", city: "BERRIEN CENTER" },
  "49103": { state: "MI", city: "BERRIEN SPRINGS" },
  "49104": { state: "MI", city: "BERRIEN SPRINGS" },
  "49106": { state: "MI", city: "BRIDGMAN" },
  "49107": { state: "MI", city: "BUCHANAN" },
  "49111": { state: "MI", city: "EAU CLAIRE" },
  "49112": { state: "MI", city: "EDWARDSBURG" },
  "49113": { state: "MI", city: "GALIEN" },
  "49115": { state: "MI", city: "HARBERT" },
  "49116": { state: "MI", city: "LAKESIDE" },
  "49117": { state: "MI", city: "NEW BUFFALO" },
  "49119": { state: "MI", city: "NEW TROY" },
  "49120": { state: "MI", city: "NILES" },
  "49125": { state: "MI", city: "SAWYER" },
  "49126": { state: "MI", city: "SODUS" },
  "49127": { state: "MI", city: "STEVENSVILLE" },
  "49128": { state: "MI", city: "THREE OAKS" },
  "49129": { state: "MI", city: "UNION PIER" },
  "49130": { state: "MI", city: "UNION" },
  "49201": { state: "MI", city: "JACKSON" },
  "49202": { state: "MI", city: "JACKSON" },
  "49203": { state: "MI", city: "JACKSON" },
  "49204": { state: "MI", city: "JACKSON" },
  "49220": { state: "MI", city: "ADDISON" },
  "49221": { state: "MI", city: "ADRIAN" },
  "49224": { state: "MI", city: "ALBION" },
  "49227": { state: "MI", city: "ALLEN" },
  "49228": { state: "MI", city: "BLISSFIELD" },
  "49229": { state: "MI", city: "BRITTON" },
  "49230": { state: "MI", city: "BROOKLYN" },
  "49232": { state: "MI", city: "CAMDEN" },
  "49233": { state: "MI", city: "CEMENT CITY" },
  "49234": { state: "MI", city: "CLARKLAKE" },
  "49235": { state: "MI", city: "CLAYTON" },
  "49236": { state: "MI", city: "CLINTON" },
  "49237": { state: "MI", city: "CONCORD" },
  "49238": { state: "MI", city: "DEERFIELD" },
  "49239": { state: "MI", city: "FRONTIER" },
  "49240": { state: "MI", city: "GRASS LAKE" },
  "49241": { state: "MI", city: "HANOVER" },
  "49242": { state: "MI", city: "HILLSDALE" },
  "49245": { state: "MI", city: "HOMER" },
  "49246": { state: "MI", city: "HORTON" },
  "49247": { state: "MI", city: "HUDSON" },
  "49248": { state: "MI", city: "JASPER" },
  "49249": { state: "MI", city: "JEROME" },
  "49250": { state: "MI", city: "JONESVILLE" },
  "49251": { state: "MI", city: "LESLIE" },
  "49252": { state: "MI", city: "LITCHFIELD" },
  "49253": { state: "MI", city: "MANITOU BEACH" },
  "49254": { state: "MI", city: "MICHIGAN CENTER" },
  "49255": { state: "MI", city: "MONTGOMERY" },
  "49256": { state: "MI", city: "MORENCI" },
  "49257": { state: "MI", city: "MOSCOW" },
  "49258": { state: "MI", city: "MOSHERVILLE" },
  "49259": { state: "MI", city: "MUNITH" },
  "49261": { state: "MI", city: "NAPOLEON" },
  "49262": { state: "MI", city: "NORTH ADAMS" },
  "49263": { state: "MI", city: "NORVELL" },
  "49264": { state: "MI", city: "ONONDAGA" },
  "49265": { state: "MI", city: "ONSTED" },
  "49266": { state: "MI", city: "OSSEO" },
  "49267": { state: "MI", city: "OTTAWA LAKE" },
  "49268": { state: "MI", city: "PALMYRA" },
  "49269": { state: "MI", city: "PARMA" },
  "49270": { state: "MI", city: "PETERSBURG" },
  "49271": { state: "MI", city: "PITTSFORD" },
  "49272": { state: "MI", city: "PLEASANT LAKE" },
  "49274": { state: "MI", city: "READING" },
  "49276": { state: "MI", city: "RIGA" },
  "49277": { state: "MI", city: "RIVES JUNCTION" },
  "49279": { state: "MI", city: "SAND CREEK" },
  "49281": { state: "MI", city: "SOMERSET" },
  "49282": { state: "MI", city: "SOMERSET CENTER" },
  "49283": { state: "MI", city: "SPRING ARBOR" },
  "49284": { state: "MI", city: "SPRINGPORT" },
  "49285": { state: "MI", city: "STOCKBRIDGE" },
  "49286": { state: "MI", city: "TECUMSEH" },
  "49287": { state: "MI", city: "TIPTON" },
  "49288": { state: "MI", city: "WALDRON" },
  "49289": { state: "MI", city: "WESTON" },
  "49301": { state: "MI", city: "ADA" },
  "49302": { state: "MI", city: "ALTO" },
  "49303": { state: "MI", city: "BAILEY" },
  "49304": { state: "MI", city: "BALDWIN" },
  "49305": { state: "MI", city: "BARRYTON" },
  "49306": { state: "MI", city: "BELMONT" },
  "49307": { state: "MI", city: "BIG RAPIDS" },
  "49309": { state: "MI", city: "BITELY" },
  "49310": { state: "MI", city: "BLANCHARD" },
  "49311": { state: "MI", city: "BRADLEY" },
  "49312": { state: "MI", city: "BROHMAN" },
  "49314": { state: "MI", city: "BURNIPS" },
  "49315": { state: "MI", city: "BYRON CENTER" },
  "49316": { state: "MI", city: "CALEDONIA" },
  "49317": { state: "MI", city: "CANNONSBURG" },
  "49318": { state: "MI", city: "CASNOVIA" },
  "49319": { state: "MI", city: "CEDAR SPRINGS" },
  "49320": { state: "MI", city: "CHIPPEWA LAKE" },
  "49321": { state: "MI", city: "COMSTOCK PARK" },
  "49322": { state: "MI", city: "CORAL" },
  "49323": { state: "MI", city: "DORR" },
  "49325": { state: "MI", city: "FREEPORT" },
  "49326": { state: "MI", city: "GOWEN" },
  "49327": { state: "MI", city: "GRANT" },
  "49328": { state: "MI", city: "HOPKINS" },
  "49329": { state: "MI", city: "HOWARD CITY" },
  "49330": { state: "MI", city: "KENT CITY" },
  "49331": { state: "MI", city: "LOWELL" },
  "49332": { state: "MI", city: "MECOSTA" },
  "49333": { state: "MI", city: "MIDDLEVILLE" },
  "49335": { state: "MI", city: "MOLINE" },
  "49336": { state: "MI", city: "MORLEY" },
  "49337": { state: "MI", city: "NEWAYGO" },
  "49338": { state: "MI", city: "PARIS" },
  "49339": { state: "MI", city: "PIERSON" },
  "49340": { state: "MI", city: "REMUS" },
  "49341": { state: "MI", city: "ROCKFORD" },
  "49342": { state: "MI", city: "RODNEY" },
  "49343": { state: "MI", city: "SAND LAKE" },
  "49344": { state: "MI", city: "SHELBYVILLE" },
  "49345": { state: "MI", city: "SPARTA" },
  "49346": { state: "MI", city: "STANWOOD" },
  "49347": { state: "MI", city: "TRUFANT" },
  "49348": { state: "MI", city: "WAYLAND" },
  "49349": { state: "MI", city: "WHITE CLOUD" },
  "49351": { state: "MI", city: "ROCKFORD" },
  "49355": { state: "MI", city: "ADA" },
  "49356": { state: "MI", city: "ADA" },
  "49357": { state: "MI", city: "ADA" },
  "49401": { state: "MI", city: "ALLENDALE" },
  "49402": { state: "MI", city: "BRANCH" },
  "49403": { state: "MI", city: "CONKLIN" },
  "49404": { state: "MI", city: "COOPERSVILLE" },
  "49405": { state: "MI", city: "CUSTER" },
  "49406": { state: "MI", city: "DOUGLAS" },
  "49408": { state: "MI", city: "FENNVILLE" },
  "49409": { state: "MI", city: "FERRYSBURG" },
  "49410": { state: "MI", city: "FOUNTAIN" },
  "49411": { state: "MI", city: "FREE SOIL" },
  "49412": { state: "MI", city: "FREMONT" },
  "49413": { state: "MI", city: "FREMONT" },
  "49415": { state: "MI", city: "FRUITPORT" },
  "49416": { state: "MI", city: "GLENN" },
  "49417": { state: "MI", city: "GRAND HAVEN" },
  "49418": { state: "MI", city: "GRANDVILLE" },
  "49419": { state: "MI", city: "HAMILTON" },
  "49420": { state: "MI", city: "HART" },
  "49421": { state: "MI", city: "HESPERIA" },
  "49422": { state: "MI", city: "HOLLAND" },
  "49423": { state: "MI", city: "HOLLAND" },
  "49424": { state: "MI", city: "HOLLAND" },
  "49425": { state: "MI", city: "HOLTON" },
  "49426": { state: "MI", city: "HUDSONVILLE" },
  "49427": { state: "MI", city: "JAMESTOWN" },
  "49428": { state: "MI", city: "JENISON" },
  "49429": { state: "MI", city: "JENISON" },
  "49430": { state: "MI", city: "LAMONT" },
  "49431": { state: "MI", city: "LUDINGTON" },
  "49434": { state: "MI", city: "MACATAWA" },
  "49435": { state: "MI", city: "MARNE" },
  "49436": { state: "MI", city: "MEARS" },
  "49437": { state: "MI", city: "MONTAGUE" },
  "49440": { state: "MI", city: "MUSKEGON" },
  "49441": { state: "MI", city: "MUSKEGON" },
  "49442": { state: "MI", city: "MUSKEGON" },
  "49443": { state: "MI", city: "MUSKEGON" },
  "49444": { state: "MI", city: "MUSKEGON" },
  "49445": { state: "MI", city: "MUSKEGON" },
  "49446": { state: "MI", city: "NEW ERA" },
  "49448": { state: "MI", city: "NUNICA" },
  "49449": { state: "MI", city: "PENTWATER" },
  "49450": { state: "MI", city: "PULLMAN" },
  "49451": { state: "MI", city: "RAVENNA" },
  "49452": { state: "MI", city: "ROTHBURY" },
  "49453": { state: "MI", city: "SAUGATUCK" },
  "49454": { state: "MI", city: "SCOTTVILLE" },
  "49455": { state: "MI", city: "SHELBY" },
  "49456": { state: "MI", city: "SPRING LAKE" },
  "49457": { state: "MI", city: "TWIN LAKE" },
  "49458": { state: "MI", city: "WALHALLA" },
  "49459": { state: "MI", city: "WALKERVILLE" },
  "49460": { state: "MI", city: "WEST OLIVE" },
  "49461": { state: "MI", city: "WHITEHALL" },
  "49463": { state: "MI", city: "SYLVAN BEACH" },
  "49464": { state: "MI", city: "ZEELAND" },
  "49468": { state: "MI", city: "GRANDVILLE" },
  "49501": { state: "MI", city: "GRAND RAPIDS" },
  "49502": { state: "MI", city: "GRAND RAPIDS" },
  "49503": { state: "MI", city: "GRAND RAPIDS" },
  "49504": { state: "MI", city: "GRAND RAPIDS" },
  "49505": { state: "MI", city: "GRAND RAPIDS" },
  "49506": { state: "MI", city: "GRAND RAPIDS" },
  "49507": { state: "MI", city: "GRAND RAPIDS" },
  "49508": { state: "MI", city: "GRAND RAPIDS" },
  "49509": { state: "MI", city: "WYOMING" },
  "49510": { state: "MI", city: "GRAND RAPIDS" },
  "49512": { state: "MI", city: "GRAND RAPIDS" },
  "49514": { state: "MI", city: "GRAND RAPIDS" },
  "49515": { state: "MI", city: "GRAND RAPIDS" },
  "49516": { state: "MI", city: "GRAND RAPIDS" },
  "49518": { state: "MI", city: "GRAND RAPIDS" },
  "49519": { state: "MI", city: "WYOMING" },
  "49523": { state: "MI", city: "GRAND RAPIDS" },
  "49525": { state: "MI", city: "GRAND RAPIDS" },
  "49528": { state: "MI", city: "GRAND RAPIDS" },
  "49530": { state: "MI", city: "GRAND RAPIDS" },
  "49534": { state: "MI", city: "GRAND RAPIDS" },
  "49544": { state: "MI", city: "GRAND RAPIDS" },
  "49546": { state: "MI", city: "GRAND RAPIDS" },
  "49548": { state: "MI", city: "GRAND RAPIDS" },
  "49555": { state: "MI", city: "GRAND RAPIDS" },
  "49560": { state: "MI", city: "GRAND RAPIDS" },
  "49588": { state: "MI", city: "GRAND RAPIDS" },
  "49599": { state: "MI", city: "GRAND RAPIDS" },
  "49601": { state: "MI", city: "CADILLAC" },
  "49610": { state: "MI", city: "ACME" },
  "49611": { state: "MI", city: "ALBA" },
  "49612": { state: "MI", city: "ALDEN" },
  "49613": { state: "MI", city: "ARCADIA" },
  "49614": { state: "MI", city: "BEAR LAKE" },
  "49615": { state: "MI", city: "BELLAIRE" },
  "49616": { state: "MI", city: "BENZONIA" },
  "49617": { state: "MI", city: "BEULAH" },
  "49618": { state: "MI", city: "BOON" },
  "49619": { state: "MI", city: "BRETHREN" },
  "49620": { state: "MI", city: "BUCKLEY" },
  "49621": { state: "MI", city: "CEDAR" },
  "49622": { state: "MI", city: "CENTRAL LAKE" },
  "49623": { state: "MI", city: "CHASE" },
  "49625": { state: "MI", city: "COPEMISH" },
  "49626": { state: "MI", city: "EASTLAKE" },
  "49627": { state: "MI", city: "EASTPORT" },
  "49628": { state: "MI", city: "ELBERTA" },
  "49629": { state: "MI", city: "ELK RAPIDS" },
  "49630": { state: "MI", city: "EMPIRE" },
  "49631": { state: "MI", city: "EVART" },
  "49632": { state: "MI", city: "FALMOUTH" },
  "49633": { state: "MI", city: "FIFE LAKE" },
  "49634": { state: "MI", city: "FILER CITY" },
  "49635": { state: "MI", city: "FRANKFORT" },
  "49636": { state: "MI", city: "GLEN ARBOR" },
  "49637": { state: "MI", city: "GRAWN" },
  "49638": { state: "MI", city: "HARRIETTA" },
  "49639": { state: "MI", city: "HERSEY" },
  "49640": { state: "MI", city: "HONOR" },
  "49642": { state: "MI", city: "IDLEWILD" },
  "49643": { state: "MI", city: "INTERLOCHEN" },
  "49644": { state: "MI", city: "IRONS" },
  "49645": { state: "MI", city: "KALEVA" },
  "49646": { state: "MI", city: "KALKASKA" },
  "49648": { state: "MI", city: "KEWADIN" },
  "49649": { state: "MI", city: "KINGSLEY" },
  "49650": { state: "MI", city: "LAKE ANN" },
  "49651": { state: "MI", city: "LAKE CITY" },
  "49653": { state: "MI", city: "LAKE LEELANAU" },
  "49654": { state: "MI", city: "LELAND" },
  "49655": { state: "MI", city: "LEROY" },
  "49656": { state: "MI", city: "LUTHER" },
  "49657": { state: "MI", city: "MC BAIN" },
  "49659": { state: "MI", city: "MANCELONA" },
  "49660": { state: "MI", city: "MANISTEE" },
  "49663": { state: "MI", city: "MANTON" },
  "49664": { state: "MI", city: "MAPLE CITY" },
  "49665": { state: "MI", city: "MARION" },
  "49666": { state: "MI", city: "MAYFIELD" },
  "49667": { state: "MI", city: "MERRITT" },
  "49668": { state: "MI", city: "MESICK" },
  "49670": { state: "MI", city: "NORTHPORT" },
  "49673": { state: "MI", city: "OLD MISSION" },
  "49674": { state: "MI", city: "OMENA" },
  "49675": { state: "MI", city: "ONEKAMA" },
  "49676": { state: "MI", city: "RAPID CITY" },
  "49677": { state: "MI", city: "REED CITY" },
  "49679": { state: "MI", city: "SEARS" },
  "49680": { state: "MI", city: "SOUTH BOARDMAN" },
  "49682": { state: "MI", city: "SUTTONS BAY" },
  "49683": { state: "MI", city: "THOMPSONVILLE" },
  "49684": { state: "MI", city: "TRAVERSE CITY" },
  "49685": { state: "MI", city: "TRAVERSE CITY" },
  "49686": { state: "MI", city: "TRAVERSE CITY" },
  "49688": { state: "MI", city: "TUSTIN" },
  "49689": { state: "MI", city: "WELLSTON" },
  "49690": { state: "MI", city: "WILLIAMSBURG" },
  "49696": { state: "MI", city: "TRAVERSE CITY" },
  "49701": { state: "MI", city: "MACKINAW CITY" },
  "49705": { state: "MI", city: "AFTON" },
  "49706": { state: "MI", city: "ALANSON" },
  "49707": { state: "MI", city: "ALPENA" },
  "49709": { state: "MI", city: "ATLANTA" },
  "49710": { state: "MI", city: "BARBEAU" },
  "49711": { state: "MI", city: "BAY SHORE" },
  "49712": { state: "MI", city: "BOYNE CITY" },
  "49713": { state: "MI", city: "BOYNE FALLS" },
  "49715": { state: "MI", city: "BRIMLEY" },
  "49716": { state: "MI", city: "BRUTUS" },
  "49717": { state: "MI", city: "BURT LAKE" },
  "49718": { state: "MI", city: "CARP LAKE" },
  "49719": { state: "MI", city: "CEDARVILLE" },
  "49720": { state: "MI", city: "CHARLEVOIX" },
  "49721": { state: "MI", city: "CHEBOYGAN" },
  "49722": { state: "MI", city: "CONWAY" },
  "49723": { state: "MI", city: "CROSS VILLAGE" },
  "49724": { state: "MI", city: "DAFTER" },
  "49725": { state: "MI", city: "DE TOUR VILLAGE" },
  "49726": { state: "MI", city: "DRUMMOND ISLAND" },
  "49727": { state: "MI", city: "EAST JORDAN" },
  "49728": { state: "MI", city: "ECKERMAN" },
  "49729": { state: "MI", city: "ELLSWORTH" },
  "49730": { state: "MI", city: "ELMIRA" },
  "49733": { state: "MI", city: "FREDERIC" },
  "49734": { state: "MI", city: "GAYLORD" },
  "49735": { state: "MI", city: "GAYLORD" },
  "49736": { state: "MI", city: "GOETZVILLE" },
  "49737": { state: "MI", city: "GOOD HART" },
  "49738": { state: "MI", city: "GRAYLING" },
  "49739": { state: "MI", city: "GRAYLING" },
  "49740": { state: "MI", city: "HARBOR SPRINGS" },
  "49743": { state: "MI", city: "HAWKS" },
  "49744": { state: "MI", city: "HERRON" },
  "49745": { state: "MI", city: "HESSEL" },
  "49746": { state: "MI", city: "HILLMAN" },
  "49747": { state: "MI", city: "HUBBARD LAKE" },
  "49748": { state: "MI", city: "HULBERT" },
  "49749": { state: "MI", city: "INDIAN RIVER" },
  "49751": { state: "MI", city: "JOHANNESBURG" },
  "49752": { state: "MI", city: "KINROSS" },
  "49753": { state: "MI", city: "LACHINE" },
  "49755": { state: "MI", city: "LEVERING" },
  "49756": { state: "MI", city: "LEWISTON" },
  "49757": { state: "MI", city: "MACKINAC ISLAND" },
  "49759": { state: "MI", city: "MILLERSBURG" },
  "49760": { state: "MI", city: "MORAN" },
  "49761": { state: "MI", city: "MULLETT LAKE" },
  "49762": { state: "MI", city: "NAUBINWAY" },
  "49764": { state: "MI", city: "ODEN" },
  "49765": { state: "MI", city: "ONAWAY" },
  "49766": { state: "MI", city: "OSSINEKE" },
  "49768": { state: "MI", city: "PARADISE" },
  "49769": { state: "MI", city: "PELLSTON" },
  "49770": { state: "MI", city: "PETOSKEY" },
  "49774": { state: "MI", city: "PICKFORD" },
  "49775": { state: "MI", city: "POINTE AUX PINS" },
  "49776": { state: "MI", city: "POSEN" },
  "49777": { state: "MI", city: "PRESQUE ISLE" },
  "49779": { state: "MI", city: "ROGERS CITY" },
  "49780": { state: "MI", city: "RUDYARD" },
  "49781": { state: "MI", city: "SAINT IGNACE" },
  "49782": { state: "MI", city: "BEAVER ISLAND" },
  "49783": { state: "MI", city: "SAULT SAINTE MARIE" },
  "49784": { state: "MI", city: "KINCHELOE" },
  "49785": { state: "MI", city: "KINCHELOE" },
  "49786": { state: "MI", city: "KINCHELOE" },
  "49788": { state: "MI", city: "KINCHELOE" },
  "49791": { state: "MI", city: "TOPINABEE" },
  "49792": { state: "MI", city: "TOWER" },
  "49793": { state: "MI", city: "TROUT LAKE" },
  "49795": { state: "MI", city: "VANDERBILT" },
  "49796": { state: "MI", city: "WALLOON LAKE" },
  "49797": { state: "MI", city: "WATERS" },
  "49799": { state: "MI", city: "WOLVERINE" },
  "49801": { state: "MI", city: "IRON MOUNTAIN" },
  "49802": { state: "MI", city: "KINGSFORD" },
  "49805": { state: "MI", city: "ALLOUEZ" },
  "49806": { state: "MI", city: "AU TRAIN" },
  "49807": { state: "MI", city: "BARK RIVER" },
  "49808": { state: "MI", city: "BIG BAY" },
  "49812": { state: "MI", city: "CARNEY" },
  "49814": { state: "MI", city: "CHAMPION" },
  "49815": { state: "MI", city: "CHANNING" },
  "49816": { state: "MI", city: "CHATHAM" },
  "49817": { state: "MI", city: "COOKS" },
  "49818": { state: "MI", city: "CORNELL" },
  "49819": { state: "MI", city: "ARNOLD" },
  "49820": { state: "MI", city: "CURTIS" },
  "49821": { state: "MI", city: "DAGGETT" },
  "49822": { state: "MI", city: "DEERTON" },
  "49825": { state: "MI", city: "EBEN JUNCTION" },
  "49826": { state: "MI", city: "RUMELY" },
  "49827": { state: "MI", city: "ENGADINE" },
  "49829": { state: "MI", city: "ESCANABA" },
  "49831": { state: "MI", city: "FELCH" },
  "49833": { state: "MI", city: "LITTLE LAKE" },
  "49834": { state: "MI", city: "FOSTER CITY" },
  "49835": { state: "MI", city: "GARDEN" },
  "49836": { state: "MI", city: "GERMFASK" },
  "49837": { state: "MI", city: "GLADSTONE" },
  "49838": { state: "MI", city: "GOULD CITY" },
  "49839": { state: "MI", city: "GRAND MARAIS" },
  "49840": { state: "MI", city: "GULLIVER" },
  "49841": { state: "MI", city: "GWINN" },
  "49845": { state: "MI", city: "HARRIS" },
  "49847": { state: "MI", city: "HERMANSVILLE" },
  "49848": { state: "MI", city: "INGALLS" },
  "49849": { state: "MI", city: "ISHPEMING" },
  "49852": { state: "MI", city: "LORETTO" },
  "49853": { state: "MI", city: "MC MILLAN" },
  "49854": { state: "MI", city: "MANISTIQUE" },
  "49855": { state: "MI", city: "MARQUETTE" },
  "49858": { state: "MI", city: "MENOMINEE" },
  "49861": { state: "MI", city: "MICHIGAMME" },
  "49862": { state: "MI", city: "MUNISING" },
  "49863": { state: "MI", city: "NADEAU" },
  "49864": { state: "MI", city: "NAHMA" },
  "49865": { state: "MI", city: "NATIONAL MINE" },
  "49866": { state: "MI", city: "NEGAUNEE" },
  "49868": { state: "MI", city: "NEWBERRY" },
  "49870": { state: "MI", city: "NORWAY" },
  "49871": { state: "MI", city: "PALMER" },
  "49872": { state: "MI", city: "PERKINS" },
  "49873": { state: "MI", city: "PERRONVILLE" },
  "49874": { state: "MI", city: "POWERS" },
  "49876": { state: "MI", city: "QUINNESEC" },
  "49877": { state: "MI", city: "RALPH" },
  "49878": { state: "MI", city: "RAPID RIVER" },
  "49879": { state: "MI", city: "REPUBLIC" },
  "49880": { state: "MI", city: "ROCK" },
  "49881": { state: "MI", city: "SAGOLA" },
  "49883": { state: "MI", city: "SENEY" },
  "49884": { state: "MI", city: "SHINGLETON" },
  "49885": { state: "MI", city: "SKANDIA" },
  "49886": { state: "MI", city: "SPALDING" },
  "49887": { state: "MI", city: "STEPHENSON" },
  "49891": { state: "MI", city: "TRENARY" },
  "49892": { state: "MI", city: "VULCAN" },
  "49893": { state: "MI", city: "WALLACE" },
  "49894": { state: "MI", city: "WELLS" },
  "49895": { state: "MI", city: "WETMORE" },
  "49896": { state: "MI", city: "WILSON" },
  "49901": { state: "MI", city: "AHMEEK" },
  "49902": { state: "MI", city: "ALPHA" },
  "49903": { state: "MI", city: "AMASA" },
  "49905": { state: "MI", city: "ATLANTIC MINE" },
  "49908": { state: "MI", city: "BARAGA" },
  "49910": { state: "MI", city: "BERGLAND" },
  "49911": { state: "MI", city: "BESSEMER" },
  "49912": { state: "MI", city: "BRUCE CROSSING" },
  "49913": { state: "MI", city: "CALUMET" },
  "49915": { state: "MI", city: "CASPIAN" },
  "49916": { state: "MI", city: "CHASSELL" },
  "49917": { state: "MI", city: "COPPER CITY" },
  "49918": { state: "MI", city: "COPPER HARBOR" },
  "49919": { state: "MI", city: "COVINGTON" },
  "49920": { state: "MI", city: "CRYSTAL FALLS" },
  "49921": { state: "MI", city: "DODGEVILLE" },
  "49922": { state: "MI", city: "DOLLAR BAY" },
  "49925": { state: "MI", city: "EWEN" },
  "49927": { state: "MI", city: "GAASTRA" },
  "49929": { state: "MI", city: "GREENLAND" },
  "49930": { state: "MI", city: "HANCOCK" },
  "49931": { state: "MI", city: "HOUGHTON" },
  "49934": { state: "MI", city: "HUBBELL" },
  "49935": { state: "MI", city: "IRON RIVER" },
  "49938": { state: "MI", city: "IRONWOOD" },
  "49942": { state: "MI", city: "KEARSARGE" },
  "49945": { state: "MI", city: "LAKE LINDEN" },
  "49946": { state: "MI", city: "LANSE" },
  "49947": { state: "MI", city: "MARENISCO" },
  "49948": { state: "MI", city: "MASS CITY" },
  "49950": { state: "MI", city: "MOHAWK" },
  "49952": { state: "MI", city: "NISULA" },
  "49953": { state: "MI", city: "ONTONAGON" },
  "49955": { state: "MI", city: "PAINESDALE" },
  "49958": { state: "MI", city: "PELKIE" },
  "49959": { state: "MI", city: "RAMSAY" },
  "49960": { state: "MI", city: "ROCKLAND" },
  "49961": { state: "MI", city: "SIDNAW" },
  "49962": { state: "MI", city: "SKANEE" },
  "49963": { state: "MI", city: "SOUTH RANGE" },
  "49964": { state: "MI", city: "STAMBAUGH" },
  "49965": { state: "MI", city: "TOIVOLA" },
  "49967": { state: "MI", city: "TROUT CREEK" },
  "49968": { state: "MI", city: "WAKEFIELD" },
  "49969": { state: "MI", city: "WATERSMEET" },
  "49970": { state: "MI", city: "WATTON" },
  "49971": { state: "MI", city: "WHITE PINE" },
  "50001": { state: "IA", city: "ACKWORTH" },
  "50002": { state: "IA", city: "ADAIR" },
  "50003": { state: "IA", city: "ADEL" },
  "50005": { state: "IA", city: "ALBION" },
  "50006": { state: "IA", city: "ALDEN" },
  "50007": { state: "IA", city: "ALLEMAN" },
  "50008": { state: "IA", city: "ALLERTON" },
  "50009": { state: "IA", city: "ALTOONA" },
  "50010": { state: "IA", city: "AMES" },
  "50011": { state: "IA", city: "AMES" },
  "50012": { state: "IA", city: "AMES" },
  "50013": { state: "IA", city: "AMES" },
  "50014": { state: "IA", city: "AMES" },
  "50020": { state: "IA", city: "ANITA" },
  "50021": { state: "IA", city: "ANKENY" },
  "50022": { state: "IA", city: "ATLANTIC" },
  "50023": { state: "IA", city: "ANKENY" },
  "50025": { state: "IA", city: "AUDUBON" },
  "50026": { state: "IA", city: "BAGLEY" },
  "50027": { state: "IA", city: "BARNES CITY" },
  "50028": { state: "IA", city: "BAXTER" },
  "50029": { state: "IA", city: "BAYARD" },
  "50031": { state: "IA", city: "BEAVER" },
  "50032": { state: "IA", city: "BERWICK" },
  "50033": { state: "IA", city: "BEVINGTON" },
  "50034": { state: "IA", city: "BLAIRSBURG" },
  "50035": { state: "IA", city: "BONDURANT" },
  "50036": { state: "IA", city: "BOONE" },
  "50037": { state: "IA", city: "BOONE" },
  "50038": { state: "IA", city: "BOONEVILLE" },
  "50039": { state: "IA", city: "BOUTON" },
  "50040": { state: "IA", city: "BOXHOLM" },
  "50041": { state: "IA", city: "BRADFORD" },
  "50042": { state: "IA", city: "BRAYTON" },
  "50043": { state: "IA", city: "BUCKEYE" },
  "50044": { state: "IA", city: "BUSSEY" },
  "50046": { state: "IA", city: "CAMBRIDGE" },
  "50047": { state: "IA", city: "CARLISLE" },
  "50048": { state: "IA", city: "CASEY" },
  "50049": { state: "IA", city: "CHARITON" },
  "50050": { state: "IA", city: "CHURDAN" },
  "50051": { state: "IA", city: "CLEMONS" },
  "50052": { state: "IA", city: "CLIO" },
  "50054": { state: "IA", city: "COLFAX" },
  "50055": { state: "IA", city: "COLLINS" },
  "50056": { state: "IA", city: "COLO" },
  "50057": { state: "IA", city: "COLUMBIA" },
  "50058": { state: "IA", city: "COON RAPIDS" },
  "50059": { state: "IA", city: "COOPER" },
  "50060": { state: "IA", city: "CORYDON" },
  "50061": { state: "IA", city: "CUMMING" },
  "50062": { state: "IA", city: "MELCHER DALLAS" },
  "50063": { state: "IA", city: "DALLAS CENTER" },
  "50064": { state: "IA", city: "DANA" },
  "50065": { state: "IA", city: "DAVIS CITY" },
  "50066": { state: "IA", city: "DAWSON" },
  "50067": { state: "IA", city: "DECATUR" },
  "50068": { state: "IA", city: "DERBY" },
  "50069": { state: "IA", city: "DE SOTO" },
  "50070": { state: "IA", city: "DEXTER" },
  "50071": { state: "IA", city: "DOWS" },
  "50072": { state: "IA", city: "EARLHAM" },
  "50073": { state: "IA", city: "ELKHART" },
  "50074": { state: "IA", city: "ELLSTON" },
  "50075": { state: "IA", city: "ELLSWORTH" },
  "50076": { state: "IA", city: "EXIRA" },
  "50078": { state: "IA", city: "FERGUSON" },
  "50099": { state: "IA", city: "BOONE" },
  "50101": { state: "IA", city: "GALT" },
  "50102": { state: "IA", city: "GARDEN CITY" },
  "50103": { state: "IA", city: "GARDEN GROVE" },
  "50104": { state: "IA", city: "GIBSON" },
  "50105": { state: "IA", city: "GILBERT" },
  "50106": { state: "IA", city: "GILMAN" },
  "50107": { state: "IA", city: "GRAND JUNCTION" },
  "50108": { state: "IA", city: "GRAND RIVER" },
  "50109": { state: "IA", city: "GRANGER" },
  "50110": { state: "IA", city: "GRAY" },
  "50111": { state: "IA", city: "GRIMES" },
  "50112": { state: "IA", city: "GRINNELL" },
  "50115": { state: "IA", city: "GUTHRIE CENTER" },
  "50116": { state: "IA", city: "HAMILTON" },
  "50117": { state: "IA", city: "HAMLIN" },
  "50118": { state: "IA", city: "HARTFORD" },
  "50119": { state: "IA", city: "HARVEY" },
  "50120": { state: "IA", city: "HAVERHILL" },
  "50122": { state: "IA", city: "HUBBARD" },
  "50123": { state: "IA", city: "HUMESTON" },
  "50124": { state: "IA", city: "HUXLEY" },
  "50125": { state: "IA", city: "INDIANOLA" },
  "50126": { state: "IA", city: "IOWA FALLS" },
  "50127": { state: "IA", city: "IRA" },
  "50128": { state: "IA", city: "JAMAICA" },
  "50129": { state: "IA", city: "JEFFERSON" },
  "50130": { state: "IA", city: "JEWELL" },
  "50131": { state: "IA", city: "JOHNSTON" },
  "50132": { state: "IA", city: "KAMRAR" },
  "50133": { state: "IA", city: "KELLERTON" },
  "50134": { state: "IA", city: "KELLEY" },
  "50135": { state: "IA", city: "KELLOGG" },
  "50136": { state: "IA", city: "KESWICK" },
  "50137": { state: "IA", city: "KILLDUFF" },
  "50138": { state: "IA", city: "KNOXVILLE" },
  "50139": { state: "IA", city: "LACONA" },
  "50140": { state: "IA", city: "LAMONI" },
  "50141": { state: "IA", city: "LAUREL" },
  "50142": { state: "IA", city: "LE GRAND" },
  "50143": { state: "IA", city: "LEIGHTON" },
  "50144": { state: "IA", city: "LEON" },
  "50145": { state: "IA", city: "LIBERTY CENTER" },
  "50146": { state: "IA", city: "LINDEN" },
  "50147": { state: "IA", city: "LINEVILLE" },
  "50148": { state: "IA", city: "LISCOMB" },
  "50149": { state: "IA", city: "LORIMOR" },
  "50150": { state: "IA", city: "LOVILIA" },
  "50151": { state: "IA", city: "LUCAS" },
  "50152": { state: "IA", city: "LUTHER" },
  "50153": { state: "IA", city: "LYNNVILLE" },
  "50154": { state: "IA", city: "MC CALLSBURG" },
  "50155": { state: "IA", city: "MACKSBURG" },
  "50156": { state: "IA", city: "MADRID" },
  "50157": { state: "IA", city: "MALCOM" },
  "50158": { state: "IA", city: "MARSHALLTOWN" },
  "50160": { state: "IA", city: "MARTENSDALE" },
  "50161": { state: "IA", city: "MAXWELL" },
  "50162": { state: "IA", city: "MELBOURNE" },
  "50163": { state: "IA", city: "MELCHER DALLAS" },
  "50164": { state: "IA", city: "MENLO" },
  "50165": { state: "IA", city: "MILLERTON" },
  "50166": { state: "IA", city: "MILO" },
  "50167": { state: "IA", city: "MINBURN" },
  "50168": { state: "IA", city: "MINGO" },
  "50169": { state: "IA", city: "MITCHELLVILLE" },
  "50170": { state: "IA", city: "MONROE" },
  "50171": { state: "IA", city: "MONTEZUMA" },
  "50173": { state: "IA", city: "MONTOUR" },
  "50174": { state: "IA", city: "MURRAY" },
  "50201": { state: "IA", city: "NEVADA" },
  "50206": { state: "IA", city: "NEW PROVIDENCE" },
  "50207": { state: "IA", city: "NEW SHARON" },
  "50208": { state: "IA", city: "NEWTON" },
  "50210": { state: "IA", city: "NEW VIRGINIA" },
  "50211": { state: "IA", city: "NORWALK" },
  "50212": { state: "IA", city: "OGDEN" },
  "50213": { state: "IA", city: "OSCEOLA" },
  "50214": { state: "IA", city: "OTLEY" },
  "50216": { state: "IA", city: "PANORA" },
  "50217": { state: "IA", city: "PATON" },
  "50218": { state: "IA", city: "PATTERSON" },
  "50219": { state: "IA", city: "PELLA" },
  "50220": { state: "IA", city: "PERRY" },
  "50222": { state: "IA", city: "PERU" },
  "50223": { state: "IA", city: "PILOT MOUND" },
  "50225": { state: "IA", city: "PLEASANTVILLE" },
  "50226": { state: "IA", city: "POLK CITY" },
  "50227": { state: "IA", city: "POPEJOY" },
  "50228": { state: "IA", city: "PRAIRIE CITY" },
  "50229": { state: "IA", city: "PROLE" },
  "50230": { state: "IA", city: "RADCLIFFE" },
  "50231": { state: "IA", city: "RANDALL" },
  "50232": { state: "IA", city: "REASNOR" },
  "50233": { state: "IA", city: "REDFIELD" },
  "50234": { state: "IA", city: "RHODES" },
  "50235": { state: "IA", city: "RIPPEY" },
  "50236": { state: "IA", city: "ROLAND" },
  "50237": { state: "IA", city: "RUNNELLS" },
  "50238": { state: "IA", city: "RUSSELL" },
  "50239": { state: "IA", city: "SAINT ANTHONY" },
  "50240": { state: "IA", city: "SAINT CHARLES" },
  "50241": { state: "IA", city: "SAINT MARYS" },
  "50242": { state: "IA", city: "SEARSBORO" },
  "50243": { state: "IA", city: "SHELDAHL" },
  "50244": { state: "IA", city: "SLATER" },
  "50246": { state: "IA", city: "STANHOPE" },
  "50247": { state: "IA", city: "STATE CENTER" },
  "50248": { state: "IA", city: "STORY CITY" },
  "50249": { state: "IA", city: "STRATFORD" },
  "50250": { state: "IA", city: "STUART" },
  "50251": { state: "IA", city: "SULLY" },
  "50252": { state: "IA", city: "SWAN" },
  "50254": { state: "IA", city: "THAYER" },
  "50255": { state: "IA", city: "THORNBURG" },
  "50256": { state: "IA", city: "TRACY" },
  "50257": { state: "IA", city: "TRURO" },
  "50258": { state: "IA", city: "UNION" },
  "50259": { state: "IA", city: "GIFFORD" },
  "50261": { state: "IA", city: "VAN METER" },
  "50262": { state: "IA", city: "VAN WERT" },
  "50263": { state: "IA", city: "WAUKEE" },
  "50264": { state: "IA", city: "WELDON" },
  "50265": { state: "IA", city: "WEST DES MOINES" },
  "50266": { state: "IA", city: "WEST DES MOINES" },
  "50268": { state: "IA", city: "WHAT CHEER" },
  "50269": { state: "IA", city: "WHITTEN" },
  "50271": { state: "IA", city: "WILLIAMS" },
  "50272": { state: "IA", city: "WILLIAMSON" },
  "50273": { state: "IA", city: "WINTERSET" },
  "50274": { state: "IA", city: "WIOTA" },
  "50275": { state: "IA", city: "WOODBURN" },
  "50276": { state: "IA", city: "WOODWARD" },
  "50277": { state: "IA", city: "YALE" },
  "50278": { state: "IA", city: "ZEARING" },
  "50301": { state: "IA", city: "DES MOINES" },
  "50302": { state: "IA", city: "DES MOINES" },
  "50303": { state: "IA", city: "DES MOINES" },
  "50304": { state: "IA", city: "DES MOINES" },
  "50305": { state: "IA", city: "DES MOINES" },
  "50306": { state: "IA", city: "DES MOINES" },
  "50307": { state: "IA", city: "DES MOINES" },
  "50308": { state: "IA", city: "DES MOINES" },
  "50309": { state: "IA", city: "DES MOINES" },
  "50310": { state: "IA", city: "DES MOINES" },
  "50311": { state: "IA", city: "DES MOINES" },
  "50312": { state: "IA", city: "DES MOINES" },
  "50313": { state: "IA", city: "DES MOINES" },
  "50314": { state: "IA", city: "DES MOINES" },
  "50315": { state: "IA", city: "DES MOINES" },
  "50316": { state: "IA", city: "DES MOINES" },
  "50317": { state: "IA", city: "DES MOINES" },
  "50318": { state: "IA", city: "DES MOINES" },
  "50319": { state: "IA", city: "DES MOINES" },
  "50320": { state: "IA", city: "DES MOINES" },
  "50321": { state: "IA", city: "DES MOINES" },
  "50322": { state: "IA", city: "URBANDALE" },
  "50323": { state: "IA", city: "URBANDALE" },
  "50324": { state: "IA", city: "WINDSOR HEIGHTS" },
  "50325": { state: "IA", city: "CLIVE" },
  "50327": { state: "IA", city: "PLEASANT HILL" },
  "50328": { state: "IA", city: "DES MOINES" },
  "50329": { state: "IA", city: "DES MOINES" },
  "50330": { state: "IA", city: "DES MOINES" },
  "50331": { state: "IA", city: "DES MOINES" },
  "50332": { state: "IA", city: "DES MOINES" },
  "50333": { state: "IA", city: "DES MOINES" },
  "50334": { state: "IA", city: "DES MOINES" },
  "50335": { state: "IA", city: "DES MOINES" },
  "50336": { state: "IA", city: "DES MOINES" },
  "50339": { state: "IA", city: "DES MOINES" },
  "50340": { state: "IA", city: "DES MOINES" },
  "50359": { state: "IA", city: "DES MOINES" },
  "50360": { state: "IA", city: "DES MOINES" },
  "50361": { state: "IA", city: "DES MOINES" },
  "50362": { state: "IA", city: "DES MOINES" },
  "50363": { state: "IA", city: "DES MOINES" },
  "50364": { state: "IA", city: "DES MOINES" },
  "50367": { state: "IA", city: "DES MOINES" },
  "50368": { state: "IA", city: "DES MOINES" },
  "50369": { state: "IA", city: "DES MOINES" },
  "50380": { state: "IA", city: "DES MOINES" },
  "50381": { state: "IA", city: "DES MOINES" },
  "50391": { state: "IA", city: "DES MOINES" },
  "50392": { state: "IA", city: "DES MOINES" },
  "50393": { state: "IA", city: "DES MOINES" },
  "50394": { state: "IA", city: "DES MOINES" },
  "50395": { state: "IA", city: "DES MOINES" },
  "50396": { state: "IA", city: "DES MOINES" },
  "50398": { state: "IA", city: "URBANDALE" },
  "50401": { state: "IA", city: "MASON CITY" },
  "50402": { state: "IA", city: "MASON CITY" },
  "50420": { state: "IA", city: "ALEXANDER" },
  "50421": { state: "IA", city: "BELMOND" },
  "50423": { state: "IA", city: "BRITT" },
  "50424": { state: "IA", city: "BUFFALO CENTER" },
  "50426": { state: "IA", city: "CARPENTER" },
  "50427": { state: "IA", city: "CHAPIN" },
  "50428": { state: "IA", city: "CLEAR LAKE" },
  "50430": { state: "IA", city: "CORWITH" },
  "50431": { state: "IA", city: "COULTER" },
  "50432": { state: "IA", city: "CRYSTAL LAKE" },
  "50433": { state: "IA", city: "DOUGHERTY" },
  "50434": { state: "IA", city: "FERTILE" },
  "50435": { state: "IA", city: "FLOYD" },
  "50436": { state: "IA", city: "FOREST CITY" },
  "50438": { state: "IA", city: "GARNER" },
  "50439": { state: "IA", city: "GOODELL" },
  "50440": { state: "IA", city: "GRAFTON" },
  "50441": { state: "IA", city: "HAMPTON" },
  "50444": { state: "IA", city: "HANLONTOWN" },
  "50446": { state: "IA", city: "JOICE" },
  "50447": { state: "IA", city: "KANAWHA" },
  "50448": { state: "IA", city: "KENSETT" },
  "50449": { state: "IA", city: "KLEMME" },
  "50450": { state: "IA", city: "LAKE MILLS" },
  "50451": { state: "IA", city: "LAKOTA" },
  "50452": { state: "IA", city: "LATIMER" },
  "50453": { state: "IA", city: "LELAND" },
  "50454": { state: "IA", city: "LITTLE CEDAR" },
  "50455": { state: "IA", city: "MC INTIRE" },
  "50456": { state: "IA", city: "MANLY" },
  "50457": { state: "IA", city: "MESERVEY" },
  "50458": { state: "IA", city: "NORA SPRINGS" },
  "50459": { state: "IA", city: "NORTHWOOD" },
  "50460": { state: "IA", city: "ORCHARD" },
  "50461": { state: "IA", city: "OSAGE" },
  "50464": { state: "IA", city: "PLYMOUTH" },
  "50465": { state: "IA", city: "RAKE" },
  "50466": { state: "IA", city: "RICEVILLE" },
  "50467": { state: "IA", city: "ROCK FALLS" },
  "50468": { state: "IA", city: "ROCKFORD" },
  "50469": { state: "IA", city: "ROCKWELL" },
  "50470": { state: "IA", city: "ROWAN" },
  "50471": { state: "IA", city: "RUDD" },
  "50472": { state: "IA", city: "SAINT ANSGAR" },
  "50473": { state: "IA", city: "SCARVILLE" },
  "50475": { state: "IA", city: "SHEFFIELD" },
  "50476": { state: "IA", city: "STACYVILLE" },
  "50477": { state: "IA", city: "SWALEDALE" },
  "50478": { state: "IA", city: "THOMPSON" },
  "50479": { state: "IA", city: "THORNTON" },
  "50480": { state: "IA", city: "TITONKA" },
  "50481": { state: "IA", city: "TOETERVILLE" },
  "50482": { state: "IA", city: "VENTURA" },
  "50483": { state: "IA", city: "WESLEY" },
  "50484": { state: "IA", city: "WODEN" },
  "50501": { state: "IA", city: "FORT DODGE" },
  "50510": { state: "IA", city: "ALBERT CITY" },
  "50511": { state: "IA", city: "ALGONA" },
  "50514": { state: "IA", city: "ARMSTRONG" },
  "50515": { state: "IA", city: "AYRSHIRE" },
  "50516": { state: "IA", city: "BADGER" },
  "50517": { state: "IA", city: "BANCROFT" },
  "50518": { state: "IA", city: "BARNUM" },
  "50519": { state: "IA", city: "BODE" },
  "50520": { state: "IA", city: "BRADGATE" },
  "50521": { state: "IA", city: "BURNSIDE" },
  "50522": { state: "IA", city: "BURT" },
  "50523": { state: "IA", city: "CALLENDER" },
  "50524": { state: "IA", city: "CLARE" },
  "50525": { state: "IA", city: "CLARION" },
  "50526": { state: "IA", city: "CLARION" },
  "50527": { state: "IA", city: "CURLEW" },
  "50528": { state: "IA", city: "CYLINDER" },
  "50529": { state: "IA", city: "DAKOTA CITY" },
  "50530": { state: "IA", city: "DAYTON" },
  "50531": { state: "IA", city: "DOLLIVER" },
  "50532": { state: "IA", city: "DUNCOMBE" },
  "50533": { state: "IA", city: "EAGLE GROVE" },
  "50535": { state: "IA", city: "EARLY" },
  "50536": { state: "IA", city: "EMMETSBURG" },
  "50538": { state: "IA", city: "FARNHAMVILLE" },
  "50539": { state: "IA", city: "FENTON" },
  "50540": { state: "IA", city: "FONDA" },
  "50541": { state: "IA", city: "GILMORE CITY" },
  "50542": { state: "IA", city: "GOLDFIELD" },
  "50543": { state: "IA", city: "GOWRIE" },
  "50544": { state: "IA", city: "HARCOURT" },
  "50545": { state: "IA", city: "HARDY" },
  "50546": { state: "IA", city: "HAVELOCK" },
  "50548": { state: "IA", city: "HUMBOLDT" },
  "50551": { state: "IA", city: "JOLLEY" },
  "50552": { state: "IA", city: "KNIERIM" },
  "50554": { state: "IA", city: "LAURENS" },
  "50556": { state: "IA", city: "LEDYARD" },
  "50557": { state: "IA", city: "LEHIGH" },
  "50558": { state: "IA", city: "LIVERMORE" },
  "50559": { state: "IA", city: "LONE ROCK" },
  "50560": { state: "IA", city: "LU VERNE" },
  "50561": { state: "IA", city: "LYTTON" },
  "50562": { state: "IA", city: "MALLARD" },
  "50563": { state: "IA", city: "MANSON" },
  "50565": { state: "IA", city: "MARATHON" },
  "50566": { state: "IA", city: "MOORLAND" },
  "50567": { state: "IA", city: "NEMAHA" },
  "50568": { state: "IA", city: "NEWELL" },
  "50569": { state: "IA", city: "OTHO" },
  "50570": { state: "IA", city: "OTTOSEN" },
  "50571": { state: "IA", city: "PALMER" },
  "50573": { state: "IA", city: "PLOVER" },
  "50574": { state: "IA", city: "POCAHONTAS" },
  "50575": { state: "IA", city: "POMEROY" },
  "50576": { state: "IA", city: "REMBRANDT" },
  "50577": { state: "IA", city: "RENWICK" },
  "50578": { state: "IA", city: "RINGSTED" },
  "50579": { state: "IA", city: "ROCKWELL CITY" },
  "50581": { state: "IA", city: "ROLFE" },
  "50582": { state: "IA", city: "RUTLAND" },
  "50583": { state: "IA", city: "SAC CITY" },
  "50585": { state: "IA", city: "SIOUX RAPIDS" },
  "50586": { state: "IA", city: "SOMERS" },
  "50588": { state: "IA", city: "STORM LAKE" },
  "50590": { state: "IA", city: "SWEA CITY" },
  "50591": { state: "IA", city: "THOR" },
  "50592": { state: "IA", city: "TRUESDALE" },
  "50593": { state: "IA", city: "VARINA" },
  "50594": { state: "IA", city: "VINCENT" },
  "50595": { state: "IA", city: "WEBSTER CITY" },
  "50597": { state: "IA", city: "WEST BEND" },
  "50598": { state: "IA", city: "WHITTEMORE" },
  "50599": { state: "IA", city: "WOOLSTOCK" },
  "50601": { state: "IA", city: "ACKLEY" },
  "50602": { state: "IA", city: "ALLISON" },
  "50603": { state: "IA", city: "ALTA VISTA" },
  "50604": { state: "IA", city: "APLINGTON" },
  "50605": { state: "IA", city: "AREDALE" },
  "50606": { state: "IA", city: "ARLINGTON" },
  "50607": { state: "IA", city: "AURORA" },
  "50608": { state: "IA", city: "AUSTINVILLE" },
  "50609": { state: "IA", city: "BEAMAN" },
  "50611": { state: "IA", city: "BRISTOW" },
  "50612": { state: "IA", city: "BUCKINGHAM" },
  "50613": { state: "IA", city: "CEDAR FALLS" },
  "50614": { state: "IA", city: "CEDAR FALLS" },
  "50616": { state: "IA", city: "CHARLES CITY" },
  "50619": { state: "IA", city: "CLARKSVILLE" },
  "50620": { state: "IA", city: "COLWELL" },
  "50621": { state: "IA", city: "CONRAD" },
  "50622": { state: "IA", city: "DENVER" },
  "50623": { state: "IA", city: "DEWAR" },
  "50624": { state: "IA", city: "DIKE" },
  "50625": { state: "IA", city: "DUMONT" },
  "50626": { state: "IA", city: "DUNKERTON" },
  "50627": { state: "IA", city: "ELDORA" },
  "50628": { state: "IA", city: "ELMA" },
  "50629": { state: "IA", city: "FAIRBANK" },
  "50630": { state: "IA", city: "FREDERICKSBURG" },
  "50631": { state: "IA", city: "FREDERIKA" },
  "50632": { state: "IA", city: "GARWIN" },
  "50633": { state: "IA", city: "GENEVA" },
  "50634": { state: "IA", city: "GILBERTVILLE" },
  "50635": { state: "IA", city: "GLADBROOK" },
  "50636": { state: "IA", city: "GREENE" },
  "50638": { state: "IA", city: "GRUNDY CENTER" },
  "50641": { state: "IA", city: "HAZLETON" },
  "50642": { state: "IA", city: "HOLLAND" },
  "50643": { state: "IA", city: "HUDSON" },
  "50644": { state: "IA", city: "INDEPENDENCE" },
  "50645": { state: "IA", city: "IONIA" },
  "50647": { state: "IA", city: "JANESVILLE" },
  "50648": { state: "IA", city: "JESUP" },
  "50649": { state: "IA", city: "KESLEY" },
  "50650": { state: "IA", city: "LAMONT" },
  "50651": { state: "IA", city: "LA PORTE CITY" },
  "50652": { state: "IA", city: "LINCOLN" },
  "50653": { state: "IA", city: "MARBLE ROCK" },
  "50654": { state: "IA", city: "MASONVILLE" },
  "50655": { state: "IA", city: "MAYNARD" },
  "50657": { state: "IA", city: "MORRISON" },
  "50658": { state: "IA", city: "NASHUA" },
  "50659": { state: "IA", city: "NEW HAMPTON" },
  "50660": { state: "IA", city: "NEW HARTFORD" },
  "50661": { state: "IA", city: "NORTH WASHINGTON" },
  "50662": { state: "IA", city: "OELWEIN" },
  "50664": { state: "IA", city: "ORAN" },
  "50665": { state: "IA", city: "PARKERSBURG" },
  "50666": { state: "IA", city: "PLAINFIELD" },
  "50667": { state: "IA", city: "RAYMOND" },
  "50668": { state: "IA", city: "READLYN" },
  "50669": { state: "IA", city: "REINBECK" },
  "50670": { state: "IA", city: "SHELL ROCK" },
  "50671": { state: "IA", city: "STANLEY" },
  "50672": { state: "IA", city: "STEAMBOAT ROCK" },
  "50673": { state: "IA", city: "STOUT" },
  "50674": { state: "IA", city: "SUMNER" },
  "50675": { state: "IA", city: "TRAER" },
  "50676": { state: "IA", city: "TRIPOLI" },
  "50677": { state: "IA", city: "WAVERLY" },
  "50680": { state: "IA", city: "WELLSBURG" },
  "50681": { state: "IA", city: "WESTGATE" },
  "50682": { state: "IA", city: "WINTHROP" },
  "50701": { state: "IA", city: "WATERLOO" },
  "50702": { state: "IA", city: "WATERLOO" },
  "50703": { state: "IA", city: "WATERLOO" },
  "50704": { state: "IA", city: "WATERLOO" },
  "50707": { state: "IA", city: "EVANSDALE" },
  "50801": { state: "IA", city: "CRESTON" },
  "50830": { state: "IA", city: "AFTON" },
  "50831": { state: "IA", city: "ARISPE" },
  "50833": { state: "IA", city: "BEDFORD" },
  "50835": { state: "IA", city: "BENTON" },
  "50836": { state: "IA", city: "BLOCKTON" },
  "50837": { state: "IA", city: "BRIDGEWATER" },
  "50839": { state: "IA", city: "CARBON" },
  "50840": { state: "IA", city: "CLEARFIELD" },
  "50841": { state: "IA", city: "CORNING" },
  "50842": { state: "IA", city: "CROMWELL" },
  "50843": { state: "IA", city: "CUMBERLAND" },
  "50845": { state: "IA", city: "DIAGONAL" },
  "50846": { state: "IA", city: "FONTANELLE" },
  "50847": { state: "IA", city: "GRANT" },
  "50848": { state: "IA", city: "GRAVITY" },
  "50849": { state: "IA", city: "GREENFIELD" },
  "50851": { state: "IA", city: "LENOX" },
  "50853": { state: "IA", city: "MASSENA" },
  "50854": { state: "IA", city: "MOUNT AYR" },
  "50857": { state: "IA", city: "NODAWAY" },
  "50858": { state: "IA", city: "ORIENT" },
  "50859": { state: "IA", city: "PRESCOTT" },
  "50860": { state: "IA", city: "REDDING" },
  "50861": { state: "IA", city: "SHANNON CITY" },
  "50862": { state: "IA", city: "SHARPSBURG" },
  "50863": { state: "IA", city: "TINGLEY" },
  "50864": { state: "IA", city: "VILLISCA" },
  "50936": { state: "IA", city: "DES MOINES" },
  "50940": { state: "IA", city: "DES MOINES" },
  "50947": { state: "IA", city: "DES MOINES" },
  "50950": { state: "IA", city: "BOONE" },
  "50980": { state: "IA", city: "DES MOINES" },
  "50981": { state: "IA", city: "DES MOINES" },
  "50982": { state: "IA", city: "DES MOINES" },
  "50983": { state: "IA", city: "DES MOINES" },
  "51001": { state: "IA", city: "AKRON" },
  "51002": { state: "IA", city: "ALTA" },
  "51003": { state: "IA", city: "ALTON" },
  "51004": { state: "IA", city: "ANTHON" },
  "51005": { state: "IA", city: "AURELIA" },
  "51006": { state: "IA", city: "BATTLE CREEK" },
  "51007": { state: "IA", city: "BRONSON" },
  "51008": { state: "IA", city: "BRUNSVILLE" },
  "51009": { state: "IA", city: "CALUMET" },
  "51010": { state: "IA", city: "CASTANA" },
  "51011": { state: "IA", city: "CHATSWORTH" },
  "51012": { state: "IA", city: "CHEROKEE" },
  "51014": { state: "IA", city: "CLEGHORN" },
  "51015": { state: "IA", city: "CLIMBING HILL" },
  "51016": { state: "IA", city: "CORRECTIONVILLE" },
  "51018": { state: "IA", city: "CUSHING" },
  "51019": { state: "IA", city: "DANBURY" },
  "51020": { state: "IA", city: "GALVA" },
  "51022": { state: "IA", city: "GRANVILLE" },
  "51023": { state: "IA", city: "HAWARDEN" },
  "51024": { state: "IA", city: "HINTON" },
  "51025": { state: "IA", city: "HOLSTEIN" },
  "51026": { state: "IA", city: "HORNICK" },
  "51027": { state: "IA", city: "IRETON" },
  "51028": { state: "IA", city: "KINGSLEY" },
  "51029": { state: "IA", city: "LARRABEE" },
  "51030": { state: "IA", city: "LAWTON" },
  "51031": { state: "IA", city: "LE MARS" },
  "51033": { state: "IA", city: "LINN GROVE" },
  "51034": { state: "IA", city: "MAPLETON" },
  "51035": { state: "IA", city: "MARCUS" },
  "51036": { state: "IA", city: "MAURICE" },
  "51037": { state: "IA", city: "MERIDEN" },
  "51038": { state: "IA", city: "MERRILL" },
  "51039": { state: "IA", city: "MOVILLE" },
  "51040": { state: "IA", city: "ONAWA" },
  "51041": { state: "IA", city: "ORANGE CITY" },
  "51044": { state: "IA", city: "OTO" },
  "51045": { state: "IA", city: "OYENS" },
  "51046": { state: "IA", city: "PAULLINA" },
  "51047": { state: "IA", city: "PETERSON" },
  "51048": { state: "IA", city: "PIERSON" },
  "51049": { state: "IA", city: "QUIMBY" },
  "51050": { state: "IA", city: "REMSEN" },
  "51051": { state: "IA", city: "RODNEY" },
  "51052": { state: "IA", city: "SALIX" },
  "51053": { state: "IA", city: "SCHALLER" },
  "51054": { state: "IA", city: "SERGEANT BLUFF" },
  "51055": { state: "IA", city: "SLOAN" },
  "51056": { state: "IA", city: "SMITHLAND" },
  "51058": { state: "IA", city: "SUTHERLAND" },
  "51060": { state: "IA", city: "UTE" },
  "51061": { state: "IA", city: "WASHTA" },
  "51062": { state: "IA", city: "WESTFIELD" },
  "51063": { state: "IA", city: "WHITING" },
  "51101": { state: "IA", city: "SIOUX CITY" },
  "51102": { state: "IA", city: "SIOUX CITY" },
  "51103": { state: "IA", city: "SIOUX CITY" },
  "51104": { state: "IA", city: "SIOUX CITY" },
  "51105": { state: "IA", city: "SIOUX CITY" },
  "51106": { state: "IA", city: "SIOUX CITY" },
  "51108": { state: "IA", city: "SIOUX CITY" },
  "51109": { state: "IA", city: "SIOUX CITY" },
  "51111": { state: "IA", city: "SIOUX CITY" },
  "51201": { state: "IA", city: "SHELDON" },
  "51230": { state: "IA", city: "ALVORD" },
  "51231": { state: "IA", city: "ARCHER" },
  "51232": { state: "IA", city: "ASHTON" },
  "51234": { state: "IA", city: "BOYDEN" },
  "51235": { state: "IA", city: "DOON" },
  "51237": { state: "IA", city: "GEORGE" },
  "51238": { state: "IA", city: "HOSPERS" },
  "51239": { state: "IA", city: "HULL" },
  "51240": { state: "IA", city: "INWOOD" },
  "51241": { state: "IA", city: "LARCHWOOD" },
  "51242": { state: "IA", city: "LESTER" },
  "51243": { state: "IA", city: "LITTLE ROCK" },
  "51244": { state: "IA", city: "MATLOCK" },
  "51245": { state: "IA", city: "PRIMGHAR" },
  "51246": { state: "IA", city: "ROCK RAPIDS" },
  "51247": { state: "IA", city: "ROCK VALLEY" },
  "51248": { state: "IA", city: "SANBORN" },
  "51249": { state: "IA", city: "SIBLEY" },
  "51250": { state: "IA", city: "SIOUX CENTER" },
  "51301": { state: "IA", city: "SPENCER" },
  "51331": { state: "IA", city: "ARNOLDS PARK" },
  "51333": { state: "IA", city: "DICKENS" },
  "51334": { state: "IA", city: "ESTHERVILLE" },
  "51338": { state: "IA", city: "EVERLY" },
  "51340": { state: "IA", city: "FOSTORIA" },
  "51341": { state: "IA", city: "GILLETT GROVE" },
  "51342": { state: "IA", city: "GRAETTINGER" },
  "51343": { state: "IA", city: "GREENVILLE" },
  "51345": { state: "IA", city: "HARRIS" },
  "51346": { state: "IA", city: "HARTLEY" },
  "51347": { state: "IA", city: "LAKE PARK" },
  "51350": { state: "IA", city: "MELVIN" },
  "51351": { state: "IA", city: "MILFORD" },
  "51354": { state: "IA", city: "OCHEYEDAN" },
  "51355": { state: "IA", city: "OKOBOJI" },
  "51357": { state: "IA", city: "ROYAL" },
  "51358": { state: "IA", city: "RUTHVEN" },
  "51360": { state: "IA", city: "SPIRIT LAKE" },
  "51363": { state: "IA", city: "SUPERIOR" },
  "51364": { state: "IA", city: "TERRIL" },
  "51365": { state: "IA", city: "WALLINGFORD" },
  "51366": { state: "IA", city: "WEBB" },
  "51401": { state: "IA", city: "CARROLL" },
  "51430": { state: "IA", city: "ARCADIA" },
  "51431": { state: "IA", city: "ARTHUR" },
  "51432": { state: "IA", city: "ASPINWALL" },
  "51433": { state: "IA", city: "AUBURN" },
  "51436": { state: "IA", city: "BREDA" },
  "51439": { state: "IA", city: "CHARTER OAK" },
  "51440": { state: "IA", city: "DEDHAM" },
  "51441": { state: "IA", city: "DELOIT" },
  "51442": { state: "IA", city: "DENISON" },
  "51443": { state: "IA", city: "GLIDDEN" },
  "51444": { state: "IA", city: "HALBUR" },
  "51445": { state: "IA", city: "IDA GROVE" },
  "51446": { state: "IA", city: "IRWIN" },
  "51447": { state: "IA", city: "KIRKMAN" },
  "51448": { state: "IA", city: "KIRON" },
  "51449": { state: "IA", city: "LAKE CITY" },
  "51450": { state: "IA", city: "LAKE VIEW" },
  "51451": { state: "IA", city: "LANESBORO" },
  "51452": { state: "IA", city: "LIDDERDALE" },
  "51453": { state: "IA", city: "LOHRVILLE" },
  "51454": { state: "IA", city: "MANILLA" },
  "51455": { state: "IA", city: "MANNING" },
  "51458": { state: "IA", city: "ODEBOLT" },
  "51459": { state: "IA", city: "RALSTON" },
  "51460": { state: "IA", city: "RICKETTS" },
  "51461": { state: "IA", city: "SCHLESWIG" },
  "51462": { state: "IA", city: "SCRANTON" },
  "51463": { state: "IA", city: "TEMPLETON" },
  "51465": { state: "IA", city: "VAIL" },
  "51466": { state: "IA", city: "WALL LAKE" },
  "51467": { state: "IA", city: "WESTSIDE" },
  "51501": { state: "IA", city: "COUNCIL BLUFFS" },
  "51502": { state: "IA", city: "COUNCIL BLUFFS" },
  "51503": { state: "IA", city: "COUNCIL BLUFFS" },
  "51510": { state: "IA", city: "CARTER LAKE" },
  "51520": { state: "IA", city: "ARION" },
  "51521": { state: "IA", city: "AVOCA" },
  "51523": { state: "IA", city: "BLENCOE" },
  "51525": { state: "IA", city: "CARSON" },
  "51526": { state: "IA", city: "CRESCENT" },
  "51527": { state: "IA", city: "DEFIANCE" },
  "51528": { state: "IA", city: "DOW CITY" },
  "51529": { state: "IA", city: "DUNLAP" },
  "51530": { state: "IA", city: "EARLING" },
  "51531": { state: "IA", city: "ELK HORN" },
  "51532": { state: "IA", city: "ELLIOTT" },
  "51533": { state: "IA", city: "EMERSON" },
  "51534": { state: "IA", city: "GLENWOOD" },
  "51535": { state: "IA", city: "GRISWOLD" },
  "51536": { state: "IA", city: "HANCOCK" },
  "51537": { state: "IA", city: "HARLAN" },
  "51540": { state: "IA", city: "HASTINGS" },
  "51541": { state: "IA", city: "HENDERSON" },
  "51542": { state: "IA", city: "HONEY CREEK" },
  "51543": { state: "IA", city: "KIMBALLTON" },
  "51544": { state: "IA", city: "LEWIS" },
  "51545": { state: "IA", city: "LITTLE SIOUX" },
  "51546": { state: "IA", city: "LOGAN" },
  "51548": { state: "IA", city: "MC CLELLAND" },
  "51549": { state: "IA", city: "MACEDONIA" },
  "51550": { state: "IA", city: "MAGNOLIA" },
  "51551": { state: "IA", city: "MALVERN" },
  "51552": { state: "IA", city: "MARNE" },
  "51553": { state: "IA", city: "MINDEN" },
  "51554": { state: "IA", city: "MINEOLA" },
  "51555": { state: "IA", city: "MISSOURI VALLEY" },
  "51556": { state: "IA", city: "MODALE" },
  "51557": { state: "IA", city: "MONDAMIN" },
  "51558": { state: "IA", city: "MOORHEAD" },
  "51559": { state: "IA", city: "NEOLA" },
  "51560": { state: "IA", city: "OAKLAND" },
  "51561": { state: "IA", city: "PACIFIC JUNCTION" },
  "51562": { state: "IA", city: "PANAMA" },
  "51563": { state: "IA", city: "PERSIA" },
  "51564": { state: "IA", city: "PISGAH" },
  "51565": { state: "IA", city: "PORTSMOUTH" },
  "51566": { state: "IA", city: "RED OAK" },
  "51570": { state: "IA", city: "SHELBY" },
  "51571": { state: "IA", city: "SILVER CITY" },
  "51572": { state: "IA", city: "SOLDIER" },
  "51573": { state: "IA", city: "STANTON" },
  "51575": { state: "IA", city: "TREYNOR" },
  "51576": { state: "IA", city: "UNDERWOOD" },
  "51577": { state: "IA", city: "WALNUT" },
  "51578": { state: "IA", city: "WESTPHALIA" },
  "51579": { state: "IA", city: "WOODBINE" },
  "51591": { state: "IA", city: "RED OAK" },
  "51593": { state: "IA", city: "HARLAN" },
  "51601": { state: "IA", city: "SHENANDOAH" },
  "51603": { state: "IA", city: "SHENANDOAH" },
  "51630": { state: "IA", city: "BLANCHARD" },
  "51631": { state: "IA", city: "BRADDYVILLE" },
  "51632": { state: "IA", city: "CLARINDA" },
  "51636": { state: "IA", city: "COIN" },
  "51637": { state: "IA", city: "COLLEGE SPRINGS" },
  "51638": { state: "IA", city: "ESSEX" },
  "51639": { state: "IA", city: "FARRAGUT" },
  "51640": { state: "IA", city: "HAMBURG" },
  "51645": { state: "IA", city: "IMOGENE" },
  "51646": { state: "IA", city: "NEW MARKET" },
  "51647": { state: "IA", city: "NORTHBORO" },
  "51648": { state: "IA", city: "PERCIVAL" },
  "51649": { state: "IA", city: "RANDOLPH" },
  "51650": { state: "IA", city: "RIVERTON" },
  "51651": { state: "IA", city: "SHAMBAUGH" },
  "51652": { state: "IA", city: "SIDNEY" },
  "51653": { state: "IA", city: "TABOR" },
  "51654": { state: "IA", city: "THURMAN" },
  "51656": { state: "IA", city: "YORKTOWN" },
  "52001": { state: "IA", city: "DUBUQUE" },
  "52002": { state: "IA", city: "DUBUQUE" },
  "52003": { state: "IA", city: "DUBUQUE" },
  "52004": { state: "IA", city: "DUBUQUE" },
  "52030": { state: "IA", city: "ANDREW" },
  "52031": { state: "IA", city: "BELLEVUE" },
  "52032": { state: "IA", city: "BERNARD" },
  "52033": { state: "IA", city: "CASCADE" },
  "52035": { state: "IA", city: "COLESBURG" },
  "52036": { state: "IA", city: "DELAWARE" },
  "52037": { state: "IA", city: "DELMAR" },
  "52038": { state: "IA", city: "DUNDEE" },
  "52039": { state: "IA", city: "DURANGO" },
  "52040": { state: "IA", city: "DYERSVILLE" },
  "52041": { state: "IA", city: "EARLVILLE" },
  "52042": { state: "IA", city: "EDGEWOOD" },
  "52043": { state: "IA", city: "ELKADER" },
  "52044": { state: "IA", city: "ELKPORT" },
  "52045": { state: "IA", city: "EPWORTH" },
  "52046": { state: "IA", city: "FARLEY" },
  "52047": { state: "IA", city: "FARMERSBURG" },
  "52048": { state: "IA", city: "GARBER" },
  "52049": { state: "IA", city: "GARNAVILLO" },
  "52050": { state: "IA", city: "GREELEY" },
  "52052": { state: "IA", city: "GUTTENBERG" },
  "52053": { state: "IA", city: "HOLY CROSS" },
  "52054": { state: "IA", city: "LA MOTTE" },
  "52056": { state: "IA", city: "LUXEMBURG" },
  "52057": { state: "IA", city: "MANCHESTER" },
  "52060": { state: "IA", city: "MAQUOKETA" },
  "52064": { state: "IA", city: "MILES" },
  "52065": { state: "IA", city: "NEW VIENNA" },
  "52066": { state: "IA", city: "NORTH BUENA VISTA" },
  "52068": { state: "IA", city: "PEOSTA" },
  "52069": { state: "IA", city: "PRESTON" },
  "52070": { state: "IA", city: "SABULA" },
  "52071": { state: "IA", city: "SAINT DONATUS" },
  "52072": { state: "IA", city: "SAINT OLAF" },
  "52073": { state: "IA", city: "SHERRILL" },
  "52074": { state: "IA", city: "SPRAGUEVILLE" },
  "52075": { state: "IA", city: "SPRINGBROOK" },
  "52076": { state: "IA", city: "STRAWBERRY POINT" },
  "52077": { state: "IA", city: "VOLGA" },
  "52078": { state: "IA", city: "WORTHINGTON" },
  "52079": { state: "IA", city: "ZWINGLE" },
  "52099": { state: "IA", city: "DUBUQUE" },
  "52101": { state: "IA", city: "DECORAH" },
  "52132": { state: "IA", city: "CALMAR" },
  "52133": { state: "IA", city: "CASTALIA" },
  "52134": { state: "IA", city: "CHESTER" },
  "52135": { state: "IA", city: "CLERMONT" },
  "52136": { state: "IA", city: "CRESCO" },
  "52140": { state: "IA", city: "DORCHESTER" },
  "52141": { state: "IA", city: "ELGIN" },
  "52142": { state: "IA", city: "FAYETTE" },
  "52144": { state: "IA", city: "FORT ATKINSON" },
  "52146": { state: "IA", city: "HARPERS FERRY" },
  "52147": { state: "IA", city: "HAWKEYE" },
  "52149": { state: "IA", city: "HIGHLANDVILLE" },
  "52151": { state: "IA", city: "LANSING" },
  "52154": { state: "IA", city: "LAWLER" },
  "52155": { state: "IA", city: "LIME SPRINGS" },
  "52156": { state: "IA", city: "LUANA" },
  "52157": { state: "IA", city: "MC GREGOR" },
  "52158": { state: "IA", city: "MARQUETTE" },
  "52159": { state: "IA", city: "MONONA" },
  "52160": { state: "IA", city: "NEW ALBIN" },
  "52161": { state: "IA", city: "OSSIAN" },
  "52162": { state: "IA", city: "POSTVILLE" },
  "52163": { state: "IA", city: "PROTIVIN" },
  "52164": { state: "IA", city: "RANDALIA" },
  "52165": { state: "IA", city: "RIDGEWAY" },
  "52166": { state: "IA", city: "SAINT LUCAS" },
  "52168": { state: "IA", city: "SPILLVILLE" },
  "52169": { state: "IA", city: "WADENA" },
  "52170": { state: "IA", city: "WATERVILLE" },
  "52171": { state: "IA", city: "WAUCOMA" },
  "52172": { state: "IA", city: "WAUKON" },
  "52175": { state: "IA", city: "WEST UNION" },
  "52201": { state: "IA", city: "AINSWORTH" },
  "52202": { state: "IA", city: "ALBURNETT" },
  "52203": { state: "IA", city: "AMANA" },
  "52204": { state: "IA", city: "AMANA" },
  "52205": { state: "IA", city: "ANAMOSA" },
  "52206": { state: "IA", city: "ATKINS" },
  "52207": { state: "IA", city: "BALDWIN" },
  "52208": { state: "IA", city: "BELLE PLAINE" },
  "52209": { state: "IA", city: "BLAIRSTOWN" },
  "52210": { state: "IA", city: "BRANDON" },
  "52211": { state: "IA", city: "BROOKLYN" },
  "52212": { state: "IA", city: "CENTER JUNCTION" },
  "52213": { state: "IA", city: "CENTER POINT" },
  "52214": { state: "IA", city: "CENTRAL CITY" },
  "52215": { state: "IA", city: "CHELSEA" },
  "52216": { state: "IA", city: "CLARENCE" },
  "52217": { state: "IA", city: "CLUTIER" },
  "52218": { state: "IA", city: "COGGON" },
  "52219": { state: "IA", city: "PRAIRIEBURG" },
  "52220": { state: "IA", city: "CONROY" },
  "52221": { state: "IA", city: "GUERNSEY" },
  "52222": { state: "IA", city: "DEEP RIVER" },
  "52223": { state: "IA", city: "DELHI" },
  "52224": { state: "IA", city: "DYSART" },
  "52225": { state: "IA", city: "ELBERON" },
  "52227": { state: "IA", city: "ELY" },
  "52228": { state: "IA", city: "FAIRFAX" },
  "52229": { state: "IA", city: "GARRISON" },
  "52231": { state: "IA", city: "HARPER" },
  "52232": { state: "IA", city: "HARTWICK" },
  "52233": { state: "IA", city: "HIAWATHA" },
  "52235": { state: "IA", city: "HILLS" },
  "52236": { state: "IA", city: "HOMESTEAD" },
  "52237": { state: "IA", city: "HOPKINTON" },
  "52240": { state: "IA", city: "IOWA CITY" },
  "52241": { state: "IA", city: "CORALVILLE" },
  "52242": { state: "IA", city: "IOWA CITY" },
  "52243": { state: "IA", city: "IOWA CITY" },
  "52244": { state: "IA", city: "IOWA CITY" },
  "52245": { state: "IA", city: "IOWA CITY" },
  "52246": { state: "IA", city: "IOWA CITY" },
  "52247": { state: "IA", city: "KALONA" },
  "52248": { state: "IA", city: "KEOTA" },
  "52249": { state: "IA", city: "KEYSTONE" },
  "52251": { state: "IA", city: "LADORA" },
  "52252": { state: "IA", city: "LANGWORTHY" },
  "52253": { state: "IA", city: "LISBON" },
  "52254": { state: "IA", city: "LOST NATION" },
  "52255": { state: "IA", city: "LOWDEN" },
  "52257": { state: "IA", city: "LUZERNE" },
  "52301": { state: "IA", city: "MARENGO" },
  "52302": { state: "IA", city: "MARION" },
  "52305": { state: "IA", city: "MARTELLE" },
  "52306": { state: "IA", city: "MECHANICSVILLE" },
  "52307": { state: "IA", city: "MIDDLE AMANA" },
  "52308": { state: "IA", city: "MILLERSBURG" },
  "52309": { state: "IA", city: "MONMOUTH" },
  "52310": { state: "IA", city: "MONTICELLO" },
  "52312": { state: "IA", city: "MORLEY" },
  "52313": { state: "IA", city: "MOUNT AUBURN" },
  "52314": { state: "IA", city: "MOUNT VERNON" },
  "52315": { state: "IA", city: "NEWHALL" },
  "52316": { state: "IA", city: "NORTH ENGLISH" },
  "52317": { state: "IA", city: "NORTH LIBERTY" },
  "52318": { state: "IA", city: "NORWAY" },
  "52320": { state: "IA", city: "OLIN" },
  "52321": { state: "IA", city: "ONSLOW" },
  "52322": { state: "IA", city: "OXFORD" },
  "52323": { state: "IA", city: "OXFORD JUNCTION" },
  "52324": { state: "IA", city: "PALO" },
  "52325": { state: "IA", city: "PARNELL" },
  "52326": { state: "IA", city: "QUASQUETON" },
  "52327": { state: "IA", city: "RIVERSIDE" },
  "52328": { state: "IA", city: "ROBINS" },
  "52329": { state: "IA", city: "ROWLEY" },
  "52330": { state: "IA", city: "RYAN" },
  "52332": { state: "IA", city: "SHELLSBURG" },
  "52333": { state: "IA", city: "SOLON" },
  "52334": { state: "IA", city: "SOUTH AMANA" },
  "52335": { state: "IA", city: "SOUTH ENGLISH" },
  "52336": { state: "IA", city: "SPRINGVILLE" },
  "52337": { state: "IA", city: "STANWOOD" },
  "52338": { state: "IA", city: "SWISHER" },
  "52339": { state: "IA", city: "TAMA" },
  "52340": { state: "IA", city: "TIFFIN" },
  "52341": { state: "IA", city: "TODDVILLE" },
  "52342": { state: "IA", city: "TOLEDO" },
  "52344": { state: "IA", city: "TROY MILLS" },
  "52345": { state: "IA", city: "URBANA" },
  "52346": { state: "IA", city: "VAN HORNE" },
  "52347": { state: "IA", city: "VICTOR" },
  "52348": { state: "IA", city: "VINING" },
  "52349": { state: "IA", city: "VINTON" },
  "52351": { state: "IA", city: "WALFORD" },
  "52352": { state: "IA", city: "WALKER" },
  "52353": { state: "IA", city: "WASHINGTON" },
  "52354": { state: "IA", city: "WATKINS" },
  "52355": { state: "IA", city: "WEBSTER" },
  "52356": { state: "IA", city: "WELLMAN" },
  "52358": { state: "IA", city: "WEST BRANCH" },
  "52359": { state: "IA", city: "WEST CHESTER" },
  "52361": { state: "IA", city: "WILLIAMSBURG" },
  "52362": { state: "IA", city: "WYOMING" },
  "52401": { state: "IA", city: "CEDAR RAPIDS" },
  "52402": { state: "IA", city: "CEDAR RAPIDS" },
  "52403": { state: "IA", city: "CEDAR RAPIDS" },
  "52404": { state: "IA", city: "CEDAR RAPIDS" },
  "52405": { state: "IA", city: "CEDAR RAPIDS" },
  "52406": { state: "IA", city: "CEDAR RAPIDS" },
  "52407": { state: "IA", city: "CEDAR RAPIDS" },
  "52408": { state: "IA", city: "CEDAR RAPIDS" },
  "52409": { state: "IA", city: "CEDAR RAPIDS" },
  "52410": { state: "IA", city: "CEDAR RAPIDS" },
  "52411": { state: "IA", city: "CEDAR RAPIDS" },
  "52497": { state: "IA", city: "CEDAR RAPIDS" },
  "52498": { state: "IA", city: "CEDAR RAPIDS" },
  "52499": { state: "IA", city: "CEDAR RAPIDS" },
  "52501": { state: "IA", city: "OTTUMWA" },
  "52530": { state: "IA", city: "AGENCY" },
  "52531": { state: "IA", city: "ALBIA" },
  "52533": { state: "IA", city: "BATAVIA" },
  "52534": { state: "IA", city: "BEACON" },
  "52535": { state: "IA", city: "BIRMINGHAM" },
  "52536": { state: "IA", city: "BLAKESBURG" },
  "52537": { state: "IA", city: "BLOOMFIELD" },
  "52540": { state: "IA", city: "BRIGHTON" },
  "52542": { state: "IA", city: "CANTRIL" },
  "52543": { state: "IA", city: "CEDAR" },
  "52544": { state: "IA", city: "CENTERVILLE" },
  "52548": { state: "IA", city: "CHILLICOTHE" },
  "52549": { state: "IA", city: "CINCINNATI" },
  "52550": { state: "IA", city: "DELTA" },
  "52551": { state: "IA", city: "DOUDS" },
  "52552": { state: "IA", city: "DRAKESVILLE" },
  "52553": { state: "IA", city: "EDDYVILLE" },
  "52554": { state: "IA", city: "ELDON" },
  "52555": { state: "IA", city: "EXLINE" },
  "52556": { state: "IA", city: "FAIRFIELD" },
  "52557": { state: "IA", city: "FAIRFIELD" },
  "52560": { state: "IA", city: "FLORIS" },
  "52561": { state: "IA", city: "FREMONT" },
  "52562": { state: "IA", city: "HAYESVILLE" },
  "52563": { state: "IA", city: "HEDRICK" },
  "52565": { state: "IA", city: "KEOSAUQUA" },
  "52566": { state: "IA", city: "KIRKVILLE" },
  "52567": { state: "IA", city: "LIBERTYVILLE" },
  "52568": { state: "IA", city: "MARTINSBURG" },
  "52569": { state: "IA", city: "MELROSE" },
  "52570": { state: "IA", city: "MILTON" },
  "52571": { state: "IA", city: "MORAVIA" },
  "52572": { state: "IA", city: "MOULTON" },
  "52573": { state: "IA", city: "MOUNT STERLING" },
  "52574": { state: "IA", city: "MYSTIC" },
  "52576": { state: "IA", city: "OLLIE" },
  "52577": { state: "IA", city: "OSKALOOSA" },
  "52580": { state: "IA", city: "PACKWOOD" },
  "52581": { state: "IA", city: "PLANO" },
  "52583": { state: "IA", city: "PROMISE CITY" },
  "52584": { state: "IA", city: "PULASKI" },
  "52585": { state: "IA", city: "RICHLAND" },
  "52586": { state: "IA", city: "ROSE HILL" },
  "52588": { state: "IA", city: "SELMA" },
  "52590": { state: "IA", city: "SEYMOUR" },
  "52591": { state: "IA", city: "SIGOURNEY" },
  "52593": { state: "IA", city: "UDELL" },
  "52594": { state: "IA", city: "UNIONVILLE" },
  "52595": { state: "IA", city: "UNIVERSITY PARK" },
  "52601": { state: "IA", city: "BURLINGTON" },
  "52619": { state: "IA", city: "ARGYLE" },
  "52620": { state: "IA", city: "BONAPARTE" },
  "52621": { state: "IA", city: "CRAWFORDSVILLE" },
  "52623": { state: "IA", city: "DANVILLE" },
  "52624": { state: "IA", city: "DENMARK" },
  "52625": { state: "IA", city: "DONNELLSON" },
  "52626": { state: "IA", city: "FARMINGTON" },
  "52627": { state: "IA", city: "FORT MADISON" },
  "52630": { state: "IA", city: "HILLSBORO" },
  "52631": { state: "IA", city: "HOUGHTON" },
  "52632": { state: "IA", city: "KEOKUK" },
  "52635": { state: "IA", city: "LOCKRIDGE" },
  "52637": { state: "IA", city: "MEDIAPOLIS" },
  "52638": { state: "IA", city: "MIDDLETOWN" },
  "52639": { state: "IA", city: "MONTROSE" },
  "52640": { state: "IA", city: "MORNING SUN" },
  "52641": { state: "IA", city: "MOUNT PLEASANT" },
  "52642": { state: "IA", city: "ROME" },
  "52644": { state: "IA", city: "MOUNT UNION" },
  "52645": { state: "IA", city: "NEW LONDON" },
  "52646": { state: "IA", city: "OAKVILLE" },
  "52647": { state: "IA", city: "OLDS" },
  "52648": { state: "IA", city: "PILOT GROVE" },
  "52649": { state: "IA", city: "SALEM" },
  "52650": { state: "IA", city: "SPERRY" },
  "52651": { state: "IA", city: "STOCKPORT" },
  "52652": { state: "IA", city: "SWEDESBURG" },
  "52653": { state: "IA", city: "WAPELLO" },
  "52654": { state: "IA", city: "WAYLAND" },
  "52655": { state: "IA", city: "WEST BURLINGTON" },
  "52656": { state: "IA", city: "WEST POINT" },
  "52657": { state: "IA", city: "SAINT PAUL" },
  "52658": { state: "IA", city: "WEVER" },
  "52659": { state: "IA", city: "WINFIELD" },
  "52660": { state: "IA", city: "YARMOUTH" },
  "52701": { state: "IA", city: "ANDOVER" },
  "52720": { state: "IA", city: "ATALISSA" },
  "52721": { state: "IA", city: "BENNETT" },
  "52722": { state: "IA", city: "BETTENDORF" },
  "52726": { state: "IA", city: "BLUE GRASS" },
  "52727": { state: "IA", city: "BRYANT" },
  "52728": { state: "IA", city: "BUFFALO" },
  "52729": { state: "IA", city: "CALAMUS" },
  "52730": { state: "IA", city: "CAMANCHE" },
  "52731": { state: "IA", city: "CHARLOTTE" },
  "52732": { state: "IA", city: "CLINTON" },
  "52733": { state: "IA", city: "CLINTON" },
  "52734": { state: "IA", city: "CLINTON" },
  "52736": { state: "IA", city: "CLINTON" },
  "52737": { state: "IA", city: "COLUMBUS CITY" },
  "52738": { state: "IA", city: "COLUMBUS JUNCTION" },
  "52739": { state: "IA", city: "CONESVILLE" },
  "52742": { state: "IA", city: "DE WITT" },
  "52745": { state: "IA", city: "DIXON" },
  "52746": { state: "IA", city: "DONAHUE" },
  "52747": { state: "IA", city: "DURANT" },
  "52748": { state: "IA", city: "ELDRIDGE" },
  "52749": { state: "IA", city: "FRUITLAND" },
  "52750": { state: "IA", city: "GOOSE LAKE" },
  "52751": { state: "IA", city: "GRAND MOUND" },
  "52752": { state: "IA", city: "GRANDVIEW" },
  "52753": { state: "IA", city: "LE CLAIRE" },
  "52754": { state: "IA", city: "LETTS" },
  "52755": { state: "IA", city: "LONE TREE" },
  "52756": { state: "IA", city: "LONG GROVE" },
  "52757": { state: "IA", city: "LOW MOOR" },
  "52758": { state: "IA", city: "MC CAUSLAND" },
  "52759": { state: "IA", city: "MONTPELIER" },
  "52760": { state: "IA", city: "MOSCOW" },
  "52761": { state: "IA", city: "MUSCATINE" },
  "52765": { state: "IA", city: "NEW LIBERTY" },
  "52766": { state: "IA", city: "NICHOLS" },
  "52767": { state: "IA", city: "PLEASANT VALLEY" },
  "52768": { state: "IA", city: "PRINCETON" },
  "52769": { state: "IA", city: "STOCKTON" },
  "52771": { state: "IA", city: "TEEDS GROVE" },
  "52772": { state: "IA", city: "TIPTON" },
  "52773": { state: "IA", city: "WALCOTT" },
  "52774": { state: "IA", city: "WELTON" },
  "52776": { state: "IA", city: "WEST LIBERTY" },
  "52777": { state: "IA", city: "WHEATLAND" },
  "52778": { state: "IA", city: "WILTON" },
  "52801": { state: "IA", city: "DAVENPORT" },
  "52802": { state: "IA", city: "DAVENPORT" },
  "52803": { state: "IA", city: "DAVENPORT" },
  "52804": { state: "IA", city: "DAVENPORT" },
  "52805": { state: "IA", city: "DAVENPORT" },
  "52806": { state: "IA", city: "DAVENPORT" },
  "52807": { state: "IA", city: "DAVENPORT" },
  "52808": { state: "IA", city: "DAVENPORT" },
  "52809": { state: "IA", city: "DAVENPORT" },
  "53001": { state: "WI", city: "ADELL" },
  "53002": { state: "WI", city: "ALLENTON" },
  "53003": { state: "WI", city: "ASHIPPUN" },
  "53004": { state: "WI", city: "BELGIUM" },
  "53005": { state: "WI", city: "BROOKFIELD" },
  "53006": { state: "WI", city: "BROWNSVILLE" },
  "53007": { state: "WI", city: "BUTLER" },
  "53008": { state: "WI", city: "BROOKFIELD" },
  "53010": { state: "WI", city: "CAMPBELLSPORT" },
  "53011": { state: "WI", city: "CASCADE" },
  "53012": { state: "WI", city: "CEDARBURG" },
  "53013": { state: "WI", city: "CEDAR GROVE" },
  "53014": { state: "WI", city: "CHILTON" },
  "53015": { state: "WI", city: "CLEVELAND" },
  "53016": { state: "WI", city: "CLYMAN" },
  "53017": { state: "WI", city: "COLGATE" },
  "53018": { state: "WI", city: "DELAFIELD" },
  "53019": { state: "WI", city: "EDEN" },
  "53020": { state: "WI", city: "ELKHART LAKE" },
  "53021": { state: "WI", city: "FREDONIA" },
  "53022": { state: "WI", city: "GERMANTOWN" },
  "53023": { state: "WI", city: "GLENBEULAH" },
  "53024": { state: "WI", city: "GRAFTON" },
  "53026": { state: "WI", city: "GREENBUSH" },
  "53027": { state: "WI", city: "HARTFORD" },
  "53029": { state: "WI", city: "HARTLAND" },
  "53031": { state: "WI", city: "HINGHAM" },
  "53032": { state: "WI", city: "HORICON" },
  "53033": { state: "WI", city: "HUBERTUS" },
  "53034": { state: "WI", city: "HUSTISFORD" },
  "53035": { state: "WI", city: "IRON RIDGE" },
  "53036": { state: "WI", city: "IXONIA" },
  "53037": { state: "WI", city: "JACKSON" },
  "53038": { state: "WI", city: "JOHNSON CREEK" },
  "53039": { state: "WI", city: "JUNEAU" },
  "53040": { state: "WI", city: "KEWASKUM" },
  "53042": { state: "WI", city: "KIEL" },
  "53044": { state: "WI", city: "KOHLER" },
  "53045": { state: "WI", city: "BROOKFIELD" },
  "53046": { state: "WI", city: "LANNON" },
  "53047": { state: "WI", city: "LEBANON" },
  "53048": { state: "WI", city: "LOMIRA" },
  "53049": { state: "WI", city: "MALONE" },
  "53050": { state: "WI", city: "MAYVILLE" },
  "53051": { state: "WI", city: "MENOMONEE FALLS" },
  "53052": { state: "WI", city: "MENOMONEE FALLS" },
  "53056": { state: "WI", city: "MERTON" },
  "53057": { state: "WI", city: "MOUNT CALVARY" },
  "53058": { state: "WI", city: "NASHOTAH" },
  "53059": { state: "WI", city: "NEOSHO" },
  "53060": { state: "WI", city: "NEWBURG" },
  "53061": { state: "WI", city: "NEW HOLSTEIN" },
  "53062": { state: "WI", city: "NEW HOLSTEIN" },
  "53063": { state: "WI", city: "NEWTON" },
  "53064": { state: "WI", city: "NORTH LAKE" },
  "53065": { state: "WI", city: "OAKFIELD" },
  "53066": { state: "WI", city: "OCONOMOWOC" },
  "53069": { state: "WI", city: "OKAUCHEE" },
  "53070": { state: "WI", city: "OOSTBURG" },
  "53072": { state: "WI", city: "PEWAUKEE" },
  "53073": { state: "WI", city: "PLYMOUTH" },
  "53074": { state: "WI", city: "PORT WASHINGTON" },
  "53075": { state: "WI", city: "RANDOM LAKE" },
  "53076": { state: "WI", city: "RICHFIELD" },
  "53078": { state: "WI", city: "RUBICON" },
  "53079": { state: "WI", city: "SAINT CLOUD" },
  "53080": { state: "WI", city: "SAUKVILLE" },
  "53081": { state: "WI", city: "SHEBOYGAN" },
  "53082": { state: "WI", city: "SHEBOYGAN" },
  "53083": { state: "WI", city: "SHEBOYGAN" },
  "53085": { state: "WI", city: "SHEBOYGAN FALLS" },
  "53086": { state: "WI", city: "SLINGER" },
  "53088": { state: "WI", city: "STOCKBRIDGE" },
  "53089": { state: "WI", city: "SUSSEX" },
  "53090": { state: "WI", city: "WEST BEND" },
  "53091": { state: "WI", city: "THERESA" },
  "53092": { state: "WI", city: "MEQUON" },
  "53093": { state: "WI", city: "WALDO" },
  "53094": { state: "WI", city: "WATERTOWN" },
  "53095": { state: "WI", city: "WEST BEND" },
  "53097": { state: "WI", city: "MEQUON" },
  "53098": { state: "WI", city: "WATERTOWN" },
  "53099": { state: "WI", city: "WOODLAND" },
  "53101": { state: "WI", city: "BASSETT" },
  "53102": { state: "WI", city: "BENET LAKE" },
  "53103": { state: "WI", city: "BIG BEND" },
  "53104": { state: "WI", city: "BRISTOL" },
  "53105": { state: "WI", city: "BURLINGTON" },
  "53108": { state: "WI", city: "CALEDONIA" },
  "53109": { state: "WI", city: "CAMP LAKE" },
  "53110": { state: "WI", city: "CUDAHY" },
  "53114": { state: "WI", city: "DARIEN" },
  "53115": { state: "WI", city: "DELAVAN" },
  "53118": { state: "WI", city: "DOUSMAN" },
  "53119": { state: "WI", city: "EAGLE" },
  "53120": { state: "WI", city: "EAST TROY" },
  "53121": { state: "WI", city: "ELKHORN" },
  "53122": { state: "WI", city: "ELM GROVE" },
  "53125": { state: "WI", city: "FONTANA" },
  "53126": { state: "WI", city: "FRANKSVILLE" },
  "53127": { state: "WI", city: "GENESEE DEPOT" },
  "53128": { state: "WI", city: "GENOA CITY" },
  "53129": { state: "WI", city: "GREENDALE" },
  "53130": { state: "WI", city: "HALES CORNERS" },
  "53132": { state: "WI", city: "FRANKLIN" },
  "53137": { state: "WI", city: "HELENVILLE" },
  "53138": { state: "WI", city: "HONEY CREEK" },
  "53139": { state: "WI", city: "KANSASVILLE" },
  "53140": { state: "WI", city: "KENOSHA" },
  "53141": { state: "WI", city: "KENOSHA" },
  "53142": { state: "WI", city: "KENOSHA" },
  "53143": { state: "WI", city: "KENOSHA" },
  "53144": { state: "WI", city: "KENOSHA" },
  "53146": { state: "WI", city: "NEW BERLIN" },
  "53147": { state: "WI", city: "LAKE GENEVA" },
  "53148": { state: "WI", city: "LYONS" },
  "53149": { state: "WI", city: "MUKWONAGO" },
  "53150": { state: "WI", city: "MUSKEGO" },
  "53151": { state: "WI", city: "NEW BERLIN" },
  "53152": { state: "WI", city: "NEW MUNSTER" },
  "53153": { state: "WI", city: "NORTH PRAIRIE" },
  "53154": { state: "WI", city: "OAK CREEK" },
  "53156": { state: "WI", city: "PALMYRA" },
  "53157": { state: "WI", city: "PELL LAKE" },
  "53158": { state: "WI", city: "PLEASANT PRAIRIE" },
  "53159": { state: "WI", city: "POWERS LAKE" },
  "53167": { state: "WI", city: "ROCHESTER" },
  "53168": { state: "WI", city: "SALEM" },
  "53170": { state: "WI", city: "SILVER LAKE" },
  "53171": { state: "WI", city: "SOMERS" },
  "53172": { state: "WI", city: "SOUTH MILWAUKEE" },
  "53176": { state: "WI", city: "SPRINGFIELD" },
  "53177": { state: "WI", city: "STURTEVANT" },
  "53178": { state: "WI", city: "SULLIVAN" },
  "53179": { state: "WI", city: "TREVOR" },
  "53181": { state: "WI", city: "TWIN LAKES" },
  "53182": { state: "WI", city: "UNION GROVE" },
  "53183": { state: "WI", city: "WALES" },
  "53184": { state: "WI", city: "WALWORTH" },
  "53185": { state: "WI", city: "WATERFORD" },
  "53186": { state: "WI", city: "WAUKESHA" },
  "53187": { state: "WI", city: "WAUKESHA" },
  "53188": { state: "WI", city: "WAUKESHA" },
  "53189": { state: "WI", city: "WAUKESHA" },
  "53190": { state: "WI", city: "WHITEWATER" },
  "53191": { state: "WI", city: "WILLIAMS BAY" },
  "53192": { state: "WI", city: "WILMOT" },
  "53194": { state: "WI", city: "WOODWORTH" },
  "53195": { state: "WI", city: "ZENDA" },
  "53201": { state: "WI", city: "MILWAUKEE" },
  "53202": { state: "WI", city: "MILWAUKEE" },
  "53203": { state: "WI", city: "MILWAUKEE" },
  "53204": { state: "WI", city: "MILWAUKEE" },
  "53205": { state: "WI", city: "MILWAUKEE" },
  "53206": { state: "WI", city: "MILWAUKEE" },
  "53207": { state: "WI", city: "MILWAUKEE" },
  "53208": { state: "WI", city: "MILWAUKEE" },
  "53209": { state: "WI", city: "MILWAUKEE" },
  "53210": { state: "WI", city: "MILWAUKEE" },
  "53211": { state: "WI", city: "MILWAUKEE" },
  "53212": { state: "WI", city: "MILWAUKEE" },
  "53213": { state: "WI", city: "MILWAUKEE" },
  "53214": { state: "WI", city: "MILWAUKEE" },
  "53215": { state: "WI", city: "MILWAUKEE" },
  "53216": { state: "WI", city: "MILWAUKEE" },
  "53217": { state: "WI", city: "MILWAUKEE" },
  "53218": { state: "WI", city: "MILWAUKEE" },
  "53219": { state: "WI", city: "MILWAUKEE" },
  "53220": { state: "WI", city: "MILWAUKEE" },
  "53221": { state: "WI", city: "MILWAUKEE" },
  "53222": { state: "WI", city: "MILWAUKEE" },
  "53223": { state: "WI", city: "MILWAUKEE" },
  "53224": { state: "WI", city: "MILWAUKEE" },
  "53225": { state: "WI", city: "MILWAUKEE" },
  "53226": { state: "WI", city: "MILWAUKEE" },
  "53227": { state: "WI", city: "MILWAUKEE" },
  "53228": { state: "WI", city: "MILWAUKEE" },
  "53233": { state: "WI", city: "MILWAUKEE" },
  "53234": { state: "WI", city: "MILWAUKEE" },
  "53235": { state: "WI", city: "SAINT FRANCIS" },
  "53237": { state: "WI", city: "MILWAUKEE" },
  "53259": { state: "WI", city: "MILWAUKEE" },
  "53263": { state: "WI", city: "MILWAUKEE" },
  "53274": { state: "WI", city: "MILWAUKEE" },
  "53278": { state: "WI", city: "MILWAUKEE" },
  "53288": { state: "WI", city: "MILWAUKEE" },
  "53290": { state: "WI", city: "MILWAUKEE" },
  "53293": { state: "WI", city: "MILWAUKEE" },
  "53295": { state: "WI", city: "MILWAUKEE" },
  "53401": { state: "WI", city: "RACINE" },
  "53402": { state: "WI", city: "RACINE" },
  "53403": { state: "WI", city: "RACINE" },
  "53404": { state: "WI", city: "RACINE" },
  "53405": { state: "WI", city: "RACINE" },
  "53406": { state: "WI", city: "RACINE" },
  "53407": { state: "WI", city: "RACINE" },
  "53408": { state: "WI", city: "RACINE" },
  "53501": { state: "WI", city: "AFTON" },
  "53502": { state: "WI", city: "ALBANY" },
  "53503": { state: "WI", city: "ARENA" },
  "53504": { state: "WI", city: "ARGYLE" },
  "53505": { state: "WI", city: "AVALON" },
  "53506": { state: "WI", city: "AVOCA" },
  "53507": { state: "WI", city: "BARNEVELD" },
  "53508": { state: "WI", city: "BELLEVILLE" },
  "53510": { state: "WI", city: "BELMONT" },
  "53511": { state: "WI", city: "BELOIT" },
  "53512": { state: "WI", city: "BELOIT" },
  "53515": { state: "WI", city: "BLACK EARTH" },
  "53516": { state: "WI", city: "BLANCHARDVILLE" },
  "53517": { state: "WI", city: "BLUE MOUNDS" },
  "53518": { state: "WI", city: "BLUE RIVER" },
  "53520": { state: "WI", city: "BRODHEAD" },
  "53521": { state: "WI", city: "BROOKLYN" },
  "53522": { state: "WI", city: "BROWNTOWN" },
  "53523": { state: "WI", city: "CAMBRIDGE" },
  "53525": { state: "WI", city: "CLINTON" },
  "53526": { state: "WI", city: "COBB" },
  "53527": { state: "WI", city: "COTTAGE GROVE" },
  "53528": { state: "WI", city: "CROSS PLAINS" },
  "53529": { state: "WI", city: "DANE" },
  "53530": { state: "WI", city: "DARLINGTON" },
  "53531": { state: "WI", city: "DEERFIELD" },
  "53532": { state: "WI", city: "DEFOREST" },
  "53533": { state: "WI", city: "DODGEVILLE" },
  "53534": { state: "WI", city: "EDGERTON" },
  "53535": { state: "WI", city: "EDMUND" },
  "53536": { state: "WI", city: "EVANSVILLE" },
  "53537": { state: "WI", city: "FOOTVILLE" },
  "53538": { state: "WI", city: "FORT ATKINSON" },
  "53540": { state: "WI", city: "GOTHAM" },
  "53541": { state: "WI", city: "GRATIOT" },
  "53542": { state: "WI", city: "HANOVER" },
  "53543": { state: "WI", city: "HIGHLAND" },
  "53544": { state: "WI", city: "HOLLANDALE" },
  "53545": { state: "WI", city: "JANESVILLE" },
  "53546": { state: "WI", city: "JANESVILLE" },
  "53547": { state: "WI", city: "JANESVILLE" },
  "53548": { state: "WI", city: "JANESVILLE" },
  "53549": { state: "WI", city: "JEFFERSON" },
  "53550": { state: "WI", city: "JUDA" },
  "53551": { state: "WI", city: "LAKE MILLS" },
  "53553": { state: "WI", city: "LINDEN" },
  "53554": { state: "WI", city: "LIVINGSTON" },
  "53555": { state: "WI", city: "LODI" },
  "53556": { state: "WI", city: "LONE ROCK" },
  "53557": { state: "WI", city: "LOWELL" },
  "53558": { state: "WI", city: "MCFARLAND" },
  "53559": { state: "WI", city: "MARSHALL" },
  "53560": { state: "WI", city: "MAZOMANIE" },
  "53561": { state: "WI", city: "MERRIMAC" },
  "53562": { state: "WI", city: "MIDDLETON" },
  "53563": { state: "WI", city: "MILTON" },
  "53565": { state: "WI", city: "MINERAL POINT" },
  "53566": { state: "WI", city: "MONROE" },
  "53569": { state: "WI", city: "MONTFORT" },
  "53570": { state: "WI", city: "MONTICELLO" },
  "53571": { state: "WI", city: "MORRISONVILLE" },
  "53572": { state: "WI", city: "MOUNT HOREB" },
  "53573": { state: "WI", city: "MUSCODA" },
  "53574": { state: "WI", city: "NEW GLARUS" },
  "53575": { state: "WI", city: "OREGON" },
  "53576": { state: "WI", city: "ORFORDVILLE" },
  "53577": { state: "WI", city: "PLAIN" },
  "53578": { state: "WI", city: "PRAIRIE DU SAC" },
  "53579": { state: "WI", city: "REESEVILLE" },
  "53580": { state: "WI", city: "REWEY" },
  "53581": { state: "WI", city: "RICHLAND CENTER" },
  "53582": { state: "WI", city: "RIDGEWAY" },
  "53583": { state: "WI", city: "SAUK CITY" },
  "53584": { state: "WI", city: "SEXTONVILLE" },
  "53585": { state: "WI", city: "SHARON" },
  "53586": { state: "WI", city: "SHULLSBURG" },
  "53587": { state: "WI", city: "SOUTH WAYNE" },
  "53588": { state: "WI", city: "SPRING GREEN" },
  "53589": { state: "WI", city: "STOUGHTON" },
  "53590": { state: "WI", city: "SUN PRAIRIE" },
  "53593": { state: "WI", city: "VERONA" },
  "53594": { state: "WI", city: "WATERLOO" },
  "53595": { state: "WI", city: "DODGEVILLE" },
  "53596": { state: "WI", city: "SUN PRAIRIE" },
  "53597": { state: "WI", city: "WAUNAKEE" },
  "53598": { state: "WI", city: "WINDSOR" },
  "53599": { state: "WI", city: "WOODFORD" },
  "53701": { state: "WI", city: "MADISON" },
  "53702": { state: "WI", city: "MADISON" },
  "53703": { state: "WI", city: "MADISON" },
  "53704": { state: "WI", city: "MADISON" },
  "53705": { state: "WI", city: "MADISON" },
  "53706": { state: "WI", city: "MADISON" },
  "53707": { state: "WI", city: "MADISON" },
  "53708": { state: "WI", city: "MADISON" },
  "53711": { state: "WI", city: "MADISON" },
  "53713": { state: "WI", city: "MADISON" },
  "53714": { state: "WI", city: "MADISON" },
  "53715": { state: "WI", city: "MADISON" },
  "53716": { state: "WI", city: "MADISON" },
  "53717": { state: "WI", city: "MADISON" },
  "53718": { state: "WI", city: "MADISON" },
  "53719": { state: "WI", city: "MADISON" },
  "53725": { state: "WI", city: "MADISON" },
  "53726": { state: "WI", city: "MADISON" },
  "53744": { state: "WI", city: "MADISON" },
  "53774": { state: "WI", city: "MADISON" },
  "53777": { state: "WI", city: "MADISON" },
  "53782": { state: "WI", city: "MADISON" },
  "53783": { state: "WI", city: "MADISON" },
  "53784": { state: "WI", city: "MADISON" },
  "53785": { state: "WI", city: "MADISON" },
  "53786": { state: "WI", city: "MADISON" },
  "53788": { state: "WI", city: "MADISON" },
  "53790": { state: "WI", city: "MADISON" },
  "53791": { state: "WI", city: "MADISON" },
  "53792": { state: "WI", city: "MADISON" },
  "53793": { state: "WI", city: "MADISON" },
  "53794": { state: "WI", city: "MADISON" },
  "53801": { state: "WI", city: "BAGLEY" },
  "53802": { state: "WI", city: "BEETOWN" },
  "53803": { state: "WI", city: "BENTON" },
  "53804": { state: "WI", city: "BLOOMINGTON" },
  "53805": { state: "WI", city: "BOSCOBEL" },
  "53806": { state: "WI", city: "CASSVILLE" },
  "53807": { state: "WI", city: "CUBA CITY" },
  "53808": { state: "WI", city: "DICKEYVILLE" },
  "53809": { state: "WI", city: "FENNIMORE" },
  "53810": { state: "WI", city: "GLEN HAVEN" },
  "53811": { state: "WI", city: "HAZEL GREEN" },
  "53812": { state: "WI", city: "KIELER" },
  "53813": { state: "WI", city: "LANCASTER" },
  "53816": { state: "WI", city: "MOUNT HOPE" },
  "53817": { state: "WI", city: "PATCH GROVE" },
  "53818": { state: "WI", city: "PLATTEVILLE" },
  "53820": { state: "WI", city: "POTOSI" },
  "53821": { state: "WI", city: "PRAIRIE DU CHIEN" },
  "53824": { state: "WI", city: "SINSINAWA" },
  "53825": { state: "WI", city: "STITZER" },
  "53826": { state: "WI", city: "WAUZEKA" },
  "53827": { state: "WI", city: "WOODMAN" },
  "53901": { state: "WI", city: "PORTAGE" },
  "53910": { state: "WI", city: "ADAMS" },
  "53911": { state: "WI", city: "ARLINGTON" },
  "53913": { state: "WI", city: "BARABOO" },
  "53916": { state: "WI", city: "BEAVER DAM" },
  "53919": { state: "WI", city: "BRANDON" },
  "53920": { state: "WI", city: "BRIGGSVILLE" },
  "53922": { state: "WI", city: "BURNETT" },
  "53923": { state: "WI", city: "CAMBRIA" },
  "53924": { state: "WI", city: "CAZENOVIA" },
  "53925": { state: "WI", city: "COLUMBUS" },
  "53926": { state: "WI", city: "DALTON" },
  "53928": { state: "WI", city: "DOYLESTOWN" },
  "53929": { state: "WI", city: "ELROY" },
  "53930": { state: "WI", city: "ENDEAVOR" },
  "53931": { state: "WI", city: "FAIRWATER" },
  "53932": { state: "WI", city: "FALL RIVER" },
  "53933": { state: "WI", city: "FOX LAKE" },
  "53934": { state: "WI", city: "FRIENDSHIP" },
  "53935": { state: "WI", city: "FRIESLAND" },
  "53936": { state: "WI", city: "GRAND MARSH" },
  "53937": { state: "WI", city: "HILLPOINT" },
  "53939": { state: "WI", city: "KINGSTON" },
  "53940": { state: "WI", city: "LAKE DELTON" },
  "53941": { state: "WI", city: "LA VALLE" },
  "53942": { state: "WI", city: "LIME RIDGE" },
  "53943": { state: "WI", city: "LOGANVILLE" },
  "53944": { state: "WI", city: "LYNDON STATION" },
  "53946": { state: "WI", city: "MARKESAN" },
  "53947": { state: "WI", city: "MARQUETTE" },
  "53948": { state: "WI", city: "MAUSTON" },
  "53949": { state: "WI", city: "MONTELLO" },
  "53950": { state: "WI", city: "NEW LISBON" },
  "53951": { state: "WI", city: "NORTH FREEDOM" },
  "53952": { state: "WI", city: "OXFORD" },
  "53953": { state: "WI", city: "PACKWAUKEE" },
  "53954": { state: "WI", city: "PARDEEVILLE" },
  "53955": { state: "WI", city: "POYNETTE" },
  "53956": { state: "WI", city: "RANDOLPH" },
  "53957": { state: "WI", city: "RANDOLPH" },
  "53958": { state: "WI", city: "REEDSBURG" },
  "53959": { state: "WI", city: "REEDSBURG" },
  "53960": { state: "WI", city: "RIO" },
  "53961": { state: "WI", city: "ROCK SPRINGS" },
  "53962": { state: "WI", city: "UNION CENTER" },
  "53963": { state: "WI", city: "WAUPUN" },
  "53964": { state: "WI", city: "WESTFIELD" },
  "53965": { state: "WI", city: "WISCONSIN DELLS" },
  "53968": { state: "WI", city: "WONEWOC" },
  "53969": { state: "WI", city: "WYOCENA" },
  "54001": { state: "WI", city: "AMERY" },
  "54002": { state: "WI", city: "BALDWIN" },
  "54003": { state: "WI", city: "BELDENVILLE" },
  "54004": { state: "WI", city: "CLAYTON" },
  "54005": { state: "WI", city: "CLEAR LAKE" },
  "54006": { state: "WI", city: "CUSHING" },
  "54007": { state: "WI", city: "DEER PARK" },
  "54009": { state: "WI", city: "DRESSER" },
  "54011": { state: "WI", city: "ELLSWORTH" },
  "54013": { state: "WI", city: "GLENWOOD CITY" },
  "54014": { state: "WI", city: "HAGER CITY" },
  "54015": { state: "WI", city: "HAMMOND" },
  "54016": { state: "WI", city: "HUDSON" },
  "54017": { state: "WI", city: "NEW RICHMOND" },
  "54020": { state: "WI", city: "OSCEOLA" },
  "54021": { state: "WI", city: "PRESCOTT" },
  "54022": { state: "WI", city: "RIVER FALLS" },
  "54023": { state: "WI", city: "ROBERTS" },
  "54024": { state: "WI", city: "SAINT CROIX FALLS" },
  "54025": { state: "WI", city: "SOMERSET" },
  "54026": { state: "WI", city: "STAR PRAIRIE" },
  "54027": { state: "WI", city: "WILSON" },
  "54028": { state: "WI", city: "WOODVILLE" },
  "54082": { state: "WI", city: "HOULTON" },
  "54101": { state: "WI", city: "ABRAMS" },
  "54102": { state: "WI", city: "AMBERG" },
  "54103": { state: "WI", city: "ARMSTRONG CREEK" },
  "54104": { state: "WI", city: "ATHELSTANE" },
  "54106": { state: "WI", city: "BLACK CREEK" },
  "54107": { state: "WI", city: "BONDUEL" },
  "54110": { state: "WI", city: "BRILLION" },
  "54111": { state: "WI", city: "CECIL" },
  "54112": { state: "WI", city: "COLEMAN" },
  "54113": { state: "WI", city: "COMBINED LOCKS" },
  "54114": { state: "WI", city: "CRIVITZ" },
  "54115": { state: "WI", city: "DE PERE" },
  "54119": { state: "WI", city: "DUNBAR" },
  "54120": { state: "WI", city: "FENCE" },
  "54121": { state: "WI", city: "FLORENCE" },
  "54123": { state: "WI", city: "FOREST JUNCTION" },
  "54124": { state: "WI", city: "GILLETT" },
  "54125": { state: "WI", city: "GOODMAN" },
  "54126": { state: "WI", city: "GREENLEAF" },
  "54127": { state: "WI", city: "GREEN VALLEY" },
  "54128": { state: "WI", city: "GRESHAM" },
  "54129": { state: "WI", city: "HILBERT" },
  "54130": { state: "WI", city: "KAUKAUNA" },
  "54131": { state: "WI", city: "FREEDOM" },
  "54135": { state: "WI", city: "KESHENA" },
  "54136": { state: "WI", city: "KIMBERLY" },
  "54137": { state: "WI", city: "KRAKOW" },
  "54138": { state: "WI", city: "LAKEWOOD" },
  "54139": { state: "WI", city: "LENA" },
  "54140": { state: "WI", city: "LITTLE CHUTE" },
  "54141": { state: "WI", city: "LITTLE SUAMICO" },
  "54143": { state: "WI", city: "MARINETTE" },
  "54149": { state: "WI", city: "MOUNTAIN" },
  "54150": { state: "WI", city: "NEOPIT" },
  "54151": { state: "WI", city: "NIAGARA" },
  "54152": { state: "WI", city: "NICHOLS" },
  "54153": { state: "WI", city: "OCONTO" },
  "54154": { state: "WI", city: "OCONTO FALLS" },
  "54155": { state: "WI", city: "ONEIDA" },
  "54156": { state: "WI", city: "PEMBINE" },
  "54157": { state: "WI", city: "PESHTIGO" },
  "54159": { state: "WI", city: "PORTERFIELD" },
  "54160": { state: "WI", city: "POTTER" },
  "54161": { state: "WI", city: "POUND" },
  "54162": { state: "WI", city: "PULASKI" },
  "54165": { state: "WI", city: "SEYMOUR" },
  "54166": { state: "WI", city: "SHAWANO" },
  "54169": { state: "WI", city: "SHERWOOD" },
  "54170": { state: "WI", city: "SHIOCTON" },
  "54171": { state: "WI", city: "SOBIESKI" },
  "54173": { state: "WI", city: "SUAMICO" },
  "54174": { state: "WI", city: "SURING" },
  "54175": { state: "WI", city: "TOWNSEND" },
  "54177": { state: "WI", city: "WAUSAUKEE" },
  "54180": { state: "WI", city: "WRIGHTSTOWN" },
  "54201": { state: "WI", city: "ALGOMA" },
  "54202": { state: "WI", city: "BAILEYS HARBOR" },
  "54204": { state: "WI", city: "BRUSSELS" },
  "54205": { state: "WI", city: "CASCO" },
  "54207": { state: "WI", city: "COLLINS" },
  "54208": { state: "WI", city: "DENMARK" },
  "54209": { state: "WI", city: "EGG HARBOR" },
  "54210": { state: "WI", city: "ELLISON BAY" },
  "54211": { state: "WI", city: "EPHRAIM" },
  "54212": { state: "WI", city: "FISH CREEK" },
  "54213": { state: "WI", city: "FORESTVILLE" },
  "54214": { state: "WI", city: "FRANCIS CREEK" },
  "54215": { state: "WI", city: "KELLNERSVILLE" },
  "54216": { state: "WI", city: "KEWAUNEE" },
  "54217": { state: "WI", city: "LUXEMBURG" },
  "54220": { state: "WI", city: "MANITOWOC" },
  "54221": { state: "WI", city: "MANITOWOC" },
  "54226": { state: "WI", city: "MAPLEWOOD" },
  "54227": { state: "WI", city: "MARIBEL" },
  "54228": { state: "WI", city: "MISHICOT" },
  "54229": { state: "WI", city: "NEW FRANKEN" },
  "54230": { state: "WI", city: "REEDSVILLE" },
  "54232": { state: "WI", city: "SAINT NAZIANZ" },
  "54234": { state: "WI", city: "SISTER BAY" },
  "54235": { state: "WI", city: "STURGEON BAY" },
  "54240": { state: "WI", city: "TISCH MILLS" },
  "54241": { state: "WI", city: "TWO RIVERS" },
  "54245": { state: "WI", city: "VALDERS" },
  "54246": { state: "WI", city: "WASHINGTON ISLAND" },
  "54247": { state: "WI", city: "WHITELAW" },
  "54301": { state: "WI", city: "GREEN BAY" },
  "54302": { state: "WI", city: "GREEN BAY" },
  "54303": { state: "WI", city: "GREEN BAY" },
  "54304": { state: "WI", city: "GREEN BAY" },
  "54305": { state: "WI", city: "GREEN BAY" },
  "54306": { state: "WI", city: "GREEN BAY" },
  "54307": { state: "WI", city: "GREEN BAY" },
  "54308": { state: "WI", city: "GREEN BAY" },
  "54311": { state: "WI", city: "GREEN BAY" },
  "54313": { state: "WI", city: "GREEN BAY" },
  "54324": { state: "WI", city: "GREEN BAY" },
  "54344": { state: "WI", city: "GREEN BAY" },
  "54401": { state: "WI", city: "WAUSAU" },
  "54402": { state: "WI", city: "WAUSAU" },
  "54403": { state: "WI", city: "WAUSAU" },
  "54404": { state: "WI", city: "MARSHFIELD" },
  "54405": { state: "WI", city: "ABBOTSFORD" },
  "54406": { state: "WI", city: "AMHERST" },
  "54407": { state: "WI", city: "AMHERST JUNCTION" },
  "54408": { state: "WI", city: "ANIWA" },
  "54409": { state: "WI", city: "ANTIGO" },
  "54410": { state: "WI", city: "ARPIN" },
  "54411": { state: "WI", city: "ATHENS" },
  "54412": { state: "WI", city: "AUBURNDALE" },
  "54413": { state: "WI", city: "BABCOCK" },
  "54414": { state: "WI", city: "BIRNAMWOOD" },
  "54415": { state: "WI", city: "BLENKER" },
  "54416": { state: "WI", city: "BOWLER" },
  "54417": { state: "WI", city: "BROKAW" },
  "54418": { state: "WI", city: "BRYANT" },
  "54420": { state: "WI", city: "CHILI" },
  "54421": { state: "WI", city: "COLBY" },
  "54422": { state: "WI", city: "CURTISS" },
  "54423": { state: "WI", city: "CUSTER" },
  "54424": { state: "WI", city: "DEERBROOK" },
  "54425": { state: "WI", city: "DORCHESTER" },
  "54426": { state: "WI", city: "EDGAR" },
  "54427": { state: "WI", city: "ELAND" },
  "54428": { state: "WI", city: "ELCHO" },
  "54429": { state: "WI", city: "ELDERON" },
  "54430": { state: "WI", city: "ELTON" },
  "54433": { state: "WI", city: "GILMAN" },
  "54435": { state: "WI", city: "GLEASON" },
  "54436": { state: "WI", city: "GRANTON" },
  "54437": { state: "WI", city: "GREENWOOD" },
  "54440": { state: "WI", city: "HATLEY" },
  "54441": { state: "WI", city: "HEWITT" },
  "54442": { state: "WI", city: "IRMA" },
  "54443": { state: "WI", city: "JUNCTION CITY" },
  "54446": { state: "WI", city: "LOYAL" },
  "54447": { state: "WI", city: "LUBLIN" },
  "54448": { state: "WI", city: "MARATHON" },
  "54449": { state: "WI", city: "MARSHFIELD" },
  "54450": { state: "WI", city: "MATTOON" },
  "54451": { state: "WI", city: "MEDFORD" },
  "54452": { state: "WI", city: "MERRILL" },
  "54454": { state: "WI", city: "MILLADORE" },
  "54455": { state: "WI", city: "MOSINEE" },
  "54456": { state: "WI", city: "NEILLSVILLE" },
  "54457": { state: "WI", city: "NEKOOSA" },
  "54458": { state: "WI", city: "NELSONVILLE" },
  "54459": { state: "WI", city: "OGEMA" },
  "54460": { state: "WI", city: "OWEN" },
  "54462": { state: "WI", city: "PEARSON" },
  "54463": { state: "WI", city: "PELICAN LAKE" },
  "54464": { state: "WI", city: "PHLOX" },
  "54465": { state: "WI", city: "PICKEREL" },
  "54466": { state: "WI", city: "PITTSVILLE" },
  "54467": { state: "WI", city: "PLOVER" },
  "54469": { state: "WI", city: "PORT EDWARDS" },
  "54470": { state: "WI", city: "RIB LAKE" },
  "54471": { state: "WI", city: "RINGLE" },
  "54473": { state: "WI", city: "ROSHOLT" },
  "54474": { state: "WI", city: "ROTHSCHILD" },
  "54475": { state: "WI", city: "RUDOLPH" },
  "54476": { state: "WI", city: "SCHOFIELD" },
  "54479": { state: "WI", city: "SPENCER" },
  "54480": { state: "WI", city: "STETSONVILLE" },
  "54481": { state: "WI", city: "STEVENS POINT" },
  "54482": { state: "WI", city: "STEVENS POINT" },
  "54484": { state: "WI", city: "STRATFORD" },
  "54485": { state: "WI", city: "SUMMIT LAKE" },
  "54486": { state: "WI", city: "TIGERTON" },
  "54487": { state: "WI", city: "TOMAHAWK" },
  "54488": { state: "WI", city: "UNITY" },
  "54489": { state: "WI", city: "VESPER" },
  "54490": { state: "WI", city: "WESTBORO" },
  "54491": { state: "WI", city: "WHITE LAKE" },
  "54492": { state: "WI", city: "STEVENS POINT" },
  "54493": { state: "WI", city: "WILLARD" },
  "54494": { state: "WI", city: "WISCONSIN RAPIDS" },
  "54495": { state: "WI", city: "WISCONSIN RAPIDS" },
  "54498": { state: "WI", city: "WITHEE" },
  "54499": { state: "WI", city: "WITTENBERG" },
  "54501": { state: "WI", city: "RHINELANDER" },
  "54511": { state: "WI", city: "ARGONNE" },
  "54512": { state: "WI", city: "BOULDER JUNCTION" },
  "54513": { state: "WI", city: "BRANTWOOD" },
  "54514": { state: "WI", city: "BUTTERNUT" },
  "54515": { state: "WI", city: "CATAWBA" },
  "54517": { state: "WI", city: "CLAM LAKE" },
  "54519": { state: "WI", city: "CONOVER" },
  "54520": { state: "WI", city: "CRANDON" },
  "54521": { state: "WI", city: "EAGLE RIVER" },
  "54524": { state: "WI", city: "FIFIELD" },
  "54525": { state: "WI", city: "GILE" },
  "54526": { state: "WI", city: "GLEN FLORA" },
  "54527": { state: "WI", city: "GLIDDEN" },
  "54529": { state: "WI", city: "HARSHAW" },
  "54530": { state: "WI", city: "HAWKINS" },
  "54531": { state: "WI", city: "HAZELHURST" },
  "54532": { state: "WI", city: "HEAFFORD JUNCTION" },
  "54534": { state: "WI", city: "HURLEY" },
  "54536": { state: "WI", city: "IRON BELT" },
  "54537": { state: "WI", city: "KENNAN" },
  "54538": { state: "WI", city: "LAC DU FLAMBEAU" },
  "54539": { state: "WI", city: "LAKE TOMAHAWK" },
  "54540": { state: "WI", city: "LAND O LAKES" },
  "54541": { state: "WI", city: "LAONA" },
  "54542": { state: "WI", city: "LONG LAKE" },
  "54543": { state: "WI", city: "MC NAUGHTON" },
  "54545": { state: "WI", city: "MANITOWISH WATERS" },
  "54546": { state: "WI", city: "MELLEN" },
  "54547": { state: "WI", city: "MERCER" },
  "54548": { state: "WI", city: "MINOCQUA" },
  "54550": { state: "WI", city: "MONTREAL" },
  "54552": { state: "WI", city: "PARK FALLS" },
  "54554": { state: "WI", city: "PHELPS" },
  "54555": { state: "WI", city: "PHILLIPS" },
  "54556": { state: "WI", city: "PRENTICE" },
  "54557": { state: "WI", city: "PRESQUE ISLE" },
  "54558": { state: "WI", city: "SAINT GERMAIN" },
  "54559": { state: "WI", city: "SAXON" },
  "54560": { state: "WI", city: "SAYNER" },
  "54561": { state: "WI", city: "STAR LAKE" },
  "54562": { state: "WI", city: "THREE LAKES" },
  "54563": { state: "WI", city: "TONY" },
  "54564": { state: "WI", city: "TRIPOLI" },
  "54565": { state: "WI", city: "UPSON" },
  "54566": { state: "WI", city: "WABENO" },
  "54568": { state: "WI", city: "WOODRUFF" },
  "54601": { state: "WI", city: "LA CROSSE" },
  "54602": { state: "WI", city: "LA CROSSE" },
  "54603": { state: "WI", city: "LA CROSSE" },
  "54610": { state: "WI", city: "ALMA" },
  "54611": { state: "WI", city: "ALMA CENTER" },
  "54612": { state: "WI", city: "ARCADIA" },
  "54613": { state: "WI", city: "ARKDALE" },
  "54614": { state: "WI", city: "BANGOR" },
  "54615": { state: "WI", city: "BLACK RIVER FALLS" },
  "54616": { state: "WI", city: "BLAIR" },
  "54618": { state: "WI", city: "CAMP DOUGLAS" },
  "54619": { state: "WI", city: "CASHTON" },
  "54620": { state: "WI", city: "CATARACT" },
  "54621": { state: "WI", city: "CHASEBURG" },
  "54622": { state: "WI", city: "COCHRANE" },
  "54623": { state: "WI", city: "COON VALLEY" },
  "54624": { state: "WI", city: "DE SOTO" },
  "54625": { state: "WI", city: "DODGE" },
  "54626": { state: "WI", city: "EASTMAN" },
  "54627": { state: "WI", city: "ETTRICK" },
  "54628": { state: "WI", city: "FERRYVILLE" },
  "54629": { state: "WI", city: "FOUNTAIN CITY" },
  "54630": { state: "WI", city: "GALESVILLE" },
  "54631": { state: "WI", city: "GAYS MILLS" },
  "54632": { state: "WI", city: "GENOA" },
  "54634": { state: "WI", city: "HILLSBORO" },
  "54635": { state: "WI", city: "HIXTON" },
  "54636": { state: "WI", city: "HOLMEN" },
  "54637": { state: "WI", city: "HUSTLER" },
  "54638": { state: "WI", city: "KENDALL" },
  "54639": { state: "WI", city: "LA FARGE" },
  "54641": { state: "WI", city: "MATHER" },
  "54642": { state: "WI", city: "MELROSE" },
  "54643": { state: "WI", city: "MILLSTON" },
  "54644": { state: "WI", city: "MINDORO" },
  "54645": { state: "WI", city: "MOUNT STERLING" },
  "54646": { state: "WI", city: "NECEDAH" },
  "54648": { state: "WI", city: "NORWALK" },
  "54649": { state: "WI", city: "OAKDALE" },
  "54650": { state: "WI", city: "ONALASKA" },
  "54651": { state: "WI", city: "ONTARIO" },
  "54652": { state: "WI", city: "READSTOWN" },
  "54653": { state: "WI", city: "ROCKLAND" },
  "54654": { state: "WI", city: "SENECA" },
  "54655": { state: "WI", city: "SOLDIERS GROVE" },
  "54656": { state: "WI", city: "SPARTA" },
  "54657": { state: "WI", city: "STEUBEN" },
  "54658": { state: "WI", city: "STODDARD" },
  "54659": { state: "WI", city: "TAYLOR" },
  "54660": { state: "WI", city: "TOMAH" },
  "54661": { state: "WI", city: "TREMPEALEAU" },
  "54662": { state: "WI", city: "TUNNEL CITY" },
  "54664": { state: "WI", city: "VIOLA" },
  "54665": { state: "WI", city: "VIROQUA" },
  "54666": { state: "WI", city: "WARRENS" },
  "54667": { state: "WI", city: "WESTBY" },
  "54669": { state: "WI", city: "WEST SALEM" },
  "54670": { state: "WI", city: "WILTON" },
  "54701": { state: "WI", city: "EAU CLAIRE" },
  "54702": { state: "WI", city: "EAU CLAIRE" },
  "54703": { state: "WI", city: "EAU CLAIRE" },
  "54720": { state: "WI", city: "ALTOONA" },
  "54721": { state: "WI", city: "ARKANSAW" },
  "54722": { state: "WI", city: "AUGUSTA" },
  "54723": { state: "WI", city: "BAY CITY" },
  "54724": { state: "WI", city: "BLOOMER" },
  "54725": { state: "WI", city: "BOYCEVILLE" },
  "54726": { state: "WI", city: "BOYD" },
  "54727": { state: "WI", city: "CADOTT" },
  "54728": { state: "WI", city: "CHETEK" },
  "54729": { state: "WI", city: "CHIPPEWA FALLS" },
  "54730": { state: "WI", city: "COLFAX" },
  "54731": { state: "WI", city: "CONRATH" },
  "54732": { state: "WI", city: "CORNELL" },
  "54733": { state: "WI", city: "DALLAS" },
  "54734": { state: "WI", city: "DOWNING" },
  "54735": { state: "WI", city: "DOWNSVILLE" },
  "54736": { state: "WI", city: "DURAND" },
  "54737": { state: "WI", city: "EAU GALLE" },
  "54738": { state: "WI", city: "ELEVA" },
  "54739": { state: "WI", city: "ELK MOUND" },
  "54740": { state: "WI", city: "ELMWOOD" },
  "54741": { state: "WI", city: "FAIRCHILD" },
  "54742": { state: "WI", city: "FALL CREEK" },
  "54743": { state: "WI", city: "GILMANTON" },
  "54745": { state: "WI", city: "HOLCOMBE" },
  "54746": { state: "WI", city: "HUMBIRD" },
  "54747": { state: "WI", city: "INDEPENDENCE" },
  "54748": { state: "WI", city: "JIM FALLS" },
  "54749": { state: "WI", city: "KNAPP" },
  "54750": { state: "WI", city: "MAIDEN ROCK" },
  "54751": { state: "WI", city: "MENOMONIE" },
  "54754": { state: "WI", city: "MERRILLAN" },
  "54755": { state: "WI", city: "MONDOVI" },
  "54756": { state: "WI", city: "NELSON" },
  "54757": { state: "WI", city: "NEW AUBURN" },
  "54758": { state: "WI", city: "OSSEO" },
  "54759": { state: "WI", city: "PEPIN" },
  "54760": { state: "WI", city: "PIGEON FALLS" },
  "54761": { state: "WI", city: "PLUM CITY" },
  "54762": { state: "WI", city: "PRAIRIE FARM" },
  "54763": { state: "WI", city: "RIDGELAND" },
  "54764": { state: "WI", city: "ROCK FALLS" },
  "54765": { state: "WI", city: "SAND CREEK" },
  "54766": { state: "WI", city: "SHELDON" },
  "54767": { state: "WI", city: "SPRING VALLEY" },
  "54768": { state: "WI", city: "STANLEY" },
  "54769": { state: "WI", city: "STOCKHOLM" },
  "54770": { state: "WI", city: "STRUM" },
  "54771": { state: "WI", city: "THORP" },
  "54772": { state: "WI", city: "WHEELER" },
  "54773": { state: "WI", city: "WHITEHALL" },
  "54774": { state: "WI", city: "CHIPPEWA FALLS" },
  "54801": { state: "WI", city: "SPOONER" },
  "54805": { state: "WI", city: "ALMENA" },
  "54806": { state: "WI", city: "ASHLAND" },
  "54810": { state: "WI", city: "BALSAM LAKE" },
  "54812": { state: "WI", city: "BARRON" },
  "54813": { state: "WI", city: "BARRONETT" },
  "54814": { state: "WI", city: "BAYFIELD" },
  "54816": { state: "WI", city: "BENOIT" },
  "54817": { state: "WI", city: "BIRCHWOOD" },
  "54819": { state: "WI", city: "BRUCE" },
  "54820": { state: "WI", city: "BRULE" },
  "54821": { state: "WI", city: "CABLE" },
  "54822": { state: "WI", city: "CAMERON" },
  "54824": { state: "WI", city: "CENTURIA" },
  "54826": { state: "WI", city: "COMSTOCK" },
  "54827": { state: "WI", city: "CORNUCOPIA" },
  "54828": { state: "WI", city: "COUDERAY" },
  "54829": { state: "WI", city: "CUMBERLAND" },
  "54830": { state: "WI", city: "DANBURY" },
  "54832": { state: "WI", city: "DRUMMOND" },
  "54835": { state: "WI", city: "EXELAND" },
  "54836": { state: "WI", city: "FOXBORO" },
  "54837": { state: "WI", city: "FREDERIC" },
  "54838": { state: "WI", city: "GORDON" },
  "54839": { state: "WI", city: "GRAND VIEW" },
  "54840": { state: "WI", city: "GRANTSBURG" },
  "54841": { state: "WI", city: "HAUGEN" },
  "54842": { state: "WI", city: "HAWTHORNE" },
  "54843": { state: "WI", city: "HAYWARD" },
  "54844": { state: "WI", city: "HERBSTER" },
  "54845": { state: "WI", city: "HERTEL" },
  "54846": { state: "WI", city: "HIGH BRIDGE" },
  "54847": { state: "WI", city: "IRON RIVER" },
  "54848": { state: "WI", city: "LADYSMITH" },
  "54849": { state: "WI", city: "LAKE NEBAGAMON" },
  "54850": { state: "WI", city: "LA POINTE" },
  "54853": { state: "WI", city: "LUCK" },
  "54854": { state: "WI", city: "MAPLE" },
  "54855": { state: "WI", city: "MARENGO" },
  "54856": { state: "WI", city: "MASON" },
  "54857": { state: "WI", city: "MIKANA" },
  "54858": { state: "WI", city: "MILLTOWN" },
  "54859": { state: "WI", city: "MINONG" },
  "54861": { state: "WI", city: "ODANAH" },
  "54862": { state: "WI", city: "OJIBWA" },
  "54864": { state: "WI", city: "POPLAR" },
  "54865": { state: "WI", city: "PORT WING" },
  "54867": { state: "WI", city: "RADISSON" },
  "54868": { state: "WI", city: "RICE LAKE" },
  "54870": { state: "WI", city: "SARONA" },
  "54871": { state: "WI", city: "SHELL LAKE" },
  "54872": { state: "WI", city: "SIREN" },
  "54873": { state: "WI", city: "SOLON SPRINGS" },
  "54874": { state: "WI", city: "SOUTH RANGE" },
  "54875": { state: "WI", city: "SPRINGBROOK" },
  "54876": { state: "WI", city: "STONE LAKE" },
  "54880": { state: "WI", city: "SUPERIOR" },
  "54888": { state: "WI", city: "TREGO" },
  "54889": { state: "WI", city: "TURTLE LAKE" },
  "54890": { state: "WI", city: "WASCOTT" },
  "54891": { state: "WI", city: "WASHBURN" },
  "54893": { state: "WI", city: "WEBSTER" },
  "54895": { state: "WI", city: "WEYERHAEUSER" },
  "54896": { state: "WI", city: "WINTER" },
  "54901": { state: "WI", city: "OSHKOSH" },
  "54902": { state: "WI", city: "OSHKOSH" },
  "54903": { state: "WI", city: "OSHKOSH" },
  "54904": { state: "WI", city: "OSHKOSH" },
  "54906": { state: "WI", city: "OSHKOSH" },
  "54909": { state: "WI", city: "ALMOND" },
  "54911": { state: "WI", city: "APPLETON" },
  "54912": { state: "WI", city: "APPLETON" },
  "54913": { state: "WI", city: "APPLETON" },
  "54914": { state: "WI", city: "APPLETON" },
  "54915": { state: "WI", city: "APPLETON" },
  "54919": { state: "WI", city: "APPLETON" },
  "54921": { state: "WI", city: "BANCROFT" },
  "54922": { state: "WI", city: "BEAR CREEK" },
  "54923": { state: "WI", city: "BERLIN" },
  "54926": { state: "WI", city: "BIG FALLS" },
  "54927": { state: "WI", city: "BUTTE DES MORTS" },
  "54928": { state: "WI", city: "CAROLINE" },
  "54929": { state: "WI", city: "CLINTONVILLE" },
  "54930": { state: "WI", city: "COLOMA" },
  "54931": { state: "WI", city: "DALE" },
  "54932": { state: "WI", city: "ELDORADO" },
  "54933": { state: "WI", city: "EMBARRASS" },
  "54935": { state: "WI", city: "FOND DU LAC" },
  "54936": { state: "WI", city: "FOND DU LAC" },
  "54937": { state: "WI", city: "FOND DU LAC" },
  "54940": { state: "WI", city: "FREMONT" },
  "54941": { state: "WI", city: "GREEN LAKE" },
  "54942": { state: "WI", city: "GREENVILLE" },
  "54943": { state: "WI", city: "HANCOCK" },
  "54944": { state: "WI", city: "HORTONVILLE" },
  "54945": { state: "WI", city: "IOLA" },
  "54946": { state: "WI", city: "KING" },
  "54947": { state: "WI", city: "LARSEN" },
  "54948": { state: "WI", city: "LEOPOLIS" },
  "54949": { state: "WI", city: "MANAWA" },
  "54950": { state: "WI", city: "MARION" },
  "54952": { state: "WI", city: "MENASHA" },
  "54956": { state: "WI", city: "NEENAH" },
  "54957": { state: "WI", city: "NEENAH" },
  "54960": { state: "WI", city: "NESHKORO" },
  "54961": { state: "WI", city: "NEW LONDON" },
  "54962": { state: "WI", city: "OGDENSBURG" },
  "54963": { state: "WI", city: "OMRO" },
  "54964": { state: "WI", city: "PICKETT" },
  "54965": { state: "WI", city: "PINE RIVER" },
  "54966": { state: "WI", city: "PLAINFIELD" },
  "54967": { state: "WI", city: "POY SIPPI" },
  "54968": { state: "WI", city: "PRINCETON" },
  "54969": { state: "WI", city: "READFIELD" },
  "54970": { state: "WI", city: "REDGRANITE" },
  "54971": { state: "WI", city: "RIPON" },
  "54974": { state: "WI", city: "ROSENDALE" },
  "54976": { state: "WI", city: "SAXEVILLE" },
  "54977": { state: "WI", city: "SCANDINAVIA" },
  "54978": { state: "WI", city: "TILLEDA" },
  "54979": { state: "WI", city: "VAN DYNE" },
  "54980": { state: "WI", city: "WAUKAU" },
  "54981": { state: "WI", city: "WAUPACA" },
  "54982": { state: "WI", city: "WAUTOMA" },
  "54983": { state: "WI", city: "WEYAUWEGA" },
  "54984": { state: "WI", city: "WILD ROSE" },
  "54985": { state: "WI", city: "WINNEBAGO" },
  "54986": { state: "WI", city: "WINNECONNE" },
  "55001": { state: "MN", city: "AFTON" },
  "55003": { state: "MN", city: "BAYPORT" },
  "55005": { state: "MN", city: "BETHEL" },
  "55006": { state: "MN", city: "BRAHAM" },
  "55007": { state: "MN", city: "BROOK PARK" },
  "55008": { state: "MN", city: "CAMBRIDGE" },
  "55009": { state: "MN", city: "CANNON FALLS" },
  "55010": { state: "MN", city: "CASTLE ROCK" },
  "55011": { state: "MN", city: "CEDAR" },
  "55012": { state: "MN", city: "CENTER CITY" },
  "55013": { state: "MN", city: "CHISAGO CITY" },
  "55014": { state: "MN", city: "CIRCLE PINES" },
  "55016": { state: "MN", city: "COTTAGE GROVE" },
  "55017": { state: "MN", city: "DALBO" },
  "55018": { state: "MN", city: "DENNISON" },
  "55019": { state: "MN", city: "DUNDAS" },
  "55020": { state: "MN", city: "ELKO NEW MARKET" },
  "55021": { state: "MN", city: "FARIBAULT" },
  "55024": { state: "MN", city: "FARMINGTON" },
  "55025": { state: "MN", city: "FOREST LAKE" },
  "55026": { state: "MN", city: "FRONTENAC" },
  "55027": { state: "MN", city: "GOODHUE" },
  "55029": { state: "MN", city: "GRANDY" },
  "55030": { state: "MN", city: "GRASSTON" },
  "55031": { state: "MN", city: "HAMPTON" },
  "55032": { state: "MN", city: "HARRIS" },
  "55033": { state: "MN", city: "HASTINGS" },
  "55036": { state: "MN", city: "HENRIETTE" },
  "55037": { state: "MN", city: "HINCKLEY" },
  "55038": { state: "MN", city: "HUGO" },
  "55040": { state: "MN", city: "ISANTI" },
  "55041": { state: "MN", city: "LAKE CITY" },
  "55042": { state: "MN", city: "LAKE ELMO" },
  "55043": { state: "MN", city: "LAKELAND" },
  "55044": { state: "MN", city: "LAKEVILLE" },
  "55045": { state: "MN", city: "LINDSTROM" },
  "55046": { state: "MN", city: "LONSDALE" },
  "55047": { state: "MN", city: "MARINE ON SAINT CROIX" },
  "55049": { state: "MN", city: "MEDFORD" },
  "55051": { state: "MN", city: "MORA" },
  "55052": { state: "MN", city: "MORRISTOWN" },
  "55053": { state: "MN", city: "NERSTRAND" },
  "55054": { state: "MN", city: "ELKO NEW MARKET" },
  "55055": { state: "MN", city: "NEWPORT" },
  "55056": { state: "MN", city: "NORTH BRANCH" },
  "55057": { state: "MN", city: "NORTHFIELD" },
  "55060": { state: "MN", city: "OWATONNA" },
  "55063": { state: "MN", city: "PINE CITY" },
  "55065": { state: "MN", city: "RANDOLPH" },
  "55066": { state: "MN", city: "RED WING" },
  "55067": { state: "MN", city: "ROCK CREEK" },
  "55068": { state: "MN", city: "ROSEMOUNT" },
  "55069": { state: "MN", city: "RUSH CITY" },
  "55070": { state: "MN", city: "SAINT FRANCIS" },
  "55071": { state: "MN", city: "SAINT PAUL PARK" },
  "55072": { state: "MN", city: "SANDSTONE" },
  "55073": { state: "MN", city: "SCANDIA" },
  "55074": { state: "MN", city: "SHAFER" },
  "55075": { state: "MN", city: "SOUTH SAINT PAUL" },
  "55076": { state: "MN", city: "INVER GROVE HEIGHTS" },
  "55077": { state: "MN", city: "INVER GROVE HEIGHTS" },
  "55078": { state: "MN", city: "STACY" },
  "55079": { state: "MN", city: "STACY" },
  "55080": { state: "MN", city: "STANCHFIELD" },
  "55082": { state: "MN", city: "STILLWATER" },
  "55083": { state: "MN", city: "STILLWATER" },
  "55084": { state: "MN", city: "TAYLORS FALLS" },
  "55085": { state: "MN", city: "VERMILLION" },
  "55087": { state: "MN", city: "WARSAW" },
  "55088": { state: "MN", city: "WEBSTER" },
  "55089": { state: "MN", city: "WELCH" },
  "55090": { state: "MN", city: "WILLERNIE" },
  "55092": { state: "MN", city: "WYOMING" },
  "55101": { state: "MN", city: "SAINT PAUL" },
  "55102": { state: "MN", city: "SAINT PAUL" },
  "55103": { state: "MN", city: "SAINT PAUL" },
  "55104": { state: "MN", city: "SAINT PAUL" },
  "55105": { state: "MN", city: "SAINT PAUL" },
  "55106": { state: "MN", city: "SAINT PAUL" },
  "55107": { state: "MN", city: "SAINT PAUL" },
  "55108": { state: "MN", city: "SAINT PAUL" },
  "55109": { state: "MN", city: "SAINT PAUL" },
  "55110": { state: "MN", city: "SAINT PAUL" },
  "55111": { state: "MN", city: "SAINT PAUL" },
  "55112": { state: "MN", city: "SAINT PAUL" },
  "55113": { state: "MN", city: "SAINT PAUL" },
  "55114": { state: "MN", city: "SAINT PAUL" },
  "55115": { state: "MN", city: "SAINT PAUL" },
  "55116": { state: "MN", city: "SAINT PAUL" },
  "55117": { state: "MN", city: "SAINT PAUL" },
  "55118": { state: "MN", city: "SAINT PAUL" },
  "55119": { state: "MN", city: "SAINT PAUL" },
  "55120": { state: "MN", city: "SAINT PAUL" },
  "55121": { state: "MN", city: "SAINT PAUL" },
  "55122": { state: "MN", city: "SAINT PAUL" },
  "55123": { state: "MN", city: "SAINT PAUL" },
  "55124": { state: "MN", city: "SAINT PAUL" },
  "55125": { state: "MN", city: "SAINT PAUL" },
  "55126": { state: "MN", city: "SAINT PAUL" },
  "55127": { state: "MN", city: "SAINT PAUL" },
  "55128": { state: "MN", city: "SAINT PAUL" },
  "55129": { state: "MN", city: "SAINT PAUL" },
  "55130": { state: "MN", city: "SAINT PAUL" },
  "55131": { state: "MN", city: "SAINT PAUL" },
  "55133": { state: "MN", city: "SAINT PAUL" },
  "55144": { state: "MN", city: "SAINT PAUL" },
  "55145": { state: "MN", city: "SAINT PAUL" },
  "55146": { state: "MN", city: "SAINT PAUL" },
  "55150": { state: "MN", city: "MENDOTA" },
  "55155": { state: "MN", city: "SAINT PAUL" },
  "55164": { state: "MN", city: "SAINT PAUL" },
  "55170": { state: "MN", city: "SAINT PAUL" },
  "55301": { state: "MN", city: "ALBERTVILLE" },
  "55302": { state: "MN", city: "ANNANDALE" },
  "55303": { state: "MN", city: "ANOKA" },
  "55304": { state: "MN", city: "ANDOVER" },
  "55305": { state: "MN", city: "HOPKINS" },
  "55306": { state: "MN", city: "BURNSVILLE" },
  "55307": { state: "MN", city: "ARLINGTON" },
  "55308": { state: "MN", city: "BECKER" },
  "55309": { state: "MN", city: "BIG LAKE" },
  "55310": { state: "MN", city: "BIRD ISLAND" },
  "55311": { state: "MN", city: "MAPLE GROVE" },
  "55312": { state: "MN", city: "BROWNTON" },
  "55313": { state: "MN", city: "BUFFALO" },
  "55314": { state: "MN", city: "BUFFALO LAKE" },
  "55315": { state: "MN", city: "CARVER" },
  "55316": { state: "MN", city: "CHAMPLIN" },
  "55317": { state: "MN", city: "CHANHASSEN" },
  "55318": { state: "MN", city: "CHASKA" },
  "55319": { state: "MN", city: "CLEAR LAKE" },
  "55320": { state: "MN", city: "CLEARWATER" },
  "55321": { state: "MN", city: "COKATO" },
  "55322": { state: "MN", city: "COLOGNE" },
  "55323": { state: "MN", city: "CRYSTAL BAY" },
  "55324": { state: "MN", city: "DARWIN" },
  "55325": { state: "MN", city: "DASSEL" },
  "55327": { state: "MN", city: "DAYTON" },
  "55328": { state: "MN", city: "DELANO" },
  "55329": { state: "MN", city: "EDEN VALLEY" },
  "55330": { state: "MN", city: "ELK RIVER" },
  "55331": { state: "MN", city: "EXCELSIOR" },
  "55332": { state: "MN", city: "FAIRFAX" },
  "55333": { state: "MN", city: "FRANKLIN" },
  "55334": { state: "MN", city: "GAYLORD" },
  "55335": { state: "MN", city: "GIBBON" },
  "55336": { state: "MN", city: "GLENCOE" },
  "55337": { state: "MN", city: "BURNSVILLE" },
  "55338": { state: "MN", city: "GREEN ISLE" },
  "55339": { state: "MN", city: "HAMBURG" },
  "55340": { state: "MN", city: "HAMEL" },
  "55341": { state: "MN", city: "HANOVER" },
  "55342": { state: "MN", city: "HECTOR" },
  "55343": { state: "MN", city: "HOPKINS" },
  "55344": { state: "MN", city: "EDEN PRAIRIE" },
  "55345": { state: "MN", city: "MINNETONKA" },
  "55346": { state: "MN", city: "EDEN PRAIRIE" },
  "55347": { state: "MN", city: "EDEN PRAIRIE" },
  "55349": { state: "MN", city: "HOWARD LAKE" },
  "55350": { state: "MN", city: "HUTCHINSON" },
  "55352": { state: "MN", city: "JORDAN" },
  "55353": { state: "MN", city: "KIMBALL" },
  "55354": { state: "MN", city: "LESTER PRAIRIE" },
  "55355": { state: "MN", city: "LITCHFIELD" },
  "55356": { state: "MN", city: "LONG LAKE" },
  "55357": { state: "MN", city: "LORETTO" },
  "55358": { state: "MN", city: "MAPLE LAKE" },
  "55359": { state: "MN", city: "MAPLE PLAIN" },
  "55360": { state: "MN", city: "MAYER" },
  "55361": { state: "MN", city: "MINNETONKA BEACH" },
  "55362": { state: "MN", city: "MONTICELLO" },
  "55363": { state: "MN", city: "MONTROSE" },
  "55364": { state: "MN", city: "MOUND" },
  "55366": { state: "MN", city: "NEW AUBURN" },
  "55367": { state: "MN", city: "NEW GERMANY" },
  "55368": { state: "MN", city: "NORWOOD YOUNG AMERICA" },
  "55369": { state: "MN", city: "OSSEO" },
  "55370": { state: "MN", city: "PLATO" },
  "55371": { state: "MN", city: "PRINCETON" },
  "55372": { state: "MN", city: "PRIOR LAKE" },
  "55373": { state: "MN", city: "ROCKFORD" },
  "55374": { state: "MN", city: "ROGERS" },
  "55375": { state: "MN", city: "SAINT BONIFACIUS" },
  "55376": { state: "MN", city: "SAINT MICHAEL" },
  "55377": { state: "MN", city: "SANTIAGO" },
  "55378": { state: "MN", city: "SAVAGE" },
  "55379": { state: "MN", city: "SHAKOPEE" },
  "55381": { state: "MN", city: "SILVER LAKE" },
  "55382": { state: "MN", city: "SOUTH HAVEN" },
  "55384": { state: "MN", city: "SPRING PARK" },
  "55385": { state: "MN", city: "STEWART" },
  "55386": { state: "MN", city: "VICTORIA" },
  "55387": { state: "MN", city: "WACONIA" },
  "55388": { state: "MN", city: "WATERTOWN" },
  "55389": { state: "MN", city: "WATKINS" },
  "55390": { state: "MN", city: "WAVERLY" },
  "55391": { state: "MN", city: "WAYZATA" },
  "55395": { state: "MN", city: "WINSTED" },
  "55396": { state: "MN", city: "WINTHROP" },
  "55397": { state: "MN", city: "YOUNG AMERICA" },
  "55398": { state: "MN", city: "ZIMMERMAN" },
  "55401": { state: "MN", city: "MINNEAPOLIS" },
  "55402": { state: "MN", city: "MINNEAPOLIS" },
  "55403": { state: "MN", city: "MINNEAPOLIS" },
  "55404": { state: "MN", city: "MINNEAPOLIS" },
  "55405": { state: "MN", city: "MINNEAPOLIS" },
  "55406": { state: "MN", city: "MINNEAPOLIS" },
  "55407": { state: "MN", city: "MINNEAPOLIS" },
  "55408": { state: "MN", city: "MINNEAPOLIS" },
  "55409": { state: "MN", city: "MINNEAPOLIS" },
  "55410": { state: "MN", city: "MINNEAPOLIS" },
  "55411": { state: "MN", city: "MINNEAPOLIS" },
  "55412": { state: "MN", city: "MINNEAPOLIS" },
  "55413": { state: "MN", city: "MINNEAPOLIS" },
  "55414": { state: "MN", city: "MINNEAPOLIS" },
  "55415": { state: "MN", city: "MINNEAPOLIS" },
  "55416": { state: "MN", city: "MINNEAPOLIS" },
  "55417": { state: "MN", city: "MINNEAPOLIS" },
  "55418": { state: "MN", city: "MINNEAPOLIS" },
  "55419": { state: "MN", city: "MINNEAPOLIS" },
  "55420": { state: "MN", city: "MINNEAPOLIS" },
  "55421": { state: "MN", city: "MINNEAPOLIS" },
  "55422": { state: "MN", city: "MINNEAPOLIS" },
  "55423": { state: "MN", city: "MINNEAPOLIS" },
  "55424": { state: "MN", city: "MINNEAPOLIS" },
  "55425": { state: "MN", city: "MINNEAPOLIS" },
  "55426": { state: "MN", city: "MINNEAPOLIS" },
  "55427": { state: "MN", city: "MINNEAPOLIS" },
  "55428": { state: "MN", city: "MINNEAPOLIS" },
  "55429": { state: "MN", city: "MINNEAPOLIS" },
  "55430": { state: "MN", city: "MINNEAPOLIS" },
  "55431": { state: "MN", city: "MINNEAPOLIS" },
  "55432": { state: "MN", city: "MINNEAPOLIS" },
  "55433": { state: "MN", city: "MINNEAPOLIS" },
  "55434": { state: "MN", city: "MINNEAPOLIS" },
  "55435": { state: "MN", city: "MINNEAPOLIS" },
  "55436": { state: "MN", city: "MINNEAPOLIS" },
  "55437": { state: "MN", city: "MINNEAPOLIS" },
  "55438": { state: "MN", city: "MINNEAPOLIS" },
  "55439": { state: "MN", city: "MINNEAPOLIS" },
  "55440": { state: "MN", city: "MINNEAPOLIS" },
  "55441": { state: "MN", city: "MINNEAPOLIS" },
  "55442": { state: "MN", city: "MINNEAPOLIS" },
  "55443": { state: "MN", city: "MINNEAPOLIS" },
  "55444": { state: "MN", city: "MINNEAPOLIS" },
  "55445": { state: "MN", city: "MINNEAPOLIS" },
  "55446": { state: "MN", city: "MINNEAPOLIS" },
  "55447": { state: "MN", city: "MINNEAPOLIS" },
  "55448": { state: "MN", city: "MINNEAPOLIS" },
  "55449": { state: "MN", city: "MINNEAPOLIS" },
  "55450": { state: "MN", city: "MINNEAPOLIS" },
  "55454": { state: "MN", city: "MINNEAPOLIS" },
  "55455": { state: "MN", city: "MINNEAPOLIS" },
  "55458": { state: "MN", city: "MINNEAPOLIS" },
  "55459": { state: "MN", city: "MINNEAPOLIS" },
  "55467": { state: "MN", city: "MINNEAPOLIS" },
  "55470": { state: "MN", city: "MINNEAPOLIS" },
  "55472": { state: "MN", city: "MINNEAPOLIS" },
  "55474": { state: "MN", city: "MINNEAPOLIS" },
  "55478": { state: "MN", city: "MINNEAPOLIS" },
  "55479": { state: "MN", city: "MINNEAPOLIS" },
  "55480": { state: "MN", city: "MINNEAPOLIS" },
  "55483": { state: "MN", city: "MINNEAPOLIS" },
  "55484": { state: "MN", city: "MINNEAPOLIS" },
  "55485": { state: "MN", city: "MINNEAPOLIS" },
  "55486": { state: "MN", city: "MINNEAPOLIS" },
  "55487": { state: "MN", city: "MINNEAPOLIS" },
  "55488": { state: "MN", city: "MINNEAPOLIS" },
  "55554": { state: "MN", city: "NORWOOD" },
  "55569": { state: "MN", city: "OSSEO" },
  "55572": { state: "MN", city: "ROCKFORD" },
  "55573": { state: "MN", city: "YOUNG AMERICA" },
  "55577": { state: "MN", city: "ROCKFORD" },
  "55592": { state: "MN", city: "MAPLE PLAIN" },
  "55593": { state: "MN", city: "MAPLE PLAIN" },
  "55599": { state: "MN", city: "LORETTO" },
  "55601": { state: "MN", city: "BEAVER BAY" },
  "55602": { state: "MN", city: "BRIMSON" },
  "55603": { state: "MN", city: "FINLAND" },
  "55604": { state: "MN", city: "GRAND MARAIS" },
  "55605": { state: "MN", city: "GRAND PORTAGE" },
  "55606": { state: "MN", city: "HOVLAND" },
  "55607": { state: "MN", city: "ISABELLA" },
  "55609": { state: "MN", city: "KNIFE RIVER" },
  "55612": { state: "MN", city: "LUTSEN" },
  "55613": { state: "MN", city: "SCHROEDER" },
  "55614": { state: "MN", city: "SILVER BAY" },
  "55615": { state: "MN", city: "TOFTE" },
  "55616": { state: "MN", city: "TWO HARBORS" },
  "55702": { state: "MN", city: "ALBORN" },
  "55703": { state: "MN", city: "ANGORA" },
  "55704": { state: "MN", city: "ASKOV" },
  "55705": { state: "MN", city: "AURORA" },
  "55706": { state: "MN", city: "BABBITT" },
  "55707": { state: "MN", city: "BARNUM" },
  "55708": { state: "MN", city: "BIWABIK" },
  "55709": { state: "MN", city: "BOVEY" },
  "55710": { state: "MN", city: "BRITT" },
  "55711": { state: "MN", city: "BROOKSTON" },
  "55712": { state: "MN", city: "BRUNO" },
  "55713": { state: "MN", city: "BUHL" },
  "55716": { state: "MN", city: "CALUMET" },
  "55717": { state: "MN", city: "CANYON" },
  "55718": { state: "MN", city: "CARLTON" },
  "55719": { state: "MN", city: "CHISHOLM" },
  "55720": { state: "MN", city: "CLOQUET" },
  "55721": { state: "MN", city: "COHASSET" },
  "55722": { state: "MN", city: "COLERAINE" },
  "55723": { state: "MN", city: "COOK" },
  "55724": { state: "MN", city: "COTTON" },
  "55725": { state: "MN", city: "CRANE LAKE" },
  "55726": { state: "MN", city: "CROMWELL" },
  "55730": { state: "MN", city: "GRAND RAPIDS" },
  "55731": { state: "MN", city: "ELY" },
  "55732": { state: "MN", city: "EMBARRASS" },
  "55733": { state: "MN", city: "ESKO" },
  "55734": { state: "MN", city: "EVELETH" },
  "55735": { state: "MN", city: "FINLAYSON" },
  "55736": { state: "MN", city: "FLOODWOOD" },
  "55738": { state: "MN", city: "FORBES" },
  "55741": { state: "MN", city: "GILBERT" },
  "55742": { state: "MN", city: "GOODLAND" },
  "55744": { state: "MN", city: "GRAND RAPIDS" },
  "55745": { state: "MN", city: "GRAND RAPIDS" },
  "55746": { state: "MN", city: "HIBBING" },
  "55748": { state: "MN", city: "HILL CITY" },
  "55749": { state: "MN", city: "HOLYOKE" },
  "55750": { state: "MN", city: "HOYT LAKES" },
  "55751": { state: "MN", city: "IRON" },
  "55752": { state: "MN", city: "JACOBSON" },
  "55753": { state: "MN", city: "KEEWATIN" },
  "55756": { state: "MN", city: "KERRICK" },
  "55757": { state: "MN", city: "KETTLE RIVER" },
  "55758": { state: "MN", city: "KINNEY" },
  "55760": { state: "MN", city: "MCGREGOR" },
  "55763": { state: "MN", city: "MAKINEN" },
  "55764": { state: "MN", city: "MARBLE" },
  "55765": { state: "MN", city: "MEADOWLANDS" },
  "55766": { state: "MN", city: "MELRUDE" },
  "55767": { state: "MN", city: "MOOSE LAKE" },
  "55768": { state: "MN", city: "MOUNTAIN IRON" },
  "55769": { state: "MN", city: "NASHWAUK" },
  "55771": { state: "MN", city: "ORR" },
  "55772": { state: "MN", city: "NETT LAKE" },
  "55775": { state: "MN", city: "PENGILLY" },
  "55777": { state: "MN", city: "VIRGINIA" },
  "55779": { state: "MN", city: "SAGINAW" },
  "55780": { state: "MN", city: "SAWYER" },
  "55781": { state: "MN", city: "SIDE LAKE" },
  "55782": { state: "MN", city: "SOUDAN" },
  "55783": { state: "MN", city: "STURGEON LAKE" },
  "55784": { state: "MN", city: "SWAN RIVER" },
  "55785": { state: "MN", city: "SWATARA" },
  "55786": { state: "MN", city: "TACONITE" },
  "55787": { state: "MN", city: "TAMARACK" },
  "55790": { state: "MN", city: "TOWER" },
  "55791": { state: "MN", city: "TWIG" },
  "55792": { state: "MN", city: "VIRGINIA" },
  "55793": { state: "MN", city: "WARBA" },
  "55795": { state: "MN", city: "WILLOW RIVER" },
  "55796": { state: "MN", city: "WINTON" },
  "55797": { state: "MN", city: "WRENSHALL" },
  "55798": { state: "MN", city: "WRIGHT" },
  "55801": { state: "MN", city: "DULUTH" },
  "55802": { state: "MN", city: "DULUTH" },
  "55803": { state: "MN", city: "DULUTH" },
  "55804": { state: "MN", city: "DULUTH" },
  "55805": { state: "MN", city: "DULUTH" },
  "55806": { state: "MN", city: "DULUTH" },
  "55807": { state: "MN", city: "DULUTH" },
  "55808": { state: "MN", city: "DULUTH" },
  "55810": { state: "MN", city: "DULUTH" },
  "55811": { state: "MN", city: "DULUTH" },
  "55812": { state: "MN", city: "DULUTH" },
  "55814": { state: "MN", city: "DULUTH" },
  "55815": { state: "MN", city: "DULUTH" },
  "55816": { state: "MN", city: "DULUTH" },
  "55901": { state: "MN", city: "ROCHESTER" },
  "55902": { state: "MN", city: "ROCHESTER" },
  "55903": { state: "MN", city: "ROCHESTER" },
  "55904": { state: "MN", city: "ROCHESTER" },
  "55905": { state: "MN", city: "ROCHESTER" },
  "55906": { state: "MN", city: "ROCHESTER" },
  "55909": { state: "MN", city: "ADAMS" },
  "55910": { state: "MN", city: "ALTURA" },
  "55912": { state: "MN", city: "AUSTIN" },
  "55917": { state: "MN", city: "BLOOMING PRAIRIE" },
  "55918": { state: "MN", city: "BROWNSDALE" },
  "55919": { state: "MN", city: "BROWNSVILLE" },
  "55920": { state: "MN", city: "BYRON" },
  "55921": { state: "MN", city: "CALEDONIA" },
  "55922": { state: "MN", city: "CANTON" },
  "55923": { state: "MN", city: "CHATFIELD" },
  "55924": { state: "MN", city: "CLAREMONT" },
  "55925": { state: "MN", city: "DAKOTA" },
  "55926": { state: "MN", city: "DEXTER" },
  "55927": { state: "MN", city: "DODGE CENTER" },
  "55929": { state: "MN", city: "DOVER" },
  "55931": { state: "MN", city: "EITZEN" },
  "55932": { state: "MN", city: "ELGIN" },
  "55933": { state: "MN", city: "ELKTON" },
  "55934": { state: "MN", city: "EYOTA" },
  "55935": { state: "MN", city: "FOUNTAIN" },
  "55936": { state: "MN", city: "GRAND MEADOW" },
  "55939": { state: "MN", city: "HARMONY" },
  "55940": { state: "MN", city: "HAYFIELD" },
  "55941": { state: "MN", city: "HOKAH" },
  "55942": { state: "MN", city: "HOMER" },
  "55943": { state: "MN", city: "HOUSTON" },
  "55944": { state: "MN", city: "KASSON" },
  "55945": { state: "MN", city: "KELLOGG" },
  "55946": { state: "MN", city: "KENYON" },
  "55947": { state: "MN", city: "LA CRESCENT" },
  "55949": { state: "MN", city: "LANESBORO" },
  "55950": { state: "MN", city: "LANSING" },
  "55951": { state: "MN", city: "LE ROY" },
  "55952": { state: "MN", city: "LEWISTON" },
  "55953": { state: "MN", city: "LYLE" },
  "55954": { state: "MN", city: "MABEL" },
  "55955": { state: "MN", city: "MANTORVILLE" },
  "55956": { state: "MN", city: "MAZEPPA" },
  "55957": { state: "MN", city: "MILLVILLE" },
  "55959": { state: "MN", city: "MINNESOTA CITY" },
  "55960": { state: "MN", city: "ORONOCO" },
  "55961": { state: "MN", city: "OSTRANDER" },
  "55962": { state: "MN", city: "PETERSON" },
  "55963": { state: "MN", city: "PINE ISLAND" },
  "55964": { state: "MN", city: "PLAINVIEW" },
  "55965": { state: "MN", city: "PRESTON" },
  "55967": { state: "MN", city: "RACINE" },
  "55968": { state: "MN", city: "READS LANDING" },
  "55969": { state: "MN", city: "ROLLINGSTONE" },
  "55970": { state: "MN", city: "ROSE CREEK" },
  "55971": { state: "MN", city: "RUSHFORD" },
  "55972": { state: "MN", city: "SAINT CHARLES" },
  "55973": { state: "MN", city: "SARGEANT" },
  "55974": { state: "MN", city: "SPRING GROVE" },
  "55975": { state: "MN", city: "SPRING VALLEY" },
  "55976": { state: "MN", city: "STEWARTVILLE" },
  "55977": { state: "MN", city: "TAOPI" },
  "55979": { state: "MN", city: "UTICA" },
  "55981": { state: "MN", city: "WABASHA" },
  "55982": { state: "MN", city: "WALTHAM" },
  "55983": { state: "MN", city: "WANAMINGO" },
  "55985": { state: "MN", city: "WEST CONCORD" },
  "55987": { state: "MN", city: "WINONA" },
  "55990": { state: "MN", city: "WYKOFF" },
  "55991": { state: "MN", city: "ZUMBRO FALLS" },
  "55992": { state: "MN", city: "ZUMBROTA" },
  "56001": { state: "MN", city: "MANKATO" },
  "56002": { state: "MN", city: "MANKATO" },
  "56003": { state: "MN", city: "MANKATO" },
  "56007": { state: "MN", city: "ALBERT LEA" },
  "56009": { state: "MN", city: "ALDEN" },
  "56010": { state: "MN", city: "AMBOY" },
  "56011": { state: "MN", city: "BELLE PLAINE" },
  "56013": { state: "MN", city: "BLUE EARTH" },
  "56014": { state: "MN", city: "BRICELYN" },
  "56016": { state: "MN", city: "CLARKS GROVE" },
  "56017": { state: "MN", city: "CLEVELAND" },
  "56019": { state: "MN", city: "COMFREY" },
  "56020": { state: "MN", city: "CONGER" },
  "56021": { state: "MN", city: "COURTLAND" },
  "56022": { state: "MN", city: "DARFUR" },
  "56023": { state: "MN", city: "DELAVAN" },
  "56024": { state: "MN", city: "EAGLE LAKE" },
  "56025": { state: "MN", city: "EASTON" },
  "56026": { state: "MN", city: "ELLENDALE" },
  "56027": { state: "MN", city: "ELMORE" },
  "56028": { state: "MN", city: "ELYSIAN" },
  "56029": { state: "MN", city: "EMMONS" },
  "56030": { state: "MN", city: "ESSIG" },
  "56031": { state: "MN", city: "FAIRMONT" },
  "56032": { state: "MN", city: "FREEBORN" },
  "56033": { state: "MN", city: "FROST" },
  "56034": { state: "MN", city: "GARDEN CITY" },
  "56035": { state: "MN", city: "GENEVA" },
  "56036": { state: "MN", city: "GLENVILLE" },
  "56037": { state: "MN", city: "GOOD THUNDER" },
  "56039": { state: "MN", city: "GRANADA" },
  "56041": { state: "MN", city: "HANSKA" },
  "56042": { state: "MN", city: "HARTLAND" },
  "56043": { state: "MN", city: "HAYWARD" },
  "56044": { state: "MN", city: "HENDERSON" },
  "56045": { state: "MN", city: "HOLLANDALE" },
  "56046": { state: "MN", city: "HOPE" },
  "56047": { state: "MN", city: "HUNTLEY" },
  "56048": { state: "MN", city: "JANESVILLE" },
  "56050": { state: "MN", city: "KASOTA" },
  "56051": { state: "MN", city: "KIESTER" },
  "56052": { state: "MN", city: "KILKENNY" },
  "56054": { state: "MN", city: "LAFAYETTE" },
  "56055": { state: "MN", city: "LAKE CRYSTAL" },
  "56056": { state: "MN", city: "LA SALLE" },
  "56057": { state: "MN", city: "LE CENTER" },
  "56058": { state: "MN", city: "LE SUEUR" },
  "56060": { state: "MN", city: "LEWISVILLE" },
  "56062": { state: "MN", city: "MADELIA" },
  "56063": { state: "MN", city: "MADISON LAKE" },
  "56065": { state: "MN", city: "MAPLETON" },
  "56068": { state: "MN", city: "MINNESOTA LAKE" },
  "56069": { state: "MN", city: "MONTGOMERY" },
  "56071": { state: "MN", city: "NEW PRAGUE" },
  "56072": { state: "MN", city: "NEW RICHLAND" },
  "56073": { state: "MN", city: "NEW ULM" },
  "56074": { state: "MN", city: "NICOLLET" },
  "56075": { state: "MN", city: "NORTHROP" },
  "56078": { state: "MN", city: "PEMBERTON" },
  "56080": { state: "MN", city: "SAINT CLAIR" },
  "56081": { state: "MN", city: "SAINT JAMES" },
  "56082": { state: "MN", city: "SAINT PETER" },
  "56083": { state: "MN", city: "SANBORN" },
  "56084": { state: "MN", city: "SEARLES" },
  "56085": { state: "MN", city: "SLEEPY EYE" },
  "56087": { state: "MN", city: "SPRINGFIELD" },
  "56088": { state: "MN", city: "TRUMAN" },
  "56089": { state: "MN", city: "TWIN LAKES" },
  "56090": { state: "MN", city: "VERNON CENTER" },
  "56091": { state: "MN", city: "WALDORF" },
  "56093": { state: "MN", city: "WASECA" },
  "56096": { state: "MN", city: "WATERVILLE" },
  "56097": { state: "MN", city: "WELLS" },
  "56098": { state: "MN", city: "WINNEBAGO" },
  "56101": { state: "MN", city: "WINDOM" },
  "56110": { state: "MN", city: "ADRIAN" },
  "56111": { state: "MN", city: "ALPHA" },
  "56113": { state: "MN", city: "ARCO" },
  "56114": { state: "MN", city: "AVOCA" },
  "56115": { state: "MN", city: "BALATON" },
  "56116": { state: "MN", city: "BEAVER CREEK" },
  "56117": { state: "MN", city: "BIGELOW" },
  "56118": { state: "MN", city: "BINGHAM LAKE" },
  "56119": { state: "MN", city: "BREWSTER" },
  "56120": { state: "MN", city: "BUTTERFIELD" },
  "56121": { state: "MN", city: "CEYLON" },
  "56122": { state: "MN", city: "CHANDLER" },
  "56123": { state: "MN", city: "CURRIE" },
  "56125": { state: "MN", city: "DOVRAY" },
  "56127": { state: "MN", city: "DUNNELL" },
  "56128": { state: "MN", city: "EDGERTON" },
  "56129": { state: "MN", city: "ELLSWORTH" },
  "56131": { state: "MN", city: "FULDA" },
  "56132": { state: "MN", city: "GARVIN" },
  "56134": { state: "MN", city: "HARDWICK" },
  "56136": { state: "MN", city: "HENDRICKS" },
  "56137": { state: "MN", city: "HERON LAKE" },
  "56138": { state: "MN", city: "HILLS" },
  "56139": { state: "MN", city: "HOLLAND" },
  "56140": { state: "MN", city: "IHLEN" },
  "56141": { state: "MN", city: "IONA" },
  "56142": { state: "MN", city: "IVANHOE" },
  "56143": { state: "MN", city: "JACKSON" },
  "56144": { state: "MN", city: "JASPER" },
  "56145": { state: "MN", city: "JEFFERS" },
  "56146": { state: "MN", city: "KANARANZI" },
  "56147": { state: "MN", city: "KENNETH" },
  "56149": { state: "MN", city: "LAKE BENTON" },
  "56150": { state: "MN", city: "LAKEFIELD" },
  "56151": { state: "MN", city: "LAKE WILSON" },
  "56152": { state: "MN", city: "LAMBERTON" },
  "56153": { state: "MN", city: "LEOTA" },
  "56155": { state: "MN", city: "LISMORE" },
  "56156": { state: "MN", city: "LUVERNE" },
  "56157": { state: "MN", city: "LYND" },
  "56158": { state: "MN", city: "MAGNOLIA" },
  "56159": { state: "MN", city: "MOUNTAIN LAKE" },
  "56160": { state: "MN", city: "ODIN" },
  "56161": { state: "MN", city: "OKABENA" },
  "56162": { state: "MN", city: "ORMSBY" },
  "56164": { state: "MN", city: "PIPESTONE" },
  "56165": { state: "MN", city: "READING" },
  "56166": { state: "MN", city: "REVERE" },
  "56167": { state: "MN", city: "ROUND LAKE" },
  "56168": { state: "MN", city: "RUSHMORE" },
  "56169": { state: "MN", city: "RUSSELL" },
  "56170": { state: "MN", city: "RUTHTON" },
  "56171": { state: "MN", city: "SHERBURN" },
  "56172": { state: "MN", city: "SLAYTON" },
  "56173": { state: "MN", city: "STEEN" },
  "56174": { state: "MN", city: "STORDEN" },
  "56175": { state: "MN", city: "TRACY" },
  "56176": { state: "MN", city: "TRIMONT" },
  "56178": { state: "MN", city: "TYLER" },
  "56180": { state: "MN", city: "WALNUT GROVE" },
  "56181": { state: "MN", city: "WELCOME" },
  "56183": { state: "MN", city: "WESTBROOK" },
  "56185": { state: "MN", city: "WILMONT" },
  "56186": { state: "MN", city: "WOODSTOCK" },
  "56187": { state: "MN", city: "WORTHINGTON" },
  "56201": { state: "MN", city: "WILLMAR" },
  "56207": { state: "MN", city: "ALBERTA" },
  "56208": { state: "MN", city: "APPLETON" },
  "56209": { state: "MN", city: "ATWATER" },
  "56210": { state: "MN", city: "BARRY" },
  "56211": { state: "MN", city: "BEARDSLEY" },
  "56212": { state: "MN", city: "BELLINGHAM" },
  "56214": { state: "MN", city: "BELVIEW" },
  "56215": { state: "MN", city: "BENSON" },
  "56216": { state: "MN", city: "BLOMKEST" },
  "56218": { state: "MN", city: "BOYD" },
  "56219": { state: "MN", city: "BROWNS VALLEY" },
  "56220": { state: "MN", city: "CANBY" },
  "56221": { state: "MN", city: "CHOKIO" },
  "56222": { state: "MN", city: "CLARA CITY" },
  "56223": { state: "MN", city: "CLARKFIELD" },
  "56224": { state: "MN", city: "CLEMENTS" },
  "56225": { state: "MN", city: "CLINTON" },
  "56226": { state: "MN", city: "CLONTARF" },
  "56227": { state: "MN", city: "CORRELL" },
  "56228": { state: "MN", city: "COSMOS" },
  "56229": { state: "MN", city: "COTTONWOOD" },
  "56230": { state: "MN", city: "DANUBE" },
  "56231": { state: "MN", city: "DANVERS" },
  "56232": { state: "MN", city: "DAWSON" },
  "56235": { state: "MN", city: "DONNELLY" },
  "56236": { state: "MN", city: "DUMONT" },
  "56237": { state: "MN", city: "ECHO" },
  "56239": { state: "MN", city: "GHENT" },
  "56240": { state: "MN", city: "GRACEVILLE" },
  "56241": { state: "MN", city: "GRANITE FALLS" },
  "56243": { state: "MN", city: "GROVE CITY" },
  "56244": { state: "MN", city: "HANCOCK" },
  "56245": { state: "MN", city: "HANLEY FALLS" },
  "56248": { state: "MN", city: "HERMAN" },
  "56249": { state: "MN", city: "HOLLOWAY" },
  "56251": { state: "MN", city: "KANDIYOHI" },
  "56252": { state: "MN", city: "KERKHOVEN" },
  "56253": { state: "MN", city: "LAKE LILLIAN" },
  "56255": { state: "MN", city: "LUCAN" },
  "56256": { state: "MN", city: "MADISON" },
  "56257": { state: "MN", city: "MARIETTA" },
  "56258": { state: "MN", city: "MARSHALL" },
  "56260": { state: "MN", city: "MAYNARD" },
  "56262": { state: "MN", city: "MILAN" },
  "56263": { state: "MN", city: "MILROY" },
  "56264": { state: "MN", city: "MINNEOTA" },
  "56265": { state: "MN", city: "MONTEVIDEO" },
  "56266": { state: "MN", city: "MORGAN" },
  "56267": { state: "MN", city: "MORRIS" },
  "56270": { state: "MN", city: "MORTON" },
  "56271": { state: "MN", city: "MURDOCK" },
  "56273": { state: "MN", city: "NEW LONDON" },
  "56274": { state: "MN", city: "NORCROSS" },
  "56276": { state: "MN", city: "ODESSA" },
  "56277": { state: "MN", city: "OLIVIA" },
  "56278": { state: "MN", city: "ORTONVILLE" },
  "56279": { state: "MN", city: "PENNOCK" },
  "56280": { state: "MN", city: "PORTER" },
  "56281": { state: "MN", city: "PRINSBURG" },
  "56282": { state: "MN", city: "RAYMOND" },
  "56283": { state: "MN", city: "REDWOOD FALLS" },
  "56284": { state: "MN", city: "RENVILLE" },
  "56285": { state: "MN", city: "SACRED HEART" },
  "56287": { state: "MN", city: "SEAFORTH" },
  "56288": { state: "MN", city: "SPICER" },
  "56289": { state: "MN", city: "SUNBURG" },
  "56291": { state: "MN", city: "TAUNTON" },
  "56292": { state: "MN", city: "VESTA" },
  "56293": { state: "MN", city: "WABASSO" },
  "56294": { state: "MN", city: "WANDA" },
  "56295": { state: "MN", city: "WATSON" },
  "56296": { state: "MN", city: "WHEATON" },
  "56297": { state: "MN", city: "WOOD LAKE" },
  "56301": { state: "MN", city: "SAINT CLOUD" },
  "56302": { state: "MN", city: "SAINT CLOUD" },
  "56303": { state: "MN", city: "SAINT CLOUD" },
  "56304": { state: "MN", city: "SAINT CLOUD" },
  "56307": { state: "MN", city: "ALBANY" },
  "56308": { state: "MN", city: "ALEXANDRIA" },
  "56309": { state: "MN", city: "ASHBY" },
  "56310": { state: "MN", city: "AVON" },
  "56311": { state: "MN", city: "BARRETT" },
  "56312": { state: "MN", city: "BELGRADE" },
  "56313": { state: "MN", city: "BOCK" },
  "56314": { state: "MN", city: "BOWLUS" },
  "56315": { state: "MN", city: "BRANDON" },
  "56316": { state: "MN", city: "BROOTEN" },
  "56317": { state: "MN", city: "BUCKMAN" },
  "56318": { state: "MN", city: "BURTRUM" },
  "56319": { state: "MN", city: "CARLOS" },
  "56320": { state: "MN", city: "COLD SPRING" },
  "56321": { state: "MN", city: "COLLEGEVILLE" },
  "56323": { state: "MN", city: "CYRUS" },
  "56324": { state: "MN", city: "DALTON" },
  "56325": { state: "MN", city: "ELROSA" },
  "56326": { state: "MN", city: "EVANSVILLE" },
  "56327": { state: "MN", city: "FARWELL" },
  "56328": { state: "MN", city: "FLENSBURG" },
  "56329": { state: "MN", city: "FOLEY" },
  "56330": { state: "MN", city: "FORESTON" },
  "56331": { state: "MN", city: "FREEPORT" },
  "56332": { state: "MN", city: "GARFIELD" },
  "56333": { state: "MN", city: "GILMAN" },
  "56334": { state: "MN", city: "GLENWOOD" },
  "56335": { state: "MN", city: "GREENWALD" },
  "56336": { state: "MN", city: "GREY EAGLE" },
  "56338": { state: "MN", city: "HILLMAN" },
  "56339": { state: "MN", city: "HOFFMAN" },
  "56340": { state: "MN", city: "HOLDINGFORD" },
  "56341": { state: "MN", city: "HOLMES CITY" },
  "56342": { state: "MN", city: "ISLE" },
  "56343": { state: "MN", city: "KENSINGTON" },
  "56344": { state: "MN", city: "LASTRUP" },
  "56345": { state: "MN", city: "LITTLE FALLS" },
  "56347": { state: "MN", city: "LONG PRAIRIE" },
  "56349": { state: "MN", city: "LOWRY" },
  "56350": { state: "MN", city: "MC GRATH" },
  "56352": { state: "MN", city: "MELROSE" },
  "56353": { state: "MN", city: "MILACA" },
  "56354": { state: "MN", city: "MILTONA" },
  "56355": { state: "MN", city: "NELSON" },
  "56356": { state: "MN", city: "NEW MUNICH" },
  "56357": { state: "MN", city: "OAK PARK" },
  "56358": { state: "MN", city: "OGILVIE" },
  "56359": { state: "MN", city: "ONAMIA" },
  "56360": { state: "MN", city: "OSAKIS" },
  "56361": { state: "MN", city: "PARKERS PRAIRIE" },
  "56362": { state: "MN", city: "PAYNESVILLE" },
  "56363": { state: "MN", city: "PEASE" },
  "56364": { state: "MN", city: "PIERZ" },
  "56367": { state: "MN", city: "RICE" },
  "56368": { state: "MN", city: "RICHMOND" },
  "56369": { state: "MN", city: "ROCKVILLE" },
  "56371": { state: "MN", city: "ROSCOE" },
  "56372": { state: "MN", city: "SAINT CLOUD" },
  "56373": { state: "MN", city: "ROYALTON" },
  "56374": { state: "MN", city: "SAINT JOSEPH" },
  "56375": { state: "MN", city: "SAINT STEPHEN" },
  "56376": { state: "MN", city: "SAINT MARTIN" },
  "56377": { state: "MN", city: "SARTELL" },
  "56378": { state: "MN", city: "SAUK CENTRE" },
  "56379": { state: "MN", city: "SAUK RAPIDS" },
  "56381": { state: "MN", city: "STARBUCK" },
  "56382": { state: "MN", city: "SWANVILLE" },
  "56384": { state: "MN", city: "UPSALA" },
  "56385": { state: "MN", city: "VILLARD" },
  "56386": { state: "MN", city: "WAHKON" },
  "56387": { state: "MN", city: "WAITE PARK" },
  "56388": { state: "MN", city: "SAINT CLOUD" },
  "56389": { state: "MN", city: "WEST UNION" },
  "56393": { state: "MN", city: "SAINT CLOUD" },
  "56395": { state: "MN", city: "SAINT CLOUD" },
  "56396": { state: "MN", city: "SAINT CLOUD" },
  "56397": { state: "MN", city: "SAINT CLOUD" },
  "56398": { state: "MN", city: "SAINT CLOUD" },
  "56399": { state: "MN", city: "SAINT CLOUD" },
  "56401": { state: "MN", city: "BRAINERD" },
  "56425": { state: "MN", city: "BAXTER" },
  "56431": { state: "MN", city: "AITKIN" },
  "56433": { state: "MN", city: "AKELEY" },
  "56434": { state: "MN", city: "ALDRICH" },
  "56435": { state: "MN", city: "BACKUS" },
  "56436": { state: "MN", city: "BENEDICT" },
  "56437": { state: "MN", city: "BERTHA" },
  "56438": { state: "MN", city: "BROWERVILLE" },
  "56440": { state: "MN", city: "CLARISSA" },
  "56441": { state: "MN", city: "CROSBY" },
  "56442": { state: "MN", city: "CROSSLAKE" },
  "56443": { state: "MN", city: "CUSHING" },
  "56444": { state: "MN", city: "DEERWOOD" },
  "56446": { state: "MN", city: "EAGLE BEND" },
  "56447": { state: "MN", city: "EMILY" },
  "56448": { state: "MN", city: "FIFTY LAKES" },
  "56449": { state: "MN", city: "FORT RIPLEY" },
  "56450": { state: "MN", city: "GARRISON" },
  "56452": { state: "MN", city: "HACKENSACK" },
  "56453": { state: "MN", city: "HEWITT" },
  "56455": { state: "MN", city: "IRONTON" },
  "56456": { state: "MN", city: "JENKINS" },
  "56458": { state: "MN", city: "LAKE GEORGE" },
  "56459": { state: "MN", city: "LAKE HUBERT" },
  "56461": { state: "MN", city: "LAPORTE" },
  "56464": { state: "MN", city: "MENAHGA" },
  "56465": { state: "MN", city: "MERRIFIELD" },
  "56466": { state: "MN", city: "MOTLEY" },
  "56467": { state: "MN", city: "NEVIS" },
  "56468": { state: "MN", city: "NISSWA" },
  "56469": { state: "MN", city: "PALISADE" },
  "56470": { state: "MN", city: "PARK RAPIDS" },
  "56472": { state: "MN", city: "PEQUOT LAKES" },
  "56473": { state: "MN", city: "PILLAGER" },
  "56474": { state: "MN", city: "PINE RIVER" },
  "56475": { state: "MN", city: "RANDALL" },
  "56477": { state: "MN", city: "SEBEKA" },
  "56479": { state: "MN", city: "STAPLES" },
  "56481": { state: "MN", city: "VERNDALE" },
  "56482": { state: "MN", city: "WADENA" },
  "56484": { state: "MN", city: "WALKER" },
  "56501": { state: "MN", city: "DETROIT LAKES" },
  "56502": { state: "MN", city: "DETROIT LAKES" },
  "56510": { state: "MN", city: "ADA" },
  "56511": { state: "MN", city: "AUDUBON" },
  "56514": { state: "MN", city: "BARNESVILLE" },
  "56515": { state: "MN", city: "BATTLE LAKE" },
  "56516": { state: "MN", city: "BEJOU" },
  "56517": { state: "MN", city: "BELTRAMI" },
  "56518": { state: "MN", city: "BLUFFTON" },
  "56519": { state: "MN", city: "BORUP" },
  "56520": { state: "MN", city: "BRECKENRIDGE" },
  "56521": { state: "MN", city: "CALLAWAY" },
  "56522": { state: "MN", city: "CAMPBELL" },
  "56523": { state: "MN", city: "CLIMAX" },
  "56524": { state: "MN", city: "CLITHERALL" },
  "56525": { state: "MN", city: "COMSTOCK" },
  "56527": { state: "MN", city: "DEER CREEK" },
  "56528": { state: "MN", city: "DENT" },
  "56529": { state: "MN", city: "DILWORTH" },
  "56531": { state: "MN", city: "ELBOW LAKE" },
  "56533": { state: "MN", city: "ELIZABETH" },
  "56534": { state: "MN", city: "ERHARD" },
  "56535": { state: "MN", city: "ERSKINE" },
  "56536": { state: "MN", city: "FELTON" },
  "56537": { state: "MN", city: "FERGUS FALLS" },
  "56538": { state: "MN", city: "FERGUS FALLS" },
  "56540": { state: "MN", city: "FERTILE" },
  "56541": { state: "MN", city: "FLOM" },
  "56542": { state: "MN", city: "FOSSTON" },
  "56543": { state: "MN", city: "FOXHOME" },
  "56544": { state: "MN", city: "FRAZEE" },
  "56545": { state: "MN", city: "GARY" },
  "56546": { state: "MN", city: "GEORGETOWN" },
  "56547": { state: "MN", city: "GLYNDON" },
  "56548": { state: "MN", city: "HALSTAD" },
  "56549": { state: "MN", city: "HAWLEY" },
  "56550": { state: "MN", city: "HENDRUM" },
  "56551": { state: "MN", city: "HENNING" },
  "56552": { state: "MN", city: "HITTERDAL" },
  "56553": { state: "MN", city: "KENT" },
  "56554": { state: "MN", city: "LAKE PARK" },
  "56556": { state: "MN", city: "MCINTOSH" },
  "56557": { state: "MN", city: "MAHNOMEN" },
  "56560": { state: "MN", city: "MOORHEAD" },
  "56561": { state: "MN", city: "MOORHEAD" },
  "56562": { state: "MN", city: "MOORHEAD" },
  "56563": { state: "MN", city: "MOORHEAD" },
  "56565": { state: "MN", city: "NASHUA" },
  "56566": { state: "MN", city: "NAYTAHWAUSH" },
  "56567": { state: "MN", city: "NEW YORK MILLS" },
  "56568": { state: "MN", city: "NIELSVILLE" },
  "56569": { state: "MN", city: "OGEMA" },
  "56570": { state: "MN", city: "OSAGE" },
  "56571": { state: "MN", city: "OTTERTAIL" },
  "56572": { state: "MN", city: "PELICAN RAPIDS" },
  "56573": { state: "MN", city: "PERHAM" },
  "56574": { state: "MN", city: "PERLEY" },
  "56575": { state: "MN", city: "PONSFORD" },
  "56576": { state: "MN", city: "RICHVILLE" },
  "56577": { state: "MN", city: "RICHWOOD" },
  "56578": { state: "MN", city: "ROCHERT" },
  "56579": { state: "MN", city: "ROTHSAY" },
  "56580": { state: "MN", city: "SABIN" },
  "56581": { state: "MN", city: "SHELLY" },
  "56583": { state: "MN", city: "TINTAH" },
  "56584": { state: "MN", city: "TWIN VALLEY" },
  "56585": { state: "MN", city: "ULEN" },
  "56586": { state: "MN", city: "UNDERWOOD" },
  "56587": { state: "MN", city: "VERGAS" },
  "56588": { state: "MN", city: "VINING" },
  "56589": { state: "MN", city: "WAUBUN" },
  "56590": { state: "MN", city: "WENDELL" },
  "56591": { state: "MN", city: "WHITE EARTH" },
  "56592": { state: "MN", city: "WINGER" },
  "56593": { state: "MN", city: "WOLF LAKE" },
  "56594": { state: "MN", city: "WOLVERTON" },
  "56601": { state: "MN", city: "BEMIDJI" },
  "56619": { state: "MN", city: "BEMIDJI" },
  "56621": { state: "MN", city: "BAGLEY" },
  "56623": { state: "MN", city: "BAUDETTE" },
  "56626": { state: "MN", city: "BENA" },
  "56627": { state: "MN", city: "BIG FALLS" },
  "56628": { state: "MN", city: "BIGFORK" },
  "56629": { state: "MN", city: "BIRCHDALE" },
  "56630": { state: "MN", city: "BLACKDUCK" },
  "56631": { state: "MN", city: "BOWSTRING" },
  "56633": { state: "MN", city: "CASS LAKE" },
  "56634": { state: "MN", city: "CLEARBROOK" },
  "56636": { state: "MN", city: "DEER RIVER" },
  "56637": { state: "MN", city: "TALMOON" },
  "56639": { state: "MN", city: "EFFIE" },
  "56641": { state: "MN", city: "FEDERAL DAM" },
  "56644": { state: "MN", city: "GONVICK" },
  "56646": { state: "MN", city: "GULLY" },
  "56647": { state: "MN", city: "HINES" },
  "56649": { state: "MN", city: "INTERNATIONAL FALLS" },
  "56650": { state: "MN", city: "KELLIHER" },
  "56651": { state: "MN", city: "LENGBY" },
  "56652": { state: "MN", city: "LEONARD" },
  "56653": { state: "MN", city: "LITTLEFORK" },
  "56654": { state: "MN", city: "LOMAN" },
  "56655": { state: "MN", city: "LONGVILLE" },
  "56657": { state: "MN", city: "MARCELL" },
  "56658": { state: "MN", city: "MARGIE" },
  "56659": { state: "MN", city: "MAX" },
  "56660": { state: "MN", city: "MIZPAH" },
  "56661": { state: "MN", city: "NORTHOME" },
  "56662": { state: "MN", city: "OUTING" },
  "56663": { state: "MN", city: "PENNINGTON" },
  "56666": { state: "MN", city: "PONEMAH" },
  "56667": { state: "MN", city: "PUPOSKY" },
  "56668": { state: "MN", city: "RANIER" },
  "56669": { state: "MN", city: "KABETOGAMA" },
  "56670": { state: "MN", city: "REDBY" },
  "56671": { state: "MN", city: "REDLAKE" },
  "56672": { state: "MN", city: "REMER" },
  "56673": { state: "MN", city: "ROOSEVELT" },
  "56676": { state: "MN", city: "SHEVLIN" },
  "56678": { state: "MN", city: "SOLWAY" },
  "56679": { state: "MN", city: "SOUTH INTERNATIONAL FALLS" },
  "56680": { state: "MN", city: "SPRING LAKE" },
  "56681": { state: "MN", city: "SQUAW LAKE" },
  "56683": { state: "MN", city: "TENSTRIKE" },
  "56684": { state: "MN", city: "TRAIL" },
  "56685": { state: "MN", city: "WASKISH" },
  "56686": { state: "MN", city: "WILLIAMS" },
  "56687": { state: "MN", city: "WILTON" },
  "56688": { state: "MN", city: "WIRT" },
  "56701": { state: "MN", city: "THIEF RIVER FALLS" },
  "56710": { state: "MN", city: "ALVARADO" },
  "56711": { state: "MN", city: "ANGLE INLET" },
  "56713": { state: "MN", city: "ARGYLE" },
  "56714": { state: "MN", city: "BADGER" },
  "56715": { state: "MN", city: "BROOKS" },
  "56716": { state: "MN", city: "CROOKSTON" },
  "56720": { state: "MN", city: "DONALDSON" },
  "56721": { state: "MN", city: "EAST GRAND FORKS" },
  "56722": { state: "MN", city: "EUCLID" },
  "56723": { state: "MN", city: "FISHER" },
  "56724": { state: "MN", city: "GATZKE" },
  "56725": { state: "MN", city: "GOODRIDGE" },
  "56726": { state: "MN", city: "GREENBUSH" },
  "56727": { state: "MN", city: "GRYGLA" },
  "56728": { state: "MN", city: "HALLOCK" },
  "56729": { state: "MN", city: "HALMA" },
  "56731": { state: "MN", city: "HUMBOLDT" },
  "56732": { state: "MN", city: "KARLSTAD" },
  "56733": { state: "MN", city: "KENNEDY" },
  "56734": { state: "MN", city: "LAKE BRONSON" },
  "56735": { state: "MN", city: "LANCASTER" },
  "56736": { state: "MN", city: "MENTOR" },
  "56737": { state: "MN", city: "MIDDLE RIVER" },
  "56738": { state: "MN", city: "NEWFOLDEN" },
  "56740": { state: "MN", city: "NOYES" },
  "56741": { state: "MN", city: "OAK ISLAND" },
  "56742": { state: "MN", city: "OKLEE" },
  "56744": { state: "MN", city: "OSLO" },
  "56748": { state: "MN", city: "PLUMMER" },
  "56750": { state: "MN", city: "RED LAKE FALLS" },
  "56751": { state: "MN", city: "ROSEAU" },
  "56754": { state: "MN", city: "SAINT HILAIRE" },
  "56755": { state: "MN", city: "SAINT VINCENT" },
  "56756": { state: "MN", city: "SALOL" },
  "56757": { state: "MN", city: "STEPHEN" },
  "56758": { state: "MN", city: "STRANDQUIST" },
  "56759": { state: "MN", city: "STRATHCONA" },
  "56760": { state: "MN", city: "VIKING" },
  "56761": { state: "MN", city: "WANNASKA" },
  "56762": { state: "MN", city: "WARREN" },
  "56763": { state: "MN", city: "WARROAD" },
  "56901": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56902": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56904": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56908": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56915": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56920": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56933": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56935": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56944": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56945": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56950": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56963": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56964": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56965": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56966": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56967": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56968": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56969": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56970": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56971": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56972": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56973": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56980": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56981": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56982": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56983": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56984": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56985": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56998": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "56999": { state: "DC", city: "PARCEL RETURN SERVICE" },
  "57001": { state: "SD", city: "ALCESTER" },
  "57002": { state: "SD", city: "AURORA" },
  "57003": { state: "SD", city: "BALTIC" },
  "57004": { state: "SD", city: "BERESFORD" },
  "57005": { state: "SD", city: "BRANDON" },
  "57006": { state: "SD", city: "BROOKINGS" },
  "57007": { state: "SD", city: "BROOKINGS" },
  "57010": { state: "SD", city: "BURBANK" },
  "57012": { state: "SD", city: "CANISTOTA" },
  "57013": { state: "SD", city: "CANTON" },
  "57014": { state: "SD", city: "CENTERVILLE" },
  "57015": { state: "SD", city: "CHANCELLOR" },
  "57016": { state: "SD", city: "CHESTER" },
  "57017": { state: "SD", city: "COLMAN" },
  "57018": { state: "SD", city: "COLTON" },
  "57020": { state: "SD", city: "CROOKS" },
  "57021": { state: "SD", city: "DAVIS" },
  "57022": { state: "SD", city: "DELL RAPIDS" },
  "57024": { state: "SD", city: "EGAN" },
  "57025": { state: "SD", city: "ELK POINT" },
  "57026": { state: "SD", city: "ELKTON" },
  "57027": { state: "SD", city: "FAIRVIEW" },
  "57028": { state: "SD", city: "FLANDREAU" },
  "57029": { state: "SD", city: "FREEMAN" },
  "57030": { state: "SD", city: "GARRETSON" },
  "57031": { state: "SD", city: "GAYVILLE" },
  "57032": { state: "SD", city: "HARRISBURG" },
  "57033": { state: "SD", city: "HARTFORD" },
  "57034": { state: "SD", city: "HUDSON" },
  "57035": { state: "SD", city: "HUMBOLDT" },
  "57036": { state: "SD", city: "HURLEY" },
  "57037": { state: "SD", city: "IRENE" },
  "57038": { state: "SD", city: "JEFFERSON" },
  "57039": { state: "SD", city: "LENNOX" },
  "57040": { state: "SD", city: "LESTERVILLE" },
  "57041": { state: "SD", city: "LYONS" },
  "57042": { state: "SD", city: "MADISON" },
  "57043": { state: "SD", city: "MARION" },
  "57045": { state: "SD", city: "MENNO" },
  "57046": { state: "SD", city: "MISSION HILL" },
  "57047": { state: "SD", city: "MONROE" },
  "57048": { state: "SD", city: "MONTROSE" },
  "57049": { state: "SD", city: "NORTH SIOUX CITY" },
  "57050": { state: "SD", city: "NUNDA" },
  "57051": { state: "SD", city: "OLDHAM" },
  "57052": { state: "SD", city: "OLIVET" },
  "57053": { state: "SD", city: "PARKER" },
  "57054": { state: "SD", city: "RAMONA" },
  "57055": { state: "SD", city: "RENNER" },
  "57057": { state: "SD", city: "RUTLAND" },
  "57058": { state: "SD", city: "SALEM" },
  "57059": { state: "SD", city: "SCOTLAND" },
  "57061": { state: "SD", city: "SINAI" },
  "57062": { state: "SD", city: "SPRINGFIELD" },
  "57063": { state: "SD", city: "TABOR" },
  "57064": { state: "SD", city: "TEA" },
  "57065": { state: "SD", city: "TRENT" },
  "57066": { state: "SD", city: "TYNDALL" },
  "57067": { state: "SD", city: "UTICA" },
  "57068": { state: "SD", city: "VALLEY SPRINGS" },
  "57069": { state: "SD", city: "VERMILLION" },
  "57070": { state: "SD", city: "VIBORG" },
  "57071": { state: "SD", city: "VOLGA" },
  "57072": { state: "SD", city: "VOLIN" },
  "57073": { state: "SD", city: "WAKONDA" },
  "57075": { state: "SD", city: "WENTWORTH" },
  "57076": { state: "SD", city: "WINFRED" },
  "57077": { state: "SD", city: "WORTHING" },
  "57078": { state: "SD", city: "YANKTON" },
  "57101": { state: "SD", city: "SIOUX FALLS" },
  "57103": { state: "SD", city: "SIOUX FALLS" },
  "57104": { state: "SD", city: "SIOUX FALLS" },
  "57105": { state: "SD", city: "SIOUX FALLS" },
  "57106": { state: "SD", city: "SIOUX FALLS" },
  "57107": { state: "SD", city: "SIOUX FALLS" },
  "57108": { state: "SD", city: "SIOUX FALLS" },
  "57109": { state: "SD", city: "SIOUX FALLS" },
  "57110": { state: "SD", city: "SIOUX FALLS" },
  "57117": { state: "SD", city: "SIOUX FALLS" },
  "57118": { state: "SD", city: "SIOUX FALLS" },
  "57186": { state: "SD", city: "SIOUX FALLS" },
  "57193": { state: "SD", city: "SIOUX FALLS" },
  "57197": { state: "SD", city: "SIOUX FALLS" },
  "57198": { state: "SD", city: "SIOUX FALLS" },
  "57201": { state: "SD", city: "WATERTOWN" },
  "57212": { state: "SD", city: "ARLINGTON" },
  "57213": { state: "SD", city: "ASTORIA" },
  "57214": { state: "SD", city: "BADGER" },
  "57216": { state: "SD", city: "BIG STONE CITY" },
  "57217": { state: "SD", city: "BRADLEY" },
  "57218": { state: "SD", city: "BRANDT" },
  "57219": { state: "SD", city: "BRISTOL" },
  "57220": { state: "SD", city: "BRUCE" },
  "57221": { state: "SD", city: "BRYANT" },
  "57223": { state: "SD", city: "CASTLEWOOD" },
  "57224": { state: "SD", city: "CLAIRE CITY" },
  "57225": { state: "SD", city: "CLARK" },
  "57226": { state: "SD", city: "CLEAR LAKE" },
  "57227": { state: "SD", city: "CORONA" },
  "57231": { state: "SD", city: "DE SMET" },
  "57232": { state: "SD", city: "EDEN" },
  "57233": { state: "SD", city: "ERWIN" },
  "57234": { state: "SD", city: "ESTELLINE" },
  "57235": { state: "SD", city: "FLORENCE" },
  "57236": { state: "SD", city: "GARDEN CITY" },
  "57237": { state: "SD", city: "GARY" },
  "57238": { state: "SD", city: "GOODWIN" },
  "57239": { state: "SD", city: "GRENVILLE" },
  "57241": { state: "SD", city: "HAYTI" },
  "57242": { state: "SD", city: "HAZEL" },
  "57243": { state: "SD", city: "HENRY" },
  "57245": { state: "SD", city: "KRANZBURG" },
  "57246": { state: "SD", city: "LABOLT" },
  "57247": { state: "SD", city: "LAKE CITY" },
  "57248": { state: "SD", city: "LAKE NORDEN" },
  "57249": { state: "SD", city: "LAKE PRESTON" },
  "57251": { state: "SD", city: "MARVIN" },
  "57252": { state: "SD", city: "MILBANK" },
  "57255": { state: "SD", city: "NEW EFFINGTON" },
  "57256": { state: "SD", city: "ORTLEY" },
  "57257": { state: "SD", city: "PEEVER" },
  "57258": { state: "SD", city: "RAYMOND" },
  "57259": { state: "SD", city: "REVILLO" },
  "57260": { state: "SD", city: "ROSHOLT" },
  "57261": { state: "SD", city: "ROSLYN" },
  "57262": { state: "SD", city: "SISSETON" },
  "57263": { state: "SD", city: "SOUTH SHORE" },
  "57264": { state: "SD", city: "STOCKHOLM" },
  "57265": { state: "SD", city: "STRANDBURG" },
  "57266": { state: "SD", city: "SUMMIT" },
  "57268": { state: "SD", city: "TORONTO" },
  "57269": { state: "SD", city: "TWIN BROOKS" },
  "57270": { state: "SD", city: "VEBLEN" },
  "57271": { state: "SD", city: "VIENNA" },
  "57272": { state: "SD", city: "WALLACE" },
  "57273": { state: "SD", city: "WAUBAY" },
  "57274": { state: "SD", city: "WEBSTER" },
  "57276": { state: "SD", city: "WHITE" },
  "57278": { state: "SD", city: "WILLOW LAKE" },
  "57279": { state: "SD", city: "WILMOT" },
  "57301": { state: "SD", city: "MITCHELL" },
  "57311": { state: "SD", city: "ALEXANDRIA" },
  "57312": { state: "SD", city: "ALPENA" },
  "57313": { state: "SD", city: "ARMOUR" },
  "57314": { state: "SD", city: "ARTESIAN" },
  "57315": { state: "SD", city: "AVON" },
  "57317": { state: "SD", city: "BONESTEEL" },
  "57319": { state: "SD", city: "BRIDGEWATER" },
  "57321": { state: "SD", city: "CANOVA" },
  "57322": { state: "SD", city: "CARPENTER" },
  "57323": { state: "SD", city: "CARTHAGE" },
  "57324": { state: "SD", city: "CAVOUR" },
  "57325": { state: "SD", city: "CHAMBERLAIN" },
  "57326": { state: "SD", city: "CHAMBERLAIN" },
  "57328": { state: "SD", city: "CORSICA" },
  "57329": { state: "SD", city: "DANTE" },
  "57330": { state: "SD", city: "DELMONT" },
  "57331": { state: "SD", city: "DIMOCK" },
  "57332": { state: "SD", city: "EMERY" },
  "57334": { state: "SD", city: "ETHAN" },
  "57335": { state: "SD", city: "FAIRFAX" },
  "57337": { state: "SD", city: "FEDORA" },
  "57339": { state: "SD", city: "FORT THOMPSON" },
  "57340": { state: "SD", city: "FULTON" },
  "57341": { state: "SD", city: "GANN VALLEY" },
  "57342": { state: "SD", city: "GEDDES" },
  "57344": { state: "SD", city: "HARRISON" },
  "57345": { state: "SD", city: "HIGHMORE" },
  "57346": { state: "SD", city: "STEPHAN" },
  "57348": { state: "SD", city: "HITCHCOCK" },
  "57349": { state: "SD", city: "HOWARD" },
  "57350": { state: "SD", city: "HURON" },
  "57353": { state: "SD", city: "IROQUOIS" },
  "57354": { state: "SD", city: "KAYLOR" },
  "57355": { state: "SD", city: "KIMBALL" },
  "57356": { state: "SD", city: "LAKE ANDES" },
  "57358": { state: "SD", city: "LANE" },
  "57359": { state: "SD", city: "LETCHER" },
  "57361": { state: "SD", city: "MARTY" },
  "57362": { state: "SD", city: "MILLER" },
  "57363": { state: "SD", city: "MOUNT VERNON" },
  "57364": { state: "SD", city: "NEW HOLLAND" },
  "57365": { state: "SD", city: "OACOMA" },
  "57366": { state: "SD", city: "PARKSTON" },
  "57367": { state: "SD", city: "PICKSTOWN" },
  "57368": { state: "SD", city: "PLANKINTON" },
  "57369": { state: "SD", city: "PLATTE" },
  "57370": { state: "SD", city: "PUKWANA" },
  "57371": { state: "SD", city: "REE HEIGHTS" },
  "57373": { state: "SD", city: "SAINT LAWRENCE" },
  "57374": { state: "SD", city: "SPENCER" },
  "57375": { state: "SD", city: "STICKNEY" },
  "57376": { state: "SD", city: "TRIPP" },
  "57379": { state: "SD", city: "VIRGIL" },
  "57380": { state: "SD", city: "WAGNER" },
  "57381": { state: "SD", city: "WESSINGTON" },
  "57382": { state: "SD", city: "WESSINGTON SPRINGS" },
  "57383": { state: "SD", city: "WHITE LAKE" },
  "57384": { state: "SD", city: "WOLSEY" },
  "57385": { state: "SD", city: "WOONSOCKET" },
  "57386": { state: "SD", city: "YALE" },
  "57399": { state: "SD", city: "HURON" },
  "57401": { state: "SD", city: "ABERDEEN" },
  "57402": { state: "SD", city: "ABERDEEN" },
  "57420": { state: "SD", city: "AKASKA" },
  "57421": { state: "SD", city: "AMHERST" },
  "57422": { state: "SD", city: "ANDOVER" },
  "57424": { state: "SD", city: "ASHTON" },
  "57426": { state: "SD", city: "BARNARD" },
  "57427": { state: "SD", city: "BATH" },
  "57428": { state: "SD", city: "BOWDLE" },
  "57429": { state: "SD", city: "BRENTFORD" },
  "57430": { state: "SD", city: "BRITTON" },
  "57432": { state: "SD", city: "CLAREMONT" },
  "57433": { state: "SD", city: "COLUMBIA" },
  "57434": { state: "SD", city: "CONDE" },
  "57435": { state: "SD", city: "CRESBARD" },
  "57436": { state: "SD", city: "DOLAND" },
  "57437": { state: "SD", city: "EUREKA" },
  "57438": { state: "SD", city: "FAULKTON" },
  "57439": { state: "SD", city: "FERNEY" },
  "57440": { state: "SD", city: "FRANKFORT" },
  "57441": { state: "SD", city: "FREDERICK" },
  "57442": { state: "SD", city: "GETTYSBURG" },
  "57445": { state: "SD", city: "GROTON" },
  "57446": { state: "SD", city: "HECLA" },
  "57448": { state: "SD", city: "HOSMER" },
  "57449": { state: "SD", city: "HOUGHTON" },
  "57450": { state: "SD", city: "HOVEN" },
  "57451": { state: "SD", city: "IPSWICH" },
  "57452": { state: "SD", city: "JAVA" },
  "57454": { state: "SD", city: "LANGFORD" },
  "57455": { state: "SD", city: "LEBANON" },
  "57456": { state: "SD", city: "LEOLA" },
  "57457": { state: "SD", city: "LONG LAKE" },
  "57460": { state: "SD", city: "MANSFIELD" },
  "57461": { state: "SD", city: "MELLETTE" },
  "57465": { state: "SD", city: "NORTHVILLE" },
  "57466": { state: "SD", city: "ONAKA" },
  "57467": { state: "SD", city: "ORIENT" },
  "57468": { state: "SD", city: "PIERPONT" },
  "57469": { state: "SD", city: "REDFIELD" },
  "57470": { state: "SD", city: "ROCKHAM" },
  "57471": { state: "SD", city: "ROSCOE" },
  "57472": { state: "SD", city: "SELBY" },
  "57473": { state: "SD", city: "SENECA" },
  "57474": { state: "SD", city: "STRATFORD" },
  "57475": { state: "SD", city: "TOLSTOY" },
  "57476": { state: "SD", city: "TULARE" },
  "57477": { state: "SD", city: "TURTON" },
  "57479": { state: "SD", city: "WARNER" },
  "57481": { state: "SD", city: "WESTPORT" },
  "57501": { state: "SD", city: "PIERRE" },
  "57520": { state: "SD", city: "AGAR" },
  "57521": { state: "SD", city: "BELVIDERE" },
  "57522": { state: "SD", city: "BLUNT" },
  "57523": { state: "SD", city: "BURKE" },
  "57528": { state: "SD", city: "COLOME" },
  "57529": { state: "SD", city: "DALLAS" },
  "57531": { state: "SD", city: "DRAPER" },
  "57532": { state: "SD", city: "FORT PIERRE" },
  "57533": { state: "SD", city: "GREGORY" },
  "57534": { state: "SD", city: "HAMILL" },
  "57536": { state: "SD", city: "HARROLD" },
  "57537": { state: "SD", city: "HAYES" },
  "57538": { state: "SD", city: "HERRICK" },
  "57540": { state: "SD", city: "HOLABIRD" },
  "57541": { state: "SD", city: "IDEAL" },
  "57543": { state: "SD", city: "KADOKA" },
  "57544": { state: "SD", city: "KENNEBEC" },
  "57547": { state: "SD", city: "LONG VALLEY" },
  "57548": { state: "SD", city: "LOWER BRULE" },
  "57551": { state: "SD", city: "MARTIN" },
  "57552": { state: "SD", city: "MIDLAND" },
  "57553": { state: "SD", city: "MILESVILLE" },
  "57555": { state: "SD", city: "MISSION" },
  "57559": { state: "SD", city: "MURDO" },
  "57560": { state: "SD", city: "NORRIS" },
  "57562": { state: "SD", city: "OKATON" },
  "57563": { state: "SD", city: "OKREEK" },
  "57564": { state: "SD", city: "ONIDA" },
  "57566": { state: "SD", city: "PARMELEE" },
  "57567": { state: "SD", city: "PHILIP" },
  "57568": { state: "SD", city: "PRESHO" },
  "57569": { state: "SD", city: "RELIANCE" },
  "57570": { state: "SD", city: "ROSEBUD" },
  "57571": { state: "SD", city: "SAINT CHARLES" },
  "57572": { state: "SD", city: "SAINT FRANCIS" },
  "57574": { state: "SD", city: "TUTHILL" },
  "57576": { state: "SD", city: "VIVIAN" },
  "57577": { state: "SD", city: "WANBLEE" },
  "57579": { state: "SD", city: "WHITE RIVER" },
  "57580": { state: "SD", city: "WINNER" },
  "57584": { state: "SD", city: "WITTEN" },
  "57585": { state: "SD", city: "WOOD" },
  "57601": { state: "SD", city: "MOBRIDGE" },
  "57620": { state: "SD", city: "BISON" },
  "57621": { state: "SD", city: "BULLHEAD" },
  "57622": { state: "SD", city: "CHERRY CREEK" },
  "57623": { state: "SD", city: "DUPREE" },
  "57625": { state: "SD", city: "EAGLE BUTTE" },
  "57626": { state: "SD", city: "FAITH" },
  "57630": { state: "SD", city: "GLENCROSS" },
  "57631": { state: "SD", city: "GLENHAM" },
  "57632": { state: "SD", city: "HERREID" },
  "57633": { state: "SD", city: "ISABEL" },
  "57634": { state: "SD", city: "KELDRON" },
  "57636": { state: "SD", city: "LANTRY" },
  "57638": { state: "SD", city: "LEMMON" },
  "57639": { state: "SD", city: "LITTLE EAGLE" },
  "57640": { state: "SD", city: "LODGEPOLE" },
  "57641": { state: "SD", city: "MC INTOSH" },
  "57642": { state: "SD", city: "MC LAUGHLIN" },
  "57644": { state: "SD", city: "MEADOW" },
  "57645": { state: "SD", city: "MORRISTOWN" },
  "57646": { state: "SD", city: "MOUND CITY" },
  "57648": { state: "SD", city: "POLLOCK" },
  "57649": { state: "SD", city: "PRAIRIE CITY" },
  "57650": { state: "SD", city: "RALPH" },
  "57651": { state: "SD", city: "REVA" },
  "57652": { state: "SD", city: "RIDGEVIEW" },
  "57656": { state: "SD", city: "TIMBER LAKE" },
  "57657": { state: "SD", city: "TRAIL CITY" },
  "57658": { state: "SD", city: "WAKPALA" },
  "57659": { state: "SD", city: "WALKER" },
  "57660": { state: "SD", city: "WATAUGA" },
  "57661": { state: "SD", city: "WHITEHORSE" },
  "57701": { state: "SD", city: "RAPID CITY" },
  "57702": { state: "SD", city: "RAPID CITY" },
  "57703": { state: "SD", city: "RAPID CITY" },
  "57706": { state: "SD", city: "ELLSWORTH AFB" },
  "57709": { state: "SD", city: "RAPID CITY" },
  "57714": { state: "SD", city: "ALLEN" },
  "57716": { state: "SD", city: "BATESLAND" },
  "57717": { state: "SD", city: "BELLE FOURCHE" },
  "57718": { state: "SD", city: "BLACK HAWK" },
  "57719": { state: "SD", city: "BOX ELDER" },
  "57720": { state: "SD", city: "BUFFALO" },
  "57722": { state: "SD", city: "BUFFALO GAP" },
  "57724": { state: "SD", city: "CAMP CROOK" },
  "57725": { state: "SD", city: "CAPUTA" },
  "57730": { state: "SD", city: "CUSTER" },
  "57732": { state: "SD", city: "DEADWOOD" },
  "57735": { state: "SD", city: "EDGEMONT" },
  "57737": { state: "SD", city: "ENNING" },
  "57738": { state: "SD", city: "FAIRBURN" },
  "57741": { state: "SD", city: "FORT MEADE" },
  "57744": { state: "SD", city: "HERMOSA" },
  "57745": { state: "SD", city: "HILL CITY" },
  "57747": { state: "SD", city: "HOT SPRINGS" },
  "57748": { state: "SD", city: "HOWES" },
  "57750": { state: "SD", city: "INTERIOR" },
  "57751": { state: "SD", city: "KEYSTONE" },
  "57752": { state: "SD", city: "KYLE" },
  "57754": { state: "SD", city: "LEAD" },
  "57755": { state: "SD", city: "LUDLOW" },
  "57756": { state: "SD", city: "MANDERSON" },
  "57758": { state: "SD", city: "MUD BUTTE" },
  "57759": { state: "SD", city: "NEMO" },
  "57760": { state: "SD", city: "NEWELL" },
  "57761": { state: "SD", city: "NEW UNDERWOOD" },
  "57762": { state: "SD", city: "NISLAND" },
  "57763": { state: "SD", city: "OELRICHS" },
  "57764": { state: "SD", city: "OGLALA" },
  "57766": { state: "SD", city: "ORAL" },
  "57767": { state: "SD", city: "OWANKA" },
  "57769": { state: "SD", city: "PIEDMONT" },
  "57770": { state: "SD", city: "PINE RIDGE" },
  "57772": { state: "SD", city: "PORCUPINE" },
  "57773": { state: "SD", city: "PRINGLE" },
  "57775": { state: "SD", city: "QUINN" },
  "57776": { state: "SD", city: "REDIG" },
  "57779": { state: "SD", city: "SAINT ONGE" },
  "57780": { state: "SD", city: "SCENIC" },
  "57782": { state: "SD", city: "SMITHWICK" },
  "57783": { state: "SD", city: "SPEARFISH" },
  "57785": { state: "SD", city: "STURGIS" },
  "57787": { state: "SD", city: "UNION CENTER" },
  "57788": { state: "SD", city: "VALE" },
  "57790": { state: "SD", city: "WALL" },
  "57791": { state: "SD", city: "WASTA" },
  "57792": { state: "SD", city: "WHITE OWL" },
  "57793": { state: "SD", city: "WHITEWOOD" },
  "57794": { state: "SD", city: "WOUNDED KNEE" },
  "57799": { state: "SD", city: "SPEARFISH" },
  "58001": { state: "ND", city: "ABERCROMBIE" },
  "58002": { state: "ND", city: "ABSARAKA" },
  "58004": { state: "ND", city: "AMENIA" },
  "58005": { state: "ND", city: "ARGUSVILLE" },
  "58006": { state: "ND", city: "ARTHUR" },
  "58007": { state: "ND", city: "AYR" },
  "58008": { state: "ND", city: "BARNEY" },
  "58009": { state: "ND", city: "BLANCHARD" },
  "58011": { state: "ND", city: "BUFFALO" },
  "58012": { state: "ND", city: "CASSELTON" },
  "58013": { state: "ND", city: "CAYUGA" },
  "58015": { state: "ND", city: "CHRISTINE" },
  "58016": { state: "ND", city: "CLIFFORD" },
  "58017": { state: "ND", city: "COGSWELL" },
  "58018": { state: "ND", city: "COLFAX" },
  "58021": { state: "ND", city: "DAVENPORT" },
  "58027": { state: "ND", city: "ENDERLIN" },
  "58029": { state: "ND", city: "ERIE" },
  "58030": { state: "ND", city: "FAIRMOUNT" },
  "58031": { state: "ND", city: "FINGAL" },
  "58032": { state: "ND", city: "FORMAN" },
  "58033": { state: "ND", city: "FORT RANSOM" },
  "58035": { state: "ND", city: "GALESBURG" },
  "58036": { state: "ND", city: "GARDNER" },
  "58038": { state: "ND", city: "GRANDIN" },
  "58040": { state: "ND", city: "GWINNER" },
  "58041": { state: "ND", city: "HANKINSON" },
  "58042": { state: "ND", city: "HARWOOD" },
  "58043": { state: "ND", city: "HAVANA" },
  "58045": { state: "ND", city: "HILLSBORO" },
  "58046": { state: "ND", city: "HOPE" },
  "58047": { state: "ND", city: "HORACE" },
  "58048": { state: "ND", city: "HUNTER" },
  "58049": { state: "ND", city: "KATHRYN" },
  "58051": { state: "ND", city: "KINDRED" },
  "58052": { state: "ND", city: "LEONARD" },
  "58053": { state: "ND", city: "LIDGERWOOD" },
  "58054": { state: "ND", city: "LISBON" },
  "58056": { state: "ND", city: "LUVERNE" },
  "58057": { state: "ND", city: "MCLEOD" },
  "58058": { state: "ND", city: "MANTADOR" },
  "58059": { state: "ND", city: "MAPLETON" },
  "58060": { state: "ND", city: "MILNOR" },
  "58061": { state: "ND", city: "MOORETON" },
  "58062": { state: "ND", city: "NOME" },
  "58063": { state: "ND", city: "ORISKA" },
  "58064": { state: "ND", city: "PAGE" },
  "58065": { state: "ND", city: "PILLSBURY" },
  "58067": { state: "ND", city: "RUTLAND" },
  "58068": { state: "ND", city: "SHELDON" },
  "58069": { state: "ND", city: "STIRUM" },
  "58071": { state: "ND", city: "TOWER CITY" },
  "58072": { state: "ND", city: "VALLEY CITY" },
  "58074": { state: "ND", city: "WAHPETON" },
  "58075": { state: "ND", city: "WAHPETON" },
  "58076": { state: "ND", city: "WAHPETON" },
  "58077": { state: "ND", city: "WALCOTT" },
  "58078": { state: "ND", city: "WEST FARGO" },
  "58079": { state: "ND", city: "WHEATLAND" },
  "58081": { state: "ND", city: "WYNDMERE" },
  "58102": { state: "ND", city: "FARGO" },
  "58103": { state: "ND", city: "FARGO" },
  "58104": { state: "ND", city: "FARGO" },
  "58105": { state: "ND", city: "FARGO" },
  "58106": { state: "ND", city: "FARGO" },
  "58107": { state: "ND", city: "FARGO" },
  "58108": { state: "ND", city: "FARGO" },
  "58109": { state: "ND", city: "FARGO" },
  "58121": { state: "ND", city: "FARGO" },
  "58122": { state: "ND", city: "FARGO" },
  "58124": { state: "ND", city: "FARGO" },
  "58125": { state: "ND", city: "FARGO" },
  "58126": { state: "ND", city: "FARGO" },
  "58201": { state: "ND", city: "GRAND FORKS" },
  "58202": { state: "ND", city: "GRAND FORKS" },
  "58203": { state: "ND", city: "GRAND FORKS" },
  "58204": { state: "ND", city: "GRAND FORKS AFB" },
  "58205": { state: "ND", city: "GRAND FORKS AFB" },
  "58206": { state: "ND", city: "GRAND FORKS" },
  "58207": { state: "ND", city: "GRAND FORKS" },
  "58208": { state: "ND", city: "GRAND FORKS" },
  "58210": { state: "ND", city: "ADAMS" },
  "58212": { state: "ND", city: "ANETA" },
  "58214": { state: "ND", city: "ARVILLA" },
  "58216": { state: "ND", city: "BATHGATE" },
  "58218": { state: "ND", city: "BUXTON" },
  "58219": { state: "ND", city: "CALEDONIA" },
  "58220": { state: "ND", city: "CAVALIER" },
  "58222": { state: "ND", city: "CRYSTAL" },
  "58223": { state: "ND", city: "CUMMINGS" },
  "58224": { state: "ND", city: "DAHLEN" },
  "58225": { state: "ND", city: "DRAYTON" },
  "58227": { state: "ND", city: "EDINBURG" },
  "58228": { state: "ND", city: "EMERADO" },
  "58229": { state: "ND", city: "FAIRDALE" },
  "58230": { state: "ND", city: "FINLEY" },
  "58231": { state: "ND", city: "FORDVILLE" },
  "58233": { state: "ND", city: "FOREST RIVER" },
  "58235": { state: "ND", city: "GILBY" },
  "58236": { state: "ND", city: "GLASSTON" },
  "58237": { state: "ND", city: "GRAFTON" },
  "58238": { state: "ND", city: "HAMILTON" },
  "58239": { state: "ND", city: "HANNAH" },
  "58240": { state: "ND", city: "HATTON" },
  "58241": { state: "ND", city: "HENSEL" },
  "58243": { state: "ND", city: "HOOPLE" },
  "58244": { state: "ND", city: "INKSTER" },
  "58249": { state: "ND", city: "LANGDON" },
  "58250": { state: "ND", city: "LANKIN" },
  "58251": { state: "ND", city: "LARIMORE" },
  "58254": { state: "ND", city: "MCVILLE" },
  "58255": { state: "ND", city: "MAIDA" },
  "58256": { state: "ND", city: "MANVEL" },
  "58257": { state: "ND", city: "MAYVILLE" },
  "58258": { state: "ND", city: "MEKINOCK" },
  "58259": { state: "ND", city: "MICHIGAN" },
  "58260": { state: "ND", city: "MILTON" },
  "58261": { state: "ND", city: "MINTO" },
  "58262": { state: "ND", city: "MOUNTAIN" },
  "58265": { state: "ND", city: "NECHE" },
  "58266": { state: "ND", city: "NIAGARA" },
  "58267": { state: "ND", city: "NORTHWOOD" },
  "58269": { state: "ND", city: "OSNABROCK" },
  "58270": { state: "ND", city: "PARK RIVER" },
  "58271": { state: "ND", city: "PEMBINA" },
  "58272": { state: "ND", city: "PETERSBURG" },
  "58273": { state: "ND", city: "PISEK" },
  "58274": { state: "ND", city: "PORTLAND" },
  "58275": { state: "ND", city: "REYNOLDS" },
  "58276": { state: "ND", city: "SAINT THOMAS" },
  "58277": { state: "ND", city: "SHARON" },
  "58278": { state: "ND", city: "THOMPSON" },
  "58281": { state: "ND", city: "WALES" },
  "58282": { state: "ND", city: "WALHALLA" },
  "58301": { state: "ND", city: "DEVILS LAKE" },
  "58310": { state: "ND", city: "AGATE" },
  "58311": { state: "ND", city: "ALSEN" },
  "58313": { state: "ND", city: "BALTA" },
  "58316": { state: "ND", city: "BELCOURT" },
  "58317": { state: "ND", city: "BISBEE" },
  "58318": { state: "ND", city: "BOTTINEAU" },
  "58321": { state: "ND", city: "BROCKET" },
  "58323": { state: "ND", city: "CALVIN" },
  "58324": { state: "ND", city: "CANDO" },
  "58325": { state: "ND", city: "CHURCHS FERRY" },
  "58327": { state: "ND", city: "CRARY" },
  "58329": { state: "ND", city: "DUNSEITH" },
  "58330": { state: "ND", city: "EDMORE" },
  "58331": { state: "ND", city: "EGELAND" },
  "58332": { state: "ND", city: "ESMOND" },
  "58335": { state: "ND", city: "FORT TOTTEN" },
  "58338": { state: "ND", city: "HAMPDEN" },
  "58339": { state: "ND", city: "HANSBORO" },
  "58341": { state: "ND", city: "HARVEY" },
  "58343": { state: "ND", city: "KNOX" },
  "58344": { state: "ND", city: "LAKOTA" },
  "58345": { state: "ND", city: "LAWTON" },
  "58346": { state: "ND", city: "LEEDS" },
  "58348": { state: "ND", city: "MADDOCK" },
  "58351": { state: "ND", city: "MINNEWAUKAN" },
  "58352": { state: "ND", city: "MUNICH" },
  "58353": { state: "ND", city: "MYLO" },
  "58355": { state: "ND", city: "NEKOMA" },
  "58356": { state: "ND", city: "NEW ROCKFORD" },
  "58357": { state: "ND", city: "OBERON" },
  "58361": { state: "ND", city: "PEKIN" },
  "58362": { state: "ND", city: "PENN" },
  "58363": { state: "ND", city: "PERTH" },
  "58365": { state: "ND", city: "ROCKLAKE" },
  "58366": { state: "ND", city: "ROLETTE" },
  "58367": { state: "ND", city: "ROLLA" },
  "58368": { state: "ND", city: "RUGBY" },
  "58369": { state: "ND", city: "SAINT JOHN" },
  "58370": { state: "ND", city: "SAINT MICHAEL" },
  "58372": { state: "ND", city: "SARLES" },
  "58374": { state: "ND", city: "SHEYENNE" },
  "58377": { state: "ND", city: "STARKWEATHER" },
  "58379": { state: "ND", city: "TOKIO" },
  "58380": { state: "ND", city: "TOLNA" },
  "58381": { state: "ND", city: "WARWICK" },
  "58382": { state: "ND", city: "WEBSTER" },
  "58384": { state: "ND", city: "WILLOW CITY" },
  "58385": { state: "ND", city: "WOLFORD" },
  "58386": { state: "ND", city: "YORK" },
  "58401": { state: "ND", city: "JAMESTOWN" },
  "58402": { state: "ND", city: "JAMESTOWN" },
  "58405": { state: "ND", city: "JAMESTOWN" },
  "58413": { state: "ND", city: "ASHLEY" },
  "58415": { state: "ND", city: "BERLIN" },
  "58416": { state: "ND", city: "BINFORD" },
  "58418": { state: "ND", city: "BOWDON" },
  "58420": { state: "ND", city: "BUCHANAN" },
  "58421": { state: "ND", city: "CARRINGTON" },
  "58422": { state: "ND", city: "CATHAY" },
  "58423": { state: "ND", city: "CHASELEY" },
  "58424": { state: "ND", city: "CLEVELAND" },
  "58425": { state: "ND", city: "COOPERSTOWN" },
  "58426": { state: "ND", city: "COURTENAY" },
  "58428": { state: "ND", city: "DAWSON" },
  "58429": { state: "ND", city: "DAZEY" },
  "58430": { state: "ND", city: "DENHOFF" },
  "58431": { state: "ND", city: "DICKEY" },
  "58433": { state: "ND", city: "EDGELEY" },
  "58436": { state: "ND", city: "ELLENDALE" },
  "58438": { state: "ND", city: "FESSENDEN" },
  "58439": { state: "ND", city: "FORBES" },
  "58440": { state: "ND", city: "FREDONIA" },
  "58441": { state: "ND", city: "FULLERTON" },
  "58442": { state: "ND", city: "GACKLE" },
  "58443": { state: "ND", city: "GLENFIELD" },
  "58444": { state: "ND", city: "GOODRICH" },
  "58445": { state: "ND", city: "GRACE CITY" },
  "58448": { state: "ND", city: "HANNAFORD" },
  "58451": { state: "ND", city: "HURDSFIELD" },
  "58452": { state: "ND", city: "JESSIE" },
  "58454": { state: "ND", city: "JUD" },
  "58455": { state: "ND", city: "KENSAL" },
  "58456": { state: "ND", city: "KULM" },
  "58458": { state: "ND", city: "LAMOURE" },
  "58460": { state: "ND", city: "LEHR" },
  "58461": { state: "ND", city: "LITCHVILLE" },
  "58463": { state: "ND", city: "MCCLUSKY" },
  "58464": { state: "ND", city: "MCHENRY" },
  "58466": { state: "ND", city: "MARION" },
  "58467": { state: "ND", city: "MEDINA" },
  "58472": { state: "ND", city: "MONTPELIER" },
  "58474": { state: "ND", city: "OAKES" },
  "58475": { state: "ND", city: "PETTIBONE" },
  "58476": { state: "ND", city: "PINGREE" },
  "58477": { state: "ND", city: "REGAN" },
  "58478": { state: "ND", city: "ROBINSON" },
  "58479": { state: "ND", city: "ROGERS" },
  "58480": { state: "ND", city: "SANBORN" },
  "58481": { state: "ND", city: "SPIRITWOOD" },
  "58482": { state: "ND", city: "STEELE" },
  "58483": { state: "ND", city: "STREETER" },
  "58484": { state: "ND", city: "SUTTON" },
  "58486": { state: "ND", city: "SYKESTON" },
  "58487": { state: "ND", city: "TAPPEN" },
  "58488": { state: "ND", city: "TUTTLE" },
  "58490": { state: "ND", city: "VERONA" },
  "58492": { state: "ND", city: "WIMBLEDON" },
  "58494": { state: "ND", city: "WING" },
  "58495": { state: "ND", city: "WISHEK" },
  "58496": { state: "ND", city: "WOODWORTH" },
  "58497": { state: "ND", city: "YPSILANTI" },
  "58501": { state: "ND", city: "BISMARCK" },
  "58502": { state: "ND", city: "BISMARCK" },
  "58503": { state: "ND", city: "BISMARCK" },
  "58504": { state: "ND", city: "BISMARCK" },
  "58505": { state: "ND", city: "BISMARCK" },
  "58506": { state: "ND", city: "BISMARCK" },
  "58507": { state: "ND", city: "BISMARCK" },
  "58520": { state: "ND", city: "ALMONT" },
  "58521": { state: "ND", city: "BALDWIN" },
  "58523": { state: "ND", city: "BEULAH" },
  "58524": { state: "ND", city: "BRADDOCK" },
  "58528": { state: "ND", city: "CANNON BALL" },
  "58529": { state: "ND", city: "CARSON" },
  "58530": { state: "ND", city: "CENTER" },
  "58531": { state: "ND", city: "COLEHARBOR" },
  "58532": { state: "ND", city: "DRISCOLL" },
  "58533": { state: "ND", city: "ELGIN" },
  "58535": { state: "ND", city: "FLASHER" },
  "58538": { state: "ND", city: "FORT YATES" },
  "58540": { state: "ND", city: "GARRISON" },
  "58541": { state: "ND", city: "GOLDEN VALLEY" },
  "58542": { state: "ND", city: "HAGUE" },
  "58544": { state: "ND", city: "HAZELTON" },
  "58545": { state: "ND", city: "HAZEN" },
  "58549": { state: "ND", city: "KINTYRE" },
  "58552": { state: "ND", city: "LINTON" },
  "58554": { state: "ND", city: "MANDAN" },
  "58558": { state: "ND", city: "MENOKEN" },
  "58559": { state: "ND", city: "MERCER" },
  "58560": { state: "ND", city: "MOFFIT" },
  "58561": { state: "ND", city: "NAPOLEON" },
  "58562": { state: "ND", city: "NEW LEIPZIG" },
  "58563": { state: "ND", city: "NEW SALEM" },
  "58564": { state: "ND", city: "RALEIGH" },
  "58565": { state: "ND", city: "RIVERDALE" },
  "58566": { state: "ND", city: "SAINT ANTHONY" },
  "58568": { state: "ND", city: "SELFRIDGE" },
  "58569": { state: "ND", city: "SHIELDS" },
  "58570": { state: "ND", city: "SOLEN" },
  "58571": { state: "ND", city: "STANTON" },
  "58572": { state: "ND", city: "STERLING" },
  "58573": { state: "ND", city: "STRASBURG" },
  "58575": { state: "ND", city: "TURTLE LAKE" },
  "58576": { state: "ND", city: "UNDERWOOD" },
  "58577": { state: "ND", city: "WASHBURN" },
  "58579": { state: "ND", city: "WILTON" },
  "58580": { state: "ND", city: "ZAP" },
  "58581": { state: "ND", city: "ZEELAND" },
  "58601": { state: "ND", city: "DICKINSON" },
  "58602": { state: "ND", city: "DICKINSON" },
  "58620": { state: "ND", city: "AMIDON" },
  "58621": { state: "ND", city: "BEACH" },
  "58622": { state: "ND", city: "BELFIELD" },
  "58623": { state: "ND", city: "BOWMAN" },
  "58625": { state: "ND", city: "DODGE" },
  "58626": { state: "ND", city: "DUNN CENTER" },
  "58627": { state: "ND", city: "FAIRFIELD" },
  "58630": { state: "ND", city: "GLADSTONE" },
  "58631": { state: "ND", city: "GLEN ULLIN" },
  "58632": { state: "ND", city: "GOLVA" },
  "58634": { state: "ND", city: "GRASSY BUTTE" },
  "58636": { state: "ND", city: "HALLIDAY" },
  "58638": { state: "ND", city: "HEBRON" },
  "58639": { state: "ND", city: "HETTINGER" },
  "58640": { state: "ND", city: "KILLDEER" },
  "58641": { state: "ND", city: "LEFOR" },
  "58642": { state: "ND", city: "MANNING" },
  "58643": { state: "ND", city: "MARMARTH" },
  "58644": { state: "ND", city: "MARSHALL" },
  "58645": { state: "ND", city: "MEDORA" },
  "58646": { state: "ND", city: "MOTT" },
  "58647": { state: "ND", city: "NEW ENGLAND" },
  "58649": { state: "ND", city: "REEDER" },
  "58650": { state: "ND", city: "REGENT" },
  "58651": { state: "ND", city: "RHAME" },
  "58652": { state: "ND", city: "RICHARDTON" },
  "58653": { state: "ND", city: "SCRANTON" },
  "58654": { state: "ND", city: "SENTINEL BUTTE" },
  "58655": { state: "ND", city: "SOUTH HEART" },
  "58656": { state: "ND", city: "TAYLOR" },
  "58701": { state: "ND", city: "MINOT" },
  "58702": { state: "ND", city: "MINOT" },
  "58703": { state: "ND", city: "MINOT" },
  "58704": { state: "ND", city: "MINOT AFB" },
  "58705": { state: "ND", city: "MINOT AFB" },
  "58707": { state: "ND", city: "MINOT" },
  "58710": { state: "ND", city: "ANAMOOSE" },
  "58711": { state: "ND", city: "ANTLER" },
  "58712": { state: "ND", city: "BALFOUR" },
  "58713": { state: "ND", city: "BANTRY" },
  "58716": { state: "ND", city: "BENEDICT" },
  "58718": { state: "ND", city: "BERTHOLD" },
  "58721": { state: "ND", city: "BOWBELLS" },
  "58722": { state: "ND", city: "BURLINGTON" },
  "58723": { state: "ND", city: "BUTTE" },
  "58725": { state: "ND", city: "CARPIO" },
  "58727": { state: "ND", city: "COLUMBUS" },
  "58730": { state: "ND", city: "CROSBY" },
  "58731": { state: "ND", city: "DEERING" },
  "58733": { state: "ND", city: "DES LACS" },
  "58734": { state: "ND", city: "DONNYBROOK" },
  "58735": { state: "ND", city: "DOUGLAS" },
  "58736": { state: "ND", city: "DRAKE" },
  "58737": { state: "ND", city: "FLAXTON" },
  "58740": { state: "ND", city: "GLENBURN" },
  "58741": { state: "ND", city: "GRANVILLE" },
  "58744": { state: "ND", city: "KARLSRUHE" },
  "58746": { state: "ND", city: "KENMARE" },
  "58748": { state: "ND", city: "KRAMER" },
  "58750": { state: "ND", city: "LANSFORD" },
  "58752": { state: "ND", city: "LIGNITE" },
  "58755": { state: "ND", city: "MCGREGOR" },
  "58756": { state: "ND", city: "MAKOTI" },
  "58757": { state: "ND", city: "MANDAREE" },
  "58758": { state: "ND", city: "MARTIN" },
  "58759": { state: "ND", city: "MAX" },
  "58760": { state: "ND", city: "MAXBASS" },
  "58761": { state: "ND", city: "MOHALL" },
  "58762": { state: "ND", city: "NEWBURG" },
  "58763": { state: "ND", city: "NEW TOWN" },
  "58765": { state: "ND", city: "NOONAN" },
  "58768": { state: "ND", city: "NORWICH" },
  "58769": { state: "ND", city: "PALERMO" },
  "58770": { state: "ND", city: "PARSHALL" },
  "58771": { state: "ND", city: "PLAZA" },
  "58772": { state: "ND", city: "PORTAL" },
  "58773": { state: "ND", city: "POWERS LAKE" },
  "58775": { state: "ND", city: "ROSEGLEN" },
  "58776": { state: "ND", city: "ROSS" },
  "58778": { state: "ND", city: "RUSO" },
  "58779": { state: "ND", city: "RYDER" },
  "58781": { state: "ND", city: "SAWYER" },
  "58782": { state: "ND", city: "SHERWOOD" },
  "58783": { state: "ND", city: "SOURIS" },
  "58784": { state: "ND", city: "STANLEY" },
  "58785": { state: "ND", city: "SURREY" },
  "58787": { state: "ND", city: "TOLLEY" },
  "58788": { state: "ND", city: "TOWNER" },
  "58789": { state: "ND", city: "UPHAM" },
  "58790": { state: "ND", city: "VELVA" },
  "58792": { state: "ND", city: "VOLTAIRE" },
  "58793": { state: "ND", city: "WESTHOPE" },
  "58794": { state: "ND", city: "WHITE EARTH" },
  "58795": { state: "ND", city: "WILDROSE" },
  "58801": { state: "ND", city: "WILLISTON" },
  "58802": { state: "ND", city: "WILLISTON" },
  "58803": { state: "ND", city: "WILLISTON" },
  "58830": { state: "ND", city: "ALAMO" },
  "58831": { state: "ND", city: "ALEXANDER" },
  "58833": { state: "ND", city: "AMBROSE" },
  "58835": { state: "ND", city: "ARNEGARD" },
  "58838": { state: "ND", city: "CARTWRIGHT" },
  "58843": { state: "ND", city: "EPPING" },
  "58844": { state: "ND", city: "FORTUNA" },
  "58845": { state: "ND", city: "GRENORA" },
  "58847": { state: "ND", city: "KEENE" },
  "58849": { state: "ND", city: "RAY" },
  "58852": { state: "ND", city: "TIOGA" },
  "58853": { state: "ND", city: "TRENTON" },
  "58854": { state: "ND", city: "WATFORD CITY" },
  "58856": { state: "ND", city: "ZAHL" },
  "59001": { state: "MT", city: "ABSAROKEE" },
  "59002": { state: "MT", city: "ACTON" },
  "59003": { state: "MT", city: "ASHLAND" },
  "59004": { state: "MT", city: "ASHLAND" },
  "59006": { state: "MT", city: "BALLANTINE" },
  "59007": { state: "MT", city: "BEARCREEK" },
  "59008": { state: "MT", city: "BELFRY" },
  "59010": { state: "MT", city: "BIGHORN" },
  "59011": { state: "MT", city: "BIG TIMBER" },
  "59012": { state: "MT", city: "BIRNEY" },
  "59013": { state: "MT", city: "BOYD" },
  "59014": { state: "MT", city: "BRIDGER" },
  "59015": { state: "MT", city: "BROADVIEW" },
  "59016": { state: "MT", city: "BUSBY" },
  "59018": { state: "MT", city: "CLYDE PARK" },
  "59019": { state: "MT", city: "COLUMBUS" },
  "59020": { state: "MT", city: "COOKE CITY" },
  "59022": { state: "MT", city: "CROW AGENCY" },
  "59024": { state: "MT", city: "CUSTER" },
  "59025": { state: "MT", city: "DECKER" },
  "59026": { state: "MT", city: "EDGAR" },
  "59027": { state: "MT", city: "EMIGRANT" },
  "59028": { state: "MT", city: "FISHTAIL" },
  "59029": { state: "MT", city: "FROMBERG" },
  "59030": { state: "MT", city: "GARDINER" },
  "59031": { state: "MT", city: "GARRYOWEN" },
  "59032": { state: "MT", city: "GRASS RANGE" },
  "59033": { state: "MT", city: "GREYCLIFF" },
  "59034": { state: "MT", city: "HARDIN" },
  "59035": { state: "MT", city: "FORT SMITH" },
  "59036": { state: "MT", city: "HARLOWTON" },
  "59037": { state: "MT", city: "HUNTLEY" },
  "59038": { state: "MT", city: "HYSHAM" },
  "59039": { state: "MT", city: "INGOMAR" },
  "59041": { state: "MT", city: "JOLIET" },
  "59043": { state: "MT", city: "LAME DEER" },
  "59044": { state: "MT", city: "LAUREL" },
  "59046": { state: "MT", city: "LAVINA" },
  "59047": { state: "MT", city: "LIVINGSTON" },
  "59050": { state: "MT", city: "LODGE GRASS" },
  "59052": { state: "MT", city: "MC LEOD" },
  "59053": { state: "MT", city: "MARTINSDALE" },
  "59054": { state: "MT", city: "MELSTONE" },
  "59055": { state: "MT", city: "MELVILLE" },
  "59057": { state: "MT", city: "MOLT" },
  "59058": { state: "MT", city: "MOSBY" },
  "59059": { state: "MT", city: "MUSSELSHELL" },
  "59061": { state: "MT", city: "NYE" },
  "59062": { state: "MT", city: "OTTER" },
  "59063": { state: "MT", city: "PARK CITY" },
  "59064": { state: "MT", city: "POMPEYS PILLAR" },
  "59065": { state: "MT", city: "PRAY" },
  "59066": { state: "MT", city: "PRYOR" },
  "59067": { state: "MT", city: "RAPELJE" },
  "59068": { state: "MT", city: "RED LODGE" },
  "59069": { state: "MT", city: "REED POINT" },
  "59070": { state: "MT", city: "ROBERTS" },
  "59071": { state: "MT", city: "ROSCOE" },
  "59072": { state: "MT", city: "ROUNDUP" },
  "59073": { state: "MT", city: "ROUNDUP" },
  "59074": { state: "MT", city: "RYEGATE" },
  "59075": { state: "MT", city: "SAINT XAVIER" },
  "59076": { state: "MT", city: "SANDERS" },
  "59077": { state: "MT", city: "SAND SPRINGS" },
  "59078": { state: "MT", city: "SHAWMUT" },
  "59079": { state: "MT", city: "SHEPHERD" },
  "59081": { state: "MT", city: "SILVER GATE" },
  "59082": { state: "MT", city: "SPRINGDALE" },
  "59083": { state: "MT", city: "SUMATRA" },
  "59084": { state: "MT", city: "TEIGEN" },
  "59085": { state: "MT", city: "TWO DOT" },
  "59086": { state: "MT", city: "WILSALL" },
  "59087": { state: "MT", city: "WINNETT" },
  "59088": { state: "MT", city: "WORDEN" },
  "59089": { state: "MT", city: "WYOLA" },
  "59101": { state: "MT", city: "BILLINGS" },
  "59102": { state: "MT", city: "BILLINGS" },
  "59103": { state: "MT", city: "BILLINGS" },
  "59104": { state: "MT", city: "BILLINGS" },
  "59105": { state: "MT", city: "BILLINGS" },
  "59106": { state: "MT", city: "BILLINGS" },
  "59107": { state: "MT", city: "BILLINGS" },
  "59108": { state: "MT", city: "BILLINGS" },
  "59111": { state: "MT", city: "BILLINGS" },
  "59112": { state: "MT", city: "BILLINGS" },
  "59114": { state: "MT", city: "BILLINGS" },
  "59115": { state: "MT", city: "BILLINGS" },
  "59116": { state: "MT", city: "BILLINGS" },
  "59117": { state: "MT", city: "BILLINGS" },
  "59201": { state: "MT", city: "WOLF POINT" },
  "59211": { state: "MT", city: "ANTELOPE" },
  "59212": { state: "MT", city: "BAINVILLE" },
  "59213": { state: "MT", city: "BROCKTON" },
  "59214": { state: "MT", city: "BROCKWAY" },
  "59215": { state: "MT", city: "CIRCLE" },
  "59217": { state: "MT", city: "CRANE" },
  "59218": { state: "MT", city: "CULBERTSON" },
  "59219": { state: "MT", city: "DAGMAR" },
  "59221": { state: "MT", city: "FAIRVIEW" },
  "59222": { state: "MT", city: "FLAXVILLE" },
  "59223": { state: "MT", city: "FORT PECK" },
  "59225": { state: "MT", city: "FRAZER" },
  "59226": { state: "MT", city: "FROID" },
  "59230": { state: "MT", city: "GLASGOW" },
  "59231": { state: "MT", city: "SAINT MARIE" },
  "59240": { state: "MT", city: "GLENTANA" },
  "59241": { state: "MT", city: "HINSDALE" },
  "59242": { state: "MT", city: "HOMESTEAD" },
  "59243": { state: "MT", city: "LAMBERT" },
  "59244": { state: "MT", city: "LARSLAN" },
  "59247": { state: "MT", city: "MEDICINE LAKE" },
  "59248": { state: "MT", city: "NASHUA" },
  "59250": { state: "MT", city: "OPHEIM" },
  "59252": { state: "MT", city: "OUTLOOK" },
  "59253": { state: "MT", city: "PEERLESS" },
  "59254": { state: "MT", city: "PLENTYWOOD" },
  "59255": { state: "MT", city: "POPLAR" },
  "59256": { state: "MT", city: "RAYMOND" },
  "59257": { state: "MT", city: "REDSTONE" },
  "59258": { state: "MT", city: "RESERVE" },
  "59259": { state: "MT", city: "RICHEY" },
  "59260": { state: "MT", city: "RICHLAND" },
  "59261": { state: "MT", city: "SACO" },
  "59262": { state: "MT", city: "SAVAGE" },
  "59263": { state: "MT", city: "SCOBEY" },
  "59270": { state: "MT", city: "SIDNEY" },
  "59273": { state: "MT", city: "VANDALIA" },
  "59274": { state: "MT", city: "VIDA" },
  "59275": { state: "MT", city: "WESTBY" },
  "59276": { state: "MT", city: "WHITETAIL" },
  "59301": { state: "MT", city: "MILES CITY" },
  "59311": { state: "MT", city: "ALZADA" },
  "59312": { state: "MT", city: "ANGELA" },
  "59313": { state: "MT", city: "BAKER" },
  "59314": { state: "MT", city: "BIDDLE" },
  "59315": { state: "MT", city: "BLOOMFIELD" },
  "59316": { state: "MT", city: "BOYES" },
  "59317": { state: "MT", city: "BROADUS" },
  "59318": { state: "MT", city: "BRUSETT" },
  "59319": { state: "MT", city: "CAPITOL" },
  "59322": { state: "MT", city: "COHAGEN" },
  "59323": { state: "MT", city: "COLSTRIP" },
  "59324": { state: "MT", city: "EKALAKA" },
  "59326": { state: "MT", city: "FALLON" },
  "59327": { state: "MT", city: "FORSYTH" },
  "59330": { state: "MT", city: "GLENDIVE" },
  "59332": { state: "MT", city: "HAMMOND" },
  "59333": { state: "MT", city: "HATHAWAY" },
  "59336": { state: "MT", city: "ISMAY" },
  "59337": { state: "MT", city: "JORDAN" },
  "59338": { state: "MT", city: "KINSEY" },
  "59339": { state: "MT", city: "LINDSAY" },
  "59341": { state: "MT", city: "MILDRED" },
  "59343": { state: "MT", city: "OLIVE" },
  "59344": { state: "MT", city: "PLEVNA" },
  "59345": { state: "MT", city: "POWDERVILLE" },
  "59347": { state: "MT", city: "ROSEBUD" },
  "59349": { state: "MT", city: "TERRY" },
  "59351": { state: "MT", city: "VOLBORG" },
  "59353": { state: "MT", city: "WIBAUX" },
  "59354": { state: "MT", city: "WILLARD" },
  "59401": { state: "MT", city: "GREAT FALLS" },
  "59402": { state: "MT", city: "MALMSTROM AFB" },
  "59403": { state: "MT", city: "GREAT FALLS" },
  "59404": { state: "MT", city: "GREAT FALLS" },
  "59405": { state: "MT", city: "GREAT FALLS" },
  "59406": { state: "MT", city: "GREAT FALLS" },
  "59410": { state: "MT", city: "AUGUSTA" },
  "59411": { state: "MT", city: "BABB" },
  "59412": { state: "MT", city: "BELT" },
  "59414": { state: "MT", city: "BLACK EAGLE" },
  "59416": { state: "MT", city: "BRADY" },
  "59417": { state: "MT", city: "BROWNING" },
  "59418": { state: "MT", city: "BUFFALO" },
  "59419": { state: "MT", city: "BYNUM" },
  "59420": { state: "MT", city: "CARTER" },
  "59421": { state: "MT", city: "CASCADE" },
  "59422": { state: "MT", city: "CHOTEAU" },
  "59424": { state: "MT", city: "COFFEE CREEK" },
  "59425": { state: "MT", city: "CONRAD" },
  "59427": { state: "MT", city: "CUT BANK" },
  "59430": { state: "MT", city: "DENTON" },
  "59432": { state: "MT", city: "DUPUYER" },
  "59433": { state: "MT", city: "DUTTON" },
  "59434": { state: "MT", city: "EAST GLACIER PARK" },
  "59435": { state: "MT", city: "ETHRIDGE" },
  "59436": { state: "MT", city: "FAIRFIELD" },
  "59440": { state: "MT", city: "FLOWEREE" },
  "59441": { state: "MT", city: "FOREST GROVE" },
  "59442": { state: "MT", city: "FORT BENTON" },
  "59443": { state: "MT", city: "FORT SHAW" },
  "59444": { state: "MT", city: "GALATA" },
  "59446": { state: "MT", city: "GERALDINE" },
  "59447": { state: "MT", city: "GEYSER" },
  "59448": { state: "MT", city: "HEART BUTTE" },
  "59450": { state: "MT", city: "HIGHWOOD" },
  "59451": { state: "MT", city: "HILGER" },
  "59452": { state: "MT", city: "HOBSON" },
  "59453": { state: "MT", city: "JUDITH GAP" },
  "59454": { state: "MT", city: "KEVIN" },
  "59456": { state: "MT", city: "LEDGER" },
  "59457": { state: "MT", city: "LEWISTOWN" },
  "59460": { state: "MT", city: "LOMA" },
  "59461": { state: "MT", city: "LOTHAIR" },
  "59462": { state: "MT", city: "MOCCASIN" },
  "59463": { state: "MT", city: "MONARCH" },
  "59464": { state: "MT", city: "MOORE" },
  "59465": { state: "MT", city: "NEIHART" },
  "59466": { state: "MT", city: "OILMONT" },
  "59467": { state: "MT", city: "PENDROY" },
  "59468": { state: "MT", city: "POWER" },
  "59469": { state: "MT", city: "RAYNESFORD" },
  "59471": { state: "MT", city: "ROY" },
  "59472": { state: "MT", city: "SAND COULEE" },
  "59474": { state: "MT", city: "SHELBY" },
  "59477": { state: "MT", city: "SIMMS" },
  "59479": { state: "MT", city: "STANFORD" },
  "59480": { state: "MT", city: "STOCKETT" },
  "59482": { state: "MT", city: "SUNBURST" },
  "59483": { state: "MT", city: "SUN RIVER" },
  "59484": { state: "MT", city: "SWEET GRASS" },
  "59485": { state: "MT", city: "ULM" },
  "59486": { state: "MT", city: "VALIER" },
  "59487": { state: "MT", city: "VAUGHN" },
  "59489": { state: "MT", city: "WINIFRED" },
  "59501": { state: "MT", city: "HAVRE" },
  "59520": { state: "MT", city: "BIG SANDY" },
  "59521": { state: "MT", city: "BOX ELDER" },
  "59522": { state: "MT", city: "CHESTER" },
  "59523": { state: "MT", city: "CHINOOK" },
  "59524": { state: "MT", city: "DODSON" },
  "59525": { state: "MT", city: "GILDFORD" },
  "59526": { state: "MT", city: "HARLEM" },
  "59527": { state: "MT", city: "HAYS" },
  "59528": { state: "MT", city: "HINGHAM" },
  "59529": { state: "MT", city: "HOGELAND" },
  "59530": { state: "MT", city: "INVERNESS" },
  "59531": { state: "MT", city: "JOPLIN" },
  "59532": { state: "MT", city: "KREMLIN" },
  "59535": { state: "MT", city: "LLOYD" },
  "59537": { state: "MT", city: "LORING" },
  "59538": { state: "MT", city: "MALTA" },
  "59540": { state: "MT", city: "RUDYARD" },
  "59542": { state: "MT", city: "TURNER" },
  "59544": { state: "MT", city: "WHITEWATER" },
  "59545": { state: "MT", city: "WHITLASH" },
  "59546": { state: "MT", city: "ZORTMAN" },
  "59547": { state: "MT", city: "ZURICH" },
  "59601": { state: "MT", city: "HELENA" },
  "59602": { state: "MT", city: "HELENA" },
  "59604": { state: "MT", city: "HELENA" },
  "59620": { state: "MT", city: "HELENA" },
  "59623": { state: "MT", city: "HELENA" },
  "59624": { state: "MT", city: "HELENA" },
  "59625": { state: "MT", city: "HELENA" },
  "59626": { state: "MT", city: "HELENA" },
  "59631": { state: "MT", city: "BASIN" },
  "59632": { state: "MT", city: "BOULDER" },
  "59633": { state: "MT", city: "CANYON CREEK" },
  "59634": { state: "MT", city: "CLANCY" },
  "59635": { state: "MT", city: "EAST HELENA" },
  "59636": { state: "MT", city: "FORT HARRISON" },
  "59638": { state: "MT", city: "JEFFERSON CITY" },
  "59639": { state: "MT", city: "LINCOLN" },
  "59640": { state: "MT", city: "MARYSVILLE" },
  "59641": { state: "MT", city: "RADERSBURG" },
  "59642": { state: "MT", city: "RINGLING" },
  "59643": { state: "MT", city: "TOSTON" },
  "59644": { state: "MT", city: "TOWNSEND" },
  "59645": { state: "MT", city: "WHITE SULPHUR SPRINGS" },
  "59647": { state: "MT", city: "WINSTON" },
  "59648": { state: "MT", city: "WOLF CREEK" },
  "59701": { state: "MT", city: "BUTTE" },
  "59702": { state: "MT", city: "BUTTE" },
  "59703": { state: "MT", city: "BUTTE" },
  "59707": { state: "MT", city: "BUTTE" },
  "59710": { state: "MT", city: "ALDER" },
  "59711": { state: "MT", city: "ANACONDA" },
  "59713": { state: "MT", city: "AVON" },
  "59714": { state: "MT", city: "BELGRADE" },
  "59715": { state: "MT", city: "BOZEMAN" },
  "59716": { state: "MT", city: "BIG SKY" },
  "59717": { state: "MT", city: "BOZEMAN" },
  "59718": { state: "MT", city: "BOZEMAN" },
  "59719": { state: "MT", city: "BOZEMAN" },
  "59720": { state: "MT", city: "CAMERON" },
  "59721": { state: "MT", city: "CARDWELL" },
  "59722": { state: "MT", city: "DEER LODGE" },
  "59724": { state: "MT", city: "DELL" },
  "59725": { state: "MT", city: "DILLON" },
  "59727": { state: "MT", city: "DIVIDE" },
  "59728": { state: "MT", city: "ELLISTON" },
  "59729": { state: "MT", city: "ENNIS" },
  "59730": { state: "MT", city: "GALLATIN GATEWAY" },
  "59731": { state: "MT", city: "GARRISON" },
  "59732": { state: "MT", city: "GLEN" },
  "59733": { state: "MT", city: "GOLD CREEK" },
  "59735": { state: "MT", city: "HARRISON" },
  "59736": { state: "MT", city: "JACKSON" },
  "59739": { state: "MT", city: "LIMA" },
  "59740": { state: "MT", city: "MC ALLISTER" },
  "59741": { state: "MT", city: "MANHATTAN" },
  "59743": { state: "MT", city: "MELROSE" },
  "59745": { state: "MT", city: "NORRIS" },
  "59746": { state: "MT", city: "POLARIS" },
  "59747": { state: "MT", city: "PONY" },
  "59748": { state: "MT", city: "RAMSAY" },
  "59749": { state: "MT", city: "SHERIDAN" },
  "59750": { state: "MT", city: "BUTTE" },
  "59751": { state: "MT", city: "SILVER STAR" },
  "59752": { state: "MT", city: "THREE FORKS" },
  "59754": { state: "MT", city: "TWIN BRIDGES" },
  "59755": { state: "MT", city: "VIRGINIA CITY" },
  "59756": { state: "MT", city: "WARM SPRINGS" },
  "59758": { state: "MT", city: "WEST YELLOWSTONE" },
  "59759": { state: "MT", city: "WHITEHALL" },
  "59760": { state: "MT", city: "WILLOW CREEK" },
  "59761": { state: "MT", city: "WISDOM" },
  "59762": { state: "MT", city: "WISE RIVER" },
  "59771": { state: "MT", city: "BOZEMAN" },
  "59772": { state: "MT", city: "BOZEMAN" },
  "59801": { state: "MT", city: "MISSOULA" },
  "59802": { state: "MT", city: "MISSOULA" },
  "59803": { state: "MT", city: "MISSOULA" },
  "59804": { state: "MT", city: "MISSOULA" },
  "59806": { state: "MT", city: "MISSOULA" },
  "59807": { state: "MT", city: "MISSOULA" },
  "59808": { state: "MT", city: "MISSOULA" },
  "59812": { state: "MT", city: "MISSOULA" },
  "59820": { state: "MT", city: "ALBERTON" },
  "59821": { state: "MT", city: "ARLEE" },
  "59823": { state: "MT", city: "BONNER" },
  "59824": { state: "MT", city: "CHARLO" },
  "59825": { state: "MT", city: "CLINTON" },
  "59826": { state: "MT", city: "CONDON" },
  "59827": { state: "MT", city: "CONNER" },
  "59828": { state: "MT", city: "CORVALLIS" },
  "59829": { state: "MT", city: "DARBY" },
  "59830": { state: "MT", city: "DE BORGIA" },
  "59831": { state: "MT", city: "DIXON" },
  "59832": { state: "MT", city: "DRUMMOND" },
  "59833": { state: "MT", city: "FLORENCE" },
  "59834": { state: "MT", city: "FRENCHTOWN" },
  "59835": { state: "MT", city: "GRANTSDALE" },
  "59837": { state: "MT", city: "HALL" },
  "59840": { state: "MT", city: "HAMILTON" },
  "59841": { state: "MT", city: "PINESDALE" },
  "59842": { state: "MT", city: "HAUGAN" },
  "59843": { state: "MT", city: "HELMVILLE" },
  "59844": { state: "MT", city: "HERON" },
  "59845": { state: "MT", city: "HOT SPRINGS" },
  "59846": { state: "MT", city: "HUSON" },
  "59847": { state: "MT", city: "LOLO" },
  "59848": { state: "MT", city: "LONEPINE" },
  "59851": { state: "MT", city: "MILLTOWN" },
  "59853": { state: "MT", city: "NOXON" },
  "59854": { state: "MT", city: "OVANDO" },
  "59855": { state: "MT", city: "PABLO" },
  "59856": { state: "MT", city: "PARADISE" },
  "59858": { state: "MT", city: "PHILIPSBURG" },
  "59859": { state: "MT", city: "PLAINS" },
  "59860": { state: "MT", city: "POLSON" },
  "59863": { state: "MT", city: "RAVALLI" },
  "59864": { state: "MT", city: "RONAN" },
  "59865": { state: "MT", city: "SAINT IGNATIUS" },
  "59866": { state: "MT", city: "SAINT REGIS" },
  "59867": { state: "MT", city: "SALTESE" },
  "59868": { state: "MT", city: "SEELEY LAKE" },
  "59870": { state: "MT", city: "STEVENSVILLE" },
  "59871": { state: "MT", city: "SULA" },
  "59872": { state: "MT", city: "SUPERIOR" },
  "59873": { state: "MT", city: "THOMPSON FALLS" },
  "59874": { state: "MT", city: "TROUT CREEK" },
  "59875": { state: "MT", city: "VICTOR" },
  "59901": { state: "MT", city: "KALISPELL" },
  "59903": { state: "MT", city: "KALISPELL" },
  "59904": { state: "MT", city: "KALISPELL" },
  "59910": { state: "MT", city: "BIG ARM" },
  "59911": { state: "MT", city: "BIGFORK" },
  "59912": { state: "MT", city: "COLUMBIA FALLS" },
  "59913": { state: "MT", city: "CORAM" },
  "59914": { state: "MT", city: "DAYTON" },
  "59915": { state: "MT", city: "ELMO" },
  "59916": { state: "MT", city: "ESSEX" },
  "59917": { state: "MT", city: "EUREKA" },
  "59918": { state: "MT", city: "FORTINE" },
  "59919": { state: "MT", city: "HUNGRY HORSE" },
  "59920": { state: "MT", city: "KILA" },
  "59921": { state: "MT", city: "LAKE MC DONALD" },
  "59922": { state: "MT", city: "LAKESIDE" },
  "59923": { state: "MT", city: "LIBBY" },
  "59925": { state: "MT", city: "MARION" },
  "59926": { state: "MT", city: "MARTIN CITY" },
  "59927": { state: "MT", city: "OLNEY" },
  "59928": { state: "MT", city: "POLEBRIDGE" },
  "59929": { state: "MT", city: "PROCTOR" },
  "59930": { state: "MT", city: "REXFORD" },
  "59931": { state: "MT", city: "ROLLINS" },
  "59932": { state: "MT", city: "SOMERS" },
  "59933": { state: "MT", city: "STRYKER" },
  "59934": { state: "MT", city: "TREGO" },
  "59935": { state: "MT", city: "TROY" },
  "59936": { state: "MT", city: "WEST GLACIER" },
  "59937": { state: "MT", city: "WHITEFISH" },
  "60002": { state: "IL", city: "ANTIOCH" },
  "60004": { state: "IL", city: "ARLINGTON HEIGHTS" },
  "60005": { state: "IL", city: "ARLINGTON HEIGHTS" },
  "60006": { state: "IL", city: "ARLINGTON HEIGHTS" },
  "60007": { state: "IL", city: "ELK GROVE VILLAGE" },
  "60008": { state: "IL", city: "ROLLING MEADOWS" },
  "60009": { state: "IL", city: "ELK GROVE VILLAGE" },
  "60010": { state: "IL", city: "BARRINGTON" },
  "60011": { state: "IL", city: "BARRINGTON" },
  "60012": { state: "IL", city: "CRYSTAL LAKE" },
  "60013": { state: "IL", city: "CARY" },
  "60014": { state: "IL", city: "CRYSTAL LAKE" },
  "60015": { state: "IL", city: "DEERFIELD" },
  "60016": { state: "IL", city: "DES PLAINES" },
  "60017": { state: "IL", city: "DES PLAINES" },
  "60018": { state: "IL", city: "DES PLAINES" },
  "60019": { state: "IL", city: "DES PLAINES" },
  "60020": { state: "IL", city: "FOX LAKE" },
  "60021": { state: "IL", city: "FOX RIVER GROVE" },
  "60022": { state: "IL", city: "GLENCOE" },
  "60025": { state: "IL", city: "GLENVIEW" },
  "60026": { state: "IL", city: "GLENVIEW" },
  "60029": { state: "IL", city: "GOLF" },
  "60030": { state: "IL", city: "GRAYSLAKE" },
  "60031": { state: "IL", city: "GURNEE" },
  "60033": { state: "IL", city: "HARVARD" },
  "60034": { state: "IL", city: "HEBRON" },
  "60035": { state: "IL", city: "HIGHLAND PARK" },
  "60037": { state: "IL", city: "FORT SHERIDAN" },
  "60038": { state: "IL", city: "PALATINE" },
  "60039": { state: "IL", city: "CRYSTAL LAKE" },
  "60040": { state: "IL", city: "HIGHWOOD" },
  "60041": { state: "IL", city: "INGLESIDE" },
  "60042": { state: "IL", city: "ISLAND LAKE" },
  "60043": { state: "IL", city: "KENILWORTH" },
  "60044": { state: "IL", city: "LAKE BLUFF" },
  "60045": { state: "IL", city: "LAKE FOREST" },
  "60046": { state: "IL", city: "LAKE VILLA" },
  "60047": { state: "IL", city: "LAKE ZURICH" },
  "60048": { state: "IL", city: "LIBERTYVILLE" },
  "60050": { state: "IL", city: "MCHENRY" },
  "60051": { state: "IL", city: "MCHENRY" },
  "60053": { state: "IL", city: "MORTON GROVE" },
  "60055": { state: "IL", city: "PALATINE" },
  "60056": { state: "IL", city: "MOUNT PROSPECT" },
  "60060": { state: "IL", city: "MUNDELEIN" },
  "60061": { state: "IL", city: "VERNON HILLS" },
  "60062": { state: "IL", city: "NORTHBROOK" },
  "60064": { state: "IL", city: "NORTH CHICAGO" },
  "60065": { state: "IL", city: "NORTHBROOK" },
  "60067": { state: "IL", city: "PALATINE" },
  "60068": { state: "IL", city: "PARK RIDGE" },
  "60069": { state: "IL", city: "LINCOLNSHIRE" },
  "60070": { state: "IL", city: "PROSPECT HEIGHTS" },
  "60071": { state: "IL", city: "RICHMOND" },
  "60072": { state: "IL", city: "RINGWOOD" },
  "60073": { state: "IL", city: "ROUND LAKE" },
  "60074": { state: "IL", city: "PALATINE" },
  "60075": { state: "IL", city: "RUSSELL" },
  "60076": { state: "IL", city: "SKOKIE" },
  "60077": { state: "IL", city: "SKOKIE" },
  "60078": { state: "IL", city: "PALATINE" },
  "60079": { state: "IL", city: "WAUKEGAN" },
  "60081": { state: "IL", city: "SPRING GROVE" },
  "60082": { state: "IL", city: "TECHNY" },
  "60083": { state: "IL", city: "WADSWORTH" },
  "60084": { state: "IL", city: "WAUCONDA" },
  "60085": { state: "IL", city: "WAUKEGAN" },
  "60086": { state: "IL", city: "NORTH CHICAGO" },
  "60087": { state: "IL", city: "WAUKEGAN" },
  "60088": { state: "IL", city: "GREAT LAKES" },
  "60089": { state: "IL", city: "BUFFALO GROVE" },
  "60090": { state: "IL", city: "WHEELING" },
  "60091": { state: "IL", city: "WILMETTE" },
  "60093": { state: "IL", city: "WINNETKA" },
  "60094": { state: "IL", city: "PALATINE" },
  "60095": { state: "IL", city: "PALATINE" },
  "60096": { state: "IL", city: "WINTHROP HARBOR" },
  "60097": { state: "IL", city: "WONDER LAKE" },
  "60098": { state: "IL", city: "WOODSTOCK" },
  "60099": { state: "IL", city: "ZION" },
  "60101": { state: "IL", city: "ADDISON" },
  "60102": { state: "IL", city: "ALGONQUIN" },
  "60103": { state: "IL", city: "BARTLETT" },
  "60104": { state: "IL", city: "BELLWOOD" },
  "60105": { state: "IL", city: "BENSENVILLE" },
  "60106": { state: "IL", city: "BENSENVILLE" },
  "60107": { state: "IL", city: "STREAMWOOD" },
  "60108": { state: "IL", city: "BLOOMINGDALE" },
  "60109": { state: "IL", city: "BURLINGTON" },
  "60110": { state: "IL", city: "CARPENTERSVILLE" },
  "60111": { state: "IL", city: "CLARE" },
  "60112": { state: "IL", city: "CORTLAND" },
  "60113": { state: "IL", city: "CRESTON" },
  "60115": { state: "IL", city: "DEKALB" },
  "60116": { state: "IL", city: "CAROL STREAM" },
  "60117": { state: "IL", city: "BLOOMINGDALE" },
  "60118": { state: "IL", city: "DUNDEE" },
  "60119": { state: "IL", city: "ELBURN" },
  "60120": { state: "IL", city: "ELGIN" },
  "60121": { state: "IL", city: "ELGIN" },
  "60122": { state: "IL", city: "CAROL STREAM" },
  "60123": { state: "IL", city: "ELGIN" },
  "60124": { state: "IL", city: "ELGIN" },
  "60126": { state: "IL", city: "ELMHURST" },
  "60128": { state: "IL", city: "CAROL STREAM" },
  "60129": { state: "IL", city: "ESMOND" },
  "60130": { state: "IL", city: "FOREST PARK" },
  "60131": { state: "IL", city: "FRANKLIN PARK" },
  "60132": { state: "IL", city: "CAROL STREAM" },
  "60133": { state: "IL", city: "HANOVER PARK" },
  "60134": { state: "IL", city: "GENEVA" },
  "60135": { state: "IL", city: "GENOA" },
  "60136": { state: "IL", city: "GILBERTS" },
  "60137": { state: "IL", city: "GLEN ELLYN" },
  "60138": { state: "IL", city: "GLEN ELLYN" },
  "60139": { state: "IL", city: "GLENDALE HEIGHTS" },
  "60140": { state: "IL", city: "HAMPSHIRE" },
  "60141": { state: "IL", city: "HINES" },
  "60142": { state: "IL", city: "HUNTLEY" },
  "60143": { state: "IL", city: "ITASCA" },
  "60144": { state: "IL", city: "KANEVILLE" },
  "60145": { state: "IL", city: "KINGSTON" },
  "60146": { state: "IL", city: "KIRKLAND" },
  "60147": { state: "IL", city: "LAFOX" },
  "60148": { state: "IL", city: "LOMBARD" },
  "60150": { state: "IL", city: "MALTA" },
  "60151": { state: "IL", city: "MAPLE PARK" },
  "60152": { state: "IL", city: "MARENGO" },
  "60153": { state: "IL", city: "MAYWOOD" },
  "60154": { state: "IL", city: "WESTCHESTER" },
  "60155": { state: "IL", city: "BROADVIEW" },
  "60156": { state: "IL", city: "LAKE IN THE HILLS" },
  "60157": { state: "IL", city: "MEDINAH" },
  "60159": { state: "IL", city: "SCHAUMBURG" },
  "60160": { state: "IL", city: "MELROSE PARK" },
  "60161": { state: "IL", city: "MELROSE PARK" },
  "60162": { state: "IL", city: "HILLSIDE" },
  "60163": { state: "IL", city: "BERKELEY" },
  "60164": { state: "IL", city: "MELROSE PARK" },
  "60165": { state: "IL", city: "STONE PARK" },
  "60168": { state: "IL", city: "SCHAUMBURG" },
  "60169": { state: "IL", city: "HOFFMAN ESTATES" },
  "60171": { state: "IL", city: "RIVER GROVE" },
  "60172": { state: "IL", city: "ROSELLE" },
  "60173": { state: "IL", city: "SCHAUMBURG" },
  "60174": { state: "IL", city: "SAINT CHARLES" },
  "60175": { state: "IL", city: "SAINT CHARLES" },
  "60176": { state: "IL", city: "SCHILLER PARK" },
  "60177": { state: "IL", city: "SOUTH ELGIN" },
  "60178": { state: "IL", city: "SYCAMORE" },
  "60179": { state: "IL", city: "HOFFMAN ESTATES" },
  "60180": { state: "IL", city: "UNION" },
  "60181": { state: "IL", city: "VILLA PARK" },
  "60183": { state: "IL", city: "WASCO" },
  "60184": { state: "IL", city: "WAYNE" },
  "60185": { state: "IL", city: "WEST CHICAGO" },
  "60186": { state: "IL", city: "WEST CHICAGO" },
  "60187": { state: "IL", city: "WHEATON" },
  "60188": { state: "IL", city: "CAROL STREAM" },
  "60189": { state: "IL", city: "WHEATON" },
  "60190": { state: "IL", city: "WINFIELD" },
  "60191": { state: "IL", city: "WOOD DALE" },
  "60192": { state: "IL", city: "HOFFMAN ESTATES" },
  "60193": { state: "IL", city: "SCHAUMBURG" },
  "60194": { state: "IL", city: "SCHAUMBURG" },
  "60195": { state: "IL", city: "SCHAUMBURG" },
  "60196": { state: "IL", city: "SCHAUMBURG" },
  "60197": { state: "IL", city: "CAROL STREAM" },
  "60199": { state: "IL", city: "CAROL STREAM" },
  "60201": { state: "IL", city: "EVANSTON" },
  "60202": { state: "IL", city: "EVANSTON" },
  "60203": { state: "IL", city: "EVANSTON" },
  "60204": { state: "IL", city: "EVANSTON" },
  "60208": { state: "IL", city: "EVANSTON" },
  "60301": { state: "IL", city: "OAK PARK" },
  "60302": { state: "IL", city: "OAK PARK" },
  "60303": { state: "IL", city: "OAK PARK" },
  "60304": { state: "IL", city: "OAK PARK" },
  "60305": { state: "IL", city: "RIVER FOREST" },
  "60399": { state: "IL", city: "WOOD DALE" },
  "60401": { state: "IL", city: "BEECHER" },
  "60402": { state: "IL", city: "BERWYN" },
  "60403": { state: "IL", city: "CREST HILL" },
  "60404": { state: "IL", city: "SHOREWOOD" },
  "60406": { state: "IL", city: "BLUE ISLAND" },
  "60407": { state: "IL", city: "BRACEVILLE" },
  "60408": { state: "IL", city: "BRAIDWOOD" },
  "60409": { state: "IL", city: "CALUMET CITY" },
  "60410": { state: "IL", city: "CHANNAHON" },
  "60411": { state: "IL", city: "CHICAGO HEIGHTS" },
  "60412": { state: "IL", city: "CHICAGO HEIGHTS" },
  "60415": { state: "IL", city: "CHICAGO RIDGE" },
  "60416": { state: "IL", city: "COAL CITY" },
  "60417": { state: "IL", city: "CRETE" },
  "60418": { state: "IL", city: "CRESTWOOD" },
  "60419": { state: "IL", city: "DOLTON" },
  "60420": { state: "IL", city: "DWIGHT" },
  "60421": { state: "IL", city: "ELWOOD" },
  "60422": { state: "IL", city: "FLOSSMOOR" },
  "60423": { state: "IL", city: "FRANKFORT" },
  "60424": { state: "IL", city: "GARDNER" },
  "60425": { state: "IL", city: "GLENWOOD" },
  "60426": { state: "IL", city: "HARVEY" },
  "60428": { state: "IL", city: "MARKHAM" },
  "60429": { state: "IL", city: "HAZEL CREST" },
  "60430": { state: "IL", city: "HOMEWOOD" },
  "60431": { state: "IL", city: "JOLIET" },
  "60432": { state: "IL", city: "JOLIET" },
  "60433": { state: "IL", city: "JOLIET" },
  "60434": { state: "IL", city: "JOLIET" },
  "60435": { state: "IL", city: "JOLIET" },
  "60436": { state: "IL", city: "JOLIET" },
  "60437": { state: "IL", city: "KINSMAN" },
  "60438": { state: "IL", city: "LANSING" },
  "60439": { state: "IL", city: "LEMONT" },
  "60440": { state: "IL", city: "BOLINGBROOK" },
  "60441": { state: "IL", city: "LOCKPORT" },
  "60442": { state: "IL", city: "MANHATTAN" },
  "60443": { state: "IL", city: "MATTESON" },
  "60444": { state: "IL", city: "MAZON" },
  "60445": { state: "IL", city: "MIDLOTHIAN" },
  "60446": { state: "IL", city: "ROMEOVILLE" },
  "60447": { state: "IL", city: "MINOOKA" },
  "60448": { state: "IL", city: "MOKENA" },
  "60449": { state: "IL", city: "MONEE" },
  "60450": { state: "IL", city: "MORRIS" },
  "60451": { state: "IL", city: "NEW LENOX" },
  "60452": { state: "IL", city: "OAK FOREST" },
  "60453": { state: "IL", city: "OAK LAWN" },
  "60454": { state: "IL", city: "OAK LAWN" },
  "60455": { state: "IL", city: "BRIDGEVIEW" },
  "60456": { state: "IL", city: "HOMETOWN" },
  "60457": { state: "IL", city: "HICKORY HILLS" },
  "60458": { state: "IL", city: "JUSTICE" },
  "60459": { state: "IL", city: "BURBANK" },
  "60460": { state: "IL", city: "ODELL" },
  "60461": { state: "IL", city: "OLYMPIA FIELDS" },
  "60462": { state: "IL", city: "ORLAND PARK" },
  "60463": { state: "IL", city: "PALOS HEIGHTS" },
  "60464": { state: "IL", city: "PALOS PARK" },
  "60465": { state: "IL", city: "PALOS HILLS" },
  "60466": { state: "IL", city: "PARK FOREST" },
  "60467": { state: "IL", city: "ORLAND PARK" },
  "60468": { state: "IL", city: "PEOTONE" },
  "60469": { state: "IL", city: "POSEN" },
  "60470": { state: "IL", city: "RANSOM" },
  "60471": { state: "IL", city: "RICHTON PARK" },
  "60472": { state: "IL", city: "ROBBINS" },
  "60473": { state: "IL", city: "SOUTH HOLLAND" },
  "60474": { state: "IL", city: "SOUTH WILMINGTON" },
  "60475": { state: "IL", city: "STEGER" },
  "60476": { state: "IL", city: "THORNTON" },
  "60477": { state: "IL", city: "TINLEY PARK" },
  "60478": { state: "IL", city: "COUNTRY CLUB HILLS" },
  "60479": { state: "IL", city: "VERONA" },
  "60480": { state: "IL", city: "WILLOW SPRINGS" },
  "60481": { state: "IL", city: "WILMINGTON" },
  "60482": { state: "IL", city: "WORTH" },
  "60484": { state: "IL", city: "UNIVERSITY PARK" },
  "60487": { state: "IL", city: "TINLEY PARK" },
  "60490": { state: "IL", city: "BOLINGBROOK" },
  "60491": { state: "IL", city: "HOMER GLEN" },
  "60499": { state: "IL", city: "BEDFORD PARK" },
  "60501": { state: "IL", city: "SUMMIT ARGO" },
  "60502": { state: "IL", city: "AURORA" },
  "60503": { state: "IL", city: "AURORA" },
  "60504": { state: "IL", city: "AURORA" },
  "60505": { state: "IL", city: "AURORA" },
  "60506": { state: "IL", city: "AURORA" },
  "60507": { state: "IL", city: "AURORA" },
  "60510": { state: "IL", city: "BATAVIA" },
  "60511": { state: "IL", city: "BIG ROCK" },
  "60512": { state: "IL", city: "BRISTOL" },
  "60513": { state: "IL", city: "BROOKFIELD" },
  "60514": { state: "IL", city: "CLARENDON HILLS" },
  "60515": { state: "IL", city: "DOWNERS GROVE" },
  "60516": { state: "IL", city: "DOWNERS GROVE" },
  "60517": { state: "IL", city: "WOODRIDGE" },
  "60518": { state: "IL", city: "EARLVILLE" },
  "60519": { state: "IL", city: "EOLA" },
  "60520": { state: "IL", city: "HINCKLEY" },
  "60521": { state: "IL", city: "HINSDALE" },
  "60522": { state: "IL", city: "HINSDALE" },
  "60523": { state: "IL", city: "OAK BROOK" },
  "60525": { state: "IL", city: "LA GRANGE" },
  "60526": { state: "IL", city: "LA GRANGE PARK" },
  "60527": { state: "IL", city: "WILLOWBROOK" },
  "60530": { state: "IL", city: "LEE" },
  "60531": { state: "IL", city: "LELAND" },
  "60532": { state: "IL", city: "LISLE" },
  "60534": { state: "IL", city: "LYONS" },
  "60536": { state: "IL", city: "MILLBROOK" },
  "60537": { state: "IL", city: "MILLINGTON" },
  "60538": { state: "IL", city: "MONTGOMERY" },
  "60539": { state: "IL", city: "MOOSEHEART" },
  "60540": { state: "IL", city: "NAPERVILLE" },
  "60541": { state: "IL", city: "NEWARK" },
  "60542": { state: "IL", city: "NORTH AURORA" },
  "60543": { state: "IL", city: "OSWEGO" },
  "60544": { state: "IL", city: "PLAINFIELD" },
  "60545": { state: "IL", city: "PLANO" },
  "60546": { state: "IL", city: "RIVERSIDE" },
  "60548": { state: "IL", city: "SANDWICH" },
  "60549": { state: "IL", city: "SERENA" },
  "60550": { state: "IL", city: "SHABBONA" },
  "60551": { state: "IL", city: "SHERIDAN" },
  "60552": { state: "IL", city: "SOMONAUK" },
  "60553": { state: "IL", city: "STEWARD" },
  "60554": { state: "IL", city: "SUGAR GROVE" },
  "60555": { state: "IL", city: "WARRENVILLE" },
  "60556": { state: "IL", city: "WATERMAN" },
  "60557": { state: "IL", city: "WEDRON" },
  "60558": { state: "IL", city: "WESTERN SPRINGS" },
  "60559": { state: "IL", city: "WESTMONT" },
  "60560": { state: "IL", city: "YORKVILLE" },
  "60561": { state: "IL", city: "DARIEN" },
  "60563": { state: "IL", city: "NAPERVILLE" },
  "60564": { state: "IL", city: "NAPERVILLE" },
  "60565": { state: "IL", city: "NAPERVILLE" },
  "60566": { state: "IL", city: "NAPERVILLE" },
  "60567": { state: "IL", city: "NAPERVILLE" },
  "60568": { state: "IL", city: "AURORA" },
  "60569": { state: "IL", city: "AURORA" },
  "60572": { state: "IL", city: "AURORA" },
  "60585": { state: "IL", city: "PLAINFIELD" },
  "60586": { state: "IL", city: "PLAINFIELD" },
  "60598": { state: "IL", city: "AURORA" },
  "60599": { state: "IL", city: "FOX VALLEY" },
  "60601": { state: "IL", city: "CHICAGO" },
  "60602": { state: "IL", city: "CHICAGO" },
  "60603": { state: "IL", city: "CHICAGO" },
  "60604": { state: "IL", city: "CHICAGO" },
  "60605": { state: "IL", city: "CHICAGO" },
  "60606": { state: "IL", city: "CHICAGO" },
  "60607": { state: "IL", city: "CHICAGO" },
  "60608": { state: "IL", city: "CHICAGO" },
  "60609": { state: "IL", city: "CHICAGO" },
  "60610": { state: "IL", city: "CHICAGO" },
  "60611": { state: "IL", city: "CHICAGO" },
  "60612": { state: "IL", city: "CHICAGO" },
  "60613": { state: "IL", city: "CHICAGO" },
  "60614": { state: "IL", city: "CHICAGO" },
  "60615": { state: "IL", city: "CHICAGO" },
  "60616": { state: "IL", city: "CHICAGO" },
  "60617": { state: "IL", city: "CHICAGO" },
  "60618": { state: "IL", city: "CHICAGO" },
  "60619": { state: "IL", city: "CHICAGO" },
  "60620": { state: "IL", city: "CHICAGO" },
  "60621": { state: "IL", city: "CHICAGO" },
  "60622": { state: "IL", city: "CHICAGO" },
  "60623": { state: "IL", city: "CHICAGO" },
  "60624": { state: "IL", city: "CHICAGO" },
  "60625": { state: "IL", city: "CHICAGO" },
  "60626": { state: "IL", city: "CHICAGO" },
  "60628": { state: "IL", city: "CHICAGO" },
  "60629": { state: "IL", city: "CHICAGO" },
  "60630": { state: "IL", city: "CHICAGO" },
  "60631": { state: "IL", city: "CHICAGO" },
  "60632": { state: "IL", city: "CHICAGO" },
  "60633": { state: "IL", city: "CHICAGO" },
  "60634": { state: "IL", city: "CHICAGO" },
  "60636": { state: "IL", city: "CHICAGO" },
  "60637": { state: "IL", city: "CHICAGO" },
  "60638": { state: "IL", city: "CHICAGO" },
  "60639": { state: "IL", city: "CHICAGO" },
  "60640": { state: "IL", city: "CHICAGO" },
  "60641": { state: "IL", city: "CHICAGO" },
  "60642": { state: "IL", city: "CHICAGO" },
  "60643": { state: "IL", city: "CHICAGO" },
  "60644": { state: "IL", city: "CHICAGO" },
  "60645": { state: "IL", city: "CHICAGO" },
  "60646": { state: "IL", city: "CHICAGO" },
  "60647": { state: "IL", city: "CHICAGO" },
  "60649": { state: "IL", city: "CHICAGO" },
  "60651": { state: "IL", city: "CHICAGO" },
  "60652": { state: "IL", city: "CHICAGO" },
  "60653": { state: "IL", city: "CHICAGO" },
  "60654": { state: "IL", city: "CHICAGO" },
  "60655": { state: "IL", city: "CHICAGO" },
  "60656": { state: "IL", city: "CHICAGO" },
  "60657": { state: "IL", city: "CHICAGO" },
  "60659": { state: "IL", city: "CHICAGO" },
  "60660": { state: "IL", city: "CHICAGO" },
  "60661": { state: "IL", city: "CHICAGO" },
  "60664": { state: "IL", city: "CHICAGO" },
  "60666": { state: "IL", city: "CHICAGO" },
  "60668": { state: "IL", city: "CHICAGO" },
  "60669": { state: "IL", city: "CHICAGO" },
  "60670": { state: "IL", city: "CHICAGO" },
  "60673": { state: "IL", city: "CHICAGO" },
  "60674": { state: "IL", city: "CHICAGO" },
  "60675": { state: "IL", city: "CHICAGO" },
  "60677": { state: "IL", city: "CHICAGO" },
  "60678": { state: "IL", city: "CHICAGO" },
  "60680": { state: "IL", city: "CHICAGO" },
  "60681": { state: "IL", city: "CHICAGO" },
  "60682": { state: "IL", city: "CHICAGO" },
  "60684": { state: "IL", city: "CHICAGO" },
  "60685": { state: "IL", city: "CHICAGO" },
  "60686": { state: "IL", city: "CHICAGO" },
  "60687": { state: "IL", city: "CHICAGO" },
  "60688": { state: "IL", city: "CHICAGO" },
  "60689": { state: "IL", city: "CHICAGO" },
  "60690": { state: "IL", city: "CHICAGO" },
  "60691": { state: "IL", city: "CHICAGO" },
  "60693": { state: "IL", city: "CHICAGO" },
  "60694": { state: "IL", city: "CHICAGO" },
  "60695": { state: "IL", city: "CHICAGO" },
  "60696": { state: "IL", city: "CHICAGO" },
  "60697": { state: "IL", city: "CHICAGO" },
  "60699": { state: "IL", city: "CHICAGO" },
  "60701": { state: "IL", city: "CHICAGO" },
  "60706": { state: "IL", city: "HARWOOD HEIGHTS" },
  "60707": { state: "IL", city: "ELMWOOD PARK" },
  "60712": { state: "IL", city: "LINCOLNWOOD" },
  "60714": { state: "IL", city: "NILES" },
  "60803": { state: "IL", city: "ALSIP" },
  "60804": { state: "IL", city: "CICERO" },
  "60805": { state: "IL", city: "EVERGREEN PARK" },
  "60827": { state: "IL", city: "RIVERDALE" },
  "60901": { state: "IL", city: "KANKAKEE" },
  "60910": { state: "IL", city: "AROMA PARK" },
  "60911": { state: "IL", city: "ASHKUM" },
  "60912": { state: "IL", city: "BEAVERVILLE" },
  "60913": { state: "IL", city: "BONFIELD" },
  "60914": { state: "IL", city: "BOURBONNAIS" },
  "60915": { state: "IL", city: "BRADLEY" },
  "60917": { state: "IL", city: "BUCKINGHAM" },
  "60918": { state: "IL", city: "BUCKLEY" },
  "60919": { state: "IL", city: "CABERY" },
  "60920": { state: "IL", city: "CAMPUS" },
  "60921": { state: "IL", city: "CHATSWORTH" },
  "60922": { state: "IL", city: "CHEBANSE" },
  "60924": { state: "IL", city: "CISSNA PARK" },
  "60926": { state: "IL", city: "CLAYTONVILLE" },
  "60927": { state: "IL", city: "CLIFTON" },
  "60928": { state: "IL", city: "CRESCENT CITY" },
  "60929": { state: "IL", city: "CULLOM" },
  "60930": { state: "IL", city: "DANFORTH" },
  "60931": { state: "IL", city: "DONOVAN" },
  "60932": { state: "IL", city: "EAST LYNN" },
  "60933": { state: "IL", city: "ELLIOTT" },
  "60934": { state: "IL", city: "EMINGTON" },
  "60935": { state: "IL", city: "ESSEX" },
  "60936": { state: "IL", city: "GIBSON CITY" },
  "60938": { state: "IL", city: "GILMAN" },
  "60939": { state: "IL", city: "GOODWINE" },
  "60940": { state: "IL", city: "GRANT PARK" },
  "60941": { state: "IL", city: "HERSCHER" },
  "60942": { state: "IL", city: "HOOPESTON" },
  "60944": { state: "IL", city: "HOPKINS PARK" },
  "60945": { state: "IL", city: "IROQUOIS" },
  "60946": { state: "IL", city: "KEMPTON" },
  "60948": { state: "IL", city: "LODA" },
  "60949": { state: "IL", city: "LUDLOW" },
  "60950": { state: "IL", city: "MANTENO" },
  "60951": { state: "IL", city: "MARTINTON" },
  "60952": { state: "IL", city: "MELVIN" },
  "60953": { state: "IL", city: "MILFORD" },
  "60954": { state: "IL", city: "MOMENCE" },
  "60955": { state: "IL", city: "ONARGA" },
  "60956": { state: "IL", city: "PAPINEAU" },
  "60957": { state: "IL", city: "PAXTON" },
  "60958": { state: "IL", city: "PEMBROKE TOWNSHIP" },
  "60959": { state: "IL", city: "PIPER CITY" },
  "60960": { state: "IL", city: "RANKIN" },
  "60961": { state: "IL", city: "REDDICK" },
  "60962": { state: "IL", city: "ROBERTS" },
  "60963": { state: "IL", city: "ROSSVILLE" },
  "60964": { state: "IL", city: "SAINT ANNE" },
  "60966": { state: "IL", city: "SHELDON" },
  "60967": { state: "IL", city: "STOCKLAND" },
  "60968": { state: "IL", city: "THAWVILLE" },
  "60969": { state: "IL", city: "UNION HILL" },
  "60970": { state: "IL", city: "WATSEKA" },
  "60973": { state: "IL", city: "WELLINGTON" },
  "60974": { state: "IL", city: "WOODLAND" },
  "61001": { state: "IL", city: "APPLE RIVER" },
  "61006": { state: "IL", city: "ASHTON" },
  "61007": { state: "IL", city: "BAILEYVILLE" },
  "61008": { state: "IL", city: "BELVIDERE" },
  "61010": { state: "IL", city: "BYRON" },
  "61011": { state: "IL", city: "CALEDONIA" },
  "61012": { state: "IL", city: "CAPRON" },
  "61013": { state: "IL", city: "CEDARVILLE" },
  "61014": { state: "IL", city: "CHADWICK" },
  "61015": { state: "IL", city: "CHANA" },
  "61016": { state: "IL", city: "CHERRY VALLEY" },
  "61018": { state: "IL", city: "DAKOTA" },
  "61019": { state: "IL", city: "DAVIS" },
  "61020": { state: "IL", city: "DAVIS JUNCTION" },
  "61021": { state: "IL", city: "DIXON" },
  "61024": { state: "IL", city: "DURAND" },
  "61025": { state: "IL", city: "EAST DUBUQUE" },
  "61027": { state: "IL", city: "ELEROY" },
  "61028": { state: "IL", city: "ELIZABETH" },
  "61030": { state: "IL", city: "FORRESTON" },
  "61031": { state: "IL", city: "FRANKLIN GROVE" },
  "61032": { state: "IL", city: "FREEPORT" },
  "61036": { state: "IL", city: "GALENA" },
  "61037": { state: "IL", city: "GALT" },
  "61038": { state: "IL", city: "GARDEN PRAIRIE" },
  "61039": { state: "IL", city: "GERMAN VALLEY" },
  "61041": { state: "IL", city: "HANOVER" },
  "61042": { state: "IL", city: "HARMON" },
  "61043": { state: "IL", city: "HOLCOMB" },
  "61044": { state: "IL", city: "KENT" },
  "61046": { state: "IL", city: "LANARK" },
  "61047": { state: "IL", city: "LEAF RIVER" },
  "61048": { state: "IL", city: "LENA" },
  "61049": { state: "IL", city: "LINDENWOOD" },
  "61050": { state: "IL", city: "MC CONNELL" },
  "61051": { state: "IL", city: "MILLEDGEVILLE" },
  "61052": { state: "IL", city: "MONROE CENTER" },
  "61053": { state: "IL", city: "MOUNT CARROLL" },
  "61054": { state: "IL", city: "MOUNT MORRIS" },
  "61057": { state: "IL", city: "NACHUSA" },
  "61059": { state: "IL", city: "NORA" },
  "61060": { state: "IL", city: "ORANGEVILLE" },
  "61061": { state: "IL", city: "OREGON" },
  "61062": { state: "IL", city: "PEARL CITY" },
  "61063": { state: "IL", city: "PECATONICA" },
  "61064": { state: "IL", city: "POLO" },
  "61065": { state: "IL", city: "POPLAR GROVE" },
  "61067": { state: "IL", city: "RIDOTT" },
  "61068": { state: "IL", city: "ROCHELLE" },
  "61070": { state: "IL", city: "ROCK CITY" },
  "61071": { state: "IL", city: "ROCK FALLS" },
  "61072": { state: "IL", city: "ROCKTON" },
  "61073": { state: "IL", city: "ROSCOE" },
  "61074": { state: "IL", city: "SAVANNA" },
  "61075": { state: "IL", city: "SCALES MOUND" },
  "61077": { state: "IL", city: "SEWARD" },
  "61078": { state: "IL", city: "SHANNON" },
  "61079": { state: "IL", city: "SHIRLAND" },
  "61080": { state: "IL", city: "SOUTH BELOIT" },
  "61081": { state: "IL", city: "STERLING" },
  "61084": { state: "IL", city: "STILLMAN VALLEY" },
  "61085": { state: "IL", city: "STOCKTON" },
  "61087": { state: "IL", city: "WARREN" },
  "61088": { state: "IL", city: "WINNEBAGO" },
  "61089": { state: "IL", city: "WINSLOW" },
  "61091": { state: "IL", city: "WOOSUNG" },
  "61101": { state: "IL", city: "ROCKFORD" },
  "61102": { state: "IL", city: "ROCKFORD" },
  "61103": { state: "IL", city: "ROCKFORD" },
  "61104": { state: "IL", city: "ROCKFORD" },
  "61105": { state: "IL", city: "ROCKFORD" },
  "61106": { state: "IL", city: "ROCKFORD" },
  "61107": { state: "IL", city: "ROCKFORD" },
  "61108": { state: "IL", city: "ROCKFORD" },
  "61109": { state: "IL", city: "ROCKFORD" },
  "61110": { state: "IL", city: "ROCKFORD" },
  "61111": { state: "IL", city: "LOVES PARK" },
  "61112": { state: "IL", city: "ROCKFORD" },
  "61114": { state: "IL", city: "ROCKFORD" },
  "61115": { state: "IL", city: "MACHESNEY PARK" },
  "61125": { state: "IL", city: "ROCKFORD" },
  "61126": { state: "IL", city: "ROCKFORD" },
  "61130": { state: "IL", city: "LOVES PARK" },
  "61131": { state: "IL", city: "LOVES PARK" },
  "61132": { state: "IL", city: "LOVES PARK" },
  "61201": { state: "IL", city: "ROCK ISLAND" },
  "61204": { state: "IL", city: "ROCK ISLAND" },
  "61230": { state: "IL", city: "ALBANY" },
  "61231": { state: "IL", city: "ALEDO" },
  "61232": { state: "IL", city: "ANDALUSIA" },
  "61233": { state: "IL", city: "ANDOVER" },
  "61234": { state: "IL", city: "ANNAWAN" },
  "61235": { state: "IL", city: "ATKINSON" },
  "61236": { state: "IL", city: "BARSTOW" },
  "61237": { state: "IL", city: "BUFFALO PRAIRIE" },
  "61238": { state: "IL", city: "CAMBRIDGE" },
  "61239": { state: "IL", city: "CARBON CLIFF" },
  "61240": { state: "IL", city: "COAL VALLEY" },
  "61241": { state: "IL", city: "COLONA" },
  "61242": { state: "IL", city: "CORDOVA" },
  "61243": { state: "IL", city: "DEER GROVE" },
  "61244": { state: "IL", city: "EAST MOLINE" },
  "61250": { state: "IL", city: "ERIE" },
  "61251": { state: "IL", city: "FENTON" },
  "61252": { state: "IL", city: "FULTON" },
  "61254": { state: "IL", city: "GENESEO" },
  "61256": { state: "IL", city: "HAMPTON" },
  "61257": { state: "IL", city: "HILLSDALE" },
  "61258": { state: "IL", city: "HOOPPOLE" },
  "61259": { state: "IL", city: "ILLINOIS CITY" },
  "61260": { state: "IL", city: "JOY" },
  "61261": { state: "IL", city: "LYNDON" },
  "61262": { state: "IL", city: "LYNN CENTER" },
  "61263": { state: "IL", city: "MATHERVILLE" },
  "61264": { state: "IL", city: "MILAN" },
  "61265": { state: "IL", city: "MOLINE" },
  "61266": { state: "IL", city: "MOLINE" },
  "61270": { state: "IL", city: "MORRISON" },
  "61272": { state: "IL", city: "NEW BOSTON" },
  "61273": { state: "IL", city: "ORION" },
  "61274": { state: "IL", city: "OSCO" },
  "61275": { state: "IL", city: "PORT BYRON" },
  "61276": { state: "IL", city: "PREEMPTION" },
  "61277": { state: "IL", city: "PROPHETSTOWN" },
  "61278": { state: "IL", city: "RAPIDS CITY" },
  "61279": { state: "IL", city: "REYNOLDS" },
  "61281": { state: "IL", city: "SHERRARD" },
  "61282": { state: "IL", city: "SILVIS" },
  "61283": { state: "IL", city: "TAMPICO" },
  "61284": { state: "IL", city: "TAYLOR RIDGE" },
  "61285": { state: "IL", city: "THOMSON" },
  "61299": { state: "IL", city: "ROCK ISLAND" },
  "61301": { state: "IL", city: "LA SALLE" },
  "61310": { state: "IL", city: "AMBOY" },
  "61311": { state: "IL", city: "ANCONA" },
  "61312": { state: "IL", city: "ARLINGTON" },
  "61313": { state: "IL", city: "BLACKSTONE" },
  "61314": { state: "IL", city: "BUDA" },
  "61315": { state: "IL", city: "BUREAU" },
  "61316": { state: "IL", city: "CEDAR POINT" },
  "61317": { state: "IL", city: "CHERRY" },
  "61318": { state: "IL", city: "COMPTON" },
  "61319": { state: "IL", city: "CORNELL" },
  "61320": { state: "IL", city: "DALZELL" },
  "61321": { state: "IL", city: "DANA" },
  "61322": { state: "IL", city: "DEPUE" },
  "61323": { state: "IL", city: "DOVER" },
  "61324": { state: "IL", city: "ELDENA" },
  "61325": { state: "IL", city: "GRAND RIDGE" },
  "61326": { state: "IL", city: "GRANVILLE" },
  "61327": { state: "IL", city: "HENNEPIN" },
  "61328": { state: "IL", city: "KASBEER" },
  "61329": { state: "IL", city: "LADD" },
  "61330": { state: "IL", city: "LA MOILLE" },
  "61331": { state: "IL", city: "LEE CENTER" },
  "61332": { state: "IL", city: "LEONORE" },
  "61333": { state: "IL", city: "LONG POINT" },
  "61334": { state: "IL", city: "LOSTANT" },
  "61335": { state: "IL", city: "MC NABB" },
  "61336": { state: "IL", city: "MAGNOLIA" },
  "61337": { state: "IL", city: "MALDEN" },
  "61338": { state: "IL", city: "MANLIUS" },
  "61340": { state: "IL", city: "MARK" },
  "61341": { state: "IL", city: "MARSEILLES" },
  "61342": { state: "IL", city: "MENDOTA" },
  "61344": { state: "IL", city: "MINERAL" },
  "61345": { state: "IL", city: "NEPONSET" },
  "61346": { state: "IL", city: "NEW BEDFORD" },
  "61348": { state: "IL", city: "OGLESBY" },
  "61349": { state: "IL", city: "OHIO" },
  "61350": { state: "IL", city: "OTTAWA" },
  "61353": { state: "IL", city: "PAW PAW" },
  "61354": { state: "IL", city: "PERU" },
  "61356": { state: "IL", city: "PRINCETON" },
  "61358": { state: "IL", city: "RUTLAND" },
  "61359": { state: "IL", city: "SEATONVILLE" },
  "61360": { state: "IL", city: "SENECA" },
  "61361": { state: "IL", city: "SHEFFIELD" },
  "61362": { state: "IL", city: "SPRING VALLEY" },
  "61363": { state: "IL", city: "STANDARD" },
  "61364": { state: "IL", city: "STREATOR" },
  "61367": { state: "IL", city: "SUBLETTE" },
  "61368": { state: "IL", city: "TISKILWA" },
  "61369": { state: "IL", city: "TOLUCA" },
  "61370": { state: "IL", city: "TONICA" },
  "61371": { state: "IL", city: "TRIUMPH" },
  "61372": { state: "IL", city: "TROY GROVE" },
  "61373": { state: "IL", city: "UTICA" },
  "61374": { state: "IL", city: "VAN ORIN" },
  "61375": { state: "IL", city: "VARNA" },
  "61376": { state: "IL", city: "WALNUT" },
  "61377": { state: "IL", city: "WENONA" },
  "61378": { state: "IL", city: "WEST BROOKLYN" },
  "61379": { state: "IL", city: "WYANET" },
  "61401": { state: "IL", city: "GALESBURG" },
  "61402": { state: "IL", city: "GALESBURG" },
  "61410": { state: "IL", city: "ABINGDON" },
  "61411": { state: "IL", city: "ADAIR" },
  "61412": { state: "IL", city: "ALEXIS" },
  "61413": { state: "IL", city: "ALPHA" },
  "61414": { state: "IL", city: "ALTONA" },
  "61415": { state: "IL", city: "AVON" },
  "61416": { state: "IL", city: "BARDOLPH" },
  "61417": { state: "IL", city: "BERWICK" },
  "61418": { state: "IL", city: "BIGGSVILLE" },
  "61419": { state: "IL", city: "BISHOP HILL" },
  "61420": { state: "IL", city: "BLANDINSVILLE" },
  "61421": { state: "IL", city: "BRADFORD" },
  "61422": { state: "IL", city: "BUSHNELL" },
  "61423": { state: "IL", city: "CAMERON" },
  "61424": { state: "IL", city: "CAMP GROVE" },
  "61425": { state: "IL", city: "CARMAN" },
  "61426": { state: "IL", city: "CASTLETON" },
  "61427": { state: "IL", city: "CUBA" },
  "61428": { state: "IL", city: "DAHINDA" },
  "61430": { state: "IL", city: "EAST GALESBURG" },
  "61431": { state: "IL", city: "ELLISVILLE" },
  "61432": { state: "IL", city: "FAIRVIEW" },
  "61433": { state: "IL", city: "FIATT" },
  "61434": { state: "IL", city: "GALVA" },
  "61435": { state: "IL", city: "GERLAW" },
  "61436": { state: "IL", city: "GILSON" },
  "61437": { state: "IL", city: "GLADSTONE" },
  "61438": { state: "IL", city: "GOOD HOPE" },
  "61439": { state: "IL", city: "HENDERSON" },
  "61440": { state: "IL", city: "INDUSTRY" },
  "61441": { state: "IL", city: "IPAVA" },
  "61442": { state: "IL", city: "KEITHSBURG" },
  "61443": { state: "IL", city: "KEWANEE" },
  "61447": { state: "IL", city: "KIRKWOOD" },
  "61448": { state: "IL", city: "KNOXVILLE" },
  "61449": { state: "IL", city: "LA FAYETTE" },
  "61450": { state: "IL", city: "LA HARPE" },
  "61451": { state: "IL", city: "LAURA" },
  "61452": { state: "IL", city: "LITTLETON" },
  "61453": { state: "IL", city: "LITTLE YORK" },
  "61454": { state: "IL", city: "LOMAX" },
  "61455": { state: "IL", city: "MACOMB" },
  "61458": { state: "IL", city: "MAQUON" },
  "61459": { state: "IL", city: "MARIETTA" },
  "61460": { state: "IL", city: "MEDIA" },
  "61462": { state: "IL", city: "MONMOUTH" },
  "61465": { state: "IL", city: "NEW WINDSOR" },
  "61466": { state: "IL", city: "NORTH HENDERSON" },
  "61467": { state: "IL", city: "ONEIDA" },
  "61468": { state: "IL", city: "OPHIEM" },
  "61469": { state: "IL", city: "OQUAWKA" },
  "61470": { state: "IL", city: "PRAIRIE CITY" },
  "61471": { state: "IL", city: "RARITAN" },
  "61472": { state: "IL", city: "RIO" },
  "61473": { state: "IL", city: "ROSEVILLE" },
  "61474": { state: "IL", city: "SAINT AUGUSTINE" },
  "61475": { state: "IL", city: "SCIOTA" },
  "61476": { state: "IL", city: "SEATON" },
  "61477": { state: "IL", city: "SMITHFIELD" },
  "61478": { state: "IL", city: "SMITHSHIRE" },
  "61479": { state: "IL", city: "SPEER" },
  "61480": { state: "IL", city: "STRONGHURST" },
  "61482": { state: "IL", city: "TABLE GROVE" },
  "61483": { state: "IL", city: "TOULON" },
  "61484": { state: "IL", city: "VERMONT" },
  "61485": { state: "IL", city: "VICTORIA" },
  "61486": { state: "IL", city: "VIOLA" },
  "61488": { state: "IL", city: "WATAGA" },
  "61489": { state: "IL", city: "WILLIAMSFIELD" },
  "61490": { state: "IL", city: "WOODHULL" },
  "61491": { state: "IL", city: "WYOMING" },
  "61501": { state: "IL", city: "ASTORIA" },
  "61516": { state: "IL", city: "BENSON" },
  "61517": { state: "IL", city: "BRIMFIELD" },
  "61519": { state: "IL", city: "BRYANT" },
  "61520": { state: "IL", city: "CANTON" },
  "61523": { state: "IL", city: "CHILLICOTHE" },
  "61524": { state: "IL", city: "DUNFERMLINE" },
  "61525": { state: "IL", city: "DUNLAP" },
  "61526": { state: "IL", city: "EDELSTEIN" },
  "61528": { state: "IL", city: "EDWARDS" },
  "61529": { state: "IL", city: "ELMWOOD" },
  "61530": { state: "IL", city: "EUREKA" },
  "61531": { state: "IL", city: "FARMINGTON" },
  "61532": { state: "IL", city: "FOREST CITY" },
  "61533": { state: "IL", city: "GLASFORD" },
  "61534": { state: "IL", city: "GREEN VALLEY" },
  "61535": { state: "IL", city: "GROVELAND" },
  "61536": { state: "IL", city: "HANNA CITY" },
  "61537": { state: "IL", city: "HENRY" },
  "61539": { state: "IL", city: "KINGSTON MINES" },
  "61540": { state: "IL", city: "LACON" },
  "61541": { state: "IL", city: "LA ROSE" },
  "61542": { state: "IL", city: "LEWISTOWN" },
  "61543": { state: "IL", city: "LIVERPOOL" },
  "61544": { state: "IL", city: "LONDON MILLS" },
  "61545": { state: "IL", city: "LOWPOINT" },
  "61546": { state: "IL", city: "MANITO" },
  "61547": { state: "IL", city: "MAPLETON" },
  "61548": { state: "IL", city: "METAMORA" },
  "61550": { state: "IL", city: "MORTON" },
  "61552": { state: "IL", city: "MOSSVILLE" },
  "61553": { state: "IL", city: "NORRIS" },
  "61554": { state: "IL", city: "PEKIN" },
  "61555": { state: "IL", city: "PEKIN" },
  "61558": { state: "IL", city: "PEKIN" },
  "61559": { state: "IL", city: "PRINCEVILLE" },
  "61560": { state: "IL", city: "PUTNAM" },
  "61561": { state: "IL", city: "ROANOKE" },
  "61562": { state: "IL", city: "ROME" },
  "61563": { state: "IL", city: "SAINT DAVID" },
  "61564": { state: "IL", city: "SOUTH PEKIN" },
  "61565": { state: "IL", city: "SPARLAND" },
  "61567": { state: "IL", city: "TOPEKA" },
  "61568": { state: "IL", city: "TREMONT" },
  "61569": { state: "IL", city: "TRIVOLI" },
  "61570": { state: "IL", city: "WASHBURN" },
  "61571": { state: "IL", city: "WASHINGTON" },
  "61572": { state: "IL", city: "YATES CITY" },
  "61601": { state: "IL", city: "PEORIA" },
  "61602": { state: "IL", city: "PEORIA" },
  "61603": { state: "IL", city: "PEORIA" },
  "61604": { state: "IL", city: "PEORIA" },
  "61605": { state: "IL", city: "PEORIA" },
  "61606": { state: "IL", city: "PEORIA" },
  "61607": { state: "IL", city: "PEORIA" },
  "61610": { state: "IL", city: "CREVE COEUR" },
  "61611": { state: "IL", city: "EAST PEORIA" },
  "61612": { state: "IL", city: "PEORIA" },
  "61613": { state: "IL", city: "PEORIA" },
  "61614": { state: "IL", city: "PEORIA" },
  "61615": { state: "IL", city: "PEORIA" },
  "61616": { state: "IL", city: "PEORIA HEIGHTS" },
  "61625": { state: "IL", city: "PEORIA" },
  "61629": { state: "IL", city: "PEORIA" },
  "61630": { state: "IL", city: "PEORIA" },
  "61633": { state: "IL", city: "PEORIA" },
  "61634": { state: "IL", city: "PEORIA" },
  "61635": { state: "IL", city: "EAST PEORIA" },
  "61636": { state: "IL", city: "PEORIA" },
  "61637": { state: "IL", city: "PEORIA" },
  "61638": { state: "IL", city: "PEORIA" },
  "61639": { state: "IL", city: "PEORIA" },
  "61641": { state: "IL", city: "PEORIA" },
  "61643": { state: "IL", city: "PEORIA" },
  "61650": { state: "IL", city: "PEORIA" },
  "61651": { state: "IL", city: "PEORIA" },
  "61652": { state: "IL", city: "PEORIA" },
  "61653": { state: "IL", city: "PEORIA" },
  "61654": { state: "IL", city: "PEORIA" },
  "61655": { state: "IL", city: "PEORIA" },
  "61656": { state: "IL", city: "PEORIA" },
  "61701": { state: "IL", city: "BLOOMINGTON" },
  "61702": { state: "IL", city: "BLOOMINGTON" },
  "61704": { state: "IL", city: "BLOOMINGTON" },
  "61705": { state: "IL", city: "BLOOMINGTON" },
  "61709": { state: "IL", city: "BLOOMINGTON" },
  "61710": { state: "IL", city: "BLOOMINGTON" },
  "61720": { state: "IL", city: "ANCHOR" },
  "61721": { state: "IL", city: "ARMINGTON" },
  "61722": { state: "IL", city: "ARROWSMITH" },
  "61723": { state: "IL", city: "ATLANTA" },
  "61724": { state: "IL", city: "BELLFLOWER" },
  "61725": { state: "IL", city: "CARLOCK" },
  "61726": { state: "IL", city: "CHENOA" },
  "61727": { state: "IL", city: "CLINTON" },
  "61728": { state: "IL", city: "COLFAX" },
  "61729": { state: "IL", city: "CONGERVILLE" },
  "61730": { state: "IL", city: "COOKSVILLE" },
  "61731": { state: "IL", city: "CROPSEY" },
  "61732": { state: "IL", city: "DANVERS" },
  "61733": { state: "IL", city: "DEER CREEK" },
  "61734": { state: "IL", city: "DELAVAN" },
  "61735": { state: "IL", city: "DEWITT" },
  "61736": { state: "IL", city: "DOWNS" },
  "61737": { state: "IL", city: "ELLSWORTH" },
  "61738": { state: "IL", city: "EL PASO" },
  "61739": { state: "IL", city: "FAIRBURY" },
  "61740": { state: "IL", city: "FLANAGAN" },
  "61741": { state: "IL", city: "FORREST" },
  "61742": { state: "IL", city: "GOODFIELD" },
  "61743": { state: "IL", city: "GRAYMONT" },
  "61744": { state: "IL", city: "GRIDLEY" },
  "61745": { state: "IL", city: "HEYWORTH" },
  "61747": { state: "IL", city: "HOPEDALE" },
  "61748": { state: "IL", city: "HUDSON" },
  "61749": { state: "IL", city: "KENNEY" },
  "61750": { state: "IL", city: "LANE" },
  "61751": { state: "IL", city: "LAWNDALE" },
  "61752": { state: "IL", city: "LE ROY" },
  "61753": { state: "IL", city: "LEXINGTON" },
  "61754": { state: "IL", city: "MC LEAN" },
  "61755": { state: "IL", city: "MACKINAW" },
  "61756": { state: "IL", city: "MAROA" },
  "61758": { state: "IL", city: "MERNA" },
  "61759": { state: "IL", city: "MINIER" },
  "61760": { state: "IL", city: "MINONK" },
  "61761": { state: "IL", city: "NORMAL" },
  "61764": { state: "IL", city: "PONTIAC" },
  "61769": { state: "IL", city: "SAUNEMIN" },
  "61770": { state: "IL", city: "SAYBROOK" },
  "61771": { state: "IL", city: "SECOR" },
  "61772": { state: "IL", city: "SHIRLEY" },
  "61773": { state: "IL", city: "SIBLEY" },
  "61774": { state: "IL", city: "STANFORD" },
  "61775": { state: "IL", city: "STRAWN" },
  "61776": { state: "IL", city: "TOWANDA" },
  "61777": { state: "IL", city: "WAPELLA" },
  "61778": { state: "IL", city: "WAYNESVILLE" },
  "61790": { state: "IL", city: "NORMAL" },
  "61791": { state: "IL", city: "BLOOMINGTON" },
  "61799": { state: "IL", city: "BLOOMINGTON" },
  "61801": { state: "IL", city: "URBANA" },
  "61802": { state: "IL", city: "URBANA" },
  "61803": { state: "IL", city: "URBANA" },
  "61810": { state: "IL", city: "ALLERTON" },
  "61811": { state: "IL", city: "ALVIN" },
  "61812": { state: "IL", city: "ARMSTRONG" },
  "61813": { state: "IL", city: "BEMENT" },
  "61814": { state: "IL", city: "BISMARCK" },
  "61815": { state: "IL", city: "BONDVILLE" },
  "61816": { state: "IL", city: "BROADLANDS" },
  "61817": { state: "IL", city: "CATLIN" },
  "61818": { state: "IL", city: "CERRO GORDO" },
  "61820": { state: "IL", city: "CHAMPAIGN" },
  "61821": { state: "IL", city: "CHAMPAIGN" },
  "61822": { state: "IL", city: "CHAMPAIGN" },
  "61824": { state: "IL", city: "CHAMPAIGN" },
  "61825": { state: "IL", city: "CHAMPAIGN" },
  "61826": { state: "IL", city: "CHAMPAIGN" },
  "61830": { state: "IL", city: "CISCO" },
  "61831": { state: "IL", city: "COLLISON" },
  "61832": { state: "IL", city: "DANVILLE" },
  "61833": { state: "IL", city: "TILTON" },
  "61834": { state: "IL", city: "DANVILLE" },
  "61839": { state: "IL", city: "DE LAND" },
  "61840": { state: "IL", city: "DEWEY" },
  "61841": { state: "IL", city: "FAIRMOUNT" },
  "61842": { state: "IL", city: "FARMER CITY" },
  "61843": { state: "IL", city: "FISHER" },
  "61844": { state: "IL", city: "FITHIAN" },
  "61845": { state: "IL", city: "FOOSLAND" },
  "61846": { state: "IL", city: "GEORGETOWN" },
  "61847": { state: "IL", city: "GIFFORD" },
  "61848": { state: "IL", city: "HENNING" },
  "61849": { state: "IL", city: "HOMER" },
  "61850": { state: "IL", city: "INDIANOLA" },
  "61851": { state: "IL", city: "IVESDALE" },
  "61852": { state: "IL", city: "LONGVIEW" },
  "61853": { state: "IL", city: "MAHOMET" },
  "61854": { state: "IL", city: "MANSFIELD" },
  "61855": { state: "IL", city: "MILMINE" },
  "61856": { state: "IL", city: "MONTICELLO" },
  "61857": { state: "IL", city: "MUNCIE" },
  "61858": { state: "IL", city: "OAKWOOD" },
  "61859": { state: "IL", city: "OGDEN" },
  "61862": { state: "IL", city: "PENFIELD" },
  "61863": { state: "IL", city: "PESOTUM" },
  "61864": { state: "IL", city: "PHILO" },
  "61865": { state: "IL", city: "POTOMAC" },
  "61866": { state: "IL", city: "RANTOUL" },
  "61870": { state: "IL", city: "RIDGE FARM" },
  "61871": { state: "IL", city: "ROYAL" },
  "61872": { state: "IL", city: "SADORUS" },
  "61873": { state: "IL", city: "SAINT JOSEPH" },
  "61874": { state: "IL", city: "SAVOY" },
  "61875": { state: "IL", city: "SEYMOUR" },
  "61876": { state: "IL", city: "SIDELL" },
  "61877": { state: "IL", city: "SIDNEY" },
  "61878": { state: "IL", city: "THOMASBORO" },
  "61880": { state: "IL", city: "TOLONO" },
  "61882": { state: "IL", city: "WELDON" },
  "61883": { state: "IL", city: "WESTVILLE" },
  "61884": { state: "IL", city: "WHITE HEATH" },
  "61910": { state: "IL", city: "ARCOLA" },
  "61911": { state: "IL", city: "ARTHUR" },
  "61912": { state: "IL", city: "ASHMORE" },
  "61913": { state: "IL", city: "ATWOOD" },
  "61914": { state: "IL", city: "BETHANY" },
  "61917": { state: "IL", city: "BROCTON" },
  "61919": { state: "IL", city: "CAMARGO" },
  "61920": { state: "IL", city: "CHARLESTON" },
  "61924": { state: "IL", city: "CHRISMAN" },
  "61925": { state: "IL", city: "DALTON CITY" },
  "61928": { state: "IL", city: "GAYS" },
  "61929": { state: "IL", city: "HAMMOND" },
  "61930": { state: "IL", city: "HINDSBORO" },
  "61931": { state: "IL", city: "HUMBOLDT" },
  "61932": { state: "IL", city: "HUME" },
  "61933": { state: "IL", city: "KANSAS" },
  "61936": { state: "IL", city: "LA PLACE" },
  "61937": { state: "IL", city: "LOVINGTON" },
  "61938": { state: "IL", city: "MATTOON" },
  "61940": { state: "IL", city: "METCALF" },
  "61941": { state: "IL", city: "MURDOCK" },
  "61942": { state: "IL", city: "NEWMAN" },
  "61943": { state: "IL", city: "OAKLAND" },
  "61944": { state: "IL", city: "PARIS" },
  "61949": { state: "IL", city: "REDMON" },
  "61951": { state: "IL", city: "SULLIVAN" },
  "61953": { state: "IL", city: "TUSCOLA" },
  "61955": { state: "IL", city: "VERMILION" },
  "61956": { state: "IL", city: "VILLA GROVE" },
  "61957": { state: "IL", city: "WINDSOR" },
  "62001": { state: "IL", city: "ALHAMBRA" },
  "62002": { state: "IL", city: "ALTON" },
  "62006": { state: "IL", city: "BATCHTOWN" },
  "62009": { state: "IL", city: "BENLD" },
  "62010": { state: "IL", city: "BETHALTO" },
  "62011": { state: "IL", city: "BINGHAM" },
  "62012": { state: "IL", city: "BRIGHTON" },
  "62013": { state: "IL", city: "BRUSSELS" },
  "62014": { state: "IL", city: "BUNKER HILL" },
  "62015": { state: "IL", city: "BUTLER" },
  "62016": { state: "IL", city: "CARROLLTON" },
  "62017": { state: "IL", city: "COFFEEN" },
  "62018": { state: "IL", city: "COTTAGE HILLS" },
  "62019": { state: "IL", city: "DONNELLSON" },
  "62021": { state: "IL", city: "DORSEY" },
  "62022": { state: "IL", city: "DOW" },
  "62023": { state: "IL", city: "EAGARVILLE" },
  "62024": { state: "IL", city: "EAST ALTON" },
  "62025": { state: "IL", city: "EDWARDSVILLE" },
  "62026": { state: "IL", city: "EDWARDSVILLE" },
  "62027": { state: "IL", city: "ELDRED" },
  "62028": { state: "IL", city: "ELSAH" },
  "62030": { state: "IL", city: "FIDELITY" },
  "62031": { state: "IL", city: "FIELDON" },
  "62032": { state: "IL", city: "FILLMORE" },
  "62033": { state: "IL", city: "GILLESPIE" },
  "62034": { state: "IL", city: "GLEN CARBON" },
  "62035": { state: "IL", city: "GODFREY" },
  "62036": { state: "IL", city: "GOLDEN EAGLE" },
  "62037": { state: "IL", city: "GRAFTON" },
  "62040": { state: "IL", city: "GRANITE CITY" },
  "62044": { state: "IL", city: "GREENFIELD" },
  "62045": { state: "IL", city: "HAMBURG" },
  "62046": { state: "IL", city: "HAMEL" },
  "62047": { state: "IL", city: "HARDIN" },
  "62048": { state: "IL", city: "HARTFORD" },
  "62049": { state: "IL", city: "HILLSBORO" },
  "62050": { state: "IL", city: "HILLVIEW" },
  "62051": { state: "IL", city: "IRVING" },
  "62052": { state: "IL", city: "JERSEYVILLE" },
  "62053": { state: "IL", city: "KAMPSVILLE" },
  "62054": { state: "IL", city: "KANE" },
  "62056": { state: "IL", city: "LITCHFIELD" },
  "62058": { state: "IL", city: "LIVINGSTON" },
  "62059": { state: "IL", city: "LOVEJOY" },
  "62060": { state: "IL", city: "MADISON" },
  "62061": { state: "IL", city: "MARINE" },
  "62062": { state: "IL", city: "MARYVILLE" },
  "62063": { state: "IL", city: "MEDORA" },
  "62065": { state: "IL", city: "MICHAEL" },
  "62067": { state: "IL", city: "MORO" },
  "62069": { state: "IL", city: "MOUNT OLIVE" },
  "62070": { state: "IL", city: "MOZIER" },
  "62071": { state: "IL", city: "NATIONAL STOCK YARDS" },
  "62074": { state: "IL", city: "NEW DOUGLAS" },
  "62075": { state: "IL", city: "NOKOMIS" },
  "62076": { state: "IL", city: "OHLMAN" },
  "62077": { state: "IL", city: "PANAMA" },
  "62078": { state: "IL", city: "PATTERSON" },
  "62079": { state: "IL", city: "PIASA" },
  "62080": { state: "IL", city: "RAMSEY" },
  "62081": { state: "IL", city: "ROCKBRIDGE" },
  "62082": { state: "IL", city: "ROODHOUSE" },
  "62083": { state: "IL", city: "ROSAMOND" },
  "62084": { state: "IL", city: "ROXANA" },
  "62085": { state: "IL", city: "SAWYERVILLE" },
  "62086": { state: "IL", city: "SORENTO" },
  "62087": { state: "IL", city: "SOUTH ROXANA" },
  "62088": { state: "IL", city: "STAUNTON" },
  "62089": { state: "IL", city: "TAYLOR SPRINGS" },
  "62090": { state: "IL", city: "VENICE" },
  "62091": { state: "IL", city: "WALSHVILLE" },
  "62092": { state: "IL", city: "WHITE HALL" },
  "62093": { state: "IL", city: "WILSONVILLE" },
  "62094": { state: "IL", city: "WITT" },
  "62095": { state: "IL", city: "WOOD RIVER" },
  "62097": { state: "IL", city: "WORDEN" },
  "62098": { state: "IL", city: "WRIGHTS" },
  "62201": { state: "IL", city: "EAST SAINT LOUIS" },
  "62202": { state: "IL", city: "EAST SAINT LOUIS" },
  "62203": { state: "IL", city: "EAST SAINT LOUIS" },
  "62204": { state: "IL", city: "EAST SAINT LOUIS" },
  "62205": { state: "IL", city: "EAST SAINT LOUIS" },
  "62206": { state: "IL", city: "EAST SAINT LOUIS" },
  "62207": { state: "IL", city: "EAST SAINT LOUIS" },
  "62208": { state: "IL", city: "FAIRVIEW HEIGHTS" },
  "62214": { state: "IL", city: "ADDIEVILLE" },
  "62215": { state: "IL", city: "ALBERS" },
  "62216": { state: "IL", city: "AVISTON" },
  "62217": { state: "IL", city: "BALDWIN" },
  "62218": { state: "IL", city: "BARTELSO" },
  "62219": { state: "IL", city: "BECKEMEYER" },
  "62220": { state: "IL", city: "BELLEVILLE" },
  "62221": { state: "IL", city: "BELLEVILLE" },
  "62222": { state: "IL", city: "BELLEVILLE" },
  "62223": { state: "IL", city: "BELLEVILLE" },
  "62225": { state: "IL", city: "SCOTT AIR FORCE BASE" },
  "62226": { state: "IL", city: "BELLEVILLE" },
  "62230": { state: "IL", city: "BREESE" },
  "62231": { state: "IL", city: "CARLYLE" },
  "62232": { state: "IL", city: "CASEYVILLE" },
  "62233": { state: "IL", city: "CHESTER" },
  "62234": { state: "IL", city: "COLLINSVILLE" },
  "62236": { state: "IL", city: "COLUMBIA" },
  "62237": { state: "IL", city: "COULTERVILLE" },
  "62238": { state: "IL", city: "CUTLER" },
  "62239": { state: "IL", city: "DUPO" },
  "62240": { state: "IL", city: "EAST CARONDELET" },
  "62241": { state: "IL", city: "ELLIS GROVE" },
  "62242": { state: "IL", city: "EVANSVILLE" },
  "62243": { state: "IL", city: "FREEBURG" },
  "62244": { state: "IL", city: "FULTS" },
  "62245": { state: "IL", city: "GERMANTOWN" },
  "62246": { state: "IL", city: "GREENVILLE" },
  "62248": { state: "IL", city: "HECKER" },
  "62249": { state: "IL", city: "HIGHLAND" },
  "62250": { state: "IL", city: "HOFFMAN" },
  "62252": { state: "IL", city: "HUEY" },
  "62253": { state: "IL", city: "KEYESPORT" },
  "62254": { state: "IL", city: "LEBANON" },
  "62255": { state: "IL", city: "LENZBURG" },
  "62256": { state: "IL", city: "MAEYSTOWN" },
  "62257": { state: "IL", city: "MARISSA" },
  "62258": { state: "IL", city: "MASCOUTAH" },
  "62259": { state: "IL", city: "MENARD" },
  "62260": { state: "IL", city: "MILLSTADT" },
  "62261": { state: "IL", city: "MODOC" },
  "62262": { state: "IL", city: "MULBERRY GROVE" },
  "62263": { state: "IL", city: "NASHVILLE" },
  "62264": { state: "IL", city: "NEW ATHENS" },
  "62265": { state: "IL", city: "NEW BADEN" },
  "62266": { state: "IL", city: "NEW MEMPHIS" },
  "62268": { state: "IL", city: "OAKDALE" },
  "62269": { state: "IL", city: "O FALLON" },
  "62271": { state: "IL", city: "OKAWVILLE" },
  "62272": { state: "IL", city: "PERCY" },
  "62273": { state: "IL", city: "PIERRON" },
  "62274": { state: "IL", city: "PINCKNEYVILLE" },
  "62275": { state: "IL", city: "POCAHONTAS" },
  "62277": { state: "IL", city: "PRAIRIE DU ROCHER" },
  "62278": { state: "IL", city: "RED BUD" },
  "62279": { state: "IL", city: "RENAULT" },
  "62280": { state: "IL", city: "ROCKWOOD" },
  "62281": { state: "IL", city: "SAINT JACOB" },
  "62282": { state: "IL", city: "SAINT LIBORY" },
  "62284": { state: "IL", city: "SMITHBORO" },
  "62285": { state: "IL", city: "SMITHTON" },
  "62286": { state: "IL", city: "SPARTA" },
  "62288": { state: "IL", city: "STEELEVILLE" },
  "62289": { state: "IL", city: "SUMMERFIELD" },
  "62292": { state: "IL", city: "TILDEN" },
  "62293": { state: "IL", city: "TRENTON" },
  "62294": { state: "IL", city: "TROY" },
  "62295": { state: "IL", city: "VALMEYER" },
  "62297": { state: "IL", city: "WALSH" },
  "62298": { state: "IL", city: "WATERLOO" },
  "62301": { state: "IL", city: "QUINCY" },
  "62305": { state: "IL", city: "QUINCY" },
  "62306": { state: "IL", city: "QUINCY" },
  "62311": { state: "IL", city: "AUGUSTA" },
  "62312": { state: "IL", city: "BARRY" },
  "62313": { state: "IL", city: "BASCO" },
  "62314": { state: "IL", city: "BAYLIS" },
  "62316": { state: "IL", city: "BOWEN" },
  "62319": { state: "IL", city: "CAMDEN" },
  "62320": { state: "IL", city: "CAMP POINT" },
  "62321": { state: "IL", city: "CARTHAGE" },
  "62323": { state: "IL", city: "CHAMBERSBURG" },
  "62324": { state: "IL", city: "CLAYTON" },
  "62325": { state: "IL", city: "COATSBURG" },
  "62326": { state: "IL", city: "COLCHESTER" },
  "62329": { state: "IL", city: "COLUSA" },
  "62330": { state: "IL", city: "DALLAS CITY" },
  "62334": { state: "IL", city: "ELVASTON" },
  "62336": { state: "IL", city: "FERRIS" },
  "62338": { state: "IL", city: "FOWLER" },
  "62339": { state: "IL", city: "GOLDEN" },
  "62340": { state: "IL", city: "GRIGGSVILLE" },
  "62341": { state: "IL", city: "HAMILTON" },
  "62343": { state: "IL", city: "HULL" },
  "62344": { state: "IL", city: "HUNTSVILLE" },
  "62345": { state: "IL", city: "KINDERHOOK" },
  "62346": { state: "IL", city: "LA PRAIRIE" },
  "62347": { state: "IL", city: "LIBERTY" },
  "62348": { state: "IL", city: "LIMA" },
  "62349": { state: "IL", city: "LORAINE" },
  "62351": { state: "IL", city: "MENDON" },
  "62352": { state: "IL", city: "MILTON" },
  "62353": { state: "IL", city: "MOUNT STERLING" },
  "62354": { state: "IL", city: "NAUVOO" },
  "62355": { state: "IL", city: "NEBO" },
  "62356": { state: "IL", city: "NEW CANTON" },
  "62357": { state: "IL", city: "NEW SALEM" },
  "62358": { state: "IL", city: "NIOTA" },
  "62359": { state: "IL", city: "PALOMA" },
  "62360": { state: "IL", city: "PAYSON" },
  "62361": { state: "IL", city: "PEARL" },
  "62362": { state: "IL", city: "PERRY" },
  "62363": { state: "IL", city: "PITTSFIELD" },
  "62365": { state: "IL", city: "PLAINVILLE" },
  "62366": { state: "IL", city: "PLEASANT HILL" },
  "62367": { state: "IL", city: "PLYMOUTH" },
  "62370": { state: "IL", city: "ROCKPORT" },
  "62373": { state: "IL", city: "SUTTER" },
  "62374": { state: "IL", city: "TENNESSEE" },
  "62375": { state: "IL", city: "TIMEWELL" },
  "62376": { state: "IL", city: "URSA" },
  "62378": { state: "IL", city: "VERSAILLES" },
  "62379": { state: "IL", city: "WARSAW" },
  "62380": { state: "IL", city: "WEST POINT" },
  "62401": { state: "IL", city: "EFFINGHAM" },
  "62410": { state: "IL", city: "ALLENDALE" },
  "62411": { state: "IL", city: "ALTAMONT" },
  "62413": { state: "IL", city: "ANNAPOLIS" },
  "62414": { state: "IL", city: "BEECHER CITY" },
  "62417": { state: "IL", city: "BRIDGEPORT" },
  "62418": { state: "IL", city: "BROWNSTOWN" },
  "62419": { state: "IL", city: "CALHOUN" },
  "62420": { state: "IL", city: "CASEY" },
  "62421": { state: "IL", city: "CLAREMONT" },
  "62422": { state: "IL", city: "COWDEN" },
  "62423": { state: "IL", city: "DENNISON" },
  "62424": { state: "IL", city: "DIETERICH" },
  "62425": { state: "IL", city: "DUNDAS" },
  "62426": { state: "IL", city: "EDGEWOOD" },
  "62427": { state: "IL", city: "FLAT ROCK" },
  "62428": { state: "IL", city: "GREENUP" },
  "62431": { state: "IL", city: "HERRICK" },
  "62432": { state: "IL", city: "HIDALGO" },
  "62433": { state: "IL", city: "HUTSONVILLE" },
  "62434": { state: "IL", city: "INGRAHAM" },
  "62436": { state: "IL", city: "JEWETT" },
  "62438": { state: "IL", city: "LAKEWOOD" },
  "62439": { state: "IL", city: "LAWRENCEVILLE" },
  "62440": { state: "IL", city: "LERNA" },
  "62441": { state: "IL", city: "MARSHALL" },
  "62442": { state: "IL", city: "MARTINSVILLE" },
  "62443": { state: "IL", city: "MASON" },
  "62444": { state: "IL", city: "MODE" },
  "62445": { state: "IL", city: "MONTROSE" },
  "62446": { state: "IL", city: "MOUNT ERIE" },
  "62447": { state: "IL", city: "NEOGA" },
  "62448": { state: "IL", city: "NEWTON" },
  "62449": { state: "IL", city: "OBLONG" },
  "62450": { state: "IL", city: "OLNEY" },
  "62451": { state: "IL", city: "PALESTINE" },
  "62452": { state: "IL", city: "PARKERSBURG" },
  "62454": { state: "IL", city: "ROBINSON" },
  "62458": { state: "IL", city: "SAINT ELMO" },
  "62459": { state: "IL", city: "SAINTE MARIE" },
  "62460": { state: "IL", city: "SAINT FRANCISVILLE" },
  "62461": { state: "IL", city: "SHUMWAY" },
  "62462": { state: "IL", city: "SIGEL" },
  "62463": { state: "IL", city: "STEWARDSON" },
  "62464": { state: "IL", city: "STOY" },
  "62465": { state: "IL", city: "STRASBURG" },
  "62466": { state: "IL", city: "SUMNER" },
  "62467": { state: "IL", city: "TEUTOPOLIS" },
  "62468": { state: "IL", city: "TOLEDO" },
  "62469": { state: "IL", city: "TRILLA" },
  "62471": { state: "IL", city: "VANDALIA" },
  "62473": { state: "IL", city: "WATSON" },
  "62474": { state: "IL", city: "WESTFIELD" },
  "62475": { state: "IL", city: "WEST LIBERTY" },
  "62476": { state: "IL", city: "WEST SALEM" },
  "62477": { state: "IL", city: "WEST UNION" },
  "62478": { state: "IL", city: "WEST YORK" },
  "62479": { state: "IL", city: "WHEELER" },
  "62480": { state: "IL", city: "WILLOW HILL" },
  "62481": { state: "IL", city: "YALE" },
  "62501": { state: "IL", city: "ARGENTA" },
  "62510": { state: "IL", city: "ASSUMPTION" },
  "62512": { state: "IL", city: "BEASON" },
  "62513": { state: "IL", city: "BLUE MOUND" },
  "62514": { state: "IL", city: "BOODY" },
  "62515": { state: "IL", city: "BUFFALO" },
  "62517": { state: "IL", city: "BULPITT" },
  "62518": { state: "IL", city: "CHESTNUT" },
  "62519": { state: "IL", city: "CORNLAND" },
  "62520": { state: "IL", city: "DAWSON" },
  "62521": { state: "IL", city: "DECATUR" },
  "62522": { state: "IL", city: "DECATUR" },
  "62523": { state: "IL", city: "DECATUR" },
  "62524": { state: "IL", city: "DECATUR" },
  "62525": { state: "IL", city: "DECATUR" },
  "62526": { state: "IL", city: "DECATUR" },
  "62530": { state: "IL", city: "DIVERNON" },
  "62531": { state: "IL", city: "EDINBURG" },
  "62532": { state: "IL", city: "ELWIN" },
  "62533": { state: "IL", city: "FARMERSVILLE" },
  "62534": { state: "IL", city: "FINDLAY" },
  "62535": { state: "IL", city: "FORSYTH" },
  "62536": { state: "IL", city: "GLENARM" },
  "62537": { state: "IL", city: "HARRISTOWN" },
  "62538": { state: "IL", city: "HARVEL" },
  "62539": { state: "IL", city: "ILLIOPOLIS" },
  "62540": { state: "IL", city: "KINCAID" },
  "62541": { state: "IL", city: "LAKE FORK" },
  "62543": { state: "IL", city: "LATHAM" },
  "62544": { state: "IL", city: "MACON" },
  "62545": { state: "IL", city: "MECHANICSBURG" },
  "62546": { state: "IL", city: "MORRISONVILLE" },
  "62547": { state: "IL", city: "MOUNT AUBURN" },
  "62548": { state: "IL", city: "MOUNT PULASKI" },
  "62549": { state: "IL", city: "MT ZION" },
  "62550": { state: "IL", city: "MOWEAQUA" },
  "62551": { state: "IL", city: "NIANTIC" },
  "62553": { state: "IL", city: "OCONEE" },
  "62554": { state: "IL", city: "OREANA" },
  "62555": { state: "IL", city: "OWANECO" },
  "62556": { state: "IL", city: "PALMER" },
  "62557": { state: "IL", city: "PANA" },
  "62558": { state: "IL", city: "PAWNEE" },
  "62560": { state: "IL", city: "RAYMOND" },
  "62561": { state: "IL", city: "RIVERTON" },
  "62563": { state: "IL", city: "ROCHESTER" },
  "62565": { state: "IL", city: "SHELBYVILLE" },
  "62567": { state: "IL", city: "STONINGTON" },
  "62568": { state: "IL", city: "TAYLORVILLE" },
  "62570": { state: "IL", city: "TOVEY" },
  "62571": { state: "IL", city: "TOWER HILL" },
  "62572": { state: "IL", city: "WAGGONER" },
  "62573": { state: "IL", city: "WARRENSBURG" },
  "62601": { state: "IL", city: "ALEXANDER" },
  "62610": { state: "IL", city: "ALSEY" },
  "62611": { state: "IL", city: "ARENZVILLE" },
  "62612": { state: "IL", city: "ASHLAND" },
  "62613": { state: "IL", city: "ATHENS" },
  "62615": { state: "IL", city: "AUBURN" },
  "62617": { state: "IL", city: "BATH" },
  "62618": { state: "IL", city: "BEARDSTOWN" },
  "62621": { state: "IL", city: "BLUFFS" },
  "62622": { state: "IL", city: "BLUFF SPRINGS" },
  "62624": { state: "IL", city: "BROWNING" },
  "62625": { state: "IL", city: "CANTRALL" },
  "62626": { state: "IL", city: "CARLINVILLE" },
  "62627": { state: "IL", city: "CHANDLERVILLE" },
  "62628": { state: "IL", city: "CHAPIN" },
  "62629": { state: "IL", city: "CHATHAM" },
  "62630": { state: "IL", city: "CHESTERFIELD" },
  "62631": { state: "IL", city: "CONCORD" },
  "62633": { state: "IL", city: "EASTON" },
  "62634": { state: "IL", city: "ELKHART" },
  "62635": { state: "IL", city: "EMDEN" },
  "62638": { state: "IL", city: "FRANKLIN" },
  "62639": { state: "IL", city: "FREDERICK" },
  "62640": { state: "IL", city: "GIRARD" },
  "62642": { state: "IL", city: "GREENVIEW" },
  "62643": { state: "IL", city: "HARTSBURG" },
  "62644": { state: "IL", city: "HAVANA" },
  "62649": { state: "IL", city: "HETTICK" },
  "62650": { state: "IL", city: "JACKSONVILLE" },
  "62651": { state: "IL", city: "JACKSONVILLE" },
  "62655": { state: "IL", city: "KILBOURNE" },
  "62656": { state: "IL", city: "LINCOLN" },
  "62659": { state: "IL", city: "LINCOLNS NEW SALEM" },
  "62660": { state: "IL", city: "LITERBERRY" },
  "62661": { state: "IL", city: "LOAMI" },
  "62662": { state: "IL", city: "LOWDER" },
  "62663": { state: "IL", city: "MANCHESTER" },
  "62664": { state: "IL", city: "MASON CITY" },
  "62665": { state: "IL", city: "MEREDOSIA" },
  "62666": { state: "IL", city: "MIDDLETOWN" },
  "62667": { state: "IL", city: "MODESTO" },
  "62668": { state: "IL", city: "MURRAYVILLE" },
  "62670": { state: "IL", city: "NEW BERLIN" },
  "62671": { state: "IL", city: "NEW HOLLAND" },
  "62672": { state: "IL", city: "NILWOOD" },
  "62673": { state: "IL", city: "OAKFORD" },
  "62674": { state: "IL", city: "PALMYRA" },
  "62675": { state: "IL", city: "PETERSBURG" },
  "62677": { state: "IL", city: "PLEASANT PLAINS" },
  "62681": { state: "IL", city: "RUSHVILLE" },
  "62682": { state: "IL", city: "SAN JOSE" },
  "62683": { state: "IL", city: "SCOTTVILLE" },
  "62684": { state: "IL", city: "SHERMAN" },
  "62685": { state: "IL", city: "SHIPMAN" },
  "62688": { state: "IL", city: "TALLULA" },
  "62689": { state: "IL", city: "THAYER" },
  "62690": { state: "IL", city: "VIRDEN" },
  "62691": { state: "IL", city: "VIRGINIA" },
  "62692": { state: "IL", city: "WAVERLY" },
  "62693": { state: "IL", city: "WILLIAMSVILLE" },
  "62694": { state: "IL", city: "WINCHESTER" },
  "62695": { state: "IL", city: "WOODSON" },
  "62701": { state: "IL", city: "SPRINGFIELD" },
  "62702": { state: "IL", city: "SPRINGFIELD" },
  "62703": { state: "IL", city: "SPRINGFIELD" },
  "62704": { state: "IL", city: "SPRINGFIELD" },
  "62705": { state: "IL", city: "SPRINGFIELD" },
  "62706": { state: "IL", city: "SPRINGFIELD" },
  "62707": { state: "IL", city: "SPRINGFIELD" },
  "62708": { state: "IL", city: "SPRINGFIELD" },
  "62711": { state: "IL", city: "SPRINGFIELD" },
  "62712": { state: "IL", city: "SPRINGFIELD" },
  "62715": { state: "IL", city: "SPRINGFIELD" },
  "62716": { state: "IL", city: "SPRINGFIELD" },
  "62719": { state: "IL", city: "SPRINGFIELD" },
  "62722": { state: "IL", city: "SPRINGFIELD" },
  "62723": { state: "IL", city: "SPRINGFIELD" },
  "62726": { state: "IL", city: "SPRINGFIELD" },
  "62736": { state: "IL", city: "SPRINGFIELD" },
  "62739": { state: "IL", city: "SPRINGFIELD" },
  "62756": { state: "IL", city: "SPRINGFIELD" },
  "62757": { state: "IL", city: "SPRINGFIELD" },
  "62761": { state: "IL", city: "SPRINGFIELD" },
  "62762": { state: "IL", city: "SPRINGFIELD" },
  "62763": { state: "IL", city: "SPRINGFIELD" },
  "62764": { state: "IL", city: "SPRINGFIELD" },
  "62765": { state: "IL", city: "SPRINGFIELD" },
  "62766": { state: "IL", city: "SPRINGFIELD" },
  "62767": { state: "IL", city: "SPRINGFIELD" },
  "62769": { state: "IL", city: "SPRINGFIELD" },
  "62776": { state: "IL", city: "SPRINGFIELD" },
  "62777": { state: "IL", city: "SPRINGFIELD" },
  "62781": { state: "IL", city: "SPRINGFIELD" },
  "62786": { state: "IL", city: "SPRINGFIELD" },
  "62791": { state: "IL", city: "SPRINGFIELD" },
  "62794": { state: "IL", city: "SPRINGFIELD" },
  "62796": { state: "IL", city: "SPRINGFIELD" },
  "62801": { state: "IL", city: "CENTRALIA" },
  "62803": { state: "IL", city: "HOYLETON" },
  "62806": { state: "IL", city: "ALBION" },
  "62807": { state: "IL", city: "ALMA" },
  "62808": { state: "IL", city: "ASHLEY" },
  "62809": { state: "IL", city: "BARNHILL" },
  "62810": { state: "IL", city: "BELLE RIVE" },
  "62811": { state: "IL", city: "BELLMONT" },
  "62812": { state: "IL", city: "BENTON" },
  "62814": { state: "IL", city: "BLUFORD" },
  "62815": { state: "IL", city: "BONE GAP" },
  "62816": { state: "IL", city: "BONNIE" },
  "62817": { state: "IL", city: "BROUGHTON" },
  "62818": { state: "IL", city: "BROWNS" },
  "62819": { state: "IL", city: "BUCKNER" },
  "62820": { state: "IL", city: "BURNT PRAIRIE" },
  "62821": { state: "IL", city: "CARMI" },
  "62822": { state: "IL", city: "CHRISTOPHER" },
  "62823": { state: "IL", city: "CISNE" },
  "62824": { state: "IL", city: "CLAY CITY" },
  "62825": { state: "IL", city: "COELLO" },
  "62827": { state: "IL", city: "CROSSVILLE" },
  "62828": { state: "IL", city: "DAHLGREN" },
  "62829": { state: "IL", city: "DALE" },
  "62830": { state: "IL", city: "DIX" },
  "62831": { state: "IL", city: "DU BOIS" },
  "62832": { state: "IL", city: "DU QUOIN" },
  "62833": { state: "IL", city: "ELLERY" },
  "62834": { state: "IL", city: "EMMA" },
  "62835": { state: "IL", city: "ENFIELD" },
  "62836": { state: "IL", city: "EWING" },
  "62837": { state: "IL", city: "FAIRFIELD" },
  "62838": { state: "IL", city: "FARINA" },
  "62839": { state: "IL", city: "FLORA" },
  "62840": { state: "IL", city: "FRANKFORT HEIGHTS" },
  "62841": { state: "IL", city: "FREEMAN SPUR" },
  "62842": { state: "IL", city: "GEFF" },
  "62843": { state: "IL", city: "GOLDEN GATE" },
  "62844": { state: "IL", city: "GRAYVILLE" },
  "62846": { state: "IL", city: "INA" },
  "62848": { state: "IL", city: "IRVINGTON" },
  "62849": { state: "IL", city: "IUKA" },
  "62850": { state: "IL", city: "JOHNSONVILLE" },
  "62851": { state: "IL", city: "KEENES" },
  "62852": { state: "IL", city: "KEENSBURG" },
  "62853": { state: "IL", city: "KELL" },
  "62854": { state: "IL", city: "KINMUNDY" },
  "62856": { state: "IL", city: "LOGAN" },
  "62858": { state: "IL", city: "LOUISVILLE" },
  "62859": { state: "IL", city: "MC LEANSBORO" },
  "62860": { state: "IL", city: "MACEDONIA" },
  "62861": { state: "IL", city: "MAUNIE" },
  "62862": { state: "IL", city: "MILL SHOALS" },
  "62863": { state: "IL", city: "MOUNT CARMEL" },
  "62864": { state: "IL", city: "MOUNT VERNON" },
  "62865": { state: "IL", city: "MULKEYTOWN" },
  "62866": { state: "IL", city: "NASON" },
  "62867": { state: "IL", city: "NEW HAVEN" },
  "62868": { state: "IL", city: "NOBLE" },
  "62869": { state: "IL", city: "NORRIS CITY" },
  "62870": { state: "IL", city: "ODIN" },
  "62871": { state: "IL", city: "OMAHA" },
  "62872": { state: "IL", city: "OPDYKE" },
  "62874": { state: "IL", city: "ORIENT" },
  "62875": { state: "IL", city: "PATOKA" },
  "62876": { state: "IL", city: "RADOM" },
  "62877": { state: "IL", city: "RICHVIEW" },
  "62878": { state: "IL", city: "RINARD" },
  "62879": { state: "IL", city: "SAILOR SPRINGS" },
  "62880": { state: "IL", city: "SAINT PETER" },
  "62881": { state: "IL", city: "SALEM" },
  "62882": { state: "IL", city: "SANDOVAL" },
  "62883": { state: "IL", city: "SCHELLER" },
  "62884": { state: "IL", city: "SESSER" },
  "62885": { state: "IL", city: "SHOBONIER" },
  "62886": { state: "IL", city: "SIMS" },
  "62887": { state: "IL", city: "SPRINGERTON" },
  "62888": { state: "IL", city: "TAMAROA" },
  "62889": { state: "IL", city: "TEXICO" },
  "62890": { state: "IL", city: "THOMPSONVILLE" },
  "62891": { state: "IL", city: "VALIER" },
  "62892": { state: "IL", city: "VERNON" },
  "62893": { state: "IL", city: "WALNUT HILL" },
  "62894": { state: "IL", city: "WALTONVILLE" },
  "62895": { state: "IL", city: "WAYNE CITY" },
  "62896": { state: "IL", city: "WEST FRANKFORT" },
  "62897": { state: "IL", city: "WHITTINGTON" },
  "62898": { state: "IL", city: "WOODLAWN" },
  "62899": { state: "IL", city: "XENIA" },
  "62901": { state: "IL", city: "CARBONDALE" },
  "62902": { state: "IL", city: "CARBONDALE" },
  "62903": { state: "IL", city: "CARBONDALE" },
  "62905": { state: "IL", city: "ALTO PASS" },
  "62906": { state: "IL", city: "ANNA" },
  "62907": { state: "IL", city: "AVA" },
  "62908": { state: "IL", city: "BELKNAP" },
  "62909": { state: "IL", city: "BOLES" },
  "62910": { state: "IL", city: "BROOKPORT" },
  "62912": { state: "IL", city: "BUNCOMBE" },
  "62914": { state: "IL", city: "CAIRO" },
  "62915": { state: "IL", city: "CAMBRIA" },
  "62916": { state: "IL", city: "CAMPBELL HILL" },
  "62917": { state: "IL", city: "CARRIER MILLS" },
  "62918": { state: "IL", city: "CARTERVILLE" },
  "62919": { state: "IL", city: "CAVE IN ROCK" },
  "62920": { state: "IL", city: "COBDEN" },
  "62921": { state: "IL", city: "COLP" },
  "62922": { state: "IL", city: "CREAL SPRINGS" },
  "62923": { state: "IL", city: "CYPRESS" },
  "62924": { state: "IL", city: "DE SOTO" },
  "62926": { state: "IL", city: "DONGOLA" },
  "62927": { state: "IL", city: "DOWELL" },
  "62928": { state: "IL", city: "EDDYVILLE" },
  "62930": { state: "IL", city: "ELDORADO" },
  "62931": { state: "IL", city: "ELIZABETHTOWN" },
  "62932": { state: "IL", city: "ELKVILLE" },
  "62933": { state: "IL", city: "ENERGY" },
  "62934": { state: "IL", city: "EQUALITY" },
  "62935": { state: "IL", city: "GALATIA" },
  "62938": { state: "IL", city: "GOLCONDA" },
  "62939": { state: "IL", city: "GOREVILLE" },
  "62940": { state: "IL", city: "GORHAM" },
  "62941": { state: "IL", city: "GRAND CHAIN" },
  "62942": { state: "IL", city: "GRAND TOWER" },
  "62943": { state: "IL", city: "GRANTSBURG" },
  "62946": { state: "IL", city: "HARRISBURG" },
  "62947": { state: "IL", city: "HEROD" },
  "62948": { state: "IL", city: "HERRIN" },
  "62949": { state: "IL", city: "HURST" },
  "62950": { state: "IL", city: "JACOB" },
  "62951": { state: "IL", city: "JOHNSTON CITY" },
  "62952": { state: "IL", city: "JONESBORO" },
  "62953": { state: "IL", city: "JOPPA" },
  "62954": { state: "IL", city: "JUNCTION" },
  "62956": { state: "IL", city: "KARNAK" },
  "62957": { state: "IL", city: "MC CLURE" },
  "62958": { state: "IL", city: "MAKANDA" },
  "62959": { state: "IL", city: "MARION" },
  "62960": { state: "IL", city: "METROPOLIS" },
  "62961": { state: "IL", city: "MILLCREEK" },
  "62962": { state: "IL", city: "MILLER CITY" },
  "62963": { state: "IL", city: "MOUND CITY" },
  "62964": { state: "IL", city: "MOUNDS" },
  "62965": { state: "IL", city: "MUDDY" },
  "62966": { state: "IL", city: "MURPHYSBORO" },
  "62967": { state: "IL", city: "NEW BURNSIDE" },
  "62969": { state: "IL", city: "OLIVE BRANCH" },
  "62970": { state: "IL", city: "OLMSTED" },
  "62972": { state: "IL", city: "OZARK" },
  "62973": { state: "IL", city: "PERKS" },
  "62974": { state: "IL", city: "PITTSBURG" },
  "62975": { state: "IL", city: "POMONA" },
  "62976": { state: "IL", city: "PULASKI" },
  "62977": { state: "IL", city: "RALEIGH" },
  "62979": { state: "IL", city: "RIDGWAY" },
  "62982": { state: "IL", city: "ROSICLARE" },
  "62983": { state: "IL", city: "ROYALTON" },
  "62984": { state: "IL", city: "SHAWNEETOWN" },
  "62985": { state: "IL", city: "SIMPSON" },
  "62987": { state: "IL", city: "STONEFORT" },
  "62988": { state: "IL", city: "TAMMS" },
  "62990": { state: "IL", city: "THEBES" },
  "62992": { state: "IL", city: "ULLIN" },
  "62993": { state: "IL", city: "UNITY" },
  "62994": { state: "IL", city: "VERGENNES" },
  "62995": { state: "IL", city: "VIENNA" },
  "62996": { state: "IL", city: "VILLA RIDGE" },
  "62997": { state: "IL", city: "WILLISVILLE" },
  "62998": { state: "IL", city: "WOLF LAKE" },
  "62999": { state: "IL", city: "ZEIGLER" },
  "63005": { state: "MO", city: "CHESTERFIELD" },
  "63006": { state: "MO", city: "CHESTERFIELD" },
  "63010": { state: "MO", city: "ARNOLD" },
  "63011": { state: "MO", city: "BALLWIN" },
  "63012": { state: "MO", city: "BARNHART" },
  "63013": { state: "MO", city: "BEAUFORT" },
  "63014": { state: "MO", city: "BERGER" },
  "63015": { state: "MO", city: "CATAWISSA" },
  "63016": { state: "MO", city: "CEDAR HILL" },
  "63017": { state: "MO", city: "CHESTERFIELD" },
  "63019": { state: "MO", city: "CRYSTAL CITY" },
  "63020": { state: "MO", city: "DE SOTO" },
  "63021": { state: "MO", city: "BALLWIN" },
  "63022": { state: "MO", city: "BALLWIN" },
  "63023": { state: "MO", city: "DITTMER" },
  "63024": { state: "MO", city: "BALLWIN" },
  "63025": { state: "MO", city: "EUREKA" },
  "63026": { state: "MO", city: "FENTON" },
  "63028": { state: "MO", city: "FESTUS" },
  "63030": { state: "MO", city: "FLETCHER" },
  "63031": { state: "MO", city: "FLORISSANT" },
  "63032": { state: "MO", city: "FLORISSANT" },
  "63033": { state: "MO", city: "FLORISSANT" },
  "63034": { state: "MO", city: "FLORISSANT" },
  "63036": { state: "MO", city: "FRENCH VILLAGE" },
  "63037": { state: "MO", city: "GERALD" },
  "63038": { state: "MO", city: "WILDWOOD" },
  "63039": { state: "MO", city: "GRAY SUMMIT" },
  "63040": { state: "MO", city: "WILDWOOD" },
  "63041": { state: "MO", city: "GRUBVILLE" },
  "63042": { state: "MO", city: "HAZELWOOD" },
  "63043": { state: "MO", city: "MARYLAND HEIGHTS" },
  "63044": { state: "MO", city: "BRIDGETON" },
  "63045": { state: "MO", city: "EARTH CITY" },
  "63047": { state: "MO", city: "HEMATITE" },
  "63048": { state: "MO", city: "HERCULANEUM" },
  "63049": { state: "MO", city: "HIGH RIDGE" },
  "63050": { state: "MO", city: "HILLSBORO" },
  "63051": { state: "MO", city: "HOUSE SPRINGS" },
  "63052": { state: "MO", city: "IMPERIAL" },
  "63053": { state: "MO", city: "KIMMSWICK" },
  "63055": { state: "MO", city: "LABADIE" },
  "63056": { state: "MO", city: "LESLIE" },
  "63057": { state: "MO", city: "LIGUORI" },
  "63060": { state: "MO", city: "LONEDELL" },
  "63061": { state: "MO", city: "LUEBBERING" },
  "63065": { state: "MO", city: "MAPAVILLE" },
  "63066": { state: "MO", city: "MORSE MILL" },
  "63068": { state: "MO", city: "NEW HAVEN" },
  "63069": { state: "MO", city: "PACIFIC" },
  "63070": { state: "MO", city: "PEVELY" },
  "63071": { state: "MO", city: "RICHWOODS" },
  "63072": { state: "MO", city: "ROBERTSVILLE" },
  "63073": { state: "MO", city: "SAINT ALBANS" },
  "63074": { state: "MO", city: "SAINT ANN" },
  "63077": { state: "MO", city: "SAINT CLAIR" },
  "63079": { state: "MO", city: "STANTON" },
  "63080": { state: "MO", city: "SULLIVAN" },
  "63084": { state: "MO", city: "UNION" },
  "63087": { state: "MO", city: "VALLES MINES" },
  "63088": { state: "MO", city: "VALLEY PARK" },
  "63089": { state: "MO", city: "VILLA RIDGE" },
  "63090": { state: "MO", city: "WASHINGTON" },
  "63091": { state: "MO", city: "ROSEBUD" },
  "63099": { state: "MO", city: "FENTON" },
  "63101": { state: "MO", city: "SAINT LOUIS" },
  "63102": { state: "MO", city: "SAINT LOUIS" },
  "63103": { state: "MO", city: "SAINT LOUIS" },
  "63104": { state: "MO", city: "SAINT LOUIS" },
  "63105": { state: "MO", city: "SAINT LOUIS" },
  "63106": { state: "MO", city: "SAINT LOUIS" },
  "63107": { state: "MO", city: "SAINT LOUIS" },
  "63108": { state: "MO", city: "SAINT LOUIS" },
  "63109": { state: "MO", city: "SAINT LOUIS" },
  "63110": { state: "MO", city: "SAINT LOUIS" },
  "63111": { state: "MO", city: "SAINT LOUIS" },
  "63112": { state: "MO", city: "SAINT LOUIS" },
  "63113": { state: "MO", city: "SAINT LOUIS" },
  "63114": { state: "MO", city: "SAINT LOUIS" },
  "63115": { state: "MO", city: "SAINT LOUIS" },
  "63116": { state: "MO", city: "SAINT LOUIS" },
  "63117": { state: "MO", city: "SAINT LOUIS" },
  "63118": { state: "MO", city: "SAINT LOUIS" },
  "63119": { state: "MO", city: "SAINT LOUIS" },
  "63120": { state: "MO", city: "SAINT LOUIS" },
  "63121": { state: "MO", city: "SAINT LOUIS" },
  "63122": { state: "MO", city: "SAINT LOUIS" },
  "63123": { state: "MO", city: "SAINT LOUIS" },
  "63124": { state: "MO", city: "SAINT LOUIS" },
  "63125": { state: "MO", city: "SAINT LOUIS" },
  "63126": { state: "MO", city: "SAINT LOUIS" },
  "63127": { state: "MO", city: "SAINT LOUIS" },
  "63128": { state: "MO", city: "SAINT LOUIS" },
  "63129": { state: "MO", city: "SAINT LOUIS" },
  "63130": { state: "MO", city: "SAINT LOUIS" },
  "63131": { state: "MO", city: "SAINT LOUIS" },
  "63132": { state: "MO", city: "SAINT LOUIS" },
  "63133": { state: "MO", city: "SAINT LOUIS" },
  "63134": { state: "MO", city: "SAINT LOUIS" },
  "63135": { state: "MO", city: "SAINT LOUIS" },
  "63136": { state: "MO", city: "SAINT LOUIS" },
  "63137": { state: "MO", city: "SAINT LOUIS" },
  "63138": { state: "MO", city: "SAINT LOUIS" },
  "63139": { state: "MO", city: "SAINT LOUIS" },
  "63140": { state: "MO", city: "SAINT LOUIS" },
  "63141": { state: "MO", city: "SAINT LOUIS" },
  "63143": { state: "MO", city: "SAINT LOUIS" },
  "63144": { state: "MO", city: "SAINT LOUIS" },
  "63145": { state: "MO", city: "SAINT LOUIS" },
  "63146": { state: "MO", city: "SAINT LOUIS" },
  "63147": { state: "MO", city: "SAINT LOUIS" },
  "63150": { state: "MO", city: "SAINT LOUIS" },
  "63151": { state: "MO", city: "SAINT LOUIS" },
  "63155": { state: "MO", city: "SAINT LOUIS" },
  "63156": { state: "MO", city: "SAINT LOUIS" },
  "63157": { state: "MO", city: "SAINT LOUIS" },
  "63158": { state: "MO", city: "SAINT LOUIS" },
  "63160": { state: "MO", city: "SAINT LOUIS" },
  "63163": { state: "MO", city: "SAINT LOUIS" },
  "63164": { state: "MO", city: "SAINT LOUIS" },
  "63166": { state: "MO", city: "SAINT LOUIS" },
  "63167": { state: "MO", city: "SAINT LOUIS" },
  "63169": { state: "MO", city: "SAINT LOUIS" },
  "63171": { state: "MO", city: "SAINT LOUIS" },
  "63177": { state: "MO", city: "SAINT LOUIS" },
  "63178": { state: "MO", city: "SAINT LOUIS" },
  "63179": { state: "MO", city: "SAINT LOUIS" },
  "63180": { state: "MO", city: "SAINT LOUIS" },
  "63188": { state: "MO", city: "SAINT LOUIS" },
  "63195": { state: "MO", city: "SAINT LOUIS" },
  "63197": { state: "MO", city: "SAINT LOUIS" },
  "63199": { state: "MO", city: "SAINT LOUIS" },
  "63301": { state: "MO", city: "SAINT CHARLES" },
  "63302": { state: "MO", city: "SAINT CHARLES" },
  "63303": { state: "MO", city: "SAINT CHARLES" },
  "63304": { state: "MO", city: "SAINT CHARLES" },
  "63330": { state: "MO", city: "ANNADA" },
  "63332": { state: "MO", city: "AUGUSTA" },
  "63333": { state: "MO", city: "BELLFLOWER" },
  "63334": { state: "MO", city: "BOWLING GREEN" },
  "63336": { state: "MO", city: "CLARKSVILLE" },
  "63338": { state: "MO", city: "COTTLEVILLE" },
  "63339": { state: "MO", city: "CURRYVILLE" },
  "63341": { state: "MO", city: "DEFIANCE" },
  "63342": { state: "MO", city: "DUTZOW" },
  "63343": { state: "MO", city: "ELSBERRY" },
  "63344": { state: "MO", city: "EOLIA" },
  "63345": { state: "MO", city: "FARBER" },
  "63346": { state: "MO", city: "FLINTHILL" },
  "63347": { state: "MO", city: "FOLEY" },
  "63348": { state: "MO", city: "FORISTELL" },
  "63349": { state: "MO", city: "HAWK POINT" },
  "63350": { state: "MO", city: "HIGH HILL" },
  "63351": { state: "MO", city: "JONESBURG" },
  "63352": { state: "MO", city: "LADDONIA" },
  "63353": { state: "MO", city: "LOUISIANA" },
  "63357": { state: "MO", city: "MARTHASVILLE" },
  "63359": { state: "MO", city: "MIDDLETOWN" },
  "63361": { state: "MO", city: "MONTGOMERY CITY" },
  "63362": { state: "MO", city: "MOSCOW MILLS" },
  "63363": { state: "MO", city: "NEW FLORENCE" },
  "63365": { state: "MO", city: "NEW MELLE" },
  "63366": { state: "MO", city: "O FALLON" },
  "63367": { state: "MO", city: "LAKE SAINT LOUIS" },
  "63368": { state: "MO", city: "O FALLON" },
  "63369": { state: "MO", city: "OLD MONROE" },
  "63370": { state: "MO", city: "OLNEY" },
  "63373": { state: "MO", city: "PORTAGE DES SIOUX" },
  "63376": { state: "MO", city: "SAINT PETERS" },
  "63377": { state: "MO", city: "SILEX" },
  "63378": { state: "MO", city: "TRELOAR" },
  "63379": { state: "MO", city: "TROY" },
  "63380": { state: "MO", city: "TRUESDALE" },
  "63381": { state: "MO", city: "TRUXTON" },
  "63382": { state: "MO", city: "VANDALIA" },
  "63383": { state: "MO", city: "WARRENTON" },
  "63384": { state: "MO", city: "WELLSVILLE" },
  "63385": { state: "MO", city: "WENTZVILLE" },
  "63386": { state: "MO", city: "WEST ALTON" },
  "63387": { state: "MO", city: "WHITESIDE" },
  "63388": { state: "MO", city: "WILLIAMSBURG" },
  "63389": { state: "MO", city: "WINFIELD" },
  "63390": { state: "MO", city: "WRIGHT CITY" },
  "63401": { state: "MO", city: "HANNIBAL" },
  "63430": { state: "MO", city: "ALEXANDRIA" },
  "63431": { state: "MO", city: "ANABEL" },
  "63432": { state: "MO", city: "ARBELA" },
  "63433": { state: "MO", city: "ASHBURN" },
  "63434": { state: "MO", city: "BETHEL" },
  "63435": { state: "MO", city: "CANTON" },
  "63436": { state: "MO", city: "CENTER" },
  "63437": { state: "MO", city: "CLARENCE" },
  "63438": { state: "MO", city: "DURHAM" },
  "63439": { state: "MO", city: "EMDEN" },
  "63440": { state: "MO", city: "EWING" },
  "63441": { state: "MO", city: "FRANKFORD" },
  "63442": { state: "MO", city: "GRANGER" },
  "63443": { state: "MO", city: "HUNNEWELL" },
  "63445": { state: "MO", city: "KAHOKA" },
  "63446": { state: "MO", city: "KNOX CITY" },
  "63447": { state: "MO", city: "LA BELLE" },
  "63448": { state: "MO", city: "LA GRANGE" },
  "63450": { state: "MO", city: "LENTNER" },
  "63451": { state: "MO", city: "LEONARD" },
  "63452": { state: "MO", city: "LEWISTOWN" },
  "63453": { state: "MO", city: "LURAY" },
  "63454": { state: "MO", city: "MAYWOOD" },
  "63456": { state: "MO", city: "MONROE CITY" },
  "63457": { state: "MO", city: "MONTICELLO" },
  "63458": { state: "MO", city: "NEWARK" },
  "63459": { state: "MO", city: "NEW LONDON" },
  "63460": { state: "MO", city: "NOVELTY" },
  "63461": { state: "MO", city: "PALMYRA" },
  "63462": { state: "MO", city: "PERRY" },
  "63463": { state: "MO", city: "PHILADELPHIA" },
  "63465": { state: "MO", city: "REVERE" },
  "63466": { state: "MO", city: "SAINT PATRICK" },
  "63467": { state: "MO", city: "SAVERTON" },
  "63468": { state: "MO", city: "SHELBINA" },
  "63469": { state: "MO", city: "SHELBYVILLE" },
  "63471": { state: "MO", city: "TAYLOR" },
  "63472": { state: "MO", city: "WAYLAND" },
  "63473": { state: "MO", city: "WILLIAMSTOWN" },
  "63474": { state: "MO", city: "WYACONDA" },
  "63501": { state: "MO", city: "KIRKSVILLE" },
  "63530": { state: "MO", city: "ATLANTA" },
  "63531": { state: "MO", city: "BARING" },
  "63532": { state: "MO", city: "BEVIER" },
  "63533": { state: "MO", city: "BRASHEAR" },
  "63534": { state: "MO", city: "CALLAO" },
  "63535": { state: "MO", city: "COATSVILLE" },
  "63536": { state: "MO", city: "DOWNING" },
  "63537": { state: "MO", city: "EDINA" },
  "63538": { state: "MO", city: "ELMER" },
  "63539": { state: "MO", city: "ETHEL" },
  "63540": { state: "MO", city: "GIBBS" },
  "63541": { state: "MO", city: "GLENWOOD" },
  "63543": { state: "MO", city: "GORIN" },
  "63544": { state: "MO", city: "GREEN CASTLE" },
  "63545": { state: "MO", city: "GREEN CITY" },
  "63546": { state: "MO", city: "GREENTOP" },
  "63547": { state: "MO", city: "HURDLAND" },
  "63548": { state: "MO", city: "LANCASTER" },
  "63549": { state: "MO", city: "LA PLATA" },
  "63551": { state: "MO", city: "LIVONIA" },
  "63552": { state: "MO", city: "MACON" },
  "63555": { state: "MO", city: "MEMPHIS" },
  "63556": { state: "MO", city: "MILAN" },
  "63557": { state: "MO", city: "NEW BOSTON" },
  "63558": { state: "MO", city: "NEW CAMBRIA" },
  "63559": { state: "MO", city: "NOVINGER" },
  "63560": { state: "MO", city: "POLLOCK" },
  "63561": { state: "MO", city: "QUEEN CITY" },
  "63563": { state: "MO", city: "RUTLEDGE" },
  "63565": { state: "MO", city: "UNIONVILLE" },
  "63566": { state: "MO", city: "WINIGAN" },
  "63567": { state: "MO", city: "WORTHINGTON" },
  "63601": { state: "MO", city: "PARK HILLS" },
  "63620": { state: "MO", city: "ANNAPOLIS" },
  "63621": { state: "MO", city: "ARCADIA" },
  "63622": { state: "MO", city: "BELGRADE" },
  "63623": { state: "MO", city: "BELLEVIEW" },
  "63624": { state: "MO", city: "BISMARCK" },
  "63625": { state: "MO", city: "BLACK" },
  "63626": { state: "MO", city: "BLACKWELL" },
  "63627": { state: "MO", city: "BLOOMSDALE" },
  "63628": { state: "MO", city: "BONNE TERRE" },
  "63629": { state: "MO", city: "BUNKER" },
  "63630": { state: "MO", city: "CADET" },
  "63631": { state: "MO", city: "CALEDONIA" },
  "63632": { state: "MO", city: "CASCADE" },
  "63633": { state: "MO", city: "CENTERVILLE" },
  "63636": { state: "MO", city: "DES ARC" },
  "63637": { state: "MO", city: "DOE RUN" },
  "63638": { state: "MO", city: "ELLINGTON" },
  "63640": { state: "MO", city: "FARMINGTON" },
  "63645": { state: "MO", city: "FREDERICKTOWN" },
  "63648": { state: "MO", city: "IRONDALE" },
  "63650": { state: "MO", city: "IRONTON" },
  "63651": { state: "MO", city: "KNOB LICK" },
  "63653": { state: "MO", city: "LEADWOOD" },
  "63654": { state: "MO", city: "LESTERVILLE" },
  "63655": { state: "MO", city: "MARQUAND" },
  "63656": { state: "MO", city: "MIDDLE BROOK" },
  "63660": { state: "MO", city: "MINERAL POINT" },
  "63662": { state: "MO", city: "PATTON" },
  "63663": { state: "MO", city: "PILOT KNOB" },
  "63664": { state: "MO", city: "POTOSI" },
  "63665": { state: "MO", city: "REDFORD" },
  "63666": { state: "MO", city: "REYNOLDS" },
  "63670": { state: "MO", city: "SAINTE GENEVIEVE" },
  "63673": { state: "MO", city: "SAINT MARY" },
  "63674": { state: "MO", city: "TIFF" },
  "63675": { state: "MO", city: "VULCAN" },
  "63701": { state: "MO", city: "CAPE GIRARDEAU" },
  "63702": { state: "MO", city: "CAPE GIRARDEAU" },
  "63703": { state: "MO", city: "CAPE GIRARDEAU" },
  "63730": { state: "MO", city: "ADVANCE" },
  "63732": { state: "MO", city: "ALTENBURG" },
  "63735": { state: "MO", city: "BELL CITY" },
  "63736": { state: "MO", city: "BENTON" },
  "63737": { state: "MO", city: "BRAZEAU" },
  "63738": { state: "MO", city: "BROWNWOOD" },
  "63739": { state: "MO", city: "BURFORDVILLE" },
  "63740": { state: "MO", city: "CHAFFEE" },
  "63742": { state: "MO", city: "COMMERCE" },
  "63743": { state: "MO", city: "DAISY" },
  "63744": { state: "MO", city: "DELTA" },
  "63745": { state: "MO", city: "DUTCHTOWN" },
  "63746": { state: "MO", city: "FARRAR" },
  "63747": { state: "MO", city: "FRIEDHEIM" },
  "63748": { state: "MO", city: "FROHNA" },
  "63750": { state: "MO", city: "GIPSY" },
  "63751": { state: "MO", city: "GLENALLEN" },
  "63752": { state: "MO", city: "GORDONVILLE" },
  "63755": { state: "MO", city: "JACKSON" },
  "63758": { state: "MO", city: "KELSO" },
  "63760": { state: "MO", city: "LEOPOLD" },
  "63763": { state: "MO", city: "MC GEE" },
  "63764": { state: "MO", city: "MARBLE HILL" },
  "63766": { state: "MO", city: "MILLERSVILLE" },
  "63767": { state: "MO", city: "MORLEY" },
  "63769": { state: "MO", city: "OAK RIDGE" },
  "63770": { state: "MO", city: "OLD APPLETON" },
  "63771": { state: "MO", city: "ORAN" },
  "63774": { state: "MO", city: "PERKINS" },
  "63775": { state: "MO", city: "PERRYVILLE" },
  "63776": { state: "MO", city: "MC BRIDE" },
  "63779": { state: "MO", city: "POCAHONTAS" },
  "63780": { state: "MO", city: "SCOTT CITY" },
  "63781": { state: "MO", city: "SEDGEWICKVILLE" },
  "63782": { state: "MO", city: "STURDIVANT" },
  "63783": { state: "MO", city: "UNIONTOWN" },
  "63784": { state: "MO", city: "VANDUSER" },
  "63785": { state: "MO", city: "WHITEWATER" },
  "63787": { state: "MO", city: "ZALMA" },
  "63801": { state: "MO", city: "SIKESTON" },
  "63820": { state: "MO", city: "ANNISTON" },
  "63821": { state: "MO", city: "ARBYRD" },
  "63822": { state: "MO", city: "BERNIE" },
  "63823": { state: "MO", city: "BERTRAND" },
  "63824": { state: "MO", city: "BLODGETT" },
  "63825": { state: "MO", city: "BLOOMFIELD" },
  "63826": { state: "MO", city: "BRAGGADOCIO" },
  "63827": { state: "MO", city: "BRAGG CITY" },
  "63828": { state: "MO", city: "CANALOU" },
  "63829": { state: "MO", city: "CARDWELL" },
  "63830": { state: "MO", city: "CARUTHERSVILLE" },
  "63833": { state: "MO", city: "CATRON" },
  "63834": { state: "MO", city: "CHARLESTON" },
  "63837": { state: "MO", city: "CLARKTON" },
  "63839": { state: "MO", city: "COOTER" },
  "63840": { state: "MO", city: "DEERING" },
  "63841": { state: "MO", city: "DEXTER" },
  "63845": { state: "MO", city: "EAST PRAIRIE" },
  "63846": { state: "MO", city: "ESSEX" },
  "63847": { state: "MO", city: "GIBSON" },
  "63848": { state: "MO", city: "GIDEON" },
  "63849": { state: "MO", city: "GOBLER" },
  "63850": { state: "MO", city: "GRAYRIDGE" },
  "63851": { state: "MO", city: "HAYTI" },
  "63852": { state: "MO", city: "HOLCOMB" },
  "63853": { state: "MO", city: "HOLLAND" },
  "63855": { state: "MO", city: "HORNERSVILLE" },
  "63857": { state: "MO", city: "KENNETT" },
  "63860": { state: "MO", city: "KEWANEE" },
  "63862": { state: "MO", city: "LILBOURN" },
  "63863": { state: "MO", city: "MALDEN" },
  "63866": { state: "MO", city: "MARSTON" },
  "63867": { state: "MO", city: "MATTHEWS" },
  "63868": { state: "MO", city: "MOREHOUSE" },
  "63869": { state: "MO", city: "NEW MADRID" },
  "63870": { state: "MO", city: "PARMA" },
  "63873": { state: "MO", city: "PORTAGEVILLE" },
  "63874": { state: "MO", city: "RISCO" },
  "63875": { state: "MO", city: "RIVES" },
  "63876": { state: "MO", city: "SENATH" },
  "63877": { state: "MO", city: "STEELE" },
  "63878": { state: "MO", city: "TALLAPOOSA" },
  "63879": { state: "MO", city: "WARDELL" },
  "63880": { state: "MO", city: "WHITEOAK" },
  "63881": { state: "MO", city: "WOLF ISLAND" },
  "63882": { state: "MO", city: "WYATT" },
  "63901": { state: "MO", city: "POPLAR BLUFF" },
  "63902": { state: "MO", city: "POPLAR BLUFF" },
  "63931": { state: "MO", city: "BRIAR" },
  "63932": { state: "MO", city: "BROSELEY" },
  "63933": { state: "MO", city: "CAMPBELL" },
  "63934": { state: "MO", city: "CLUBB" },
  "63935": { state: "MO", city: "DONIPHAN" },
  "63936": { state: "MO", city: "DUDLEY" },
  "63937": { state: "MO", city: "ELLSINORE" },
  "63939": { state: "MO", city: "FAIRDEALING" },
  "63940": { state: "MO", city: "FISK" },
  "63941": { state: "MO", city: "FREMONT" },
  "63942": { state: "MO", city: "GATEWOOD" },
  "63943": { state: "MO", city: "GRANDIN" },
  "63944": { state: "MO", city: "GREENVILLE" },
  "63945": { state: "MO", city: "HARVIELL" },
  "63950": { state: "MO", city: "LODI" },
  "63951": { state: "MO", city: "LOWNDES" },
  "63952": { state: "MO", city: "MILL SPRING" },
  "63953": { state: "MO", city: "NAYLOR" },
  "63954": { state: "MO", city: "NEELYVILLE" },
  "63955": { state: "MO", city: "OXLY" },
  "63956": { state: "MO", city: "PATTERSON" },
  "63957": { state: "MO", city: "PIEDMONT" },
  "63960": { state: "MO", city: "PUXICO" },
  "63961": { state: "MO", city: "QULIN" },
  "63962": { state: "MO", city: "ROMBAUER" },
  "63964": { state: "MO", city: "SILVA" },
  "63965": { state: "MO", city: "VAN BUREN" },
  "63966": { state: "MO", city: "WAPPAPELLO" },
  "63967": { state: "MO", city: "WILLIAMSVILLE" },
  "64001": { state: "MO", city: "ALMA" },
  "64002": { state: "MO", city: "LEES SUMMIT" },
  "64011": { state: "MO", city: "BATES CITY" },
  "64012": { state: "MO", city: "BELTON" },
  "64013": { state: "MO", city: "BLUE SPRINGS" },
  "64014": { state: "MO", city: "BLUE SPRINGS" },
  "64015": { state: "MO", city: "BLUE SPRINGS" },
  "64016": { state: "MO", city: "BUCKNER" },
  "64017": { state: "MO", city: "CAMDEN" },
  "64018": { state: "MO", city: "CAMDEN POINT" },
  "64019": { state: "MO", city: "CENTERVIEW" },
  "64020": { state: "MO", city: "CONCORDIA" },
  "64021": { state: "MO", city: "CORDER" },
  "64022": { state: "MO", city: "DOVER" },
  "64024": { state: "MO", city: "EXCELSIOR SPRINGS" },
  "64028": { state: "MO", city: "FARLEY" },
  "64029": { state: "MO", city: "GRAIN VALLEY" },
  "64030": { state: "MO", city: "GRANDVIEW" },
  "64034": { state: "MO", city: "GREENWOOD" },
  "64035": { state: "MO", city: "HARDIN" },
  "64036": { state: "MO", city: "HENRIETTA" },
  "64037": { state: "MO", city: "HIGGINSVILLE" },
  "64040": { state: "MO", city: "HOLDEN" },
  "64048": { state: "MO", city: "HOLT" },
  "64050": { state: "MO", city: "INDEPENDENCE" },
  "64051": { state: "MO", city: "INDEPENDENCE" },
  "64052": { state: "MO", city: "INDEPENDENCE" },
  "64053": { state: "MO", city: "INDEPENDENCE" },
  "64054": { state: "MO", city: "INDEPENDENCE" },
  "64055": { state: "MO", city: "INDEPENDENCE" },
  "64056": { state: "MO", city: "INDEPENDENCE" },
  "64057": { state: "MO", city: "INDEPENDENCE" },
  "64058": { state: "MO", city: "INDEPENDENCE" },
  "64060": { state: "MO", city: "KEARNEY" },
  "64061": { state: "MO", city: "KINGSVILLE" },
  "64062": { state: "MO", city: "LAWSON" },
  "64063": { state: "MO", city: "LEES SUMMIT" },
  "64064": { state: "MO", city: "LEES SUMMIT" },
  "64065": { state: "MO", city: "LEES SUMMIT" },
  "64066": { state: "MO", city: "LEVASY" },
  "64067": { state: "MO", city: "LEXINGTON" },
  "64068": { state: "MO", city: "LIBERTY" },
  "64069": { state: "MO", city: "LIBERTY" },
  "64070": { state: "MO", city: "LONE JACK" },
  "64071": { state: "MO", city: "MAYVIEW" },
  "64072": { state: "MO", city: "MISSOURI CITY" },
  "64073": { state: "MO", city: "MOSBY" },
  "64074": { state: "MO", city: "NAPOLEON" },
  "64075": { state: "MO", city: "OAK GROVE" },
  "64076": { state: "MO", city: "ODESSA" },
  "64077": { state: "MO", city: "ORRICK" },
  "64078": { state: "MO", city: "PECULIAR" },
  "64079": { state: "MO", city: "PLATTE CITY" },
  "64080": { state: "MO", city: "PLEASANT HILL" },
  "64081": { state: "MO", city: "LEES SUMMIT" },
  "64082": { state: "MO", city: "LEES SUMMIT" },
  "64083": { state: "MO", city: "RAYMORE" },
  "64084": { state: "MO", city: "RAYVILLE" },
  "64085": { state: "MO", city: "RICHMOND" },
  "64086": { state: "MO", city: "LEES SUMMIT" },
  "64088": { state: "MO", city: "SIBLEY" },
  "64089": { state: "MO", city: "SMITHVILLE" },
  "64090": { state: "MO", city: "STRASBURG" },
  "64092": { state: "MO", city: "WALDRON" },
  "64093": { state: "MO", city: "WARRENSBURG" },
  "64096": { state: "MO", city: "WAVERLY" },
  "64097": { state: "MO", city: "WELLINGTON" },
  "64098": { state: "MO", city: "WESTON" },
  "64101": { state: "MO", city: "KANSAS CITY" },
  "64102": { state: "MO", city: "KANSAS CITY" },
  "64105": { state: "MO", city: "KANSAS CITY" },
  "64106": { state: "MO", city: "KANSAS CITY" },
  "64108": { state: "MO", city: "KANSAS CITY" },
  "64109": { state: "MO", city: "KANSAS CITY" },
  "64110": { state: "MO", city: "KANSAS CITY" },
  "64111": { state: "MO", city: "KANSAS CITY" },
  "64112": { state: "MO", city: "KANSAS CITY" },
  "64113": { state: "MO", city: "KANSAS CITY" },
  "64114": { state: "MO", city: "KANSAS CITY" },
  "64116": { state: "MO", city: "KANSAS CITY" },
  "64117": { state: "MO", city: "KANSAS CITY" },
  "64118": { state: "MO", city: "KANSAS CITY" },
  "64119": { state: "MO", city: "KANSAS CITY" },
  "64120": { state: "MO", city: "KANSAS CITY" },
  "64121": { state: "MO", city: "KANSAS CITY" },
  "64123": { state: "MO", city: "KANSAS CITY" },
  "64124": { state: "MO", city: "KANSAS CITY" },
  "64125": { state: "MO", city: "KANSAS CITY" },
  "64126": { state: "MO", city: "KANSAS CITY" },
  "64127": { state: "MO", city: "KANSAS CITY" },
  "64128": { state: "MO", city: "KANSAS CITY" },
  "64129": { state: "MO", city: "KANSAS CITY" },
  "64130": { state: "MO", city: "KANSAS CITY" },
  "64131": { state: "MO", city: "KANSAS CITY" },
  "64132": { state: "MO", city: "KANSAS CITY" },
  "64133": { state: "MO", city: "KANSAS CITY" },
  "64134": { state: "MO", city: "KANSAS CITY" },
  "64136": { state: "MO", city: "KANSAS CITY" },
  "64137": { state: "MO", city: "KANSAS CITY" },
  "64138": { state: "MO", city: "KANSAS CITY" },
  "64139": { state: "MO", city: "KANSAS CITY" },
  "64141": { state: "MO", city: "KANSAS CITY" },
  "64144": { state: "MO", city: "KANSAS CITY" },
  "64145": { state: "MO", city: "KANSAS CITY" },
  "64146": { state: "MO", city: "KANSAS CITY" },
  "64147": { state: "MO", city: "KANSAS CITY" },
  "64148": { state: "MO", city: "KANSAS CITY" },
  "64149": { state: "MO", city: "KANSAS CITY" },
  "64150": { state: "MO", city: "RIVERSIDE" },
  "64151": { state: "MO", city: "KANSAS CITY" },
  "64152": { state: "MO", city: "KANSAS CITY" },
  "64153": { state: "MO", city: "KANSAS CITY" },
  "64154": { state: "MO", city: "KANSAS CITY" },
  "64155": { state: "MO", city: "KANSAS CITY" },
  "64156": { state: "MO", city: "KANSAS CITY" },
  "64157": { state: "MO", city: "KANSAS CITY" },
  "64158": { state: "MO", city: "KANSAS CITY" },
  "64161": { state: "MO", city: "KANSAS CITY" },
  "64162": { state: "MO", city: "KANSAS CITY" },
  "64163": { state: "MO", city: "KANSAS CITY" },
  "64164": { state: "MO", city: "KANSAS CITY" },
  "64165": { state: "MO", city: "KANSAS CITY" },
  "64166": { state: "MO", city: "KANSAS CITY" },
  "64167": { state: "MO", city: "KANSAS CITY" },
  "64168": { state: "MO", city: "KANSAS CITY" },
  "64170": { state: "MO", city: "KANSAS CITY" },
  "64171": { state: "MO", city: "KANSAS CITY" },
  "64179": { state: "MO", city: "KANSAS CITY" },
  "64180": { state: "MO", city: "KANSAS CITY" },
  "64184": { state: "MO", city: "KANSAS CITY" },
  "64187": { state: "MO", city: "KANSAS CITY" },
  "64188": { state: "MO", city: "KANSAS CITY" },
  "64190": { state: "MO", city: "KANSAS CITY" },
  "64191": { state: "MO", city: "KANSAS CITY" },
  "64195": { state: "MO", city: "KANSAS CITY" },
  "64196": { state: "MO", city: "KANSAS CITY" },
  "64197": { state: "MO", city: "KANSAS CITY" },
  "64198": { state: "MO", city: "KANSAS CITY" },
  "64199": { state: "MO", city: "KANSAS CITY" },
  "64401": { state: "MO", city: "AGENCY" },
  "64402": { state: "MO", city: "ALBANY" },
  "64420": { state: "MO", city: "ALLENDALE" },
  "64421": { state: "MO", city: "AMAZONIA" },
  "64422": { state: "MO", city: "AMITY" },
  "64423": { state: "MO", city: "BARNARD" },
  "64424": { state: "MO", city: "BETHANY" },
  "64426": { state: "MO", city: "BLYTHEDALE" },
  "64427": { state: "MO", city: "BOLCKOW" },
  "64428": { state: "MO", city: "BURLINGTON JUNCTION" },
  "64429": { state: "MO", city: "CAMERON" },
  "64430": { state: "MO", city: "CLARKSDALE" },
  "64431": { state: "MO", city: "CLEARMONT" },
  "64432": { state: "MO", city: "CLYDE" },
  "64433": { state: "MO", city: "CONCEPTION" },
  "64434": { state: "MO", city: "CONCEPTION JUNCTION" },
  "64436": { state: "MO", city: "COSBY" },
  "64437": { state: "MO", city: "CRAIG" },
  "64438": { state: "MO", city: "DARLINGTON" },
  "64439": { state: "MO", city: "DEARBORN" },
  "64440": { state: "MO", city: "DE KALB" },
  "64441": { state: "MO", city: "DENVER" },
  "64442": { state: "MO", city: "EAGLEVILLE" },
  "64443": { state: "MO", city: "EASTON" },
  "64444": { state: "MO", city: "EDGERTON" },
  "64445": { state: "MO", city: "ELMO" },
  "64446": { state: "MO", city: "FAIRFAX" },
  "64448": { state: "MO", city: "FAUCETT" },
  "64449": { state: "MO", city: "FILLMORE" },
  "64451": { state: "MO", city: "FOREST CITY" },
  "64453": { state: "MO", city: "GENTRY" },
  "64454": { state: "MO", city: "GOWER" },
  "64455": { state: "MO", city: "GRAHAM" },
  "64456": { state: "MO", city: "GRANT CITY" },
  "64457": { state: "MO", city: "GUILFORD" },
  "64458": { state: "MO", city: "HATFIELD" },
  "64459": { state: "MO", city: "HELENA" },
  "64461": { state: "MO", city: "HOPKINS" },
  "64463": { state: "MO", city: "KING CITY" },
  "64465": { state: "MO", city: "LATHROP" },
  "64466": { state: "MO", city: "MAITLAND" },
  "64467": { state: "MO", city: "MARTINSVILLE" },
  "64468": { state: "MO", city: "MARYVILLE" },
  "64469": { state: "MO", city: "MAYSVILLE" },
  "64470": { state: "MO", city: "MOUND CITY" },
  "64471": { state: "MO", city: "NEW HAMPTON" },
  "64473": { state: "MO", city: "OREGON" },
  "64474": { state: "MO", city: "OSBORN" },
  "64475": { state: "MO", city: "PARNELL" },
  "64476": { state: "MO", city: "PICKERING" },
  "64477": { state: "MO", city: "PLATTSBURG" },
  "64479": { state: "MO", city: "RAVENWOOD" },
  "64480": { state: "MO", city: "REA" },
  "64481": { state: "MO", city: "RIDGEWAY" },
  "64482": { state: "MO", city: "ROCK PORT" },
  "64483": { state: "MO", city: "ROSENDALE" },
  "64484": { state: "MO", city: "RUSHVILLE" },
  "64485": { state: "MO", city: "SAVANNAH" },
  "64486": { state: "MO", city: "SHERIDAN" },
  "64487": { state: "MO", city: "SKIDMORE" },
  "64489": { state: "MO", city: "STANBERRY" },
  "64490": { state: "MO", city: "STEWARTSVILLE" },
  "64491": { state: "MO", city: "TARKIO" },
  "64492": { state: "MO", city: "TRIMBLE" },
  "64493": { state: "MO", city: "TURNEY" },
  "64494": { state: "MO", city: "UNION STAR" },
  "64496": { state: "MO", city: "WATSON" },
  "64497": { state: "MO", city: "WEATHERBY" },
  "64498": { state: "MO", city: "WESTBORO" },
  "64499": { state: "MO", city: "WORTH" },
  "64501": { state: "MO", city: "SAINT JOSEPH" },
  "64502": { state: "MO", city: "SAINT JOSEPH" },
  "64503": { state: "MO", city: "SAINT JOSEPH" },
  "64504": { state: "MO", city: "SAINT JOSEPH" },
  "64505": { state: "MO", city: "SAINT JOSEPH" },
  "64506": { state: "MO", city: "SAINT JOSEPH" },
  "64507": { state: "MO", city: "SAINT JOSEPH" },
  "64508": { state: "MO", city: "SAINT JOSEPH" },
  "64601": { state: "MO", city: "CHILLICOTHE" },
  "64620": { state: "MO", city: "ALTAMONT" },
  "64622": { state: "MO", city: "BOGARD" },
  "64623": { state: "MO", city: "BOSWORTH" },
  "64624": { state: "MO", city: "BRAYMER" },
  "64625": { state: "MO", city: "BRECKENRIDGE" },
  "64628": { state: "MO", city: "BROOKFIELD" },
  "64630": { state: "MO", city: "BROWNING" },
  "64631": { state: "MO", city: "BUCKLIN" },
  "64632": { state: "MO", city: "CAINSVILLE" },
  "64633": { state: "MO", city: "CARROLLTON" },
  "64635": { state: "MO", city: "CHULA" },
  "64636": { state: "MO", city: "COFFEY" },
  "64637": { state: "MO", city: "COWGILL" },
  "64638": { state: "MO", city: "DAWN" },
  "64639": { state: "MO", city: "DE WITT" },
  "64640": { state: "MO", city: "GALLATIN" },
  "64641": { state: "MO", city: "GALT" },
  "64642": { state: "MO", city: "GILMAN CITY" },
  "64643": { state: "MO", city: "HALE" },
  "64644": { state: "MO", city: "HAMILTON" },
  "64645": { state: "MO", city: "HARRIS" },
  "64646": { state: "MO", city: "HUMPHREYS" },
  "64647": { state: "MO", city: "JAMESON" },
  "64648": { state: "MO", city: "JAMESPORT" },
  "64649": { state: "MO", city: "KIDDER" },
  "64650": { state: "MO", city: "KINGSTON" },
  "64651": { state: "MO", city: "LACLEDE" },
  "64652": { state: "MO", city: "LAREDO" },
  "64653": { state: "MO", city: "LINNEUS" },
  "64654": { state: "MO", city: "LOCK SPRINGS" },
  "64655": { state: "MO", city: "LUCERNE" },
  "64656": { state: "MO", city: "LUDLOW" },
  "64657": { state: "MO", city: "MC FALL" },
  "64658": { state: "MO", city: "MARCELINE" },
  "64659": { state: "MO", city: "MEADVILLE" },
  "64660": { state: "MO", city: "MENDON" },
  "64661": { state: "MO", city: "MERCER" },
  "64664": { state: "MO", city: "MOORESVILLE" },
  "64667": { state: "MO", city: "NEWTOWN" },
  "64668": { state: "MO", city: "NORBORNE" },
  "64670": { state: "MO", city: "PATTONSBURG" },
  "64671": { state: "MO", city: "POLO" },
  "64672": { state: "MO", city: "POWERSVILLE" },
  "64673": { state: "MO", city: "PRINCETON" },
  "64674": { state: "MO", city: "PURDIN" },
  "64676": { state: "MO", city: "ROTHVILLE" },
  "64679": { state: "MO", city: "SPICKARD" },
  "64680": { state: "MO", city: "STET" },
  "64681": { state: "MO", city: "SUMNER" },
  "64682": { state: "MO", city: "TINA" },
  "64683": { state: "MO", city: "TRENTON" },
  "64686": { state: "MO", city: "UTICA" },
  "64688": { state: "MO", city: "WHEELING" },
  "64689": { state: "MO", city: "WINSTON" },
  "64701": { state: "MO", city: "HARRISONVILLE" },
  "64720": { state: "MO", city: "ADRIAN" },
  "64722": { state: "MO", city: "AMORET" },
  "64723": { state: "MO", city: "AMSTERDAM" },
  "64724": { state: "MO", city: "APPLETON CITY" },
  "64725": { state: "MO", city: "ARCHIE" },
  "64726": { state: "MO", city: "BLAIRSTOWN" },
  "64728": { state: "MO", city: "BRONAUGH" },
  "64730": { state: "MO", city: "BUTLER" },
  "64733": { state: "MO", city: "CHILHOWEE" },
  "64734": { state: "MO", city: "CLEVELAND" },
  "64735": { state: "MO", city: "CLINTON" },
  "64738": { state: "MO", city: "COLLINS" },
  "64739": { state: "MO", city: "CREIGHTON" },
  "64740": { state: "MO", city: "DEEPWATER" },
  "64741": { state: "MO", city: "DEERFIELD" },
  "64742": { state: "MO", city: "DREXEL" },
  "64743": { state: "MO", city: "EAST LYNNE" },
  "64744": { state: "MO", city: "EL DORADO SPRINGS" },
  "64745": { state: "MO", city: "FOSTER" },
  "64746": { state: "MO", city: "FREEMAN" },
  "64747": { state: "MO", city: "GARDEN CITY" },
  "64748": { state: "MO", city: "GOLDEN CITY" },
  "64750": { state: "MO", city: "HARWOOD" },
  "64752": { state: "MO", city: "HUME" },
  "64755": { state: "MO", city: "JASPER" },
  "64756": { state: "MO", city: "JERICO SPRINGS" },
  "64759": { state: "MO", city: "LAMAR" },
  "64761": { state: "MO", city: "LEETON" },
  "64762": { state: "MO", city: "LIBERAL" },
  "64763": { state: "MO", city: "LOWRY CITY" },
  "64765": { state: "MO", city: "METZ" },
  "64766": { state: "MO", city: "MILFORD" },
  "64767": { state: "MO", city: "MILO" },
  "64769": { state: "MO", city: "MINDENMINES" },
  "64770": { state: "MO", city: "MONTROSE" },
  "64771": { state: "MO", city: "MOUNDVILLE" },
  "64772": { state: "MO", city: "NEVADA" },
  "64776": { state: "MO", city: "OSCEOLA" },
  "64778": { state: "MO", city: "RICHARDS" },
  "64779": { state: "MO", city: "RICH HILL" },
  "64780": { state: "MO", city: "ROCKVILLE" },
  "64781": { state: "MO", city: "ROSCOE" },
  "64783": { state: "MO", city: "SCHELL CITY" },
  "64784": { state: "MO", city: "SHELDON" },
  "64788": { state: "MO", city: "URICH" },
  "64790": { state: "MO", city: "WALKER" },
  "64801": { state: "MO", city: "JOPLIN" },
  "64802": { state: "MO", city: "JOPLIN" },
  "64803": { state: "MO", city: "JOPLIN" },
  "64804": { state: "MO", city: "JOPLIN" },
  "64830": { state: "MO", city: "ALBA" },
  "64831": { state: "MO", city: "ANDERSON" },
  "64832": { state: "MO", city: "ASBURY" },
  "64833": { state: "MO", city: "AVILLA" },
  "64834": { state: "MO", city: "CARL JUNCTION" },
  "64835": { state: "MO", city: "CARTERVILLE" },
  "64836": { state: "MO", city: "CARTHAGE" },
  "64840": { state: "MO", city: "DIAMOND" },
  "64841": { state: "MO", city: "DUENWEG" },
  "64842": { state: "MO", city: "FAIRVIEW" },
  "64843": { state: "MO", city: "GOODMAN" },
  "64844": { state: "MO", city: "GRANBY" },
  "64847": { state: "MO", city: "LANAGAN" },
  "64848": { state: "MO", city: "LA RUSSELL" },
  "64849": { state: "MO", city: "NECK CITY" },
  "64850": { state: "MO", city: "NEOSHO" },
  "64853": { state: "MO", city: "NEWTONIA" },
  "64854": { state: "MO", city: "NOEL" },
  "64855": { state: "MO", city: "ORONOGO" },
  "64856": { state: "MO", city: "PINEVILLE" },
  "64857": { state: "MO", city: "PURCELL" },
  "64858": { state: "MO", city: "RACINE" },
  "64859": { state: "MO", city: "REEDS" },
  "64861": { state: "MO", city: "ROCKY COMFORT" },
  "64862": { state: "MO", city: "SARCOXIE" },
  "64863": { state: "MO", city: "SOUTH WEST CITY" },
  "64864": { state: "MO", city: "SAGINAW" },
  "64865": { state: "MO", city: "SENECA" },
  "64866": { state: "MO", city: "STARK CITY" },
  "64867": { state: "MO", city: "STELLA" },
  "64868": { state: "MO", city: "TIFF CITY" },
  "64870": { state: "MO", city: "WEBB CITY" },
  "64873": { state: "MO", city: "WENTWORTH" },
  "64874": { state: "MO", city: "WHEATON" },
  "64999": { state: "MO", city: "KANSAS CITY" },
  "65001": { state: "MO", city: "ARGYLE" },
  "65010": { state: "MO", city: "ASHLAND" },
  "65011": { state: "MO", city: "BARNETT" },
  "65013": { state: "MO", city: "BELLE" },
  "65014": { state: "MO", city: "BLAND" },
  "65016": { state: "MO", city: "BONNOTS MILL" },
  "65017": { state: "MO", city: "BRUMLEY" },
  "65018": { state: "MO", city: "CALIFORNIA" },
  "65020": { state: "MO", city: "CAMDENTON" },
  "65023": { state: "MO", city: "CENTERTOWN" },
  "65024": { state: "MO", city: "CHAMOIS" },
  "65025": { state: "MO", city: "CLARKSBURG" },
  "65026": { state: "MO", city: "ELDON" },
  "65032": { state: "MO", city: "EUGENE" },
  "65034": { state: "MO", city: "FORTUNA" },
  "65035": { state: "MO", city: "FREEBURG" },
  "65036": { state: "MO", city: "GASCONADE" },
  "65037": { state: "MO", city: "GRAVOIS MILLS" },
  "65038": { state: "MO", city: "LAURIE" },
  "65039": { state: "MO", city: "HARTSBURG" },
  "65040": { state: "MO", city: "HENLEY" },
  "65041": { state: "MO", city: "HERMANN" },
  "65042": { state: "MO", city: "HIGH POINT" },
  "65043": { state: "MO", city: "HOLTS SUMMIT" },
  "65046": { state: "MO", city: "JAMESTOWN" },
  "65047": { state: "MO", city: "KAISER" },
  "65048": { state: "MO", city: "KOELTZTOWN" },
  "65049": { state: "MO", city: "LAKE OZARK" },
  "65050": { state: "MO", city: "LATHAM" },
  "65051": { state: "MO", city: "LINN" },
  "65052": { state: "MO", city: "LINN CREEK" },
  "65053": { state: "MO", city: "LOHMAN" },
  "65054": { state: "MO", city: "LOOSE CREEK" },
  "65055": { state: "MO", city: "MC GIRK" },
  "65058": { state: "MO", city: "META" },
  "65059": { state: "MO", city: "MOKANE" },
  "65061": { state: "MO", city: "MORRISON" },
  "65062": { state: "MO", city: "MOUNT STERLING" },
  "65063": { state: "MO", city: "NEW BLOOMFIELD" },
  "65064": { state: "MO", city: "OLEAN" },
  "65065": { state: "MO", city: "OSAGE BEACH" },
  "65066": { state: "MO", city: "OWENSVILLE" },
  "65067": { state: "MO", city: "PORTLAND" },
  "65068": { state: "MO", city: "PRAIRIE HOME" },
  "65069": { state: "MO", city: "RHINELAND" },
  "65072": { state: "MO", city: "ROCKY MOUNT" },
  "65074": { state: "MO", city: "RUSSELLVILLE" },
  "65075": { state: "MO", city: "SAINT ELIZABETH" },
  "65076": { state: "MO", city: "SAINT THOMAS" },
  "65077": { state: "MO", city: "STEEDMAN" },
  "65078": { state: "MO", city: "STOVER" },
  "65079": { state: "MO", city: "SUNRISE BEACH" },
  "65080": { state: "MO", city: "TEBBETTS" },
  "65081": { state: "MO", city: "TIPTON" },
  "65082": { state: "MO", city: "TUSCUMBIA" },
  "65083": { state: "MO", city: "ULMAN" },
  "65084": { state: "MO", city: "VERSAILLES" },
  "65085": { state: "MO", city: "WESTPHALIA" },
  "65101": { state: "MO", city: "JEFFERSON CITY" },
  "65102": { state: "MO", city: "JEFFERSON CITY" },
  "65103": { state: "MO", city: "JEFFERSON CITY" },
  "65104": { state: "MO", city: "JEFFERSON CITY" },
  "65105": { state: "MO", city: "JEFFERSON CITY" },
  "65106": { state: "MO", city: "JEFFERSON CITY" },
  "65107": { state: "MO", city: "JEFFERSON CITY" },
  "65108": { state: "MO", city: "JEFFERSON CITY" },
  "65109": { state: "MO", city: "JEFFERSON CITY" },
  "65110": { state: "MO", city: "JEFFERSON CITY" },
  "65111": { state: "MO", city: "JEFFERSON CITY" },
  "65201": { state: "MO", city: "COLUMBIA" },
  "65202": { state: "MO", city: "COLUMBIA" },
  "65203": { state: "MO", city: "COLUMBIA" },
  "65205": { state: "MO", city: "COLUMBIA" },
  "65211": { state: "MO", city: "COLUMBIA" },
  "65212": { state: "MO", city: "COLUMBIA" },
  "65215": { state: "MO", city: "COLUMBIA" },
  "65216": { state: "MO", city: "COLUMBIA" },
  "65217": { state: "MO", city: "COLUMBIA" },
  "65218": { state: "MO", city: "COLUMBIA" },
  "65230": { state: "MO", city: "ARMSTRONG" },
  "65231": { state: "MO", city: "AUXVASSE" },
  "65232": { state: "MO", city: "BENTON CITY" },
  "65233": { state: "MO", city: "BOONVILLE" },
  "65236": { state: "MO", city: "BRUNSWICK" },
  "65237": { state: "MO", city: "BUNCETON" },
  "65239": { state: "MO", city: "CAIRO" },
  "65240": { state: "MO", city: "CENTRALIA" },
  "65243": { state: "MO", city: "CLARK" },
  "65244": { state: "MO", city: "CLIFTON HILL" },
  "65246": { state: "MO", city: "DALTON" },
  "65247": { state: "MO", city: "EXCELLO" },
  "65248": { state: "MO", city: "FAYETTE" },
  "65250": { state: "MO", city: "FRANKLIN" },
  "65251": { state: "MO", city: "FULTON" },
  "65254": { state: "MO", city: "GLASGOW" },
  "65255": { state: "MO", city: "HALLSVILLE" },
  "65256": { state: "MO", city: "HARRISBURG" },
  "65257": { state: "MO", city: "HIGBEE" },
  "65258": { state: "MO", city: "HOLLIDAY" },
  "65259": { state: "MO", city: "HUNTSVILLE" },
  "65260": { state: "MO", city: "JACKSONVILLE" },
  "65261": { state: "MO", city: "KEYTESVILLE" },
  "65262": { state: "MO", city: "KINGDOM CITY" },
  "65263": { state: "MO", city: "MADISON" },
  "65264": { state: "MO", city: "MARTINSBURG" },
  "65265": { state: "MO", city: "MEXICO" },
  "65270": { state: "MO", city: "MOBERLY" },
  "65274": { state: "MO", city: "NEW FRANKLIN" },
  "65275": { state: "MO", city: "PARIS" },
  "65276": { state: "MO", city: "PILOT GROVE" },
  "65278": { state: "MO", city: "RENICK" },
  "65279": { state: "MO", city: "ROCHEPORT" },
  "65280": { state: "MO", city: "RUSH HILL" },
  "65281": { state: "MO", city: "SALISBURY" },
  "65282": { state: "MO", city: "SANTA FE" },
  "65283": { state: "MO", city: "STOUTSVILLE" },
  "65284": { state: "MO", city: "STURGEON" },
  "65285": { state: "MO", city: "THOMPSON" },
  "65286": { state: "MO", city: "TRIPLETT" },
  "65287": { state: "MO", city: "WOOLDRIDGE" },
  "65299": { state: "MO", city: "COLUMBIA" },
  "65301": { state: "MO", city: "SEDALIA" },
  "65302": { state: "MO", city: "SEDALIA" },
  "65305": { state: "MO", city: "WHITEMAN AIR FORCE BASE NEW" },
  "65320": { state: "MO", city: "ARROW ROCK" },
  "65321": { state: "MO", city: "BLACKBURN" },
  "65322": { state: "MO", city: "BLACKWATER" },
  "65323": { state: "MO", city: "CALHOUN" },
  "65324": { state: "MO", city: "CLIMAX SPRINGS" },
  "65325": { state: "MO", city: "COLE CAMP" },
  "65326": { state: "MO", city: "EDWARDS" },
  "65327": { state: "MO", city: "EMMA" },
  "65329": { state: "MO", city: "FLORENCE" },
  "65330": { state: "MO", city: "GILLIAM" },
  "65332": { state: "MO", city: "GREEN RIDGE" },
  "65333": { state: "MO", city: "HOUSTONIA" },
  "65334": { state: "MO", city: "HUGHESVILLE" },
  "65335": { state: "MO", city: "IONIA" },
  "65336": { state: "MO", city: "KNOB NOSTER" },
  "65337": { state: "MO", city: "LA MONTE" },
  "65338": { state: "MO", city: "LINCOLN" },
  "65339": { state: "MO", city: "MALTA BEND" },
  "65340": { state: "MO", city: "MARSHALL" },
  "65344": { state: "MO", city: "MIAMI" },
  "65345": { state: "MO", city: "MORA" },
  "65347": { state: "MO", city: "NELSON" },
  "65348": { state: "MO", city: "OTTERVILLE" },
  "65349": { state: "MO", city: "SLATER" },
  "65350": { state: "MO", city: "SMITHTON" },
  "65351": { state: "MO", city: "SWEET SPRINGS" },
  "65354": { state: "MO", city: "SYRACUSE" },
  "65355": { state: "MO", city: "WARSAW" },
  "65360": { state: "MO", city: "WINDSOR" },
  "65401": { state: "MO", city: "ROLLA" },
  "65402": { state: "MO", city: "ROLLA" },
  "65409": { state: "MO", city: "ROLLA" },
  "65436": { state: "MO", city: "BEULAH" },
  "65438": { state: "MO", city: "BIRCH TREE" },
  "65439": { state: "MO", city: "BIXBY" },
  "65440": { state: "MO", city: "BOSS" },
  "65441": { state: "MO", city: "BOURBON" },
  "65443": { state: "MO", city: "BRINKTOWN" },
  "65444": { state: "MO", city: "BUCYRUS" },
  "65446": { state: "MO", city: "CHERRYVILLE" },
  "65449": { state: "MO", city: "COOK STA" },
  "65452": { state: "MO", city: "CROCKER" },
  "65453": { state: "MO", city: "CUBA" },
  "65456": { state: "MO", city: "DAVISVILLE" },
  "65457": { state: "MO", city: "DEVILS ELBOW" },
  "65459": { state: "MO", city: "DIXON" },
  "65461": { state: "MO", city: "DUKE" },
  "65462": { state: "MO", city: "EDGAR SPRINGS" },
  "65463": { state: "MO", city: "ELDRIDGE" },
  "65464": { state: "MO", city: "ELK CREEK" },
  "65466": { state: "MO", city: "EMINENCE" },
  "65468": { state: "MO", city: "EUNICE" },
  "65470": { state: "MO", city: "FALCON" },
  "65473": { state: "MO", city: "FORT LEONARD WOOD" },
  "65479": { state: "MO", city: "HARTSHORN" },
  "65483": { state: "MO", city: "HOUSTON" },
  "65484": { state: "MO", city: "HUGGINS" },
  "65486": { state: "MO", city: "IBERIA" },
  "65501": { state: "MO", city: "JADWIN" },
  "65529": { state: "MO", city: "JEROME" },
  "65532": { state: "MO", city: "LAKE SPRING" },
  "65534": { state: "MO", city: "LAQUEY" },
  "65535": { state: "MO", city: "LEASBURG" },
  "65536": { state: "MO", city: "LEBANON" },
  "65541": { state: "MO", city: "LENOX" },
  "65542": { state: "MO", city: "LICKING" },
  "65543": { state: "MO", city: "LYNCHBURG" },
  "65546": { state: "MO", city: "MONTIER" },
  "65548": { state: "MO", city: "MOUNTAIN VIEW" },
  "65550": { state: "MO", city: "NEWBURG" },
  "65552": { state: "MO", city: "PLATO" },
  "65555": { state: "MO", city: "RAYMONDVILLE" },
  "65556": { state: "MO", city: "RICHLAND" },
  "65557": { state: "MO", city: "ROBY" },
  "65559": { state: "MO", city: "SAINT JAMES" },
  "65560": { state: "MO", city: "SALEM" },
  "65564": { state: "MO", city: "SOLO" },
  "65565": { state: "MO", city: "STEELVILLE" },
  "65566": { state: "MO", city: "VIBURNUM" },
  "65567": { state: "MO", city: "STOUTLAND" },
  "65570": { state: "MO", city: "SUCCESS" },
  "65571": { state: "MO", city: "SUMMERSVILLE" },
  "65580": { state: "MO", city: "VICHY" },
  "65582": { state: "MO", city: "VIENNA" },
  "65583": { state: "MO", city: "WAYNESVILLE" },
  "65584": { state: "MO", city: "SAINT ROBERT" },
  "65586": { state: "MO", city: "WESCO" },
  "65588": { state: "MO", city: "WINONA" },
  "65589": { state: "MO", city: "YUKON" },
  "65590": { state: "MO", city: "LONG LANE" },
  "65591": { state: "MO", city: "MONTREAL" },
  "65601": { state: "MO", city: "ALDRICH" },
  "65603": { state: "MO", city: "ARCOLA" },
  "65604": { state: "MO", city: "ASH GROVE" },
  "65605": { state: "MO", city: "AURORA" },
  "65606": { state: "MO", city: "ALTON" },
  "65607": { state: "MO", city: "CAPLINGER MILLS" },
  "65608": { state: "MO", city: "AVA" },
  "65609": { state: "MO", city: "BAKERSFIELD" },
  "65610": { state: "MO", city: "BILLINGS" },
  "65611": { state: "MO", city: "BLUE EYE" },
  "65612": { state: "MO", city: "BOIS D ARC" },
  "65613": { state: "MO", city: "BOLIVAR" },
  "65614": { state: "MO", city: "BRADLEYVILLE" },
  "65615": { state: "MO", city: "BRANSON" },
  "65616": { state: "MO", city: "BRANSON" },
  "65617": { state: "MO", city: "BRIGHTON" },
  "65618": { state: "MO", city: "BRIXEY" },
  "65619": { state: "MO", city: "BROOKLINE" },
  "65620": { state: "MO", city: "BRUNER" },
  "65622": { state: "MO", city: "BUFFALO" },
  "65623": { state: "MO", city: "BUTTERFIELD" },
  "65624": { state: "MO", city: "CAPE FAIR" },
  "65625": { state: "MO", city: "CASSVILLE" },
  "65626": { state: "MO", city: "CAULFIELD" },
  "65627": { state: "MO", city: "CEDARCREEK" },
  "65629": { state: "MO", city: "CHADWICK" },
  "65630": { state: "MO", city: "CHESTNUTRIDGE" },
  "65631": { state: "MO", city: "CLEVER" },
  "65632": { state: "MO", city: "CONWAY" },
  "65633": { state: "MO", city: "CRANE" },
  "65634": { state: "MO", city: "CROSS TIMBERS" },
  "65635": { state: "MO", city: "DADEVILLE" },
  "65636": { state: "MO", city: "DIGGINS" },
  "65637": { state: "MO", city: "DORA" },
  "65638": { state: "MO", city: "DRURY" },
  "65640": { state: "MO", city: "DUNNEGAN" },
  "65641": { state: "MO", city: "EAGLE ROCK" },
  "65644": { state: "MO", city: "ELKLAND" },
  "65645": { state: "MO", city: "MORRISVILLE" },
  "65646": { state: "MO", city: "EVERTON" },
  "65647": { state: "MO", city: "EXETER" },
  "65648": { state: "MO", city: "FAIR GROVE" },
  "65649": { state: "MO", city: "FAIR PLAY" },
  "65650": { state: "MO", city: "FLEMINGTON" },
  "65652": { state: "MO", city: "FORDLAND" },
  "65653": { state: "MO", city: "FORSYTH" },
  "65654": { state: "MO", city: "FREISTATT" },
  "65655": { state: "MO", city: "GAINESVILLE" },
  "65656": { state: "MO", city: "GALENA" },
  "65657": { state: "MO", city: "GARRISON" },
  "65658": { state: "MO", city: "GOLDEN" },
  "65660": { state: "MO", city: "GRAFF" },
  "65661": { state: "MO", city: "GREENFIELD" },
  "65662": { state: "MO", city: "GROVESPRING" },
  "65663": { state: "MO", city: "HALF WAY" },
  "65664": { state: "MO", city: "HALLTOWN" },
  "65666": { state: "MO", city: "HARDENVILLE" },
  "65667": { state: "MO", city: "HARTVILLE" },
  "65668": { state: "MO", city: "HERMITAGE" },
  "65669": { state: "MO", city: "HIGHLANDVILLE" },
  "65672": { state: "MO", city: "HOLLISTER" },
  "65673": { state: "MO", city: "HOLLISTER" },
  "65674": { state: "MO", city: "HUMANSVILLE" },
  "65675": { state: "MO", city: "HURLEY" },
  "65676": { state: "MO", city: "ISABELLA" },
  "65679": { state: "MO", city: "KIRBYVILLE" },
  "65680": { state: "MO", city: "KISSEE MILLS" },
  "65681": { state: "MO", city: "LAMPE" },
  "65682": { state: "MO", city: "LOCKWOOD" },
  "65685": { state: "MO", city: "LOUISBURG" },
  "65686": { state: "MO", city: "KIMBERLING CITY" },
  "65688": { state: "MO", city: "BRANDSVILLE" },
  "65689": { state: "MO", city: "CABOOL" },
  "65690": { state: "MO", city: "COUCH" },
  "65692": { state: "MO", city: "KOSHKONONG" },
  "65702": { state: "MO", city: "MACOMB" },
  "65704": { state: "MO", city: "MANSFIELD" },
  "65705": { state: "MO", city: "MARIONVILLE" },
  "65706": { state: "MO", city: "MARSHFIELD" },
  "65707": { state: "MO", city: "MILLER" },
  "65708": { state: "MO", city: "MONETT" },
  "65710": { state: "MO", city: "MORRISVILLE" },
  "65711": { state: "MO", city: "MOUNTAIN GROVE" },
  "65712": { state: "MO", city: "MOUNT VERNON" },
  "65713": { state: "MO", city: "NIANGUA" },
  "65714": { state: "MO", city: "NIXA" },
  "65715": { state: "MO", city: "NOBLE" },
  "65717": { state: "MO", city: "NORWOOD" },
  "65720": { state: "MO", city: "OLDFIELD" },
  "65721": { state: "MO", city: "OZARK" },
  "65722": { state: "MO", city: "PHILLIPSBURG" },
  "65723": { state: "MO", city: "PIERCE CITY" },
  "65724": { state: "MO", city: "PITTSBURG" },
  "65725": { state: "MO", city: "PLEASANT HOPE" },
  "65726": { state: "MO", city: "POINT LOOKOUT" },
  "65727": { state: "MO", city: "POLK" },
  "65728": { state: "MO", city: "PONCE DE LEON" },
  "65729": { state: "MO", city: "PONTIAC" },
  "65730": { state: "MO", city: "POWELL" },
  "65731": { state: "MO", city: "POWERSITE" },
  "65732": { state: "MO", city: "PRESTON" },
  "65733": { state: "MO", city: "PROTEM" },
  "65734": { state: "MO", city: "PURDY" },
  "65735": { state: "MO", city: "QUINCY" },
  "65737": { state: "MO", city: "REEDS SPRING" },
  "65738": { state: "MO", city: "REPUBLIC" },
  "65739": { state: "MO", city: "RIDGEDALE" },
  "65740": { state: "MO", city: "ROCKAWAY BEACH" },
  "65741": { state: "MO", city: "ROCKBRIDGE" },
  "65742": { state: "MO", city: "ROGERSVILLE" },
  "65744": { state: "MO", city: "RUETER" },
  "65745": { state: "MO", city: "SELIGMAN" },
  "65746": { state: "MO", city: "SEYMOUR" },
  "65747": { state: "MO", city: "SHELL KNOB" },
  "65752": { state: "MO", city: "SOUTH GREENFIELD" },
  "65753": { state: "MO", city: "SPARTA" },
  "65754": { state: "MO", city: "SPOKANE" },
  "65755": { state: "MO", city: "SQUIRES" },
  "65756": { state: "MO", city: "STOTTS CITY" },
  "65757": { state: "MO", city: "STRAFFORD" },
  "65759": { state: "MO", city: "TANEYVILLE" },
  "65760": { state: "MO", city: "TECUMSEH" },
  "65761": { state: "MO", city: "THEODOSIA" },
  "65762": { state: "MO", city: "THORNFIELD" },
  "65764": { state: "MO", city: "TUNAS" },
  "65765": { state: "MO", city: "TURNERS" },
  "65766": { state: "MO", city: "UDALL" },
  "65767": { state: "MO", city: "URBANA" },
  "65768": { state: "MO", city: "VANZANT" },
  "65769": { state: "MO", city: "VERONA" },
  "65770": { state: "MO", city: "WALNUT GROVE" },
  "65771": { state: "MO", city: "WALNUT SHADE" },
  "65772": { state: "MO", city: "WASHBURN" },
  "65773": { state: "MO", city: "WASOLA" },
  "65774": { state: "MO", city: "WEAUBLEAU" },
  "65775": { state: "MO", city: "WEST PLAINS" },
  "65777": { state: "MO", city: "MOODY" },
  "65778": { state: "MO", city: "MYRTLE" },
  "65779": { state: "MO", city: "WHEATLAND" },
  "65781": { state: "MO", city: "WILLARD" },
  "65783": { state: "MO", city: "WINDYVILLE" },
  "65784": { state: "MO", city: "ZANONI" },
  "65785": { state: "MO", city: "STOCKTON" },
  "65786": { state: "MO", city: "MACKS CREEK" },
  "65787": { state: "MO", city: "ROACH" },
  "65788": { state: "MO", city: "PEACE VALLEY" },
  "65789": { state: "MO", city: "POMONA" },
  "65790": { state: "MO", city: "POTTERSVILLE" },
  "65791": { state: "MO", city: "THAYER" },
  "65793": { state: "MO", city: "WILLOW SPRINGS" },
  "65801": { state: "MO", city: "SPRINGFIELD" },
  "65802": { state: "MO", city: "SPRINGFIELD" },
  "65803": { state: "MO", city: "SPRINGFIELD" },
  "65804": { state: "MO", city: "SPRINGFIELD" },
  "65805": { state: "MO", city: "SPRINGFIELD" },
  "65806": { state: "MO", city: "SPRINGFIELD" },
  "65807": { state: "MO", city: "SPRINGFIELD" },
  "65808": { state: "MO", city: "SPRINGFIELD" },
  "65809": { state: "MO", city: "SPRINGFIELD" },
  "65810": { state: "MO", city: "SPRINGFIELD" },
  "65814": { state: "MO", city: "SPRINGFIELD" },
  "65817": { state: "MO", city: "SPRINGFIELD" },
  "65890": { state: "MO", city: "SPRINGFIELD" },
  "65897": { state: "MO", city: "SPRINGFIELD" },
  "65898": { state: "MO", city: "SPRINGFIELD" },
  "65899": { state: "MO", city: "SPRINGFIELD" },
  "66002": { state: "KS", city: "ATCHISON" },
  "66006": { state: "KS", city: "BALDWIN CITY" },
  "66007": { state: "KS", city: "BASEHOR" },
  "66008": { state: "KS", city: "BENDENA" },
  "66010": { state: "KS", city: "BLUE MOUND" },
  "66012": { state: "KS", city: "BONNER SPRINGS" },
  "66013": { state: "KS", city: "BUCYRUS" },
  "66014": { state: "KS", city: "CENTERVILLE" },
  "66015": { state: "KS", city: "COLONY" },
  "66016": { state: "KS", city: "CUMMINGS" },
  "66017": { state: "KS", city: "DENTON" },
  "66018": { state: "KS", city: "DE SOTO" },
  "66020": { state: "KS", city: "EASTON" },
  "66021": { state: "KS", city: "EDGERTON" },
  "66023": { state: "KS", city: "EFFINGHAM" },
  "66024": { state: "KS", city: "ELWOOD" },
  "66025": { state: "KS", city: "EUDORA" },
  "66026": { state: "KS", city: "FONTANA" },
  "66027": { state: "KS", city: "FORT LEAVENWORTH" },
  "66030": { state: "KS", city: "GARDNER" },
  "66031": { state: "KS", city: "NEW CENTURY" },
  "66032": { state: "KS", city: "GARNETT" },
  "66033": { state: "KS", city: "GREELEY" },
  "66035": { state: "KS", city: "HIGHLAND" },
  "66036": { state: "KS", city: "HILLSDALE" },
  "66039": { state: "KS", city: "KINCAID" },
  "66040": { state: "KS", city: "LA CYGNE" },
  "66041": { state: "KS", city: "LANCASTER" },
  "66042": { state: "KS", city: "LANE" },
  "66043": { state: "KS", city: "LANSING" },
  "66044": { state: "KS", city: "LAWRENCE" },
  "66045": { state: "KS", city: "LAWRENCE" },
  "66046": { state: "KS", city: "LAWRENCE" },
  "66047": { state: "KS", city: "LAWRENCE" },
  "66048": { state: "KS", city: "LEAVENWORTH" },
  "66049": { state: "KS", city: "LAWRENCE" },
  "66050": { state: "KS", city: "LECOMPTON" },
  "66051": { state: "KS", city: "OLATHE" },
  "66052": { state: "KS", city: "LINWOOD" },
  "66053": { state: "KS", city: "LOUISBURG" },
  "66054": { state: "KS", city: "MC LOUTH" },
  "66056": { state: "KS", city: "MOUND CITY" },
  "66058": { state: "KS", city: "MUSCOTAH" },
  "66060": { state: "KS", city: "NORTONVILLE" },
  "66061": { state: "KS", city: "OLATHE" },
  "66062": { state: "KS", city: "OLATHE" },
  "66063": { state: "KS", city: "OLATHE" },
  "66064": { state: "KS", city: "OSAWATOMIE" },
  "66066": { state: "KS", city: "OSKALOOSA" },
  "66067": { state: "KS", city: "OTTAWA" },
  "66070": { state: "KS", city: "OZAWKIE" },
  "66071": { state: "KS", city: "PAOLA" },
  "66072": { state: "KS", city: "PARKER" },
  "66073": { state: "KS", city: "PERRY" },
  "66075": { state: "KS", city: "PLEASANTON" },
  "66076": { state: "KS", city: "POMONA" },
  "66078": { state: "KS", city: "PRINCETON" },
  "66079": { state: "KS", city: "RANTOUL" },
  "66080": { state: "KS", city: "RICHMOND" },
  "66083": { state: "KS", city: "SPRING HILL" },
  "66085": { state: "KS", city: "STILWELL" },
  "66086": { state: "KS", city: "TONGANOXIE" },
  "66087": { state: "KS", city: "TROY" },
  "66088": { state: "KS", city: "VALLEY FALLS" },
  "66090": { state: "KS", city: "WATHENA" },
  "66091": { state: "KS", city: "WELDA" },
  "66092": { state: "KS", city: "WELLSVILLE" },
  "66093": { state: "KS", city: "WESTPHALIA" },
  "66094": { state: "KS", city: "WHITE CLOUD" },
  "66095": { state: "KS", city: "WILLIAMSBURG" },
  "66097": { state: "KS", city: "WINCHESTER" },
  "66101": { state: "KS", city: "KANSAS CITY" },
  "66102": { state: "KS", city: "KANSAS CITY" },
  "66103": { state: "KS", city: "KANSAS CITY" },
  "66104": { state: "KS", city: "KANSAS CITY" },
  "66105": { state: "KS", city: "KANSAS CITY" },
  "66106": { state: "KS", city: "KANSAS CITY" },
  "66109": { state: "KS", city: "KANSAS CITY" },
  "66110": { state: "KS", city: "KANSAS CITY" },
  "66111": { state: "KS", city: "KANSAS CITY" },
  "66112": { state: "KS", city: "KANSAS CITY" },
  "66113": { state: "KS", city: "EDWARDSVILLE" },
  "66115": { state: "KS", city: "KANSAS CITY" },
  "66117": { state: "KS", city: "KANSAS CITY" },
  "66118": { state: "KS", city: "KANSAS CITY" },
  "66119": { state: "KS", city: "KANSAS CITY" },
  "66160": { state: "KS", city: "KANSAS CITY" },
  "66201": { state: "KS", city: "MISSION" },
  "66202": { state: "KS", city: "MISSION" },
  "66203": { state: "KS", city: "SHAWNEE" },
  "66204": { state: "KS", city: "OVERLAND PARK" },
  "66205": { state: "KS", city: "MISSION" },
  "66206": { state: "KS", city: "LEAWOOD" },
  "66207": { state: "KS", city: "OVERLAND PARK" },
  "66208": { state: "KS", city: "PRAIRIE VILLAGE" },
  "66209": { state: "KS", city: "LEAWOOD" },
  "66210": { state: "KS", city: "OVERLAND PARK" },
  "66211": { state: "KS", city: "LEAWOOD" },
  "66212": { state: "KS", city: "OVERLAND PARK" },
  "66213": { state: "KS", city: "OVERLAND PARK" },
  "66214": { state: "KS", city: "OVERLAND PARK" },
  "66215": { state: "KS", city: "LENEXA" },
  "66216": { state: "KS", city: "SHAWNEE" },
  "66217": { state: "KS", city: "SHAWNEE" },
  "66218": { state: "KS", city: "SHAWNEE" },
  "66219": { state: "KS", city: "LENEXA" },
  "66220": { state: "KS", city: "LENEXA" },
  "66221": { state: "KS", city: "OVERLAND PARK" },
  "66222": { state: "KS", city: "MISSION" },
  "66223": { state: "KS", city: "OVERLAND PARK" },
  "66224": { state: "KS", city: "OVERLAND PARK" },
  "66225": { state: "KS", city: "OVERLAND PARK" },
  "66226": { state: "KS", city: "SHAWNEE" },
  "66227": { state: "KS", city: "LENEXA" },
  "66250": { state: "KS", city: "LENEXA" },
  "66251": { state: "KS", city: "OVERLAND PARK" },
  "66276": { state: "KS", city: "LENEXA" },
  "66282": { state: "KS", city: "OVERLAND PARK" },
  "66283": { state: "KS", city: "OVERLAND PARK" },
  "66285": { state: "KS", city: "LENEXA" },
  "66286": { state: "KS", city: "SHAWNEE" },
  "66401": { state: "KS", city: "ALMA" },
  "66402": { state: "KS", city: "AUBURN" },
  "66403": { state: "KS", city: "AXTELL" },
  "66404": { state: "KS", city: "BAILEYVILLE" },
  "66406": { state: "KS", city: "BEATTIE" },
  "66407": { state: "KS", city: "BELVUE" },
  "66408": { state: "KS", city: "BERN" },
  "66409": { state: "KS", city: "BERRYTON" },
  "66411": { state: "KS", city: "BLUE RAPIDS" },
  "66412": { state: "KS", city: "BREMEN" },
  "66413": { state: "KS", city: "BURLINGAME" },
  "66414": { state: "KS", city: "CARBONDALE" },
  "66415": { state: "KS", city: "CENTRALIA" },
  "66416": { state: "KS", city: "CIRCLEVILLE" },
  "66417": { state: "KS", city: "CORNING" },
  "66418": { state: "KS", city: "DELIA" },
  "66419": { state: "KS", city: "DENISON" },
  "66420": { state: "KS", city: "DOVER" },
  "66422": { state: "KS", city: "EMMETT" },
  "66423": { state: "KS", city: "ESKRIDGE" },
  "66424": { state: "KS", city: "EVEREST" },
  "66425": { state: "KS", city: "FAIRVIEW" },
  "66426": { state: "KS", city: "FOSTORIA" },
  "66427": { state: "KS", city: "FRANKFORT" },
  "66428": { state: "KS", city: "GOFF" },
  "66429": { state: "KS", city: "GRANTVILLE" },
  "66431": { state: "KS", city: "HARVEYVILLE" },
  "66432": { state: "KS", city: "HAVENSVILLE" },
  "66434": { state: "KS", city: "HIAWATHA" },
  "66436": { state: "KS", city: "HOLTON" },
  "66438": { state: "KS", city: "HOME" },
  "66439": { state: "KS", city: "HORTON" },
  "66440": { state: "KS", city: "HOYT" },
  "66441": { state: "KS", city: "JUNCTION CITY" },
  "66442": { state: "KS", city: "FORT RILEY" },
  "66449": { state: "KS", city: "LEONARDVILLE" },
  "66451": { state: "KS", city: "LYNDON" },
  "66501": { state: "KS", city: "MC FARLAND" },
  "66502": { state: "KS", city: "MANHATTAN" },
  "66503": { state: "KS", city: "MANHATTAN" },
  "66505": { state: "KS", city: "MANHATTAN" },
  "66506": { state: "KS", city: "MANHATTAN" },
  "66507": { state: "KS", city: "MAPLE HILL" },
  "66508": { state: "KS", city: "MARYSVILLE" },
  "66509": { state: "KS", city: "MAYETTA" },
  "66510": { state: "KS", city: "MELVERN" },
  "66512": { state: "KS", city: "MERIDEN" },
  "66514": { state: "KS", city: "MILFORD" },
  "66515": { state: "KS", city: "MORRILL" },
  "66516": { state: "KS", city: "NETAWAKA" },
  "66517": { state: "KS", city: "OGDEN" },
  "66518": { state: "KS", city: "OKETO" },
  "66520": { state: "KS", city: "OLSBURG" },
  "66521": { state: "KS", city: "ONAGA" },
  "66522": { state: "KS", city: "ONEIDA" },
  "66523": { state: "KS", city: "OSAGE CITY" },
  "66524": { state: "KS", city: "OVERBROOK" },
  "66526": { state: "KS", city: "PAXICO" },
  "66527": { state: "KS", city: "POWHATTAN" },
  "66528": { state: "KS", city: "QUENEMO" },
  "66531": { state: "KS", city: "RILEY" },
  "66532": { state: "KS", city: "ROBINSON" },
  "66533": { state: "KS", city: "ROSSVILLE" },
  "66534": { state: "KS", city: "SABETHA" },
  "66535": { state: "KS", city: "SAINT GEORGE" },
  "66536": { state: "KS", city: "SAINT MARYS" },
  "66537": { state: "KS", city: "SCRANTON" },
  "66538": { state: "KS", city: "SENECA" },
  "66539": { state: "KS", city: "SILVER LAKE" },
  "66540": { state: "KS", city: "SOLDIER" },
  "66541": { state: "KS", city: "SUMMERFIELD" },
  "66542": { state: "KS", city: "TECUMSEH" },
  "66543": { state: "KS", city: "VASSAR" },
  "66544": { state: "KS", city: "VERMILLION" },
  "66546": { state: "KS", city: "WAKARUSA" },
  "66547": { state: "KS", city: "WAMEGO" },
  "66548": { state: "KS", city: "WATERVILLE" },
  "66549": { state: "KS", city: "WESTMORELAND" },
  "66550": { state: "KS", city: "WETMORE" },
  "66552": { state: "KS", city: "WHITING" },
  "66554": { state: "KS", city: "RANDOLPH" },
  "66601": { state: "KS", city: "TOPEKA" },
  "66603": { state: "KS", city: "TOPEKA" },
  "66604": { state: "KS", city: "TOPEKA" },
  "66605": { state: "KS", city: "TOPEKA" },
  "66606": { state: "KS", city: "TOPEKA" },
  "66607": { state: "KS", city: "TOPEKA" },
  "66608": { state: "KS", city: "TOPEKA" },
  "66609": { state: "KS", city: "TOPEKA" },
  "66610": { state: "KS", city: "TOPEKA" },
  "66611": { state: "KS", city: "TOPEKA" },
  "66612": { state: "KS", city: "TOPEKA" },
  "66614": { state: "KS", city: "TOPEKA" },
  "66615": { state: "KS", city: "TOPEKA" },
  "66616": { state: "KS", city: "TOPEKA" },
  "66617": { state: "KS", city: "TOPEKA" },
  "66618": { state: "KS", city: "TOPEKA" },
  "66619": { state: "KS", city: "TOPEKA" },
  "66620": { state: "KS", city: "TOPEKA" },
  "66621": { state: "KS", city: "TOPEKA" },
  "66622": { state: "KS", city: "TOPEKA" },
  "66624": { state: "KS", city: "TOPEKA" },
  "66625": { state: "KS", city: "TOPEKA" },
  "66626": { state: "KS", city: "TOPEKA" },
  "66629": { state: "KS", city: "TOPEKA" },
  "66630": { state: "KS", city: "TOPEKA" },
  "66636": { state: "KS", city: "TOPEKA" },
  "66647": { state: "KS", city: "TOPEKA" },
  "66667": { state: "KS", city: "TOPEKA" },
  "66675": { state: "KS", city: "TOPEKA" },
  "66683": { state: "KS", city: "TOPEKA" },
  "66699": { state: "KS", city: "TOPEKA" },
  "66701": { state: "KS", city: "FORT SCOTT" },
  "66710": { state: "KS", city: "ALTOONA" },
  "66711": { state: "KS", city: "ARCADIA" },
  "66712": { state: "KS", city: "ARMA" },
  "66713": { state: "KS", city: "BAXTER SPRINGS" },
  "66714": { state: "KS", city: "BENEDICT" },
  "66716": { state: "KS", city: "BRONSON" },
  "66717": { state: "KS", city: "BUFFALO" },
  "66720": { state: "KS", city: "CHANUTE" },
  "66724": { state: "KS", city: "CHEROKEE" },
  "66725": { state: "KS", city: "COLUMBUS" },
  "66728": { state: "KS", city: "CRESTLINE" },
  "66732": { state: "KS", city: "ELSMORE" },
  "66733": { state: "KS", city: "ERIE" },
  "66734": { state: "KS", city: "FARLINGTON" },
  "66735": { state: "KS", city: "FRANKLIN" },
  "66736": { state: "KS", city: "FREDONIA" },
  "66738": { state: "KS", city: "FULTON" },
  "66739": { state: "KS", city: "GALENA" },
  "66740": { state: "KS", city: "GALESBURG" },
  "66741": { state: "KS", city: "GARLAND" },
  "66742": { state: "KS", city: "GAS" },
  "66743": { state: "KS", city: "GIRARD" },
  "66746": { state: "KS", city: "HEPLER" },
  "66748": { state: "KS", city: "HUMBOLDT" },
  "66749": { state: "KS", city: "IOLA" },
  "66751": { state: "KS", city: "LA HARPE" },
  "66753": { state: "KS", city: "MC CUNE" },
  "66754": { state: "KS", city: "MAPLETON" },
  "66755": { state: "KS", city: "MORAN" },
  "66756": { state: "KS", city: "MULBERRY" },
  "66757": { state: "KS", city: "NEODESHA" },
  "66758": { state: "KS", city: "NEOSHO FALLS" },
  "66760": { state: "KS", city: "OPOLIS" },
  "66761": { state: "KS", city: "PIQUA" },
  "66762": { state: "KS", city: "PITTSBURG" },
  "66763": { state: "KS", city: "FRONTENAC" },
  "66767": { state: "KS", city: "PRESCOTT" },
  "66769": { state: "KS", city: "REDFIELD" },
  "66770": { state: "KS", city: "RIVERTON" },
  "66771": { state: "KS", city: "SAINT PAUL" },
  "66772": { state: "KS", city: "SAVONBURG" },
  "66773": { state: "KS", city: "SCAMMON" },
  "66775": { state: "KS", city: "STARK" },
  "66776": { state: "KS", city: "THAYER" },
  "66777": { state: "KS", city: "TORONTO" },
  "66778": { state: "KS", city: "TREECE" },
  "66779": { state: "KS", city: "UNIONTOWN" },
  "66780": { state: "KS", city: "WALNUT" },
  "66781": { state: "KS", city: "WEIR" },
  "66782": { state: "KS", city: "WEST MINERAL" },
  "66783": { state: "KS", city: "YATES CENTER" },
  "66801": { state: "KS", city: "EMPORIA" },
  "66830": { state: "KS", city: "ADMIRE" },
  "66833": { state: "KS", city: "ALLEN" },
  "66834": { state: "KS", city: "ALTA VISTA" },
  "66835": { state: "KS", city: "AMERICUS" },
  "66838": { state: "KS", city: "BURDICK" },
  "66839": { state: "KS", city: "BURLINGTON" },
  "66840": { state: "KS", city: "BURNS" },
  "66842": { state: "KS", city: "CASSODAY" },
  "66843": { state: "KS", city: "CEDAR POINT" },
  "66845": { state: "KS", city: "COTTONWOOD FALLS" },
  "66846": { state: "KS", city: "COUNCIL GROVE" },
  "66849": { state: "KS", city: "DWIGHT" },
  "66850": { state: "KS", city: "ELMDALE" },
  "66851": { state: "KS", city: "FLORENCE" },
  "66852": { state: "KS", city: "GRIDLEY" },
  "66853": { state: "KS", city: "HAMILTON" },
  "66854": { state: "KS", city: "HARTFORD" },
  "66855": { state: "KS", city: "LAMONT" },
  "66856": { state: "KS", city: "LEBO" },
  "66857": { state: "KS", city: "LE ROY" },
  "66858": { state: "KS", city: "LINCOLNVILLE" },
  "66859": { state: "KS", city: "LOST SPRINGS" },
  "66860": { state: "KS", city: "MADISON" },
  "66861": { state: "KS", city: "MARION" },
  "66862": { state: "KS", city: "MATFIELD GREEN" },
  "66863": { state: "KS", city: "NEAL" },
  "66864": { state: "KS", city: "NEOSHO RAPIDS" },
  "66865": { state: "KS", city: "OLPE" },
  "66866": { state: "KS", city: "PEABODY" },
  "66868": { state: "KS", city: "READING" },
  "66869": { state: "KS", city: "STRONG CITY" },
  "66870": { state: "KS", city: "VIRGIL" },
  "66871": { state: "KS", city: "WAVERLY" },
  "66872": { state: "KS", city: "WHITE CITY" },
  "66873": { state: "KS", city: "WILSEY" },
  "66901": { state: "KS", city: "CONCORDIA" },
  "66930": { state: "KS", city: "AGENDA" },
  "66932": { state: "KS", city: "ATHOL" },
  "66933": { state: "KS", city: "BARNES" },
  "66935": { state: "KS", city: "BELLEVILLE" },
  "66936": { state: "KS", city: "BURR OAK" },
  "66937": { state: "KS", city: "CLIFTON" },
  "66938": { state: "KS", city: "CLYDE" },
  "66939": { state: "KS", city: "COURTLAND" },
  "66940": { state: "KS", city: "CUBA" },
  "66941": { state: "KS", city: "ESBON" },
  "66942": { state: "KS", city: "FORMOSO" },
  "66943": { state: "KS", city: "GREENLEAF" },
  "66944": { state: "KS", city: "HADDAM" },
  "66945": { state: "KS", city: "HANOVER" },
  "66946": { state: "KS", city: "HOLLENBERG" },
  "66948": { state: "KS", city: "JAMESTOWN" },
  "66949": { state: "KS", city: "JEWELL" },
  "66951": { state: "KS", city: "KENSINGTON" },
  "66952": { state: "KS", city: "LEBANON" },
  "66953": { state: "KS", city: "LINN" },
  "66955": { state: "KS", city: "MAHASKA" },
  "66956": { state: "KS", city: "MANKATO" },
  "66958": { state: "KS", city: "MORROWVILLE" },
  "66959": { state: "KS", city: "MUNDEN" },
  "66960": { state: "KS", city: "NARKA" },
  "66961": { state: "KS", city: "NORWAY" },
  "66962": { state: "KS", city: "PALMER" },
  "66963": { state: "KS", city: "RANDALL" },
  "66964": { state: "KS", city: "REPUBLIC" },
  "66966": { state: "KS", city: "SCANDIA" },
  "66967": { state: "KS", city: "SMITH CENTER" },
  "66968": { state: "KS", city: "WASHINGTON" },
  "66970": { state: "KS", city: "WEBBER" },
  "67001": { state: "KS", city: "ANDALE" },
  "67002": { state: "KS", city: "ANDOVER" },
  "67003": { state: "KS", city: "ANTHONY" },
  "67004": { state: "KS", city: "ARGONIA" },
  "67005": { state: "KS", city: "ARKANSAS CITY" },
  "67008": { state: "KS", city: "ATLANTA" },
  "67009": { state: "KS", city: "ATTICA" },
  "67010": { state: "KS", city: "AUGUSTA" },
  "67012": { state: "KS", city: "BEAUMONT" },
  "67013": { state: "KS", city: "BELLE PLAINE" },
  "67016": { state: "KS", city: "BENTLEY" },
  "67017": { state: "KS", city: "BENTON" },
  "67018": { state: "KS", city: "BLUFF CITY" },
  "67019": { state: "KS", city: "BURDEN" },
  "67020": { state: "KS", city: "BURRTON" },
  "67021": { state: "KS", city: "BYERS" },
  "67022": { state: "KS", city: "CALDWELL" },
  "67023": { state: "KS", city: "CAMBRIDGE" },
  "67024": { state: "KS", city: "CEDAR VALE" },
  "67025": { state: "KS", city: "CHENEY" },
  "67026": { state: "KS", city: "CLEARWATER" },
  "67028": { state: "KS", city: "COATS" },
  "67029": { state: "KS", city: "COLDWATER" },
  "67030": { state: "KS", city: "COLWICH" },
  "67031": { state: "KS", city: "CONWAY SPRINGS" },
  "67035": { state: "KS", city: "CUNNINGHAM" },
  "67036": { state: "KS", city: "DANVILLE" },
  "67037": { state: "KS", city: "DERBY" },
  "67038": { state: "KS", city: "DEXTER" },
  "67039": { state: "KS", city: "DOUGLASS" },
  "67041": { state: "KS", city: "ELBING" },
  "67042": { state: "KS", city: "EL DORADO" },
  "67045": { state: "KS", city: "EUREKA" },
  "67047": { state: "KS", city: "FALL RIVER" },
  "67049": { state: "KS", city: "FREEPORT" },
  "67050": { state: "KS", city: "GARDEN PLAIN" },
  "67051": { state: "KS", city: "GEUDA SPRINGS" },
  "67052": { state: "KS", city: "GODDARD" },
  "67053": { state: "KS", city: "GOESSEL" },
  "67054": { state: "KS", city: "GREENSBURG" },
  "67055": { state: "KS", city: "GREENWICH" },
  "67056": { state: "KS", city: "HALSTEAD" },
  "67057": { state: "KS", city: "HARDTNER" },
  "67058": { state: "KS", city: "HARPER" },
  "67059": { state: "KS", city: "HAVILAND" },
  "67060": { state: "KS", city: "HAYSVILLE" },
  "67061": { state: "KS", city: "HAZELTON" },
  "67062": { state: "KS", city: "HESSTON" },
  "67063": { state: "KS", city: "HILLSBORO" },
  "67065": { state: "KS", city: "ISABEL" },
  "67066": { state: "KS", city: "IUKA" },
  "67067": { state: "KS", city: "KECHI" },
  "67068": { state: "KS", city: "KINGMAN" },
  "67070": { state: "KS", city: "KIOWA" },
  "67071": { state: "KS", city: "LAKE CITY" },
  "67072": { state: "KS", city: "LATHAM" },
  "67073": { state: "KS", city: "LEHIGH" },
  "67074": { state: "KS", city: "LEON" },
  "67101": { state: "KS", city: "MAIZE" },
  "67102": { state: "KS", city: "MAPLE CITY" },
  "67103": { state: "KS", city: "MAYFIELD" },
  "67104": { state: "KS", city: "MEDICINE LODGE" },
  "67105": { state: "KS", city: "MILAN" },
  "67106": { state: "KS", city: "MILTON" },
  "67107": { state: "KS", city: "MOUNDRIDGE" },
  "67108": { state: "KS", city: "MOUNT HOPE" },
  "67109": { state: "KS", city: "MULLINVILLE" },
  "67110": { state: "KS", city: "MULVANE" },
  "67111": { state: "KS", city: "MURDOCK" },
  "67112": { state: "KS", city: "NASHVILLE" },
  "67114": { state: "KS", city: "NEWTON" },
  "67117": { state: "KS", city: "NORTH NEWTON" },
  "67118": { state: "KS", city: "NORWICH" },
  "67119": { state: "KS", city: "OXFORD" },
  "67120": { state: "KS", city: "PECK" },
  "67122": { state: "KS", city: "PIEDMONT" },
  "67123": { state: "KS", city: "POTWIN" },
  "67124": { state: "KS", city: "PRATT" },
  "67127": { state: "KS", city: "PROTECTION" },
  "67131": { state: "KS", city: "ROCK" },
  "67132": { state: "KS", city: "ROSALIA" },
  "67133": { state: "KS", city: "ROSE HILL" },
  "67134": { state: "KS", city: "SAWYER" },
  "67135": { state: "KS", city: "SEDGWICK" },
  "67137": { state: "KS", city: "SEVERY" },
  "67138": { state: "KS", city: "SHARON" },
  "67140": { state: "KS", city: "SOUTH HAVEN" },
  "67142": { state: "KS", city: "SPIVEY" },
  "67143": { state: "KS", city: "SUN CITY" },
  "67144": { state: "KS", city: "TOWANDA" },
  "67146": { state: "KS", city: "UDALL" },
  "67147": { state: "KS", city: "VALLEY CENTER" },
  "67149": { state: "KS", city: "VIOLA" },
  "67150": { state: "KS", city: "WALDRON" },
  "67151": { state: "KS", city: "WALTON" },
  "67152": { state: "KS", city: "WELLINGTON" },
  "67154": { state: "KS", city: "WHITEWATER" },
  "67155": { state: "KS", city: "WILMORE" },
  "67156": { state: "KS", city: "WINFIELD" },
  "67159": { state: "KS", city: "ZENDA" },
  "67201": { state: "KS", city: "WICHITA" },
  "67202": { state: "KS", city: "WICHITA" },
  "67203": { state: "KS", city: "WICHITA" },
  "67204": { state: "KS", city: "WICHITA" },
  "67205": { state: "KS", city: "WICHITA" },
  "67206": { state: "KS", city: "WICHITA" },
  "67207": { state: "KS", city: "WICHITA" },
  "67208": { state: "KS", city: "WICHITA" },
  "67209": { state: "KS", city: "WICHITA" },
  "67210": { state: "KS", city: "WICHITA" },
  "67211": { state: "KS", city: "WICHITA" },
  "67212": { state: "KS", city: "WICHITA" },
  "67213": { state: "KS", city: "WICHITA" },
  "67214": { state: "KS", city: "WICHITA" },
  "67215": { state: "KS", city: "WICHITA" },
  "67216": { state: "KS", city: "WICHITA" },
  "67217": { state: "KS", city: "WICHITA" },
  "67218": { state: "KS", city: "WICHITA" },
  "67219": { state: "KS", city: "WICHITA" },
  "67220": { state: "KS", city: "WICHITA" },
  "67221": { state: "KS", city: "MCCONNELL AFB" },
  "67223": { state: "KS", city: "WICHITA" },
  "67226": { state: "KS", city: "WICHITA" },
  "67227": { state: "KS", city: "WICHITA" },
  "67228": { state: "KS", city: "WICHITA" },
  "67230": { state: "KS", city: "WICHITA" },
  "67232": { state: "KS", city: "WICHITA" },
  "67235": { state: "KS", city: "WICHITA" },
  "67260": { state: "KS", city: "WICHITA" },
  "67275": { state: "KS", city: "WICHITA" },
  "67276": { state: "KS", city: "WICHITA" },
  "67277": { state: "KS", city: "WICHITA" },
  "67278": { state: "KS", city: "WICHITA" },
  "67301": { state: "KS", city: "INDEPENDENCE" },
  "67330": { state: "KS", city: "ALTAMONT" },
  "67332": { state: "KS", city: "BARTLETT" },
  "67333": { state: "KS", city: "CANEY" },
  "67334": { state: "KS", city: "CHAUTAUQUA" },
  "67335": { state: "KS", city: "CHERRYVALE" },
  "67336": { state: "KS", city: "CHETOPA" },
  "67337": { state: "KS", city: "COFFEYVILLE" },
  "67340": { state: "KS", city: "DEARING" },
  "67341": { state: "KS", city: "DENNIS" },
  "67342": { state: "KS", city: "EDNA" },
  "67344": { state: "KS", city: "ELK CITY" },
  "67345": { state: "KS", city: "ELK FALLS" },
  "67346": { state: "KS", city: "GRENOLA" },
  "67347": { state: "KS", city: "HAVANA" },
  "67349": { state: "KS", city: "HOWARD" },
  "67351": { state: "KS", city: "LIBERTY" },
  "67352": { state: "KS", city: "LONGTON" },
  "67353": { state: "KS", city: "MOLINE" },
  "67354": { state: "KS", city: "MOUND VALLEY" },
  "67355": { state: "KS", city: "NIOTAZE" },
  "67356": { state: "KS", city: "OSWEGO" },
  "67357": { state: "KS", city: "PARSONS" },
  "67360": { state: "KS", city: "PERU" },
  "67361": { state: "KS", city: "SEDAN" },
  "67363": { state: "KS", city: "SYCAMORE" },
  "67364": { state: "KS", city: "TYRO" },
  "67401": { state: "KS", city: "SALINA" },
  "67402": { state: "KS", city: "SALINA" },
  "67410": { state: "KS", city: "ABILENE" },
  "67416": { state: "KS", city: "ASSARIA" },
  "67417": { state: "KS", city: "AURORA" },
  "67418": { state: "KS", city: "BARNARD" },
  "67420": { state: "KS", city: "BELOIT" },
  "67422": { state: "KS", city: "BENNINGTON" },
  "67423": { state: "KS", city: "BEVERLY" },
  "67425": { state: "KS", city: "BROOKVILLE" },
  "67427": { state: "KS", city: "BUSHTON" },
  "67428": { state: "KS", city: "CANTON" },
  "67430": { state: "KS", city: "CAWKER CITY" },
  "67431": { state: "KS", city: "CHAPMAN" },
  "67432": { state: "KS", city: "CLAY CENTER" },
  "67436": { state: "KS", city: "DELPHOS" },
  "67437": { state: "KS", city: "DOWNS" },
  "67438": { state: "KS", city: "DURHAM" },
  "67439": { state: "KS", city: "ELLSWORTH" },
  "67441": { state: "KS", city: "ENTERPRISE" },
  "67442": { state: "KS", city: "FALUN" },
  "67443": { state: "KS", city: "GALVA" },
  "67444": { state: "KS", city: "GENESEO" },
  "67445": { state: "KS", city: "GLASCO" },
  "67446": { state: "KS", city: "GLEN ELDER" },
  "67447": { state: "KS", city: "GREEN" },
  "67448": { state: "KS", city: "GYPSUM" },
  "67449": { state: "KS", city: "HERINGTON" },
  "67450": { state: "KS", city: "HOLYROOD" },
  "67451": { state: "KS", city: "HOPE" },
  "67452": { state: "KS", city: "HUNTER" },
  "67454": { state: "KS", city: "KANOPOLIS" },
  "67455": { state: "KS", city: "LINCOLN" },
  "67456": { state: "KS", city: "LINDSBORG" },
  "67457": { state: "KS", city: "LITTLE RIVER" },
  "67458": { state: "KS", city: "LONGFORD" },
  "67459": { state: "KS", city: "LORRAINE" },
  "67460": { state: "KS", city: "MCPHERSON" },
  "67464": { state: "KS", city: "MARQUETTE" },
  "67466": { state: "KS", city: "MILTONVALE" },
  "67467": { state: "KS", city: "MINNEAPOLIS" },
  "67468": { state: "KS", city: "MORGANVILLE" },
  "67470": { state: "KS", city: "NEW CAMBRIA" },
  "67473": { state: "KS", city: "OSBORNE" },
  "67474": { state: "KS", city: "PORTIS" },
  "67475": { state: "KS", city: "RAMONA" },
  "67476": { state: "KS", city: "ROXBURY" },
  "67478": { state: "KS", city: "SIMPSON" },
  "67480": { state: "KS", city: "SOLOMON" },
  "67481": { state: "KS", city: "SYLVAN GROVE" },
  "67482": { state: "KS", city: "TALMAGE" },
  "67483": { state: "KS", city: "TAMPA" },
  "67484": { state: "KS", city: "TESCOTT" },
  "67485": { state: "KS", city: "TIPTON" },
  "67487": { state: "KS", city: "WAKEFIELD" },
  "67490": { state: "KS", city: "WILSON" },
  "67491": { state: "KS", city: "WINDOM" },
  "67492": { state: "KS", city: "WOODBINE" },
  "67501": { state: "KS", city: "HUTCHINSON" },
  "67502": { state: "KS", city: "HUTCHINSON" },
  "67504": { state: "KS", city: "HUTCHINSON" },
  "67505": { state: "KS", city: "SOUTH HUTCHINSON" },
  "67510": { state: "KS", city: "ABBYVILLE" },
  "67511": { state: "KS", city: "ALBERT" },
  "67512": { state: "KS", city: "ALDEN" },
  "67513": { state: "KS", city: "ALEXANDER" },
  "67514": { state: "KS", city: "ARLINGTON" },
  "67515": { state: "KS", city: "ARNOLD" },
  "67516": { state: "KS", city: "BAZINE" },
  "67518": { state: "KS", city: "BEELER" },
  "67519": { state: "KS", city: "BELPRE" },
  "67520": { state: "KS", city: "BISON" },
  "67521": { state: "KS", city: "BROWNELL" },
  "67522": { state: "KS", city: "BUHLER" },
  "67523": { state: "KS", city: "BURDETT" },
  "67524": { state: "KS", city: "CHASE" },
  "67525": { state: "KS", city: "CLAFLIN" },
  "67526": { state: "KS", city: "ELLINWOOD" },
  "67529": { state: "KS", city: "GARFIELD" },
  "67530": { state: "KS", city: "GREAT BEND" },
  "67543": { state: "KS", city: "HAVEN" },
  "67544": { state: "KS", city: "HOISINGTON" },
  "67545": { state: "KS", city: "HUDSON" },
  "67546": { state: "KS", city: "INMAN" },
  "67547": { state: "KS", city: "KINSLEY" },
  "67548": { state: "KS", city: "LA CROSSE" },
  "67550": { state: "KS", city: "LARNED" },
  "67552": { state: "KS", city: "LEWIS" },
  "67553": { state: "KS", city: "LIEBENTHAL" },
  "67554": { state: "KS", city: "LYONS" },
  "67556": { state: "KS", city: "MC CRACKEN" },
  "67557": { state: "KS", city: "MACKSVILLE" },
  "67559": { state: "KS", city: "NEKOMA" },
  "67560": { state: "KS", city: "NESS CITY" },
  "67561": { state: "KS", city: "NICKERSON" },
  "67563": { state: "KS", city: "OFFERLE" },
  "67564": { state: "KS", city: "OLMITZ" },
  "67565": { state: "KS", city: "OTIS" },
  "67566": { state: "KS", city: "PARTRIDGE" },
  "67567": { state: "KS", city: "PAWNEE ROCK" },
  "67568": { state: "KS", city: "PLEVNA" },
  "67570": { state: "KS", city: "PRETTY PRAIRIE" },
  "67572": { state: "KS", city: "RANSOM" },
  "67573": { state: "KS", city: "RAYMOND" },
  "67574": { state: "KS", city: "ROZEL" },
  "67575": { state: "KS", city: "RUSH CENTER" },
  "67576": { state: "KS", city: "ST JOHN" },
  "67578": { state: "KS", city: "STAFFORD" },
  "67579": { state: "KS", city: "STERLING" },
  "67581": { state: "KS", city: "SYLVIA" },
  "67583": { state: "KS", city: "TURON" },
  "67584": { state: "KS", city: "UTICA" },
  "67585": { state: "KS", city: "YODER" },
  "67601": { state: "KS", city: "HAYS" },
  "67621": { state: "KS", city: "AGRA" },
  "67622": { state: "KS", city: "ALMENA" },
  "67623": { state: "KS", city: "ALTON" },
  "67625": { state: "KS", city: "BOGUE" },
  "67626": { state: "KS", city: "BUNKER HILL" },
  "67627": { state: "KS", city: "CATHARINE" },
  "67628": { state: "KS", city: "CEDAR" },
  "67629": { state: "KS", city: "CLAYTON" },
  "67631": { state: "KS", city: "COLLYER" },
  "67632": { state: "KS", city: "DAMAR" },
  "67634": { state: "KS", city: "DORRANCE" },
  "67635": { state: "KS", city: "DRESDEN" },
  "67637": { state: "KS", city: "ELLIS" },
  "67638": { state: "KS", city: "GAYLORD" },
  "67639": { state: "KS", city: "GLADE" },
  "67640": { state: "KS", city: "GORHAM" },
  "67642": { state: "KS", city: "HILL CITY" },
  "67643": { state: "KS", city: "JENNINGS" },
  "67644": { state: "KS", city: "KIRWIN" },
  "67645": { state: "KS", city: "LENORA" },
  "67646": { state: "KS", city: "LOGAN" },
  "67647": { state: "KS", city: "LONG ISLAND" },
  "67648": { state: "KS", city: "LUCAS" },
  "67649": { state: "KS", city: "LURAY" },
  "67650": { state: "KS", city: "MORLAND" },
  "67651": { state: "KS", city: "NATOMA" },
  "67653": { state: "KS", city: "NORCATUR" },
  "67654": { state: "KS", city: "NORTON" },
  "67656": { state: "KS", city: "OGALLAH" },
  "67657": { state: "KS", city: "PALCO" },
  "67658": { state: "KS", city: "PARADISE" },
  "67659": { state: "KS", city: "PENOKEE" },
  "67660": { state: "KS", city: "PFEIFER" },
  "67661": { state: "KS", city: "PHILLIPSBURG" },
  "67663": { state: "KS", city: "PLAINVILLE" },
  "67664": { state: "KS", city: "PRAIRIE VIEW" },
  "67665": { state: "KS", city: "RUSSELL" },
  "67667": { state: "KS", city: "SCHOENCHEN" },
  "67669": { state: "KS", city: "STOCKTON" },
  "67671": { state: "KS", city: "VICTORIA" },
  "67672": { state: "KS", city: "WAKEENEY" },
  "67673": { state: "KS", city: "WALDO" },
  "67674": { state: "KS", city: "WALKER" },
  "67675": { state: "KS", city: "WOODSTON" },
  "67701": { state: "KS", city: "COLBY" },
  "67730": { state: "KS", city: "ATWOOD" },
  "67731": { state: "KS", city: "BIRD CITY" },
  "67732": { state: "KS", city: "BREWSTER" },
  "67733": { state: "KS", city: "EDSON" },
  "67734": { state: "KS", city: "GEM" },
  "67735": { state: "KS", city: "GOODLAND" },
  "67736": { state: "KS", city: "GOVE" },
  "67737": { state: "KS", city: "GRAINFIELD" },
  "67738": { state: "KS", city: "GRINNELL" },
  "67739": { state: "KS", city: "HERNDON" },
  "67740": { state: "KS", city: "HOXIE" },
  "67741": { state: "KS", city: "KANORADO" },
  "67743": { state: "KS", city: "LEVANT" },
  "67744": { state: "KS", city: "LUDELL" },
  "67745": { state: "KS", city: "MC DONALD" },
  "67747": { state: "KS", city: "MONUMENT" },
  "67748": { state: "KS", city: "OAKLEY" },
  "67749": { state: "KS", city: "OBERLIN" },
  "67751": { state: "KS", city: "PARK" },
  "67752": { state: "KS", city: "QUINTER" },
  "67753": { state: "KS", city: "REXFORD" },
  "67756": { state: "KS", city: "SAINT FRANCIS" },
  "67757": { state: "KS", city: "SELDEN" },
  "67758": { state: "KS", city: "SHARON SPRINGS" },
  "67761": { state: "KS", city: "WALLACE" },
  "67762": { state: "KS", city: "WESKAN" },
  "67764": { state: "KS", city: "WINONA" },
  "67801": { state: "KS", city: "DODGE CITY" },
  "67831": { state: "KS", city: "ASHLAND" },
  "67834": { state: "KS", city: "BUCKLIN" },
  "67835": { state: "KS", city: "CIMARRON" },
  "67836": { state: "KS", city: "COOLIDGE" },
  "67837": { state: "KS", city: "COPELAND" },
  "67838": { state: "KS", city: "DEERFIELD" },
  "67839": { state: "KS", city: "DIGHTON" },
  "67840": { state: "KS", city: "ENGLEWOOD" },
  "67841": { state: "KS", city: "ENSIGN" },
  "67842": { state: "KS", city: "FORD" },
  "67843": { state: "KS", city: "FORT DODGE" },
  "67844": { state: "KS", city: "FOWLER" },
  "67846": { state: "KS", city: "GARDEN CITY" },
  "67849": { state: "KS", city: "HANSTON" },
  "67850": { state: "KS", city: "HEALY" },
  "67851": { state: "KS", city: "HOLCOMB" },
  "67853": { state: "KS", city: "INGALLS" },
  "67854": { state: "KS", city: "JETMORE" },
  "67855": { state: "KS", city: "JOHNSON" },
  "67857": { state: "KS", city: "KENDALL" },
  "67859": { state: "KS", city: "KISMET" },
  "67860": { state: "KS", city: "LAKIN" },
  "67861": { state: "KS", city: "LEOTI" },
  "67862": { state: "KS", city: "MANTER" },
  "67863": { state: "KS", city: "MARIENTHAL" },
  "67864": { state: "KS", city: "MEADE" },
  "67865": { state: "KS", city: "MINNEOLA" },
  "67867": { state: "KS", city: "MONTEZUMA" },
  "67868": { state: "KS", city: "PIERCEVILLE" },
  "67869": { state: "KS", city: "PLAINS" },
  "67870": { state: "KS", city: "SATANTA" },
  "67871": { state: "KS", city: "SCOTT CITY" },
  "67876": { state: "KS", city: "SPEARVILLE" },
  "67877": { state: "KS", city: "SUBLETTE" },
  "67878": { state: "KS", city: "SYRACUSE" },
  "67879": { state: "KS", city: "TRIBUNE" },
  "67880": { state: "KS", city: "ULYSSES" },
  "67882": { state: "KS", city: "WRIGHT" },
  "67901": { state: "KS", city: "LIBERAL" },
  "67905": { state: "KS", city: "LIBERAL" },
  "67950": { state: "KS", city: "ELKHART" },
  "67951": { state: "KS", city: "HUGOTON" },
  "67952": { state: "KS", city: "MOSCOW" },
  "67953": { state: "KS", city: "RICHFIELD" },
  "67954": { state: "KS", city: "ROLLA" },
  "68001": { state: "NE", city: "ABIE" },
  "68002": { state: "NE", city: "ARLINGTON" },
  "68003": { state: "NE", city: "ASHLAND" },
  "68004": { state: "NE", city: "BANCROFT" },
  "68005": { state: "NE", city: "BELLEVUE" },
  "68007": { state: "NE", city: "BENNINGTON" },
  "68008": { state: "NE", city: "BLAIR" },
  "68010": { state: "NE", city: "BOYS TOWN" },
  "68014": { state: "NE", city: "BRUNO" },
  "68015": { state: "NE", city: "CEDAR BLUFFS" },
  "68016": { state: "NE", city: "CEDAR CREEK" },
  "68017": { state: "NE", city: "CERESCO" },
  "68018": { state: "NE", city: "COLON" },
  "68019": { state: "NE", city: "CRAIG" },
  "68020": { state: "NE", city: "DECATUR" },
  "68022": { state: "NE", city: "ELKHORN" },
  "68023": { state: "NE", city: "FORT CALHOUN" },
  "68025": { state: "NE", city: "FREMONT" },
  "68026": { state: "NE", city: "FREMONT" },
  "68028": { state: "NE", city: "GRETNA" },
  "68029": { state: "NE", city: "HERMAN" },
  "68030": { state: "NE", city: "HOMER" },
  "68031": { state: "NE", city: "HOOPER" },
  "68033": { state: "NE", city: "ITHACA" },
  "68034": { state: "NE", city: "KENNARD" },
  "68036": { state: "NE", city: "LINWOOD" },
  "68037": { state: "NE", city: "LOUISVILLE" },
  "68038": { state: "NE", city: "LYONS" },
  "68039": { state: "NE", city: "MACY" },
  "68040": { state: "NE", city: "MALMO" },
  "68041": { state: "NE", city: "MEAD" },
  "68042": { state: "NE", city: "MEMPHIS" },
  "68044": { state: "NE", city: "NICKERSON" },
  "68045": { state: "NE", city: "OAKLAND" },
  "68046": { state: "NE", city: "PAPILLION" },
  "68047": { state: "NE", city: "PENDER" },
  "68048": { state: "NE", city: "PLATTSMOUTH" },
  "68050": { state: "NE", city: "PRAGUE" },
  "68055": { state: "NE", city: "ROSALIE" },
  "68056": { state: "NE", city: "ST COLUMBANS" },
  "68057": { state: "NE", city: "SCRIBNER" },
  "68058": { state: "NE", city: "SOUTH BEND" },
  "68059": { state: "NE", city: "SPRINGFIELD" },
  "68061": { state: "NE", city: "TEKAMAH" },
  "68062": { state: "NE", city: "THURSTON" },
  "68063": { state: "NE", city: "UEHLING" },
  "68064": { state: "NE", city: "VALLEY" },
  "68065": { state: "NE", city: "VALPARAISO" },
  "68066": { state: "NE", city: "WAHOO" },
  "68067": { state: "NE", city: "WALTHILL" },
  "68068": { state: "NE", city: "WASHINGTON" },
  "68069": { state: "NE", city: "WATERLOO" },
  "68070": { state: "NE", city: "WESTON" },
  "68071": { state: "NE", city: "WINNEBAGO" },
  "68072": { state: "NE", city: "WINSLOW" },
  "68073": { state: "NE", city: "YUTAN" },
  "68101": { state: "NE", city: "OMAHA" },
  "68102": { state: "NE", city: "OMAHA" },
  "68103": { state: "NE", city: "OMAHA" },
  "68104": { state: "NE", city: "OMAHA" },
  "68105": { state: "NE", city: "OMAHA" },
  "68106": { state: "NE", city: "OMAHA" },
  "68107": { state: "NE", city: "OMAHA" },
  "68108": { state: "NE", city: "OMAHA" },
  "68109": { state: "NE", city: "OMAHA" },
  "68110": { state: "NE", city: "OMAHA" },
  "68111": { state: "NE", city: "OMAHA" },
  "68112": { state: "NE", city: "OMAHA" },
  "68113": { state: "NE", city: "OFFUTT AFB" },
  "68114": { state: "NE", city: "OMAHA" },
  "68116": { state: "NE", city: "OMAHA" },
  "68117": { state: "NE", city: "OMAHA" },
  "68118": { state: "NE", city: "OMAHA" },
  "68119": { state: "NE", city: "OMAHA" },
  "68122": { state: "NE", city: "OMAHA" },
  "68123": { state: "NE", city: "BELLEVUE" },
  "68124": { state: "NE", city: "OMAHA" },
  "68127": { state: "NE", city: "OMAHA" },
  "68128": { state: "NE", city: "LA VISTA" },
  "68130": { state: "NE", city: "OMAHA" },
  "68131": { state: "NE", city: "OMAHA" },
  "68132": { state: "NE", city: "OMAHA" },
  "68133": { state: "NE", city: "PAPILLION" },
  "68134": { state: "NE", city: "OMAHA" },
  "68135": { state: "NE", city: "OMAHA" },
  "68136": { state: "NE", city: "OMAHA" },
  "68137": { state: "NE", city: "OMAHA" },
  "68138": { state: "NE", city: "OMAHA" },
  "68139": { state: "NE", city: "OMAHA" },
  "68142": { state: "NE", city: "OMAHA" },
  "68144": { state: "NE", city: "OMAHA" },
  "68145": { state: "NE", city: "OMAHA" },
  "68147": { state: "NE", city: "BELLEVUE" },
  "68152": { state: "NE", city: "OMAHA" },
  "68154": { state: "NE", city: "OMAHA" },
  "68157": { state: "NE", city: "OMAHA" },
  "68164": { state: "NE", city: "OMAHA" },
  "68172": { state: "NE", city: "OMAHA" },
  "68175": { state: "NE", city: "OMAHA" },
  "68176": { state: "NE", city: "OMAHA" },
  "68178": { state: "NE", city: "OMAHA" },
  "68179": { state: "NE", city: "OMAHA" },
  "68180": { state: "NE", city: "OMAHA" },
  "68182": { state: "NE", city: "OMAHA" },
  "68183": { state: "NE", city: "OMAHA" },
  "68197": { state: "NE", city: "OMAHA" },
  "68198": { state: "NE", city: "OMAHA" },
  "68301": { state: "NE", city: "ADAMS" },
  "68303": { state: "NE", city: "ALEXANDRIA" },
  "68304": { state: "NE", city: "ALVO" },
  "68305": { state: "NE", city: "AUBURN" },
  "68307": { state: "NE", city: "AVOCA" },
  "68309": { state: "NE", city: "BARNESTON" },
  "68310": { state: "NE", city: "BEATRICE" },
  "68313": { state: "NE", city: "BEAVER CROSSING" },
  "68314": { state: "NE", city: "BEE" },
  "68315": { state: "NE", city: "BELVIDERE" },
  "68316": { state: "NE", city: "BENEDICT" },
  "68317": { state: "NE", city: "BENNET" },
  "68318": { state: "NE", city: "BLUE SPRINGS" },
  "68319": { state: "NE", city: "BRADSHAW" },
  "68320": { state: "NE", city: "BROCK" },
  "68321": { state: "NE", city: "BROWNVILLE" },
  "68322": { state: "NE", city: "BRUNING" },
  "68323": { state: "NE", city: "BURCHARD" },
  "68324": { state: "NE", city: "BURR" },
  "68325": { state: "NE", city: "BYRON" },
  "68326": { state: "NE", city: "CARLETON" },
  "68327": { state: "NE", city: "CHESTER" },
  "68328": { state: "NE", city: "CLATONIA" },
  "68329": { state: "NE", city: "COOK" },
  "68330": { state: "NE", city: "CORDOVA" },
  "68331": { state: "NE", city: "CORTLAND" },
  "68332": { state: "NE", city: "CRAB ORCHARD" },
  "68333": { state: "NE", city: "CRETE" },
  "68335": { state: "NE", city: "DAVENPORT" },
  "68336": { state: "NE", city: "DAVEY" },
  "68337": { state: "NE", city: "DAWSON" },
  "68338": { state: "NE", city: "DAYKIN" },
  "68339": { state: "NE", city: "DENTON" },
  "68340": { state: "NE", city: "DESHLER" },
  "68341": { state: "NE", city: "DE WITT" },
  "68342": { state: "NE", city: "DILLER" },
  "68343": { state: "NE", city: "DORCHESTER" },
  "68344": { state: "NE", city: "DOUGLAS" },
  "68345": { state: "NE", city: "DU BOIS" },
  "68346": { state: "NE", city: "DUNBAR" },
  "68347": { state: "NE", city: "EAGLE" },
  "68348": { state: "NE", city: "ELK CREEK" },
  "68349": { state: "NE", city: "ELMWOOD" },
  "68350": { state: "NE", city: "ENDICOTT" },
  "68351": { state: "NE", city: "EXETER" },
  "68352": { state: "NE", city: "FAIRBURY" },
  "68354": { state: "NE", city: "FAIRMONT" },
  "68355": { state: "NE", city: "FALLS CITY" },
  "68357": { state: "NE", city: "FILLEY" },
  "68358": { state: "NE", city: "FIRTH" },
  "68359": { state: "NE", city: "FRIEND" },
  "68360": { state: "NE", city: "GARLAND" },
  "68361": { state: "NE", city: "GENEVA" },
  "68362": { state: "NE", city: "GILEAD" },
  "68364": { state: "NE", city: "GOEHNER" },
  "68365": { state: "NE", city: "GRAFTON" },
  "68366": { state: "NE", city: "GREENWOOD" },
  "68367": { state: "NE", city: "GRESHAM" },
  "68368": { state: "NE", city: "HALLAM" },
  "68370": { state: "NE", city: "HEBRON" },
  "68371": { state: "NE", city: "HENDERSON" },
  "68372": { state: "NE", city: "HICKMAN" },
  "68375": { state: "NE", city: "HUBBELL" },
  "68376": { state: "NE", city: "HUMBOLDT" },
  "68377": { state: "NE", city: "JANSEN" },
  "68378": { state: "NE", city: "JOHNSON" },
  "68379": { state: "NE", city: "JULIAN" },
  "68380": { state: "NE", city: "LEWISTON" },
  "68381": { state: "NE", city: "LIBERTY" },
  "68382": { state: "NE", city: "LORTON" },
  "68401": { state: "NE", city: "MC COOL JUNCTION" },
  "68402": { state: "NE", city: "MALCOLM" },
  "68403": { state: "NE", city: "MANLEY" },
  "68404": { state: "NE", city: "MARTELL" },
  "68405": { state: "NE", city: "MILFORD" },
  "68406": { state: "NE", city: "MILLIGAN" },
  "68407": { state: "NE", city: "MURDOCK" },
  "68409": { state: "NE", city: "MURRAY" },
  "68410": { state: "NE", city: "NEBRASKA CITY" },
  "68413": { state: "NE", city: "NEHAWKA" },
  "68414": { state: "NE", city: "NEMAHA" },
  "68415": { state: "NE", city: "ODELL" },
  "68416": { state: "NE", city: "OHIOWA" },
  "68417": { state: "NE", city: "OTOE" },
  "68418": { state: "NE", city: "PALMYRA" },
  "68419": { state: "NE", city: "PANAMA" },
  "68420": { state: "NE", city: "PAWNEE CITY" },
  "68421": { state: "NE", city: "PERU" },
  "68422": { state: "NE", city: "PICKRELL" },
  "68423": { state: "NE", city: "PLEASANT DALE" },
  "68424": { state: "NE", city: "PLYMOUTH" },
  "68428": { state: "NE", city: "RAYMOND" },
  "68429": { state: "NE", city: "REYNOLDS" },
  "68430": { state: "NE", city: "ROCA" },
  "68431": { state: "NE", city: "RULO" },
  "68433": { state: "NE", city: "SALEM" },
  "68434": { state: "NE", city: "SEWARD" },
  "68436": { state: "NE", city: "SHICKLEY" },
  "68437": { state: "NE", city: "SHUBERT" },
  "68438": { state: "NE", city: "SPRAGUE" },
  "68439": { state: "NE", city: "STAPLEHURST" },
  "68440": { state: "NE", city: "STEELE CITY" },
  "68441": { state: "NE", city: "STEINAUER" },
  "68442": { state: "NE", city: "STELLA" },
  "68443": { state: "NE", city: "STERLING" },
  "68444": { state: "NE", city: "STRANG" },
  "68445": { state: "NE", city: "SWANTON" },
  "68446": { state: "NE", city: "SYRACUSE" },
  "68447": { state: "NE", city: "TABLE ROCK" },
  "68448": { state: "NE", city: "TALMAGE" },
  "68450": { state: "NE", city: "TECUMSEH" },
  "68452": { state: "NE", city: "ONG" },
  "68453": { state: "NE", city: "TOBIAS" },
  "68454": { state: "NE", city: "UNADILLA" },
  "68455": { state: "NE", city: "UNION" },
  "68456": { state: "NE", city: "UTICA" },
  "68457": { state: "NE", city: "VERDON" },
  "68458": { state: "NE", city: "VIRGINIA" },
  "68460": { state: "NE", city: "WACO" },
  "68461": { state: "NE", city: "WALTON" },
  "68462": { state: "NE", city: "WAVERLY" },
  "68463": { state: "NE", city: "WEEPING WATER" },
  "68464": { state: "NE", city: "WESTERN" },
  "68465": { state: "NE", city: "WILBER" },
  "68466": { state: "NE", city: "WYMORE" },
  "68467": { state: "NE", city: "YORK" },
  "68501": { state: "NE", city: "LINCOLN" },
  "68502": { state: "NE", city: "LINCOLN" },
  "68503": { state: "NE", city: "LINCOLN" },
  "68504": { state: "NE", city: "LINCOLN" },
  "68505": { state: "NE", city: "LINCOLN" },
  "68506": { state: "NE", city: "LINCOLN" },
  "68507": { state: "NE", city: "LINCOLN" },
  "68508": { state: "NE", city: "LINCOLN" },
  "68509": { state: "NE", city: "LINCOLN" },
  "68510": { state: "NE", city: "LINCOLN" },
  "68512": { state: "NE", city: "LINCOLN" },
  "68514": { state: "NE", city: "LINCOLN" },
  "68516": { state: "NE", city: "LINCOLN" },
  "68517": { state: "NE", city: "LINCOLN" },
  "68520": { state: "NE", city: "LINCOLN" },
  "68521": { state: "NE", city: "LINCOLN" },
  "68522": { state: "NE", city: "LINCOLN" },
  "68523": { state: "NE", city: "LINCOLN" },
  "68524": { state: "NE", city: "LINCOLN" },
  "68526": { state: "NE", city: "LINCOLN" },
  "68527": { state: "NE", city: "LINCOLN" },
  "68528": { state: "NE", city: "LINCOLN" },
  "68529": { state: "NE", city: "LINCOLN" },
  "68531": { state: "NE", city: "LINCOLN" },
  "68532": { state: "NE", city: "LINCOLN" },
  "68542": { state: "NE", city: "LINCOLN" },
  "68544": { state: "NE", city: "LINCOLN" },
  "68583": { state: "NE", city: "LINCOLN" },
  "68588": { state: "NE", city: "LINCOLN" },
  "68601": { state: "NE", city: "COLUMBUS" },
  "68602": { state: "NE", city: "COLUMBUS" },
  "68620": { state: "NE", city: "ALBION" },
  "68621": { state: "NE", city: "AMES" },
  "68622": { state: "NE", city: "BARTLETT" },
  "68623": { state: "NE", city: "BELGRADE" },
  "68624": { state: "NE", city: "BELLWOOD" },
  "68626": { state: "NE", city: "BRAINARD" },
  "68627": { state: "NE", city: "CEDAR RAPIDS" },
  "68628": { state: "NE", city: "CLARKS" },
  "68629": { state: "NE", city: "CLARKSON" },
  "68631": { state: "NE", city: "CRESTON" },
  "68632": { state: "NE", city: "DAVID CITY" },
  "68633": { state: "NE", city: "DODGE" },
  "68634": { state: "NE", city: "DUNCAN" },
  "68635": { state: "NE", city: "DWIGHT" },
  "68636": { state: "NE", city: "ELGIN" },
  "68637": { state: "NE", city: "ERICSON" },
  "68638": { state: "NE", city: "FULLERTON" },
  "68640": { state: "NE", city: "GENOA" },
  "68641": { state: "NE", city: "HOWELLS" },
  "68642": { state: "NE", city: "HUMPHREY" },
  "68643": { state: "NE", city: "LEIGH" },
  "68644": { state: "NE", city: "LINDSAY" },
  "68647": { state: "NE", city: "MONROE" },
  "68648": { state: "NE", city: "MORSE BLUFF" },
  "68649": { state: "NE", city: "NORTH BEND" },
  "68651": { state: "NE", city: "OSCEOLA" },
  "68652": { state: "NE", city: "PETERSBURG" },
  "68653": { state: "NE", city: "PLATTE CENTER" },
  "68654": { state: "NE", city: "POLK" },
  "68655": { state: "NE", city: "PRIMROSE" },
  "68658": { state: "NE", city: "RISING CITY" },
  "68659": { state: "NE", city: "ROGERS" },
  "68660": { state: "NE", city: "SAINT EDWARD" },
  "68661": { state: "NE", city: "SCHUYLER" },
  "68662": { state: "NE", city: "SHELBY" },
  "68663": { state: "NE", city: "SILVER CREEK" },
  "68664": { state: "NE", city: "SNYDER" },
  "68665": { state: "NE", city: "SPALDING" },
  "68666": { state: "NE", city: "STROMSBURG" },
  "68667": { state: "NE", city: "SURPRISE" },
  "68669": { state: "NE", city: "ULYSSES" },
  "68701": { state: "NE", city: "NORFOLK" },
  "68702": { state: "NE", city: "NORFOLK" },
  "68710": { state: "NE", city: "ALLEN" },
  "68711": { state: "NE", city: "AMELIA" },
  "68713": { state: "NE", city: "ATKINSON" },
  "68714": { state: "NE", city: "BASSETT" },
  "68715": { state: "NE", city: "BATTLE CREEK" },
  "68716": { state: "NE", city: "BEEMER" },
  "68717": { state: "NE", city: "BELDEN" },
  "68718": { state: "NE", city: "BLOOMFIELD" },
  "68719": { state: "NE", city: "BRISTOW" },
  "68720": { state: "NE", city: "BRUNSWICK" },
  "68722": { state: "NE", city: "BUTTE" },
  "68723": { state: "NE", city: "CARROLL" },
  "68724": { state: "NE", city: "CENTER" },
  "68725": { state: "NE", city: "CHAMBERS" },
  "68726": { state: "NE", city: "CLEARWATER" },
  "68727": { state: "NE", city: "COLERIDGE" },
  "68728": { state: "NE", city: "CONCORD" },
  "68729": { state: "NE", city: "CREIGHTON" },
  "68730": { state: "NE", city: "CROFTON" },
  "68731": { state: "NE", city: "DAKOTA CITY" },
  "68732": { state: "NE", city: "DIXON" },
  "68733": { state: "NE", city: "EMERSON" },
  "68734": { state: "NE", city: "EMMET" },
  "68735": { state: "NE", city: "EWING" },
  "68736": { state: "NE", city: "FORDYCE" },
  "68738": { state: "NE", city: "HADAR" },
  "68739": { state: "NE", city: "HARTINGTON" },
  "68740": { state: "NE", city: "HOSKINS" },
  "68741": { state: "NE", city: "HUBBARD" },
  "68742": { state: "NE", city: "INMAN" },
  "68743": { state: "NE", city: "JACKSON" },
  "68745": { state: "NE", city: "LAUREL" },
  "68746": { state: "NE", city: "LYNCH" },
  "68747": { state: "NE", city: "MCLEAN" },
  "68748": { state: "NE", city: "MADISON" },
  "68749": { state: "NE", city: "MAGNET" },
  "68751": { state: "NE", city: "MASKELL" },
  "68752": { state: "NE", city: "MEADOW GROVE" },
  "68753": { state: "NE", city: "MILLS" },
  "68755": { state: "NE", city: "NAPER" },
  "68756": { state: "NE", city: "NELIGH" },
  "68757": { state: "NE", city: "NEWCASTLE" },
  "68758": { state: "NE", city: "NEWMAN GROVE" },
  "68759": { state: "NE", city: "NEWPORT" },
  "68760": { state: "NE", city: "NIOBRARA" },
  "68761": { state: "NE", city: "OAKDALE" },
  "68763": { state: "NE", city: "ONEILL" },
  "68764": { state: "NE", city: "ORCHARD" },
  "68765": { state: "NE", city: "OSMOND" },
  "68766": { state: "NE", city: "PAGE" },
  "68767": { state: "NE", city: "PIERCE" },
  "68768": { state: "NE", city: "PILGER" },
  "68769": { state: "NE", city: "PLAINVIEW" },
  "68770": { state: "NE", city: "PONCA" },
  "68771": { state: "NE", city: "RANDOLPH" },
  "68773": { state: "NE", city: "ROYAL" },
  "68774": { state: "NE", city: "SAINT HELENA" },
  "68776": { state: "NE", city: "SOUTH SIOUX CITY" },
  "68777": { state: "NE", city: "SPENCER" },
  "68778": { state: "NE", city: "SPRINGVIEW" },
  "68779": { state: "NE", city: "STANTON" },
  "68780": { state: "NE", city: "STUART" },
  "68781": { state: "NE", city: "TILDEN" },
  "68783": { state: "NE", city: "VERDIGRE" },
  "68784": { state: "NE", city: "WAKEFIELD" },
  "68785": { state: "NE", city: "WATERBURY" },
  "68786": { state: "NE", city: "WAUSA" },
  "68787": { state: "NE", city: "WAYNE" },
  "68788": { state: "NE", city: "WEST POINT" },
  "68789": { state: "NE", city: "WINNETOON" },
  "68790": { state: "NE", city: "WINSIDE" },
  "68791": { state: "NE", city: "WISNER" },
  "68792": { state: "NE", city: "WYNOT" },
  "68801": { state: "NE", city: "GRAND ISLAND" },
  "68802": { state: "NE", city: "GRAND ISLAND" },
  "68803": { state: "NE", city: "GRAND ISLAND" },
  "68810": { state: "NE", city: "ALDA" },
  "68812": { state: "NE", city: "AMHERST" },
  "68813": { state: "NE", city: "ANSELMO" },
  "68814": { state: "NE", city: "ANSLEY" },
  "68815": { state: "NE", city: "ARCADIA" },
  "68816": { state: "NE", city: "ARCHER" },
  "68817": { state: "NE", city: "ASHTON" },
  "68818": { state: "NE", city: "AURORA" },
  "68820": { state: "NE", city: "BOELUS" },
  "68821": { state: "NE", city: "BREWSTER" },
  "68822": { state: "NE", city: "BROKEN BOW" },
  "68823": { state: "NE", city: "BURWELL" },
  "68824": { state: "NE", city: "CAIRO" },
  "68825": { state: "NE", city: "CALLAWAY" },
  "68826": { state: "NE", city: "CENTRAL CITY" },
  "68827": { state: "NE", city: "CHAPMAN" },
  "68828": { state: "NE", city: "COMSTOCK" },
  "68831": { state: "NE", city: "DANNEBROG" },
  "68832": { state: "NE", city: "DONIPHAN" },
  "68833": { state: "NE", city: "DUNNING" },
  "68834": { state: "NE", city: "EDDYVILLE" },
  "68835": { state: "NE", city: "ELBA" },
  "68836": { state: "NE", city: "ELM CREEK" },
  "68837": { state: "NE", city: "ELYRIA" },
  "68838": { state: "NE", city: "FARWELL" },
  "68840": { state: "NE", city: "GIBBON" },
  "68841": { state: "NE", city: "GILTNER" },
  "68842": { state: "NE", city: "GREELEY" },
  "68843": { state: "NE", city: "HAMPTON" },
  "68844": { state: "NE", city: "HAZARD" },
  "68845": { state: "NE", city: "KEARNEY" },
  "68846": { state: "NE", city: "HORDVILLE" },
  "68847": { state: "NE", city: "KEARNEY" },
  "68848": { state: "NE", city: "KEARNEY" },
  "68849": { state: "NE", city: "KEARNEY" },
  "68850": { state: "NE", city: "LEXINGTON" },
  "68852": { state: "NE", city: "LITCHFIELD" },
  "68853": { state: "NE", city: "LOUP CITY" },
  "68854": { state: "NE", city: "MARQUETTE" },
  "68855": { state: "NE", city: "MASON CITY" },
  "68856": { state: "NE", city: "MERNA" },
  "68858": { state: "NE", city: "MILLER" },
  "68859": { state: "NE", city: "NORTH LOUP" },
  "68860": { state: "NE", city: "OCONTO" },
  "68861": { state: "NE", city: "ODESSA" },
  "68862": { state: "NE", city: "ORD" },
  "68863": { state: "NE", city: "OVERTON" },
  "68864": { state: "NE", city: "PALMER" },
  "68865": { state: "NE", city: "PHILLIPS" },
  "68866": { state: "NE", city: "PLEASANTON" },
  "68869": { state: "NE", city: "RAVENNA" },
  "68870": { state: "NE", city: "RIVERDALE" },
  "68871": { state: "NE", city: "ROCKVILLE" },
  "68872": { state: "NE", city: "SAINT LIBORY" },
  "68873": { state: "NE", city: "SAINT PAUL" },
  "68874": { state: "NE", city: "SARGENT" },
  "68875": { state: "NE", city: "SCOTIA" },
  "68876": { state: "NE", city: "SHELTON" },
  "68878": { state: "NE", city: "SUMNER" },
  "68879": { state: "NE", city: "TAYLOR" },
  "68881": { state: "NE", city: "WESTERVILLE" },
  "68882": { state: "NE", city: "WOLBACH" },
  "68883": { state: "NE", city: "WOOD RIVER" },
  "68901": { state: "NE", city: "HASTINGS" },
  "68902": { state: "NE", city: "HASTINGS" },
  "68920": { state: "NE", city: "ALMA" },
  "68922": { state: "NE", city: "ARAPAHOE" },
  "68923": { state: "NE", city: "ATLANTA" },
  "68924": { state: "NE", city: "AXTELL" },
  "68925": { state: "NE", city: "AYR" },
  "68926": { state: "NE", city: "BEAVER CITY" },
  "68927": { state: "NE", city: "BERTRAND" },
  "68928": { state: "NE", city: "BLADEN" },
  "68929": { state: "NE", city: "BLOOMINGTON" },
  "68930": { state: "NE", city: "BLUE HILL" },
  "68932": { state: "NE", city: "CAMPBELL" },
  "68933": { state: "NE", city: "CLAY CENTER" },
  "68934": { state: "NE", city: "DEWEESE" },
  "68935": { state: "NE", city: "EDGAR" },
  "68936": { state: "NE", city: "EDISON" },
  "68937": { state: "NE", city: "ELWOOD" },
  "68938": { state: "NE", city: "FAIRFIELD" },
  "68939": { state: "NE", city: "FRANKLIN" },
  "68940": { state: "NE", city: "FUNK" },
  "68941": { state: "NE", city: "GLENVIL" },
  "68942": { state: "NE", city: "GUIDE ROCK" },
  "68943": { state: "NE", city: "HARDY" },
  "68944": { state: "NE", city: "HARVARD" },
  "68945": { state: "NE", city: "HEARTWELL" },
  "68946": { state: "NE", city: "HENDLEY" },
  "68947": { state: "NE", city: "HILDRETH" },
  "68948": { state: "NE", city: "HOLBROOK" },
  "68949": { state: "NE", city: "HOLDREGE" },
  "68950": { state: "NE", city: "HOLSTEIN" },
  "68952": { state: "NE", city: "INAVALE" },
  "68954": { state: "NE", city: "INLAND" },
  "68955": { state: "NE", city: "JUNIATA" },
  "68956": { state: "NE", city: "KENESAW" },
  "68957": { state: "NE", city: "LAWRENCE" },
  "68958": { state: "NE", city: "LOOMIS" },
  "68959": { state: "NE", city: "MINDEN" },
  "68960": { state: "NE", city: "NAPONEE" },
  "68961": { state: "NE", city: "NELSON" },
  "68964": { state: "NE", city: "OAK" },
  "68966": { state: "NE", city: "ORLEANS" },
  "68967": { state: "NE", city: "OXFORD" },
  "68969": { state: "NE", city: "RAGAN" },
  "68970": { state: "NE", city: "RED CLOUD" },
  "68971": { state: "NE", city: "REPUBLICAN CITY" },
  "68972": { state: "NE", city: "RIVERTON" },
  "68973": { state: "NE", city: "ROSELAND" },
  "68974": { state: "NE", city: "RUSKIN" },
  "68975": { state: "NE", city: "SARONVILLE" },
  "68976": { state: "NE", city: "SMITHFIELD" },
  "68977": { state: "NE", city: "STAMFORD" },
  "68978": { state: "NE", city: "SUPERIOR" },
  "68979": { state: "NE", city: "SUTTON" },
  "68980": { state: "NE", city: "TRUMBULL" },
  "68981": { state: "NE", city: "UPLAND" },
  "68982": { state: "NE", city: "WILCOX" },
  "69001": { state: "NE", city: "MC COOK" },
  "69020": { state: "NE", city: "BARTLEY" },
  "69021": { state: "NE", city: "BENKELMAN" },
  "69022": { state: "NE", city: "CAMBRIDGE" },
  "69023": { state: "NE", city: "CHAMPION" },
  "69024": { state: "NE", city: "CULBERTSON" },
  "69025": { state: "NE", city: "CURTIS" },
  "69026": { state: "NE", city: "DANBURY" },
  "69027": { state: "NE", city: "ENDERS" },
  "69028": { state: "NE", city: "EUSTIS" },
  "69029": { state: "NE", city: "FARNAM" },
  "69030": { state: "NE", city: "HAIGLER" },
  "69032": { state: "NE", city: "HAYES CENTER" },
  "69033": { state: "NE", city: "IMPERIAL" },
  "69034": { state: "NE", city: "INDIANOLA" },
  "69036": { state: "NE", city: "LEBANON" },
  "69037": { state: "NE", city: "MAX" },
  "69038": { state: "NE", city: "MAYWOOD" },
  "69039": { state: "NE", city: "MOOREFIELD" },
  "69040": { state: "NE", city: "PALISADE" },
  "69041": { state: "NE", city: "PARKS" },
  "69042": { state: "NE", city: "STOCKVILLE" },
  "69043": { state: "NE", city: "STRATTON" },
  "69044": { state: "NE", city: "TRENTON" },
  "69045": { state: "NE", city: "WAUNETA" },
  "69046": { state: "NE", city: "WILSONVILLE" },
  "69101": { state: "NE", city: "NORTH PLATTE" },
  "69103": { state: "NE", city: "NORTH PLATTE" },
  "69120": { state: "NE", city: "ARNOLD" },
  "69121": { state: "NE", city: "ARTHUR" },
  "69122": { state: "NE", city: "BIG SPRINGS" },
  "69123": { state: "NE", city: "BRADY" },
  "69125": { state: "NE", city: "BROADWATER" },
  "69127": { state: "NE", city: "BRULE" },
  "69128": { state: "NE", city: "BUSHNELL" },
  "69129": { state: "NE", city: "CHAPPELL" },
  "69130": { state: "NE", city: "COZAD" },
  "69131": { state: "NE", city: "DALTON" },
  "69132": { state: "NE", city: "DICKENS" },
  "69133": { state: "NE", city: "DIX" },
  "69134": { state: "NE", city: "ELSIE" },
  "69135": { state: "NE", city: "ELSMERE" },
  "69138": { state: "NE", city: "GOTHENBURG" },
  "69140": { state: "NE", city: "GRANT" },
  "69141": { state: "NE", city: "GURLEY" },
  "69142": { state: "NE", city: "HALSEY" },
  "69143": { state: "NE", city: "HERSHEY" },
  "69144": { state: "NE", city: "KEYSTONE" },
  "69145": { state: "NE", city: "KIMBALL" },
  "69146": { state: "NE", city: "LEMOYNE" },
  "69147": { state: "NE", city: "LEWELLEN" },
  "69148": { state: "NE", city: "LISCO" },
  "69149": { state: "NE", city: "LODGEPOLE" },
  "69150": { state: "NE", city: "MADRID" },
  "69151": { state: "NE", city: "MAXWELL" },
  "69152": { state: "NE", city: "MULLEN" },
  "69153": { state: "NE", city: "OGALLALA" },
  "69154": { state: "NE", city: "OSHKOSH" },
  "69155": { state: "NE", city: "PAXTON" },
  "69156": { state: "NE", city: "POTTER" },
  "69157": { state: "NE", city: "PURDUM" },
  "69160": { state: "NE", city: "SIDNEY" },
  "69161": { state: "NE", city: "SENECA" },
  "69162": { state: "NE", city: "SIDNEY" },
  "69163": { state: "NE", city: "STAPLETON" },
  "69165": { state: "NE", city: "SUTHERLAND" },
  "69166": { state: "NE", city: "THEDFORD" },
  "69167": { state: "NE", city: "TRYON" },
  "69168": { state: "NE", city: "VENANGO" },
  "69169": { state: "NE", city: "WALLACE" },
  "69170": { state: "NE", city: "WELLFLEET" },
  "69171": { state: "NE", city: "WILLOW ISLAND" },
  "69201": { state: "NE", city: "VALENTINE" },
  "69210": { state: "NE", city: "AINSWORTH" },
  "69211": { state: "NE", city: "CODY" },
  "69212": { state: "NE", city: "CROOKSTON" },
  "69214": { state: "NE", city: "JOHNSTOWN" },
  "69216": { state: "NE", city: "KILGORE" },
  "69217": { state: "NE", city: "LONG PINE" },
  "69218": { state: "NE", city: "MERRIMAN" },
  "69219": { state: "NE", city: "NENZEL" },
  "69220": { state: "NE", city: "SPARKS" },
  "69221": { state: "NE", city: "WOOD LAKE" },
  "69301": { state: "NE", city: "ALLIANCE" },
  "69331": { state: "NE", city: "ANGORA" },
  "69333": { state: "NE", city: "ASHBY" },
  "69334": { state: "NE", city: "BAYARD" },
  "69335": { state: "NE", city: "BINGHAM" },
  "69336": { state: "NE", city: "BRIDGEPORT" },
  "69337": { state: "NE", city: "CHADRON" },
  "69339": { state: "NE", city: "CRAWFORD" },
  "69340": { state: "NE", city: "ELLSWORTH" },
  "69341": { state: "NE", city: "GERING" },
  "69343": { state: "NE", city: "GORDON" },
  "69345": { state: "NE", city: "HARRISBURG" },
  "69346": { state: "NE", city: "HARRISON" },
  "69347": { state: "NE", city: "HAY SPRINGS" },
  "69348": { state: "NE", city: "HEMINGFORD" },
  "69350": { state: "NE", city: "HYANNIS" },
  "69351": { state: "NE", city: "LAKESIDE" },
  "69352": { state: "NE", city: "LYMAN" },
  "69353": { state: "NE", city: "MCGREW" },
  "69354": { state: "NE", city: "MARSLAND" },
  "69355": { state: "NE", city: "MELBETA" },
  "69356": { state: "NE", city: "MINATARE" },
  "69357": { state: "NE", city: "MITCHELL" },
  "69358": { state: "NE", city: "MORRILL" },
  "69360": { state: "NE", city: "RUSHVILLE" },
  "69361": { state: "NE", city: "SCOTTSBLUFF" },
  "69363": { state: "NE", city: "SCOTTSBLUFF" },
  "69365": { state: "NE", city: "WHITECLAY" },
  "69366": { state: "NE", city: "WHITMAN" },
  "69367": { state: "NE", city: "WHITNEY" },
  "70001": { state: "LA", city: "METAIRIE" },
  "70002": { state: "LA", city: "METAIRIE" },
  "70003": { state: "LA", city: "METAIRIE" },
  "70004": { state: "LA", city: "METAIRIE" },
  "70005": { state: "LA", city: "METAIRIE" },
  "70006": { state: "LA", city: "METAIRIE" },
  "70009": { state: "LA", city: "METAIRIE" },
  "70010": { state: "LA", city: "METAIRIE" },
  "70011": { state: "LA", city: "METAIRIE" },
  "70030": { state: "LA", city: "DES ALLEMANDS" },
  "70031": { state: "LA", city: "AMA" },
  "70032": { state: "LA", city: "ARABI" },
  "70033": { state: "LA", city: "METAIRIE" },
  "70036": { state: "LA", city: "BARATARIA" },
  "70037": { state: "LA", city: "BELLE CHASSE" },
  "70038": { state: "LA", city: "BOOTHVILLE" },
  "70039": { state: "LA", city: "BOUTTE" },
  "70040": { state: "LA", city: "BRAITHWAITE" },
  "70041": { state: "LA", city: "BURAS" },
  "70043": { state: "LA", city: "CHALMETTE" },
  "70044": { state: "LA", city: "CHALMETTE" },
  "70047": { state: "LA", city: "DESTREHAN" },
  "70049": { state: "LA", city: "EDGARD" },
  "70050": { state: "LA", city: "EMPIRE" },
  "70051": { state: "LA", city: "GARYVILLE" },
  "70052": { state: "LA", city: "GRAMERCY" },
  "70053": { state: "LA", city: "GRETNA" },
  "70054": { state: "LA", city: "GRETNA" },
  "70055": { state: "LA", city: "METAIRIE" },
  "70056": { state: "LA", city: "GRETNA" },
  "70057": { state: "LA", city: "HAHNVILLE" },
  "70058": { state: "LA", city: "HARVEY" },
  "70059": { state: "LA", city: "HARVEY" },
  "70060": { state: "LA", city: "METAIRIE" },
  "70062": { state: "LA", city: "KENNER" },
  "70063": { state: "LA", city: "KENNER" },
  "70064": { state: "LA", city: "KENNER" },
  "70065": { state: "LA", city: "KENNER" },
  "70067": { state: "LA", city: "LAFITTE" },
  "70068": { state: "LA", city: "LA PLACE" },
  "70069": { state: "LA", city: "LA PLACE" },
  "70070": { state: "LA", city: "LULING" },
  "70071": { state: "LA", city: "LUTCHER" },
  "70072": { state: "LA", city: "MARRERO" },
  "70073": { state: "LA", city: "MARRERO" },
  "70075": { state: "LA", city: "MERAUX" },
  "70076": { state: "LA", city: "MOUNT AIRY" },
  "70078": { state: "LA", city: "NEW SARPY" },
  "70079": { state: "LA", city: "NORCO" },
  "70080": { state: "LA", city: "PARADIS" },
  "70081": { state: "LA", city: "PILOTTOWN" },
  "70082": { state: "LA", city: "POINTE A LA HACHE" },
  "70083": { state: "LA", city: "PORT SULPHUR" },
  "70084": { state: "LA", city: "RESERVE" },
  "70085": { state: "LA", city: "SAINT BERNARD" },
  "70086": { state: "LA", city: "SAINT JAMES" },
  "70087": { state: "LA", city: "SAINT ROSE" },
  "70090": { state: "LA", city: "VACHERIE" },
  "70091": { state: "LA", city: "VENICE" },
  "70092": { state: "LA", city: "VIOLET" },
  "70093": { state: "LA", city: "BELLE CHASSE" },
  "70094": { state: "LA", city: "WESTWEGO" },
  "70096": { state: "LA", city: "WESTWEGO" },
  "70097": { state: "LA", city: "KENNER" },
  "70112": { state: "LA", city: "NEW ORLEANS" },
  "70113": { state: "LA", city: "NEW ORLEANS" },
  "70114": { state: "LA", city: "NEW ORLEANS" },
  "70115": { state: "LA", city: "NEW ORLEANS" },
  "70116": { state: "LA", city: "NEW ORLEANS" },
  "70117": { state: "LA", city: "NEW ORLEANS" },
  "70118": { state: "LA", city: "NEW ORLEANS" },
  "70119": { state: "LA", city: "NEW ORLEANS" },
  "70121": { state: "LA", city: "NEW ORLEANS" },
  "70122": { state: "LA", city: "NEW ORLEANS" },
  "70123": { state: "LA", city: "NEW ORLEANS" },
  "70124": { state: "LA", city: "NEW ORLEANS" },
  "70125": { state: "LA", city: "NEW ORLEANS" },
  "70126": { state: "LA", city: "NEW ORLEANS" },
  "70127": { state: "LA", city: "NEW ORLEANS" },
  "70128": { state: "LA", city: "NEW ORLEANS" },
  "70129": { state: "LA", city: "NEW ORLEANS" },
  "70130": { state: "LA", city: "NEW ORLEANS" },
  "70131": { state: "LA", city: "NEW ORLEANS" },
  "70139": { state: "LA", city: "NEW ORLEANS" },
  "70141": { state: "LA", city: "NEW ORLEANS" },
  "70142": { state: "LA", city: "NEW ORLEANS" },
  "70143": { state: "LA", city: "NEW ORLEANS" },
  "70145": { state: "LA", city: "NEW ORLEANS" },
  "70146": { state: "LA", city: "NEW ORLEANS" },
  "70148": { state: "LA", city: "NEW ORLEANS" },
  "70150": { state: "LA", city: "NEW ORLEANS" },
  "70151": { state: "LA", city: "NEW ORLEANS" },
  "70152": { state: "LA", city: "NEW ORLEANS" },
  "70153": { state: "LA", city: "NEW ORLEANS" },
  "70154": { state: "LA", city: "NEW ORLEANS" },
  "70156": { state: "LA", city: "NEW ORLEANS" },
  "70157": { state: "LA", city: "NEW ORLEANS" },
  "70158": { state: "LA", city: "NEW ORLEANS" },
  "70159": { state: "LA", city: "NEW ORLEANS" },
  "70160": { state: "LA", city: "NEW ORLEANS" },
  "70161": { state: "LA", city: "NEW ORLEANS" },
  "70162": { state: "LA", city: "NEW ORLEANS" },
  "70163": { state: "LA", city: "NEW ORLEANS" },
  "70164": { state: "LA", city: "NEW ORLEANS" },
  "70165": { state: "LA", city: "NEW ORLEANS" },
  "70166": { state: "LA", city: "NEW ORLEANS" },
  "70167": { state: "LA", city: "NEW ORLEANS" },
  "70170": { state: "LA", city: "NEW ORLEANS" },
  "70172": { state: "LA", city: "NEW ORLEANS" },
  "70174": { state: "LA", city: "NEW ORLEANS" },
  "70175": { state: "LA", city: "NEW ORLEANS" },
  "70176": { state: "LA", city: "NEW ORLEANS" },
  "70177": { state: "LA", city: "NEW ORLEANS" },
  "70178": { state: "LA", city: "NEW ORLEANS" },
  "70179": { state: "LA", city: "NEW ORLEANS" },
  "70181": { state: "LA", city: "NEW ORLEANS" },
  "70182": { state: "LA", city: "NEW ORLEANS" },
  "70183": { state: "LA", city: "NEW ORLEANS" },
  "70184": { state: "LA", city: "NEW ORLEANS" },
  "70185": { state: "LA", city: "NEW ORLEANS" },
  "70186": { state: "LA", city: "NEW ORLEANS" },
  "70187": { state: "LA", city: "NEW ORLEANS" },
  "70189": { state: "LA", city: "NEW ORLEANS" },
  "70190": { state: "LA", city: "NEW ORLEANS" },
  "70195": { state: "LA", city: "NEW ORLEANS" },
  "70301": { state: "LA", city: "THIBODAUX" },
  "70302": { state: "LA", city: "THIBODAUX" },
  "70310": { state: "LA", city: "THIBODAUX" },
  "70339": { state: "LA", city: "PIERRE PART" },
  "70340": { state: "LA", city: "AMELIA" },
  "70341": { state: "LA", city: "BELLE ROSE" },
  "70342": { state: "LA", city: "BERWICK" },
  "70343": { state: "LA", city: "BOURG" },
  "70344": { state: "LA", city: "CHAUVIN" },
  "70345": { state: "LA", city: "CUT OFF" },
  "70346": { state: "LA", city: "DONALDSONVILLE" },
  "70352": { state: "LA", city: "DONNER" },
  "70353": { state: "LA", city: "DULAC" },
  "70354": { state: "LA", city: "GALLIANO" },
  "70355": { state: "LA", city: "GHEENS" },
  "70356": { state: "LA", city: "GIBSON" },
  "70357": { state: "LA", city: "GOLDEN MEADOW" },
  "70358": { state: "LA", city: "GRAND ISLE" },
  "70359": { state: "LA", city: "GRAY" },
  "70360": { state: "LA", city: "HOUMA" },
  "70361": { state: "LA", city: "HOUMA" },
  "70363": { state: "LA", city: "HOUMA" },
  "70364": { state: "LA", city: "HOUMA" },
  "70371": { state: "LA", city: "KRAEMER" },
  "70372": { state: "LA", city: "LABADIEVILLE" },
  "70373": { state: "LA", city: "LAROSE" },
  "70374": { state: "LA", city: "LOCKPORT" },
  "70375": { state: "LA", city: "MATHEWS" },
  "70377": { state: "LA", city: "MONTEGUT" },
  "70380": { state: "LA", city: "MORGAN CITY" },
  "70381": { state: "LA", city: "MORGAN CITY" },
  "70390": { state: "LA", city: "NAPOLEONVILLE" },
  "70391": { state: "LA", city: "PAINCOURTVILLE" },
  "70392": { state: "LA", city: "PATTERSON" },
  "70393": { state: "LA", city: "PLATTENVILLE" },
  "70394": { state: "LA", city: "RACELAND" },
  "70395": { state: "LA", city: "SCHRIEVER" },
  "70397": { state: "LA", city: "THERIOT" },
  "70401": { state: "LA", city: "HAMMOND" },
  "70402": { state: "LA", city: "HAMMOND" },
  "70403": { state: "LA", city: "HAMMOND" },
  "70404": { state: "LA", city: "HAMMOND" },
  "70420": { state: "LA", city: "ABITA SPRINGS" },
  "70421": { state: "LA", city: "AKERS" },
  "70422": { state: "LA", city: "AMITE" },
  "70426": { state: "LA", city: "ANGIE" },
  "70427": { state: "LA", city: "BOGALUSA" },
  "70429": { state: "LA", city: "BOGALUSA" },
  "70431": { state: "LA", city: "BUSH" },
  "70433": { state: "LA", city: "COVINGTON" },
  "70434": { state: "LA", city: "COVINGTON" },
  "70435": { state: "LA", city: "COVINGTON" },
  "70436": { state: "LA", city: "FLUKER" },
  "70437": { state: "LA", city: "FOLSOM" },
  "70438": { state: "LA", city: "FRANKLINTON" },
  "70441": { state: "LA", city: "GREENSBURG" },
  "70442": { state: "LA", city: "HUSSER" },
  "70443": { state: "LA", city: "INDEPENDENCE" },
  "70444": { state: "LA", city: "KENTWOOD" },
  "70445": { state: "LA", city: "LACOMBE" },
  "70446": { state: "LA", city: "LORANGER" },
  "70447": { state: "LA", city: "MADISONVILLE" },
  "70448": { state: "LA", city: "MANDEVILLE" },
  "70449": { state: "LA", city: "MAUREPAS" },
  "70450": { state: "LA", city: "MOUNT HERMON" },
  "70451": { state: "LA", city: "NATALBANY" },
  "70452": { state: "LA", city: "PEARL RIVER" },
  "70453": { state: "LA", city: "PINE GROVE" },
  "70454": { state: "LA", city: "PONCHATOULA" },
  "70455": { state: "LA", city: "ROBERT" },
  "70456": { state: "LA", city: "ROSELAND" },
  "70457": { state: "LA", city: "SAINT BENEDICT" },
  "70458": { state: "LA", city: "SLIDELL" },
  "70459": { state: "LA", city: "SLIDELL" },
  "70460": { state: "LA", city: "SLIDELL" },
  "70461": { state: "LA", city: "SLIDELL" },
  "70462": { state: "LA", city: "SPRINGFIELD" },
  "70463": { state: "LA", city: "SUN" },
  "70464": { state: "LA", city: "TALISHEEK" },
  "70465": { state: "LA", city: "TANGIPAHOA" },
  "70466": { state: "LA", city: "TICKFAW" },
  "70467": { state: "LA", city: "VARNADO" },
  "70469": { state: "LA", city: "SLIDELL" },
  "70470": { state: "LA", city: "MANDEVILLE" },
  "70471": { state: "LA", city: "MANDEVILLE" },
  "70501": { state: "LA", city: "LAFAYETTE" },
  "70502": { state: "LA", city: "LAFAYETTE" },
  "70503": { state: "LA", city: "LAFAYETTE" },
  "70504": { state: "LA", city: "LAFAYETTE" },
  "70505": { state: "LA", city: "LAFAYETTE" },
  "70506": { state: "LA", city: "LAFAYETTE" },
  "70507": { state: "LA", city: "LAFAYETTE" },
  "70508": { state: "LA", city: "LAFAYETTE" },
  "70509": { state: "LA", city: "LAFAYETTE" },
  "70510": { state: "LA", city: "ABBEVILLE" },
  "70511": { state: "LA", city: "ABBEVILLE" },
  "70512": { state: "LA", city: "ARNAUDVILLE" },
  "70513": { state: "LA", city: "AVERY ISLAND" },
  "70514": { state: "LA", city: "BALDWIN" },
  "70515": { state: "LA", city: "BASILE" },
  "70516": { state: "LA", city: "BRANCH" },
  "70517": { state: "LA", city: "BREAUX BRIDGE" },
  "70518": { state: "LA", city: "BROUSSARD" },
  "70519": { state: "LA", city: "CADE" },
  "70520": { state: "LA", city: "CARENCRO" },
  "70521": { state: "LA", city: "CECILIA" },
  "70522": { state: "LA", city: "CENTERVILLE" },
  "70523": { state: "LA", city: "CHARENTON" },
  "70524": { state: "LA", city: "CHATAIGNIER" },
  "70525": { state: "LA", city: "CHURCH POINT" },
  "70526": { state: "LA", city: "CROWLEY" },
  "70527": { state: "LA", city: "CROWLEY" },
  "70528": { state: "LA", city: "DELCAMBRE" },
  "70529": { state: "LA", city: "DUSON" },
  "70531": { state: "LA", city: "EGAN" },
  "70532": { state: "LA", city: "ELTON" },
  "70533": { state: "LA", city: "ERATH" },
  "70534": { state: "LA", city: "ESTHERWOOD" },
  "70535": { state: "LA", city: "EUNICE" },
  "70537": { state: "LA", city: "EVANGELINE" },
  "70538": { state: "LA", city: "FRANKLIN" },
  "70540": { state: "LA", city: "GARDEN CITY" },
  "70541": { state: "LA", city: "GRAND COTEAU" },
  "70542": { state: "LA", city: "GUEYDAN" },
  "70543": { state: "LA", city: "IOTA" },
  "70544": { state: "LA", city: "JEANERETTE" },
  "70546": { state: "LA", city: "JENNINGS" },
  "70548": { state: "LA", city: "KAPLAN" },
  "70549": { state: "LA", city: "LAKE ARTHUR" },
  "70550": { state: "LA", city: "LAWTELL" },
  "70551": { state: "LA", city: "LEONVILLE" },
  "70552": { state: "LA", city: "LOREAUVILLE" },
  "70554": { state: "LA", city: "MAMOU" },
  "70555": { state: "LA", city: "MAURICE" },
  "70556": { state: "LA", city: "MERMENTAU" },
  "70558": { state: "LA", city: "MILTON" },
  "70559": { state: "LA", city: "MORSE" },
  "70560": { state: "LA", city: "NEW IBERIA" },
  "70562": { state: "LA", city: "NEW IBERIA" },
  "70563": { state: "LA", city: "NEW IBERIA" },
  "70569": { state: "LA", city: "LYDIA" },
  "70570": { state: "LA", city: "OPELOUSAS" },
  "70571": { state: "LA", city: "OPELOUSAS" },
  "70575": { state: "LA", city: "PERRY" },
  "70576": { state: "LA", city: "PINE PRAIRIE" },
  "70577": { state: "LA", city: "PORT BARRE" },
  "70578": { state: "LA", city: "RAYNE" },
  "70580": { state: "LA", city: "REDDELL" },
  "70581": { state: "LA", city: "ROANOKE" },
  "70582": { state: "LA", city: "SAINT MARTINVILLE" },
  "70583": { state: "LA", city: "SCOTT" },
  "70584": { state: "LA", city: "SUNSET" },
  "70585": { state: "LA", city: "TURKEY CREEK" },
  "70586": { state: "LA", city: "VILLE PLATTE" },
  "70589": { state: "LA", city: "WASHINGTON" },
  "70591": { state: "LA", city: "WELSH" },
  "70592": { state: "LA", city: "YOUNGSVILLE" },
  "70593": { state: "LA", city: "LAFAYETTE" },
  "70596": { state: "LA", city: "LAFAYETTE" },
  "70598": { state: "LA", city: "LAFAYETTE" },
  "70601": { state: "LA", city: "LAKE CHARLES" },
  "70602": { state: "LA", city: "LAKE CHARLES" },
  "70605": { state: "LA", city: "LAKE CHARLES" },
  "70606": { state: "LA", city: "LAKE CHARLES" },
  "70607": { state: "LA", city: "LAKE CHARLES" },
  "70609": { state: "LA", city: "LAKE CHARLES" },
  "70611": { state: "LA", city: "LAKE CHARLES" },
  "70612": { state: "LA", city: "LAKE CHARLES" },
  "70615": { state: "LA", city: "LAKE CHARLES" },
  "70616": { state: "LA", city: "LAKE CHARLES" },
  "70629": { state: "LA", city: "LAKE CHARLES" },
  "70630": { state: "LA", city: "BELL CITY" },
  "70631": { state: "LA", city: "CAMERON" },
  "70632": { state: "LA", city: "CREOLE" },
  "70633": { state: "LA", city: "DEQUINCY" },
  "70634": { state: "LA", city: "DERIDDER" },
  "70637": { state: "LA", city: "DRY CREEK" },
  "70638": { state: "LA", city: "ELIZABETH" },
  "70639": { state: "LA", city: "EVANS" },
  "70640": { state: "LA", city: "FENTON" },
  "70643": { state: "LA", city: "GRAND CHENIER" },
  "70644": { state: "LA", city: "GRANT" },
  "70645": { state: "LA", city: "HACKBERRY" },
  "70646": { state: "LA", city: "HAYES" },
  "70647": { state: "LA", city: "IOWA" },
  "70648": { state: "LA", city: "KINDER" },
  "70650": { state: "LA", city: "LACASSINE" },
  "70651": { state: "LA", city: "LEBLANC" },
  "70652": { state: "LA", city: "LONGVILLE" },
  "70653": { state: "LA", city: "MERRYVILLE" },
  "70654": { state: "LA", city: "MITTIE" },
  "70655": { state: "LA", city: "OBERLIN" },
  "70656": { state: "LA", city: "PITKIN" },
  "70657": { state: "LA", city: "RAGLEY" },
  "70658": { state: "LA", city: "REEVES" },
  "70659": { state: "LA", city: "ROSEPINE" },
  "70660": { state: "LA", city: "SINGER" },
  "70661": { state: "LA", city: "STARKS" },
  "70662": { state: "LA", city: "SUGARTOWN" },
  "70663": { state: "LA", city: "SULPHUR" },
  "70664": { state: "LA", city: "SULPHUR" },
  "70665": { state: "LA", city: "SULPHUR" },
  "70668": { state: "LA", city: "VINTON" },
  "70669": { state: "LA", city: "WESTLAKE" },
  "70704": { state: "LA", city: "BAKER" },
  "70706": { state: "LA", city: "DENHAM SPRINGS" },
  "70707": { state: "LA", city: "GONZALES" },
  "70710": { state: "LA", city: "ADDIS" },
  "70711": { state: "LA", city: "ALBANY" },
  "70712": { state: "LA", city: "ANGOLA" },
  "70714": { state: "LA", city: "BAKER" },
  "70715": { state: "LA", city: "BATCHELOR" },
  "70718": { state: "LA", city: "BRITTANY" },
  "70719": { state: "LA", city: "BRUSLY" },
  "70721": { state: "LA", city: "CARVILLE" },
  "70722": { state: "LA", city: "CLINTON" },
  "70723": { state: "LA", city: "CONVENT" },
  "70725": { state: "LA", city: "DARROW" },
  "70726": { state: "LA", city: "DENHAM SPRINGS" },
  "70727": { state: "LA", city: "DENHAM SPRINGS" },
  "70728": { state: "LA", city: "DUPLESSIS" },
  "70729": { state: "LA", city: "ERWINVILLE" },
  "70730": { state: "LA", city: "ETHEL" },
  "70732": { state: "LA", city: "FORDOCHE" },
  "70733": { state: "LA", city: "FRENCH SETTLEMENT" },
  "70734": { state: "LA", city: "GEISMAR" },
  "70736": { state: "LA", city: "GLYNN" },
  "70737": { state: "LA", city: "GONZALES" },
  "70738": { state: "LA", city: "BURNSIDE" },
  "70739": { state: "LA", city: "GREENWELL SPRINGS" },
  "70740": { state: "LA", city: "GROSSE TETE" },
  "70743": { state: "LA", city: "HESTER" },
  "70744": { state: "LA", city: "HOLDEN" },
  "70747": { state: "LA", city: "INNIS" },
  "70748": { state: "LA", city: "JACKSON" },
  "70749": { state: "LA", city: "JARREAU" },
  "70750": { state: "LA", city: "KROTZ SPRINGS" },
  "70752": { state: "LA", city: "LAKELAND" },
  "70753": { state: "LA", city: "LETTSWORTH" },
  "70754": { state: "LA", city: "LIVINGSTON" },
  "70755": { state: "LA", city: "LIVONIA" },
  "70756": { state: "LA", city: "LOTTIE" },
  "70757": { state: "LA", city: "MARINGOUIN" },
  "70759": { state: "LA", city: "MORGANZA" },
  "70760": { state: "LA", city: "NEW ROADS" },
  "70761": { state: "LA", city: "NORWOOD" },
  "70762": { state: "LA", city: "OSCAR" },
  "70763": { state: "LA", city: "PAULINA" },
  "70764": { state: "LA", city: "PLAQUEMINE" },
  "70765": { state: "LA", city: "PLAQUEMINE" },
  "70767": { state: "LA", city: "PORT ALLEN" },
  "70769": { state: "LA", city: "PRAIRIEVILLE" },
  "70770": { state: "LA", city: "PRIDE" },
  "70772": { state: "LA", city: "ROSEDALE" },
  "70773": { state: "LA", city: "ROUGON" },
  "70774": { state: "LA", city: "SAINT AMANT" },
  "70775": { state: "LA", city: "SAINT FRANCISVILLE" },
  "70776": { state: "LA", city: "SAINT GABRIEL" },
  "70777": { state: "LA", city: "SLAUGHTER" },
  "70778": { state: "LA", city: "SORRENTO" },
  "70780": { state: "LA", city: "SUNSHINE" },
  "70782": { state: "LA", city: "TUNICA" },
  "70783": { state: "LA", city: "VENTRESS" },
  "70784": { state: "LA", city: "WAKEFIELD" },
  "70785": { state: "LA", city: "WALKER" },
  "70786": { state: "LA", city: "WATSON" },
  "70787": { state: "LA", city: "WEYANOKE" },
  "70788": { state: "LA", city: "WHITE CASTLE" },
  "70789": { state: "LA", city: "WILSON" },
  "70791": { state: "LA", city: "ZACHARY" },
  "70792": { state: "LA", city: "UNCLE SAM" },
  "70801": { state: "LA", city: "BATON ROUGE" },
  "70802": { state: "LA", city: "BATON ROUGE" },
  "70803": { state: "LA", city: "BATON ROUGE" },
  "70804": { state: "LA", city: "BATON ROUGE" },
  "70805": { state: "LA", city: "BATON ROUGE" },
  "70806": { state: "LA", city: "BATON ROUGE" },
  "70807": { state: "LA", city: "BATON ROUGE" },
  "70808": { state: "LA", city: "BATON ROUGE" },
  "70809": { state: "LA", city: "BATON ROUGE" },
  "70810": { state: "LA", city: "BATON ROUGE" },
  "70811": { state: "LA", city: "BATON ROUGE" },
  "70812": { state: "LA", city: "BATON ROUGE" },
  "70813": { state: "LA", city: "BATON ROUGE" },
  "70814": { state: "LA", city: "BATON ROUGE" },
  "70815": { state: "LA", city: "BATON ROUGE" },
  "70816": { state: "LA", city: "BATON ROUGE" },
  "70817": { state: "LA", city: "BATON ROUGE" },
  "70818": { state: "LA", city: "BATON ROUGE" },
  "70819": { state: "LA", city: "BATON ROUGE" },
  "70820": { state: "LA", city: "BATON ROUGE" },
  "70821": { state: "LA", city: "BATON ROUGE" },
  "70822": { state: "LA", city: "BATON ROUGE" },
  "70823": { state: "LA", city: "BATON ROUGE" },
  "70825": { state: "LA", city: "BATON ROUGE" },
  "70826": { state: "LA", city: "BATON ROUGE" },
  "70827": { state: "LA", city: "BATON ROUGE" },
  "70831": { state: "LA", city: "BATON ROUGE" },
  "70833": { state: "LA", city: "BATON ROUGE" },
  "70835": { state: "LA", city: "BATON ROUGE" },
  "70836": { state: "LA", city: "BATON ROUGE" },
  "70837": { state: "LA", city: "BATON ROUGE" },
  "70873": { state: "LA", city: "BATON ROUGE" },
  "70874": { state: "LA", city: "BATON ROUGE" },
  "70879": { state: "LA", city: "BATON ROUGE" },
  "70884": { state: "LA", city: "BATON ROUGE" },
  "70891": { state: "LA", city: "BATON ROUGE" },
  "70892": { state: "LA", city: "BATON ROUGE" },
  "70893": { state: "LA", city: "BATON ROUGE" },
  "70894": { state: "LA", city: "BATON ROUGE" },
  "70895": { state: "LA", city: "BATON ROUGE" },
  "70896": { state: "LA", city: "BATON ROUGE" },
  "70898": { state: "LA", city: "BATON ROUGE" },
  "71001": { state: "LA", city: "ARCADIA" },
  "71002": { state: "LA", city: "ASHLAND" },
  "71003": { state: "LA", city: "ATHENS" },
  "71004": { state: "LA", city: "BELCHER" },
  "71006": { state: "LA", city: "BENTON" },
  "71007": { state: "LA", city: "BETHANY" },
  "71008": { state: "LA", city: "BIENVILLE" },
  "71009": { state: "LA", city: "BLANCHARD" },
  "71016": { state: "LA", city: "CASTOR" },
  "71018": { state: "LA", city: "COTTON VALLEY" },
  "71019": { state: "LA", city: "COUSHATTA" },
  "71021": { state: "LA", city: "CULLEN" },
  "71023": { state: "LA", city: "DOYLINE" },
  "71024": { state: "LA", city: "DUBBERLY" },
  "71027": { state: "LA", city: "FRIERSON" },
  "71028": { state: "LA", city: "GIBSLAND" },
  "71029": { state: "LA", city: "GILLIAM" },
  "71030": { state: "LA", city: "GLOSTER" },
  "71031": { state: "LA", city: "GOLDONNA" },
  "71032": { state: "LA", city: "GRAND CANE" },
  "71033": { state: "LA", city: "GREENWOOD" },
  "71034": { state: "LA", city: "HALL SUMMIT" },
  "71037": { state: "LA", city: "HAUGHTON" },
  "71038": { state: "LA", city: "HAYNESVILLE" },
  "71039": { state: "LA", city: "HEFLIN" },
  "71040": { state: "LA", city: "HOMER" },
  "71043": { state: "LA", city: "HOSSTON" },
  "71044": { state: "LA", city: "IDA" },
  "71045": { state: "LA", city: "JAMESTOWN" },
  "71046": { state: "LA", city: "KEATCHIE" },
  "71047": { state: "LA", city: "KEITHVILLE" },
  "71048": { state: "LA", city: "LISBON" },
  "71049": { state: "LA", city: "LOGANSPORT" },
  "71050": { state: "LA", city: "LONGSTREET" },
  "71051": { state: "LA", city: "ELM GROVE" },
  "71052": { state: "LA", city: "MANSFIELD" },
  "71055": { state: "LA", city: "MINDEN" },
  "71058": { state: "LA", city: "MINDEN" },
  "71060": { state: "LA", city: "MOORINGSPORT" },
  "71061": { state: "LA", city: "OIL CITY" },
  "71063": { state: "LA", city: "PELICAN" },
  "71064": { state: "LA", city: "PLAIN DEALING" },
  "71065": { state: "LA", city: "PLEASANT HILL" },
  "71066": { state: "LA", city: "POWHATAN" },
  "71067": { state: "LA", city: "PRINCETON" },
  "71068": { state: "LA", city: "RINGGOLD" },
  "71069": { state: "LA", city: "RODESSA" },
  "71070": { state: "LA", city: "SALINE" },
  "71071": { state: "LA", city: "SAREPTA" },
  "71072": { state: "LA", city: "SHONGALOO" },
  "71073": { state: "LA", city: "SIBLEY" },
  "71075": { state: "LA", city: "SPRINGHILL" },
  "71078": { state: "LA", city: "STONEWALL" },
  "71079": { state: "LA", city: "SUMMERFIELD" },
  "71080": { state: "LA", city: "TAYLOR" },
  "71082": { state: "LA", city: "VIVIAN" },
  "71101": { state: "LA", city: "SHREVEPORT" },
  "71102": { state: "LA", city: "SHREVEPORT" },
  "71103": { state: "LA", city: "SHREVEPORT" },
  "71104": { state: "LA", city: "SHREVEPORT" },
  "71105": { state: "LA", city: "SHREVEPORT" },
  "71106": { state: "LA", city: "SHREVEPORT" },
  "71107": { state: "LA", city: "SHREVEPORT" },
  "71108": { state: "LA", city: "SHREVEPORT" },
  "71109": { state: "LA", city: "SHREVEPORT" },
  "71110": { state: "LA", city: "BARKSDALE AFB" },
  "71111": { state: "LA", city: "BOSSIER CITY" },
  "71112": { state: "LA", city: "BOSSIER CITY" },
  "71113": { state: "LA", city: "BOSSIER CITY" },
  "71115": { state: "LA", city: "SHREVEPORT" },
  "71118": { state: "LA", city: "SHREVEPORT" },
  "71119": { state: "LA", city: "SHREVEPORT" },
  "71120": { state: "LA", city: "SHREVEPORT" },
  "71129": { state: "LA", city: "SHREVEPORT" },
  "71130": { state: "LA", city: "SHREVEPORT" },
  "71133": { state: "LA", city: "SHREVEPORT" },
  "71134": { state: "LA", city: "SHREVEPORT" },
  "71135": { state: "LA", city: "SHREVEPORT" },
  "71136": { state: "LA", city: "SHREVEPORT" },
  "71137": { state: "LA", city: "SHREVEPORT" },
  "71138": { state: "LA", city: "SHREVEPORT" },
  "71148": { state: "LA", city: "SHREVEPORT" },
  "71149": { state: "LA", city: "SHREVEPORT" },
  "71150": { state: "LA", city: "SHREVEPORT" },
  "71151": { state: "LA", city: "SHREVEPORT" },
  "71152": { state: "LA", city: "SHREVEPORT" },
  "71153": { state: "LA", city: "SHREVEPORT" },
  "71154": { state: "LA", city: "SHREVEPORT" },
  "71156": { state: "LA", city: "SHREVEPORT" },
  "71161": { state: "LA", city: "SHREVEPORT" },
  "71162": { state: "LA", city: "SHREVEPORT" },
  "71163": { state: "LA", city: "SHREVEPORT" },
  "71164": { state: "LA", city: "SHREVEPORT" },
  "71165": { state: "LA", city: "SHREVEPORT" },
  "71166": { state: "LA", city: "SHREVEPORT" },
  "71171": { state: "LA", city: "BOSSIER CITY" },
  "71172": { state: "LA", city: "BOSSIER CITY" },
  "71201": { state: "LA", city: "MONROE" },
  "71202": { state: "LA", city: "MONROE" },
  "71203": { state: "LA", city: "MONROE" },
  "71207": { state: "LA", city: "MONROE" },
  "71209": { state: "LA", city: "MONROE" },
  "71210": { state: "LA", city: "MONROE" },
  "71211": { state: "LA", city: "MONROE" },
  "71212": { state: "LA", city: "MONROE" },
  "71213": { state: "LA", city: "MONROE" },
  "71217": { state: "LA", city: "MONROE" },
  "71218": { state: "LA", city: "ARCHIBALD" },
  "71219": { state: "LA", city: "BASKIN" },
  "71220": { state: "LA", city: "BASTROP" },
  "71221": { state: "LA", city: "BASTROP" },
  "71222": { state: "LA", city: "BERNICE" },
  "71223": { state: "LA", city: "BONITA" },
  "71225": { state: "LA", city: "CALHOUN" },
  "71226": { state: "LA", city: "CHATHAM" },
  "71227": { state: "LA", city: "CHOUDRANT" },
  "71229": { state: "LA", city: "COLLINSTON" },
  "71230": { state: "LA", city: "CROWVILLE" },
  "71232": { state: "LA", city: "DELHI" },
  "71233": { state: "LA", city: "DELTA" },
  "71234": { state: "LA", city: "DOWNSVILLE" },
  "71235": { state: "LA", city: "DUBACH" },
  "71237": { state: "LA", city: "EPPS" },
  "71238": { state: "LA", city: "EROS" },
  "71240": { state: "LA", city: "FAIRBANKS" },
  "71241": { state: "LA", city: "FARMERVILLE" },
  "71242": { state: "LA", city: "FOREST" },
  "71243": { state: "LA", city: "FORT NECESSITY" },
  "71245": { state: "LA", city: "GRAMBLING" },
  "71247": { state: "LA", city: "HODGE" },
  "71249": { state: "LA", city: "JIGGER" },
  "71250": { state: "LA", city: "JONES" },
  "71251": { state: "LA", city: "JONESBORO" },
  "71253": { state: "LA", city: "KILBOURNE" },
  "71254": { state: "LA", city: "LAKE PROVIDENCE" },
  "71256": { state: "LA", city: "LILLIE" },
  "71259": { state: "LA", city: "MANGHAM" },
  "71260": { state: "LA", city: "MARION" },
  "71261": { state: "LA", city: "MER ROUGE" },
  "71263": { state: "LA", city: "OAK GROVE" },
  "71264": { state: "LA", city: "OAK RIDGE" },
  "71266": { state: "LA", city: "PIONEER" },
  "71268": { state: "LA", city: "QUITMAN" },
  "71269": { state: "LA", city: "RAYVILLE" },
  "71270": { state: "LA", city: "RUSTON" },
  "71272": { state: "LA", city: "RUSTON" },
  "71273": { state: "LA", city: "RUSTON" },
  "71275": { state: "LA", city: "SIMSBORO" },
  "71276": { state: "LA", city: "SONDHEIMER" },
  "71277": { state: "LA", city: "SPEARSVILLE" },
  "71279": { state: "LA", city: "START" },
  "71280": { state: "LA", city: "STERLINGTON" },
  "71281": { state: "LA", city: "SWARTZ" },
  "71282": { state: "LA", city: "TALLULAH" },
  "71284": { state: "LA", city: "TALLULAH" },
  "71286": { state: "LA", city: "TRANSYLVANIA" },
  "71291": { state: "LA", city: "WEST MONROE" },
  "71292": { state: "LA", city: "WEST MONROE" },
  "71294": { state: "LA", city: "WEST MONROE" },
  "71295": { state: "LA", city: "WINNSBORO" },
  "71301": { state: "LA", city: "ALEXANDRIA" },
  "71302": { state: "LA", city: "ALEXANDRIA" },
  "71303": { state: "LA", city: "ALEXANDRIA" },
  "71306": { state: "LA", city: "ALEXANDRIA" },
  "71307": { state: "LA", city: "ALEXANDRIA" },
  "71309": { state: "LA", city: "ALEXANDRIA" },
  "71315": { state: "LA", city: "ALEXANDRIA" },
  "71316": { state: "LA", city: "ACME" },
  "71320": { state: "LA", city: "BORDELONVILLE" },
  "71322": { state: "LA", city: "BUNKIE" },
  "71323": { state: "LA", city: "CENTER POINT" },
  "71324": { state: "LA", city: "CHASE" },
  "71325": { state: "LA", city: "CHENEYVILLE" },
  "71326": { state: "LA", city: "CLAYTON" },
  "71327": { state: "LA", city: "COTTONPORT" },
  "71328": { state: "LA", city: "DEVILLE" },
  "71329": { state: "LA", city: "DUPONT" },
  "71330": { state: "LA", city: "ECHO" },
  "71331": { state: "LA", city: "EFFIE" },
  "71333": { state: "LA", city: "EVERGREEN" },
  "71334": { state: "LA", city: "FERRIDAY" },
  "71336": { state: "LA", city: "GILBERT" },
  "71339": { state: "LA", city: "HAMBURG" },
  "71340": { state: "LA", city: "HARRISONBURG" },
  "71341": { state: "LA", city: "HESSMER" },
  "71342": { state: "LA", city: "JENA" },
  "71343": { state: "LA", city: "JONESVILLE" },
  "71345": { state: "LA", city: "LEBEAU" },
  "71346": { state: "LA", city: "LECOMPTE" },
  "71348": { state: "LA", city: "LIBUSE" },
  "71350": { state: "LA", city: "MANSURA" },
  "71351": { state: "LA", city: "MARKSVILLE" },
  "71353": { state: "LA", city: "MELVILLE" },
  "71354": { state: "LA", city: "MONTEREY" },
  "71355": { state: "LA", city: "MOREAUVILLE" },
  "71356": { state: "LA", city: "MORROW" },
  "71357": { state: "LA", city: "NEWELLTON" },
  "71358": { state: "LA", city: "PALMETTO" },
  "71359": { state: "LA", city: "PINEVILLE" },
  "71360": { state: "LA", city: "PINEVILLE" },
  "71361": { state: "LA", city: "PINEVILLE" },
  "71362": { state: "LA", city: "PLAUCHEVILLE" },
  "71363": { state: "LA", city: "RHINEHART" },
  "71365": { state: "LA", city: "RUBY" },
  "71366": { state: "LA", city: "SAINT JOSEPH" },
  "71367": { state: "LA", city: "SAINT LANDRY" },
  "71368": { state: "LA", city: "SICILY ISLAND" },
  "71369": { state: "LA", city: "SIMMESPORT" },
  "71371": { state: "LA", city: "TROUT" },
  "71373": { state: "LA", city: "VIDALIA" },
  "71375": { state: "LA", city: "WATERPROOF" },
  "71377": { state: "LA", city: "WILDSVILLE" },
  "71378": { state: "LA", city: "WISNER" },
  "71401": { state: "LA", city: "AIMWELL" },
  "71403": { state: "LA", city: "ANACOCO" },
  "71404": { state: "LA", city: "ATLANTA" },
  "71405": { state: "LA", city: "BALL" },
  "71406": { state: "LA", city: "BELMONT" },
  "71407": { state: "LA", city: "BENTLEY" },
  "71409": { state: "LA", city: "BOYCE" },
  "71410": { state: "LA", city: "CALVIN" },
  "71411": { state: "LA", city: "CAMPTI" },
  "71414": { state: "LA", city: "CLARENCE" },
  "71415": { state: "LA", city: "CLARKS" },
  "71416": { state: "LA", city: "CLOUTIERVILLE" },
  "71417": { state: "LA", city: "COLFAX" },
  "71418": { state: "LA", city: "COLUMBIA" },
  "71419": { state: "LA", city: "CONVERSE" },
  "71422": { state: "LA", city: "DODSON" },
  "71423": { state: "LA", city: "DRY PRONG" },
  "71424": { state: "LA", city: "ELMER" },
  "71425": { state: "LA", city: "ENTERPRISE" },
  "71426": { state: "LA", city: "FISHER" },
  "71427": { state: "LA", city: "FLATWOODS" },
  "71428": { state: "LA", city: "FLORA" },
  "71429": { state: "LA", city: "FLORIEN" },
  "71430": { state: "LA", city: "FOREST HILL" },
  "71431": { state: "LA", city: "GARDNER" },
  "71432": { state: "LA", city: "GEORGETOWN" },
  "71433": { state: "LA", city: "GLENMORA" },
  "71434": { state: "LA", city: "GORUM" },
  "71435": { state: "LA", city: "GRAYSON" },
  "71438": { state: "LA", city: "HINESTON" },
  "71439": { state: "LA", city: "HORNBECK" },
  "71440": { state: "LA", city: "JOYCE" },
  "71441": { state: "LA", city: "KELLY" },
  "71443": { state: "LA", city: "KURTHWOOD" },
  "71446": { state: "LA", city: "LEESVILLE" },
  "71447": { state: "LA", city: "LENA" },
  "71448": { state: "LA", city: "LONGLEAF" },
  "71449": { state: "LA", city: "MANY" },
  "71450": { state: "LA", city: "MARTHAVILLE" },
  "71452": { state: "LA", city: "MELROSE" },
  "71454": { state: "LA", city: "MONTGOMERY" },
  "71455": { state: "LA", city: "MORA" },
  "71456": { state: "LA", city: "NATCHEZ" },
  "71457": { state: "LA", city: "NATCHITOCHES" },
  "71458": { state: "LA", city: "NATCHITOCHES" },
  "71459": { state: "LA", city: "FORT JOHNSON" },
  "71460": { state: "LA", city: "NEGREET" },
  "71461": { state: "LA", city: "NEW LLANO" },
  "71462": { state: "LA", city: "NOBLE" },
  "71463": { state: "LA", city: "OAKDALE" },
  "71465": { state: "LA", city: "OLLA" },
  "71466": { state: "LA", city: "OTIS" },
  "71467": { state: "LA", city: "POLLOCK" },
  "71468": { state: "LA", city: "PROVENCAL" },
  "71469": { state: "LA", city: "ROBELINE" },
  "71471": { state: "LA", city: "SAINT MAURICE" },
  "71472": { state: "LA", city: "SIEPER" },
  "71473": { state: "LA", city: "SIKES" },
  "71474": { state: "LA", city: "SIMPSON" },
  "71475": { state: "LA", city: "SLAGLE" },
  "71477": { state: "LA", city: "TIOGA" },
  "71479": { state: "LA", city: "TULLOS" },
  "71480": { state: "LA", city: "URANIA" },
  "71483": { state: "LA", city: "WINNFIELD" },
  "71485": { state: "LA", city: "WOODWORTH" },
  "71486": { state: "LA", city: "ZWOLLE" },
  "71496": { state: "LA", city: "LEESVILLE" },
  "71497": { state: "LA", city: "NATCHITOCHES" },
  "71601": { state: "AR", city: "PINE BLUFF" },
  "71602": { state: "AR", city: "WHITE HALL" },
  "71603": { state: "AR", city: "PINE BLUFF" },
  "71611": { state: "AR", city: "PINE BLUFF" },
  "71612": { state: "AR", city: "WHITE HALL" },
  "71613": { state: "AR", city: "PINE BLUFF" },
  "71630": { state: "AR", city: "ARKANSAS CITY" },
  "71631": { state: "AR", city: "BANKS" },
  "71635": { state: "AR", city: "CROSSETT" },
  "71638": { state: "AR", city: "DERMOTT" },
  "71639": { state: "AR", city: "DUMAS" },
  "71640": { state: "AR", city: "EUDORA" },
  "71642": { state: "AR", city: "FOUNTAIN HILL" },
  "71643": { state: "AR", city: "GOULD" },
  "71644": { state: "AR", city: "GRADY" },
  "71646": { state: "AR", city: "HAMBURG" },
  "71647": { state: "AR", city: "HERMITAGE" },
  "71651": { state: "AR", city: "JERSEY" },
  "71652": { state: "AR", city: "KINGSLAND" },
  "71653": { state: "AR", city: "LAKE VILLAGE" },
  "71654": { state: "AR", city: "MC GEHEE" },
  "71655": { state: "AR", city: "MONTICELLO" },
  "71656": { state: "AR", city: "MONTICELLO" },
  "71657": { state: "AR", city: "MONTICELLO" },
  "71658": { state: "AR", city: "MONTROSE" },
  "71659": { state: "AR", city: "MOSCOW" },
  "71660": { state: "AR", city: "NEW EDINBURG" },
  "71661": { state: "AR", city: "PARKDALE" },
  "71662": { state: "AR", city: "PICKENS" },
  "71663": { state: "AR", city: "PORTLAND" },
  "71665": { state: "AR", city: "RISON" },
  "71666": { state: "AR", city: "ROHWER" },
  "71667": { state: "AR", city: "STAR CITY" },
  "71670": { state: "AR", city: "TILLAR" },
  "71671": { state: "AR", city: "WARREN" },
  "71674": { state: "AR", city: "WATSON" },
  "71675": { state: "AR", city: "WILMAR" },
  "71676": { state: "AR", city: "WILMOT" },
  "71677": { state: "AR", city: "WINCHESTER" },
  "71678": { state: "AR", city: "YORKTOWN" },
  "71701": { state: "AR", city: "CAMDEN" },
  "71711": { state: "AR", city: "CAMDEN" },
  "71720": { state: "AR", city: "BEARDEN" },
  "71721": { state: "AR", city: "BEIRNE" },
  "71722": { state: "AR", city: "BLUFF CITY" },
  "71724": { state: "AR", city: "CALION" },
  "71725": { state: "AR", city: "CARTHAGE" },
  "71726": { state: "AR", city: "CHIDESTER" },
  "71728": { state: "AR", city: "CURTIS" },
  "71730": { state: "AR", city: "EL DORADO" },
  "71731": { state: "AR", city: "EL DORADO" },
  "71740": { state: "AR", city: "EMERSON" },
  "71742": { state: "AR", city: "FORDYCE" },
  "71743": { state: "AR", city: "GURDON" },
  "71744": { state: "AR", city: "HAMPTON" },
  "71745": { state: "AR", city: "HARRELL" },
  "71747": { state: "AR", city: "HUTTIG" },
  "71748": { state: "AR", city: "IVAN" },
  "71749": { state: "AR", city: "JUNCTION CITY" },
  "71750": { state: "AR", city: "LAWSON" },
  "71751": { state: "AR", city: "LOUANN" },
  "71752": { state: "AR", city: "MC NEIL" },
  "71753": { state: "AR", city: "MAGNOLIA" },
  "71754": { state: "AR", city: "MAGNOLIA" },
  "71758": { state: "AR", city: "MOUNT HOLLY" },
  "71759": { state: "AR", city: "NORPHLET" },
  "71762": { state: "AR", city: "SMACKOVER" },
  "71763": { state: "AR", city: "SPARKMAN" },
  "71764": { state: "AR", city: "STEPHENS" },
  "71765": { state: "AR", city: "STRONG" },
  "71766": { state: "AR", city: "THORNTON" },
  "71770": { state: "AR", city: "WALDO" },
  "71772": { state: "AR", city: "WHELEN SPRINGS" },
  "71801": { state: "AR", city: "HOPE" },
  "71802": { state: "AR", city: "HOPE" },
  "71820": { state: "AR", city: "ALLEENE" },
  "71822": { state: "AR", city: "ASHDOWN" },
  "71823": { state: "AR", city: "BEN LOMOND" },
  "71825": { state: "AR", city: "BLEVINS" },
  "71826": { state: "AR", city: "BRADLEY" },
  "71827": { state: "AR", city: "BUCKNER" },
  "71828": { state: "AR", city: "CALE" },
  "71831": { state: "AR", city: "COLUMBUS" },
  "71832": { state: "AR", city: "DE QUEEN" },
  "71833": { state: "AR", city: "DIERKS" },
  "71834": { state: "AR", city: "DODDRIDGE" },
  "71835": { state: "AR", city: "EMMET" },
  "71836": { state: "AR", city: "FOREMAN" },
  "71837": { state: "AR", city: "FOUKE" },
  "71838": { state: "AR", city: "FULTON" },
  "71839": { state: "AR", city: "GARLAND CITY" },
  "71840": { state: "AR", city: "GENOA" },
  "71841": { state: "AR", city: "GILLHAM" },
  "71842": { state: "AR", city: "HORATIO" },
  "71845": { state: "AR", city: "LEWISVILLE" },
  "71846": { state: "AR", city: "LOCKESBURG" },
  "71847": { state: "AR", city: "MC CASKILL" },
  "71851": { state: "AR", city: "MINERAL SPRINGS" },
  "71852": { state: "AR", city: "NASHVILLE" },
  "71853": { state: "AR", city: "OGDEN" },
  "71854": { state: "AR", city: "TEXARKANA" },
  "71855": { state: "AR", city: "OZAN" },
  "71857": { state: "AR", city: "PRESCOTT" },
  "71858": { state: "AR", city: "ROSSTON" },
  "71859": { state: "AR", city: "SARATOGA" },
  "71860": { state: "AR", city: "STAMPS" },
  "71861": { state: "AR", city: "TAYLOR" },
  "71862": { state: "AR", city: "WASHINGTON" },
  "71864": { state: "AR", city: "WILLISVILLE" },
  "71865": { state: "AR", city: "WILTON" },
  "71866": { state: "AR", city: "WINTHROP" },
  "71901": { state: "AR", city: "HOT SPRINGS NATIONAL PARK" },
  "71902": { state: "AR", city: "HOT SPRINGS NATIONAL PARK" },
  "71903": { state: "AR", city: "HOT SPRINGS NATIONAL PARK" },
  "71909": { state: "AR", city: "HOT SPRINGS VILLAGE" },
  "71910": { state: "AR", city: "HOT SPRINGS VILLAGE" },
  "71913": { state: "AR", city: "HOT SPRINGS NATIONAL PARK" },
  "71914": { state: "AR", city: "HOT SPRINGS NATIONAL PARK" },
  "71920": { state: "AR", city: "ALPINE" },
  "71921": { state: "AR", city: "AMITY" },
  "71922": { state: "AR", city: "ANTOINE" },
  "71923": { state: "AR", city: "ARKADELPHIA" },
  "71929": { state: "AR", city: "BISMARCK" },
  "71932": { state: "AR", city: "BOARD CAMP" },
  "71933": { state: "AR", city: "BONNERDALE" },
  "71935": { state: "AR", city: "CADDO GAP" },
  "71937": { state: "AR", city: "COVE" },
  "71940": { state: "AR", city: "DELIGHT" },
  "71941": { state: "AR", city: "DONALDSON" },
  "71942": { state: "AR", city: "FRIENDSHIP" },
  "71943": { state: "AR", city: "GLENWOOD" },
  "71944": { state: "AR", city: "GRANNIS" },
  "71945": { state: "AR", city: "HATFIELD" },
  "71949": { state: "AR", city: "JESSIEVILLE" },
  "71950": { state: "AR", city: "KIRBY" },
  "71952": { state: "AR", city: "LANGLEY" },
  "71953": { state: "AR", city: "MENA" },
  "71956": { state: "AR", city: "MOUNTAIN PINE" },
  "71957": { state: "AR", city: "MOUNT IDA" },
  "71958": { state: "AR", city: "MURFREESBORO" },
  "71959": { state: "AR", city: "NEWHOPE" },
  "71960": { state: "AR", city: "NORMAN" },
  "71961": { state: "AR", city: "ODEN" },
  "71962": { state: "AR", city: "OKOLONA" },
  "71964": { state: "AR", city: "PEARCY" },
  "71965": { state: "AR", city: "PENCIL BLUFF" },
  "71966": { state: "AR", city: "ODEN" },
  "71968": { state: "AR", city: "ROYAL" },
  "71969": { state: "AR", city: "SIMS" },
  "71970": { state: "AR", city: "STORY" },
  "71971": { state: "AR", city: "UMPIRE" },
  "71972": { state: "AR", city: "VANDERVOORT" },
  "71973": { state: "AR", city: "WICKES" },
  "71998": { state: "AR", city: "ARKADELPHIA" },
  "71999": { state: "AR", city: "ARKADELPHIA" },
  "72001": { state: "AR", city: "ADONA" },
  "72002": { state: "AR", city: "ALEXANDER" },
  "72003": { state: "AR", city: "ALMYRA" },
  "72004": { state: "AR", city: "ALTHEIMER" },
  "72005": { state: "AR", city: "AMAGON" },
  "72006": { state: "AR", city: "AUGUSTA" },
  "72007": { state: "AR", city: "AUSTIN" },
  "72010": { state: "AR", city: "BALD KNOB" },
  "72011": { state: "AR", city: "BAUXITE" },
  "72012": { state: "AR", city: "BEEBE" },
  "72013": { state: "AR", city: "BEE BRANCH" },
  "72014": { state: "AR", city: "BEEDEVILLE" },
  "72015": { state: "AR", city: "BENTON" },
  "72016": { state: "AR", city: "BIGELOW" },
  "72017": { state: "AR", city: "BISCOE" },
  "72018": { state: "AR", city: "BENTON" },
  "72019": { state: "AR", city: "BENTON" },
  "72020": { state: "AR", city: "BRADFORD" },
  "72021": { state: "AR", city: "BRINKLEY" },
  "72022": { state: "AR", city: "BRYANT" },
  "72023": { state: "AR", city: "CABOT" },
  "72024": { state: "AR", city: "CARLISLE" },
  "72025": { state: "AR", city: "CASA" },
  "72026": { state: "AR", city: "CASSCOE" },
  "72027": { state: "AR", city: "CENTER RIDGE" },
  "72028": { state: "AR", city: "CHOCTAW" },
  "72029": { state: "AR", city: "CLARENDON" },
  "72030": { state: "AR", city: "CLEVELAND" },
  "72031": { state: "AR", city: "CLINTON" },
  "72032": { state: "AR", city: "CONWAY" },
  "72033": { state: "AR", city: "CONWAY" },
  "72034": { state: "AR", city: "CONWAY" },
  "72035": { state: "AR", city: "CONWAY" },
  "72036": { state: "AR", city: "COTTON PLANT" },
  "72037": { state: "AR", city: "COY" },
  "72038": { state: "AR", city: "CROCKETTS BLUFF" },
  "72039": { state: "AR", city: "DAMASCUS" },
  "72040": { state: "AR", city: "DES ARC" },
  "72041": { state: "AR", city: "DE VALLS BLUFF" },
  "72042": { state: "AR", city: "DE WITT" },
  "72043": { state: "AR", city: "DIAZ" },
  "72044": { state: "AR", city: "EDGEMONT" },
  "72045": { state: "AR", city: "EL PASO" },
  "72046": { state: "AR", city: "ENGLAND" },
  "72047": { state: "AR", city: "ENOLA" },
  "72048": { state: "AR", city: "ETHEL" },
  "72051": { state: "AR", city: "FOX" },
  "72052": { state: "AR", city: "GARNER" },
  "72053": { state: "AR", city: "COLLEGE STATION" },
  "72055": { state: "AR", city: "GILLETT" },
  "72057": { state: "AR", city: "GRAPEVINE" },
  "72058": { state: "AR", city: "GREENBRIER" },
  "72059": { state: "AR", city: "GREGORY" },
  "72060": { state: "AR", city: "GRIFFITHVILLE" },
  "72061": { state: "AR", city: "GUY" },
  "72063": { state: "AR", city: "HATTIEVILLE" },
  "72064": { state: "AR", city: "HAZEN" },
  "72065": { state: "AR", city: "HENSLEY" },
  "72066": { state: "AR", city: "HICKORY PLAINS" },
  "72067": { state: "AR", city: "HIGDEN" },
  "72068": { state: "AR", city: "HIGGINSON" },
  "72069": { state: "AR", city: "HOLLY GROVE" },
  "72070": { state: "AR", city: "HOUSTON" },
  "72072": { state: "AR", city: "HUMNOKE" },
  "72073": { state: "AR", city: "HUMPHREY" },
  "72074": { state: "AR", city: "HUNTER" },
  "72075": { state: "AR", city: "JACKSONPORT" },
  "72076": { state: "AR", city: "JACKSONVILLE" },
  "72078": { state: "AR", city: "JACKSONVILLE" },
  "72079": { state: "AR", city: "JEFFERSON" },
  "72080": { state: "AR", city: "JERUSALEM" },
  "72081": { state: "AR", city: "JUDSONIA" },
  "72082": { state: "AR", city: "KENSETT" },
  "72083": { state: "AR", city: "KEO" },
  "72084": { state: "AR", city: "LEOLA" },
  "72085": { state: "AR", city: "LETONA" },
  "72086": { state: "AR", city: "LONOKE" },
  "72087": { state: "AR", city: "LONSDALE" },
  "72088": { state: "AR", city: "FAIRFIELD BAY" },
  "72089": { state: "AR", city: "BRYANT" },
  "72099": { state: "AR", city: "LITTLE ROCK AIR FORCE BASE" },
  "72101": { state: "AR", city: "MC CRORY" },
  "72102": { state: "AR", city: "MC RAE" },
  "72103": { state: "AR", city: "MABELVALE" },
  "72104": { state: "AR", city: "MALVERN" },
  "72106": { state: "AR", city: "MAYFLOWER" },
  "72107": { state: "AR", city: "MENIFEE" },
  "72108": { state: "AR", city: "MONROE" },
  "72110": { state: "AR", city: "MORRILTON" },
  "72111": { state: "AR", city: "MOUNT VERNON" },
  "72112": { state: "AR", city: "NEWPORT" },
  "72113": { state: "AR", city: "MAUMELLE" },
  "72114": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72115": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72116": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72117": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72118": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72119": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72120": { state: "AR", city: "SHERWOOD" },
  "72121": { state: "AR", city: "PANGBURN" },
  "72122": { state: "AR", city: "PARON" },
  "72123": { state: "AR", city: "PATTERSON" },
  "72124": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72125": { state: "AR", city: "PERRY" },
  "72126": { state: "AR", city: "PERRYVILLE" },
  "72127": { state: "AR", city: "PLUMERVILLE" },
  "72128": { state: "AR", city: "POYEN" },
  "72129": { state: "AR", city: "PRATTSVILLE" },
  "72130": { state: "AR", city: "PRIM" },
  "72131": { state: "AR", city: "QUITMAN" },
  "72132": { state: "AR", city: "REDFIELD" },
  "72133": { state: "AR", city: "REYDELL" },
  "72134": { state: "AR", city: "ROE" },
  "72135": { state: "AR", city: "ROLAND" },
  "72136": { state: "AR", city: "ROMANCE" },
  "72137": { state: "AR", city: "ROSE BUD" },
  "72139": { state: "AR", city: "RUSSELL" },
  "72140": { state: "AR", city: "SAINT CHARLES" },
  "72141": { state: "AR", city: "SCOTLAND" },
  "72142": { state: "AR", city: "SCOTT" },
  "72143": { state: "AR", city: "SEARCY" },
  "72145": { state: "AR", city: "SEARCY" },
  "72149": { state: "AR", city: "SEARCY" },
  "72150": { state: "AR", city: "SHERIDAN" },
  "72152": { state: "AR", city: "SHERRILL" },
  "72153": { state: "AR", city: "SHIRLEY" },
  "72156": { state: "AR", city: "SOLGOHACHIA" },
  "72157": { state: "AR", city: "SPRINGFIELD" },
  "72158": { state: "AR", city: "BENTON" },
  "72160": { state: "AR", city: "STUTTGART" },
  "72164": { state: "AR", city: "SWEET HOME" },
  "72165": { state: "AR", city: "THIDA" },
  "72166": { state: "AR", city: "TICHNOR" },
  "72167": { state: "AR", city: "TRASKWOOD" },
  "72168": { state: "AR", city: "TUCKER" },
  "72169": { state: "AR", city: "TUPELO" },
  "72170": { state: "AR", city: "ULM" },
  "72173": { state: "AR", city: "VILONIA" },
  "72175": { state: "AR", city: "WABBASEKA" },
  "72176": { state: "AR", city: "WARD" },
  "72178": { state: "AR", city: "WEST POINT" },
  "72179": { state: "AR", city: "WILBURN" },
  "72180": { state: "AR", city: "WOODSON" },
  "72181": { state: "AR", city: "WOOSTER" },
  "72182": { state: "AR", city: "WRIGHT" },
  "72183": { state: "AR", city: "WRIGHTSVILLE" },
  "72190": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72199": { state: "AR", city: "NORTH LITTLE ROCK" },
  "72201": { state: "AR", city: "LITTLE ROCK" },
  "72202": { state: "AR", city: "LITTLE ROCK" },
  "72203": { state: "AR", city: "LITTLE ROCK" },
  "72204": { state: "AR", city: "LITTLE ROCK" },
  "72205": { state: "AR", city: "LITTLE ROCK" },
  "72206": { state: "AR", city: "LITTLE ROCK" },
  "72207": { state: "AR", city: "LITTLE ROCK" },
  "72209": { state: "AR", city: "LITTLE ROCK" },
  "72210": { state: "AR", city: "LITTLE ROCK" },
  "72211": { state: "AR", city: "LITTLE ROCK" },
  "72212": { state: "AR", city: "LITTLE ROCK" },
  "72214": { state: "AR", city: "LITTLE ROCK" },
  "72215": { state: "AR", city: "LITTLE ROCK" },
  "72216": { state: "AR", city: "LITTLE ROCK" },
  "72217": { state: "AR", city: "LITTLE ROCK" },
  "72219": { state: "AR", city: "LITTLE ROCK" },
  "72221": { state: "AR", city: "LITTLE ROCK" },
  "72222": { state: "AR", city: "LITTLE ROCK" },
  "72223": { state: "AR", city: "LITTLE ROCK" },
  "72225": { state: "AR", city: "LITTLE ROCK" },
  "72227": { state: "AR", city: "LITTLE ROCK" },
  "72231": { state: "AR", city: "LITTLE ROCK" },
  "72255": { state: "AR", city: "LITTLE ROCK" },
  "72260": { state: "AR", city: "LITTLE ROCK" },
  "72295": { state: "AR", city: "LITTLE ROCK" },
  "72301": { state: "AR", city: "WEST MEMPHIS" },
  "72303": { state: "AR", city: "WEST MEMPHIS" },
  "72310": { state: "AR", city: "ARMOREL" },
  "72311": { state: "AR", city: "AUBREY" },
  "72312": { state: "AR", city: "BARTON" },
  "72313": { state: "AR", city: "BASSETT" },
  "72315": { state: "AR", city: "BLYTHEVILLE" },
  "72316": { state: "AR", city: "BLYTHEVILLE" },
  "72319": { state: "AR", city: "GOSNELL" },
  "72320": { state: "AR", city: "BRICKEYS" },
  "72321": { state: "AR", city: "BURDETTE" },
  "72322": { state: "AR", city: "CALDWELL" },
  "72324": { state: "AR", city: "CHERRY VALLEY" },
  "72325": { state: "AR", city: "CLARKEDALE" },
  "72326": { state: "AR", city: "COLT" },
  "72327": { state: "AR", city: "CRAWFORDSVILLE" },
  "72328": { state: "AR", city: "CRUMROD" },
  "72329": { state: "AR", city: "DRIVER" },
  "72330": { state: "AR", city: "DYESS" },
  "72331": { state: "AR", city: "EARLE" },
  "72332": { state: "AR", city: "EDMONDSON" },
  "72333": { state: "AR", city: "ELAINE" },
  "72335": { state: "AR", city: "FORREST CITY" },
  "72336": { state: "AR", city: "FORREST CITY" },
  "72338": { state: "AR", city: "FRENCHMANS BAYOU" },
  "72339": { state: "AR", city: "GILMORE" },
  "72340": { state: "AR", city: "GOODWIN" },
  "72341": { state: "AR", city: "HAYNES" },
  "72342": { state: "AR", city: "HELENA" },
  "72346": { state: "AR", city: "HETH" },
  "72347": { state: "AR", city: "HICKORY RIDGE" },
  "72348": { state: "AR", city: "HUGHES" },
  "72350": { state: "AR", city: "JOINER" },
  "72351": { state: "AR", city: "KEISER" },
  "72352": { state: "AR", city: "LA GRANGE" },
  "72353": { state: "AR", city: "LAMBROOK" },
  "72354": { state: "AR", city: "LEPANTO" },
  "72355": { state: "AR", city: "LEXA" },
  "72358": { state: "AR", city: "LUXORA" },
  "72359": { state: "AR", city: "MADISON" },
  "72360": { state: "AR", city: "MARIANNA" },
  "72364": { state: "AR", city: "MARION" },
  "72365": { state: "AR", city: "MARKED TREE" },
  "72366": { state: "AR", city: "MARVELL" },
  "72367": { state: "AR", city: "MELLWOOD" },
  "72368": { state: "AR", city: "MORO" },
  "72369": { state: "AR", city: "ONEIDA" },
  "72370": { state: "AR", city: "OSCEOLA" },
  "72372": { state: "AR", city: "PALESTINE" },
  "72373": { state: "AR", city: "PARKIN" },
  "72374": { state: "AR", city: "POPLAR GROVE" },
  "72376": { state: "AR", city: "PROCTOR" },
  "72377": { state: "AR", city: "RIVERVALE" },
  "72379": { state: "AR", city: "SNOW LAKE" },
  "72383": { state: "AR", city: "TURNER" },
  "72384": { state: "AR", city: "TURRELL" },
  "72386": { state: "AR", city: "TYRONZA" },
  "72387": { state: "AR", city: "VANNDALE" },
  "72389": { state: "AR", city: "WABASH" },
  "72390": { state: "AR", city: "WEST HELENA" },
  "72391": { state: "AR", city: "WEST RIDGE" },
  "72392": { state: "AR", city: "WHEATLEY" },
  "72394": { state: "AR", city: "WIDENER" },
  "72395": { state: "AR", city: "WILSON" },
  "72396": { state: "AR", city: "WYNNE" },
  "72401": { state: "AR", city: "JONESBORO" },
  "72402": { state: "AR", city: "JONESBORO" },
  "72403": { state: "AR", city: "JONESBORO" },
  "72404": { state: "AR", city: "JONESBORO" },
  "72405": { state: "AR", city: "JONESBORO" },
  "72410": { state: "AR", city: "ALICIA" },
  "72411": { state: "AR", city: "BAY" },
  "72412": { state: "AR", city: "BEECH GROVE" },
  "72413": { state: "AR", city: "BIGGERS" },
  "72414": { state: "AR", city: "BLACK OAK" },
  "72415": { state: "AR", city: "BLACK ROCK" },
  "72416": { state: "AR", city: "BONO" },
  "72417": { state: "AR", city: "BROOKLAND" },
  "72419": { state: "AR", city: "CARAWAY" },
  "72421": { state: "AR", city: "CASH" },
  "72422": { state: "AR", city: "CORNING" },
  "72424": { state: "AR", city: "DATTO" },
  "72425": { state: "AR", city: "DELAPLAINE" },
  "72426": { state: "AR", city: "DELL" },
  "72427": { state: "AR", city: "EGYPT" },
  "72428": { state: "AR", city: "ETOWAH" },
  "72429": { state: "AR", city: "FISHER" },
  "72430": { state: "AR", city: "GREENWAY" },
  "72431": { state: "AR", city: "GRUBBS" },
  "72432": { state: "AR", city: "HARRISBURG" },
  "72433": { state: "AR", city: "HOXIE" },
  "72434": { state: "AR", city: "IMBODEN" },
  "72435": { state: "AR", city: "KNOBEL" },
  "72436": { state: "AR", city: "LAFE" },
  "72437": { state: "AR", city: "LAKE CITY" },
  "72438": { state: "AR", city: "LEACHVILLE" },
  "72440": { state: "AR", city: "LYNN" },
  "72441": { state: "AR", city: "MC DOUGAL" },
  "72442": { state: "AR", city: "MANILA" },
  "72443": { state: "AR", city: "MARMADUKE" },
  "72444": { state: "AR", city: "MAYNARD" },
  "72445": { state: "AR", city: "MINTURN" },
  "72447": { state: "AR", city: "MONETTE" },
  "72449": { state: "AR", city: "O KEAN" },
  "72450": { state: "AR", city: "PARAGOULD" },
  "72451": { state: "AR", city: "PARAGOULD" },
  "72453": { state: "AR", city: "PEACH ORCHARD" },
  "72454": { state: "AR", city: "PIGGOTT" },
  "72455": { state: "AR", city: "POCAHONTAS" },
  "72456": { state: "AR", city: "POLLARD" },
  "72457": { state: "AR", city: "PORTIA" },
  "72458": { state: "AR", city: "POWHATAN" },
  "72459": { state: "AR", city: "RAVENDEN" },
  "72460": { state: "AR", city: "RAVENDEN SPRINGS" },
  "72461": { state: "AR", city: "RECTOR" },
  "72462": { state: "AR", city: "REYNO" },
  "72464": { state: "AR", city: "SAINT FRANCIS" },
  "72465": { state: "AR", city: "SEDGWICK" },
  "72466": { state: "AR", city: "SMITHVILLE" },
  "72467": { state: "AR", city: "STATE UNIVERSITY" },
  "72469": { state: "AR", city: "STRAWBERRY" },
  "72470": { state: "AR", city: "SUCCESS" },
  "72471": { state: "AR", city: "SWIFTON" },
  "72472": { state: "AR", city: "TRUMANN" },
  "72473": { state: "AR", city: "TUCKERMAN" },
  "72474": { state: "AR", city: "WALCOTT" },
  "72475": { state: "AR", city: "WALDENBURG" },
  "72476": { state: "AR", city: "WALNUT RIDGE" },
  "72478": { state: "AR", city: "WARM SPRINGS" },
  "72479": { state: "AR", city: "WEINER" },
  "72482": { state: "AR", city: "WILLIFORD" },
  "72501": { state: "AR", city: "BATESVILLE" },
  "72503": { state: "AR", city: "BATESVILLE" },
  "72512": { state: "AR", city: "HORSESHOE BEND" },
  "72513": { state: "AR", city: "ASH FLAT" },
  "72515": { state: "AR", city: "BEXAR" },
  "72517": { state: "AR", city: "BROCKWELL" },
  "72519": { state: "AR", city: "CALICO ROCK" },
  "72520": { state: "AR", city: "CAMP" },
  "72521": { state: "AR", city: "CAVE CITY" },
  "72522": { state: "AR", city: "CHARLOTTE" },
  "72523": { state: "AR", city: "CONCORD" },
  "72524": { state: "AR", city: "CORD" },
  "72525": { state: "AR", city: "CHEROKEE VILLAGE" },
  "72526": { state: "AR", city: "CUSHMAN" },
  "72527": { state: "AR", city: "DESHA" },
  "72528": { state: "AR", city: "DOLPH" },
  "72529": { state: "AR", city: "CHEROKEE VILLAGE" },
  "72530": { state: "AR", city: "DRASCO" },
  "72531": { state: "AR", city: "ELIZABETH" },
  "72532": { state: "AR", city: "EVENING SHADE" },
  "72533": { state: "AR", city: "FIFTY SIX" },
  "72534": { state: "AR", city: "FLORAL" },
  "72536": { state: "AR", city: "FRANKLIN" },
  "72537": { state: "AR", city: "GAMALIEL" },
  "72538": { state: "AR", city: "GEPP" },
  "72539": { state: "AR", city: "GLENCOE" },
  "72540": { state: "AR", city: "GUION" },
  "72542": { state: "AR", city: "HARDY" },
  "72543": { state: "AR", city: "HEBER SPRINGS" },
  "72544": { state: "AR", city: "HENDERSON" },
  "72545": { state: "AR", city: "HEBER SPRINGS" },
  "72546": { state: "AR", city: "IDA" },
  "72550": { state: "AR", city: "LOCUST GROVE" },
  "72553": { state: "AR", city: "MAGNESS" },
  "72554": { state: "AR", city: "MAMMOTH SPRING" },
  "72555": { state: "AR", city: "MARCELLA" },
  "72556": { state: "AR", city: "MELBOURNE" },
  "72560": { state: "AR", city: "MOUNTAIN VIEW" },
  "72561": { state: "AR", city: "MOUNT PLEASANT" },
  "72562": { state: "AR", city: "NEWARK" },
  "72564": { state: "AR", city: "OIL TROUGH" },
  "72565": { state: "AR", city: "OXFORD" },
  "72566": { state: "AR", city: "PINEVILLE" },
  "72567": { state: "AR", city: "PLEASANT GROVE" },
  "72568": { state: "AR", city: "PLEASANT PLAINS" },
  "72569": { state: "AR", city: "POUGHKEEPSIE" },
  "72571": { state: "AR", city: "ROSIE" },
  "72572": { state: "AR", city: "SAFFELL" },
  "72573": { state: "AR", city: "SAGE" },
  "72575": { state: "AR", city: "SALADO" },
  "72576": { state: "AR", city: "SALEM" },
  "72577": { state: "AR", city: "SIDNEY" },
  "72578": { state: "AR", city: "STURKIE" },
  "72579": { state: "AR", city: "SULPHUR ROCK" },
  "72581": { state: "AR", city: "TUMBLING SHOALS" },
  "72583": { state: "AR", city: "VIOLA" },
  "72584": { state: "AR", city: "VIOLET HILL" },
  "72585": { state: "AR", city: "WIDEMAN" },
  "72587": { state: "AR", city: "WISEMAN" },
  "72601": { state: "AR", city: "HARRISON" },
  "72602": { state: "AR", city: "HARRISON" },
  "72611": { state: "AR", city: "ALPENA" },
  "72613": { state: "AR", city: "BEAVER" },
  "72615": { state: "AR", city: "BERGMAN" },
  "72616": { state: "AR", city: "BERRYVILLE" },
  "72617": { state: "AR", city: "BIG FLAT" },
  "72619": { state: "AR", city: "BULL SHOALS" },
  "72623": { state: "AR", city: "CLARKRIDGE" },
  "72624": { state: "AR", city: "COMPTON" },
  "72626": { state: "AR", city: "COTTER" },
  "72628": { state: "AR", city: "DEER" },
  "72629": { state: "AR", city: "DENNARD" },
  "72630": { state: "AR", city: "DIAMOND CITY" },
  "72631": { state: "AR", city: "EUREKA SPRINGS" },
  "72632": { state: "AR", city: "EUREKA SPRINGS" },
  "72633": { state: "AR", city: "EVERTON" },
  "72634": { state: "AR", city: "FLIPPIN" },
  "72635": { state: "AR", city: "GASSVILLE" },
  "72636": { state: "AR", city: "GILBERT" },
  "72638": { state: "AR", city: "GREEN FOREST" },
  "72639": { state: "AR", city: "HARRIET" },
  "72640": { state: "AR", city: "HASTY" },
  "72641": { state: "AR", city: "JASPER" },
  "72642": { state: "AR", city: "LAKEVIEW" },
  "72643": { state: "MO", city: "LEAD HILL" },
  "72644": { state: "AR", city: "LEAD HILL" },
  "72645": { state: "AR", city: "LESLIE" },
  "72648": { state: "AR", city: "MARBLE FALLS" },
  "72650": { state: "AR", city: "MARSHALL" },
  "72651": { state: "AR", city: "MIDWAY" },
  "72653": { state: "AR", city: "MOUNTAIN HOME" },
  "72654": { state: "AR", city: "MOUNTAIN HOME" },
  "72655": { state: "AR", city: "MOUNT JUDEA" },
  "72657": { state: "AR", city: "TIMBO" },
  "72658": { state: "AR", city: "NORFORK" },
  "72659": { state: "AR", city: "NORFORK" },
  "72660": { state: "AR", city: "OAK GROVE" },
  "72661": { state: "AR", city: "OAKLAND" },
  "72662": { state: "AR", city: "OMAHA" },
  "72663": { state: "AR", city: "ONIA" },
  "72666": { state: "AR", city: "PARTHENON" },
  "72668": { state: "AR", city: "PEEL" },
  "72669": { state: "AR", city: "PINDALL" },
  "72670": { state: "AR", city: "PONCA" },
  "72672": { state: "AR", city: "PYATT" },
  "72675": { state: "AR", city: "SAINT JOE" },
  "72677": { state: "AR", city: "SUMMIT" },
  "72679": { state: "AR", city: "TILLY" },
  "72680": { state: "AR", city: "TIMBO" },
  "72682": { state: "AR", city: "VALLEY SPRINGS" },
  "72683": { state: "AR", city: "VENDOR" },
  "72685": { state: "AR", city: "WESTERN GROVE" },
  "72686": { state: "AR", city: "WITTS SPRINGS" },
  "72687": { state: "AR", city: "YELLVILLE" },
  "72701": { state: "AR", city: "FAYETTEVILLE" },
  "72702": { state: "AR", city: "FAYETTEVILLE" },
  "72703": { state: "AR", city: "FAYETTEVILLE" },
  "72704": { state: "AR", city: "FAYETTEVILLE" },
  "72711": { state: "AR", city: "AVOCA" },
  "72712": { state: "AR", city: "BENTONVILLE" },
  "72713": { state: "AR", city: "BENTONVILLE" },
  "72714": { state: "AR", city: "BELLA VISTA" },
  "72715": { state: "AR", city: "BELLA VISTA" },
  "72716": { state: "AR", city: "BENTONVILLE" },
  "72717": { state: "AR", city: "CANEHILL" },
  "72718": { state: "AR", city: "CAVE SPRINGS" },
  "72719": { state: "AR", city: "CENTERTON" },
  "72721": { state: "AR", city: "COMBS" },
  "72722": { state: "AR", city: "DECATUR" },
  "72727": { state: "AR", city: "ELKINS" },
  "72728": { state: "AR", city: "ELM SPRINGS" },
  "72729": { state: "AR", city: "EVANSVILLE" },
  "72730": { state: "AR", city: "FARMINGTON" },
  "72732": { state: "AR", city: "GARFIELD" },
  "72733": { state: "AR", city: "GATEWAY" },
  "72734": { state: "AR", city: "GENTRY" },
  "72735": { state: "AR", city: "GOSHEN" },
  "72736": { state: "AR", city: "GRAVETTE" },
  "72737": { state: "AR", city: "GREENLAND" },
  "72738": { state: "AR", city: "HINDSVILLE" },
  "72739": { state: "AR", city: "HIWASSE" },
  "72740": { state: "AR", city: "HUNTSVILLE" },
  "72741": { state: "AR", city: "JOHNSON" },
  "72742": { state: "AR", city: "KINGSTON" },
  "72744": { state: "AR", city: "LINCOLN" },
  "72745": { state: "AR", city: "LOWELL" },
  "72747": { state: "AR", city: "MAYSVILLE" },
  "72749": { state: "AR", city: "MORROW" },
  "72751": { state: "AR", city: "PEA RIDGE" },
  "72752": { state: "AR", city: "PETTIGREW" },
  "72753": { state: "AR", city: "PRAIRIE GROVE" },
  "72756": { state: "AR", city: "ROGERS" },
  "72757": { state: "AR", city: "ROGERS" },
  "72758": { state: "AR", city: "ROGERS" },
  "72760": { state: "AR", city: "SAINT PAUL" },
  "72761": { state: "AR", city: "SILOAM SPRINGS" },
  "72762": { state: "AR", city: "SPRINGDALE" },
  "72764": { state: "AR", city: "SPRINGDALE" },
  "72765": { state: "AR", city: "SPRINGDALE" },
  "72766": { state: "AR", city: "SPRINGDALE" },
  "72768": { state: "AR", city: "SULPHUR SPRINGS" },
  "72769": { state: "AR", city: "SUMMERS" },
  "72770": { state: "AR", city: "TONTITOWN" },
  "72773": { state: "AR", city: "WESLEY" },
  "72774": { state: "AR", city: "WEST FORK" },
  "72776": { state: "AR", city: "WITTER" },
  "72801": { state: "AR", city: "RUSSELLVILLE" },
  "72802": { state: "AR", city: "RUSSELLVILLE" },
  "72811": { state: "AR", city: "RUSSELLVILLE" },
  "72812": { state: "AR", city: "RUSSELLVILLE" },
  "72820": { state: "AR", city: "ALIX" },
  "72821": { state: "AR", city: "ALTUS" },
  "72823": { state: "AR", city: "ATKINS" },
  "72824": { state: "AR", city: "BELLEVILLE" },
  "72826": { state: "AR", city: "BLUE MOUNTAIN" },
  "72827": { state: "AR", city: "BLUFFTON" },
  "72828": { state: "AR", city: "BRIGGSVILLE" },
  "72829": { state: "AR", city: "CENTERVILLE" },
  "72830": { state: "AR", city: "CLARKSVILLE" },
  "72832": { state: "AR", city: "COAL HILL" },
  "72833": { state: "AR", city: "DANVILLE" },
  "72834": { state: "AR", city: "DARDANELLE" },
  "72835": { state: "AR", city: "DELAWARE" },
  "72837": { state: "AR", city: "DOVER" },
  "72838": { state: "AR", city: "GRAVELLY" },
  "72839": { state: "AR", city: "HAGARVILLE" },
  "72840": { state: "AR", city: "HARTMAN" },
  "72841": { state: "AR", city: "HARVEY" },
  "72842": { state: "AR", city: "HAVANA" },
  "72843": { state: "AR", city: "HECTOR" },
  "72845": { state: "AR", city: "KNOXVILLE" },
  "72846": { state: "AR", city: "LAMAR" },
  "72847": { state: "AR", city: "LONDON" },
  "72851": { state: "AR", city: "NEW BLAINE" },
  "72852": { state: "AR", city: "OARK" },
  "72853": { state: "AR", city: "OLA" },
  "72854": { state: "AR", city: "OZONE" },
  "72855": { state: "AR", city: "PARIS" },
  "72856": { state: "AR", city: "PELSOR" },
  "72857": { state: "AR", city: "PLAINVIEW" },
  "72858": { state: "AR", city: "POTTSVILLE" },
  "72860": { state: "AR", city: "ROVER" },
  "72863": { state: "AR", city: "SCRANTON" },
  "72865": { state: "AR", city: "SUBIACO" },
  "72901": { state: "AR", city: "FORT SMITH" },
  "72902": { state: "AR", city: "FORT SMITH" },
  "72903": { state: "AR", city: "FORT SMITH" },
  "72904": { state: "AR", city: "FORT SMITH" },
  "72905": { state: "AR", city: "FORT SMITH" },
  "72906": { state: "AR", city: "FORT SMITH" },
  "72908": { state: "AR", city: "FORT SMITH" },
  "72913": { state: "AR", city: "FORT SMITH" },
  "72914": { state: "AR", city: "FORT SMITH" },
  "72916": { state: "AR", city: "FORT SMITH" },
  "72917": { state: "AR", city: "FORT SMITH" },
  "72918": { state: "AR", city: "FORT SMITH" },
  "72919": { state: "AR", city: "FORT SMITH" },
  "72921": { state: "AR", city: "ALMA" },
  "72923": { state: "AR", city: "BARLING" },
  "72926": { state: "AR", city: "BOLES" },
  "72927": { state: "AR", city: "BOONEVILLE" },
  "72928": { state: "AR", city: "BRANCH" },
  "72930": { state: "AR", city: "CECIL" },
  "72932": { state: "AR", city: "CEDARVILLE" },
  "72933": { state: "AR", city: "CHARLESTON" },
  "72934": { state: "AR", city: "CHESTER" },
  "72935": { state: "AR", city: "DYER" },
  "72936": { state: "AR", city: "GREENWOOD" },
  "72937": { state: "AR", city: "HACKETT" },
  "72938": { state: "AR", city: "HARTFORD" },
  "72940": { state: "AR", city: "HUNTINGTON" },
  "72941": { state: "AR", city: "LAVACA" },
  "72943": { state: "AR", city: "MAGAZINE" },
  "72944": { state: "AR", city: "MANSFIELD" },
  "72945": { state: "AR", city: "MIDLAND" },
  "72946": { state: "AR", city: "MOUNTAINBURG" },
  "72947": { state: "AR", city: "MULBERRY" },
  "72948": { state: "AR", city: "NATURAL DAM" },
  "72949": { state: "AR", city: "OZARK" },
  "72950": { state: "AR", city: "PARKS" },
  "72951": { state: "AR", city: "RATCLIFF" },
  "72952": { state: "AR", city: "RUDY" },
  "72955": { state: "AR", city: "UNIONTOWN" },
  "72956": { state: "AR", city: "VAN BUREN" },
  "72957": { state: "AR", city: "VAN BUREN" },
  "72958": { state: "AR", city: "WALDRON" },
  "72959": { state: "AR", city: "WINSLOW" },
  "73001": { state: "OK", city: "ALBERT" },
  "73002": { state: "OK", city: "ALEX" },
  "73003": { state: "OK", city: "EDMOND" },
  "73004": { state: "OK", city: "AMBER" },
  "73005": { state: "OK", city: "ANADARKO" },
  "73006": { state: "OK", city: "APACHE" },
  "73007": { state: "OK", city: "ARCADIA" },
  "73008": { state: "OK", city: "BETHANY" },
  "73009": { state: "OK", city: "BINGER" },
  "73010": { state: "OK", city: "BLANCHARD" },
  "73011": { state: "OK", city: "BRADLEY" },
  "73012": { state: "OK", city: "EDMOND" },
  "73013": { state: "OK", city: "EDMOND" },
  "73014": { state: "OK", city: "CALUMET" },
  "73015": { state: "OK", city: "CARNEGIE" },
  "73016": { state: "OK", city: "CASHION" },
  "73017": { state: "OK", city: "CEMENT" },
  "73018": { state: "OK", city: "CHICKASHA" },
  "73019": { state: "OK", city: "NORMAN" },
  "73020": { state: "OK", city: "CHOCTAW" },
  "73021": { state: "OK", city: "COLONY" },
  "73022": { state: "OK", city: "CONCHO" },
  "73023": { state: "OK", city: "CHICKASHA" },
  "73024": { state: "OK", city: "CORN" },
  "73025": { state: "OK", city: "EDMOND" },
  "73026": { state: "OK", city: "NORMAN" },
  "73027": { state: "OK", city: "COYLE" },
  "73028": { state: "OK", city: "CRESCENT" },
  "73029": { state: "OK", city: "CYRIL" },
  "73030": { state: "OK", city: "DAVIS" },
  "73031": { state: "OK", city: "DIBBLE" },
  "73032": { state: "OK", city: "DOUGHERTY" },
  "73033": { state: "OK", city: "EAKLY" },
  "73034": { state: "OK", city: "EDMOND" },
  "73036": { state: "OK", city: "EL RENO" },
  "73038": { state: "OK", city: "FORT COBB" },
  "73039": { state: "OK", city: "DAVIS" },
  "73040": { state: "OK", city: "GEARY" },
  "73041": { state: "OK", city: "GOTEBO" },
  "73042": { state: "OK", city: "GRACEMONT" },
  "73043": { state: "OK", city: "GREENFIELD" },
  "73044": { state: "OK", city: "GUTHRIE" },
  "73045": { state: "OK", city: "HARRAH" },
  "73047": { state: "OK", city: "HINTON" },
  "73048": { state: "OK", city: "HYDRO" },
  "73049": { state: "OK", city: "JONES" },
  "73050": { state: "OK", city: "LANGSTON" },
  "73051": { state: "OK", city: "LEXINGTON" },
  "73052": { state: "OK", city: "LINDSAY" },
  "73053": { state: "OK", city: "LOOKEBA" },
  "73054": { state: "OK", city: "LUTHER" },
  "73055": { state: "OK", city: "MARLOW" },
  "73056": { state: "OK", city: "MARSHALL" },
  "73057": { state: "OK", city: "MAYSVILLE" },
  "73058": { state: "OK", city: "MERIDIAN" },
  "73059": { state: "OK", city: "MINCO" },
  "73061": { state: "OK", city: "MORRISON" },
  "73062": { state: "OK", city: "MOUNTAIN VIEW" },
  "73063": { state: "OK", city: "MULHALL" },
  "73064": { state: "OK", city: "MUSTANG" },
  "73065": { state: "OK", city: "NEWCASTLE" },
  "73066": { state: "OK", city: "NICOMA PARK" },
  "73067": { state: "OK", city: "NINNEKAH" },
  "73068": { state: "OK", city: "NOBLE" },
  "73069": { state: "OK", city: "NORMAN" },
  "73070": { state: "OK", city: "NORMAN" },
  "73071": { state: "OK", city: "NORMAN" },
  "73072": { state: "OK", city: "NORMAN" },
  "73073": { state: "OK", city: "ORLANDO" },
  "73074": { state: "OK", city: "PAOLI" },
  "73075": { state: "OK", city: "PAULS VALLEY" },
  "73077": { state: "OK", city: "PERRY" },
  "73078": { state: "OK", city: "PIEDMONT" },
  "73079": { state: "OK", city: "POCASSET" },
  "73080": { state: "OK", city: "PURCELL" },
  "73082": { state: "OK", city: "RUSH SPRINGS" },
  "73083": { state: "OK", city: "EDMOND" },
  "73084": { state: "OK", city: "SPENCER" },
  "73085": { state: "OK", city: "YUKON" },
  "73086": { state: "OK", city: "SULPHUR" },
  "73089": { state: "OK", city: "TUTTLE" },
  "73090": { state: "OK", city: "UNION CITY" },
  "73092": { state: "OK", city: "VERDEN" },
  "73093": { state: "OK", city: "WASHINGTON" },
  "73095": { state: "OK", city: "WAYNE" },
  "73096": { state: "OK", city: "WEATHERFORD" },
  "73097": { state: "OK", city: "WHEATLAND" },
  "73098": { state: "OK", city: "WYNNEWOOD" },
  "73099": { state: "OK", city: "YUKON" },
  "73101": { state: "OK", city: "OKLAHOMA CITY" },
  "73102": { state: "OK", city: "OKLAHOMA CITY" },
  "73103": { state: "OK", city: "OKLAHOMA CITY" },
  "73104": { state: "OK", city: "OKLAHOMA CITY" },
  "73105": { state: "OK", city: "OKLAHOMA CITY" },
  "73106": { state: "OK", city: "OKLAHOMA CITY" },
  "73107": { state: "OK", city: "OKLAHOMA CITY" },
  "73108": { state: "OK", city: "OKLAHOMA CITY" },
  "73109": { state: "OK", city: "OKLAHOMA CITY" },
  "73110": { state: "OK", city: "OKLAHOMA CITY" },
  "73111": { state: "OK", city: "OKLAHOMA CITY" },
  "73112": { state: "OK", city: "OKLAHOMA CITY" },
  "73113": { state: "OK", city: "OKLAHOMA CITY" },
  "73114": { state: "OK", city: "OKLAHOMA CITY" },
  "73115": { state: "OK", city: "OKLAHOMA CITY" },
  "73116": { state: "OK", city: "OKLAHOMA CITY" },
  "73117": { state: "OK", city: "OKLAHOMA CITY" },
  "73118": { state: "OK", city: "OKLAHOMA CITY" },
  "73119": { state: "OK", city: "OKLAHOMA CITY" },
  "73120": { state: "OK", city: "OKLAHOMA CITY" },
  "73121": { state: "OK", city: "OKLAHOMA CITY" },
  "73122": { state: "OK", city: "OKLAHOMA CITY" },
  "73123": { state: "OK", city: "OKLAHOMA CITY" },
  "73124": { state: "OK", city: "OKLAHOMA CITY" },
  "73125": { state: "OK", city: "OKLAHOMA CITY" },
  "73126": { state: "OK", city: "OKLAHOMA CITY" },
  "73127": { state: "OK", city: "OKLAHOMA CITY" },
  "73128": { state: "OK", city: "OKLAHOMA CITY" },
  "73129": { state: "OK", city: "OKLAHOMA CITY" },
  "73130": { state: "OK", city: "OKLAHOMA CITY" },
  "73131": { state: "OK", city: "OKLAHOMA CITY" },
  "73132": { state: "OK", city: "OKLAHOMA CITY" },
  "73134": { state: "OK", city: "OKLAHOMA CITY" },
  "73135": { state: "OK", city: "OKLAHOMA CITY" },
  "73136": { state: "OK", city: "OKLAHOMA CITY" },
  "73137": { state: "OK", city: "OKLAHOMA CITY" },
  "73139": { state: "OK", city: "OKLAHOMA CITY" },
  "73140": { state: "OK", city: "OKLAHOMA CITY" },
  "73141": { state: "OK", city: "OKLAHOMA CITY" },
  "73142": { state: "OK", city: "OKLAHOMA CITY" },
  "73143": { state: "OK", city: "OKLAHOMA CITY" },
  "73144": { state: "OK", city: "OKLAHOMA CITY" },
  "73145": { state: "OK", city: "OKLAHOMA CITY" },
  "73146": { state: "OK", city: "OKLAHOMA CITY" },
  "73147": { state: "OK", city: "OKLAHOMA CITY" },
  "73148": { state: "OK", city: "OKLAHOMA CITY" },
  "73149": { state: "OK", city: "OKLAHOMA CITY" },
  "73150": { state: "OK", city: "OKLAHOMA CITY" },
  "73151": { state: "OK", city: "OKLAHOMA CITY" },
  "73152": { state: "OK", city: "OKLAHOMA CITY" },
  "73153": { state: "OK", city: "OKLAHOMA CITY" },
  "73154": { state: "OK", city: "OKLAHOMA CITY" },
  "73155": { state: "OK", city: "OKLAHOMA CITY" },
  "73156": { state: "OK", city: "OKLAHOMA CITY" },
  "73157": { state: "OK", city: "OKLAHOMA CITY" },
  "73159": { state: "OK", city: "OKLAHOMA CITY" },
  "73160": { state: "OK", city: "OKLAHOMA CITY" },
  "73162": { state: "OK", city: "OKLAHOMA CITY" },
  "73163": { state: "OK", city: "OKLAHOMA CITY" },
  "73164": { state: "OK", city: "OKLAHOMA CITY" },
  "73165": { state: "OK", city: "OKLAHOMA CITY" },
  "73167": { state: "OK", city: "OKLAHOMA CITY" },
  "73169": { state: "OK", city: "OKLAHOMA CITY" },
  "73170": { state: "OK", city: "OKLAHOMA CITY" },
  "73172": { state: "OK", city: "OKLAHOMA CITY" },
  "73173": { state: "OK", city: "OKLAHOMA CITY" },
  "73178": { state: "OK", city: "OKLAHOMA CITY" },
  "73179": { state: "OK", city: "OKLAHOMA CITY" },
  "73184": { state: "OK", city: "OKLAHOMA CITY" },
  "73189": { state: "OK", city: "OKLAHOMA CITY" },
  "73190": { state: "OK", city: "OKLAHOMA CITY" },
  "73194": { state: "OK", city: "OKLAHOMA CITY" },
  "73195": { state: "OK", city: "OKLAHOMA CITY" },
  "73196": { state: "OK", city: "OKLAHOMA CITY" },
  "73301": { state: "TX", city: "AUSTIN" },
  "73344": { state: "TX", city: "AUSTIN" },
  "73401": { state: "OK", city: "ARDMORE" },
  "73402": { state: "OK", city: "ARDMORE" },
  "73403": { state: "OK", city: "ARDMORE" },
  "73425": { state: "OK", city: "COUNTYLINE" },
  "73430": { state: "OK", city: "BURNEYVILLE" },
  "73432": { state: "OK", city: "COLEMAN" },
  "73433": { state: "OK", city: "ELMORE CITY" },
  "73434": { state: "OK", city: "FOSTER" },
  "73435": { state: "OK", city: "FOX" },
  "73436": { state: "OK", city: "GENE AUTRY" },
  "73437": { state: "OK", city: "GRAHAM" },
  "73438": { state: "OK", city: "HEALDTON" },
  "73439": { state: "OK", city: "KINGSTON" },
  "73440": { state: "OK", city: "LEBANON" },
  "73441": { state: "OK", city: "LEON" },
  "73442": { state: "OK", city: "LOCO" },
  "73443": { state: "OK", city: "LONE GROVE" },
  "73444": { state: "OK", city: "HENNEPIN" },
  "73446": { state: "OK", city: "MADILL" },
  "73447": { state: "OK", city: "MANNSVILLE" },
  "73448": { state: "OK", city: "MARIETTA" },
  "73449": { state: "OK", city: "MEAD" },
  "73450": { state: "OK", city: "MILBURN" },
  "73453": { state: "OK", city: "OVERBROOK" },
  "73455": { state: "OK", city: "RAVIA" },
  "73456": { state: "OK", city: "RINGLING" },
  "73458": { state: "OK", city: "SPRINGER" },
  "73459": { state: "OK", city: "THACKERVILLE" },
  "73460": { state: "OK", city: "TISHOMINGO" },
  "73461": { state: "OK", city: "WAPANUCKA" },
  "73463": { state: "OK", city: "WILSON" },
  "73481": { state: "OK", city: "RATLIFF CITY" },
  "73487": { state: "OK", city: "TATUMS" },
  "73488": { state: "OK", city: "TUSSY" },
  "73491": { state: "OK", city: "VELMA" },
  "73501": { state: "OK", city: "LAWTON" },
  "73502": { state: "OK", city: "LAWTON" },
  "73503": { state: "OK", city: "FORT SILL" },
  "73505": { state: "OK", city: "LAWTON" },
  "73506": { state: "OK", city: "LAWTON" },
  "73507": { state: "OK", city: "LAWTON" },
  "73520": { state: "OK", city: "ADDINGTON" },
  "73521": { state: "OK", city: "ALTUS" },
  "73522": { state: "OK", city: "ALTUS" },
  "73523": { state: "OK", city: "ALTUS AFB" },
  "73526": { state: "OK", city: "BLAIR" },
  "73527": { state: "OK", city: "CACHE" },
  "73528": { state: "OK", city: "CHATTANOOGA" },
  "73529": { state: "OK", city: "COMANCHE" },
  "73530": { state: "OK", city: "DAVIDSON" },
  "73531": { state: "OK", city: "DEVOL" },
  "73532": { state: "OK", city: "DUKE" },
  "73533": { state: "OK", city: "DUNCAN" },
  "73534": { state: "OK", city: "DUNCAN" },
  "73536": { state: "OK", city: "DUNCAN" },
  "73537": { state: "OK", city: "ELDORADO" },
  "73538": { state: "OK", city: "ELGIN" },
  "73539": { state: "OK", city: "ELMER" },
  "73540": { state: "OK", city: "FAXON" },
  "73541": { state: "OK", city: "FLETCHER" },
  "73542": { state: "OK", city: "FREDERICK" },
  "73543": { state: "OK", city: "GERONIMO" },
  "73544": { state: "OK", city: "GOULD" },
  "73546": { state: "OK", city: "GRANDFIELD" },
  "73547": { state: "OK", city: "GRANITE" },
  "73548": { state: "OK", city: "HASTINGS" },
  "73549": { state: "OK", city: "HEADRICK" },
  "73550": { state: "OK", city: "HOLLIS" },
  "73551": { state: "OK", city: "HOLLISTER" },
  "73552": { state: "OK", city: "INDIAHOMA" },
  "73553": { state: "OK", city: "LOVELAND" },
  "73554": { state: "OK", city: "MANGUM" },
  "73555": { state: "OK", city: "MANITOU" },
  "73556": { state: "OK", city: "MARTHA" },
  "73557": { state: "OK", city: "MEDICINE PARK" },
  "73558": { state: "OK", city: "MEERS" },
  "73559": { state: "OK", city: "MOUNTAIN PARK" },
  "73560": { state: "OK", city: "OLUSTEE" },
  "73561": { state: "OK", city: "OSCAR" },
  "73562": { state: "OK", city: "RANDLETT" },
  "73564": { state: "OK", city: "ROOSEVELT" },
  "73565": { state: "OK", city: "RYAN" },
  "73566": { state: "OK", city: "SNYDER" },
  "73567": { state: "OK", city: "STERLING" },
  "73568": { state: "OK", city: "TEMPLE" },
  "73569": { state: "OK", city: "TERRAL" },
  "73570": { state: "OK", city: "TIPTON" },
  "73571": { state: "OK", city: "VINSON" },
  "73572": { state: "OK", city: "WALTERS" },
  "73573": { state: "OK", city: "WAURIKA" },
  "73601": { state: "OK", city: "CLINTON" },
  "73620": { state: "OK", city: "ARAPAHO" },
  "73622": { state: "OK", city: "BESSIE" },
  "73624": { state: "OK", city: "BURNS FLAT" },
  "73625": { state: "OK", city: "BUTLER" },
  "73626": { state: "OK", city: "CANUTE" },
  "73627": { state: "OK", city: "CARTER" },
  "73628": { state: "OK", city: "CHEYENNE" },
  "73632": { state: "OK", city: "CORDELL" },
  "73638": { state: "OK", city: "CRAWFORD" },
  "73639": { state: "OK", city: "CUSTER CITY" },
  "73641": { state: "OK", city: "DILL CITY" },
  "73642": { state: "OK", city: "DURHAM" },
  "73644": { state: "OK", city: "ELK CITY" },
  "73645": { state: "OK", city: "ERICK" },
  "73646": { state: "OK", city: "FAY" },
  "73647": { state: "OK", city: "FOSS" },
  "73648": { state: "OK", city: "ELK CITY" },
  "73650": { state: "OK", city: "HAMMON" },
  "73651": { state: "OK", city: "HOBART" },
  "73654": { state: "OK", city: "LEEDEY" },
  "73655": { state: "OK", city: "LONE WOLF" },
  "73658": { state: "OK", city: "OAKWOOD" },
  "73659": { state: "OK", city: "PUTNAM" },
  "73660": { state: "OK", city: "REYDON" },
  "73661": { state: "OK", city: "ROCKY" },
  "73662": { state: "OK", city: "SAYRE" },
  "73663": { state: "OK", city: "SEILING" },
  "73664": { state: "OK", city: "SENTINEL" },
  "73666": { state: "OK", city: "SWEETWATER" },
  "73667": { state: "OK", city: "TALOGA" },
  "73668": { state: "OK", city: "TEXOLA" },
  "73669": { state: "OK", city: "THOMAS" },
  "73673": { state: "OK", city: "WILLOW" },
  "73701": { state: "OK", city: "ENID" },
  "73702": { state: "OK", city: "ENID" },
  "73703": { state: "OK", city: "ENID" },
  "73705": { state: "OK", city: "ENID" },
  "73706": { state: "OK", city: "ENID" },
  "73716": { state: "OK", city: "ALINE" },
  "73717": { state: "OK", city: "ALVA" },
  "73718": { state: "OK", city: "AMES" },
  "73719": { state: "OK", city: "AMORITA" },
  "73720": { state: "OK", city: "BISON" },
  "73722": { state: "OK", city: "BURLINGTON" },
  "73724": { state: "OK", city: "CANTON" },
  "73726": { state: "OK", city: "CARMEN" },
  "73727": { state: "OK", city: "CARRIER" },
  "73728": { state: "OK", city: "CHEROKEE" },
  "73729": { state: "OK", city: "CLEO SPRINGS" },
  "73730": { state: "OK", city: "COVINGTON" },
  "73731": { state: "OK", city: "DACOMA" },
  "73733": { state: "OK", city: "DOUGLAS" },
  "73734": { state: "OK", city: "DOVER" },
  "73735": { state: "OK", city: "DRUMMOND" },
  "73736": { state: "OK", city: "FAIRMONT" },
  "73737": { state: "OK", city: "FAIRVIEW" },
  "73738": { state: "OK", city: "GARBER" },
  "73739": { state: "OK", city: "GOLTRY" },
  "73741": { state: "OK", city: "HELENA" },
  "73742": { state: "OK", city: "HENNESSEY" },
  "73743": { state: "OK", city: "HILLSDALE" },
  "73744": { state: "OK", city: "HITCHCOCK" },
  "73746": { state: "OK", city: "HOPETON" },
  "73747": { state: "OK", city: "ISABELLA" },
  "73749": { state: "OK", city: "JET" },
  "73750": { state: "OK", city: "KINGFISHER" },
  "73753": { state: "OK", city: "KREMLIN" },
  "73754": { state: "OK", city: "LAHOMA" },
  "73755": { state: "OK", city: "LONGDALE" },
  "73756": { state: "OK", city: "LOYAL" },
  "73757": { state: "OK", city: "LUCIEN" },
  "73758": { state: "OK", city: "MANCHESTER" },
  "73759": { state: "OK", city: "MEDFORD" },
  "73760": { state: "OK", city: "MENO" },
  "73761": { state: "OK", city: "NASH" },
  "73762": { state: "OK", city: "OKARCHE" },
  "73763": { state: "OK", city: "OKEENE" },
  "73764": { state: "OK", city: "OMEGA" },
  "73766": { state: "OK", city: "POND CREEK" },
  "73768": { state: "OK", city: "RINGWOOD" },
  "73770": { state: "OK", city: "SOUTHARD" },
  "73771": { state: "OK", city: "WAKITA" },
  "73772": { state: "OK", city: "WATONGA" },
  "73773": { state: "OK", city: "WAUKOMIS" },
  "73801": { state: "OK", city: "WOODWARD" },
  "73802": { state: "OK", city: "WOODWARD" },
  "73832": { state: "OK", city: "ARNETT" },
  "73834": { state: "OK", city: "BUFFALO" },
  "73835": { state: "OK", city: "CAMARGO" },
  "73838": { state: "OK", city: "CHESTER" },
  "73840": { state: "OK", city: "FARGO" },
  "73841": { state: "OK", city: "FORT SUPPLY" },
  "73842": { state: "OK", city: "FREEDOM" },
  "73843": { state: "OK", city: "GAGE" },
  "73844": { state: "OK", city: "GATE" },
  "73848": { state: "OK", city: "LAVERNE" },
  "73851": { state: "OK", city: "MAY" },
  "73852": { state: "OK", city: "MOORELAND" },
  "73853": { state: "OK", city: "MUTUAL" },
  "73855": { state: "OK", city: "ROSSTON" },
  "73857": { state: "OK", city: "SHARON" },
  "73858": { state: "OK", city: "SHATTUCK" },
  "73859": { state: "OK", city: "VICI" },
  "73860": { state: "OK", city: "WAYNOKA" },
  "73901": { state: "OK", city: "ADAMS" },
  "73931": { state: "OK", city: "BALKO" },
  "73932": { state: "OK", city: "BEAVER" },
  "73933": { state: "OK", city: "BOISE CITY" },
  "73937": { state: "OK", city: "FELT" },
  "73938": { state: "OK", city: "FORGAN" },
  "73939": { state: "OK", city: "GOODWELL" },
  "73942": { state: "OK", city: "GUYMON" },
  "73944": { state: "OK", city: "HARDESTY" },
  "73945": { state: "OK", city: "HOOKER" },
  "73946": { state: "OK", city: "KENTON" },
  "73947": { state: "OK", city: "KEYES" },
  "73949": { state: "OK", city: "TEXHOMA" },
  "73950": { state: "OK", city: "TURPIN" },
  "73951": { state: "OK", city: "TYRONE" },
  "73960": { state: "TX", city: "TEXHOMA" },
  "74001": { state: "OK", city: "AVANT" },
  "74002": { state: "OK", city: "BARNSDALL" },
  "74003": { state: "OK", city: "BARTLESVILLE" },
  "74004": { state: "OK", city: "BARTLESVILLE" },
  "74005": { state: "OK", city: "BARTLESVILLE" },
  "74006": { state: "OK", city: "BARTLESVILLE" },
  "74008": { state: "OK", city: "BIXBY" },
  "74010": { state: "OK", city: "BRISTOW" },
  "74011": { state: "OK", city: "BROKEN ARROW" },
  "74012": { state: "OK", city: "BROKEN ARROW" },
  "74013": { state: "OK", city: "BROKEN ARROW" },
  "74014": { state: "OK", city: "BROKEN ARROW" },
  "74015": { state: "OK", city: "CATOOSA" },
  "74016": { state: "OK", city: "CHELSEA" },
  "74017": { state: "OK", city: "CLAREMORE" },
  "74018": { state: "OK", city: "CLAREMORE" },
  "74019": { state: "OK", city: "CLAREMORE" },
  "74020": { state: "OK", city: "CLEVELAND" },
  "74021": { state: "OK", city: "COLLINSVILLE" },
  "74022": { state: "OK", city: "COPAN" },
  "74023": { state: "OK", city: "CUSHING" },
  "74026": { state: "OK", city: "DAVENPORT" },
  "74027": { state: "OK", city: "DELAWARE" },
  "74028": { state: "OK", city: "DEPEW" },
  "74029": { state: "OK", city: "DEWEY" },
  "74030": { state: "OK", city: "DRUMRIGHT" },
  "74031": { state: "OK", city: "FOYIL" },
  "74032": { state: "OK", city: "GLENCOE" },
  "74033": { state: "OK", city: "GLENPOOL" },
  "74034": { state: "OK", city: "HALLETT" },
  "74035": { state: "OK", city: "HOMINY" },
  "74036": { state: "OK", city: "INOLA" },
  "74037": { state: "OK", city: "JENKS" },
  "74038": { state: "OK", city: "JENNINGS" },
  "74039": { state: "OK", city: "KELLYVILLE" },
  "74041": { state: "OK", city: "KIEFER" },
  "74042": { state: "OK", city: "LENAPAH" },
  "74043": { state: "OK", city: "LEONARD" },
  "74044": { state: "OK", city: "MANNFORD" },
  "74045": { state: "OK", city: "MARAMEC" },
  "74046": { state: "OK", city: "MILFAY" },
  "74047": { state: "OK", city: "MOUNDS" },
  "74048": { state: "OK", city: "NOWATA" },
  "74050": { state: "OK", city: "OAKHURST" },
  "74051": { state: "OK", city: "OCHELATA" },
  "74052": { state: "OK", city: "OILTON" },
  "74053": { state: "OK", city: "OOLOGAH" },
  "74054": { state: "OK", city: "OSAGE" },
  "74055": { state: "OK", city: "OWASSO" },
  "74056": { state: "OK", city: "PAWHUSKA" },
  "74058": { state: "OK", city: "PAWNEE" },
  "74059": { state: "OK", city: "PERKINS" },
  "74060": { state: "OK", city: "PRUE" },
  "74061": { state: "OK", city: "RAMONA" },
  "74062": { state: "OK", city: "RIPLEY" },
  "74063": { state: "OK", city: "SAND SPRINGS" },
  "74066": { state: "OK", city: "SAPULPA" },
  "74067": { state: "OK", city: "SAPULPA" },
  "74068": { state: "OK", city: "SHAMROCK" },
  "74070": { state: "OK", city: "SKIATOOK" },
  "74071": { state: "OK", city: "SLICK" },
  "74072": { state: "OK", city: "S COFFEYVILLE" },
  "74073": { state: "OK", city: "SPERRY" },
  "74074": { state: "OK", city: "STILLWATER" },
  "74075": { state: "OK", city: "STILLWATER" },
  "74076": { state: "OK", city: "STILLWATER" },
  "74077": { state: "OK", city: "STILLWATER" },
  "74078": { state: "OK", city: "STILLWATER" },
  "74079": { state: "OK", city: "STROUD" },
  "74080": { state: "OK", city: "TALALA" },
  "74081": { state: "OK", city: "TERLTON" },
  "74082": { state: "OK", city: "VERA" },
  "74083": { state: "OK", city: "WANN" },
  "74084": { state: "OK", city: "WYNONA" },
  "74085": { state: "OK", city: "YALE" },
  "74101": { state: "OK", city: "TULSA" },
  "74102": { state: "OK", city: "TULSA" },
  "74103": { state: "OK", city: "TULSA" },
  "74104": { state: "OK", city: "TULSA" },
  "74105": { state: "OK", city: "TULSA" },
  "74106": { state: "OK", city: "TULSA" },
  "74107": { state: "OK", city: "TULSA" },
  "74108": { state: "OK", city: "TULSA" },
  "74110": { state: "OK", city: "TULSA" },
  "74112": { state: "OK", city: "TULSA" },
  "74114": { state: "OK", city: "TULSA" },
  "74115": { state: "OK", city: "TULSA" },
  "74116": { state: "OK", city: "TULSA" },
  "74117": { state: "OK", city: "TULSA" },
  "74119": { state: "OK", city: "TULSA" },
  "74120": { state: "OK", city: "TULSA" },
  "74121": { state: "OK", city: "TULSA" },
  "74126": { state: "OK", city: "TULSA" },
  "74127": { state: "OK", city: "TULSA" },
  "74128": { state: "OK", city: "TULSA" },
  "74129": { state: "OK", city: "TULSA" },
  "74130": { state: "OK", city: "TULSA" },
  "74131": { state: "OK", city: "TULSA" },
  "74132": { state: "OK", city: "TULSA" },
  "74133": { state: "OK", city: "TULSA" },
  "74134": { state: "OK", city: "TULSA" },
  "74135": { state: "OK", city: "TULSA" },
  "74136": { state: "OK", city: "TULSA" },
  "74137": { state: "OK", city: "TULSA" },
  "74141": { state: "OK", city: "TULSA" },
  "74145": { state: "OK", city: "TULSA" },
  "74146": { state: "OK", city: "TULSA" },
  "74147": { state: "OK", city: "TULSA" },
  "74148": { state: "OK", city: "TULSA" },
  "74149": { state: "OK", city: "TULSA" },
  "74150": { state: "OK", city: "TULSA" },
  "74152": { state: "OK", city: "TULSA" },
  "74153": { state: "OK", city: "TULSA" },
  "74155": { state: "OK", city: "TULSA" },
  "74156": { state: "OK", city: "TULSA" },
  "74157": { state: "OK", city: "TULSA" },
  "74158": { state: "OK", city: "TULSA" },
  "74159": { state: "OK", city: "TULSA" },
  "74169": { state: "OK", city: "TULSA" },
  "74170": { state: "OK", city: "TULSA" },
  "74171": { state: "OK", city: "TULSA" },
  "74172": { state: "OK", city: "TULSA" },
  "74182": { state: "OK", city: "TULSA" },
  "74186": { state: "OK", city: "TULSA" },
  "74187": { state: "OK", city: "TULSA" },
  "74192": { state: "OK", city: "TULSA" },
  "74193": { state: "OK", city: "TULSA" },
  "74301": { state: "OK", city: "VINITA" },
  "74330": { state: "OK", city: "ADAIR" },
  "74331": { state: "OK", city: "AFTON" },
  "74332": { state: "OK", city: "BIG CABIN" },
  "74333": { state: "OK", city: "BLUEJACKET" },
  "74335": { state: "OK", city: "CARDIN" },
  "74337": { state: "OK", city: "CHOUTEAU" },
  "74338": { state: "OK", city: "COLCORD" },
  "74339": { state: "OK", city: "COMMERCE" },
  "74340": { state: "OK", city: "DISNEY" },
  "74342": { state: "OK", city: "EUCHA" },
  "74343": { state: "OK", city: "FAIRLAND" },
  "74344": { state: "OK", city: "GROVE" },
  "74345": { state: "OK", city: "GROVE" },
  "74346": { state: "OK", city: "JAY" },
  "74347": { state: "OK", city: "KANSAS" },
  "74349": { state: "OK", city: "KETCHUM" },
  "74350": { state: "OK", city: "LANGLEY" },
  "74352": { state: "OK", city: "LOCUST GROVE" },
  "74354": { state: "OK", city: "MIAMI" },
  "74355": { state: "OK", city: "MIAMI" },
  "74358": { state: "OK", city: "NORTH MIAMI" },
  "74359": { state: "OK", city: "OAKS" },
  "74360": { state: "OK", city: "PICHER" },
  "74361": { state: "OK", city: "PRYOR" },
  "74362": { state: "OK", city: "PRYOR" },
  "74363": { state: "OK", city: "QUAPAW" },
  "74364": { state: "OK", city: "ROSE" },
  "74365": { state: "OK", city: "SALINA" },
  "74366": { state: "OK", city: "SPAVINAW" },
  "74367": { state: "OK", city: "STRANG" },
  "74368": { state: "OK", city: "TWIN OAKS" },
  "74369": { state: "OK", city: "WELCH" },
  "74370": { state: "OK", city: "WYANDOTTE" },
  "74401": { state: "OK", city: "MUSKOGEE" },
  "74402": { state: "OK", city: "MUSKOGEE" },
  "74403": { state: "OK", city: "MUSKOGEE" },
  "74421": { state: "OK", city: "BEGGS" },
  "74422": { state: "OK", city: "BOYNTON" },
  "74423": { state: "OK", city: "BRAGGS" },
  "74425": { state: "OK", city: "CANADIAN" },
  "74426": { state: "OK", city: "CHECOTAH" },
  "74427": { state: "OK", city: "COOKSON" },
  "74428": { state: "OK", city: "COUNCIL HILL" },
  "74429": { state: "OK", city: "COWETA" },
  "74430": { state: "OK", city: "CROWDER" },
  "74431": { state: "OK", city: "DEWAR" },
  "74432": { state: "OK", city: "EUFAULA" },
  "74434": { state: "OK", city: "FORT GIBSON" },
  "74435": { state: "OK", city: "GORE" },
  "74436": { state: "OK", city: "HASKELL" },
  "74437": { state: "OK", city: "HENRYETTA" },
  "74438": { state: "OK", city: "HITCHITA" },
  "74439": { state: "OK", city: "BRAGGS" },
  "74440": { state: "OK", city: "HOYT" },
  "74441": { state: "OK", city: "HULBERT" },
  "74442": { state: "OK", city: "INDIANOLA" },
  "74444": { state: "OK", city: "MOODYS" },
  "74445": { state: "OK", city: "MORRIS" },
  "74446": { state: "OK", city: "OKAY" },
  "74447": { state: "OK", city: "OKMULGEE" },
  "74450": { state: "OK", city: "OKTAHA" },
  "74451": { state: "OK", city: "PARK HILL" },
  "74452": { state: "OK", city: "PEGGS" },
  "74454": { state: "OK", city: "PORTER" },
  "74455": { state: "OK", city: "PORUM" },
  "74456": { state: "OK", city: "PRESTON" },
  "74457": { state: "OK", city: "PROCTOR" },
  "74458": { state: "OK", city: "REDBIRD" },
  "74459": { state: "OK", city: "RENTIESVILLE" },
  "74460": { state: "OK", city: "SCHULTER" },
  "74461": { state: "OK", city: "STIDHAM" },
  "74462": { state: "OK", city: "STIGLER" },
  "74463": { state: "OK", city: "TAFT" },
  "74464": { state: "OK", city: "TAHLEQUAH" },
  "74465": { state: "OK", city: "TAHLEQUAH" },
  "74467": { state: "OK", city: "WAGONER" },
  "74468": { state: "OK", city: "WAINWRIGHT" },
  "74469": { state: "OK", city: "WARNER" },
  "74470": { state: "OK", city: "WEBBERS FALLS" },
  "74471": { state: "OK", city: "WELLING" },
  "74472": { state: "OK", city: "WHITEFIELD" },
  "74477": { state: "OK", city: "WAGONER" },
  "74501": { state: "OK", city: "MCALESTER" },
  "74502": { state: "OK", city: "MCALESTER" },
  "74521": { state: "OK", city: "ALBION" },
  "74522": { state: "OK", city: "ALDERSON" },
  "74523": { state: "OK", city: "ANTLERS" },
  "74525": { state: "OK", city: "ATOKA" },
  "74528": { state: "OK", city: "BLANCO" },
  "74529": { state: "OK", city: "BLOCKER" },
  "74530": { state: "OK", city: "BROMIDE" },
  "74531": { state: "OK", city: "CALVIN" },
  "74533": { state: "OK", city: "CANEY" },
  "74534": { state: "OK", city: "CENTRAHOMA" },
  "74535": { state: "OK", city: "CLARITA" },
  "74536": { state: "OK", city: "CLAYTON" },
  "74538": { state: "OK", city: "COALGATE" },
  "74540": { state: "OK", city: "DAISY" },
  "74543": { state: "OK", city: "FINLEY" },
  "74545": { state: "OK", city: "GOWEN" },
  "74546": { state: "OK", city: "HAILEYVILLE" },
  "74547": { state: "OK", city: "HARTSHORNE" },
  "74549": { state: "OK", city: "HONOBIA" },
  "74552": { state: "OK", city: "KINTA" },
  "74553": { state: "OK", city: "KIOWA" },
  "74554": { state: "OK", city: "KREBS" },
  "74555": { state: "OK", city: "LANE" },
  "74556": { state: "OK", city: "LEHIGH" },
  "74557": { state: "OK", city: "MOYERS" },
  "74558": { state: "OK", city: "NASHOBA" },
  "74559": { state: "OK", city: "PANOLA" },
  "74560": { state: "OK", city: "PITTSBURG" },
  "74561": { state: "OK", city: "QUINTON" },
  "74562": { state: "OK", city: "RATTAN" },
  "74563": { state: "OK", city: "RED OAK" },
  "74565": { state: "OK", city: "SAVANNA" },
  "74567": { state: "OK", city: "SNOW" },
  "74569": { state: "OK", city: "STRINGTOWN" },
  "74570": { state: "OK", city: "STUART" },
  "74571": { state: "OK", city: "TALIHINA" },
  "74572": { state: "OK", city: "TUPELO" },
  "74574": { state: "OK", city: "TUSKAHOMA" },
  "74576": { state: "OK", city: "WARDVILLE" },
  "74577": { state: "OK", city: "WHITESBORO" },
  "74578": { state: "OK", city: "WILBURTON" },
  "74601": { state: "OK", city: "PONCA CITY" },
  "74602": { state: "OK", city: "PONCA CITY" },
  "74604": { state: "OK", city: "PONCA CITY" },
  "74630": { state: "OK", city: "BILLINGS" },
  "74631": { state: "OK", city: "BLACKWELL" },
  "74632": { state: "OK", city: "BRAMAN" },
  "74633": { state: "OK", city: "BURBANK" },
  "74636": { state: "OK", city: "DEER CREEK" },
  "74637": { state: "OK", city: "FAIRFAX" },
  "74640": { state: "OK", city: "HUNTER" },
  "74641": { state: "OK", city: "KAW CITY" },
  "74643": { state: "OK", city: "LAMONT" },
  "74644": { state: "OK", city: "MARLAND" },
  "74646": { state: "OK", city: "NARDIN" },
  "74647": { state: "OK", city: "NEWKIRK" },
  "74650": { state: "OK", city: "RALSTON" },
  "74651": { state: "OK", city: "RED ROCK" },
  "74652": { state: "OK", city: "SHIDLER" },
  "74653": { state: "OK", city: "TONKAWA" },
  "74701": { state: "OK", city: "DURANT" },
  "74702": { state: "OK", city: "DURANT" },
  "74720": { state: "OK", city: "ACHILLE" },
  "74721": { state: "OK", city: "ALBANY" },
  "74722": { state: "OK", city: "BATTIEST" },
  "74723": { state: "OK", city: "BENNINGTON" },
  "74724": { state: "OK", city: "BETHEL" },
  "74726": { state: "OK", city: "BOKCHITO" },
  "74727": { state: "OK", city: "BOSWELL" },
  "74728": { state: "OK", city: "BROKEN BOW" },
  "74729": { state: "OK", city: "CADDO" },
  "74730": { state: "OK", city: "CALERA" },
  "74731": { state: "OK", city: "CARTWRIGHT" },
  "74733": { state: "OK", city: "COLBERT" },
  "74734": { state: "OK", city: "EAGLETOWN" },
  "74735": { state: "OK", city: "FORT TOWSON" },
  "74736": { state: "OK", city: "GARVIN" },
  "74737": { state: "OK", city: "GOLDEN" },
  "74738": { state: "OK", city: "GRANT" },
  "74740": { state: "OK", city: "HAWORTH" },
  "74741": { state: "OK", city: "HENDRIX" },
  "74743": { state: "OK", city: "HUGO" },
  "74745": { state: "OK", city: "IDABEL" },
  "74747": { state: "OK", city: "KEMP" },
  "74748": { state: "OK", city: "KENEFIC" },
  "74750": { state: "OK", city: "MILLERTON" },
  "74752": { state: "OK", city: "PICKENS" },
  "74753": { state: "OK", city: "PLATTER" },
  "74754": { state: "OK", city: "RINGOLD" },
  "74755": { state: "OK", city: "RUFE" },
  "74756": { state: "OK", city: "SAWYER" },
  "74759": { state: "OK", city: "SOPER" },
  "74760": { state: "OK", city: "SPENCERVILLE" },
  "74761": { state: "OK", city: "SWINK" },
  "74764": { state: "OK", city: "VALLIANT" },
  "74766": { state: "OK", city: "WRIGHT CITY" },
  "74801": { state: "OK", city: "SHAWNEE" },
  "74802": { state: "OK", city: "SHAWNEE" },
  "74804": { state: "OK", city: "SHAWNEE" },
  "74818": { state: "OK", city: "SEMINOLE" },
  "74820": { state: "OK", city: "ADA" },
  "74821": { state: "OK", city: "ADA" },
  "74824": { state: "OK", city: "AGRA" },
  "74825": { state: "OK", city: "ALLEN" },
  "74826": { state: "OK", city: "ASHER" },
  "74827": { state: "OK", city: "ATWOOD" },
  "74829": { state: "OK", city: "BOLEY" },
  "74830": { state: "OK", city: "BOWLEGS" },
  "74831": { state: "OK", city: "BYARS" },
  "74832": { state: "OK", city: "CARNEY" },
  "74833": { state: "OK", city: "CASTLE" },
  "74834": { state: "OK", city: "CHANDLER" },
  "74836": { state: "OK", city: "CONNERVILLE" },
  "74837": { state: "OK", city: "CROMWELL" },
  "74839": { state: "OK", city: "DUSTIN" },
  "74840": { state: "OK", city: "EARLSBORO" },
  "74842": { state: "OK", city: "FITTSTOWN" },
  "74843": { state: "OK", city: "FITZHUGH" },
  "74844": { state: "OK", city: "FRANCIS" },
  "74845": { state: "OK", city: "HANNA" },
  "74848": { state: "OK", city: "HOLDENVILLE" },
  "74849": { state: "OK", city: "KONAWA" },
  "74850": { state: "OK", city: "LAMAR" },
  "74851": { state: "OK", city: "MCLOUD" },
  "74852": { state: "OK", city: "MACOMB" },
  "74854": { state: "OK", city: "MAUD" },
  "74855": { state: "OK", city: "MEEKER" },
  "74856": { state: "OK", city: "MILL CREEK" },
  "74857": { state: "OK", city: "NEWALLA" },
  "74859": { state: "OK", city: "OKEMAH" },
  "74860": { state: "OK", city: "PADEN" },
  "74864": { state: "OK", city: "PRAGUE" },
  "74865": { state: "OK", city: "ROFF" },
  "74866": { state: "OK", city: "SAINT LOUIS" },
  "74867": { state: "OK", city: "SASAKWA" },
  "74868": { state: "OK", city: "SEMINOLE" },
  "74869": { state: "OK", city: "SPARKS" },
  "74871": { state: "OK", city: "STONEWALL" },
  "74872": { state: "OK", city: "STRATFORD" },
  "74873": { state: "OK", city: "TECUMSEH" },
  "74875": { state: "OK", city: "TRYON" },
  "74878": { state: "OK", city: "WANETTE" },
  "74880": { state: "OK", city: "WELEETKA" },
  "74881": { state: "OK", city: "WELLSTON" },
  "74883": { state: "OK", city: "WETUMKA" },
  "74884": { state: "OK", city: "WEWOKA" },
  "74901": { state: "OK", city: "ARKOMA" },
  "74902": { state: "OK", city: "POCOLA" },
  "74930": { state: "OK", city: "BOKOSHE" },
  "74931": { state: "OK", city: "BUNCH" },
  "74932": { state: "OK", city: "CAMERON" },
  "74935": { state: "OK", city: "FANSHAWE" },
  "74936": { state: "OK", city: "GANS" },
  "74937": { state: "OK", city: "HEAVENER" },
  "74939": { state: "OK", city: "HODGEN" },
  "74940": { state: "OK", city: "HOWE" },
  "74941": { state: "OK", city: "KEOTA" },
  "74942": { state: "OK", city: "LEFLORE" },
  "74943": { state: "OK", city: "LEQUIRE" },
  "74944": { state: "OK", city: "MCCURTAIN" },
  "74945": { state: "OK", city: "MARBLE CITY" },
  "74946": { state: "OK", city: "MOFFETT" },
  "74947": { state: "OK", city: "MONROE" },
  "74948": { state: "OK", city: "MULDROW" },
  "74949": { state: "OK", city: "MUSE" },
  "74951": { state: "OK", city: "PANAMA" },
  "74953": { state: "OK", city: "POTEAU" },
  "74954": { state: "OK", city: "ROLAND" },
  "74955": { state: "OK", city: "SALLISAW" },
  "74956": { state: "OK", city: "SHADY POINT" },
  "74957": { state: "OK", city: "SMITHVILLE" },
  "74959": { state: "OK", city: "SPIRO" },
  "74960": { state: "OK", city: "STILWELL" },
  "74962": { state: "OK", city: "VIAN" },
  "74963": { state: "OK", city: "WATSON" },
  "74964": { state: "OK", city: "WATTS" },
  "74965": { state: "OK", city: "WESTVILLE" },
  "74966": { state: "OK", city: "WISTER" },
  "75001": { state: "TX", city: "ADDISON" },
  "75002": { state: "TX", city: "ALLEN" },
  "75006": { state: "TX", city: "CARROLLTON" },
  "75007": { state: "TX", city: "CARROLLTON" },
  "75009": { state: "TX", city: "CELINA" },
  "75010": { state: "TX", city: "CARROLLTON" },
  "75011": { state: "TX", city: "CARROLLTON" },
  "75013": { state: "TX", city: "ALLEN" },
  "75014": { state: "TX", city: "IRVING" },
  "75015": { state: "TX", city: "IRVING" },
  "75016": { state: "TX", city: "IRVING" },
  "75017": { state: "TX", city: "IRVING" },
  "75019": { state: "TX", city: "COPPELL" },
  "75020": { state: "TX", city: "DENISON" },
  "75021": { state: "TX", city: "DENISON" },
  "75022": { state: "TX", city: "FLOWER MOUND" },
  "75023": { state: "TX", city: "PLANO" },
  "75024": { state: "TX", city: "PLANO" },
  "75025": { state: "TX", city: "PLANO" },
  "75026": { state: "TX", city: "PLANO" },
  "75027": { state: "TX", city: "FLOWER MOUND" },
  "75028": { state: "TX", city: "FLOWER MOUND" },
  "75029": { state: "TX", city: "LEWISVILLE" },
  "75030": { state: "TX", city: "ROWLETT" },
  "75032": { state: "TX", city: "ROCKWALL" },
  "75033": { state: "TX", city: "FRISCO" },
  "75034": { state: "TX", city: "FRISCO" },
  "75035": { state: "TX", city: "FRISCO" },
  "75036": { state: "TX", city: "FRISCO" },
  "75038": { state: "TX", city: "IRVING" },
  "75039": { state: "TX", city: "IRVING" },
  "75040": { state: "TX", city: "GARLAND" },
  "75041": { state: "TX", city: "GARLAND" },
  "75042": { state: "TX", city: "GARLAND" },
  "75043": { state: "TX", city: "GARLAND" },
  "75044": { state: "TX", city: "GARLAND" },
  "75045": { state: "TX", city: "GARLAND" },
  "75046": { state: "TX", city: "GARLAND" },
  "75047": { state: "TX", city: "GARLAND" },
  "75048": { state: "TX", city: "SACHSE" },
  "75049": { state: "TX", city: "GARLAND" },
  "75050": { state: "TX", city: "GRAND PRAIRIE" },
  "75051": { state: "TX", city: "GRAND PRAIRIE" },
  "75052": { state: "TX", city: "GRAND PRAIRIE" },
  "75053": { state: "TX", city: "GRAND PRAIRIE" },
  "75054": { state: "TX", city: "GRAND PRAIRIE" },
  "75056": { state: "TX", city: "THE COLONY" },
  "75057": { state: "TX", city: "LEWISVILLE" },
  "75058": { state: "TX", city: "GUNTER" },
  "75059": { state: "TX", city: "IRVING" },
  "75060": { state: "TX", city: "IRVING" },
  "75061": { state: "TX", city: "IRVING" },
  "75062": { state: "TX", city: "IRVING" },
  "75063": { state: "TX", city: "IRVING" },
  "75064": { state: "TX", city: "IRVING" },
  "75065": { state: "TX", city: "LAKE DALLAS" },
  "75067": { state: "TX", city: "LEWISVILLE" },
  "75068": { state: "TX", city: "LITTLE ELM" },
  "75069": { state: "TX", city: "MCKINNEY" },
  "75070": { state: "TX", city: "MCKINNEY" },
  "75071": { state: "TX", city: "MCKINNEY" },
  "75072": { state: "TX", city: "MCKINNEY" },
  "75074": { state: "TX", city: "PLANO" },
  "75075": { state: "TX", city: "PLANO" },
  "75076": { state: "TX", city: "POTTSBORO" },
  "75077": { state: "TX", city: "LEWISVILLE" },
  "75078": { state: "TX", city: "PROSPER" },
  "75080": { state: "TX", city: "RICHARDSON" },
  "75081": { state: "TX", city: "RICHARDSON" },
  "75082": { state: "TX", city: "RICHARDSON" },
  "75083": { state: "TX", city: "RICHARDSON" },
  "75085": { state: "TX", city: "RICHARDSON" },
  "75086": { state: "TX", city: "PLANO" },
  "75087": { state: "TX", city: "ROCKWALL" },
  "75088": { state: "TX", city: "ROWLETT" },
  "75089": { state: "TX", city: "ROWLETT" },
  "75090": { state: "TX", city: "SHERMAN" },
  "75091": { state: "TX", city: "SHERMAN" },
  "75092": { state: "TX", city: "SHERMAN" },
  "75093": { state: "TX", city: "PLANO" },
  "75094": { state: "TX", city: "PLANO" },
  "75097": { state: "TX", city: "WESTON" },
  "75098": { state: "TX", city: "WYLIE" },
  "75099": { state: "TX", city: "COPPELL" },
  "75101": { state: "TX", city: "BARDWELL" },
  "75102": { state: "TX", city: "BARRY" },
  "75103": { state: "TX", city: "CANTON" },
  "75104": { state: "TX", city: "CEDAR HILL" },
  "75105": { state: "TX", city: "CHATFIELD" },
  "75106": { state: "TX", city: "CEDAR HILL" },
  "75109": { state: "TX", city: "CORSICANA" },
  "75110": { state: "TX", city: "CORSICANA" },
  "75114": { state: "TX", city: "CRANDALL" },
  "75115": { state: "TX", city: "DESOTO" },
  "75116": { state: "TX", city: "DUNCANVILLE" },
  "75117": { state: "TX", city: "EDGEWOOD" },
  "75118": { state: "TX", city: "ELMO" },
  "75119": { state: "TX", city: "ENNIS" },
  "75120": { state: "TX", city: "ENNIS" },
  "75121": { state: "TX", city: "COPEVILLE" },
  "75123": { state: "TX", city: "DESOTO" },
  "75124": { state: "TX", city: "EUSTACE" },
  "75125": { state: "TX", city: "FERRIS" },
  "75126": { state: "TX", city: "FORNEY" },
  "75127": { state: "TX", city: "FRUITVALE" },
  "75132": { state: "TX", city: "FATE" },
  "75134": { state: "TX", city: "LANCASTER" },
  "75135": { state: "TX", city: "CADDO MILLS" },
  "75137": { state: "TX", city: "DUNCANVILLE" },
  "75138": { state: "TX", city: "DUNCANVILLE" },
  "75140": { state: "TX", city: "GRAND SALINE" },
  "75141": { state: "TX", city: "HUTCHINS" },
  "75142": { state: "TX", city: "KAUFMAN" },
  "75143": { state: "TX", city: "KEMP" },
  "75144": { state: "TX", city: "KERENS" },
  "75146": { state: "TX", city: "LANCASTER" },
  "75147": { state: "TX", city: "MABANK" },
  "75148": { state: "TX", city: "MALAKOFF" },
  "75149": { state: "TX", city: "MESQUITE" },
  "75150": { state: "TX", city: "MESQUITE" },
  "75151": { state: "TX", city: "CORSICANA" },
  "75152": { state: "TX", city: "PALMER" },
  "75153": { state: "TX", city: "POWELL" },
  "75154": { state: "TX", city: "RED OAK" },
  "75155": { state: "TX", city: "RICE" },
  "75156": { state: "TX", city: "MABANK" },
  "75157": { state: "TX", city: "ROSSER" },
  "75158": { state: "TX", city: "SCURRY" },
  "75159": { state: "TX", city: "SEAGOVILLE" },
  "75160": { state: "TX", city: "TERRELL" },
  "75161": { state: "TX", city: "TERRELL" },
  "75163": { state: "TX", city: "TRINIDAD" },
  "75164": { state: "TX", city: "JOSEPHINE" },
  "75165": { state: "TX", city: "WAXAHACHIE" },
  "75166": { state: "TX", city: "LAVON" },
  "75167": { state: "TX", city: "WAXAHACHIE" },
  "75168": { state: "TX", city: "WAXAHACHIE" },
  "75169": { state: "TX", city: "WILLS POINT" },
  "75172": { state: "TX", city: "WILMER" },
  "75173": { state: "TX", city: "NEVADA" },
  "75180": { state: "TX", city: "BALCH SPRINGS" },
  "75181": { state: "TX", city: "MESQUITE" },
  "75182": { state: "TX", city: "SUNNYVALE" },
  "75185": { state: "TX", city: "MESQUITE" },
  "75187": { state: "TX", city: "MESQUITE" },
  "75189": { state: "TX", city: "ROYSE CITY" },
  "75201": { state: "TX", city: "DALLAS" },
  "75202": { state: "TX", city: "DALLAS" },
  "75203": { state: "TX", city: "DALLAS" },
  "75204": { state: "TX", city: "DALLAS" },
  "75205": { state: "TX", city: "DALLAS" },
  "75206": { state: "TX", city: "DALLAS" },
  "75207": { state: "TX", city: "DALLAS" },
  "75208": { state: "TX", city: "DALLAS" },
  "75209": { state: "TX", city: "DALLAS" },
  "75210": { state: "TX", city: "DALLAS" },
  "75211": { state: "TX", city: "DALLAS" },
  "75212": { state: "TX", city: "DALLAS" },
  "75214": { state: "TX", city: "DALLAS" },
  "75215": { state: "TX", city: "DALLAS" },
  "75216": { state: "TX", city: "DALLAS" },
  "75217": { state: "TX", city: "DALLAS" },
  "75218": { state: "TX", city: "DALLAS" },
  "75219": { state: "TX", city: "DALLAS" },
  "75220": { state: "TX", city: "DALLAS" },
  "75221": { state: "TX", city: "DALLAS" },
  "75222": { state: "TX", city: "DALLAS" },
  "75223": { state: "TX", city: "DALLAS" },
  "75224": { state: "TX", city: "DALLAS" },
  "75225": { state: "TX", city: "DALLAS" },
  "75226": { state: "TX", city: "DALLAS" },
  "75227": { state: "TX", city: "DALLAS" },
  "75228": { state: "TX", city: "DALLAS" },
  "75229": { state: "TX", city: "DALLAS" },
  "75230": { state: "TX", city: "DALLAS" },
  "75231": { state: "TX", city: "DALLAS" },
  "75232": { state: "TX", city: "DALLAS" },
  "75233": { state: "TX", city: "DALLAS" },
  "75234": { state: "TX", city: "DALLAS" },
  "75235": { state: "TX", city: "DALLAS" },
  "75236": { state: "TX", city: "DALLAS" },
  "75237": { state: "TX", city: "DALLAS" },
  "75238": { state: "TX", city: "DALLAS" },
  "75240": { state: "TX", city: "DALLAS" },
  "75241": { state: "TX", city: "DALLAS" },
  "75242": { state: "TX", city: "DALLAS" },
  "75243": { state: "TX", city: "DALLAS" },
  "75244": { state: "TX", city: "DALLAS" },
  "75246": { state: "TX", city: "DALLAS" },
  "75247": { state: "TX", city: "DALLAS" },
  "75248": { state: "TX", city: "DALLAS" },
  "75249": { state: "TX", city: "DALLAS" },
  "75250": { state: "TX", city: "DALLAS" },
  "75251": { state: "TX", city: "DALLAS" },
  "75252": { state: "TX", city: "DALLAS" },
  "75253": { state: "TX", city: "DALLAS" },
  "75254": { state: "TX", city: "DALLAS" },
  "75260": { state: "TX", city: "DALLAS" },
  "75261": { state: "TX", city: "DALLAS" },
  "75262": { state: "TX", city: "DALLAS" },
  "75263": { state: "TX", city: "DALLAS" },
  "75264": { state: "TX", city: "DALLAS" },
  "75265": { state: "TX", city: "DALLAS" },
  "75266": { state: "TX", city: "DALLAS" },
  "75267": { state: "TX", city: "DALLAS" },
  "75270": { state: "TX", city: "DALLAS" },
  "75275": { state: "TX", city: "DALLAS" },
  "75277": { state: "TX", city: "DALLAS" },
  "75283": { state: "TX", city: "DALLAS" },
  "75284": { state: "TX", city: "DALLAS" },
  "75285": { state: "TX", city: "DALLAS" },
  "75287": { state: "TX", city: "DALLAS" },
  "75301": { state: "TX", city: "DALLAS" },
  "75303": { state: "TX", city: "DALLAS" },
  "75312": { state: "TX", city: "DALLAS" },
  "75313": { state: "TX", city: "DALLAS" },
  "75315": { state: "TX", city: "DALLAS" },
  "75320": { state: "TX", city: "DALLAS" },
  "75326": { state: "TX", city: "DALLAS" },
  "75336": { state: "TX", city: "DALLAS" },
  "75339": { state: "TX", city: "DALLAS" },
  "75342": { state: "TX", city: "DALLAS" },
  "75354": { state: "TX", city: "DALLAS" },
  "75355": { state: "TX", city: "DALLAS" },
  "75356": { state: "TX", city: "DALLAS" },
  "75357": { state: "TX", city: "DALLAS" },
  "75358": { state: "TX", city: "DALLAS" },
  "75359": { state: "TX", city: "DALLAS" },
  "75360": { state: "TX", city: "DALLAS" },
  "75367": { state: "TX", city: "DALLAS" },
  "75368": { state: "TX", city: "DALLAS" },
  "75370": { state: "TX", city: "DALLAS" },
  "75371": { state: "TX", city: "DALLAS" },
  "75372": { state: "TX", city: "DALLAS" },
  "75373": { state: "TX", city: "DALLAS" },
  "75374": { state: "TX", city: "DALLAS" },
  "75376": { state: "TX", city: "DALLAS" },
  "75378": { state: "TX", city: "DALLAS" },
  "75379": { state: "TX", city: "DALLAS" },
  "75380": { state: "TX", city: "DALLAS" },
  "75381": { state: "TX", city: "DALLAS" },
  "75382": { state: "TX", city: "DALLAS" },
  "75389": { state: "TX", city: "DALLAS" },
  "75390": { state: "TX", city: "DALLAS" },
  "75391": { state: "TX", city: "DALLAS" },
  "75392": { state: "TX", city: "DALLAS" },
  "75393": { state: "TX", city: "DALLAS" },
  "75394": { state: "TX", city: "DALLAS" },
  "75395": { state: "TX", city: "DALLAS" },
  "75397": { state: "TX", city: "DALLAS" },
  "75398": { state: "TX", city: "DALLAS" },
  "75401": { state: "TX", city: "GREENVILLE" },
  "75402": { state: "TX", city: "GREENVILLE" },
  "75403": { state: "TX", city: "GREENVILLE" },
  "75404": { state: "TX", city: "GREENVILLE" },
  "75407": { state: "TX", city: "PRINCETON" },
  "75409": { state: "TX", city: "ANNA" },
  "75410": { state: "TX", city: "ALBA" },
  "75411": { state: "TX", city: "ARTHUR CITY" },
  "75412": { state: "TX", city: "BAGWELL" },
  "75413": { state: "TX", city: "BAILEY" },
  "75414": { state: "TX", city: "BELLS" },
  "75415": { state: "TX", city: "BEN FRANKLIN" },
  "75416": { state: "TX", city: "BLOSSOM" },
  "75417": { state: "TX", city: "BOGATA" },
  "75418": { state: "TX", city: "BONHAM" },
  "75420": { state: "TX", city: "BRASHEAR" },
  "75421": { state: "TX", city: "BROOKSTON" },
  "75422": { state: "TX", city: "CAMPBELL" },
  "75423": { state: "TX", city: "CELESTE" },
  "75424": { state: "TX", city: "BLUE RIDGE" },
  "75425": { state: "TX", city: "CHICOTA" },
  "75426": { state: "TX", city: "CLARKSVILLE" },
  "75428": { state: "TX", city: "COMMERCE" },
  "75429": { state: "TX", city: "COMMERCE" },
  "75431": { state: "TX", city: "COMO" },
  "75432": { state: "TX", city: "COOPER" },
  "75433": { state: "TX", city: "CUMBY" },
  "75434": { state: "TX", city: "CUNNINGHAM" },
  "75435": { state: "TX", city: "DEPORT" },
  "75436": { state: "TX", city: "DETROIT" },
  "75437": { state: "TX", city: "DIKE" },
  "75438": { state: "TX", city: "DODD CITY" },
  "75439": { state: "TX", city: "ECTOR" },
  "75440": { state: "TX", city: "EMORY" },
  "75441": { state: "TX", city: "ENLOE" },
  "75442": { state: "TX", city: "FARMERSVILLE" },
  "75443": { state: "TX", city: "GOBER" },
  "75444": { state: "TX", city: "GOLDEN" },
  "75446": { state: "TX", city: "HONEY GROVE" },
  "75447": { state: "TX", city: "IVANHOE" },
  "75448": { state: "TX", city: "KLONDIKE" },
  "75449": { state: "TX", city: "LADONIA" },
  "75450": { state: "TX", city: "LAKE CREEK" },
  "75451": { state: "TX", city: "LEESBURG" },
  "75452": { state: "TX", city: "LEONARD" },
  "75453": { state: "TX", city: "LONE OAK" },
  "75454": { state: "TX", city: "MELISSA" },
  "75455": { state: "TX", city: "MOUNT PLEASANT" },
  "75456": { state: "TX", city: "MOUNT PLEASANT" },
  "75457": { state: "TX", city: "MOUNT VERNON" },
  "75458": { state: "TX", city: "MERIT" },
  "75459": { state: "TX", city: "HOWE" },
  "75460": { state: "TX", city: "PARIS" },
  "75461": { state: "TX", city: "PARIS" },
  "75462": { state: "TX", city: "PARIS" },
  "75468": { state: "TX", city: "PATTONVILLE" },
  "75469": { state: "TX", city: "PECAN GAP" },
  "75470": { state: "TX", city: "PETTY" },
  "75471": { state: "TX", city: "PICKTON" },
  "75472": { state: "TX", city: "POINT" },
  "75473": { state: "TX", city: "POWDERLY" },
  "75474": { state: "TX", city: "QUINLAN" },
  "75475": { state: "TX", city: "RANDOLPH" },
  "75476": { state: "TX", city: "RAVENNA" },
  "75477": { state: "TX", city: "ROXTON" },
  "75478": { state: "TX", city: "SALTILLO" },
  "75479": { state: "TX", city: "SAVOY" },
  "75480": { state: "TX", city: "SCROGGINS" },
  "75481": { state: "TX", city: "SULPHUR BLUFF" },
  "75482": { state: "TX", city: "SULPHUR SPRINGS" },
  "75483": { state: "TX", city: "SULPHUR SPRINGS" },
  "75485": { state: "TX", city: "WESTMINSTER" },
  "75486": { state: "TX", city: "SUMNER" },
  "75487": { state: "TX", city: "TALCO" },
  "75488": { state: "TX", city: "TELEPHONE" },
  "75489": { state: "TX", city: "TOM BEAN" },
  "75490": { state: "TX", city: "TRENTON" },
  "75491": { state: "TX", city: "WHITEWRIGHT" },
  "75492": { state: "TX", city: "WINDOM" },
  "75493": { state: "TX", city: "WINFIELD" },
  "75494": { state: "TX", city: "WINNSBORO" },
  "75495": { state: "TX", city: "VAN ALSTYNE" },
  "75496": { state: "TX", city: "WOLFE CITY" },
  "75497": { state: "TX", city: "YANTIS" },
  "75501": { state: "TX", city: "TEXARKANA" },
  "75503": { state: "TX", city: "TEXARKANA" },
  "75504": { state: "TX", city: "TEXARKANA" },
  "75505": { state: "TX", city: "TEXARKANA" },
  "75507": { state: "TX", city: "TEXARKANA" },
  "75550": { state: "TX", city: "ANNONA" },
  "75551": { state: "TX", city: "ATLANTA" },
  "75554": { state: "TX", city: "AVERY" },
  "75555": { state: "TX", city: "BIVINS" },
  "75556": { state: "TX", city: "BLOOMBURG" },
  "75558": { state: "TX", city: "COOKVILLE" },
  "75559": { state: "TX", city: "DE KALB" },
  "75560": { state: "TX", city: "DOUGLASSVILLE" },
  "75561": { state: "TX", city: "HOOKS" },
  "75562": { state: "TX", city: "KILDARE" },
  "75563": { state: "TX", city: "LINDEN" },
  "75564": { state: "TX", city: "LODI" },
  "75565": { state: "TX", city: "MC LEOD" },
  "75566": { state: "TX", city: "MARIETTA" },
  "75567": { state: "TX", city: "MAUD" },
  "75568": { state: "TX", city: "NAPLES" },
  "75569": { state: "TX", city: "NASH" },
  "75570": { state: "TX", city: "NEW BOSTON" },
  "75571": { state: "TX", city: "OMAHA" },
  "75572": { state: "TX", city: "QUEEN CITY" },
  "75573": { state: "TX", city: "REDWATER" },
  "75574": { state: "TX", city: "SIMMS" },
  "75599": { state: "TX", city: "TEXARKANA" },
  "75601": { state: "TX", city: "LONGVIEW" },
  "75602": { state: "TX", city: "LONGVIEW" },
  "75603": { state: "TX", city: "LONGVIEW" },
  "75604": { state: "TX", city: "LONGVIEW" },
  "75605": { state: "TX", city: "LONGVIEW" },
  "75606": { state: "TX", city: "LONGVIEW" },
  "75607": { state: "TX", city: "LONGVIEW" },
  "75608": { state: "TX", city: "LONGVIEW" },
  "75615": { state: "TX", city: "LONGVIEW" },
  "75630": { state: "TX", city: "AVINGER" },
  "75631": { state: "TX", city: "BECKVILLE" },
  "75633": { state: "TX", city: "CARTHAGE" },
  "75636": { state: "TX", city: "CASON" },
  "75637": { state: "TX", city: "CLAYTON" },
  "75638": { state: "TX", city: "DAINGERFIELD" },
  "75639": { state: "TX", city: "DE BERRY" },
  "75640": { state: "TX", city: "DIANA" },
  "75641": { state: "TX", city: "EASTON" },
  "75642": { state: "TX", city: "ELYSIAN FIELDS" },
  "75643": { state: "TX", city: "GARY" },
  "75644": { state: "TX", city: "GILMER" },
  "75645": { state: "TX", city: "GILMER" },
  "75647": { state: "TX", city: "GLADEWATER" },
  "75650": { state: "TX", city: "HALLSVILLE" },
  "75651": { state: "TX", city: "HARLETON" },
  "75652": { state: "TX", city: "HENDERSON" },
  "75653": { state: "TX", city: "HENDERSON" },
  "75654": { state: "TX", city: "HENDERSON" },
  "75656": { state: "TX", city: "HUGHES SPRINGS" },
  "75657": { state: "TX", city: "JEFFERSON" },
  "75658": { state: "TX", city: "JOINERVILLE" },
  "75659": { state: "TX", city: "JONESVILLE" },
  "75660": { state: "TX", city: "JUDSON" },
  "75661": { state: "TX", city: "KARNACK" },
  "75662": { state: "TX", city: "KILGORE" },
  "75663": { state: "TX", city: "KILGORE" },
  "75666": { state: "TX", city: "LAIRD HILL" },
  "75667": { state: "TX", city: "LANEVILLE" },
  "75668": { state: "TX", city: "LONE STAR" },
  "75669": { state: "TX", city: "LONG BRANCH" },
  "75670": { state: "TX", city: "MARSHALL" },
  "75671": { state: "TX", city: "MARSHALL" },
  "75672": { state: "TX", city: "MARSHALL" },
  "75680": { state: "TX", city: "MINDEN" },
  "75681": { state: "TX", city: "MOUNT ENTERPRISE" },
  "75682": { state: "TX", city: "NEW LONDON" },
  "75683": { state: "TX", city: "ORE CITY" },
  "75684": { state: "TX", city: "OVERTON" },
  "75685": { state: "TX", city: "PANOLA" },
  "75686": { state: "TX", city: "PITTSBURG" },
  "75687": { state: "TX", city: "PRICE" },
  "75688": { state: "TX", city: "SCOTTSVILLE" },
  "75689": { state: "TX", city: "SELMAN CITY" },
  "75691": { state: "TX", city: "TATUM" },
  "75692": { state: "TX", city: "WASKOM" },
  "75693": { state: "TX", city: "WHITE OAK" },
  "75694": { state: "TX", city: "WOODLAWN" },
  "75701": { state: "TX", city: "TYLER" },
  "75702": { state: "TX", city: "TYLER" },
  "75703": { state: "TX", city: "TYLER" },
  "75704": { state: "TX", city: "TYLER" },
  "75705": { state: "TX", city: "TYLER" },
  "75706": { state: "TX", city: "TYLER" },
  "75707": { state: "TX", city: "TYLER" },
  "75708": { state: "TX", city: "TYLER" },
  "75709": { state: "TX", city: "TYLER" },
  "75710": { state: "TX", city: "TYLER" },
  "75711": { state: "TX", city: "TYLER" },
  "75712": { state: "TX", city: "TYLER" },
  "75713": { state: "TX", city: "TYLER" },
  "75750": { state: "TX", city: "ARP" },
  "75751": { state: "TX", city: "ATHENS" },
  "75752": { state: "TX", city: "ATHENS" },
  "75754": { state: "TX", city: "BEN WHEELER" },
  "75755": { state: "TX", city: "BIG SANDY" },
  "75756": { state: "TX", city: "BROWNSBORO" },
  "75757": { state: "TX", city: "BULLARD" },
  "75758": { state: "TX", city: "CHANDLER" },
  "75759": { state: "TX", city: "CUNEY" },
  "75760": { state: "TX", city: "CUSHING" },
  "75762": { state: "TX", city: "FLINT" },
  "75763": { state: "TX", city: "FRANKSTON" },
  "75764": { state: "TX", city: "GALLATIN" },
  "75765": { state: "TX", city: "HAWKINS" },
  "75766": { state: "TX", city: "JACKSONVILLE" },
  "75770": { state: "TX", city: "LARUE" },
  "75771": { state: "TX", city: "LINDALE" },
  "75772": { state: "TX", city: "MAYDELLE" },
  "75773": { state: "TX", city: "MINEOLA" },
  "75778": { state: "TX", city: "MURCHISON" },
  "75779": { state: "TX", city: "NECHES" },
  "75780": { state: "TX", city: "NEW SUMMERFIELD" },
  "75782": { state: "TX", city: "POYNOR" },
  "75783": { state: "TX", city: "QUITMAN" },
  "75784": { state: "TX", city: "REKLAW" },
  "75785": { state: "TX", city: "RUSK" },
  "75788": { state: "TX", city: "SACUL" },
  "75789": { state: "TX", city: "TROUP" },
  "75790": { state: "TX", city: "VAN" },
  "75791": { state: "TX", city: "WHITEHOUSE" },
  "75792": { state: "TX", city: "WINONA" },
  "75797": { state: "TX", city: "BIG SANDY" },
  "75798": { state: "TX", city: "TYLER" },
  "75799": { state: "TX", city: "TYLER" },
  "75801": { state: "TX", city: "PALESTINE" },
  "75802": { state: "TX", city: "PALESTINE" },
  "75803": { state: "TX", city: "PALESTINE" },
  "75831": { state: "TX", city: "BUFFALO" },
  "75832": { state: "TX", city: "CAYUGA" },
  "75833": { state: "TX", city: "CENTERVILLE" },
  "75834": { state: "TX", city: "CENTRALIA" },
  "75835": { state: "TX", city: "CROCKETT" },
  "75838": { state: "TX", city: "DONIE" },
  "75839": { state: "TX", city: "ELKHART" },
  "75840": { state: "TX", city: "FAIRFIELD" },
  "75844": { state: "TX", city: "GRAPELAND" },
  "75845": { state: "TX", city: "GROVETON" },
  "75846": { state: "TX", city: "JEWETT" },
  "75847": { state: "TX", city: "KENNARD" },
  "75848": { state: "TX", city: "KIRVIN" },
  "75849": { state: "TX", city: "LATEXO" },
  "75850": { state: "TX", city: "LEONA" },
  "75851": { state: "TX", city: "LOVELADY" },
  "75852": { state: "TX", city: "MIDWAY" },
  "75853": { state: "TX", city: "MONTALBA" },
  "75855": { state: "TX", city: "OAKWOOD" },
  "75856": { state: "TX", city: "PENNINGTON" },
  "75858": { state: "TX", city: "RATCLIFF" },
  "75859": { state: "TX", city: "STREETMAN" },
  "75860": { state: "TX", city: "TEAGUE" },
  "75861": { state: "TX", city: "TENNESSEE COLONY" },
  "75862": { state: "TX", city: "TRINITY" },
  "75865": { state: "TX", city: "WOODLAKE" },
  "75880": { state: "TX", city: "TENNESSEE COLONY" },
  "75882": { state: "TX", city: "PALESTINE" },
  "75884": { state: "TX", city: "TENNESSEE COLONY" },
  "75886": { state: "TX", city: "TENNESSEE COLONY" },
  "75901": { state: "TX", city: "LUFKIN" },
  "75902": { state: "TX", city: "LUFKIN" },
  "75903": { state: "TX", city: "LUFKIN" },
  "75904": { state: "TX", city: "LUFKIN" },
  "75915": { state: "TX", city: "LUFKIN" },
  "75925": { state: "TX", city: "ALTO" },
  "75926": { state: "TX", city: "APPLE SPRINGS" },
  "75928": { state: "TX", city: "BON WIER" },
  "75929": { state: "TX", city: "BROADDUS" },
  "75930": { state: "TX", city: "BRONSON" },
  "75931": { state: "TX", city: "BROOKELAND" },
  "75932": { state: "TX", city: "BURKEVILLE" },
  "75933": { state: "TX", city: "CALL" },
  "75934": { state: "TX", city: "CAMDEN" },
  "75935": { state: "TX", city: "CENTER" },
  "75936": { state: "TX", city: "CHESTER" },
  "75937": { state: "TX", city: "CHIRENO" },
  "75938": { state: "TX", city: "COLMESNEIL" },
  "75939": { state: "TX", city: "CORRIGAN" },
  "75941": { state: "TX", city: "DIBOLL" },
  "75942": { state: "TX", city: "DOUCETTE" },
  "75943": { state: "TX", city: "DOUGLASS" },
  "75944": { state: "TX", city: "ETOILE" },
  "75946": { state: "TX", city: "GARRISON" },
  "75948": { state: "TX", city: "HEMPHILL" },
  "75949": { state: "TX", city: "HUNTINGTON" },
  "75951": { state: "TX", city: "JASPER" },
  "75954": { state: "TX", city: "JOAQUIN" },
  "75956": { state: "TX", city: "KIRBYVILLE" },
  "75958": { state: "TX", city: "MARTINSVILLE" },
  "75959": { state: "TX", city: "MILAM" },
  "75960": { state: "TX", city: "MOSCOW" },
  "75961": { state: "TX", city: "NACOGDOCHES" },
  "75962": { state: "TX", city: "NACOGDOCHES" },
  "75963": { state: "TX", city: "NACOGDOCHES" },
  "75964": { state: "TX", city: "NACOGDOCHES" },
  "75965": { state: "TX", city: "NACOGDOCHES" },
  "75966": { state: "TX", city: "NEWTON" },
  "75968": { state: "TX", city: "PINELAND" },
  "75969": { state: "TX", city: "POLLOK" },
  "75972": { state: "TX", city: "SAN AUGUSTINE" },
  "75973": { state: "TX", city: "SHELBYVILLE" },
  "75974": { state: "TX", city: "TENAHA" },
  "75975": { state: "TX", city: "TIMPSON" },
  "75976": { state: "TX", city: "WELLS" },
  "75977": { state: "TX", city: "WIERGATE" },
  "75978": { state: "TX", city: "WODEN" },
  "75979": { state: "TX", city: "WOODVILLE" },
  "75980": { state: "TX", city: "ZAVALLA" },
  "75990": { state: "TX", city: "WOODVILLE" },
  "76001": { state: "TX", city: "ARLINGTON" },
  "76002": { state: "TX", city: "ARLINGTON" },
  "76003": { state: "TX", city: "ARLINGTON" },
  "76004": { state: "TX", city: "ARLINGTON" },
  "76005": { state: "TX", city: "ARLINGTON" },
  "76006": { state: "TX", city: "ARLINGTON" },
  "76007": { state: "TX", city: "ARLINGTON" },
  "76008": { state: "TX", city: "ALEDO" },
  "76009": { state: "TX", city: "ALVARADO" },
  "76010": { state: "TX", city: "ARLINGTON" },
  "76011": { state: "TX", city: "ARLINGTON" },
  "76012": { state: "TX", city: "ARLINGTON" },
  "76013": { state: "TX", city: "ARLINGTON" },
  "76014": { state: "TX", city: "ARLINGTON" },
  "76015": { state: "TX", city: "ARLINGTON" },
  "76016": { state: "TX", city: "ARLINGTON" },
  "76017": { state: "TX", city: "ARLINGTON" },
  "76018": { state: "TX", city: "ARLINGTON" },
  "76019": { state: "TX", city: "ARLINGTON" },
  "76020": { state: "TX", city: "AZLE" },
  "76021": { state: "TX", city: "BEDFORD" },
  "76022": { state: "TX", city: "BEDFORD" },
  "76023": { state: "TX", city: "BOYD" },
  "76028": { state: "TX", city: "BURLESON" },
  "76031": { state: "TX", city: "CLEBURNE" },
  "76033": { state: "TX", city: "CLEBURNE" },
  "76034": { state: "TX", city: "COLLEYVILLE" },
  "76035": { state: "TX", city: "CRESSON" },
  "76036": { state: "TX", city: "CROWLEY" },
  "76039": { state: "TX", city: "EULESS" },
  "76040": { state: "TX", city: "EULESS" },
  "76041": { state: "TX", city: "FORRESTON" },
  "76043": { state: "TX", city: "GLEN ROSE" },
  "76044": { state: "TX", city: "GODLEY" },
  "76048": { state: "TX", city: "GRANBURY" },
  "76049": { state: "TX", city: "GRANBURY" },
  "76050": { state: "TX", city: "GRANDVIEW" },
  "76051": { state: "TX", city: "GRAPEVINE" },
  "76052": { state: "TX", city: "HASLET" },
  "76053": { state: "TX", city: "HURST" },
  "76054": { state: "TX", city: "HURST" },
  "76055": { state: "TX", city: "ITASCA" },
  "76058": { state: "TX", city: "JOSHUA" },
  "76059": { state: "TX", city: "KEENE" },
  "76060": { state: "TX", city: "KENNEDALE" },
  "76061": { state: "TX", city: "LILLIAN" },
  "76063": { state: "TX", city: "MANSFIELD" },
  "76064": { state: "TX", city: "MAYPEARL" },
  "76065": { state: "TX", city: "MIDLOTHIAN" },
  "76066": { state: "TX", city: "MILLSAP" },
  "76067": { state: "TX", city: "MINERAL WELLS" },
  "76068": { state: "TX", city: "MINERAL WELLS" },
  "76070": { state: "TX", city: "NEMO" },
  "76071": { state: "TX", city: "NEWARK" },
  "76073": { state: "TX", city: "PARADISE" },
  "76077": { state: "TX", city: "RAINBOW" },
  "76078": { state: "TX", city: "RHOME" },
  "76082": { state: "TX", city: "SPRINGTOWN" },
  "76084": { state: "TX", city: "VENUS" },
  "76085": { state: "TX", city: "WEATHERFORD" },
  "76086": { state: "TX", city: "WEATHERFORD" },
  "76087": { state: "TX", city: "WEATHERFORD" },
  "76088": { state: "TX", city: "WEATHERFORD" },
  "76092": { state: "TX", city: "SOUTHLAKE" },
  "76093": { state: "TX", city: "RIO VISTA" },
  "76094": { state: "TX", city: "ARLINGTON" },
  "76095": { state: "TX", city: "BEDFORD" },
  "76096": { state: "TX", city: "ARLINGTON" },
  "76097": { state: "TX", city: "BURLESON" },
  "76098": { state: "TX", city: "AZLE" },
  "76099": { state: "TX", city: "GRAPEVINE" },
  "76101": { state: "TX", city: "FORT WORTH" },
  "76102": { state: "TX", city: "FORT WORTH" },
  "76103": { state: "TX", city: "FORT WORTH" },
  "76104": { state: "TX", city: "FORT WORTH" },
  "76105": { state: "TX", city: "FORT WORTH" },
  "76106": { state: "TX", city: "FORT WORTH" },
  "76107": { state: "TX", city: "FORT WORTH" },
  "76108": { state: "TX", city: "FORT WORTH" },
  "76109": { state: "TX", city: "FORT WORTH" },
  "76110": { state: "TX", city: "FORT WORTH" },
  "76111": { state: "TX", city: "FORT WORTH" },
  "76112": { state: "TX", city: "FORT WORTH" },
  "76113": { state: "TX", city: "FORT WORTH" },
  "76114": { state: "TX", city: "FORT WORTH" },
  "76115": { state: "TX", city: "FORT WORTH" },
  "76116": { state: "TX", city: "FORT WORTH" },
  "76117": { state: "TX", city: "HALTOM CITY" },
  "76118": { state: "TX", city: "FORT WORTH" },
  "76119": { state: "TX", city: "FORT WORTH" },
  "76120": { state: "TX", city: "FORT WORTH" },
  "76121": { state: "TX", city: "FORT WORTH" },
  "76122": { state: "TX", city: "FORT WORTH" },
  "76123": { state: "TX", city: "FORT WORTH" },
  "76124": { state: "TX", city: "FORT WORTH" },
  "76126": { state: "TX", city: "FORT WORTH" },
  "76127": { state: "TX", city: "NAVAL AIR STATION JRB" },
  "76129": { state: "TX", city: "FORT WORTH" },
  "76130": { state: "TX", city: "FORT WORTH" },
  "76131": { state: "TX", city: "FORT WORTH" },
  "76132": { state: "TX", city: "FORT WORTH" },
  "76133": { state: "TX", city: "FORT WORTH" },
  "76134": { state: "TX", city: "FORT WORTH" },
  "76135": { state: "TX", city: "FORT WORTH" },
  "76136": { state: "TX", city: "FORT WORTH" },
  "76137": { state: "TX", city: "FORT WORTH" },
  "76140": { state: "TX", city: "FORT WORTH" },
  "76147": { state: "TX", city: "FORT WORTH" },
  "76148": { state: "TX", city: "FORT WORTH" },
  "76150": { state: "TX", city: "FORT WORTH" },
  "76155": { state: "TX", city: "FORT WORTH" },
  "76161": { state: "TX", city: "FORT WORTH" },
  "76162": { state: "TX", city: "FORT WORTH" },
  "76163": { state: "TX", city: "FORT WORTH" },
  "76164": { state: "TX", city: "FORT WORTH" },
  "76166": { state: "TX", city: "FORT WORTH" },
  "76177": { state: "TX", city: "FORT WORTH" },
  "76179": { state: "TX", city: "FORT WORTH" },
  "76180": { state: "TX", city: "NORTH RICHLAND HILLS" },
  "76181": { state: "TX", city: "FORT WORTH" },
  "76182": { state: "TX", city: "NORTH RICHLAND HILLS" },
  "76185": { state: "TX", city: "FORT WORTH" },
  "76190": { state: "TX", city: "FORT WORTH" },
  "76191": { state: "TX", city: "FORT WORTH" },
  "76192": { state: "TX", city: "FORT WORTH" },
  "76193": { state: "TX", city: "FORT WORTH" },
  "76195": { state: "TX", city: "FORT WORTH" },
  "76196": { state: "TX", city: "FORT WORTH" },
  "76197": { state: "TX", city: "FORT WORTH" },
  "76198": { state: "TX", city: "FORT WORTH" },
  "76199": { state: "TX", city: "FORT WORTH" },
  "76201": { state: "TX", city: "DENTON" },
  "76202": { state: "TX", city: "DENTON" },
  "76203": { state: "TX", city: "DENTON" },
  "76204": { state: "TX", city: "DENTON" },
  "76205": { state: "TX", city: "DENTON" },
  "76206": { state: "TX", city: "DENTON" },
  "76207": { state: "TX", city: "DENTON" },
  "76208": { state: "TX", city: "DENTON" },
  "76209": { state: "TX", city: "DENTON" },
  "76210": { state: "TX", city: "DENTON" },
  "76225": { state: "TX", city: "ALVORD" },
  "76226": { state: "TX", city: "ARGYLE" },
  "76227": { state: "TX", city: "AUBREY" },
  "76228": { state: "TX", city: "BELLEVUE" },
  "76230": { state: "TX", city: "BOWIE" },
  "76233": { state: "TX", city: "COLLINSVILLE" },
  "76234": { state: "TX", city: "DECATUR" },
  "76238": { state: "TX", city: "ERA" },
  "76239": { state: "TX", city: "FORESTBURG" },
  "76240": { state: "TX", city: "GAINESVILLE" },
  "76241": { state: "TX", city: "GAINESVILLE" },
  "76244": { state: "TX", city: "KELLER" },
  "76245": { state: "TX", city: "GORDONVILLE" },
  "76246": { state: "TX", city: "GREENWOOD" },
  "76247": { state: "TX", city: "JUSTIN" },
  "76248": { state: "TX", city: "KELLER" },
  "76249": { state: "TX", city: "KRUM" },
  "76250": { state: "TX", city: "LINDSAY" },
  "76251": { state: "TX", city: "MONTAGUE" },
  "76252": { state: "TX", city: "MUENSTER" },
  "76253": { state: "TX", city: "MYRA" },
  "76255": { state: "TX", city: "NOCONA" },
  "76258": { state: "TX", city: "PILOT POINT" },
  "76259": { state: "TX", city: "PONDER" },
  "76261": { state: "TX", city: "RINGGOLD" },
  "76262": { state: "TX", city: "ROANOKE" },
  "76263": { state: "TX", city: "ROSSTON" },
  "76264": { state: "TX", city: "SADLER" },
  "76265": { state: "TX", city: "SAINT JO" },
  "76266": { state: "TX", city: "SANGER" },
  "76267": { state: "TX", city: "SLIDELL" },
  "76268": { state: "TX", city: "SOUTHMAYD" },
  "76270": { state: "TX", city: "SUNSET" },
  "76271": { state: "TX", city: "TIOGA" },
  "76272": { state: "TX", city: "VALLEY VIEW" },
  "76273": { state: "TX", city: "WHITESBORO" },
  "76301": { state: "TX", city: "WICHITA FALLS" },
  "76302": { state: "TX", city: "WICHITA FALLS" },
  "76305": { state: "TX", city: "WICHITA FALLS" },
  "76306": { state: "TX", city: "WICHITA FALLS" },
  "76307": { state: "TX", city: "WICHITA FALLS" },
  "76308": { state: "TX", city: "WICHITA FALLS" },
  "76309": { state: "TX", city: "WICHITA FALLS" },
  "76310": { state: "TX", city: "WICHITA FALLS" },
  "76311": { state: "TX", city: "SHEPPARD AFB" },
  "76351": { state: "TX", city: "ARCHER CITY" },
  "76352": { state: "TX", city: "BLUEGROVE" },
  "76354": { state: "TX", city: "BURKBURNETT" },
  "76357": { state: "TX", city: "BYERS" },
  "76360": { state: "TX", city: "ELECTRA" },
  "76363": { state: "TX", city: "GOREE" },
  "76364": { state: "TX", city: "HARROLD" },
  "76365": { state: "TX", city: "HENRIETTA" },
  "76366": { state: "TX", city: "HOLLIDAY" },
  "76367": { state: "TX", city: "IOWA PARK" },
  "76369": { state: "TX", city: "KAMAY" },
  "76370": { state: "TX", city: "MEGARGEL" },
  "76371": { state: "TX", city: "MUNDAY" },
  "76372": { state: "TX", city: "NEWCASTLE" },
  "76373": { state: "TX", city: "OKLAUNION" },
  "76374": { state: "TX", city: "OLNEY" },
  "76377": { state: "TX", city: "PETROLIA" },
  "76379": { state: "TX", city: "SCOTLAND" },
  "76380": { state: "TX", city: "SEYMOUR" },
  "76384": { state: "TX", city: "VERNON" },
  "76385": { state: "TX", city: "VERNON" },
  "76388": { state: "TX", city: "WEINERT" },
  "76389": { state: "TX", city: "WINDTHORST" },
  "76401": { state: "TX", city: "STEPHENVILLE" },
  "76402": { state: "TX", city: "STEPHENVILLE" },
  "76424": { state: "TX", city: "BRECKENRIDGE" },
  "76426": { state: "TX", city: "BRIDGEPORT" },
  "76427": { state: "TX", city: "BRYSON" },
  "76429": { state: "TX", city: "CADDO" },
  "76430": { state: "TX", city: "ALBANY" },
  "76431": { state: "TX", city: "CHICO" },
  "76432": { state: "TX", city: "BLANKET" },
  "76433": { state: "TX", city: "BLUFF DALE" },
  "76435": { state: "TX", city: "CARBON" },
  "76436": { state: "TX", city: "CARLTON" },
  "76437": { state: "TX", city: "CISCO" },
  "76439": { state: "TX", city: "DENNIS" },
  "76442": { state: "TX", city: "COMANCHE" },
  "76443": { state: "TX", city: "CROSS PLAINS" },
  "76444": { state: "TX", city: "DE LEON" },
  "76445": { state: "TX", city: "DESDEMONA" },
  "76446": { state: "TX", city: "DUBLIN" },
  "76448": { state: "TX", city: "EASTLAND" },
  "76449": { state: "TX", city: "GRAFORD" },
  "76450": { state: "TX", city: "GRAHAM" },
  "76452": { state: "TX", city: "ENERGY" },
  "76453": { state: "TX", city: "GORDON" },
  "76454": { state: "TX", city: "GORMAN" },
  "76455": { state: "TX", city: "GUSTINE" },
  "76457": { state: "TX", city: "HICO" },
  "76458": { state: "TX", city: "JACKSBORO" },
  "76459": { state: "TX", city: "JERMYN" },
  "76460": { state: "TX", city: "LOVING" },
  "76461": { state: "TX", city: "LINGLEVILLE" },
  "76462": { state: "TX", city: "LIPAN" },
  "76463": { state: "TX", city: "MINGUS" },
  "76464": { state: "TX", city: "MORAN" },
  "76465": { state: "TX", city: "MORGAN MILL" },
  "76466": { state: "TX", city: "OLDEN" },
  "76467": { state: "TX", city: "PALUXY" },
  "76468": { state: "TX", city: "PROCTOR" },
  "76469": { state: "TX", city: "PUTNAM" },
  "76470": { state: "TX", city: "RANGER" },
  "76471": { state: "TX", city: "RISING STAR" },
  "76472": { state: "TX", city: "SANTO" },
  "76474": { state: "TX", city: "SIDNEY" },
  "76475": { state: "TX", city: "STRAWN" },
  "76476": { state: "TX", city: "TOLAR" },
  "76481": { state: "TX", city: "SOUTH BEND" },
  "76483": { state: "TX", city: "THROCKMORTON" },
  "76484": { state: "TX", city: "PALO PINTO" },
  "76485": { state: "TX", city: "PEASTER" },
  "76486": { state: "TX", city: "PERRIN" },
  "76487": { state: "TX", city: "POOLVILLE" },
  "76490": { state: "TX", city: "WHITT" },
  "76491": { state: "TX", city: "WOODSON" },
  "76501": { state: "TX", city: "TEMPLE" },
  "76502": { state: "TX", city: "TEMPLE" },
  "76503": { state: "TX", city: "TEMPLE" },
  "76504": { state: "TX", city: "TEMPLE" },
  "76508": { state: "TX", city: "TEMPLE" },
  "76511": { state: "TX", city: "BARTLETT" },
  "76513": { state: "TX", city: "BELTON" },
  "76518": { state: "TX", city: "BUCKHOLTS" },
  "76519": { state: "TX", city: "BURLINGTON" },
  "76520": { state: "TX", city: "CAMERON" },
  "76522": { state: "TX", city: "COPPERAS COVE" },
  "76523": { state: "TX", city: "DAVILLA" },
  "76524": { state: "TX", city: "EDDY" },
  "76525": { state: "TX", city: "EVANT" },
  "76526": { state: "TX", city: "FLAT" },
  "76527": { state: "TX", city: "FLORENCE" },
  "76528": { state: "TX", city: "GATESVILLE" },
  "76530": { state: "TX", city: "GRANGER" },
  "76531": { state: "TX", city: "HAMILTON" },
  "76533": { state: "TX", city: "HEIDENHEIMER" },
  "76534": { state: "TX", city: "HOLLAND" },
  "76537": { state: "TX", city: "JARRELL" },
  "76538": { state: "TX", city: "JONESBORO" },
  "76539": { state: "TX", city: "KEMPNER" },
  "76540": { state: "TX", city: "KILLEEN" },
  "76541": { state: "TX", city: "KILLEEN" },
  "76542": { state: "TX", city: "KILLEEN" },
  "76543": { state: "TX", city: "KILLEEN" },
  "76544": { state: "TX", city: "FORT CAVAZOS" },
  "76547": { state: "TX", city: "KILLEEN" },
  "76548": { state: "TX", city: "HARKER HEIGHTS" },
  "76549": { state: "TX", city: "KILLEEN" },
  "76550": { state: "TX", city: "LAMPASAS" },
  "76554": { state: "TX", city: "LITTLE RIVER ACADEMY" },
  "76556": { state: "TX", city: "MILANO" },
  "76557": { state: "TX", city: "MOODY" },
  "76558": { state: "TX", city: "MOUND" },
  "76559": { state: "TX", city: "NOLANVILLE" },
  "76561": { state: "TX", city: "OGLESBY" },
  "76564": { state: "TX", city: "PENDLETON" },
  "76565": { state: "TX", city: "POTTSVILLE" },
  "76566": { state: "TX", city: "PURMELA" },
  "76567": { state: "TX", city: "ROCKDALE" },
  "76569": { state: "TX", city: "ROGERS" },
  "76570": { state: "TX", city: "ROSEBUD" },
  "76571": { state: "TX", city: "SALADO" },
  "76573": { state: "TX", city: "SCHWERTNER" },
  "76574": { state: "TX", city: "TAYLOR" },
  "76577": { state: "TX", city: "THORNDALE" },
  "76578": { state: "TX", city: "THRALL" },
  "76579": { state: "TX", city: "TROY" },
  "76596": { state: "TX", city: "GATESVILLE" },
  "76597": { state: "TX", city: "GATESVILLE" },
  "76598": { state: "TX", city: "GATESVILLE" },
  "76599": { state: "TX", city: "GATESVILLE" },
  "76621": { state: "TX", city: "ABBOTT" },
  "76622": { state: "TX", city: "AQUILLA" },
  "76623": { state: "TX", city: "AVALON" },
  "76624": { state: "TX", city: "AXTELL" },
  "76626": { state: "TX", city: "BLOOMING GROVE" },
  "76627": { state: "TX", city: "BLUM" },
  "76628": { state: "TX", city: "BRANDON" },
  "76629": { state: "TX", city: "BREMOND" },
  "76630": { state: "TX", city: "BRUCEVILLE" },
  "76631": { state: "TX", city: "BYNUM" },
  "76632": { state: "TX", city: "CHILTON" },
  "76633": { state: "TX", city: "CHINA SPRING" },
  "76634": { state: "TX", city: "CLIFTON" },
  "76635": { state: "TX", city: "COOLIDGE" },
  "76636": { state: "TX", city: "COVINGTON" },
  "76637": { state: "TX", city: "CRANFILLS GAP" },
  "76638": { state: "TX", city: "CRAWFORD" },
  "76639": { state: "TX", city: "DAWSON" },
  "76640": { state: "TX", city: "ELM MOTT" },
  "76641": { state: "TX", city: "FROST" },
  "76642": { state: "TX", city: "GROESBECK" },
  "76643": { state: "TX", city: "HEWITT" },
  "76644": { state: "TX", city: "LAGUNA PARK" },
  "76645": { state: "TX", city: "HILLSBORO" },
  "76648": { state: "TX", city: "HUBBARD" },
  "76649": { state: "TX", city: "IREDELL" },
  "76650": { state: "TX", city: "IRENE" },
  "76651": { state: "TX", city: "ITALY" },
  "76652": { state: "TX", city: "KOPPERL" },
  "76653": { state: "TX", city: "KOSSE" },
  "76654": { state: "TX", city: "LEROY" },
  "76655": { state: "TX", city: "LORENA" },
  "76656": { state: "TX", city: "LOTT" },
  "76657": { state: "TX", city: "MCGREGOR" },
  "76660": { state: "TX", city: "MALONE" },
  "76661": { state: "TX", city: "MARLIN" },
  "76664": { state: "TX", city: "MART" },
  "76665": { state: "TX", city: "MERIDIAN" },
  "76666": { state: "TX", city: "MERTENS" },
  "76667": { state: "TX", city: "MEXIA" },
  "76670": { state: "TX", city: "MILFORD" },
  "76671": { state: "TX", city: "MORGAN" },
  "76673": { state: "TX", city: "MOUNT CALM" },
  "76676": { state: "TX", city: "PENELOPE" },
  "76678": { state: "TX", city: "PRAIRIE HILL" },
  "76679": { state: "TX", city: "PURDON" },
  "76680": { state: "TX", city: "REAGAN" },
  "76681": { state: "TX", city: "RICHLAND" },
  "76682": { state: "TX", city: "RIESEL" },
  "76684": { state: "TX", city: "ROSS" },
  "76685": { state: "TX", city: "SATIN" },
  "76686": { state: "TX", city: "TEHUACANA" },
  "76687": { state: "TX", city: "THORNTON" },
  "76689": { state: "TX", city: "VALLEY MILLS" },
  "76690": { state: "TX", city: "WALNUT SPRINGS" },
  "76691": { state: "TX", city: "WEST" },
  "76692": { state: "TX", city: "WHITNEY" },
  "76693": { state: "TX", city: "WORTHAM" },
  "76701": { state: "TX", city: "WACO" },
  "76702": { state: "TX", city: "WACO" },
  "76703": { state: "TX", city: "WACO" },
  "76704": { state: "TX", city: "WACO" },
  "76705": { state: "TX", city: "WACO" },
  "76706": { state: "TX", city: "WACO" },
  "76707": { state: "TX", city: "WACO" },
  "76708": { state: "TX", city: "WACO" },
  "76710": { state: "TX", city: "WACO" },
  "76711": { state: "TX", city: "WACO" },
  "76712": { state: "TX", city: "WOODWAY" },
  "76714": { state: "TX", city: "WACO" },
  "76715": { state: "TX", city: "WACO" },
  "76716": { state: "TX", city: "WACO" },
  "76797": { state: "TX", city: "WACO" },
  "76798": { state: "TX", city: "WACO" },
  "76799": { state: "TX", city: "WACO" },
  "76801": { state: "TX", city: "BROWNWOOD" },
  "76802": { state: "TX", city: "EARLY" },
  "76803": { state: "TX", city: "EARLY" },
  "76804": { state: "TX", city: "BROWNWOOD" },
  "76820": { state: "TX", city: "ART" },
  "76821": { state: "TX", city: "BALLINGER" },
  "76823": { state: "TX", city: "BANGS" },
  "76824": { state: "TX", city: "BEND" },
  "76825": { state: "TX", city: "BRADY" },
  "76827": { state: "TX", city: "BROOKESMITH" },
  "76828": { state: "TX", city: "BURKETT" },
  "76831": { state: "TX", city: "CASTELL" },
  "76832": { state: "TX", city: "CHEROKEE" },
  "76834": { state: "TX", city: "COLEMAN" },
  "76836": { state: "TX", city: "DOOLE" },
  "76837": { state: "TX", city: "EDEN" },
  "76841": { state: "TX", city: "FORT MC KAVETT" },
  "76842": { state: "TX", city: "FREDONIA" },
  "76844": { state: "TX", city: "GOLDTHWAITE" },
  "76845": { state: "TX", city: "GOULDBUSK" },
  "76848": { state: "TX", city: "HEXT" },
  "76849": { state: "TX", city: "JUNCTION" },
  "76852": { state: "TX", city: "LOHN" },
  "76853": { state: "TX", city: "LOMETA" },
  "76854": { state: "TX", city: "LONDON" },
  "76855": { state: "TX", city: "LOWAKE" },
  "76856": { state: "TX", city: "MASON" },
  "76857": { state: "TX", city: "MAY" },
  "76858": { state: "TX", city: "MELVIN" },
  "76859": { state: "TX", city: "MENARD" },
  "76861": { state: "TX", city: "MILES" },
  "76862": { state: "TX", city: "MILLERSVIEW" },
  "76864": { state: "TX", city: "MULLIN" },
  "76865": { state: "TX", city: "NORTON" },
  "76866": { state: "TX", city: "PAINT ROCK" },
  "76869": { state: "TX", city: "PONTOTOC" },
  "76870": { state: "TX", city: "PRIDDY" },
  "76871": { state: "TX", city: "RICHLAND SPRINGS" },
  "76872": { state: "TX", city: "ROCHELLE" },
  "76873": { state: "TX", city: "ROCKWOOD" },
  "76874": { state: "TX", city: "ROOSEVELT" },
  "76875": { state: "TX", city: "ROWENA" },
  "76877": { state: "TX", city: "SAN SABA" },
  "76878": { state: "TX", city: "SANTA ANNA" },
  "76880": { state: "TX", city: "STAR" },
  "76882": { state: "TX", city: "TALPA" },
  "76883": { state: "TX", city: "TELEGRAPH" },
  "76884": { state: "TX", city: "VALERA" },
  "76885": { state: "TX", city: "VALLEY SPRING" },
  "76886": { state: "TX", city: "VERIBEST" },
  "76887": { state: "TX", city: "VOCA" },
  "76888": { state: "TX", city: "VOSS" },
  "76890": { state: "TX", city: "ZEPHYR" },
  "76901": { state: "TX", city: "SAN ANGELO" },
  "76902": { state: "TX", city: "SAN ANGELO" },
  "76903": { state: "TX", city: "SAN ANGELO" },
  "76904": { state: "TX", city: "SAN ANGELO" },
  "76905": { state: "TX", city: "SAN ANGELO" },
  "76906": { state: "TX", city: "SAN ANGELO" },
  "76908": { state: "TX", city: "GOODFELLOW AFB" },
  "76909": { state: "TX", city: "SAN ANGELO" },
  "76930": { state: "TX", city: "BARNHART" },
  "76932": { state: "TX", city: "BIG LAKE" },
  "76933": { state: "TX", city: "BRONTE" },
  "76934": { state: "TX", city: "CARLSBAD" },
  "76935": { state: "TX", city: "CHRISTOVAL" },
  "76936": { state: "TX", city: "ELDORADO" },
  "76937": { state: "TX", city: "EOLA" },
  "76939": { state: "TX", city: "KNICKERBOCKER" },
  "76940": { state: "TX", city: "MERETA" },
  "76941": { state: "TX", city: "MERTZON" },
  "76943": { state: "TX", city: "OZONA" },
  "76945": { state: "TX", city: "ROBERT LEE" },
  "76949": { state: "TX", city: "SILVER" },
  "76950": { state: "TX", city: "SONORA" },
  "76951": { state: "TX", city: "STERLING CITY" },
  "76953": { state: "TX", city: "TENNYSON" },
  "76955": { state: "TX", city: "VANCOURT" },
  "76957": { state: "TX", city: "WALL" },
  "76958": { state: "TX", city: "WATER VALLEY" },
  "77001": { state: "TX", city: "HOUSTON" },
  "77002": { state: "TX", city: "HOUSTON" },
  "77003": { state: "TX", city: "HOUSTON" },
  "77004": { state: "TX", city: "HOUSTON" },
  "77005": { state: "TX", city: "HOUSTON" },
  "77006": { state: "TX", city: "HOUSTON" },
  "77007": { state: "TX", city: "HOUSTON" },
  "77008": { state: "TX", city: "HOUSTON" },
  "77009": { state: "TX", city: "HOUSTON" },
  "77010": { state: "TX", city: "HOUSTON" },
  "77011": { state: "TX", city: "HOUSTON" },
  "77012": { state: "TX", city: "HOUSTON" },
  "77013": { state: "TX", city: "HOUSTON" },
  "77014": { state: "TX", city: "HOUSTON" },
  "77015": { state: "TX", city: "HOUSTON" },
  "77016": { state: "TX", city: "HOUSTON" },
  "77017": { state: "TX", city: "HOUSTON" },
  "77018": { state: "TX", city: "HOUSTON" },
  "77019": { state: "TX", city: "HOUSTON" },
  "77020": { state: "TX", city: "HOUSTON" },
  "77021": { state: "TX", city: "HOUSTON" },
  "77022": { state: "TX", city: "HOUSTON" },
  "77023": { state: "TX", city: "HOUSTON" },
  "77024": { state: "TX", city: "HOUSTON" },
  "77025": { state: "TX", city: "HOUSTON" },
  "77026": { state: "TX", city: "HOUSTON" },
  "77027": { state: "TX", city: "HOUSTON" },
  "77028": { state: "TX", city: "HOUSTON" },
  "77029": { state: "TX", city: "HOUSTON" },
  "77030": { state: "TX", city: "HOUSTON" },
  "77031": { state: "TX", city: "HOUSTON" },
  "77032": { state: "TX", city: "HOUSTON" },
  "77033": { state: "TX", city: "HOUSTON" },
  "77034": { state: "TX", city: "HOUSTON" },
  "77035": { state: "TX", city: "HOUSTON" },
  "77036": { state: "TX", city: "HOUSTON" },
  "77037": { state: "TX", city: "HOUSTON" },
  "77038": { state: "TX", city: "HOUSTON" },
  "77039": { state: "TX", city: "HOUSTON" },
  "77040": { state: "TX", city: "HOUSTON" },
  "77041": { state: "TX", city: "HOUSTON" },
  "77042": { state: "TX", city: "HOUSTON" },
  "77043": { state: "TX", city: "HOUSTON" },
  "77044": { state: "TX", city: "HOUSTON" },
  "77045": { state: "TX", city: "HOUSTON" },
  "77046": { state: "TX", city: "HOUSTON" },
  "77047": { state: "TX", city: "HOUSTON" },
  "77048": { state: "TX", city: "HOUSTON" },
  "77049": { state: "TX", city: "HOUSTON" },
  "77050": { state: "TX", city: "HOUSTON" },
  "77051": { state: "TX", city: "HOUSTON" },
  "77052": { state: "TX", city: "HOUSTON" },
  "77053": { state: "TX", city: "HOUSTON" },
  "77054": { state: "TX", city: "HOUSTON" },
  "77055": { state: "TX", city: "HOUSTON" },
  "77056": { state: "TX", city: "HOUSTON" },
  "77057": { state: "TX", city: "HOUSTON" },
  "77058": { state: "TX", city: "HOUSTON" },
  "77059": { state: "TX", city: "HOUSTON" },
  "77060": { state: "TX", city: "HOUSTON" },
  "77061": { state: "TX", city: "HOUSTON" },
  "77062": { state: "TX", city: "HOUSTON" },
  "77063": { state: "TX", city: "HOUSTON" },
  "77064": { state: "TX", city: "HOUSTON" },
  "77065": { state: "TX", city: "HOUSTON" },
  "77066": { state: "TX", city: "HOUSTON" },
  "77067": { state: "TX", city: "HOUSTON" },
  "77068": { state: "TX", city: "HOUSTON" },
  "77069": { state: "TX", city: "HOUSTON" },
  "77070": { state: "TX", city: "HOUSTON" },
  "77071": { state: "TX", city: "HOUSTON" },
  "77072": { state: "TX", city: "HOUSTON" },
  "77073": { state: "TX", city: "HOUSTON" },
  "77074": { state: "TX", city: "HOUSTON" },
  "77075": { state: "TX", city: "HOUSTON" },
  "77076": { state: "TX", city: "HOUSTON" },
  "77077": { state: "TX", city: "HOUSTON" },
  "77078": { state: "TX", city: "HOUSTON" },
  "77079": { state: "TX", city: "HOUSTON" },
  "77080": { state: "TX", city: "HOUSTON" },
  "77081": { state: "TX", city: "HOUSTON" },
  "77082": { state: "TX", city: "HOUSTON" },
  "77083": { state: "TX", city: "HOUSTON" },
  "77084": { state: "TX", city: "HOUSTON" },
  "77085": { state: "TX", city: "HOUSTON" },
  "77086": { state: "TX", city: "HOUSTON" },
  "77087": { state: "TX", city: "HOUSTON" },
  "77088": { state: "TX", city: "HOUSTON" },
  "77089": { state: "TX", city: "HOUSTON" },
  "77090": { state: "TX", city: "HOUSTON" },
  "77091": { state: "TX", city: "HOUSTON" },
  "77092": { state: "TX", city: "HOUSTON" },
  "77093": { state: "TX", city: "HOUSTON" },
  "77094": { state: "TX", city: "HOUSTON" },
  "77095": { state: "TX", city: "HOUSTON" },
  "77096": { state: "TX", city: "HOUSTON" },
  "77098": { state: "TX", city: "HOUSTON" },
  "77099": { state: "TX", city: "HOUSTON" },
  "77201": { state: "TX", city: "HOUSTON" },
  "77202": { state: "TX", city: "HOUSTON" },
  "77203": { state: "TX", city: "HOUSTON" },
  "77204": { state: "TX", city: "HOUSTON" },
  "77205": { state: "TX", city: "HOUSTON" },
  "77206": { state: "TX", city: "HOUSTON" },
  "77207": { state: "TX", city: "HOUSTON" },
  "77208": { state: "TX", city: "HOUSTON" },
  "77209": { state: "TX", city: "HOUSTON" },
  "77210": { state: "TX", city: "HOUSTON" },
  "77212": { state: "TX", city: "HOUSTON" },
  "77213": { state: "TX", city: "HOUSTON" },
  "77215": { state: "TX", city: "HOUSTON" },
  "77216": { state: "TX", city: "HOUSTON" },
  "77217": { state: "TX", city: "HOUSTON" },
  "77218": { state: "TX", city: "HOUSTON" },
  "77219": { state: "TX", city: "HOUSTON" },
  "77220": { state: "TX", city: "HOUSTON" },
  "77221": { state: "TX", city: "HOUSTON" },
  "77222": { state: "TX", city: "HOUSTON" },
  "77223": { state: "TX", city: "HOUSTON" },
  "77224": { state: "TX", city: "HOUSTON" },
  "77225": { state: "TX", city: "HOUSTON" },
  "77226": { state: "TX", city: "HOUSTON" },
  "77227": { state: "TX", city: "HOUSTON" },
  "77228": { state: "TX", city: "HOUSTON" },
  "77229": { state: "TX", city: "HOUSTON" },
  "77230": { state: "TX", city: "HOUSTON" },
  "77231": { state: "TX", city: "HOUSTON" },
  "77233": { state: "TX", city: "HOUSTON" },
  "77234": { state: "TX", city: "HOUSTON" },
  "77235": { state: "TX", city: "HOUSTON" },
  "77236": { state: "TX", city: "HOUSTON" },
  "77237": { state: "TX", city: "HOUSTON" },
  "77238": { state: "TX", city: "HOUSTON" },
  "77240": { state: "TX", city: "HOUSTON" },
  "77241": { state: "TX", city: "HOUSTON" },
  "77242": { state: "TX", city: "HOUSTON" },
  "77243": { state: "TX", city: "HOUSTON" },
  "77244": { state: "TX", city: "HOUSTON" },
  "77245": { state: "TX", city: "HOUSTON" },
  "77248": { state: "TX", city: "HOUSTON" },
  "77249": { state: "TX", city: "HOUSTON" },
  "77251": { state: "TX", city: "HOUSTON" },
  "77252": { state: "TX", city: "HOUSTON" },
  "77253": { state: "TX", city: "HOUSTON" },
  "77254": { state: "TX", city: "HOUSTON" },
  "77255": { state: "TX", city: "HOUSTON" },
  "77256": { state: "TX", city: "HOUSTON" },
  "77257": { state: "TX", city: "HOUSTON" },
  "77258": { state: "TX", city: "HOUSTON" },
  "77259": { state: "TX", city: "HOUSTON" },
  "77261": { state: "TX", city: "HOUSTON" },
  "77262": { state: "TX", city: "HOUSTON" },
  "77263": { state: "TX", city: "HOUSTON" },
  "77265": { state: "TX", city: "HOUSTON" },
  "77266": { state: "TX", city: "HOUSTON" },
  "77267": { state: "TX", city: "HOUSTON" },
  "77268": { state: "TX", city: "HOUSTON" },
  "77269": { state: "TX", city: "HOUSTON" },
  "77270": { state: "TX", city: "HOUSTON" },
  "77271": { state: "TX", city: "HOUSTON" },
  "77272": { state: "TX", city: "HOUSTON" },
  "77273": { state: "TX", city: "HOUSTON" },
  "77274": { state: "TX", city: "HOUSTON" },
  "77275": { state: "TX", city: "HOUSTON" },
  "77277": { state: "TX", city: "HOUSTON" },
  "77279": { state: "TX", city: "HOUSTON" },
  "77280": { state: "TX", city: "HOUSTON" },
  "77282": { state: "TX", city: "HOUSTON" },
  "77284": { state: "TX", city: "HOUSTON" },
  "77287": { state: "TX", city: "HOUSTON" },
  "77288": { state: "TX", city: "HOUSTON" },
  "77289": { state: "TX", city: "HOUSTON" },
  "77290": { state: "TX", city: "HOUSTON" },
  "77291": { state: "TX", city: "HOUSTON" },
  "77292": { state: "TX", city: "HOUSTON" },
  "77293": { state: "TX", city: "HOUSTON" },
  "77297": { state: "TX", city: "HOUSTON" },
  "77299": { state: "TX", city: "HOUSTON" },
  "77301": { state: "TX", city: "CONROE" },
  "77302": { state: "TX", city: "CONROE" },
  "77303": { state: "TX", city: "CONROE" },
  "77304": { state: "TX", city: "CONROE" },
  "77305": { state: "TX", city: "CONROE" },
  "77306": { state: "TX", city: "CONROE" },
  "77315": { state: "TX", city: "NORTH HOUSTON" },
  "77316": { state: "TX", city: "MONTGOMERY" },
  "77318": { state: "TX", city: "WILLIS" },
  "77320": { state: "TX", city: "HUNTSVILLE" },
  "77325": { state: "TX", city: "KINGWOOD" },
  "77326": { state: "TX", city: "ACE" },
  "77327": { state: "TX", city: "CLEVELAND" },
  "77328": { state: "TX", city: "CLEVELAND" },
  "77331": { state: "TX", city: "COLDSPRING" },
  "77332": { state: "TX", city: "DALLARDSVILLE" },
  "77333": { state: "TX", city: "DOBBIN" },
  "77334": { state: "TX", city: "DODGE" },
  "77335": { state: "TX", city: "GOODRICH" },
  "77336": { state: "TX", city: "HUFFMAN" },
  "77337": { state: "TX", city: "HUFSMITH" },
  "77338": { state: "TX", city: "HUMBLE" },
  "77339": { state: "TX", city: "KINGWOOD" },
  "77340": { state: "TX", city: "HUNTSVILLE" },
  "77341": { state: "TX", city: "HUNTSVILLE" },
  "77342": { state: "TX", city: "HUNTSVILLE" },
  "77343": { state: "TX", city: "HUNTSVILLE" },
  "77344": { state: "TX", city: "HUNTSVILLE" },
  "77345": { state: "TX", city: "KINGWOOD" },
  "77346": { state: "TX", city: "HUMBLE" },
  "77347": { state: "TX", city: "HUMBLE" },
  "77348": { state: "TX", city: "HUNTSVILLE" },
  "77349": { state: "TX", city: "HUNTSVILLE" },
  "77350": { state: "TX", city: "LEGGETT" },
  "77351": { state: "TX", city: "LIVINGSTON" },
  "77353": { state: "TX", city: "MAGNOLIA" },
  "77354": { state: "TX", city: "MAGNOLIA" },
  "77355": { state: "TX", city: "MAGNOLIA" },
  "77356": { state: "TX", city: "MONTGOMERY" },
  "77357": { state: "TX", city: "NEW CANEY" },
  "77358": { state: "TX", city: "NEW WAVERLY" },
  "77359": { state: "TX", city: "OAKHURST" },
  "77360": { state: "TX", city: "ONALASKA" },
  "77362": { state: "TX", city: "PINEHURST" },
  "77363": { state: "TX", city: "PLANTERSVILLE" },
  "77364": { state: "TX", city: "POINTBLANK" },
  "77365": { state: "TX", city: "PORTER" },
  "77367": { state: "TX", city: "RIVERSIDE" },
  "77368": { state: "TX", city: "ROMAYOR" },
  "77369": { state: "TX", city: "RYE" },
  "77371": { state: "TX", city: "SHEPHERD" },
  "77372": { state: "TX", city: "SPLENDORA" },
  "77373": { state: "TX", city: "SPRING" },
  "77374": { state: "TX", city: "THICKET" },
  "77375": { state: "TX", city: "TOMBALL" },
  "77376": { state: "TX", city: "VOTAW" },
  "77377": { state: "TX", city: "TOMBALL" },
  "77378": { state: "TX", city: "WILLIS" },
  "77379": { state: "TX", city: "SPRING" },
  "77380": { state: "TX", city: "SPRING" },
  "77381": { state: "TX", city: "SPRING" },
  "77382": { state: "TX", city: "SPRING" },
  "77383": { state: "TX", city: "SPRING" },
  "77384": { state: "TX", city: "CONROE" },
  "77385": { state: "TX", city: "CONROE" },
  "77386": { state: "TX", city: "SPRING" },
  "77387": { state: "TX", city: "SPRING" },
  "77388": { state: "TX", city: "SPRING" },
  "77389": { state: "TX", city: "SPRING" },
  "77391": { state: "TX", city: "SPRING" },
  "77393": { state: "TX", city: "SPRING" },
  "77396": { state: "TX", city: "HUMBLE" },
  "77399": { state: "TX", city: "LIVINGSTON" },
  "77401": { state: "TX", city: "BELLAIRE" },
  "77402": { state: "TX", city: "BELLAIRE" },
  "77404": { state: "TX", city: "BAY CITY" },
  "77406": { state: "TX", city: "RICHMOND" },
  "77407": { state: "TX", city: "RICHMOND" },
  "77410": { state: "TX", city: "CYPRESS" },
  "77411": { state: "TX", city: "ALIEF" },
  "77412": { state: "TX", city: "ALTAIR" },
  "77413": { state: "TX", city: "BARKER" },
  "77414": { state: "TX", city: "BAY CITY" },
  "77415": { state: "TX", city: "CEDAR LANE" },
  "77417": { state: "TX", city: "BEASLEY" },
  "77418": { state: "TX", city: "BELLVILLE" },
  "77419": { state: "TX", city: "BLESSING" },
  "77420": { state: "TX", city: "BOLING" },
  "77422": { state: "TX", city: "BRAZORIA" },
  "77423": { state: "TX", city: "BROOKSHIRE" },
  "77426": { state: "TX", city: "CHAPPELL HILL" },
  "77428": { state: "TX", city: "COLLEGEPORT" },
  "77429": { state: "TX", city: "CYPRESS" },
  "77430": { state: "TX", city: "DAMON" },
  "77431": { state: "TX", city: "DANCIGER" },
  "77432": { state: "TX", city: "DANEVANG" },
  "77433": { state: "TX", city: "CYPRESS" },
  "77434": { state: "TX", city: "EAGLE LAKE" },
  "77435": { state: "TX", city: "EAST BERNARD" },
  "77436": { state: "TX", city: "EGYPT" },
  "77437": { state: "TX", city: "EL CAMPO" },
  "77440": { state: "TX", city: "ELMATON" },
  "77441": { state: "TX", city: "FULSHEAR" },
  "77442": { state: "TX", city: "GARWOOD" },
  "77443": { state: "TX", city: "GLEN FLORA" },
  "77444": { state: "TX", city: "GUY" },
  "77445": { state: "TX", city: "HEMPSTEAD" },
  "77446": { state: "TX", city: "PRAIRIE VIEW" },
  "77447": { state: "TX", city: "HOCKLEY" },
  "77448": { state: "TX", city: "HUNGERFORD" },
  "77449": { state: "TX", city: "KATY" },
  "77450": { state: "TX", city: "KATY" },
  "77451": { state: "TX", city: "KENDLETON" },
  "77452": { state: "TX", city: "KENNEY" },
  "77453": { state: "TX", city: "LANE CITY" },
  "77454": { state: "TX", city: "LISSIE" },
  "77455": { state: "TX", city: "LOUISE" },
  "77456": { state: "TX", city: "MARKHAM" },
  "77457": { state: "TX", city: "MATAGORDA" },
  "77458": { state: "TX", city: "MIDFIELD" },
  "77459": { state: "TX", city: "MISSOURI CITY" },
  "77460": { state: "TX", city: "NADA" },
  "77461": { state: "TX", city: "NEEDVILLE" },
  "77463": { state: "TX", city: "OLD OCEAN" },
  "77464": { state: "TX", city: "ORCHARD" },
  "77465": { state: "TX", city: "PALACIOS" },
  "77466": { state: "TX", city: "PATTISON" },
  "77467": { state: "TX", city: "PIERCE" },
  "77468": { state: "TX", city: "PLEDGER" },
  "77469": { state: "TX", city: "RICHMOND" },
  "77470": { state: "TX", city: "ROCK ISLAND" },
  "77471": { state: "TX", city: "ROSENBERG" },
  "77473": { state: "TX", city: "SAN FELIPE" },
  "77474": { state: "TX", city: "SEALY" },
  "77475": { state: "TX", city: "SHERIDAN" },
  "77476": { state: "TX", city: "SIMONTON" },
  "77477": { state: "TX", city: "STAFFORD" },
  "77478": { state: "TX", city: "SUGAR LAND" },
  "77479": { state: "TX", city: "SUGAR LAND" },
  "77480": { state: "TX", city: "SWEENY" },
  "77481": { state: "TX", city: "THOMPSONS" },
  "77482": { state: "TX", city: "VAN VLECK" },
  "77483": { state: "TX", city: "WADSWORTH" },
  "77484": { state: "TX", city: "WALLER" },
  "77485": { state: "TX", city: "WALLIS" },
  "77486": { state: "TX", city: "WEST COLUMBIA" },
  "77487": { state: "TX", city: "SUGAR LAND" },
  "77488": { state: "TX", city: "WHARTON" },
  "77489": { state: "TX", city: "MISSOURI CITY" },
  "77491": { state: "TX", city: "KATY" },
  "77492": { state: "TX", city: "KATY" },
  "77493": { state: "TX", city: "KATY" },
  "77494": { state: "TX", city: "KATY" },
  "77496": { state: "TX", city: "SUGAR LAND" },
  "77497": { state: "TX", city: "STAFFORD" },
  "77498": { state: "TX", city: "SUGAR LAND" },
  "77501": { state: "TX", city: "PASADENA" },
  "77502": { state: "TX", city: "PASADENA" },
  "77503": { state: "TX", city: "PASADENA" },
  "77504": { state: "TX", city: "PASADENA" },
  "77505": { state: "TX", city: "PASADENA" },
  "77506": { state: "TX", city: "PASADENA" },
  "77507": { state: "TX", city: "PASADENA" },
  "77508": { state: "TX", city: "PASADENA" },
  "77510": { state: "TX", city: "SANTA FE" },
  "77511": { state: "TX", city: "ALVIN" },
  "77512": { state: "TX", city: "ALVIN" },
  "77514": { state: "TX", city: "ANAHUAC" },
  "77515": { state: "TX", city: "ANGLETON" },
  "77516": { state: "TX", city: "ANGLETON" },
  "77517": { state: "TX", city: "SANTA FE" },
  "77518": { state: "TX", city: "BACLIFF" },
  "77519": { state: "TX", city: "BATSON" },
  "77520": { state: "TX", city: "BAYTOWN" },
  "77521": { state: "TX", city: "BAYTOWN" },
  "77522": { state: "TX", city: "BAYTOWN" },
  "77523": { state: "TX", city: "BAYTOWN" },
  "77530": { state: "TX", city: "CHANNELVIEW" },
  "77531": { state: "TX", city: "CLUTE" },
  "77532": { state: "TX", city: "CROSBY" },
  "77533": { state: "TX", city: "DAISETTA" },
  "77534": { state: "TX", city: "DANBURY" },
  "77535": { state: "TX", city: "DAYTON" },
  "77536": { state: "TX", city: "DEER PARK" },
  "77538": { state: "TX", city: "DEVERS" },
  "77539": { state: "TX", city: "DICKINSON" },
  "77541": { state: "TX", city: "FREEPORT" },
  "77542": { state: "TX", city: "FREEPORT" },
  "77545": { state: "TX", city: "FRESNO" },
  "77546": { state: "TX", city: "FRIENDSWOOD" },
  "77547": { state: "TX", city: "GALENA PARK" },
  "77549": { state: "TX", city: "FRIENDSWOOD" },
  "77550": { state: "TX", city: "GALVESTON" },
  "77551": { state: "TX", city: "GALVESTON" },
  "77552": { state: "TX", city: "GALVESTON" },
  "77553": { state: "TX", city: "GALVESTON" },
  "77554": { state: "TX", city: "GALVESTON" },
  "77555": { state: "TX", city: "GALVESTON" },
  "77560": { state: "TX", city: "HANKAMER" },
  "77561": { state: "TX", city: "HARDIN" },
  "77562": { state: "TX", city: "HIGHLANDS" },
  "77563": { state: "TX", city: "HITCHCOCK" },
  "77564": { state: "TX", city: "HULL" },
  "77565": { state: "TX", city: "KEMAH" },
  "77566": { state: "TX", city: "LAKE JACKSON" },
  "77568": { state: "TX", city: "LA MARQUE" },
  "77571": { state: "TX", city: "LA PORTE" },
  "77572": { state: "TX", city: "LA PORTE" },
  "77573": { state: "TX", city: "LEAGUE CITY" },
  "77574": { state: "TX", city: "LEAGUE CITY" },
  "77575": { state: "TX", city: "LIBERTY" },
  "77577": { state: "TX", city: "LIVERPOOL" },
  "77578": { state: "TX", city: "MANVEL" },
  "77580": { state: "TX", city: "MONT BELVIEU" },
  "77581": { state: "TX", city: "PEARLAND" },
  "77582": { state: "TX", city: "RAYWOOD" },
  "77583": { state: "TX", city: "ROSHARON" },
  "77584": { state: "TX", city: "PEARLAND" },
  "77585": { state: "TX", city: "SARATOGA" },
  "77586": { state: "TX", city: "SEABROOK" },
  "77587": { state: "TX", city: "SOUTH HOUSTON" },
  "77588": { state: "TX", city: "PEARLAND" },
  "77590": { state: "TX", city: "TEXAS CITY" },
  "77591": { state: "TX", city: "TEXAS CITY" },
  "77592": { state: "TX", city: "TEXAS CITY" },
  "77597": { state: "TX", city: "WALLISVILLE" },
  "77598": { state: "TX", city: "WEBSTER" },
  "77611": { state: "TX", city: "BRIDGE CITY" },
  "77612": { state: "TX", city: "BUNA" },
  "77613": { state: "TX", city: "CHINA" },
  "77614": { state: "TX", city: "DEWEYVILLE" },
  "77615": { state: "TX", city: "EVADALE" },
  "77616": { state: "TX", city: "FRED" },
  "77617": { state: "TX", city: "GILCHRIST" },
  "77619": { state: "TX", city: "GROVES" },
  "77622": { state: "TX", city: "HAMSHIRE" },
  "77623": { state: "TX", city: "HIGH ISLAND" },
  "77624": { state: "TX", city: "HILLISTER" },
  "77625": { state: "TX", city: "KOUNTZE" },
  "77626": { state: "TX", city: "MAURICEVILLE" },
  "77627": { state: "TX", city: "NEDERLAND" },
  "77629": { state: "TX", city: "NOME" },
  "77630": { state: "TX", city: "ORANGE" },
  "77631": { state: "TX", city: "ORANGE" },
  "77632": { state: "TX", city: "ORANGE" },
  "77639": { state: "TX", city: "ORANGEFIELD" },
  "77640": { state: "TX", city: "PORT ARTHUR" },
  "77641": { state: "TX", city: "PORT ARTHUR" },
  "77642": { state: "TX", city: "PORT ARTHUR" },
  "77643": { state: "TX", city: "PORT ARTHUR" },
  "77650": { state: "TX", city: "PORT BOLIVAR" },
  "77651": { state: "TX", city: "PORT NECHES" },
  "77655": { state: "TX", city: "SABINE PASS" },
  "77656": { state: "TX", city: "SILSBEE" },
  "77657": { state: "TX", city: "LUMBERTON" },
  "77659": { state: "TX", city: "SOUR LAKE" },
  "77660": { state: "TX", city: "SPURGER" },
  "77661": { state: "TX", city: "STOWELL" },
  "77662": { state: "TX", city: "VIDOR" },
  "77663": { state: "TX", city: "VILLAGE MILLS" },
  "77664": { state: "TX", city: "WARREN" },
  "77665": { state: "TX", city: "WINNIE" },
  "77670": { state: "TX", city: "VIDOR" },
  "77701": { state: "TX", city: "BEAUMONT" },
  "77702": { state: "TX", city: "BEAUMONT" },
  "77703": { state: "TX", city: "BEAUMONT" },
  "77704": { state: "TX", city: "BEAUMONT" },
  "77705": { state: "TX", city: "BEAUMONT" },
  "77706": { state: "TX", city: "BEAUMONT" },
  "77707": { state: "TX", city: "BEAUMONT" },
  "77708": { state: "TX", city: "BEAUMONT" },
  "77710": { state: "TX", city: "BEAUMONT" },
  "77713": { state: "TX", city: "BEAUMONT" },
  "77720": { state: "TX", city: "BEAUMONT" },
  "77725": { state: "TX", city: "BEAUMONT" },
  "77726": { state: "TX", city: "BEAUMONT" },
  "77801": { state: "TX", city: "BRYAN" },
  "77802": { state: "TX", city: "BRYAN" },
  "77803": { state: "TX", city: "BRYAN" },
  "77805": { state: "TX", city: "BRYAN" },
  "77806": { state: "TX", city: "BRYAN" },
  "77807": { state: "TX", city: "BRYAN" },
  "77808": { state: "TX", city: "BRYAN" },
  "77830": { state: "TX", city: "ANDERSON" },
  "77831": { state: "TX", city: "BEDIAS" },
  "77833": { state: "TX", city: "BRENHAM" },
  "77834": { state: "TX", city: "BRENHAM" },
  "77835": { state: "TX", city: "BURTON" },
  "77836": { state: "TX", city: "CALDWELL" },
  "77837": { state: "TX", city: "CALVERT" },
  "77838": { state: "TX", city: "CHRIESMAN" },
  "77840": { state: "TX", city: "COLLEGE STATION" },
  "77841": { state: "TX", city: "COLLEGE STATION" },
  "77842": { state: "TX", city: "COLLEGE STATION" },
  "77843": { state: "TX", city: "COLLEGE STATION" },
  "77844": { state: "TX", city: "COLLEGE STATION" },
  "77845": { state: "TX", city: "COLLEGE STATION" },
  "77850": { state: "TX", city: "CONCORD" },
  "77852": { state: "TX", city: "DEANVILLE" },
  "77853": { state: "TX", city: "DIME BOX" },
  "77855": { state: "TX", city: "FLYNN" },
  "77856": { state: "TX", city: "FRANKLIN" },
  "77857": { state: "TX", city: "GAUSE" },
  "77859": { state: "TX", city: "HEARNE" },
  "77861": { state: "TX", city: "IOLA" },
  "77862": { state: "TX", city: "KURTEN" },
  "77863": { state: "TX", city: "LYONS" },
  "77864": { state: "TX", city: "MADISONVILLE" },
  "77865": { state: "TX", city: "MARQUEZ" },
  "77866": { state: "TX", city: "MILLICAN" },
  "77867": { state: "TX", city: "MUMFORD" },
  "77868": { state: "TX", city: "NAVASOTA" },
  "77870": { state: "TX", city: "NEW BADEN" },
  "77871": { state: "TX", city: "NORMANGEE" },
  "77872": { state: "TX", city: "NORTH ZULCH" },
  "77873": { state: "TX", city: "RICHARDS" },
  "77875": { state: "TX", city: "ROANS PRAIRIE" },
  "77876": { state: "TX", city: "SHIRO" },
  "77878": { state: "TX", city: "SNOOK" },
  "77879": { state: "TX", city: "SOMERVILLE" },
  "77880": { state: "TX", city: "WASHINGTON" },
  "77881": { state: "TX", city: "WELLBORN" },
  "77882": { state: "TX", city: "WHEELOCK" },
  "77901": { state: "TX", city: "VICTORIA" },
  "77902": { state: "TX", city: "VICTORIA" },
  "77903": { state: "TX", city: "VICTORIA" },
  "77904": { state: "TX", city: "VICTORIA" },
  "77905": { state: "TX", city: "VICTORIA" },
  "77950": { state: "TX", city: "AUSTWELL" },
  "77951": { state: "TX", city: "BLOOMINGTON" },
  "77954": { state: "TX", city: "CUERO" },
  "77957": { state: "TX", city: "EDNA" },
  "77960": { state: "TX", city: "FANNIN" },
  "77961": { state: "TX", city: "FRANCITAS" },
  "77962": { state: "TX", city: "GANADO" },
  "77963": { state: "TX", city: "GOLIAD" },
  "77964": { state: "TX", city: "HALLETTSVILLE" },
  "77967": { state: "TX", city: "HOCHHEIM" },
  "77968": { state: "TX", city: "INEZ" },
  "77969": { state: "TX", city: "LA SALLE" },
  "77970": { state: "TX", city: "LA WARD" },
  "77971": { state: "TX", city: "LOLITA" },
  "77973": { state: "TX", city: "MCFADDIN" },
  "77974": { state: "TX", city: "MEYERSVILLE" },
  "77975": { state: "TX", city: "MOULTON" },
  "77976": { state: "TX", city: "NURSERY" },
  "77977": { state: "TX", city: "PLACEDO" },
  "77978": { state: "TX", city: "POINT COMFORT" },
  "77979": { state: "TX", city: "PORT LAVACA" },
  "77982": { state: "TX", city: "PORT O CONNOR" },
  "77983": { state: "TX", city: "SEADRIFT" },
  "77984": { state: "TX", city: "SHINER" },
  "77986": { state: "TX", city: "SUBLIME" },
  "77987": { state: "TX", city: "SWEET HOME" },
  "77988": { state: "TX", city: "TELFERNER" },
  "77989": { state: "TX", city: "THOMASTON" },
  "77990": { state: "TX", city: "TIVOLI" },
  "77991": { state: "TX", city: "VANDERBILT" },
  "77993": { state: "TX", city: "WEESATCHE" },
  "77994": { state: "TX", city: "WESTHOFF" },
  "77995": { state: "TX", city: "YOAKUM" },
  "78001": { state: "TX", city: "ARTESIA WELLS" },
  "78002": { state: "TX", city: "ATASCOSA" },
  "78003": { state: "TX", city: "BANDERA" },
  "78004": { state: "TX", city: "BERGHEIM" },
  "78005": { state: "TX", city: "BIGFOOT" },
  "78006": { state: "TX", city: "BOERNE" },
  "78007": { state: "TX", city: "CALLIHAM" },
  "78008": { state: "TX", city: "CAMPBELLTON" },
  "78009": { state: "TX", city: "CASTROVILLE" },
  "78010": { state: "TX", city: "CENTER POINT" },
  "78011": { state: "TX", city: "CHARLOTTE" },
  "78012": { state: "TX", city: "CHRISTINE" },
  "78013": { state: "TX", city: "COMFORT" },
  "78014": { state: "TX", city: "COTULLA" },
  "78015": { state: "TX", city: "BOERNE" },
  "78016": { state: "TX", city: "DEVINE" },
  "78017": { state: "TX", city: "DILLEY" },
  "78019": { state: "TX", city: "ENCINAL" },
  "78021": { state: "TX", city: "FOWLERTON" },
  "78022": { state: "TX", city: "GEORGE WEST" },
  "78023": { state: "TX", city: "HELOTES" },
  "78024": { state: "TX", city: "HUNT" },
  "78025": { state: "TX", city: "INGRAM" },
  "78026": { state: "TX", city: "JOURDANTON" },
  "78027": { state: "TX", city: "KENDALIA" },
  "78028": { state: "TX", city: "KERRVILLE" },
  "78029": { state: "TX", city: "KERRVILLE" },
  "78039": { state: "TX", city: "LA COSTE" },
  "78040": { state: "TX", city: "LAREDO" },
  "78041": { state: "TX", city: "LAREDO" },
  "78042": { state: "TX", city: "LAREDO" },
  "78043": { state: "TX", city: "LAREDO" },
  "78044": { state: "TX", city: "LAREDO" },
  "78045": { state: "TX", city: "LAREDO" },
  "78046": { state: "TX", city: "LAREDO" },
  "78050": { state: "TX", city: "LEMING" },
  "78052": { state: "TX", city: "LYTLE" },
  "78054": { state: "TX", city: "MACDONA" },
  "78055": { state: "TX", city: "MEDINA" },
  "78056": { state: "TX", city: "MICO" },
  "78057": { state: "TX", city: "MOORE" },
  "78058": { state: "TX", city: "MOUNTAIN HOME" },
  "78059": { state: "TX", city: "NATALIA" },
  "78060": { state: "TX", city: "OAKVILLE" },
  "78061": { state: "TX", city: "PEARSALL" },
  "78062": { state: "TX", city: "PEGGY" },
  "78063": { state: "TX", city: "PIPE CREEK" },
  "78064": { state: "TX", city: "PLEASANTON" },
  "78065": { state: "TX", city: "POTEET" },
  "78066": { state: "TX", city: "RIO MEDINA" },
  "78067": { state: "TX", city: "SAN YGNACIO" },
  "78069": { state: "TX", city: "SOMERSET" },
  "78070": { state: "TX", city: "SPRING BRANCH" },
  "78071": { state: "TX", city: "THREE RIVERS" },
  "78072": { state: "TX", city: "TILDEN" },
  "78073": { state: "TX", city: "VON ORMY" },
  "78074": { state: "TX", city: "WARING" },
  "78075": { state: "TX", city: "WHITSETT" },
  "78076": { state: "TX", city: "ZAPATA" },
  "78101": { state: "TX", city: "ADKINS" },
  "78102": { state: "TX", city: "BEEVILLE" },
  "78104": { state: "TX", city: "BEEVILLE" },
  "78107": { state: "TX", city: "BERCLAIR" },
  "78108": { state: "TX", city: "CIBOLO" },
  "78109": { state: "TX", city: "CONVERSE" },
  "78111": { state: "TX", city: "ECLETO" },
  "78112": { state: "TX", city: "ELMENDORF" },
  "78113": { state: "TX", city: "FALLS CITY" },
  "78114": { state: "TX", city: "FLORESVILLE" },
  "78115": { state: "TX", city: "GERONIMO" },
  "78116": { state: "TX", city: "GILLETT" },
  "78117": { state: "TX", city: "HOBSON" },
  "78118": { state: "TX", city: "KARNES CITY" },
  "78119": { state: "TX", city: "KENEDY" },
  "78121": { state: "TX", city: "LA VERNIA" },
  "78122": { state: "TX", city: "LEESVILLE" },
  "78123": { state: "TX", city: "MC QUEENEY" },
  "78124": { state: "TX", city: "MARION" },
  "78125": { state: "TX", city: "MINERAL" },
  "78130": { state: "TX", city: "NEW BRAUNFELS" },
  "78131": { state: "TX", city: "NEW BRAUNFELS" },
  "78132": { state: "TX", city: "NEW BRAUNFELS" },
  "78133": { state: "TX", city: "CANYON LAKE" },
  "78135": { state: "TX", city: "NEW BRAUNFELS" },
  "78140": { state: "TX", city: "NIXON" },
  "78141": { state: "TX", city: "NORDHEIM" },
  "78142": { state: "TX", city: "NORMANNA" },
  "78143": { state: "TX", city: "PANDORA" },
  "78144": { state: "TX", city: "PANNA MARIA" },
  "78145": { state: "TX", city: "PAWNEE" },
  "78146": { state: "TX", city: "PETTUS" },
  "78147": { state: "TX", city: "POTH" },
  "78148": { state: "TX", city: "UNIVERSAL CITY" },
  "78150": { state: "TX", city: "JBSA RANDOLPH" },
  "78151": { state: "TX", city: "RUNGE" },
  "78152": { state: "TX", city: "SAINT HEDWIG" },
  "78154": { state: "TX", city: "SCHERTZ" },
  "78155": { state: "TX", city: "SEGUIN" },
  "78156": { state: "TX", city: "SEGUIN" },
  "78159": { state: "TX", city: "SMILEY" },
  "78160": { state: "TX", city: "STOCKDALE" },
  "78161": { state: "TX", city: "SUTHERLAND SPRINGS" },
  "78162": { state: "TX", city: "TULETA" },
  "78163": { state: "TX", city: "BULVERDE" },
  "78164": { state: "TX", city: "YORKTOWN" },
  "78201": { state: "TX", city: "SAN ANTONIO" },
  "78202": { state: "TX", city: "SAN ANTONIO" },
  "78203": { state: "TX", city: "SAN ANTONIO" },
  "78204": { state: "TX", city: "SAN ANTONIO" },
  "78205": { state: "TX", city: "SAN ANTONIO" },
  "78206": { state: "TX", city: "SAN ANTONIO" },
  "78207": { state: "TX", city: "SAN ANTONIO" },
  "78208": { state: "TX", city: "SAN ANTONIO" },
  "78209": { state: "TX", city: "SAN ANTONIO" },
  "78210": { state: "TX", city: "SAN ANTONIO" },
  "78211": { state: "TX", city: "SAN ANTONIO" },
  "78212": { state: "TX", city: "SAN ANTONIO" },
  "78213": { state: "TX", city: "SAN ANTONIO" },
  "78214": { state: "TX", city: "SAN ANTONIO" },
  "78215": { state: "TX", city: "SAN ANTONIO" },
  "78216": { state: "TX", city: "SAN ANTONIO" },
  "78217": { state: "TX", city: "SAN ANTONIO" },
  "78218": { state: "TX", city: "SAN ANTONIO" },
  "78219": { state: "TX", city: "SAN ANTONIO" },
  "78220": { state: "TX", city: "SAN ANTONIO" },
  "78221": { state: "TX", city: "SAN ANTONIO" },
  "78222": { state: "TX", city: "SAN ANTONIO" },
  "78223": { state: "TX", city: "SAN ANTONIO" },
  "78224": { state: "TX", city: "SAN ANTONIO" },
  "78225": { state: "TX", city: "SAN ANTONIO" },
  "78226": { state: "TX", city: "SAN ANTONIO" },
  "78227": { state: "TX", city: "SAN ANTONIO" },
  "78228": { state: "TX", city: "SAN ANTONIO" },
  "78229": { state: "TX", city: "SAN ANTONIO" },
  "78230": { state: "TX", city: "SAN ANTONIO" },
  "78231": { state: "TX", city: "SAN ANTONIO" },
  "78232": { state: "TX", city: "SAN ANTONIO" },
  "78233": { state: "TX", city: "SAN ANTONIO" },
  "78234": { state: "TX", city: "JBSA FT SAM HOUSTON" },
  "78235": { state: "TX", city: "SAN ANTONIO" },
  "78236": { state: "TX", city: "JBSA LACKLAND" },
  "78237": { state: "TX", city: "SAN ANTONIO" },
  "78238": { state: "TX", city: "SAN ANTONIO" },
  "78239": { state: "TX", city: "SAN ANTONIO" },
  "78240": { state: "TX", city: "SAN ANTONIO" },
  "78241": { state: "TX", city: "SAN ANTONIO" },
  "78242": { state: "TX", city: "SAN ANTONIO" },
  "78243": { state: "TX", city: "SAN ANTONIO" },
  "78244": { state: "TX", city: "SAN ANTONIO" },
  "78245": { state: "TX", city: "SAN ANTONIO" },
  "78246": { state: "TX", city: "SAN ANTONIO" },
  "78247": { state: "TX", city: "SAN ANTONIO" },
  "78248": { state: "TX", city: "SAN ANTONIO" },
  "78249": { state: "TX", city: "SAN ANTONIO" },
  "78250": { state: "TX", city: "SAN ANTONIO" },
  "78251": { state: "TX", city: "SAN ANTONIO" },
  "78252": { state: "TX", city: "SAN ANTONIO" },
  "78253": { state: "TX", city: "SAN ANTONIO" },
  "78254": { state: "TX", city: "SAN ANTONIO" },
  "78255": { state: "TX", city: "SAN ANTONIO" },
  "78256": { state: "TX", city: "SAN ANTONIO" },
  "78257": { state: "TX", city: "SAN ANTONIO" },
  "78258": { state: "TX", city: "SAN ANTONIO" },
  "78259": { state: "TX", city: "SAN ANTONIO" },
  "78260": { state: "TX", city: "SAN ANTONIO" },
  "78261": { state: "TX", city: "SAN ANTONIO" },
  "78263": { state: "TX", city: "SAN ANTONIO" },
  "78264": { state: "TX", city: "SAN ANTONIO" },
  "78265": { state: "TX", city: "SAN ANTONIO" },
  "78266": { state: "TX", city: "SAN ANTONIO" },
  "78268": { state: "TX", city: "SAN ANTONIO" },
  "78269": { state: "TX", city: "SAN ANTONIO" },
  "78270": { state: "TX", city: "SAN ANTONIO" },
  "78278": { state: "TX", city: "SAN ANTONIO" },
  "78279": { state: "TX", city: "SAN ANTONIO" },
  "78280": { state: "TX", city: "SAN ANTONIO" },
  "78283": { state: "TX", city: "SAN ANTONIO" },
  "78284": { state: "TX", city: "SAN ANTONIO" },
  "78285": { state: "TX", city: "SAN ANTONIO" },
  "78288": { state: "TX", city: "SAN ANTONIO" },
  "78289": { state: "TX", city: "SAN ANTONIO" },
  "78291": { state: "TX", city: "SAN ANTONIO" },
  "78292": { state: "TX", city: "SAN ANTONIO" },
  "78293": { state: "TX", city: "SAN ANTONIO" },
  "78294": { state: "TX", city: "SAN ANTONIO" },
  "78295": { state: "TX", city: "SAN ANTONIO" },
  "78296": { state: "TX", city: "SAN ANTONIO" },
  "78297": { state: "TX", city: "SAN ANTONIO" },
  "78298": { state: "TX", city: "SAN ANTONIO" },
  "78299": { state: "TX", city: "SAN ANTONIO" },
  "78330": { state: "TX", city: "AGUA DULCE" },
  "78332": { state: "TX", city: "ALICE" },
  "78333": { state: "TX", city: "ALICE" },
  "78335": { state: "TX", city: "ARANSAS PASS" },
  "78336": { state: "TX", city: "ARANSAS PASS" },
  "78338": { state: "TX", city: "ARMSTRONG" },
  "78339": { state: "TX", city: "BANQUETE" },
  "78340": { state: "TX", city: "BAYSIDE" },
  "78341": { state: "TX", city: "BENAVIDES" },
  "78342": { state: "TX", city: "BEN BOLT" },
  "78343": { state: "TX", city: "BISHOP" },
  "78344": { state: "TX", city: "BRUNI" },
  "78347": { state: "TX", city: "CHAPMAN RANCH" },
  "78349": { state: "TX", city: "CONCEPCION" },
  "78350": { state: "TX", city: "DINERO" },
  "78351": { state: "TX", city: "DRISCOLL" },
  "78352": { state: "TX", city: "EDROY" },
  "78353": { state: "TX", city: "ENCINO" },
  "78355": { state: "TX", city: "FALFURRIAS" },
  "78357": { state: "TX", city: "FREER" },
  "78358": { state: "TX", city: "FULTON" },
  "78359": { state: "TX", city: "GREGORY" },
  "78360": { state: "TX", city: "GUERRA" },
  "78361": { state: "TX", city: "HEBBRONVILLE" },
  "78362": { state: "TX", city: "INGLESIDE" },
  "78363": { state: "TX", city: "KINGSVILLE" },
  "78364": { state: "TX", city: "KINGSVILLE" },
  "78368": { state: "TX", city: "MATHIS" },
  "78369": { state: "TX", city: "MIRANDO CITY" },
  "78370": { state: "TX", city: "ODEM" },
  "78371": { state: "TX", city: "OILTON" },
  "78372": { state: "TX", city: "ORANGE GROVE" },
  "78373": { state: "TX", city: "PORT ARANSAS" },
  "78374": { state: "TX", city: "PORTLAND" },
  "78375": { state: "TX", city: "PREMONT" },
  "78376": { state: "TX", city: "REALITOS" },
  "78377": { state: "TX", city: "REFUGIO" },
  "78379": { state: "TX", city: "RIVIERA" },
  "78380": { state: "TX", city: "ROBSTOWN" },
  "78381": { state: "TX", city: "ROCKPORT" },
  "78382": { state: "TX", city: "ROCKPORT" },
  "78383": { state: "TX", city: "SANDIA" },
  "78384": { state: "TX", city: "SAN DIEGO" },
  "78385": { state: "TX", city: "SARITA" },
  "78387": { state: "TX", city: "SINTON" },
  "78389": { state: "TX", city: "SKIDMORE" },
  "78390": { state: "TX", city: "TAFT" },
  "78391": { state: "TX", city: "TYNAN" },
  "78393": { state: "TX", city: "WOODSBORO" },
  "78401": { state: "TX", city: "CORPUS CHRISTI" },
  "78402": { state: "TX", city: "CORPUS CHRISTI" },
  "78403": { state: "TX", city: "CORPUS CHRISTI" },
  "78404": { state: "TX", city: "CORPUS CHRISTI" },
  "78405": { state: "TX", city: "CORPUS CHRISTI" },
  "78406": { state: "TX", city: "CORPUS CHRISTI" },
  "78407": { state: "TX", city: "CORPUS CHRISTI" },
  "78408": { state: "TX", city: "CORPUS CHRISTI" },
  "78409": { state: "TX", city: "CORPUS CHRISTI" },
  "78410": { state: "TX", city: "CORPUS CHRISTI" },
  "78411": { state: "TX", city: "CORPUS CHRISTI" },
  "78412": { state: "TX", city: "CORPUS CHRISTI" },
  "78413": { state: "TX", city: "CORPUS CHRISTI" },
  "78414": { state: "TX", city: "CORPUS CHRISTI" },
  "78415": { state: "TX", city: "CORPUS CHRISTI" },
  "78416": { state: "TX", city: "CORPUS CHRISTI" },
  "78417": { state: "TX", city: "CORPUS CHRISTI" },
  "78418": { state: "TX", city: "CORPUS CHRISTI" },
  "78419": { state: "TX", city: "CORPUS CHRISTI" },
  "78426": { state: "TX", city: "CORPUS CHRISTI" },
  "78427": { state: "TX", city: "CORPUS CHRISTI" },
  "78460": { state: "TX", city: "CORPUS CHRISTI" },
  "78463": { state: "TX", city: "CORPUS CHRISTI" },
  "78465": { state: "TX", city: "CORPUS CHRISTI" },
  "78466": { state: "TX", city: "CORPUS CHRISTI" },
  "78467": { state: "TX", city: "CORPUS CHRISTI" },
  "78468": { state: "TX", city: "CORPUS CHRISTI" },
  "78469": { state: "TX", city: "CORPUS CHRISTI" },
  "78472": { state: "TX", city: "CORPUS CHRISTI" },
  "78480": { state: "TX", city: "CORPUS CHRISTI" },
  "78501": { state: "TX", city: "MCALLEN" },
  "78502": { state: "TX", city: "MCALLEN" },
  "78503": { state: "TX", city: "MCALLEN" },
  "78504": { state: "TX", city: "MCALLEN" },
  "78505": { state: "TX", city: "MCALLEN" },
  "78516": { state: "TX", city: "ALAMO" },
  "78520": { state: "TX", city: "BROWNSVILLE" },
  "78521": { state: "TX", city: "BROWNSVILLE" },
  "78522": { state: "TX", city: "BROWNSVILLE" },
  "78523": { state: "TX", city: "BROWNSVILLE" },
  "78526": { state: "TX", city: "BROWNSVILLE" },
  "78535": { state: "TX", city: "COMBES" },
  "78536": { state: "TX", city: "DELMITA" },
  "78537": { state: "TX", city: "DONNA" },
  "78538": { state: "TX", city: "EDCOUCH" },
  "78539": { state: "TX", city: "EDINBURG" },
  "78540": { state: "TX", city: "EDINBURG" },
  "78541": { state: "TX", city: "EDINBURG" },
  "78542": { state: "TX", city: "EDINBURG" },
  "78543": { state: "TX", city: "ELSA" },
  "78545": { state: "TX", city: "FALCON HEIGHTS" },
  "78547": { state: "TX", city: "GARCIASVILLE" },
  "78548": { state: "TX", city: "GRULLA" },
  "78549": { state: "TX", city: "HARGILL" },
  "78550": { state: "TX", city: "HARLINGEN" },
  "78551": { state: "TX", city: "HARLINGEN" },
  "78552": { state: "TX", city: "HARLINGEN" },
  "78553": { state: "TX", city: "HARLINGEN" },
  "78557": { state: "TX", city: "HIDALGO" },
  "78558": { state: "TX", city: "LA BLANCA" },
  "78559": { state: "TX", city: "LA FERIA" },
  "78560": { state: "TX", city: "LA JOYA" },
  "78561": { state: "TX", city: "LASARA" },
  "78562": { state: "TX", city: "LA VILLA" },
  "78563": { state: "TX", city: "LINN" },
  "78564": { state: "TX", city: "LOPENO" },
  "78565": { state: "TX", city: "LOS EBANOS" },
  "78566": { state: "TX", city: "LOS FRESNOS" },
  "78567": { state: "TX", city: "LOS INDIOS" },
  "78568": { state: "TX", city: "LOZANO" },
  "78569": { state: "TX", city: "LYFORD" },
  "78570": { state: "TX", city: "MERCEDES" },
  "78572": { state: "TX", city: "MISSION" },
  "78573": { state: "TX", city: "MISSION" },
  "78574": { state: "TX", city: "MISSION" },
  "78575": { state: "TX", city: "OLMITO" },
  "78576": { state: "TX", city: "PENITAS" },
  "78577": { state: "TX", city: "PHARR" },
  "78578": { state: "TX", city: "PORT ISABEL" },
  "78579": { state: "TX", city: "PROGRESO" },
  "78580": { state: "TX", city: "RAYMONDVILLE" },
  "78582": { state: "TX", city: "RIO GRANDE CITY" },
  "78583": { state: "TX", city: "RIO HONDO" },
  "78584": { state: "TX", city: "ROMA" },
  "78585": { state: "TX", city: "SALINENO" },
  "78586": { state: "TX", city: "SAN BENITO" },
  "78588": { state: "TX", city: "SAN ISIDRO" },
  "78589": { state: "TX", city: "SAN JUAN" },
  "78590": { state: "TX", city: "SAN PERLITA" },
  "78591": { state: "TX", city: "SANTA ELENA" },
  "78592": { state: "TX", city: "SANTA MARIA" },
  "78593": { state: "TX", city: "SANTA ROSA" },
  "78594": { state: "TX", city: "SEBASTIAN" },
  "78595": { state: "TX", city: "SULLIVAN CITY" },
  "78596": { state: "TX", city: "WESLACO" },
  "78597": { state: "TX", city: "SOUTH PADRE ISLAND" },
  "78598": { state: "TX", city: "PORT MANSFIELD" },
  "78599": { state: "TX", city: "WESLACO" },
  "78602": { state: "TX", city: "BASTROP" },
  "78604": { state: "TX", city: "BELMONT" },
  "78605": { state: "TX", city: "BERTRAM" },
  "78606": { state: "TX", city: "BLANCO" },
  "78607": { state: "TX", city: "BLUFFTON" },
  "78608": { state: "TX", city: "BRIGGS" },
  "78609": { state: "TX", city: "BUCHANAN DAM" },
  "78610": { state: "TX", city: "BUDA" },
  "78611": { state: "TX", city: "BURNET" },
  "78612": { state: "TX", city: "CEDAR CREEK" },
  "78613": { state: "TX", city: "CEDAR PARK" },
  "78614": { state: "TX", city: "COST" },
  "78615": { state: "TX", city: "COUPLAND" },
  "78616": { state: "TX", city: "DALE" },
  "78617": { state: "TX", city: "DEL VALLE" },
  "78618": { state: "TX", city: "DOSS" },
  "78619": { state: "TX", city: "DRIFTWOOD" },
  "78620": { state: "TX", city: "DRIPPING SPRINGS" },
  "78621": { state: "TX", city: "ELGIN" },
  "78622": { state: "TX", city: "FENTRESS" },
  "78623": { state: "TX", city: "FISCHER" },
  "78624": { state: "TX", city: "FREDERICKSBURG" },
  "78626": { state: "TX", city: "GEORGETOWN" },
  "78627": { state: "TX", city: "GEORGETOWN" },
  "78628": { state: "TX", city: "GEORGETOWN" },
  "78629": { state: "TX", city: "GONZALES" },
  "78630": { state: "TX", city: "CEDAR PARK" },
  "78631": { state: "TX", city: "HARPER" },
  "78632": { state: "TX", city: "HARWOOD" },
  "78633": { state: "TX", city: "GEORGETOWN" },
  "78634": { state: "TX", city: "HUTTO" },
  "78635": { state: "TX", city: "HYE" },
  "78636": { state: "TX", city: "JOHNSON CITY" },
  "78638": { state: "TX", city: "KINGSBURY" },
  "78639": { state: "TX", city: "KINGSLAND" },
  "78640": { state: "TX", city: "KYLE" },
  "78641": { state: "TX", city: "LEANDER" },
  "78642": { state: "TX", city: "LIBERTY HILL" },
  "78643": { state: "TX", city: "LLANO" },
  "78644": { state: "TX", city: "LOCKHART" },
  "78645": { state: "TX", city: "LEANDER" },
  "78646": { state: "TX", city: "LEANDER" },
  "78648": { state: "TX", city: "LULING" },
  "78650": { state: "TX", city: "MC DADE" },
  "78651": { state: "TX", city: "MC NEIL" },
  "78652": { state: "TX", city: "MANCHACA" },
  "78653": { state: "TX", city: "MANOR" },
  "78654": { state: "TX", city: "MARBLE FALLS" },
  "78655": { state: "TX", city: "MARTINDALE" },
  "78656": { state: "TX", city: "MAXWELL" },
  "78657": { state: "TX", city: "HORSESHOE BAY" },
  "78658": { state: "TX", city: "OTTINE" },
  "78659": { state: "TX", city: "PAIGE" },
  "78660": { state: "TX", city: "PFLUGERVILLE" },
  "78661": { state: "TX", city: "PRAIRIE LEA" },
  "78662": { state: "TX", city: "RED ROCK" },
  "78663": { state: "TX", city: "ROUND MOUNTAIN" },
  "78664": { state: "TX", city: "ROUND ROCK" },
  "78665": { state: "TX", city: "ROUND ROCK" },
  "78666": { state: "TX", city: "SAN MARCOS" },
  "78667": { state: "TX", city: "SAN MARCOS" },
  "78669": { state: "TX", city: "SPICEWOOD" },
  "78670": { state: "TX", city: "STAPLES" },
  "78671": { state: "TX", city: "STONEWALL" },
  "78672": { state: "TX", city: "TOW" },
  "78673": { state: "TX", city: "WALBURG" },
  "78674": { state: "TX", city: "WEIR" },
  "78675": { state: "TX", city: "WILLOW CITY" },
  "78676": { state: "TX", city: "WIMBERLEY" },
  "78677": { state: "TX", city: "WRIGHTSBORO" },
  "78680": { state: "TX", city: "ROUND ROCK" },
  "78681": { state: "TX", city: "ROUND ROCK" },
  "78682": { state: "TX", city: "ROUND ROCK" },
  "78683": { state: "TX", city: "ROUND ROCK" },
  "78691": { state: "TX", city: "PFLUGERVILLE" },
  "78701": { state: "TX", city: "AUSTIN" },
  "78702": { state: "TX", city: "AUSTIN" },
  "78703": { state: "TX", city: "AUSTIN" },
  "78704": { state: "TX", city: "AUSTIN" },
  "78705": { state: "TX", city: "AUSTIN" },
  "78708": { state: "TX", city: "AUSTIN" },
  "78709": { state: "TX", city: "AUSTIN" },
  "78710": { state: "TX", city: "AUSTIN" },
  "78711": { state: "TX", city: "AUSTIN" },
  "78712": { state: "TX", city: "AUSTIN" },
  "78713": { state: "TX", city: "AUSTIN" },
  "78714": { state: "TX", city: "AUSTIN" },
  "78715": { state: "TX", city: "AUSTIN" },
  "78716": { state: "TX", city: "AUSTIN" },
  "78717": { state: "TX", city: "AUSTIN" },
  "78718": { state: "TX", city: "AUSTIN" },
  "78719": { state: "TX", city: "AUSTIN" },
  "78720": { state: "TX", city: "AUSTIN" },
  "78721": { state: "TX", city: "AUSTIN" },
  "78722": { state: "TX", city: "AUSTIN" },
  "78723": { state: "TX", city: "AUSTIN" },
  "78724": { state: "TX", city: "AUSTIN" },
  "78725": { state: "TX", city: "AUSTIN" },
  "78726": { state: "TX", city: "AUSTIN" },
  "78727": { state: "TX", city: "AUSTIN" },
  "78728": { state: "TX", city: "AUSTIN" },
  "78729": { state: "TX", city: "AUSTIN" },
  "78730": { state: "TX", city: "AUSTIN" },
  "78731": { state: "TX", city: "AUSTIN" },
  "78732": { state: "TX", city: "AUSTIN" },
  "78733": { state: "TX", city: "AUSTIN" },
  "78734": { state: "TX", city: "AUSTIN" },
  "78735": { state: "TX", city: "AUSTIN" },
  "78736": { state: "TX", city: "AUSTIN" },
  "78737": { state: "TX", city: "AUSTIN" },
  "78738": { state: "TX", city: "AUSTIN" },
  "78739": { state: "TX", city: "AUSTIN" },
  "78741": { state: "TX", city: "AUSTIN" },
  "78742": { state: "TX", city: "AUSTIN" },
  "78744": { state: "TX", city: "AUSTIN" },
  "78745": { state: "TX", city: "AUSTIN" },
  "78746": { state: "TX", city: "AUSTIN" },
  "78747": { state: "TX", city: "AUSTIN" },
  "78748": { state: "TX", city: "AUSTIN" },
  "78749": { state: "TX", city: "AUSTIN" },
  "78750": { state: "TX", city: "AUSTIN" },
  "78751": { state: "TX", city: "AUSTIN" },
  "78752": { state: "TX", city: "AUSTIN" },
  "78753": { state: "TX", city: "AUSTIN" },
  "78754": { state: "TX", city: "AUSTIN" },
  "78755": { state: "TX", city: "AUSTIN" },
  "78756": { state: "TX", city: "AUSTIN" },
  "78757": { state: "TX", city: "AUSTIN" },
  "78758": { state: "TX", city: "AUSTIN" },
  "78759": { state: "TX", city: "AUSTIN" },
  "78760": { state: "TX", city: "AUSTIN" },
  "78761": { state: "TX", city: "AUSTIN" },
  "78762": { state: "TX", city: "AUSTIN" },
  "78763": { state: "TX", city: "AUSTIN" },
  "78764": { state: "TX", city: "AUSTIN" },
  "78765": { state: "TX", city: "AUSTIN" },
  "78766": { state: "TX", city: "AUSTIN" },
  "78767": { state: "TX", city: "AUSTIN" },
  "78768": { state: "TX", city: "AUSTIN" },
  "78772": { state: "TX", city: "AUSTIN" },
  "78773": { state: "TX", city: "AUSTIN" },
  "78774": { state: "TX", city: "AUSTIN" },
  "78778": { state: "TX", city: "AUSTIN" },
  "78779": { state: "TX", city: "AUSTIN" },
  "78783": { state: "TX", city: "AUSTIN" },
  "78799": { state: "TX", city: "AUSTIN" },
  "78801": { state: "TX", city: "UVALDE" },
  "78802": { state: "TX", city: "UVALDE" },
  "78827": { state: "TX", city: "ASHERTON" },
  "78828": { state: "TX", city: "BARKSDALE" },
  "78829": { state: "TX", city: "BATESVILLE" },
  "78830": { state: "TX", city: "BIG WELLS" },
  "78832": { state: "TX", city: "BRACKETTVILLE" },
  "78833": { state: "TX", city: "CAMP WOOD" },
  "78834": { state: "TX", city: "CARRIZO SPRINGS" },
  "78836": { state: "TX", city: "CATARINA" },
  "78837": { state: "TX", city: "COMSTOCK" },
  "78838": { state: "TX", city: "CONCAN" },
  "78839": { state: "TX", city: "CRYSTAL CITY" },
  "78840": { state: "TX", city: "DEL RIO" },
  "78841": { state: "TX", city: "DEL RIO" },
  "78842": { state: "TX", city: "DEL RIO" },
  "78843": { state: "TX", city: "LAUGHLIN AFB" },
  "78847": { state: "TX", city: "DEL RIO" },
  "78850": { state: "TX", city: "D HANIS" },
  "78851": { state: "TX", city: "DRYDEN" },
  "78852": { state: "TX", city: "EAGLE PASS" },
  "78853": { state: "TX", city: "EAGLE PASS" },
  "78860": { state: "TX", city: "EL INDIO" },
  "78861": { state: "TX", city: "HONDO" },
  "78870": { state: "TX", city: "KNIPPA" },
  "78871": { state: "TX", city: "LANGTRY" },
  "78872": { state: "TX", city: "LA PRYOR" },
  "78873": { state: "TX", city: "LEAKEY" },
  "78877": { state: "TX", city: "QUEMADO" },
  "78879": { state: "TX", city: "RIO FRIO" },
  "78880": { state: "TX", city: "ROCKSPRINGS" },
  "78881": { state: "TX", city: "SABINAL" },
  "78883": { state: "TX", city: "TARPLEY" },
  "78884": { state: "TX", city: "UTOPIA" },
  "78885": { state: "TX", city: "VANDERPOOL" },
  "78886": { state: "TX", city: "YANCEY" },
  "78931": { state: "TX", city: "BLEIBLERVILLE" },
  "78932": { state: "TX", city: "CARMINE" },
  "78933": { state: "TX", city: "CAT SPRING" },
  "78934": { state: "TX", city: "COLUMBUS" },
  "78935": { state: "TX", city: "ALLEYTON" },
  "78938": { state: "TX", city: "ELLINGER" },
  "78940": { state: "TX", city: "FAYETTEVILLE" },
  "78941": { state: "TX", city: "FLATONIA" },
  "78942": { state: "TX", city: "GIDDINGS" },
  "78943": { state: "TX", city: "GLIDDEN" },
  "78944": { state: "TX", city: "INDUSTRY" },
  "78945": { state: "TX", city: "LA GRANGE" },
  "78946": { state: "TX", city: "LEDBETTER" },
  "78947": { state: "TX", city: "LEXINGTON" },
  "78948": { state: "TX", city: "LINCOLN" },
  "78949": { state: "TX", city: "MULDOON" },
  "78950": { state: "TX", city: "NEW ULM" },
  "78951": { state: "TX", city: "OAKLAND" },
  "78952": { state: "TX", city: "PLUM" },
  "78953": { state: "TX", city: "ROSANKY" },
  "78954": { state: "TX", city: "ROUND TOP" },
  "78956": { state: "TX", city: "SCHULENBURG" },
  "78957": { state: "TX", city: "SMITHVILLE" },
  "78959": { state: "TX", city: "WAELDER" },
  "78960": { state: "TX", city: "WARDA" },
  "78961": { state: "TX", city: "WARRENTON" },
  "78962": { state: "TX", city: "WEIMAR" },
  "78963": { state: "TX", city: "WEST POINT" },
  "79001": { state: "TX", city: "ADRIAN" },
  "79002": { state: "TX", city: "ALANREED" },
  "79003": { state: "TX", city: "ALLISON" },
  "79005": { state: "TX", city: "BOOKER" },
  "79007": { state: "TX", city: "BORGER" },
  "79008": { state: "TX", city: "BORGER" },
  "79009": { state: "TX", city: "BOVINA" },
  "79010": { state: "TX", city: "BOYS RANCH" },
  "79011": { state: "TX", city: "BRISCOE" },
  "79012": { state: "TX", city: "BUSHLAND" },
  "79013": { state: "TX", city: "CACTUS" },
  "79014": { state: "TX", city: "CANADIAN" },
  "79015": { state: "TX", city: "CANYON" },
  "79016": { state: "TX", city: "CANYON" },
  "79018": { state: "TX", city: "CHANNING" },
  "79019": { state: "TX", city: "CLAUDE" },
  "79021": { state: "TX", city: "COTTON CENTER" },
  "79022": { state: "TX", city: "DALHART" },
  "79024": { state: "TX", city: "DARROUZETT" },
  "79025": { state: "TX", city: "DAWN" },
  "79027": { state: "TX", city: "DIMMITT" },
  "79029": { state: "TX", city: "DUMAS" },
  "79031": { state: "TX", city: "EARTH" },
  "79032": { state: "TX", city: "EDMONSON" },
  "79033": { state: "TX", city: "FARNSWORTH" },
  "79034": { state: "TX", city: "FOLLETT" },
  "79035": { state: "TX", city: "FRIONA" },
  "79036": { state: "TX", city: "FRITCH" },
  "79039": { state: "TX", city: "GROOM" },
  "79040": { state: "TX", city: "GRUVER" },
  "79041": { state: "TX", city: "HALE CENTER" },
  "79042": { state: "TX", city: "HAPPY" },
  "79043": { state: "TX", city: "HART" },
  "79044": { state: "TX", city: "HARTLEY" },
  "79045": { state: "TX", city: "HEREFORD" },
  "79046": { state: "TX", city: "HIGGINS" },
  "79051": { state: "TX", city: "KERRICK" },
  "79052": { state: "TX", city: "KRESS" },
  "79053": { state: "TX", city: "LAZBUDDIE" },
  "79054": { state: "TX", city: "LEFORS" },
  "79056": { state: "TX", city: "LIPSCOMB" },
  "79057": { state: "TX", city: "MCLEAN" },
  "79058": { state: "TX", city: "MASTERSON" },
  "79059": { state: "TX", city: "MIAMI" },
  "79061": { state: "TX", city: "MOBEETIE" },
  "79062": { state: "TX", city: "MORSE" },
  "79063": { state: "TX", city: "NAZARETH" },
  "79064": { state: "TX", city: "OLTON" },
  "79065": { state: "TX", city: "PAMPA" },
  "79066": { state: "TX", city: "PAMPA" },
  "79068": { state: "TX", city: "PANHANDLE" },
  "79070": { state: "TX", city: "PERRYTON" },
  "79072": { state: "TX", city: "PLAINVIEW" },
  "79073": { state: "TX", city: "PLAINVIEW" },
  "79077": { state: "TX", city: "SAMNORWOOD" },
  "79078": { state: "TX", city: "SANFORD" },
  "79079": { state: "TX", city: "SHAMROCK" },
  "79080": { state: "TX", city: "SKELLYTOWN" },
  "79081": { state: "TX", city: "SPEARMAN" },
  "79082": { state: "TX", city: "SPRINGLAKE" },
  "79083": { state: "TX", city: "STINNETT" },
  "79084": { state: "TX", city: "STRATFORD" },
  "79085": { state: "TX", city: "SUMMERFIELD" },
  "79086": { state: "TX", city: "SUNRAY" },
  "79087": { state: "TX", city: "TEXLINE" },
  "79088": { state: "TX", city: "TULIA" },
  "79091": { state: "TX", city: "UMBARGER" },
  "79092": { state: "TX", city: "VEGA" },
  "79093": { state: "TX", city: "WAKA" },
  "79094": { state: "TX", city: "WAYSIDE" },
  "79095": { state: "TX", city: "WELLINGTON" },
  "79096": { state: "TX", city: "WHEELER" },
  "79097": { state: "TX", city: "WHITE DEER" },
  "79098": { state: "TX", city: "WILDORADO" },
  "79101": { state: "TX", city: "AMARILLO" },
  "79102": { state: "TX", city: "AMARILLO" },
  "79103": { state: "TX", city: "AMARILLO" },
  "79104": { state: "TX", city: "AMARILLO" },
  "79105": { state: "TX", city: "AMARILLO" },
  "79106": { state: "TX", city: "AMARILLO" },
  "79107": { state: "TX", city: "AMARILLO" },
  "79108": { state: "TX", city: "AMARILLO" },
  "79109": { state: "TX", city: "AMARILLO" },
  "79110": { state: "TX", city: "AMARILLO" },
  "79111": { state: "TX", city: "AMARILLO" },
  "79114": { state: "TX", city: "AMARILLO" },
  "79116": { state: "TX", city: "AMARILLO" },
  "79117": { state: "TX", city: "AMARILLO" },
  "79118": { state: "TX", city: "AMARILLO" },
  "79119": { state: "TX", city: "AMARILLO" },
  "79120": { state: "TX", city: "AMARILLO" },
  "79121": { state: "TX", city: "AMARILLO" },
  "79124": { state: "TX", city: "AMARILLO" },
  "79159": { state: "TX", city: "AMARILLO" },
  "79166": { state: "TX", city: "AMARILLO" },
  "79168": { state: "TX", city: "AMARILLO" },
  "79172": { state: "TX", city: "AMARILLO" },
  "79174": { state: "TX", city: "AMARILLO" },
  "79178": { state: "TX", city: "AMARILLO" },
  "79185": { state: "TX", city: "AMARILLO" },
  "79189": { state: "TX", city: "AMARILLO" },
  "79201": { state: "TX", city: "CHILDRESS" },
  "79220": { state: "TX", city: "AFTON" },
  "79221": { state: "TX", city: "AIKEN" },
  "79223": { state: "TX", city: "CEE VEE" },
  "79225": { state: "TX", city: "CHILLICOTHE" },
  "79226": { state: "TX", city: "CLARENDON" },
  "79227": { state: "TX", city: "CROWELL" },
  "79229": { state: "TX", city: "DICKENS" },
  "79230": { state: "TX", city: "DODSON" },
  "79231": { state: "TX", city: "DOUGHERTY" },
  "79233": { state: "TX", city: "ESTELLINE" },
  "79234": { state: "TX", city: "FLOMOT" },
  "79235": { state: "TX", city: "FLOYDADA" },
  "79236": { state: "TX", city: "GUTHRIE" },
  "79237": { state: "TX", city: "HEDLEY" },
  "79239": { state: "TX", city: "LAKEVIEW" },
  "79240": { state: "TX", city: "LELIA LAKE" },
  "79241": { state: "TX", city: "LOCKNEY" },
  "79243": { state: "TX", city: "MCADOO" },
  "79244": { state: "TX", city: "MATADOR" },
  "79245": { state: "TX", city: "MEMPHIS" },
  "79247": { state: "TX", city: "ODELL" },
  "79248": { state: "TX", city: "PADUCAH" },
  "79250": { state: "TX", city: "PETERSBURG" },
  "79251": { state: "TX", city: "QUAIL" },
  "79252": { state: "TX", city: "QUANAH" },
  "79255": { state: "TX", city: "QUITAQUE" },
  "79256": { state: "TX", city: "ROARING SPRINGS" },
  "79257": { state: "TX", city: "SILVERTON" },
  "79258": { state: "TX", city: "SOUTH PLAINS" },
  "79259": { state: "TX", city: "TELL" },
  "79261": { state: "TX", city: "TURKEY" },
  "79311": { state: "TX", city: "ABERNATHY" },
  "79312": { state: "TX", city: "AMHERST" },
  "79313": { state: "TX", city: "ANTON" },
  "79314": { state: "TX", city: "BLEDSOE" },
  "79316": { state: "TX", city: "BROWNFIELD" },
  "79322": { state: "TX", city: "CROSBYTON" },
  "79323": { state: "TX", city: "DENVER CITY" },
  "79324": { state: "TX", city: "ENOCHS" },
  "79325": { state: "TX", city: "FARWELL" },
  "79326": { state: "TX", city: "FIELDTON" },
  "79329": { state: "TX", city: "IDALOU" },
  "79330": { state: "TX", city: "JUSTICEBURG" },
  "79331": { state: "TX", city: "LAMESA" },
  "79336": { state: "TX", city: "LEVELLAND" },
  "79338": { state: "TX", city: "LEVELLAND" },
  "79339": { state: "TX", city: "LITTLEFIELD" },
  "79342": { state: "TX", city: "LOOP" },
  "79343": { state: "TX", city: "LORENZO" },
  "79344": { state: "TX", city: "MAPLE" },
  "79345": { state: "TX", city: "MEADOW" },
  "79346": { state: "TX", city: "MORTON" },
  "79347": { state: "TX", city: "MULESHOE" },
  "79350": { state: "TX", city: "NEW DEAL" },
  "79351": { state: "TX", city: "ODONNELL" },
  "79353": { state: "TX", city: "PEP" },
  "79355": { state: "TX", city: "PLAINS" },
  "79356": { state: "TX", city: "POST" },
  "79357": { state: "TX", city: "RALLS" },
  "79358": { state: "TX", city: "ROPESVILLE" },
  "79359": { state: "TX", city: "SEAGRAVES" },
  "79360": { state: "TX", city: "SEMINOLE" },
  "79363": { state: "TX", city: "SHALLOWATER" },
  "79364": { state: "TX", city: "SLATON" },
  "79366": { state: "TX", city: "RANSOM CANYON" },
  "79367": { state: "TX", city: "SMYER" },
  "79369": { state: "TX", city: "SPADE" },
  "79370": { state: "TX", city: "SPUR" },
  "79371": { state: "TX", city: "SUDAN" },
  "79372": { state: "TX", city: "SUNDOWN" },
  "79373": { state: "TX", city: "TAHOKA" },
  "79376": { state: "TX", city: "TOKIO" },
  "79377": { state: "TX", city: "WELCH" },
  "79378": { state: "TX", city: "WELLMAN" },
  "79379": { state: "TX", city: "WHITEFACE" },
  "79380": { state: "TX", city: "WHITHARRAL" },
  "79381": { state: "TX", city: "WILSON" },
  "79382": { state: "TX", city: "WOLFFORTH" },
  "79383": { state: "TX", city: "NEW HOME" },
  "79401": { state: "TX", city: "LUBBOCK" },
  "79402": { state: "TX", city: "LUBBOCK" },
  "79403": { state: "TX", city: "LUBBOCK" },
  "79404": { state: "TX", city: "LUBBOCK" },
  "79406": { state: "TX", city: "LUBBOCK" },
  "79407": { state: "TX", city: "LUBBOCK" },
  "79408": { state: "TX", city: "LUBBOCK" },
  "79409": { state: "TX", city: "LUBBOCK" },
  "79410": { state: "TX", city: "LUBBOCK" },
  "79411": { state: "TX", city: "LUBBOCK" },
  "79412": { state: "TX", city: "LUBBOCK" },
  "79413": { state: "TX", city: "LUBBOCK" },
  "79414": { state: "TX", city: "LUBBOCK" },
  "79415": { state: "TX", city: "LUBBOCK" },
  "79416": { state: "TX", city: "LUBBOCK" },
  "79423": { state: "TX", city: "LUBBOCK" },
  "79424": { state: "TX", city: "LUBBOCK" },
  "79430": { state: "TX", city: "LUBBOCK" },
  "79452": { state: "TX", city: "LUBBOCK" },
  "79453": { state: "TX", city: "LUBBOCK" },
  "79457": { state: "TX", city: "LUBBOCK" },
  "79464": { state: "TX", city: "LUBBOCK" },
  "79490": { state: "TX", city: "LUBBOCK" },
  "79491": { state: "TX", city: "LUBBOCK" },
  "79493": { state: "TX", city: "LUBBOCK" },
  "79499": { state: "TX", city: "LUBBOCK" },
  "79501": { state: "TX", city: "ANSON" },
  "79502": { state: "TX", city: "ASPERMONT" },
  "79503": { state: "TX", city: "AVOCA" },
  "79504": { state: "TX", city: "BAIRD" },
  "79505": { state: "TX", city: "BENJAMIN" },
  "79506": { state: "TX", city: "BLACKWELL" },
  "79508": { state: "TX", city: "BUFFALO GAP" },
  "79510": { state: "TX", city: "CLYDE" },
  "79511": { state: "TX", city: "COAHOMA" },
  "79512": { state: "TX", city: "COLORADO CITY" },
  "79516": { state: "TX", city: "DUNN" },
  "79517": { state: "TX", city: "FLUVANNA" },
  "79518": { state: "TX", city: "GIRARD" },
  "79519": { state: "TX", city: "GOLDSBORO" },
  "79520": { state: "TX", city: "HAMLIN" },
  "79521": { state: "TX", city: "HASKELL" },
  "79525": { state: "TX", city: "HAWLEY" },
  "79526": { state: "TX", city: "HERMLEIGH" },
  "79527": { state: "TX", city: "IRA" },
  "79528": { state: "TX", city: "JAYTON" },
  "79529": { state: "TX", city: "KNOX CITY" },
  "79530": { state: "TX", city: "LAWN" },
  "79532": { state: "TX", city: "LORAINE" },
  "79533": { state: "TX", city: "LUEDERS" },
  "79534": { state: "TX", city: "MC CAULLEY" },
  "79535": { state: "TX", city: "MARYNEAL" },
  "79536": { state: "TX", city: "MERKEL" },
  "79537": { state: "TX", city: "NOLAN" },
  "79538": { state: "TX", city: "NOVICE" },
  "79539": { state: "TX", city: "O BRIEN" },
  "79540": { state: "TX", city: "OLD GLORY" },
  "79541": { state: "TX", city: "OVALO" },
  "79543": { state: "TX", city: "ROBY" },
  "79544": { state: "TX", city: "ROCHESTER" },
  "79545": { state: "TX", city: "ROSCOE" },
  "79546": { state: "TX", city: "ROTAN" },
  "79547": { state: "TX", city: "RULE" },
  "79548": { state: "TX", city: "RULE" },
  "79549": { state: "TX", city: "SNYDER" },
  "79550": { state: "TX", city: "SNYDER" },
  "79553": { state: "TX", city: "STAMFORD" },
  "79556": { state: "TX", city: "SWEETWATER" },
  "79560": { state: "TX", city: "SYLVESTER" },
  "79561": { state: "TX", city: "TRENT" },
  "79562": { state: "TX", city: "TUSCOLA" },
  "79563": { state: "TX", city: "TYE" },
  "79565": { state: "TX", city: "WESTBROOK" },
  "79566": { state: "TX", city: "WINGATE" },
  "79567": { state: "TX", city: "WINTERS" },
  "79601": { state: "TX", city: "ABILENE" },
  "79602": { state: "TX", city: "ABILENE" },
  "79603": { state: "TX", city: "ABILENE" },
  "79604": { state: "TX", city: "ABILENE" },
  "79605": { state: "TX", city: "ABILENE" },
  "79606": { state: "TX", city: "ABILENE" },
  "79607": { state: "TX", city: "DYESS AFB" },
  "79608": { state: "TX", city: "ABILENE" },
  "79697": { state: "TX", city: "ABILENE" },
  "79698": { state: "TX", city: "ABILENE" },
  "79699": { state: "TX", city: "ABILENE" },
  "79701": { state: "TX", city: "MIDLAND" },
  "79702": { state: "TX", city: "MIDLAND" },
  "79703": { state: "TX", city: "MIDLAND" },
  "79704": { state: "TX", city: "MIDLAND" },
  "79705": { state: "TX", city: "MIDLAND" },
  "79706": { state: "TX", city: "MIDLAND" },
  "79707": { state: "TX", city: "MIDLAND" },
  "79708": { state: "TX", city: "MIDLAND" },
  "79710": { state: "TX", city: "MIDLAND" },
  "79711": { state: "TX", city: "MIDLAND" },
  "79712": { state: "TX", city: "MIDLAND" },
  "79713": { state: "TX", city: "ACKERLY" },
  "79714": { state: "TX", city: "ANDREWS" },
  "79718": { state: "TX", city: "BALMORHEA" },
  "79719": { state: "TX", city: "BARSTOW" },
  "79720": { state: "TX", city: "BIG SPRING" },
  "79721": { state: "TX", city: "BIG SPRING" },
  "79730": { state: "TX", city: "COYANOSA" },
  "79731": { state: "TX", city: "CRANE" },
  "79733": { state: "TX", city: "FORSAN" },
  "79734": { state: "TX", city: "FORT DAVIS" },
  "79735": { state: "TX", city: "FORT STOCKTON" },
  "79738": { state: "TX", city: "GAIL" },
  "79739": { state: "TX", city: "GARDEN CITY" },
  "79740": { state: "TX", city: "GIRVIN" },
  "79741": { state: "TX", city: "GOLDSMITH" },
  "79742": { state: "TX", city: "GRANDFALLS" },
  "79743": { state: "TX", city: "IMPERIAL" },
  "79744": { state: "TX", city: "IRAAN" },
  "79745": { state: "TX", city: "KERMIT" },
  "79748": { state: "TX", city: "KNOTT" },
  "79749": { state: "TX", city: "LENORAH" },
  "79752": { state: "TX", city: "MC CAMEY" },
  "79754": { state: "TX", city: "MENTONE" },
  "79755": { state: "TX", city: "MIDKIFF" },
  "79756": { state: "TX", city: "MONAHANS" },
  "79758": { state: "TX", city: "GARDENDALE" },
  "79759": { state: "TX", city: "NOTREES" },
  "79760": { state: "TX", city: "ODESSA" },
  "79761": { state: "TX", city: "ODESSA" },
  "79762": { state: "TX", city: "ODESSA" },
  "79763": { state: "TX", city: "ODESSA" },
  "79764": { state: "TX", city: "ODESSA" },
  "79765": { state: "TX", city: "ODESSA" },
  "79766": { state: "TX", city: "ODESSA" },
  "79768": { state: "TX", city: "ODESSA" },
  "79769": { state: "TX", city: "ODESSA" },
  "79770": { state: "TX", city: "ORLA" },
  "79772": { state: "TX", city: "PECOS" },
  "79776": { state: "TX", city: "PENWELL" },
  "79777": { state: "TX", city: "PYOTE" },
  "79778": { state: "TX", city: "RANKIN" },
  "79780": { state: "TX", city: "SARAGOSA" },
  "79781": { state: "TX", city: "SHEFFIELD" },
  "79782": { state: "TX", city: "STANTON" },
  "79783": { state: "TX", city: "TARZAN" },
  "79785": { state: "TX", city: "TOYAH" },
  "79786": { state: "TX", city: "TOYAHVALE" },
  "79788": { state: "TX", city: "WICKETT" },
  "79789": { state: "TX", city: "WINK" },
  "79821": { state: "TX", city: "ANTHONY" },
  "79830": { state: "TX", city: "ALPINE" },
  "79831": { state: "TX", city: "ALPINE" },
  "79832": { state: "TX", city: "ALPINE" },
  "79834": { state: "TX", city: "BIG BEND NATIONAL PARK" },
  "79835": { state: "TX", city: "CANUTILLO" },
  "79836": { state: "TX", city: "CLINT" },
  "79837": { state: "TX", city: "DELL CITY" },
  "79838": { state: "TX", city: "FABENS" },
  "79839": { state: "TX", city: "FORT HANCOCK" },
  "79842": { state: "TX", city: "MARATHON" },
  "79843": { state: "TX", city: "MARFA" },
  "79845": { state: "TX", city: "PRESIDIO" },
  "79846": { state: "TX", city: "REDFORD" },
  "79847": { state: "TX", city: "SALT FLAT" },
  "79848": { state: "TX", city: "SANDERSON" },
  "79849": { state: "TX", city: "SAN ELIZARIO" },
  "79851": { state: "TX", city: "SIERRA BLANCA" },
  "79852": { state: "TX", city: "TERLINGUA" },
  "79853": { state: "TX", city: "TORNILLO" },
  "79854": { state: "TX", city: "VALENTINE" },
  "79855": { state: "TX", city: "VAN HORN" },
  "79901": { state: "TX", city: "EL PASO" },
  "79902": { state: "TX", city: "EL PASO" },
  "79903": { state: "TX", city: "EL PASO" },
  "79904": { state: "TX", city: "EL PASO" },
  "79905": { state: "TX", city: "EL PASO" },
  "79906": { state: "TX", city: "EL PASO" },
  "79907": { state: "TX", city: "EL PASO" },
  "79908": { state: "TX", city: "EL PASO" },
  "79910": { state: "TX", city: "EL PASO" },
  "79911": { state: "TX", city: "EL PASO" },
  "79912": { state: "TX", city: "EL PASO" },
  "79913": { state: "TX", city: "EL PASO" },
  "79914": { state: "TX", city: "EL PASO" },
  "79915": { state: "TX", city: "EL PASO" },
  "79916": { state: "TX", city: "FORT BLISS" },
  "79917": { state: "TX", city: "EL PASO" },
  "79918": { state: "TX", city: "FORT BLISS" },
  "79920": { state: "TX", city: "EL PASO" },
  "79922": { state: "TX", city: "EL PASO" },
  "79923": { state: "TX", city: "EL PASO" },
  "79924": { state: "TX", city: "EL PASO" },
  "79925": { state: "TX", city: "EL PASO" },
  "79926": { state: "TX", city: "EL PASO" },
  "79927": { state: "TX", city: "EL PASO" },
  "79928": { state: "TX", city: "EL PASO" },
  "79929": { state: "TX", city: "EL PASO" },
  "79930": { state: "TX", city: "EL PASO" },
  "79931": { state: "TX", city: "EL PASO" },
  "79932": { state: "TX", city: "EL PASO" },
  "79934": { state: "TX", city: "EL PASO" },
  "79935": { state: "TX", city: "EL PASO" },
  "79936": { state: "TX", city: "EL PASO" },
  "79937": { state: "TX", city: "EL PASO" },
  "79938": { state: "TX", city: "EL PASO" },
  "79940": { state: "TX", city: "EL PASO" },
  "79941": { state: "TX", city: "EL PASO" },
  "79942": { state: "TX", city: "EL PASO" },
  "79943": { state: "TX", city: "EL PASO" },
  "79944": { state: "TX", city: "EL PASO" },
  "79945": { state: "TX", city: "EL PASO" },
  "79946": { state: "TX", city: "EL PASO" },
  "79947": { state: "TX", city: "EL PASO" },
  "79948": { state: "TX", city: "EL PASO" },
  "79949": { state: "TX", city: "EL PASO" },
  "79950": { state: "TX", city: "EL PASO" },
  "79951": { state: "TX", city: "EL PASO" },
  "79952": { state: "TX", city: "EL PASO" },
  "79953": { state: "TX", city: "EL PASO" },
  "79954": { state: "TX", city: "EL PASO" },
  "79955": { state: "TX", city: "EL PASO" },
  "79958": { state: "TX", city: "EL PASO" },
  "79960": { state: "TX", city: "EL PASO" },
  "79961": { state: "TX", city: "EL PASO" },
  "79968": { state: "TX", city: "EL PASO" },
  "79976": { state: "TX", city: "EL PASO" },
  "79978": { state: "TX", city: "EL PASO" },
  "79980": { state: "TX", city: "EL PASO" },
  "79995": { state: "TX", city: "EL PASO" },
  "79996": { state: "TX", city: "EL PASO" },
  "79997": { state: "TX", city: "EL PASO" },
  "79998": { state: "TX", city: "EL PASO" },
  "79999": { state: "TX", city: "EL PASO" },
  "80001": { state: "CO", city: "ARVADA" },
  "80002": { state: "CO", city: "ARVADA" },
  "80003": { state: "CO", city: "ARVADA" },
  "80004": { state: "CO", city: "ARVADA" },
  "80005": { state: "CO", city: "ARVADA" },
  "80006": { state: "CO", city: "ARVADA" },
  "80007": { state: "CO", city: "ARVADA" },
  "80010": { state: "CO", city: "AURORA" },
  "80011": { state: "CO", city: "AURORA" },
  "80012": { state: "CO", city: "AURORA" },
  "80013": { state: "CO", city: "AURORA" },
  "80014": { state: "CO", city: "AURORA" },
  "80015": { state: "CO", city: "AURORA" },
  "80016": { state: "CO", city: "AURORA" },
  "80017": { state: "CO", city: "AURORA" },
  "80018": { state: "CO", city: "AURORA" },
  "80019": { state: "CO", city: "AURORA" },
  "80020": { state: "CO", city: "BROOMFIELD" },
  "80021": { state: "CO", city: "BROOMFIELD" },
  "80022": { state: "CO", city: "COMMERCE CITY" },
  "80023": { state: "CO", city: "BROOMFIELD" },
  "80024": { state: "CO", city: "DUPONT" },
  "80025": { state: "CO", city: "ELDORADO SPRINGS" },
  "80026": { state: "CO", city: "LAFAYETTE" },
  "80027": { state: "CO", city: "LOUISVILLE" },
  "80030": { state: "CO", city: "WESTMINSTER" },
  "80031": { state: "CO", city: "WESTMINSTER" },
  "80033": { state: "CO", city: "WHEAT RIDGE" },
  "80034": { state: "CO", city: "WHEAT RIDGE" },
  "80035": { state: "CO", city: "WESTMINSTER" },
  "80036": { state: "CO", city: "WESTMINSTER" },
  "80037": { state: "CO", city: "COMMERCE CITY" },
  "80038": { state: "CO", city: "BROOMFIELD" },
  "80040": { state: "CO", city: "AURORA" },
  "80041": { state: "CO", city: "AURORA" },
  "80042": { state: "CO", city: "AURORA" },
  "80044": { state: "CO", city: "AURORA" },
  "80045": { state: "CO", city: "AURORA" },
  "80046": { state: "CO", city: "AURORA" },
  "80047": { state: "CO", city: "AURORA" },
  "80101": { state: "CO", city: "AGATE" },
  "80102": { state: "CO", city: "BENNETT" },
  "80103": { state: "CO", city: "BYERS" },
  "80104": { state: "CO", city: "CASTLE ROCK" },
  "80105": { state: "CO", city: "DEER TRAIL" },
  "80106": { state: "CO", city: "ELBERT" },
  "80107": { state: "CO", city: "ELIZABETH" },
  "80108": { state: "CO", city: "CASTLE ROCK" },
  "80109": { state: "CO", city: "CASTLE ROCK" },
  "80110": { state: "CO", city: "ENGLEWOOD" },
  "80111": { state: "CO", city: "ENGLEWOOD" },
  "80112": { state: "CO", city: "ENGLEWOOD" },
  "80113": { state: "CO", city: "ENGLEWOOD" },
  "80116": { state: "CO", city: "FRANKTOWN" },
  "80117": { state: "CO", city: "KIOWA" },
  "80118": { state: "CO", city: "LARKSPUR" },
  "80120": { state: "CO", city: "LITTLETON" },
  "80121": { state: "CO", city: "LITTLETON" },
  "80122": { state: "CO", city: "LITTLETON" },
  "80123": { state: "CO", city: "LITTLETON" },
  "80124": { state: "CO", city: "LONE TREE" },
  "80125": { state: "CO", city: "LITTLETON" },
  "80126": { state: "CO", city: "LITTLETON" },
  "80127": { state: "CO", city: "LITTLETON" },
  "80128": { state: "CO", city: "LITTLETON" },
  "80129": { state: "CO", city: "LITTLETON" },
  "80130": { state: "CO", city: "LITTLETON" },
  "80131": { state: "CO", city: "LOUVIERS" },
  "80132": { state: "CO", city: "MONUMENT" },
  "80133": { state: "CO", city: "PALMER LAKE" },
  "80134": { state: "CO", city: "PARKER" },
  "80135": { state: "CO", city: "SEDALIA" },
  "80136": { state: "CO", city: "STRASBURG" },
  "80137": { state: "CO", city: "WATKINS" },
  "80138": { state: "CO", city: "PARKER" },
  "80150": { state: "CO", city: "ENGLEWOOD" },
  "80151": { state: "CO", city: "ENGLEWOOD" },
  "80155": { state: "CO", city: "ENGLEWOOD" },
  "80160": { state: "CO", city: "LITTLETON" },
  "80161": { state: "CO", city: "LITTLETON" },
  "80162": { state: "CO", city: "LITTLETON" },
  "80163": { state: "CO", city: "LITTLETON" },
  "80165": { state: "CO", city: "LITTLETON" },
  "80166": { state: "CO", city: "LITTLETON" },
  "80201": { state: "CO", city: "DENVER" },
  "80202": { state: "CO", city: "DENVER" },
  "80203": { state: "CO", city: "DENVER" },
  "80204": { state: "CO", city: "DENVER" },
  "80205": { state: "CO", city: "DENVER" },
  "80206": { state: "CO", city: "DENVER" },
  "80207": { state: "CO", city: "DENVER" },
  "80208": { state: "CO", city: "DENVER" },
  "80209": { state: "CO", city: "DENVER" },
  "80210": { state: "CO", city: "DENVER" },
  "80211": { state: "CO", city: "DENVER" },
  "80212": { state: "CO", city: "DENVER" },
  "80214": { state: "CO", city: "DENVER" },
  "80215": { state: "CO", city: "DENVER" },
  "80216": { state: "CO", city: "DENVER" },
  "80217": { state: "CO", city: "DENVER" },
  "80218": { state: "CO", city: "DENVER" },
  "80219": { state: "CO", city: "DENVER" },
  "80220": { state: "CO", city: "DENVER" },
  "80221": { state: "CO", city: "DENVER" },
  "80222": { state: "CO", city: "DENVER" },
  "80223": { state: "CO", city: "DENVER" },
  "80224": { state: "CO", city: "DENVER" },
  "80225": { state: "CO", city: "DENVER" },
  "80226": { state: "CO", city: "DENVER" },
  "80227": { state: "CO", city: "DENVER" },
  "80228": { state: "CO", city: "DENVER" },
  "80229": { state: "CO", city: "DENVER" },
  "80230": { state: "CO", city: "DENVER" },
  "80231": { state: "CO", city: "DENVER" },
  "80232": { state: "CO", city: "DENVER" },
  "80233": { state: "CO", city: "DENVER" },
  "80234": { state: "CO", city: "DENVER" },
  "80235": { state: "CO", city: "DENVER" },
  "80236": { state: "CO", city: "DENVER" },
  "80237": { state: "CO", city: "DENVER" },
  "80238": { state: "CO", city: "DENVER" },
  "80239": { state: "CO", city: "DENVER" },
  "80241": { state: "CO", city: "THORNTON" },
  "80243": { state: "CO", city: "DENVER" },
  "80244": { state: "CO", city: "DENVER" },
  "80246": { state: "CO", city: "DENVER" },
  "80247": { state: "CO", city: "DENVER" },
  "80248": { state: "CO", city: "DENVER" },
  "80249": { state: "CO", city: "DENVER" },
  "80250": { state: "CO", city: "DENVER" },
  "80256": { state: "CO", city: "DENVER" },
  "80257": { state: "CO", city: "DENVER" },
  "80259": { state: "CO", city: "DENVER" },
  "80260": { state: "CO", city: "DENVER" },
  "80261": { state: "CO", city: "DENVER" },
  "80263": { state: "CO", city: "DENVER" },
  "80264": { state: "CO", city: "DENVER" },
  "80265": { state: "CO", city: "DENVER" },
  "80266": { state: "CO", city: "DENVER" },
  "80271": { state: "CO", city: "DENVER" },
  "80273": { state: "CO", city: "DENVER" },
  "80274": { state: "CO", city: "DENVER" },
  "80281": { state: "CO", city: "DENVER" },
  "80290": { state: "CO", city: "DENVER" },
  "80291": { state: "CO", city: "DENVER" },
  "80293": { state: "CO", city: "DENVER" },
  "80294": { state: "CO", city: "DENVER" },
  "80299": { state: "CO", city: "DENVER" },
  "80301": { state: "CO", city: "BOULDER" },
  "80302": { state: "CO", city: "BOULDER" },
  "80303": { state: "CO", city: "BOULDER" },
  "80304": { state: "CO", city: "BOULDER" },
  "80305": { state: "CO", city: "BOULDER" },
  "80306": { state: "CO", city: "BOULDER" },
  "80307": { state: "CO", city: "BOULDER" },
  "80308": { state: "CO", city: "BOULDER" },
  "80309": { state: "CO", city: "BOULDER" },
  "80310": { state: "CO", city: "BOULDER" },
  "80314": { state: "CO", city: "BOULDER" },
  "80401": { state: "CO", city: "GOLDEN" },
  "80402": { state: "CO", city: "GOLDEN" },
  "80403": { state: "CO", city: "GOLDEN" },
  "80419": { state: "CO", city: "GOLDEN" },
  "80420": { state: "CO", city: "ALMA" },
  "80421": { state: "CO", city: "BAILEY" },
  "80422": { state: "CO", city: "BLACK HAWK" },
  "80423": { state: "CO", city: "BOND" },
  "80424": { state: "CO", city: "BRECKENRIDGE" },
  "80425": { state: "CO", city: "BUFFALO CREEK" },
  "80426": { state: "CO", city: "BURNS" },
  "80427": { state: "CO", city: "CENTRAL CITY" },
  "80428": { state: "CO", city: "CLARK" },
  "80429": { state: "CO", city: "CLIMAX" },
  "80430": { state: "CO", city: "COALMONT" },
  "80432": { state: "CO", city: "COMO" },
  "80433": { state: "CO", city: "CONIFER" },
  "80434": { state: "CO", city: "COWDREY" },
  "80435": { state: "CO", city: "DILLON" },
  "80436": { state: "CO", city: "DUMONT" },
  "80437": { state: "CO", city: "EVERGREEN" },
  "80438": { state: "CO", city: "EMPIRE" },
  "80439": { state: "CO", city: "EVERGREEN" },
  "80440": { state: "CO", city: "FAIRPLAY" },
  "80442": { state: "CO", city: "FRASER" },
  "80443": { state: "CO", city: "FRISCO" },
  "80444": { state: "CO", city: "GEORGETOWN" },
  "80446": { state: "CO", city: "GRANBY" },
  "80447": { state: "CO", city: "GRAND LAKE" },
  "80448": { state: "CO", city: "GRANT" },
  "80449": { state: "CO", city: "HARTSEL" },
  "80451": { state: "CO", city: "HOT SULPHUR SPRINGS" },
  "80452": { state: "CO", city: "IDAHO SPRINGS" },
  "80453": { state: "CO", city: "IDLEDALE" },
  "80454": { state: "CO", city: "INDIAN HILLS" },
  "80455": { state: "CO", city: "JAMESTOWN" },
  "80456": { state: "CO", city: "JEFFERSON" },
  "80457": { state: "CO", city: "KITTREDGE" },
  "80459": { state: "CO", city: "KREMMLING" },
  "80461": { state: "CO", city: "LEADVILLE" },
  "80463": { state: "CO", city: "MC COY" },
  "80465": { state: "CO", city: "MORRISON" },
  "80466": { state: "CO", city: "NEDERLAND" },
  "80467": { state: "CO", city: "OAK CREEK" },
  "80468": { state: "CO", city: "PARSHALL" },
  "80469": { state: "CO", city: "PHIPPSBURG" },
  "80470": { state: "CO", city: "PINE" },
  "80471": { state: "CO", city: "PINECLIFFE" },
  "80473": { state: "CO", city: "RAND" },
  "80474": { state: "CO", city: "ROLLINSVILLE" },
  "80475": { state: "CO", city: "SHAWNEE" },
  "80476": { state: "CO", city: "SILVER PLUME" },
  "80477": { state: "CO", city: "STEAMBOAT SPRINGS" },
  "80478": { state: "CO", city: "TABERNASH" },
  "80479": { state: "CO", city: "TOPONAS" },
  "80480": { state: "CO", city: "WALDEN" },
  "80481": { state: "CO", city: "WARD" },
  "80482": { state: "CO", city: "WINTER PARK" },
  "80483": { state: "CO", city: "YAMPA" },
  "80487": { state: "CO", city: "STEAMBOAT SPRINGS" },
  "80488": { state: "CO", city: "STEAMBOAT SPRINGS" },
  "80497": { state: "CO", city: "SILVERTHORNE" },
  "80498": { state: "CO", city: "SILVERTHORNE" },
  "80501": { state: "CO", city: "LONGMONT" },
  "80502": { state: "CO", city: "LONGMONT" },
  "80503": { state: "CO", city: "LONGMONT" },
  "80504": { state: "CO", city: "LONGMONT" },
  "80510": { state: "CO", city: "ALLENSPARK" },
  "80511": { state: "CO", city: "ESTES PARK" },
  "80512": { state: "CO", city: "BELLVUE" },
  "80513": { state: "CO", city: "BERTHOUD" },
  "80514": { state: "CO", city: "DACONO" },
  "80515": { state: "CO", city: "DRAKE" },
  "80516": { state: "CO", city: "ERIE" },
  "80517": { state: "CO", city: "ESTES PARK" },
  "80520": { state: "CO", city: "FIRESTONE" },
  "80521": { state: "CO", city: "FORT COLLINS" },
  "80522": { state: "CO", city: "FORT COLLINS" },
  "80523": { state: "CO", city: "FORT COLLINS" },
  "80524": { state: "CO", city: "FORT COLLINS" },
  "80525": { state: "CO", city: "FORT COLLINS" },
  "80526": { state: "CO", city: "FORT COLLINS" },
  "80527": { state: "CO", city: "FORT COLLINS" },
  "80528": { state: "CO", city: "FORT COLLINS" },
  "80530": { state: "CO", city: "FREDERICK" },
  "80532": { state: "CO", city: "GLEN HAVEN" },
  "80533": { state: "CO", city: "HYGIENE" },
  "80534": { state: "CO", city: "JOHNSTOWN" },
  "80535": { state: "CO", city: "LAPORTE" },
  "80536": { state: "CO", city: "LIVERMORE" },
  "80537": { state: "CO", city: "LOVELAND" },
  "80538": { state: "CO", city: "LOVELAND" },
  "80539": { state: "CO", city: "LOVELAND" },
  "80540": { state: "CO", city: "LYONS" },
  "80541": { state: "CO", city: "MASONVILLE" },
  "80542": { state: "CO", city: "MEAD" },
  "80543": { state: "CO", city: "MILLIKEN" },
  "80544": { state: "CO", city: "NIWOT" },
  "80545": { state: "CO", city: "RED FEATHER LAKES" },
  "80546": { state: "CO", city: "SEVERANCE" },
  "80547": { state: "CO", city: "TIMNATH" },
  "80549": { state: "CO", city: "WELLINGTON" },
  "80550": { state: "CO", city: "WINDSOR" },
  "80551": { state: "CO", city: "WINDSOR" },
  "80553": { state: "CO", city: "FORT COLLINS" },
  "80601": { state: "CO", city: "BRIGHTON" },
  "80602": { state: "CO", city: "BRIGHTON" },
  "80603": { state: "CO", city: "BRIGHTON" },
  "80610": { state: "CO", city: "AULT" },
  "80611": { state: "CO", city: "BRIGGSDALE" },
  "80612": { state: "CO", city: "CARR" },
  "80614": { state: "CO", city: "EASTLAKE" },
  "80615": { state: "CO", city: "EATON" },
  "80620": { state: "CO", city: "EVANS" },
  "80621": { state: "CO", city: "FORT LUPTON" },
  "80622": { state: "CO", city: "GALETON" },
  "80623": { state: "CO", city: "GILCREST" },
  "80624": { state: "CO", city: "GILL" },
  "80631": { state: "CO", city: "GREELEY" },
  "80632": { state: "CO", city: "GREELEY" },
  "80633": { state: "CO", city: "GREELEY" },
  "80634": { state: "CO", city: "GREELEY" },
  "80638": { state: "CO", city: "GREELEY" },
  "80639": { state: "CO", city: "GREELEY" },
  "80640": { state: "CO", city: "HENDERSON" },
  "80642": { state: "CO", city: "HUDSON" },
  "80643": { state: "CO", city: "KEENESBURG" },
  "80644": { state: "CO", city: "KERSEY" },
  "80645": { state: "CO", city: "LA SALLE" },
  "80646": { state: "CO", city: "LUCERNE" },
  "80648": { state: "CO", city: "NUNN" },
  "80649": { state: "CO", city: "ORCHARD" },
  "80650": { state: "CO", city: "PIERCE" },
  "80651": { state: "CO", city: "PLATTEVILLE" },
  "80652": { state: "CO", city: "ROGGEN" },
  "80653": { state: "CO", city: "WELDONA" },
  "80654": { state: "CO", city: "WIGGINS" },
  "80701": { state: "CO", city: "FORT MORGAN" },
  "80705": { state: "CO", city: "LOG LANE VILLAGE" },
  "80720": { state: "CO", city: "AKRON" },
  "80721": { state: "CO", city: "AMHERST" },
  "80722": { state: "CO", city: "ATWOOD" },
  "80723": { state: "CO", city: "BRUSH" },
  "80726": { state: "CO", city: "CROOK" },
  "80727": { state: "CO", city: "ECKLEY" },
  "80728": { state: "CO", city: "FLEMING" },
  "80729": { state: "CO", city: "GROVER" },
  "80731": { state: "CO", city: "HAXTUN" },
  "80732": { state: "CO", city: "HEREFORD" },
  "80733": { state: "CO", city: "HILLROSE" },
  "80734": { state: "CO", city: "HOLYOKE" },
  "80735": { state: "CO", city: "IDALIA" },
  "80736": { state: "CO", city: "ILIFF" },
  "80737": { state: "CO", city: "JULESBURG" },
  "80740": { state: "CO", city: "LINDON" },
  "80741": { state: "CO", city: "MERINO" },
  "80742": { state: "CO", city: "NEW RAYMER" },
  "80743": { state: "CO", city: "OTIS" },
  "80744": { state: "CO", city: "OVID" },
  "80745": { state: "CO", city: "PADRONI" },
  "80746": { state: "CO", city: "PAOLI" },
  "80747": { state: "CO", city: "PEETZ" },
  "80749": { state: "CO", city: "SEDGWICK" },
  "80750": { state: "CO", city: "SNYDER" },
  "80751": { state: "CO", city: "STERLING" },
  "80754": { state: "CO", city: "STONEHAM" },
  "80755": { state: "CO", city: "VERNON" },
  "80757": { state: "CO", city: "WOODROW" },
  "80758": { state: "CO", city: "WRAY" },
  "80759": { state: "CO", city: "YUMA" },
  "80801": { state: "CO", city: "ANTON" },
  "80802": { state: "CO", city: "ARAPAHOE" },
  "80804": { state: "CO", city: "ARRIBA" },
  "80805": { state: "CO", city: "BETHUNE" },
  "80807": { state: "CO", city: "BURLINGTON" },
  "80808": { state: "CO", city: "CALHAN" },
  "80809": { state: "CO", city: "CASCADE" },
  "80810": { state: "CO", city: "CHEYENNE WELLS" },
  "80812": { state: "CO", city: "COPE" },
  "80813": { state: "CO", city: "CRIPPLE CREEK" },
  "80814": { state: "CO", city: "DIVIDE" },
  "80815": { state: "CO", city: "FLAGLER" },
  "80816": { state: "CO", city: "FLORISSANT" },
  "80817": { state: "CO", city: "FOUNTAIN" },
  "80818": { state: "CO", city: "GENOA" },
  "80819": { state: "CO", city: "GREEN MOUNTAIN FALLS" },
  "80820": { state: "CO", city: "GUFFEY" },
  "80821": { state: "CO", city: "HUGO" },
  "80822": { state: "CO", city: "JOES" },
  "80823": { state: "CO", city: "KARVAL" },
  "80824": { state: "CO", city: "KIRK" },
  "80825": { state: "CO", city: "KIT CARSON" },
  "80826": { state: "CO", city: "LIMON" },
  "80827": { state: "CO", city: "LAKE GEORGE" },
  "80828": { state: "CO", city: "LIMON" },
  "80829": { state: "CO", city: "MANITOU SPRINGS" },
  "80830": { state: "CO", city: "MATHESON" },
  "80831": { state: "CO", city: "PEYTON" },
  "80832": { state: "CO", city: "RAMAH" },
  "80833": { state: "CO", city: "RUSH" },
  "80834": { state: "CO", city: "SEIBERT" },
  "80835": { state: "CO", city: "SIMLA" },
  "80836": { state: "CO", city: "STRATTON" },
  "80840": { state: "CO", city: "USAF ACADEMY" },
  "80841": { state: "CO", city: "USAF ACADEMY" },
  "80860": { state: "CO", city: "VICTOR" },
  "80861": { state: "CO", city: "VONA" },
  "80862": { state: "CO", city: "WILD HORSE" },
  "80863": { state: "CO", city: "WOODLAND PARK" },
  "80864": { state: "CO", city: "YODER" },
  "80866": { state: "CO", city: "WOODLAND PARK" },
  "80901": { state: "CO", city: "COLORADO SPRINGS" },
  "80902": { state: "CO", city: "COLORADO SPRINGS" },
  "80903": { state: "CO", city: "COLORADO SPRINGS" },
  "80904": { state: "CO", city: "COLORADO SPRINGS" },
  "80905": { state: "CO", city: "COLORADO SPRINGS" },
  "80906": { state: "CO", city: "COLORADO SPRINGS" },
  "80907": { state: "CO", city: "COLORADO SPRINGS" },
  "80908": { state: "CO", city: "COLORADO SPRINGS" },
  "80909": { state: "CO", city: "COLORADO SPRINGS" },
  "80910": { state: "CO", city: "COLORADO SPRINGS" },
  "80911": { state: "CO", city: "COLORADO SPRINGS" },
  "80912": { state: "CO", city: "COLORADO SPRINGS" },
  "80913": { state: "CO", city: "COLORADO SPRINGS" },
  "80914": { state: "CO", city: "COLORADO SPRINGS" },
  "80915": { state: "CO", city: "COLORADO SPRINGS" },
  "80916": { state: "CO", city: "COLORADO SPRINGS" },
  "80917": { state: "CO", city: "COLORADO SPRINGS" },
  "80918": { state: "CO", city: "COLORADO SPRINGS" },
  "80919": { state: "CO", city: "COLORADO SPRINGS" },
  "80920": { state: "CO", city: "COLORADO SPRINGS" },
  "80921": { state: "CO", city: "COLORADO SPRINGS" },
  "80922": { state: "CO", city: "COLORADO SPRINGS" },
  "80923": { state: "CO", city: "COLORADO SPRINGS" },
  "80924": { state: "CO", city: "COLORADO SPRINGS" },
  "80925": { state: "CO", city: "COLORADO SPRINGS" },
  "80926": { state: "CO", city: "COLORADO SPRINGS" },
  "80927": { state: "CO", city: "COLORADO SPRINGS" },
  "80928": { state: "CO", city: "COLORADO SPRINGS" },
  "80929": { state: "CO", city: "COLORADO SPRINGS" },
  "80930": { state: "CO", city: "COLORADO SPRINGS" },
  "80931": { state: "CO", city: "COLORADO SPRINGS" },
  "80932": { state: "CO", city: "COLORADO SPRINGS" },
  "80933": { state: "CO", city: "COLORADO SPRINGS" },
  "80934": { state: "CO", city: "COLORADO SPRINGS" },
  "80935": { state: "CO", city: "COLORADO SPRINGS" },
  "80936": { state: "CO", city: "COLORADO SPRINGS" },
  "80937": { state: "CO", city: "COLORADO SPRINGS" },
  "80938": { state: "CO", city: "COLORADO SPRINGS" },
  "80939": { state: "CO", city: "COLORADO SPRINGS" },
  "80941": { state: "CO", city: "COLORADO SPRINGS" },
  "80942": { state: "CO", city: "COLORADO SPRINGS" },
  "80946": { state: "CO", city: "COLORADO SPRINGS" },
  "80947": { state: "CO", city: "COLORADO SPRINGS" },
  "80949": { state: "CO", city: "COLORADO SPRINGS" },
  "80950": { state: "CO", city: "COLORADO SPRINGS" },
  "80951": { state: "CO", city: "COLORADO SPRINGS" },
  "80960": { state: "CO", city: "COLORADO SPRINGS" },
  "80962": { state: "CO", city: "COLORADO SPRINGS" },
  "80970": { state: "CO", city: "COLORADO SPRINGS" },
  "80977": { state: "CO", city: "COLORADO SPRINGS" },
  "80995": { state: "CO", city: "COLORADO SPRINGS" },
  "80997": { state: "CO", city: "COLORADO SPRINGS" },
  "81001": { state: "CO", city: "PUEBLO" },
  "81002": { state: "CO", city: "PUEBLO" },
  "81003": { state: "CO", city: "PUEBLO" },
  "81004": { state: "CO", city: "PUEBLO" },
  "81005": { state: "CO", city: "PUEBLO" },
  "81006": { state: "CO", city: "PUEBLO" },
  "81007": { state: "CO", city: "PUEBLO" },
  "81008": { state: "CO", city: "PUEBLO" },
  "81009": { state: "CO", city: "PUEBLO" },
  "81010": { state: "CO", city: "PUEBLO" },
  "81011": { state: "CO", city: "PUEBLO" },
  "81012": { state: "CO", city: "PUEBLO" },
  "81019": { state: "CO", city: "COLORADO CITY" },
  "81020": { state: "CO", city: "AGUILAR" },
  "81021": { state: "CO", city: "ARLINGTON" },
  "81022": { state: "CO", city: "AVONDALE" },
  "81023": { state: "CO", city: "BEULAH" },
  "81024": { state: "CO", city: "BONCARBO" },
  "81025": { state: "CO", city: "BOONE" },
  "81027": { state: "CO", city: "BRANSON" },
  "81029": { state: "CO", city: "CAMPO" },
  "81030": { state: "CO", city: "CHERAW" },
  "81033": { state: "CO", city: "CROWLEY" },
  "81034": { state: "CO", city: "ORDWAY" },
  "81036": { state: "CO", city: "EADS" },
  "81038": { state: "CO", city: "FORT LYON" },
  "81039": { state: "CO", city: "FOWLER" },
  "81040": { state: "CO", city: "GARDNER" },
  "81041": { state: "CO", city: "GRANADA" },
  "81043": { state: "CO", city: "HARTMAN" },
  "81044": { state: "CO", city: "HASTY" },
  "81045": { state: "CO", city: "HASWELL" },
  "81046": { state: "CO", city: "HOEHNE" },
  "81047": { state: "CO", city: "HOLLY" },
  "81049": { state: "CO", city: "KIM" },
  "81050": { state: "CO", city: "LA JUNTA" },
  "81052": { state: "CO", city: "LAMAR" },
  "81054": { state: "CO", city: "LAS ANIMAS" },
  "81055": { state: "CO", city: "LA VETA" },
  "81057": { state: "CO", city: "MC CLAVE" },
  "81058": { state: "CO", city: "MANZANOLA" },
  "81059": { state: "CO", city: "MODEL" },
  "81062": { state: "CO", city: "OLNEY SPRINGS" },
  "81063": { state: "CO", city: "ORDWAY" },
  "81064": { state: "CO", city: "PRITCHETT" },
  "81067": { state: "CO", city: "ROCKY FORD" },
  "81069": { state: "CO", city: "RYE" },
  "81071": { state: "CO", city: "SHERIDAN LAKE" },
  "81073": { state: "CO", city: "SPRINGFIELD" },
  "81076": { state: "CO", city: "SUGAR CITY" },
  "81077": { state: "CO", city: "SWINK" },
  "81081": { state: "CO", city: "TRINCHERA" },
  "81082": { state: "CO", city: "TRINIDAD" },
  "81084": { state: "CO", city: "TWO BUTTES" },
  "81087": { state: "CO", city: "VILAS" },
  "81089": { state: "CO", city: "WALSENBURG" },
  "81090": { state: "CO", city: "WALSH" },
  "81091": { state: "CO", city: "WESTON" },
  "81092": { state: "CO", city: "WILEY" },
  "81101": { state: "CO", city: "ALAMOSA" },
  "81102": { state: "CO", city: "ALAMOSA" },
  "81120": { state: "CO", city: "ANTONITO" },
  "81121": { state: "CO", city: "ARBOLES" },
  "81122": { state: "CO", city: "BAYFIELD" },
  "81123": { state: "CO", city: "BLANCA" },
  "81124": { state: "CO", city: "CAPULIN" },
  "81125": { state: "CO", city: "CENTER" },
  "81126": { state: "CO", city: "CHAMA" },
  "81128": { state: "CO", city: "CHROMO" },
  "81129": { state: "CO", city: "CONEJOS" },
  "81130": { state: "CO", city: "CREEDE" },
  "81131": { state: "CO", city: "CRESTONE" },
  "81132": { state: "CO", city: "DEL NORTE" },
  "81133": { state: "CO", city: "FORT GARLAND" },
  "81135": { state: "CO", city: "HOMELAKE" },
  "81136": { state: "CO", city: "HOOPER" },
  "81137": { state: "CO", city: "IGNACIO" },
  "81138": { state: "CO", city: "JAROSO" },
  "81140": { state: "CO", city: "LA JARA" },
  "81141": { state: "CO", city: "MANASSA" },
  "81143": { state: "CO", city: "MOFFAT" },
  "81144": { state: "CO", city: "MONTE VISTA" },
  "81146": { state: "CO", city: "MOSCA" },
  "81147": { state: "CO", city: "PAGOSA SPRINGS" },
  "81148": { state: "CO", city: "ROMEO" },
  "81149": { state: "CO", city: "SAGUACHE" },
  "81151": { state: "CO", city: "SANFORD" },
  "81152": { state: "CO", city: "SAN LUIS" },
  "81154": { state: "CO", city: "SOUTH FORK" },
  "81155": { state: "CO", city: "VILLA GROVE" },
  "81157": { state: "CO", city: "PAGOSA SPRINGS" },
  "81201": { state: "CO", city: "SALIDA" },
  "81210": { state: "CO", city: "ALMONT" },
  "81211": { state: "CO", city: "BUENA VISTA" },
  "81212": { state: "CO", city: "CANON CITY" },
  "81215": { state: "CO", city: "CANON CITY" },
  "81220": { state: "CO", city: "CIMARRON" },
  "81221": { state: "CO", city: "COAL CREEK" },
  "81222": { state: "CO", city: "COALDALE" },
  "81223": { state: "CO", city: "COTOPAXI" },
  "81224": { state: "CO", city: "CRESTED BUTTE" },
  "81225": { state: "CO", city: "CRESTED BUTTE" },
  "81226": { state: "CO", city: "FLORENCE" },
  "81227": { state: "CO", city: "MONARCH" },
  "81228": { state: "CO", city: "GRANITE" },
  "81230": { state: "CO", city: "GUNNISON" },
  "81231": { state: "CO", city: "GUNNISON" },
  "81232": { state: "CO", city: "HILLSIDE" },
  "81233": { state: "CO", city: "HOWARD" },
  "81235": { state: "CO", city: "LAKE CITY" },
  "81236": { state: "CO", city: "NATHROP" },
  "81237": { state: "CO", city: "OHIO CITY" },
  "81239": { state: "CO", city: "PARLIN" },
  "81240": { state: "CO", city: "PENROSE" },
  "81241": { state: "CO", city: "PITKIN" },
  "81242": { state: "CO", city: "PONCHA SPRINGS" },
  "81243": { state: "CO", city: "POWDERHORN" },
  "81244": { state: "CO", city: "ROCKVALE" },
  "81248": { state: "CO", city: "SARGENTS" },
  "81251": { state: "CO", city: "TWIN LAKES" },
  "81252": { state: "CO", city: "WESTCLIFFE" },
  "81253": { state: "CO", city: "WETMORE" },
  "81290": { state: "CO", city: "FLORENCE" },
  "81301": { state: "CO", city: "DURANGO" },
  "81302": { state: "CO", city: "DURANGO" },
  "81303": { state: "CO", city: "DURANGO" },
  "81320": { state: "CO", city: "CAHONE" },
  "81321": { state: "CO", city: "CORTEZ" },
  "81323": { state: "CO", city: "DOLORES" },
  "81324": { state: "CO", city: "DOVE CREEK" },
  "81325": { state: "CO", city: "EGNAR" },
  "81326": { state: "CO", city: "HESPERUS" },
  "81327": { state: "CO", city: "LEWIS" },
  "81328": { state: "CO", city: "MANCOS" },
  "81329": { state: "CO", city: "MARVEL" },
  "81330": { state: "CO", city: "MESA VERDE NATIONAL PARK" },
  "81331": { state: "CO", city: "PLEASANT VIEW" },
  "81332": { state: "CO", city: "RICO" },
  "81334": { state: "CO", city: "TOWAOC" },
  "81335": { state: "CO", city: "YELLOW JACKET" },
  "81401": { state: "CO", city: "MONTROSE" },
  "81402": { state: "CO", city: "MONTROSE" },
  "81403": { state: "CO", city: "MONTROSE" },
  "81410": { state: "CO", city: "AUSTIN" },
  "81411": { state: "CO", city: "BEDROCK" },
  "81413": { state: "CO", city: "CEDAREDGE" },
  "81414": { state: "CO", city: "CORY" },
  "81415": { state: "CO", city: "CRAWFORD" },
  "81416": { state: "CO", city: "DELTA" },
  "81418": { state: "CO", city: "ECKERT" },
  "81419": { state: "CO", city: "HOTCHKISS" },
  "81420": { state: "CO", city: "LAZEAR" },
  "81422": { state: "CO", city: "NATURITA" },
  "81423": { state: "CO", city: "NORWOOD" },
  "81424": { state: "CO", city: "NUCLA" },
  "81425": { state: "CO", city: "OLATHE" },
  "81426": { state: "CO", city: "OPHIR" },
  "81427": { state: "CO", city: "OURAY" },
  "81428": { state: "CO", city: "PAONIA" },
  "81429": { state: "CO", city: "PARADOX" },
  "81430": { state: "CO", city: "PLACERVILLE" },
  "81431": { state: "CO", city: "REDVALE" },
  "81432": { state: "CO", city: "RIDGWAY" },
  "81433": { state: "CO", city: "SILVERTON" },
  "81434": { state: "CO", city: "SOMERSET" },
  "81435": { state: "CO", city: "TELLURIDE" },
  "81501": { state: "CO", city: "GRAND JUNCTION" },
  "81502": { state: "CO", city: "GRAND JUNCTION" },
  "81503": { state: "CO", city: "GRAND JUNCTION" },
  "81504": { state: "CO", city: "GRAND JUNCTION" },
  "81505": { state: "CO", city: "GRAND JUNCTION" },
  "81506": { state: "CO", city: "GRAND JUNCTION" },
  "81507": { state: "CO", city: "GRAND JUNCTION" },
  "81520": { state: "CO", city: "CLIFTON" },
  "81521": { state: "CO", city: "FRUITA" },
  "81522": { state: "CO", city: "GATEWAY" },
  "81523": { state: "CO", city: "GLADE PARK" },
  "81524": { state: "CO", city: "LOMA" },
  "81525": { state: "CO", city: "MACK" },
  "81526": { state: "CO", city: "PALISADE" },
  "81527": { state: "CO", city: "WHITEWATER" },
  "81601": { state: "CO", city: "GLENWOOD SPRINGS" },
  "81602": { state: "CO", city: "GLENWOOD SPRINGS" },
  "81610": { state: "CO", city: "DINOSAUR" },
  "81611": { state: "CO", city: "ASPEN" },
  "81612": { state: "CO", city: "ASPEN" },
  "81615": { state: "CO", city: "SNOWMASS VILLAGE" },
  "81620": { state: "CO", city: "AVON" },
  "81621": { state: "CO", city: "BASALT" },
  "81623": { state: "CO", city: "CARBONDALE" },
  "81624": { state: "CO", city: "COLLBRAN" },
  "81625": { state: "CO", city: "CRAIG" },
  "81626": { state: "CO", city: "CRAIG" },
  "81630": { state: "CO", city: "DE BEQUE" },
  "81631": { state: "CO", city: "EAGLE" },
  "81632": { state: "CO", city: "EDWARDS" },
  "81633": { state: "CO", city: "DINOSAUR" },
  "81635": { state: "CO", city: "PARACHUTE" },
  "81636": { state: "CO", city: "BATTLEMENT MESA" },
  "81637": { state: "CO", city: "GYPSUM" },
  "81638": { state: "CO", city: "HAMILTON" },
  "81639": { state: "CO", city: "HAYDEN" },
  "81640": { state: "CO", city: "MAYBELL" },
  "81641": { state: "CO", city: "MEEKER" },
  "81642": { state: "CO", city: "MEREDITH" },
  "81643": { state: "CO", city: "MESA" },
  "81645": { state: "CO", city: "MINTURN" },
  "81646": { state: "CO", city: "MOLINA" },
  "81647": { state: "CO", city: "NEW CASTLE" },
  "81648": { state: "CO", city: "RANGELY" },
  "81649": { state: "CO", city: "RED CLIFF" },
  "81650": { state: "CO", city: "RIFLE" },
  "81652": { state: "CO", city: "SILT" },
  "81653": { state: "CO", city: "SLATER" },
  "81654": { state: "CO", city: "SNOWMASS" },
  "81655": { state: "CO", city: "WOLCOTT" },
  "81656": { state: "CO", city: "WOODY CREEK" },
  "81657": { state: "CO", city: "VAIL" },
  "81658": { state: "CO", city: "VAIL" },
  "82001": { state: "WY", city: "CHEYENNE" },
  "82002": { state: "WY", city: "CHEYENNE" },
  "82003": { state: "WY", city: "CHEYENNE" },
  "82005": { state: "WY", city: "FE WARREN AFB" },
  "82006": { state: "WY", city: "CHEYENNE" },
  "82007": { state: "WY", city: "CHEYENNE" },
  "82009": { state: "WY", city: "CHEYENNE" },
  "82010": { state: "WY", city: "CHEYENNE" },
  "82050": { state: "WY", city: "ALBIN" },
  "82051": { state: "WY", city: "BOSLER" },
  "82052": { state: "WY", city: "BUFORD" },
  "82053": { state: "WY", city: "BURNS" },
  "82054": { state: "WY", city: "CARPENTER" },
  "82055": { state: "WY", city: "CENTENNIAL" },
  "82058": { state: "WY", city: "GARRETT" },
  "82059": { state: "WY", city: "GRANITE CANON" },
  "82060": { state: "WY", city: "HILLSDALE" },
  "82061": { state: "WY", city: "HORSE CREEK" },
  "82063": { state: "WY", city: "JELM" },
  "82070": { state: "WY", city: "LARAMIE" },
  "82071": { state: "WY", city: "LARAMIE" },
  "82072": { state: "WY", city: "LARAMIE" },
  "82073": { state: "WY", city: "LARAMIE" },
  "82081": { state: "WY", city: "MERIDEN" },
  "82082": { state: "WY", city: "PINE BLUFFS" },
  "82083": { state: "WY", city: "ROCK RIVER" },
  "82084": { state: "WY", city: "TIE SIDING" },
  "82190": { state: "WY", city: "YELLOWSTONE NATIONAL PARK" },
  "82201": { state: "WY", city: "WHEATLAND" },
  "82210": { state: "WY", city: "CHUGWATER" },
  "82212": { state: "WY", city: "FORT LARAMIE" },
  "82213": { state: "WY", city: "GLENDO" },
  "82214": { state: "WY", city: "GUERNSEY" },
  "82215": { state: "WY", city: "HARTVILLE" },
  "82217": { state: "WY", city: "HAWK SPRINGS" },
  "82218": { state: "WY", city: "HUNTLEY" },
  "82219": { state: "WY", city: "JAY EM" },
  "82221": { state: "WY", city: "LAGRANGE" },
  "82222": { state: "WY", city: "LANCE CREEK" },
  "82223": { state: "WY", city: "LINGLE" },
  "82224": { state: "WY", city: "LOST SPRINGS" },
  "82225": { state: "WY", city: "LUSK" },
  "82227": { state: "WY", city: "MANVILLE" },
  "82229": { state: "WY", city: "SHAWNEE" },
  "82240": { state: "WY", city: "TORRINGTON" },
  "82242": { state: "WY", city: "VAN TASSELL" },
  "82243": { state: "WY", city: "VETERAN" },
  "82244": { state: "WY", city: "YODER" },
  "82301": { state: "WY", city: "RAWLINS" },
  "82310": { state: "WY", city: "JEFFREY CITY" },
  "82321": { state: "WY", city: "BAGGS" },
  "82322": { state: "WY", city: "BAIROIL" },
  "82323": { state: "WY", city: "DIXON" },
  "82324": { state: "WY", city: "ELK MOUNTAIN" },
  "82325": { state: "WY", city: "ENCAMPMENT" },
  "82327": { state: "WY", city: "HANNA" },
  "82329": { state: "WY", city: "MEDICINE BOW" },
  "82331": { state: "WY", city: "SARATOGA" },
  "82332": { state: "WY", city: "SAVERY" },
  "82334": { state: "WY", city: "SINCLAIR" },
  "82335": { state: "WY", city: "WALCOTT" },
  "82336": { state: "WY", city: "WAMSUTTER" },
  "82401": { state: "WY", city: "WORLAND" },
  "82410": { state: "WY", city: "BASIN" },
  "82411": { state: "WY", city: "BURLINGTON" },
  "82412": { state: "WY", city: "BYRON" },
  "82414": { state: "WY", city: "CODY" },
  "82420": { state: "WY", city: "COWLEY" },
  "82421": { state: "WY", city: "DEAVER" },
  "82422": { state: "WY", city: "EMBLEM" },
  "82423": { state: "WY", city: "FRANNIE" },
  "82426": { state: "WY", city: "GREYBULL" },
  "82428": { state: "WY", city: "HYATTVILLE" },
  "82430": { state: "WY", city: "KIRBY" },
  "82431": { state: "WY", city: "LOVELL" },
  "82432": { state: "WY", city: "MANDERSON" },
  "82433": { state: "WY", city: "MEETEETSE" },
  "82434": { state: "WY", city: "OTTO" },
  "82435": { state: "WY", city: "POWELL" },
  "82440": { state: "WY", city: "RALSTON" },
  "82441": { state: "WY", city: "SHELL" },
  "82442": { state: "WY", city: "TEN SLEEP" },
  "82443": { state: "WY", city: "THERMOPOLIS" },
  "82450": { state: "WY", city: "WAPITI" },
  "82501": { state: "WY", city: "RIVERTON" },
  "82510": { state: "WY", city: "ARAPAHOE" },
  "82512": { state: "WY", city: "CROWHEART" },
  "82513": { state: "WY", city: "DUBOIS" },
  "82514": { state: "WY", city: "FORT WASHAKIE" },
  "82515": { state: "WY", city: "HUDSON" },
  "82516": { state: "WY", city: "KINNEAR" },
  "82520": { state: "WY", city: "LANDER" },
  "82523": { state: "WY", city: "PAVILLION" },
  "82524": { state: "WY", city: "SAINT STEPHENS" },
  "82601": { state: "WY", city: "CASPER" },
  "82602": { state: "WY", city: "CASPER" },
  "82604": { state: "WY", city: "CASPER" },
  "82605": { state: "WY", city: "CASPER" },
  "82609": { state: "WY", city: "CASPER" },
  "82615": { state: "WY", city: "SHIRLEY BASIN" },
  "82620": { state: "WY", city: "ALCOVA" },
  "82630": { state: "WY", city: "ARMINTO" },
  "82633": { state: "WY", city: "DOUGLAS" },
  "82635": { state: "WY", city: "EDGERTON" },
  "82636": { state: "WY", city: "EVANSVILLE" },
  "82637": { state: "WY", city: "GLENROCK" },
  "82638": { state: "WY", city: "HILAND" },
  "82639": { state: "WY", city: "KAYCEE" },
  "82640": { state: "WY", city: "LINCH" },
  "82642": { state: "WY", city: "LYSITE" },
  "82643": { state: "WY", city: "MIDWEST" },
  "82644": { state: "WY", city: "MILLS" },
  "82646": { state: "WY", city: "NATRONA" },
  "82648": { state: "WY", city: "POWDER RIVER" },
  "82649": { state: "WY", city: "SHOSHONI" },
  "82701": { state: "WY", city: "NEWCASTLE" },
  "82710": { state: "WY", city: "ALADDIN" },
  "82711": { state: "WY", city: "ALVA" },
  "82712": { state: "WY", city: "BEULAH" },
  "82714": { state: "WY", city: "DEVILS TOWER" },
  "82715": { state: "WY", city: "FOUR CORNERS" },
  "82716": { state: "WY", city: "GILLETTE" },
  "82717": { state: "WY", city: "GILLETTE" },
  "82718": { state: "WY", city: "GILLETTE" },
  "82720": { state: "WY", city: "HULETT" },
  "82721": { state: "WY", city: "MOORCROFT" },
  "82723": { state: "WY", city: "OSAGE" },
  "82725": { state: "WY", city: "RECLUSE" },
  "82727": { state: "WY", city: "ROZET" },
  "82729": { state: "WY", city: "SUNDANCE" },
  "82730": { state: "WY", city: "UPTON" },
  "82731": { state: "WY", city: "WESTON" },
  "82732": { state: "WY", city: "WRIGHT" },
  "82801": { state: "WY", city: "SHERIDAN" },
  "82831": { state: "WY", city: "ARVADA" },
  "82832": { state: "WY", city: "BANNER" },
  "82833": { state: "WY", city: "BIG HORN" },
  "82834": { state: "WY", city: "BUFFALO" },
  "82835": { state: "WY", city: "CLEARMONT" },
  "82836": { state: "WY", city: "DAYTON" },
  "82837": { state: "WY", city: "LEITER" },
  "82838": { state: "WY", city: "PARKMAN" },
  "82839": { state: "WY", city: "RANCHESTER" },
  "82840": { state: "WY", city: "SADDLESTRING" },
  "82842": { state: "WY", city: "STORY" },
  "82844": { state: "WY", city: "WOLF" },
  "82845": { state: "WY", city: "WYARNO" },
  "82901": { state: "WY", city: "ROCK SPRINGS" },
  "82902": { state: "WY", city: "ROCK SPRINGS" },
  "82922": { state: "WY", city: "BONDURANT" },
  "82923": { state: "WY", city: "BOULDER" },
  "82925": { state: "WY", city: "CORA" },
  "82929": { state: "WY", city: "LITTLE AMERICA" },
  "82930": { state: "WY", city: "EVANSTON" },
  "82931": { state: "WY", city: "EVANSTON" },
  "82932": { state: "WY", city: "FARSON" },
  "82933": { state: "WY", city: "FORT BRIDGER" },
  "82934": { state: "WY", city: "GRANGER" },
  "82935": { state: "WY", city: "GREEN RIVER" },
  "82936": { state: "WY", city: "LONETREE" },
  "82937": { state: "WY", city: "LYMAN" },
  "82938": { state: "WY", city: "MC KINNON" },
  "82939": { state: "WY", city: "MOUNTAIN VIEW" },
  "82941": { state: "WY", city: "PINEDALE" },
  "82942": { state: "WY", city: "POINT OF ROCKS" },
  "82943": { state: "WY", city: "RELIANCE" },
  "82944": { state: "WY", city: "ROBERTSON" },
  "82945": { state: "WY", city: "SUPERIOR" },
  "83001": { state: "WY", city: "JACKSON" },
  "83002": { state: "WY", city: "JACKSON" },
  "83011": { state: "WY", city: "KELLY" },
  "83012": { state: "WY", city: "MOOSE" },
  "83013": { state: "WY", city: "MORAN" },
  "83014": { state: "WY", city: "WILSON" },
  "83025": { state: "WY", city: "TETON VILLAGE" },
  "83101": { state: "WY", city: "KEMMERER" },
  "83110": { state: "WY", city: "AFTON" },
  "83111": { state: "WY", city: "AUBURN" },
  "83112": { state: "WY", city: "BEDFORD" },
  "83113": { state: "WY", city: "BIG PINEY" },
  "83114": { state: "WY", city: "COKEVILLE" },
  "83115": { state: "WY", city: "DANIEL" },
  "83116": { state: "WY", city: "DIAMONDVILLE" },
  "83118": { state: "WY", city: "ETNA" },
  "83119": { state: "WY", city: "FAIRVIEW" },
  "83120": { state: "WY", city: "FREEDOM" },
  "83121": { state: "WY", city: "FRONTIER" },
  "83122": { state: "WY", city: "GROVER" },
  "83123": { state: "WY", city: "LA BARGE" },
  "83124": { state: "WY", city: "OPAL" },
  "83126": { state: "WY", city: "SMOOT" },
  "83127": { state: "WY", city: "THAYNE" },
  "83128": { state: "WY", city: "ALPINE" },
  "83201": { state: "ID", city: "POCATELLO" },
  "83202": { state: "ID", city: "POCATELLO" },
  "83203": { state: "ID", city: "FORT HALL" },
  "83204": { state: "ID", city: "POCATELLO" },
  "83205": { state: "ID", city: "POCATELLO" },
  "83206": { state: "ID", city: "POCATELLO" },
  "83209": { state: "ID", city: "POCATELLO" },
  "83210": { state: "ID", city: "ABERDEEN" },
  "83211": { state: "ID", city: "AMERICAN FALLS" },
  "83212": { state: "ID", city: "ARBON" },
  "83213": { state: "ID", city: "ARCO" },
  "83214": { state: "ID", city: "ARIMO" },
  "83215": { state: "ID", city: "ATOMIC CITY" },
  "83217": { state: "ID", city: "BANCROFT" },
  "83218": { state: "ID", city: "BASALT" },
  "83220": { state: "ID", city: "BERN" },
  "83221": { state: "ID", city: "BLACKFOOT" },
  "83223": { state: "ID", city: "BLOOMINGTON" },
  "83226": { state: "ID", city: "CHALLIS" },
  "83227": { state: "ID", city: "CLAYTON" },
  "83228": { state: "ID", city: "CLIFTON" },
  "83229": { state: "ID", city: "COBALT" },
  "83232": { state: "ID", city: "DAYTON" },
  "83233": { state: "ID", city: "DINGLE" },
  "83234": { state: "ID", city: "DOWNEY" },
  "83235": { state: "ID", city: "ELLIS" },
  "83236": { state: "ID", city: "FIRTH" },
  "83237": { state: "ID", city: "FRANKLIN" },
  "83238": { state: "ID", city: "GENEVA" },
  "83239": { state: "ID", city: "GEORGETOWN" },
  "83241": { state: "ID", city: "GRACE" },
  "83243": { state: "ID", city: "HOLBROOK" },
  "83244": { state: "ID", city: "HOWE" },
  "83245": { state: "ID", city: "INKOM" },
  "83246": { state: "ID", city: "LAVA HOT SPRINGS" },
  "83250": { state: "ID", city: "MCCAMMON" },
  "83251": { state: "ID", city: "MACKAY" },
  "83252": { state: "ID", city: "MALAD CITY" },
  "83253": { state: "ID", city: "MAY" },
  "83254": { state: "ID", city: "MONTPELIER" },
  "83255": { state: "ID", city: "MOORE" },
  "83256": { state: "ID", city: "MORELAND" },
  "83261": { state: "ID", city: "PARIS" },
  "83262": { state: "ID", city: "PINGREE" },
  "83263": { state: "ID", city: "PRESTON" },
  "83271": { state: "ID", city: "ROCKLAND" },
  "83272": { state: "ID", city: "SAINT CHARLES" },
  "83274": { state: "ID", city: "SHELLEY" },
  "83276": { state: "ID", city: "SODA SPRINGS" },
  "83277": { state: "ID", city: "SPRINGFIELD" },
  "83278": { state: "ID", city: "STANLEY" },
  "83281": { state: "ID", city: "SWANLAKE" },
  "83283": { state: "ID", city: "THATCHER" },
  "83285": { state: "ID", city: "WAYAN" },
  "83286": { state: "ID", city: "WESTON" },
  "83287": { state: "ID", city: "FISH HAVEN" },
  "83301": { state: "ID", city: "TWIN FALLS" },
  "83302": { state: "ID", city: "ROGERSON" },
  "83303": { state: "ID", city: "TWIN FALLS" },
  "83311": { state: "ID", city: "ALBION" },
  "83312": { state: "ID", city: "ALMO" },
  "83313": { state: "ID", city: "BELLEVUE" },
  "83314": { state: "ID", city: "BLISS" },
  "83316": { state: "ID", city: "BUHL" },
  "83318": { state: "ID", city: "BURLEY" },
  "83320": { state: "ID", city: "CAREY" },
  "83321": { state: "ID", city: "CASTLEFORD" },
  "83322": { state: "ID", city: "CORRAL" },
  "83323": { state: "ID", city: "DECLO" },
  "83324": { state: "ID", city: "DIETRICH" },
  "83325": { state: "ID", city: "EDEN" },
  "83327": { state: "ID", city: "FAIRFIELD" },
  "83328": { state: "ID", city: "FILER" },
  "83330": { state: "ID", city: "GOODING" },
  "83332": { state: "ID", city: "HAGERMAN" },
  "83333": { state: "ID", city: "HAILEY" },
  "83334": { state: "ID", city: "HANSEN" },
  "83335": { state: "ID", city: "HAZELTON" },
  "83336": { state: "ID", city: "HEYBURN" },
  "83337": { state: "ID", city: "HILL CITY" },
  "83338": { state: "ID", city: "JEROME" },
  "83340": { state: "ID", city: "KETCHUM" },
  "83341": { state: "ID", city: "KIMBERLY" },
  "83342": { state: "ID", city: "MALTA" },
  "83343": { state: "ID", city: "MINIDOKA" },
  "83344": { state: "ID", city: "MURTAUGH" },
  "83346": { state: "ID", city: "OAKLEY" },
  "83347": { state: "ID", city: "PAUL" },
  "83348": { state: "ID", city: "PICABO" },
  "83349": { state: "ID", city: "RICHFIELD" },
  "83350": { state: "ID", city: "RUPERT" },
  "83352": { state: "ID", city: "SHOSHONE" },
  "83353": { state: "ID", city: "SUN VALLEY" },
  "83354": { state: "ID", city: "SUN VALLEY" },
  "83355": { state: "ID", city: "WENDELL" },
  "83401": { state: "ID", city: "IDAHO FALLS" },
  "83402": { state: "ID", city: "IDAHO FALLS" },
  "83403": { state: "ID", city: "IDAHO FALLS" },
  "83404": { state: "ID", city: "IDAHO FALLS" },
  "83405": { state: "ID", city: "IDAHO FALLS" },
  "83406": { state: "ID", city: "IDAHO FALLS" },
  "83414": { state: "WY", city: "ALTA" },
  "83415": { state: "ID", city: "IDAHO FALLS" },
  "83420": { state: "ID", city: "ASHTON" },
  "83421": { state: "ID", city: "CHESTER" },
  "83422": { state: "ID", city: "DRIGGS" },
  "83423": { state: "ID", city: "DUBOIS" },
  "83424": { state: "ID", city: "FELT" },
  "83425": { state: "ID", city: "HAMER" },
  "83427": { state: "ID", city: "IONA" },
  "83428": { state: "ID", city: "IRWIN" },
  "83429": { state: "ID", city: "ISLAND PARK" },
  "83431": { state: "ID", city: "LEWISVILLE" },
  "83433": { state: "ID", city: "MACKS INN" },
  "83434": { state: "ID", city: "MENAN" },
  "83435": { state: "ID", city: "MONTEVIEW" },
  "83436": { state: "ID", city: "NEWDALE" },
  "83438": { state: "ID", city: "PARKER" },
  "83440": { state: "ID", city: "REXBURG" },
  "83441": { state: "ID", city: "REXBURG" },
  "83442": { state: "ID", city: "RIGBY" },
  "83443": { state: "ID", city: "RIRIE" },
  "83444": { state: "ID", city: "ROBERTS" },
  "83445": { state: "ID", city: "SAINT ANTHONY" },
  "83446": { state: "ID", city: "SPENCER" },
  "83448": { state: "ID", city: "SUGAR CITY" },
  "83449": { state: "ID", city: "SWAN VALLEY" },
  "83450": { state: "ID", city: "TERRETON" },
  "83451": { state: "ID", city: "TETON" },
  "83452": { state: "ID", city: "TETONIA" },
  "83454": { state: "ID", city: "UCON" },
  "83455": { state: "ID", city: "VICTOR" },
  "83460": { state: "ID", city: "REXBURG" },
  "83462": { state: "ID", city: "CARMEN" },
  "83463": { state: "ID", city: "GIBBONSVILLE" },
  "83464": { state: "ID", city: "LEADORE" },
  "83465": { state: "ID", city: "LEMHI" },
  "83466": { state: "ID", city: "NORTH FORK" },
  "83467": { state: "ID", city: "SALMON" },
  "83468": { state: "ID", city: "TENDOY" },
  "83469": { state: "ID", city: "SHOUP" },
  "83501": { state: "ID", city: "LEWISTON" },
  "83520": { state: "ID", city: "AHSAHKA" },
  "83522": { state: "ID", city: "COTTONWOOD" },
  "83523": { state: "ID", city: "CRAIGMONT" },
  "83524": { state: "ID", city: "CULDESAC" },
  "83525": { state: "ID", city: "ELK CITY" },
  "83526": { state: "ID", city: "FERDINAND" },
  "83530": { state: "ID", city: "GRANGEVILLE" },
  "83531": { state: "ID", city: "FENN" },
  "83533": { state: "ID", city: "GREENCREEK" },
  "83535": { state: "ID", city: "JULIAETTA" },
  "83536": { state: "ID", city: "KAMIAH" },
  "83537": { state: "ID", city: "KENDRICK" },
  "83539": { state: "ID", city: "KOOSKIA" },
  "83540": { state: "ID", city: "LAPWAI" },
  "83541": { state: "ID", city: "LENORE" },
  "83542": { state: "ID", city: "LUCILE" },
  "83543": { state: "ID", city: "NEZPERCE" },
  "83544": { state: "ID", city: "OROFINO" },
  "83545": { state: "ID", city: "PECK" },
  "83546": { state: "ID", city: "PIERCE" },
  "83547": { state: "ID", city: "POLLOCK" },
  "83548": { state: "ID", city: "REUBENS" },
  "83549": { state: "ID", city: "RIGGINS" },
  "83552": { state: "ID", city: "STITES" },
  "83553": { state: "ID", city: "WEIPPE" },
  "83554": { state: "ID", city: "WHITE BIRD" },
  "83555": { state: "ID", city: "WINCHESTER" },
  "83602": { state: "ID", city: "BANKS" },
  "83604": { state: "ID", city: "BRUNEAU" },
  "83605": { state: "ID", city: "CALDWELL" },
  "83606": { state: "ID", city: "CALDWELL" },
  "83607": { state: "ID", city: "CALDWELL" },
  "83610": { state: "ID", city: "CAMBRIDGE" },
  "83611": { state: "ID", city: "CASCADE" },
  "83612": { state: "ID", city: "COUNCIL" },
  "83615": { state: "ID", city: "DONNELLY" },
  "83616": { state: "ID", city: "EAGLE" },
  "83617": { state: "ID", city: "EMMETT" },
  "83619": { state: "ID", city: "FRUITLAND" },
  "83622": { state: "ID", city: "GARDEN VALLEY" },
  "83623": { state: "ID", city: "GLENNS FERRY" },
  "83624": { state: "ID", city: "GRAND VIEW" },
  "83626": { state: "ID", city: "GREENLEAF" },
  "83627": { state: "ID", city: "HAMMETT" },
  "83628": { state: "ID", city: "HOMEDALE" },
  "83629": { state: "ID", city: "HORSESHOE BEND" },
  "83630": { state: "ID", city: "HUSTON" },
  "83631": { state: "ID", city: "IDAHO CITY" },
  "83632": { state: "ID", city: "INDIAN VALLEY" },
  "83633": { state: "ID", city: "KING HILL" },
  "83634": { state: "ID", city: "KUNA" },
  "83635": { state: "ID", city: "LAKE FORK" },
  "83636": { state: "ID", city: "LETHA" },
  "83637": { state: "ID", city: "LOWMAN" },
  "83638": { state: "ID", city: "MCCALL" },
  "83639": { state: "ID", city: "MARSING" },
  "83641": { state: "ID", city: "MELBA" },
  "83642": { state: "ID", city: "MERIDIAN" },
  "83643": { state: "ID", city: "MESA" },
  "83644": { state: "ID", city: "MIDDLETON" },
  "83645": { state: "ID", city: "MIDVALE" },
  "83646": { state: "ID", city: "MERIDIAN" },
  "83647": { state: "ID", city: "MOUNTAIN HOME" },
  "83648": { state: "ID", city: "MOUNTAIN HOME AFB" },
  "83650": { state: "ID", city: "MURPHY" },
  "83651": { state: "ID", city: "NAMPA" },
  "83652": { state: "ID", city: "NAMPA" },
  "83653": { state: "ID", city: "NAMPA" },
  "83654": { state: "ID", city: "NEW MEADOWS" },
  "83655": { state: "ID", city: "NEW PLYMOUTH" },
  "83656": { state: "ID", city: "NOTUS" },
  "83657": { state: "ID", city: "OLA" },
  "83660": { state: "ID", city: "PARMA" },
  "83661": { state: "ID", city: "PAYETTE" },
  "83666": { state: "ID", city: "PLACERVILLE" },
  "83669": { state: "ID", city: "STAR" },
  "83670": { state: "ID", city: "SWEET" },
  "83671": { state: "ID", city: "WARREN" },
  "83672": { state: "ID", city: "WEISER" },
  "83676": { state: "ID", city: "WILDER" },
  "83677": { state: "ID", city: "YELLOW PINE" },
  "83680": { state: "ID", city: "MERIDIAN" },
  "83686": { state: "ID", city: "NAMPA" },
  "83687": { state: "ID", city: "NAMPA" },
  "83701": { state: "ID", city: "BOISE" },
  "83702": { state: "ID", city: "BOISE" },
  "83703": { state: "ID", city: "BOISE" },
  "83704": { state: "ID", city: "BOISE" },
  "83705": { state: "ID", city: "BOISE" },
  "83706": { state: "ID", city: "BOISE" },
  "83707": { state: "ID", city: "BOISE" },
  "83708": { state: "ID", city: "BOISE" },
  "83709": { state: "ID", city: "BOISE" },
  "83711": { state: "ID", city: "BOISE" },
  "83712": { state: "ID", city: "BOISE" },
  "83713": { state: "ID", city: "BOISE" },
  "83714": { state: "ID", city: "GARDEN CITY" },
  "83715": { state: "ID", city: "BOISE" },
  "83716": { state: "ID", city: "BOISE" },
  "83717": { state: "ID", city: "BOISE" },
  "83719": { state: "ID", city: "BOISE" },
  "83720": { state: "ID", city: "BOISE" },
  "83722": { state: "ID", city: "BOISE" },
  "83724": { state: "ID", city: "BOISE" },
  "83725": { state: "ID", city: "BOISE" },
  "83726": { state: "ID", city: "BOISE" },
  "83728": { state: "ID", city: "BOISE" },
  "83729": { state: "ID", city: "BOISE" },
  "83732": { state: "ID", city: "BOISE" },
  "83735": { state: "ID", city: "BOISE" },
  "83756": { state: "ID", city: "BOISE" },
  "83799": { state: "ID", city: "BOISE" },
  "83801": { state: "ID", city: "ATHOL" },
  "83802": { state: "ID", city: "AVERY" },
  "83803": { state: "ID", city: "BAYVIEW" },
  "83804": { state: "ID", city: "BLANCHARD" },
  "83805": { state: "ID", city: "BONNERS FERRY" },
  "83806": { state: "ID", city: "BOVILL" },
  "83808": { state: "ID", city: "CALDER" },
  "83809": { state: "ID", city: "CAREYWOOD" },
  "83810": { state: "ID", city: "CATALDO" },
  "83811": { state: "ID", city: "CLARK FORK" },
  "83812": { state: "ID", city: "CLARKIA" },
  "83813": { state: "ID", city: "COCOLALLA" },
  "83814": { state: "ID", city: "COEUR D ALENE" },
  "83815": { state: "ID", city: "COEUR D ALENE" },
  "83816": { state: "ID", city: "COEUR D ALENE" },
  "83821": { state: "ID", city: "COOLIN" },
  "83822": { state: "ID", city: "OLDTOWN" },
  "83823": { state: "ID", city: "DEARY" },
  "83824": { state: "ID", city: "DESMET" },
  "83825": { state: "ID", city: "DOVER" },
  "83826": { state: "ID", city: "EASTPORT" },
  "83827": { state: "ID", city: "ELK RIVER" },
  "83830": { state: "ID", city: "FERNWOOD" },
  "83832": { state: "ID", city: "GENESEE" },
  "83833": { state: "ID", city: "HARRISON" },
  "83834": { state: "ID", city: "HARVARD" },
  "83835": { state: "ID", city: "HAYDEN" },
  "83836": { state: "ID", city: "HOPE" },
  "83837": { state: "ID", city: "KELLOGG" },
  "83839": { state: "ID", city: "KINGSTON" },
  "83840": { state: "ID", city: "KOOTENAI" },
  "83841": { state: "ID", city: "LACLEDE" },
  "83842": { state: "ID", city: "MEDIMONT" },
  "83843": { state: "ID", city: "MOSCOW" },
  "83844": { state: "ID", city: "MOSCOW" },
  "83845": { state: "ID", city: "MOYIE SPRINGS" },
  "83846": { state: "ID", city: "MULLAN" },
  "83847": { state: "ID", city: "NAPLES" },
  "83848": { state: "ID", city: "NORDMAN" },
  "83849": { state: "ID", city: "OSBURN" },
  "83850": { state: "ID", city: "PINEHURST" },
  "83851": { state: "ID", city: "PLUMMER" },
  "83852": { state: "ID", city: "PONDERAY" },
  "83853": { state: "ID", city: "PORTHILL" },
  "83854": { state: "ID", city: "POST FALLS" },
  "83855": { state: "ID", city: "POTLATCH" },
  "83856": { state: "ID", city: "PRIEST RIVER" },
  "83857": { state: "ID", city: "PRINCETON" },
  "83858": { state: "ID", city: "RATHDRUM" },
  "83860": { state: "ID", city: "SAGLE" },
  "83861": { state: "ID", city: "SAINT MARIES" },
  "83864": { state: "ID", city: "SANDPOINT" },
  "83865": { state: "ID", city: "COLBURN" },
  "83866": { state: "ID", city: "SANTA" },
  "83867": { state: "ID", city: "SILVERTON" },
  "83868": { state: "ID", city: "SMELTERVILLE" },
  "83869": { state: "ID", city: "SPIRIT LAKE" },
  "83870": { state: "ID", city: "TENSED" },
  "83871": { state: "ID", city: "TROY" },
  "83872": { state: "ID", city: "VIOLA" },
  "83873": { state: "ID", city: "WALLACE" },
  "83874": { state: "ID", city: "MURRAY" },
  "83876": { state: "ID", city: "WORLEY" },
  "83877": { state: "ID", city: "POST FALLS" },
  "84001": { state: "UT", city: "ALTAMONT" },
  "84002": { state: "UT", city: "ALTONAH" },
  "84003": { state: "UT", city: "AMERICAN FORK" },
  "84004": { state: "UT", city: "ALPINE" },
  "84005": { state: "UT", city: "EAGLE MOUNTAIN" },
  "84006": { state: "UT", city: "BINGHAM CANYON" },
  "84007": { state: "UT", city: "BLUEBELL" },
  "84008": { state: "UT", city: "BONANZA" },
  "84009": { state: "UT", city: "SOUTH JORDAN" },
  "84010": { state: "UT", city: "BOUNTIFUL" },
  "84011": { state: "UT", city: "BOUNTIFUL" },
  "84013": { state: "UT", city: "CEDAR VALLEY" },
  "84014": { state: "UT", city: "CENTERVILLE" },
  "84015": { state: "UT", city: "CLEARFIELD" },
  "84016": { state: "UT", city: "CLEARFIELD" },
  "84017": { state: "UT", city: "COALVILLE" },
  "84018": { state: "UT", city: "CROYDON" },
  "84020": { state: "UT", city: "DRAPER" },
  "84021": { state: "UT", city: "DUCHESNE" },
  "84022": { state: "UT", city: "DUGWAY" },
  "84023": { state: "UT", city: "DUTCH JOHN" },
  "84024": { state: "UT", city: "ECHO" },
  "84025": { state: "UT", city: "FARMINGTON" },
  "84026": { state: "UT", city: "FORT DUCHESNE" },
  "84027": { state: "UT", city: "FRUITLAND" },
  "84028": { state: "UT", city: "GARDEN CITY" },
  "84029": { state: "UT", city: "GRANTSVILLE" },
  "84031": { state: "UT", city: "HANNA" },
  "84032": { state: "UT", city: "HEBER CITY" },
  "84033": { state: "UT", city: "HENEFER" },
  "84034": { state: "UT", city: "IBAPAH" },
  "84035": { state: "UT", city: "JENSEN" },
  "84036": { state: "UT", city: "KAMAS" },
  "84037": { state: "UT", city: "KAYSVILLE" },
  "84038": { state: "UT", city: "LAKETOWN" },
  "84039": { state: "UT", city: "LAPOINT" },
  "84040": { state: "UT", city: "LAYTON" },
  "84041": { state: "UT", city: "LAYTON" },
  "84042": { state: "UT", city: "LINDON" },
  "84043": { state: "UT", city: "LEHI" },
  "84044": { state: "UT", city: "MAGNA" },
  "84045": { state: "UT", city: "SARATOGA SPRINGS" },
  "84046": { state: "UT", city: "MANILA" },
  "84047": { state: "UT", city: "MIDVALE" },
  "84049": { state: "UT", city: "MIDWAY" },
  "84050": { state: "UT", city: "MORGAN" },
  "84051": { state: "UT", city: "MOUNTAIN HOME" },
  "84052": { state: "UT", city: "MYTON" },
  "84053": { state: "UT", city: "NEOLA" },
  "84054": { state: "UT", city: "NORTH SALT LAKE" },
  "84055": { state: "UT", city: "OAKLEY" },
  "84056": { state: "UT", city: "HILL AFB" },
  "84057": { state: "UT", city: "OREM" },
  "84058": { state: "UT", city: "OREM" },
  "84059": { state: "UT", city: "VINEYARD" },
  "84060": { state: "UT", city: "PARK CITY" },
  "84061": { state: "UT", city: "PEOA" },
  "84062": { state: "UT", city: "PLEASANT GROVE" },
  "84063": { state: "UT", city: "RANDLETT" },
  "84064": { state: "UT", city: "RANDOLPH" },
  "84065": { state: "UT", city: "RIVERTON" },
  "84066": { state: "UT", city: "ROOSEVELT" },
  "84067": { state: "UT", city: "ROY" },
  "84068": { state: "UT", city: "PARK CITY" },
  "84069": { state: "UT", city: "RUSH VALLEY" },
  "84070": { state: "UT", city: "SANDY" },
  "84071": { state: "UT", city: "STOCKTON" },
  "84072": { state: "UT", city: "TABIONA" },
  "84073": { state: "UT", city: "TALMAGE" },
  "84074": { state: "UT", city: "TOOELE" },
  "84075": { state: "UT", city: "SYRACUSE" },
  "84076": { state: "UT", city: "TRIDELL" },
  "84078": { state: "UT", city: "VERNAL" },
  "84079": { state: "UT", city: "VERNAL" },
  "84080": { state: "UT", city: "VERNON" },
  "84081": { state: "UT", city: "WEST JORDAN" },
  "84082": { state: "UT", city: "WALLSBURG" },
  "84083": { state: "UT", city: "WENDOVER" },
  "84084": { state: "UT", city: "WEST JORDAN" },
  "84085": { state: "UT", city: "WHITEROCKS" },
  "84086": { state: "UT", city: "WOODRUFF" },
  "84087": { state: "UT", city: "WOODS CROSS" },
  "84088": { state: "UT", city: "WEST JORDAN" },
  "84089": { state: "UT", city: "CLEARFIELD" },
  "84090": { state: "UT", city: "SANDY" },
  "84091": { state: "UT", city: "SANDY" },
  "84092": { state: "UT", city: "SANDY" },
  "84093": { state: "UT", city: "SANDY" },
  "84094": { state: "UT", city: "SANDY" },
  "84095": { state: "UT", city: "SOUTH JORDAN" },
  "84096": { state: "UT", city: "HERRIMAN" },
  "84097": { state: "UT", city: "OREM" },
  "84098": { state: "UT", city: "PARK CITY" },
  "84101": { state: "UT", city: "SALT LAKE CITY" },
  "84102": { state: "UT", city: "SALT LAKE CITY" },
  "84103": { state: "UT", city: "SALT LAKE CITY" },
  "84104": { state: "UT", city: "SALT LAKE CITY" },
  "84105": { state: "UT", city: "SALT LAKE CITY" },
  "84106": { state: "UT", city: "SALT LAKE CITY" },
  "84107": { state: "UT", city: "SALT LAKE CITY" },
  "84108": { state: "UT", city: "SALT LAKE CITY" },
  "84109": { state: "UT", city: "SALT LAKE CITY" },
  "84110": { state: "UT", city: "SALT LAKE CITY" },
  "84111": { state: "UT", city: "SALT LAKE CITY" },
  "84112": { state: "UT", city: "SALT LAKE CITY" },
  "84113": { state: "UT", city: "SALT LAKE CITY" },
  "84114": { state: "UT", city: "SALT LAKE CITY" },
  "84115": { state: "UT", city: "SALT LAKE CITY" },
  "84116": { state: "UT", city: "SALT LAKE CITY" },
  "84117": { state: "UT", city: "SALT LAKE CITY" },
  "84118": { state: "UT", city: "SALT LAKE CITY" },
  "84119": { state: "UT", city: "WEST VALLEY CITY" },
  "84120": { state: "UT", city: "WEST VALLEY CITY" },
  "84121": { state: "UT", city: "SALT LAKE CITY" },
  "84122": { state: "UT", city: "SALT LAKE CITY" },
  "84123": { state: "UT", city: "SALT LAKE CITY" },
  "84124": { state: "UT", city: "SALT LAKE CITY" },
  "84125": { state: "UT", city: "SALT LAKE CITY" },
  "84126": { state: "UT", city: "SALT LAKE CITY" },
  "84127": { state: "UT", city: "SALT LAKE CITY" },
  "84128": { state: "UT", city: "WEST VALLEY CITY" },
  "84129": { state: "UT", city: "SALT LAKE CITY" },
  "84130": { state: "UT", city: "SALT LAKE CITY" },
  "84131": { state: "UT", city: "SALT LAKE CITY" },
  "84132": { state: "UT", city: "SALT LAKE CITY" },
  "84133": { state: "UT", city: "SALT LAKE CITY" },
  "84134": { state: "UT", city: "SALT LAKE CITY" },
  "84138": { state: "UT", city: "SALT LAKE CITY" },
  "84139": { state: "UT", city: "SALT LAKE CITY" },
  "84141": { state: "UT", city: "SALT LAKE CITY" },
  "84143": { state: "UT", city: "SALT LAKE CITY" },
  "84145": { state: "UT", city: "SALT LAKE CITY" },
  "84147": { state: "UT", city: "SALT LAKE CITY" },
  "84148": { state: "UT", city: "SALT LAKE CITY" },
  "84150": { state: "UT", city: "SALT LAKE CITY" },
  "84151": { state: "UT", city: "SALT LAKE CITY" },
  "84152": { state: "UT", city: "SALT LAKE CITY" },
  "84157": { state: "UT", city: "SALT LAKE CITY" },
  "84158": { state: "UT", city: "SALT LAKE CITY" },
  "84165": { state: "UT", city: "SALT LAKE CITY" },
  "84170": { state: "UT", city: "SALT LAKE CITY" },
  "84171": { state: "UT", city: "SALT LAKE CITY" },
  "84180": { state: "UT", city: "SALT LAKE CITY" },
  "84184": { state: "UT", city: "SALT LAKE CITY" },
  "84190": { state: "UT", city: "SALT LAKE CITY" },
  "84199": { state: "UT", city: "SALT LAKE CITY" },
  "84201": { state: "UT", city: "OGDEN" },
  "84244": { state: "UT", city: "OGDEN" },
  "84301": { state: "UT", city: "BEAR RIVER CITY" },
  "84302": { state: "UT", city: "BRIGHAM CITY" },
  "84304": { state: "UT", city: "CACHE JUNCTION" },
  "84305": { state: "UT", city: "CLARKSTON" },
  "84306": { state: "UT", city: "COLLINSTON" },
  "84307": { state: "UT", city: "CORINNE" },
  "84308": { state: "UT", city: "CORNISH" },
  "84309": { state: "UT", city: "DEWEYVILLE" },
  "84310": { state: "UT", city: "EDEN" },
  "84311": { state: "UT", city: "FIELDING" },
  "84312": { state: "UT", city: "GARLAND" },
  "84313": { state: "UT", city: "GROUSE CREEK" },
  "84314": { state: "UT", city: "HONEYVILLE" },
  "84315": { state: "UT", city: "HOOPER" },
  "84316": { state: "UT", city: "HOWELL" },
  "84317": { state: "UT", city: "HUNTSVILLE" },
  "84318": { state: "UT", city: "HYDE PARK" },
  "84319": { state: "UT", city: "HYRUM" },
  "84320": { state: "UT", city: "LEWISTON" },
  "84321": { state: "UT", city: "LOGAN" },
  "84322": { state: "UT", city: "LOGAN" },
  "84323": { state: "UT", city: "LOGAN" },
  "84324": { state: "UT", city: "MANTUA" },
  "84325": { state: "UT", city: "MENDON" },
  "84326": { state: "UT", city: "MILLVILLE" },
  "84327": { state: "UT", city: "NEWTON" },
  "84328": { state: "UT", city: "PARADISE" },
  "84329": { state: "UT", city: "PARK VALLEY" },
  "84330": { state: "UT", city: "PLYMOUTH" },
  "84331": { state: "UT", city: "PORTAGE" },
  "84332": { state: "UT", city: "PROVIDENCE" },
  "84333": { state: "UT", city: "RICHMOND" },
  "84334": { state: "UT", city: "RIVERSIDE" },
  "84335": { state: "UT", city: "SMITHFIELD" },
  "84336": { state: "UT", city: "SNOWVILLE" },
  "84337": { state: "UT", city: "TREMONTON" },
  "84338": { state: "UT", city: "TRENTON" },
  "84339": { state: "UT", city: "WELLSVILLE" },
  "84340": { state: "UT", city: "WILLARD" },
  "84341": { state: "UT", city: "LOGAN" },
  "84401": { state: "UT", city: "OGDEN" },
  "84402": { state: "UT", city: "OGDEN" },
  "84403": { state: "UT", city: "OGDEN" },
  "84404": { state: "UT", city: "OGDEN" },
  "84405": { state: "UT", city: "OGDEN" },
  "84407": { state: "UT", city: "OGDEN" },
  "84408": { state: "UT", city: "OGDEN" },
  "84409": { state: "UT", city: "OGDEN" },
  "84412": { state: "UT", city: "OGDEN" },
  "84414": { state: "UT", city: "OGDEN" },
  "84415": { state: "UT", city: "OGDEN" },
  "84501": { state: "UT", city: "PRICE" },
  "84510": { state: "UT", city: "ANETH" },
  "84511": { state: "UT", city: "BLANDING" },
  "84512": { state: "UT", city: "BLUFF" },
  "84513": { state: "UT", city: "CASTLE DALE" },
  "84515": { state: "UT", city: "CISCO" },
  "84516": { state: "UT", city: "CLAWSON" },
  "84518": { state: "UT", city: "CLEVELAND" },
  "84520": { state: "UT", city: "EAST CARBON" },
  "84521": { state: "UT", city: "ELMO" },
  "84522": { state: "UT", city: "EMERY" },
  "84523": { state: "UT", city: "FERRON" },
  "84525": { state: "UT", city: "GREEN RIVER" },
  "84526": { state: "UT", city: "HELPER" },
  "84528": { state: "UT", city: "HUNTINGTON" },
  "84529": { state: "UT", city: "KENILWORTH" },
  "84530": { state: "UT", city: "LA SAL" },
  "84531": { state: "UT", city: "MEXICAN HAT" },
  "84532": { state: "UT", city: "MOAB" },
  "84533": { state: "UT", city: "LAKE POWELL" },
  "84534": { state: "UT", city: "MONTEZUMA CREEK" },
  "84535": { state: "UT", city: "MONTICELLO" },
  "84536": { state: "UT", city: "MONUMENT VALLEY" },
  "84537": { state: "UT", city: "ORANGEVILLE" },
  "84539": { state: "UT", city: "SUNNYSIDE" },
  "84540": { state: "UT", city: "THOMPSON" },
  "84542": { state: "UT", city: "WELLINGTON" },
  "84601": { state: "UT", city: "PROVO" },
  "84602": { state: "UT", city: "PROVO" },
  "84603": { state: "UT", city: "PROVO" },
  "84604": { state: "UT", city: "PROVO" },
  "84605": { state: "UT", city: "PROVO" },
  "84606": { state: "UT", city: "PROVO" },
  "84620": { state: "UT", city: "AURORA" },
  "84621": { state: "UT", city: "AXTELL" },
  "84622": { state: "UT", city: "CENTERFIELD" },
  "84623": { state: "UT", city: "CHESTER" },
  "84624": { state: "UT", city: "DELTA" },
  "84626": { state: "UT", city: "ELBERTA" },
  "84627": { state: "UT", city: "EPHRAIM" },
  "84628": { state: "UT", city: "EUREKA" },
  "84629": { state: "UT", city: "FAIRVIEW" },
  "84630": { state: "UT", city: "FAYETTE" },
  "84631": { state: "UT", city: "FILLMORE" },
  "84632": { state: "UT", city: "FOUNTAIN GREEN" },
  "84633": { state: "UT", city: "GOSHEN" },
  "84634": { state: "UT", city: "GUNNISON" },
  "84635": { state: "UT", city: "HINCKLEY" },
  "84636": { state: "UT", city: "HOLDEN" },
  "84637": { state: "UT", city: "KANOSH" },
  "84638": { state: "UT", city: "LEAMINGTON" },
  "84639": { state: "UT", city: "LEVAN" },
  "84640": { state: "UT", city: "LYNNDYL" },
  "84642": { state: "UT", city: "MANTI" },
  "84643": { state: "UT", city: "MAYFIELD" },
  "84644": { state: "UT", city: "MEADOW" },
  "84645": { state: "UT", city: "MONA" },
  "84646": { state: "UT", city: "MORONI" },
  "84647": { state: "UT", city: "MOUNT PLEASANT" },
  "84648": { state: "UT", city: "NEPHI" },
  "84649": { state: "UT", city: "OAK CITY" },
  "84651": { state: "UT", city: "PAYSON" },
  "84652": { state: "UT", city: "REDMOND" },
  "84653": { state: "UT", city: "SALEM" },
  "84654": { state: "UT", city: "SALINA" },
  "84655": { state: "UT", city: "SANTAQUIN" },
  "84656": { state: "UT", city: "SCIPIO" },
  "84657": { state: "UT", city: "SIGURD" },
  "84660": { state: "UT", city: "SPANISH FORK" },
  "84662": { state: "UT", city: "SPRING CITY" },
  "84663": { state: "UT", city: "SPRINGVILLE" },
  "84664": { state: "UT", city: "MAPLETON" },
  "84665": { state: "UT", city: "STERLING" },
  "84667": { state: "UT", city: "WALES" },
  "84701": { state: "UT", city: "RICHFIELD" },
  "84710": { state: "UT", city: "ALTON" },
  "84711": { state: "UT", city: "ANNABELLA" },
  "84712": { state: "UT", city: "ANTIMONY" },
  "84713": { state: "UT", city: "BEAVER" },
  "84714": { state: "UT", city: "BERYL" },
  "84715": { state: "UT", city: "BICKNELL" },
  "84716": { state: "UT", city: "BOULDER" },
  "84718": { state: "UT", city: "CANNONVILLE" },
  "84719": { state: "UT", city: "BRIAN HEAD" },
  "84720": { state: "UT", city: "CEDAR CITY" },
  "84721": { state: "UT", city: "CEDAR CITY" },
  "84722": { state: "UT", city: "CENTRAL" },
  "84723": { state: "UT", city: "CIRCLEVILLE" },
  "84724": { state: "UT", city: "ELSINORE" },
  "84725": { state: "UT", city: "ENTERPRISE" },
  "84726": { state: "UT", city: "ESCALANTE" },
  "84728": { state: "UT", city: "GARRISON" },
  "84729": { state: "UT", city: "GLENDALE" },
  "84730": { state: "UT", city: "GLENWOOD" },
  "84731": { state: "UT", city: "GREENVILLE" },
  "84732": { state: "UT", city: "GREENWICH" },
  "84733": { state: "UT", city: "GUNLOCK" },
  "84734": { state: "UT", city: "HANKSVILLE" },
  "84735": { state: "UT", city: "HATCH" },
  "84736": { state: "UT", city: "HENRIEVILLE" },
  "84737": { state: "UT", city: "HURRICANE" },
  "84738": { state: "UT", city: "IVINS" },
  "84739": { state: "UT", city: "JOSEPH" },
  "84740": { state: "UT", city: "JUNCTION" },
  "84741": { state: "UT", city: "KANAB" },
  "84742": { state: "UT", city: "KANARRAVILLE" },
  "84743": { state: "UT", city: "KINGSTON" },
  "84744": { state: "UT", city: "KOOSHAREM" },
  "84745": { state: "UT", city: "LA VERKIN" },
  "84746": { state: "UT", city: "LEEDS" },
  "84747": { state: "UT", city: "LOA" },
  "84749": { state: "UT", city: "LYMAN" },
  "84750": { state: "UT", city: "MARYSVALE" },
  "84751": { state: "UT", city: "MILFORD" },
  "84752": { state: "UT", city: "MINERSVILLE" },
  "84753": { state: "UT", city: "MODENA" },
  "84754": { state: "UT", city: "MONROE" },
  "84755": { state: "UT", city: "MOUNT CARMEL" },
  "84756": { state: "UT", city: "NEWCASTLE" },
  "84757": { state: "UT", city: "NEW HARMONY" },
  "84758": { state: "UT", city: "ORDERVILLE" },
  "84759": { state: "UT", city: "PANGUITCH" },
  "84760": { state: "UT", city: "PARAGONAH" },
  "84761": { state: "UT", city: "PAROWAN" },
  "84762": { state: "UT", city: "DUCK CREEK VILLAGE" },
  "84763": { state: "UT", city: "ROCKVILLE" },
  "84764": { state: "UT", city: "BRYCE" },
  "84765": { state: "UT", city: "SANTA CLARA" },
  "84766": { state: "UT", city: "SEVIER" },
  "84767": { state: "UT", city: "SPRINGDALE" },
  "84770": { state: "UT", city: "SAINT GEORGE" },
  "84771": { state: "UT", city: "SAINT GEORGE" },
  "84772": { state: "UT", city: "SUMMIT" },
  "84773": { state: "UT", city: "TEASDALE" },
  "84774": { state: "UT", city: "TOQUERVILLE" },
  "84775": { state: "UT", city: "TORREY" },
  "84776": { state: "UT", city: "TROPIC" },
  "84779": { state: "UT", city: "VIRGIN" },
  "84780": { state: "UT", city: "WASHINGTON" },
  "84781": { state: "UT", city: "PINE VALLEY" },
  "84782": { state: "UT", city: "VEYO" },
  "84783": { state: "UT", city: "DAMMERON VALLEY" },
  "84784": { state: "UT", city: "HILDALE" },
  "84790": { state: "UT", city: "SAINT GEORGE" },
  "84791": { state: "UT", city: "SAINT GEORGE" },
  "85001": { state: "AZ", city: "PHOENIX" },
  "85002": { state: "AZ", city: "PHOENIX" },
  "85003": { state: "AZ", city: "PHOENIX" },
  "85004": { state: "AZ", city: "PHOENIX" },
  "85005": { state: "AZ", city: "PHOENIX" },
  "85006": { state: "AZ", city: "PHOENIX" },
  "85007": { state: "AZ", city: "PHOENIX" },
  "85008": { state: "AZ", city: "PHOENIX" },
  "85009": { state: "AZ", city: "PHOENIX" },
  "85010": { state: "AZ", city: "PHOENIX" },
  "85011": { state: "AZ", city: "PHOENIX" },
  "85012": { state: "AZ", city: "PHOENIX" },
  "85013": { state: "AZ", city: "PHOENIX" },
  "85014": { state: "AZ", city: "PHOENIX" },
  "85015": { state: "AZ", city: "PHOENIX" },
  "85016": { state: "AZ", city: "PHOENIX" },
  "85017": { state: "AZ", city: "PHOENIX" },
  "85018": { state: "AZ", city: "PHOENIX" },
  "85019": { state: "AZ", city: "PHOENIX" },
  "85020": { state: "AZ", city: "PHOENIX" },
  "85021": { state: "AZ", city: "PHOENIX" },
  "85022": { state: "AZ", city: "PHOENIX" },
  "85023": { state: "AZ", city: "PHOENIX" },
  "85024": { state: "AZ", city: "PHOENIX" },
  "85026": { state: "AZ", city: "PHOENIX" },
  "85027": { state: "AZ", city: "PHOENIX" },
  "85028": { state: "AZ", city: "PHOENIX" },
  "85029": { state: "AZ", city: "PHOENIX" },
  "85030": { state: "AZ", city: "PHOENIX" },
  "85031": { state: "AZ", city: "PHOENIX" },
  "85032": { state: "AZ", city: "PHOENIX" },
  "85033": { state: "AZ", city: "PHOENIX" },
  "85034": { state: "AZ", city: "PHOENIX" },
  "85035": { state: "AZ", city: "PHOENIX" },
  "85036": { state: "AZ", city: "PHOENIX" },
  "85037": { state: "AZ", city: "PHOENIX" },
  "85038": { state: "AZ", city: "PHOENIX" },
  "85039": { state: "AZ", city: "PHOENIX" },
  "85040": { state: "AZ", city: "PHOENIX" },
  "85041": { state: "AZ", city: "PHOENIX" },
  "85042": { state: "AZ", city: "PHOENIX" },
  "85043": { state: "AZ", city: "PHOENIX" },
  "85044": { state: "AZ", city: "PHOENIX" },
  "85045": { state: "AZ", city: "PHOENIX" },
  "85046": { state: "AZ", city: "PHOENIX" },
  "85048": { state: "AZ", city: "PHOENIX" },
  "85050": { state: "AZ", city: "PHOENIX" },
  "85051": { state: "AZ", city: "PHOENIX" },
  "85053": { state: "AZ", city: "PHOENIX" },
  "85054": { state: "AZ", city: "PHOENIX" },
  "85060": { state: "AZ", city: "PHOENIX" },
  "85061": { state: "AZ", city: "PHOENIX" },
  "85062": { state: "AZ", city: "PHOENIX" },
  "85063": { state: "AZ", city: "PHOENIX" },
  "85064": { state: "AZ", city: "PHOENIX" },
  "85065": { state: "AZ", city: "PHOENIX" },
  "85066": { state: "AZ", city: "PHOENIX" },
  "85067": { state: "AZ", city: "PHOENIX" },
  "85068": { state: "AZ", city: "PHOENIX" },
  "85069": { state: "AZ", city: "PHOENIX" },
  "85070": { state: "AZ", city: "PHOENIX" },
  "85071": { state: "AZ", city: "PHOENIX" },
  "85072": { state: "AZ", city: "PHOENIX" },
  "85073": { state: "AZ", city: "PHOENIX" },
  "85074": { state: "AZ", city: "PHOENIX" },
  "85075": { state: "AZ", city: "PHOENIX" },
  "85076": { state: "AZ", city: "PHOENIX" },
  "85078": { state: "AZ", city: "PHOENIX" },
  "85079": { state: "AZ", city: "PHOENIX" },
  "85080": { state: "AZ", city: "PHOENIX" },
  "85082": { state: "AZ", city: "PHOENIX" },
  "85083": { state: "AZ", city: "PHOENIX" },
  "85085": { state: "AZ", city: "PHOENIX" },
  "85086": { state: "AZ", city: "PHOENIX" },
  "85087": { state: "AZ", city: "NEW RIVER" },
  "85117": { state: "AZ", city: "APACHE JUNCTION" },
  "85118": { state: "AZ", city: "GOLD CANYON" },
  "85119": { state: "AZ", city: "APACHE JUNCTION" },
  "85120": { state: "AZ", city: "APACHE JUNCTION" },
  "85121": { state: "AZ", city: "BAPCHULE" },
  "85122": { state: "AZ", city: "CASA GRANDE" },
  "85123": { state: "AZ", city: "ARIZONA CITY" },
  "85127": { state: "AZ", city: "CHANDLER HEIGHTS" },
  "85128": { state: "AZ", city: "COOLIDGE" },
  "85130": { state: "AZ", city: "CASA GRANDE" },
  "85131": { state: "AZ", city: "ELOY" },
  "85132": { state: "AZ", city: "FLORENCE" },
  "85135": { state: "AZ", city: "HAYDEN" },
  "85137": { state: "AZ", city: "KEARNY" },
  "85138": { state: "AZ", city: "MARICOPA" },
  "85139": { state: "AZ", city: "MARICOPA" },
  "85140": { state: "AZ", city: "SAN TAN VALLEY" },
  "85141": { state: "AZ", city: "PICACHO" },
  "85142": { state: "AZ", city: "QUEEN CREEK" },
  "85143": { state: "AZ", city: "SAN TAN VALLEY" },
  "85144": { state: "AZ", city: "SAN TAN VALLEY" },
  "85145": { state: "AZ", city: "RED ROCK" },
  "85147": { state: "AZ", city: "SACATON" },
  "85172": { state: "AZ", city: "STANFIELD" },
  "85173": { state: "AZ", city: "SUPERIOR" },
  "85178": { state: "AZ", city: "APACHE JUNCTION" },
  "85190": { state: "AZ", city: "TORTILLA FLAT" },
  "85191": { state: "AZ", city: "VALLEY FARMS" },
  "85192": { state: "AZ", city: "WINKELMAN" },
  "85193": { state: "AZ", city: "CASA GRANDE" },
  "85194": { state: "AZ", city: "CASA GRANDE" },
  "85201": { state: "AZ", city: "MESA" },
  "85202": { state: "AZ", city: "MESA" },
  "85203": { state: "AZ", city: "MESA" },
  "85204": { state: "AZ", city: "MESA" },
  "85205": { state: "AZ", city: "MESA" },
  "85206": { state: "AZ", city: "MESA" },
  "85207": { state: "AZ", city: "MESA" },
  "85208": { state: "AZ", city: "MESA" },
  "85209": { state: "AZ", city: "MESA" },
  "85210": { state: "AZ", city: "MESA" },
  "85211": { state: "AZ", city: "MESA" },
  "85212": { state: "AZ", city: "MESA" },
  "85213": { state: "AZ", city: "MESA" },
  "85214": { state: "AZ", city: "MESA" },
  "85215": { state: "AZ", city: "MESA" },
  "85216": { state: "AZ", city: "MESA" },
  "85224": { state: "AZ", city: "CHANDLER" },
  "85225": { state: "AZ", city: "CHANDLER" },
  "85226": { state: "AZ", city: "CHANDLER" },
  "85233": { state: "AZ", city: "GILBERT" },
  "85234": { state: "AZ", city: "GILBERT" },
  "85236": { state: "AZ", city: "HIGLEY" },
  "85244": { state: "AZ", city: "CHANDLER" },
  "85246": { state: "AZ", city: "CHANDLER" },
  "85248": { state: "AZ", city: "CHANDLER" },
  "85249": { state: "AZ", city: "CHANDLER" },
  "85250": { state: "AZ", city: "SCOTTSDALE" },
  "85251": { state: "AZ", city: "SCOTTSDALE" },
  "85252": { state: "AZ", city: "SCOTTSDALE" },
  "85253": { state: "AZ", city: "PARADISE VALLEY" },
  "85254": { state: "AZ", city: "SCOTTSDALE" },
  "85255": { state: "AZ", city: "SCOTTSDALE" },
  "85256": { state: "AZ", city: "SCOTTSDALE" },
  "85257": { state: "AZ", city: "SCOTTSDALE" },
  "85258": { state: "AZ", city: "SCOTTSDALE" },
  "85259": { state: "AZ", city: "SCOTTSDALE" },
  "85260": { state: "AZ", city: "SCOTTSDALE" },
  "85261": { state: "AZ", city: "SCOTTSDALE" },
  "85262": { state: "AZ", city: "SCOTTSDALE" },
  "85263": { state: "AZ", city: "RIO VERDE" },
  "85264": { state: "AZ", city: "FORT MCDOWELL" },
  "85266": { state: "AZ", city: "SCOTTSDALE" },
  "85267": { state: "AZ", city: "SCOTTSDALE" },
  "85268": { state: "AZ", city: "FOUNTAIN HILLS" },
  "85269": { state: "AZ", city: "FOUNTAIN HILLS" },
  "85271": { state: "AZ", city: "SCOTTSDALE" },
  "85274": { state: "AZ", city: "MESA" },
  "85275": { state: "AZ", city: "MESA" },
  "85277": { state: "AZ", city: "MESA" },
  "85280": { state: "AZ", city: "TEMPE" },
  "85281": { state: "AZ", city: "TEMPE" },
  "85282": { state: "AZ", city: "TEMPE" },
  "85283": { state: "AZ", city: "TEMPE" },
  "85284": { state: "AZ", city: "TEMPE" },
  "85285": { state: "AZ", city: "TEMPE" },
  "85286": { state: "AZ", city: "CHANDLER" },
  "85287": { state: "AZ", city: "TEMPE" },
  "85288": { state: "AZ", city: "TEMPE" },
  "85295": { state: "AZ", city: "GILBERT" },
  "85296": { state: "AZ", city: "GILBERT" },
  "85297": { state: "AZ", city: "GILBERT" },
  "85298": { state: "AZ", city: "GILBERT" },
  "85299": { state: "AZ", city: "GILBERT" },
  "85301": { state: "AZ", city: "GLENDALE" },
  "85302": { state: "AZ", city: "GLENDALE" },
  "85303": { state: "AZ", city: "GLENDALE" },
  "85304": { state: "AZ", city: "GLENDALE" },
  "85305": { state: "AZ", city: "GLENDALE" },
  "85306": { state: "AZ", city: "GLENDALE" },
  "85307": { state: "AZ", city: "GLENDALE" },
  "85308": { state: "AZ", city: "GLENDALE" },
  "85309": { state: "AZ", city: "LUKE AIR FORCE BASE" },
  "85310": { state: "AZ", city: "GLENDALE" },
  "85311": { state: "AZ", city: "GLENDALE" },
  "85312": { state: "AZ", city: "GLENDALE" },
  "85318": { state: "AZ", city: "GLENDALE" },
  "85320": { state: "AZ", city: "AGUILA" },
  "85321": { state: "AZ", city: "AJO" },
  "85322": { state: "AZ", city: "ARLINGTON" },
  "85323": { state: "AZ", city: "AVONDALE" },
  "85324": { state: "AZ", city: "BLACK CANYON CITY" },
  "85325": { state: "AZ", city: "BOUSE" },
  "85326": { state: "AZ", city: "BUCKEYE" },
  "85327": { state: "AZ", city: "CAVE CREEK" },
  "85328": { state: "AZ", city: "CIBOLA" },
  "85329": { state: "AZ", city: "CASHION" },
  "85331": { state: "AZ", city: "CAVE CREEK" },
  "85332": { state: "AZ", city: "CONGRESS" },
  "85333": { state: "AZ", city: "DATELAND" },
  "85334": { state: "AZ", city: "EHRENBERG" },
  "85335": { state: "AZ", city: "EL MIRAGE" },
  "85336": { state: "AZ", city: "SAN LUIS" },
  "85337": { state: "AZ", city: "GILA BEND" },
  "85338": { state: "AZ", city: "GOODYEAR" },
  "85339": { state: "AZ", city: "LAVEEN" },
  "85340": { state: "AZ", city: "LITCHFIELD PARK" },
  "85341": { state: "AZ", city: "LUKEVILLE" },
  "85342": { state: "AZ", city: "MORRISTOWN" },
  "85343": { state: "AZ", city: "PALO VERDE" },
  "85344": { state: "AZ", city: "PARKER" },
  "85345": { state: "AZ", city: "PEORIA" },
  "85346": { state: "AZ", city: "QUARTZSITE" },
  "85347": { state: "AZ", city: "ROLL" },
  "85348": { state: "AZ", city: "SALOME" },
  "85349": { state: "AZ", city: "SAN LUIS" },
  "85350": { state: "AZ", city: "SOMERTON" },
  "85351": { state: "AZ", city: "SUN CITY" },
  "85352": { state: "AZ", city: "TACNA" },
  "85353": { state: "AZ", city: "TOLLESON" },
  "85354": { state: "AZ", city: "TONOPAH" },
  "85355": { state: "AZ", city: "WADDELL" },
  "85356": { state: "AZ", city: "WELLTON" },
  "85357": { state: "AZ", city: "WENDEN" },
  "85358": { state: "AZ", city: "WICKENBURG" },
  "85359": { state: "AZ", city: "QUARTZSITE" },
  "85360": { state: "AZ", city: "WIKIEUP" },
  "85361": { state: "AZ", city: "WITTMANN" },
  "85362": { state: "AZ", city: "YARNELL" },
  "85363": { state: "AZ", city: "YOUNGTOWN" },
  "85364": { state: "AZ", city: "YUMA" },
  "85365": { state: "AZ", city: "YUMA" },
  "85366": { state: "AZ", city: "YUMA" },
  "85367": { state: "AZ", city: "YUMA" },
  "85369": { state: "AZ", city: "YUMA" },
  "85371": { state: "AZ", city: "POSTON" },
  "85372": { state: "AZ", city: "SUN CITY" },
  "85373": { state: "AZ", city: "SUN CITY" },
  "85374": { state: "AZ", city: "SURPRISE" },
  "85375": { state: "AZ", city: "SUN CITY WEST" },
  "85376": { state: "AZ", city: "SUN CITY WEST" },
  "85377": { state: "AZ", city: "CAREFREE" },
  "85378": { state: "AZ", city: "SURPRISE" },
  "85379": { state: "AZ", city: "SURPRISE" },
  "85380": { state: "AZ", city: "PEORIA" },
  "85381": { state: "AZ", city: "PEORIA" },
  "85382": { state: "AZ", city: "PEORIA" },
  "85383": { state: "AZ", city: "PEORIA" },
  "85385": { state: "AZ", city: "PEORIA" },
  "85387": { state: "AZ", city: "SURPRISE" },
  "85388": { state: "AZ", city: "SURPRISE" },
  "85390": { state: "AZ", city: "WICKENBURG" },
  "85392": { state: "AZ", city: "AVONDALE" },
  "85395": { state: "AZ", city: "GOODYEAR" },
  "85396": { state: "AZ", city: "BUCKEYE" },
  "85501": { state: "AZ", city: "GLOBE" },
  "85502": { state: "AZ", city: "GLOBE" },
  "85530": { state: "AZ", city: "BYLAS" },
  "85531": { state: "AZ", city: "CENTRAL" },
  "85532": { state: "AZ", city: "CLAYPOOL" },
  "85533": { state: "AZ", city: "CLIFTON" },
  "85534": { state: "AZ", city: "DUNCAN" },
  "85535": { state: "AZ", city: "EDEN" },
  "85536": { state: "AZ", city: "FORT THOMAS" },
  "85539": { state: "AZ", city: "MIAMI" },
  "85540": { state: "AZ", city: "MORENCI" },
  "85541": { state: "AZ", city: "PAYSON" },
  "85542": { state: "AZ", city: "PERIDOT" },
  "85543": { state: "AZ", city: "PIMA" },
  "85544": { state: "AZ", city: "PINE" },
  "85545": { state: "AZ", city: "ROOSEVELT" },
  "85546": { state: "AZ", city: "SAFFORD" },
  "85547": { state: "AZ", city: "PAYSON" },
  "85548": { state: "AZ", city: "SAFFORD" },
  "85550": { state: "AZ", city: "SAN CARLOS" },
  "85551": { state: "AZ", city: "SOLOMON" },
  "85552": { state: "AZ", city: "THATCHER" },
  "85553": { state: "AZ", city: "TONTO BASIN" },
  "85554": { state: "AZ", city: "YOUNG" },
  "85601": { state: "AZ", city: "ARIVACA" },
  "85602": { state: "AZ", city: "BENSON" },
  "85603": { state: "AZ", city: "BISBEE" },
  "85605": { state: "AZ", city: "BOWIE" },
  "85606": { state: "AZ", city: "COCHISE" },
  "85607": { state: "AZ", city: "DOUGLAS" },
  "85608": { state: "AZ", city: "DOUGLAS" },
  "85609": { state: "AZ", city: "DRAGOON" },
  "85610": { state: "AZ", city: "ELFRIDA" },
  "85611": { state: "AZ", city: "ELGIN" },
  "85613": { state: "AZ", city: "FORT HUACHUCA" },
  "85614": { state: "AZ", city: "GREEN VALLEY" },
  "85615": { state: "AZ", city: "HEREFORD" },
  "85616": { state: "AZ", city: "HUACHUCA CITY" },
  "85617": { state: "AZ", city: "MC NEAL" },
  "85618": { state: "AZ", city: "MAMMOTH" },
  "85619": { state: "AZ", city: "MOUNT LEMMON" },
  "85620": { state: "AZ", city: "NACO" },
  "85621": { state: "AZ", city: "NOGALES" },
  "85622": { state: "AZ", city: "GREEN VALLEY" },
  "85623": { state: "AZ", city: "ORACLE" },
  "85624": { state: "AZ", city: "PATAGONIA" },
  "85625": { state: "AZ", city: "PEARCE" },
  "85626": { state: "AZ", city: "PIRTLEVILLE" },
  "85627": { state: "AZ", city: "POMERENE" },
  "85628": { state: "AZ", city: "NOGALES" },
  "85629": { state: "AZ", city: "SAHUARITA" },
  "85630": { state: "AZ", city: "SAINT DAVID" },
  "85631": { state: "AZ", city: "SAN MANUEL" },
  "85632": { state: "AZ", city: "SAN SIMON" },
  "85633": { state: "AZ", city: "SASABE" },
  "85634": { state: "AZ", city: "SELLS" },
  "85635": { state: "AZ", city: "SIERRA VISTA" },
  "85636": { state: "AZ", city: "SIERRA VISTA" },
  "85637": { state: "AZ", city: "SONOITA" },
  "85638": { state: "AZ", city: "TOMBSTONE" },
  "85639": { state: "AZ", city: "TOPAWA" },
  "85640": { state: "AZ", city: "TUMACACORI" },
  "85641": { state: "AZ", city: "VAIL" },
  "85643": { state: "AZ", city: "WILLCOX" },
  "85644": { state: "AZ", city: "WILLCOX" },
  "85645": { state: "AZ", city: "AMADO" },
  "85646": { state: "AZ", city: "TUBAC" },
  "85648": { state: "AZ", city: "RIO RICO" },
  "85650": { state: "AZ", city: "SIERRA VISTA" },
  "85652": { state: "AZ", city: "CORTARO" },
  "85653": { state: "AZ", city: "MARANA" },
  "85654": { state: "AZ", city: "RILLITO" },
  "85655": { state: "AZ", city: "DOUGLAS" },
  "85658": { state: "AZ", city: "MARANA" },
  "85670": { state: "AZ", city: "FORT HUACHUCA" },
  "85671": { state: "AZ", city: "SIERRA VISTA" },
  "85701": { state: "AZ", city: "TUCSON" },
  "85702": { state: "AZ", city: "TUCSON" },
  "85703": { state: "AZ", city: "TUCSON" },
  "85704": { state: "AZ", city: "TUCSON" },
  "85705": { state: "AZ", city: "TUCSON" },
  "85706": { state: "AZ", city: "TUCSON" },
  "85707": { state: "AZ", city: "TUCSON" },
  "85708": { state: "AZ", city: "TUCSON" },
  "85709": { state: "AZ", city: "TUCSON" },
  "85710": { state: "AZ", city: "TUCSON" },
  "85711": { state: "AZ", city: "TUCSON" },
  "85712": { state: "AZ", city: "TUCSON" },
  "85713": { state: "AZ", city: "TUCSON" },
  "85714": { state: "AZ", city: "TUCSON" },
  "85715": { state: "AZ", city: "TUCSON" },
  "85716": { state: "AZ", city: "TUCSON" },
  "85717": { state: "AZ", city: "TUCSON" },
  "85718": { state: "AZ", city: "TUCSON" },
  "85719": { state: "AZ", city: "TUCSON" },
  "85720": { state: "AZ", city: "TUCSON" },
  "85721": { state: "AZ", city: "TUCSON" },
  "85722": { state: "AZ", city: "TUCSON" },
  "85723": { state: "AZ", city: "TUCSON" },
  "85724": { state: "AZ", city: "TUCSON" },
  "85725": { state: "AZ", city: "TUCSON" },
  "85726": { state: "AZ", city: "TUCSON" },
  "85728": { state: "AZ", city: "TUCSON" },
  "85730": { state: "AZ", city: "TUCSON" },
  "85731": { state: "AZ", city: "TUCSON" },
  "85732": { state: "AZ", city: "TUCSON" },
  "85733": { state: "AZ", city: "TUCSON" },
  "85734": { state: "AZ", city: "TUCSON" },
  "85735": { state: "AZ", city: "TUCSON" },
  "85736": { state: "AZ", city: "TUCSON" },
  "85737": { state: "AZ", city: "TUCSON" },
  "85738": { state: "AZ", city: "CATALINA" },
  "85739": { state: "AZ", city: "TUCSON" },
  "85740": { state: "AZ", city: "TUCSON" },
  "85741": { state: "AZ", city: "TUCSON" },
  "85742": { state: "AZ", city: "TUCSON" },
  "85743": { state: "AZ", city: "TUCSON" },
  "85744": { state: "AZ", city: "TUCSON" },
  "85745": { state: "AZ", city: "TUCSON" },
  "85746": { state: "AZ", city: "TUCSON" },
  "85747": { state: "AZ", city: "TUCSON" },
  "85748": { state: "AZ", city: "TUCSON" },
  "85749": { state: "AZ", city: "TUCSON" },
  "85750": { state: "AZ", city: "TUCSON" },
  "85751": { state: "AZ", city: "TUCSON" },
  "85752": { state: "AZ", city: "TUCSON" },
  "85754": { state: "AZ", city: "TUCSON" },
  "85755": { state: "AZ", city: "TUCSON" },
  "85756": { state: "AZ", city: "TUCSON" },
  "85757": { state: "AZ", city: "TUCSON" },
  "85775": { state: "AZ", city: "TUCSON" },
  "85901": { state: "AZ", city: "SHOW LOW" },
  "85902": { state: "AZ", city: "SHOW LOW" },
  "85911": { state: "AZ", city: "CIBECUE" },
  "85912": { state: "AZ", city: "WHITE MOUNTAIN LAKE" },
  "85920": { state: "AZ", city: "ALPINE" },
  "85922": { state: "AZ", city: "BLUE" },
  "85923": { state: "AZ", city: "CLAY SPRINGS" },
  "85924": { state: "AZ", city: "CONCHO" },
  "85925": { state: "AZ", city: "EAGAR" },
  "85926": { state: "AZ", city: "FORT APACHE" },
  "85927": { state: "AZ", city: "GREER" },
  "85928": { state: "AZ", city: "HEBER" },
  "85929": { state: "AZ", city: "LAKESIDE" },
  "85930": { state: "AZ", city: "MCNARY" },
  "85931": { state: "AZ", city: "FOREST LAKES" },
  "85932": { state: "AZ", city: "NUTRIOSO" },
  "85933": { state: "AZ", city: "OVERGAARD" },
  "85934": { state: "AZ", city: "PINEDALE" },
  "85935": { state: "AZ", city: "PINETOP" },
  "85936": { state: "AZ", city: "SAINT JOHNS" },
  "85937": { state: "AZ", city: "SNOWFLAKE" },
  "85938": { state: "AZ", city: "SPRINGERVILLE" },
  "85939": { state: "AZ", city: "TAYLOR" },
  "85940": { state: "AZ", city: "VERNON" },
  "85941": { state: "AZ", city: "WHITERIVER" },
  "85942": { state: "AZ", city: "WOODRUFF" },
  "86001": { state: "AZ", city: "FLAGSTAFF" },
  "86002": { state: "AZ", city: "FLAGSTAFF" },
  "86003": { state: "AZ", city: "FLAGSTAFF" },
  "86004": { state: "AZ", city: "FLAGSTAFF" },
  "86005": { state: "AZ", city: "FLAGSTAFF" },
  "86011": { state: "AZ", city: "FLAGSTAFF" },
  "86015": { state: "AZ", city: "BELLEMONT" },
  "86016": { state: "AZ", city: "GRAY MOUNTAIN" },
  "86017": { state: "AZ", city: "MUNDS PARK" },
  "86018": { state: "AZ", city: "PARKS" },
  "86020": { state: "AZ", city: "CAMERON" },
  "86021": { state: "AZ", city: "COLORADO CITY" },
  "86022": { state: "AZ", city: "FREDONIA" },
  "86023": { state: "AZ", city: "GRAND CANYON" },
  "86024": { state: "AZ", city: "HAPPY JACK" },
  "86025": { state: "AZ", city: "HOLBROOK" },
  "86028": { state: "AZ", city: "PETRIFIED FOREST NATL PK" },
  "86029": { state: "AZ", city: "SUN VALLEY" },
  "86030": { state: "AZ", city: "HOTEVILLA" },
  "86031": { state: "AZ", city: "INDIAN WELLS" },
  "86032": { state: "AZ", city: "JOSEPH CITY" },
  "86033": { state: "AZ", city: "KAYENTA" },
  "86034": { state: "AZ", city: "KEAMS CANYON" },
  "86035": { state: "AZ", city: "LEUPP" },
  "86036": { state: "AZ", city: "MARBLE CANYON" },
  "86038": { state: "AZ", city: "MORMON LAKE" },
  "86039": { state: "AZ", city: "KYKOTSMOVI VILLAGE" },
  "86040": { state: "AZ", city: "PAGE" },
  "86042": { state: "AZ", city: "POLACCA" },
  "86043": { state: "AZ", city: "SECOND MESA" },
  "86044": { state: "AZ", city: "TONALEA" },
  "86045": { state: "AZ", city: "TUBA CITY" },
  "86046": { state: "AZ", city: "WILLIAMS" },
  "86047": { state: "AZ", city: "WINSLOW" },
  "86052": { state: "AZ", city: "NORTH RIM" },
  "86053": { state: "AZ", city: "KAIBETO" },
  "86054": { state: "AZ", city: "SHONTO" },
  "86301": { state: "AZ", city: "PRESCOTT" },
  "86302": { state: "AZ", city: "PRESCOTT" },
  "86303": { state: "AZ", city: "PRESCOTT" },
  "86304": { state: "AZ", city: "PRESCOTT" },
  "86305": { state: "AZ", city: "PRESCOTT" },
  "86312": { state: "AZ", city: "PRESCOTT VALLEY" },
  "86313": { state: "AZ", city: "PRESCOTT" },
  "86314": { state: "AZ", city: "PRESCOTT VALLEY" },
  "86315": { state: "AZ", city: "PRESCOTT VALLEY" },
  "86320": { state: "AZ", city: "ASH FORK" },
  "86321": { state: "AZ", city: "BAGDAD" },
  "86322": { state: "AZ", city: "CAMP VERDE" },
  "86323": { state: "AZ", city: "CHINO VALLEY" },
  "86324": { state: "AZ", city: "CLARKDALE" },
  "86325": { state: "AZ", city: "CORNVILLE" },
  "86326": { state: "AZ", city: "COTTONWOOD" },
  "86327": { state: "AZ", city: "DEWEY" },
  "86329": { state: "AZ", city: "HUMBOLDT" },
  "86331": { state: "AZ", city: "JEROME" },
  "86332": { state: "AZ", city: "KIRKLAND" },
  "86333": { state: "AZ", city: "MAYER" },
  "86334": { state: "AZ", city: "PAULDEN" },
  "86335": { state: "AZ", city: "RIMROCK" },
  "86336": { state: "AZ", city: "SEDONA" },
  "86337": { state: "AZ", city: "SELIGMAN" },
  "86338": { state: "AZ", city: "SKULL VALLEY" },
  "86339": { state: "AZ", city: "SEDONA" },
  "86340": { state: "AZ", city: "SEDONA" },
  "86341": { state: "AZ", city: "SEDONA" },
  "86342": { state: "AZ", city: "LAKE MONTEZUMA" },
  "86343": { state: "AZ", city: "CROWN KING" },
  "86351": { state: "AZ", city: "SEDONA" },
  "86401": { state: "AZ", city: "KINGMAN" },
  "86402": { state: "AZ", city: "KINGMAN" },
  "86403": { state: "AZ", city: "LAKE HAVASU CITY" },
  "86404": { state: "AZ", city: "LAKE HAVASU CITY" },
  "86405": { state: "AZ", city: "LAKE HAVASU CITY" },
  "86406": { state: "AZ", city: "LAKE HAVASU CITY" },
  "86409": { state: "AZ", city: "KINGMAN" },
  "86411": { state: "AZ", city: "HACKBERRY" },
  "86412": { state: "AZ", city: "HUALAPAI" },
  "86413": { state: "AZ", city: "GOLDEN VALLEY" },
  "86426": { state: "AZ", city: "FORT MOHAVE" },
  "86427": { state: "AZ", city: "FORT MOHAVE" },
  "86429": { state: "AZ", city: "BULLHEAD CITY" },
  "86430": { state: "AZ", city: "BULLHEAD CITY" },
  "86431": { state: "AZ", city: "CHLORIDE" },
  "86432": { state: "AZ", city: "LITTLEFIELD" },
  "86433": { state: "AZ", city: "OATMAN" },
  "86434": { state: "AZ", city: "PEACH SPRINGS" },
  "86435": { state: "AZ", city: "SUPAI" },
  "86436": { state: "AZ", city: "TOPOCK" },
  "86437": { state: "AZ", city: "VALENTINE" },
  "86438": { state: "AZ", city: "YUCCA" },
  "86439": { state: "AZ", city: "BULLHEAD CITY" },
  "86440": { state: "AZ", city: "MOHAVE VALLEY" },
  "86441": { state: "AZ", city: "DOLAN SPRINGS" },
  "86442": { state: "AZ", city: "BULLHEAD CITY" },
  "86443": { state: "AZ", city: "TEMPLE BAR MARINA" },
  "86444": { state: "AZ", city: "MEADVIEW" },
  "86445": { state: "AZ", city: "WILLOW BEACH" },
  "86446": { state: "AZ", city: "MOHAVE VALLEY" },
  "86502": { state: "AZ", city: "CHAMBERS" },
  "86503": { state: "AZ", city: "CHINLE" },
  "86504": { state: "AZ", city: "FORT DEFIANCE" },
  "86505": { state: "AZ", city: "GANADO" },
  "86506": { state: "AZ", city: "HOUCK" },
  "86507": { state: "AZ", city: "LUKACHUKAI" },
  "86508": { state: "AZ", city: "LUPTON" },
  "86510": { state: "AZ", city: "PINON" },
  "86511": { state: "AZ", city: "SAINT MICHAELS" },
  "86512": { state: "AZ", city: "SANDERS" },
  "86514": { state: "AZ", city: "TEEC NOS POS" },
  "86515": { state: "AZ", city: "WINDOW ROCK" },
  "86520": { state: "AZ", city: "BLUE GAP" },
  "86535": { state: "AZ", city: "DENNEHOTSO" },
  "86538": { state: "AZ", city: "MANY FARMS" },
  "86540": { state: "AZ", city: "NAZLINI" },
  "86544": { state: "AZ", city: "RED VALLEY" },
  "86545": { state: "AZ", city: "ROCK POINT" },
  "86547": { state: "AZ", city: "ROUND ROCK" },
  "86556": { state: "AZ", city: "TSAILE" },
  "87001": { state: "NM", city: "ALGODONES" },
  "87002": { state: "NM", city: "BELEN" },
  "87004": { state: "NM", city: "BERNALILLO" },
  "87005": { state: "NM", city: "BLUEWATER" },
  "87006": { state: "NM", city: "BOSQUE" },
  "87007": { state: "NM", city: "CASA BLANCA" },
  "87008": { state: "NM", city: "CEDAR CREST" },
  "87009": { state: "NM", city: "CEDARVALE" },
  "87010": { state: "NM", city: "CERRILLOS" },
  "87011": { state: "NM", city: "CLAUNCH" },
  "87012": { state: "NM", city: "COYOTE" },
  "87013": { state: "NM", city: "CUBA" },
  "87014": { state: "NM", city: "CUBERO" },
  "87015": { state: "NM", city: "EDGEWOOD" },
  "87016": { state: "NM", city: "ESTANCIA" },
  "87017": { state: "NM", city: "GALLINA" },
  "87018": { state: "NM", city: "COUNSELOR" },
  "87020": { state: "NM", city: "GRANTS" },
  "87021": { state: "NM", city: "MILAN" },
  "87022": { state: "NM", city: "ISLETA" },
  "87023": { state: "NM", city: "JARALES" },
  "87024": { state: "NM", city: "JEMEZ PUEBLO" },
  "87025": { state: "NM", city: "JEMEZ SPRINGS" },
  "87026": { state: "NM", city: "LAGUNA" },
  "87027": { state: "NM", city: "LA JARA" },
  "87028": { state: "NM", city: "LA JOYA" },
  "87029": { state: "NM", city: "LINDRITH" },
  "87031": { state: "NM", city: "LOS LUNAS" },
  "87032": { state: "NM", city: "MCINTOSH" },
  "87034": { state: "NM", city: "PUEBLO OF ACOMA" },
  "87035": { state: "NM", city: "MORIARTY" },
  "87036": { state: "NM", city: "MOUNTAINAIR" },
  "87037": { state: "NM", city: "NAGEEZI" },
  "87038": { state: "NM", city: "NEW LAGUNA" },
  "87040": { state: "NM", city: "PAGUATE" },
  "87041": { state: "NM", city: "PENA BLANCA" },
  "87042": { state: "NM", city: "PERALTA" },
  "87043": { state: "NM", city: "PLACITAS" },
  "87044": { state: "NM", city: "PONDEROSA" },
  "87045": { state: "NM", city: "PREWITT" },
  "87046": { state: "NM", city: "REGINA" },
  "87047": { state: "NM", city: "SANDIA PARK" },
  "87048": { state: "NM", city: "CORRALES" },
  "87049": { state: "NM", city: "SAN FIDEL" },
  "87051": { state: "NM", city: "SAN RAFAEL" },
  "87052": { state: "NM", city: "SANTO DOMINGO PUEBLO" },
  "87053": { state: "NM", city: "SAN YSIDRO" },
  "87056": { state: "NM", city: "STANLEY" },
  "87059": { state: "NM", city: "TIJERAS" },
  "87060": { state: "NM", city: "TOME" },
  "87061": { state: "NM", city: "TORREON" },
  "87062": { state: "NM", city: "VEGUITA" },
  "87063": { state: "NM", city: "WILLARD" },
  "87064": { state: "NM", city: "YOUNGSVILLE" },
  "87068": { state: "NM", city: "BOSQUE FARMS" },
  "87070": { state: "NM", city: "CLINES CORNERS" },
  "87072": { state: "NM", city: "COCHITI PUEBLO" },
  "87083": { state: "NM", city: "COCHITI LAKE" },
  "87101": { state: "NM", city: "ALBUQUERQUE" },
  "87102": { state: "NM", city: "ALBUQUERQUE" },
  "87103": { state: "NM", city: "ALBUQUERQUE" },
  "87104": { state: "NM", city: "ALBUQUERQUE" },
  "87105": { state: "NM", city: "ALBUQUERQUE" },
  "87106": { state: "NM", city: "ALBUQUERQUE" },
  "87107": { state: "NM", city: "ALBUQUERQUE" },
  "87108": { state: "NM", city: "ALBUQUERQUE" },
  "87109": { state: "NM", city: "ALBUQUERQUE" },
  "87110": { state: "NM", city: "ALBUQUERQUE" },
  "87111": { state: "NM", city: "ALBUQUERQUE" },
  "87112": { state: "NM", city: "ALBUQUERQUE" },
  "87113": { state: "NM", city: "ALBUQUERQUE" },
  "87114": { state: "NM", city: "ALBUQUERQUE" },
  "87115": { state: "NM", city: "ALBUQUERQUE" },
  "87116": { state: "NM", city: "ALBUQUERQUE" },
  "87117": { state: "NM", city: "KIRTLAND AFB" },
  "87119": { state: "NM", city: "ALBUQUERQUE" },
  "87120": { state: "NM", city: "ALBUQUERQUE" },
  "87121": { state: "NM", city: "ALBUQUERQUE" },
  "87122": { state: "NM", city: "ALBUQUERQUE" },
  "87123": { state: "NM", city: "ALBUQUERQUE" },
  "87124": { state: "NM", city: "RIO RANCHO" },
  "87125": { state: "NM", city: "ALBUQUERQUE" },
  "87131": { state: "NM", city: "ALBUQUERQUE" },
  "87144": { state: "NM", city: "RIO RANCHO" },
  "87151": { state: "NM", city: "ALBUQUERQUE" },
  "87153": { state: "NM", city: "ALBUQUERQUE" },
  "87154": { state: "NM", city: "ALBUQUERQUE" },
  "87158": { state: "NM", city: "ALBUQUERQUE" },
  "87174": { state: "NM", city: "RIO RANCHO" },
  "87176": { state: "NM", city: "ALBUQUERQUE" },
  "87181": { state: "NM", city: "ALBUQUERQUE" },
  "87184": { state: "NM", city: "ALBUQUERQUE" },
  "87185": { state: "NM", city: "ALBUQUERQUE" },
  "87187": { state: "NM", city: "ALBUQUERQUE" },
  "87190": { state: "NM", city: "ALBUQUERQUE" },
  "87191": { state: "NM", city: "ALBUQUERQUE" },
  "87192": { state: "NM", city: "ALBUQUERQUE" },
  "87193": { state: "NM", city: "ALBUQUERQUE" },
  "87194": { state: "NM", city: "ALBUQUERQUE" },
  "87195": { state: "NM", city: "ALBUQUERQUE" },
  "87196": { state: "NM", city: "ALBUQUERQUE" },
  "87197": { state: "NM", city: "ALBUQUERQUE" },
  "87198": { state: "NM", city: "ALBUQUERQUE" },
  "87199": { state: "NM", city: "ALBUQUERQUE" },
  "87301": { state: "NM", city: "GALLUP" },
  "87302": { state: "NM", city: "GALLUP" },
  "87305": { state: "NM", city: "GALLUP" },
  "87310": { state: "NM", city: "BRIMHALL" },
  "87311": { state: "NM", city: "CHURCH ROCK" },
  "87312": { state: "NM", city: "CONTINENTAL DIVIDE" },
  "87313": { state: "NM", city: "CROWNPOINT" },
  "87315": { state: "NM", city: "FENCE LAKE" },
  "87316": { state: "NM", city: "FORT WINGATE" },
  "87317": { state: "NM", city: "GAMERCO" },
  "87319": { state: "NM", city: "MENTMORE" },
  "87320": { state: "NM", city: "MEXICAN SPRINGS" },
  "87321": { state: "NM", city: "RAMAH" },
  "87322": { state: "NM", city: "REHOBOTH" },
  "87323": { state: "NM", city: "THOREAU" },
  "87325": { state: "NM", city: "TOHATCHI" },
  "87326": { state: "NM", city: "VANDERWAGEN" },
  "87327": { state: "NM", city: "ZUNI" },
  "87328": { state: "NM", city: "NAVAJO" },
  "87347": { state: "NM", city: "JAMESTOWN" },
  "87357": { state: "NM", city: "PINEHILL" },
  "87364": { state: "NM", city: "SHEEP SPRINGS" },
  "87365": { state: "NM", city: "SMITH LAKE" },
  "87375": { state: "NM", city: "YATAHEY" },
  "87401": { state: "NM", city: "FARMINGTON" },
  "87402": { state: "NM", city: "FARMINGTON" },
  "87410": { state: "NM", city: "AZTEC" },
  "87412": { state: "NM", city: "BLANCO" },
  "87413": { state: "NM", city: "BLOOMFIELD" },
  "87415": { state: "NM", city: "FLORA VISTA" },
  "87416": { state: "NM", city: "FRUITLAND" },
  "87417": { state: "NM", city: "KIRTLAND" },
  "87418": { state: "NM", city: "LA PLATA" },
  "87419": { state: "NM", city: "NAVAJO DAM" },
  "87420": { state: "NM", city: "SHIPROCK" },
  "87421": { state: "NM", city: "WATERFLOW" },
  "87455": { state: "NM", city: "NEWCOMB" },
  "87461": { state: "NM", city: "SANOSTEE" },
  "87499": { state: "NM", city: "FARMINGTON" },
  "87501": { state: "NM", city: "SANTA FE" },
  "87502": { state: "NM", city: "SANTA FE" },
  "87503": { state: "NM", city: "SANTA FE" },
  "87504": { state: "NM", city: "SANTA FE" },
  "87505": { state: "NM", city: "SANTA FE" },
  "87506": { state: "NM", city: "SANTA FE" },
  "87507": { state: "NM", city: "SANTA FE" },
  "87508": { state: "NM", city: "SANTA FE" },
  "87509": { state: "NM", city: "SANTA FE" },
  "87510": { state: "NM", city: "ABIQUIU" },
  "87511": { state: "NM", city: "ALCALDE" },
  "87512": { state: "NM", city: "AMALIA" },
  "87513": { state: "NM", city: "ARROYO HONDO" },
  "87514": { state: "NM", city: "ARROYO SECO" },
  "87515": { state: "NM", city: "CANJILON" },
  "87516": { state: "NM", city: "CANONES" },
  "87517": { state: "NM", city: "CARSON" },
  "87518": { state: "NM", city: "CEBOLLA" },
  "87519": { state: "NM", city: "CERRO" },
  "87520": { state: "NM", city: "CHAMA" },
  "87521": { state: "NM", city: "CHAMISAL" },
  "87522": { state: "NM", city: "CHIMAYO" },
  "87523": { state: "NM", city: "CORDOVA" },
  "87524": { state: "NM", city: "COSTILLA" },
  "87525": { state: "NM", city: "TAOS SKI VALLEY" },
  "87527": { state: "NM", city: "DIXON" },
  "87528": { state: "NM", city: "DULCE" },
  "87529": { state: "NM", city: "EL PRADO" },
  "87530": { state: "NM", city: "EL RITO" },
  "87531": { state: "NM", city: "EMBUDO" },
  "87532": { state: "NM", city: "ESPANOLA" },
  "87533": { state: "NM", city: "ESPANOLA" },
  "87535": { state: "NM", city: "GLORIETA" },
  "87537": { state: "NM", city: "HERNANDEZ" },
  "87538": { state: "NM", city: "ILFELD" },
  "87539": { state: "NM", city: "LA MADERA" },
  "87540": { state: "NM", city: "LAMY" },
  "87543": { state: "NM", city: "LLANO" },
  "87544": { state: "NM", city: "LOS ALAMOS" },
  "87545": { state: "NM", city: "LOS ALAMOS" },
  "87547": { state: "NM", city: "WHITE ROCK" },
  "87548": { state: "NM", city: "MEDANALES" },
  "87549": { state: "NM", city: "OJO CALIENTE" },
  "87551": { state: "NM", city: "LOS OJOS" },
  "87552": { state: "NM", city: "PECOS" },
  "87553": { state: "NM", city: "PENASCO" },
  "87554": { state: "NM", city: "PETACA" },
  "87556": { state: "NM", city: "QUESTA" },
  "87557": { state: "NM", city: "RANCHOS DE TAOS" },
  "87558": { state: "NM", city: "RED RIVER" },
  "87560": { state: "NM", city: "RIBERA" },
  "87562": { state: "NM", city: "ROWE" },
  "87564": { state: "NM", city: "SAN CRISTOBAL" },
  "87565": { state: "NM", city: "SAN JOSE" },
  "87566": { state: "NM", city: "OHKAY OWINGEH" },
  "87567": { state: "NM", city: "SANTA CRUZ" },
  "87569": { state: "NM", city: "SERAFINA" },
  "87571": { state: "NM", city: "TAOS" },
  "87573": { state: "NM", city: "TERERRO" },
  "87574": { state: "NM", city: "TESUQUE" },
  "87575": { state: "NM", city: "TIERRA AMARILLA" },
  "87576": { state: "NM", city: "TRAMPAS" },
  "87577": { state: "NM", city: "TRES PIEDRAS" },
  "87578": { state: "NM", city: "TRUCHAS" },
  "87579": { state: "NM", city: "VADITO" },
  "87580": { state: "NM", city: "VALDEZ" },
  "87581": { state: "NM", city: "VALLECITOS" },
  "87582": { state: "NM", city: "VELARDE" },
  "87583": { state: "NM", city: "VILLANUEVA" },
  "87592": { state: "NM", city: "SANTA FE" },
  "87594": { state: "NM", city: "SANTA FE" },
  "87654": { state: "NM", city: "SPACEPORT CITY" },
  "87701": { state: "NM", city: "LAS VEGAS" },
  "87710": { state: "NM", city: "ANGEL FIRE" },
  "87711": { state: "NM", city: "ANTON CHICO" },
  "87712": { state: "NM", city: "BUENA VISTA" },
  "87713": { state: "NM", city: "CHACON" },
  "87714": { state: "NM", city: "CIMARRON" },
  "87715": { state: "NM", city: "CLEVELAND" },
  "87718": { state: "NM", city: "EAGLE NEST" },
  "87722": { state: "NM", city: "GUADALUPITA" },
  "87723": { state: "NM", city: "HOLMAN" },
  "87724": { state: "NM", city: "LA LOMA" },
  "87728": { state: "NM", city: "MAXWELL" },
  "87729": { state: "NM", city: "MIAMI" },
  "87730": { state: "NM", city: "MILLS" },
  "87731": { state: "NM", city: "MONTEZUMA" },
  "87732": { state: "NM", city: "MORA" },
  "87733": { state: "NM", city: "MOSQUERO" },
  "87734": { state: "NM", city: "OCATE" },
  "87735": { state: "NM", city: "OJO FELIZ" },
  "87736": { state: "NM", city: "RAINSVILLE" },
  "87740": { state: "NM", city: "RATON" },
  "87742": { state: "NM", city: "ROCIADA" },
  "87743": { state: "NM", city: "ROY" },
  "87745": { state: "NM", city: "SAPELLO" },
  "87746": { state: "NM", city: "SOLANO" },
  "87747": { state: "NM", city: "SPRINGER" },
  "87749": { state: "NM", city: "UTE PARK" },
  "87750": { state: "NM", city: "VALMORA" },
  "87752": { state: "NM", city: "WAGON MOUND" },
  "87753": { state: "NM", city: "WATROUS" },
  "87801": { state: "NM", city: "SOCORRO" },
  "87820": { state: "NM", city: "ARAGON" },
  "87821": { state: "NM", city: "DATIL" },
  "87823": { state: "NM", city: "LEMITAR" },
  "87824": { state: "NM", city: "LUNA" },
  "87825": { state: "NM", city: "MAGDALENA" },
  "87827": { state: "NM", city: "PIE TOWN" },
  "87828": { state: "NM", city: "POLVADERA" },
  "87829": { state: "NM", city: "QUEMADO" },
  "87830": { state: "NM", city: "RESERVE" },
  "87831": { state: "NM", city: "SAN ACACIA" },
  "87832": { state: "NM", city: "SAN ANTONIO" },
  "87901": { state: "NM", city: "TRUTH OR CONSEQUENCES" },
  "87930": { state: "NM", city: "ARREY" },
  "87931": { state: "NM", city: "CABALLO" },
  "87933": { state: "NM", city: "DERRY" },
  "87935": { state: "NM", city: "ELEPHANT BUTTE" },
  "87936": { state: "NM", city: "GARFIELD" },
  "87937": { state: "NM", city: "HATCH" },
  "87939": { state: "NM", city: "MONTICELLO" },
  "87940": { state: "NM", city: "RINCON" },
  "87941": { state: "NM", city: "SALEM" },
  "87942": { state: "NM", city: "WILLIAMSBURG" },
  "87943": { state: "NM", city: "WINSTON" },
  "88001": { state: "NM", city: "LAS CRUCES" },
  "88002": { state: "NM", city: "WHITE SANDS MISSILE RANGE" },
  "88003": { state: "NM", city: "LAS CRUCES" },
  "88004": { state: "NM", city: "LAS CRUCES" },
  "88005": { state: "NM", city: "LAS CRUCES" },
  "88006": { state: "NM", city: "LAS CRUCES" },
  "88007": { state: "NM", city: "LAS CRUCES" },
  "88008": { state: "NM", city: "SANTA TERESA" },
  "88009": { state: "NM", city: "PLAYAS" },
  "88011": { state: "NM", city: "LAS CRUCES" },
  "88012": { state: "NM", city: "LAS CRUCES" },
  "88013": { state: "NM", city: "LAS CRUCES" },
  "88020": { state: "NM", city: "ANIMAS" },
  "88021": { state: "NM", city: "ANTHONY" },
  "88022": { state: "NM", city: "ARENAS VALLEY" },
  "88023": { state: "NM", city: "BAYARD" },
  "88024": { state: "NM", city: "BERINO" },
  "88025": { state: "NM", city: "BUCKHORN" },
  "88026": { state: "NM", city: "SANTA CLARA" },
  "88027": { state: "NM", city: "CHAMBERINO" },
  "88028": { state: "NM", city: "CLIFF" },
  "88029": { state: "NM", city: "COLUMBUS" },
  "88030": { state: "NM", city: "DEMING" },
  "88031": { state: "NM", city: "DEMING" },
  "88032": { state: "NM", city: "DONA ANA" },
  "88033": { state: "NM", city: "FAIRACRES" },
  "88034": { state: "NM", city: "FAYWOOD" },
  "88036": { state: "NM", city: "FORT BAYARD" },
  "88038": { state: "NM", city: "GILA" },
  "88039": { state: "NM", city: "GLENWOOD" },
  "88040": { state: "NM", city: "HACHITA" },
  "88041": { state: "NM", city: "HANOVER" },
  "88042": { state: "NM", city: "HILLSBORO" },
  "88043": { state: "NM", city: "HURLEY" },
  "88044": { state: "NM", city: "LA MESA" },
  "88045": { state: "NM", city: "LORDSBURG" },
  "88046": { state: "NM", city: "MESILLA" },
  "88047": { state: "NM", city: "MESILLA PARK" },
  "88048": { state: "NM", city: "MESQUITE" },
  "88049": { state: "NM", city: "MIMBRES" },
  "88051": { state: "NM", city: "MULE CREEK" },
  "88052": { state: "NM", city: "ORGAN" },
  "88053": { state: "NM", city: "PINOS ALTOS" },
  "88054": { state: "NM", city: "RADIUM SPRINGS" },
  "88055": { state: "NM", city: "REDROCK" },
  "88056": { state: "NM", city: "RODEO" },
  "88058": { state: "NM", city: "SAN MIGUEL" },
  "88061": { state: "NM", city: "SILVER CITY" },
  "88062": { state: "NM", city: "SILVER CITY" },
  "88063": { state: "NM", city: "SUNLAND PARK" },
  "88065": { state: "NM", city: "TYRONE" },
  "88072": { state: "NM", city: "VADO" },
  "88081": { state: "NM", city: "CHAPARRAL" },
  "88101": { state: "NM", city: "CLOVIS" },
  "88102": { state: "NM", city: "CLOVIS" },
  "88103": { state: "NM", city: "CANNON AFB" },
  "88112": { state: "NM", city: "BROADVIEW" },
  "88113": { state: "NM", city: "CAUSEY" },
  "88114": { state: "NM", city: "CROSSROADS" },
  "88115": { state: "NM", city: "DORA" },
  "88116": { state: "NM", city: "ELIDA" },
  "88118": { state: "NM", city: "FLOYD" },
  "88119": { state: "NM", city: "FORT SUMNER" },
  "88120": { state: "NM", city: "GRADY" },
  "88121": { state: "NM", city: "HOUSE" },
  "88122": { state: "NM", city: "KENNA" },
  "88123": { state: "NM", city: "LINGO" },
  "88124": { state: "NM", city: "MELROSE" },
  "88125": { state: "NM", city: "MILNESAND" },
  "88126": { state: "NM", city: "PEP" },
  "88130": { state: "NM", city: "PORTALES" },
  "88132": { state: "NM", city: "ROGERS" },
  "88133": { state: "NM", city: "SAINT VRAIN" },
  "88134": { state: "NM", city: "TAIBAN" },
  "88135": { state: "NM", city: "TEXICO" },
  "88136": { state: "NM", city: "YESO" },
  "88201": { state: "NM", city: "ROSWELL" },
  "88202": { state: "NM", city: "ROSWELL" },
  "88203": { state: "NM", city: "ROSWELL" },
  "88210": { state: "NM", city: "ARTESIA" },
  "88211": { state: "NM", city: "ARTESIA" },
  "88213": { state: "NM", city: "CAPROCK" },
  "88220": { state: "NM", city: "CARLSBAD" },
  "88221": { state: "NM", city: "CARLSBAD" },
  "88230": { state: "NM", city: "DEXTER" },
  "88231": { state: "NM", city: "EUNICE" },
  "88232": { state: "NM", city: "HAGERMAN" },
  "88240": { state: "NM", city: "HOBBS" },
  "88241": { state: "NM", city: "HOBBS" },
  "88242": { state: "NM", city: "HOBBS" },
  "88244": { state: "NM", city: "HOBBS" },
  "88250": { state: "NM", city: "HOPE" },
  "88252": { state: "NM", city: "JAL" },
  "88253": { state: "NM", city: "LAKE ARTHUR" },
  "88254": { state: "NM", city: "LAKEWOOD" },
  "88255": { state: "NM", city: "LOCO HILLS" },
  "88256": { state: "NM", city: "LOVING" },
  "88260": { state: "NM", city: "LOVINGTON" },
  "88262": { state: "NM", city: "MCDONALD" },
  "88263": { state: "NM", city: "MALAGA" },
  "88264": { state: "NM", city: "MALJAMAR" },
  "88265": { state: "NM", city: "MONUMENT" },
  "88267": { state: "NM", city: "TATUM" },
  "88268": { state: "NM", city: "WHITES CITY" },
  "88301": { state: "NM", city: "CARRIZOZO" },
  "88310": { state: "NM", city: "ALAMOGORDO" },
  "88311": { state: "NM", city: "ALAMOGORDO" },
  "88312": { state: "NM", city: "ALTO" },
  "88314": { state: "NM", city: "BENT" },
  "88316": { state: "NM", city: "CAPITAN" },
  "88317": { state: "NM", city: "CLOUDCROFT" },
  "88318": { state: "NM", city: "CORONA" },
  "88321": { state: "NM", city: "ENCINO" },
  "88323": { state: "NM", city: "FORT STANTON" },
  "88324": { state: "NM", city: "GLENCOE" },
  "88325": { state: "NM", city: "HIGH ROLLS MOUNTAIN PARK" },
  "88330": { state: "NM", city: "HOLLOMAN AIR FORCE BASE" },
  "88336": { state: "NM", city: "HONDO" },
  "88337": { state: "NM", city: "LA LUZ" },
  "88338": { state: "NM", city: "LINCOLN" },
  "88339": { state: "NM", city: "MAYHILL" },
  "88340": { state: "NM", city: "MESCALERO" },
  "88341": { state: "NM", city: "NOGAL" },
  "88342": { state: "NM", city: "OROGRANDE" },
  "88343": { state: "NM", city: "PICACHO" },
  "88344": { state: "NM", city: "PINON" },
  "88345": { state: "NM", city: "RUIDOSO" },
  "88346": { state: "NM", city: "RUIDOSO DOWNS" },
  "88347": { state: "NM", city: "SACRAMENTO" },
  "88348": { state: "NM", city: "SAN PATRICIO" },
  "88349": { state: "NM", city: "SUNSPOT" },
  "88350": { state: "NM", city: "TIMBERON" },
  "88351": { state: "NM", city: "TINNIE" },
  "88352": { state: "NM", city: "TULAROSA" },
  "88353": { state: "NM", city: "VAUGHN" },
  "88354": { state: "NM", city: "WEED" },
  "88355": { state: "NM", city: "RUIDOSO" },
  "88401": { state: "NM", city: "TUCUMCARI" },
  "88410": { state: "NM", city: "AMISTAD" },
  "88411": { state: "NM", city: "BARD" },
  "88414": { state: "NM", city: "CAPULIN" },
  "88415": { state: "NM", city: "CLAYTON" },
  "88416": { state: "NM", city: "CONCHAS DAM" },
  "88417": { state: "NM", city: "CUERVO" },
  "88418": { state: "NM", city: "DES MOINES" },
  "88419": { state: "NM", city: "FOLSOM" },
  "88421": { state: "NM", city: "GARITA" },
  "88422": { state: "NM", city: "GLADSTONE" },
  "88424": { state: "NM", city: "GRENVILLE" },
  "88426": { state: "NM", city: "LOGAN" },
  "88427": { state: "NM", city: "MCALISTER" },
  "88430": { state: "NM", city: "NARA VISA" },
  "88431": { state: "NM", city: "NEWKIRK" },
  "88433": { state: "NM", city: "QUAY" },
  "88434": { state: "NM", city: "SAN JON" },
  "88435": { state: "NM", city: "SANTA ROSA" },
  "88436": { state: "NM", city: "SEDAN" },
  "88439": { state: "NM", city: "TREMENTINA" },
  "88510": { state: "TX", city: "EL PASO" },
  "88511": { state: "TX", city: "EL PASO" },
  "88512": { state: "TX", city: "EL PASO" },
  "88513": { state: "TX", city: "EL PASO" },
  "88514": { state: "TX", city: "EL PASO" },
  "88515": { state: "TX", city: "EL PASO" },
  "88517": { state: "TX", city: "EL PASO" },
  "88518": { state: "TX", city: "EL PASO" },
  "88519": { state: "TX", city: "EL PASO" },
  "88520": { state: "TX", city: "EL PASO" },
  "88521": { state: "TX", city: "EL PASO" },
  "88523": { state: "TX", city: "EL PASO" },
  "88524": { state: "TX", city: "EL PASO" },
  "88525": { state: "TX", city: "EL PASO" },
  "88526": { state: "TX", city: "EL PASO" },
  "88527": { state: "TX", city: "EL PASO" },
  "88528": { state: "TX", city: "EL PASO" },
  "88529": { state: "TX", city: "EL PASO" },
  "88530": { state: "TX", city: "EL PASO" },
  "88531": { state: "TX", city: "EL PASO" },
  "88532": { state: "TX", city: "EL PASO" },
  "88533": { state: "TX", city: "EL PASO" },
  "88534": { state: "TX", city: "EL PASO" },
  "88535": { state: "TX", city: "EL PASO" },
  "88536": { state: "TX", city: "EL PASO" },
  "88538": { state: "TX", city: "EL PASO" },
  "88539": { state: "TX", city: "EL PASO" },
  "88540": { state: "TX", city: "EL PASO" },
  "88541": { state: "TX", city: "EL PASO" },
  "88542": { state: "TX", city: "EL PASO" },
  "88543": { state: "TX", city: "EL PASO" },
  "88544": { state: "TX", city: "EL PASO" },
  "88545": { state: "TX", city: "EL PASO" },
  "88546": { state: "TX", city: "EL PASO" },
  "88547": { state: "TX", city: "EL PASO" },
  "88548": { state: "TX", city: "EL PASO" },
  "88549": { state: "TX", city: "EL PASO" },
  "88550": { state: "TX", city: "EL PASO" },
  "88553": { state: "TX", city: "EL PASO" },
  "88554": { state: "TX", city: "EL PASO" },
  "88555": { state: "TX", city: "EL PASO" },
  "88556": { state: "TX", city: "EL PASO" },
  "88557": { state: "TX", city: "EL PASO" },
  "88558": { state: "TX", city: "EL PASO" },
  "88559": { state: "TX", city: "EL PASO" },
  "88560": { state: "TX", city: "EL PASO" },
  "88561": { state: "TX", city: "EL PASO" },
  "88562": { state: "TX", city: "EL PASO" },
  "88563": { state: "TX", city: "EL PASO" },
  "88565": { state: "TX", city: "EL PASO" },
  "88566": { state: "TX", city: "EL PASO" },
  "88567": { state: "TX", city: "EL PASO" },
  "88568": { state: "TX", city: "EL PASO" },
  "88569": { state: "TX", city: "EL PASO" },
  "88570": { state: "TX", city: "EL PASO" },
  "88571": { state: "TX", city: "EL PASO" },
  "88572": { state: "TX", city: "EL PASO" },
  "88573": { state: "TX", city: "EL PASO" },
  "88574": { state: "TX", city: "EL PASO" },
  "88575": { state: "TX", city: "EL PASO" },
  "88576": { state: "TX", city: "EL PASO" },
  "88577": { state: "TX", city: "EL PASO" },
  "88578": { state: "TX", city: "EL PASO" },
  "88579": { state: "TX", city: "EL PASO" },
  "88580": { state: "TX", city: "EL PASO" },
  "88581": { state: "TX", city: "EL PASO" },
  "88582": { state: "TX", city: "EL PASO" },
  "88583": { state: "TX", city: "EL PASO" },
  "88584": { state: "TX", city: "EL PASO" },
  "88585": { state: "TX", city: "EL PASO" },
  "88586": { state: "TX", city: "EL PASO" },
  "88587": { state: "TX", city: "EL PASO" },
  "88588": { state: "TX", city: "EL PASO" },
  "88589": { state: "TX", city: "EL PASO" },
  "88590": { state: "TX", city: "EL PASO" },
  "88595": { state: "TX", city: "EL PASO" },
  "88888": { state: "DC", city: "NORTH POLE" },
  "88901": { state: "NV", city: "THE LAKES" },
  "88905": { state: "NV", city: "THE LAKES" },
  "89001": { state: "NV", city: "ALAMO" },
  "89002": { state: "NV", city: "HENDERSON" },
  "89003": { state: "NV", city: "BEATTY" },
  "89004": { state: "NV", city: "BLUE DIAMOND" },
  "89005": { state: "NV", city: "BOULDER CITY" },
  "89006": { state: "NV", city: "BOULDER CITY" },
  "89007": { state: "NV", city: "BUNKERVILLE" },
  "89008": { state: "NV", city: "CALIENTE" },
  "89009": { state: "NV", city: "HENDERSON" },
  "89010": { state: "NV", city: "DYER" },
  "89011": { state: "NV", city: "HENDERSON" },
  "89012": { state: "NV", city: "HENDERSON" },
  "89013": { state: "NV", city: "GOLDFIELD" },
  "89014": { state: "NV", city: "HENDERSON" },
  "89015": { state: "NV", city: "HENDERSON" },
  "89016": { state: "NV", city: "HENDERSON" },
  "89017": { state: "NV", city: "HIKO" },
  "89018": { state: "NV", city: "INDIAN SPRINGS" },
  "89019": { state: "NV", city: "JEAN" },
  "89020": { state: "NV", city: "AMARGOSA VALLEY" },
  "89021": { state: "NV", city: "LOGANDALE" },
  "89022": { state: "NV", city: "MANHATTAN" },
  "89023": { state: "NV", city: "MERCURY" },
  "89024": { state: "NV", city: "MESQUITE" },
  "89025": { state: "NV", city: "MOAPA" },
  "89026": { state: "NV", city: "JEAN" },
  "89027": { state: "NV", city: "MESQUITE" },
  "89028": { state: "NV", city: "LAUGHLIN" },
  "89029": { state: "NV", city: "LAUGHLIN" },
  "89030": { state: "NV", city: "NORTH LAS VEGAS" },
  "89031": { state: "NV", city: "NORTH LAS VEGAS" },
  "89032": { state: "NV", city: "NORTH LAS VEGAS" },
  "89033": { state: "NV", city: "NORTH LAS VEGAS" },
  "89034": { state: "NV", city: "MESQUITE" },
  "89036": { state: "NV", city: "NORTH LAS VEGAS" },
  "89037": { state: "NV", city: "COYOTE SPRINGS" },
  "89039": { state: "NV", city: "CAL NEV ARI" },
  "89040": { state: "NV", city: "OVERTON" },
  "89041": { state: "NV", city: "PAHRUMP" },
  "89042": { state: "NV", city: "PANACA" },
  "89043": { state: "NV", city: "PIOCHE" },
  "89044": { state: "NV", city: "HENDERSON" },
  "89045": { state: "NV", city: "ROUND MOUNTAIN" },
  "89046": { state: "NV", city: "SEARCHLIGHT" },
  "89047": { state: "NV", city: "SILVERPEAK" },
  "89048": { state: "NV", city: "PAHRUMP" },
  "89049": { state: "NV", city: "TONOPAH" },
  "89052": { state: "NV", city: "HENDERSON" },
  "89053": { state: "NV", city: "HENDERSON" },
  "89054": { state: "NV", city: "SLOAN" },
  "89060": { state: "NV", city: "PAHRUMP" },
  "89061": { state: "NV", city: "PAHRUMP" },
  "89067": { state: "NV", city: "COYOTE SPRINGS" },
  "89070": { state: "NV", city: "INDIAN SPRINGS" },
  "89074": { state: "NV", city: "HENDERSON" },
  "89077": { state: "NV", city: "HENDERSON" },
  "89081": { state: "NV", city: "NORTH LAS VEGAS" },
  "89084": { state: "NV", city: "NORTH LAS VEGAS" },
  "89085": { state: "NV", city: "NORTH LAS VEGAS" },
  "89086": { state: "NV", city: "NORTH LAS VEGAS" },
  "89087": { state: "NV", city: "NORTH LAS VEGAS" },
  "89101": { state: "NV", city: "LAS VEGAS" },
  "89102": { state: "NV", city: "LAS VEGAS" },
  "89103": { state: "NV", city: "LAS VEGAS" },
  "89104": { state: "NV", city: "LAS VEGAS" },
  "89105": { state: "NV", city: "LAS VEGAS" },
  "89106": { state: "NV", city: "LAS VEGAS" },
  "89107": { state: "NV", city: "LAS VEGAS" },
  "89108": { state: "NV", city: "LAS VEGAS" },
  "89109": { state: "NV", city: "LAS VEGAS" },
  "89110": { state: "NV", city: "LAS VEGAS" },
  "89111": { state: "NV", city: "LAS VEGAS" },
  "89112": { state: "NV", city: "LAS VEGAS" },
  "89113": { state: "NV", city: "LAS VEGAS" },
  "89114": { state: "NV", city: "LAS VEGAS" },
  "89115": { state: "NV", city: "LAS VEGAS" },
  "89116": { state: "NV", city: "LAS VEGAS" },
  "89117": { state: "NV", city: "LAS VEGAS" },
  "89118": { state: "NV", city: "LAS VEGAS" },
  "89119": { state: "NV", city: "LAS VEGAS" },
  "89120": { state: "NV", city: "LAS VEGAS" },
  "89121": { state: "NV", city: "LAS VEGAS" },
  "89122": { state: "NV", city: "LAS VEGAS" },
  "89123": { state: "NV", city: "LAS VEGAS" },
  "89124": { state: "NV", city: "LAS VEGAS" },
  "89125": { state: "NV", city: "LAS VEGAS" },
  "89126": { state: "NV", city: "LAS VEGAS" },
  "89127": { state: "NV", city: "LAS VEGAS" },
  "89128": { state: "NV", city: "LAS VEGAS" },
  "89129": { state: "NV", city: "LAS VEGAS" },
  "89130": { state: "NV", city: "LAS VEGAS" },
  "89131": { state: "NV", city: "LAS VEGAS" },
  "89132": { state: "NV", city: "LAS VEGAS" },
  "89133": { state: "NV", city: "LAS VEGAS" },
  "89134": { state: "NV", city: "LAS VEGAS" },
  "89135": { state: "NV", city: "LAS VEGAS" },
  "89136": { state: "NV", city: "LAS VEGAS" },
  "89137": { state: "NV", city: "LAS VEGAS" },
  "89138": { state: "NV", city: "LAS VEGAS" },
  "89139": { state: "NV", city: "LAS VEGAS" },
  "89140": { state: "NV", city: "LAS VEGAS" },
  "89141": { state: "NV", city: "LAS VEGAS" },
  "89142": { state: "NV", city: "LAS VEGAS" },
  "89143": { state: "NV", city: "LAS VEGAS" },
  "89144": { state: "NV", city: "LAS VEGAS" },
  "89145": { state: "NV", city: "LAS VEGAS" },
  "89146": { state: "NV", city: "LAS VEGAS" },
  "89147": { state: "NV", city: "LAS VEGAS" },
  "89148": { state: "NV", city: "LAS VEGAS" },
  "89149": { state: "NV", city: "LAS VEGAS" },
  "89150": { state: "NV", city: "LAS VEGAS" },
  "89151": { state: "NV", city: "LAS VEGAS" },
  "89152": { state: "NV", city: "LAS VEGAS" },
  "89153": { state: "NV", city: "LAS VEGAS" },
  "89154": { state: "NV", city: "LAS VEGAS" },
  "89155": { state: "NV", city: "LAS VEGAS" },
  "89156": { state: "NV", city: "LAS VEGAS" },
  "89157": { state: "NV", city: "LAS VEGAS" },
  "89158": { state: "NV", city: "LAS VEGAS" },
  "89159": { state: "NV", city: "LAS VEGAS" },
  "89160": { state: "NV", city: "LAS VEGAS" },
  "89161": { state: "NV", city: "LAS VEGAS" },
  "89162": { state: "NV", city: "LAS VEGAS" },
  "89163": { state: "NV", city: "THE LAKES" },
  "89164": { state: "NV", city: "LAS VEGAS" },
  "89165": { state: "NV", city: "LAS VEGAS" },
  "89166": { state: "NV", city: "LAS VEGAS" },
  "89169": { state: "NV", city: "LAS VEGAS" },
  "89170": { state: "NV", city: "LAS VEGAS" },
  "89173": { state: "NV", city: "LAS VEGAS" },
  "89177": { state: "NV", city: "LAS VEGAS" },
  "89178": { state: "NV", city: "LAS VEGAS" },
  "89179": { state: "NV", city: "LAS VEGAS" },
  "89180": { state: "NV", city: "LAS VEGAS" },
  "89183": { state: "NV", city: "LAS VEGAS" },
  "89185": { state: "NV", city: "LAS VEGAS" },
  "89191": { state: "NV", city: "NELLIS AFB" },
  "89193": { state: "NV", city: "LAS VEGAS" },
  "89195": { state: "NV", city: "LAS VEGAS" },
  "89199": { state: "NV", city: "LAS VEGAS" },
  "89301": { state: "NV", city: "ELY" },
  "89310": { state: "NV", city: "AUSTIN" },
  "89311": { state: "NV", city: "BAKER" },
  "89314": { state: "NV", city: "DUCKWATER" },
  "89315": { state: "NV", city: "ELY" },
  "89316": { state: "NV", city: "EUREKA" },
  "89317": { state: "NV", city: "LUND" },
  "89318": { state: "NV", city: "MC GILL" },
  "89319": { state: "NV", city: "RUTH" },
  "89402": { state: "NV", city: "CRYSTAL BAY" },
  "89403": { state: "NV", city: "DAYTON" },
  "89404": { state: "NV", city: "DENIO" },
  "89405": { state: "NV", city: "EMPIRE" },
  "89406": { state: "NV", city: "FALLON" },
  "89407": { state: "NV", city: "FALLON" },
  "89408": { state: "NV", city: "FERNLEY" },
  "89409": { state: "NV", city: "GABBS" },
  "89410": { state: "NV", city: "GARDNERVILLE" },
  "89411": { state: "NV", city: "GENOA" },
  "89412": { state: "NV", city: "GERLACH" },
  "89413": { state: "NV", city: "GLENBROOK" },
  "89414": { state: "NV", city: "GOLCONDA" },
  "89415": { state: "NV", city: "HAWTHORNE" },
  "89418": { state: "NV", city: "IMLAY" },
  "89419": { state: "NV", city: "LOVELOCK" },
  "89420": { state: "NV", city: "LUNING" },
  "89421": { state: "NV", city: "MC DERMITT" },
  "89422": { state: "NV", city: "MINA" },
  "89423": { state: "NV", city: "MINDEN" },
  "89424": { state: "NV", city: "NIXON" },
  "89425": { state: "NV", city: "OROVADA" },
  "89426": { state: "NV", city: "PARADISE VALLEY" },
  "89427": { state: "NV", city: "SCHURZ" },
  "89428": { state: "NV", city: "SILVER CITY" },
  "89429": { state: "NV", city: "SILVER SPRINGS" },
  "89430": { state: "NV", city: "SMITH" },
  "89431": { state: "NV", city: "SPARKS" },
  "89432": { state: "NV", city: "SPARKS" },
  "89433": { state: "NV", city: "SUN VALLEY" },
  "89434": { state: "NV", city: "SPARKS" },
  "89435": { state: "NV", city: "SPARKS" },
  "89436": { state: "NV", city: "SPARKS" },
  "89437": { state: "NV", city: "SPARKS" },
  "89438": { state: "NV", city: "VALMY" },
  "89439": { state: "NV", city: "VERDI" },
  "89440": { state: "NV", city: "VIRGINIA CITY" },
  "89441": { state: "NV", city: "SPARKS" },
  "89442": { state: "NV", city: "WADSWORTH" },
  "89444": { state: "NV", city: "WELLINGTON" },
  "89445": { state: "NV", city: "WINNEMUCCA" },
  "89446": { state: "NV", city: "WINNEMUCCA" },
  "89447": { state: "NV", city: "YERINGTON" },
  "89448": { state: "NV", city: "ZEPHYR COVE" },
  "89449": { state: "NV", city: "STATELINE" },
  "89450": { state: "NV", city: "INCLINE VILLAGE" },
  "89451": { state: "NV", city: "INCLINE VILLAGE" },
  "89452": { state: "NV", city: "INCLINE VILLAGE" },
  "89460": { state: "NV", city: "GARDNERVILLE" },
  "89496": { state: "NV", city: "FALLON" },
  "89501": { state: "NV", city: "RENO" },
  "89502": { state: "NV", city: "RENO" },
  "89503": { state: "NV", city: "RENO" },
  "89504": { state: "NV", city: "RENO" },
  "89505": { state: "NV", city: "RENO" },
  "89506": { state: "NV", city: "RENO" },
  "89507": { state: "NV", city: "RENO" },
  "89508": { state: "NV", city: "RENO" },
  "89509": { state: "NV", city: "RENO" },
  "89510": { state: "NV", city: "RENO" },
  "89511": { state: "NV", city: "RENO" },
  "89512": { state: "NV", city: "RENO" },
  "89513": { state: "NV", city: "RENO" },
  "89515": { state: "NV", city: "RENO" },
  "89519": { state: "NV", city: "RENO" },
  "89520": { state: "NV", city: "RENO" },
  "89521": { state: "NV", city: "RENO" },
  "89523": { state: "NV", city: "RENO" },
  "89533": { state: "NV", city: "RENO" },
  "89555": { state: "NV", city: "RENO" },
  "89557": { state: "NV", city: "RENO" },
  "89570": { state: "NV", city: "RENO" },
  "89595": { state: "NV", city: "RENO" },
  "89599": { state: "NV", city: "RENO" },
  "89701": { state: "NV", city: "CARSON CITY" },
  "89702": { state: "NV", city: "CARSON CITY" },
  "89703": { state: "NV", city: "CARSON CITY" },
  "89704": { state: "NV", city: "WASHOE VALLEY" },
  "89705": { state: "NV", city: "CARSON CITY" },
  "89706": { state: "NV", city: "CARSON CITY" },
  "89711": { state: "NV", city: "CARSON CITY" },
  "89712": { state: "NV", city: "CARSON CITY" },
  "89713": { state: "NV", city: "CARSON CITY" },
  "89714": { state: "NV", city: "CARSON CITY" },
  "89721": { state: "NV", city: "CARSON CITY" },
  "89801": { state: "NV", city: "ELKO" },
  "89802": { state: "NV", city: "ELKO" },
  "89803": { state: "NV", city: "ELKO" },
  "89815": { state: "NV", city: "SPRING CREEK" },
  "89820": { state: "NV", city: "BATTLE MOUNTAIN" },
  "89821": { state: "NV", city: "CRESCENT VALLEY" },
  "89822": { state: "NV", city: "CARLIN" },
  "89823": { state: "NV", city: "DEETH" },
  "89825": { state: "NV", city: "JACKPOT" },
  "89826": { state: "NV", city: "JARBIDGE" },
  "89828": { state: "NV", city: "LAMOILLE" },
  "89830": { state: "NV", city: "MONTELLO" },
  "89831": { state: "NV", city: "MOUNTAIN CITY" },
  "89832": { state: "NV", city: "OWYHEE" },
  "89833": { state: "NV", city: "RUBY VALLEY" },
  "89834": { state: "NV", city: "TUSCARORA" },
  "89835": { state: "NV", city: "WELLS" },
  "89883": { state: "NV", city: "WEST WENDOVER" },
  "90001": { state: "CA", city: "LOS ANGELES" },
  "90002": { state: "CA", city: "LOS ANGELES" },
  "90003": { state: "CA", city: "LOS ANGELES" },
  "90004": { state: "CA", city: "LOS ANGELES" },
  "90005": { state: "CA", city: "LOS ANGELES" },
  "90006": { state: "CA", city: "LOS ANGELES" },
  "90007": { state: "CA", city: "LOS ANGELES" },
  "90008": { state: "CA", city: "LOS ANGELES" },
  "90009": { state: "CA", city: "LOS ANGELES" },
  "90010": { state: "CA", city: "LOS ANGELES" },
  "90011": { state: "CA", city: "LOS ANGELES" },
  "90012": { state: "CA", city: "LOS ANGELES" },
  "90013": { state: "CA", city: "LOS ANGELES" },
  "90014": { state: "CA", city: "LOS ANGELES" },
  "90015": { state: "CA", city: "LOS ANGELES" },
  "90016": { state: "CA", city: "LOS ANGELES" },
  "90017": { state: "CA", city: "LOS ANGELES" },
  "90018": { state: "CA", city: "LOS ANGELES" },
  "90019": { state: "CA", city: "LOS ANGELES" },
  "90020": { state: "CA", city: "LOS ANGELES" },
  "90021": { state: "CA", city: "LOS ANGELES" },
  "90022": { state: "CA", city: "LOS ANGELES" },
  "90023": { state: "CA", city: "LOS ANGELES" },
  "90024": { state: "CA", city: "LOS ANGELES" },
  "90025": { state: "CA", city: "LOS ANGELES" },
  "90026": { state: "CA", city: "LOS ANGELES" },
  "90027": { state: "CA", city: "LOS ANGELES" },
  "90028": { state: "CA", city: "LOS ANGELES" },
  "90029": { state: "CA", city: "LOS ANGELES" },
  "90030": { state: "CA", city: "LOS ANGELES" },
  "90031": { state: "CA", city: "LOS ANGELES" },
  "90032": { state: "CA", city: "LOS ANGELES" },
  "90033": { state: "CA", city: "LOS ANGELES" },
  "90034": { state: "CA", city: "LOS ANGELES" },
  "90035": { state: "CA", city: "LOS ANGELES" },
  "90036": { state: "CA", city: "LOS ANGELES" },
  "90037": { state: "CA", city: "LOS ANGELES" },
  "90038": { state: "CA", city: "LOS ANGELES" },
  "90039": { state: "CA", city: "LOS ANGELES" },
  "90040": { state: "CA", city: "LOS ANGELES" },
  "90041": { state: "CA", city: "LOS ANGELES" },
  "90042": { state: "CA", city: "LOS ANGELES" },
  "90043": { state: "CA", city: "LOS ANGELES" },
  "90044": { state: "CA", city: "LOS ANGELES" },
  "90045": { state: "CA", city: "LOS ANGELES" },
  "90046": { state: "CA", city: "LOS ANGELES" },
  "90047": { state: "CA", city: "LOS ANGELES" },
  "90048": { state: "CA", city: "LOS ANGELES" },
  "90049": { state: "CA", city: "LOS ANGELES" },
  "90050": { state: "CA", city: "LOS ANGELES" },
  "90051": { state: "CA", city: "LOS ANGELES" },
  "90052": { state: "CA", city: "LOS ANGELES" },
  "90053": { state: "CA", city: "LOS ANGELES" },
  "90054": { state: "CA", city: "LOS ANGELES" },
  "90055": { state: "CA", city: "LOS ANGELES" },
  "90056": { state: "CA", city: "LOS ANGELES" },
  "90057": { state: "CA", city: "LOS ANGELES" },
  "90058": { state: "CA", city: "LOS ANGELES" },
  "90059": { state: "CA", city: "LOS ANGELES" },
  "90060": { state: "CA", city: "LOS ANGELES" },
  "90061": { state: "CA", city: "LOS ANGELES" },
  "90062": { state: "CA", city: "LOS ANGELES" },
  "90063": { state: "CA", city: "LOS ANGELES" },
  "90064": { state: "CA", city: "LOS ANGELES" },
  "90065": { state: "CA", city: "LOS ANGELES" },
  "90066": { state: "CA", city: "LOS ANGELES" },
  "90067": { state: "CA", city: "LOS ANGELES" },
  "90068": { state: "CA", city: "LOS ANGELES" },
  "90069": { state: "CA", city: "WEST HOLLYWOOD" },
  "90070": { state: "CA", city: "LOS ANGELES" },
  "90071": { state: "CA", city: "LOS ANGELES" },
  "90072": { state: "CA", city: "LOS ANGELES" },
  "90073": { state: "CA", city: "LOS ANGELES" },
  "90074": { state: "CA", city: "LOS ANGELES" },
  "90075": { state: "CA", city: "LOS ANGELES" },
  "90076": { state: "CA", city: "LOS ANGELES" },
  "90077": { state: "CA", city: "LOS ANGELES" },
  "90078": { state: "CA", city: "LOS ANGELES" },
  "90079": { state: "CA", city: "LOS ANGELES" },
  "90080": { state: "CA", city: "LOS ANGELES" },
  "90081": { state: "CA", city: "LOS ANGELES" },
  "90082": { state: "CA", city: "LOS ANGELES" },
  "90083": { state: "CA", city: "LOS ANGELES" },
  "90084": { state: "CA", city: "LOS ANGELES" },
  "90086": { state: "CA", city: "LOS ANGELES" },
  "90087": { state: "CA", city: "LOS ANGELES" },
  "90088": { state: "CA", city: "LOS ANGELES" },
  "90089": { state: "CA", city: "LOS ANGELES" },
  "90091": { state: "CA", city: "LOS ANGELES" },
  "90093": { state: "CA", city: "LOS ANGELES" },
  "90094": { state: "CA", city: "PLAYA VISTA" },
  "90095": { state: "CA", city: "LOS ANGELES" },
  "90096": { state: "CA", city: "LOS ANGELES" },
  "90099": { state: "CA", city: "LOS ANGELES" },
  "90134": { state: "CA", city: "LOS ANGELES" },
  "90189": { state: "CA", city: "LOS ANGELES" },
  "90201": { state: "CA", city: "BELL GARDENS" },
  "90202": { state: "CA", city: "BELL" },
  "90209": { state: "CA", city: "BEVERLY HILLS" },
  "90210": { state: "CA", city: "BEVERLY HILLS" },
  "90211": { state: "CA", city: "BEVERLY HILLS" },
  "90212": { state: "CA", city: "BEVERLY HILLS" },
  "90213": { state: "CA", city: "BEVERLY HILLS" },
  "90220": { state: "CA", city: "COMPTON" },
  "90221": { state: "CA", city: "COMPTON" },
  "90222": { state: "CA", city: "COMPTON" },
  "90223": { state: "CA", city: "COMPTON" },
  "90224": { state: "CA", city: "COMPTON" },
  "90230": { state: "CA", city: "CULVER CITY" },
  "90231": { state: "CA", city: "CULVER CITY" },
  "90232": { state: "CA", city: "CULVER CITY" },
  "90239": { state: "CA", city: "DOWNEY" },
  "90240": { state: "CA", city: "DOWNEY" },
  "90241": { state: "CA", city: "DOWNEY" },
  "90242": { state: "CA", city: "DOWNEY" },
  "90245": { state: "CA", city: "EL SEGUNDO" },
  "90247": { state: "CA", city: "GARDENA" },
  "90248": { state: "CA", city: "GARDENA" },
  "90249": { state: "CA", city: "GARDENA" },
  "90250": { state: "CA", city: "HAWTHORNE" },
  "90251": { state: "CA", city: "HAWTHORNE" },
  "90254": { state: "CA", city: "HERMOSA BEACH" },
  "90255": { state: "CA", city: "HUNTINGTON PARK" },
  "90260": { state: "CA", city: "LAWNDALE" },
  "90261": { state: "CA", city: "LAWNDALE" },
  "90262": { state: "CA", city: "LYNWOOD" },
  "90263": { state: "CA", city: "MALIBU" },
  "90264": { state: "CA", city: "MALIBU" },
  "90265": { state: "CA", city: "MALIBU" },
  "90266": { state: "CA", city: "MANHATTAN BEACH" },
  "90267": { state: "CA", city: "MANHATTAN BEACH" },
  "90270": { state: "CA", city: "MAYWOOD" },
  "90272": { state: "CA", city: "PACIFIC PALISADES" },
  "90274": { state: "CA", city: "PALOS VERDES PENINSULA" },
  "90275": { state: "CA", city: "RANCHO PALOS VERDES" },
  "90277": { state: "CA", city: "REDONDO BEACH" },
  "90278": { state: "CA", city: "REDONDO BEACH" },
  "90280": { state: "CA", city: "SOUTH GATE" },
  "90290": { state: "CA", city: "TOPANGA" },
  "90291": { state: "CA", city: "VENICE" },
  "90292": { state: "CA", city: "MARINA DEL REY" },
  "90293": { state: "CA", city: "PLAYA DEL REY" },
  "90294": { state: "CA", city: "VENICE" },
  "90295": { state: "CA", city: "MARINA DEL REY" },
  "90296": { state: "CA", city: "PLAYA DEL REY" },
  "90301": { state: "CA", city: "INGLEWOOD" },
  "90302": { state: "CA", city: "INGLEWOOD" },
  "90303": { state: "CA", city: "INGLEWOOD" },
  "90304": { state: "CA", city: "INGLEWOOD" },
  "90305": { state: "CA", city: "INGLEWOOD" },
  "90306": { state: "CA", city: "INGLEWOOD" },
  "90307": { state: "CA", city: "INGLEWOOD" },
  "90308": { state: "CA", city: "INGLEWOOD" },
  "90309": { state: "CA", city: "INGLEWOOD" },
  "90310": { state: "CA", city: "INGLEWOOD" },
  "90311": { state: "CA", city: "INGLEWOOD" },
  "90312": { state: "CA", city: "INGLEWOOD" },
  "90401": { state: "CA", city: "SANTA MONICA" },
  "90402": { state: "CA", city: "SANTA MONICA" },
  "90403": { state: "CA", city: "SANTA MONICA" },
  "90404": { state: "CA", city: "SANTA MONICA" },
  "90405": { state: "CA", city: "SANTA MONICA" },
  "90406": { state: "CA", city: "SANTA MONICA" },
  "90407": { state: "CA", city: "SANTA MONICA" },
  "90408": { state: "CA", city: "SANTA MONICA" },
  "90409": { state: "CA", city: "SANTA MONICA" },
  "90410": { state: "CA", city: "SANTA MONICA" },
  "90411": { state: "CA", city: "SANTA MONICA" },
  "90501": { state: "CA", city: "TORRANCE" },
  "90502": { state: "CA", city: "TORRANCE" },
  "90503": { state: "CA", city: "TORRANCE" },
  "90504": { state: "CA", city: "TORRANCE" },
  "90505": { state: "CA", city: "TORRANCE" },
  "90506": { state: "CA", city: "TORRANCE" },
  "90507": { state: "CA", city: "TORRANCE" },
  "90508": { state: "CA", city: "TORRANCE" },
  "90509": { state: "CA", city: "TORRANCE" },
  "90510": { state: "CA", city: "TORRANCE" },
  "90601": { state: "CA", city: "WHITTIER" },
  "90602": { state: "CA", city: "WHITTIER" },
  "90603": { state: "CA", city: "WHITTIER" },
  "90604": { state: "CA", city: "WHITTIER" },
  "90605": { state: "CA", city: "WHITTIER" },
  "90606": { state: "CA", city: "WHITTIER" },
  "90607": { state: "CA", city: "WHITTIER" },
  "90608": { state: "CA", city: "WHITTIER" },
  "90609": { state: "CA", city: "WHITTIER" },
  "90610": { state: "CA", city: "WHITTIER" },
  "90620": { state: "CA", city: "BUENA PARK" },
  "90621": { state: "CA", city: "BUENA PARK" },
  "90622": { state: "CA", city: "BUENA PARK" },
  "90623": { state: "CA", city: "LA PALMA" },
  "90624": { state: "CA", city: "BUENA PARK" },
  "90630": { state: "CA", city: "CYPRESS" },
  "90631": { state: "CA", city: "LA HABRA" },
  "90632": { state: "CA", city: "LA HABRA" },
  "90633": { state: "CA", city: "LA HABRA" },
  "90637": { state: "CA", city: "LA MIRADA" },
  "90638": { state: "CA", city: "LA MIRADA" },
  "90639": { state: "CA", city: "LA MIRADA" },
  "90640": { state: "CA", city: "MONTEBELLO" },
  "90650": { state: "CA", city: "NORWALK" },
  "90651": { state: "CA", city: "NORWALK" },
  "90652": { state: "CA", city: "NORWALK" },
  "90660": { state: "CA", city: "PICO RIVERA" },
  "90661": { state: "CA", city: "PICO RIVERA" },
  "90662": { state: "CA", city: "PICO RIVERA" },
  "90670": { state: "CA", city: "SANTA FE SPRINGS" },
  "90671": { state: "CA", city: "SANTA FE SPRINGS" },
  "90680": { state: "CA", city: "STANTON" },
  "90701": { state: "CA", city: "ARTESIA" },
  "90702": { state: "CA", city: "ARTESIA" },
  "90703": { state: "CA", city: "CERRITOS" },
  "90704": { state: "CA", city: "AVALON" },
  "90706": { state: "CA", city: "BELLFLOWER" },
  "90707": { state: "CA", city: "BELLFLOWER" },
  "90710": { state: "CA", city: "HARBOR CITY" },
  "90711": { state: "CA", city: "LAKEWOOD" },
  "90712": { state: "CA", city: "LAKEWOOD" },
  "90713": { state: "CA", city: "LAKEWOOD" },
  "90714": { state: "CA", city: "LAKEWOOD" },
  "90715": { state: "CA", city: "LAKEWOOD" },
  "90716": { state: "CA", city: "HAWAIIAN GARDENS" },
  "90717": { state: "CA", city: "LOMITA" },
  "90720": { state: "CA", city: "LOS ALAMITOS" },
  "90721": { state: "CA", city: "LOS ALAMITOS" },
  "90723": { state: "CA", city: "PARAMOUNT" },
  "90731": { state: "CA", city: "SAN PEDRO" },
  "90732": { state: "CA", city: "SAN PEDRO" },
  "90733": { state: "CA", city: "SAN PEDRO" },
  "90734": { state: "CA", city: "SAN PEDRO" },
  "90740": { state: "CA", city: "SEAL BEACH" },
  "90742": { state: "CA", city: "SUNSET BEACH" },
  "90743": { state: "CA", city: "SURFSIDE" },
  "90744": { state: "CA", city: "WILMINGTON" },
  "90745": { state: "CA", city: "CARSON" },
  "90746": { state: "CA", city: "CARSON" },
  "90747": { state: "CA", city: "CARSON" },
  "90748": { state: "CA", city: "WILMINGTON" },
  "90749": { state: "CA", city: "CARSON" },
  "90755": { state: "CA", city: "SIGNAL HILL" },
  "90801": { state: "CA", city: "LONG BEACH" },
  "90802": { state: "CA", city: "LONG BEACH" },
  "90803": { state: "CA", city: "LONG BEACH" },
  "90804": { state: "CA", city: "LONG BEACH" },
  "90805": { state: "CA", city: "LONG BEACH" },
  "90806": { state: "CA", city: "LONG BEACH" },
  "90807": { state: "CA", city: "LONG BEACH" },
  "90808": { state: "CA", city: "LONG BEACH" },
  "90809": { state: "CA", city: "LONG BEACH" },
  "90810": { state: "CA", city: "LONG BEACH" },
  "90813": { state: "CA", city: "LONG BEACH" },
  "90814": { state: "CA", city: "LONG BEACH" },
  "90815": { state: "CA", city: "LONG BEACH" },
  "90822": { state: "CA", city: "LONG BEACH" },
  "90831": { state: "CA", city: "LONG BEACH" },
  "90832": { state: "CA", city: "LONG BEACH" },
  "90833": { state: "CA", city: "LONG BEACH" },
  "90840": { state: "CA", city: "LONG BEACH" },
  "90842": { state: "CA", city: "LONG BEACH" },
  "90844": { state: "CA", city: "LONG BEACH" },
  "90846": { state: "CA", city: "LONG BEACH" },
  "90847": { state: "CA", city: "LONG BEACH" },
  "90848": { state: "CA", city: "LONG BEACH" },
  "90853": { state: "CA", city: "LONG BEACH" },
  "90895": { state: "CA", city: "CARSON" },
  "91001": { state: "CA", city: "ALTADENA" },
  "91003": { state: "CA", city: "ALTADENA" },
  "91006": { state: "CA", city: "ARCADIA" },
  "91007": { state: "CA", city: "ARCADIA" },
  "91008": { state: "CA", city: "DUARTE" },
  "91009": { state: "CA", city: "DUARTE" },
  "91010": { state: "CA", city: "DUARTE" },
  "91011": { state: "CA", city: "LA CANADA FLINTRIDGE" },
  "91012": { state: "CA", city: "LA CANADA FLINTRIDGE" },
  "91016": { state: "CA", city: "MONROVIA" },
  "91017": { state: "CA", city: "MONROVIA" },
  "91020": { state: "CA", city: "MONTROSE" },
  "91021": { state: "CA", city: "MONTROSE" },
  "91023": { state: "CA", city: "MOUNT WILSON" },
  "91024": { state: "CA", city: "SIERRA MADRE" },
  "91025": { state: "CA", city: "SIERRA MADRE" },
  "91030": { state: "CA", city: "SOUTH PASADENA" },
  "91031": { state: "CA", city: "SOUTH PASADENA" },
  "91040": { state: "CA", city: "SUNLAND" },
  "91041": { state: "CA", city: "SUNLAND" },
  "91042": { state: "CA", city: "TUJUNGA" },
  "91043": { state: "CA", city: "TUJUNGA" },
  "91046": { state: "CA", city: "VERDUGO CITY" },
  "91066": { state: "CA", city: "ARCADIA" },
  "91077": { state: "CA", city: "ARCADIA" },
  "91101": { state: "CA", city: "PASADENA" },
  "91102": { state: "CA", city: "PASADENA" },
  "91103": { state: "CA", city: "PASADENA" },
  "91104": { state: "CA", city: "PASADENA" },
  "91105": { state: "CA", city: "PASADENA" },
  "91106": { state: "CA", city: "PASADENA" },
  "91107": { state: "CA", city: "PASADENA" },
  "91108": { state: "CA", city: "SAN MARINO" },
  "91109": { state: "CA", city: "PASADENA" },
  "91110": { state: "CA", city: "PASADENA" },
  "91114": { state: "CA", city: "PASADENA" },
  "91115": { state: "CA", city: "PASADENA" },
  "91116": { state: "CA", city: "PASADENA" },
  "91117": { state: "CA", city: "PASADENA" },
  "91118": { state: "CA", city: "SAN MARINO" },
  "91121": { state: "CA", city: "PASADENA" },
  "91123": { state: "CA", city: "PASADENA" },
  "91124": { state: "CA", city: "PASADENA" },
  "91125": { state: "CA", city: "PASADENA" },
  "91126": { state: "CA", city: "PASADENA" },
  "91129": { state: "CA", city: "PASADENA" },
  "91182": { state: "CA", city: "PASADENA" },
  "91184": { state: "CA", city: "PASADENA" },
  "91185": { state: "CA", city: "PASADENA" },
  "91188": { state: "CA", city: "PASADENA" },
  "91189": { state: "CA", city: "PASADENA" },
  "91199": { state: "CA", city: "PASADENA" },
  "91201": { state: "CA", city: "GLENDALE" },
  "91202": { state: "CA", city: "GLENDALE" },
  "91203": { state: "CA", city: "GLENDALE" },
  "91204": { state: "CA", city: "GLENDALE" },
  "91205": { state: "CA", city: "GLENDALE" },
  "91206": { state: "CA", city: "GLENDALE" },
  "91207": { state: "CA", city: "GLENDALE" },
  "91208": { state: "CA", city: "GLENDALE" },
  "91209": { state: "CA", city: "GLENDALE" },
  "91210": { state: "CA", city: "GLENDALE" },
  "91214": { state: "CA", city: "LA CRESCENTA" },
  "91221": { state: "CA", city: "GLENDALE" },
  "91222": { state: "CA", city: "GLENDALE" },
  "91224": { state: "CA", city: "LA CRESCENTA" },
  "91225": { state: "CA", city: "GLENDALE" },
  "91226": { state: "CA", city: "GLENDALE" },
  "91301": { state: "CA", city: "AGOURA HILLS" },
  "91302": { state: "CA", city: "CALABASAS" },
  "91303": { state: "CA", city: "CANOGA PARK" },
  "91304": { state: "CA", city: "CANOGA PARK" },
  "91305": { state: "CA", city: "CANOGA PARK" },
  "91306": { state: "CA", city: "WINNETKA" },
  "91307": { state: "CA", city: "WEST HILLS" },
  "91308": { state: "CA", city: "WEST HILLS" },
  "91309": { state: "CA", city: "CANOGA PARK" },
  "91310": { state: "CA", city: "CASTAIC" },
  "91311": { state: "CA", city: "CHATSWORTH" },
  "91313": { state: "CA", city: "CHATSWORTH" },
  "91316": { state: "CA", city: "ENCINO" },
  "91319": { state: "CA", city: "NEWBURY PARK" },
  "91320": { state: "CA", city: "NEWBURY PARK" },
  "91321": { state: "CA", city: "NEWHALL" },
  "91322": { state: "CA", city: "NEWHALL" },
  "91324": { state: "CA", city: "NORTHRIDGE" },
  "91325": { state: "CA", city: "NORTHRIDGE" },
  "91326": { state: "CA", city: "PORTER RANCH" },
  "91327": { state: "CA", city: "NORTHRIDGE" },
  "91328": { state: "CA", city: "NORTHRIDGE" },
  "91329": { state: "CA", city: "NORTHRIDGE" },
  "91330": { state: "CA", city: "NORTHRIDGE" },
  "91331": { state: "CA", city: "PACOIMA" },
  "91333": { state: "CA", city: "PACOIMA" },
  "91334": { state: "CA", city: "PACOIMA" },
  "91335": { state: "CA", city: "RESEDA" },
  "91337": { state: "CA", city: "RESEDA" },
  "91340": { state: "CA", city: "SAN FERNANDO" },
  "91341": { state: "CA", city: "SAN FERNANDO" },
  "91342": { state: "CA", city: "SYLMAR" },
  "91343": { state: "CA", city: "NORTH HILLS" },
  "91344": { state: "CA", city: "GRANADA HILLS" },
  "91345": { state: "CA", city: "MISSION HILLS" },
  "91346": { state: "CA", city: "MISSION HILLS" },
  "91350": { state: "CA", city: "SANTA CLARITA" },
  "91351": { state: "CA", city: "CANYON COUNTRY" },
  "91352": { state: "CA", city: "SUN VALLEY" },
  "91353": { state: "CA", city: "SUN VALLEY" },
  "91354": { state: "CA", city: "VALENCIA" },
  "91355": { state: "CA", city: "VALENCIA" },
  "91356": { state: "CA", city: "TARZANA" },
  "91357": { state: "CA", city: "TARZANA" },
  "91358": { state: "CA", city: "THOUSAND OAKS" },
  "91359": { state: "CA", city: "WESTLAKE VILLAGE" },
  "91360": { state: "CA", city: "THOUSAND OAKS" },
  "91361": { state: "CA", city: "WESTLAKE VILLAGE" },
  "91362": { state: "CA", city: "THOUSAND OAKS" },
  "91364": { state: "CA", city: "WOODLAND HILLS" },
  "91365": { state: "CA", city: "WOODLAND HILLS" },
  "91367": { state: "CA", city: "WOODLAND HILLS" },
  "91371": { state: "CA", city: "WOODLAND HILLS" },
  "91372": { state: "CA", city: "CALABASAS" },
  "91376": { state: "CA", city: "AGOURA HILLS" },
  "91377": { state: "CA", city: "OAK PARK" },
  "91380": { state: "CA", city: "SANTA CLARITA" },
  "91381": { state: "CA", city: "STEVENSON RANCH" },
  "91382": { state: "CA", city: "SANTA CLARITA" },
  "91383": { state: "CA", city: "SANTA CLARITA" },
  "91384": { state: "CA", city: "CASTAIC" },
  "91385": { state: "CA", city: "VALENCIA" },
  "91386": { state: "CA", city: "CANYON COUNTRY" },
  "91387": { state: "CA", city: "CANYON COUNTRY" },
  "91390": { state: "CA", city: "SANTA CLARITA" },
  "91392": { state: "CA", city: "SYLMAR" },
  "91393": { state: "CA", city: "NORTH HILLS" },
  "91394": { state: "CA", city: "GRANADA HILLS" },
  "91395": { state: "CA", city: "MISSION HILLS" },
  "91396": { state: "CA", city: "WINNETKA" },
  "91401": { state: "CA", city: "VAN NUYS" },
  "91402": { state: "CA", city: "PANORAMA CITY" },
  "91403": { state: "CA", city: "SHERMAN OAKS" },
  "91404": { state: "CA", city: "VAN NUYS" },
  "91405": { state: "CA", city: "VAN NUYS" },
  "91406": { state: "CA", city: "VAN NUYS" },
  "91407": { state: "CA", city: "VAN NUYS" },
  "91408": { state: "CA", city: "VAN NUYS" },
  "91409": { state: "CA", city: "VAN NUYS" },
  "91410": { state: "CA", city: "VAN NUYS" },
  "91411": { state: "CA", city: "VAN NUYS" },
  "91412": { state: "CA", city: "PANORAMA CITY" },
  "91413": { state: "CA", city: "SHERMAN OAKS" },
  "91416": { state: "CA", city: "ENCINO" },
  "91423": { state: "CA", city: "SHERMAN OAKS" },
  "91426": { state: "CA", city: "ENCINO" },
  "91436": { state: "CA", city: "ENCINO" },
  "91470": { state: "CA", city: "VAN NUYS" },
  "91482": { state: "CA", city: "VAN NUYS" },
  "91495": { state: "CA", city: "SHERMAN OAKS" },
  "91496": { state: "CA", city: "VAN NUYS" },
  "91499": { state: "CA", city: "VAN NUYS" },
  "91501": { state: "CA", city: "BURBANK" },
  "91502": { state: "CA", city: "BURBANK" },
  "91503": { state: "CA", city: "BURBANK" },
  "91504": { state: "CA", city: "BURBANK" },
  "91505": { state: "CA", city: "BURBANK" },
  "91506": { state: "CA", city: "BURBANK" },
  "91507": { state: "CA", city: "BURBANK" },
  "91508": { state: "CA", city: "BURBANK" },
  "91510": { state: "CA", city: "BURBANK" },
  "91521": { state: "CA", city: "BURBANK" },
  "91522": { state: "CA", city: "BURBANK" },
  "91523": { state: "CA", city: "BURBANK" },
  "91526": { state: "CA", city: "BURBANK" },
  "91601": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91602": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91603": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91604": { state: "CA", city: "STUDIO CITY" },
  "91605": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91606": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91607": { state: "CA", city: "VALLEY VILLAGE" },
  "91608": { state: "CA", city: "UNIVERSAL CITY" },
  "91609": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91610": { state: "CA", city: "TOLUCA LAKE" },
  "91611": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91612": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91614": { state: "CA", city: "STUDIO CITY" },
  "91615": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91616": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91617": { state: "CA", city: "VALLEY VILLAGE" },
  "91618": { state: "CA", city: "NORTH HOLLYWOOD" },
  "91701": { state: "CA", city: "RANCHO CUCAMONGA" },
  "91702": { state: "CA", city: "AZUSA" },
  "91706": { state: "CA", city: "BALDWIN PARK" },
  "91708": { state: "CA", city: "CHINO" },
  "91709": { state: "CA", city: "CHINO HILLS" },
  "91710": { state: "CA", city: "CHINO" },
  "91711": { state: "CA", city: "CLAREMONT" },
  "91714": { state: "CA", city: "CITY OF INDUSTRY" },
  "91715": { state: "CA", city: "CITY OF INDUSTRY" },
  "91716": { state: "CA", city: "CITY OF INDUSTRY" },
  "91722": { state: "CA", city: "COVINA" },
  "91723": { state: "CA", city: "COVINA" },
  "91724": { state: "CA", city: "COVINA" },
  "91729": { state: "CA", city: "RANCHO CUCAMONGA" },
  "91730": { state: "CA", city: "RANCHO CUCAMONGA" },
  "91731": { state: "CA", city: "EL MONTE" },
  "91732": { state: "CA", city: "EL MONTE" },
  "91733": { state: "CA", city: "SOUTH EL MONTE" },
  "91734": { state: "CA", city: "EL MONTE" },
  "91735": { state: "CA", city: "EL MONTE" },
  "91737": { state: "CA", city: "RANCHO CUCAMONGA" },
  "91739": { state: "CA", city: "RANCHO CUCAMONGA" },
  "91740": { state: "CA", city: "GLENDORA" },
  "91741": { state: "CA", city: "GLENDORA" },
  "91743": { state: "CA", city: "GUASTI" },
  "91744": { state: "CA", city: "LA PUENTE" },
  "91745": { state: "CA", city: "HACIENDA HEIGHTS" },
  "91746": { state: "CA", city: "LA PUENTE" },
  "91747": { state: "CA", city: "LA PUENTE" },
  "91748": { state: "CA", city: "ROWLAND HEIGHTS" },
  "91749": { state: "CA", city: "LA PUENTE" },
  "91750": { state: "CA", city: "LA VERNE" },
  "91752": { state: "CA", city: "MIRA LOMA" },
  "91754": { state: "CA", city: "MONTEREY PARK" },
  "91755": { state: "CA", city: "MONTEREY PARK" },
  "91756": { state: "CA", city: "MONTEREY PARK" },
  "91758": { state: "CA", city: "ONTARIO" },
  "91759": { state: "CA", city: "MT BALDY" },
  "91761": { state: "CA", city: "ONTARIO" },
  "91762": { state: "CA", city: "ONTARIO" },
  "91763": { state: "CA", city: "MONTCLAIR" },
  "91764": { state: "CA", city: "ONTARIO" },
  "91765": { state: "CA", city: "DIAMOND BAR" },
  "91766": { state: "CA", city: "POMONA" },
  "91767": { state: "CA", city: "POMONA" },
  "91768": { state: "CA", city: "POMONA" },
  "91769": { state: "CA", city: "POMONA" },
  "91770": { state: "CA", city: "ROSEMEAD" },
  "91771": { state: "CA", city: "ROSEMEAD" },
  "91772": { state: "CA", city: "ROSEMEAD" },
  "91773": { state: "CA", city: "SAN DIMAS" },
  "91775": { state: "CA", city: "SAN GABRIEL" },
  "91776": { state: "CA", city: "SAN GABRIEL" },
  "91778": { state: "CA", city: "SAN GABRIEL" },
  "91780": { state: "CA", city: "TEMPLE CITY" },
  "91784": { state: "CA", city: "UPLAND" },
  "91785": { state: "CA", city: "UPLAND" },
  "91786": { state: "CA", city: "UPLAND" },
  "91788": { state: "CA", city: "WALNUT" },
  "91789": { state: "CA", city: "WALNUT" },
  "91790": { state: "CA", city: "WEST COVINA" },
  "91791": { state: "CA", city: "WEST COVINA" },
  "91792": { state: "CA", city: "WEST COVINA" },
  "91793": { state: "CA", city: "WEST COVINA" },
  "91801": { state: "CA", city: "ALHAMBRA" },
  "91802": { state: "CA", city: "ALHAMBRA" },
  "91803": { state: "CA", city: "ALHAMBRA" },
  "91804": { state: "CA", city: "ALHAMBRA" },
  "91896": { state: "CA", city: "ALHAMBRA" },
  "91899": { state: "CA", city: "ALHAMBRA" },
  "91901": { state: "CA", city: "ALPINE" },
  "91902": { state: "CA", city: "BONITA" },
  "91903": { state: "CA", city: "ALPINE" },
  "91905": { state: "CA", city: "BOULEVARD" },
  "91906": { state: "CA", city: "CAMPO" },
  "91908": { state: "CA", city: "BONITA" },
  "91909": { state: "CA", city: "CHULA VISTA" },
  "91910": { state: "CA", city: "CHULA VISTA" },
  "91911": { state: "CA", city: "CHULA VISTA" },
  "91912": { state: "CA", city: "CHULA VISTA" },
  "91913": { state: "CA", city: "CHULA VISTA" },
  "91914": { state: "CA", city: "CHULA VISTA" },
  "91915": { state: "CA", city: "CHULA VISTA" },
  "91916": { state: "CA", city: "DESCANSO" },
  "91917": { state: "CA", city: "DULZURA" },
  "91921": { state: "CA", city: "CHULA VISTA" },
  "91931": { state: "CA", city: "GUATAY" },
  "91932": { state: "CA", city: "IMPERIAL BEACH" },
  "91933": { state: "CA", city: "IMPERIAL BEACH" },
  "91934": { state: "CA", city: "JACUMBA" },
  "91935": { state: "CA", city: "JAMUL" },
  "91941": { state: "CA", city: "LA MESA" },
  "91942": { state: "CA", city: "LA MESA" },
  "91943": { state: "CA", city: "LA MESA" },
  "91944": { state: "CA", city: "LA MESA" },
  "91945": { state: "CA", city: "LEMON GROVE" },
  "91946": { state: "CA", city: "LEMON GROVE" },
  "91948": { state: "CA", city: "MOUNT LAGUNA" },
  "91950": { state: "CA", city: "NATIONAL CITY" },
  "91951": { state: "CA", city: "NATIONAL CITY" },
  "91962": { state: "CA", city: "PINE VALLEY" },
  "91963": { state: "CA", city: "POTRERO" },
  "91976": { state: "CA", city: "SPRING VALLEY" },
  "91977": { state: "CA", city: "SPRING VALLEY" },
  "91978": { state: "CA", city: "SPRING VALLEY" },
  "91979": { state: "CA", city: "SPRING VALLEY" },
  "91980": { state: "CA", city: "TECATE" },
  "91987": { state: "CA", city: "TECATE" },
  "92003": { state: "CA", city: "BONSALL" },
  "92004": { state: "CA", city: "BORREGO SPRINGS" },
  "92007": { state: "CA", city: "CARDIFF BY THE SEA" },
  "92008": { state: "CA", city: "CARLSBAD" },
  "92009": { state: "CA", city: "CARLSBAD" },
  "92010": { state: "CA", city: "CARLSBAD" },
  "92011": { state: "CA", city: "CARLSBAD" },
  "92013": { state: "CA", city: "CARLSBAD" },
  "92014": { state: "CA", city: "DEL MAR" },
  "92018": { state: "CA", city: "CARLSBAD" },
  "92019": { state: "CA", city: "EL CAJON" },
  "92020": { state: "CA", city: "EL CAJON" },
  "92021": { state: "CA", city: "EL CAJON" },
  "92022": { state: "CA", city: "EL CAJON" },
  "92023": { state: "CA", city: "ENCINITAS" },
  "92024": { state: "CA", city: "ENCINITAS" },
  "92025": { state: "CA", city: "ESCONDIDO" },
  "92026": { state: "CA", city: "ESCONDIDO" },
  "92027": { state: "CA", city: "ESCONDIDO" },
  "92028": { state: "CA", city: "FALLBROOK" },
  "92029": { state: "CA", city: "ESCONDIDO" },
  "92030": { state: "CA", city: "ESCONDIDO" },
  "92033": { state: "CA", city: "ESCONDIDO" },
  "92036": { state: "CA", city: "JULIAN" },
  "92037": { state: "CA", city: "LA JOLLA" },
  "92038": { state: "CA", city: "LA JOLLA" },
  "92039": { state: "CA", city: "LA JOLLA" },
  "92040": { state: "CA", city: "LAKESIDE" },
  "92046": { state: "CA", city: "ESCONDIDO" },
  "92049": { state: "CA", city: "OCEANSIDE" },
  "92051": { state: "CA", city: "OCEANSIDE" },
  "92052": { state: "CA", city: "OCEANSIDE" },
  "92054": { state: "CA", city: "OCEANSIDE" },
  "92055": { state: "CA", city: "CAMP PENDLETON" },
  "92056": { state: "CA", city: "OCEANSIDE" },
  "92057": { state: "CA", city: "OCEANSIDE" },
  "92058": { state: "CA", city: "OCEANSIDE" },
  "92059": { state: "CA", city: "PALA" },
  "92060": { state: "CA", city: "PALOMAR MOUNTAIN" },
  "92061": { state: "CA", city: "PAUMA VALLEY" },
  "92064": { state: "CA", city: "POWAY" },
  "92065": { state: "CA", city: "RAMONA" },
  "92066": { state: "CA", city: "RANCHITA" },
  "92067": { state: "CA", city: "RANCHO SANTA FE" },
  "92068": { state: "CA", city: "SAN LUIS REY" },
  "92069": { state: "CA", city: "SAN MARCOS" },
  "92070": { state: "CA", city: "SANTA YSABEL" },
  "92071": { state: "CA", city: "SANTEE" },
  "92072": { state: "CA", city: "SANTEE" },
  "92074": { state: "CA", city: "POWAY" },
  "92075": { state: "CA", city: "SOLANA BEACH" },
  "92078": { state: "CA", city: "SAN MARCOS" },
  "92079": { state: "CA", city: "SAN MARCOS" },
  "92081": { state: "CA", city: "VISTA" },
  "92082": { state: "CA", city: "VALLEY CENTER" },
  "92083": { state: "CA", city: "VISTA" },
  "92084": { state: "CA", city: "VISTA" },
  "92085": { state: "CA", city: "VISTA" },
  "92086": { state: "CA", city: "WARNER SPRINGS" },
  "92088": { state: "CA", city: "FALLBROOK" },
  "92091": { state: "CA", city: "RANCHO SANTA FE" },
  "92092": { state: "CA", city: "LA JOLLA" },
  "92093": { state: "CA", city: "LA JOLLA" },
  "92096": { state: "CA", city: "SAN MARCOS" },
  "92101": { state: "CA", city: "SAN DIEGO" },
  "92102": { state: "CA", city: "SAN DIEGO" },
  "92103": { state: "CA", city: "SAN DIEGO" },
  "92104": { state: "CA", city: "SAN DIEGO" },
  "92105": { state: "CA", city: "SAN DIEGO" },
  "92106": { state: "CA", city: "SAN DIEGO" },
  "92107": { state: "CA", city: "SAN DIEGO" },
  "92108": { state: "CA", city: "SAN DIEGO" },
  "92109": { state: "CA", city: "SAN DIEGO" },
  "92110": { state: "CA", city: "SAN DIEGO" },
  "92111": { state: "CA", city: "SAN DIEGO" },
  "92112": { state: "CA", city: "SAN DIEGO" },
  "92113": { state: "CA", city: "SAN DIEGO" },
  "92114": { state: "CA", city: "SAN DIEGO" },
  "92115": { state: "CA", city: "SAN DIEGO" },
  "92116": { state: "CA", city: "SAN DIEGO" },
  "92117": { state: "CA", city: "SAN DIEGO" },
  "92118": { state: "CA", city: "CORONADO" },
  "92119": { state: "CA", city: "SAN DIEGO" },
  "92120": { state: "CA", city: "SAN DIEGO" },
  "92121": { state: "CA", city: "SAN DIEGO" },
  "92122": { state: "CA", city: "SAN DIEGO" },
  "92123": { state: "CA", city: "SAN DIEGO" },
  "92124": { state: "CA", city: "SAN DIEGO" },
  "92126": { state: "CA", city: "SAN DIEGO" },
  "92127": { state: "CA", city: "SAN DIEGO" },
  "92128": { state: "CA", city: "SAN DIEGO" },
  "92129": { state: "CA", city: "SAN DIEGO" },
  "92130": { state: "CA", city: "SAN DIEGO" },
  "92131": { state: "CA", city: "SAN DIEGO" },
  "92132": { state: "CA", city: "SAN DIEGO" },
  "92134": { state: "CA", city: "SAN DIEGO" },
  "92135": { state: "CA", city: "SAN DIEGO" },
  "92136": { state: "CA", city: "SAN DIEGO" },
  "92137": { state: "CA", city: "SAN DIEGO" },
  "92138": { state: "CA", city: "SAN DIEGO" },
  "92139": { state: "CA", city: "SAN DIEGO" },
  "92140": { state: "CA", city: "SAN DIEGO" },
  "92142": { state: "CA", city: "SAN DIEGO" },
  "92143": { state: "CA", city: "SAN YSIDRO" },
  "92145": { state: "CA", city: "SAN DIEGO" },
  "92147": { state: "CA", city: "SAN DIEGO" },
  "92149": { state: "CA", city: "SAN DIEGO" },
  "92150": { state: "CA", city: "SAN DIEGO" },
  "92152": { state: "CA", city: "SAN DIEGO" },
  "92153": { state: "CA", city: "SAN DIEGO" },
  "92154": { state: "CA", city: "SAN DIEGO" },
  "92155": { state: "CA", city: "SAN DIEGO" },
  "92158": { state: "CA", city: "SAN DIEGO" },
  "92159": { state: "CA", city: "SAN DIEGO" },
  "92160": { state: "CA", city: "SAN DIEGO" },
  "92161": { state: "CA", city: "SAN DIEGO" },
  "92163": { state: "CA", city: "SAN DIEGO" },
  "92165": { state: "CA", city: "SAN DIEGO" },
  "92166": { state: "CA", city: "SAN DIEGO" },
  "92167": { state: "CA", city: "SAN DIEGO" },
  "92168": { state: "CA", city: "SAN DIEGO" },
  "92169": { state: "CA", city: "SAN DIEGO" },
  "92170": { state: "CA", city: "SAN DIEGO" },
  "92171": { state: "CA", city: "SAN DIEGO" },
  "92172": { state: "CA", city: "SAN DIEGO" },
  "92173": { state: "CA", city: "SAN YSIDRO" },
  "92174": { state: "CA", city: "SAN DIEGO" },
  "92175": { state: "CA", city: "SAN DIEGO" },
  "92176": { state: "CA", city: "SAN DIEGO" },
  "92177": { state: "CA", city: "SAN DIEGO" },
  "92178": { state: "CA", city: "CORONADO" },
  "92179": { state: "CA", city: "SAN DIEGO" },
  "92182": { state: "CA", city: "SAN DIEGO" },
  "92186": { state: "CA", city: "SAN DIEGO" },
  "92187": { state: "CA", city: "SAN DIEGO" },
  "92191": { state: "CA", city: "SAN DIEGO" },
  "92192": { state: "CA", city: "SAN DIEGO" },
  "92193": { state: "CA", city: "SAN DIEGO" },
  "92195": { state: "CA", city: "SAN DIEGO" },
  "92196": { state: "CA", city: "SAN DIEGO" },
  "92197": { state: "CA", city: "SAN DIEGO" },
  "92198": { state: "CA", city: "SAN DIEGO" },
  "92199": { state: "CA", city: "SAN DIEGO" },
  "92201": { state: "CA", city: "INDIO" },
  "92202": { state: "CA", city: "INDIO" },
  "92203": { state: "CA", city: "INDIO" },
  "92210": { state: "CA", city: "INDIAN WELLS" },
  "92211": { state: "CA", city: "PALM DESERT" },
  "92220": { state: "CA", city: "BANNING" },
  "92222": { state: "CA", city: "BARD" },
  "92223": { state: "CA", city: "BEAUMONT" },
  "92225": { state: "CA", city: "BLYTHE" },
  "92226": { state: "CA", city: "BLYTHE" },
  "92227": { state: "CA", city: "BRAWLEY" },
  "92230": { state: "CA", city: "CABAZON" },
  "92231": { state: "CA", city: "CALEXICO" },
  "92232": { state: "CA", city: "CALEXICO" },
  "92233": { state: "CA", city: "CALIPATRIA" },
  "92234": { state: "CA", city: "CATHEDRAL CITY" },
  "92235": { state: "CA", city: "CATHEDRAL CITY" },
  "92236": { state: "CA", city: "COACHELLA" },
  "92239": { state: "CA", city: "DESERT CENTER" },
  "92240": { state: "CA", city: "DESERT HOT SPRINGS" },
  "92241": { state: "CA", city: "DESERT HOT SPRINGS" },
  "92242": { state: "CA", city: "EARP" },
  "92243": { state: "CA", city: "EL CENTRO" },
  "92244": { state: "CA", city: "EL CENTRO" },
  "92247": { state: "CA", city: "LA QUINTA" },
  "92248": { state: "CA", city: "LA QUINTA" },
  "92249": { state: "CA", city: "HEBER" },
  "92250": { state: "CA", city: "HOLTVILLE" },
  "92251": { state: "CA", city: "IMPERIAL" },
  "92252": { state: "CA", city: "JOSHUA TREE" },
  "92253": { state: "CA", city: "LA QUINTA" },
  "92254": { state: "CA", city: "MECCA" },
  "92255": { state: "CA", city: "PALM DESERT" },
  "92256": { state: "CA", city: "MORONGO VALLEY" },
  "92257": { state: "CA", city: "NILAND" },
  "92258": { state: "CA", city: "NORTH PALM SPRINGS" },
  "92259": { state: "CA", city: "OCOTILLO" },
  "92260": { state: "CA", city: "PALM DESERT" },
  "92261": { state: "CA", city: "PALM DESERT" },
  "92262": { state: "CA", city: "PALM SPRINGS" },
  "92263": { state: "CA", city: "PALM SPRINGS" },
  "92264": { state: "CA", city: "PALM SPRINGS" },
  "92266": { state: "CA", city: "PALO VERDE" },
  "92267": { state: "CA", city: "PARKER DAM" },
  "92268": { state: "CA", city: "PIONEERTOWN" },
  "92270": { state: "CA", city: "RANCHO MIRAGE" },
  "92273": { state: "CA", city: "SEELEY" },
  "92274": { state: "CA", city: "THERMAL" },
  "92275": { state: "CA", city: "SALTON CITY" },
  "92276": { state: "CA", city: "THOUSAND PALMS" },
  "92277": { state: "CA", city: "TWENTYNINE PALMS" },
  "92278": { state: "CA", city: "TWENTYNINE PALMS" },
  "92280": { state: "CA", city: "VIDAL" },
  "92281": { state: "CA", city: "WESTMORLAND" },
  "92282": { state: "CA", city: "WHITEWATER" },
  "92283": { state: "CA", city: "WINTERHAVEN" },
  "92284": { state: "CA", city: "YUCCA VALLEY" },
  "92285": { state: "CA", city: "LANDERS" },
  "92286": { state: "CA", city: "YUCCA VALLEY" },
  "92301": { state: "CA", city: "ADELANTO" },
  "92304": { state: "CA", city: "AMBOY" },
  "92305": { state: "CA", city: "ANGELUS OAKS" },
  "92307": { state: "CA", city: "APPLE VALLEY" },
  "92308": { state: "CA", city: "APPLE VALLEY" },
  "92309": { state: "CA", city: "BAKER" },
  "92310": { state: "CA", city: "FORT IRWIN" },
  "92311": { state: "CA", city: "BARSTOW" },
  "92312": { state: "CA", city: "BARSTOW" },
  "92313": { state: "CA", city: "GRAND TERRACE" },
  "92314": { state: "CA", city: "BIG BEAR CITY" },
  "92315": { state: "CA", city: "BIG BEAR LAKE" },
  "92316": { state: "CA", city: "BLOOMINGTON" },
  "92317": { state: "CA", city: "BLUE JAY" },
  "92318": { state: "CA", city: "BRYN MAWR" },
  "92320": { state: "CA", city: "CALIMESA" },
  "92321": { state: "CA", city: "CEDAR GLEN" },
  "92322": { state: "CA", city: "CEDARPINES PARK" },
  "92323": { state: "CA", city: "CIMA" },
  "92324": { state: "CA", city: "COLTON" },
  "92325": { state: "CA", city: "CRESTLINE" },
  "92327": { state: "CA", city: "DAGGETT" },
  "92328": { state: "CA", city: "DEATH VALLEY" },
  "92329": { state: "CA", city: "PHELAN" },
  "92331": { state: "CA", city: "FONTANA" },
  "92332": { state: "CA", city: "ESSEX" },
  "92333": { state: "CA", city: "FAWNSKIN" },
  "92334": { state: "CA", city: "FONTANA" },
  "92335": { state: "CA", city: "FONTANA" },
  "92336": { state: "CA", city: "FONTANA" },
  "92337": { state: "CA", city: "FONTANA" },
  "92338": { state: "CA", city: "LUDLOW" },
  "92339": { state: "CA", city: "FOREST FALLS" },
  "92340": { state: "CA", city: "HESPERIA" },
  "92341": { state: "CA", city: "GREEN VALLEY LAKE" },
  "92342": { state: "CA", city: "HELENDALE" },
  "92344": { state: "CA", city: "HESPERIA" },
  "92345": { state: "CA", city: "HESPERIA" },
  "92346": { state: "CA", city: "HIGHLAND" },
  "92347": { state: "CA", city: "HINKLEY" },
  "92350": { state: "CA", city: "LOMA LINDA" },
  "92352": { state: "CA", city: "LAKE ARROWHEAD" },
  "92354": { state: "CA", city: "LOMA LINDA" },
  "92356": { state: "CA", city: "LUCERNE VALLEY" },
  "92357": { state: "CA", city: "LOMA LINDA" },
  "92358": { state: "CA", city: "LYTLE CREEK" },
  "92359": { state: "CA", city: "MENTONE" },
  "92363": { state: "CA", city: "NEEDLES" },
  "92364": { state: "CA", city: "NIPTON" },
  "92365": { state: "CA", city: "NEWBERRY SPRINGS" },
  "92366": { state: "CA", city: "MOUNTAIN PASS" },
  "92368": { state: "CA", city: "ORO GRANDE" },
  "92369": { state: "CA", city: "PATTON" },
  "92371": { state: "CA", city: "PHELAN" },
  "92372": { state: "CA", city: "PINON HILLS" },
  "92373": { state: "CA", city: "REDLANDS" },
  "92374": { state: "CA", city: "REDLANDS" },
  "92375": { state: "CA", city: "REDLANDS" },
  "92376": { state: "CA", city: "RIALTO" },
  "92377": { state: "CA", city: "RIALTO" },
  "92378": { state: "CA", city: "RIMFOREST" },
  "92382": { state: "CA", city: "RUNNING SPRINGS" },
  "92384": { state: "CA", city: "SHOSHONE" },
  "92385": { state: "CA", city: "SKYFOREST" },
  "92386": { state: "CA", city: "SUGARLOAF" },
  "92389": { state: "CA", city: "TECOPA" },
  "92391": { state: "CA", city: "TWIN PEAKS" },
  "92392": { state: "CA", city: "VICTORVILLE" },
  "92393": { state: "CA", city: "VICTORVILLE" },
  "92394": { state: "CA", city: "VICTORVILLE" },
  "92395": { state: "CA", city: "VICTORVILLE" },
  "92397": { state: "CA", city: "WRIGHTWOOD" },
  "92398": { state: "CA", city: "YERMO" },
  "92399": { state: "CA", city: "YUCAIPA" },
  "92401": { state: "CA", city: "SAN BERNARDINO" },
  "92402": { state: "CA", city: "SAN BERNARDINO" },
  "92403": { state: "CA", city: "SAN BERNARDINO" },
  "92404": { state: "CA", city: "SAN BERNARDINO" },
  "92405": { state: "CA", city: "SAN BERNARDINO" },
  "92406": { state: "CA", city: "SAN BERNARDINO" },
  "92407": { state: "CA", city: "SAN BERNARDINO" },
  "92408": { state: "CA", city: "SAN BERNARDINO" },
  "92410": { state: "CA", city: "SAN BERNARDINO" },
  "92411": { state: "CA", city: "SAN BERNARDINO" },
  "92413": { state: "CA", city: "SAN BERNARDINO" },
  "92415": { state: "CA", city: "SAN BERNARDINO" },
  "92418": { state: "CA", city: "SAN BERNARDINO" },
  "92423": { state: "CA", city: "SAN BERNARDINO" },
  "92427": { state: "CA", city: "SAN BERNARDINO" },
  "92501": { state: "CA", city: "RIVERSIDE" },
  "92502": { state: "CA", city: "RIVERSIDE" },
  "92503": { state: "CA", city: "RIVERSIDE" },
  "92504": { state: "CA", city: "RIVERSIDE" },
  "92505": { state: "CA", city: "RIVERSIDE" },
  "92506": { state: "CA", city: "RIVERSIDE" },
  "92507": { state: "CA", city: "RIVERSIDE" },
  "92508": { state: "CA", city: "RIVERSIDE" },
  "92509": { state: "CA", city: "JURUPA VALLEY" },
  "92513": { state: "CA", city: "RIVERSIDE" },
  "92514": { state: "CA", city: "RIVERSIDE" },
  "92516": { state: "CA", city: "RIVERSIDE" },
  "92517": { state: "CA", city: "RIVERSIDE" },
  "92518": { state: "CA", city: "MARCH AIR RESERVE BASE" },
  "92519": { state: "CA", city: "RIVERSIDE" },
  "92521": { state: "CA", city: "RIVERSIDE" },
  "92522": { state: "CA", city: "RIVERSIDE" },
  "92530": { state: "CA", city: "LAKE ELSINORE" },
  "92531": { state: "CA", city: "LAKE ELSINORE" },
  "92532": { state: "CA", city: "LAKE ELSINORE" },
  "92536": { state: "CA", city: "AGUANGA" },
  "92539": { state: "CA", city: "ANZA" },
  "92543": { state: "CA", city: "HEMET" },
  "92544": { state: "CA", city: "HEMET" },
  "92545": { state: "CA", city: "HEMET" },
  "92546": { state: "CA", city: "HEMET" },
  "92548": { state: "CA", city: "HOMELAND" },
  "92549": { state: "CA", city: "IDYLLWILD" },
  "92551": { state: "CA", city: "MORENO VALLEY" },
  "92552": { state: "CA", city: "MORENO VALLEY" },
  "92553": { state: "CA", city: "MORENO VALLEY" },
  "92554": { state: "CA", city: "MORENO VALLEY" },
  "92555": { state: "CA", city: "MORENO VALLEY" },
  "92556": { state: "CA", city: "MORENO VALLEY" },
  "92557": { state: "CA", city: "MORENO VALLEY" },
  "92561": { state: "CA", city: "MOUNTAIN CENTER" },
  "92562": { state: "CA", city: "MURRIETA" },
  "92563": { state: "CA", city: "MURRIETA" },
  "92564": { state: "CA", city: "MURRIETA" },
  "92567": { state: "CA", city: "NUEVO" },
  "92570": { state: "CA", city: "PERRIS" },
  "92571": { state: "CA", city: "PERRIS" },
  "92572": { state: "CA", city: "PERRIS" },
  "92581": { state: "CA", city: "SAN JACINTO" },
  "92582": { state: "CA", city: "SAN JACINTO" },
  "92583": { state: "CA", city: "SAN JACINTO" },
  "92584": { state: "CA", city: "MENIFEE" },
  "92585": { state: "CA", city: "MENIFEE" },
  "92586": { state: "CA", city: "MENIFEE" },
  "92587": { state: "CA", city: "MENIFEE" },
  "92589": { state: "CA", city: "TEMECULA" },
  "92590": { state: "CA", city: "TEMECULA" },
  "92591": { state: "CA", city: "TEMECULA" },
  "92592": { state: "CA", city: "TEMECULA" },
  "92593": { state: "CA", city: "TEMECULA" },
  "92595": { state: "CA", city: "WILDOMAR" },
  "92596": { state: "CA", city: "WINCHESTER" },
  "92599": { state: "CA", city: "PERRIS" },
  "92602": { state: "CA", city: "IRVINE" },
  "92603": { state: "CA", city: "IRVINE" },
  "92604": { state: "CA", city: "IRVINE" },
  "92605": { state: "CA", city: "HUNTINGTON BEACH" },
  "92606": { state: "CA", city: "IRVINE" },
  "92607": { state: "CA", city: "LAGUNA NIGUEL" },
  "92609": { state: "CA", city: "EL TORO" },
  "92610": { state: "CA", city: "FOOTHILL RANCH" },
  "92612": { state: "CA", city: "IRVINE" },
  "92614": { state: "CA", city: "IRVINE" },
  "92615": { state: "CA", city: "HUNTINGTON BEACH" },
  "92616": { state: "CA", city: "IRVINE" },
  "92617": { state: "CA", city: "IRVINE" },
  "92618": { state: "CA", city: "IRVINE" },
  "92619": { state: "CA", city: "IRVINE" },
  "92620": { state: "CA", city: "IRVINE" },
  "92623": { state: "CA", city: "IRVINE" },
  "92624": { state: "CA", city: "CAPISTRANO BEACH" },
  "92625": { state: "CA", city: "CORONA DEL MAR" },
  "92626": { state: "CA", city: "COSTA MESA" },
  "92627": { state: "CA", city: "COSTA MESA" },
  "92628": { state: "CA", city: "COSTA MESA" },
  "92629": { state: "CA", city: "DANA POINT" },
  "92630": { state: "CA", city: "LAKE FOREST" },
  "92637": { state: "CA", city: "LAGUNA WOODS" },
  "92646": { state: "CA", city: "HUNTINGTON BEACH" },
  "92647": { state: "CA", city: "HUNTINGTON BEACH" },
  "92648": { state: "CA", city: "HUNTINGTON BEACH" },
  "92649": { state: "CA", city: "HUNTINGTON BEACH" },
  "92650": { state: "CA", city: "EAST IRVINE" },
  "92651": { state: "CA", city: "LAGUNA BEACH" },
  "92652": { state: "CA", city: "LAGUNA BEACH" },
  "92653": { state: "CA", city: "LAGUNA HILLS" },
  "92654": { state: "CA", city: "LAGUNA HILLS" },
  "92655": { state: "CA", city: "MIDWAY CITY" },
  "92656": { state: "CA", city: "ALISO VIEJO" },
  "92657": { state: "CA", city: "NEWPORT COAST" },
  "92658": { state: "CA", city: "NEWPORT BEACH" },
  "92659": { state: "CA", city: "NEWPORT BEACH" },
  "92660": { state: "CA", city: "NEWPORT BEACH" },
  "92661": { state: "CA", city: "NEWPORT BEACH" },
  "92662": { state: "CA", city: "NEWPORT BEACH" },
  "92663": { state: "CA", city: "NEWPORT BEACH" },
  "92672": { state: "CA", city: "SAN CLEMENTE" },
  "92673": { state: "CA", city: "SAN CLEMENTE" },
  "92674": { state: "CA", city: "SAN CLEMENTE" },
  "92675": { state: "CA", city: "SAN JUAN CAPISTRANO" },
  "92676": { state: "CA", city: "SILVERADO" },
  "92677": { state: "CA", city: "LAGUNA NIGUEL" },
  "92678": { state: "CA", city: "TRABUCO CANYON" },
  "92679": { state: "CA", city: "TRABUCO CANYON" },
  "92683": { state: "CA", city: "WESTMINSTER" },
  "92684": { state: "CA", city: "WESTMINSTER" },
  "92685": { state: "CA", city: "WESTMINSTER" },
  "92688": { state: "CA", city: "RANCHO SANTA MARGARITA" },
  "92690": { state: "CA", city: "MISSION VIEJO" },
  "92691": { state: "CA", city: "MISSION VIEJO" },
  "92692": { state: "CA", city: "MISSION VIEJO" },
  "92693": { state: "CA", city: "SAN JUAN CAPISTRANO" },
  "92694": { state: "CA", city: "LADERA RANCH" },
  "92697": { state: "CA", city: "IRVINE" },
  "92698": { state: "CA", city: "ALISO VIEJO" },
  "92701": { state: "CA", city: "SANTA ANA" },
  "92702": { state: "CA", city: "SANTA ANA" },
  "92703": { state: "CA", city: "SANTA ANA" },
  "92704": { state: "CA", city: "SANTA ANA" },
  "92705": { state: "CA", city: "SANTA ANA" },
  "92706": { state: "CA", city: "SANTA ANA" },
  "92707": { state: "CA", city: "SANTA ANA" },
  "92708": { state: "CA", city: "FOUNTAIN VALLEY" },
  "92711": { state: "CA", city: "SANTA ANA" },
  "92712": { state: "CA", city: "SANTA ANA" },
  "92728": { state: "CA", city: "FOUNTAIN VALLEY" },
  "92735": { state: "CA", city: "SANTA ANA" },
  "92780": { state: "CA", city: "TUSTIN" },
  "92781": { state: "CA", city: "TUSTIN" },
  "92782": { state: "CA", city: "TUSTIN" },
  "92799": { state: "CA", city: "SANTA ANA" },
  "92801": { state: "CA", city: "ANAHEIM" },
  "92802": { state: "CA", city: "ANAHEIM" },
  "92803": { state: "CA", city: "ANAHEIM" },
  "92804": { state: "CA", city: "ANAHEIM" },
  "92805": { state: "CA", city: "ANAHEIM" },
  "92806": { state: "CA", city: "ANAHEIM" },
  "92807": { state: "CA", city: "ANAHEIM" },
  "92808": { state: "CA", city: "ANAHEIM" },
  "92809": { state: "CA", city: "ANAHEIM" },
  "92811": { state: "CA", city: "ATWOOD" },
  "92812": { state: "CA", city: "ANAHEIM" },
  "92814": { state: "CA", city: "ANAHEIM" },
  "92815": { state: "CA", city: "ANAHEIM" },
  "92816": { state: "CA", city: "ANAHEIM" },
  "92817": { state: "CA", city: "ANAHEIM" },
  "92821": { state: "CA", city: "BREA" },
  "92822": { state: "CA", city: "BREA" },
  "92823": { state: "CA", city: "BREA" },
  "92825": { state: "CA", city: "ANAHEIM" },
  "92831": { state: "CA", city: "FULLERTON" },
  "92832": { state: "CA", city: "FULLERTON" },
  "92833": { state: "CA", city: "FULLERTON" },
  "92834": { state: "CA", city: "FULLERTON" },
  "92835": { state: "CA", city: "FULLERTON" },
  "92836": { state: "CA", city: "FULLERTON" },
  "92837": { state: "CA", city: "FULLERTON" },
  "92838": { state: "CA", city: "FULLERTON" },
  "92840": { state: "CA", city: "GARDEN GROVE" },
  "92841": { state: "CA", city: "GARDEN GROVE" },
  "92842": { state: "CA", city: "GARDEN GROVE" },
  "92843": { state: "CA", city: "GARDEN GROVE" },
  "92844": { state: "CA", city: "GARDEN GROVE" },
  "92845": { state: "CA", city: "GARDEN GROVE" },
  "92846": { state: "CA", city: "GARDEN GROVE" },
  "92850": { state: "CA", city: "ANAHEIM" },
  "92856": { state: "CA", city: "ORANGE" },
  "92857": { state: "CA", city: "ORANGE" },
  "92859": { state: "CA", city: "ORANGE" },
  "92860": { state: "CA", city: "NORCO" },
  "92861": { state: "CA", city: "VILLA PARK" },
  "92862": { state: "CA", city: "ORANGE" },
  "92863": { state: "CA", city: "ORANGE" },
  "92864": { state: "CA", city: "ORANGE" },
  "92865": { state: "CA", city: "ORANGE" },
  "92866": { state: "CA", city: "ORANGE" },
  "92867": { state: "CA", city: "ORANGE" },
  "92868": { state: "CA", city: "ORANGE" },
  "92869": { state: "CA", city: "ORANGE" },
  "92870": { state: "CA", city: "PLACENTIA" },
  "92871": { state: "CA", city: "PLACENTIA" },
  "92877": { state: "CA", city: "CORONA" },
  "92878": { state: "CA", city: "CORONA" },
  "92879": { state: "CA", city: "CORONA" },
  "92880": { state: "CA", city: "CORONA" },
  "92881": { state: "CA", city: "CORONA" },
  "92882": { state: "CA", city: "CORONA" },
  "92883": { state: "CA", city: "CORONA" },
  "92885": { state: "CA", city: "YORBA LINDA" },
  "92886": { state: "CA", city: "YORBA LINDA" },
  "92887": { state: "CA", city: "YORBA LINDA" },
  "92899": { state: "CA", city: "ANAHEIM" },
  "93001": { state: "CA", city: "VENTURA" },
  "93002": { state: "CA", city: "VENTURA" },
  "93003": { state: "CA", city: "VENTURA" },
  "93004": { state: "CA", city: "VENTURA" },
  "93005": { state: "CA", city: "VENTURA" },
  "93006": { state: "CA", city: "VENTURA" },
  "93007": { state: "CA", city: "VENTURA" },
  "93009": { state: "CA", city: "VENTURA" },
  "93010": { state: "CA", city: "CAMARILLO" },
  "93011": { state: "CA", city: "CAMARILLO" },
  "93012": { state: "CA", city: "CAMARILLO" },
  "93013": { state: "CA", city: "CARPINTERIA" },
  "93014": { state: "CA", city: "CARPINTERIA" },
  "93015": { state: "CA", city: "FILLMORE" },
  "93016": { state: "CA", city: "FILLMORE" },
  "93020": { state: "CA", city: "MOORPARK" },
  "93021": { state: "CA", city: "MOORPARK" },
  "93022": { state: "CA", city: "OAK VIEW" },
  "93023": { state: "CA", city: "OJAI" },
  "93024": { state: "CA", city: "OJAI" },
  "93030": { state: "CA", city: "OXNARD" },
  "93031": { state: "CA", city: "OXNARD" },
  "93032": { state: "CA", city: "OXNARD" },
  "93033": { state: "CA", city: "OXNARD" },
  "93034": { state: "CA", city: "OXNARD" },
  "93035": { state: "CA", city: "OXNARD" },
  "93036": { state: "CA", city: "OXNARD" },
  "93040": { state: "CA", city: "PIRU" },
  "93041": { state: "CA", city: "PORT HUENEME" },
  "93042": { state: "CA", city: "POINT MUGU NAWC" },
  "93043": { state: "CA", city: "PORT HUENEME CBC BASE" },
  "93044": { state: "CA", city: "PORT HUENEME" },
  "93060": { state: "CA", city: "SANTA PAULA" },
  "93061": { state: "CA", city: "SANTA PAULA" },
  "93062": { state: "CA", city: "SIMI VALLEY" },
  "93063": { state: "CA", city: "SIMI VALLEY" },
  "93064": { state: "CA", city: "BRANDEIS" },
  "93065": { state: "CA", city: "SIMI VALLEY" },
  "93066": { state: "CA", city: "SOMIS" },
  "93067": { state: "CA", city: "SUMMERLAND" },
  "93094": { state: "CA", city: "SIMI VALLEY" },
  "93099": { state: "CA", city: "SIMI VALLEY" },
  "93101": { state: "CA", city: "SANTA BARBARA" },
  "93102": { state: "CA", city: "SANTA BARBARA" },
  "93103": { state: "CA", city: "SANTA BARBARA" },
  "93105": { state: "CA", city: "SANTA BARBARA" },
  "93106": { state: "CA", city: "SANTA BARBARA" },
  "93107": { state: "CA", city: "SANTA BARBARA" },
  "93108": { state: "CA", city: "SANTA BARBARA" },
  "93109": { state: "CA", city: "SANTA BARBARA" },
  "93110": { state: "CA", city: "SANTA BARBARA" },
  "93111": { state: "CA", city: "SANTA BARBARA" },
  "93116": { state: "CA", city: "GOLETA" },
  "93117": { state: "CA", city: "GOLETA" },
  "93118": { state: "CA", city: "GOLETA" },
  "93120": { state: "CA", city: "SANTA BARBARA" },
  "93121": { state: "CA", city: "SANTA BARBARA" },
  "93130": { state: "CA", city: "SANTA BARBARA" },
  "93140": { state: "CA", city: "SANTA BARBARA" },
  "93150": { state: "CA", city: "SANTA BARBARA" },
  "93160": { state: "CA", city: "SANTA BARBARA" },
  "93190": { state: "CA", city: "SANTA BARBARA" },
  "93199": { state: "CA", city: "GOLETA" },
  "93201": { state: "CA", city: "ALPAUGH" },
  "93202": { state: "CA", city: "ARMONA" },
  "93203": { state: "CA", city: "ARVIN" },
  "93204": { state: "CA", city: "AVENAL" },
  "93205": { state: "CA", city: "BODFISH" },
  "93206": { state: "CA", city: "BUTTONWILLOW" },
  "93207": { state: "CA", city: "CALIFORNIA HOT SPRINGS" },
  "93208": { state: "CA", city: "CAMP NELSON" },
  "93210": { state: "CA", city: "COALINGA" },
  "93212": { state: "CA", city: "CORCORAN" },
  "93215": { state: "CA", city: "DELANO" },
  "93216": { state: "CA", city: "DELANO" },
  "93218": { state: "CA", city: "DUCOR" },
  "93219": { state: "CA", city: "EARLIMART" },
  "93220": { state: "CA", city: "EDISON" },
  "93221": { state: "CA", city: "EXETER" },
  "93222": { state: "CA", city: "PINE MOUNTAIN CLUB" },
  "93223": { state: "CA", city: "FARMERSVILLE" },
  "93224": { state: "CA", city: "FELLOWS" },
  "93225": { state: "CA", city: "FRAZIER PARK" },
  "93226": { state: "CA", city: "GLENNVILLE" },
  "93227": { state: "CA", city: "GOSHEN" },
  "93230": { state: "CA", city: "HANFORD" },
  "93232": { state: "CA", city: "HANFORD" },
  "93234": { state: "CA", city: "HURON" },
  "93235": { state: "CA", city: "IVANHOE" },
  "93237": { state: "CA", city: "KAWEAH" },
  "93238": { state: "CA", city: "KERNVILLE" },
  "93239": { state: "CA", city: "KETTLEMAN CITY" },
  "93240": { state: "CA", city: "LAKE ISABELLA" },
  "93241": { state: "CA", city: "LAMONT" },
  "93242": { state: "CA", city: "LATON" },
  "93243": { state: "CA", city: "LEBEC" },
  "93244": { state: "CA", city: "LEMON COVE" },
  "93245": { state: "CA", city: "LEMOORE" },
  "93246": { state: "CA", city: "LEMOORE" },
  "93247": { state: "CA", city: "LINDSAY" },
  "93249": { state: "CA", city: "LOST HILLS" },
  "93250": { state: "CA", city: "MC FARLAND" },
  "93251": { state: "CA", city: "MC KITTRICK" },
  "93252": { state: "CA", city: "MARICOPA" },
  "93254": { state: "CA", city: "NEW CUYAMA" },
  "93255": { state: "CA", city: "ONYX" },
  "93256": { state: "CA", city: "PIXLEY" },
  "93257": { state: "CA", city: "PORTERVILLE" },
  "93258": { state: "CA", city: "PORTERVILLE" },
  "93260": { state: "CA", city: "POSEY" },
  "93261": { state: "CA", city: "RICHGROVE" },
  "93262": { state: "CA", city: "SEQUOIA NATIONAL PARK" },
  "93263": { state: "CA", city: "SHAFTER" },
  "93265": { state: "CA", city: "SPRINGVILLE" },
  "93266": { state: "CA", city: "STRATFORD" },
  "93267": { state: "CA", city: "STRATHMORE" },
  "93268": { state: "CA", city: "TAFT" },
  "93270": { state: "CA", city: "TERRA BELLA" },
  "93271": { state: "CA", city: "THREE RIVERS" },
  "93272": { state: "CA", city: "TIPTON" },
  "93274": { state: "CA", city: "TULARE" },
  "93275": { state: "CA", city: "TULARE" },
  "93276": { state: "CA", city: "TUPMAN" },
  "93277": { state: "CA", city: "VISALIA" },
  "93278": { state: "CA", city: "VISALIA" },
  "93279": { state: "CA", city: "VISALIA" },
  "93280": { state: "CA", city: "WASCO" },
  "93282": { state: "CA", city: "WAUKENA" },
  "93283": { state: "CA", city: "WELDON" },
  "93285": { state: "CA", city: "WOFFORD HEIGHTS" },
  "93286": { state: "CA", city: "WOODLAKE" },
  "93287": { state: "CA", city: "WOODY" },
  "93290": { state: "CA", city: "VISALIA" },
  "93291": { state: "CA", city: "VISALIA" },
  "93292": { state: "CA", city: "VISALIA" },
  "93301": { state: "CA", city: "BAKERSFIELD" },
  "93302": { state: "CA", city: "BAKERSFIELD" },
  "93303": { state: "CA", city: "BAKERSFIELD" },
  "93304": { state: "CA", city: "BAKERSFIELD" },
  "93305": { state: "CA", city: "BAKERSFIELD" },
  "93306": { state: "CA", city: "BAKERSFIELD" },
  "93307": { state: "CA", city: "BAKERSFIELD" },
  "93308": { state: "CA", city: "BAKERSFIELD" },
  "93309": { state: "CA", city: "BAKERSFIELD" },
  "93311": { state: "CA", city: "BAKERSFIELD" },
  "93312": { state: "CA", city: "BAKERSFIELD" },
  "93313": { state: "CA", city: "BAKERSFIELD" },
  "93314": { state: "CA", city: "BAKERSFIELD" },
  "93380": { state: "CA", city: "BAKERSFIELD" },
  "93383": { state: "CA", city: "BAKERSFIELD" },
  "93384": { state: "CA", city: "BAKERSFIELD" },
  "93385": { state: "CA", city: "BAKERSFIELD" },
  "93386": { state: "CA", city: "BAKERSFIELD" },
  "93387": { state: "CA", city: "BAKERSFIELD" },
  "93388": { state: "CA", city: "BAKERSFIELD" },
  "93389": { state: "CA", city: "BAKERSFIELD" },
  "93390": { state: "CA", city: "BAKERSFIELD" },
  "93401": { state: "CA", city: "SAN LUIS OBISPO" },
  "93402": { state: "CA", city: "LOS OSOS" },
  "93403": { state: "CA", city: "SAN LUIS OBISPO" },
  "93405": { state: "CA", city: "SAN LUIS OBISPO" },
  "93406": { state: "CA", city: "SAN LUIS OBISPO" },
  "93407": { state: "CA", city: "SAN LUIS OBISPO" },
  "93408": { state: "CA", city: "SAN LUIS OBISPO" },
  "93409": { state: "CA", city: "SAN LUIS OBISPO" },
  "93410": { state: "CA", city: "SAN LUIS OBISPO" },
  "93412": { state: "CA", city: "LOS OSOS" },
  "93420": { state: "CA", city: "ARROYO GRANDE" },
  "93421": { state: "CA", city: "ARROYO GRANDE" },
  "93422": { state: "CA", city: "ATASCADERO" },
  "93423": { state: "CA", city: "ATASCADERO" },
  "93424": { state: "CA", city: "AVILA BEACH" },
  "93426": { state: "CA", city: "BRADLEY" },
  "93427": { state: "CA", city: "BUELLTON" },
  "93428": { state: "CA", city: "CAMBRIA" },
  "93429": { state: "CA", city: "CASMALIA" },
  "93430": { state: "CA", city: "CAYUCOS" },
  "93432": { state: "CA", city: "CRESTON" },
  "93433": { state: "CA", city: "GROVER BEACH" },
  "93434": { state: "CA", city: "GUADALUPE" },
  "93435": { state: "CA", city: "HARMONY" },
  "93436": { state: "CA", city: "LOMPOC" },
  "93437": { state: "CA", city: "LOMPOC" },
  "93438": { state: "CA", city: "LOMPOC" },
  "93440": { state: "CA", city: "LOS ALAMOS" },
  "93441": { state: "CA", city: "LOS OLIVOS" },
  "93442": { state: "CA", city: "MORRO BAY" },
  "93443": { state: "CA", city: "MORRO BAY" },
  "93444": { state: "CA", city: "NIPOMO" },
  "93445": { state: "CA", city: "OCEANO" },
  "93446": { state: "CA", city: "PASO ROBLES" },
  "93447": { state: "CA", city: "PASO ROBLES" },
  "93448": { state: "CA", city: "PISMO BEACH" },
  "93449": { state: "CA", city: "PISMO BEACH" },
  "93450": { state: "CA", city: "SAN ARDO" },
  "93451": { state: "CA", city: "SAN MIGUEL" },
  "93452": { state: "CA", city: "SAN SIMEON" },
  "93453": { state: "CA", city: "SANTA MARGARITA" },
  "93454": { state: "CA", city: "SANTA MARIA" },
  "93455": { state: "CA", city: "SANTA MARIA" },
  "93456": { state: "CA", city: "SANTA MARIA" },
  "93457": { state: "CA", city: "SANTA MARIA" },
  "93458": { state: "CA", city: "SANTA MARIA" },
  "93460": { state: "CA", city: "SANTA YNEZ" },
  "93461": { state: "CA", city: "SHANDON" },
  "93463": { state: "CA", city: "SOLVANG" },
  "93464": { state: "CA", city: "SOLVANG" },
  "93465": { state: "CA", city: "TEMPLETON" },
  "93475": { state: "CA", city: "OCEANO" },
  "93483": { state: "CA", city: "GROVER BEACH" },
  "93501": { state: "CA", city: "MOJAVE" },
  "93502": { state: "CA", city: "MOJAVE" },
  "93504": { state: "CA", city: "CALIFORNIA CITY" },
  "93505": { state: "CA", city: "CALIFORNIA CITY" },
  "93510": { state: "CA", city: "ACTON" },
  "93512": { state: "CA", city: "BENTON" },
  "93513": { state: "CA", city: "BIG PINE" },
  "93514": { state: "CA", city: "BISHOP" },
  "93515": { state: "CA", city: "BISHOP" },
  "93516": { state: "CA", city: "BORON" },
  "93517": { state: "CA", city: "BRIDGEPORT" },
  "93518": { state: "CA", city: "CALIENTE" },
  "93519": { state: "CA", city: "CANTIL" },
  "93522": { state: "CA", city: "DARWIN" },
  "93523": { state: "CA", city: "EDWARDS" },
  "93524": { state: "CA", city: "EDWARDS" },
  "93526": { state: "CA", city: "INDEPENDENCE" },
  "93527": { state: "CA", city: "INYOKERN" },
  "93528": { state: "CA", city: "JOHANNESBURG" },
  "93529": { state: "CA", city: "JUNE LAKE" },
  "93530": { state: "CA", city: "KEELER" },
  "93531": { state: "CA", city: "KEENE" },
  "93532": { state: "CA", city: "LAKE HUGHES" },
  "93534": { state: "CA", city: "LANCASTER" },
  "93535": { state: "CA", city: "LANCASTER" },
  "93536": { state: "CA", city: "LANCASTER" },
  "93539": { state: "CA", city: "LANCASTER" },
  "93541": { state: "CA", city: "LEE VINING" },
  "93542": { state: "CA", city: "LITTLE LAKE" },
  "93543": { state: "CA", city: "LITTLEROCK" },
  "93544": { state: "CA", city: "LLANO" },
  "93545": { state: "CA", city: "LONE PINE" },
  "93546": { state: "CA", city: "MAMMOTH LAKES" },
  "93549": { state: "CA", city: "OLANCHA" },
  "93550": { state: "CA", city: "PALMDALE" },
  "93551": { state: "CA", city: "PALMDALE" },
  "93552": { state: "CA", city: "PALMDALE" },
  "93553": { state: "CA", city: "PEARBLOSSOM" },
  "93554": { state: "CA", city: "RANDSBURG" },
  "93555": { state: "CA", city: "RIDGECREST" },
  "93556": { state: "CA", city: "RIDGECREST" },
  "93558": { state: "CA", city: "RED MOUNTAIN" },
  "93560": { state: "CA", city: "ROSAMOND" },
  "93561": { state: "CA", city: "TEHACHAPI" },
  "93562": { state: "CA", city: "TRONA" },
  "93563": { state: "CA", city: "VALYERMO" },
  "93581": { state: "CA", city: "TEHACHAPI" },
  "93584": { state: "CA", city: "LANCASTER" },
  "93586": { state: "CA", city: "LANCASTER" },
  "93590": { state: "CA", city: "PALMDALE" },
  "93591": { state: "CA", city: "PALMDALE" },
  "93592": { state: "CA", city: "TRONA" },
  "93596": { state: "CA", city: "BORON" },
  "93599": { state: "CA", city: "PALMDALE" },
  "93601": { state: "CA", city: "AHWAHNEE" },
  "93602": { state: "CA", city: "AUBERRY" },
  "93603": { state: "CA", city: "BADGER" },
  "93604": { state: "CA", city: "BASS LAKE" },
  "93605": { state: "CA", city: "BIG CREEK" },
  "93606": { state: "CA", city: "BIOLA" },
  "93607": { state: "CA", city: "BURREL" },
  "93608": { state: "CA", city: "CANTUA CREEK" },
  "93609": { state: "CA", city: "CARUTHERS" },
  "93610": { state: "CA", city: "CHOWCHILLA" },
  "93611": { state: "CA", city: "CLOVIS" },
  "93612": { state: "CA", city: "CLOVIS" },
  "93613": { state: "CA", city: "CLOVIS" },
  "93614": { state: "CA", city: "COARSEGOLD" },
  "93615": { state: "CA", city: "CUTLER" },
  "93616": { state: "CA", city: "DEL REY" },
  "93618": { state: "CA", city: "DINUBA" },
  "93619": { state: "CA", city: "CLOVIS" },
  "93620": { state: "CA", city: "DOS PALOS" },
  "93621": { state: "CA", city: "DUNLAP" },
  "93622": { state: "CA", city: "FIREBAUGH" },
  "93623": { state: "CA", city: "FISH CAMP" },
  "93624": { state: "CA", city: "FIVE POINTS" },
  "93625": { state: "CA", city: "FOWLER" },
  "93626": { state: "CA", city: "FRIANT" },
  "93627": { state: "CA", city: "HELM" },
  "93628": { state: "CA", city: "HUME" },
  "93630": { state: "CA", city: "KERMAN" },
  "93631": { state: "CA", city: "KINGSBURG" },
  "93633": { state: "CA", city: "KINGS CANYON NATIONAL PK" },
  "93634": { state: "CA", city: "LAKESHORE" },
  "93635": { state: "CA", city: "LOS BANOS" },
  "93636": { state: "CA", city: "MADERA" },
  "93637": { state: "CA", city: "MADERA" },
  "93638": { state: "CA", city: "MADERA" },
  "93639": { state: "CA", city: "MADERA" },
  "93640": { state: "CA", city: "MENDOTA" },
  "93641": { state: "CA", city: "MIRAMONTE" },
  "93642": { state: "CA", city: "MONO HOT SPRINGS" },
  "93643": { state: "CA", city: "NORTH FORK" },
  "93644": { state: "CA", city: "OAKHURST" },
  "93645": { state: "CA", city: "O NEALS" },
  "93646": { state: "CA", city: "ORANGE COVE" },
  "93647": { state: "CA", city: "OROSI" },
  "93648": { state: "CA", city: "PARLIER" },
  "93649": { state: "CA", city: "PIEDRA" },
  "93650": { state: "CA", city: "FRESNO" },
  "93651": { state: "CA", city: "PRATHER" },
  "93652": { state: "CA", city: "RAISIN CITY" },
  "93653": { state: "CA", city: "RAYMOND" },
  "93654": { state: "CA", city: "REEDLEY" },
  "93656": { state: "CA", city: "RIVERDALE" },
  "93657": { state: "CA", city: "SANGER" },
  "93660": { state: "CA", city: "SAN JOAQUIN" },
  "93661": { state: "CA", city: "SANTA RITA PARK" },
  "93662": { state: "CA", city: "SELMA" },
  "93664": { state: "CA", city: "SHAVER LAKE" },
  "93665": { state: "CA", city: "SOUTH DOS PALOS" },
  "93666": { state: "CA", city: "SULTANA" },
  "93667": { state: "CA", city: "TOLLHOUSE" },
  "93668": { state: "CA", city: "TRANQUILLITY" },
  "93669": { state: "CA", city: "WISHON" },
  "93670": { state: "CA", city: "YETTEM" },
  "93673": { state: "CA", city: "TRAVER" },
  "93675": { state: "CA", city: "SQUAW VALLEY" },
  "93701": { state: "CA", city: "FRESNO" },
  "93702": { state: "CA", city: "FRESNO" },
  "93703": { state: "CA", city: "FRESNO" },
  "93704": { state: "CA", city: "FRESNO" },
  "93705": { state: "CA", city: "FRESNO" },
  "93706": { state: "CA", city: "FRESNO" },
  "93707": { state: "CA", city: "FRESNO" },
  "93708": { state: "CA", city: "FRESNO" },
  "93709": { state: "CA", city: "FRESNO" },
  "93710": { state: "CA", city: "FRESNO" },
  "93711": { state: "CA", city: "FRESNO" },
  "93712": { state: "CA", city: "FRESNO" },
  "93714": { state: "CA", city: "FRESNO" },
  "93715": { state: "CA", city: "FRESNO" },
  "93716": { state: "CA", city: "FRESNO" },
  "93717": { state: "CA", city: "FRESNO" },
  "93718": { state: "CA", city: "FRESNO" },
  "93720": { state: "CA", city: "FRESNO" },
  "93721": { state: "CA", city: "FRESNO" },
  "93722": { state: "CA", city: "FRESNO" },
  "93723": { state: "CA", city: "FRESNO" },
  "93724": { state: "CA", city: "FRESNO" },
  "93725": { state: "CA", city: "FRESNO" },
  "93726": { state: "CA", city: "FRESNO" },
  "93727": { state: "CA", city: "FRESNO" },
  "93728": { state: "CA", city: "FRESNO" },
  "93729": { state: "CA", city: "FRESNO" },
  "93730": { state: "CA", city: "FRESNO" },
  "93737": { state: "CA", city: "FRESNO" },
  "93740": { state: "CA", city: "FRESNO" },
  "93741": { state: "CA", city: "FRESNO" },
  "93744": { state: "CA", city: "FRESNO" },
  "93745": { state: "CA", city: "FRESNO" },
  "93747": { state: "CA", city: "FRESNO" },
  "93750": { state: "CA", city: "FRESNO" },
  "93755": { state: "CA", city: "FRESNO" },
  "93760": { state: "CA", city: "FRESNO" },
  "93761": { state: "CA", city: "FRESNO" },
  "93764": { state: "CA", city: "FRESNO" },
  "93765": { state: "CA", city: "FRESNO" },
  "93771": { state: "CA", city: "FRESNO" },
  "93772": { state: "CA", city: "FRESNO" },
  "93773": { state: "CA", city: "FRESNO" },
  "93774": { state: "CA", city: "FRESNO" },
  "93775": { state: "CA", city: "FRESNO" },
  "93776": { state: "CA", city: "FRESNO" },
  "93777": { state: "CA", city: "FRESNO" },
  "93778": { state: "CA", city: "FRESNO" },
  "93779": { state: "CA", city: "FRESNO" },
  "93786": { state: "CA", city: "FRESNO" },
  "93790": { state: "CA", city: "FRESNO" },
  "93791": { state: "CA", city: "FRESNO" },
  "93792": { state: "CA", city: "FRESNO" },
  "93793": { state: "CA", city: "FRESNO" },
  "93794": { state: "CA", city: "FRESNO" },
  "93844": { state: "CA", city: "FRESNO" },
  "93888": { state: "CA", city: "FRESNO" },
  "93901": { state: "CA", city: "SALINAS" },
  "93902": { state: "CA", city: "SALINAS" },
  "93905": { state: "CA", city: "SALINAS" },
  "93906": { state: "CA", city: "SALINAS" },
  "93907": { state: "CA", city: "SALINAS" },
  "93908": { state: "CA", city: "SALINAS" },
  "93912": { state: "CA", city: "SALINAS" },
  "93915": { state: "CA", city: "SALINAS" },
  "93920": { state: "CA", city: "BIG SUR" },
  "93921": { state: "CA", city: "CARMEL BY THE SEA" },
  "93922": { state: "CA", city: "CARMEL" },
  "93923": { state: "CA", city: "CARMEL" },
  "93924": { state: "CA", city: "CARMEL VALLEY" },
  "93925": { state: "CA", city: "CHUALAR" },
  "93926": { state: "CA", city: "GONZALES" },
  "93927": { state: "CA", city: "GREENFIELD" },
  "93928": { state: "CA", city: "JOLON" },
  "93930": { state: "CA", city: "KING CITY" },
  "93932": { state: "CA", city: "LOCKWOOD" },
  "93933": { state: "CA", city: "MARINA" },
  "93940": { state: "CA", city: "MONTEREY" },
  "93942": { state: "CA", city: "MONTEREY" },
  "93943": { state: "CA", city: "MONTEREY" },
  "93944": { state: "CA", city: "MONTEREY" },
  "93950": { state: "CA", city: "PACIFIC GROVE" },
  "93953": { state: "CA", city: "PEBBLE BEACH" },
  "93954": { state: "CA", city: "SAN LUCAS" },
  "93955": { state: "CA", city: "SEASIDE" },
  "93960": { state: "CA", city: "SOLEDAD" },
  "93962": { state: "CA", city: "SPRECKELS" },
  "94002": { state: "CA", city: "BELMONT" },
  "94005": { state: "CA", city: "BRISBANE" },
  "94010": { state: "CA", city: "BURLINGAME" },
  "94011": { state: "CA", city: "BURLINGAME" },
  "94014": { state: "CA", city: "DALY CITY" },
  "94015": { state: "CA", city: "DALY CITY" },
  "94016": { state: "CA", city: "DALY CITY" },
  "94017": { state: "CA", city: "DALY CITY" },
  "94018": { state: "CA", city: "EL GRANADA" },
  "94019": { state: "CA", city: "HALF MOON BAY" },
  "94020": { state: "CA", city: "LA HONDA" },
  "94021": { state: "CA", city: "LOMA MAR" },
  "94022": { state: "CA", city: "LOS ALTOS" },
  "94023": { state: "CA", city: "LOS ALTOS" },
  "94024": { state: "CA", city: "LOS ALTOS" },
  "94025": { state: "CA", city: "MENLO PARK" },
  "94026": { state: "CA", city: "MENLO PARK" },
  "94027": { state: "CA", city: "ATHERTON" },
  "94028": { state: "CA", city: "PORTOLA VALLEY" },
  "94030": { state: "CA", city: "MILLBRAE" },
  "94035": { state: "CA", city: "MOUNTAIN VIEW" },
  "94037": { state: "CA", city: "MONTARA" },
  "94038": { state: "CA", city: "MOSS BEACH" },
  "94039": { state: "CA", city: "MOUNTAIN VIEW" },
  "94040": { state: "CA", city: "MOUNTAIN VIEW" },
  "94041": { state: "CA", city: "MOUNTAIN VIEW" },
  "94042": { state: "CA", city: "MOUNTAIN VIEW" },
  "94043": { state: "CA", city: "MOUNTAIN VIEW" },
  "94044": { state: "CA", city: "PACIFICA" },
  "94060": { state: "CA", city: "PESCADERO" },
  "94061": { state: "CA", city: "REDWOOD CITY" },
  "94062": { state: "CA", city: "REDWOOD CITY" },
  "94063": { state: "CA", city: "REDWOOD CITY" },
  "94064": { state: "CA", city: "REDWOOD CITY" },
  "94065": { state: "CA", city: "REDWOOD CITY" },
  "94066": { state: "CA", city: "SAN BRUNO" },
  "94070": { state: "CA", city: "SAN CARLOS" },
  "94074": { state: "CA", city: "SAN GREGORIO" },
  "94080": { state: "CA", city: "SOUTH SAN FRANCISCO" },
  "94083": { state: "CA", city: "SOUTH SAN FRANCISCO" },
  "94085": { state: "CA", city: "SUNNYVALE" },
  "94086": { state: "CA", city: "SUNNYVALE" },
  "94087": { state: "CA", city: "SUNNYVALE" },
  "94088": { state: "CA", city: "SUNNYVALE" },
  "94089": { state: "CA", city: "SUNNYVALE" },
  "94102": { state: "CA", city: "SAN FRANCISCO" },
  "94103": { state: "CA", city: "SAN FRANCISCO" },
  "94104": { state: "CA", city: "SAN FRANCISCO" },
  "94105": { state: "CA", city: "SAN FRANCISCO" },
  "94107": { state: "CA", city: "SAN FRANCISCO" },
  "94108": { state: "CA", city: "SAN FRANCISCO" },
  "94109": { state: "CA", city: "SAN FRANCISCO" },
  "94110": { state: "CA", city: "SAN FRANCISCO" },
  "94111": { state: "CA", city: "SAN FRANCISCO" },
  "94112": { state: "CA", city: "SAN FRANCISCO" },
  "94114": { state: "CA", city: "SAN FRANCISCO" },
  "94115": { state: "CA", city: "SAN FRANCISCO" },
  "94116": { state: "CA", city: "SAN FRANCISCO" },
  "94117": { state: "CA", city: "SAN FRANCISCO" },
  "94118": { state: "CA", city: "SAN FRANCISCO" },
  "94119": { state: "CA", city: "SAN FRANCISCO" },
  "94120": { state: "CA", city: "SAN FRANCISCO" },
  "94121": { state: "CA", city: "SAN FRANCISCO" },
  "94122": { state: "CA", city: "SAN FRANCISCO" },
  "94123": { state: "CA", city: "SAN FRANCISCO" },
  "94124": { state: "CA", city: "SAN FRANCISCO" },
  "94125": { state: "CA", city: "SAN FRANCISCO" },
  "94126": { state: "CA", city: "SAN FRANCISCO" },
  "94127": { state: "CA", city: "SAN FRANCISCO" },
  "94128": { state: "CA", city: "SAN FRANCISCO" },
  "94129": { state: "CA", city: "SAN FRANCISCO" },
  "94130": { state: "CA", city: "SAN FRANCISCO" },
  "94131": { state: "CA", city: "SAN FRANCISCO" },
  "94132": { state: "CA", city: "SAN FRANCISCO" },
  "94133": { state: "CA", city: "SAN FRANCISCO" },
  "94134": { state: "CA", city: "SAN FRANCISCO" },
  "94137": { state: "CA", city: "SAN FRANCISCO" },
  "94139": { state: "CA", city: "SAN FRANCISCO" },
  "94140": { state: "CA", city: "SAN FRANCISCO" },
  "94141": { state: "CA", city: "SAN FRANCISCO" },
  "94142": { state: "CA", city: "SAN FRANCISCO" },
  "94143": { state: "CA", city: "SAN FRANCISCO" },
  "94144": { state: "CA", city: "SAN FRANCISCO" },
  "94145": { state: "CA", city: "SAN FRANCISCO" },
  "94146": { state: "CA", city: "SAN FRANCISCO" },
  "94147": { state: "CA", city: "SAN FRANCISCO" },
  "94151": { state: "CA", city: "SAN FRANCISCO" },
  "94158": { state: "CA", city: "SAN FRANCISCO" },
  "94159": { state: "CA", city: "SAN FRANCISCO" },
  "94160": { state: "CA", city: "SAN FRANCISCO" },
  "94161": { state: "CA", city: "SAN FRANCISCO" },
  "94163": { state: "CA", city: "SAN FRANCISCO" },
  "94164": { state: "CA", city: "SAN FRANCISCO" },
  "94172": { state: "CA", city: "SAN FRANCISCO" },
  "94177": { state: "CA", city: "SAN FRANCISCO" },
  "94188": { state: "CA", city: "SAN FRANCISCO" },
  "94203": { state: "CA", city: "SACRAMENTO" },
  "94204": { state: "CA", city: "SACRAMENTO" },
  "94205": { state: "CA", city: "SACRAMENTO" },
  "94206": { state: "CA", city: "SACRAMENTO" },
  "94207": { state: "CA", city: "SACRAMENTO" },
  "94208": { state: "CA", city: "SACRAMENTO" },
  "94209": { state: "CA", city: "SACRAMENTO" },
  "94211": { state: "CA", city: "SACRAMENTO" },
  "94229": { state: "CA", city: "SACRAMENTO" },
  "94230": { state: "CA", city: "SACRAMENTO" },
  "94232": { state: "CA", city: "SACRAMENTO" },
  "94234": { state: "CA", city: "SACRAMENTO" },
  "94235": { state: "CA", city: "SACRAMENTO" },
  "94236": { state: "CA", city: "SACRAMENTO" },
  "94237": { state: "CA", city: "SACRAMENTO" },
  "94239": { state: "CA", city: "SACRAMENTO" },
  "94240": { state: "CA", city: "SACRAMENTO" },
  "94244": { state: "CA", city: "SACRAMENTO" },
  "94245": { state: "CA", city: "SACRAMENTO" },
  "94247": { state: "CA", city: "SACRAMENTO" },
  "94248": { state: "CA", city: "SACRAMENTO" },
  "94249": { state: "CA", city: "SACRAMENTO" },
  "94250": { state: "CA", city: "SACRAMENTO" },
  "94252": { state: "CA", city: "SACRAMENTO" },
  "94254": { state: "CA", city: "SACRAMENTO" },
  "94256": { state: "CA", city: "SACRAMENTO" },
  "94257": { state: "CA", city: "SACRAMENTO" },
  "94258": { state: "CA", city: "SACRAMENTO" },
  "94259": { state: "CA", city: "SACRAMENTO" },
  "94261": { state: "CA", city: "SACRAMENTO" },
  "94262": { state: "CA", city: "SACRAMENTO" },
  "94263": { state: "CA", city: "SACRAMENTO" },
  "94267": { state: "CA", city: "SACRAMENTO" },
  "94268": { state: "CA", city: "SACRAMENTO" },
  "94269": { state: "CA", city: "SACRAMENTO" },
  "94271": { state: "CA", city: "SACRAMENTO" },
  "94273": { state: "CA", city: "SACRAMENTO" },
  "94274": { state: "CA", city: "SACRAMENTO" },
  "94277": { state: "CA", city: "SACRAMENTO" },
  "94278": { state: "CA", city: "SACRAMENTO" },
  "94279": { state: "CA", city: "SACRAMENTO" },
  "94280": { state: "CA", city: "SACRAMENTO" },
  "94282": { state: "CA", city: "SACRAMENTO" },
  "94283": { state: "CA", city: "SACRAMENTO" },
  "94284": { state: "CA", city: "SACRAMENTO" },
  "94285": { state: "CA", city: "SACRAMENTO" },
  "94287": { state: "CA", city: "SACRAMENTO" },
  "94288": { state: "CA", city: "SACRAMENTO" },
  "94289": { state: "CA", city: "SACRAMENTO" },
  "94290": { state: "CA", city: "SACRAMENTO" },
  "94291": { state: "CA", city: "SACRAMENTO" },
  "94293": { state: "CA", city: "SACRAMENTO" },
  "94294": { state: "CA", city: "SACRAMENTO" },
  "94295": { state: "CA", city: "SACRAMENTO" },
  "94296": { state: "CA", city: "SACRAMENTO" },
  "94297": { state: "CA", city: "SACRAMENTO" },
  "94298": { state: "CA", city: "SACRAMENTO" },
  "94299": { state: "CA", city: "SACRAMENTO" },
  "94301": { state: "CA", city: "PALO ALTO" },
  "94302": { state: "CA", city: "PALO ALTO" },
  "94303": { state: "CA", city: "PALO ALTO" },
  "94304": { state: "CA", city: "PALO ALTO" },
  "94305": { state: "CA", city: "STANFORD" },
  "94306": { state: "CA", city: "PALO ALTO" },
  "94309": { state: "CA", city: "PALO ALTO" },
  "94401": { state: "CA", city: "SAN MATEO" },
  "94402": { state: "CA", city: "SAN MATEO" },
  "94403": { state: "CA", city: "SAN MATEO" },
  "94404": { state: "CA", city: "SAN MATEO" },
  "94497": { state: "CA", city: "SAN MATEO" },
  "94501": { state: "CA", city: "ALAMEDA" },
  "94502": { state: "CA", city: "ALAMEDA" },
  "94503": { state: "CA", city: "AMERICAN CANYON" },
  "94505": { state: "CA", city: "DISCOVERY BAY" },
  "94506": { state: "CA", city: "DANVILLE" },
  "94507": { state: "CA", city: "ALAMO" },
  "94508": { state: "CA", city: "ANGWIN" },
  "94509": { state: "CA", city: "ANTIOCH" },
  "94510": { state: "CA", city: "BENICIA" },
  "94511": { state: "CA", city: "BETHEL ISLAND" },
  "94512": { state: "CA", city: "BIRDS LANDING" },
  "94513": { state: "CA", city: "BRENTWOOD" },
  "94514": { state: "CA", city: "BYRON" },
  "94515": { state: "CA", city: "CALISTOGA" },
  "94516": { state: "CA", city: "CANYON" },
  "94517": { state: "CA", city: "CLAYTON" },
  "94518": { state: "CA", city: "CONCORD" },
  "94519": { state: "CA", city: "CONCORD" },
  "94520": { state: "CA", city: "CONCORD" },
  "94521": { state: "CA", city: "CONCORD" },
  "94522": { state: "CA", city: "CONCORD" },
  "94523": { state: "CA", city: "PLEASANT HILL" },
  "94524": { state: "CA", city: "CONCORD" },
  "94525": { state: "CA", city: "CROCKETT" },
  "94526": { state: "CA", city: "DANVILLE" },
  "94527": { state: "CA", city: "CONCORD" },
  "94528": { state: "CA", city: "DIABLO" },
  "94529": { state: "CA", city: "CONCORD" },
  "94530": { state: "CA", city: "EL CERRITO" },
  "94531": { state: "CA", city: "ANTIOCH" },
  "94533": { state: "CA", city: "FAIRFIELD" },
  "94534": { state: "CA", city: "FAIRFIELD" },
  "94535": { state: "CA", city: "TRAVIS AFB" },
  "94536": { state: "CA", city: "FREMONT" },
  "94537": { state: "CA", city: "FREMONT" },
  "94538": { state: "CA", city: "FREMONT" },
  "94539": { state: "CA", city: "FREMONT" },
  "94540": { state: "CA", city: "HAYWARD" },
  "94541": { state: "CA", city: "HAYWARD" },
  "94542": { state: "CA", city: "HAYWARD" },
  "94543": { state: "CA", city: "HAYWARD" },
  "94544": { state: "CA", city: "HAYWARD" },
  "94545": { state: "CA", city: "HAYWARD" },
  "94546": { state: "CA", city: "CASTRO VALLEY" },
  "94547": { state: "CA", city: "HERCULES" },
  "94548": { state: "CA", city: "KNIGHTSEN" },
  "94549": { state: "CA", city: "LAFAYETTE" },
  "94550": { state: "CA", city: "LIVERMORE" },
  "94551": { state: "CA", city: "LIVERMORE" },
  "94552": { state: "CA", city: "CASTRO VALLEY" },
  "94553": { state: "CA", city: "MARTINEZ" },
  "94555": { state: "CA", city: "FREMONT" },
  "94556": { state: "CA", city: "MORAGA" },
  "94557": { state: "CA", city: "HAYWARD" },
  "94558": { state: "CA", city: "NAPA" },
  "94559": { state: "CA", city: "NAPA" },
  "94560": { state: "CA", city: "NEWARK" },
  "94561": { state: "CA", city: "OAKLEY" },
  "94562": { state: "CA", city: "OAKVILLE" },
  "94563": { state: "CA", city: "ORINDA" },
  "94564": { state: "CA", city: "PINOLE" },
  "94565": { state: "CA", city: "PITTSBURG" },
  "94566": { state: "CA", city: "PLEASANTON" },
  "94567": { state: "CA", city: "POPE VALLEY" },
  "94568": { state: "CA", city: "DUBLIN" },
  "94569": { state: "CA", city: "PORT COSTA" },
  "94570": { state: "CA", city: "MORAGA" },
  "94571": { state: "CA", city: "RIO VISTA" },
  "94572": { state: "CA", city: "RODEO" },
  "94573": { state: "CA", city: "RUTHERFORD" },
  "94574": { state: "CA", city: "SAINT HELENA" },
  "94575": { state: "CA", city: "MORAGA" },
  "94576": { state: "CA", city: "DEER PARK" },
  "94577": { state: "CA", city: "SAN LEANDRO" },
  "94578": { state: "CA", city: "SAN LEANDRO" },
  "94579": { state: "CA", city: "SAN LEANDRO" },
  "94580": { state: "CA", city: "SAN LORENZO" },
  "94581": { state: "CA", city: "NAPA" },
  "94582": { state: "CA", city: "SAN RAMON" },
  "94583": { state: "CA", city: "SAN RAMON" },
  "94585": { state: "CA", city: "SUISUN CITY" },
  "94586": { state: "CA", city: "SUNOL" },
  "94587": { state: "CA", city: "UNION CITY" },
  "94588": { state: "CA", city: "PLEASANTON" },
  "94589": { state: "CA", city: "VALLEJO" },
  "94590": { state: "CA", city: "VALLEJO" },
  "94591": { state: "CA", city: "VALLEJO" },
  "94592": { state: "CA", city: "VALLEJO" },
  "94595": { state: "CA", city: "WALNUT CREEK" },
  "94596": { state: "CA", city: "WALNUT CREEK" },
  "94597": { state: "CA", city: "WALNUT CREEK" },
  "94598": { state: "CA", city: "WALNUT CREEK" },
  "94599": { state: "CA", city: "YOUNTVILLE" },
  "94601": { state: "CA", city: "OAKLAND" },
  "94602": { state: "CA", city: "OAKLAND" },
  "94603": { state: "CA", city: "OAKLAND" },
  "94604": { state: "CA", city: "OAKLAND" },
  "94605": { state: "CA", city: "OAKLAND" },
  "94606": { state: "CA", city: "OAKLAND" },
  "94607": { state: "CA", city: "OAKLAND" },
  "94608": { state: "CA", city: "EMERYVILLE" },
  "94609": { state: "CA", city: "OAKLAND" },
  "94610": { state: "CA", city: "OAKLAND" },
  "94611": { state: "CA", city: "OAKLAND" },
  "94612": { state: "CA", city: "OAKLAND" },
  "94613": { state: "CA", city: "OAKLAND" },
  "94614": { state: "CA", city: "OAKLAND" },
  "94615": { state: "CA", city: "OAKLAND" },
  "94617": { state: "CA", city: "OAKLAND" },
  "94618": { state: "CA", city: "OAKLAND" },
  "94619": { state: "CA", city: "OAKLAND" },
  "94620": { state: "CA", city: "PIEDMONT" },
  "94621": { state: "CA", city: "OAKLAND" },
  "94622": { state: "CA", city: "OAKLAND" },
  "94623": { state: "CA", city: "OAKLAND" },
  "94624": { state: "CA", city: "OAKLAND" },
  "94649": { state: "CA", city: "OAKLAND" },
  "94659": { state: "CA", city: "OAKLAND" },
  "94660": { state: "CA", city: "OAKLAND" },
  "94661": { state: "CA", city: "OAKLAND" },
  "94662": { state: "CA", city: "EMERYVILLE" },
  "94666": { state: "CA", city: "OAKLAND" },
  "94701": { state: "CA", city: "BERKELEY" },
  "94702": { state: "CA", city: "BERKELEY" },
  "94703": { state: "CA", city: "BERKELEY" },
  "94704": { state: "CA", city: "BERKELEY" },
  "94705": { state: "CA", city: "BERKELEY" },
  "94706": { state: "CA", city: "ALBANY" },
  "94707": { state: "CA", city: "BERKELEY" },
  "94708": { state: "CA", city: "BERKELEY" },
  "94709": { state: "CA", city: "BERKELEY" },
  "94710": { state: "CA", city: "BERKELEY" },
  "94712": { state: "CA", city: "BERKELEY" },
  "94720": { state: "CA", city: "BERKELEY" },
  "94801": { state: "CA", city: "RICHMOND" },
  "94802": { state: "CA", city: "RICHMOND" },
  "94803": { state: "CA", city: "EL SOBRANTE" },
  "94804": { state: "CA", city: "RICHMOND" },
  "94805": { state: "CA", city: "RICHMOND" },
  "94806": { state: "CA", city: "SAN PABLO" },
  "94807": { state: "CA", city: "RICHMOND" },
  "94808": { state: "CA", city: "RICHMOND" },
  "94820": { state: "CA", city: "EL SOBRANTE" },
  "94850": { state: "CA", city: "RICHMOND" },
  "94901": { state: "CA", city: "SAN RAFAEL" },
  "94903": { state: "CA", city: "SAN RAFAEL" },
  "94904": { state: "CA", city: "GREENBRAE" },
  "94912": { state: "CA", city: "SAN RAFAEL" },
  "94913": { state: "CA", city: "SAN RAFAEL" },
  "94914": { state: "CA", city: "KENTFIELD" },
  "94915": { state: "CA", city: "SAN RAFAEL" },
  "94920": { state: "CA", city: "BELVEDERE TIBURON" },
  "94922": { state: "CA", city: "BODEGA" },
  "94923": { state: "CA", city: "BODEGA BAY" },
  "94924": { state: "CA", city: "BOLINAS" },
  "94925": { state: "CA", city: "CORTE MADERA" },
  "94926": { state: "CA", city: "ROHNERT PARK" },
  "94927": { state: "CA", city: "ROHNERT PARK" },
  "94928": { state: "CA", city: "ROHNERT PARK" },
  "94929": { state: "CA", city: "DILLON BEACH" },
  "94930": { state: "CA", city: "FAIRFAX" },
  "94931": { state: "CA", city: "COTATI" },
  "94933": { state: "CA", city: "FOREST KNOLLS" },
  "94937": { state: "CA", city: "INVERNESS" },
  "94938": { state: "CA", city: "LAGUNITAS" },
  "94939": { state: "CA", city: "LARKSPUR" },
  "94940": { state: "CA", city: "MARSHALL" },
  "94941": { state: "CA", city: "MILL VALLEY" },
  "94942": { state: "CA", city: "MILL VALLEY" },
  "94945": { state: "CA", city: "NOVATO" },
  "94946": { state: "CA", city: "NICASIO" },
  "94947": { state: "CA", city: "NOVATO" },
  "94948": { state: "CA", city: "NOVATO" },
  "94949": { state: "CA", city: "NOVATO" },
  "94950": { state: "CA", city: "OLEMA" },
  "94951": { state: "CA", city: "PENNGROVE" },
  "94952": { state: "CA", city: "PETALUMA" },
  "94953": { state: "CA", city: "PETALUMA" },
  "94954": { state: "CA", city: "PETALUMA" },
  "94955": { state: "CA", city: "PETALUMA" },
  "94956": { state: "CA", city: "POINT REYES STATION" },
  "94957": { state: "CA", city: "ROSS" },
  "94960": { state: "CA", city: "SAN ANSELMO" },
  "94963": { state: "CA", city: "SAN GERONIMO" },
  "94964": { state: "CA", city: "SAN QUENTIN" },
  "94965": { state: "CA", city: "SAUSALITO" },
  "94966": { state: "CA", city: "SAUSALITO" },
  "94970": { state: "CA", city: "STINSON BEACH" },
  "94971": { state: "CA", city: "TOMALES" },
  "94972": { state: "CA", city: "VALLEY FORD" },
  "94973": { state: "CA", city: "WOODACRE" },
  "94974": { state: "CA", city: "SAN QUENTIN" },
  "94975": { state: "CA", city: "PETALUMA" },
  "94976": { state: "CA", city: "CORTE MADERA" },
  "94977": { state: "CA", city: "LARKSPUR" },
  "94978": { state: "CA", city: "FAIRFAX" },
  "94979": { state: "CA", city: "SAN ANSELMO" },
  "94998": { state: "CA", city: "NOVATO" },
  "94999": { state: "CA", city: "PETALUMA" },
  "95001": { state: "CA", city: "APTOS" },
  "95002": { state: "CA", city: "ALVISO" },
  "95003": { state: "CA", city: "APTOS" },
  "95004": { state: "CA", city: "AROMAS" },
  "95005": { state: "CA", city: "BEN LOMOND" },
  "95006": { state: "CA", city: "BOULDER CREEK" },
  "95007": { state: "CA", city: "BROOKDALE" },
  "95008": { state: "CA", city: "CAMPBELL" },
  "95009": { state: "CA", city: "CAMPBELL" },
  "95010": { state: "CA", city: "CAPITOLA" },
  "95011": { state: "CA", city: "CAMPBELL" },
  "95012": { state: "CA", city: "CASTROVILLE" },
  "95013": { state: "CA", city: "COYOTE" },
  "95014": { state: "CA", city: "CUPERTINO" },
  "95015": { state: "CA", city: "CUPERTINO" },
  "95017": { state: "CA", city: "DAVENPORT" },
  "95018": { state: "CA", city: "FELTON" },
  "95019": { state: "CA", city: "FREEDOM" },
  "95020": { state: "CA", city: "GILROY" },
  "95021": { state: "CA", city: "GILROY" },
  "95023": { state: "CA", city: "HOLLISTER" },
  "95024": { state: "CA", city: "HOLLISTER" },
  "95026": { state: "CA", city: "HOLY CITY" },
  "95030": { state: "CA", city: "LOS GATOS" },
  "95031": { state: "CA", city: "LOS GATOS" },
  "95032": { state: "CA", city: "LOS GATOS" },
  "95033": { state: "CA", city: "LOS GATOS" },
  "95035": { state: "CA", city: "MILPITAS" },
  "95036": { state: "CA", city: "MILPITAS" },
  "95037": { state: "CA", city: "MORGAN HILL" },
  "95038": { state: "CA", city: "MORGAN HILL" },
  "95039": { state: "CA", city: "MOSS LANDING" },
  "95041": { state: "CA", city: "MOUNT HERMON" },
  "95042": { state: "CA", city: "NEW ALMADEN" },
  "95043": { state: "CA", city: "PAICINES" },
  "95044": { state: "CA", city: "REDWOOD ESTATES" },
  "95045": { state: "CA", city: "SAN JUAN BAUTISTA" },
  "95046": { state: "CA", city: "SAN MARTIN" },
  "95050": { state: "CA", city: "SANTA CLARA" },
  "95051": { state: "CA", city: "SANTA CLARA" },
  "95052": { state: "CA", city: "SANTA CLARA" },
  "95053": { state: "CA", city: "SANTA CLARA" },
  "95054": { state: "CA", city: "SANTA CLARA" },
  "95055": { state: "CA", city: "SANTA CLARA" },
  "95056": { state: "CA", city: "SANTA CLARA" },
  "95060": { state: "CA", city: "SANTA CRUZ" },
  "95061": { state: "CA", city: "SANTA CRUZ" },
  "95062": { state: "CA", city: "SANTA CRUZ" },
  "95063": { state: "CA", city: "SANTA CRUZ" },
  "95064": { state: "CA", city: "SANTA CRUZ" },
  "95065": { state: "CA", city: "SANTA CRUZ" },
  "95066": { state: "CA", city: "SCOTTS VALLEY" },
  "95067": { state: "CA", city: "SCOTTS VALLEY" },
  "95070": { state: "CA", city: "SARATOGA" },
  "95071": { state: "CA", city: "SARATOGA" },
  "95073": { state: "CA", city: "SOQUEL" },
  "95075": { state: "CA", city: "TRES PINOS" },
  "95076": { state: "CA", city: "WATSONVILLE" },
  "95077": { state: "CA", city: "WATSONVILLE" },
  "95101": { state: "CA", city: "SAN JOSE" },
  "95103": { state: "CA", city: "SAN JOSE" },
  "95106": { state: "CA", city: "SAN JOSE" },
  "95108": { state: "CA", city: "SAN JOSE" },
  "95109": { state: "CA", city: "SAN JOSE" },
  "95110": { state: "CA", city: "SAN JOSE" },
  "95111": { state: "CA", city: "SAN JOSE" },
  "95112": { state: "CA", city: "SAN JOSE" },
  "95113": { state: "CA", city: "SAN JOSE" },
  "95115": { state: "CA", city: "SAN JOSE" },
  "95116": { state: "CA", city: "SAN JOSE" },
  "95117": { state: "CA", city: "SAN JOSE" },
  "95118": { state: "CA", city: "SAN JOSE" },
  "95119": { state: "CA", city: "SAN JOSE" },
  "95120": { state: "CA", city: "SAN JOSE" },
  "95121": { state: "CA", city: "SAN JOSE" },
  "95122": { state: "CA", city: "SAN JOSE" },
  "95123": { state: "CA", city: "SAN JOSE" },
  "95124": { state: "CA", city: "SAN JOSE" },
  "95125": { state: "CA", city: "SAN JOSE" },
  "95126": { state: "CA", city: "SAN JOSE" },
  "95127": { state: "CA", city: "SAN JOSE" },
  "95128": { state: "CA", city: "SAN JOSE" },
  "95129": { state: "CA", city: "SAN JOSE" },
  "95130": { state: "CA", city: "SAN JOSE" },
  "95131": { state: "CA", city: "SAN JOSE" },
  "95132": { state: "CA", city: "SAN JOSE" },
  "95133": { state: "CA", city: "SAN JOSE" },
  "95134": { state: "CA", city: "SAN JOSE" },
  "95135": { state: "CA", city: "SAN JOSE" },
  "95136": { state: "CA", city: "SAN JOSE" },
  "95138": { state: "CA", city: "SAN JOSE" },
  "95139": { state: "CA", city: "SAN JOSE" },
  "95140": { state: "CA", city: "MOUNT HAMILTON" },
  "95141": { state: "CA", city: "SAN JOSE" },
  "95148": { state: "CA", city: "SAN JOSE" },
  "95150": { state: "CA", city: "SAN JOSE" },
  "95151": { state: "CA", city: "SAN JOSE" },
  "95152": { state: "CA", city: "SAN JOSE" },
  "95153": { state: "CA", city: "SAN JOSE" },
  "95154": { state: "CA", city: "SAN JOSE" },
  "95155": { state: "CA", city: "SAN JOSE" },
  "95156": { state: "CA", city: "SAN JOSE" },
  "95157": { state: "CA", city: "SAN JOSE" },
  "95158": { state: "CA", city: "SAN JOSE" },
  "95159": { state: "CA", city: "SAN JOSE" },
  "95160": { state: "CA", city: "SAN JOSE" },
  "95161": { state: "CA", city: "SAN JOSE" },
  "95164": { state: "CA", city: "SAN JOSE" },
  "95170": { state: "CA", city: "SAN JOSE" },
  "95172": { state: "CA", city: "SAN JOSE" },
  "95173": { state: "CA", city: "SAN JOSE" },
  "95190": { state: "CA", city: "SAN JOSE" },
  "95191": { state: "CA", city: "SAN JOSE" },
  "95192": { state: "CA", city: "SAN JOSE" },
  "95193": { state: "CA", city: "SAN JOSE" },
  "95194": { state: "CA", city: "SAN JOSE" },
  "95196": { state: "CA", city: "SAN JOSE" },
  "95201": { state: "CA", city: "STOCKTON" },
  "95202": { state: "CA", city: "STOCKTON" },
  "95203": { state: "CA", city: "STOCKTON" },
  "95204": { state: "CA", city: "STOCKTON" },
  "95205": { state: "CA", city: "STOCKTON" },
  "95206": { state: "CA", city: "STOCKTON" },
  "95207": { state: "CA", city: "STOCKTON" },
  "95208": { state: "CA", city: "STOCKTON" },
  "95209": { state: "CA", city: "STOCKTON" },
  "95210": { state: "CA", city: "STOCKTON" },
  "95211": { state: "CA", city: "STOCKTON" },
  "95212": { state: "CA", city: "STOCKTON" },
  "95213": { state: "CA", city: "STOCKTON" },
  "95214": { state: "CA", city: "STOCKTON" },
  "95215": { state: "CA", city: "STOCKTON" },
  "95219": { state: "CA", city: "STOCKTON" },
  "95220": { state: "CA", city: "ACAMPO" },
  "95221": { state: "CA", city: "ALTAVILLE" },
  "95222": { state: "CA", city: "ANGELS CAMP" },
  "95223": { state: "CA", city: "ARNOLD" },
  "95224": { state: "CA", city: "AVERY" },
  "95225": { state: "CA", city: "BURSON" },
  "95226": { state: "CA", city: "CAMPO SECO" },
  "95227": { state: "CA", city: "CLEMENTS" },
  "95228": { state: "CA", city: "COPPEROPOLIS" },
  "95229": { state: "CA", city: "DOUGLAS FLAT" },
  "95230": { state: "CA", city: "FARMINGTON" },
  "95231": { state: "CA", city: "FRENCH CAMP" },
  "95232": { state: "CA", city: "GLENCOE" },
  "95233": { state: "CA", city: "HATHAWAY PINES" },
  "95234": { state: "CA", city: "HOLT" },
  "95236": { state: "CA", city: "LINDEN" },
  "95237": { state: "CA", city: "LOCKEFORD" },
  "95240": { state: "CA", city: "LODI" },
  "95241": { state: "CA", city: "LODI" },
  "95242": { state: "CA", city: "LODI" },
  "95245": { state: "CA", city: "MOKELUMNE HILL" },
  "95246": { state: "CA", city: "MOUNTAIN RANCH" },
  "95247": { state: "CA", city: "MURPHYS" },
  "95248": { state: "CA", city: "RAIL ROAD FLAT" },
  "95249": { state: "CA", city: "SAN ANDREAS" },
  "95251": { state: "CA", city: "VALLECITO" },
  "95252": { state: "CA", city: "VALLEY SPRINGS" },
  "95253": { state: "CA", city: "VICTOR" },
  "95254": { state: "CA", city: "WALLACE" },
  "95255": { state: "CA", city: "WEST POINT" },
  "95257": { state: "CA", city: "WILSEYVILLE" },
  "95258": { state: "CA", city: "WOODBRIDGE" },
  "95267": { state: "CA", city: "STOCKTON" },
  "95269": { state: "CA", city: "STOCKTON" },
  "95296": { state: "CA", city: "STOCKTON" },
  "95297": { state: "CA", city: "STOCKTON" },
  "95301": { state: "CA", city: "ATWATER" },
  "95303": { state: "CA", city: "BALLICO" },
  "95304": { state: "CA", city: "TRACY" },
  "95305": { state: "CA", city: "BIG OAK FLAT" },
  "95306": { state: "CA", city: "CATHEYS VALLEY" },
  "95307": { state: "CA", city: "CERES" },
  "95309": { state: "CA", city: "CHINESE CAMP" },
  "95310": { state: "CA", city: "COLUMBIA" },
  "95311": { state: "CA", city: "COULTERVILLE" },
  "95312": { state: "CA", city: "CRESSEY" },
  "95313": { state: "CA", city: "CROWS LANDING" },
  "95315": { state: "CA", city: "DELHI" },
  "95316": { state: "CA", city: "DENAIR" },
  "95317": { state: "CA", city: "EL NIDO" },
  "95318": { state: "CA", city: "EL PORTAL" },
  "95319": { state: "CA", city: "EMPIRE" },
  "95320": { state: "CA", city: "ESCALON" },
  "95321": { state: "CA", city: "GROVELAND" },
  "95322": { state: "CA", city: "GUSTINE" },
  "95323": { state: "CA", city: "HICKMAN" },
  "95324": { state: "CA", city: "HILMAR" },
  "95325": { state: "CA", city: "HORNITOS" },
  "95326": { state: "CA", city: "HUGHSON" },
  "95327": { state: "CA", city: "JAMESTOWN" },
  "95328": { state: "CA", city: "KEYES" },
  "95329": { state: "CA", city: "LA GRANGE" },
  "95330": { state: "CA", city: "LATHROP" },
  "95333": { state: "CA", city: "LE GRAND" },
  "95334": { state: "CA", city: "LIVINGSTON" },
  "95335": { state: "CA", city: "LONG BARN" },
  "95336": { state: "CA", city: "MANTECA" },
  "95337": { state: "CA", city: "MANTECA" },
  "95338": { state: "CA", city: "MARIPOSA" },
  "95340": { state: "CA", city: "MERCED" },
  "95341": { state: "CA", city: "MERCED" },
  "95343": { state: "CA", city: "MERCED" },
  "95344": { state: "CA", city: "MERCED" },
  "95345": { state: "CA", city: "MIDPINES" },
  "95346": { state: "CA", city: "MI WUK VILLAGE" },
  "95347": { state: "CA", city: "MOCCASIN" },
  "95348": { state: "CA", city: "MERCED" },
  "95350": { state: "CA", city: "MODESTO" },
  "95351": { state: "CA", city: "MODESTO" },
  "95352": { state: "CA", city: "MODESTO" },
  "95353": { state: "CA", city: "MODESTO" },
  "95354": { state: "CA", city: "MODESTO" },
  "95355": { state: "CA", city: "MODESTO" },
  "95356": { state: "CA", city: "MODESTO" },
  "95357": { state: "CA", city: "MODESTO" },
  "95358": { state: "CA", city: "MODESTO" },
  "95360": { state: "CA", city: "NEWMAN" },
  "95361": { state: "CA", city: "OAKDALE" },
  "95363": { state: "CA", city: "PATTERSON" },
  "95364": { state: "CA", city: "PINECREST" },
  "95365": { state: "CA", city: "PLANADA" },
  "95366": { state: "CA", city: "RIPON" },
  "95367": { state: "CA", city: "RIVERBANK" },
  "95368": { state: "CA", city: "SALIDA" },
  "95369": { state: "CA", city: "SNELLING" },
  "95370": { state: "CA", city: "SONORA" },
  "95372": { state: "CA", city: "SOULSBYVILLE" },
  "95373": { state: "CA", city: "STANDARD" },
  "95374": { state: "CA", city: "STEVINSON" },
  "95375": { state: "CA", city: "STRAWBERRY" },
  "95376": { state: "CA", city: "TRACY" },
  "95377": { state: "CA", city: "TRACY" },
  "95378": { state: "CA", city: "TRACY" },
  "95379": { state: "CA", city: "TUOLUMNE" },
  "95380": { state: "CA", city: "TURLOCK" },
  "95381": { state: "CA", city: "TURLOCK" },
  "95382": { state: "CA", city: "TURLOCK" },
  "95383": { state: "CA", city: "TWAIN HARTE" },
  "95385": { state: "CA", city: "VERNALIS" },
  "95386": { state: "CA", city: "WATERFORD" },
  "95387": { state: "CA", city: "WESTLEY" },
  "95388": { state: "CA", city: "WINTON" },
  "95389": { state: "CA", city: "YOSEMITE NATIONAL PARK" },
  "95391": { state: "CA", city: "TRACY" },
  "95397": { state: "CA", city: "MODESTO" },
  "95401": { state: "CA", city: "SANTA ROSA" },
  "95402": { state: "CA", city: "SANTA ROSA" },
  "95403": { state: "CA", city: "SANTA ROSA" },
  "95404": { state: "CA", city: "SANTA ROSA" },
  "95405": { state: "CA", city: "SANTA ROSA" },
  "95406": { state: "CA", city: "SANTA ROSA" },
  "95407": { state: "CA", city: "SANTA ROSA" },
  "95409": { state: "CA", city: "SANTA ROSA" },
  "95410": { state: "CA", city: "ALBION" },
  "95412": { state: "CA", city: "ANNAPOLIS" },
  "95415": { state: "CA", city: "BOONVILLE" },
  "95416": { state: "CA", city: "BOYES HOT SPRINGS" },
  "95417": { state: "CA", city: "BRANSCOMB" },
  "95418": { state: "CA", city: "CALPELLA" },
  "95419": { state: "CA", city: "CAMP MEEKER" },
  "95420": { state: "CA", city: "CASPAR" },
  "95421": { state: "CA", city: "CAZADERO" },
  "95422": { state: "CA", city: "CLEARLAKE" },
  "95423": { state: "CA", city: "CLEARLAKE OAKS" },
  "95424": { state: "CA", city: "CLEARLAKE PARK" },
  "95425": { state: "CA", city: "CLOVERDALE" },
  "95426": { state: "CA", city: "COBB" },
  "95427": { state: "CA", city: "COMPTCHE" },
  "95428": { state: "CA", city: "COVELO" },
  "95429": { state: "CA", city: "DOS RIOS" },
  "95430": { state: "CA", city: "DUNCANS MILLS" },
  "95431": { state: "CA", city: "ELDRIDGE" },
  "95432": { state: "CA", city: "ELK" },
  "95433": { state: "CA", city: "EL VERANO" },
  "95435": { state: "CA", city: "FINLEY" },
  "95436": { state: "CA", city: "FORESTVILLE" },
  "95437": { state: "CA", city: "FORT BRAGG" },
  "95439": { state: "CA", city: "FULTON" },
  "95441": { state: "CA", city: "GEYSERVILLE" },
  "95442": { state: "CA", city: "GLEN ELLEN" },
  "95443": { state: "CA", city: "GLENHAVEN" },
  "95444": { state: "CA", city: "GRATON" },
  "95445": { state: "CA", city: "GUALALA" },
  "95446": { state: "CA", city: "GUERNEVILLE" },
  "95448": { state: "CA", city: "HEALDSBURG" },
  "95449": { state: "CA", city: "HOPLAND" },
  "95450": { state: "CA", city: "JENNER" },
  "95451": { state: "CA", city: "KELSEYVILLE" },
  "95452": { state: "CA", city: "KENWOOD" },
  "95453": { state: "CA", city: "LAKEPORT" },
  "95454": { state: "CA", city: "LAYTONVILLE" },
  "95456": { state: "CA", city: "LITTLE RIVER" },
  "95457": { state: "CA", city: "LOWER LAKE" },
  "95458": { state: "CA", city: "LUCERNE" },
  "95459": { state: "CA", city: "MANCHESTER" },
  "95460": { state: "CA", city: "MENDOCINO" },
  "95461": { state: "CA", city: "MIDDLETOWN" },
  "95462": { state: "CA", city: "MONTE RIO" },
  "95463": { state: "CA", city: "NAVARRO" },
  "95464": { state: "CA", city: "NICE" },
  "95465": { state: "CA", city: "OCCIDENTAL" },
  "95466": { state: "CA", city: "PHILO" },
  "95467": { state: "CA", city: "HIDDEN VALLEY LAKE" },
  "95468": { state: "CA", city: "POINT ARENA" },
  "95469": { state: "CA", city: "POTTER VALLEY" },
  "95470": { state: "CA", city: "REDWOOD VALLEY" },
  "95471": { state: "CA", city: "RIO NIDO" },
  "95472": { state: "CA", city: "SEBASTOPOL" },
  "95473": { state: "CA", city: "SEBASTOPOL" },
  "95476": { state: "CA", city: "SONOMA" },
  "95480": { state: "CA", city: "STEWARTS POINT" },
  "95481": { state: "CA", city: "TALMAGE" },
  "95482": { state: "CA", city: "UKIAH" },
  "95485": { state: "CA", city: "UPPER LAKE" },
  "95486": { state: "CA", city: "VILLA GRANDE" },
  "95487": { state: "CA", city: "VINEBURG" },
  "95488": { state: "CA", city: "WESTPORT" },
  "95490": { state: "CA", city: "WILLITS" },
  "95492": { state: "CA", city: "WINDSOR" },
  "95493": { state: "CA", city: "WITTER SPRINGS" },
  "95494": { state: "CA", city: "YORKVILLE" },
  "95497": { state: "CA", city: "THE SEA RANCH" },
  "95501": { state: "CA", city: "EUREKA" },
  "95502": { state: "CA", city: "EUREKA" },
  "95503": { state: "CA", city: "EUREKA" },
  "95511": { state: "CA", city: "ALDERPOINT" },
  "95514": { state: "CA", city: "BLOCKSBURG" },
  "95518": { state: "CA", city: "ARCATA" },
  "95519": { state: "CA", city: "MCKINLEYVILLE" },
  "95521": { state: "CA", city: "ARCATA" },
  "95524": { state: "CA", city: "BAYSIDE" },
  "95525": { state: "CA", city: "BLUE LAKE" },
  "95526": { state: "CA", city: "BRIDGEVILLE" },
  "95527": { state: "CA", city: "BURNT RANCH" },
  "95528": { state: "CA", city: "CARLOTTA" },
  "95531": { state: "CA", city: "CRESCENT CITY" },
  "95532": { state: "CA", city: "CRESCENT CITY" },
  "95534": { state: "CA", city: "CUTTEN" },
  "95536": { state: "CA", city: "FERNDALE" },
  "95537": { state: "CA", city: "FIELDS LANDING" },
  "95538": { state: "CA", city: "FORT DICK" },
  "95540": { state: "CA", city: "FORTUNA" },
  "95542": { state: "CA", city: "GARBERVILLE" },
  "95543": { state: "CA", city: "GASQUET" },
  "95545": { state: "CA", city: "HONEYDEW" },
  "95546": { state: "CA", city: "HOOPA" },
  "95547": { state: "CA", city: "HYDESVILLE" },
  "95548": { state: "CA", city: "KLAMATH" },
  "95549": { state: "CA", city: "KNEELAND" },
  "95550": { state: "CA", city: "KORBEL" },
  "95551": { state: "CA", city: "LOLETA" },
  "95552": { state: "CA", city: "MAD RIVER" },
  "95553": { state: "CA", city: "MIRANDA" },
  "95554": { state: "CA", city: "MYERS FLAT" },
  "95555": { state: "CA", city: "ORICK" },
  "95556": { state: "CA", city: "ORLEANS" },
  "95558": { state: "CA", city: "PETROLIA" },
  "95559": { state: "CA", city: "PHILLIPSVILLE" },
  "95560": { state: "CA", city: "REDWAY" },
  "95562": { state: "CA", city: "RIO DELL" },
  "95563": { state: "CA", city: "SALYER" },
  "95564": { state: "CA", city: "SAMOA" },
  "95565": { state: "CA", city: "SCOTIA" },
  "95567": { state: "CA", city: "SMITH RIVER" },
  "95568": { state: "CA", city: "SOMES BAR" },
  "95569": { state: "CA", city: "REDCREST" },
  "95570": { state: "CA", city: "TRINIDAD" },
  "95571": { state: "CA", city: "WEOTT" },
  "95573": { state: "CA", city: "WILLOW CREEK" },
  "95585": { state: "CA", city: "LEGGETT" },
  "95587": { state: "CA", city: "PIERCY" },
  "95589": { state: "CA", city: "WHITETHORN" },
  "95595": { state: "CA", city: "ZENIA" },
  "95601": { state: "CA", city: "AMADOR CITY" },
  "95602": { state: "CA", city: "AUBURN" },
  "95603": { state: "CA", city: "AUBURN" },
  "95604": { state: "CA", city: "AUBURN" },
  "95605": { state: "CA", city: "WEST SACRAMENTO" },
  "95606": { state: "CA", city: "BROOKS" },
  "95607": { state: "CA", city: "CAPAY" },
  "95608": { state: "CA", city: "CARMICHAEL" },
  "95609": { state: "CA", city: "CARMICHAEL" },
  "95610": { state: "CA", city: "CITRUS HEIGHTS" },
  "95611": { state: "CA", city: "CITRUS HEIGHTS" },
  "95612": { state: "CA", city: "CLARKSBURG" },
  "95613": { state: "CA", city: "COLOMA" },
  "95614": { state: "CA", city: "COOL" },
  "95615": { state: "CA", city: "COURTLAND" },
  "95616": { state: "CA", city: "DAVIS" },
  "95617": { state: "CA", city: "DAVIS" },
  "95618": { state: "CA", city: "DAVIS" },
  "95619": { state: "CA", city: "DIAMOND SPRINGS" },
  "95620": { state: "CA", city: "DIXON" },
  "95621": { state: "CA", city: "CITRUS HEIGHTS" },
  "95623": { state: "CA", city: "EL DORADO" },
  "95624": { state: "CA", city: "ELK GROVE" },
  "95625": { state: "CA", city: "ELMIRA" },
  "95626": { state: "CA", city: "ELVERTA" },
  "95627": { state: "CA", city: "ESPARTO" },
  "95628": { state: "CA", city: "FAIR OAKS" },
  "95629": { state: "CA", city: "FIDDLETOWN" },
  "95630": { state: "CA", city: "FOLSOM" },
  "95631": { state: "CA", city: "FORESTHILL" },
  "95632": { state: "CA", city: "GALT" },
  "95633": { state: "CA", city: "GARDEN VALLEY" },
  "95634": { state: "CA", city: "GEORGETOWN" },
  "95635": { state: "CA", city: "GREENWOOD" },
  "95636": { state: "CA", city: "GRIZZLY FLATS" },
  "95637": { state: "CA", city: "GUINDA" },
  "95638": { state: "CA", city: "HERALD" },
  "95639": { state: "CA", city: "HOOD" },
  "95640": { state: "CA", city: "IONE" },
  "95641": { state: "CA", city: "ISLETON" },
  "95642": { state: "CA", city: "JACKSON" },
  "95644": { state: "CA", city: "KIT CARSON" },
  "95645": { state: "CA", city: "KNIGHTS LANDING" },
  "95646": { state: "CA", city: "KIRKWOOD" },
  "95648": { state: "CA", city: "LINCOLN" },
  "95650": { state: "CA", city: "LOOMIS" },
  "95651": { state: "CA", city: "LOTUS" },
  "95652": { state: "CA", city: "MCCLELLAN" },
  "95653": { state: "CA", city: "MADISON" },
  "95654": { state: "CA", city: "MARTELL" },
  "95655": { state: "CA", city: "MATHER" },
  "95656": { state: "CA", city: "MOUNT AUKUM" },
  "95658": { state: "CA", city: "NEWCASTLE" },
  "95659": { state: "CA", city: "NICOLAUS" },
  "95660": { state: "CA", city: "NORTH HIGHLANDS" },
  "95661": { state: "CA", city: "ROSEVILLE" },
  "95662": { state: "CA", city: "ORANGEVALE" },
  "95663": { state: "CA", city: "PENRYN" },
  "95664": { state: "CA", city: "PILOT HILL" },
  "95665": { state: "CA", city: "PINE GROVE" },
  "95666": { state: "CA", city: "PIONEER" },
  "95667": { state: "CA", city: "PLACERVILLE" },
  "95668": { state: "CA", city: "PLEASANT GROVE" },
  "95669": { state: "CA", city: "PLYMOUTH" },
  "95670": { state: "CA", city: "RANCHO CORDOVA" },
  "95671": { state: "CA", city: "REPRESA" },
  "95672": { state: "CA", city: "RESCUE" },
  "95673": { state: "CA", city: "RIO LINDA" },
  "95674": { state: "CA", city: "RIO OSO" },
  "95675": { state: "CA", city: "RIVER PINES" },
  "95676": { state: "CA", city: "ROBBINS" },
  "95677": { state: "CA", city: "ROCKLIN" },
  "95678": { state: "CA", city: "ROSEVILLE" },
  "95679": { state: "CA", city: "RUMSEY" },
  "95680": { state: "CA", city: "RYDE" },
  "95681": { state: "CA", city: "SHERIDAN" },
  "95682": { state: "CA", city: "SHINGLE SPRINGS" },
  "95683": { state: "CA", city: "SLOUGHHOUSE" },
  "95684": { state: "CA", city: "SOMERSET" },
  "95685": { state: "CA", city: "SUTTER CREEK" },
  "95686": { state: "CA", city: "THORNTON" },
  "95687": { state: "CA", city: "VACAVILLE" },
  "95688": { state: "CA", city: "VACAVILLE" },
  "95689": { state: "CA", city: "VOLCANO" },
  "95690": { state: "CA", city: "WALNUT GROVE" },
  "95691": { state: "CA", city: "WEST SACRAMENTO" },
  "95692": { state: "CA", city: "WHEATLAND" },
  "95693": { state: "CA", city: "WILTON" },
  "95694": { state: "CA", city: "WINTERS" },
  "95695": { state: "CA", city: "WOODLAND" },
  "95696": { state: "CA", city: "VACAVILLE" },
  "95697": { state: "CA", city: "YOLO" },
  "95698": { state: "CA", city: "ZAMORA" },
  "95699": { state: "CA", city: "DRYTOWN" },
  "95701": { state: "CA", city: "ALTA" },
  "95703": { state: "CA", city: "APPLEGATE" },
  "95709": { state: "CA", city: "CAMINO" },
  "95712": { state: "CA", city: "CHICAGO PARK" },
  "95713": { state: "CA", city: "COLFAX" },
  "95714": { state: "CA", city: "DUTCH FLAT" },
  "95715": { state: "CA", city: "EMIGRANT GAP" },
  "95717": { state: "CA", city: "GOLD RUN" },
  "95720": { state: "CA", city: "KYBURZ" },
  "95721": { state: "CA", city: "ECHO LAKE" },
  "95722": { state: "CA", city: "MEADOW VISTA" },
  "95724": { state: "CA", city: "NORDEN" },
  "95726": { state: "CA", city: "POLLOCK PINES" },
  "95728": { state: "CA", city: "SODA SPRINGS" },
  "95735": { state: "CA", city: "TWIN BRIDGES" },
  "95736": { state: "CA", city: "WEIMAR" },
  "95741": { state: "CA", city: "RANCHO CORDOVA" },
  "95742": { state: "CA", city: "RANCHO CORDOVA" },
  "95746": { state: "CA", city: "GRANITE BAY" },
  "95747": { state: "CA", city: "ROSEVILLE" },
  "95757": { state: "CA", city: "ELK GROVE" },
  "95758": { state: "CA", city: "ELK GROVE" },
  "95759": { state: "CA", city: "ELK GROVE" },
  "95762": { state: "CA", city: "EL DORADO HILLS" },
  "95763": { state: "CA", city: "FOLSOM" },
  "95765": { state: "CA", city: "ROCKLIN" },
  "95776": { state: "CA", city: "WOODLAND" },
  "95798": { state: "CA", city: "WEST SACRAMENTO" },
  "95799": { state: "CA", city: "WEST SACRAMENTO" },
  "95811": { state: "CA", city: "SACRAMENTO" },
  "95812": { state: "CA", city: "SACRAMENTO" },
  "95813": { state: "CA", city: "SACRAMENTO" },
  "95814": { state: "CA", city: "SACRAMENTO" },
  "95815": { state: "CA", city: "SACRAMENTO" },
  "95816": { state: "CA", city: "SACRAMENTO" },
  "95817": { state: "CA", city: "SACRAMENTO" },
  "95818": { state: "CA", city: "SACRAMENTO" },
  "95819": { state: "CA", city: "SACRAMENTO" },
  "95820": { state: "CA", city: "SACRAMENTO" },
  "95821": { state: "CA", city: "SACRAMENTO" },
  "95822": { state: "CA", city: "SACRAMENTO" },
  "95823": { state: "CA", city: "SACRAMENTO" },
  "95824": { state: "CA", city: "SACRAMENTO" },
  "95825": { state: "CA", city: "SACRAMENTO" },
  "95826": { state: "CA", city: "SACRAMENTO" },
  "95827": { state: "CA", city: "SACRAMENTO" },
  "95828": { state: "CA", city: "SACRAMENTO" },
  "95829": { state: "CA", city: "SACRAMENTO" },
  "95830": { state: "CA", city: "SACRAMENTO" },
  "95831": { state: "CA", city: "SACRAMENTO" },
  "95832": { state: "CA", city: "SACRAMENTO" },
  "95833": { state: "CA", city: "SACRAMENTO" },
  "95834": { state: "CA", city: "SACRAMENTO" },
  "95835": { state: "CA", city: "SACRAMENTO" },
  "95836": { state: "CA", city: "SACRAMENTO" },
  "95837": { state: "CA", city: "SACRAMENTO" },
  "95838": { state: "CA", city: "SACRAMENTO" },
  "95840": { state: "CA", city: "SACRAMENTO" },
  "95841": { state: "CA", city: "SACRAMENTO" },
  "95842": { state: "CA", city: "SACRAMENTO" },
  "95843": { state: "CA", city: "ANTELOPE" },
  "95851": { state: "CA", city: "SACRAMENTO" },
  "95852": { state: "CA", city: "SACRAMENTO" },
  "95853": { state: "CA", city: "SACRAMENTO" },
  "95860": { state: "CA", city: "SACRAMENTO" },
  "95864": { state: "CA", city: "SACRAMENTO" },
  "95865": { state: "CA", city: "SACRAMENTO" },
  "95866": { state: "CA", city: "SACRAMENTO" },
  "95867": { state: "CA", city: "SACRAMENTO" },
  "95894": { state: "CA", city: "SACRAMENTO" },
  "95899": { state: "CA", city: "SACRAMENTO" },
  "95901": { state: "CA", city: "MARYSVILLE" },
  "95903": { state: "CA", city: "BEALE AFB" },
  "95910": { state: "CA", city: "ALLEGHANY" },
  "95912": { state: "CA", city: "ARBUCKLE" },
  "95913": { state: "CA", city: "ARTOIS" },
  "95914": { state: "CA", city: "BANGOR" },
  "95915": { state: "CA", city: "BELDEN" },
  "95916": { state: "CA", city: "BERRY CREEK" },
  "95917": { state: "CA", city: "BIGGS" },
  "95918": { state: "CA", city: "BROWNS VALLEY" },
  "95919": { state: "CA", city: "BROWNSVILLE" },
  "95920": { state: "CA", city: "BUTTE CITY" },
  "95922": { state: "CA", city: "CAMPTONVILLE" },
  "95923": { state: "CA", city: "CANYON DAM" },
  "95924": { state: "CA", city: "CEDAR RIDGE" },
  "95925": { state: "CA", city: "CHALLENGE" },
  "95926": { state: "CA", city: "CHICO" },
  "95927": { state: "CA", city: "CHICO" },
  "95928": { state: "CA", city: "CHICO" },
  "95929": { state: "CA", city: "CHICO" },
  "95930": { state: "CA", city: "CLIPPER MILLS" },
  "95932": { state: "CA", city: "COLUSA" },
  "95934": { state: "CA", city: "CRESCENT MILLS" },
  "95935": { state: "CA", city: "DOBBINS" },
  "95936": { state: "CA", city: "DOWNIEVILLE" },
  "95937": { state: "CA", city: "DUNNIGAN" },
  "95938": { state: "CA", city: "DURHAM" },
  "95939": { state: "CA", city: "ELK CREEK" },
  "95940": { state: "CA", city: "FEATHER FALLS" },
  "95941": { state: "CA", city: "FORBESTOWN" },
  "95942": { state: "CA", city: "FOREST RANCH" },
  "95943": { state: "CA", city: "GLENN" },
  "95944": { state: "CA", city: "GOODYEARS BAR" },
  "95945": { state: "CA", city: "GRASS VALLEY" },
  "95946": { state: "CA", city: "PENN VALLEY" },
  "95947": { state: "CA", city: "GREENVILLE" },
  "95948": { state: "CA", city: "GRIDLEY" },
  "95949": { state: "CA", city: "GRASS VALLEY" },
  "95950": { state: "CA", city: "GRIMES" },
  "95951": { state: "CA", city: "HAMILTON CITY" },
  "95953": { state: "CA", city: "LIVE OAK" },
  "95954": { state: "CA", city: "MAGALIA" },
  "95955": { state: "CA", city: "MAXWELL" },
  "95956": { state: "CA", city: "MEADOW VALLEY" },
  "95957": { state: "CA", city: "MERIDIAN" },
  "95958": { state: "CA", city: "NELSON" },
  "95959": { state: "CA", city: "NEVADA CITY" },
  "95960": { state: "CA", city: "NORTH SAN JUAN" },
  "95961": { state: "CA", city: "OLIVEHURST" },
  "95962": { state: "CA", city: "OREGON HOUSE" },
  "95963": { state: "CA", city: "ORLAND" },
  "95965": { state: "CA", city: "OROVILLE" },
  "95966": { state: "CA", city: "OROVILLE" },
  "95967": { state: "CA", city: "PARADISE" },
  "95968": { state: "CA", city: "PALERMO" },
  "95969": { state: "CA", city: "PARADISE" },
  "95970": { state: "CA", city: "PRINCETON" },
  "95971": { state: "CA", city: "QUINCY" },
  "95972": { state: "CA", city: "RACKERBY" },
  "95973": { state: "CA", city: "CHICO" },
  "95974": { state: "CA", city: "RICHVALE" },
  "95975": { state: "CA", city: "ROUGH AND READY" },
  "95976": { state: "CA", city: "CHICO" },
  "95977": { state: "CA", city: "SMARTSVILLE" },
  "95978": { state: "CA", city: "STIRLING CITY" },
  "95979": { state: "CA", city: "STONYFORD" },
  "95980": { state: "CA", city: "STORRIE" },
  "95981": { state: "CA", city: "STRAWBERRY VALLEY" },
  "95982": { state: "CA", city: "SUTTER" },
  "95983": { state: "CA", city: "TAYLORSVILLE" },
  "95984": { state: "CA", city: "TWAIN" },
  "95986": { state: "CA", city: "WASHINGTON" },
  "95987": { state: "CA", city: "WILLIAMS" },
  "95988": { state: "CA", city: "WILLOWS" },
  "95991": { state: "CA", city: "YUBA CITY" },
  "95992": { state: "CA", city: "YUBA CITY" },
  "95993": { state: "CA", city: "YUBA CITY" },
  "96001": { state: "CA", city: "REDDING" },
  "96002": { state: "CA", city: "REDDING" },
  "96003": { state: "CA", city: "REDDING" },
  "96006": { state: "CA", city: "ADIN" },
  "96007": { state: "CA", city: "ANDERSON" },
  "96008": { state: "CA", city: "BELLA VISTA" },
  "96009": { state: "CA", city: "BIEBER" },
  "96010": { state: "CA", city: "BIG BAR" },
  "96011": { state: "CA", city: "BIG BEND" },
  "96013": { state: "CA", city: "BURNEY" },
  "96014": { state: "CA", city: "CALLAHAN" },
  "96015": { state: "CA", city: "CANBY" },
  "96016": { state: "CA", city: "CASSEL" },
  "96017": { state: "CA", city: "CASTELLA" },
  "96019": { state: "CA", city: "SHASTA LAKE" },
  "96020": { state: "CA", city: "CHESTER" },
  "96021": { state: "CA", city: "CORNING" },
  "96022": { state: "CA", city: "COTTONWOOD" },
  "96023": { state: "CA", city: "DORRIS" },
  "96024": { state: "CA", city: "DOUGLAS CITY" },
  "96025": { state: "CA", city: "DUNSMUIR" },
  "96027": { state: "CA", city: "ETNA" },
  "96028": { state: "CA", city: "FALL RIVER MILLS" },
  "96029": { state: "CA", city: "FLOURNOY" },
  "96031": { state: "CA", city: "FORKS OF SALMON" },
  "96032": { state: "CA", city: "FORT JONES" },
  "96033": { state: "CA", city: "FRENCH GULCH" },
  "96034": { state: "CA", city: "GAZELLE" },
  "96035": { state: "CA", city: "GERBER" },
  "96037": { state: "CA", city: "GREENVIEW" },
  "96038": { state: "CA", city: "GRENADA" },
  "96039": { state: "CA", city: "HAPPY CAMP" },
  "96040": { state: "CA", city: "HAT CREEK" },
  "96041": { state: "CA", city: "HAYFORK" },
  "96044": { state: "CA", city: "HORNBROOK" },
  "96046": { state: "CA", city: "HYAMPOM" },
  "96047": { state: "CA", city: "IGO" },
  "96048": { state: "CA", city: "JUNCTION CITY" },
  "96049": { state: "CA", city: "REDDING" },
  "96050": { state: "CA", city: "KLAMATH RIVER" },
  "96051": { state: "CA", city: "LAKEHEAD" },
  "96052": { state: "CA", city: "LEWISTON" },
  "96054": { state: "CA", city: "LOOKOUT" },
  "96055": { state: "CA", city: "LOS MOLINOS" },
  "96056": { state: "CA", city: "MCARTHUR" },
  "96057": { state: "CA", city: "MCCLOUD" },
  "96058": { state: "CA", city: "MACDOEL" },
  "96059": { state: "CA", city: "MANTON" },
  "96061": { state: "CA", city: "MILL CREEK" },
  "96062": { state: "CA", city: "MILLVILLE" },
  "96063": { state: "CA", city: "MINERAL" },
  "96064": { state: "CA", city: "MONTAGUE" },
  "96065": { state: "CA", city: "MONTGOMERY CREEK" },
  "96067": { state: "CA", city: "MOUNT SHASTA" },
  "96068": { state: "CA", city: "NUBIEBER" },
  "96069": { state: "CA", city: "OAK RUN" },
  "96070": { state: "CA", city: "OBRIEN" },
  "96071": { state: "CA", city: "OLD STATION" },
  "96073": { state: "CA", city: "PALO CEDRO" },
  "96074": { state: "CA", city: "PASKENTA" },
  "96075": { state: "CA", city: "PAYNES CREEK" },
  "96076": { state: "CA", city: "PLATINA" },
  "96078": { state: "CA", city: "PROBERTA" },
  "96079": { state: "CA", city: "SHASTA LAKE" },
  "96080": { state: "CA", city: "RED BLUFF" },
  "96084": { state: "CA", city: "ROUND MOUNTAIN" },
  "96085": { state: "CA", city: "SCOTT BAR" },
  "96086": { state: "CA", city: "SEIAD VALLEY" },
  "96087": { state: "CA", city: "SHASTA" },
  "96088": { state: "CA", city: "SHINGLETOWN" },
  "96089": { state: "CA", city: "SHASTA LAKE" },
  "96090": { state: "CA", city: "TEHAMA" },
  "96091": { state: "CA", city: "TRINITY CENTER" },
  "96092": { state: "CA", city: "VINA" },
  "96093": { state: "CA", city: "WEAVERVILLE" },
  "96094": { state: "CA", city: "WEED" },
  "96095": { state: "CA", city: "WHISKEYTOWN" },
  "96096": { state: "CA", city: "WHITMORE" },
  "96097": { state: "CA", city: "YREKA" },
  "96099": { state: "CA", city: "REDDING" },
  "96101": { state: "CA", city: "ALTURAS" },
  "96103": { state: "CA", city: "BLAIRSDEN GRAEAGLE" },
  "96104": { state: "CA", city: "CEDARVILLE" },
  "96105": { state: "CA", city: "CHILCOOT" },
  "96106": { state: "CA", city: "CLIO" },
  "96107": { state: "CA", city: "COLEVILLE" },
  "96108": { state: "CA", city: "DAVIS CREEK" },
  "96109": { state: "CA", city: "DOYLE" },
  "96110": { state: "CA", city: "EAGLEVILLE" },
  "96111": { state: "CA", city: "FLORISTON" },
  "96112": { state: "CA", city: "FORT BIDWELL" },
  "96113": { state: "CA", city: "HERLONG" },
  "96114": { state: "CA", city: "JANESVILLE" },
  "96115": { state: "CA", city: "LAKE CITY" },
  "96116": { state: "CA", city: "LIKELY" },
  "96117": { state: "CA", city: "LITCHFIELD" },
  "96118": { state: "CA", city: "LOYALTON" },
  "96119": { state: "CA", city: "MADELINE" },
  "96120": { state: "CA", city: "MARKLEEVILLE" },
  "96121": { state: "CA", city: "MILFORD" },
  "96122": { state: "CA", city: "PORTOLA" },
  "96123": { state: "CA", city: "RAVENDALE" },
  "96124": { state: "CA", city: "CALPINE" },
  "96125": { state: "CA", city: "SIERRA CITY" },
  "96126": { state: "CA", city: "SIERRAVILLE" },
  "96127": { state: "CA", city: "SUSANVILLE" },
  "96128": { state: "CA", city: "STANDISH" },
  "96129": { state: "CA", city: "BECKWOURTH" },
  "96130": { state: "CA", city: "SUSANVILLE" },
  "96132": { state: "CA", city: "TERMO" },
  "96133": { state: "CA", city: "TOPAZ" },
  "96134": { state: "CA", city: "TULELAKE" },
  "96135": { state: "CA", city: "VINTON" },
  "96136": { state: "CA", city: "WENDEL" },
  "96137": { state: "CA", city: "WESTWOOD" },
  "96140": { state: "CA", city: "CARNELIAN BAY" },
  "96141": { state: "CA", city: "HOMEWOOD" },
  "96142": { state: "CA", city: "TAHOMA" },
  "96143": { state: "CA", city: "KINGS BEACH" },
  "96145": { state: "CA", city: "TAHOE CITY" },
  "96146": { state: "CA", city: "OLYMPIC VALLEY" },
  "96148": { state: "CA", city: "TAHOE VISTA" },
  "96150": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96151": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96152": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96154": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96155": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96156": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96157": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96158": { state: "CA", city: "SOUTH LAKE TAHOE" },
  "96160": { state: "CA", city: "TRUCKEE" },
  "96161": { state: "CA", city: "TRUCKEE" },
  "96162": { state: "CA", city: "TRUCKEE" },
  "96201": { state: "AP", city: "APO" },
  "96202": { state: "AP", city: "APO" },
  "96203": { state: "AP", city: "APO" },
  "96204": { state: "AP", city: "APO" },
  "96205": { state: "AP", city: "APO" },
  "96206": { state: "AP", city: "APO" },
  "96207": { state: "AP", city: "APO" },
  "96208": { state: "AP", city: "APO" },
  "96209": { state: "AP", city: "DPO" },
  "96210": { state: "AP", city: "DPO" },
  "96212": { state: "AP", city: "FPO" },
  "96213": { state: "AP", city: "APO" },
  "96214": { state: "AP", city: "APO" },
  "96218": { state: "AP", city: "APO" },
  "96224": { state: "AP", city: "APO" },
  "96251": { state: "AP", city: "APO" },
  "96257": { state: "AP", city: "APO" },
  "96258": { state: "AP", city: "APO" },
  "96260": { state: "AP", city: "APO" },
  "96262": { state: "AP", city: "APO" },
  "96264": { state: "AP", city: "APO" },
  "96266": { state: "AP", city: "APO" },
  "96267": { state: "AP", city: "APO" },
  "96269": { state: "AP", city: "FPO" },
  "96271": { state: "AP", city: "APO" },
  "96273": { state: "AP", city: "APO" },
  "96275": { state: "AP", city: "APO" },
  "96276": { state: "AP", city: "APO" },
  "96278": { state: "AP", city: "APO" },
  "96283": { state: "AP", city: "APO" },
  "96284": { state: "AP", city: "APO" },
  "96297": { state: "AP", city: "APO" },
  "96301": { state: "AP", city: "APO" },
  "96303": { state: "AP", city: "DPO" },
  "96306": { state: "AP", city: "FPO" },
  "96309": { state: "AP", city: "APO" },
  "96310": { state: "AP", city: "FPO" },
  "96311": { state: "AP", city: "FPO" },
  "96315": { state: "AP", city: "FPO" },
  "96319": { state: "AP", city: "APO" },
  "96321": { state: "AP", city: "FPO" },
  "96322": { state: "AP", city: "FPO" },
  "96323": { state: "AP", city: "APO" },
  "96326": { state: "AP", city: "APO" },
  "96328": { state: "AP", city: "APO" },
  "96330": { state: "AP", city: "APO" },
  "96331": { state: "AP", city: "APO" },
  "96336": { state: "AP", city: "APO" },
  "96337": { state: "AP", city: "APO" },
  "96338": { state: "AP", city: "APO" },
  "96339": { state: "AP", city: "APO" },
  "96343": { state: "AP", city: "APO" },
  "96346": { state: "AP", city: "FPO" },
  "96347": { state: "AP", city: "FPO" },
  "96349": { state: "AP", city: "FPO" },
  "96350": { state: "AP", city: "FPO" },
  "96351": { state: "AP", city: "FPO" },
  "96362": { state: "AP", city: "FPO" },
  "96365": { state: "AP", city: "APO" },
  "96367": { state: "AP", city: "APO" },
  "96368": { state: "AP", city: "APO" },
  "96370": { state: "AP", city: "FPO" },
  "96371": { state: "AP", city: "FPO" },
  "96372": { state: "AP", city: "FPO" },
  "96373": { state: "AP", city: "FPO" },
  "96374": { state: "AP", city: "FPO" },
  "96375": { state: "AP", city: "FPO" },
  "96376": { state: "AP", city: "APO" },
  "96377": { state: "AP", city: "FPO" },
  "96378": { state: "AP", city: "APO" },
  "96379": { state: "AP", city: "FPO" },
  "96380": { state: "AP", city: "FPO" },
  "96382": { state: "AP", city: "FPO" },
  "96384": { state: "AP", city: "FPO" },
  "96385": { state: "AP", city: "FPO" },
  "96386": { state: "AP", city: "APO" },
  "96387": { state: "AP", city: "FPO" },
  "96388": { state: "AP", city: "FPO" },
  "96389": { state: "AP", city: "FPO" },
  "96401": { state: "AP", city: "APO" },
  "96502": { state: "AP", city: "APO" },
  "96504": { state: "AP", city: "DPO" },
  "96505": { state: "AP", city: "DPO" },
  "96506": { state: "AP", city: "APO" },
  "96507": { state: "AP", city: "DPO" },
  "96510": { state: "AP", city: "FPO" },
  "96511": { state: "AP", city: "FPO" },
  "96515": { state: "AP", city: "DPO" },
  "96516": { state: "AP", city: "FPO" },
  "96517": { state: "AP", city: "FPO" },
  "96520": { state: "AP", city: "DPO" },
  "96521": { state: "AP", city: "DPO" },
  "96522": { state: "AP", city: "FPO" },
  "96530": { state: "AP", city: "DPO" },
  "96531": { state: "AP", city: "FPO" },
  "96532": { state: "AP", city: "DPO" },
  "96534": { state: "AP", city: "FPO" },
  "96535": { state: "AP", city: "DPO" },
  "96537": { state: "AP", city: "FPO" },
  "96539": { state: "AP", city: "APO" },
  "96540": { state: "AP", city: "FPO" },
  "96542": { state: "AP", city: "FPO" },
  "96543": { state: "AP", city: "FPO" },
  "96546": { state: "AP", city: "APO" },
  "96548": { state: "AP", city: "APO" },
  "96549": { state: "AP", city: "DPO" },
  "96550": { state: "AP", city: "APO" },
  "96551": { state: "AP", city: "DPO" },
  "96552": { state: "AP", city: "APO" },
  "96553": { state: "AP", city: "APO" },
  "96554": { state: "AP", city: "DPO" },
  "96555": { state: "AP", city: "APO" },
  "96557": { state: "AP", city: "APO" },
  "96562": { state: "AP", city: "DPO" },
  "96577": { state: "AP", city: "APO" },
  "96578": { state: "AP", city: "FPO" },
  "96595": { state: "AP", city: "FPO" },
  "96598": { state: "AP", city: "FPO" },
  "96599": { state: "AP", city: "APO" },
  "96601": { state: "AP", city: "FPO" },
  "96602": { state: "AP", city: "FPO" },
  "96603": { state: "AP", city: "FPO" },
  "96604": { state: "AP", city: "FPO" },
  "96605": { state: "AP", city: "FPO" },
  "96606": { state: "AP", city: "FPO" },
  "96607": { state: "AP", city: "FPO" },
  "96608": { state: "AP", city: "FPO" },
  "96609": { state: "AP", city: "FPO" },
  "96610": { state: "AP", city: "FPO" },
  "96611": { state: "AP", city: "FPO" },
  "96612": { state: "AP", city: "FPO" },
  "96613": { state: "AP", city: "FPO" },
  "96615": { state: "AP", city: "FPO" },
  "96616": { state: "AP", city: "FPO" },
  "96617": { state: "AP", city: "FPO" },
  "96619": { state: "AP", city: "FPO" },
  "96620": { state: "AP", city: "FPO" },
  "96628": { state: "AP", city: "FPO" },
  "96629": { state: "AP", city: "FPO" },
  "96632": { state: "AP", city: "FPO" },
  "96633": { state: "AP", city: "APO" },
  "96641": { state: "AP", city: "APO" },
  "96642": { state: "AP", city: "APO" },
  "96643": { state: "AP", city: "FPO" },
  "96644": { state: "AP", city: "APO" },
  "96645": { state: "AP", city: "APO" },
  "96649": { state: "AP", city: "FPO" },
  "96650": { state: "AP", city: "FPO" },
  "96657": { state: "AP", city: "FPO" },
  "96660": { state: "AP", city: "FPO" },
  "96661": { state: "AP", city: "FPO" },
  "96662": { state: "AP", city: "FPO" },
  "96663": { state: "AP", city: "FPO" },
  "96664": { state: "AP", city: "FPO" },
  "96665": { state: "AP", city: "FPO" },
  "96666": { state: "AP", city: "FPO" },
  "96667": { state: "AP", city: "FPO" },
  "96668": { state: "AP", city: "FPO" },
  "96669": { state: "AP", city: "FPO" },
  "96670": { state: "AP", city: "FPO" },
  "96671": { state: "AP", city: "FPO" },
  "96672": { state: "AP", city: "FPO" },
  "96673": { state: "AP", city: "FPO" },
  "96674": { state: "AP", city: "FPO" },
  "96675": { state: "AP", city: "FPO" },
  "96677": { state: "AP", city: "FPO" },
  "96678": { state: "AP", city: "FPO" },
  "96679": { state: "AP", city: "FPO" },
  "96681": { state: "AP", city: "FPO" },
  "96682": { state: "AP", city: "FPO" },
  "96683": { state: "AP", city: "FPO" },
  "96686": { state: "AP", city: "FPO" },
  "96691": { state: "AP", city: "FPO" },
  "96692": { state: "AP", city: "FPO" },
  "96693": { state: "AP", city: "FPO" },
  "96694": { state: "AP", city: "FPO" },
  "96695": { state: "AP", city: "FPO" },
  "96696": { state: "AP", city: "FPO" },
  "96698": { state: "AP", city: "FPO" },
  "96701": { state: "HI", city: "AIEA" },
  "96703": { state: "HI", city: "ANAHOLA" },
  "96704": { state: "HI", city: "CAPTAIN COOK" },
  "96705": { state: "HI", city: "ELEELE" },
  "96706": { state: "HI", city: "EWA BEACH" },
  "96707": { state: "HI", city: "KAPOLEI" },
  "96708": { state: "HI", city: "HAIKU" },
  "96709": { state: "HI", city: "KAPOLEI" },
  "96710": { state: "HI", city: "HAKALAU" },
  "96712": { state: "HI", city: "HALEIWA" },
  "96713": { state: "HI", city: "HANA" },
  "96714": { state: "HI", city: "HANALEI" },
  "96715": { state: "HI", city: "HANAMAULU" },
  "96716": { state: "HI", city: "HANAPEPE" },
  "96717": { state: "HI", city: "HAUULA" },
  "96718": { state: "HI", city: "HAWAII NATIONAL PARK" },
  "96719": { state: "HI", city: "HAWI" },
  "96720": { state: "HI", city: "HILO" },
  "96721": { state: "HI", city: "HILO" },
  "96722": { state: "HI", city: "PRINCEVILLE" },
  "96725": { state: "HI", city: "HOLUALOA" },
  "96726": { state: "HI", city: "HONAUNAU" },
  "96727": { state: "HI", city: "HONOKAA" },
  "96728": { state: "HI", city: "HONOMU" },
  "96729": { state: "HI", city: "HOOLEHUA" },
  "96730": { state: "HI", city: "KAAAWA" },
  "96731": { state: "HI", city: "KAHUKU" },
  "96732": { state: "HI", city: "KAHULUI" },
  "96733": { state: "HI", city: "KAHULUI" },
  "96734": { state: "HI", city: "KAILUA" },
  "96737": { state: "HI", city: "OCEAN VIEW" },
  "96738": { state: "HI", city: "WAIKOLOA" },
  "96739": { state: "HI", city: "KEAUHOU" },
  "96740": { state: "HI", city: "KAILUA KONA" },
  "96741": { state: "HI", city: "KALAHEO" },
  "96742": { state: "HI", city: "KALAUPAPA" },
  "96743": { state: "HI", city: "KAMUELA" },
  "96744": { state: "HI", city: "KANEOHE" },
  "96745": { state: "HI", city: "KAILUA KONA" },
  "96746": { state: "HI", city: "KAPAA" },
  "96747": { state: "HI", city: "KAUMAKANI" },
  "96748": { state: "HI", city: "KAUNAKAKAI" },
  "96749": { state: "HI", city: "KEAAU" },
  "96750": { state: "HI", city: "KEALAKEKUA" },
  "96751": { state: "HI", city: "KEALIA" },
  "96752": { state: "HI", city: "KEKAHA" },
  "96753": { state: "HI", city: "KIHEI" },
  "96754": { state: "HI", city: "KILAUEA" },
  "96755": { state: "HI", city: "KAPAAU" },
  "96756": { state: "HI", city: "KOLOA" },
  "96757": { state: "HI", city: "KUALAPUU" },
  "96759": { state: "HI", city: "KUNIA" },
  "96760": { state: "HI", city: "KURTISTOWN" },
  "96761": { state: "HI", city: "LAHAINA" },
  "96762": { state: "HI", city: "LAIE" },
  "96763": { state: "HI", city: "LANAI CITY" },
  "96764": { state: "HI", city: "LAUPAHOEHOE" },
  "96765": { state: "HI", city: "LAWAI" },
  "96766": { state: "HI", city: "LIHUE" },
  "96767": { state: "HI", city: "LAHAINA" },
  "96768": { state: "HI", city: "MAKAWAO" },
  "96769": { state: "HI", city: "MAKAWELI" },
  "96770": { state: "HI", city: "MAUNALOA" },
  "96771": { state: "HI", city: "MOUNTAIN VIEW" },
  "96772": { state: "HI", city: "NAALEHU" },
  "96773": { state: "HI", city: "NINOLE" },
  "96774": { state: "HI", city: "OOKALA" },
  "96776": { state: "HI", city: "PAAUILO" },
  "96777": { state: "HI", city: "PAHALA" },
  "96778": { state: "HI", city: "PAHOA" },
  "96779": { state: "HI", city: "PAIA" },
  "96780": { state: "HI", city: "PAPAALOA" },
  "96781": { state: "HI", city: "PAPAIKOU" },
  "96782": { state: "HI", city: "PEARL CITY" },
  "96783": { state: "HI", city: "PEPEEKEO" },
  "96784": { state: "HI", city: "PUUNENE" },
  "96785": { state: "HI", city: "VOLCANO" },
  "96786": { state: "HI", city: "WAHIAWA" },
  "96788": { state: "HI", city: "PUKALANI" },
  "96789": { state: "HI", city: "MILILANI" },
  "96790": { state: "HI", city: "KULA" },
  "96791": { state: "HI", city: "WAIALUA" },
  "96792": { state: "HI", city: "WAIANAE" },
  "96793": { state: "HI", city: "WAILUKU" },
  "96795": { state: "HI", city: "WAIMANALO" },
  "96796": { state: "HI", city: "WAIMEA" },
  "96797": { state: "HI", city: "WAIPAHU" },
  "96799": { state: "AS", city: "PAGO PAGO" },
  "96801": { state: "HI", city: "HONOLULU" },
  "96802": { state: "HI", city: "HONOLULU" },
  "96803": { state: "HI", city: "HONOLULU" },
  "96804": { state: "HI", city: "HONOLULU" },
  "96805": { state: "HI", city: "HONOLULU" },
  "96806": { state: "HI", city: "HONOLULU" },
  "96807": { state: "HI", city: "HONOLULU" },
  "96808": { state: "HI", city: "HONOLULU" },
  "96809": { state: "HI", city: "HONOLULU" },
  "96810": { state: "HI", city: "HONOLULU" },
  "96811": { state: "HI", city: "HONOLULU" },
  "96812": { state: "HI", city: "HONOLULU" },
  "96813": { state: "HI", city: "HONOLULU" },
  "96814": { state: "HI", city: "HONOLULU" },
  "96815": { state: "HI", city: "HONOLULU" },
  "96816": { state: "HI", city: "HONOLULU" },
  "96817": { state: "HI", city: "HONOLULU" },
  "96818": { state: "HI", city: "HONOLULU" },
  "96819": { state: "HI", city: "HONOLULU" },
  "96820": { state: "HI", city: "HONOLULU" },
  "96821": { state: "HI", city: "HONOLULU" },
  "96822": { state: "HI", city: "HONOLULU" },
  "96823": { state: "HI", city: "HONOLULU" },
  "96824": { state: "HI", city: "HONOLULU" },
  "96825": { state: "HI", city: "HONOLULU" },
  "96826": { state: "HI", city: "HONOLULU" },
  "96828": { state: "HI", city: "HONOLULU" },
  "96830": { state: "HI", city: "HONOLULU" },
  "96836": { state: "HI", city: "HONOLULU" },
  "96837": { state: "HI", city: "HONOLULU" },
  "96838": { state: "HI", city: "HONOLULU" },
  "96839": { state: "HI", city: "HONOLULU" },
  "96840": { state: "HI", city: "HONOLULU" },
  "96841": { state: "HI", city: "HONOLULU" },
  "96843": { state: "HI", city: "HONOLULU" },
  "96844": { state: "HI", city: "HONOLULU" },
  "96846": { state: "HI", city: "HONOLULU" },
  "96847": { state: "HI", city: "HONOLULU" },
  "96848": { state: "HI", city: "HONOLULU" },
  "96849": { state: "HI", city: "HONOLULU" },
  "96850": { state: "HI", city: "HONOLULU" },
  "96853": { state: "HI", city: "JBPHH" },
  "96854": { state: "HI", city: "WHEELER ARMY AIRFIELD" },
  "96857": { state: "HI", city: "SCHOFIELD BARRACKS" },
  "96858": { state: "HI", city: "FORT SHAFTER" },
  "96859": { state: "HI", city: "TRIPLER ARMY MEDICAL CENTER" },
  "96860": { state: "HI", city: "JBPHH" },
  "96861": { state: "HI", city: "CAMP H M SMITH" },
  "96863": { state: "HI", city: "MCBH KANEOHE BAY" },
  "96898": { state: "HI", city: "WAKE ISLAND" },
  "96910": { state: "GU", city: "HAGATNA" },
  "96912": { state: "GU", city: "DEDEDO" },
  "96913": { state: "GU", city: "BARRIGADA" },
  "96915": { state: "GU", city: "SANTA RITA" },
  "96916": { state: "GU", city: "MERIZO" },
  "96917": { state: "GU", city: "INARAJAN" },
  "96919": { state: "GU", city: "AGANA HEIGHTS" },
  "96921": { state: "GU", city: "BARRIGADA" },
  "96923": { state: "GU", city: "MANGILAO" },
  "96928": { state: "GU", city: "AGAT" },
  "96929": { state: "GU", city: "YIGO" },
  "96931": { state: "GU", city: "TAMUNING" },
  "96932": { state: "GU", city: "HAGATNA" },
  "96939": { state: "PW", city: "NGERULMUD" },
  "96940": { state: "PW", city: "KOROR" },
  "96941": { state: "FM", city: "POHNPEI" },
  "96942": { state: "FM", city: "CHUUK" },
  "96943": { state: "FM", city: "YAP" },
  "96944": { state: "FM", city: "KOSRAE" },
  "96950": { state: "MP", city: "SAIPAN" },
  "96951": { state: "MP", city: "ROTA" },
  "96952": { state: "MP", city: "TINIAN" },
  "96960": { state: "MH", city: "MAJURO" },
  "96970": { state: "MH", city: "EBEYE" },
  "97001": { state: "OR", city: "ANTELOPE" },
  "97002": { state: "OR", city: "AURORA" },
  "97003": { state: "OR", city: "BEAVERTON" },
  "97004": { state: "OR", city: "BEAVERCREEK" },
  "97005": { state: "OR", city: "BEAVERTON" },
  "97006": { state: "OR", city: "BEAVERTON" },
  "97007": { state: "OR", city: "BEAVERTON" },
  "97008": { state: "OR", city: "BEAVERTON" },
  "97009": { state: "OR", city: "BORING" },
  "97010": { state: "OR", city: "BRIDAL VEIL" },
  "97011": { state: "OR", city: "BRIGHTWOOD" },
  "97013": { state: "OR", city: "CANBY" },
  "97014": { state: "OR", city: "CASCADE LOCKS" },
  "97015": { state: "OR", city: "CLACKAMAS" },
  "97016": { state: "OR", city: "CLATSKANIE" },
  "97017": { state: "OR", city: "COLTON" },
  "97018": { state: "OR", city: "COLUMBIA CITY" },
  "97019": { state: "OR", city: "CORBETT" },
  "97020": { state: "OR", city: "DONALD" },
  "97021": { state: "OR", city: "DUFUR" },
  "97022": { state: "OR", city: "EAGLE CREEK" },
  "97023": { state: "OR", city: "ESTACADA" },
  "97024": { state: "OR", city: "FAIRVIEW" },
  "97026": { state: "OR", city: "GERVAIS" },
  "97027": { state: "OR", city: "GLADSTONE" },
  "97028": { state: "OR", city: "GOVERNMENT CAMP" },
  "97029": { state: "OR", city: "GRASS VALLEY" },
  "97030": { state: "OR", city: "GRESHAM" },
  "97031": { state: "OR", city: "HOOD RIVER" },
  "97032": { state: "OR", city: "HUBBARD" },
  "97033": { state: "OR", city: "KENT" },
  "97034": { state: "OR", city: "LAKE OSWEGO" },
  "97035": { state: "OR", city: "LAKE OSWEGO" },
  "97036": { state: "OR", city: "MARYLHURST" },
  "97037": { state: "OR", city: "MAUPIN" },
  "97038": { state: "OR", city: "MOLALLA" },
  "97039": { state: "OR", city: "MORO" },
  "97040": { state: "OR", city: "MOSIER" },
  "97041": { state: "OR", city: "MOUNT HOOD PARKDALE" },
  "97042": { state: "OR", city: "MULINO" },
  "97044": { state: "OR", city: "ODELL" },
  "97045": { state: "OR", city: "OREGON CITY" },
  "97048": { state: "OR", city: "RAINIER" },
  "97049": { state: "OR", city: "RHODODENDRON" },
  "97050": { state: "OR", city: "RUFUS" },
  "97051": { state: "OR", city: "SAINT HELENS" },
  "97053": { state: "OR", city: "WARREN" },
  "97054": { state: "OR", city: "DEER ISLAND" },
  "97055": { state: "OR", city: "SANDY" },
  "97056": { state: "OR", city: "SCAPPOOSE" },
  "97057": { state: "OR", city: "SHANIKO" },
  "97058": { state: "OR", city: "THE DALLES" },
  "97060": { state: "OR", city: "TROUTDALE" },
  "97062": { state: "OR", city: "TUALATIN" },
  "97063": { state: "OR", city: "TYGH VALLEY" },
  "97064": { state: "OR", city: "VERNONIA" },
  "97065": { state: "OR", city: "WASCO" },
  "97067": { state: "OR", city: "WELCHES" },
  "97068": { state: "OR", city: "WEST LINN" },
  "97070": { state: "OR", city: "WILSONVILLE" },
  "97071": { state: "OR", city: "WOODBURN" },
  "97075": { state: "OR", city: "BEAVERTON" },
  "97076": { state: "OR", city: "BEAVERTON" },
  "97077": { state: "OR", city: "BEAVERTON" },
  "97078": { state: "OR", city: "BEAVERTON" },
  "97079": { state: "OR", city: "BEAVERTON" },
  "97080": { state: "OR", city: "GRESHAM" },
  "97086": { state: "OR", city: "HAPPY VALLEY" },
  "97089": { state: "OR", city: "DAMASCUS" },
  "97101": { state: "OR", city: "AMITY" },
  "97102": { state: "OR", city: "ARCH CAPE" },
  "97103": { state: "OR", city: "ASTORIA" },
  "97106": { state: "OR", city: "BANKS" },
  "97107": { state: "OR", city: "BAY CITY" },
  "97108": { state: "OR", city: "BEAVER" },
  "97109": { state: "OR", city: "BUXTON" },
  "97110": { state: "OR", city: "CANNON BEACH" },
  "97111": { state: "OR", city: "CARLTON" },
  "97112": { state: "OR", city: "CLOVERDALE" },
  "97113": { state: "OR", city: "CORNELIUS" },
  "97114": { state: "OR", city: "DAYTON" },
  "97115": { state: "OR", city: "DUNDEE" },
  "97116": { state: "OR", city: "FOREST GROVE" },
  "97117": { state: "OR", city: "GALES CREEK" },
  "97118": { state: "OR", city: "GARIBALDI" },
  "97119": { state: "OR", city: "GASTON" },
  "97121": { state: "OR", city: "HAMMOND" },
  "97122": { state: "OR", city: "HEBO" },
  "97123": { state: "OR", city: "HILLSBORO" },
  "97124": { state: "OR", city: "HILLSBORO" },
  "97125": { state: "OR", city: "MANNING" },
  "97127": { state: "OR", city: "LAFAYETTE" },
  "97128": { state: "OR", city: "MCMINNVILLE" },
  "97129": { state: "OR", city: "HILLSBORO" },
  "97130": { state: "OR", city: "MANZANITA" },
  "97131": { state: "OR", city: "NEHALEM" },
  "97132": { state: "OR", city: "NEWBERG" },
  "97133": { state: "OR", city: "NORTH PLAINS" },
  "97134": { state: "OR", city: "OCEANSIDE" },
  "97135": { state: "OR", city: "PACIFIC CITY" },
  "97136": { state: "OR", city: "ROCKAWAY BEACH" },
  "97137": { state: "OR", city: "SAINT PAUL" },
  "97138": { state: "OR", city: "SEASIDE" },
  "97140": { state: "OR", city: "SHERWOOD" },
  "97141": { state: "OR", city: "TILLAMOOK" },
  "97143": { state: "OR", city: "NETARTS" },
  "97144": { state: "OR", city: "TIMBER" },
  "97145": { state: "OR", city: "TOLOVANA PARK" },
  "97146": { state: "OR", city: "WARRENTON" },
  "97147": { state: "OR", city: "WHEELER" },
  "97148": { state: "OR", city: "YAMHILL" },
  "97149": { state: "OR", city: "NESKOWIN" },
  "97201": { state: "OR", city: "PORTLAND" },
  "97202": { state: "OR", city: "PORTLAND" },
  "97203": { state: "OR", city: "PORTLAND" },
  "97204": { state: "OR", city: "PORTLAND" },
  "97205": { state: "OR", city: "PORTLAND" },
  "97206": { state: "OR", city: "PORTLAND" },
  "97207": { state: "OR", city: "PORTLAND" },
  "97208": { state: "OR", city: "PORTLAND" },
  "97209": { state: "OR", city: "PORTLAND" },
  "97210": { state: "OR", city: "PORTLAND" },
  "97211": { state: "OR", city: "PORTLAND" },
  "97212": { state: "OR", city: "PORTLAND" },
  "97213": { state: "OR", city: "PORTLAND" },
  "97214": { state: "OR", city: "PORTLAND" },
  "97215": { state: "OR", city: "PORTLAND" },
  "97216": { state: "OR", city: "PORTLAND" },
  "97217": { state: "OR", city: "PORTLAND" },
  "97218": { state: "OR", city: "PORTLAND" },
  "97219": { state: "OR", city: "PORTLAND" },
  "97220": { state: "OR", city: "PORTLAND" },
  "97221": { state: "OR", city: "PORTLAND" },
  "97222": { state: "OR", city: "PORTLAND" },
  "97223": { state: "OR", city: "PORTLAND" },
  "97224": { state: "OR", city: "PORTLAND" },
  "97225": { state: "OR", city: "PORTLAND" },
  "97227": { state: "OR", city: "PORTLAND" },
  "97228": { state: "OR", city: "PORTLAND" },
  "97229": { state: "OR", city: "PORTLAND" },
  "97230": { state: "OR", city: "PORTLAND" },
  "97231": { state: "OR", city: "PORTLAND" },
  "97232": { state: "OR", city: "PORTLAND" },
  "97233": { state: "OR", city: "PORTLAND" },
  "97236": { state: "OR", city: "PORTLAND" },
  "97238": { state: "OR", city: "PORTLAND" },
  "97239": { state: "OR", city: "PORTLAND" },
  "97240": { state: "OR", city: "PORTLAND" },
  "97242": { state: "OR", city: "PORTLAND" },
  "97250": { state: "OR", city: "PORTLAND" },
  "97251": { state: "OR", city: "PORTLAND" },
  "97252": { state: "OR", city: "PORTLAND" },
  "97253": { state: "OR", city: "PORTLAND" },
  "97254": { state: "OR", city: "PORTLAND" },
  "97256": { state: "OR", city: "PORTLAND" },
  "97266": { state: "OR", city: "PORTLAND" },
  "97267": { state: "OR", city: "PORTLAND" },
  "97268": { state: "OR", city: "PORTLAND" },
  "97269": { state: "OR", city: "PORTLAND" },
  "97280": { state: "OR", city: "PORTLAND" },
  "97281": { state: "OR", city: "PORTLAND" },
  "97282": { state: "OR", city: "PORTLAND" },
  "97283": { state: "OR", city: "PORTLAND" },
  "97286": { state: "OR", city: "PORTLAND" },
  "97290": { state: "OR", city: "PORTLAND" },
  "97291": { state: "OR", city: "PORTLAND" },
  "97292": { state: "OR", city: "PORTLAND" },
  "97293": { state: "OR", city: "PORTLAND" },
  "97294": { state: "OR", city: "PORTLAND" },
  "97296": { state: "OR", city: "PORTLAND" },
  "97298": { state: "OR", city: "PORTLAND" },
  "97301": { state: "OR", city: "SALEM" },
  "97302": { state: "OR", city: "SALEM" },
  "97303": { state: "OR", city: "SALEM" },
  "97304": { state: "OR", city: "SALEM" },
  "97305": { state: "OR", city: "SALEM" },
  "97306": { state: "OR", city: "SALEM" },
  "97307": { state: "OR", city: "KEIZER" },
  "97308": { state: "OR", city: "SALEM" },
  "97309": { state: "OR", city: "SALEM" },
  "97310": { state: "OR", city: "SALEM" },
  "97311": { state: "OR", city: "SALEM" },
  "97312": { state: "OR", city: "SALEM" },
  "97314": { state: "OR", city: "SALEM" },
  "97317": { state: "OR", city: "SALEM" },
  "97321": { state: "OR", city: "ALBANY" },
  "97322": { state: "OR", city: "ALBANY" },
  "97324": { state: "OR", city: "ALSEA" },
  "97325": { state: "OR", city: "AUMSVILLE" },
  "97326": { state: "OR", city: "BLODGETT" },
  "97327": { state: "OR", city: "BROWNSVILLE" },
  "97329": { state: "OR", city: "CASCADIA" },
  "97330": { state: "OR", city: "CORVALLIS" },
  "97331": { state: "OR", city: "CORVALLIS" },
  "97333": { state: "OR", city: "CORVALLIS" },
  "97335": { state: "OR", city: "CRABTREE" },
  "97336": { state: "OR", city: "CRAWFORDSVILLE" },
  "97338": { state: "OR", city: "DALLAS" },
  "97339": { state: "OR", city: "CORVALLIS" },
  "97341": { state: "OR", city: "DEPOE BAY" },
  "97342": { state: "OR", city: "DETROIT" },
  "97343": { state: "OR", city: "EDDYVILLE" },
  "97344": { state: "OR", city: "FALLS CITY" },
  "97345": { state: "OR", city: "FOSTER" },
  "97346": { state: "OR", city: "GATES" },
  "97347": { state: "OR", city: "GRAND RONDE" },
  "97348": { state: "OR", city: "HALSEY" },
  "97350": { state: "OR", city: "IDANHA" },
  "97351": { state: "OR", city: "INDEPENDENCE" },
  "97352": { state: "OR", city: "JEFFERSON" },
  "97355": { state: "OR", city: "LEBANON" },
  "97357": { state: "OR", city: "LOGSDEN" },
  "97358": { state: "OR", city: "LYONS" },
  "97360": { state: "OR", city: "MILL CITY" },
  "97361": { state: "OR", city: "MONMOUTH" },
  "97362": { state: "OR", city: "MOUNT ANGEL" },
  "97364": { state: "OR", city: "NEOTSU" },
  "97365": { state: "OR", city: "NEWPORT" },
  "97366": { state: "OR", city: "SOUTH BEACH" },
  "97367": { state: "OR", city: "LINCOLN CITY" },
  "97368": { state: "OR", city: "OTIS" },
  "97369": { state: "OR", city: "OTTER ROCK" },
  "97370": { state: "OR", city: "PHILOMATH" },
  "97371": { state: "OR", city: "RICKREALL" },
  "97373": { state: "OR", city: "SAINT BENEDICT" },
  "97374": { state: "OR", city: "SCIO" },
  "97375": { state: "OR", city: "SCOTTS MILLS" },
  "97376": { state: "OR", city: "SEAL ROCK" },
  "97377": { state: "OR", city: "SHEDD" },
  "97378": { state: "OR", city: "SHERIDAN" },
  "97380": { state: "OR", city: "SILETZ" },
  "97381": { state: "OR", city: "SILVERTON" },
  "97383": { state: "OR", city: "STAYTON" },
  "97384": { state: "OR", city: "MEHAMA" },
  "97385": { state: "OR", city: "SUBLIMITY" },
  "97386": { state: "OR", city: "SWEET HOME" },
  "97388": { state: "OR", city: "GLENEDEN BEACH" },
  "97389": { state: "OR", city: "TANGENT" },
  "97390": { state: "OR", city: "TIDEWATER" },
  "97391": { state: "OR", city: "TOLEDO" },
  "97392": { state: "OR", city: "TURNER" },
  "97394": { state: "OR", city: "WALDPORT" },
  "97396": { state: "OR", city: "WILLAMINA" },
  "97401": { state: "OR", city: "EUGENE" },
  "97402": { state: "OR", city: "EUGENE" },
  "97403": { state: "OR", city: "EUGENE" },
  "97404": { state: "OR", city: "EUGENE" },
  "97405": { state: "OR", city: "EUGENE" },
  "97406": { state: "OR", city: "AGNESS" },
  "97407": { state: "OR", city: "ALLEGANY" },
  "97408": { state: "OR", city: "EUGENE" },
  "97409": { state: "OR", city: "ALVADORE" },
  "97410": { state: "OR", city: "AZALEA" },
  "97411": { state: "OR", city: "BANDON" },
  "97412": { state: "OR", city: "BLACHLY" },
  "97413": { state: "OR", city: "BLUE RIVER" },
  "97414": { state: "OR", city: "BROADBENT" },
  "97415": { state: "OR", city: "BROOKINGS" },
  "97416": { state: "OR", city: "CAMAS VALLEY" },
  "97417": { state: "OR", city: "CANYONVILLE" },
  "97419": { state: "OR", city: "CHESHIRE" },
  "97420": { state: "OR", city: "COOS BAY" },
  "97423": { state: "OR", city: "COQUILLE" },
  "97424": { state: "OR", city: "COTTAGE GROVE" },
  "97426": { state: "OR", city: "CRESWELL" },
  "97429": { state: "OR", city: "DAYS CREEK" },
  "97430": { state: "OR", city: "DEADWOOD" },
  "97431": { state: "OR", city: "DEXTER" },
  "97432": { state: "OR", city: "DILLARD" },
  "97434": { state: "OR", city: "DORENA" },
  "97435": { state: "OR", city: "DRAIN" },
  "97436": { state: "OR", city: "ELKTON" },
  "97437": { state: "OR", city: "ELMIRA" },
  "97438": { state: "OR", city: "FALL CREEK" },
  "97439": { state: "OR", city: "FLORENCE" },
  "97440": { state: "OR", city: "EUGENE" },
  "97441": { state: "OR", city: "GARDINER" },
  "97442": { state: "OR", city: "GLENDALE" },
  "97443": { state: "OR", city: "GLIDE" },
  "97444": { state: "OR", city: "GOLD BEACH" },
  "97446": { state: "OR", city: "HARRISBURG" },
  "97447": { state: "OR", city: "IDLEYLD PARK" },
  "97448": { state: "OR", city: "JUNCTION CITY" },
  "97449": { state: "OR", city: "LAKESIDE" },
  "97450": { state: "OR", city: "LANGLOIS" },
  "97451": { state: "OR", city: "LORANE" },
  "97452": { state: "OR", city: "LOWELL" },
  "97453": { state: "OR", city: "MAPLETON" },
  "97454": { state: "OR", city: "MARCOLA" },
  "97455": { state: "OR", city: "PLEASANT HILL" },
  "97456": { state: "OR", city: "MONROE" },
  "97457": { state: "OR", city: "MYRTLE CREEK" },
  "97458": { state: "OR", city: "MYRTLE POINT" },
  "97459": { state: "OR", city: "NORTH BEND" },
  "97461": { state: "OR", city: "NOTI" },
  "97462": { state: "OR", city: "OAKLAND" },
  "97463": { state: "OR", city: "OAKRIDGE" },
  "97464": { state: "OR", city: "OPHIR" },
  "97465": { state: "OR", city: "PORT ORFORD" },
  "97466": { state: "OR", city: "POWERS" },
  "97467": { state: "OR", city: "REEDSPORT" },
  "97469": { state: "OR", city: "RIDDLE" },
  "97470": { state: "OR", city: "ROSEBURG" },
  "97471": { state: "OR", city: "ROSEBURG" },
  "97473": { state: "OR", city: "SCOTTSBURG" },
  "97475": { state: "OR", city: "SPRINGFIELD" },
  "97476": { state: "OR", city: "SIXES" },
  "97477": { state: "OR", city: "SPRINGFIELD" },
  "97478": { state: "OR", city: "SPRINGFIELD" },
  "97479": { state: "OR", city: "SUTHERLIN" },
  "97480": { state: "OR", city: "SWISSHOME" },
  "97481": { state: "OR", city: "TENMILE" },
  "97484": { state: "OR", city: "TILLER" },
  "97486": { state: "OR", city: "UMPQUA" },
  "97487": { state: "OR", city: "VENETA" },
  "97488": { state: "OR", city: "VIDA" },
  "97489": { state: "OR", city: "WALTERVILLE" },
  "97490": { state: "OR", city: "WALTON" },
  "97491": { state: "OR", city: "WEDDERBURN" },
  "97492": { state: "OR", city: "WESTFIR" },
  "97493": { state: "OR", city: "WESTLAKE" },
  "97494": { state: "OR", city: "WILBUR" },
  "97495": { state: "OR", city: "WINCHESTER" },
  "97496": { state: "OR", city: "WINSTON" },
  "97497": { state: "OR", city: "WOLF CREEK" },
  "97498": { state: "OR", city: "YACHATS" },
  "97499": { state: "OR", city: "YONCALLA" },
  "97501": { state: "OR", city: "MEDFORD" },
  "97502": { state: "OR", city: "CENTRAL POINT" },
  "97503": { state: "OR", city: "WHITE CITY" },
  "97504": { state: "OR", city: "MEDFORD" },
  "97520": { state: "OR", city: "ASHLAND" },
  "97522": { state: "OR", city: "BUTTE FALLS" },
  "97523": { state: "OR", city: "CAVE JUNCTION" },
  "97524": { state: "OR", city: "EAGLE POINT" },
  "97525": { state: "OR", city: "GOLD HILL" },
  "97526": { state: "OR", city: "GRANTS PASS" },
  "97527": { state: "OR", city: "GRANTS PASS" },
  "97528": { state: "OR", city: "GRANTS PASS" },
  "97530": { state: "OR", city: "JACKSONVILLE" },
  "97531": { state: "OR", city: "KERBY" },
  "97532": { state: "OR", city: "MERLIN" },
  "97533": { state: "OR", city: "MURPHY" },
  "97534": { state: "OR", city: "O BRIEN" },
  "97535": { state: "OR", city: "PHOENIX" },
  "97536": { state: "OR", city: "PROSPECT" },
  "97537": { state: "OR", city: "ROGUE RIVER" },
  "97538": { state: "OR", city: "SELMA" },
  "97539": { state: "OR", city: "SHADY COVE" },
  "97540": { state: "OR", city: "TALENT" },
  "97541": { state: "OR", city: "TRAIL" },
  "97543": { state: "OR", city: "WILDERVILLE" },
  "97544": { state: "OR", city: "WILLIAMS" },
  "97601": { state: "OR", city: "KLAMATH FALLS" },
  "97602": { state: "OR", city: "KLAMATH FALLS" },
  "97603": { state: "OR", city: "KLAMATH FALLS" },
  "97604": { state: "OR", city: "CRATER LAKE" },
  "97620": { state: "OR", city: "ADEL" },
  "97621": { state: "OR", city: "BEATTY" },
  "97622": { state: "OR", city: "BLY" },
  "97623": { state: "OR", city: "BONANZA" },
  "97624": { state: "OR", city: "CHILOQUIN" },
  "97625": { state: "OR", city: "DAIRY" },
  "97626": { state: "OR", city: "FORT KLAMATH" },
  "97627": { state: "OR", city: "KENO" },
  "97630": { state: "OR", city: "LAKEVIEW" },
  "97632": { state: "OR", city: "MALIN" },
  "97633": { state: "OR", city: "MERRILL" },
  "97634": { state: "OR", city: "MIDLAND" },
  "97635": { state: "OR", city: "NEW PINE CREEK" },
  "97636": { state: "OR", city: "PAISLEY" },
  "97637": { state: "OR", city: "PLUSH" },
  "97638": { state: "OR", city: "SILVER LAKE" },
  "97639": { state: "OR", city: "SPRAGUE RIVER" },
  "97640": { state: "OR", city: "SUMMER LAKE" },
  "97641": { state: "OR", city: "CHRISTMAS VALLEY" },
  "97701": { state: "OR", city: "BEND" },
  "97702": { state: "OR", city: "BEND" },
  "97703": { state: "OR", city: "BEND" },
  "97707": { state: "OR", city: "BEND" },
  "97708": { state: "OR", city: "BEND" },
  "97709": { state: "OR", city: "BEND" },
  "97710": { state: "OR", city: "FIELDS" },
  "97711": { state: "OR", city: "ASHWOOD" },
  "97712": { state: "OR", city: "BROTHERS" },
  "97720": { state: "OR", city: "BURNS" },
  "97721": { state: "OR", city: "PRINCETON" },
  "97722": { state: "OR", city: "DIAMOND" },
  "97730": { state: "OR", city: "CAMP SHERMAN" },
  "97731": { state: "OR", city: "CHEMULT" },
  "97732": { state: "OR", city: "CRANE" },
  "97733": { state: "OR", city: "CRESCENT" },
  "97734": { state: "OR", city: "CULVER" },
  "97735": { state: "OR", city: "FORT ROCK" },
  "97736": { state: "OR", city: "FRENCHGLEN" },
  "97737": { state: "OR", city: "GILCHRIST" },
  "97738": { state: "OR", city: "HINES" },
  "97739": { state: "OR", city: "LA PINE" },
  "97741": { state: "OR", city: "MADRAS" },
  "97750": { state: "OR", city: "MITCHELL" },
  "97751": { state: "OR", city: "PAULINA" },
  "97752": { state: "OR", city: "POST" },
  "97753": { state: "OR", city: "POWELL BUTTE" },
  "97754": { state: "OR", city: "PRINEVILLE" },
  "97756": { state: "OR", city: "REDMOND" },
  "97758": { state: "OR", city: "RILEY" },
  "97759": { state: "OR", city: "SISTERS" },
  "97760": { state: "OR", city: "TERREBONNE" },
  "97761": { state: "OR", city: "WARM SPRINGS" },
  "97801": { state: "OR", city: "PENDLETON" },
  "97810": { state: "OR", city: "ADAMS" },
  "97812": { state: "OR", city: "ARLINGTON" },
  "97813": { state: "OR", city: "ATHENA" },
  "97814": { state: "OR", city: "BAKER CITY" },
  "97817": { state: "OR", city: "BATES" },
  "97818": { state: "OR", city: "BOARDMAN" },
  "97819": { state: "OR", city: "BRIDGEPORT" },
  "97820": { state: "OR", city: "CANYON CITY" },
  "97823": { state: "OR", city: "CONDON" },
  "97824": { state: "OR", city: "COVE" },
  "97825": { state: "OR", city: "DAYVILLE" },
  "97826": { state: "OR", city: "ECHO" },
  "97827": { state: "OR", city: "ELGIN" },
  "97828": { state: "OR", city: "ENTERPRISE" },
  "97830": { state: "OR", city: "FOSSIL" },
  "97833": { state: "OR", city: "HAINES" },
  "97834": { state: "OR", city: "HALFWAY" },
  "97835": { state: "OR", city: "HELIX" },
  "97836": { state: "OR", city: "HEPPNER" },
  "97837": { state: "OR", city: "HEREFORD" },
  "97838": { state: "OR", city: "HERMISTON" },
  "97839": { state: "OR", city: "LEXINGTON" },
  "97840": { state: "OR", city: "OXBOW" },
  "97841": { state: "OR", city: "IMBLER" },
  "97842": { state: "OR", city: "IMNAHA" },
  "97843": { state: "OR", city: "IONE" },
  "97844": { state: "OR", city: "IRRIGON" },
  "97845": { state: "OR", city: "JOHN DAY" },
  "97846": { state: "OR", city: "JOSEPH" },
  "97848": { state: "OR", city: "KIMBERLY" },
  "97850": { state: "OR", city: "LA GRANDE" },
  "97856": { state: "OR", city: "LONG CREEK" },
  "97857": { state: "OR", city: "LOSTINE" },
  "97859": { state: "OR", city: "MEACHAM" },
  "97861": { state: "OR", city: "MIKKALO" },
  "97862": { state: "OR", city: "MILTON FREEWATER" },
  "97864": { state: "OR", city: "MONUMENT" },
  "97865": { state: "OR", city: "MOUNT VERNON" },
  "97867": { state: "OR", city: "NORTH POWDER" },
  "97868": { state: "OR", city: "PILOT ROCK" },
  "97869": { state: "OR", city: "PRAIRIE CITY" },
  "97870": { state: "OR", city: "RICHLAND" },
  "97873": { state: "OR", city: "SENECA" },
  "97874": { state: "OR", city: "SPRAY" },
  "97875": { state: "OR", city: "STANFIELD" },
  "97876": { state: "OR", city: "SUMMERVILLE" },
  "97877": { state: "OR", city: "SUMPTER" },
  "97880": { state: "OR", city: "UKIAH" },
  "97882": { state: "OR", city: "UMATILLA" },
  "97883": { state: "OR", city: "UNION" },
  "97884": { state: "OR", city: "UNITY" },
  "97885": { state: "OR", city: "WALLOWA" },
  "97886": { state: "OR", city: "WESTON" },
  "97901": { state: "OR", city: "ADRIAN" },
  "97902": { state: "OR", city: "AROCK" },
  "97903": { state: "OR", city: "BROGAN" },
  "97904": { state: "OR", city: "DREWSEY" },
  "97905": { state: "OR", city: "DURKEE" },
  "97906": { state: "OR", city: "HARPER" },
  "97907": { state: "OR", city: "HUNTINGTON" },
  "97908": { state: "OR", city: "IRONSIDE" },
  "97909": { state: "OR", city: "JAMIESON" },
  "97910": { state: "OR", city: "JORDAN VALLEY" },
  "97911": { state: "OR", city: "JUNTURA" },
  "97913": { state: "OR", city: "NYSSA" },
  "97914": { state: "OR", city: "ONTARIO" },
  "97917": { state: "OR", city: "RIVERSIDE" },
  "97918": { state: "OR", city: "VALE" },
  "97920": { state: "OR", city: "WESTFALL" },
  "98001": { state: "WA", city: "AUBURN" },
  "98002": { state: "WA", city: "AUBURN" },
  "98003": { state: "WA", city: "FEDERAL WAY" },
  "98004": { state: "WA", city: "BELLEVUE" },
  "98005": { state: "WA", city: "BELLEVUE" },
  "98006": { state: "WA", city: "BELLEVUE" },
  "98007": { state: "WA", city: "BELLEVUE" },
  "98008": { state: "WA", city: "BELLEVUE" },
  "98009": { state: "WA", city: "BELLEVUE" },
  "98010": { state: "WA", city: "BLACK DIAMOND" },
  "98011": { state: "WA", city: "BOTHELL" },
  "98012": { state: "WA", city: "BOTHELL" },
  "98013": { state: "WA", city: "BURTON" },
  "98014": { state: "WA", city: "CARNATION" },
  "98015": { state: "WA", city: "BELLEVUE" },
  "98019": { state: "WA", city: "DUVALL" },
  "98020": { state: "WA", city: "EDMONDS" },
  "98021": { state: "WA", city: "BOTHELL" },
  "98022": { state: "WA", city: "ENUMCLAW" },
  "98023": { state: "WA", city: "FEDERAL WAY" },
  "98024": { state: "WA", city: "FALL CITY" },
  "98025": { state: "WA", city: "HOBART" },
  "98026": { state: "WA", city: "EDMONDS" },
  "98027": { state: "WA", city: "ISSAQUAH" },
  "98028": { state: "WA", city: "KENMORE" },
  "98029": { state: "WA", city: "ISSAQUAH" },
  "98030": { state: "WA", city: "KENT" },
  "98031": { state: "WA", city: "KENT" },
  "98032": { state: "WA", city: "KENT" },
  "98033": { state: "WA", city: "KIRKLAND" },
  "98034": { state: "WA", city: "KIRKLAND" },
  "98035": { state: "WA", city: "KENT" },
  "98036": { state: "WA", city: "LYNNWOOD" },
  "98037": { state: "WA", city: "LYNNWOOD" },
  "98038": { state: "WA", city: "MAPLE VALLEY" },
  "98039": { state: "WA", city: "MEDINA" },
  "98040": { state: "WA", city: "MERCER ISLAND" },
  "98041": { state: "WA", city: "BOTHELL" },
  "98042": { state: "WA", city: "KENT" },
  "98043": { state: "WA", city: "MOUNTLAKE TERRACE" },
  "98045": { state: "WA", city: "NORTH BEND" },
  "98046": { state: "WA", city: "LYNNWOOD" },
  "98047": { state: "WA", city: "PACIFIC" },
  "98050": { state: "WA", city: "PRESTON" },
  "98051": { state: "WA", city: "RAVENSDALE" },
  "98052": { state: "WA", city: "REDMOND" },
  "98053": { state: "WA", city: "REDMOND" },
  "98055": { state: "WA", city: "RENTON" },
  "98056": { state: "WA", city: "RENTON" },
  "98057": { state: "WA", city: "RENTON" },
  "98058": { state: "WA", city: "RENTON" },
  "98059": { state: "WA", city: "RENTON" },
  "98061": { state: "WA", city: "ROLLINGBAY" },
  "98062": { state: "WA", city: "SEAHURST" },
  "98063": { state: "WA", city: "FEDERAL WAY" },
  "98064": { state: "WA", city: "KENT" },
  "98065": { state: "WA", city: "SNOQUALMIE" },
  "98068": { state: "WA", city: "SNOQUALMIE PASS" },
  "98070": { state: "WA", city: "VASHON" },
  "98071": { state: "WA", city: "AUBURN" },
  "98072": { state: "WA", city: "WOODINVILLE" },
  "98073": { state: "WA", city: "REDMOND" },
  "98074": { state: "WA", city: "SAMMAMISH" },
  "98075": { state: "WA", city: "SAMMAMISH" },
  "98077": { state: "WA", city: "WOODINVILLE" },
  "98082": { state: "WA", city: "MILL CREEK" },
  "98083": { state: "WA", city: "KIRKLAND" },
  "98087": { state: "WA", city: "LYNNWOOD" },
  "98089": { state: "WA", city: "KENT" },
  "98092": { state: "WA", city: "AUBURN" },
  "98093": { state: "WA", city: "FEDERAL WAY" },
  "98101": { state: "WA", city: "SEATTLE" },
  "98102": { state: "WA", city: "SEATTLE" },
  "98103": { state: "WA", city: "SEATTLE" },
  "98104": { state: "WA", city: "SEATTLE" },
  "98105": { state: "WA", city: "SEATTLE" },
  "98106": { state: "WA", city: "SEATTLE" },
  "98107": { state: "WA", city: "SEATTLE" },
  "98108": { state: "WA", city: "SEATTLE" },
  "98109": { state: "WA", city: "SEATTLE" },
  "98110": { state: "WA", city: "BAINBRIDGE ISLAND" },
  "98111": { state: "WA", city: "SEATTLE" },
  "98112": { state: "WA", city: "SEATTLE" },
  "98113": { state: "WA", city: "SEATTLE" },
  "98114": { state: "WA", city: "SEATTLE" },
  "98115": { state: "WA", city: "SEATTLE" },
  "98116": { state: "WA", city: "SEATTLE" },
  "98117": { state: "WA", city: "SEATTLE" },
  "98118": { state: "WA", city: "SEATTLE" },
  "98119": { state: "WA", city: "SEATTLE" },
  "98121": { state: "WA", city: "SEATTLE" },
  "98122": { state: "WA", city: "SEATTLE" },
  "98124": { state: "WA", city: "SEATTLE" },
  "98125": { state: "WA", city: "SEATTLE" },
  "98126": { state: "WA", city: "SEATTLE" },
  "98127": { state: "WA", city: "SEATTLE" },
  "98129": { state: "WA", city: "SEATTLE" },
  "98131": { state: "WA", city: "SEATTLE" },
  "98133": { state: "WA", city: "SEATTLE" },
  "98134": { state: "WA", city: "SEATTLE" },
  "98136": { state: "WA", city: "SEATTLE" },
  "98138": { state: "WA", city: "SEATTLE" },
  "98139": { state: "WA", city: "SEATTLE" },
  "98141": { state: "WA", city: "SEATTLE" },
  "98144": { state: "WA", city: "SEATTLE" },
  "98145": { state: "WA", city: "SEATTLE" },
  "98146": { state: "WA", city: "SEATTLE" },
  "98148": { state: "WA", city: "SEATTLE" },
  "98154": { state: "WA", city: "SEATTLE" },
  "98155": { state: "WA", city: "SEATTLE" },
  "98158": { state: "WA", city: "SEATTLE" },
  "98160": { state: "WA", city: "SEATTLE" },
  "98161": { state: "WA", city: "SEATTLE" },
  "98164": { state: "WA", city: "SEATTLE" },
  "98165": { state: "WA", city: "SEATTLE" },
  "98166": { state: "WA", city: "SEATTLE" },
  "98168": { state: "WA", city: "SEATTLE" },
  "98170": { state: "WA", city: "SEATTLE" },
  "98174": { state: "WA", city: "SEATTLE" },
  "98175": { state: "WA", city: "SEATTLE" },
  "98177": { state: "WA", city: "SEATTLE" },
  "98178": { state: "WA", city: "SEATTLE" },
  "98181": { state: "WA", city: "SEATTLE" },
  "98185": { state: "WA", city: "SEATTLE" },
  "98188": { state: "WA", city: "SEATTLE" },
  "98190": { state: "WA", city: "SEATTLE" },
  "98191": { state: "WA", city: "SEATTLE" },
  "98194": { state: "WA", city: "SEATTLE" },
  "98195": { state: "WA", city: "SEATTLE" },
  "98198": { state: "WA", city: "SEATTLE" },
  "98199": { state: "WA", city: "SEATTLE" },
  "98201": { state: "WA", city: "EVERETT" },
  "98203": { state: "WA", city: "EVERETT" },
  "98204": { state: "WA", city: "EVERETT" },
  "98206": { state: "WA", city: "EVERETT" },
  "98207": { state: "WA", city: "EVERETT" },
  "98208": { state: "WA", city: "EVERETT" },
  "98213": { state: "WA", city: "EVERETT" },
  "98220": { state: "WA", city: "ACME" },
  "98221": { state: "WA", city: "ANACORTES" },
  "98222": { state: "WA", city: "BLAKELY ISLAND" },
  "98223": { state: "WA", city: "ARLINGTON" },
  "98224": { state: "WA", city: "BARING" },
  "98225": { state: "WA", city: "BELLINGHAM" },
  "98226": { state: "WA", city: "BELLINGHAM" },
  "98227": { state: "WA", city: "BELLINGHAM" },
  "98228": { state: "WA", city: "BELLINGHAM" },
  "98229": { state: "WA", city: "BELLINGHAM" },
  "98230": { state: "WA", city: "BLAINE" },
  "98231": { state: "WA", city: "BLAINE" },
  "98232": { state: "WA", city: "BOW" },
  "98233": { state: "WA", city: "BURLINGTON" },
  "98235": { state: "WA", city: "CLEARLAKE" },
  "98236": { state: "WA", city: "CLINTON" },
  "98237": { state: "WA", city: "CONCRETE" },
  "98238": { state: "WA", city: "CONWAY" },
  "98239": { state: "WA", city: "COUPEVILLE" },
  "98240": { state: "WA", city: "CUSTER" },
  "98241": { state: "WA", city: "DARRINGTON" },
  "98243": { state: "WA", city: "DEER HARBOR" },
  "98244": { state: "WA", city: "DEMING" },
  "98245": { state: "WA", city: "EASTSOUND" },
  "98247": { state: "WA", city: "EVERSON" },
  "98248": { state: "WA", city: "FERNDALE" },
  "98249": { state: "WA", city: "FREELAND" },
  "98250": { state: "WA", city: "FRIDAY HARBOR" },
  "98251": { state: "WA", city: "GOLD BAR" },
  "98252": { state: "WA", city: "GRANITE FALLS" },
  "98253": { state: "WA", city: "GREENBANK" },
  "98255": { state: "WA", city: "HAMILTON" },
  "98256": { state: "WA", city: "INDEX" },
  "98257": { state: "WA", city: "LA CONNER" },
  "98258": { state: "WA", city: "LAKE STEVENS" },
  "98259": { state: "WA", city: "NORTH LAKEWOOD" },
  "98260": { state: "WA", city: "LANGLEY" },
  "98261": { state: "WA", city: "LOPEZ ISLAND" },
  "98262": { state: "WA", city: "LUMMI ISLAND" },
  "98263": { state: "WA", city: "LYMAN" },
  "98264": { state: "WA", city: "LYNDEN" },
  "98266": { state: "WA", city: "MAPLE FALLS" },
  "98267": { state: "WA", city: "MARBLEMOUNT" },
  "98270": { state: "WA", city: "MARYSVILLE" },
  "98271": { state: "WA", city: "MARYSVILLE" },
  "98272": { state: "WA", city: "MONROE" },
  "98273": { state: "WA", city: "MOUNT VERNON" },
  "98274": { state: "WA", city: "MOUNT VERNON" },
  "98275": { state: "WA", city: "MUKILTEO" },
  "98276": { state: "WA", city: "NOOKSACK" },
  "98277": { state: "WA", city: "OAK HARBOR" },
  "98278": { state: "WA", city: "OAK HARBOR" },
  "98279": { state: "WA", city: "OLGA" },
  "98280": { state: "WA", city: "ORCAS" },
  "98281": { state: "WA", city: "POINT ROBERTS" },
  "98282": { state: "WA", city: "CAMANO ISLAND" },
  "98283": { state: "WA", city: "ROCKPORT" },
  "98284": { state: "WA", city: "SEDRO WOOLLEY" },
  "98286": { state: "WA", city: "SHAW ISLAND" },
  "98287": { state: "WA", city: "SILVANA" },
  "98288": { state: "WA", city: "SKYKOMISH" },
  "98290": { state: "WA", city: "SNOHOMISH" },
  "98291": { state: "WA", city: "SNOHOMISH" },
  "98292": { state: "WA", city: "STANWOOD" },
  "98293": { state: "WA", city: "STARTUP" },
  "98294": { state: "WA", city: "SULTAN" },
  "98295": { state: "WA", city: "SUMAS" },
  "98296": { state: "WA", city: "SNOHOMISH" },
  "98297": { state: "WA", city: "WALDRON" },
  "98303": { state: "WA", city: "ANDERSON ISLAND" },
  "98304": { state: "WA", city: "ASHFORD" },
  "98305": { state: "WA", city: "BEAVER" },
  "98310": { state: "WA", city: "BREMERTON" },
  "98311": { state: "WA", city: "BREMERTON" },
  "98312": { state: "WA", city: "BREMERTON" },
  "98314": { state: "WA", city: "BREMERTON" },
  "98315": { state: "WA", city: "SILVERDALE" },
  "98320": { state: "WA", city: "BRINNON" },
  "98321": { state: "WA", city: "BUCKLEY" },
  "98322": { state: "WA", city: "BURLEY" },
  "98323": { state: "WA", city: "CARBONADO" },
  "98324": { state: "WA", city: "CARLSBORG" },
  "98325": { state: "WA", city: "CHIMACUM" },
  "98326": { state: "WA", city: "CLALLAM BAY" },
  "98327": { state: "WA", city: "DUPONT" },
  "98328": { state: "WA", city: "EATONVILLE" },
  "98329": { state: "WA", city: "GIG HARBOR" },
  "98330": { state: "WA", city: "ELBE" },
  "98331": { state: "WA", city: "FORKS" },
  "98332": { state: "WA", city: "GIG HARBOR" },
  "98333": { state: "WA", city: "FOX ISLAND" },
  "98335": { state: "WA", city: "GIG HARBOR" },
  "98336": { state: "WA", city: "GLENOMA" },
  "98337": { state: "WA", city: "BREMERTON" },
  "98338": { state: "WA", city: "GRAHAM" },
  "98339": { state: "WA", city: "PORT HADLOCK" },
  "98340": { state: "WA", city: "HANSVILLE" },
  "98342": { state: "WA", city: "INDIANOLA" },
  "98343": { state: "WA", city: "JOYCE" },
  "98344": { state: "WA", city: "KAPOWSIN" },
  "98345": { state: "WA", city: "KEYPORT" },
  "98346": { state: "WA", city: "KINGSTON" },
  "98348": { state: "WA", city: "LA GRANDE" },
  "98349": { state: "WA", city: "LAKEBAY" },
  "98350": { state: "WA", city: "LA PUSH" },
  "98351": { state: "WA", city: "LONGBRANCH" },
  "98352": { state: "WA", city: "SUMNER" },
  "98353": { state: "WA", city: "MANCHESTER" },
  "98354": { state: "WA", city: "MILTON" },
  "98355": { state: "WA", city: "MINERAL" },
  "98356": { state: "WA", city: "MORTON" },
  "98357": { state: "WA", city: "NEAH BAY" },
  "98358": { state: "WA", city: "NORDLAND" },
  "98359": { state: "WA", city: "OLALLA" },
  "98360": { state: "WA", city: "ORTING" },
  "98361": { state: "WA", city: "PACKWOOD" },
  "98362": { state: "WA", city: "PORT ANGELES" },
  "98363": { state: "WA", city: "PORT ANGELES" },
  "98364": { state: "WA", city: "PORT GAMBLE" },
  "98365": { state: "WA", city: "PORT LUDLOW" },
  "98366": { state: "WA", city: "PORT ORCHARD" },
  "98367": { state: "WA", city: "PORT ORCHARD" },
  "98368": { state: "WA", city: "PORT TOWNSEND" },
  "98370": { state: "WA", city: "POULSBO" },
  "98371": { state: "WA", city: "PUYALLUP" },
  "98372": { state: "WA", city: "PUYALLUP" },
  "98373": { state: "WA", city: "PUYALLUP" },
  "98374": { state: "WA", city: "PUYALLUP" },
  "98375": { state: "WA", city: "PUYALLUP" },
  "98376": { state: "WA", city: "QUILCENE" },
  "98377": { state: "WA", city: "RANDLE" },
  "98378": { state: "WA", city: "RETSIL" },
  "98380": { state: "WA", city: "SEABECK" },
  "98381": { state: "WA", city: "SEKIU" },
  "98382": { state: "WA", city: "SEQUIM" },
  "98383": { state: "WA", city: "SILVERDALE" },
  "98384": { state: "WA", city: "SOUTH COLBY" },
  "98385": { state: "WA", city: "SOUTH PRAIRIE" },
  "98386": { state: "WA", city: "SOUTHWORTH" },
  "98387": { state: "WA", city: "SPANAWAY" },
  "98388": { state: "WA", city: "STEILACOOM" },
  "98390": { state: "WA", city: "SUMNER" },
  "98391": { state: "WA", city: "BONNEY LAKE" },
  "98392": { state: "WA", city: "SUQUAMISH" },
  "98393": { state: "WA", city: "TRACYTON" },
  "98394": { state: "WA", city: "VAUGHN" },
  "98395": { state: "WA", city: "WAUNA" },
  "98396": { state: "WA", city: "WILKESON" },
  "98397": { state: "WA", city: "LONGMIRE" },
  "98398": { state: "WA", city: "PARADISE INN" },
  "98401": { state: "WA", city: "TACOMA" },
  "98402": { state: "WA", city: "TACOMA" },
  "98403": { state: "WA", city: "TACOMA" },
  "98404": { state: "WA", city: "TACOMA" },
  "98405": { state: "WA", city: "TACOMA" },
  "98406": { state: "WA", city: "TACOMA" },
  "98407": { state: "WA", city: "TACOMA" },
  "98408": { state: "WA", city: "TACOMA" },
  "98409": { state: "WA", city: "TACOMA" },
  "98411": { state: "WA", city: "TACOMA" },
  "98412": { state: "WA", city: "TACOMA" },
  "98413": { state: "WA", city: "TACOMA" },
  "98415": { state: "WA", city: "TACOMA" },
  "98416": { state: "WA", city: "TACOMA" },
  "98417": { state: "WA", city: "TACOMA" },
  "98418": { state: "WA", city: "TACOMA" },
  "98419": { state: "WA", city: "TACOMA" },
  "98421": { state: "WA", city: "TACOMA" },
  "98422": { state: "WA", city: "TACOMA" },
  "98424": { state: "WA", city: "FIFE" },
  "98430": { state: "WA", city: "CAMP MURRAY" },
  "98431": { state: "WA", city: "TACOMA" },
  "98433": { state: "WA", city: "TACOMA" },
  "98438": { state: "WA", city: "MCCHORD AFB" },
  "98439": { state: "WA", city: "LAKEWOOD" },
  "98443": { state: "WA", city: "TACOMA" },
  "98444": { state: "WA", city: "TACOMA" },
  "98445": { state: "WA", city: "TACOMA" },
  "98446": { state: "WA", city: "TACOMA" },
  "98447": { state: "WA", city: "TACOMA" },
  "98448": { state: "WA", city: "TACOMA" },
  "98464": { state: "WA", city: "TACOMA" },
  "98465": { state: "WA", city: "TACOMA" },
  "98466": { state: "WA", city: "TACOMA" },
  "98467": { state: "WA", city: "UNIVERSITY PLACE" },
  "98471": { state: "WA", city: "TACOMA" },
  "98481": { state: "WA", city: "TACOMA" },
  "98490": { state: "WA", city: "TACOMA" },
  "98493": { state: "WA", city: "TACOMA" },
  "98496": { state: "WA", city: "LAKEWOOD" },
  "98497": { state: "WA", city: "LAKEWOOD" },
  "98498": { state: "WA", city: "LAKEWOOD" },
  "98499": { state: "WA", city: "LAKEWOOD" },
  "98501": { state: "WA", city: "OLYMPIA" },
  "98502": { state: "WA", city: "OLYMPIA" },
  "98503": { state: "WA", city: "LACEY" },
  "98504": { state: "WA", city: "OLYMPIA" },
  "98505": { state: "WA", city: "OLYMPIA" },
  "98506": { state: "WA", city: "OLYMPIA" },
  "98507": { state: "WA", city: "OLYMPIA" },
  "98508": { state: "WA", city: "OLYMPIA" },
  "98509": { state: "WA", city: "LACEY" },
  "98511": { state: "WA", city: "TUMWATER" },
  "98512": { state: "WA", city: "OLYMPIA" },
  "98513": { state: "WA", city: "OLYMPIA" },
  "98516": { state: "WA", city: "OLYMPIA" },
  "98520": { state: "WA", city: "ABERDEEN" },
  "98522": { state: "WA", city: "ADNA" },
  "98524": { state: "WA", city: "ALLYN" },
  "98526": { state: "WA", city: "AMANDA PARK" },
  "98527": { state: "WA", city: "BAY CENTER" },
  "98528": { state: "WA", city: "BELFAIR" },
  "98530": { state: "WA", city: "BUCODA" },
  "98531": { state: "WA", city: "CENTRALIA" },
  "98532": { state: "WA", city: "CHEHALIS" },
  "98533": { state: "WA", city: "CINEBAR" },
  "98535": { state: "WA", city: "COPALIS BEACH" },
  "98536": { state: "WA", city: "COPALIS CROSSING" },
  "98537": { state: "WA", city: "COSMOPOLIS" },
  "98538": { state: "WA", city: "CURTIS" },
  "98539": { state: "WA", city: "DOTY" },
  "98540": { state: "WA", city: "EAST OLYMPIA" },
  "98541": { state: "WA", city: "ELMA" },
  "98542": { state: "WA", city: "ETHEL" },
  "98544": { state: "WA", city: "GALVIN" },
  "98546": { state: "WA", city: "GRAPEVIEW" },
  "98547": { state: "WA", city: "GRAYLAND" },
  "98548": { state: "WA", city: "HOODSPORT" },
  "98550": { state: "WA", city: "HOQUIAM" },
  "98552": { state: "WA", city: "HUMPTULIPS" },
  "98554": { state: "WA", city: "LEBAM" },
  "98555": { state: "WA", city: "LILLIWAUP" },
  "98556": { state: "WA", city: "LITTLEROCK" },
  "98557": { state: "WA", city: "MCCLEARY" },
  "98558": { state: "WA", city: "MCKENNA" },
  "98559": { state: "WA", city: "MALONE" },
  "98560": { state: "WA", city: "MATLOCK" },
  "98561": { state: "WA", city: "MENLO" },
  "98562": { state: "WA", city: "MOCLIPS" },
  "98563": { state: "WA", city: "MONTESANO" },
  "98564": { state: "WA", city: "MOSSYROCK" },
  "98565": { state: "WA", city: "NAPAVINE" },
  "98566": { state: "WA", city: "NEILTON" },
  "98568": { state: "WA", city: "OAKVILLE" },
  "98569": { state: "WA", city: "OCEAN SHORES" },
  "98570": { state: "WA", city: "ONALASKA" },
  "98571": { state: "WA", city: "PACIFIC BEACH" },
  "98572": { state: "WA", city: "PE ELL" },
  "98575": { state: "WA", city: "QUINAULT" },
  "98576": { state: "WA", city: "RAINIER" },
  "98577": { state: "WA", city: "RAYMOND" },
  "98579": { state: "WA", city: "ROCHESTER" },
  "98580": { state: "WA", city: "ROY" },
  "98581": { state: "WA", city: "RYDERWOOD" },
  "98582": { state: "WA", city: "SALKUM" },
  "98583": { state: "WA", city: "SATSOP" },
  "98584": { state: "WA", city: "SHELTON" },
  "98585": { state: "WA", city: "SILVER CREEK" },
  "98586": { state: "WA", city: "SOUTH BEND" },
  "98587": { state: "WA", city: "TAHOLAH" },
  "98588": { state: "WA", city: "TAHUYA" },
  "98589": { state: "WA", city: "TENINO" },
  "98590": { state: "WA", city: "TOKELAND" },
  "98591": { state: "WA", city: "TOLEDO" },
  "98592": { state: "WA", city: "UNION" },
  "98593": { state: "WA", city: "VADER" },
  "98595": { state: "WA", city: "WESTPORT" },
  "98596": { state: "WA", city: "WINLOCK" },
  "98597": { state: "WA", city: "YELM" },
  "98599": { state: "WA", city: "OLYMPIA" },
  "98601": { state: "WA", city: "AMBOY" },
  "98602": { state: "WA", city: "APPLETON" },
  "98603": { state: "WA", city: "ARIEL" },
  "98604": { state: "WA", city: "BATTLE GROUND" },
  "98605": { state: "WA", city: "BINGEN" },
  "98606": { state: "WA", city: "BRUSH PRAIRIE" },
  "98607": { state: "WA", city: "CAMAS" },
  "98609": { state: "WA", city: "CARROLLS" },
  "98610": { state: "WA", city: "CARSON" },
  "98611": { state: "WA", city: "CASTLE ROCK" },
  "98612": { state: "WA", city: "CATHLAMET" },
  "98613": { state: "WA", city: "CENTERVILLE" },
  "98614": { state: "WA", city: "CHINOOK" },
  "98616": { state: "WA", city: "COUGAR" },
  "98617": { state: "WA", city: "DALLESPORT" },
  "98619": { state: "WA", city: "GLENWOOD" },
  "98620": { state: "WA", city: "GOLDENDALE" },
  "98621": { state: "WA", city: "GRAYS RIVER" },
  "98622": { state: "WA", city: "HEISSON" },
  "98623": { state: "WA", city: "HUSUM" },
  "98624": { state: "WA", city: "ILWACO" },
  "98625": { state: "WA", city: "KALAMA" },
  "98626": { state: "WA", city: "KELSO" },
  "98628": { state: "WA", city: "KLICKITAT" },
  "98629": { state: "WA", city: "LA CENTER" },
  "98631": { state: "WA", city: "LONG BEACH" },
  "98632": { state: "WA", city: "LONGVIEW" },
  "98635": { state: "WA", city: "LYLE" },
  "98637": { state: "WA", city: "NAHCOTTA" },
  "98638": { state: "WA", city: "NASELLE" },
  "98639": { state: "WA", city: "NORTH BONNEVILLE" },
  "98640": { state: "WA", city: "OCEAN PARK" },
  "98641": { state: "WA", city: "OYSTERVILLE" },
  "98642": { state: "WA", city: "RIDGEFIELD" },
  "98643": { state: "WA", city: "ROSBURG" },
  "98644": { state: "WA", city: "SEAVIEW" },
  "98645": { state: "WA", city: "SILVERLAKE" },
  "98647": { state: "WA", city: "SKAMOKAWA" },
  "98648": { state: "WA", city: "STEVENSON" },
  "98649": { state: "WA", city: "TOUTLE" },
  "98650": { state: "WA", city: "TROUT LAKE" },
  "98651": { state: "WA", city: "UNDERWOOD" },
  "98660": { state: "WA", city: "VANCOUVER" },
  "98661": { state: "WA", city: "VANCOUVER" },
  "98662": { state: "WA", city: "VANCOUVER" },
  "98663": { state: "WA", city: "VANCOUVER" },
  "98664": { state: "WA", city: "VANCOUVER" },
  "98665": { state: "WA", city: "VANCOUVER" },
  "98666": { state: "WA", city: "VANCOUVER" },
  "98668": { state: "WA", city: "VANCOUVER" },
  "98670": { state: "WA", city: "WAHKIACUS" },
  "98671": { state: "WA", city: "WASHOUGAL" },
  "98672": { state: "WA", city: "WHITE SALMON" },
  "98673": { state: "WA", city: "WISHRAM" },
  "98674": { state: "WA", city: "WOODLAND" },
  "98675": { state: "WA", city: "YACOLT" },
  "98682": { state: "WA", city: "VANCOUVER" },
  "98683": { state: "WA", city: "VANCOUVER" },
  "98684": { state: "WA", city: "VANCOUVER" },
  "98685": { state: "WA", city: "VANCOUVER" },
  "98686": { state: "WA", city: "VANCOUVER" },
  "98687": { state: "WA", city: "VANCOUVER" },
  "98801": { state: "WA", city: "WENATCHEE" },
  "98802": { state: "WA", city: "EAST WENATCHEE" },
  "98807": { state: "WA", city: "WENATCHEE" },
  "98811": { state: "WA", city: "ARDENVOIR" },
  "98812": { state: "WA", city: "BREWSTER" },
  "98813": { state: "WA", city: "BRIDGEPORT" },
  "98814": { state: "WA", city: "CARLTON" },
  "98815": { state: "WA", city: "CASHMERE" },
  "98816": { state: "WA", city: "CHELAN" },
  "98817": { state: "WA", city: "CHELAN FALLS" },
  "98819": { state: "WA", city: "CONCONULLY" },
  "98821": { state: "WA", city: "DRYDEN" },
  "98822": { state: "WA", city: "ENTIAT" },
  "98823": { state: "WA", city: "EPHRATA" },
  "98824": { state: "WA", city: "GEORGE" },
  "98826": { state: "WA", city: "LEAVENWORTH" },
  "98827": { state: "WA", city: "LOOMIS" },
  "98828": { state: "WA", city: "MALAGA" },
  "98829": { state: "WA", city: "MALOTT" },
  "98830": { state: "WA", city: "MANSFIELD" },
  "98831": { state: "WA", city: "MANSON" },
  "98832": { state: "WA", city: "MARLIN" },
  "98833": { state: "WA", city: "MAZAMA" },
  "98834": { state: "WA", city: "METHOW" },
  "98836": { state: "WA", city: "MONITOR" },
  "98837": { state: "WA", city: "MOSES LAKE" },
  "98840": { state: "WA", city: "OKANOGAN" },
  "98841": { state: "WA", city: "OMAK" },
  "98843": { state: "WA", city: "ORONDO" },
  "98844": { state: "WA", city: "OROVILLE" },
  "98845": { state: "WA", city: "PALISADES" },
  "98846": { state: "WA", city: "PATEROS" },
  "98847": { state: "WA", city: "PESHASTIN" },
  "98848": { state: "WA", city: "QUINCY" },
  "98849": { state: "WA", city: "RIVERSIDE" },
  "98850": { state: "WA", city: "ROCK ISLAND" },
  "98851": { state: "WA", city: "SOAP LAKE" },
  "98852": { state: "WA", city: "STEHEKIN" },
  "98853": { state: "WA", city: "STRATFORD" },
  "98855": { state: "WA", city: "TONASKET" },
  "98856": { state: "WA", city: "TWISP" },
  "98857": { state: "WA", city: "WARDEN" },
  "98858": { state: "WA", city: "WATERVILLE" },
  "98859": { state: "WA", city: "WAUCONDA" },
  "98860": { state: "WA", city: "WILSON CREEK" },
  "98862": { state: "WA", city: "WINTHROP" },
  "98901": { state: "WA", city: "YAKIMA" },
  "98902": { state: "WA", city: "YAKIMA" },
  "98903": { state: "WA", city: "YAKIMA" },
  "98904": { state: "WA", city: "YAKIMA" },
  "98907": { state: "WA", city: "YAKIMA" },
  "98908": { state: "WA", city: "YAKIMA" },
  "98909": { state: "WA", city: "YAKIMA" },
  "98920": { state: "WA", city: "BROWNSTOWN" },
  "98921": { state: "WA", city: "BUENA" },
  "98922": { state: "WA", city: "CLE ELUM" },
  "98923": { state: "WA", city: "COWICHE" },
  "98925": { state: "WA", city: "EASTON" },
  "98926": { state: "WA", city: "ELLENSBURG" },
  "98930": { state: "WA", city: "GRANDVIEW" },
  "98932": { state: "WA", city: "GRANGER" },
  "98933": { state: "WA", city: "HARRAH" },
  "98934": { state: "WA", city: "KITTITAS" },
  "98935": { state: "WA", city: "MABTON" },
  "98936": { state: "WA", city: "MOXEE" },
  "98937": { state: "WA", city: "NACHES" },
  "98938": { state: "WA", city: "OUTLOOK" },
  "98939": { state: "WA", city: "PARKER" },
  "98940": { state: "WA", city: "RONALD" },
  "98941": { state: "WA", city: "ROSLYN" },
  "98942": { state: "WA", city: "SELAH" },
  "98943": { state: "WA", city: "SOUTH CLE ELUM" },
  "98944": { state: "WA", city: "SUNNYSIDE" },
  "98946": { state: "WA", city: "THORP" },
  "98947": { state: "WA", city: "TIETON" },
  "98948": { state: "WA", city: "TOPPENISH" },
  "98950": { state: "WA", city: "VANTAGE" },
  "98951": { state: "WA", city: "WAPATO" },
  "98952": { state: "WA", city: "WHITE SWAN" },
  "98953": { state: "WA", city: "ZILLAH" },
  "99001": { state: "WA", city: "AIRWAY HEIGHTS" },
  "99003": { state: "WA", city: "CHATTAROY" },
  "99004": { state: "WA", city: "CHENEY" },
  "99005": { state: "WA", city: "COLBERT" },
  "99006": { state: "WA", city: "DEER PARK" },
  "99008": { state: "WA", city: "EDWALL" },
  "99009": { state: "WA", city: "ELK" },
  "99011": { state: "WA", city: "FAIRCHILD AIR FORCE BASE" },
  "99012": { state: "WA", city: "FAIRFIELD" },
  "99013": { state: "WA", city: "FORD" },
  "99014": { state: "WA", city: "FOUR LAKES" },
  "99016": { state: "WA", city: "GREENACRES" },
  "99017": { state: "WA", city: "LAMONT" },
  "99018": { state: "WA", city: "LATAH" },
  "99019": { state: "WA", city: "LIBERTY LAKE" },
  "99020": { state: "WA", city: "MARSHALL" },
  "99021": { state: "WA", city: "MEAD" },
  "99022": { state: "WA", city: "MEDICAL LAKE" },
  "99023": { state: "WA", city: "MICA" },
  "99025": { state: "WA", city: "NEWMAN LAKE" },
  "99026": { state: "WA", city: "NINE MILE FALLS" },
  "99027": { state: "WA", city: "OTIS ORCHARDS" },
  "99029": { state: "WA", city: "REARDAN" },
  "99030": { state: "WA", city: "ROCKFORD" },
  "99031": { state: "WA", city: "SPANGLE" },
  "99032": { state: "WA", city: "SPRAGUE" },
  "99033": { state: "WA", city: "TEKOA" },
  "99034": { state: "WA", city: "TUMTUM" },
  "99036": { state: "WA", city: "VALLEYFORD" },
  "99037": { state: "WA", city: "VERADALE" },
  "99039": { state: "WA", city: "WAVERLY" },
  "99040": { state: "WA", city: "WELLPINIT" },
  "99101": { state: "WA", city: "ADDY" },
  "99102": { state: "WA", city: "ALBION" },
  "99103": { state: "WA", city: "ALMIRA" },
  "99104": { state: "WA", city: "BELMONT" },
  "99105": { state: "WA", city: "BENGE" },
  "99109": { state: "WA", city: "CHEWELAH" },
  "99110": { state: "WA", city: "CLAYTON" },
  "99111": { state: "WA", city: "COLFAX" },
  "99113": { state: "WA", city: "COLTON" },
  "99114": { state: "WA", city: "COLVILLE" },
  "99115": { state: "WA", city: "COULEE CITY" },
  "99116": { state: "WA", city: "COULEE DAM" },
  "99117": { state: "WA", city: "CRESTON" },
  "99118": { state: "WA", city: "CURLEW" },
  "99119": { state: "WA", city: "CUSICK" },
  "99121": { state: "WA", city: "DANVILLE" },
  "99122": { state: "WA", city: "DAVENPORT" },
  "99123": { state: "WA", city: "ELECTRIC CITY" },
  "99124": { state: "WA", city: "ELMER CITY" },
  "99125": { state: "WA", city: "ENDICOTT" },
  "99126": { state: "WA", city: "EVANS" },
  "99128": { state: "WA", city: "FARMINGTON" },
  "99129": { state: "WA", city: "FRUITLAND" },
  "99130": { state: "WA", city: "GARFIELD" },
  "99131": { state: "WA", city: "GIFFORD" },
  "99133": { state: "WA", city: "GRAND COULEE" },
  "99134": { state: "WA", city: "HARRINGTON" },
  "99135": { state: "WA", city: "HARTLINE" },
  "99136": { state: "WA", city: "HAY" },
  "99137": { state: "WA", city: "HUNTERS" },
  "99138": { state: "WA", city: "INCHELIUM" },
  "99139": { state: "WA", city: "IONE" },
  "99140": { state: "WA", city: "KELLER" },
  "99141": { state: "WA", city: "KETTLE FALLS" },
  "99143": { state: "WA", city: "LACROSSE" },
  "99144": { state: "WA", city: "LAMONA" },
  "99146": { state: "WA", city: "LAURIER" },
  "99147": { state: "WA", city: "LINCOLN" },
  "99148": { state: "WA", city: "LOON LAKE" },
  "99149": { state: "WA", city: "MALDEN" },
  "99150": { state: "WA", city: "MALO" },
  "99151": { state: "WA", city: "MARCUS" },
  "99152": { state: "WA", city: "METALINE" },
  "99153": { state: "WA", city: "METALINE FALLS" },
  "99154": { state: "WA", city: "MOHLER" },
  "99155": { state: "WA", city: "NESPELEM" },
  "99156": { state: "WA", city: "NEWPORT" },
  "99157": { state: "WA", city: "NORTHPORT" },
  "99158": { state: "WA", city: "OAKESDALE" },
  "99159": { state: "WA", city: "ODESSA" },
  "99160": { state: "WA", city: "ORIENT" },
  "99161": { state: "WA", city: "PALOUSE" },
  "99163": { state: "WA", city: "PULLMAN" },
  "99164": { state: "WA", city: "PULLMAN" },
  "99166": { state: "WA", city: "REPUBLIC" },
  "99167": { state: "WA", city: "RICE" },
  "99169": { state: "WA", city: "RITZVILLE" },
  "99170": { state: "WA", city: "ROSALIA" },
  "99171": { state: "WA", city: "SAINT JOHN" },
  "99173": { state: "WA", city: "SPRINGDALE" },
  "99174": { state: "WA", city: "STEPTOE" },
  "99176": { state: "WA", city: "THORNTON" },
  "99179": { state: "WA", city: "UNIONTOWN" },
  "99180": { state: "WA", city: "USK" },
  "99181": { state: "WA", city: "VALLEY" },
  "99185": { state: "WA", city: "WILBUR" },
  "99201": { state: "WA", city: "SPOKANE" },
  "99202": { state: "WA", city: "SPOKANE" },
  "99203": { state: "WA", city: "SPOKANE" },
  "99204": { state: "WA", city: "SPOKANE" },
  "99205": { state: "WA", city: "SPOKANE" },
  "99206": { state: "WA", city: "SPOKANE" },
  "99207": { state: "WA", city: "SPOKANE" },
  "99208": { state: "WA", city: "SPOKANE" },
  "99209": { state: "WA", city: "SPOKANE" },
  "99210": { state: "WA", city: "SPOKANE" },
  "99211": { state: "WA", city: "SPOKANE" },
  "99212": { state: "WA", city: "SPOKANE" },
  "99213": { state: "WA", city: "SPOKANE" },
  "99214": { state: "WA", city: "SPOKANE" },
  "99215": { state: "WA", city: "SPOKANE" },
  "99216": { state: "WA", city: "SPOKANE" },
  "99217": { state: "WA", city: "SPOKANE" },
  "99218": { state: "WA", city: "SPOKANE" },
  "99219": { state: "WA", city: "SPOKANE" },
  "99220": { state: "WA", city: "SPOKANE" },
  "99223": { state: "WA", city: "SPOKANE" },
  "99224": { state: "WA", city: "SPOKANE" },
  "99228": { state: "WA", city: "SPOKANE" },
  "99251": { state: "WA", city: "SPOKANE" },
  "99252": { state: "WA", city: "SPOKANE" },
  "99256": { state: "WA", city: "SPOKANE" },
  "99258": { state: "WA", city: "SPOKANE" },
  "99260": { state: "WA", city: "SPOKANE" },
  "99301": { state: "WA", city: "PASCO" },
  "99302": { state: "WA", city: "PASCO" },
  "99320": { state: "WA", city: "BENTON CITY" },
  "99321": { state: "WA", city: "BEVERLY" },
  "99322": { state: "WA", city: "BICKLETON" },
  "99323": { state: "WA", city: "BURBANK" },
  "99324": { state: "WA", city: "COLLEGE PLACE" },
  "99326": { state: "WA", city: "CONNELL" },
  "99328": { state: "WA", city: "DAYTON" },
  "99329": { state: "WA", city: "DIXIE" },
  "99330": { state: "WA", city: "ELTOPIA" },
  "99333": { state: "WA", city: "HOOPER" },
  "99335": { state: "WA", city: "KAHLOTUS" },
  "99336": { state: "WA", city: "KENNEWICK" },
  "99337": { state: "WA", city: "KENNEWICK" },
  "99338": { state: "WA", city: "KENNEWICK" },
  "99341": { state: "WA", city: "LIND" },
  "99343": { state: "WA", city: "MESA" },
  "99344": { state: "WA", city: "OTHELLO" },
  "99345": { state: "WA", city: "PATERSON" },
  "99346": { state: "WA", city: "PLYMOUTH" },
  "99347": { state: "WA", city: "POMEROY" },
  "99348": { state: "WA", city: "PRESCOTT" },
  "99349": { state: "WA", city: "MATTAWA" },
  "99350": { state: "WA", city: "PROSSER" },
  "99352": { state: "WA", city: "RICHLAND" },
  "99353": { state: "WA", city: "WEST RICHLAND" },
  "99354": { state: "WA", city: "RICHLAND" },
  "99356": { state: "WA", city: "ROOSEVELT" },
  "99357": { state: "WA", city: "ROYAL CITY" },
  "99359": { state: "WA", city: "STARBUCK" },
  "99360": { state: "WA", city: "TOUCHET" },
  "99361": { state: "WA", city: "WAITSBURG" },
  "99362": { state: "WA", city: "WALLA WALLA" },
  "99363": { state: "WA", city: "WALLULA" },
  "99371": { state: "WA", city: "WASHTUCNA" },
  "99401": { state: "WA", city: "ANATONE" },
  "99402": { state: "WA", city: "ASOTIN" },
  "99403": { state: "WA", city: "CLARKSTON" },
  "99501": { state: "AK", city: "ANCHORAGE" },
  "99502": { state: "AK", city: "ANCHORAGE" },
  "99503": { state: "AK", city: "ANCHORAGE" },
  "99504": { state: "AK", city: "ANCHORAGE" },
  "99505": { state: "AK", city: "JBER" },
  "99506": { state: "AK", city: "JBER" },
  "99507": { state: "AK", city: "ANCHORAGE" },
  "99508": { state: "AK", city: "ANCHORAGE" },
  "99509": { state: "AK", city: "ANCHORAGE" },
  "99510": { state: "AK", city: "ANCHORAGE" },
  "99511": { state: "AK", city: "ANCHORAGE" },
  "99513": { state: "AK", city: "ANCHORAGE" },
  "99514": { state: "AK", city: "ANCHORAGE" },
  "99515": { state: "AK", city: "ANCHORAGE" },
  "99516": { state: "AK", city: "ANCHORAGE" },
  "99517": { state: "AK", city: "ANCHORAGE" },
  "99518": { state: "AK", city: "ANCHORAGE" },
  "99519": { state: "AK", city: "ANCHORAGE" },
  "99520": { state: "AK", city: "ANCHORAGE" },
  "99521": { state: "AK", city: "ANCHORAGE" },
  "99522": { state: "AK", city: "ANCHORAGE" },
  "99523": { state: "AK", city: "ANCHORAGE" },
  "99524": { state: "AK", city: "ANCHORAGE" },
  "99529": { state: "AK", city: "ANCHORAGE" },
  "99530": { state: "AK", city: "ANCHORAGE" },
  "99540": { state: "AK", city: "INDIAN" },
  "99545": { state: "AK", city: "KONGIGANAK" },
  "99546": { state: "AK", city: "ADAK" },
  "99547": { state: "AK", city: "ATKA" },
  "99548": { state: "AK", city: "CHIGNIK LAKE" },
  "99549": { state: "AK", city: "PORT HEIDEN" },
  "99550": { state: "AK", city: "PORT LIONS" },
  "99551": { state: "AK", city: "AKIACHAK" },
  "99552": { state: "AK", city: "AKIAK" },
  "99553": { state: "AK", city: "AKUTAN" },
  "99554": { state: "AK", city: "ALAKANUK" },
  "99555": { state: "AK", city: "ALEKNAGIK" },
  "99556": { state: "AK", city: "ANCHOR POINT" },
  "99557": { state: "AK", city: "ANIAK" },
  "99558": { state: "AK", city: "ANVIK" },
  "99559": { state: "AK", city: "BETHEL" },
  "99561": { state: "AK", city: "CHEFORNAK" },
  "99563": { state: "AK", city: "CHEVAK" },
  "99564": { state: "AK", city: "CHIGNIK" },
  "99565": { state: "AK", city: "CHIGNIK LAGOON" },
  "99566": { state: "AK", city: "CHITINA" },
  "99567": { state: "AK", city: "CHUGIAK" },
  "99568": { state: "AK", city: "CLAM GULCH" },
  "99569": { state: "AK", city: "CLARKS POINT" },
  "99571": { state: "AK", city: "COLD BAY" },
  "99572": { state: "AK", city: "COOPER LANDING" },
  "99573": { state: "AK", city: "COPPER CENTER" },
  "99574": { state: "AK", city: "CORDOVA" },
  "99575": { state: "AK", city: "CROOKED CREEK" },
  "99576": { state: "AK", city: "DILLINGHAM" },
  "99577": { state: "AK", city: "EAGLE RIVER" },
  "99578": { state: "AK", city: "EEK" },
  "99579": { state: "AK", city: "EGEGIK" },
  "99580": { state: "AK", city: "EKWOK" },
  "99581": { state: "AK", city: "EMMONAK" },
  "99583": { state: "AK", city: "FALSE PASS" },
  "99585": { state: "AK", city: "MARSHALL" },
  "99586": { state: "AK", city: "GAKONA" },
  "99587": { state: "AK", city: "GIRDWOOD" },
  "99588": { state: "AK", city: "GLENNALLEN" },
  "99589": { state: "AK", city: "GOODNEWS BAY" },
  "99590": { state: "AK", city: "GRAYLING" },
  "99591": { state: "AK", city: "SAINT GEORGE ISLAND" },
  "99599": { state: "AK", city: "ANCHORAGE" },
  "99602": { state: "AK", city: "HOLY CROSS" },
  "99603": { state: "AK", city: "HOMER" },
  "99604": { state: "AK", city: "HOOPER BAY" },
  "99605": { state: "AK", city: "HOPE" },
  "99606": { state: "AK", city: "ILIAMNA" },
  "99607": { state: "AK", city: "KALSKAG" },
  "99608": { state: "AK", city: "KARLUK" },
  "99609": { state: "AK", city: "KASIGLUK" },
  "99610": { state: "AK", city: "KASILOF" },
  "99611": { state: "AK", city: "KENAI" },
  "99612": { state: "AK", city: "KING COVE" },
  "99613": { state: "AK", city: "KING SALMON" },
  "99614": { state: "AK", city: "KIPNUK" },
  "99615": { state: "AK", city: "KODIAK" },
  "99619": { state: "AK", city: "KODIAK" },
  "99620": { state: "AK", city: "KOTLIK" },
  "99621": { state: "AK", city: "KWETHLUK" },
  "99622": { state: "AK", city: "KWIGILLINGOK" },
  "99623": { state: "AK", city: "WASILLA" },
  "99624": { state: "AK", city: "LARSEN BAY" },
  "99625": { state: "AK", city: "LEVELOCK" },
  "99626": { state: "AK", city: "LOWER KALSKAG" },
  "99627": { state: "AK", city: "MC GRATH" },
  "99628": { state: "AK", city: "MANOKOTAK" },
  "99629": { state: "AK", city: "WASILLA" },
  "99630": { state: "AK", city: "MEKORYUK" },
  "99631": { state: "AK", city: "MOOSE PASS" },
  "99632": { state: "AK", city: "MOUNTAIN VILLAGE" },
  "99633": { state: "AK", city: "NAKNEK" },
  "99634": { state: "AK", city: "NAPAKIAK" },
  "99635": { state: "AK", city: "NIKISKI" },
  "99636": { state: "AK", city: "NEW STUYAHOK" },
  "99637": { state: "AK", city: "TOKSOOK BAY" },
  "99638": { state: "AK", city: "NIKOLSKI" },
  "99639": { state: "AK", city: "NINILCHIK" },
  "99640": { state: "AK", city: "NONDALTON" },
  "99641": { state: "AK", city: "NUNAPITCHUK" },
  "99643": { state: "AK", city: "OLD HARBOR" },
  "99644": { state: "AK", city: "OUZINKIE" },
  "99645": { state: "AK", city: "PALMER" },
  "99647": { state: "AK", city: "PEDRO BAY" },
  "99648": { state: "AK", city: "PERRYVILLE" },
  "99649": { state: "AK", city: "PILOT POINT" },
  "99650": { state: "AK", city: "PILOT STATION" },
  "99651": { state: "AK", city: "PLATINUM" },
  "99652": { state: "AK", city: "BIG LAKE" },
  "99653": { state: "AK", city: "PORT ALSWORTH" },
  "99654": { state: "AK", city: "WASILLA" },
  "99655": { state: "AK", city: "QUINHAGAK" },
  "99656": { state: "AK", city: "RED DEVIL" },
  "99657": { state: "AK", city: "RUSSIAN MISSION" },
  "99658": { state: "AK", city: "SAINT MARYS" },
  "99659": { state: "AK", city: "SAINT MICHAEL" },
  "99660": { state: "AK", city: "SAINT PAUL ISLAND" },
  "99661": { state: "AK", city: "SAND POINT" },
  "99662": { state: "AK", city: "SCAMMON BAY" },
  "99663": { state: "AK", city: "SELDOVIA" },
  "99664": { state: "AK", city: "SEWARD" },
  "99665": { state: "AK", city: "SHAGELUK" },
  "99666": { state: "AK", city: "NUNAM IQUA" },
  "99667": { state: "AK", city: "SKWENTNA" },
  "99668": { state: "AK", city: "SLEETMUTE" },
  "99669": { state: "AK", city: "SOLDOTNA" },
  "99670": { state: "AK", city: "SOUTH NAKNEK" },
  "99671": { state: "AK", city: "STEBBINS" },
  "99672": { state: "AK", city: "STERLING" },
  "99674": { state: "AK", city: "SUTTON" },
  "99675": { state: "AK", city: "TAKOTNA" },
  "99676": { state: "AK", city: "TALKEETNA" },
  "99677": { state: "AK", city: "TATITLEK" },
  "99678": { state: "AK", city: "TOGIAK" },
  "99679": { state: "AK", city: "TULUKSAK" },
  "99680": { state: "AK", city: "TUNTUTULIAK" },
  "99681": { state: "AK", city: "TUNUNAK" },
  "99682": { state: "AK", city: "TYONEK" },
  "99683": { state: "AK", city: "TRAPPER CREEK" },
  "99684": { state: "AK", city: "UNALAKLEET" },
  "99685": { state: "AK", city: "UNALASKA" },
  "99686": { state: "AK", city: "VALDEZ" },
  "99687": { state: "AK", city: "WASILLA" },
  "99688": { state: "AK", city: "WILLOW" },
  "99689": { state: "AK", city: "YAKUTAT" },
  "99690": { state: "AK", city: "NIGHTMUTE" },
  "99691": { state: "AK", city: "NIKOLAI" },
  "99692": { state: "AK", city: "DUTCH HARBOR" },
  "99693": { state: "AK", city: "WHITTIER" },
  "99694": { state: "AK", city: "HOUSTON" },
  "99695": { state: "AK", city: "ANCHORAGE" },
  "99697": { state: "AK", city: "KODIAK" },
  "99701": { state: "AK", city: "FAIRBANKS" },
  "99702": { state: "AK", city: "EIELSON AFB" },
  "99703": { state: "AK", city: "FORT WAINWRIGHT" },
  "99704": { state: "AK", city: "CLEAR" },
  "99705": { state: "AK", city: "NORTH POLE" },
  "99706": { state: "AK", city: "FAIRBANKS" },
  "99707": { state: "AK", city: "FAIRBANKS" },
  "99708": { state: "AK", city: "FAIRBANKS" },
  "99709": { state: "AK", city: "FAIRBANKS" },
  "99710": { state: "AK", city: "FAIRBANKS" },
  "99711": { state: "AK", city: "FAIRBANKS" },
  "99712": { state: "AK", city: "FAIRBANKS" },
  "99714": { state: "AK", city: "SALCHA" },
  "99716": { state: "AK", city: "TWO RIVERS" },
  "99720": { state: "AK", city: "ALLAKAKET" },
  "99721": { state: "AK", city: "ANAKTUVUK PASS" },
  "99722": { state: "AK", city: "ARCTIC VILLAGE" },
  "99723": { state: "AK", city: "BARROW" },
  "99724": { state: "AK", city: "BEAVER" },
  "99725": { state: "AK", city: "ESTER" },
  "99726": { state: "AK", city: "BETTLES FIELD" },
  "99727": { state: "AK", city: "BUCKLAND" },
  "99729": { state: "AK", city: "CANTWELL" },
  "99730": { state: "AK", city: "CENTRAL" },
  "99731": { state: "AK", city: "FORT GREELY" },
  "99732": { state: "AK", city: "CHICKEN" },
  "99733": { state: "AK", city: "CIRCLE" },
  "99734": { state: "AK", city: "PRUDHOE BAY" },
  "99736": { state: "AK", city: "DEERING" },
  "99737": { state: "AK", city: "DELTA JUNCTION" },
  "99738": { state: "AK", city: "EAGLE" },
  "99739": { state: "AK", city: "ELIM" },
  "99740": { state: "AK", city: "FORT YUKON" },
  "99741": { state: "AK", city: "GALENA" },
  "99742": { state: "AK", city: "GAMBELL" },
  "99743": { state: "AK", city: "HEALY" },
  "99744": { state: "AK", city: "ANDERSON" },
  "99745": { state: "AK", city: "HUGHES" },
  "99746": { state: "AK", city: "HUSLIA" },
  "99747": { state: "AK", city: "KAKTOVIK" },
  "99748": { state: "AK", city: "KALTAG" },
  "99749": { state: "AK", city: "KIANA" },
  "99750": { state: "AK", city: "KIVALINA" },
  "99751": { state: "AK", city: "KOBUK" },
  "99752": { state: "AK", city: "KOTZEBUE" },
  "99753": { state: "AK", city: "KOYUK" },
  "99754": { state: "AK", city: "KOYUKUK" },
  "99755": { state: "AK", city: "DENALI NATIONAL PARK" },
  "99756": { state: "AK", city: "MANLEY HOT SPRINGS" },
  "99757": { state: "AK", city: "LAKE MINCHUMINA" },
  "99758": { state: "AK", city: "MINTO" },
  "99759": { state: "AK", city: "POINT LAY" },
  "99760": { state: "AK", city: "NENANA" },
  "99761": { state: "AK", city: "NOATAK" },
  "99762": { state: "AK", city: "NOME" },
  "99763": { state: "AK", city: "NOORVIK" },
  "99764": { state: "AK", city: "NORTHWAY" },
  "99765": { state: "AK", city: "NULATO" },
  "99766": { state: "AK", city: "POINT HOPE" },
  "99767": { state: "AK", city: "RAMPART" },
  "99768": { state: "AK", city: "RUBY" },
  "99769": { state: "AK", city: "SAVOONGA" },
  "99770": { state: "AK", city: "SELAWIK" },
  "99771": { state: "AK", city: "SHAKTOOLIK" },
  "99772": { state: "AK", city: "SHISHMAREF" },
  "99773": { state: "AK", city: "SHUNGNAK" },
  "99774": { state: "AK", city: "STEVENS VILLAGE" },
  "99775": { state: "AK", city: "FAIRBANKS" },
  "99776": { state: "AK", city: "TANACROSS" },
  "99777": { state: "AK", city: "TANANA" },
  "99778": { state: "AK", city: "TELLER" },
  "99780": { state: "AK", city: "TOK" },
  "99781": { state: "AK", city: "VENETIE" },
  "99782": { state: "AK", city: "WAINWRIGHT" },
  "99783": { state: "AK", city: "WALES" },
  "99784": { state: "AK", city: "WHITE MOUNTAIN" },
  "99785": { state: "AK", city: "BREVIG MISSION" },
  "99786": { state: "AK", city: "AMBLER" },
  "99788": { state: "AK", city: "CHALKYITSIK" },
  "99789": { state: "AK", city: "NUIQSUT" },
  "99790": { state: "AK", city: "FAIRBANKS" },
  "99791": { state: "AK", city: "ATQASUK" },
  "99801": { state: "AK", city: "JUNEAU" },
  "99802": { state: "AK", city: "JUNEAU" },
  "99803": { state: "AK", city: "JUNEAU" },
  "99811": { state: "AK", city: "JUNEAU" },
  "99812": { state: "AK", city: "JUNEAU" },
  "99820": { state: "AK", city: "ANGOON" },
  "99821": { state: "AK", city: "AUKE BAY" },
  "99824": { state: "AK", city: "DOUGLAS" },
  "99825": { state: "AK", city: "ELFIN COVE" },
  "99826": { state: "AK", city: "GUSTAVUS" },
  "99827": { state: "AK", city: "HAINES" },
  "99829": { state: "AK", city: "HOONAH" },
  "99830": { state: "AK", city: "KAKE" },
  "99832": { state: "AK", city: "PELICAN" },
  "99833": { state: "AK", city: "PETERSBURG" },
  "99835": { state: "AK", city: "SITKA" },
  "99836": { state: "AK", city: "PORT ALEXANDER" },
  "99840": { state: "AK", city: "SKAGWAY" },
  "99841": { state: "AK", city: "TENAKEE SPRINGS" },
  "99850": { state: "AK", city: "JUNEAU" },
  "99901": { state: "AK", city: "KETCHIKAN" },
  "99903": { state: "AK", city: "MEYERS CHUCK" },
  "99918": { state: "AK", city: "COFFMAN COVE" },
  "99919": { state: "AK", city: "THORNE BAY" },
  "99921": { state: "AK", city: "CRAIG" },
  "99922": { state: "AK", city: "HYDABURG" },
  "99923": { state: "AK", city: "HYDER" },
  "99925": { state: "AK", city: "KLAWOCK" },
  "99926": { state: "AK", city: "METLAKATLA" },
  "99927": { state: "AK", city: "POINT BAKER" },
  "99928": { state: "AK", city: "WARD COVE" },
  "99929": { state: "AK", city: "WRANGELL" },
  "99950": { state: "AK", city: "KETCHIKAN" },
};
