import { ChangeEventHandler, ReactNode } from "react";
import classNames from "classnames";

interface FloatingInputProps {
  name: string;
  label?: string | ReactNode;
  type?: string;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  prefix?: ReactNode;
  hasComma?: boolean;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
}

const FloatingInput = ({
  name,
  label,
  type,
  value,
  error,
  labelProps,
  inputProps,
  errorProps,
  onChange,
  prefix,
  hasComma = false,
  disabled = false,
}: FloatingInputProps) => {
  const errorClassName = classNames(
    "absolute bg-back-light dark:bg-base-100 text-error text-2xs md:text-xs italic left-4 top-[37px] px-1",
    errorProps?.className
  );

  return (
    <>
      {prefix ? (
        <div className="relative flex items-center justify-center">
          <div className="absolute -left-[4px] top-[15px] z-50 bg-back-light dark:bg-base-100">
            {prefix}
          </div>
          <input
            disabled={disabled}
            type={type || "number"}
            id={name}
            pattern="\d*"
            className={
              error
                ? "peer block h-[55px] w-full appearance-none rounded-lg border border-error bg-transparent px-2.5 pb-2.5 pt-3.5 text-[17px] text-gray-900 focus:border-error focus:outline-none focus:ring-0 dark:border-error dark:text-text-light dark:focus:border-error"
                : "peer block h-[55px] w-full appearance-none rounded-lg border border-secondary bg-transparent pb-2.5 pl-5 pt-3.5 text-[17px] font-semibold focus:border-secondary focus:outline-none focus:ring-0 dark:border-secondary dark:text-text-light dark:focus:border-secondary"
            }
            placeholder=" "
            style={{
              outline: "none",
            }}
            onChange={onChange}
            value={value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 1}
            name={name}
          />
          <label
            htmlFor={name}
            className="absolute left-2.5 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-back-light px-3 text-sm font-medium text-text-dark duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-3 dark:bg-base-100 dark:text-text-light"
          >
            {label}
          </label>
          {error && (
            <p {...errorProps} className={errorClassName}>
              {error}
            </p>
          )}
        </div>
      ) : (
        <div className="relative">
          <input
            type={type}
            id={name}
            className={
              error
                ? "peer block w-full appearance-none rounded-lg border border-error bg-transparent px-2.5 pb-2.5 pt-4 text-[17px] text-gray-900 focus:border-error focus:outline-none focus:ring-0 dark:border-error dark:text-text-light dark:focus:border-error"
                : "peer block w-full appearance-none rounded-lg border border-secondary bg-transparent pb-2.5 pl-5 pt-4 text-[17px] font-semibold focus:border-secondary focus:outline-none focus:ring-0 dark:border-secondary dark:text-text-light dark:focus:border-secondary"
            }
            placeholder=" "
            style={{
              outline: "none",
            }}
            onChange={onChange}
            value={value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 1}
            name={name}
          />
          <label
            htmlFor={name}
            className="absolute left-2.5 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform bg-back-light px-2 text-sm font-medium text-text-dark duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-3 peer-focus:text-text-dark dark:bg-base-100 dark:text-text-light peer-focus:dark:text-text-light"
          >
            {label}
          </label>
          {error && (
            <p {...errorProps} className={errorClassName}>
              {error}
            </p>
          )}
        </div>
      )}
    </>
  );
};

export default FloatingInput;
