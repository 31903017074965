import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import { shortenString } from "src/helpers/parseStrings";
import useSearchBar from "src/hooks/useSearchBar";
import useViewport from "src/hooks/useViewport";
import NearMeButton from "../buttons/NearMeButton";

const SearchBarDesktop = () => {
  const {
    ref,
    value,
    isLoading,
    isInitialLoading,
    searchInput,
    isSearching,
    searchString,
    addressBeingResearched,
    handleInput,
    clearSearch,
    renderSuggestions,
    handleSearchProperties,
  } = useSearchBar();

  const suggestionElements = renderSuggestions();

  const { height } = useViewport();

  return (
    <>
      <div ref={ref} className="w-full">
        <div className="w-full rounded-lg">
          <div className="mr-6 w-full rounded-lg bg-gradient-to-r from-gradient3 to-gradient4 p-[2px] shadow-md">
            <div className="flex h-full flex-col justify-between rounded-lg p-[.5px]">
              <form className="relative">
                {isLoading ? (
                  <>
                    <div className="absolute left-[5px] top-[5px]">
                      <div className="flex items-center justify-center gap-0.5 ">
                        <LoadingSpinner className={"text-secondary"} />
                        <div className="animate-pulse text-sm font-bold text-secondary ml-1">
                          {`Researching ${shortenString(
                            addressBeingResearched,
                            38
                          )}...`}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="absolute inset-y-0 left-[8px] top-[1px] flex items-center">
                    {isInitialLoading ? (
                      <LoadingSpinner className={"text-secondary"} />
                    ) : (
                      <FontAwesomeIcon
                        className="md:text-3xl text-xl text-secondary"
                        icon={faMagnifyingGlass}
                      />
                    )}
                  </div>
                )}
                <input
                  type="text"
                  name="search"
                  ref={searchInput}
                  onKeyDown={(e: any) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  placeholder={
                    isLoading
                      ? ""
                      : isSearching
                      ? "Search Saved Properties"
                      : "Search New Properties"
                  }
                  className={
                    isSearching
                      ? "text-slate-dark h-8 w-64 min-w-full rounded-md bg-back-light pl-9 font-medium text-back-dark placeholder:italic placeholder:text-icon-light focus:text-text-dark focus:outline-none dark:bg-back-dark dark:text-text-light focus:dark:text-text-light"
                      : "text-slate-dark h-8 w-64 min-w-full rounded-md bg-back-light pl-9 font-medium text-back-dark placeholder:italic placeholder:text-icon-light focus:text-text-dark focus:outline-none dark:bg-back-dark dark:text-text-light focus:dark:text-text-light"
                  }
                  onChange={isSearching ? handleSearchProperties : handleInput}
                  disabled={isLoading}
                />

                {isSearching && searchString && (
                  <div className="relative">
                    <div className="absolute -top-[26px] right-[0px] flex h-[20px] w-[30px] items-center justify-center bg-back-light pr-2 dark:bg-back-dark">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className=" md:text-3xl cursor-pointer text-xl text-gradient4 "
                        onClick={() => {
                          clearSearch("search");
                        }}
                      />
                    </div>
                  </div>
                )}
                {!value.length && (
                  <div className="relative">
                    <div className="absolute -top-[24px] right-[0px] flex h-[20px] w-[30px] items-center justify-center bg-back-light pr-2 dark:bg-back-dark">
                      <NearMeButton className="text-primary" />
                    </div>
                  </div>
                )}

                {value.length > 0 && (
                  <div className="relative">
                    <div className="absolute -top-[26px] right-[0px] flex h-[20px] w-[30px] items-center justify-center bg-back-light pr-2 dark:bg-back-dark">
                      <FontAwesomeIcon
                        icon={faXmark}
                        className=" md:text-3xl cursor-pointer text-xl text-gradient4 "
                        onClick={() => {
                          clearSearch("value");
                        }}
                      />
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        {suggestionElements.suggestions.length > 0 && (
          <div
            className="fixed mt-1 overflow-auto rounded-lg bg-card-light px-2 shadow-xl drop-shadow-2xl dark:bg-card-dark"
            style={{ maxHeight: height - 120 }}
          >
            {suggestionElements.element}
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBarDesktop;
