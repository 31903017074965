import { useState } from "react";
import { sequenceApi } from "src/api";
import Property from "src/interfaces/property";
import useDrawer from "src/hooks/private/useDrawer";
import useSequenceLetterContext from "src/hooks/private/useSequenceLetterContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import InsufficientFundsModal from "./InsufficientFundsModal";
import PurchaseCoinsModal from "./PurchaseCoinsModal";
import { Option } from "../fields/SelectInput";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import { useNavigate } from "react-router-dom";
import { Campaign } from "src/interfaces/campaign";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import ButtonBlock from "./ButtonBlock";
import getHostName from "src/helpers/getAppFromHost";
import useUserContext from "src/hooks/private/useUserContext";
import useApp from "src/hooks/useAppContext";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

interface SequenceLetterProps {
  property: Property;
}

const SequenceLetter = ({ property }: SequenceLetterProps) => {
  const { id, ownerOccupied } = property;

  const navigate = useNavigate();

  const { setAlert } = useAlert();

  const { user } = useUserContext();

  const { app } = useApp();

  const { setShowModal, openModalWith } = useModal();

  const { teamSettings, checkCoinBalance, fetchUserTeamData, team, teammates } =
    useTeamContext();

  const { refreshProperty } = useDrawer();

  const { fetchMailings, fetchSequences } = useSequenceLetterContext();

  const { campaigns } = useCampaignContext();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const filteredCampaigns = campaigns.filter(
    (campaign: Campaign) =>
      campaign.target === (ownerOccupied ? "occupied" : "absentee")
  );

  const createCampaignOptions = (): Option[] => {
    const options: Option[] = [];
    for (const campaign of filteredCampaigns) {
      options.push({
        label: campaign.name,
        value: campaign.id,
      });
    }
    return options;
  };

  const [selectedCampaign, setSelectedCampaign] = useState<Campaign>(
    filteredCampaigns[0]
  );

  const handleSelectCampaign = (e: any) => {
    const value = parseInt(e.target.value, 10);
    const selectedCampaign = campaigns.find(
      (campaign: Campaign) => campaign.id === value
    );
    if (selectedCampaign) {
      setSelectedCampaign(selectedCampaign);
    } else {
    }
  };

  const findTeamLeaderEmail = () => {
    const teamLeaderID = team?.leaderID;
    if (user?.id === teamLeaderID) {
      return user?.email;
    } else {
      const teammate = teammates.find(
        (teammate: any) => teammate.id === teamLeaderID
      );
      return teammate?.email;
    }
  };

  const handleSubmit = async () => {
    if (
      selectedCampaign &&
      checkCoinBalance(
        (subscriptionFeatures?.["Sequence"].coinCost || 6) *
          selectedCampaign.quantity
      )
    ) {
      setIsSubmitting(true);
      try {
        await sequenceApi.createSequence({
          propertyID: id,
          cadence: teamSettings?.postCardIntervals,
          numLetters: selectedCampaign.quantity,
          templateIDs: selectedCampaign.templateIDs,
          addressTo: property.owner1,
        });
        setAlert({
          type: "success",
          message: "Sequence created",
          display: true,
        });
      } catch (error: any) {
        setAlert({
          type: "error",
          message: error?.message ?? "Sequence creation failed",
          display: true,
        });
      } finally {
        setShowModal(false);
        setIsSubmitting(false);
        fetchSequences();
        fetchMailings();
        fetchUserTeamData();
        refreshProperty();
      }
    } else {
      openModalWith({
        title: "Insufficient FuseCoins",
        body: <InsufficientFundsModal />,
        hideButtons: true,
      });
    }
  };

  const handleAddCoins = () => {
    openModalWith({
      title: "Add FuseCoins",
      body: <PurchaseCoinsModal />,
      hideButtons: true,
    });
  };

  const handleCustomSequence = () => {
    setShowModal(false);
    return navigate(`/sequences/new/${property.id}`);
  };

  return (
    <div className="flex columns-1 flex-col items-center justify-center">
      <div className="my-2 flex items-center justify-between gap-4">
        <div className="font-semibold">Select a Campaign</div>
        <select
          name={"selectCampaign"}
          className="select select-secondary h-12 w-48 border border-secondary bg-back-light text-text-dark dark:bg-back-dark dark:text-text-light"
          value={selectedCampaign.id.toString()}
          onChange={handleSelectCampaign}
        >
          {createCampaignOptions().map((opt: Option) => (
            <option key={`op-${opt.value}`} value={opt.value}>
              {opt.label}
            </option>
          ))}
          ;
        </select>
      </div>
      <div className="my-2">or</div>
      <button
        className="btn btn-ghost btn-sm mb-4 hover:bg-hover-back-light hover:dark:bg-hover-card-dark"
        onClick={handleCustomSequence}
      >
        Create a Custom Sequence
      </button>
      <div className="flex items-center justify-center">
        {selectedCampaign &&
        checkCoinBalance(
          (subscriptionFeatures?.["Sequence"].coinCost || 6) *
            selectedCampaign.quantity
        ) ? (
          <ButtonBlock
            handleSubmit={handleSubmit}
            submitting={isSubmitting}
            submitIcon={faPaperPlane}
            submitLabel={"Start"}
          />
        ) : user?.canPurchase || user?.isLeader ? (
          <div className="mt-4 flex items-center justify-center">
            <button
              className="btn btn-secondary ml-1 w-48 border-none text-center text-lg font-bold text-text-light hover:bg-primary hover:drop-shadow-lg dark:text-text-dark"
              onClick={handleAddCoins}
            >
              Add {app?.coinName}
            </button>
          </div>
        ) : (
          <>
            <p className="mt-4 text-center text-text-dark dark:text-text-light">
              Please{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href={`mailto:${findTeamLeaderEmail()}?subject=Purchase Coins for ${
                  app?.name || getHostName()
                } account`}
                className="text-secondary hover:underline"
                onClick={() => setShowModal(false)}
              >
                contact
              </a>{" "}
              your team leader to purchase more {app?.name || getHostName()}.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default SequenceLetter;
