import { useCallback, useState } from "react";
import { offerApi } from "src/api";
import { Offer } from "src/interfaces/offer";
import useErrorHandler from "./useErrorHandler";

const useOffers = () => {
  const [offers, setOffers] = useState<Offer[]>([]);
  const { handleError } = useErrorHandler();

  const fetchAOffers = useCallback(
    async (propertyID: number) => {
      try {
        const rows = await offerApi.getAOffers(propertyID);
        setOffers(rows);
      } catch (err) {
        handleError(err);
      }
    },
    [handleError]
  );

  return {
    offers: offers.sort(
      (a: Offer, b: Offer) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ),
    fetchAOffers,
  };
};

export default useOffers;
