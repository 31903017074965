import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import React from "react";
import { watchlistApi } from "src/api";
import useWatchlist from "src/hooks/private/useWatchlist";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useTimeAgo from "src/hooks/useTimeAgo";
import useViewport from "src/hooks/useViewport";
import { Watchlist as WatchlistType } from "src/interfaces/watchlist";
import CircularActionButton from "../buttons/CircularActionButton";
import ResearchAddressButton from "../buttons/ResearchAddressButton";
import WatchlistHelp from "../help/WatchlistHelp";

interface WatchlistBirddogRowProps {
  address: WatchlistType;
  numRows: number;
  index: number;
}

const WatchlistBirddogRow = ({
  address,
  numRows,
  index,
}: WatchlistBirddogRowProps) => {
  const { openModalWith, setShowModal } = useModal();

  const { fetchWatchlist } = useWatchlist();

  const { setAlert } = useAlert();

  const { timeAgo } = useTimeAgo();

  const { isMobile, width } = useViewport();

  const isFinalRow = numRows === index + 1;

  const handleDelete = (id: number) => {
    openModalWith({
      title: "Remove From Watchlist",
      body: "Are you sure you want to delete this address from your Watchlist?",
      onSubmit: () => handleDeleteSubmit(id),
      submitLabel: "Delete",
      submitIcon: faTrashCan,
      helpTitle: "Watchlist",
      helpBody: <WatchlistHelp />,
    });
  };

  const handleDeleteSubmit = async (id: number) => {
    try {
      setShowModal(false);
      const result = await watchlistApi.deleteWatchlist(id);
      if (result) {
        setAlert({
          display: true,
          message: "Watchlist Item deleted",
          type: "success",
        });
      }
    } catch (err: any) {
      setAlert({
        display: true,
        message: "An error occurred deleting this Watchlist item",
        type: "error",
      });
    } finally {
      return fetchWatchlist();
    }
  };

  return (
    <>
      <tr key={address.id} className="">
        {width > 440 && (
          <td
            className={`bg-card-light text-center text-xxs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light md:text-base  ${
              isFinalRow && "rounded-bl-lg"
            }`}
          >
            <span className="">{timeAgo(address.createdAt)}</span>
          </td>
        )}
        <td
          className={`bg-card-light text-left font-bold text-text-dark dark:bg-card-dark dark:text-text-light  ${
            isFinalRow && width < 440 && "rounded-bl-lg"
          } `}
        >
          <ResearchAddressButton
            address={address.fullAddress}
            placeID={address.placeID}
            isSmall={isMobile}
          />
        </td>
        <td
          className={`flex items-center justify-center gap-2 bg-card-light text-center dark:bg-card-dark  ${
            isFinalRow && "rounded-br-lg"
          }`}
        >
          <CircularActionButton
            icon={faTrashCan}
            onClick={() => handleDelete(address.id)}
            isSmall={isMobile}
            tooltip="Delete Address"
          />
        </td>
      </tr>
    </>
  );
};

export default WatchlistBirddogRow;
