import React from "react";
import CartBalance from "../../market/cart/CartBalance";
import useTheme from "src/hooks/useTheme";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";
import ModeSwitch from "src/components/buttons/ModeSwitch";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import MarketOrdersButton from "./MarketOrdersButton";
import { useNavigate } from "react-router-dom";
import SettingsButton from "src/components/market/buttons/SettingsButton";
import useViewport from "src/hooks/useViewport";
import HelpButton from "src/components/market/buttons/HelpButton";
import HomeButton from "src/components/market/buttons/HomeButton";
import MarketTooltip from "src/components/market/MarketTooltip";

interface HeaderNavMarketProps {
  props?: any;
}

const HeaderNavMarket = ({ props }: HeaderNavMarketProps) => {
  const { mode } = useTheme();

  const { width } = useViewport();

  const { app } = useApp();

  const navigate = useNavigate();

  const marketOrdersState = useSelector(
    (state: RootState) => state.marketOrders
  );

  return (
    <>
      <header
        className={
          "fixed left-0 right-0 top-0 z-40 flex h-14 columns-1 flex-col bg-card-light px-8 dark:bg-card-dark"
        }
      >
        <div className={"flex h-full w-full items-center justify-between "}>
          <div
            className="flex cursor-pointer flex-col items-center justify-start gap-3 sm:flex-row"
            onClick={() => navigate("/market")}
          >
            {width > 460 && (
              <img
                src={mode === "light" ? app?.logoPathLight : app?.logoPathDark}
                alt={`${app?.name || getHostName()} Logo`}
                className={app?.id === 1 ? "max-w-48" : "px-20"}
              />
            )}
            {width > 680 && (
              <h1 className=" invisible text-[12px] font-bold italic text-secondary dark:text-text-light sm:visible sm:mt-[8px] sm:text-[26px]">
                Market
              </h1>
            )}
          </div>
          <div
            className={`flex flex-row ${
              width < 460
                ? "w-full items-center justify-between"
                : "justify-end"
            } gap-0.5`}
          >
            <HelpButton />
            {marketOrdersState.marketOrders.length > 0 && (
              <MarketOrdersButton />
            )}
            <HomeButton />
            <div data-tip data-for={"mode-switch-button"}>
              <ModeSwitch className="text-secondary" />
              <MarketTooltip
                id={`mode-switch-button`}
                content={mode === "dark" ? "Light Mode" : "Dark Mode"}
              />
            </div>
            <SettingsButton />
            <CartBalance />
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderNavMarket;
