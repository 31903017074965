/* eslint-disable import/no-anonymous-default-export */
import App from "src/interfaces/app";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class AppApi extends ApiTokenSupport {
  async getByTeam(teamID: number): Promise<App> {
    const resp = await axios.get<App>(`/api/app/team/${teamID}`);
    return resp.data;
  }

  async load(): Promise<App> {
    const resp = await axios.get<App>(`/api/app/load`);
    return resp.data;
  }
}

export default new AppApi();
