import React from "react";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useApp from "src/hooks/useAppContext";
import useSubscriptionContext from "src/hooks/useSubscription";
import {
  SubscriptionFeature,
  SubscriptionFeatures,
} from "src/interfaces/subscriptions";

interface FeaturesProps {
  feature: SubscriptionFeature;
}


const Feature = ({ feature }: FeaturesProps) => {

  const { app } = useApp();

  function getFeatureNameById(id: number): string {
    switch (id) {
      case 1:
        return "Research";
      case 2:
        return "Debt Stack";
      case 3:
        return "Skip Trace";
      case 4:
        return "Enhanced Skip Trace";
      case 5:
        return "Sequence";
      case 6:
        return "Offer";
      case 7:
        return "Three Tiered Offer";
      case 8:
        return `${app?.name} ${app?.dashName}`;
      default:
        return "Unknown Feature";
    }
  }

  if (!feature) {
    return null;
  }

  return (
    <>
      {feature.accessType === "coin" && (
        <div className="flex flex-row gap-2 text-[15px]">
          <div>
            <FontAwesomeIcon icon={faCircleCheck} className="text-primary" />
          </div>
          <div className="text-zinc-700 dark:text-zinc-400">
            {getFeatureNameById(feature.featureID)}
          </div>
        </div>
      )}
    </>
  );
};

interface SubFeaturesProps {
  subFeatures: SubscriptionFeatures;
}

const SubFeatures = ({ subFeatures }: SubFeaturesProps) => {
  const { features } = useSubscriptionContext();

  const subFeatureRecord = Object.values(subFeatures || {}).reduce(
    (acc, feature) => {
      acc[feature.featureID] = feature;
      return acc;
    },
    {} as Record<number, SubscriptionFeature>
  );

  return (
    <div className="px-2">
      {features?.map((feature, index) => {
        const subscriptionFeature = subFeatureRecord[feature.id];

        const hasFeature = subscriptionFeature?.accessType === "coin";

        return (
          <div key={index} className={hasFeature ? "" : "invisible"}>
            <Feature feature={subscriptionFeature} />
          </div>
        );
      })}
    </div>
  );
};

export default SubFeatures;
