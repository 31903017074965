import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sequence } from "src/interfaces/sequence";
import { Mailing } from "src/interfaces/mailing";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useSequenceMailingContext from "src/hooks/private/useSequenceLetterContext";

interface LettersSetProps {
  sequences: Sequence[];
}

const LettersSet = ({ sequences }: LettersSetProps) => {
  const sortedSequences = sequences.sort(
    (a: any, b: any) =>
      new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return (
    <>
      <div className="dropdown-hover dropdown ">
        <label
          tabIndex={0}
          className="btn-ghost btn bg-card-light dark:bg-card-dark"
        >
          <Letters sequence={sortedSequences[0]} />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu rounded-box w-fit bg-back-light p-2 shadow dark:bg-base-100"
        >
          <table className="border-none bg-back-light dark:bg-base-100">
            {sequences
              .filter((sequence: Sequence, index: number) => index > 0)
              .map((sequence: Sequence) => (
                <td className="border-none bg-back-light hover:bg-hover-card-light dark:bg-base-100 hover:dark:bg-hover-card-dark">
                  <Letters sequence={sequence} />
                </td>
              ))}
          </table>
        </ul>
      </div>
    </>
  );
};

interface LettersProps {
  sequence: Sequence;
}

interface LettersBlockProps {
  sequence: Sequence | Sequence[];
}

const LettersBlock = ({ sequence }: LettersBlockProps) => {
  return (
    <>
      {Array.isArray(sequence) ? (
        sequence.length > 1 ? (
          <LettersSet sequences={sequence} />
        ) : (
          sequence.map((_sequence) => <Letters sequence={_sequence} />)
        )
      ) : (
        <Letters sequence={sequence} />
      )}
    </>
  );
};

export default LettersBlock;

const Letters = ({ sequence }: LettersProps) => {
  const { mailings } = useSequenceMailingContext();

  return (
    <>
      <div
        className="grid grid-cols-4 gap-0 w-16 sm:w-24 lg:w-28"
      >
        {mailings
          .sort((a: Mailing, b: Mailing) => a.rank - b.rank)
          .map(
            (letter: any, index: number) =>
              letter.sequenceID === sequence.id && (
                <div
                  key={index}
                  className={"tooltip"}
                  style={{
                    gridColumn: `${
                      letter.rank <= 4 ? letter.rank : letter.rank - 4
                    }`,
                    gridRow: `${letter.rank <= 4 ? 1 : 2}`,
                  }}
                  data-tip={
                    letter.status === "pending"
                      ? `Scheduled To Send: ${parseMonthDayYear(
                          letter.scheduledDate
                        )}`
                      : letter.status === "sent"
                      ? `Sent: ${parseMonthDayYear(letter.scheduledDate)}`
                      : letter.status === "failed"
                      ? `Status: Failed`
                      : "Error sending letter"
                  }
                >
                  <FontAwesomeIcon
                    className={
                      letter.status === "pending"
                        ? "pr-0.5 text-sm text-info sm:text-xl lg:text-xxl"
                        : letter.status === "sent"
                        ? "pr-0.5 text-sm text-success sm:text-xl lg:text-xxl"
                        : letter.status === "failed"
                        ? "pr-0.5 text-sm text-error sm:text-xl lg:text-xxl"
                        : "pr-0.5 text-sm text-warning sm:text-xl lg:text-xxl"
                    }
                    icon={faEnvelope}
                  />
                </div>
              )
          )}
      </div>
    </>
  );
};
