import { UserType } from "src/interfaces/user";
import useTeamContext from "src/hooks/private/useTeamContext";
import useUserContext from "src/hooks/private/useUserContext";
import useModal from "src/hooks/useModal";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";
import TeammatesHelp from "../help/TeamHelp";
import TeammateModal from "../modals/TeammateModal";

interface PropertyButtonProps {
  userID: number;
  disabledClick?: boolean;
  isSmall?: boolean;
  trim?: boolean;
}

const UserAvatar = ({
  userID,
  disabledClick = false,
  isSmall = false,
  trim = true,
}: PropertyButtonProps) => {
  const { tailwindTheme } = useTheme();
  const { user } = useUserContext();
  const { teamUsers } = useTeamContext();
  const avatar = teamUsers.find((user: UserType) => user.id === userID) || user;

  const { openModalWith } = useModal();

  const userInitial = avatar?.firstName[0];

  const colors = [
    tailwindTheme?.theme1,
    tailwindTheme?.theme2,
    tailwindTheme?.theme3,
    tailwindTheme?.theme4,
    tailwindTheme?.theme5,
  ];

  const assignColor = (userID: number) => {
    return colors[userID % colors.length];
  };

  const handleClick = () => {
    if (avatar) {
      openModalWith({
        title: avatar?.isLeader ? "Team Leader" : "Teammate",
        body: <TeammateModal teammate={avatar} />,
        hideButtons: true,
        helpTitle: "Teammates",
        helpBody: <TeammatesHelp />,
      });
    }
  };

  const { isMobile } = useViewport();

  const trimLongerNames = (_user: UserType | null) => {
    if (!_user) return "";
    if (trim) {
      if (isMobile && _user.fullName.length > 14) {
        return _user.firstName;
      } else {
        return _user.fullName;
      }
    } else {
      return _user.fullName;
    }
  };

  return (
    <>
      <div
        style={{
          textTransform: "none",
        }}
        className={
          disabledClick
            ? "flex items-center justify-center gap-1 select-none max-w-fit"
            : "flex items-center justify-center gap-1 cursor-pointer btn btn-ghost btn-xs sm:btn-sm select-none max-w-fit hover:dark:bg-back-dark"
        }
        onClick={handleClick}
      >
        <div
          style={{
            backgroundColor: assignColor(userID),
          }}
          className={
            isSmall
              ? "rounded-full w-[16px] h-[16px] flex items-center justify-center"
              : "rounded-full w-[18px] h-[18px] md:h-[18px] md:w-[18px] flex items-center justify-center"
          }
        >
          <p className="text-text-light dark:text-back-dark font-extrabold">
            {userInitial}
          </p>
        </div>
        <div>
          <p className="font-semibold text-text-dark dark:text-text-light text-xxs sm:text-sm">
            {trimLongerNames(avatar ? avatar : null)}
          </p>
        </div>
      </div>
    </>
  );
};

export default UserAvatar;
