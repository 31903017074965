import { useState } from "react";
import { PropertyDrawerOptionsProps } from "src/contexts/private/DrawerContext";
import useAlert from "src/hooks/useAlert";
import Alert from "../alert/Alert";
import DeleteProperty from "../buttons/DeleteProperty";
import ActionPanel from "../property/ActionPanel";
import GalleryCard from "../property/GalleryCard";
import Progress from "../property/Progress";
import PropertyUpdateTable from "../property/PropertyUpdateTable";
import InactiveBanner from "./InactiveBanner";
import { PropertyUpdateProvider } from "src/contexts/private/PropertyUpdateContext";
import { LoanProvider } from "src/contexts/private/LoanContext";
import TabGroup from "../property/TabGroup";
import DocumentsTable from "../property/DocumentsTable";
import { motion, AnimatePresence } from "framer-motion";
import ExternalToolbar from "../property/ExternalToolbar";
import PropertyDetails from "../property/PropertyDetails";
import ScoresDisplay from "../drives/ScoresDisplay";
import { useSwipe } from "src/hooks/useSwipe";
import useDrawer from "src/hooks/private/useDrawer";
import useApp from "src/hooks/useAppContext";

const PropertyDrawer = ({ property }: PropertyDrawerOptionsProps) => {
  const { isActive, fromDrives, index90Day, indexInvestor, indexWholesale } =
    property;

  const { alert, setAlert } = useAlert();

  const { app } = useApp();

  const { setShowPropertyDrawer } = useDrawer();

  useSwipe({
    onSwipeRight: () => setShowPropertyDrawer(false),
    onSwipeLeft: () => {},
    swipeThreshold: 90,
  });

  const [isHovering, setIsHovering] = useState(false);

  const specificDate = new Date("2023-10-19T12:00:00");
  const dateRealeflowPropertiesStarted = new Date(
    specificDate.getTime() + 10 * 60 * 1000
  );

  return (
    <>
      <PropertyUpdateProvider>
        <LoanProvider>
          <div className="flex w-full  columns-1 flex-col overflow-x-hidden">
            <div className="fixed left-0 right-0 w-full px-4">
              <AnimatePresence>
                {alert.display && (
                  <motion.div
                    initial={{ y: -20, opacity: 0.8 }}
                    transition={{ duration: 0.15 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -20, opacity: 0.5 }}
                    className="z-50"
                  >
                    <Alert
                      alert={alert}
                      _className={"h-[30px] lg:h-[32px] z-50"}
                      onClose={() =>
                        setAlert({
                          display: false,
                          type: alert.type,
                          message: alert.message,
                        })
                      }
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            {isActive ? (
              <div
                className="mb-2 rounded-lg bg-card-light p-2 shadow-sm dark:bg-card-dark"
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <Progress
                  property={property}
                  showLegend={false}
                  isHovering={isHovering}
                  textSize={
                    "text-xxs sm:text-sm md:text-base lg:text-lg font-semibold"
                  }
                />
              </div>
            ) : (
              <InactiveBanner property={property} />
            )}
            <div className="mb-2">
              <GalleryCard property={property} />
            </div>
            <div className="mb-2">
              <ActionPanel property={property} isFullWidth={false} />
            </div>
            <div className="mb-2">
              <TabGroup
                tabs={[
                  {
                    title: "Updates",
                    body: (
                      <PropertyUpdateTable
                        property={property}
                        className={"w-full"}
                      />
                    ),
                  },
                  {
                    title: "Documents",
                    body: <DocumentsTable property={property} />,
                  },
                ]}
              />
            </div>
            {fromDrives ||
              (app?.hasPurchaseableZones && index90Day && index90Day > 0 && (
                <div className="mb-2 text-black dark:text-white">
                  <ScoresDisplay
                    investor={indexInvestor || 0}
                    retail={index90Day || 0}
                    wholesale={indexWholesale || 0}
                  />
                </div>
              ))}
            {new Date(property.createdAt) >= dateRealeflowPropertiesStarted && (
              <div className="mb-2">
                <PropertyDetails property={property} />
              </div>
            )}
            <div className="mb-2">
              <ExternalToolbar property={property} />
            </div>
            <div>{isActive && <DeleteProperty property={property} />}</div>
          </div>
        </LoanProvider>
      </PropertyUpdateProvider>
    </>
  );
};

export default PropertyDrawer;
