import { PropsWithChildren, useState, createContext } from "react";

interface NavContextValue {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  footerOpen: boolean;
  setFooterOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState = {
  open: false,
  setOpen: () => {},
  isDisabled: false,
  setIsDisabled: () => {},
  footerOpen: true,
  setFooterOpen: () => {},
};

export const NavContext = createContext<NavContextValue>(defaultState);

interface NavContextProps {};

export const NavProvider = ({
  children,
}: PropsWithChildren<NavContextProps>) => {
  const [open, setOpen] = useState<boolean>(defaultState.open);
  const [footerOpen, setFooterOpen] = useState<boolean>(defaultState.footerOpen);
  const [isDisabled, setIsDisabled] = useState<boolean>(defaultState.isDisabled);

  return (
    <NavContext.Provider value={{ open, setOpen, isDisabled, setIsDisabled, footerOpen, setFooterOpen  }}>
      {children}
    </NavContext.Provider>
  );
};
