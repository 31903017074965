import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useModal from "src/hooks/useModal";

interface QuestionButtonProps {
  helpTitle: string;
  helpBody: ReactNode;
}

const QuestionButton = ({ helpTitle, helpBody }: QuestionButtonProps) => {
  const { openHelpDrawerWith } = useHelpDrawer();

  const { setShowModal } = useModal();

  const handleHelp = () => {
    setShowModal(false);
    openHelpDrawerWith({
      title: helpTitle,
      body: helpBody,
    });
  };

  return (
    <button
      className="btn btn-circle btn-sm cursor-pointer border-none bg-gray-300 text-text-light hover:bg-gray-400 hover:bg-hover-card-light dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
      onClick={handleHelp}
    >
      <FontAwesomeIcon
        className="cursor-pointer text-lg text-text-dark dark:text-gray-500"
        icon={faInfo}
      />
    </button>
  );
};

export default QuestionButton;
