import { forwardRef, Ref, Suspense, useEffect, useRef, useState } from "react";
import { TemplatePostcard } from "src/interfaces/templatePostcard";
import { templatePostcardsApi } from "src/api";
import SuspenseImage from "src/components/suspenseImage/SuspenseImagePostcard";
import FlipCard from "react-card-flip";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useAuth from "src/hooks/useAuth";
import Button from "../buttons/Button";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

interface TemplateHolderProps {
  template: TemplatePostcard;
}

interface TemplateImageProps {
  id: number;
  view: "front" | "back";
  onClick?: () => void;
  className?: string;
  noCache?: boolean;
  ref?: Ref<HTMLImageElement>;
  // [key: string]: any;
}

export const SkeletonLoader = () => {
  const ref = useRef<any>(null);
  const [w, setW] = useState<number>(545);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setW(ref.current.clientWidth);
      }
    };

    if (ref.current) {
      setW(ref.current.clientWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={ref}>
      <span
        className="color-primary"
        style={{
          position: "absolute",
          zIndex: 2,
          marginTop: 185,
          marginLeft: w / 3,
        }}
      >
        Loading preview...
      </span>
      <Skeleton className="h-96" />
    </div>
  );
};

export const TemplateImage = forwardRef<HTMLImageElement, TemplateImageProps>(
  ({ id, view, noCache, ...props }: TemplateImageProps, ref) => {
    const { currentUser } = useAuth();
    const url = templatePostcardsApi.previewUrl(
      id,
      view,
      currentUser?.id as number,
      noCache
    );
    return <SuspenseImage {...props} ref={ref} src={url} noCache={noCache} />;
  }
);

const TemplateHolder = ({ template }: TemplateHolderProps) => {
  const { id } = template;
  const [flip, setFlip] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <Suspense fallback={<SkeletonLoader />}>
      <div className="indicator w-full">
        <div className="indicator-start indicator-item ml-[30px]">
          <Button
            className="btn-xs btn bg-back-dark hover:btn-secondary dark:bg-white dark:text-text-dark hover:dark:btn-secondary"
            onClick={() => navigate(`/templates/${id}/edit`)}
          >
            <div className="flex items-center justify-center gap-1">
              <FontAwesomeIcon icon={faPenToSquare} />
              <p>Edit</p>
            </div>
          </Button>
        </div>
        <FlipCard
          containerClassName="cursor-pointer w-full"
          isFlipped={flip}
          flipDirection="horizontal"
        >
          <TemplateImage
            id={id as number}
            view="front"
            onClick={() => setFlip(!flip)}
            className="w-full"
          />
          <TemplateImage
            id={id as number}
            view="back"
            onClick={() => setFlip(!flip)}
            className="w-full"
          />
        </FlipCard>
      </div>
    </Suspense>
  );
};

export default TemplateHolder;
