import React, { useState } from "react";
import { RealeflowAccountDetails } from "src/interfaces/realeflow";

import { faFlag, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Subscription } from "src/interfaces/subscriptions";
import AddZones from "../zones/AddZones";
import MyZones from "../zones/MyZones";
import { convertToZones } from "src/helpers/realeflow/convertAIZones";

interface ZoneModalProps {
  realeflowAccount: RealeflowAccountDetails;
  subscription: Subscription;
  pingIcon?: boolean;
}

const ZoneModal = ({
  realeflowAccount,
  subscription,
  pingIcon,
}: ZoneModalProps) => {
  const purchasedZones = convertToZones(realeflowAccount.AITerritories);

  const startingIndex = purchasedZones.zips.length > 0 ? 0 : 1;

  const [modalIndex, setModalIndex] = useState<number>(startingIndex);

  return (
    <>
      <div className="mb-8 flex w-full columns-1 flex-col items-center justify-center">
        <div className="z-50 flex h-[50px] w-full items-center justify-center gap-3 bg-back-light dark:bg-base-100">
          <div className="flex gap-2 rounded-lg bg-hover-back-light px-1 py-1 dark:bg-hover-card-dark">
            <button
              value="single"
              onClick={() => setModalIndex(0)}
              className={
                modalIndex === 0
                  ? "btn btn-secondary btn-sm w-36 text-text-light hover:bg-secondary"
                  : "btn btn-ghost btn-sm w-36 text-base-100 dark:text-text-light"
              }
            >
              <FontAwesomeIcon icon={faFlag} />
              My Zones
            </button>
            <button
              value="three"
              onClick={() => setModalIndex(1)}
              className={
                modalIndex === 1
                  ? "relative btn btn-secondary btn-sm w-36 text-text-light hover:bg-secondary"
                  : "relative btn btn-ghost btn-sm w-36 text-base-100 dark:text-text-light"
              }
            >
              <FontAwesomeIcon icon={faPlus} />
              Add Zones
              {pingIcon && (
                <span className="absolute right-[2px] top-[2px] flex h-[9px] w-[9px]">
                  <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-accent opacity-75"></span>
                  <span className="absolute inline-flex h-[9px] w-[9px] rounded-full bg-accent"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>

      {modalIndex === 0 && (
        <MyZones
          realeflowAccount={realeflowAccount}
          purchasedZones={purchasedZones}
        />
      )}
      {modalIndex === 1 && (
        <AddZones
          realeflowAccount={realeflowAccount}
          subscription={subscription}
          purchasedZones={purchasedZones}
        />
      )}
    </>
  );
};

export default ZoneModal;
