import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { propertyApi } from "src/api";
import { PropertyUpdate } from "src/interfaces/propertyUpdate";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface PropertyUpdateContextInterface {
  propertyUpdates: PropertyUpdate[];
  fetchPropertyUpdates: () => void;
}

const propertyUpdateContextDefaults: PropertyUpdateContextInterface = {
  propertyUpdates: [],
  fetchPropertyUpdates: () => {},
};

const PropertyUpdateContext =
  React.createContext<PropertyUpdateContextInterface>(
    propertyUpdateContextDefaults
  );

interface PropertyUpdateProviderProps {}

export const PropertyUpdateProvider = ({
  children,
}: PropsWithChildren<PropertyUpdateProviderProps>) => {
  const [propertyUpdates, setPropertyUpdates] = useState<PropertyUpdate[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchPropertyUpdates = useCallback(async () => {
    if (user) {
      try {
        const propertyUpdates = await propertyApi.getPropertyUpdates();
        setPropertyUpdates(propertyUpdates);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  useEffect(() => {
    fetchPropertyUpdates();
  }, [fetchPropertyUpdates]);

  return (
    <PropertyUpdateContext.Provider
      value={{
        propertyUpdates,
        fetchPropertyUpdates,
      }}
    >
      {children}
    </PropertyUpdateContext.Provider>
  );
};

export default PropertyUpdateContext;
