import TimeAgo from "react-timeago";
import moment from "moment";

const useTimeAgo = () => {

  const timeAgo = (date: Date | number | string) => {
    const refDate = moment.utc(date).local();


    const customFormatter = (value: number, unit: string) => {
      // Define units for which we want to display the date instead of the relative time
      const unitsForDateFormat = ["week", "month", "year"];

      if (unitsForDateFormat.includes(unit)) {
        // Format the date as MM/DD/YY for anything over a week
        return moment(date).format("MM/DD/YY");
      } else {
        // Handle seconds and minutes with custom units
        if (unit === "second") {
          unit = "sec";
        } else if (unit === "minute") {
          unit = "min";
        } else if (value !== 1) {
          unit += "s";
        }
        return `${value} ${unit}`;
      }
    };
    return (
      <TimeAgo
        date={refDate.toDate()}
        title={refDate.format("DD.MM.YYYY HH:mm")}
        className={"text-xxxs xs:text-xxs sm:text-xs md:text-sm tabular-nums"}
        formatter={customFormatter}
      />
    );
  };

  return { timeAgo };
};

export default useTimeAgo;
