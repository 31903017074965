import { forwardRef, Ref, Suspense, useEffect, useRef, useState } from "react";
import { TemplatePostcard } from "src/interfaces/templatePostcard";
import { templatePostcardsApi } from "src/api";
import SuspenseImagePostcard from "src/components/suspenseImage/SuspenseImagePostcard";
import FlipCard from "react-card-flip";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import useAuth from "src/hooks/useAuth";

interface TemplateHolderProps {
  template: TemplatePostcard;
  pointLoader?: boolean;
  propertyID?: number;
}

interface TemplateImageProps {
  id: number;
  view: "front" | "back";
  onClick?: () => void;
  className?: string;
  noCache?: boolean;
  ref?: Ref<HTMLImageElement>;
  propertyID?: number;
  // [key: string]: any;
}

export const SkeletonLoader = () => {
  const ref = useRef<any>(null);
  const [w, setW] = useState<number>(545);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setW(ref.current.clientWidth);
      }
    };

    if (ref.current) {
      setW(ref.current.clientWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div ref={ref}>
      <span
        className="color-primary"
        style={{
          position: "absolute",
          zIndex: 2,
          marginTop: 185,
          marginLeft: w / 3,
        }}
      >
        Loading preview...
      </span>
      <Skeleton className="h-80 w-80" />
    </div>
  );
};

export const TemplateImage = forwardRef<HTMLImageElement, TemplateImageProps>(
  ({ id, view, noCache, propertyID, ...props }: TemplateImageProps, ref) => {
    const { currentUser } = useAuth();
    const url = templatePostcardsApi.previewUrl(
      id,
      view,
      currentUser?.id as number,
      noCache,
      propertyID
    );
    return <SuspenseImagePostcard {...props} ref={ref} src={url} noCache={noCache} />;
  }
);

const TemplatePostcardHolder = ({ template, pointLoader, propertyID }: TemplateHolderProps) => {
  const { id } = template;
  const [flip, setFlip] = useState<boolean>(false);

  return (
    <Suspense fallback={pointLoader ? <div className='w-80 justify-center flex'><span className="loading-10">loading preview...</span></div> : <SkeletonLoader />}>
      <div className="indicator">
        <FlipCard
          containerClassName="cursor-pointer"
          isFlipped={flip}
          flipDirection="horizontal"
        >
          <TemplateImage
            id={id as number}
            view="front"
            onClick={() => setFlip(!flip)}
            className="w-80"
            propertyID={propertyID}
          />
          <TemplateImage
            id={id as number}
            view="back"
            onClick={() => setFlip(!flip)}
            className="w-80"
            propertyID={propertyID}
          />
        </FlipCard>
      </div>
    </Suspense>
  );
};

export default TemplatePostcardHolder;
