import { forwardRef } from "react";
import loadImage from "./loadImage";

interface SuspenseImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  noCache?: boolean;
  defaultImg?: string;
}

const SuspenseImage = forwardRef<HTMLImageElement, SuspenseImageProps>(
  ({ noCache, defaultImg, ...props }: SuspenseImageProps, ref) => {
    // const { isMobile } = useViewport();
    loadImage(props.src as string, noCache, defaultImg).read();
    return (
      <div className="flex w-full items-center justify-center bg-white py-24 shadow-xl">
        <div className="w-[350px]">
          <img className={""} {...props} ref={ref} alt={props.alt} />
        </div>
      </div>
    );
  }
);

export default SuspenseImage;
