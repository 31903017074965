import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useModal from "src/hooks/useModal";


interface ActionButtonProps {
  icon: any;
  label: string;
  className?: string;
  onClick: any;
  disabled?: boolean;
}

const ActionButton = ({
  icon,
  label,
  className,
  onClick,
  disabled,
}: ActionButtonProps) => {
  const { openModalWith } = useModal();

  const buttonClassnames = classNames(
    className,
    "btn gap-1.5 disabled:bg-gray-500 disabled:dark:bg-gray-900 disabled:text-text-light"
  );

  const handleDisabled = () => {
    openModalWith({
      title: "Unable to Mail",
      hideButtons: true,
      body: "This property is owned by an international based owner and is unable to receive mail correspondence. Please try Skip Tracing and then calling the owner directly.",
    });
  };

  return (
    <div className="@container" onClick={disabled ? handleDisabled : () => {}}>
      <button
        disabled={disabled}
        className={buttonClassnames}
        onClick={onClick}
      >
        <FontAwesomeIcon
          className="@[7rem]:text-[.8rem] @[8rem]:text-[.85rem] @[9rem]:text-[.9rem] @[10rem]:text-[1rem] @[11rem]:text-[1.2rem]"
          icon={icon}
        />
        <div className="@[7rem]:text-[.8rem] @[8rem]:text-[.85rem] @[9rem]:text-[.9rem] @[10rem]:text-[1rem] @[11rem]:text-[1.2rem]">
          {label}
        </div>
      </button>
    </div>
  );
};

export default ActionButton;
