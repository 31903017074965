import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import { Outlet } from 'react-router-dom';

import PublicLayout from "../layouts/PublicLayout";

interface PublicRouteProps {
  children?: ReactNode;
}

const PublicRoute = ({ children }: PublicRouteProps) => {
  const { currentUser, redirectTo } = useAuth();

  if (currentUser) {
    return <Navigate to={redirectTo || '/dashboard'} />
  }

  return (
    <>
      <PublicLayout>{children || <Outlet />}</PublicLayout>
    </>
  );
};

export default PublicRoute;
