/**
 * Generate a random string with a specified length
 *
 * @param length
 * @returns
 */
export const generateId = (length: number = 16): string => {
	const result = [];
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
	}
	return result.join("");
};