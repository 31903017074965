import { forwardRef } from "react";
import loadImage from "./loadImage";

interface SuspenseImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  noCache?: boolean;
  defaultImg?: string;
}

const SuspenseImage = forwardRef<HTMLImageElement, SuspenseImageProps>(
  ({ noCache, defaultImg, ...props }: SuspenseImageProps, ref) => {
    loadImage(props.src as string, noCache, defaultImg).read();
    return <img className={""} {...props} ref={ref} alt={props.alt} />;
  }
);

export default SuspenseImage;
