import { configureStore } from "@reduxjs/toolkit";
import cartReducer, { CartState } from "../slices/cartSlice";
import addressReducer, { AddressState } from "../slices/addressSlice";
import { loadState, saveState } from "./localStorage";
import marketOrderReducer, {
  MarketOrderState,
} from "../slices/marketOrderSlice";

export interface RootState {
  cart: CartState;
  addresses: AddressState;
  marketOrders: MarketOrderState;
}

const preloadedState = loadState();

const store = configureStore<RootState, any, any>({
  reducer: {
    cart: cartReducer,
    addresses: addressReducer,
    marketOrders: marketOrderReducer,
  },
  preloadedState,
});

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
export type AppDispatch = typeof store.dispatch;
