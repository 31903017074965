/* eslint-disable import/no-anonymous-default-export */
import { Team } from "src/interfaces/team";
import { TeamSettings } from "src/interfaces/teamSettings";
import { UserType as User } from "src/interfaces/user";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class TeamApi extends ApiTokenSupport {
  /**
   * Get team by ID
   * @param teamID
   * @returns
   */
  async getTeam(teamID: number): Promise<Team> {
    const resp = await axios.get<Team>(
      `/api/team/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get user teammates
   *
   * @returns
   */
  async getTeammates(): Promise<User[]> {
    const resp = await axios.get<User[]>(
      `/api/user/teammates`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get all users on a team
   *
   * @returns
   */
  async getTeamUsers(): Promise<User[]> {
    const resp = await axios.get<User[]>(
      `/api/user/team-users`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Get team settings
   * @param teamID
   * @returns
   */
  async getTeamSettings(teamID: number): Promise<TeamSettings> {
    const resp = await axios.get<TeamSettings>(
      `/api/teamSettings/team/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Update team settings
   * @param teamID
   * @param data
   * @returns
   */
  async updateTeamSettings(
    teamSettingsID: number,
    data: {}
  ): Promise<TeamSettings> {
    const resp = await axios.put<TeamSettings>(
      `/api/teamSettings/${teamSettingsID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async uploadCompanyLogoImage(data: FormData): Promise<any> {
    const resp = await axios.post<any>(
      `/api/fileUpload/save`,
      data,
      this.withAuthorization({
        "Content-Type": "multipart/form-data",
      })
    );
    return resp.data;
  }

  /**
   * Add team member
   * @param data
   * @returns
   */
  async addTeamMember(data: {}): Promise<User> {
    const resp = await axios.post<User>(
      `/api/user/`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Update team user
   * @param userID
   * @param data
   * @returns
   */
  async updateTeam(userID: number, data: any): Promise<User> {
    const resp = await axios.put<User>(
      `/api/user/${userID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

 async setDashInterest(): Promise<void> {
    await axios.post<void>(
      `/api/team/set-dash-interest`,
      {},
      this.withAuthorization()
    );
  }

  /**
   * Verify demo token
   *
   * @param token
   * @returns
   */
  async verifyDemoModeToken(
    token: string
  ): Promise<{ email: string; redirectTo: string }> {
    const resp = await axios.post<{ email: string; redirectTo: string }>(
      `/api/user/verify-demo-token`,
      { token },
      this.withAuthorization()
    );
    return resp.data;
  }

  async verifyUpdateCardToken(
    token: string
  ): Promise<{ email: string; redirectTo: string }> {
    const resp = await axios.post<{ email: string; redirectTo: string }>(
      `/api/team/verify-update-card-token`,
      { token }
    );
    return resp.data;
  }
}

export default new TeamApi();
