import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { PropsWithChildren } from "react";
import ButtonLoader from "../loading/ButtonLoader";
import "./Button.css";

export interface ButtonProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  icon?: IconProp;
  onClick?: (data: any) => void;
  onMouseEnter?: any;
  onMouseLeave?: any;
  loaderProps?: any;
}

const Button = ({
  className,
  disabled,
  loading,
  children,
  icon,
  type,
  onClick,
  onMouseEnter,
  onMouseLeave,
  loaderProps,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <button
      className={classNames("btn-custom", className)}
      type={type || "button"}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex items-center justify-center">
        <div>
          {icon && !loading && (
            <FontAwesomeIcon
              icon={icon}
              className={
                disabled
                  ? "mr-1.5 text-base  text-gray-500 dark:text-gray-800"
                  : "mr-1.5 text-base text-text-light dark:text-text-light"
              }
            />
          )}
        </div>
        {loading ? (
          <ButtonLoader className="h-6 w-6" {...loaderProps} />
        ) : (
          <div>{children}</div>
        )}
      </div>
    </button>
  );
};

export default Button;
