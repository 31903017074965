import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { driveApi } from "src/api";
import Drive  from "src/interfaces/drive";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useUserContext from "src/hooks/private/useUserContext";

interface DriveContextInterface {
  drives: Drive[];
  fetchDrives: () => void;
}

const DriveContextDefaults: DriveContextInterface = {
  drives: [],
  fetchDrives: () => {},
};

const DriveContext = React.createContext<DriveContextInterface>(
  DriveContextDefaults
);

interface DriveProviderProps {}

export const DriveProvider = ({
  children,
}: PropsWithChildren<DriveProviderProps>) => {
  const [drives, setDrives] = useState<Drive[]>([]);

  const { handleError } = useErrorHandler();

  const { user } = useUserContext();

  const fetchDrives = useCallback(async () => {
    try {
      if (user) {
        const Drives = await driveApi.getUserDrives();
        setDrives(Drives);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, user]);

  useEffect(() => {
    fetchDrives();
  }, [fetchDrives]);

  return (
    <DriveContext.Provider
      value={{
        drives,
        fetchDrives,
      }}
    >
      {children}
    </DriveContext.Provider>
  );
};

export default DriveContext;
