import Property from "src/interfaces/property";
import { abbreviateDirection } from "./parseStrings";

export function debounce<T extends (...args: any[]) => any>(
  fn: T,
  wait: number
): T {
  let timeout: any;

  return function (this: any, ...args: any[]) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => fn.apply(context, args), wait);
  } as T;
}

export function throttle<T extends (...args: any[]) => any>(
  fn: T,
  limit: number
): T {
  let lastFn: any;
  let lastRan: number;

  return function (this: any, ...args: any[]) {
    if (!lastRan) {
      fn.apply(this, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          fn.apply(this, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  } as T;
}

export const removeFinalComma = (str: string) => {
  if (!str.endsWith(",")) return str;
  return str.slice(0, -1);
};

export const removeLastWord = (str: string) => {
  let address = str.split(" ");
  if (address.length < 2) return str;
  const lastWord = address.pop();
  const newDirection = abbreviateDirection(lastWord);
  address[address.length - 1] = newDirection;
  return removeFinalComma(address.join(" "));
};

export function uniqueValue(arr1: any[], arr2: any[]) {
  const arr1Map = arr1.reduce((map, item) => {
    map.set(item.id, item);
    return map;
  }, new Map());

  return [...arr1, ...arr2.filter((item) => !arr1Map.has(item.id))];
}

type SortFunction = (
  a: Property,
  b: Property,
  direction: "asc" | "desc"
) => number;

export const sortFunctions: { [sortBy: string]: SortFunction } = {
  createdAt: (a, b, direction) => {
    return direction === "asc"
      ? +new Date(a.createdAt) - +new Date(b.createdAt)
      : +new Date(b.createdAt) - +new Date(a.createdAt);
  },
  address: (a, b, direction) => {
    return direction === "asc"
      ? a.fullAddress.localeCompare(b.fullAddress)
      : b.fullAddress.localeCompare(a.fullAddress);
  },
  status: (a, b, direction) => {
    return direction === "asc"
      ? a.status.localeCompare(b.status)
      : b.status.localeCompare(a.status);
  },
  value: (a, b, direction) => {
    return direction === "asc"
      ? a.appraisedValue - b.appraisedValue
      : b.appraisedValue - a.appraisedValue;
  },
  sqft: (a, b, direction) => {
    return direction === "asc" ? a.sqft - b.sqft : b.sqft - a.sqft;
  },
  year: (a, b, direction) => {
    return direction === "asc"
      ? a.yearBuilt - b.yearBuilt
      : b.yearBuilt - a.yearBuilt;
  },
};
