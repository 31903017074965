import { forwardRef, Ref, Suspense, useState } from "react";
import { templateLettersApi } from "src/api";
import { TemplateLetter } from "src/interfaces/templateLetter";
import SuspenseImageLetter from "src/components/suspenseImage/SuspenseImageLetter";
import useAuth from "src/hooks/useAuth";
import { SkeletonLoader } from "./TemplatePostcardHolder";
import Modal from "react-modal";
import useViewport from "src/hooks/useViewport";
import SuspenseImageLetterZoom from "../suspenseImage/SuspenseImageLetterZoom";

interface TemplateLetterHolderProps {
  template: TemplateLetter;
  className?: string;
  pointLoader?: boolean;
  propertyID?: number;
}

interface TemplateLetterImageProps {
  id: number;
  ref?: Ref<HTMLImageElement>;
  propertyID?: number;
  noCache?: boolean;
  className?: string;
}

export const TemplateLetterImage = forwardRef<
  HTMLImageElement,
  TemplateLetterImageProps
>(({ id, noCache, propertyID, ...props }: TemplateLetterImageProps, ref) => {
  const { currentUser } = useAuth();
  const url = templateLettersApi.previewUrl(
    id,
    currentUser?.id as number,
    noCache,
    propertyID
  );
  return (
    <SuspenseImageLetter {...props} ref={ref} src={url} noCache={noCache} />
  );
});

const TemplateLetterImageZoom = forwardRef<
  HTMLImageElement,
  TemplateLetterImageProps
>(({ id, noCache, propertyID, ...props }: TemplateLetterImageProps, ref) => {
  const { currentUser } = useAuth();
  const url = templateLettersApi.previewUrl(
    id,
    currentUser?.id as number,
    noCache,
    propertyID
  );
  return (
    <SuspenseImageLetterZoom
      {...props}
      className="h-full w-full"
      ref={ref}
      src={url}
      noCache={noCache}
    />
  );
});

const TemplateLetterHolder = ({
  template,
  className,
  pointLoader,
  propertyID
}: TemplateLetterHolderProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id } = template;

  const { isMobile } = useViewport();

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Suspense fallback={pointLoader ? <div className='w-80 justify-center flex'><span className="loading-10">loading preview...</span></div> : <SkeletonLoader />}>
      <div
        className="indicator flex flex-col relative"
        onClick={isMobile ? () => {} : handleImageClick}
      >
        <TemplateLetterImage
          id={id as number}
          className={className || "w-full"}
          propertyID={propertyID}
        />
        <p className="text-text-dark absolute left-2 top-1 font-medium">{template.name}</p>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: { margin: "auto", maxWidth: "80%", maxHeight: "80%" },
        }}
        ariaHideApp={false}
      >
        <TemplateLetterImageZoom id={id as number} className={"w-[900px]"} propertyID={propertyID} />
      </Modal>
    </Suspense>
  );
};

export default TemplateLetterHolder;
