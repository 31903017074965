/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC } from 'react';
import { useEffect, useState, useRef, createContext } from 'react';
import { loadScript } from 'src/utils/common';
import useAuth from 'src/hooks/useAuth';


const notifierUrl = process.env.REACT_APP_NOTIFIER_URL;

export declare type FayeListener<T = any> = (channel: string, message: T) => void;

export interface Cancelable {
  cancel: () => void;
}

export interface WithChannel<T = any> {
  withChannel?: (listener?: FayeListener<T>) => Cancelable & Promise<void>;
}

export declare type Subscription = Cancelable & WithChannel;

export interface FayeContextValue {
  subscribe: (channel: string, listener?: FayeListener) => Subscription;
}

export const FayeContext = createContext<FayeContextValue>({
  subscribe: () => {
    // @ts-ignore
    console.warn('[FayeContent] default context value in use, attempt to subscribe');
    return {
      cancel: () => {},
      withChannel: () => {}
    } as Subscription;
  }
});

interface FayeProviderProps {
  endpoint?: string;
}

export const FayeProvider: FC<FayeProviderProps> = (props: FayeProviderProps) => {
  // @ts-ignore
  const { children, endpoint } = props;
  const notifierArtifact = `${endpoint || notifierUrl}/client.js`;
  const user = useAuth();
  const client = useRef<any>();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [extensionAdded, setExtensionAdded] = useState(false);

  useEffect(() => {
    // load faye artifact then set the app as loaded
    // @todo: handle too long loading -> print an error
    loadScript(notifierArtifact)
      .then(() => {
        // setup client
        // @ts-ignore
        client.current = new window.Faye.Client(endpoint || notifierUrl, { retry: 5 });

        setLoaded(true);
        console.info(`Loaded ${notifierArtifact} successfully`);
      })
      .catch((err) => {
        setLoaded(false);
        console.warn(err.message);
      });
  }, [endpoint, notifierArtifact]);

  // let extensionAdded = false;
  useEffect(() => {
    if (!extensionAdded && user && client.current) {
      // adding token into faye client while subscribing
      client.current.addExtension({
        outgoing: (message: any, callback: (message: any) => void) => {
          if (message.channel !== '/meta/subscribe') {
            return callback(message);
          }

          if (!message.ext) {
            message.ext = {};
          }

          message.ext.token = localStorage.getItem("x-access-token");

          return callback(message);
        }
      });
      setExtensionAdded(true);
    }
  }, [user, client.current, extensionAdded]);

  if (!loaded) {
    return <div className='w-full justify-center flex'><span className='loading'>...</span></div>;
  }

  return (
    // @ts-ignore
    <FayeContext.Provider value={client.current}>{children}</FayeContext.Provider>
  );
};

export default FayeContext;
