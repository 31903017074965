import React from "react";
import Property from "src/interfaces/property";
import addCommas from "src/helpers/addCommas";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DriveProperty } from "src/interfaces/driveProperty";

interface DetailProps {
  title: string;
  value: any;
}

const Detail = ({ title, value }: DetailProps) => {
  return (
    <>
      <div className="flex flex-row gap-2 text-sm">
        <div className="w-1/2">
          <span className="font-medium text-text-dark dark:text-text-light">
            {title}
          </span>
        </div>
        <div className="w-1/2">
          <span className="font-medium text-zinc-500 dark:text-zinc-400">{value}</span>
        </div>
      </div>
    </>
  );
};

const Check = () => {
  return (
    <FontAwesomeIcon icon={faCircleCheck} className="text-success" />
  )
}

interface PropertyDetailsProps {
  property: Property | DriveProperty;
}

const PropertyDetails = ({ property }: PropertyDetailsProps) => {
  const squareFeetToAcres = (sqft: number): number => {
    const acres = sqft / 43560;
    return parseFloat(acres.toFixed(2));
  };

  const convertToReadableDate = (dateStr: string): string => {
    if (dateStr.length !== 8) {
      return "Unknown";
    }

    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);

    const date = new Date(`${year}-${month}-${day}`);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Unknown";
    }

    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <>
      <div className="card-compact card flex h-full w-full flex-row flex-wrap rounded-lg bg-card-light px-8 py-4 dark:bg-card-dark ">
        <div className="w-full md:w-1/2">
          <Detail title="Heating" value={property.heatingAC ? <Check /> : "N/A"} />
          <Detail
            title="Cooling"
            value={property.airConditioning ? <Check /> : "N/A"}
          />
          <Detail title="Basement" value={property.basement ? <Check /> : "N/A"} />
          <Detail title="Garage" value={property.garage ? <Check /> : "N/A"} />
          <Detail title="Water" value={property.water ? <Check /> : "N/A"} />
          <Detail title="Sewer" value={property.sewer ? <Check /> : "N/A"} />
          <Detail title="Patio" value={property.patio ? <Check /> : "N/A"} />
          <Detail title="Pool" value={property.pool ? <Check /> : "N/A"} />
          <Detail title="Porch" value={property.porch ? <Check /> : "N/A"} />
        </div>
        <div className="w-full md:w-1/2">
          <Detail title="Home Type" value={property.propertyType} />
          <Detail title="Year Built" value={property.yearBuilt} />
          <Detail
            title="Lot Size"
            value={`${squareFeetToAcres(property.lotSize || 0)} ac`}
          />
          <Detail
            title="Open Loans"
            value={`$${addCommas(property.totalOpenLienAmount || 0)} (${
              property.totalOpenLien
            })`}
          />

          <Detail
            title="LTV"
            value={
              property.loanToValue
                ? `${(property?.loanToValue * 100).toFixed(2)} %`
                : "?"
            }
          />
          <Detail
            title="Last Sale"
            value={`$${addCommas(property.marketValue || 0)}`}
          />
          <Detail
            title="Sale Date"
            value={convertToReadableDate(
              property.currentSaleContractDate || ""
            )}
          />
          <Detail
            title="Owner Type"
            value={property.ownerOccupied ? "Occupied" : "Absentee"}
          />
          <Detail
            title="In-State Owner"
            value={property.mailState === property.state ? "Yes" : "No"}
          />
        </div>
      </div>
    </>
  );
};

export default PropertyDetails;
