import React, { ReactNode } from "react";
import {
  faShoppingCart,
  faTags,
  faMagnifyingGlassDollar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ScoreProps {
  score: number;
  label: string;
  icon: ReactNode;
}

const Score = ({ score, label, icon }: ScoreProps) => {
  return (
    <div className="gap-22 flex columns-1 flex-col gap-1.5">
      <div className="flex flex-row gap-1">
        <p>{icon}</p>
        <p>{label}</p>
      </div>

      <p className="font-semibold text-black dark:text-white">{score}</p>
    </div>
  );
};

interface ScoresDisplayProps {
  retail: number;
  wholesale: number;
  investor: number;
}

const ScoresDisplay = ({ retail, wholesale, investor }: ScoresDisplayProps) => {
  return (
    <>
      <div className="item-center card rounded-lg w-full flex-row justify-between bg-card-light px-4 py-4 dark:bg-card-dark md:px-12">
        <Score
          score={retail}
          label={"Retail"}
          icon={<FontAwesomeIcon icon={faShoppingCart} color="#55CAA5" />}
        />
        <Score
          score={investor}
          label={"Rental"}
          icon={
            <FontAwesomeIcon icon={faMagnifyingGlassDollar} color="#59a2d3" />
          }
        />
        <Score
          score={wholesale}
          label={"Wholesale"}
          icon={<FontAwesomeIcon icon={faTags} color="#a850b0" />}
        />
      </div>
    </>
  );
};

export default ScoresDisplay;
