import React, { useCallback, useEffect } from "react";
import { subscriptionApi } from "src/api";
import { useState } from "react";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import {
  Subscription,
  Feature,
  SubscriptionFeatures,
  AppSubscriptionsAndFeatures,
} from "src/interfaces/subscriptions";
import useApp from "src/hooks/useAppContext";

interface SubscriptionContextInterface {
  appSubscriptions: Subscription[] | null | undefined;
  features: Feature[] | null | undefined;
  appSubscriptionsAndFeatures: AppSubscriptionsAndFeatures[] | null | undefined;
  fetchAppSubscriptions: () => void;
  fetchFeatures: () => void;
  fetchAppSubscriptionsAndFeatures: () => void;
}

type CombinedSubscription = {
  subscription: Subscription;
  subscriptionFeatures: SubscriptionFeatures;
};

const subscriptionContextDefaults: SubscriptionContextInterface = {
  appSubscriptions: null,
  features: null,
  appSubscriptionsAndFeatures: null,
  fetchAppSubscriptions: () => {},
  fetchFeatures: () => {},
  fetchAppSubscriptionsAndFeatures: () => {},
};

const SubscriptionContext = React.createContext<SubscriptionContextInterface>(
  subscriptionContextDefaults
);

export const SubscriptionProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {

  const { app } = useApp();

  const [appSubscriptions, setAppSubscriptions] = useState<Subscription[]>([]);

  const [features, setFeatures] = useState<Feature[]>([])

  const [appSubscriptionsAndFeatures, setAppSubscriptionsAndFeatures] =
    useState<AppSubscriptionsAndFeatures[]>();

  const { handleError } = useErrorHandler();

  const fetchAppSubscriptions = useCallback(async () => {
    try {
      if (app?.id) {
        const appSubscriptionsData = await subscriptionApi.getAllSubscriptions(
          app.id
        );
        setAppSubscriptions(appSubscriptionsData);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, app?.id]);

  const fetchFeatures = useCallback(async () => {
    try {
      const featuresData = await subscriptionApi.getFeatures();
      setFeatures(featuresData);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);


  const fetchAppSubscriptionsAndFeatures = useCallback(async () => {
    try {
      if (app?.id) {
        const appSubscriptionsData = await subscriptionApi.getAllSubscriptions(
          app?.id
        );

        // Fetch all features at once
        const allFeaturesData =
          await subscriptionApi.getAppSubscriptionFeaturesByAppID(app.id);

        const appSubscriptionsWithFeatures: CombinedSubscription[] = [];

        for (const subscription of appSubscriptionsData) {
          const appSubscriptionFeaturesData = allFeaturesData
            .filter((feature) => feature.subscriptionID === subscription.id)
            .sort((a, b) => a.featureID - b.featureID);

          const featuresObject: SubscriptionFeatures =
            appSubscriptionFeaturesData.reduce((obj: any, feature) => {
              obj[feature.id] = feature;
              return obj;
            }, {});

          appSubscriptionsWithFeatures.push({
            subscription,
            subscriptionFeatures: featuresObject,
          });
        }

        appSubscriptionsWithFeatures.sort(
          (a, b) => a.subscription.sortOrder - b.subscription.sortOrder
        );

        setAppSubscriptionsAndFeatures(appSubscriptionsWithFeatures);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, app]);

  useEffect(() => {
    fetchAppSubscriptions();
    fetchFeatures();
    fetchAppSubscriptionsAndFeatures();
  }, [
    fetchFeatures,
    fetchAppSubscriptions,
    fetchAppSubscriptionsAndFeatures,
  ]);

  return (
    <SubscriptionContext.Provider
      value={{
        appSubscriptions,
        features,
        appSubscriptionsAndFeatures,
        fetchAppSubscriptions,
        fetchFeatures,
        fetchAppSubscriptionsAndFeatures,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;
