import { AITerritory, Zones } from "src/interfaces/realeflow";

export  const convertToZones = (territories: AITerritory[]): Zones => {
  const zones: Zones = { zips: [], fips: [], states: [] };

  territories.forEach((territory: AITerritory) => {
    switch (territory.Type) {
      case "Zip":
        zones.zips.push(territory.Value);
        break;
      case "State":
        zones.states.push(territory.Value);
        break;
      case "FIPS":
        zones.fips.push(territory.Value);
        break;
    }
  });

  return zones;
};
