import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface TrashButtonProps {
  onClick?: (id?: number | undefined) => void;
  isSmall?: boolean;
}

const TrashButton = ({ onClick, isSmall }: TrashButtonProps) => {
  return (
    <>
      <label
        className={
          isSmall
            ? "btn-sm btn w-7 cursor-pointer border-none bg-back-light text-text-dark  hover:bg-card-light  hover:text-error dark:bg-base-100 dark:text-gray-500 hover:dark:bg-black dark:hover:text-error"
            : "hover-text-error btn-sm btn h-6 w-6 cursor-pointer bg-card-dark  text-text-light hover:bg-black dark:bg-back-dark dark:text-gray-500 hover:dark:bg-black"
        }
        onClick={() => onClick && onClick()}
      >
        <FontAwesomeIcon
          icon={faTrashCan}
          className={isSmall ? "h-5 w-5" : "h-6 w-6"}
        />
      </label>
    </>
  );
};

export default TrashButton;
