import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CartTypes = "offer" | "debtStack" | "proofOfFunds";
interface BaseItem {
  id: string;
  address: google.maps.places.AutocompletePrediction;
  type: CartTypes;
}

export interface OfferItem extends BaseItem {
  isThreeTiered: boolean;
  offer1: number;
  offer2: number | null;
  offer3: number | null;
  addressTo: string;
}

export interface DebtStackItem extends BaseItem {
  link?: string;
  success?: boolean;
}

export interface ProofOfFundsItem extends BaseItem {
  amount: number;
  recipientName: string;
}

export interface CartState {
  offers: OfferItem[];
  debtStacks: DebtStackItem[];
  proofOfFunds: ProofOfFundsItem[];
}

const initialState: CartState = {
  offers: [],
  debtStacks: [],
  proofOfFunds: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addOffer(state, action: PayloadAction<OfferItem>) {
      state.offers.push(action.payload);
    },
    addDebtStack(state, action: PayloadAction<DebtStackItem>) {
      state.debtStacks.push(action.payload);
    },
    addProofOfFunds(state, action: PayloadAction<ProofOfFundsItem>) {
      state.proofOfFunds.push(action.payload);
    },
    removeOffer(state, action: PayloadAction<string>) {
      state.offers = state.offers.filter((item) => item.id !== action.payload);
    },
    removeDebtStack(state, action: PayloadAction<string>) {
      state.debtStacks = state.debtStacks.filter(
        (item) => item.id !== action.payload
      );
    },
    removeProofOfFunds(state, action: PayloadAction<string>) {
      state.proofOfFunds = state.proofOfFunds.filter(
        (item) => item.id !== action.payload
      );
    },
    clearCart(state) {
      state.offers = [];
      state.debtStacks = [];
      state.proofOfFunds = [];
    },
  },
});

export const {
  addOffer,
  addDebtStack,
  addProofOfFunds,
  removeOffer,
  removeDebtStack,
  removeProofOfFunds,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
