import React from 'react';

interface VacationModeHelpProps {
  props?: any
}

const VacationModeHelp = ({props}: VacationModeHelpProps) => {

  return (
    <>
      
    </>
  )
};

export default VacationModeHelp;