import React from "react";
import WhatsNew3 from "./WhatsNew/WhatsNew3";

const WhatsNewModal = () => {

  return (
    <>
      <div>
          <WhatsNew3 />
      </div>
    </>
  );
};

export default WhatsNewModal;
