import React from 'react';
import useHelpDrawer from 'src/hooks/private/useHelpDrawer';
import SequencesHelp from './SequencesHelp';

interface CampaignsHelpProps {
  props?: any
}

const CampaignsHelp = ({props}: CampaignsHelpProps) => {

  const { helpLink } = useHelpDrawer();

  return (
    <>
    <div>
      <p>
        A Campaign is a collection of settings for a <span
            className="font-bold hover:underline text-primary cursor-pointer"
            onClick={() => helpLink("Sequences", <SequencesHelp />)}
          >
            Sequence
          </span> . There are two types of campaigns, absentee or owner occupied. Each campaign can have a name, a number of pieces of mail to send, the cadence between each mail sending and then a collection of templates of either postcards or letters to send. 
      </p>
    </div>
      
    </>
  )
};

export default CampaignsHelp;