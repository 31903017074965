import React from "react";
import { Campaign } from "src/interfaces/campaign";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import useViewport from "src/hooks/useViewport";
import CampaignsRow from "./CampaignsRow";

const CampaignsTab = () => {
  const { campaigns } = useCampaignContext();

  const { isMobile } = useViewport();

  return (
    <div className="">
      <table
        className={
          isMobile
            ? "table-compact table w-full shadow-xl"
            : "table w-full shadow-xl"
        }
      >
        <thead>
          <tr className="border-b-2 border-secondary text-secondary">
            <th
              style={{
                position: "static",
              }}
              className="select-none rounded-tl-lg bg-card-light text-center text-xs dark:bg-card-dark md:text-base"
            >
              Name
            </th>
            <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
              Settings
            </th>
            {!isMobile && (
              <th className="select-none bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
                Created By
              </th>
            )}
            <th className="select-none rounded-tr-lg bg-card-light text-center text-xs dark:bg-card-dark md:text-base">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="">
          {campaigns.length ? (
            campaigns.map((campaign: Campaign, index: number) => (
              <CampaignsRow
                key={index}
                campaign={campaign}
                numRows={campaigns.length}
                index={index}
              />
            ))
          ) : (
            <tr className="border-b-0">
              <td
                className="rounded-b-lg bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                colSpan={4}
              >
                No Campaigns Yet
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CampaignsTab;
