/* eslint-disable import/no-anonymous-default-export */
import { Offer } from "src/interfaces/offer";
import { FilterParams } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { customHash } from "src/utils/hash";
import Property from "src/interfaces/property";
import { getOfferPDFfilename } from "src/helpers/pdf";

class OfferApi extends ApiTokenSupport {
  async getOffers(filter?: FilterParams): Promise<Offer[]> {
    const resp = await axios.post<Offer[]>(
      `/api/offer/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  async createOffer(
    propertyID: number,
    arv: number,
    offerAmount: number,
    offer2Amount?: number,
    offer3Amount?: number,
    isThreeTiered?: boolean,
    addressTo?: string
  ): Promise<any> {
    const resp = await axios.post<Offer>(
      `/api/offer/create`,
      {
        propertyID,
        arv,
        offerAmount,
        offer2Amount,
        offer3Amount,
        isThreeTiered,
        addressTo,
      },
      this.withAuthorization()
    );
    return resp.data;
  }

  async sendOffer(offerID: number): Promise<any> {
    const resp = await axios.post<string>(
      `/api/offer/send/${offerID}`,
      {},
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAOffers(propertyID: number): Promise<Offer[]> {
    const resp = await axios.get<Offer[]>(
      `api/offer/property/${propertyID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getFHALoansByStateCounty(
    state: string,
    countyID: string
  ): Promise<any> {
    const resp = await axios.post<any>(
      `api/fhaloan/get-state-county`,
      { state, countyID },
      this.withAuthorization()
    );
    return resp.data;
  }

  preview(offerID: number, mode: string, property: Property) {
    const hashed = customHash(offerID);
    const fileName = getOfferPDFfilename(property);
    return `${process.env.REACT_APP_BACKEND_URL}/api/offer/preview/${hashed}/${fileName}?mode=${mode}`;
  }

  async updateOfferStatus(offerID: number, status: string): Promise<any> {
    const resp = await axios.put<any>(
      `api/offer/${offerID}`,
      { status },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new OfferApi();
