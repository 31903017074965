import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Configs } from "src/interfaces/configs";
import useConfigs from "src/hooks/useConfigs";

const BannerAlertMobile = () => {
  const { configs } = useConfigs();

  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const bannerConfig = configs.find(
      (config: Configs) => config.name === "BannerAlert"
    );
    const booleanValue = bannerConfig?.value === "true" ? true : false;
    setShowBanner(booleanValue);
  }, [configs]);

  return (
    <>
      {showBanner && (
        <div className="card bg-warning p-4 ">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-base text-black"
          />
          <p className="font-medium text-black text-sm">
            We are currently experiencing an issue with a data provider. Some
            services may be affected.
          </p>
        </div>
      )}
    </>
  );
};

export default BannerAlertMobile;
