import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import sequenceApi from "src/api/sequence";
import { Sequence, Sequence1, SequenceResponse } from "src/interfaces/sequence";
import useUserContext from "src/hooks/private/useUserContext";
import { Mailing } from "src/interfaces/mailing";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import { FilterParams } from "src/global/types";
// import { uniqueValue } from "src/helpers/js-utils";

interface SequenceMailingContextInterface {
  sequences: Sequence[];
  fetchSequences: () => void;
  mailings: Mailing[];
  fetchMailings: () => void;
  activeSequences: any;
  fetchActiveSequences: (params?: FilterParams) => void;
  inactiveSequences: any;
  fetchInactiveSequences: (params?: FilterParams) => void;
  activeCurrentPage: number;
  setActiveCurrentPage: Dispatch<SetStateAction<number>>;
  activeTotalPage: number;
  setActiveTotalPage: Dispatch<SetStateAction<number>>;
  inactiveCurrentPage: number;
  setInactiveCurrentPage: Dispatch<SetStateAction<number>>;
  inactiveTotalPage: number;
  setInactiveTotalPage: Dispatch<SetStateAction<number>>;
}

const SequenceLetterContextDefaults: SequenceMailingContextInterface = {
  sequences: [],
  fetchSequences: () => {},
  mailings: [],
  fetchMailings: () => {},
  activeSequences: [],
  fetchActiveSequences: () => {},
  inactiveSequences: [],
  fetchInactiveSequences: () => {},
  activeCurrentPage: 1,
  setActiveCurrentPage: () => {},
  activeTotalPage: 1,
  setActiveTotalPage: () => {},
  inactiveCurrentPage: 1,
  setInactiveCurrentPage: () => {},
  inactiveTotalPage: 1,
  setInactiveTotalPage: () => {},
};

const SequenceLetterContext =
  React.createContext<SequenceMailingContextInterface>(
    SequenceLetterContextDefaults
  );

interface SequenceLetterProviderProps {}

export const SequenceLetterProvider = ({
  children,
}: PropsWithChildren<SequenceLetterProviderProps>) => {
  const { user } = useUserContext();

  const [sequences, setSequences] = useState<Sequence[]>([]);
  const [activeSequences, setActiveSequences] = useState<Sequence1[]>([]);
  const [activeCurrentPage, setActiveCurrentPage] = useState<number>(1);
  const [activeTotalPage, setActiveTotalPage] = useState<number>(1);

  const [inactiveSequences, setInactiveSequences] = useState<any>([]);
  const [inactiveCurrentPage, setInactiveCurrentPage] = useState<number>(1);
  const [inactiveTotalPage, setInactiveTotalPage] = useState<number>(1);

  const [mailings, setMailings] = useState<Mailing[]>([]);

  function uniqueSequence(arr1: any[], arr2: any[]) {
    const arr1Map = arr1.reduce((map, item) => {
      map.set(item.sequence.id, item);
      return map;
    }, new Map());

    return [...arr1, ...arr2.filter((item) => !arr1Map.has(item.sequence.id))];
  }

  const { handleError } = useErrorHandler();

  const fetchSequences = useCallback(async () => {
    if (user) {
      try {
        const sequences = await sequenceApi.getSequences(user?.teamID);
        setSequences(sequences);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  const fetchMailings = useCallback(async () => {
    if (user) {
      try {
        const mailings = await sequenceApi.getMailings(user?.teamID);
        setMailings(mailings);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  const fetchActiveSequences = useCallback(
    async (params?: FilterParams) => {
      try {
        const data: SequenceResponse = await sequenceApi.listSequences(params);
        if (activeCurrentPage === 1) {
          setActiveSequences(data.rows);
        } else {
          setActiveSequences((oldData: Sequence1[]) =>
            uniqueSequence(oldData, data.rows)
          );
        }
        setActiveTotalPage(data?.totalPages);
      } catch (err: any) {
        handleError(err);
      }
    },
    [activeCurrentPage, handleError]
  );

  const fetchInactiveSequences = useCallback(
    async (params?: FilterParams) => {
      try {
        const data: SequenceResponse = await sequenceApi.listSequences(params);

        if (inactiveCurrentPage === 1) {
          setInactiveSequences(data.rows);
        } else {
          setInactiveSequences((oldData: Sequence1[]) =>
            uniqueSequence(oldData, data.rows)
          );
        }
        setInactiveTotalPage(data?.totalPages);
      } catch (err: any) {
        handleError(err);
      }
    },
    [inactiveCurrentPage, handleError]
  );

  useEffect(() => {
    fetchSequences();
  }, [fetchSequences]);

  useEffect(() => {
    fetchMailings();
  }, [fetchMailings]);

  useEffect(() => {
    fetchActiveSequences({
      page: activeCurrentPage,
      query: {
        isActive: true,
      },
    });
  }, [activeCurrentPage, fetchActiveSequences]);

  useEffect(() => {
    fetchInactiveSequences({
      page: inactiveCurrentPage,
      query: {
        isActive: false,
      },
    });
  }, [inactiveCurrentPage, fetchInactiveSequences]);

  return (
    <SequenceLetterContext.Provider
      value={{
        sequences,
        fetchSequences,
        mailings,
        fetchMailings,
        activeSequences,
        fetchActiveSequences,
        inactiveSequences,
        fetchInactiveSequences,
        activeCurrentPage,
        setActiveCurrentPage,
        activeTotalPage,
        setActiveTotalPage,
        inactiveCurrentPage,
        setInactiveCurrentPage,
        inactiveTotalPage,
        setInactiveTotalPage,
      }}
    >
      {children}
    </SequenceLetterContext.Provider>
  );
};

export default SequenceLetterContext;
