import React from "react";
import { faFileCsv, faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faFiles } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import BulkImportHelp from "src/components/help/BulkImportHelp";
import BulkImportModal from "src/components/modals/BulkImportModal/BulkImportModal";
import useModal from "src/hooks/useModal";
import DealioLinkModal from "src/components/modals/BulkImportModal/DealioLinkModal";
import useDrawer from "src/hooks/private/useDrawer";
import DealioLinkHelp from "src/components/help/DealioLinkHelp";
import useApp from "src/hooks/useAppContext";

const BulkImportButton = () => {
  const { openModalWith } = useModal();

  const { openPropertyDrawerWith } = useDrawer();

  const { app } = useApp();

  const navigate = useNavigate();

  const handleBulkImportAddresses = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    openModalWith({
      title: "Import Addresses",
      body: <BulkImportModal />,
      helpTitle: "Bulk Import",
      helpBody: <BulkImportHelp />,
      hideButtons: true,
    });
  };

  const handleClickImportHistory = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    navigate("import-history");
  };

  const handleDealioLinkAdd = () => {
    app?.displayDealio &&
    openModalWith({
      title: "Import From Dealio Link",
      body: <DealioLinkModal openPropertyDrawerWith={openPropertyDrawerWith} />,
      helpTitle: "Import From Dealio Link",
      helpBody: <DealioLinkHelp />,
      hideButtons: true,
    });
  };

  return (
    <div className="dropdown dropdown-end dropdown-bottom dropdown-hover">
      <label className="btn btn-ghost flex select-none items-center justify-center text-xl font-bold text-primary hover:bg-hover-back-light dark:hover:bg-hover-back-dark ">
        <FontAwesomeIcon icon={faPlus} />
      </label>
      <ul
        tabIndex={0}
        className="menu dropdown-content w-52 rounded-box bg-card-light p-2 text-primary shadow-2xl drop-shadow-xl dark:bg-card-dark md:dark:bg-card-dark"
      >
        <li className="mb-1">
          <button
            onClick={handleBulkImportAddresses}
            className="btn btn-ghost flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
          >
            <FontAwesomeIcon icon={faFileCsv} className="w-4" />
            <span>Bulk Import</span>
          </button>
        </li>
        <li className="mb-1">
          <button
            onClick={handleClickImportHistory}
            className="btn btn-ghost flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
          >
            <FontAwesomeIcon icon={faFiles} className="w-4" />
            <span>Import History</span>
          </button>
        </li>
        {app?.displayDealio && (
          <li className="mb-1">
            <button
              onClick={handleDealioLinkAdd}
              className="btn btn-ghost flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
            >
              <FontAwesomeIcon icon={faLink} className="w-4" />
              <span>Dealio Link</span>
             
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default BulkImportButton;
