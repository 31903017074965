import moment from "moment";
import Stripe from "stripe";
const calculateProrationAmount = ({
  cost,
  quantity,
  subscription,
  isAnnual,
}: {
  cost: number;
  quantity: number;
  subscription: Stripe.Subscription;
  isAnnual: boolean;
}): number => {
  const now = moment();

  const billingCycleEnd = moment.unix(subscription.current_period_end);

  const remainingDays = billingCycleEnd.diff(now, "days");

  const billingCycleDurationDays = isAnnual ? 365 : 30;

  const prorationRate = remainingDays / billingCycleDurationDays;

  console.log("prorationRate", prorationRate);
  console.log("cost", cost);
  console.log("quantity", quantity);

  const proratedAmount = cost * quantity * prorationRate;

  return Math.round(proratedAmount * 100) / 100;
};

export default calculateProrationAmount;
