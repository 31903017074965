import { Link } from "react-router-dom";

import logoLight from "src/assets/images/SendFuse_Logo_Light.png";
import logoDark from "src/assets/images/SendFuse_Logo_Dark.png";

import useTheme from "src/hooks/useTheme";
import {
  faArrowRightFromBracket,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAuth from "src/hooks/useAuth";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";

interface SideNavBirddogProps {
  props?: any;
}

const SideNavBirddog = ({ props }: SideNavBirddogProps) => {
  const { app } = useApp();
  const { logout } = useAuth();
  const { mode } = useTheme();
  return (
    <>
      <nav className="fixed left-0 top-0 bottom-0 w-72 bg-card-light py-4 px-6 dark:bg-card-dark">
        <div>
          <Link to="/dashboard">
            <div className="mx-auto flex items-center justify-center">
              <img
                src={mode === "light" ? logoLight : logoDark}
                alt={`${app?.name || getHostName()} Logo`}
                className="my-2"
              />
            </div>
          </Link>
          <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />
          <Link to={"/watchlist"}>
            <div
              className={
                "mb-1 rounded-lg bg-gradient-to-r from-gradient1 to-gradient2 px-10 py-1 shadow-lg shadow-icon-light dark:shadow-back-dark lg:mb-3 lg:py-3"
              }
            >
              <FontAwesomeIcon
                icon={faEye}
                className={"mr-1.5 text-xl text-text-light dark:text-text-dark"}
              />
              <span
                className={
                  "text-xl font-bold text-text-light dark:text-text-dark"
                }
              >
                Watchlist
              </span>
            </div>
          </Link>

          <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />
          <Link to={"/"} onClick={logout} className="mb-4 px-10 py-3">
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              className="text-md mr-1.5 text-icon-light dark:text-icon-dark"
            />
            <span className="text-md font-bold text-icon-light dark:text-icon-dark">
              Logout
            </span>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default SideNavBirddog;
