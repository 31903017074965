import { PropsWithChildren, useState, createContext } from "react";
import Property from "src/interfaces/property";

interface RealtimeSyncContextValue {
  notificationUpdater: number;
  propertyUpdater: { action: string; property?: Property };
  syncNotificationUpdater: () => void;
  syncPropertyUpdater: (p: { action: string; property?: Property }) => void;
}

const defaultState = {
  notificationUpdater: 0,
  propertyUpdater: { action: "" },
  syncNotificationUpdater: () => {},
  syncPropertyUpdater: () => {},
};

export const RealtimeSyncContext = createContext<RealtimeSyncContextValue>({
  ...defaultState,
});

interface RealtimeSyncContextProps {}

export const RealtimeSyncProvider = ({
  children,
}: PropsWithChildren<RealtimeSyncContextProps>) => {
  const [notificationUpdater, setNotificationUpdater] = useState<number>(0);
  const [propertyUpdater, setPropertyUpdater] = useState<{
    action: string;
    property?: Property;
  }>(defaultState.propertyUpdater);

  const syncNotificationUpdater = () => setNotificationUpdater((n) => n + 1);
  const syncPropertyUpdater = (p: { action: string; property?: Property }) =>
    setPropertyUpdater(p);

  return (
    <RealtimeSyncContext.Provider
      value={{
        syncNotificationUpdater,
        notificationUpdater,
        propertyUpdater,
        syncPropertyUpdater,
      }}
    >
      {children}
    </RealtimeSyncContext.Provider>
  );
};
