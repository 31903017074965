import { Link } from "react-router-dom";

import SideNavItems from "./SideNavItems";
import useTheme from "src/hooks/useTheme";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";
import Upgrade from "./Upgrade";

const SideBarNav = () => {
  const { mode } = useTheme();

  const { app } = useApp();

  return (
    <>
      <nav className="fixed bottom-0 left-0 top-0 w-72 bg-card-light px-6 pt-4 dark:bg-card-dark overflow-y-scroll">
        <div>
          <Link to="/dashboard">
            <div className="mx-auto flex items-center justify-center">
              <img
                src={mode === "light" ? app?.logoPathLight : app?.logoPathDark}
                alt={`${app?.name || getHostName()} Logo`}
                className={app?.id === 1 ? "my-2" : "px-20"}
              />
            </div>
          </Link>
          <hr className="my-4 min-w-full border-icon-light dark:border-icon-dark" />
          <SideNavItems />
        </div>
        <div className="w-full px-4 pt-8">
          <Upgrade />
        </div>
      </nav>
    </>
  );
};

export default SideBarNav;
