/* eslint-disable import/no-anonymous-default-export */
import { Configs } from "src/interfaces/configs";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";


class ConfigApi extends ApiTokenSupport {

  async getConfigs(): Promise<Configs[]> {
    const resp = await axios.get<any>(`/api/config/configs`, this.withAuthorization());
    return resp.data;
  }
}

export default new ConfigApi();