// src/hooks/useSyncMarketOrders.ts
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { marketApi } from "src/api";
import { setOrders } from "src/redux/slices/marketOrderSlice";
import MarketOrder from "src/interfaces/marketOrder";
import { RootState } from "src/redux/store";

const useSyncMarketOrders = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.marketOrders);

  const emails = state.emails;

  useEffect(() => {
    if (!emails.length) {
      setError("No email provided.");
      setIsLoading(false);
      return;
    }

    const fetchOrders = async () => {
      try {
        const orders: MarketOrder[] = await marketApi.getMarketOrders(emails);
        dispatch(setOrders(orders));
      } catch (err: any) {
        setError(err?.message || "An error occurred while retrieving orders.");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [emails, dispatch]);

  return { isLoading, error };
};

export default useSyncMarketOrders;
