import cogoLogo from "src/assets/images/Cogo_Capital.png";
import classNames from "classnames";

interface CogoCapitalButtonProps {
  className?: string;
}

const CogoCapitalButton = ({ className }: CogoCapitalButtonProps) => {
  const _className = classNames(
    className,
    "btn btn-secondary hover:bg-secondary text-text-light"
  );

  const linkSource =
    "https://app.cogocapital.com/?utm_source=cogohomepage&utm_medium=direct&utm_campaign=cogowebsite";

  return (
    <>
      <a
        href={linkSource}
        target="_blank"
        rel="noreferrer"
        className={_className}
      >
        <span className="mr-2">Apply for funds</span>
        <img src={cogoLogo} alt="Cogo Capital" className="w-28" />
      </a>
    </>
  );
};

export default CogoCapitalButton;
