/* eslint-disable react-hooks/rules-of-hooks */
import { useFaye } from "src/faye";
import Property from "src/interfaces/property";
import useRealtimeSync from "src/hooks/useRealtimeSync";

export const listenPropertyChanges = (teamID: number) => {
  const { syncPropertyUpdater } = useRealtimeSync();
  useFaye(
    {
      [`/${teamID}/properties`]: (
        channel: string,
        data: { action: string; property: Property }
      ) => {
        syncPropertyUpdater(data);
      },
    },
    [teamID]
  );
};
