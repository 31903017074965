import React from "react";

interface UsageHelpProps {
  props?: any;
}

const UsageHelp = ({ props }: UsageHelpProps) => {
  return (
    <>
      <div>
        <p>
          Your current subscription has limits on how many leads or users your
          are able to add to your account. Once a limit is reached you will need
          to upgrade to the next subscription to be able to add additional leads
          or users.
        </p>
      </div>
    </>
  );
};

export default UsageHelp;
