import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface BackButtonProps {
  onClick: () => void;
}

const BackButton = ({onClick}: BackButtonProps) => {

  return (
    <>
      <button
        className="btn btn-ghost hover:bg-hover-back-light hover:dark:bg-hover-card-dark"
        onClick={onClick}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="text-title-text-dark dark:text-title-text-dark"
            />
      </button>
    </>
  )
};

export default BackButton;