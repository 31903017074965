import { Subscription } from "src/interfaces/subscriptions";

const getNextSubscription = (currentSub: Subscription, allSubs: Subscription[], canTeam = false): Subscription | null => {

  const sortedSubs = allSubs.sort((a, b) => a.sortOrder - b.sortOrder);

  const currentIndex = sortedSubs.findIndex(sub => sub.id === currentSub.id);

  // if canTeam is true, find the next subscription where canTeam is true
  if(canTeam) {
    return sortedSubs.slice(currentIndex + 1).find(sub => sub.canTeam) || null;
  }

  // find the next subscription in sortOrder, irrespective of canTeam
  return sortedSubs[currentIndex + 1] || null;
}

export default getNextSubscription;