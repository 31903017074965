import { propertyApi } from "src/api";
import Property from "src/interfaces/property";
import usePropertiesContext from "src/hooks/private/usePropertiesContext";
import useAlert from "src/hooks/useAlert";
import useDrawer from "src/hooks/private/useDrawer";
import useModal from "src/hooks/useModal";

interface InactiveBannerProps {
  property: Property;
}

const InactiveBanner = ({ property }: InactiveBannerProps) => {
  const { setShowModal, openModalWith } = useModal();

  const { fetchProperties } = usePropertiesContext();

  const { setAlert } = useAlert();

  const { showPropertyDrawer, setShowPropertyDrawer } = useDrawer();

  const { id, isActive } = property;

  const onRestoreSubmit = async () => {
    setShowModal(false);
    await propertyApi.updatePropertyIsActive(id, true);
    setAlert({
      display: true,
      message: "Property restored",
      type: "success",
    });
    showPropertyDrawer && setShowPropertyDrawer(false);
    fetchProperties();
  };

  const handleRestore = async () => {
    setShowPropertyDrawer(false);
    openModalWith({
      title: "Restore Property",
      body: (
        <div className="text-center">
          <p className="text-text-dark dark:text-text-light">
            Are you sure you want to restore this property?
          </p>
        </div>
      ),
      onSubmit: onRestoreSubmit,
      helpTitle: "Deleting a Property",
      helpBody: (
        <p>This will restore a property to your team's Properties tab.</p>
      ),
    });
  };

  return (
    <>
      {!isActive && (
        <div className="w-full bg-error h-8 rounded-lg flex items-center justify-center mb-2 z-50">
          <div className="w-full flex items-center justify-center">
            <div className="flex items-center justify-center gap-3">
              <div className="flex items-center justify-center">
                <span className="text-white font-bold text-xxs sm:text-xs md:text-sm">
                  This property is in the trash
                </span>
              </div>
              <button
                className="btn btn-outline btn-xs text-white rounded-md hover:bg-white hover:text-black"
                onClick={handleRestore}
              >
                <span className="text-xxxs xs:text-xxs sm:text-xs">
                  Restore Property
                  </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InactiveBanner;
