import { PropsWithChildren, createContext, ReactNode, useState } from "react";
import HelpDrawer from "src/components/help/HelpDrawer";

export interface HelpDrawerOptionsProps {
  title: string;
  body: ReactNode;
}

interface HelpDrawerContextValue {
  showHelpDrawer: boolean;
  setShowHelpDrawer: (showCard: boolean) => void;
  openHelpDrawerWith: (o: HelpDrawerOptionsProps) => void;
  helpHistory: any[];
  setHelpHistory: any;
  helpLink: (title: string, body: ReactNode) => void;
  backLink: () => void;
}

const defaultState = {
  showHelpDrawer: false,
  setShowHelpDrawer: () => {},
  openHelpDrawerWith: () => {},
  helpHistory: [],
  setHelpHistory: () => {},
  helpLink: () => {},
  backLink: () => {},
};

export const HelpDrawerContext = createContext<HelpDrawerContextValue>({
  ...defaultState,
});

interface HelpDrawerContextProps {}

export const HelpDrawerProvider = ({
  children,
}: PropsWithChildren<HelpDrawerContextProps>) => {
  const [showHelpDrawer, setShowHelpDrawer] = useState<boolean>(
    defaultState.showHelpDrawer
  );

  const [HelpDrawerProps, setHelpDrawerProps] =
    useState<HelpDrawerOptionsProps>({
      title: "",
      body: <></>,
    });

  const [helpHistory, setHelpHistory] = useState<any[]>(
    defaultState.helpHistory
  );

  const openHelpDrawerWith = (o: HelpDrawerOptionsProps) => {
    setHelpDrawerProps(o);
    setShowHelpDrawer(true);
    setHelpHistory([{ ...o }]);
  };

  const helpLink = (title: string, body: ReactNode) => {
    setHelpHistory(helpHistory.concat({ title: title, body: body }));
    setHelpDrawerProps({
      title: title,
      body: body,
    });
  };

  const backLink = () => {
    helpHistory.pop();
    const { title, body } = helpHistory[helpHistory.length - 1]
    setHelpDrawerProps({
      title: title,
      body: body,
    });
  };

  return (
    <HelpDrawerContext.Provider
      value={{
        showHelpDrawer,
        setShowHelpDrawer,
        openHelpDrawerWith,
        helpHistory,
        setHelpHistory,
        helpLink,
        backLink,
      }}
    >
      <HelpDrawer {...HelpDrawerProps} />
      {children}
    </HelpDrawerContext.Provider>
  );
};
