import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import SkipTracesHelp from "./SkipTracesHelp";

const CallsEmailsHelp = () => {
  const { helpLink } = useHelpDrawer();

  return (
    <>
      <div>
        <p>
          Once you have made a successful{" "}
          <span
            className="font-bold hover:underline text-primary cursor-pointer"
            onClick={() => helpLink("Skip Traces", <SkipTracesHelp />)}
          >
            Skip Trace
          </span>{" "}
          you may begin making Calls or Emails on a property. Simply click on
          the Skip Trace button, or the Activity Badge in the Property Updates
          table to show a modal with the Skip Traced Phone Numbers or Emails.{" "}
        </p>
      </div>
    </>
  );
};

export default CallsEmailsHelp;
