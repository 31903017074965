/* eslint-disable import/no-anonymous-default-export */
import { StripeAccount } from "src/interfaces/stripeAccount";
import { FilterParams } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class StripeAccountApi extends ApiTokenSupport {
  /**
   * Get stripe account
   *
   * @returns
   */
  async getStripeAccount(filter?: FilterParams): Promise<StripeAccount[]> {
    const resp = await axios.post<StripeAccount[]>(
      `/api/stripeAccount/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }


  async addNewCard(cardToken: any) {
    const resp = await axios.post(
      `/api/stripeAccount/add-new-card`,
      { cardToken, isDefault: true },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new StripeAccountApi();
