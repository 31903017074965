
interface SubscriptionBadgeProps {
  subscription: string;
  onClick?: () => void;
  className?: string;
}

const SubscriptionBadge = ({
  onClick,
}: SubscriptionBadgeProps) => {


  return (
    <>
      <label  onClick={onClick}>
        <div className="badge badge-accent font-extrabold text-text-light dark:text-text-dark">
          Pro
        </div>
      </label>
    </>
  );
};

export default SubscriptionBadge;
