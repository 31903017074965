import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notificationApi } from "src/api";
import { listenNewNotification } from "src/faye/listener";
import useRealtimeSync from "src/hooks/useRealtimeSync";
import useNotification from "src/hooks/private/useNotification";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useAuth from "src/hooks/useAuth";
import InlineNotification from "src/components/notifications/InlineNotification";
import { Notification } from "src/global/types";

interface ListenerProps {
  teamID: number;
}

const Listener = React.memo(({ teamID }: ListenerProps) => {
  listenNewNotification(teamID);
  return <></>;
});

const AlertButton = () => {
  const { notifications, getUserNotifications, readNotification } =
    useNotification();

  const [unreadNotification, setUnreadNotification] = useState<number>(0);

  const { handleError } = useErrorHandler();

  const { currentUser } = useAuth();

  const { notificationUpdater } = useRealtimeSync();

  const navigate = useNavigate();

  const getNotificationCount = useCallback(async () => {
    try {
      const count = await notificationApi.getUserNotificationCount();
      setUnreadNotification(count);
    } catch (error: any) {
      handleError(error);
    }
  }, [handleError]);

  const resetCount = async () => {
    try {
      await notificationApi.resetUserNotificationCount();
      setUnreadNotification(0);
    } catch (err) {}
  };

  const getNotifications = useCallback(async () => {
    let params = {
      limit: 5,
      page: 1,
      sort: "-createdAt",
      query: {
        showUnreadOnly: false,
      },
    };

    await getUserNotifications(params);
  }, [getUserNotifications]);

  useEffect(() => {
    getNotificationCount();
    getNotifications();
  }, [notificationUpdater, getNotifications, getNotificationCount]);

  const handleClickViewAll = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    navigate("/notifications");
  };

  const readAllNotifications = () => {
    readNotification(0, true);
    resetCount();
  };

  return (
    <>
      {currentUser?.teamID && <Listener teamID={currentUser.teamID} />}
      <div className="dropdown dropdown-bottom dropdown-hover flex columns-1 flex-col items-center">
        <button
          tabIndex={0}
          className="btn btn-ghost relative hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
          onClick={resetCount}
        >
          <FontAwesomeIcon icon={faBell} className="text-xl text-primary" />
          {unreadNotification !== 0 && (
            <div className="badge badge-accent absolute left-3 top-0 text-sm font-extrabold">
              {unreadNotification < 99 ? unreadNotification : "99+"}
            </div>
          )}
        </button>

        <ul
          tabIndex={0}
          className="h-hull dropdown-content mt-0.5 max-h-[400px] w-[300px] overflow-auto rounded-box bg-card-light p-2 shadow-2xl drop-shadow-xl dark:bg-card-dark xs:max-h-[400px] xs:w-[300px]  sm:max-h-max sm:w-[350px] md:w-[400px] md:dark:bg-card-dark lg:w-[550px] xl:w-[550px] 2xl:w-[550px] z-50"
        >
          <div id="dropdownUsers" className="z-10">
            {notifications && notifications.length !== 0 ? (
              notifications
                ?.slice(0, 5)
                ?.map((notification: Notification, index: number) => (
                  <InlineNotification key={index} notification={notification} />
                ))
            ) : (
              <div className="mt-4 flex flex-wrap justify-center text-center">
                <label className="text-text-dark dark:text-text-light">
                  You're all caught up on notifications!
                </label>
              </div>
            )}
            <div className="my-2 flex flex-wrap justify-center gap-4 font-semibold text-text-dark dark:text-text-light">
              <div
                onClick={handleClickViewAll}
                className="cursor-pointer  hover:underline "
              >
                View all
              </div>
              <div>
                <label
                  className="cursor-pointer text-secondary  hover:underline "
                  onClick={readAllNotifications}
                >
                  Mark all as read
                </label>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </>
  );
};

export default AlertButton;
