import React from "react";
import AnimatedNumber from "react-awesome-animated-number";
import useApp from "src/hooks/useAppContext";

interface CoinDisplayProps {
  initialCoins: number;
  recurringCoins: number;
  isAnnual: boolean;
  lightModeOnly?: boolean;
}

const CoinDisplay: React.FC<CoinDisplayProps> = ({
  initialCoins,
  recurringCoins,
  isAnnual,
  lightModeOnly,
}) => {
  const { app } = useApp();

  return (
    <>
      <div className="flex columns-1 flex-col rounded-lg border border-secondary py-1.5">
        <div className="relative flex w-full items-center justify-center">
          <div className="btn btn-secondary btn-xs absolute -top-[22px] z-50 w-2/3 normal-case text-text-light shadow-md">
            {app?.coinName}
          </div>
        </div>
        <div className="flex items-end justify-center pt-1">
          {initialCoins < 0 && (
            <>
              <h2
                className={`mr-1 text-lg font-extrabold ${
                  lightModeOnly ? "text-black" : "text-black dark:text-white"
                }`}
              >
                <AnimatedNumber
                  className=""
                  value={initialCoins}
                  size={17}
                  duration={200}
                />
              </h2>
              <div>
                <p className="mt-1 text-gray-500">/ instant</p>
              </div>
            </>
          )}
        </div>
        <div className="flex items-end justify-center ">
          <h2
            className={`mr-1 text-lg font-extrabold ${
              lightModeOnly ? "text-black" : "text-black dark:text-white"
            }`}
          >
            <AnimatedNumber
              className=""
              value={recurringCoins}
              size={17}
              duration={200}
            />
          </h2>
          <div>
            <p className="mt-1 text-gray-500">
              / {isAnnual ? "year" : "month"}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoinDisplay;
