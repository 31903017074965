import useUserContext from "src/hooks/private/useUserContext";
import { userApi } from "src/api";
import { useState } from "react";
import useViewport from "src/hooks/useViewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faMessageXmark,
  faMessageQuestion,
} from "@fortawesome/pro-solid-svg-icons";

const UserName = () => {
  const { user, userSettings, fetchUser } = useUserContext();

  const { width } = useViewport();

  const [isTooltips, setIsTooltips] = useState<boolean>(
    userSettings?.isTooltips || true
  );

  const handleToggleTooltips = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    if (user) {
      const resp = await userApi.updateSettings(user?.id, {
        isTooltips: !isTooltips,
      });
      setIsTooltips(resp.isTooltips);
      fetchUser();
    }
  };
  return (
    <>
      <div className="dropdown-hover dropdown-end dropdown">
        <label
          tabIndex={0}
          className="btn-ghost btn mr-2 select-none text-xl font-bold text-primary hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
        >
          {width < 1280 || !user?.firstName || !user?.lastName ? (
            <FontAwesomeIcon className="text-primary" icon={faUser} />
          ) : (
            `${user?.firstName} ${user?.lastName}`
          )}
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu rounded-box mt-0.5 w-52 bg-card-light p-2 text-primary shadow-2xl drop-shadow-xl dark:bg-card-dark md:dark:bg-card-dark"
        >
          <li className="">
            <button
              onClick={handleToggleTooltips}
              className="btn-ghost btn flex justify-start font-semibold text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
            >
              <FontAwesomeIcon
                icon={isTooltips ? faMessageXmark : faMessageQuestion}
                className="w-4"
              />
              <span>{isTooltips ? "Hide Tooltips" : "Show Tooltips"}</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default UserName;
