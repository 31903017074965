/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { TemplateID } from "src/interfaces/campaign";
import getDateStr from "src/helpers/dateStrings";

export interface CampaignTemplateForm {
  campaignName: string;
  numLetters: number;
  numDays: number;
  selectedTemplates: TemplateID[];
  target: "absentee" | "occupied";
}

const useCampaignTemplate = (initialState: CampaignTemplateForm) => {
  const [formData, setFormData] = useState(initialState);
  const [prevValues, setPrevValues] = useState({
    numLetters: initialState.numLetters,
    numDays: initialState.numDays,
  });
  const [dateStrings, setDateStrings] = useState<string[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { numLetters: prevNumLetters = 0, numDays: prevNumDays = 0 } =
    prevValues;
  const [validationError, setValidationError] = useState<string | null>(null);

  const handleChange = (e: any, customName?: string) => {
    let name = customName ? customName : e.target.name;
    let value = customName ? e : e.target.value;

    // Parse the value to a number if it should be a number
    if (name === "numLetters" || name === "numDays") {
      value = Number(value);
    }

    setFormData((prevState) => {
      let updatedState = { ...prevState, [name]: value };

      if (name === "numLetters") {
        let updatedTemplates = { ...prevState.selectedTemplates };
        for (let i = value; i < prevState.numLetters; i++) {
          delete updatedTemplates[i];
        }
        updatedState = { ...updatedState, selectedTemplates: updatedTemplates };
      }
      return updatedState;
    });

    // Update prevValues for numLetters or numDays
    if (name === "numLetters" || name === "numDays") {
      setPrevValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  useEffect(() => {
    let updatedTemplates = { ...formData.selectedTemplates };
    for (let i = formData.numLetters; i < prevNumLetters; i++) {
      delete updatedTemplates[i];
    }
    setFormData((prevState) => ({
      ...prevState,
      selectedTemplates: updatedTemplates,
    }));
  }, [formData.numLetters, prevNumLetters]);

  const handleTemplateSelection = (
    sequenceIndex: number,
    templateId: number,
    mailType: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      selectedTemplates: {
        ...prev.selectedTemplates,
        [sequenceIndex]: { id: templateId, type: mailType },
      },
    }));
  };

  useEffect(() => {
    const dateStrings = Array.from({ length: formData.numLetters }).map(
      (_, index) => {
        return index === 0 ? "Today" : getDateStr(index, formData.numDays);
      }
    );
    setDateStrings(dateStrings);
  }, [formData]);

  useEffect(() => {
    if (Object.values(formData.selectedTemplates).length !== formData.numLetters) {
      setValidationError("Please select a template for each piece of mail.");
    } else {
      setValidationError(null);
    }
  }, [formData]);

  return {
    formData,
    setFormData,
    dateStrings,
    handleChange,
    validationError,
    handleTemplateSelection,
  };
};

export default useCampaignTemplate;
