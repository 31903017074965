import React from "react";

import ModeSwitch from "../../buttons/ModeSwitch";
import CoinBalance from "../mobile/CoinBalance";
import SearchBar from "../../search/SearchBarDesktop";
import UserName from "../desktop/UserName";
import AlertButton from "./AlertButton";
import ThemeChooser from "src/components/buttons/ThemeChooser";
import useDrawer from "src/hooks/private/useDrawer";
import HelpButton from "./HelpButton";
import useAlert from "src/hooks/useAlert";
import Alert from "src/components/alert/Alert";
import { motion, AnimatePresence } from "framer-motion";
import BulkImportButton from "./BulkImportButton";
import BannerAlert from "src/components/banners/BannerAlert";
import useApp from "src/hooks/useAppContext";
import ZoneButton from "src/components/buttons/ZoneButton";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

interface HeaderNavProps {
  showBanner: boolean;
}

const HeaderNav = ({ showBanner }: HeaderNavProps) => {
  const { showPropertyDrawer } = useDrawer();

  const { alert, setAlert } = useAlert();

  const { app } = useApp();

  const { subscription } = useCurrentSubscriptionContext();

  return (
    <header
      className={
        "fixed left-72 right-0 top-0 z-40 flex h-16 columns-1 flex-col bg-back-light dark:bg-back-dark"
      }
    >
      <BannerAlert showBanner={showBanner} />
      <div className="flex w-full items-center justify-center bg-back-light px-6 pt-2 dark:bg-back-dark">
        <div className="flex w-full max-w-[650px] flex-row justify-start gap-1">
          <div className="mt-[5px] w-full">
            <SearchBar />
          </div>
          <BulkImportButton />
        </div>
        <div className="flex w-full items-center justify-end">
          <HelpButton />
          {app?.hasPurchaseableZones && subscription && (
            <ZoneButton subscription={subscription} />
          )}
          <AlertButton />
          <ModeSwitch className="text-primary" />
          {app?.hasThemes && <ThemeChooser />}
          <UserName />
          <CoinBalance isDisabled={false} />
        </div>
      </div>
      {!showPropertyDrawer && (
        <div className="px-[22px]">
          <AnimatePresence>
            {alert.display && (
              <motion.div
                initial={{ y: -20, opacity: 0.8 }}
                transition={{ duration: 0.15 }}
                animate={{ y: 2, opacity: 1 }}
                exit={{ y: -20, opacity: 0.5 }}
              >
                <Alert
                  alert={alert}
                  _className={"h-[30px] lg:h-[44px]"}
                  onClose={() =>
                    setAlert({
                      display: false,
                      type: alert.type,
                      message: alert.message,
                    })
                  }
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </header>
  );
};

export default HeaderNav;
