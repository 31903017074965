/* eslint-disable import/no-anonymous-default-export */
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import {
  AddZonesData,
  Feature,
  FrontendOrigin,
  Subscription,
  SubscriptionFeature,
  VerifySubscription,
} from "src/interfaces/subscriptions";
import { CancelFormData } from "src/components/modals/CancelSubscriptionModal";
import { StripeAccount } from "src/interfaces/stripeAccount";
import Stripe from "stripe";

class SubscriptionApi extends ApiTokenSupport {
  async subscribe(
    plan: string,
    oneTimePaymentAmount: number
  ): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/subscription/subscribe`,
      { plan, oneTimePaymentAmount },
      this.withAuthorization()
    );
    return resp.data;
  }

  async checkSubscription(): Promise<{
    plan: string;
    hasFailedPayments: boolean;
    isDemo?: boolean;
  }> {
    const resp = await axios.get<{
      plan: string;
      hasFailedPayments: boolean;
      isDemo?: boolean;
    }>(`/api/subscription/check`, this.withAuthorization());
    return resp.data;
  }

  async purchaseCoins(
    amount: number,
    coinBlocks: number
  ): Promise<StripeAccount> {
    const resp = await axios.post<StripeAccount>(
      `/api/subscription/purchase-coins`,
      { amount, coinBlocks },
      this.withAuthorization()
    );
    return resp.data;
  }

  async changeSubscription(data: {
    plan: string;
    subscriptionID: number;
    isAnnual?: boolean;
    metadata?: { [name: string]: any };
    costToChange?: number;
    frontendOrigin: FrontendOrigin;
    chargeImmediately?: boolean;
  }): Promise<string> {
    const resp = await axios.post<string>(
      "api/subscription/change-subscription",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }
  async getStripeSubscription(): Promise<Stripe.Subscription> {
    const resp = await axios.get<Stripe.Subscription>(
      `/api/subscription/stripe-subscription`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAllSubscriptions(appID: number): Promise<Subscription[]> {
    const resp = await axios.get<Subscription[]>(
      `/api/subscription/app/${appID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getSubscription(teamID: number): Promise<Subscription> {
    const resp = await axios.get<Subscription>(
      `/api/subscription/team/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getByID(subscriptionID: number): Promise<Subscription> {
    const resp = await axios.get<Subscription>(
      `api/subscription/${subscriptionID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getFeatures(): Promise<Feature[]> {
    const resp = await axios.get<Feature[]>(
      `/api/feature/features`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getSubscriptionFeatures(
    teamID: number
  ): Promise<SubscriptionFeature[]> {
    const resp = await axios.get<SubscriptionFeature[]>(
      `/api/subscriptionFeatures/team/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAppSubscriptionFeaturesByAppID(
    appID: number
  ): Promise<SubscriptionFeature[]> {
    const resp = await axios.get<SubscriptionFeature[]>(
      `api/subscriptionFeatures/app/${appID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async cancelSubscription(data: CancelFormData): Promise<any> {
    const resp = await axios.post<any>(
      `/api/subscription/cancel-subscription-request`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async addZones(data: AddZonesData): Promise<any> {
    const resp = await axios.post<any>(
      `/api/subscription/add-zones`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async verifySubscriptionID(
    subscriptionID: string
  ): Promise<VerifySubscription> {
    const resp = await axios.get<VerifySubscription>(
      `/api/subscription/verify/${subscriptionID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new SubscriptionApi();
