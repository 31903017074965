import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { campaignApi } from "src/api";
import { Campaign } from "src/interfaces/campaign";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useUserContext from "src/hooks/private/useUserContext";

interface CampaignContextInterface {
  campaigns: Campaign[];
  fetchCampaigns: () => void;
}

const campaignContextDefaults: CampaignContextInterface = {
  campaigns: [],
  fetchCampaigns: () => {},
};

const CampaignContext = React.createContext<CampaignContextInterface>(
  campaignContextDefaults
);

interface CampaignProviderProps {}

export const CampaignProvider = ({
  children,
}: PropsWithChildren<CampaignProviderProps>) => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const { handleError } = useErrorHandler();

  const { user } = useUserContext();

  const fetchCampaigns = useCallback(async () => {
    try {
      if (user) {
        const campaigns = await campaignApi.getCampaigns(user.teamID);
        setCampaigns(campaigns);
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, user]);

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  return (
    <CampaignContext.Provider
      value={{
        campaigns,
        fetchCampaigns,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export default CampaignContext;
