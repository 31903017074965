import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { templatePostcardsApi } from "src/api";
import { TemplatePostcard } from "src/interfaces/templatePostcard";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface TemplateContextInterface {
  templatePostcards: TemplatePostcard[];
  fetchTemplatePostcards: () => void;
}

const TemplateContextDefaults: TemplateContextInterface = {
  templatePostcards: [],
  fetchTemplatePostcards: () => {},
};

const TemplatePostcardsContext = React.createContext<TemplateContextInterface>(
  TemplateContextDefaults
);

interface TemplatePostcardsProviderProps {}

export const TemplatePostcardsProvider = ({
  children,
}: PropsWithChildren<TemplatePostcardsProviderProps>) => {
  const [templatePostcards, setTemplatePostcards] = useState<TemplatePostcard[]>([]);

  const { handleError } = useErrorHandler();

  const fetchTemplatePostcards = useCallback(async () => {
    try {
      const templatePostcards = await templatePostcardsApi.getTemplatePostcards();
      setTemplatePostcards((templatePostcards || []).map(t => ({ ...t, type: "postcard" })));
    } catch (err: any) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchTemplatePostcards();
  }, [fetchTemplatePostcards]);

  return (
    <TemplatePostcardsContext.Provider
      value={{
        templatePostcards,
        fetchTemplatePostcards,
      }}
    >
      {children}
    </TemplatePostcardsContext.Provider>
  );
};

export default TemplatePostcardsContext;
