/* eslint-disable import/no-anonymous-default-export */
import { Event } from 'src/interfaces/event';
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class EventApi extends ApiTokenSupport {

  async getEvents(filter?: FilterParams): Promise<Event[]> {
    const resp = await axios.post<Event[]>(`/api/event/filter`, { ...filter } ,this.withAuthorization());
    return resp.data;
  }

  async createEvent(teamID: number): Promise<Event> {
    const resp = await axios.post<Event>(`/api/event`, {teamID}, this.withAuthorization());
    console.log(resp.data);
    return resp.data
  }
  
}

export default new EventApi();
