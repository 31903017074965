import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import FuseCoinsHelp from "./FuseCoinsHelp";
import useApp from "src/hooks/useAppContext";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import getFeatureCost from "src/helpers/getFeatureCost";

const DebtStacksHelp = () => {
  const { helpLink } = useHelpDrawer();

  const { app } = useApp();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  return (
    <>
      <p>
        Debt Stacks give you data on a property's owner or owners, physical
        characteristics, recent sales and loans, assessments and tax
        information, mortgages, construction, nearby neighbors, next sellers,
        out of state owners and an estimated amortization schedule.
      </p>
      <br></br>
      <p>
        Ordering a Debt Stack costs
        <span className="font-bold">
          {getFeatureCost(subscriptionFeatures?.DebtStack)}
        </span>
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() =>
            helpLink(app?.coinName || "FuseCoins™", <FuseCoinsHelp />)
          }
        >
          {app?.coinName}
        </span>{" "}
        and is an appropriate action to take on a property when you are looking
        to get a deeper knowledge of its current financial situation.
      </p>
      <br></br>
    </>
  );
};

export default DebtStacksHelp;
