import { useNavigate } from "react-router-dom";
import { watchlistApi } from "src/api";
import useTeamContext from "src/hooks/private/useTeamContext";
import useUserContext from "src/hooks/private/useUserContext";
import useWatchlist from "src/hooks/private/useWatchlist";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import ResearchAddressButton from "../buttons/ResearchAddressButton";
import useApp from "src/hooks/useAppContext";
import PurchaseCoinsModal from "./PurchaseCoinsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { RealeflowSuggestion } from "src/interfaces/property";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

interface ResearchModalProps {
  suggestion: RealeflowSuggestion;
  placeID: string;
  showWatchlist?: boolean;
}

const ResearchModal = ({
  suggestion,
  placeID,
  showWatchlist,
}: ResearchModalProps) => {
  const { app } = useApp();

  const { setShowModal, openModalWith } = useModal();

  const { fetchWatchlist } = useWatchlist();

  const { setAlert } = useAlert();

  const { user } = useUserContext();

  const { team, checkCoinBalance } = useTeamContext();

  const navigate = useNavigate();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const isBalanceOk = checkCoinBalance(
    subscriptionFeatures?.Research.coinCost || 1
  );

  const handleAddToWatchlist = async () => {
    setShowModal(false);
    try {
      const watchlist = await watchlistApi.createWatchlist({
        userID: user?.id,
        teamID: team?.id,
        fullAddress: suggestion.text,
        placeID: placeID,
        hash: suggestion.address.hash,
      });
      if (watchlist) {
        setAlert({
          display: true,
          message: "Address added to Watchlist",
          type: "success",
        });
      }
      fetchWatchlist();
      navigate("/watchlist");
    } catch (err: any) {
      setAlert({
        display: true,
        message:
          err?.message || "An error occurred adding this address to Watchlist",
        type: err?.message ? "warning" : "error",
      });
    }
  };

  const handleClickPurchase = () => {
    openModalWith({
      title: `Add ${app?.coinName}`,
      body: <PurchaseCoinsModal />,
      hideButtons: true,
    });
  };

  return (
    <div className="flex columns-1 flex-col">
      <div className="flex items-center justify-center">
        <ResearchAddressButton
          address={suggestion.text}
          placeID={placeID}
          className="mr-1 rounded-lg bg-card-light px-4 py-2 dark:bg-back-dark"
        />
      </div>
      {showWatchlist && (
        <div className="mt-4 w-full">
          <Button
            className="hover:dark:text-light btn btn-ghost mr-1 w-64 text-lg font-bold text-text-dark hover:bg-secondary hover:text-text-light dark:text-text-light hover:dark:bg-secondary"
            onClick={handleAddToWatchlist}
          >
            <FontAwesomeIcon icon={faEye} className="mr-2" />
            Add To Watchlist
          </Button>
          {!isBalanceOk && (
            <p className="mt-4">
              You need to{" "}
              <span
                onClick={handleClickPurchase}
                className="cursor-pointer text-secondary underline"
              >
                purchase
              </span>{" "}
              some {app?.coinName || "coins"} before you can research this
              property.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default ResearchModal;
