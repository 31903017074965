import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { skiptraceApi } from "src/api";
import { SkipTrace } from "src/interfaces/skipTrace";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface SkipTraceContextInterface {
  skipTraces: SkipTrace[];
  fetchSkipTraces: () => void;
}

const skipTraceContextDefaults: SkipTraceContextInterface = {
  skipTraces: [],
  fetchSkipTraces: () => {},
};

const SkipTraceContext = React.createContext<SkipTraceContextInterface>(
  skipTraceContextDefaults
);

interface SkipTraceProviderProps {}

export const SkipTraceProvider = ({
  children,
}: PropsWithChildren<SkipTraceProviderProps>) => {
  const [skipTraces, setSkipTraces] = useState<SkipTrace[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchSkipTraces = useCallback(async () => {
    if (user) {
      try {
        const skiptraces = await skiptraceApi.getSkiptraces();
        setSkipTraces(skiptraces);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  useEffect(() => {
    fetchSkipTraces();
  }, [fetchSkipTraces]);

  return (
    <SkipTraceContext.Provider
      value={{
        skipTraces,
        fetchSkipTraces,
      }}
    >
      {children}
    </SkipTraceContext.Provider>
  );
};

export default SkipTraceContext;
