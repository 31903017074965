import { faDollar, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useState } from "react";

interface ZoneBadgeProps {
  zone: string;
  index: number;
  displayDollarSign?: boolean;
  handleRemoveZone?: (zone: string) => void;
}

const ZoneBadge = ({
  zone,
  index,
  displayDollarSign,
  handleRemoveZone,
}: ZoneBadgeProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <motion.div
      key={`${index}-${zone}-zone-badge`}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      className="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className={`badge badge-secondary relative cursor-pointer font-bold text-white ${
          isHovering ? "badge-secondary" : "badge-outline"
        }`}
      >
        {zone}
        {displayDollarSign && (
          <button className="absolute -left-[8px] -top-[8px] flex h-4 w-4 items-center justify-center rounded-full bg-success text-xs text-white">
            <FontAwesomeIcon icon={faDollar} className="text-black" />
          </button>
        )}
        {handleRemoveZone && isHovering && (
          <button
            className="absolute -right-[8px] -top-[8px] flex h-4 w-4 items-center justify-center rounded-full bg-gray-400 text-xs text-white"
            onClick={handleRemoveZone ? () => handleRemoveZone(zone) : () => {}}
          >
            <FontAwesomeIcon icon={faXmark} className="text-black" />
          </button>
        )}
      </div>
    </motion.div>
  );
};

export default ZoneBadge;
