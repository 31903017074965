import { Navigate, Outlet } from "react-router-dom";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import useRegistrationGuard from "src/hooks/private/useRegistrationGuard";

const RegistrationGuard = () => {
  const {
    referralID,
    subID,
    onCheckingReferral,
    onCheckingSubscription,
    redirection,
  } = useRegistrationGuard();

  if (onCheckingReferral && onCheckingSubscription) {
    return <LoadingSpinner />;
  }

  if (redirection && (referralID || subID)) {
    return <Navigate to={redirection} />;
  }

  return <Outlet />;
};

export default RegistrationGuard;
