import { ReactNode } from "react";
import SwiperButton from "./SwiperButton";
import SwiperTitleDropdown from "./SwiperTitleDropdown";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface SlideProps {
  title: string;
  content: ReactNode;
  slides: any;
  icon?: IconProp;
}

const SwiperSlideCanvas = ({ title, content, slides, icon }: SlideProps) => {
  return (
    <div className="">
      <div className="z-50 mb-1 flex h-[32px] w-full items-center justify-between rounded-md bg-card-light shadow-xl dark:bg-card-dark lg:h-[40px]">
        <div className="ml-2 mt-0.5">
          <SwiperButton direction="left" />
        </div>
        <SwiperTitleDropdown title={title} slides={slides} icon={icon} />
        <div className="mr-2 mt-0.5">
          <SwiperButton direction="right" />
        </div>
      </div>
      <div className="">{content}</div>
    </div>
  );
};

export default SwiperSlideCanvas;
