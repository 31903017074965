import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import property from "src/api/property";
import TemplateSelect from "../TemplateSelect";
import { TemplateID } from "src/interfaces/campaign";

interface CampaignBuilderProps {
  quantity: number;
  type: "occupied" | "absentee";
  onTemplateSelect:(sequenceIndex: number, templateId: number, mailType: string) => void;
  selectedTemplates: { [key: number]: TemplateID };
  propertyID?: number; 
}

const CampaignBuilder = ({
  quantity,
  type,
  onTemplateSelect,
  selectedTemplates,
  propertyID
}: CampaignBuilderProps) => {
  // Create a new array with filler values for undefined templates
  const selectedTemplatesWithFiller = Array.from({ length: quantity }, (_, i) => selectedTemplates[i] || -1);
  return (
    <>
      <AnimatePresence>
        {selectedTemplatesWithFiller.map((selectedTemplate, index: number) => (
          <motion.div
            key={`template-${index}`}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.3 }}
          >
            {property && (
              <TemplateSelect
                type={type}
                sequenceIndex={index}
                onTemplateSelect={onTemplateSelect}
                selectedTemplate={selectedTemplate}
                propertyID={propertyID}
              />
            )}
          </motion.div>
        ))}
      </AnimatePresence>
    </>
  );
};

export default CampaignBuilder;