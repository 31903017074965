import React, {
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { motion } from "framer-motion";
import TrashButton from "src/components/buttons/TrashButton";
import SearchBarModal from "src/components/search/SearchBarModal";
import { CsvAddress } from "./BulkImportModal";
import { RealeflowSuggestion } from "src/interfaces/property";
import { getGeocode } from "use-places-autocomplete";


interface CheckBoxProps {
  isChecked: boolean;
  onChange: () => void;
  disabled?: boolean;
}

const CheckBox = ({ isChecked, onChange, disabled }: CheckBoxProps) => {
  return (
    <label className="label w-10 cursor-pointer">
      <input
        type="checkbox"
        checked={isChecked}
        className="checkbox-secondary checkbox lg:checkbox-lg"
        onChange={onChange}
        disabled={disabled}
      />
    </label>
  );
};

interface NewBulkRowProps {
  id: string;
  address: string;
  isWatchlist: boolean;
  isResearch: boolean;
  isSkiptrace: boolean;
  isDebtstack: boolean;
  data: CsvAddress[];
  setData: Dispatch<SetStateAction<CsvAddress[]>>;
  index: number;
  source: "file" | "research";
  error: boolean;
}

const NewBulkRow = ({
  id,
  address,
  isWatchlist,
  isResearch,
  isSkiptrace,
  isDebtstack,
  data,
  setData,
  index,
  source,
  error,
}: NewBulkRowProps) => {
  const [hasMadeSelection, setHasMadeSelection] = useState<boolean>(false);

  const handleChangeCheckbox = (
    id: string,
    key: string,
    value: boolean | number
  ) => {
    setData((prevData: CsvAddress[]) => {
      const updatedData = prevData.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [key]: value,
          };
        }
        return row;
      });
      return updatedData;
    });
  };

  const handleSuggestionsSelect = async (suggestion: RealeflowSuggestion) => {
    const address = suggestion.text;

    const results = await getGeocode({ address });

    const { place_id } = results[0];

    setData((prevData: CsvAddress[]) => {
      const updatedData = prevData.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            address: address,
            placeID: place_id,
            suggestion,
          };
        }
        return row;
      });
      return updatedData;
    });
    setHasMadeSelection(true);
  };

  const handleDeleteRow = (_index: number) => {
    const newArray = data.filter(
      (obj: CsvAddress, index: number) => index !== _index
    );
    setData(newArray);
  };

  return (
    <motion.li
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={id}
      className="flex flex-row"
    >
      <CheckBox
        isChecked={isWatchlist}
        onChange={() => handleChangeCheckbox(id, "isWatchlist", !isWatchlist)}
      />
      <CheckBox
        isChecked={isResearch}
        onChange={() => handleChangeCheckbox(id, "isResearch", !isResearch)}
      />
      <CheckBox
        isChecked={isSkiptrace}
        onChange={() => handleChangeCheckbox(id, "isSkiptrace", !isSkiptrace)}
        disabled={!isResearch}
      />
      <CheckBox
        isChecked={isDebtstack}
        onChange={() => handleChangeCheckbox(id, "isDebtstack", !isDebtstack)}
        disabled={!isResearch}
      />
      <div className="flex items-center justify-center">
        <SearchBarModal
          id={id}
          selectedValue={address}
          onSelect={handleSuggestionsSelect}
          hasMadeSelection={hasMadeSelection}
          setHasMadeSelection={setHasMadeSelection}
          source={source}
          error={error}
        />
      </div>
      <div className="ml-2 flex items-center justify-center">
        <TrashButton onClick={() => handleDeleteRow(index)} isSmall={true} />
      </div>
    </motion.li>
  );
};

export default NewBulkRow;
