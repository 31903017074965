import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface BannerAlertProps {
  showBanner: boolean;
}

const BannerAlert = ({ showBanner }: BannerAlertProps) => {
  return (
    <>
      {showBanner && (
        <div className="h-8 w-full bg-warning">
          <div className="flex h-full w-full items-center justify-center p-4 gap-2">
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-black text-lg"
              />
            <p className="font-medium text-black">
              We are currently experiencing an issue with a data provider. Some
              services may be affected.
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default BannerAlert;
