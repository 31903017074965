import { useCallback, useState } from "react";
import { propertyApi } from "src/api";
import { PropertyUpdate } from "src/interfaces/propertyUpdate";
import useErrorHandler from "./useErrorHandler";

const usePropertyUpdates = () => {
  const [propertyUpdates, setPropertyUpdates] = useState<PropertyUpdate[]>([]);
  const { handleError } = useErrorHandler();

  const fetchAPropertyUpdates = useCallback(
    async (propertyID: number) => {
      try {
        const rows = await propertyApi.getAPropertyUpdates(propertyID);
        setPropertyUpdates(rows);
      } catch (err) {
        handleError(err);
      }
    },
    [handleError]
  );

  return {
    propertyUpdates: propertyUpdates.sort(
      (a: PropertyUpdate, b: PropertyUpdate) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ),
    fetchAPropertyUpdates,
  };
};

export default usePropertyUpdates;
