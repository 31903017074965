/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { campaignApi } from "src/api";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import useUserContext from "src/hooks/private/useUserContext";
import useAlert from "src/hooks/useAlert";
import SequenceCadence from "../SequenceCadence";
import SequenceQuantity from "../SequenceQuantity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TemplateSummary from "../TemplateSummary";
import { faArrowRight, faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import CampaignBuilder from "./CampaignBuilder";
import useTeamContext from "src/hooks/private/useTeamContext";
import CampaignTarget from "./CampaignTarget";
import useCampaignTemplate, {
  CampaignTemplateForm,
} from "src/hooks/private/useCampaignTemplate";

const NewCampaign = () => {
  const { fetchCampaigns } = useCampaignContext();

  const { teamSettings } = useTeamContext();

  const { user } = useUserContext();

  const { setAlert } = useAlert();

  const navigate = useNavigate();

  const [onSendingSequence, setOnSendingSequence] = useState(false);

  const initialState: CampaignTemplateForm = {
    campaignName: "",
    numLetters: teamSettings?.postCardNumber || 3,
    numDays: teamSettings?.postCardIntervals || 3,
    selectedTemplates: [],
    target: "absentee",
  };

  const {
    formData,
    dateStrings,
    handleChange,
    validationError,
    handleTemplateSelection,
  } = useCampaignTemplate(initialState);

  useEffect(() => {
    formData.selectedTemplates = [];
  }, [formData.target]);

  const handleSubmit = async () => {
    if (!validationError) {
      const { campaignName, numLetters, numDays, target, selectedTemplates } =
        formData;
      try {
        if (user) {
          setOnSendingSequence(true);
          await campaignApi.create({
            name: formData.campaignName.length ? formData.campaignName : "[New Campaign]",
            teamID: user.teamID,
            userID: user.id,
            cadence: numDays,
            quantity: numLetters,
            target: target,
            templateIDs: Object.values(selectedTemplates),
          });
          setAlert({
            type: "success",
            message: `A new Campaign: ${campaignName} has been created`,
            display: true,
          });
        }
      } catch (error: any) {
        setAlert({
          type: "error",
          message: error?.message ?? "Campaign edit failed to save",
          display: true,
        });
      } finally {
        setOnSendingSequence(false);
        fetchCampaigns();
        return navigate("/sequences/campaigns");
      }
    } else {
      setAlert({
        display: true,
        type: "warning",
        message: "Select a template for each piece of mail.",
      });
    }
  };

  return (
    <>
      <div className="mb-2 rounded-md bg-card-light shadow-xl dark:bg-card-dark ">
        <div className="flex w-full items-center justify-between py-1 md:justify-around lg:justify-center lg:gap-12">
          <div className="px-4">
            <span className="text-sm font-bold  text-secondary xs:text-base sm:text-xl">
              New Campaign
            </span>
          </div>
          <div>
            <FontAwesomeIcon
              className="text-icon-light dark:text-icon-dark"
              icon={faArrowRight}
            />
          </div>
          <div className="flex items-center justify-center">
            <input
              type="text"
              name="campaignName"
              placeholder="Campaign Name"
              className="input-bordered input-secondary input z-0 w-full bg-back-light font-medium text-text-dark placeholder:text-icon-light  dark:bg-back-dark dark:text-text-light lg:w-80"
              value={formData.campaignName}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="mb-2 flex w-full flex-col gap-2 lg:flex-row lg:items-stretch ">
        <CampaignTarget target={formData.target} onChange={handleChange} />
        <SequenceQuantity
          quantity={formData.numLetters}
          onChange={handleChange}
        />
        <SequenceCadence cadence={formData.numDays} onChange={handleChange} />
      </div>
      <CampaignBuilder
        quantity={formData.numLetters}
        type={formData.target}
        onTemplateSelect={handleTemplateSelection}
        selectedTemplates={formData.selectedTemplates}
      />
      <TemplateSummary
        templateIDs={formData.selectedTemplates}
        sendDays={dateStrings}
      />
      <div className="mb-20 flex w-full items-center justify-center gap-2 lg:mb-0">
        <button
          className="btn-secondary hover:bg-primary text-text-light btn mb-2 flex-grow gap-2 text-lg border-none"
          disabled={onSendingSequence}
          onClick={handleSubmit}
        >
          <FontAwesomeIcon icon={faFloppyDisk} />
          <span className="">Save</span>
        </button>
      </div>
    </>
  );
};
export default NewCampaign;
