import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AddressState {
  selectedAddress: google.maps.places.AutocompletePrediction | null;
  addresses: google.maps.places.AutocompletePrediction[];
}

const initialState: AddressState = {
  selectedAddress: null,
  addresses: [],
};

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    setAddresses(
      state,
      action: PayloadAction<google.maps.places.AutocompletePrediction[]>
    ) {
      state.addresses = action.payload;
      if (action.payload.length > 0) {
        state.selectedAddress = action.payload[action.payload.length - 1];
      } else {
        state.selectedAddress = null;
      }
    },
    clearAddresses(state) {
      state.addresses = [];
      state.selectedAddress = null;
    },
    removeAddress(state, action: PayloadAction<string>) {
      state.addresses = state.addresses.filter(
        (address) => address.place_id !== action.payload
      );
      if (state.selectedAddress?.place_id === action.payload) {
        state.selectedAddress =
          state.addresses.length > 0 ? state.addresses[state.addresses.length - 1] : null;
      }
    },
    setSelectedAddress(
      state,
      action: PayloadAction<google.maps.places.AutocompletePrediction | null>
    ) {
      state.selectedAddress = action.payload;
    },
  },
});

export const {
  setAddresses,
  clearAddresses,
  removeAddress,
  setSelectedAddress,
} = addressSlice.actions;

export default addressSlice.reducer;
