/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { faArrowRight, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import { campaignApi, propertyApi, sequenceApi } from "src/api";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import PropertyModalButton from "src/components/buttons/PropertyModalButton";
import Toggle from "src/components/buttons/ToggleX";
import Property from "src/interfaces/property";
import "src/css/removeSafariInputOutline.css";
import useTeamContext from "src/hooks/private/useTeamContext";
import SelectOwner from "src/components/fields/SelectOwnerNoLabel";
import TemplateSummary from "src/components/sequences/TemplateSummary";
import useAlert from "src/hooks/useAlert";
import InsufficientFundsModal from "src/components/modals/InsufficientFundsModal";
import useModal from "src/hooks/useModal";
import useSequenceLetterContext from "src/hooks/private/useSequenceLetterContext";
import useUserContext from "src/hooks/private/useUserContext";
import SequenceQuantity from "src/components/sequences/SequenceQuantity";
import SequenceCadence from "src/components/sequences/SequenceCadence";
import CampaignBuilder from "src/components/sequences/campaigns/CampaignBuilder";
import useCampaignTemplate, {
  CampaignTemplateForm,
} from "src/hooks/private/useCampaignTemplate";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import useDrawer from "src/hooks/private/useDrawer";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

const NewSequence = () => {
  const { propertyID } = useParams();

  const [property, setProperty] = useState<Property | null>(null);

  const { setAlert } = useAlert();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const [isCheckedSaveToCampaigns, setIsCheckedSaveToCampaigns] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const { fetchCampaigns } = useCampaignContext();

  const { setShowPropertyDrawer } = useDrawer();

  const { fetchMailings, fetchSequences, fetchActiveSequences } =
    useSequenceLetterContext();

  const { setShowModal, openModalWith } = useModal();

  const { teamSettings, checkCoinBalance, fetchUserTeamData } =
    useTeamContext();

  const { user } = useUserContext();

  const [onSendingSequence, setOnSendingSequence] = useState(false);

  const initialState: CampaignTemplateForm = {
    campaignName: "",
    numLetters: teamSettings?.postCardNumber || 3,
    numDays: teamSettings?.postCardIntervals || 3,
    selectedTemplates: [],
    target: "absentee",
  };

  const {
    formData,
    dateStrings,
    handleChange,
    validationError,
    handleTemplateSelection,
  } = useCampaignTemplate(initialState);

  const fetchProperty = async () => {
    if (propertyID) {
      const resp = await propertyApi.getProperty(parseInt(propertyID));
      setProperty(resp);
    }
  };

  useEffect(() => {
    fetchProperty();
  }, [propertyID]);

  setShowPropertyDrawer(false);

  const submitSequence = async () => {
    if (!validationError) {
      const { campaignName, numLetters, numDays, selectedTemplates } = formData;
      if (
        checkCoinBalance(
          (subscriptionFeatures?.Sequence.coinCost || 6) * numLetters
        )
      ) {
        setShowModal(false);
        try {
          setOnSendingSequence(true);
          await sequenceApi.createSequence({
            propertyID: property?.id,
            cadence: numDays,
            numLetters: numLetters,
            templateIDs: Object.values(selectedTemplates),
            addressTo: property?.owner1,
          });
          if (isCheckedSaveToCampaigns) {
            if (user) {
              await campaignApi.create({
                name: campaignName.length ? campaignName : "[New Campaign]",
                teamID: user.teamID,
                userID: user.id,
                cadence: numDays,
                quantity: numLetters,
                target: property?.ownerOccupied ? "occupied" : "absentee",
                templateIDs: Object.values(selectedTemplates),
              });
              setAlert({
                type: "success",
                message: "Sequence created and campaign saved",
                display: true,
              });
            }
          } else {
            setAlert({
              type: "success",
              message: "Sequence created",
              display: true,
            });
          }
        } catch (error: any) {
          setAlert({
            type: "error",
            message: error?.message ?? "Sequence creation failed",
            display: true,
          });
        } finally {
          setOnSendingSequence(false);
          fetchSequences();
          fetchActiveSequences();
          fetchMailings();
          fetchUserTeamData();
          fetchCampaigns();
          navigate("/sequences");
        }
      } else {
        openModalWith({
          title: "Insufficient FuseCoins",
          body: <InsufficientFundsModal />,
          hideButtons: true,
        });
      }
    } else {
      setAlert({
        display: true,
        type: "warning",
        message: validationError,
      });
    }
  };

  return (
    <>
      <div className="relative left-0 right-0 mb-2">
        <div className="mb-2 rounded-md bg-card-light shadow-xl dark:bg-card-dark">
          <div className="flex w-full items-center justify-between md:justify-around lg:justify-center lg:gap-12">
            <div className="px-4">
              <span className="text-sm font-bold  text-secondary xs:text-base sm:text-xl">
                New Sequence
              </span>
            </div>
            <div>
              <FontAwesomeIcon
                className="text-icon-light dark:text-icon-dark"
                icon={faArrowRight}
              />
            </div>
            {property ? (
              <PropertyModalButton property={property} />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
        <div className="rounded-md bg-card-light px-4 py-2 shadow-xl dark:bg-card-dark">
          <div className="flex w-full items-center justify-between md:justify-around lg:justify-center lg:gap-12">
            <div className="">
              <span className="text-sm font-light  text-text-dark dark:text-text-light xs:text-base sm:text-xl">
                Save to Campaigns
              </span>
            </div>
            <div>
              <Toggle
                checked={isCheckedSaveToCampaigns}
                onChange={() =>
                  setIsCheckedSaveToCampaigns(
                    (prevState: boolean) => !prevState
                  )
                }
              />
            </div>
          </div>
          {isCheckedSaveToCampaigns && (
            <div className="mt-2 flex items-center justify-center">
              <input
                type="text"
                name="campaignName"
                placeholder="Campaign Name"
                className="input input-bordered input-secondary z-0 w-full bg-back-light font-medium text-text-dark placeholder:text-icon-light  dark:bg-back-dark dark:text-text-light lg:w-80"
                value={formData.campaignName}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      </div>
      <div className="mb-2 flex w-full flex-col gap-2 lg:flex-row">
        <SequenceQuantity
          quantity={formData.numLetters}
          onChange={handleChange}
        />
        <SequenceCadence cadence={formData.numDays} onChange={handleChange} />
      </div>
      {property && property?.owner2 && property?.owner2.length > 2 && (
        <div className="relative mb-2 rounded-md bg-card-light px-4 py-2 shadow-xl dark:bg-card-dark">
          <div className="mb-2 flex w-full flex-col items-center justify-between gap-4 text-center sm:flex-row md:justify-around lg:justify-center lg:gap-12">
            <span className="w-1/2 text-sm font-light text-text-dark dark:text-text-light xs:text-base sm:text-xl">
              Which property owner should mail be addressed to?
            </span>
            <div className="flex w-full  items-center justify-center py-2 md:w-1/2">
              <SelectOwner property={property} onChange={handleChange} />
            </div>
          </div>
        </div>
      )}
      <CampaignBuilder
        quantity={formData.numLetters}
        type={property?.ownerOccupied ? "occupied" : "absentee"}
        onTemplateSelect={handleTemplateSelection}
        selectedTemplates={Object.values(formData.selectedTemplates)}
        propertyID={property?.id}
      />
      <TemplateSummary
        templateIDs={formData.selectedTemplates}
        sendDays={dateStrings}
        propertyID={property?.id}
      />
      <div className="mb-20">
        <button
          className="btn btn-secondary mb-2 w-full gap-2 text-text-light"
          disabled={onSendingSequence}
          onClick={submitSequence}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
          <span className="text-lg">Send Sequence</span>
        </button>
      </div>
    </>
  );
};

export default NewSequence;
