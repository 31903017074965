import React from "react";
import useTemplateLettersContext from "src/hooks/private/useTemplateLettersContext";
import useCarousel from "src/hooks/useCarousel";
import CarouselHeader from "../carousel/CarouselHeader";
import { TemplateLetter } from "src/interfaces/templateLetter";
import TemplateLetterHolder from "./TemplateLetterHolder";

const LettersTab = () => {
  const { templateLetters } = useTemplateLettersContext();

  const carousel = ["Absentee Owner", "Owner Occupied"];
  const { carouselIndex, carouselActions } = useCarousel(carousel);

  const type =
    carousel[carouselIndex] === "Absentee Owner" ? "absentee" : "occupied";
  return (
    <>
      <CarouselHeader title={carousel[carouselIndex]} {...carouselActions} />
      <div className="mt-4 mb-20 grid grid-cols-1 gap-12 md:grid-cols-2 lg:mb-0 lg:grid-cols-2 xl:grid-cols-3">
        {(templateLetters || [])
          .filter((temp: TemplateLetter) => temp.target === type)
          .map((template: TemplateLetter) => (
            <div key={`$-temp-${template.id}`} className="flex justify-center">
              <TemplateLetterHolder template={template} />
            </div>
          ))}
      </div>
    </>
  );
};

export default LettersTab;
