import React from "react";
import TemplateSummary from "../TemplateSummary";
import { TemplateID } from "src/interfaces/campaign";

interface CampaignsDrawerProps {
  templateIDs: TemplateID[];
  cadence: number;
}

const CampaignsDrawer = ({ templateIDs, cadence }: CampaignsDrawerProps) => {
  const generateSendDays = (): string[] => {
    let sendDays = [];
    for (let i = 0; i < templateIDs.length; i++) {
      sendDays.push(`Day ${i * cadence + 1}`);
    }
    return sendDays;
  };
  return (
    <>
      <TemplateSummary
        templateIDs={templateIDs}
        sendDays={generateSendDays()}
        showSummaryHeader={false}
      />
    </>
  );
};

export default CampaignsDrawer;
