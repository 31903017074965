import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faXmark } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "src/components/loading/LoadingSpinner";
import { shortenString } from "src/helpers/parseStrings";
import useSearchBar from "src/hooks/useSearchBar";
import useViewport from "src/hooks/useViewport";
import NearMeButton from "../buttons/NearMeButton";
import { faLocationArrow } from "@fortawesome/pro-solid-svg-icons";

const SearchBarMobile = () => {
  const {
    ref,
    value,
    isLoading,
    isInitialLoading,
    searchInput,
    isSearching,
    searchString,
    addressBeingResearched,
    clearSearch,
    handleSubmit,
    handleInput,
    handleClick,
    renderSuggestions,
    handleSearchProperties,
  } = useSearchBar();

  let keyboardDismissRef = useRef<any>(null);

  const { isMobile, height } = useViewport();

  const suggestionElements = renderSuggestions(isMobile);

  return (
    <>
      <div ref={ref} className="bg-back-light dark:bg-back-dark">
        <div className="fixed left-0 right-0 top-[54px] flex h-[30px] w-screen items-center justify-center bg-back-light pt-[32px] dark:bg-back-dark ">
          <div className="mt-[9px] w-full bg-back-light px-1.5 pb-[7px] dark:bg-back-dark">
            {!value.length && (
              <div className="relative">
                <div className="absolute right-[0px] top-[0px] flex h-[48px] w-[48px] items-center justify-center pr-[8px] pt-[4px] ">
                  <NearMeButton
                    className=" text-xxxl text-secondary "
                    icon={faLocationArrow}
                  />
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit} onClick={handleClick}>
              {isLoading ? (
                <>
                  <div className="absolute left-[25px] right-0 top-[23px] flex w-full items-center justify-center">
                    <div className="flex items-center justify-center gap-0.5  ">
                      <LoadingSpinner className={"text-secondary"} />
                      <div className="animate-pulse text-sm font-bold text-secondary">
                        {addressBeingResearched.length &&
                          `Researching ${shortenString(
                            addressBeingResearched,
                            29
                          )}...`}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="absolute top-[23px] pl-4">
                  {isInitialLoading ? (
                    <LoadingSpinner className={"text-secondary"} />
                  ) : (
                    <FontAwesomeIcon
                      className="md:text-3xl text-xl text-secondary"
                      icon={faMagnifyingGlass}
                    />
                  )}
                </div>
              )}
              <input
                id="searchproperties"
                type="text"
                name="search"
                ref={searchInput}
                onKeyDown={(e: any) => {
                  e.key === "Enter" && e.preventDefault();
                }}
                placeholder={
                  isLoading
                    ? ""
                    : isSearching
                    ? "Search Saved Properties"
                    : "Search New Properties"
                }
                className={`h-12 w-full rounded-lg border-2 border-back-light dark:border-back-dark bg-card-light pl-12 text-sm font-medium text-text-dark shadow-md placeholder:text-lg placeholder:italic placeholder:text-neutral-400 dark:bg-card-dark dark:text-text-light  ${
                  isSearching
                    ? "md:text-3xl border-secondary text-text-dark placeholder:dark:text-gray-600"
                    : "md:text-3xl focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-secondary"
                }`}
                onChange={isSearching ? handleSearchProperties : handleInput}
                disabled={isLoading}
              />
              {searchString.length > 0 && (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="md:text-3xl absolute right-[20px] top-[23px] cursor-pointer text-xl text-secondary"
                  onClick={() => {
                    clearSearch("search");
                  }}
                />
              )}

              {value.length > 0 && (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="md:text-3xl absolute right-[20px] top-[23px] cursor-pointer text-xl text-secondary"
                  onClick={() => {
                    clearSearch("value");
                  }}
                />
              )}
            </form>
            {suggestionElements.suggestions.length > 0 && (
              <div
                className="fixed left-1.5 right-1.5 mt-1  overflow-auto rounded-lg bg-card-light px-1 shadow-xl dark:bg-back-dark dark:shadow-md dark:shadow-slate-500"
                style={{ maxHeight: height - 200 }}
              >
                {suggestionElements.element}
              </div>
            )}
          </div>
        </div>
        <div className="hidden">
          <input ref={keyboardDismissRef}></input>
        </div>
      </div>
    </>
  );
};

export default SearchBarMobile;
