import { BrokerOffer, BrokerProofOfFunds } from "src/interfaces/brokers";
import Property from "src/interfaces/property";

function getDebtStackPDFfileName(title?: string, address?: string) {
  const filename = title?.split("/").pop();
  const pdfFileName = `${address || filename}`.replace(/\s/g, "_");
  return pdfFileName ? pdfFileName : "";
}

function getOfferPDFfilename(property: Property) {
  const address = property.fullAddress.replace(/\s/g, "_").replace(/,/g, "");

  return `Offer_${address}`;
}

function getProofOfFundsPDFFilename(proofOfFunds: BrokerProofOfFunds) {
  return `ProofOfFunds_${proofOfFunds.recipientAddress}_${proofOfFunds.recipientName}.pdf`;
}

function getBrokerOfferPDFfilename(brokerOffer: BrokerOffer) {
  const address = JSON.parse(brokerOffer.recipientAddress);
  return `Offer_${address.description.replace(/\s/g, "_").replace(/,/g, "")}.pdf`;
}

export {
  getDebtStackPDFfileName,
  getOfferPDFfilename,
  getProofOfFundsPDFFilename,
  getBrokerOfferPDFfilename,
};
