/* eslint-disable import/no-anonymous-default-export */
import ApiTokenSupport from "./token.support";
import Stripe from "stripe";
import axios from "src/utils/axios";
import MarketOrder, { MarketOrderSettings } from "src/interfaces/marketOrder";

class MarketApi extends ApiTokenSupport {
  async createCheckoutSession(data: any): Promise<Stripe.Checkout.Session> {
    const resp = await axios.post<Stripe.Checkout.Session>(
      "/api/stripe/checkout-session",
      data
    );
    return resp.data;
  }

  async getOrderDetails(sessionID: string): Promise<{
    isSessionInDB: boolean;
    session: Stripe.Checkout.Session;
  }> {
    const resp = await axios.get(`/api/stripe/checkout-session/${sessionID}`);
    return resp.data;
  }

  async createMarketOrder(data: any): Promise<any> {
    const resp = await axios.post("/api/marketOrder", data);
    return resp.data;
  }

  async getMarketOrders(emails: string[]): Promise<MarketOrder[]> {
    const resp = await axios.post<MarketOrder[]>(`/api/marketOrder/emails`, {
      emails,
    });
    return resp.data;
  }

  async handleEmail({
    orderID,
    marketSettings,
    itemID,
  }: {
    orderID: number;
    marketSettings: MarketOrderSettings;
    itemID?: string;
  }): Promise<void> {
    const resp = await axios.post(`/api/marketOrder/email`, {
      orderID,
      marketSettings,
      itemID,
    });
    return resp.data;
  }
}

export default new MarketApi();
