import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { supportTickerApi } from "src/api";
import { SupportTicket } from "src/interfaces/supportTicket";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface SupportTicketContextInterface {
  supportTickets: SupportTicket[];
  fetchSupportTickets: () => void;
}

const supportTicketContextDefaults: SupportTicketContextInterface = {
  supportTickets: [],
  fetchSupportTickets: () => {},
};

const SupportTicketContext = React.createContext<SupportTicketContextInterface>(
  supportTicketContextDefaults
);

interface SupportTicketProviderProps {}

export const SupportTicketProvider = ({
  children,
}: PropsWithChildren<SupportTicketProviderProps>) => {
  const [supportTickets, setSupportTickets] = useState<SupportTicket[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchSupportTickets = useCallback(async () => {
    if (user) {
      try {
        const supportTickets = await supportTickerApi.getSupportTickets();
        setSupportTickets(supportTickets);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  useEffect(() => {
    fetchSupportTickets();
  }, [fetchSupportTickets]);

  return (
    <SupportTicketContext.Provider
      value={{
        supportTickets,
        fetchSupportTickets,
      }}
    >
      {children}
    </SupportTicketContext.Provider>
  );
};

export default SupportTicketContext;
