import { PropsWithChildren, useState } from "react";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import useUserContext from "src/hooks/private/useUserContext";
import useModal from "src/hooks/useModal";
import Button from "../buttons/Button";
import DeleteButton from "../buttons/DeleteButton";
import OutsideClickHandler from "react-outside-click-handler";
import QuestionButton from "../buttons/QuestionButton";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import PropertyModalButton from "../buttons/PropertyModalButton";
import useTeamContext from "src/hooks/private/useTeamContext";
import { motion, AnimatePresence, MotionStyle } from "framer-motion";
import ButtonLoader from "../loading/ButtonLoader";
import useTheme from "src/hooks/useTheme";
import useApp from "src/hooks/useAppContext";

export const ModalLayout = ({
  children,
  disableOutsideClick,
  style,
  onCancel,
}: PropsWithChildren<{
  disableOutsideClick?: boolean;
  style?: MotionStyle;
  onCancel?: () => void;
}>) => {
  const { setShowModal } = useModal();
  const { showHelpDrawer, setShowHelpDrawer } = useHelpDrawer();

  const closeModal = () => {
    setShowModal(false);
    window.history.pushState({}, "", window.location.pathname);
    if (onCancel) onCancel();
  };

  return (
    <AnimatePresence>
      <motion.div
        style={{
          backgroundColor: "rgba(0,0,0,0.69)",
          ...style,
        }}
        className={
          showHelpDrawer
            ? "fixed bottom-0 left-0 right-0 top-0 flex max-h-screen items-center justify-center bg-title-text-light"
            : "fixed bottom-0 left-0 right-0 top-0 z-50 flex max-h-screen items-center justify-center bg-title-text-light"
        }
        initial={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <div className="flex max-h-screen w-full items-center justify-center">
          <motion.div
            className="max-h-screen w-11/12 rounded-lg bg-back-light px-2 pb-6 pt-2 shadow-lg drop-shadow-2xl dark:bg-base-100 sm:w-8/12 md:w-7/12 lg:w-7/12 xl:w-6/12 2xl:w-5/12"
            initial={{ opacity: 0, scale: 0.5 }}
            transition={{
              opacity: { duration: 0.2 },
              scale: { duration: 0.18 },
            }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="flex max-h-screen columns-1 flex-col justify-center">
              <OutsideClickHandler
                disabled={disableOutsideClick}
                onOutsideClick={
                  showHelpDrawer
                    ? () => setShowHelpDrawer(false)
                    : () => closeModal()
                }
                children={children}
              />
            </div>
          </motion.div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const titles: any = {
  "Research Property": "research properties",
  "Request Skip Trace": "request a skip trace",
  "Start Sequence": "start a sequence",
  "Cancel Sequence": "cancel a sequence",
  "Create An Offer": "create an offer",
  "Invite New Teammate": "invite new team members",
  "Order Debt Stack": "order a debt stack",
  "Purchase Coins:": "purchase coins",
};

const ModalCard = ({
  title,
  body,
  property,
  onSubmit,
  onCancel,
  submitLabel,
  cancelLabel,
  hideButtons,
  hideCancelButton,
  helpTitle,
  helpBody,
  disableOutsideClick,
  modalStyle,
  submitIcon,
}: ModalOptionsProps) => {
  const { setShowModal, showModal } = useModal();

  const { user } = useUserContext();

  const { app } = useApp();

  const { team, teammates } = useTeamContext();

  const { tailwindTheme } = useTheme();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const teamLeaderID = team?.leaderID;

  function determineAuthorization() {
    // Leaders may do any action
    if (user?.id === teamLeaderID || user?.isLeader) {
      return true;
    }
    // Actions that do not require any authorization
    if (
      title === "Create Support Ticket" ||
      title === "Teammate" ||
      title === "Team Leader" ||
      title === "Skip Trace Results" ||
      title === "Sequence" ||
      title === "Offer" ||
      title === "Calls/Emails" ||
      title === "What's New"
    ) {
      return true;
    }
    if (
      (title === "Add To Watchlist" ||
        title === "Remove From Watchlist" ||
        title === "Properties Nearby") &&
      (user?.canWatchlist || user?.isBirddog)
    ) {
      return true;
    }
    if (user?.isBirddog) {
      return false;
    }
    if (title === "Research Property" && user?.canResearch) {
      return true;
    }
    if (title === "Order Debt Stack" && user?.canTitle) {
      return true;
    }
    if (
      (title === "Cancel Sequence" || title === "Start Sequence") &&
      user?.canSequence
    ) {
      return true;
    }
    if (title === "Create An Offer" && user?.canOffer) {
      return true;
    }
    if (
      (title === "Invite New Teammate" || title === "Invite New Birddog") &&
      user?.canInvite
    ) {
      return true;
    }
    if (title === "Request Skip Trace" && user?.canSkipTrace) {
      return true;
    }
    if (title === "Upgrade Subscription" && user?.canPurchase) {
      return true;
    }
    if (title === `Add ${app?.coinName}` && user?.canPurchase) {
      return true;
    }
    return false;
  }

  const findTeamLeaderEmail = () => {
    if (user?.id === teamLeaderID) {
      return user?.email;
    } else {
      const teammate = teammates.find(
        (teammate: any) => teammate.id === teamLeaderID
      );
      return teammate?.email;
    }
  };

  const handleSubmitWithLoader = async (data: any) => {
    setOnSubmitting(true);
    if (typeof onSubmit === "function") await onSubmit(data);
    setOnSubmitting(false);
  };

  const handleCancel = async () => {
    setShowModal(false);
    window.history.pushState({}, "", window.location.pathname);
    if (onCancel) onCancel();
  };

  const teamLeaderEmail = findTeamLeaderEmail();

  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <>
      {showModal && determineAuthorization() ? (
        <ModalLayout
          disableOutsideClick={disableOutsideClick}
          style={modalStyle}
          onCancel={handleCancel}
        >
          <div className="flex items-center justify-between">
            <div className={helpTitle?.length ? "mb-6" : "invisible"}>
              {helpTitle && (
                <QuestionButton helpTitle={helpTitle} helpBody={helpBody} />
              )}
            </div>
            <h1 className="text-dark mt-4 select-none text-center text-xxl font-semibold text-secondary md:text-xxxl">
              {title}
            </h1>
            <div className="mb-6">
              <DeleteButton onClick={handleCancel} />
            </div>
          </div>
          {property && (
            <div className="mt-3 flex w-full items-center justify-center">
              <PropertyModalButton property={property} />
            </div>
          )}
          <div className="my-4 w-full px-2 text-center text-text-dark dark:text-text-light">
            {body}
          </div>
          {!hideButtons && (
            <div className="flex items-center justify-center">
              {!hideCancelButton && (
                <Button
                  className="btn btn-ghost mr-1 w-32 text-lg font-bold text-text-dark hover:bg-hover-back-light dark:text-text-light hover:dark:bg-hover-card-dark"
                  onClick={handleCancel}
                  disabled={onSubmitting}
                >
                  {cancelLabel || "Cancel"}
                </Button>
              )}
              <Button
                className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-primary hover:drop-shadow-lg disabled:bg-hover-back-light disabled:text-gray-500 dark:text-text-light disabled:dark:bg-hover-card-dark disabled:dark:text-gray-800"
                onClick={!onSubmitting ? handleSubmitWithLoader : () => {}}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                icon={!onSubmitting ? submitIcon : undefined}
              >
                {onSubmitting ? (
                  <ButtonLoader
                    className="h-6 w-6 text-white"
                    background={"card"}
                    fill={
                      isHovering
                        ? tailwindTheme.theme2
                        : tailwindTheme.gradient1
                    }
                  />
                ) : (
                  submitLabel ?? "Submit"
                )}
              </Button>
            </div>
          )}
        </ModalLayout>
      ) : (
        showModal && (
          <ModalLayout>
            <div className="">
              <div className="flex justify-between">
                <div className="w-10"></div>
                <h1 className="mt-4 select-none text-center text-xxl font-bold text-error">
                  Access Denied
                </h1>
                <DeleteButton onClick={handleCancel} />
              </div>
            </div>
            <div className="my-4 px-8">
              <p className="select-none text-center text-xl text-title-text-light dark:text-title-text-dark">
                Sorry you don't have sufficient privileges to{" "}
                {titles[title] || "perform this action"}. Please{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:${teamLeaderEmail}`}
                  className="text-secondary hover:underline"
                  onClick={() => setShowModal(false)}
                >
                  contact
                </a>{" "}
                your team leader if you need access to this feature.
              </p>
            </div>

            <div className="flex items-center justify-center">
              <button
                className="btn mr-1 bg-white text-lg font-bold text-text-dark hover:text-text-light dark:bg-black dark:text-text-light hover:dark:border-white"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </ModalLayout>
        )
      )}
    </>
  );
};

export default ModalCard;
