import Property from "src/interfaces/property";
import classNames from "classnames";
import useViewport from "src/hooks/useViewport";
import useAlert from "src/hooks/useAlert";


interface ProgressLinkProps {
  label: string;
  isActive: boolean;
  className?: string;
  textSize: string;
  isHovering?: boolean;
}

const ProgressLink = ({
  label,
  isActive,
  className,
  textSize,
  isHovering,
}: ProgressLinkProps) => {
  const _className = classNames(
    "h-3 sm:h-5 w-full rounded-md flex items-center justify-center ",
    className,
    textSize
  );

  const isHoveringClassNames = classNames(
    "h-3 sm:h-5 w-full rounded-md flex items-center justify-center opacity-60",
    className,
    textSize
  );

  return (
    <>
      <div
        className={
          isActive
            ? _className
            : isHovering
            ? isHoveringClassNames
            : classNames(
                "flex h-3 w-full items-center justify-center rounded-md bg-gray-300 dark:bg-back-dark sm:h-5",
                textSize
              )
        }
      >
        <div
          className={
            isActive || isHovering
              ? classNames("text-text-light dark:text-text-dark", textSize)
              : classNames("text-gray-300 dark:text-back-dark", textSize)
          }
        >
          {label}
        </div>
      </div>
    </>
  );
};

interface ProgressProps {
  property: Property;
  showLegend: boolean;
  textSize: string;
  isHovering?: boolean;
}

const Progress = ({
  property,
  textSize,
  isHovering = false,
}: ProgressProps) => {
  const { isTitle, isSkipTrace, isSequence, isOffer } = property;

  const { isMobile } = useViewport();

  const { alert } = useAlert();

  const progressLinks = [
    {
      label: isMobile ? "DebtStack" : "Debt Stack",
      isActive: isTitle,
      color: "bg-theme5",
    },
    {
      label: isMobile ? "SkipTrace" : "Skip Trace",
      isActive: isSkipTrace,
      color: "bg-theme4",
    },
    {
      label: "Sequence",
      isActive: isSequence,
      color: "bg-theme2",
    },
    {
      label: "Offer",
      isActive: isOffer,
      color: "bg-theme3",
    },
  ];

  const orderedLinks = progressLinks.sort((a, b) => {
    if (a.isActive && !b.isActive) {
      return -1;
    } else if (!a.isActive && b.isActive) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <div className={isHovering ? "hover:cursor-pointer" : ""}>
      <div className="flex justify-between gap-[0.5px]">
        <div className="basis basis-1/5">
          <ProgressLink
            label={"Research"}
            isActive={true}
            className={"bg-theme1"}
            textSize={textSize}
          />
        </div>
        <div className="basis basis-1/5">
          <ProgressLink
            label={orderedLinks[0].label}
            isActive={orderedLinks[0].isActive}
            className={orderedLinks[0].color}
            isHovering={isHovering && !alert.display}
            textSize={textSize}
          />
        </div>
        <div className="basis basis-1/5">
          <ProgressLink
            label={orderedLinks[1].label}
            isActive={orderedLinks[1].isActive}
            className={orderedLinks[1].color}
            isHovering={isHovering && !alert.display}
            textSize={textSize}
          />
        </div>
        <div className="basis basis-1/5">
          <ProgressLink
            label={orderedLinks[2].label}
            isActive={orderedLinks[2].isActive}
            className={orderedLinks[2].color}
            isHovering={isHovering && !alert.display}
            textSize={textSize}
          />
        </div>
        <div className="basis basis-1/5">
          <ProgressLink
            label={orderedLinks[3].label}
            isActive={orderedLinks[3].isActive}
            className={orderedLinks[3].color}
            isHovering={isHovering && !alert.display}
            textSize={textSize}
          />
        </div>
      </div>
    </div>
  );
};

export default Progress;
