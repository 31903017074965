/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from "react";
import { propertyApi, watchlistApi } from "src/api";
import { getGeocode } from "use-places-autocomplete";
import useAlert from "./useAlert";
import useDrawer from "./private/useDrawer";
import useModal from "src/hooks/useModal";
import useOnclickOutside from "react-cool-onclickoutside";
import Property, { RealeflowSuggestion } from "src/interfaces/property";
import usePropertiesContext from "./private/usePropertiesContext";
import ResearchModal from "src/components/modals/ResearchModal";
import ResearchingHelp from "src/components/help/ResearchingHelp";
import useTheme from "./useTheme";
import useTeamContext from "./private/useTeamContext";
import { throttle } from "src/helpers/js-utils";
import parseAddress from "src/helpers/parseAddress";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import useUserContext from "./private/useUserContext";
import WatchlistHelp from "src/components/help/WatchlistHelp";
import useWatchlist from "./private/useWatchlist";
import { useNavigate } from "react-router-dom";
import ResearchAddressButton from "src/components/buttons/ResearchAddressButton";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { shortenString } from "src/helpers/parseStrings";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

const useSearchBar = () => {
  const { mode } = useTheme();

  const { setAlert, clearAlert } = useAlert();

  const { checkCoinBalance, fetchUserTeamData } = useTeamContext();

  const { openModalWith, setShowModal } = useModal();

  const { openPropertyDrawerWith } = useDrawer();

  const { isSearching, fetchProperties, unshiftProperty } =
    usePropertiesContext();

  const { fetchWatchlist } = useWatchlist();

  const { user } = useUserContext();

  const navigate = useNavigate();

  const { team } = useTeamContext();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isInitialLoading, setIsInitialLoading] = useState<boolean>(false);

  const [addressBeingResearched, setAddressBeingResearched] =
    useState<string>("");

  const [suggestions, setSuggestions] = useState<RealeflowSuggestion[]>([]);

  const [searchString, setSearchString] = useState<string>("");

  const [value, setValue] = useState<string>("");

  const searchInput = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    searchInput?.current?.focus();
  };

  const handleClick = () => {
    focusInput();
    clearAlert();
  };

  const ref = useOnclickOutside(() => {
    setSuggestions([]);
  });

  const handleInput = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setValue(e.target.value);
    if (e.target.value.length === 0) {
      setSuggestions([]);
    }
    try {
      const res = await propertyApi.getSuggestions(e.target.value);
      if (res.length) {
        setSuggestions(res);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const getFullAddress = async (address: string) => {
    const parameter = {
      address: address,
    };

    const [results] = await getGeocode(parameter);
    return results;
  };

  const checkExistingProperty = async (address: string, place_id?: string) => {
    if (!place_id) {
      const gRes = await getFullAddress(address);
      place_id = gRes?.place_id;
    }

    if (place_id) {
      const existingProperty = await propertyApi.propertyAlreadySearched(
        place_id
      );
      if (existingProperty) {
        return { ...existingProperty, place_id };
      }
    }

    return null;
  };

  const handleResearchSubmit = async (
    suggestion: RealeflowSuggestion,
    placeID: string,
    fromWatchList: boolean = false
  ) => {
    clearSearch("value");
    try {
      setIsLoading(true);
      setAddressBeingResearched(parseAddress(suggestion.text));
      !fromWatchList && setShowModal(false);
      const result = await propertyApi.researchProperty({
        suggestion,
        placeID,
      });
      unshiftProperty(result);
      fetchUserTeamData();
      openPropertyDrawerWith({
        property: result,
      });
    } catch (err: any) {
      setAlert({
        display: true,
        message: "Unable to find data on this address",
        type: "warning",
      });
    } finally {
      setIsLoading(false);
      setShowModal(false);
      setAddressBeingResearched("");
    }
  };

  const handleAddToWatchlist = async (
    address: string,
    placeID: string,
    suggestion: RealeflowSuggestion
  ) => {
    setShowModal(false);
    try {
      const watchlist = await watchlistApi.createWatchlist({
        userID: user?.id,
        teamID: team?.id,
        fullAddress: address,
        placeID: placeID,
        hash: suggestion.address.hash,
      });
      if (watchlist) {
        setAlert({
          display: true,
          message: "Address added to Watchlist",
          type: "success",
        });
      }
      fetchWatchlist();
      navigate("/watchlist");
    } catch (err) {
      setAlert({
        display: true,
        message: "An error occurred adding this address to Watchlist",
        type: "error",
      });
    }
  };

  const handleSelect = async (
    suggestion: RealeflowSuggestion,
    fromWatchlist?: boolean
  ) => {
    clearAlert();
    setSuggestions([]);
    setValue("");

    try {
      setIsInitialLoading(true);
      const address = suggestion.text;

      const results = await getGeocode({ address });

      const { place_id } = results[0];

      const result: any = await checkExistingProperty(address, place_id);
      if (result) {
        if (user?.isBirddog) {
          setAlert({
            display: true,
            message: "Property already researched by another team member",
            type: "warning",
          });
          return;
        } else {
          return openPropertyDrawerWith({
            property: { id: result.id } as Property,
          });
        }
      }

      const isBalanceOk = checkCoinBalance(
        subscriptionFeatures?.Research.coinCost || 1
      );

      let modalProps: ModalOptionsProps = user?.isBirddog
        ? {
            title: "Add To Watchlist",
            helpTitle: "Watchlist",
            helpBody: <WatchlistHelp />,
            submitLabel: "Add",
            body: (
              <div className="flex items-center justify-center">
                <ResearchAddressButton address={address} placeID={place_id} />
              </div>
            ),
            onSubmit: () =>
              handleAddToWatchlist(address, place_id || "", suggestion),
          }
        : user?.canResearch
        ? {
            title: "Research Property",
            hideButtons: !isBalanceOk,
            helpTitle: "Researching",
            helpBody: <ResearchingHelp />,
            submitLabel: "Research",
            submitIcon: faMagnifyingGlass,
            body: (
              <ResearchModal
                suggestion={suggestion}
                placeID={place_id || ""}
                showWatchlist={!fromWatchlist && user?.canWatchlist}
              />
            ),
            onSubmit: () =>
              isBalanceOk
                ? handleResearchSubmit(
                    suggestion,
                    (result?.place_id || place_id) as string,
                    fromWatchlist
                  )
                : Promise.resolve(),
          }
        : {
            title: "Add To Watchlist",
            helpTitle: "Watchlist",
            helpBody: <WatchlistHelp />,
            submitLabel: "Add",
            body: (
              <div className="flex items-center justify-center">
                <ResearchAddressButton address={address} placeID={place_id} />
              </div>
            ),
            onSubmit: () =>
              handleAddToWatchlist(address, place_id || "", suggestion),
          };

      openModalWith({
        ...modalProps,
      });
      clearSearch("value");
    } catch (error: any) {
      setAlert({
        display: true,
        message: error?.message ?? "Something went wrong, Please try later.",
        type: "error",
      });
    } finally {
      setIsInitialLoading(false);
    }
  };

  // exclusively for searching new property
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSearching) {
      // handleSelect(value, false);
    }
  };

  // As the searchInput has been changed to uncontrolled component, we have to reset the input value manually
  // uncontrolled component give the ability to throttle input fire action
  const clearSearch = (key: "value" | "search") => {
    if (key === "value") {
      setValue("");
      setSuggestions([]);
    } else {
      setSearchString("");
    }
    if (searchInput?.current?.value !== undefined) {
      searchInput.current.value = "";
    }
  };

  const handleSearchSavedProperties = () => {
    if (!isSearching) {
      return;
    }
    fetchProperties({
      search: searchString.toLowerCase(),
      searchFields: "fullAddress",
    });
  };

  // Throttle setting state to avoid multiple api search properties call
  // Using useCallback here to avoid to recreate every stating change the throttle
  const handleSearchProperties = useCallback(
    throttle((e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      let searchParameters = e.target.value || "";
      setSearchString(searchParameters);
    }, 2000),
    []
  );

  // Only fire search on searchString change
  useEffect(() => {
    handleSearchSavedProperties();
  }, [searchString]);

  const renderSuggestions = (
    isMobile?: boolean
  ): { element: JSX.Element; suggestions: RealeflowSuggestion[] } => {
    const suggestionsList = suggestions.map(
      (suggestion: RealeflowSuggestion, index: number) => (
        <li
          className={
            index === 0
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pt-2 text-lg text-text-light dark:text-text-light"
              : index === suggestions.length - 1
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pb-2 text-lg text-text-light dark:text-text-light"
              : "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 text-lg text-text-light dark:text-text-light"
          }
          key={index}
          value={suggestion.text}
          onClick={() => handleSelect(suggestion)}
        >
          <button
            style={{
              textTransform: "none",
            }}
            className={
              isMobile
                ? "btn btn-ghost flex w-full items-center justify-start gap-3 text-xxs text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
                : "btn btn-ghost flex w-full items-center justify-start gap-3 text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
            }
          >
            <img
              className={isMobile ? "w-[22px]" : "w-[33px]"}
              src={require(`src/assets/icons/${suggestion.address.state}-${
                mode === "light" ? "dark" : "light"
              }.png`)}
              alt="state"
            />
            {isMobile ? shortenString(suggestion.text, 45) : suggestion.text}
          </button>
        </li>
      )
    );
    return {
      element: <div className="pb-1.5">{suggestionsList}</div>,
      suggestions,
    };
  };

  const renderStaticSuggestions = (
    isMobile: boolean,
    callBack: (suggestion: RealeflowSuggestion) => void
  ): JSX.Element => {
    const suggestionsList = suggestions.map(
      (suggestion: RealeflowSuggestion, index: number) => (
        <li
          className={
            index === 0
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pt-2 text-lg text-text-light dark:text-text-light"
              : index === suggestions.length - 1
              ? "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 pb-2 text-lg text-text-light dark:text-text-light"
              : "flex min-w-full list-none items-center justify-start rounded-lg py-0.5 text-lg text-text-light dark:text-text-light"
          }
          key={index}
          value={suggestion.text}
          onClick={() => callBack(suggestion)}
        >
          <button
            style={{
              textTransform: "none",
            }}
            className={
              isMobile && suggestion.text.length > 40
                ? suggestion.text.length > 40
                  ? "btn btn-ghost flex w-full items-center justify-start gap-3 text-xxs text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
                  : "btn btn-ghost flex w-full items-center justify-start gap-3 text-xs text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
                : "btn btn-ghost flex w-full items-center justify-start gap-3 text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
            }
          >
            <img
              className={isMobile ? "w-[27px]" : "w-[33px]"}
              src={require(`src/assets/icons/${suggestion.address.state}-${
                mode === "light" ? "dark" : "light"
              }.png`)}
              alt="state"
            />
            {suggestion.text}
          </button>
        </li>
      )
    );
    return <>{suggestionsList}</>;
  };

  useEffect(() => {
    if (isSearching) {
      focusInput();
    }
  }, [isSearching]);

  return {
    ref,
    value,
    isLoading,
    isInitialLoading,
    searchInput,
    isSearching,
    searchString,
    addressBeingResearched,
    setValue,
    handleInput,
    handleClick,
    handleSubmit,
    handleSelect,
    clearSearch,
    setSearchString,
    renderSuggestions,
    renderStaticSuggestions,
    handleSearchProperties,
    checkExistingProperty,
  };
};

export default useSearchBar;
