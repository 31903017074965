import { Navigate, Outlet } from "react-router-dom";
import useTokenGuard from "../hooks/useTokenGuard";

const UpdateCardGuard = () => {
    const { token, onChecking, redirection } = useTokenGuard("failed-payment");

    if (onChecking) {
        return <div className='w-full justify-center flex'><span className='loading'>...</span></div>;
    }

    if (token) {
        return <Navigate to={redirection || "/settings?hasfailedpayment=1"} />
    }

    return (<Outlet />)
}

export default UpdateCardGuard;