// import { useEffect, useRef } from "react";
import SubHolder from "./SubHolder";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useSubscriptionContext from "src/hooks/useSubscription";
import { AppSubscriptionsAndFeatures } from "src/interfaces/subscriptions";

interface SubscriptionChoiceProps {
  isUpgrade?: boolean;
  selectedSubscription: number;
  isAnnual: boolean;
  handleSelectSub: (subId: number) => void;
  handleSelectPeriod: (annual: boolean) => void;
  lightModeOnly?: boolean;
}

const SubscriptionChoice = ({
  isUpgrade,
  selectedSubscription,
  isAnnual,
  handleSelectSub,
  handleSelectPeriod,
  lightModeOnly
}: SubscriptionChoiceProps) => {
  const { subscription } = useCurrentSubscriptionContext();

  const { appSubscriptionsAndFeatures } = useSubscriptionContext();

  const availableSubscriptions = Object.values(
    appSubscriptionsAndFeatures || {}
  ).filter(
    (sub: AppSubscriptionsAndFeatures) =>
      sub.subscription.sortOrder > (subscription?.sortOrder || 0)
  );

  return (
    <>
      <div
        className="grid snap-x auto-cols-max grid-flow-col items-center justify-start gap-4 overflow-x-auto px-8 py-4 md:snap-align-none md:gap-12 overflow-y-scroll w-full md:justify-center"
        // ref={containerRef}
      >
        {availableSubscriptions.map(
          (sub: AppSubscriptionsAndFeatures, index: number) => (
            <div key={index} className="snap-start md:snap-none">
              <SubHolder
                sub={sub}
                isSelected={sub.subscription.id === selectedSubscription}
                isAnnual={isAnnual}
                handleSelectSub={
                  subscription?.id === sub.subscription.id
                    ? () => {}
                    : handleSelectSub
                }
                handleSelectPeriod={
                  subscription?.id === sub.subscription.id
                    ? () => {}
                    : handleSelectPeriod
                }
                currentSub={subscription?.id === sub.subscription.id}
                isUpgrade={isUpgrade}
                lightModeOnly={lightModeOnly}
              />
            </div>
          )
        )}
      </div>
    </>
  );
};

export default SubscriptionChoice;
