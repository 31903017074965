import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import useApp from "src/hooks/useAppContext";
import useRealeflowContext from "src/hooks/private/useRealeflowContext";
import { AITerritory } from "src/interfaces/realeflow";
import useModal from "src/hooks/useModal";
import ZoneModal from "../modals/ZoneModal";
import ZonesHelp from "../help/ZonesHelp";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import { Subscription } from "src/interfaces/subscriptions";

interface ZoneButtonProps {
  subscription: Subscription;
}

const ZoneButton = ({ subscription }: ZoneButtonProps) => {
  const { app } = useApp();

  const { openModalWith } = useModal();

  const { isLoadingSubscription } = useCurrentSubscriptionContext();

  const { isLoading, realeflowAccount } = useRealeflowContext();

  const [pingIcon, setPingIcon] = useState<boolean>(false);

  useEffect(() => {
    const actionNeededOnRealeflowAccount = () => {
      if (
        realeflowAccount?.AITerritories.filter(
          (territory: AITerritory) => territory.Type === "Zip"
        ).length ||
        0 < subscription?.includedZips ||
        0
      ) {
        setPingIcon(true);
      } else {
        setPingIcon(false);
      }
    };

    if (!isLoading && realeflowAccount) {
      actionNeededOnRealeflowAccount();
    }
  }, [isLoading, realeflowAccount, isLoadingSubscription, subscription]);

  const handleZoneClick = () => {
    if (app?.hasPurchaseableZones && realeflowAccount && subscription) {
      openModalWith({
        title: "Zones",
        body: (
          <ZoneModal
            realeflowAccount={realeflowAccount}
            subscription={subscription}
            pingIcon={pingIcon}
          />
        ),
        hideButtons: true,
        helpTitle: "Zones",
        helpBody: <ZonesHelp />,
      });
    }
  };

  return (
    <>
      <button
        onClick={handleZoneClick}
        className="btn btn-ghost relative hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
      >
        <FontAwesomeIcon icon={faFlag} className="w-4 text-xl text-primary" />
        {!isLoading && pingIcon && (
          <span className="absolute right-[6px] top-[6px] flex h-[9px] w-[9px]">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-accent opacity-75"></span>
            <span className="absolute inline-flex h-[9px] w-[9px] rounded-full bg-accent"></span>
          </span>
        )}
      </button>
    </>
  );
};

export default ZoneButton;
