import React from "react";
import { faCartCircleCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import MarketTooltip from "src/components/market/MarketTooltip";

interface MarketOrdersButtonProps {
  props?: any;
}

const MarketOrdersButton = ({ props }: MarketOrdersButtonProps) => {
  const navigate = useNavigate();

  return (
    <button
      className="btn btn-ghost rounded-xl border-none px-4 py-0.5 font-bold text-secondary hover:bg-hover-card-light hover:dark:bg-hover-card-dark"
      onClick={() => navigate("/market/orders")}
      data-tip
      data-for={`tooltip-id-orders-button`}
    >
      <FontAwesomeIcon
        icon={faCartCircleCheck}
        className="text-[20px] text-secondary"
        onClick={() => navigate("/market")}
      />
      <MarketTooltip id={`tooltip-id-orders-button`} content={"Orders"} />
    </button>
  );
};

export default MarketOrdersButton;
