import { useState } from 'react';

const useCarousel = (titles: string[]) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);

  const handleCarouselRight = () => {
        if (carouselIndex < titles.length - 1) {
            setCarouselIndex(carouselIndex + 1);
        } else {
            setCarouselIndex(0);
        }
  };

  const handleCarouselLeft = () => {
        if (carouselIndex > 0) {
            setCarouselIndex(carouselIndex - 1);
        } else {
            setCarouselIndex(titles.length - 1);
        }
  };

  return { carouselIndex, carouselActions: { handleCarouselLeft, handleCarouselRight } };
}

export default useCarousel;