/* eslint-disable import/no-anonymous-default-export */
import { Campaign } from "src/interfaces/campaign";
import ApiTokenSupport from "./token.support";
import axios from "src/utils/axios";

class CampaignApi extends ApiTokenSupport {

  async getCampaigns(teamID: number): Promise<Campaign[]> {
    const resp = await axios.get<Campaign[]>(
      `/api/campaign/all/${teamID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getByID(campaignID: number): Promise<Campaign> {
    const resp = await axios.get<Campaign>(
      `/api/campaign/${campaignID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async create(campaignData: Omit<Campaign, "id">): Promise<Campaign> {
    const resp = await axios.post<Campaign>(
      `/api/campaign`,
      campaignData,
      this.withAuthorization()
    );
    return resp.data;
  }

  async edit(campaignData: Campaign): Promise<Campaign> {
    const resp = await axios.put<Campaign>(
      `/api/campaign/${campaignData.id}`,
      campaignData,
      this.withAuthorization()
    );
    return resp.data;
  }

  async delete(campaignID: number): Promise<Campaign> {
    const resp = await axios.delete<Campaign>(
      `/api/campaign/${campaignID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new CampaignApi();
