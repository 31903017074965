/* eslint-disable import/no-anonymous-default-export */
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { VerifyAffiliate } from "src/interfaces/affiliate";

class AffiliateApi extends ApiTokenSupport {
  async verifyReferralID(referralID: string): Promise<VerifyAffiliate> {
    const resp = await axios.post<VerifyAffiliate>(
      `/api/affiliate/referralID`,
      {
        referralID,
      }
    );
    return resp.data;
  }
}

export default new AffiliateApi();
