function capitalize(str: any) {
  if(!str || typeof str !== 'string' || str.trim() === '') return '';
  return str.trim().replace(/\b\w/g, l => l.toUpperCase());
}

const directions: { [key: string]: string } = {
  north: 'N',
  south: 'S',
  east: 'E',
  west: 'W',
  northeast: 'NE',
  northwest: 'NW',
  southeast: 'SE',
  southwest: 'SW'
}

function abbreviateDirection(str: any) {
  if(!str || typeof str !== 'string') return '';
  str = str.toLowerCase().trim();
  return directions[str] || '';
};

function shortenString(str: string, size: number) {
  if(!str || typeof str !== 'string') return '';
  return str.slice(0, size);
}

export { capitalize, abbreviateDirection, shortenString };
