import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import HeaderNavMarket from "src/components/navigation/market/HeaderNavMarket";
import useViewport from "src/hooks/useViewport";

interface MarketLayoutProps {
  children?: ReactNode;
}

const MarketLayout = ({ children }: MarketLayoutProps) => {
  const { width } = useViewport();

  const dynamicWidth = () => {
    if (width < 640) {
      return "100%";
    } else if (width < 1024) {
      return "80%";
    } else {
      return 550;
    }
  };

  return (
    <>
      <div className=" min-h-screen min-w-full bg-back-light dark:bg-back-dark ">
        <HeaderNavMarket />
        <div className="fixed mt-14 flex h-full w-full flex-grow flex-col items-center justify-start px-1.5 pt-1.5">
          <div className="h-full " style={{ width: dynamicWidth() }}>
            {children || <Outlet />}
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketLayout;
