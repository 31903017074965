import React from "react";
import ReactTooltip, { Place } from "react-tooltip";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";

interface MarketTooltipProps {
  id: string;
  place?: Place;
  backgroundColor?: string;
  textColor?: string;
  content: any;
  disable?: boolean;
}

const MarketTooltip = ({
  id,
  place,
  backgroundColor,
  textColor,
  content,
  disable,
}: MarketTooltipProps) => {
  const { isMobile } = useViewport();

  const { mode } = useTheme();

  const defaultPlace: Place = "bottom";

  return (
    <>
      <ReactTooltip
        id={id}
        place={place || defaultPlace}
        effect="solid"
        disable={isMobile || disable}
        backgroundColor={
          backgroundColor || (mode === "dark" ? "white" : "black")
        }
        textColor={textColor || (mode === "dark" ? "black" : "white")}
        className="font-semibold opacity-5"
      >
        {content}
      </ReactTooltip>
    </>
  );
};
export default MarketTooltip;
