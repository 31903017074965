import { PropsWithChildren, useState, createContext } from "react";

interface SpinnerContextValue {
  isSpinning: boolean;
  setSpinner: () => void;
  clearSpinner: () => void;
}

const defaultState = {
  isSpinning: false,
  setSpinner: () => {},
  clearSpinner: () => {},
};

export const SpinnerContext = createContext<SpinnerContextValue>({...defaultState});

interface SpinnerContextProps {};

export const SpinnerProvider = ({
  children,
}: PropsWithChildren<SpinnerContextProps>) => {
  const [isSpinning, setIsSpinning] = useState(defaultState.isSpinning);

  const setSpinner = () => setIsSpinning(true);
  const clearSpinner = () => setIsSpinning(false);

  return (
    <SpinnerContext.Provider value={{ isSpinning, setSpinner, clearSpinner }}>
      {children}
    </SpinnerContext.Provider>
  );
};
