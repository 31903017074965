import {
  PropsWithChildren,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { configApi } from "src/api";
import { Configs } from "src/interfaces/configs";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface ConfigContextValue {
  configs: Configs[];
}

const defaultState = {
  configs: [],
};

export const ConfigContext = createContext<ConfigContextValue>(defaultState);

interface ConfigProviderProps {}

export const ConfigProvider = ({
  children,
}: PropsWithChildren<ConfigProviderProps>) => {
  const { handleError } = useErrorHandler();

  const [configs, setConfigs] = useState<Configs[]>([]);

  const fetchConfigs = useCallback(async () => {
    try {
      const configs = await configApi.getConfigs();
      setConfigs(configs);
    } catch (e) {
      handleError(e);
    }
  }, [handleError]);

  useEffect(() => {
    fetchConfigs();
  }, [fetchConfigs]);

  return (
    <ConfigContext.Provider
      value={{
        configs,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
