import { Navigate, Outlet } from "react-router-dom";
import useAffiliateGuard from "../hooks/useAffiliateGuard";
import LoadingSpinner from "src/components/loading/LoadingSpinner";

const AffiliateGuard = () => {
  const { referralID, onChecking, redirection } = useAffiliateGuard();

  if (onChecking) {
      return <LoadingSpinner />;
  }

  if (redirection && referralID) {
      return <Navigate to={redirection} />
  }

  return (<Outlet />)
};

export default AffiliateGuard;