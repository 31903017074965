import React, { useState } from "react";
import { faPlus, faRoute } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";

interface ViewItemProps {
  icon: any;
  label: string;
  onClick: () => void;
  isActive: boolean;
}

const ViewItem = ({ icon, label, onClick, isActive }: ViewItemProps) => {
  const handleClick = (event: any) => {
    event.stopPropagation();
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    onClick();
  };

  return (
    <li>
      <div
        className={
          isActive
            ? "flex cursor-pointer items-center justify-start text-secondary hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
            : "flex cursor-pointer items-center justify-start text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"
        }
        onClick={handleClick}
      >
        <FontAwesomeIcon icon={icon} className="w-4 text-base" />
        <p
          style={{
            textTransform: "none",
          }}
        >
          {label}
        </p>
      </div>
    </li>
  );
};

interface CampaignToolbarButtonProps {
  onClick: () => void;
  isActive: boolean;
}

const CampaignToolbarButton = ({
  onClick,
  isActive,
}: CampaignToolbarButtonProps) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = () => {
    setIsOpen(false);
    onClick();
  };

  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  return (
    <>
      <div
        className={
          isOpen
            ? "dropdown dropdown-end dropdown-open dropdown-hover w-1/4 "
            : "dropdown dropdown-end dropdown-hover w-1/4 "
        }
        onClick={() => setIsOpen(!isOpen)}
        ref={ref}
      >
        <label
          className={
            isActive
              ? "btn join-item btn-sm  z-50 flex h-full w-full cursor-pointer select-none items-center justify-center gap-2 border-[1px] border-zinc-300 bg-secondary px-1.5 text-xxs text-text-light hover:bg-secondary hover:dark:bg-secondary hover:text-text-light dark:border-black sm:text-xs md:text-base"
              : "btn join-item btn-sm  z-50 flex h-full w-full cursor-pointer select-none items-center justify-center gap-2 border-[1px] border-zinc-300 bg-card-light px-1.5 text-xxs text-text-dark hover:bg-secondary hover:dark:bg-secondary hover:text-text-light dark:border-black dark:bg-card-dark sm:text-xs md:text-base dark:text-text-light"
          }
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faRoute} className="" />
          <div className="">{"Campaigns"}</div>
        </label>
        <ul
          tabIndex={0}
          className="menu dropdown-content z-50 w-48 rounded-lg bg-card-light p-2 shadow-2xl drop-shadow-xl dark:border-icon-light dark:bg-card-dark font-semibold"
        >
          <ViewItem
            icon={faPlus}
            label="New Campaign"
            onClick={() => navigate("/sequences/campaigns/new")}
            isActive={false}
          />
        </ul>
      </div>
    </>
  );
};

export default CampaignToolbarButton;
