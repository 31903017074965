import React from "react";
import getHostName from "src/helpers/getAppFromHost";
import useApp from "src/hooks/useAppContext";

const BulkImportHelp = () => {
  const { app } = useApp();
  return (
    <>
      <div>
        <p>
          To upload a CSV file with multiple properties or to rapidly Research
          more than one property at a time, click the + Icon to the right of the
          search bar on Desktop view.
        </p>
        <br></br>
        <p>
          Ensure your CSV file has the entire address inside a single column and
          has one of these formats:
        </p>
        <br></br>
        <p className="text-red-400">
          [Street Address] [City] [State code] [Zip]
        </p>
        <br></br>
        <p className="text-red-400">[Address] [City], [State code] [Zip]</p>
        <br></br>
        <p className="text-red-400">
          [Street Address], [City], [State code] [Zip], [Country]
        </p>
        <br></br>
        <p>
          The number of addresses you can import at one time is limited to a
          maximum of 300. If you have any duplicates{" "}
          {app?.name || getHostName()} will automatically remove them and they
          will not be counted towards this limit.
        </p>
        <br></br>
        {app?.displayDealio && (
          <>
            <h3 className="font-bold">Dealio Import</h3>
            <br></br>
            <p>
              To successfully import addresses from Dealio please ensure you are
              following the correct format for exporting them from Dealio.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 1:</span> Select the leads you
              want to save and click Download.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 2:</span> Select the Standard
              Format option radio button.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 3:</span> Select the default
              Overview checkbox and ONLY this checkbox.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 4:</span> Click download.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 5:</span> Extract the .zip file
              that downloaded.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 6:</span> Upload the extracted
              .csv file in the Dealio Import Modal found in the dropdown menu
              button on Desktop view to the right of the search bar.
            </p>
            <br></br>
            <p>
              <span className="font-bold">Step 7:</span> Make sure to select the
              checkbox that says this file is coming from Dealio.
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default BulkImportHelp;
