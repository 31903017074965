import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HomeHelp from "src/components/help/HomeHelp";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";

const HelpButton = () => {
  const { openHelpDrawerWith } = useHelpDrawer();

  const handleHelp = () => {
    openHelpDrawerWith({
      title: "Home",
      body: <HomeHelp />,
    });
  };

  return (
    <>
      <button
        onClick={handleHelp}
        className="btn btn-ghost hover:bg-hover-back-light dark:hover:bg-hover-back-dark"
      >
        <FontAwesomeIcon icon={faInfo} className="text-primary text-xl w-4" />
      </button>
    </>
  );
};

export default HelpButton;
