// src/components/OrderSyncWrapper.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import useSyncMarketOrders from "src/hooks/useSyncMarketOrders";
import LoadingSpinner from "src/components/loading/LoadingSpinner";

interface OrderSyncWrapperProps {
  children: React.ReactNode;
}

const OrderSyncWrapper: React.FC<OrderSyncWrapperProps> = ({ children }) => {
  const { isLoading, error } = useSyncMarketOrders();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="my-4 flex items-center justify-center gap-2 rounded-lg bg-card-light px-2 py-2 dark:bg-card-dark">
        <LoadingSpinner className="text-secondary dark:text-theme1" />
        <span className="animate-pulse font-bold text-secondary">
          Syncing orders...
        </span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="mt-4 rounded-lg bg-card-light px-4 py-2 dark:bg-card-dark">
          <p className="text-error">{error}</p>
        </div>
        <div className="mt-4 flex w-full justify-center">
          <button
            className="btn btn-ghost btn-secondary btn-sm bg-hover-back-light text-secondary dark:bg-hover-back-dark"
            onClick={() => navigate("/market")}
          >
            Go Home
          </button>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default OrderSyncWrapper;
