import { ReactNode, useEffect } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import PrivateProvider from "src/provider/private";
import PrivateLayout from "../layouts/PrivateLayout";
import { Outlet } from "react-router-dom";
import BirddogLayout from "src/layouts/BirddogLayout";

/* Private Route that checks if the user is logged in then provides correct Layout depending on Subscription Level */

interface PrivateRouteProps {
  children?: ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const {
    currentUser,
    setRedirection,
    subscription,
    onCheckingSubscription,
    isDemo,
  } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const alreadyRedirected = searchParams.get("hasfailedpayments");

  useEffect(() => {
    setRedirection(location.pathname);
  }, [location.pathname, setRedirection]);

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (
    !onCheckingSubscription &&
    !subscription?.plan &&
    !isDemo &&
    location.pathname !== "/subscription"
  ) {
    return <Navigate to="/subscription" />;
  }

  if (!onCheckingSubscription && subscription && !isDemo) {
    if (subscription.hasFailedPayments && alreadyRedirected !== "1") {
      return <Navigate to="/settings?hasfailedpayments=1" />;
    }
  }

  if (currentUser.isBirddog) {
    return (
      <>
        <PrivateProvider>
          <BirddogLayout />
        </PrivateProvider>
      </>
    );
  }

  return (
    <>
      <PrivateProvider>
        <PrivateLayout>{children || <Outlet />}</PrivateLayout>
      </PrivateProvider>
    </>
  );
};

export default PrivateRoute;
