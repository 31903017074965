import { skiptraceApi } from "src/api";
import useDrawer from "src/hooks/private/useDrawer";
import useSkipTraceContext from "src/hooks/private/useSkipTraceContext";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useViewport from "src/hooks/useViewport";
import Property from "src/interfaces/property";
import SubscriptionsHelp from "../help/SubscriptionsHelp";
import UpgradeModal from "./UpgradeModal";
import ButtonBlock from "./ButtonBlock";
import { useState } from "react";
import shortenProperty from "src/helpers/shortenProperty";

interface SkipTraceModalProps {
  property: Property;
}

const SkipTraceModal = ({ property }: SkipTraceModalProps) => {
  const { id } = property;

  const { isMobile } = useViewport();

  const { setShowModal, openModalWith } = useModal();

  const { setAlert } = useAlert();

  const { showPropertyDrawer, refreshProperty } = useDrawer();

  const { fetchUserTeamData } = useTeamContext();

  const { fetchSkipTraces } = useSkipTraceContext();

  const { subscriptionFeatures } = useCurrentSubscriptionContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const extra =
    subscriptionFeatures?.["SkipTrace"].accessType === "fiat"
      ? subscriptionFeatures?.["SkipTrace"].fiatCost.toFixed(2)
      : 0;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await skiptraceApi.orderSkipTrace(id);
      setAlert({
        display: true,
        message:
          isMobile || showPropertyDrawer
            ? "Skip Trace data found"
            : `Skip Trace data found for ${shortenProperty(property)}`,
        type: "success",
      });
    } catch (err) {
      setAlert({
        display: true,
        message:
          isMobile || showPropertyDrawer
            ? "Unable to find Skip Trace Data"
            : `Skip Trace data failed for ${shortenProperty(property)}`,
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
      setShowModal(false);
      fetchUserTeamData();
      fetchSkipTraces();
      refreshProperty();
    }
  };

  const handleSubscriptionUpgrade = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: (
        <UpgradeModal mustFullyUpgrade={true} featureClicked={"SkipTrace"} />
      ),
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  return (
    <>
      {extra !== 0 && (
        <div className="mb-4 flex items-center justify-center">
          <div>Request the Skip Trace for ${extra} or &nbsp;</div>
          <p
            onClick={handleSubscriptionUpgrade}
            className="cursor-pointer underline hover:opacity-70"
          >
            upgrade your subscription
          </p>
        </div>
      )}
      <ButtonBlock
        handleSubmit={handleSubmit}
        submitLabel={<span> Submit {extra !== 0 ? `$${extra}` : ""}</span>}
        submitting={isSubmitting}
      />
    </>
  );
};

export default SkipTraceModal;
