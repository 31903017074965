import { SubscriptionFeature } from "src/interfaces/subscriptions";

export const getFeatureCost = (
  feature: SubscriptionFeature | undefined
): string => {
  return feature
    ? feature.accessType === "coin"
      ? ` ${feature.coinCost} `
      : ` ${feature.fiatCost} `
    : "?";
};

export default getFeatureCost;
