import React, { useState } from "react";
import useModal from "src/hooks/useModal";
import useApp from "src/hooks/useAppContext";
import Button from "../buttons/Button";

interface VacationModalProps {
  props?: any;
}

const VacationModal = ({ props }: VacationModalProps) => {
  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const { setShowModal } = useModal();

  const { app } = useApp();

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleVacationMode = () => {
    setOnSubmitting(true);
  };

  return (
    <>
      <div className="my-4 px-8">
        <p className="mb-2 text-center text-text-dark dark:text-text-light">
          To purchase more {app?.coinName} you must first turn off Vacation
          Mode.
        </p>
      </div>

      <div className="flex items-center justify-center">
        <Button
          className="btn-ghost btn mr-1 w-32 text-lg font-bold text-text-dark hover:bg-blue-200 dark:text-text-light hover:dark:bg-gray-900"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          className="btn ml-1 w-32 bg-secondary text-lg font-bold text-text-light hover:bg-accent hover:drop-shadow-lg dark:text-text-dark"
          onClick={handleVacationMode}
          disabled={onSubmitting}
          loading={onSubmitting}
        >
          Turn Off
        </Button>
      </div>
    </>
  );
};

export default VacationModal;
