import React from "react";

interface AIScoresHelpProps {
  props?: any;
}

const AIScoresHelp = ({ props }: AIScoresHelpProps) => {
  return (
    <>
      <div>
        <p>
          AI Scores are a proprietary algorithm that predicts the likelihood of
          a property selling in the next 3 months. Scores are based on a scale
          of 0-1000 and are placed into three categories: Retail, Rental and
          Wholesale. Retail scores indicated the property is more likely to be
          sold at market price, Rental scores indicate the property is more
          likely to be sold to a landlord, and Wholesale scores indicate the
          property is more likely to be sold at a discount.
        </p>
        <br></br>
        <p>
          Scores of 500 and up indicate a higher propensity to sell. Properties
          that have recently sold will be scored 0.
        </p>
      </div>
    </>
  );
};

export default AIScoresHelp;
