import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

/***** Context Provider *****/
import { ThemeProvider } from "src/contexts/ThemeContext";
import { AuthProvider } from "src/contexts/AuthContext";
import { AlertProvider } from "src/contexts/AlertContext";
import { NavProvider } from "src/contexts/NavContext";
import { FayeProvider } from "src/faye";
import { RealtimeSyncProvider } from "src/contexts/RealtimeSyncContext";
import { ConfigProvider } from "src/contexts/ConfigContext";
import { AppProvider } from "src/contexts/AppContext";
import { QueryParamsProvider } from "src/contexts/QueryParamsContext";
import { SubscriptionProvider } from "src/contexts/SubscriptionContext";
import { HelpDrawerProvider } from "src/contexts/HelpDrawerContext";

interface AppProviderProps {}

const ApplicationProvider = ({
  children,
}: PropsWithChildren<AppProviderProps>) => (
  <AppProvider>
    <ThemeProvider>
      <BrowserRouter>
        <QueryParamsProvider>
          <NavProvider>
            <AlertProvider>
              <RealtimeSyncProvider>
                <FayeProvider>
                  <SubscriptionProvider>
                    <ConfigProvider>
                      <HelpDrawerProvider>
                        <AuthProvider>{children}</AuthProvider>
                      </HelpDrawerProvider>
                    </ConfigProvider>
                  </SubscriptionProvider>
                </FayeProvider>
              </RealtimeSyncProvider>
            </AlertProvider>
          </NavProvider>
        </QueryParamsProvider>
      </BrowserRouter>
    </ThemeProvider>
  </AppProvider>
);

export default ApplicationProvider;
