/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

const TOKEN_ID = "x-access-token";

const LoginFromNative = () => {
  const { token, destination } = useParams();

  useEffect(() => {
    localStorage.setItem(TOKEN_ID, token as string);
  }, []);

  return <Navigate to={`/${destination}` || "/"} />;
};

export default LoginFromNative;
