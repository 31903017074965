export default function parseAddress(address: string) {
  const abbreviations: { [key: string]: string } = {
    north: "N",
    south: "S",
    east: "E",
    west: "W",
    northeast: "NE",
    northwest: "NW",
    southeast: "SE",
    southwest: "SW",
    street: "St",
    road: "Rd",
    lane: "Ln",
    turnpike: "Tpke",
    trail: "Trl",
    drive: "Dr",
    cove: "Cv",
    court: "Ct",
    courts: "Cts",
    circle: "Cir",
    boulevard: "Blvd",
    avenue: "Ave",
    place: "Pl",
  };

  function abbreviateElement(str: string) {
    str = str.toLowerCase().trim();
    return abbreviations[str] || "";
  }

  let addressArr = address.split(" ");

  let keys = Object.keys(abbreviations);

  for (let i = 0; i < addressArr.length; i++) {
    let wasTrimmed = false;
    if (addressArr[i].endsWith(",")) {
      addressArr.splice(i, 1, addressArr[i].replace(/,$/g, ""));
      wasTrimmed = true;
    }

    if (keys.indexOf(addressArr[i].toLowerCase()) !== -1) {
      addressArr.splice(
        i,
        1,
        `${abbreviateElement(addressArr[i].toLowerCase())}${
          wasTrimmed ? "," : ""
        }`
      );
    } else {
      wasTrimmed && addressArr.splice(i, 1, `${addressArr[i]},`);
    }
  }

  return addressArr.join(" ");
}

export const trimCountry = (str: string) => {
  let arr = str.split(",");
  arr.pop();
  return arr.join(",");
};
