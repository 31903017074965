import React from "react";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import SubscriptionsHelp from "./SubscriptionsHelp";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import AIScoresHelp from "./AIScoresHelp";

interface ZonesHelpProps {
  props?: any;
}

const ZonesHelp = ({ props }: ZonesHelpProps) => {
  const { helpLink } = useHelpDrawer();

  const { subscription } = useCurrentSubscriptionContext();

  return (
    <>
      {subscription && (
        <div>
          <p>
            Zones are areas of the US that will return{" "}
            <span
              className="cursor-pointer font-bold text-primary hover:underline"
              onClick={() => helpLink("AI Scores", <AIScoresHelp />)}
            >
              AI Scores{" "}
            </span>{" "}
            on Properties that have been Researched. There are three types of
            zones, Zipcodes, Counties, and States. Zipcodes are available on all{" "}
            <span
              className="cursor-pointer font-bold text-primary hover:underline"
              onClick={() => helpLink("Subscriptions", <SubscriptionsHelp />)}
            >
              Subscription{" "}
            </span>
            levels.
          </p>
          <br></br>
          <p>
            Your current subscription level is {`${subscription?.name}`} which
            comes with {`${subscription?.includedZips}`} included Zipcodes with
            the ability to add an additional{" "}
            {`${subscription?.maxZips - subscription?.includedZips}`} at a rate
            of ${subscription?.extraZipCost} per extra Zipcode added.
          </p>
        </div>
      )}
    </>
  );
};

export default ZonesHelp;
