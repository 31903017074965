import { useState, useCallback, useEffect } from "react";

export type Location = {
  latitude: number;
  longitude: number;
};

interface UseLocationResult {
  location: Location | null;
  locationError: string | null;
  requestLocation: (onSuccess: (location: Location) => void) => Promise<void>;
  checkGeolocationPermissions: () => Promise<boolean | undefined>;
}

const useCurrentLocation = (): UseLocationResult => {
  const [location, setLocation] = useState<Location | null>(null);
  const [locationError, setError] = useState<string | null>(null);

  const checkGeolocationPermissions = async (): Promise<
    boolean | undefined
  > => {
    if (!("permissions" in navigator)) return undefined;

    try {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      return permissionStatus.state === "granted";
    } catch (error) {
      console.error("Error checking geolocation permission:", error);
      return undefined;
    }
  };

  const requestLocation = useCallback(
    (onSuccess: (location: Location) => void) => {
      return new Promise<void>((resolve, reject) => {
        if (!navigator.geolocation) {
          setError("Geolocation not supported");
          reject("Geolocation not supported");
          return;
        }

        navigator.geolocation.getCurrentPosition(
          (position) => {
            const newLocation: Location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };
            setLocation(newLocation);
            setError(null);
            onSuccess(newLocation);
            resolve();
          },
          () => {
            setError("Unable to retrieve location");
            reject("Unable to retrieve location");
          }
        );
      });
    },
    []
  );

  useEffect(() => {
    async function fetchLocationOnStartup() {
      const permissionGranted = await checkGeolocationPermissions();
      if (permissionGranted) {
        await requestLocation(() => {});
      }
    }

    fetchLocationOnStartup();
  }, [requestLocation]);

  return {
    location,
    locationError,
    requestLocation,
    checkGeolocationPermissions,
  };
};

export default useCurrentLocation;
