import React from "react";
import CarouselHeader from "src/components/carousel/CarouselHeader";
import { TemplatePostcard } from "src/interfaces/templatePostcard";
import useTemplatePostcardsContext from "src/hooks/private/useTemplatePostcardsContext";
import useCarousel from "src/hooks/useCarousel";
import TemplateHolder from "src/components/templates/TemplateHolder";

const PostcardsTab = () => {
  const { templatePostcards } = useTemplatePostcardsContext();

  const carousel = ["Absentee Owner", "Owner Occupied"];
  const { carouselIndex, carouselActions } = useCarousel(carousel);

  const type =
    carousel[carouselIndex] === "Absentee Owner" ? "absentee" : "occupied";
  return (
    <>
      <CarouselHeader title={carousel[carouselIndex]} {...carouselActions} />
      <div className="mt-4 mb-20 grid grid-cols-1 gap-4 md:grid-cols-2 lg:mb-0 lg:grid-cols-2 xl:grid-cols-3">
        {(templatePostcards || [])
          .filter((temp: TemplatePostcard) => temp.target === type)
          .map((template: TemplatePostcard) => (
            <div key={`$-temp-${template.id}`}>
              <TemplateHolder template={template} />
            </div>
          ))}
      </div>
    </>
  );
};

export default PostcardsTab;
