/* eslint-disable react-hooks/rules-of-hooks */
import { useFaye } from 'src/faye';
import useRealtimeSync from 'src/hooks/useRealtimeSync';

export const listenNewNotification = (teamID: number) => {
    const { syncNotificationUpdater } = useRealtimeSync();
    useFaye(
    {
      [`/${teamID}/notification`]: (channel: string, { notificationID }) => {
        syncNotificationUpdater()
      }
    },
    [teamID]
  );
};