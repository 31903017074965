import { RealeflowAccountDetails, Zones } from "src/interfaces/realeflow";
import ZoneBadge from "./ZoneBadge";

interface MyZonesProps {
  realeflowAccount: RealeflowAccountDetails;
  purchasedZones: Zones;
}

const MyZones = ({ realeflowAccount, purchasedZones }: MyZonesProps) => {
  return (
    <>
      <div className="w-full">
        <h1 className="font-semibold text-lg">Zipcodes</h1>
        {purchasedZones.zips.length > 0 ? (
          <div className="mt-4 flex w-full flex-col items-center justify-center">
            <div className=" w-1/2">
              <div className="flex w-full flex-row flex-wrap gap-1 rounded-lg ">
                {purchasedZones.zips.map((zone: string, index: number) => (
                  <ZoneBadge
                    key={`${index}-${zone}-my-zones`}
                    zone={zone === "*" ? "All Zipcodes" : zone}
                    index={index}
                    displayDollarSign={false}
                  />
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="mt-4 flex w-full flex-col items-center justify-center">
            <div className=" w-1/2">
              <div className="flex w-full justify-center items-center">
                <p className="text-zinc-400">No zones added</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyZones;
