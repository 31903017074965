import { useState, useEffect } from "react";
import AnimatedNumber from "react-awesome-animated-number";
import { subscriptionApi } from "src/api";
import useTeamContext from "src/hooks/private/useTeamContext";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import SubscriptionChoice from "../stripe/SubscriptionChoice";
import useSubscriptionContext from "src/hooks/useSubscription";
import ButtonLoader from "../loading/ButtonLoader";
import ButtonBlock from "./ButtonBlock";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

interface UpgradeModalProps {
  mustFullyUpgrade?: boolean;
  hideExplanation?: boolean;
  featureClicked?: string;
  defaultSub?: number;
}

const UpgradeModal = ({
  mustFullyUpgrade = false,
  hideExplanation = false,
  featureClicked,
  defaultSub,
}: UpgradeModalProps) => {
  const { fetchUserTeamData } = useTeamContext();

  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  const { appSubscriptionsAndFeatures } = useSubscriptionContext();

  const { subscription, isLoadingSubscription } =
    useCurrentSubscriptionContext();

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const [selectedSubscription, setSelectedSubscription] = useState<number>(
    defaultSub || 2
  );

  const [costToUpgrade, setCostToUpgrade] = useState<number>(0);

  const [isAnnual, setIsAnnual] = useState<boolean>(true);

  const [isCheckedNonrefundableAgreement, setIsCheckedNonrefundableAgreement] =
    useState<boolean>(false);

  const handleSelectSub = (subId: number) => {
    setSelectedSubscription(subId);
    // Calculate cost to upgrade
    let cost = 0;
    let startCounting = false;
    if (appSubscriptionsAndFeatures) {
      Object.values(appSubscriptionsAndFeatures).forEach((sub) => {
        if (sub.subscription.id === subscription?.id) {
          startCounting = true;
          return; // Skip the current subscription
        }

        if (startCounting) {
          cost += sub.subscription.upgradeCost;
        }

        if (sub.subscription.id === subId) {
          startCounting = false;
        }
      });
    }

    setCostToUpgrade(cost);
  };

  useEffect(() => {
    // Calculate initial cost to upgrade
    let cost = 0;
    let startCounting = false;
    if (appSubscriptionsAndFeatures) {
      Object.values(appSubscriptionsAndFeatures).forEach((sub) => {
        if (sub.subscription.id === subscription?.id) {
          startCounting = true;
          return; // Skip the current subscription
        }

        if (startCounting) {
          cost += sub.subscription.upgradeCost;
        }

        if (sub.subscription.id === selectedSubscription) {
          startCounting = false;
        }
      });
    }

    setCostToUpgrade(cost);
  }, [appSubscriptionsAndFeatures, subscription, selectedSubscription]);

  const handleSelectPeriod = (annual: boolean) => {
    setIsAnnual(annual);
  };

  const getSlugFromSub = (subID: number): string => {
    return "";
  };

  const handleSubmit = async () => {
    setOnSubmitting(true);
    try {
      let res = await subscriptionApi.changeSubscription({
        plan: getSlugFromSub(selectedSubscription),
        subscriptionID: selectedSubscription,
        isAnnual: isAnnual,
        costToChange: costToUpgrade,
        chargeImmediately: isAnnual,
        frontendOrigin: "pwa",
      });
      console.log(res);
      setAlert({
        type: "success",
        message: "Subscription updated successfully!",
        display: true,
      });
      fetchUserTeamData();
    } catch (error: any) {
      setAlert({
        type: "error",
        message: error?.message || "Error upgrading team subscription",
        display: true,
      });
    } finally {
      setOnSubmitting(false);
      setShowModal(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div>
        <div>
          {!hideExplanation && (
            <p className="mb-2 text-center text-text-dark dark:text-text-light">
              To use this feature please upgrade.
            </p>
          )}
          <div className="mb-4 flex items-center justify-center">
            {isLoadingSubscription ? (
              <ButtonLoader className="h-20 w-20" />
            ) : (
              <SubscriptionChoice
                isUpgrade={true}
                handleSelectSub={handleSelectSub}
                handleSelectPeriod={handleSelectPeriod}
                selectedSubscription={selectedSubscription}
                isAnnual={isAnnual}
              />
            )}
          </div>
        </div>
        <div>
          <div className="form-control mb-4">
            <label className="label flex cursor-pointer items-center justify-center gap-4">
              <input
                type="checkbox"
                checked={isCheckedNonrefundableAgreement}
                className="checkbox-secondary checkbox"
                onChange={() =>
                  setIsCheckedNonrefundableAgreement(
                    !isCheckedNonrefundableAgreement
                  )
                }
              />
              <span className="label-text text-text-dark dark:text-text-light">
                I understand subscriptions are non-refundable.
              </span>
            </label>
          </div>
        </div>
        <ButtonBlock
          handleSubmit={handleSubmit}
          submitting={onSubmitting}
          disabled={!isCheckedNonrefundableAgreement}
          submitLabel={
            <span className="inline-flex items-baseline whitespace-nowrap">
              <span className="text-[17px]">Buy $</span>
              <AnimatedNumber
                value={isAnnual ? 0 : costToUpgrade ? costToUpgrade : 0}
                duration={200}
                size={17}
              />
            </span>
          }
        />
      </div>
    </>
  );
};

export default UpgradeModal;
