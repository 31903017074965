import Property from "src/interfaces/property";
import useModal from "src/hooks/useModal";
import propertyApi from "src/api/property";
import useAlert from "src/hooks/useAlert";
import usePropertiesContext from "src/hooks/private/usePropertiesContext";
import useDrawer from "src/hooks/private/useDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

interface DeletePropertyProps {
  property: Property;
}

const DeleteProperty = ({ property }: DeletePropertyProps) => {
  const { setShowModal, openModalWith } = useModal();

  const { showPropertyDrawer, setShowPropertyDrawer } = useDrawer();

  const { fetchProperties } = usePropertiesContext();

  const { setAlert } = useAlert();

  const onDeleteSubmit = async () => {
    setShowModal(false);
    await propertyApi.updatePropertyIsActive(property.id, false);
    setAlert({
      display: true,
      message: "Property moved to trash",
      type: "success",
    });
    showPropertyDrawer && setShowPropertyDrawer(false);
    fetchProperties();
  };

  const handleDelete = () => {
    setShowPropertyDrawer(false);
    openModalWith({
      title: "Delete Property",
      body: (
        <div className="text-center">
          <p className="text-text-dark dark:text-text-light">
            Are you sure you want to delete this property?
          </p>
        </div>
      ),
      submitLabel: "Delete",
      submitIcon: faTrashCan,
      onSubmit: onDeleteSubmit,
      helpTitle: "Deleting a Property",
      helpBody: (
        <p>
          This will remove a property from your team's Properties tab. If an
          action has been performed on the property it will still be viewable
          and restorable from the Transactions page.
        </p>
      ),
    });
  };

  return (
    <>
      <button
        className="btn btn-outline btn-sm btn-error w-full flex items-center justify-center gap-2"
        onClick={handleDelete}
      >
        <FontAwesomeIcon
          icon={faTrashCan}
          className=""
          />
        <p className="">
          Delete Property
          </p>
      </button>
    </>
  );
};

export default DeleteProperty;
