import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { affiliateApi, subscriptionApi } from "src/api";
import { VerifyAffiliate } from "src/interfaces/affiliate";
import { VerifySubscription } from "src/interfaces/subscriptions";

const useRegistrationGuard = () => {
  const location = useLocation();

  const [queryParams, setQueryParams] = useState<{ [key: string]: string }>({});

  const [onCheckingReferral, setOnCheckingReferral] = useState<boolean>(false);
  const [onCheckingSubscription, setOnCheckingSubscription] =
    useState<boolean>(false);
  const [referralID, setReferralID] = useState<number>();
  const [subID, setSubID] = useState<number>();
  const [redirection, setRedirectTo] = useState<string>("");

  const parseQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    const result: { [key: string]: string } = {};
    params.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  };

  const verifyAffiliateID = useCallback(async (affiliateID?: string) => {
    if (!affiliateID) return;

    setOnCheckingReferral(true);
    try {
      const res: VerifyAffiliate = await affiliateApi.verifyReferralID(
        affiliateID
      );
      if (res.isVerified) {
        setReferralID(res.affiliate.id);
        localStorage.setItem("affiliateID", res.affiliate.id.toString());
        setRedirectTo("/register");
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOnCheckingReferral(false);
    }
  }, []);

  const verifySubscriptionID = useCallback(async (subscriptionID?: string) => {
    if (!subscriptionID) return;

    setOnCheckingSubscription(true);
    try {
      const res: VerifySubscription =
        await subscriptionApi.verifySubscriptionID(subscriptionID);
      console.log({res})
      if (res.isVerified) {
        setSubID(res.subscription.id);
        localStorage.setItem("subID", res.subscription.id.toString());
        setRedirectTo("/register");
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOnCheckingSubscription(false);
    }
  }, []);

  useEffect(() => {
    const params = parseQueryParams(location.search);
    setQueryParams(params);
    if (params.affiliateID) {
      verifyAffiliateID(params.affiliateID);
    }
    if (params.subID) {
      verifySubscriptionID(params.subID);
    }
  }, [location.search, verifyAffiliateID, verifySubscriptionID]);

  return {
    queryParams,
    referralID,
    subID,
    onCheckingReferral,
    onCheckingSubscription,
    redirection,
  };
};

export default useRegistrationGuard;
