import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import {  faTrashCan } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import CircularActionButton from "src/components/buttons/CircularActionButton";
import UserAvatar from "src/components/buttons/UserAvatar";
import { Campaign } from "src/interfaces/campaign";
import useViewport from "src/hooks/useViewport";
import ToggleDrawer from "src/components/buttons/ToggleDrawer";
import CampaignsDrawer from "./CampaignsDrawer";
import { capitalize } from "src/helpers/parseStrings";
import useModal from "src/hooks/useModal";
import useCampaignContext from "src/hooks/private/useCampaignContext";
import useAlert from "src/hooks/useAlert";
import { campaignApi } from "src/api";
import { useNavigate } from "react-router-dom";

interface CampaignsRowProps {
  campaign: Campaign;
  numRows: number;
  index: number;
}

const CampaignsRow = ({ campaign, numRows, index }: CampaignsRowProps) => {
  const { isMobile } = useViewport();

  const { openModalWith, setShowModal } = useModal();

  const { setAlert } = useAlert();

  const { fetchCampaigns } = useCampaignContext();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const navigate = useNavigate();

  const handleDeleteSubmit = async () => {
    try {
      await campaignApi.delete(campaign.id);
      setAlert({
        display: true,
        type: "success",
        message: `Campaign: ${campaign.name} was deleted`,
      });
    } catch (e: any) {
      setAlert({
        display: true,
        type: "error",
        message: e.message,
      });
    } finally {
      setShowModal(false);
      fetchCampaigns();
    }
  };

  const handleEditCampaign = () => {
    return navigate(`/sequences/campaigns/edit/${campaign.id}`);
  };

  const handleDeleteCampaign = () => {
    openModalWith({
      title: "Delete Campaign",
      body: (
        <p>
          Are you sure you want to delete the campaign:
          <span className="font-semibold">{" "}{campaign.name}</span>
          {"?"}
        </p>
      ),
      submitLabel: "Delete",
      submitIcon: faTrashCan,
      onSubmit: handleDeleteSubmit,
    });
  };

  const isFinalRow = numRows === index + 1;

  return (
    <>
      <tr
        className={
          isFinalRow
            ? "relative border-b-0 bg-card-light text-xs text-text-dark dark:bg-card-dark dark:text-text-light sm:text-base"
            : "relative bg-card-light text-xs text-text-dark dark:bg-card-dark dark:text-text-light sm:text-base"
        }
      >
        <td
          className={
            isFinalRow
              ? "cursor-pointer rounded-bl-lg bg-card-light text-center font-semibold dark:bg-card-dark"
              : "cursor-pointer bg-card-light text-center font-semibold dark:bg-card-dark"
          }
        >
          <div>{campaign.name}</div>
          <div className="badge badge-secondary badge-outline ">
            {capitalize(campaign.target)}
          </div>
        </td>
        <td className="bg-card-light text-center dark:bg-card-dark">
          <span className="text-xs sm:text-sm">
            {campaign.quantity} mailing every {campaign.cadence} days
          </span>
        </td>
        {!isMobile && (
          <td className="bg-card-light text-center dark:bg-card-dark">
            <div className="flex w-full items-center justify-center">
              <UserAvatar userID={campaign.userID} />
            </div>
          </td>
        )}

        <td
          className={
            isFinalRow ? "rounded-br-lg text-center " : " text-center "
          }
        >
          <div className="flex items-center justify-end gap-1.5">
            <CircularActionButton
              icon={faPenToSquare}
              onClick={handleEditCampaign}
              tooltip="Edit Campaign"
            />
            <CircularActionButton
              icon={faTrashCan}
              onClick={handleDeleteCampaign}
              tooltip="Delete Campaign"
            />
            <ToggleDrawer
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              isToggled={isDrawerOpen}
            />
          </div>
        </td>
      </tr>
      <tr
        className={
          isDrawerOpen
            ? "relative transition-all duration-300 "
            : "absolute h-0 duration-300 "
        }
      >
        {isDrawerOpen && (
          <td
            colSpan={isMobile ? 3 : 6}
            className=" overflow-auto rounded-b-lg border-x bg-back-light dark:border-x dark:border-card-dark dark:bg-back-dark"
            style={{ maxHeight: "300px", overflow: "auto", maxWidth: "375px" }}
          >
            <CampaignsDrawer
              templateIDs={campaign.templateIDs}
              cadence={campaign.cadence}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default CampaignsRow;
