import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSwiper } from "swiper/react";

interface SwiperButtonProps {
  direction: "left" | "right";
}

const SwiperButton = ({ direction }: SwiperButtonProps) => {
  const swiper = useSwiper();

  const handleDirectionClick = () => {
    if (direction === "left") {
      if (swiper.activeIndex === 0) {
        swiper.slideTo(swiper.slides.length - 1);
      } else {
        swiper.slidePrev();
      }
    } else {
      if (swiper.activeIndex === swiper.slides.length - 1) {
        swiper.slideTo(0);
      } else {
        swiper.slideNext();
      }
    }
  };

  return (
    <>
      <button>
        <FontAwesomeIcon
          icon={direction === "left" ? faCaretLeft : faCaretRight}
          className="text-xxxl h-[20px] lg:h-[25px] rounded-md w-[40px] md:w-24 bg-secondary dark:bg-secondary text-text-light "
          onClick={handleDirectionClick}
        />
      </button>
    </>
  );
};

export default SwiperButton;
