/* eslint-disable import/no-anonymous-default-export */
import { ChartData } from 'chart.js';
import { AccountCount } from 'src/components/cards/AccountStats';
import { RuleOf56Count } from 'src/interfaces/ruleOf56';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class StatisticApi extends ApiTokenSupport {

  async getRuleOf56Count(): Promise<RuleOf56Count> {
    const resp = await axios.get<RuleOf56Count>(`/api/statistics/ruleof56`, this.withAuthorization());
    return resp.data;
  }

  async getActionStats(period: string, interval: number): Promise<ChartData<"bar">> {
    const resp = await axios.post<ChartData<"bar">>(`/api/statistics/actions-stats`, { period, interval }, this.withAuthorization());
    return resp.data;
  }

  async getAccountStats(): Promise<{ properties: AccountCount, contacted: AccountCount }> {
    const resp = await axios.get<{ properties: AccountCount, contacted: AccountCount }>(`/api/statistics/account-stats`, this.withAuthorization());
    return resp.data;
  }

}

export default new StatisticApi();
