import { Navigate, Outlet } from "react-router-dom";
import useTokenGuard from "../hooks/useTokenGuard";

const InvitationGuard = () => {
    const { token, onChecking, redirection } = useTokenGuard("invite-to-v2");

    if (onChecking) {
        return <div className='w-full justify-center flex'><span className='loading'>...</span></div>;
    }

    if (redirection && token) {
        return <Navigate to={redirection} />
    }

    return (<Outlet />)
}

export default InvitationGuard;