import React from "react";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useViewport from "src/hooks/useViewport";
import UserAvatar from "../buttons/UserAvatar";
import { SkipTraceContactButton } from "./SkipTraceResultsModal";

interface PhoneEmailResultsModalProps {
  callEmail: any;
}

const PhoneEmailResultsModal = ({ callEmail }: PhoneEmailResultsModalProps) => {
  const { isMobile } = useViewport();

  type ValidationResult = "phone" | "email" | "unknown";

  const validateInput = (input: string): ValidationResult => {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for validating a phone number of all numbers (allowing optional spaces or dashes)
    const phoneRegex = /^\d[\d\s-]*\d$/;

    if (emailRegex.test(input)) {
      return "email";
    } else if (phoneRegex.test(input)) {
      return "phone";
    } else {
      return "unknown";
    }
  };

  return (
    <>
      {callEmail && (
        <div className="w-full">
          <table
            className={
              isMobile
                ? "table-compact table block w-full shadow-xl"
                : "table block w-full shadow-xl"
            }
          >
            <thead>
              <tr className="border-b-2 border-secondary text-secondary">
                <td className="select-none rounded-tl-lg bg-card-light text-center text-xs dark:bg-card-dark">
                  Created
                </td>
                <td className="select-none bg-card-light text-center text-xs dark:bg-card-dark">
                  Teammate
                </td>
                <td className="select-none rounded-tr-lg bg-card-light text-center text-xs dark:bg-card-dark">
                  Phone/Email
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b-0">
                <td className="rounded-bl-lg bg-card-light text-center font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {parseMonthDayYear(callEmail.createdAt)}
                </td>
                <td className="bg-card-light text-center text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  <div className="flex items-center justify-center">
                    <UserAvatar isSmall={true} userID={callEmail.userID} />
                  </div>
                </td>
                <td className="rounded-br-lg bg-card-light text-center font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  {validateInput(callEmail.value) === "phone"
                    ? callEmail.value
                        .toString()
                        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
                    : callEmail.value}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default PhoneEmailResultsModal;
