import React from "react";
import { userApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useApp from "src/hooks/useAppContext";
import useModal from "src/hooks/useModal";
import { UserType } from "src/interfaces/user";

interface InviteToDashProps {
  teammate: UserType;
}

const InviteToDash = ({ teammate }: InviteToDashProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const { app } = useApp();

  const handleInviteToDash = async () => {
    try {
      const res = await userApi.inviteToDash(teammate.id);
      setAlert({
        display: true,
        type: "success",
        message: res,
      });
    } catch (err: any) {
      setAlert({
        display: true,
        type: "error",
        message:
          err?.message || `There was an error inviting this user to ${app?.dashName}`,
      });
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <button
        className="btn-outline btn-secondary btn-sm btn w-36 hover:text-text-light hover:dark:text-text-light"
        onClick={handleInviteToDash}
      >
        {/* <FontAwesomeIcon icon={faCar} /> */}
        <span className="ml-1">Invite To {app?.dashName}</span>
      </button>
    </>
  );
};

export default InviteToDash;
