import { useState } from "react";
import AnimatedNumber from "react-awesome-animated-number";
import { subscriptionApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useModal from "src/hooks/useModal";
import useTeamContext from "src/hooks/private/useTeamContext";
import FuseCoin from "../icons/FuseCoin";
import useApp from "src/hooks/useAppContext";
import ButtonBlock from "./ButtonBlock";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";

const PurchaseCoinsModal = () => {
  const [coinBlockAmountsToPurchase, setCoinBlockAmountsToPurchase] =
    useState<number>(1);

  const [onSubmitting, setOnSubmitting] = useState<boolean>(false);

  const { fetchUserTeamData } = useTeamContext();

  const { subscription } = useCurrentSubscriptionContext();

  const { app } = useApp();

  const { setAlert } = useAlert();

  const { setShowModal } = useModal();

  if (!subscription) {
    return <></>;
  }

  const { coinBlockSize, purchaseCoinCost } = subscription;

  const handleChange = (e: any) => {
    const { value } = e.target;
    setCoinBlockAmountsToPurchase(parseInt(value));
  };

  const onPurchaseCoinsSubmit = async () => {
    try {
      await subscriptionApi.purchaseCoins(
        coinBlockAmountsToPurchase * purchaseCoinCost * 100,
        coinBlockAmountsToPurchase
      );
      setAlert({
        type: "success",
        message: `${coinBlockAmountsToPurchase * 100} ${
          app?.coinName
        }  purchased successfully`,
        display: true,
      });
      fetchUserTeamData();
      setShowModal(false);
    } catch (err: any) {
      setAlert({
        type: "error",
        message: err?.message,
        display: true,
      });
    }
  };

  const handleSubmitWithLoader = async () => {
    setOnSubmitting(true);
    await onPurchaseCoinsSubmit();
    setOnSubmitting(false);
  };

  return (
    <>
      <div className="flex columns-1 flex-col items-center justify-center">
        <div className="mb-2 mt-0.5  flex h-12 cursor-pointer select-none items-center justify-center space-x-1 rounded-lg bg-card-light px-1.5 shadow-lg drop-shadow-lg dark:bg-back-dark">
          <FuseCoin />
          <span className="select-none text-sm font-extrabold text-text-dark dark:text-text-light">
            <AnimatedNumber
              className="mr-0.5 lg:mr-1"
              value={coinBlockAmountsToPurchase * coinBlockSize}
              size={14}
              duration={200}
            />
          </span>
        </div>
        <input
          type="range"
          min="1"
          max="10"
          value={coinBlockAmountsToPurchase}
          onChange={handleChange}
          className="range range-secondary mb-4"
        />
        <div className="mb-4 flex items-center justify-center gap-3">
          <button
            className="btn btn-sm gap-1 border-none bg-gray-300 text-text-dark hover:bg-gray-400 hover:bg-hover-card-light dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
            onClick={() => setCoinBlockAmountsToPurchase(2)}
          >
            {/* <FuseCoin /> */}
            {coinBlockSize * 2}
          </button>
          <button
            className="btn btn-sm gap-1 border-none bg-gray-300 text-text-dark hover:bg-gray-400 hover:bg-hover-card-light dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black"
            onClick={() => setCoinBlockAmountsToPurchase(4)}
          >
            {/* <FuseCoin /> */}
            {coinBlockSize * 4}
          </button>
          <button
            className="btn btn-sm gap-1 border-none bg-gray-300 text-text-dark hover:bg-gray-400 hover:bg-hover-card-light dark:bg-back-dark  dark:text-gray-500 hover:dark:bg-black "
            onClick={() => setCoinBlockAmountsToPurchase(8)}
          >
            {/* <FuseCoin /> */}
            {coinBlockSize * 8}
          </button>
        </div>

        <ButtonBlock
          submitLabel={
            <span className="text-sm">
              Buy $
              <AnimatedNumber
                className=""
                value={
                  coinBlockAmountsToPurchase * purchaseCoinCost * coinBlockSize
                }
                size={14}
                duration={200}
              />
            </span>
          }
          handleSubmit={handleSubmitWithLoader}
          submitting={onSubmitting}
        />
      </div>
    </>
  );
};

export default PurchaseCoinsModal;
