import React, { useEffect, useState } from "react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faBath,
  faBed,
  faBookmark,
  faRulerCombined,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Property from "src/interfaces/property";
import Tooltip from "src/components/tooltips/Tooltip";
import { propertyApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import useDrawer from "src/hooks/private/useDrawer";
import { shortenString } from "src/helpers/parseStrings";
import classNames from "classnames";
import UpdatePropertyStatus from "./UpdatePropertyStatus";
import useTheme from "src/hooks/useTheme";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";
import useViewport from "src/hooks/useViewport";
import CircularActionButton from "../buttons/CircularActionButton";

interface GalleryCardProps {
  property: Property;
}

const GalleryCard = React.memo(({ property }: GalleryCardProps) => {
  const { openPropertyDrawerWith } = useDrawer();

  const { setAlert } = useAlert();

  const { isMobile } = useViewport();

  const { tailwindTheme } = useTheme();

  const [_isWatchList, setIsWatchlist] = useState<boolean | undefined>(
    property?.isWatchList
  );

  const [isHoveringAddress, setIsHoveringAddress] = useState<boolean>(false);

  const handleIsWatchlist = async () => {
    try {
      const value = !_isWatchList;
      const result = await propertyApi.toggleWatchList(id, value);
      setIsWatchlist(result?.isWatchList);
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err || "Unable to toggle Favorite",
        type: "error",
      });
    }
  };

  useEffect(() => {
    setIsWatchlist(property?.isWatchList);
  }, [property?.isWatchList]);

  const {
    id,
    bed,
    bath,
    sqft,
    yearAppraised,
    appraisedValue,
    ownerOccupied,
    owner1,
    fullAddress,
  } = property;

  const handlePropertyClick = () => {
    openPropertyDrawerWith({
      property,
    });
  };

  const streetImg = propertyApi.propertyImg(id, "street");
  const imgClass = !!streetImg ? "object-cover" : "object-contain";

  return (
    <>
      <div className="card card-compact h-full w-full overflow-hidden rounded-lg bg-card-light shadow-sm dark:bg-card-dark">
        <div className="relative mb-1">
          <figure onClick={handlePropertyClick}>
            {/* set property dynamic image & alter text in image tag */}
            <img
              className={classNames(
                "h-[250px] w-full cursor-pointer",
                imgClass
              )}
              src={streetImg ?? "/assets/no_img.webp"}
              alt={fullAddress ?? "property"}
            />
          </figure>
          <div
            className="absolute -bottom-[27px] left-[10px] cursor-pointer p-1"
            data-tip
            data-for={"watchlist-button"}
            onClick={handleIsWatchlist}
          >
            <FontAwesomeIcon
              className={
                _isWatchList
                  ? "border-none text-xxxl text-secondary"
                  : "border-none text-xxxl text-gray-300 dark:text-stone-600"
              }
              icon={faBookmark as IconDefinition}
              border
            />
            <Tooltip
              id="watchlist-button"
              place="right"
              content={"Toggle Favorite"}
              backgroundColor={tailwindTheme.theme2}
            />
          </div>
          <div className="absolute -bottom-[20px] right-[12px]">
            <UpdatePropertyStatus propertyID={id} status={property.status} />
          </div>
        </div>
        <div className="card-body flex w-full flex-grow columns-1 flex-col gap-0 @container">
          <div
            className="flex items-start justify-start"
            onMouseEnter={() => setIsHoveringAddress(true)}
            onMouseLeave={() => setIsHoveringAddress(false)}
          >
            <h2
              className={
                fullAddress.length > 38
                  ? "card-title text-secondary @[8rem]:text-[0.25rem] @[9rem]:text-[0.3rem] @[10rem]:text-[0.35rem] @[11rem]:text-[0.37rem] @[12rem]:text-[0.45rem]  @[13rem]:text-[0.55rem] @[14rem]:text-[0.55rem]  @[15rem]:text-[.6rem]  @[16rem]:text-[.625rem] @[17rem]:text-[.65rem] @[18rem]:text-[.7rem] @[19rem]:text-[.75rem] @[20rem]:text-[.8rem] @[21rem]:text-[.85rem]"
                  : "card-title text-secondary @[8rem]:text-[0.25rem] @[9rem]:text-[0.3rem] @[10rem]:text-[0.35rem] @[11rem]:text-[0.37rem] @[12rem]:text-[0.45rem]  @[13rem]:text-[0.55rem] @[14rem]:text-[0.6rem]  @[15rem]:text-[.625rem]  @[16rem]:text-[.65rem] @[17rem]:text-[.70rem] @[18rem]:text-[.75rem] @[19rem]:text-[.85rem] @[20rem]:text-[.9rem] @[21rem]:text-[.95rem]"
              }
            >
              {fullAddress}
            </h2>
            {(isHoveringAddress || isMobile) && (
              <div className="flex items-center ml-0.5 mt-0.5">
                <CircularActionButton
                  icon={faCopy}
                  onClick={() => copyTextToClipboard(fullAddress)}
                  isSmall={true}
                />
              </div>
            )}
          </div>
          <div className="w-full">
            <h2 className="">
              <FontAwesomeIcon
                className="mr-1 text-text-dark dark:text-text-light"
                icon={faBed}
              />
              <span className="text-text-dark dark:text-text-light">
                {bed ? bed : "?"} Bed
              </span>
              <span className="mx-2 text-text-dark dark:text-text-light">
                |
              </span>{" "}
              <FontAwesomeIcon
                className="mr-1 text-text-dark dark:text-text-light"
                icon={faBath}
              />
              <span className="text-text-dark dark:text-text-light">
                {bath ? bath : "?"} Bath
              </span>
              <span className="mx-2 text-text-dark dark:text-text-light">
                |
              </span>{" "}
              <FontAwesomeIcon
                className="mr-1 text-text-dark dark:text-text-light"
                icon={faRulerCombined}
              />
              <span className="text-text-dark dark:text-text-light">
                {sqft
                  ? sqft.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  : "?"}{" "}
                ft<sup>2</sup>
              </span>
            </h2>
            <div>
              <p>
                <span className="font-bold text-text-dark dark:text-text-light">
                  Tax Assessed Value
                </span>
                <span className="font-bold text-text-dark dark:text-text-light">
                  {yearAppraised ? `(${yearAppraised}):` : ":"}
                </span>
                <span className="font-medium text-secondary"> $</span>
                <span className="font-extrabold text-secondary">
                  {appraisedValue
                    ? appraisedValue
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
              </p>
            </div>
            <div className="flex flex-wrap font-bold text-text-dark dark:text-text-light">
              <span className="font-bold text-text-dark dark:text-text-light ">
                {ownerOccupied ? "Occupied " : "Absentee "}Owner:
              </span>
              {owner1 && (
                <span className="ml-1 text-secondary">
                  {` ${shortenString(owner1, 23)}`}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default GalleryCard;
