/* eslint-disable import/no-anonymous-default-export */
import { SupportTicket } from 'src/interfaces/supportTicket';
import { FilterParams } from 'src/global/types';
import axios from 'src/utils/axios';
import ApiTokenSupport from './token.support';

class SupportTicketApi extends ApiTokenSupport {

  /**
   * Get support tickets
   * @returns 
   */
  async getSupportTickets(filter?: FilterParams): Promise<SupportTicket[]> {
    const resp = await axios.post<SupportTicket[]>(`/api/supportticket/filter`, { ...filter } , this.withAuthorization());
    return resp.data;
  }
  
  /**
   * Create support ticket
   * @param data 
   * @returns 
   */
  async createSupportTicket(data: any): Promise<SupportTicket> {
    const resp = await axios.post<SupportTicket>(`/api/supportticket/create`, data ,this.withAuthorization());
    return resp.data;
  }
  
  /**
   * delete support ticket
   * @param supportTicketID 
   * @returns 
   */
  async deleteSupportTicket(supportTicketID: number): Promise<boolean> {
    const resp = await axios.delete<boolean>(`/api/supportticket/${supportTicketID}`,this.withAuthorization());
    return resp.data;
  }
  
}

export default new SupportTicketApi();

