import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Property from "src/interfaces/property";
import { callsEmailsApi } from "src/api";
import useUserContext from "src/hooks/private/useUserContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import CircularActionButton from "../buttons/CircularActionButton";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import copyTextToClipboard from "src/helpers/copyTextToClipboard";

interface SkipTraceContactButtonProps {
  valueKey: "phone1" | "phone2" | "email1" | "email2" | "domain";
  value: string | number;
  propertyID: number;
}

export const SkipTraceContactButton = ({
  valueKey,
  value,
  propertyID,
}: SkipTraceContactButtonProps) => {
  const { user } = useUserContext();

  const { team } = useTeamContext();

  const { handleError } = useErrorHandler();

  const createHREF = () => {
    if (valueKey === "phone1" || valueKey === "phone2") {
      return `tel:${value}`;
    } else if (valueKey === "email1" || valueKey === "email2") {
      return `mailto:${value}`;
    } else {
      return `${value}`;
    }
  };

  const handleClick = async () => {
    if (valueKey === "domain") {
      return;
    }
    if (user?.id && team?.id) {
      try {
        await callsEmailsApi.createCallsEmails({
          userID: user?.id,
          teamID: team.id,
          propertyID: propertyID,
          phoneEmail: `${value}`,
        });
      } catch (err) {
        handleError(err);
      }
    }
  };

  return (
    <div className="flex gap-2">
      <a
        className={
          "btn btn-ghost btn-sm gap-1 border-none text-xs text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-back-dark xs:text-sm md:text-base"
        }
        target="_blank"
        rel="noreferrer"
        href={createHREF()}
        onClick={handleClick}
      >
        <FontAwesomeIcon
          icon={
            valueKey === "phone1" || valueKey === "phone2"
              ? faPhone
              : faEnvelope
          }
          className="text-xs xs:text-sm md:text-base"
        />
        <p>
          {valueKey === "phone1" || valueKey === "phone2"
            ? value.toString().replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
            : value.toString()}
        </p>
      </a>
      <CircularActionButton
        icon={faCopy}
        onClick={() => copyTextToClipboard(value.toString())}
      />
    </div>
  );
};

interface SkipTraceResultsProps {
  property: Property;
}

const SkipTraceResults = ({ property }: SkipTraceResultsProps) => {
  const { id, phone1, phone2, email1, email2, domain, owner1, owner2 } =
    property;

  return (
    <>
      {phone1 || phone2 || email1 || email2 ? (
        <div className="flex w-full columns-1 flex-col items-center justify-center gap-1 border-b-0 bg-back-light px-0 dark:bg-base-100 sm:px-12">
          <div className="p-2">
            <div className="p-2 rounded-t-lg bg-card-light dark:bg-card-dark">
              <div className="text-xs font-bold md:text-base ">{owner1}</div>
            </div>
            <div className=" p-2 flex items-center gap-2 rounded-b-lg bg-card-light dark:bg-card-dark">
              {phone1 && (
                <SkipTraceContactButton
                  valueKey={"phone1"}
                  value={phone1}
                  propertyID={id}
                />
              )}
              {email1 && (
                <SkipTraceContactButton
                  valueKey={"email1"}
                  value={email1}
                  propertyID={id}
                />
              )}
              {domain && (
                <SkipTraceContactButton
                  valueKey={"email1"}
                  value={domain}
                  propertyID={id}
                />
              )}
            </div>
          </div>
          {(phone2?.toString().length || email2?.length) &&
          phone1 !== phone2 ? (
            <div>
              <div className="p-2 rounded-t-lg bg-card-light dark:bg-card-dark">
                <div className="text-xs font-bold md:text-base">{owner2}</div>
              </div>
              <div className="p-2 flex items-center gap-2 rounded-b-lg bg-card-light dark:bg-card-dark">
                {phone2?.toString().length && (
                  <SkipTraceContactButton
                    valueKey={"phone1"}
                    value={phone2}
                    propertyID={id}
                  />
                )}
                {email2?.length && (
                  <SkipTraceContactButton
                    valueKey={"email1"}
                    value={email2}
                    propertyID={id}
                  />
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <p className="font-medium">
          Unable to find any Skip Trace information for this property.
        </p>
      )}
    </>
  );
};

export default SkipTraceResults;
