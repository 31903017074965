import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalize } from "src/helpers/parseStrings";
import { useSwiper } from "swiper/react";

interface SwiperTitleDropdownProps {
  title: string;
  slides: any;
  icon?: IconProp;
}

const SwiperTitleDropdown = ({
  title,
  slides,
  icon,
}: SwiperTitleDropdownProps) => {
  const swiper = useSwiper();

  const handleClick = (index: number) => {
    swiper.slideTo(index);
  };

  return (
    <>
      <div className="dropdown flex columns-1 flex-col items-center ">
        <label
          tabIndex={0}
          className="btn btn-secondary btn-xs m-1 text-center text-xl font-bold text-text-light"
        >
          {icon && <FontAwesomeIcon icon={icon} className={"w-4 text-sm"} />}
          {capitalize(title)}
        </label>
        <ul
          tabIndex={0}
          className="menu dropdown-content top-[45px] z-[100] w-52 overflow-visible rounded-box bg-card-light p-2 text-center shadow-2xl drop-shadow-2xl dark:bg-card-dark"
        >
          {slides.map((slide: any, index: number) => (
            <li key={index} className="">
              <button
                className={title === slide.title ? "btn btn-ghost flex items-center justify-start text-secondary hover:bg-hover-card-light dark:hover:bg-hover-card-dark" : "btn btn-ghost flex items-center justify-start text-text-dark hover:bg-hover-card-light dark:text-text-light dark:hover:bg-hover-card-dark"}
                onMouseDown={() => handleClick(index)}
              >
                {slide.icon && (
                  <FontAwesomeIcon icon={slide.icon} className={"w-4"} />
                )}
                {slide.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default SwiperTitleDropdown;
