import { useContext } from "react";
import CurrentSubscriptionContext from "./CurrentSubscriptionContext";

const useCurrentSubscription = () => {
  const context = useContext(CurrentSubscriptionContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentSubscription must be used within a CurrentSubscriptionProvider"
    );
  }
  return context;
};

export default useCurrentSubscription;
