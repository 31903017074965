import { useState } from "react";
import CampaignsTab from "src/components/sequences/campaigns/CampaignsTab";
import LettersTab from "src/components/sequences/LettersTab";
import PostcardsTab from "src/components/sequences/PostcardsTab";
import SequencesTab from "src/components/sequences/SequencesTab";
import SequencesToolbar from "src/components/sequences/SequencesToolbar";

export interface ActiveSequenceTabs {
  sequences: boolean;
  postcards: boolean;
  letters: boolean;
  campaigns: boolean;
}

interface SequencesProps {
  activeTab?: "sequences" | "postcards" | "letters" | "campaigns";
}

const Sequences = ({ activeTab = "sequences" }: SequencesProps) => {
  const initialTabsState = {
    sequences: activeTab === "sequences",
    postcards: activeTab === "postcards",
    letters: activeTab === "letters",
    campaigns: activeTab === "campaigns",
  };

  const [activeTabs, setActiveTabs] = useState(initialTabsState);

  return (
    <>
      <div className="mb-1">
        <SequencesToolbar
          activeTabs={activeTabs}
          setActiveTabs={setActiveTabs}
        />
      </div>
      <div className="bg-back-light dark:bg-back-dark">
        {activeTabs.sequences && <SequencesTab />}
        {activeTabs.postcards && <PostcardsTab />}
        {activeTabs.letters && <LettersTab />}
        {activeTabs.campaigns && <CampaignsTab />}
      </div>
    </>
  );
};

export default Sequences;
