import React, { useState } from "react";
import { faCartShopping } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimatedNumber from "react-awesome-animated-number";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { useSwipe } from "src/hooks/useSwipe";
import CartDrawer from "./CartDrawer";
import CartFooter from "./CartFooter";
import MarketTooltip from "../MarketTooltip";

interface CartBalanceProps {
  props?: any;
}

const CartBalance: React.FC<CartBalanceProps> = ({ props }) => {
  const cartState = useSelector((state: RootState) => state.cart);

  const [cartDrawerOpen, setCartDrawerOpen] = useState<boolean>(false);

  const numItems =
    cartState.offers.length +
    cartState.debtStacks.length +
    cartState.proofOfFunds.length;

  useSwipe({
    onSwipeRight: () => setCartDrawerOpen(false),
    onSwipeLeft: () => {},
    swipeThreshold: 90,
  });

  return (
    <>
      <div
        className="btn btn-ghost relative hover:bg-hover-card-light dark:hover:bg-hover-card-dark"
        onClick={() => setCartDrawerOpen(true)}
        data-tip
        data-for={"cart-market-button"}
      >
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faCartShopping}
            className="text-[20px] text-secondary"
          />
          <MarketTooltip id={`cart-market-button`} content={"Cart"} />

          {numItems > 0 && (
            <div className="absolute right-0 top-0 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-accent">
              <AnimatedNumber
                value={numItems}
                duration={300}
                className="text-white dark:text-black"
                style={{
                  fontSize: 12,
                  fontWeight: "bold",
                  color: "#fff",
                  marginLeft: 0,
                }}
              />
            </div>
          )}
        </div>
      </div>
      {cartDrawerOpen && (
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.55)",
          }}
          onClick={() => setCartDrawerOpen(!cartDrawerOpen)}
          className={
            cartDrawerOpen
              ? "absolute bottom-0 left-0 top-0 z-40 min-h-screen w-full transition-all duration-300"
              : "absolute bottom-0 left-0 top-0 z-40 min-h-screen w-full transition-all duration-300"
          }
        ></div>
      )}
      <div
        id="menu"
        className={
          cartDrawerOpen
            ? "absolute bottom-0 right-0 top-0 z-40 -mr-0 min-h-screen w-10/12 bg-back-light duration-300 dark:bg-back-dark xs:w-9/12 sm:w-2/3 md:w-1/2 lg:w-5/12 xl:w-1/3 "
            : "absolute bottom-0 right-0 top-0 z-40 -mr-[1000px] min-h-screen bg-back-light duration-300 dark:bg-back-dark xs:w-9/12 sm:w-2/3 md:w-1/2 lg:w-5/12 xl:w-1/3"
        }
      >
        <nav className=" relative bottom-0  top-0 h-screen w-full bg-back-light px-2 py-4 dark:bg-back-dark xs:px-1">
          <CartDrawer />
          <CartFooter setCartDrawerOpen={setCartDrawerOpen} />
        </nav>
      </div>
    </>
  );
};

export default CartBalance;
