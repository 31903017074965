import ReactTooltip, { Place } from "react-tooltip";
import useUserContext from "src/hooks/private/useUserContext";
import useTheme from "src/hooks/useTheme";
import useViewport from "src/hooks/useViewport";

interface TooltipProps {
  id: string;
  place?: Place;
  backgroundColor?: string;
  content: any;
  overRideDisable?: boolean;
}

const Tooltip = ({
  id,
  place,
  backgroundColor,
  content,
  overRideDisable,
}: TooltipProps) => {
  const { isMobile } = useViewport();

  const { userSettings } = useUserContext();

  const { mode } = useTheme();

  const defaultPlace: Place = "top";

  return (
    <>
      <ReactTooltip
        id={id}
        place={place || defaultPlace}
        effect="solid"
        disable={
          isMobile
            ? true
            : overRideDisable
            ? false
            : userSettings?.isTooltips
            ? false
            : true
        }
        backgroundColor={mode === "dark" ? "white" : "black"}
        textColor={mode === "dark" ? "black" : "white"}
        className="font-semibold opacity-5"
      >
        {content}
      </ReactTooltip>
    </>
  );
};

export default Tooltip;
