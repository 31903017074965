/* eslint-disable import/no-anonymous-default-export */
import { Watchlist } from "src/interfaces/watchlist";
import { FilterParams } from "src/global/types";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class WatchlistApi extends ApiTokenSupport {
  async getWatchlist(filter?: FilterParams): Promise<Watchlist[]> {
    const resp = await axios.post<Watchlist[]>(
      `/api/watchlist/filter`,
      { ...filter },
      this.withAuthorization()
    );
    return resp.data;
  }

  async createWatchlist(data: any): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/watchlist`,
      { ...data },
      this.withAuthorization()
    );
    return resp.data;
  }

  async deleteWatchlist(watchlistID: number): Promise<boolean> {
    const resp = await axios.delete<boolean>(
      `/api/watchlist/${watchlistID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new WatchlistApi();
