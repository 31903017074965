import React from "react";
import { faExclamation, faGear } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "src/redux/store";
import MarketTooltip from "../MarketTooltip";

interface SettingsButtonProps {
  props?: any;
}

const SettingsButton = ({ props }: SettingsButtonProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/market/settings");
  };

  const marketSettings = useSelector(
    (state: RootState) => state.marketOrders.marketSettings
  );

  const isAllRequiredMarketSettingsFilled = () => {
    return (
      marketSettings.name?.trim() !== "" &&
      marketSettings.email?.trim() !== "" &&
      marketSettings.phone?.trim() !== "" &&
      marketSettings.address !== null
    );
  };

  return (
    <>
      <button
        onClick={handleClick}
        className="btn btn-ghost relative hover:bg-hover-card-light hover:dark:bg-hover-card-dark"
        data-tip
        data-for={"user-settings-market-button"}
      >
        {!isAllRequiredMarketSettingsFilled() && (
          <div className="absolute right-0 top-0 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-accent">
            <FontAwesomeIcon icon={faExclamation} className="text-white" />
          </div>
        )}
        <FontAwesomeIcon icon={faGear} className="text-[20px] text-secondary" />
        <MarketTooltip
          id={`user-settings-market-button`}
          content={"User Settings"}
        />
      </button>
    </>
  );
};

export default SettingsButton;
