export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const userNotificationSettingList = [
  "researches",
  "debtStack",
  "skipTraces",
  "sequences",
  "offers",
  "proofOfFunds",
  "drives",
  "mail",
  "watchlist",
  "campaigns",
];

export const leaderExtraNotificationSettingList = ["invites", "purchases"];

export const keyEvents: { [key: string]: string } = {
  "researches": "Research",
  "debtStack": "Debt Stack",
  "skipTraces": "Skip Trace",
  "sequences": "Sequence",
  "offers": "Offer",
  "drives": "Drive",
  "invites": "Invitation",
  "purchases": "Coins",
  "mail": "Mail",
  "watchlist": "Watchlist",
  "campaigns": "Campaign",
  "proofOfFunds" : "Proof Of Funds"
}

export const eventRoutes: { [key: string]: string } = {
  "researches": "/properties?",
  "debtStack": "/properties?sl=2&",
  "skipTraces": "/properties?",
  "sequences": "/properties?sl=3&",
  "offers": "/properties?sl=4&",
  "drives": "",
  "invites": "/team",
  "purchases": "/transactions",
  "postcards": "sequences",
  "campaigns": "/sequences/campaigns",
  "proofOfFunds": "/brokers",
}