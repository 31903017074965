import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { propertyApi } from "src/api";
import { Loan } from "src/interfaces/loan";
import useUserContext from "src/hooks/private/useUserContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface LoanContextInterface {
  loans: Loan[];
  fetchLoans: () => void;
}

const loanContextDefaults: LoanContextInterface = {
  loans: [],
  fetchLoans: () => {},
};

const LoanContext =
  React.createContext<LoanContextInterface>(loanContextDefaults);

interface LoanProviderProps {}

export const LoanProvider = ({
  children,
}: PropsWithChildren<LoanProviderProps>) => {
  const [loans, setLoans] = useState<Loan[]>([]);

  const { user } = useUserContext();

  const { handleError } = useErrorHandler();

  const fetchLoans = useCallback(async () => {
    if (user) {
      try {
        const loans = await propertyApi.getLoans();
        setLoans(loans);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  useEffect(() => {
    fetchLoans();
  }, [fetchLoans]);

  return (
    <LoanContext.Provider
      value={{
        loans,
        fetchLoans,
      }}
    >
      {children}
    </LoanContext.Provider>
  );
};

export default LoanContext;
