import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { realeflowApi } from "src/api";
import { RealeflowAccountDetails } from "src/interfaces/realeflow";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import useTeamContext from "src/hooks/private/useTeamContext";

interface RealeflowContextInterface {
  realeflowAccount: RealeflowAccountDetails | undefined | null;
  fetchRealeflowAccount: () => void;
  isLoading: boolean;
}

const RealeflowContextDefaults: RealeflowContextInterface = {
  realeflowAccount: null,
  fetchRealeflowAccount: () => {},
  isLoading: false,
};

const RealeflowContext = createContext<RealeflowContextInterface>(
  RealeflowContextDefaults
);

interface RealeflowProviderProps {}

export const RealeflowProvider = ({
  children,
}: PropsWithChildren<RealeflowProviderProps>) => {
  const [realeflowAccount, setRealeflowAccount] =
    useState<RealeflowAccountDetails>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { team } = useTeamContext();

  const { handleError } = useErrorHandler();

  const fetchRealeflowAccount = useCallback(async () => {
    if (team) {
      try {
        setIsLoading(true);
        const account = await realeflowApi.getRealeflowAccountDetails({
          AccountId: team.realeflowID,
        });
        setRealeflowAccount(account);
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoading(false);
      }
    }
  }, [handleError, team]);

  useEffect(() => {
    fetchRealeflowAccount();
  }, [fetchRealeflowAccount]);

  return (
    <RealeflowContext.Provider
      value={{
        realeflowAccount,
        fetchRealeflowAccount,
        isLoading,
      }}
    >
      {children}
    </RealeflowContext.Provider>
  );
};

export default RealeflowContext;
