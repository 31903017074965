import React from "react";
import useWatchlist from "src/hooks/private/useWatchlist";
import useViewport from "src/hooks/useViewport";
import { Watchlist as WatchlistType } from "src/interfaces/watchlist";
import useUserContext from "src/hooks/private/useUserContext";

import WatchlistBirddogRow from "./WatchlistBirddogRow";

const WatchlistBirddog = () => {
  const { watchlist } = useWatchlist();

  const { isLayoutChange } = useViewport();

  const { width } = useViewport();

  const { user } = useUserContext();

  const birddogsWatchlist = watchlist?.filter(
    (item: any) => item.userID === user?.id
  );

  return (
    <>
      <div>
        <table
          className={
            isLayoutChange
              ? "table-compact table block w-full shadow-xl"
              : "table block w-full shadow-xl"
          }
        >
          <thead>
            <tr className="sticky top-0 border-separate border-b-2 border-secondary text-secondary ">
              {width > 440 && (
                <th className="sticky top-[115px] select-none rounded-tl-lg bg-card-light text-center  text-xxs dark:bg-card-dark md:text-base lg:top-0">
                  Created
                </th>
              )}
              <th
                className={`sticky top-[115px] select-none bg-card-light text-left text-xxs dark:bg-card-dark md:text-base lg:top-0 ${
                  width < 440 ? "rounded-tl-lg" : ""
                }`}
              >
                Address
              </th>
              <th className="sticky top-[115px] select-none rounded-tr-lg bg-card-light text-center  text-xxs dark:bg-card-dark md:text-base lg:top-0">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="">
            {birddogsWatchlist?.length ? (
              birddogsWatchlist?.map(
                (address: WatchlistType, index: number) => (
                  <WatchlistBirddogRow
                    address={address}
                    key={address.id}
                    numRows={birddogsWatchlist.length}
                    index={index}
                  />
                )
              )
            ) : (
              <tr>
                <td
                  className="rounded-b-lg bg-card-light text-center text-xs font-semibold text-text-dark dark:bg-card-dark dark:text-text-light xs:text-sm sm:text-base"
                  colSpan={4}
                >
                  No Watchlist Items Yet
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WatchlistBirddog;
