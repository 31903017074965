export const themes = [
  {
    name: "legacy",
    daisyUI: ["#0f639c", "#ef4444", "#55caa5"],
    tailwind: {
      "back-light": "#EDEDED",
      "back-dark": "#1b2225",
      "card-light": "#ffffffff",
      "card-dark": "#273135",
      "hover-back-light":   "#dedede",
      "hover-back-dark":  "#283236",
      "hover-card-light":  "#e5e7ea",
      "hover-card-dark":   "#2e3a3f",
      "theme1": "#59a2d3",
      "theme2": "#55caa5",
      "theme3": "#f37878",
      "theme4": "#ffa75a",
      "theme5": "#a850b0",
      "gradient1": "#0f639c",
      "gradient2": "#699bc5",
      "gradient3": "#699bc5",
      "gradient4": "#55caa5",
      "icon-light": "#8C8C8C",
      "icon-dark": "#C4C4C4",
      "text-light": "#ffffff",
      "text-dark": "#000000",
      "text-neutral-light": "#8c8c8c",
      "text-neutral-dark": "#CCD0D1",
      "title-text-light": "#7E7D85",
      "title-text-dark": "#ABA9B4",
      "info": "#3ABFF8",
      "success": "#36D399",
      "warning": "#FBBD23",
      "error": "#F87272",
    }
  },
  {
    name: "crush",
    daisyUI: ["#5D7CEA", "#7760F0", "#F9ACBF"],
    tailwind: {
      "back-light": "#F0F3FB",
      "back-dark": "#1E2B30",
      "card-light": "#FFFFFF",
      "card-dark": "#2A3C47",
      "hover-back-light":  "#dde3f1",
      "hover-back-dark":  "#383b43",
      "hover-card-light":  "#e5e8f1",
      "hover-card-dark":  "#3d4c53",
      "theme1": "#3da3a8",
      "theme2": "#7760F0",
      "theme3": "#F9ACBF",
      "theme4": "#BA44AE",
      "theme5": "#5D7CEA",
      "gradient1": "#5d7cea",
      "gradient2": "#F9ACBF",
      "gradient3": "#2597e8",
      "gradient4": "#5F2BCE",
      "icon-light": "#8C8C8C",
      "icon-dark": "#C4C4C4",
      "text-light": "#ffffff",
      "text-dark": "#000000",
      "text-neutral-light": "#CCD0D1",
      "text-neutral-dark": "#CCD0D1",
      "title-text-light": "#7E7D85",
      "title-text-dark": "#ABA9B4",
      "info": "#3ABFF8",
      "success": "#36D399",
      "warning": "#FBBD23",
      "error": "#F87272",
    }
  },
  // {
  //   name: "synthwave",
  //   daisyUI: ["#E779c1", "#57C7F2", "#F3CB30"],
  //   tailwind: {
  //     "back-light": "#fdf3f9",
  //     "back-dark": "#2C1B69",
  //     "card-light": "#FFFFFF",
  //     "card-dark": "#28185C",
  //     "hover-back-light":  "#dde3f1",
  //     "hover-back-dark":  "#383b43",
  //     "hover-card-light":  "#e5e8f1",
  //     "hover-card-dark":  "#3d4c53",
  //     "theme1": "#98F9FF",
  //     "theme2": "#7760F0",
  //     "theme3": "#F3CB30",
  //     "theme4": "#BA44AE",
  //     "theme5": "#5D7CEA",
  //     "gradient1": "#E779c1",
  //     "gradient2": "#57C7F2",
  //     "gradient3": "#2597e8",
  //     "gradient4": "#5F2BCE",
  //     "icon-light": "#8C8C8C",
  //     "icon-dark": "#C4C4C4",
  //     "text-light": "#ffffff",
  //     "text-dark": "#000000",
  //     "title-text-light": "#7E7D85",
  //     "title-text-dark": "#ABA9B4",
  //     "info": "#3ABFF8",
  //     "success": "#36D399",
  //     "warning": "#FBBD23",
  //     "error": "#F87272",
  //   }
  // },
  // {
  //   name: "aqua",
  //   daisyUI: ["#05E9F1", "#966FB3", "#FEE999"],
  //   tailwind: {
  //     "back-light": "#fdf3f9",
  //     "back-dark": "#355CA8",
  //     "card-light": "#FFFFFF",
  //     "card-dark": "#2F5498",
  //     "hover-back-light":  "#dde3f1",
  //     "hover-back-dark":  "#383b43",
  //     "hover-card-light":  "#e5e8f1",
  //     "hover-card-dark":  "#3d4c53",
  //     "theme1": "#05E9F1",
  //     "theme2": "#966FB3",
  //     "theme3": "#FEE999",
  //     "theme4": "#BA44AE",
  //     "theme5": "#5D7CEA",
  //     "gradient1": "#E779c1",
  //     "gradient2": "#57C7F2",
  //     "gradient3": "#2597e8",
  //     "gradient4": "#5F2BCE",
  //     "icon-light": "#8C8C8C",
  //     "icon-dark": "#C4C4C4",
  //     "text-light": "#ffffff",
  //     "text-dark": "#000000",
  //     "title-text-light": "#7E7D85",
  //     "title-text-dark": "#ABA9B4",
  //     "info": "#3ABFF8",
  //     "success": "#36D399",
  //     "warning": "#FBBD23",
  //     "error": "#F87272",
  //   }
  // },
];


export interface Theme {
  name: string;
  daisyUI: string[];
  tailwind: any;
}