import React from "react";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppSubscriptionsAndFeatures } from "src/interfaces/subscriptions";
import SubFeatures from "./SubFeatures";
import CoinDisplay from "./CoinDisplay";

interface SubHolderProps {
  sub: AppSubscriptionsAndFeatures;
  isSelected: boolean;
  isAnnual: boolean;
  handleSelectSub: (subId: number) => void;
  handleSelectPeriod: (annual: boolean) => void;
  currentSub?: boolean;
  isUpgrade?: boolean;
  lightModeOnly?: boolean;
}

const SubHolder: React.FC<SubHolderProps> = ({
  sub,
  isSelected,
  isAnnual,
  handleSelectSub,
  handleSelectPeriod,
  currentSub,
  isUpgrade,
  lightModeOnly,
}) => {
  const { subscription, subscriptionFeatures } = sub;

  const selectedClass = isSelected
    ? `relative h-[540px] w-64 select-none rounded-lg border-4 border-secondary bg-card-light shadow-xl ${
        lightModeOnly ? "" : "dark:bg-card-dark"
      }`
    : `relative h-[540px] w-64 cursor-pointer select-none rounded-lg border-4 border-card-light bg-card-light shadow-xl ${
        lightModeOnly ? "" : "dark:bg-card-dark dark:border-back-dark"
      }`;

  const cardBodyClass = `card-body relative flex h-full columns-1 flex-col justify-between rounded-md bg-card-light pt-0 text-center ${
    lightModeOnly ? "" : "dark:bg-back-dark"
  }`;

  return (
    <div
      className={selectedClass}
      onClick={() => handleSelectSub(subscription.id)}
    >
      <div className="group card relative h-full rounded-lg drop-shadow-xl">
        {currentSub && (
          <div className="relative flex w-full items-center justify-center">
            <div className="btn btn-secondary btn-xs absolute -top-[8px] z-50 w-2/3 normal-case text-text-light drop-shadow-xl">
              Current Subscription
            </div>
          </div>
        )}
        <div className={cardBodyClass}>
          <div>
            <h2 className="card-title mt-2 flex h-20 items-center justify-center bg-gradient-to-tr from-gradient1 to-gradient2 bg-clip-text text-xxxl font-bold text-transparent opacity-85">
              {subscription.name}
            </h2>
            <div
              className={
                isSelected && !isAnnual
                  ? `flex items-center justify-center rounded-full bg-hover-card-light ${
                      lightModeOnly ? "" : "dark:bg-hover-card-dark"
                    }`
                  : "flex cursor-pointer items-center justify-center"
              }
              onClick={() => handleSelectPeriod(false)}
            >
              <div>
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className={`mb-1 mr-0.5 ${
                    lightModeOnly ? "text-black" : "text-black dark:text-white"
                  }`}
                />
              </div>
              <div>
                <h2
                  className={`mr-1 text-xxxl font-extrabold ${
                    lightModeOnly ? "text-black" : "text-black dark:text-white"
                  }`}
                >
                  {subscription.monthlyCost}
                </h2>
              </div>
              <div>
                <p className="mt-1 text-gray-500">/ month</p>
              </div>
            </div>
            {subscription.hasAnnual ? (
              <div>
                <div className="text-gray-900 dark:text-gray-500">or</div>
                <div
                  className={
                    isSelected && isAnnual
                      ? `flex items-center justify-center rounded-full bg-hover-card-light ${
                          lightModeOnly ? "" : "dark:bg-hover-card-dark"
                        }`
                      : "flex cursor-pointer items-center justify-center"
                  }
                  onClick={() => handleSelectPeriod(true)}
                >
                  <div>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className={`mb-1 mr-0.5 ${
                        lightModeOnly
                          ? "text-black"
                          : "text-black dark:text-white"
                      }`}
                    />
                  </div>
                  <div>
                    <h2
                      className={`mr-1 text-xxxl font-extrabold ${
                        lightModeOnly
                          ? "text-black"
                          : "text-black dark:text-white"
                      }`}
                    >
                      {subscription.annualCost}
                    </h2>
                  </div>
                  <div>
                    <p className="mt-1 text-gray-500">/ year</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="invisible">
                <div className="dark:text-gray-500">or</div>
                <div className="flex items-center justify-center">
                  <div>
                    <FontAwesomeIcon
                      icon={faDollarSign}
                      className="mb-1 mr-0.5 text-white"
                    />
                  </div>
                  <div>
                    <h2 className="mr-1 text-xxxl font-extrabold text-white">
                      {subscription.annualCost}
                    </h2>
                  </div>
                  <div>
                    <p className="mt-1 text-gray-500">/year</p>
                  </div>
                </div>
              </div>
            )}
            <div className="divider mb-0.5 before:bg-zinc-500 after:bg-zinc-500"></div>
            <div className="badge badge-primary badge-outline mb-2 px-2 font-semibold">
              {subscription.canTeam ? (
                <div>
                  {subscription.maxTeamMembers < 999
                    ? subscription.maxTeamMembers
                    : "Unlimited"}{" "}
                  Users
                </div>
              ) : (
                <div>1 User</div>
              )}
            </div>
            <div>
              <SubFeatures subFeatures={subscriptionFeatures} />
            </div>
          </div>
          <div className="relative left-0 right-0 w-full">
            {!currentSub && (
              <div className="relative -bottom-[14px] w-full">
                <CoinDisplay
                  initialCoins={
                    isUpgrade
                      ? isAnnual
                        ? subscription.annualUpgradeCoins
                        : subscription.monthlyUpgradeCoins
                      : isAnnual
                      ? subscription.annualInitialCoins
                      : subscription.monthlyInitialCoins
                  }
                  recurringCoins={
                    isAnnual
                      ? subscription.annualCoins * 12
                      : subscription.monthlyCoins
                  }
                  isAnnual={isAnnual}
                  lightModeOnly={lightModeOnly}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHolder;
