import React, { SetStateAction, Dispatch } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import addCommas from "src/helpers/addCommas";
interface HighlightNumProps {
  num: number;
}

const HighlightNum = ({ num }: HighlightNumProps) => {
  return (
    <>
      {" "}
      <span className="rounded-sm bg-warning font-bold text-black">
        ${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </span>{" "}
    </>
  );
};

interface OfferSummaryCardProps {
  title: "All Cash" | "Seller Carry" | "Seller Financing";
  allCashARV: number;
  sellerCarryARV: number;
  sellerCarryDownPayment: number;
  sellerCarryRate: number;
  sellerCarryTerm: number;
  sellerFinancingARV: number;
  sellerFinancingRate: number;
  sellerFinancingBalloon: number;
  setDisplaySummaryCard: Dispatch<SetStateAction<boolean>>;
}

const OfferSummaryCard = ({
  title,
  allCashARV,
  sellerCarryARV,
  sellerCarryDownPayment,
  sellerCarryRate,
  sellerCarryTerm,
  sellerFinancingARV,
  sellerFinancingRate,
  sellerFinancingBalloon,
  setDisplaySummaryCard,
}: OfferSummaryCardProps) => {
  // Seller Carry Offer
  let loanAmount = sellerCarryARV;
  let downPayment = Math.floor(loanAmount * (sellerCarryDownPayment / 100));
  const yearlyRate = sellerCarryRate / 100;
  const periodRate = yearlyRate / 12;

  if (downPayment > 0) {
    loanAmount -= downPayment;
  }
  const paymentPeriods = 30 * 12;

  const accruedAmount = loanAmount * (1 + yearlyRate * sellerCarryTerm);

  const totalYield = accruedAmount + downPayment;

  const monthlyPayment = Math.round(
    loanAmount *
      ((periodRate * Math.pow(1 + periodRate, paymentPeriods)) /
        (Math.pow(1 + periodRate, paymentPeriods) - 1))
  );

  const totalPayments = Math.floor(monthlyPayment * sellerCarryTerm * 12);

  const balloonPayment = Math.floor(accruedAmount - totalPayments);

  //Seller Financing Offer
  let loanAmountSF = sellerFinancingARV;
  const periodRateSF = sellerFinancingRate / 100 / 12;
  const paymentPeriodsSF = 12 * 30; // 30 year mortgage
  const balloonPaymentPeriods = 12 * sellerFinancingBalloon;

  const monthlyPaymentSF = Math.round(
    loanAmountSF *
      ((periodRateSF * Math.pow(1 + periodRateSF, paymentPeriodsSF)) /
        (Math.pow(1 + periodRateSF, paymentPeriodsSF) - 1))
  );

  let monthlyInterestPaid;
  let monthlyPrincipalPaid;
  let totalInterestPaid = 0;
  let principal = loanAmountSF;
  for (let i = 0; i < balloonPaymentPeriods; i++) {
    monthlyInterestPaid = principal * periodRateSF;
    totalInterestPaid += monthlyInterestPaid;

    monthlyPrincipalPaid = monthlyPaymentSF - monthlyInterestPaid;
    principal -= monthlyPrincipalPaid;
  }

  const totalYieldSF = loanAmountSF + totalInterestPaid;

  const totalPaymentsSF = sellerFinancingBalloon * 12 * monthlyPaymentSF;

  const balloonPaymentSF = Math.floor(totalYieldSF - totalPaymentsSF);

  return (
    <>
      <div className="w-full rounded-lg bg-card-light py-3 px-4 pb-6 dark:bg-back-dark">
        <div className="flex items-center justify-between">
          <div className="text-lg font-semibold text-secondary">{title}</div>
          <div className="cursor-pointer">
            <FontAwesomeIcon
              className="text-[24px] text-text-dark dark:text-text-light"
              icon={faXmark}
              onClick={() => setDisplaySummaryCard(false)}
            />
          </div>
        </div>
        <div className="text-xs md:text-base">
          {title === "All Cash" ? (
            <div className="text-left">
              Buyer to pay $
              <span className="font-bold">{addCommas(allCashARV)}</span>
            </div>
          ) : (
            <p className="">
              Buyer to pay $
              <span className="font-bold">
                {addCommas(title === "Seller Carry" ? downPayment : 0)}{" "}
              </span>
              down, Seller to Carry 1st mortgage in the amount of $
              <span className="font-bold">
                {addCommas(
                  title === "Seller Carry" ? loanAmount : loanAmountSF
                )}
              </span>{" "}
              at{" "}
              {title === "Seller Carry"
                ? (yearlyRate * 100).toFixed(2)
                : sellerFinancingRate.toFixed(2)}
              % fully amortizing for 30 years, with monthly payments in the
              amount of $
              <span className="font-bold">
                {addCommas(
                  title === "Seller Carry" ? monthlyPayment : monthlyPaymentSF
                )}
              </span>{" "}
              per month and a{" "}
              <span className="font-bold">
                {title === "Seller Carry"
                  ? sellerCarryTerm
                  : sellerFinancingBalloon}
              </span>{" "}
              year balloon payment of $
              <span className="font-bold">
                {addCommas(
                  title === "Seller Carry" ? balloonPayment : balloonPaymentSF
                )}
              </span>
              .{" "}
              <span className="font-bold">
                {title === "Seller Carry"
                  ? sellerCarryTerm * 12
                  : sellerFinancingBalloon * 12}
              </span>{" "}
              Payments of $
              <span className="font-bold">
                {addCommas(
                  title === "Seller Carry" ? monthlyPayment : monthlyPaymentSF
                )}
              </span>{" "}
              = $
              <span className="font-bold">
                {addCommas(
                  title === "Seller Carry" ? totalPayments : totalPaymentsSF
                )}
              </span>
              . Total revenue after{" "}
              <span className="font-bold">
                {title === "Seller Carry"
                  ? sellerCarryTerm
                  : sellerFinancingBalloon}
              </span>{" "}
              years is{" "}
              <HighlightNum
                num={
                  title === "Seller Carry"
                    ? Math.round(totalYield)
                    : Math.round(totalYieldSF)
                }
              />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

interface OfferSummaryProps {
  summaryCardDisplayed: 0 | 1 | 2;
  setSummaryCardDisplayed: Dispatch<SetStateAction<0 | 1 | 2>>;
  displaySummaryCard: boolean;
  setDisplaySummaryCard: Dispatch<SetStateAction<boolean>>;
  allCashARV: number;
  sellerCarryARV: number;
  sellerCarryDownPayment: number;
  sellerCarryRate: number;
  sellerCarryTerm: number;
  sellerFinancingARV: number;
  sellerFinancingRate: number;
  sellerFinancingBalloon: number;
}

const OfferSummary = ({
  summaryCardDisplayed,
  setSummaryCardDisplayed,
  displaySummaryCard,
  setDisplaySummaryCard,
  allCashARV,
  sellerCarryARV,
  sellerCarryDownPayment,
  sellerCarryRate,
  sellerCarryTerm,
  sellerFinancingARV,
  sellerFinancingRate,
  sellerFinancingBalloon,
}: OfferSummaryProps) => {
  return (
    <>
      {displaySummaryCard && (
        <div className="">
          <AnimatePresence mode={"wait"}>
            {summaryCardDisplayed === 0 && (
              <motion.div
                initial={{ y: 8, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -8, opacity: 0 }}
                transition={{ duration: 0.1 }}
                layout
              >
                <OfferSummaryCard
                  title={"All Cash"}
                  allCashARV={allCashARV}
                  sellerCarryARV={sellerCarryARV}
                  sellerCarryDownPayment={sellerCarryDownPayment}
                  sellerCarryRate={sellerCarryRate}
                  sellerCarryTerm={sellerCarryTerm}
                  sellerFinancingARV={sellerFinancingARV}
                  sellerFinancingRate={sellerFinancingRate}
                  sellerFinancingBalloon={sellerFinancingBalloon}
                  setDisplaySummaryCard={setDisplaySummaryCard}
                />
              </motion.div>
            )}
            {summaryCardDisplayed === 1 && (
              <motion.div
                initial={{ y: 8, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -8, opacity: 0 }}
                transition={{ duration: 0.1 }}
                layout
              >
                <OfferSummaryCard
                  title={"Seller Carry"}
                  allCashARV={allCashARV}
                  sellerCarryARV={sellerCarryARV}
                  sellerCarryDownPayment={sellerCarryDownPayment}
                  sellerCarryRate={sellerCarryRate}
                  sellerCarryTerm={sellerCarryTerm}
                  sellerFinancingARV={sellerFinancingARV}
                  sellerFinancingRate={sellerFinancingRate}
                  sellerFinancingBalloon={sellerFinancingBalloon}
                  setDisplaySummaryCard={setDisplaySummaryCard}
                />
              </motion.div>
            )}
            {summaryCardDisplayed === 2 && (
              <motion.div
                initial={{ y: 8, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -8, opacity: 0 }}
                transition={{ duration: 0.1 }}
                layout
              >
                <OfferSummaryCard
                  title={"Seller Financing"}
                  allCashARV={allCashARV}
                  sellerCarryARV={sellerCarryARV}
                  sellerCarryDownPayment={sellerCarryDownPayment}
                  sellerCarryRate={sellerCarryRate}
                  sellerCarryTerm={sellerCarryTerm}
                  sellerFinancingARV={sellerFinancingARV}
                  sellerFinancingRate={sellerFinancingRate}
                  sellerFinancingBalloon={sellerFinancingBalloon}
                  setDisplaySummaryCard={setDisplaySummaryCard}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}
    </>
  );
};

export default OfferSummary;
