import {
  faCircleCheck,
  faCircleExclamation,
  faDollar,
  faFlag,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { AnyRecord } from "dns";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect, ReactNode } from "react";
import AnimatedNumber from "react-awesome-animated-number";
import { subscriptionApi } from "src/api";
import { zipcodes } from "src/data/zips";
import useModal from "src/hooks/useModal";
import {
  AITerritory,
  RealeflowAccountDetails,
  Zones,
} from "src/interfaces/realeflow";
import { Subscription } from "src/interfaces/subscriptions";
import SubscriptionsHelp from "../help/SubscriptionsHelp";
import ButtonBlock from "../modals/ButtonBlock";
import UpgradeModal from "../modals/UpgradeModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingSpinner from "../loading/LoadingSpinner";
import ZoneBadge from "./ZoneBadge";
import delay from "src/helpers/delay";
import calculateProrationAmount from "src/helpers/calculateProrationAmount";
import useCurrentSubscriptionContext from "src/contexts/private/useCurrentSubscriptionContext";
import useTeamContext from "src/hooks/private/useTeamContext";
import useRealeflowContext from "src/hooks/private/useRealeflowContext";

interface IconWrapperProps {
  icon: ReactNode;
  index: number;
}

const IconWrapper = ({ icon, index }: IconWrapperProps) => {
  return (
    <motion.div
      key={index}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ duration: 0.1 }}
      className="flex h-[27px] w-[27px] items-center justify-center "
    >
      {icon}
    </motion.div>
  );
};

type ProcessStates = "default" | "processing" | "invalid" | "success";

// Define an object where keys correspond to ProcessStates and values are functions returning JSX
const stateComponents: Record<ProcessStates, () => ReactNode> = {
  default: () => (
    <IconWrapper icon={<FontAwesomeIcon icon={faFlag} />} index={0} />
  ),
  processing: () => (
    <IconWrapper
      icon={<LoadingSpinner className={"text-secondary"} />}
      index={1}
    />
  ),
  invalid: () => (
    <IconWrapper
      icon={
        <FontAwesomeIcon icon={faCircleExclamation} className="text-warning" />
      }
      index={2}
    />
  ),
  success: () => (
    <IconWrapper
      icon={<FontAwesomeIcon icon={faCircleCheck} className="text-success" />}
      index={3}
    />
  ),
};

interface AddZonesProps {
  realeflowAccount: RealeflowAccountDetails;
  subscription: Subscription;
  purchasedZones: Zones;
}

const AddZones = ({
  realeflowAccount,
  subscription,
  purchasedZones,
}: AddZonesProps) => {
  type ZoneType = "Zipcode" | "County" | "State";

  const { openModalWith } = useModal();

  const { fetchRealeflowAccount } = useRealeflowContext();

  const { team } = useTeamContext();

  const { setShowModal } = useModal();

  const { stripeSubscription } = useCurrentSubscriptionContext();

  const [zonesToAdd, setZonesToAdd] = useState<string[]>([]);

  const [zoneType, setZoneType] = useState<ZoneType>("Zipcode");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  const currentNumZips = realeflowAccount.AITerritories.filter(
    (territory: AITerritory) => territory.Type === "Zip"
  ).length;

  const [requiresPurchase, setRequiresPurchase] = useState<boolean>(false);

  const [zonesRemaining, setZonesRemaining] = useState<number>(
    subscription?.maxZips - currentNumZips
  );

  const [currentZoneAdding, setCurrentZoneAdding] = useState<string>("");

  const handleUpgradeSubscription = () => {
    openModalWith({
      title: "Upgrade Subscription",
      body: <UpgradeModal mustFullyUpgrade={false} />,
      hideButtons: true,
      helpTitle: "Subscriptions",
      helpBody: <SubscriptionsHelp />,
    });
  };

  useEffect(() => {
    if (zonesToAdd.length > subscription.includedZips - currentNumZips) {
      setRequiresPurchase(true);
    } else {
      setRequiresPurchase(false);
    }
  }, [currentNumZips, subscription.includedZips, zonesToAdd]);

  const [processState, setProcessState] = useState<ProcessStates>("default");

  const handleZoneToAddChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setProcessState("default");
    let { value } = e.target;

    if (zoneType === "Zipcode") {
      value = value.replace(/[^0-9]/g, "").slice(0, 5);
    }

    setCurrentZoneAdding(value);
  };

  const verifyZipcode = (zipcode: string) => {
    if (zipcode.length !== 5) {
      setError("Zipcode must be 5 digits");
      return false;
    }
    if (purchasedZones.zips.indexOf(zipcode) !== -1) {
      setError("Already purchased this zipcode");
    }

    if (zonesToAdd.indexOf(zipcode) !== -1) {
      setError("Already added this zipcode");
      return false;
    }

    if (!zipcodes.hasOwnProperty(zipcode)) {
      setError("Not a valid zipcode");
      return false;
    }
    return true;
  };

  const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && currentZoneAdding.length > 0) {
      setProcessState("processing");
      if (verifyZipcode(currentZoneAdding)) {
        setProcessState("success");
        setZonesToAdd([...zonesToAdd, currentZoneAdding]);
        setCurrentZoneAdding("");
        setZonesRemaining((prevState) => prevState - 1);
        await delay(2000);
        setProcessState("default");
      } else {
        setProcessState("invalid");
      }
    }
  };

  useEffect(() => {
    const handleChange = async () => {
      // if (zonesToAdd.length > 0) {
      //   setProcessState("processing");
      // } else {
      //   setProcessState("default");
      // }
    };
    handleChange();
  }, [zonesToAdd, currentNumZips, subscription.includedZips]);

  const handleRemoveZone = (index: number) => {
    const newZones = zonesToAdd.filter((_, i) => i !== index);
    setZonesToAdd(newZones);
    setZonesRemaining(subscription?.maxZips - currentNumZips - newZones.length);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await subscriptionApi.addZones({
        zips: zonesToAdd,
        counties: [],
        states: [],
      });
    } catch (err) {
      console.log(err);
    } finally {
      setShowModal(false);
      fetchRealeflowAccount();
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <AnimatePresence mode="wait">
        {subscription && purchasedZones.zips.length < subscription?.maxZips ? (
          <>
            <div className="flex w-full columns-1 flex-col items-center justify-center">
              {zonesToAdd.length > subscription.includedZips - currentNumZips &&
                stripeSubscription && (
                  <>
                    <motion.p
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      className="-mt-2 mb-4 rounded-lg px-2 py-1 text-zinc-300 dark:bg-base-300"
                    >
                      Your monthly subscription will increase by
                      <span className="px-1 font-bold text-secondary">
                        $
                        {
                          <AnimatedNumber
                            className=""
                            style={{
                              letterSpacing: -1,
                            }}
                            value={
                              -(
                                subscription.includedZips -
                                currentNumZips -
                                zonesToAdd.length
                              ) * subscription.extraZipCost
                            }
                            size={18}
                            duration={200}
                          />
                        }
                      </span>
                    </motion.p>
                    <motion.p
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      className="-mt-2 mb-4 rounded-lg px-2 py-1 text-zinc-300 dark:bg-base-300"
                    >
                      One time prorated payment today of
                      <span className="px-1 font-bold text-secondary">
                        $
                        {
                          <AnimatedNumber
                            className=""
                            style={{
                              letterSpacing: -1,
                            }}
                            value={calculateProrationAmount({
                              cost: subscription.extraZipCost,
                              quantity: -(
                                subscription.includedZips -
                                currentNumZips -
                                zonesToAdd.length
                              ),
                              subscription: stripeSubscription,
                              isAnnual: team?.isAnnualSubscription || false,
                            })}
                            size={18}
                            duration={200}
                          />
                        }
                      </span>
                    </motion.p>
                  </>
                )}
              <div className="mb-4">
                {error ? (
                  <p className="text-warning">{error}</p>
                ) : (
                  <div className="text-zinc-300">
                    You have
                    <span className="px-1 font-bold text-secondary">
                      {
                        <AnimatedNumber
                          className=""
                          style={{
                            letterSpacing: -1,
                          }}
                          value={zonesRemaining}
                          size={18}
                          duration={200}
                        />
                      }
                    </span>
                    {`${zoneType}${
                      zonesRemaining > 1 || zonesRemaining === 0 ? "s" : ""
                    }`}{" "}
                    left to add
                  </div>
                )}
              </div>
              <div className="md:w/5/12 w-full xs:w-9/12 sm:w-8/12 lg:w-1/2">
                <label className="input input-bordered flex items-center gap-2 bg-card-light text-zinc-500 dark:bg-back-dark">
                  <AnimatePresence>
                    {stateComponents[processState]()}
                  </AnimatePresence>
                  <input
                    type="text"
                    className="w-full grow text-text-dark placeholder:text-zinc-400 dark:text-text-light"
                    placeholder={`Type ${zoneType} and press enter`}
                    value={currentZoneAdding}
                    onChange={handleZoneToAddChange}
                    onKeyDown={handleKeyPress}
                    disabled={zonesRemaining === 0}
                    data-1p-ignore
                  />
                </label>
              </div>
            </div>
            {zonesToAdd.length > 0 && (
              <motion.div className="mt-4 flex w-full flex-col items-center justify-center">
                <div className="w-full  sm:w-2/3">
                  <div className="flex w-full flex-row flex-wrap gap-4 rounded-lg ">
                    <AnimatePresence mode="sync">
                      {zonesToAdd.map((zone: string, index: number) => (
                        <ZoneBadge
                          // key={`${index}-${zone}-add-zones`}
                          zone={zone}
                          index={index}
                          displayDollarSign={
                            index + 1 + currentNumZips >
                            subscription.includedZips
                          }
                          handleRemoveZone={() => handleRemoveZone(index)}
                        />
                      ))}
                    </AnimatePresence>
                  </div>
                </div>
              </motion.div>
            )}
            {zonesRemaining === 0 && zonesToAdd.length === 0 ? (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="mt-4"
              >
                <p className="text-zinc-300">To add more zones please</p>
                <span
                  onClick={handleUpgradeSubscription}
                  className="btn btn-outline btn-secondary btn-sm mt-2 cursor-pointer text-secondary hover:font-bold"
                >
                  Upgrade your Subscription
                </span>
              </motion.div>
            ) : zonesToAdd.length > 0 ? (
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0 }}
                className="mt-4"
              >
                <ButtonBlock
                  handleSubmit={handleSubmit}
                  submitIcon={
                    zonesToAdd.length > subscription.includedZips
                      ? faDollar
                      : faPlus
                  }
                  submitLabel={
                    zonesToAdd.length > subscription.includedZips
                      ? "Purchase"
                      : "Add"
                  }
                  submitting={isSubmitting}
                />
              </motion.div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <p>To add more zones please</p>
            <span
              onClick={handleUpgradeSubscription}
              className="btn btn-outline btn-secondary btn-sm mt-2 cursor-pointer text-secondary hover:font-bold"
            >
              Upgrade your Subscription
            </span>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default AddZones;
