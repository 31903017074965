import React from "react";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserType } from "src/interfaces/user";

interface EmailTeammateProps {
  teammate: UserType;
}

const EmailTeammate = ({ teammate }: EmailTeammateProps) => {
  return (
    <>
      <a href={`mailto:${teammate.email}`} target="_blank" rel="noreferrer">
        <button className="btn btn-secondary btn-outline btn-sm hover:dark:text-text-light hover:text-text-light w-36">
          <FontAwesomeIcon icon={faEnvelope} />
          <span className="ml-1">Email</span>
        </button>
      </a>
    </>
  );
};

export default EmailTeammate;
