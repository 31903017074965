import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { propertyApi } from "src/api";
import useTeamContext from "src/hooks/private/useTeamContext";
import useErrorHandler from "src/hooks/private/useErrorHandler";

interface ChartContextInterface {
  statusChart: any[];
  fetchStatusChart: () => void;
}

const chartContextDefaults: ChartContextInterface = {
  statusChart: [],
  fetchStatusChart: () => {},
};

const ChartContext =
  React.createContext<ChartContextInterface>(chartContextDefaults);

interface ChartProviderProps {}

export const ChartProvider = ({
  children,
}: PropsWithChildren<ChartProviderProps>) => {
  const { team } = useTeamContext();

  const { handleError } = useErrorHandler();

  const [statusChart, setStatusChart] = useState([]);

  const fetchStatusChart = useCallback(async () => {
    if (team?.id) {
      try {
        const statusChart = await propertyApi.getStatusChart(team.id);
        setStatusChart(statusChart);
      } catch (err) {
        handleError(err);
      }
    }
  }, [team, handleError]);

  useEffect(() => {
    fetchStatusChart();
  }, [fetchStatusChart]);

  return (
    <ChartContext.Provider
      value={{
        statusChart,
        fetchStatusChart,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};

export default ChartContext
