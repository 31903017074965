/* eslint-disable import/no-anonymous-default-export */
import { BrokerProofOfFunds, BrokerOffer } from "src/interfaces/brokers";
import ApiTokenSupport from "./token.support";
import axios from "src/utils/axios";
import { ProofOfFundsCreateParams } from "src/components/modals/ProofOfFundsLetterModal";
import { customHash } from "src/utils/hash";
import {
  getProofOfFundsPDFFilename,
  getBrokerOfferPDFfilename,
} from "src/helpers/pdf";
import { BrokerOfferCreateParams } from "src/components/modals/BrokerOffers/BrokerOffersModal";

class BrokersApi extends ApiTokenSupport {
  async getTeamProofOfFunds(): Promise<BrokerProofOfFunds[]> {
    const resp = await axios.get<BrokerProofOfFunds[]>(
      `/api/proofOfFunds/team`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTeamOffers(): Promise<BrokerOffer[]> {
    const resp = await axios.get<BrokerOffer[]>(
      `/api/brokerOffers/team`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async createProofOfFunds(
    data: ProofOfFundsCreateParams
  ): Promise<BrokerProofOfFunds> {
    const resp = await axios.post<BrokerProofOfFunds>(
      `/api/proofOfFunds`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async createOffer(data: BrokerOfferCreateParams): Promise<BrokerOffer> {
    const resp = await axios.post<BrokerOffer>(
      `/api/brokerOffers`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  downloadPofPDF(proofOfFunds: BrokerProofOfFunds): string {
    const hashed = customHash(proofOfFunds.id);
    const fileName = getProofOfFundsPDFFilename(proofOfFunds);
    return `${process.env.REACT_APP_BACKEND_URL}/api/proofOfFunds/preview/${hashed}/${fileName}?mode=pdf`;
  }

  downloadOfferPDF(brokerOffer: BrokerOffer): string {
    const hashed = customHash(brokerOffer.id);
    const fileName = getBrokerOfferPDFfilename(brokerOffer);
    return `${process.env.REACT_APP_BACKEND_URL}/api/brokerOffers/preview/${hashed}/${fileName}?mode=pdf`;
  }
}

export default new BrokersApi();
