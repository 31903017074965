import React, { useState } from "react";
import useAlert from "src/hooks/useAlert";
import { userApi } from "src/api";
import Button from "./Button";

interface ResendInviteProps {
  email: string;
}

const ResendInvite = ({ email }: ResendInviteProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { setAlert } = useAlert();

  const sendEmail = async () => {
    try {
      setSubmitting(true);
      await userApi.resendInvite(email);
      setAlert({
        display: true,
        message: `An invitation to join team was sent to ${email}`,
        type: "success",
      });
    } catch (err: any) {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <Button
          className="btn btn-secondary btn-sm w-36 text-text-light dark:text-text-light hover:text-text-light hover:dark:text-text-light"
          disabled={submitting}
          onClick={sendEmail}
          loading={submitting}
        >
          <span className="ml-1">Resend Invite</span>
        </Button>
      </div>
    </>
  );
};

export default ResendInvite;
