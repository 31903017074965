import React, { useState } from "react";
import { UserType } from "src/interfaces/user";
import useModal from "src/hooks/useModal";
import FloatingInput from "../fields/FloatingInputText";
import useAlert from "src/hooks/useAlert";
import { teamApi } from "src/api";
import useTeamContext from "src/hooks/private/useTeamContext";
import ButtonBlock from "./ButtonBlock";

interface EditTeammateModalProps {
  teammate: UserType;
}

const EditTeammateModal = ({ teammate }: EditTeammateModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const { fetchTeammates, fetchTeamUsers } = useTeamContext();

  interface FormState {
    firstName: string;
    lastName: string;
  }

  const initialFormState = {
    firstName: teammate.firstName,
    lastName: teammate.lastName,
  };

  const initialFormErrors: {
    firstName: string;
    lastName: string;
  } = {
    firstName: "",
    lastName: "",
  };

  interface FormErrors {
    firstName: string | null;
    lastName: string | null;
  }

  const [formData, setFormData] = useState<FormState>(initialFormState);
  const [onSubmitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (e: any) => {
    const { name, value, checked } = e.target;
    setFormData({ ...formData, [name]: checked || value });
  };

  const validate: any = (data: any) => {
    const errors: {
      firstName?: string;
      lastName?: string;
    } = {};
    if (!data.firstName) {
      errors.firstName = "First name is required";
    }
    if (!data.lastName) {
      errors.lastName = "Last name is required";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.firstName || errors.lastName) {
      return setFormErrors(errors);
    } else {
      setSubmitting(true);
      try {
        const { firstName, lastName } = formData;
        await teamApi.updateTeam(teammate.id, {
          ...formData,
          fullName: `${firstName} ${lastName}`,
        });
        setShowModal(false);
        setAlert({
          display: true,
          message: `${firstName} ${lastName} has been updated`,
          type: "success",
        });
      } catch (err: any) {
        setAlert({
          display: true,
          message: err?.message || "Unable to invited update team member",
          type: "error",
        });
      } finally {
        fetchTeammates();
        fetchTeamUsers();
      }
      setSubmitting(false);
    }
  };

  return (
    <>
      <div>
        <div className="mb-3 flex w-full items-center justify-center">
          <div className="w-full sm:w-2/3">
            <div className="my-3">
              <FloatingInput
                name={"firstName"}
                type={"text"}
                value={formData.firstName}
                onChange={handleChange}
                error={formErrors.firstName}
                label={"First Name"}
              />
            </div>
            <div className="my-3">
              <FloatingInput
                name={"lastName"}
                type={"text"}
                value={formData.lastName}
                onChange={handleChange}
                error={formErrors.lastName}
                label={"Last Name"}
              />
            </div>
          </div>
        </div>
        <ButtonBlock
          submitLabel="Save"
          submitting={onSubmitting}
          handleSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default EditTeammateModal;
