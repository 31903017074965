/* eslint-disable import/no-anonymous-default-export */
import { RealeflowAccountDetails } from "src/interfaces/realeflow";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";

class RealeflowApi extends ApiTokenSupport {
  async getRealeflowAccountDetails(data: {
    AccountId: number;
  }): Promise<RealeflowAccountDetails> {
    const resp = await axios.post<RealeflowAccountDetails>(
      `/api/team/realeflow-account-details`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new RealeflowApi();
