import React, { useCallback, useEffect } from "react";
import { subscriptionApi } from "src/api";
import { useState } from "react";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import {
  Subscription,
  SubscriptionFeatures,
} from "src/interfaces/subscriptions";
import useAuth from "src/hooks/useAuth";
import Stripe from "stripe";

interface CurrentSubscriptionContextInterface {
  subscription: Subscription | null | undefined;
  stripeSubscription: Stripe.Subscription | null | undefined;
  isLoadingSubscription: boolean;
  subscriptionFeatures: SubscriptionFeatures | null | undefined;
  isLoadingSubscriptionFeatures: boolean;
  fetchSubscription: () => void;
  fetchSubscriptionFeatures: () => void;
}

const subscriptionContextDefaults: CurrentSubscriptionContextInterface = {
  subscription: null,
  stripeSubscription: null,
  isLoadingSubscription: true,
  subscriptionFeatures: null,
  isLoadingSubscriptionFeatures: true,
  fetchSubscription: () => {},
  fetchSubscriptionFeatures: () => {},
};

const CurrentSubscriptionContext =
  React.createContext<CurrentSubscriptionContextInterface>(
    subscriptionContextDefaults
  );

export const CurrentSubscriptionProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { currentUser } = useAuth();

  const [subscription, setSubscription] = useState<Subscription>();

  const [isLoadingSubscription, setIsLoadingSubscription] =
    useState<boolean>(true);

  const [subscriptionFeatures, setSubscriptionFeatures] =
    useState<SubscriptionFeatures>();
  const [isLoadingSubscriptionFeatures, setIsLoadingSubscriptionFeatures] =
    useState<boolean>(true);

  const [stripeSubscription, setStripSubscription] = useState<
    Stripe.Subscription | null | undefined
  >(null);

  const { handleError } = useErrorHandler();

  const fetchSubscription = useCallback(async () => {
    setIsLoadingSubscription(true);
    try {
      if (currentUser?.teamID) {
        const teamSubscription = await subscriptionApi.getSubscription(
          currentUser?.teamID
        );
        setSubscription(teamSubscription);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoadingSubscription(false);
    }
  }, [handleError, currentUser?.teamID]);

  const fetchSubscriptionFeatures = useCallback(async () => {
    setIsLoadingSubscriptionFeatures(true);
    try {
      if (currentUser?.teamID) {
        const subscriptionFeaturesData =
          await subscriptionApi.getSubscriptionFeatures(currentUser?.teamID);

        // Converting this to an object for easier use;
        const featuresObject: SubscriptionFeatures =
          subscriptionFeaturesData.reduce((obj: any, feature) => {
            obj[feature.name] = feature;
            return obj;
          }, {});

        setSubscriptionFeatures(featuresObject);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoadingSubscriptionFeatures(false);
    }
  }, [handleError, currentUser?.teamID]);

  const fetchStripeSubscription = useCallback(async () => {
    try {
      const stripeSubscription = await subscriptionApi.getStripeSubscription();
      setStripSubscription(stripeSubscription);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchSubscription();
    fetchSubscriptionFeatures();
    fetchStripeSubscription();
  }, [fetchSubscription, fetchSubscriptionFeatures, fetchStripeSubscription]);

  return (
    <CurrentSubscriptionContext.Provider
      value={{
        subscription,
        stripeSubscription,
        isLoadingSubscription,
        subscriptionFeatures,
        isLoadingSubscriptionFeatures,
        fetchSubscription,
        fetchSubscriptionFeatures,
      }}
    >
      {children}
    </CurrentSubscriptionContext.Provider>
  );
};

export default CurrentSubscriptionContext;
