import React, { ReactNode, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
interface TabType {
  title: string;
  body: ReactNode;
  isActive: boolean;
  onClick: (index: number) => void;
  index: number;
}

const Tab = ({ title, body, isActive, onClick, index }: TabType) => {
  return (
    <button
      className={
        isActive
          ? "tab tab-xs md:tab-md bg-secondary rounded-md text-text-light font-semibold"
          : "tab tab-xs md:tab-md text-title-text-light dark:text-[#73868F] hover:bg-hover-card-light hover:text-black hover:dark:bg-hover-card-dark hover:dark:text-white rounded-lg"
      }
      onClick={() => onClick(index)}
    >
      {title}
    </button>
  );
};

interface TabPropType {
  title: string;
  body: ReactNode;
}

interface TabGroupProps {
  tabs: TabPropType[];
}

const TabGroup = ({ tabs }: TabGroupProps) => {
  const [isActiveTabs, setIsActiveTabs] = useState<boolean[]>(
    tabs.map((tab: TabPropType, index: number) => (index === 0 ? true : false))
  );

  const handleChange = (_index: number) => {
    setIsActiveTabs(
      tabs.map((tab: TabPropType, index: number) =>
        index === _index ? true : false
      )
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="tabs tabs-boxed flex items-center justify-around sm:justify-start bg-white dark:bg-card-dark gap-1">
          {tabs.map((tab: TabPropType, index: number) => (
            <div key={tab.title} className="">
              <Tab
                title={tab.title}
                body={tab.body}
                isActive={isActiveTabs[index]}
                onClick={handleChange}
                index={index}
              />
            </div>
          ))}
        </div>
        <div className="mt-1">
          <AnimatePresence mode="wait">
            {tabs.map(
              (tab: TabPropType, index: number) =>
                isActiveTabs[index] && (
                  <motion.div
                    key={index}
                    initial={{ y: 8, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -8, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    className="w-full"
                  >
                    {tab.body}
                  </motion.div>
                )
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

export default TabGroup;
