import { faCircleCheck, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Mailing } from "src/interfaces/mailing";
import { parseMonthDayYear } from "src/helpers/parseEpochs";

interface MailingsStatusProps {
  mailings: Mailing[];
}

const MailingStatus = ({ mailings }: MailingsStatusProps) => {

  return (
    <>
      <div className="grid w-16 grid-cols-4 gap-0 sm:w-24 lg:w-28">
        {mailings.length > 0 ? (
          mailings
            .sort((a: Mailing, b: Mailing) => a.rank - b.rank)
            .map((mailing: Mailing, index: number) => (
              <div
                key={index}
                className={"tooltip"}
                style={{
                  gridColumn: `${
                    mailing.rank <= 4 ? mailing.rank : mailing.rank - 4
                  }`,
                  gridRow: `${mailing.rank <= 4 ? 1 : 2}`,
                }}
                data-tip={
                  mailing.status === "pending"
                    ? `Scheduled To Send: ${parseMonthDayYear(
                        mailing.scheduledDate
                      )}`
                    : mailing.status === "sent"
                    ? `Sent: ${parseMonthDayYear(mailing.scheduledDate)}`
                    : mailing.status === "failed"
                    ? `Status: Failed`
                    : "Error sending mailing"
                }
              >
                <FontAwesomeIcon
                  className={
                    mailing.status === "pending"
                      ? "pr-0.5 text-sm text-info sm:text-xl lg:text-xxl"
                      : mailing.status === "sent"
                      ? "pr-0.5 text-sm text-success sm:text-xl lg:text-xxl"
                      : mailing.status === "failed"
                      ? "pr-0.5 text-sm text-error sm:text-xl lg:text-xxl"
                      : "pr-0.5 text-sm text-warning sm:text-xl lg:text-xxl"
                  }
                  icon={faEnvelope}
                />
              </div>
            ))
        ) : (
          <div
            className={"tooltip relative"}
            data-tip={"This legacy Sequence is sending successfully"}
          >
            <FontAwesomeIcon
              className={"pr-0.5 text-sm text-success sm:text-xl lg:text-xxl"}
              icon={faEnvelope}
            />
            <div className="absolute -top-3.5 -right-1.5">
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="text-xs text-info"
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MailingStatus;
