import useTheme from "src/hooks/useTheme";
import Property from "src/interfaces/property";
import { capitalize } from "src/helpers/parseStrings";
import useModal from "src/hooks/useModal";
import SkipTracesHelp from "../help/SkipTracesHelp";
import SkipTraceResultsModal from "../modals/SkipTraceResultsModal";
import OfferResultsModal from "../modals/OfferResultsModal";
import useDrawer from "src/hooks/private/useDrawer";
import { PropertyUpdate } from "src/interfaces/propertyUpdate";
import OffersHelp from "../help/OffersHelp";
import SequenceResultsModal from "../modals/SequenceResultsModal";
import SequencesHelp from "../help/SequencesHelp";
import PhoneEmailResultsModal from "../modals/PhoneEmailResultsModal";
import CallsEmailsHelp from "../help/CallsEmailsHelp";
import {
  faDollarSign,
  faEnvelope,
  faFileInvoiceDollar,
  faMagnifyingGlass,
  faPhone,
  faTriangleExclamation,
  faQuestion,
  faLayerGroup,
  faCar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDebtStack from "src/hooks/private/useDebtStack";

interface ActivityBadgeProps {
  property: Property;
  update: PropertyUpdate;
  disableClick?: boolean;
}

const ActivityBadge = ({
  property,
  update,
  disableClick = false,
}: ActivityBadgeProps) => {
  const { tailwindTheme } = useTheme();

  const { openModalWith } = useModal();

  const { showPropertyDrawer, openPropertyDrawerWith } = useDrawer();

  const { debtStackLink } = useDebtStack(
    property.id,
    update.value ? parseInt(update.value, 10) : 0,
    update.key === "Debt Stack" && update.value !== "-",
    property?.fullAddress
  );

  const colors = [
    tailwindTheme?.theme1,
    tailwindTheme?.theme2,
    tailwindTheme?.theme3,
    tailwindTheme?.theme4,
    tailwindTheme?.theme5,
    tailwindTheme?.gradient1,
  ];

  const assignColor = (activity: string) => {
    switch (activity) {
      case "Research":
        return colors[0];
      case "Phone Call":
        return colors[3];
      case "Call/Email":
        return colors[3];
      case "Debt Stack":
        return colors[4];
      case "Sequence":
        return colors[1];
      case "Offer":
        return colors[2];
      case "Three Tiered Offer":
        return colors[2];
      case "Skip Trace":
        return colors[3];
      case "Drive":
          return colors[1]
      default:
        return colors[0];
    }
  };

  const handleSkipTrace = () => {
    openModalWith({
      title: "Skip Trace Results",
      property,
      body: <SkipTraceResultsModal property={property} />,
      hideButtons: true,
      helpTitle: "Skip Traces",
      helpBody: <SkipTracesHelp />,
    });
  };

  const handleDebtStack = async () => {
    if (update.value === "-") {
      return;
    }
    if (debtStackLink) {
      window.open(new URL(debtStackLink), "_blank");
    }
  };

  const handleSequence = () => {
    openModalWith({
      title: "Sequence",
      property,
      body: (
        <SequenceResultsModal
          sequenceID={parseInt(update.value)}
          property={property}
        />
      ),
      hideButtons: true,
      helpTitle: "Sequences",
      helpBody: <SequencesHelp />,
    });
  };

  const handleOffer = () => {
    openModalWith({
      title: "Offer",
      property,
      body: (
        <OfferResultsModal
          offerID={parseInt(update.value)}
          property={property}
        />
      ),
      hideButtons: true,
      helpTitle: "Offers",
      helpBody: <OffersHelp />,
    });
  };

  const handleCallEmail = () => {
    openModalWith({
      title: "Calls/Emails",
      property,
      body: <PhoneEmailResultsModal callEmail={update} />,
      hideButtons: true,
      helpTitle: "Calls/Emails",
      helpBody: <CallsEmailsHelp />,
    });
  };

  const handleClick = async () => {
    if (disableClick) return;
    switch (update.key) {
      case "Research":
        return !showPropertyDrawer && openPropertyDrawerWith({ property });
      case "Call/Email":
        return handleCallEmail();
      case "Debt Stack":
        return await handleDebtStack();
      case "Sequence":
        return handleSequence();
      case "Offer":
        return handleOffer();
      case "Three Tiered Offer":
        return handleOffer();
      case "Skip Trace":
        return handleSkipTrace();
      default:
        return;
    }
  };

  const renderValue = () => {
    switch (update.key) {
      case "Three Tiered Offer":
        return "Offer";
      case "Debt Stack":
      case "Skip Trace":
        if (update.value === "-") {
          return (
            <div
              className="tooltip"
              data-tip={`Failed to ${capitalize(
                update.key.toLowerCase().replace(/\s/g, "")
              )}`}
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-text-light dark:text-text-dark"
              />{" "}
              {capitalize(update.key)}
            </div>
          );
        }
        return capitalize(update.key);
      case "Drive":
        return "Dash";
      default:
        return capitalize(update.key);
    }
  };

  const assignIcon = () => {
    switch (update.key) {
      case "Research":
        return faMagnifyingGlass;
      case "Call/Email":
        return faPhone;
      case "Debt Stack":
        return faFileInvoiceDollar;
      case "Sequence":
        return faEnvelope;
      case "Offer":
        return faDollarSign;
      case "Three Tiered Offer":
        return faLayerGroup;
      case "Skip Trace":
        return faPhone;
      case "Drive":
        return faCar;
      default:
        return faQuestion;
    }
  };

  return (
    <>
      <div
        className="flex w-fit cursor-pointer select-none items-center justify-center gap-1 rounded-[3px] py-[1px] px-[5px] text-xxxs font-semibold text-text-light dark:text-text-dark sm:text-sm"
        style={{
          backgroundColor: assignColor(update.key),
        }}
        onClick={handleClick}
      >
        {update.value !== "-" && <FontAwesomeIcon icon={assignIcon()} />}
        <div className="">{renderValue()}</div>
      </div>
    </>
  );
};

export default ActivityBadge;
