import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { affiliateApi } from "src/api";
import { VerifyAffiliate } from "src/interfaces/affiliate";

const useAffiliateGuard = () => {
  const { affiliateID } = useParams<{ affiliateID: string }>();

  const [onChecking, setOnChecking] = useState<boolean>(false);

  const [referralID, setReferralID] = useState<number>();

  const [redirection, setRedirectTo] = useState<string>("");

  const navigate = useNavigate();

  const verifyAffiliateID = useCallback(async () => {
    setOnChecking(true);
    try {
      if (affiliateID) {
        const res: VerifyAffiliate = await affiliateApi.verifyReferralID(
          affiliateID
        );
        if (res.isVerified) {
          setReferralID(res.affiliate.id);
          localStorage.setItem("affiliateID", res.affiliate.id.toString());
          setRedirectTo("/register");
        }
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setOnChecking(false);
    }
  }, [affiliateID]);

  useEffect(() => {
    verifyAffiliateID();
  }, [affiliateID, verifyAffiliateID, navigate]);

  return { referralID, onChecking, redirection };
};

export default useAffiliateGuard;
