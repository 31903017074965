import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DebtStackItem,
  OfferItem,
  ProofOfFundsItem,
  removeDebtStack,
  removeOffer,
  removeProofOfFunds,
} from "src/redux/slices/cartSlice";
import {
  faFileInvoiceDollar,
  faHandHoldingCircleDollar,
  faHandshake,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { motion } from "framer-motion";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import addCommas from "src/helpers/addCommas";
import { marketPrices } from "./CartFooter";

interface CartItemProps {
  item: DebtStackItem | OfferItem | ProofOfFundsItem;
}

const CartItem = ({ item }: CartItemProps) => {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleDelete = () => {
    switch (item.type) {
      case "debtStack":
        dispatch(removeDebtStack(item.id));
        break;
      case "offer":
        dispatch(removeOffer(item.id));
        break;
      case "proofOfFunds":
        dispatch(removeProofOfFunds(item.id));
        break;
      default:
        break;
    }
  };

  const handleInfoToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // Define icons for each type
  const icons = {
    debtStack: faFileInvoiceDollar,
    offer: faHandHoldingCircleDollar,
    proofOfFunds: faHandshake,
  };

  // Select the appropriate icon based on the item type
  const icon = icons[item.type];

  // Define labels for each type
  const labels = {
    debtStack: "Debt Stack",
    offer: "Offer",
    proofOfFunds: "Proof of Funds",
  };

  // Select the appropriate label based on the item type
  const label = labels[item.type];

  const [drawerHeight, setDrawerHeight] = useState("0px");
  const drawerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDrawerOpen && drawerRef.current) {
      setDrawerHeight(`${drawerRef.current.scrollHeight}px`);
    } else {
      setDrawerHeight("0px");
    }
  }, [isDrawerOpen]);

  const calculatePrice = () => {
    let price = 0;
    if (item.type === "debtStack") {
      price = marketPrices.debtStacks;
    } else if (item.type === "offer") {
      if ((item as OfferItem).isThreeTiered) {
        price = marketPrices.threeTieredOffers;
      } else {
        price = marketPrices.allCashOffers;
      }
    } else if (item.type === "proofOfFunds") {
      price = marketPrices.proofOfFunds;
    }
    return price / 100;
  };

  return (
    <>
      <motion.div
        className="mb-1.5 flex items-center justify-between rounded-lg bg-card-light p-4 shadow-sm dark:bg-card-dark"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        layout
      >
        <div className="flex items-center">
          <div className="mr-4 flex h-12 w-12 items-center justify-center rounded-full bg-hover-card-light dark:bg-hover-card-dark">
            <FontAwesomeIcon
              icon={icon}
              className="text-lg text-secondary dark:text-theme1"
            />
          </div>
          <div>
            <p className="text-lg font-semibold text-gray-800 dark:text-gray-200">
              {label}
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {item.address.structured_formatting.main_text}
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              {item.address.structured_formatting.secondary_text}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-0.5">
          <div className="flex columns-1 flex-col items-end">
            <div>
              <p className="font-semibold text-gray-800 dark:text-gray-200">
                $ {calculatePrice()}
              </p>
            </div>

            <div className="flex flex-row items-end justify-end gap-0.5">
              {item.type !== "debtStack" && (
                <button
                  onClick={handleInfoToggle}
                  className={`btn btn-ghost text-text-neutral-light dark:text-text-neutral-dark`}
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </button>
              )}
              <button
                onClick={handleDelete}
                className="btn btn-ghost text-text-neutral-light dark:text-text-neutral-dark"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      {isDrawerOpen && (
        <motion.div
          className="-mt-5 mb-1.5 overflow-hidden rounded-lg bg-card-light  dark:bg-card-dark"
          style={{ height: drawerHeight }}
          initial={{ height: 0 }}
          animate={{ height: drawerHeight }}
          transition={{ type: "tween" }}
          ref={drawerRef}
        >
          <div className="p-4 text-sm text-gray-800 dark:text-gray-200">
            {item.type === "offer" && (
              <>
                <p>
                  <span className="font-medium text-text-dark dark:text-text-neutral-dark">
                    Recipient:{" "}
                  </span>
                  <span className="text-text-neutral-light dark:text-text-gray-500">
                    {(item as OfferItem).addressTo}
                  </span>
                </p>
                <p>
                  <span className="font-medium text-text-dark dark:text-text-neutral-dark">
                    All Cash:{" "}
                  </span>
                  <span className="text-text-neutral-light dark:text-text-gray-500">
                    ${addCommas((item as OfferItem).offer1)}
                  </span>
                </p>
                {(item as OfferItem).isThreeTiered && (
                  <>
                    <p>
                      <span className="font-medium text-text-dark dark:text-text-neutral-dark">
                        Seller Carry:{" "}
                      </span>
                      <span className="text-neutral-light dark:text-gray-500">
                        ${addCommas((item as OfferItem).offer2)}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium text-text-dark dark:text-text-neutral-dark">
                        Seller Finance:{" "}
                      </span>
                      <span className="text-neutral-light dark:text-gray-500">
                        ${addCommas((item as OfferItem).offer3)}
                      </span>
                    </p>
                  </>
                )}
              </>
            )}
            {item.type === "proofOfFunds" && (
              <>
                <p>
                  <span className="font-medium text-text-dark dark:text-text-neutral-dark">
                    Recipient:{" "}
                  </span>
                  <span className="text-neutral-light dark:text-gray-500">
                    {(item as ProofOfFundsItem).recipientName}
                  </span>
                </p>
                <p>
                  <span className="font-medium text-text-dark dark:text-text-neutral-dark">
                    Amount:{" "}
                  </span>
                  <span className="text-neutral-light dark:text-gray-500">
                    ${(item as ProofOfFundsItem).amount}
                  </span>
                </p>
              </>
            )}
          </div>
        </motion.div>
      )}
    </>
  );
};

export default CartItem;
