import React from "react";
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faEnvelope,
  faGauge,
  faUsers,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import useNav from "src/hooks/useNav";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

interface MobileFooterNavProps {
  props?: any;
}

const MobileFooterNav = ({ props }: MobileFooterNavProps) => {
  const isKeyboardOpen = useDetectKeyboardOpen();

  const { setOpen, footerOpen } = useNav();

  const navItems = [
    {
      route: "/properties",
      icon: faHouse,
      label: "Properties",
    },
    {
      route: "/team",
      icon: faUsers,
      label: "Team",
    },
    {
      route: "/dashboard",
      icon: faGauge,
      label: "Dashboard",
    },
    {
      route: "/sequences",
      icon: faEnvelope,
      label: "Sequences",
    },
    {
      route: "/watchlist",
      icon: faEye,
      label: "Watchlist",
    },
  ];

  return (
    <>
      {!isKeyboardOpen && (
        <footer
          style={{
            top: footerOpen ? "calc(100vh - 77px)" : "calc(100vh + 77px)",
          }}
          className={
            "fixed inset-x-0 bottom-0 z-50 border-t-2 border-primary  bg-card-light text-center transition-all duration-700 dark:bg-card-dark"
          }
        >
          <nav className="grid grid-cols-5 place-content-evenly px-1.5">
            {navItems.map((navItem, index) => (
              <NavLink
                key={index}
                to={navItem.route}
                className={(navData) =>
                  navData.isActive
                    ? "p-4 text-primary"
                    : "p-4 text-icon-light  dark:text-icon-dark"
                }
                onClick={() => setOpen(false)}
              >
                <div className="flex columns-1 flex-col items-center justify-center">
                  <FontAwesomeIcon size="lg" icon={navItem.icon} />
                  <div className="pt-1 text-[.7rem] font-semibold sm:text-xs">
                    {navItem.label}
                  </div>
                </div>
              </NavLink>
            ))}
          </nav>
        </footer>
      )}
    </>
  );
};

export default MobileFooterNav;
