/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface QueryParamsContextValue {
  queryParams: URLSearchParams;
  setQueryParams: Dispatch<SetStateAction<URLSearchParams>>;
}

const QueryParamsContext = createContext<QueryParamsContextValue>({
  queryParams: new URLSearchParams(),
  setQueryParams: () => {},
});

interface QueryParamsContextProps {}

export const QueryParamsProvider = ({
  children,
}: PropsWithChildren<QueryParamsContextProps>) => {
  const location = useLocation();
  const [queryParams, setQueryParams] = useState<URLSearchParams>(
    new URLSearchParams(location.search)
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQueryParams(searchParams);
  }, []);

  return (
    <QueryParamsContext.Provider value={{ queryParams, setQueryParams }}>
      {children}
    </QueryParamsContext.Provider>
  );
};

export const useQueryParams = () => useContext(QueryParamsContext);
