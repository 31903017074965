/* eslint-disable react-hooks/rules-of-hooks */
import { useFaye } from "src/faye";
import { UserType } from "src/interfaces/user";

export const listenUserPermissionsChanges = (
  currentUser: UserType,
  fetchUser: () => void
) => {
  const { id, teamID } = currentUser;
  useFaye(
    {
      [`/${teamID}/${id}/permissions`]: () => {
        fetchUser();
      },
    },
    [teamID]
  );
};
