import { propertyApi } from "src/api";
import Property, { ModalProperty } from "src/interfaces/property";
import { capitalize } from "src/helpers/parseStrings";
import useViewport from "src/hooks/useViewport";

interface PropertyButtonProps {
  property: Property | ModalProperty;
  showImage?: boolean;
}

const PropertyModalButton = ({
  property,
  showImage = false,
}: PropertyButtonProps) => {
  const { width } = useViewport();

  const { houseNumber, preDirectional, street, suffix, fullAddress } = property;

  const calculateLettersToRemove = () => {
    let num = 20 - Math.floor((430 - width) / 10);
    return num;
  };
  const coerceButtonSize = () => {
    const fullAddress = `${houseNumber || ""} ${preDirectional || ""} ${
      street || ""
    } ${suffix || ""}`;
    if (width < 430) {
      if (fullAddress.length > 20 - calculateLettersToRemove()) {
        return capitalize(fullAddress.slice(0, calculateLettersToRemove()));
      } else {
        return capitalize(fullAddress);
      }
    } else {
      return capitalize(fullAddress);
    }
  };

  const addCityState = () => {
    const fullCityState = `${property.city}, ${property.state}`;
    if (width < 430) {
      if (fullCityState.length > 20 - calculateLettersToRemove()) {
        return capitalize(fullCityState.slice(0, calculateLettersToRemove()));
      } else {
        return capitalize(fullCityState);
      }
    } else {
      return capitalize(fullCityState);
    }
  };

  const streetImg = propertyApi.propertyImg(property.id, "street");

  return (
    <div className="h-12 cursor-pointer flex items-center justify-between rounded-md bg-secondary max-w-fit select-none">
      {/* set property dynamic image & alter text in image tag */}
      <img
        className={"w-12 h-full mr-1 rounded-md z-40"}
        src={streetImg ?? "/assets/no_img.webp"}
        alt={fullAddress ?? "property"}
      />
      <div className="flex flex-col columns-1 text-sm  bg-secondary text-back-light font-bold mr-1">
        <div className="text-center">{coerceButtonSize()}</div>
        <div className="text-center">{addCityState()}</div>
      </div>
    </div>
  );
};

export default PropertyModalButton;
