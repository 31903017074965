import React from "react";
import addCommas from "src/helpers/addCommas";
import { Subscription } from "src/interfaces/subscriptions";

interface UsageModalProps {
  numLeads: number;
  subscription: Subscription;
  teamSize: number;
}

const UsageModal = ({ numLeads, subscription, teamSize }: UsageModalProps) => {
  return (
    <>
      <div className="flex w-full flex-col items-center justify-center gap-1 sm:flex-row">
        <div className="w-full rounded-lg bg-card-light p-2 dark:bg-back-dark sm:w-1/2">
          <div className="font-semibold">Leads</div>
          <div className="flex w-full items-center justify-center font-extralight">
            <p>{`${addCommas(numLeads)} / ${addCommas(
              subscription.maxLeads
            )}`}</p>
          </div>
          <progress
            className="progress progress-primary w-56"
            value={numLeads}
            max={subscription.maxLeads}
          ></progress>
        </div>
        {subscription.maxTeamMembers < 999 && (
          <div className="w-full rounded-lg bg-card-light p-2 dark:bg-back-dark sm:w-1/2">
            <div className="font-semibold">Users</div>
            <div className="flex w-full items-center justify-center font-extralight">
              <p>{`${teamSize} / ${subscription.maxTeamMembers}`}</p>
            </div>
            <progress
              className="progress progress-primary w-56"
              value={teamSize}
              max={subscription.maxTeamMembers}
            ></progress>
          </div>
        )}
      </div>
    </>
  );
};

export default UsageModal;
