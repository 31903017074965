import React from "react";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import MarketDebtStackHelp from "./MarketDebtStackHelp";
import MarketOfferHelp from "./MarketOfferHelp";
import MarketProofOfFundsHelp from "./MarketProofOfFundsHelp";

interface MarketHomeHelpProps {
  startIntro: () => void;
}

const MarketHomeHelp = ({ startIntro }: MarketHomeHelpProps) => {
  const { helpLink } = useHelpDrawer();

  return (
    <div>
      <h1 className="text-lg font-semibold">What is SendFuse Market?</h1>
      <br></br>
      <p>
        SendFuse Market is an innovative platform designed to streamline the
        process of acquiring essential financial documents for real estate
        transactions. Whether you're a buyer, investor, or real estate
        professional, SendFuse Market provides a user-friendly interface to
        effortlessly generate critical documents like{" "}
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() => helpLink("Debt Stacks", <MarketDebtStackHelp />)}
        >
          Debt Stack
        </span>{" "}
        reports,{" "}
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() => helpLink("Offers", <MarketOfferHelp />)}
        >
          Offers
        </span>
        , and{" "}
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() => helpLink("Proof Of Funds Letter", <MarketProofOfFundsHelp />)}
        >
          Proof Of Funds Letters
        </span>{" "}
        . These documents are crucial for validating financial credibility and
        securing real estate deals.
      </p>
      <br></br>
      <p>
        By integrating SendFuse Market into your workflow, you gain access to a
        centralized hub where you can manage addresses, select properties, and
        instantly generate the necessary documentation to move forward with
        confidence in your real estate transactions. With its intuitive design
        and guided tours, even first-time users can quickly navigate and utilize
        the platform's powerful features.
      </p>
      <br></br>
      <h1 className="text-lg font-semibold">Other Resources</h1>
      <br></br>
      <button
        className="btn btn-ghost btn-sm text-primary"
        onClick={startIntro}
      >
        Onboarding Tour
      </button>
    </div>
  );
};

export default MarketHomeHelp;
