import React from "react";

interface CampaignTargetProps {
  target: "absentee" | "occupied";
  onChange: any;
}

const CampaignTarget = ({ target, onChange }: CampaignTargetProps) => {
  return (
    <>
      <div className="relative flex h-full flex-col rounded-md bg-card-light px-4 py-2 shadow-xl dark:bg-card-dark lg:w-1/2">
        <div className="mb-2 flex h-full w-full flex-col items-center justify-between md:justify-around lg:justify-center">
          <div className="mb-2">
            <span className="text-sm font-light text-text-dark dark:text-text-light xs:text-base sm:text-xl">
              Select a owner target audience.
            </span>
          </div>
          <div className="my-4 flex w-full items-center justify-center gap-4 md:justify-around lg:justify-center lg:gap-12">
            <div
              onClick={() => onChange("absentee", "target")}
              className={`${
                target === "absentee"
                  ? "badge badge-secondary text-white"
                  : "badge badge-outline text-secondary"
              } cursor-pointer`}
            >
              Absentee
            </div>
            <div
              onClick={() => onChange("occupied", "target")}
              className={`${
                target === "occupied"
                  ? "badge badge-secondary text-white"
                  : "badge badge-outline text-secondary"
              } cursor-pointer`}
            >
              Occupied
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignTarget;
