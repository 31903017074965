import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface CarouselProps {
  title: string;
  handleCarouselRight: () => void;
  handleCarouselLeft: () => void;
  className?: string;
}

const CarouselHeader = ({
  title,
  handleCarouselLeft,
  handleCarouselRight,
  className,
}: CarouselProps) => {
  return (
    <>
      <div
        className={classNames([
          "flex items-center justify-between bg-card-light dark:bg-card-dark h-[32px] lg:h-[40px] rounded-md z-50 w-full shadow-xl mb-2",
          className,
        ])}
      >
        <FontAwesomeIcon
          icon={faCaretLeft}
          onClick={handleCarouselLeft}
          className="text-xxxl h-[20px] lg:h-[25px] rounded-md w-[40px] md:w-24 bg-secondary dark:bg-secondary text-text-light ml-2"
        />

        <div className="flex flex-grow items-center justify-center">
          <h1 className="btn btn-secondary btn-xs m-1 text-xl text-text-light font-bold text-center">
            {title}
          </h1>
        </div>

        <FontAwesomeIcon
          icon={faCaretRight}
          onClick={handleCarouselRight}
          className="text-xxxl h-[20px] lg:h-[25px] rounded-md w-[40px] md:w-24 bg-secondary dark:bg-secondary text-text-light mr-2"
        />
      </div>
    </>
  );
};

export default CarouselHeader;
