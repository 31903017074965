import React from "react";

interface MarketDebtStackHelpProps {
  props?: any;
}

const MarketDebtStackHelp = ({ props }: MarketDebtStackHelpProps) => {
  return (
    <div>
      <p>
        The Debt Stack is a critical financial document within the SendFuse
        Market platform, designed to provide a comprehensive overview of the
        financial obligations associated with a particular real estate property.
        It details the different layers of debt, including mortgages, liens, and
        other encumbrances, that may affect the property's value and the buyer's
        decision-making process.
      </p>
      <br></br>

      <h2 className="text-lg font-semibold">Key Components of a Debt Stack</h2>
      <br></br>
      <ul>
        <li>
          <strong>Primary Mortgage:</strong> The main loan secured against the
          property with the highest repayment priority.
        </li>
        <li>
          <strong>Secondary Loans:</strong> Includes home equity loans or second
          mortgages that are subordinate to the primary mortgage.
        </li>
        <li>
          <strong>Liens:</strong> Legal claims or holds on the property, often
          due to unpaid taxes or contractor work.
        </li>
        <li>
          <strong>Equity:</strong> The difference between the property's market
          value and the total debt owed.
        </li>
      </ul>
      <br></br>
      <h2 className="text-lg font-semibold">How to Use the Debt Stack</h2>
      <br></br>
      <p>
        - <strong>Review Before Purchase:</strong> Understand all financial
        obligations tied to the property before making a purchase decision.
      </p>
      <p>
        - <strong>Negotiation Tool:</strong> Use the Debt Stack to gain leverage
        in negotiations with the seller.
      </p>
      <p>
        - <strong>Due Diligence:</strong> Ensure all debts and liens are
        accounted for to avoid future complications.
      </p>
    </div>
  );
};

export default MarketDebtStackHelp;
