import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classnames from "classnames";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";

interface DownloadButtonProps {
  onClick: () => void;
  className?: string;
}

const DownloadButton = ({ onClick, className }: DownloadButtonProps) => {
  const classNames = classnames(
    "btn btn-ghost btn-xs sm:btn-sm text-text-dark dark:text-text-light hover:text-secondary dark:hover:text-secondary hover:bg-hover-card-light dark:hover:bg-back-dark text-xxs sm:text-xs",
    !!className
  );
  return (
    <>
      <button  className={classNames} onClick={onClick}>
        <FontAwesomeIcon className="mr-1" icon={faFilePdf} />
        <p>Download</p>
      </button>
    </>
  );
};

export default DownloadButton;
