import AnimatedNumber from "react-awesome-animated-number";
import { Offer } from "src/interfaces/offer";
import { parseMonthDayYear } from "src/helpers/parseEpochs";
import useOfferContext from "src/hooks/private/useOfferContext";
import UserAvatar from "../buttons/UserAvatar";
import Button from "../buttons/Button";
import { offerApi } from "src/api";
import { useState } from "react";
import useAlert from "src/hooks/useAlert";
import {
  faFileCode,
  faFilePdf,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";
import Property from "src/interfaces/property";
import OfferStatus from "../offer/OfferStatus";

interface OfferResultsModalProps {
  offerID: number;
  property: Property;
}

const OfferResultsModal = ({ offerID, property }: OfferResultsModalProps) => {
  const [onSending, setOnSending] = useState(false);
  const { setAlert } = useAlert();
  const { offers, fetchOffers } = useOfferContext();

  const offer = offers.find((offer: Offer) => offer.id === offerID);

  const handlePreviewDownload = async (mode: string) => {
    if (offer) {
      const link = offerApi.preview(offer.id, mode, property);
      window.open(new URL(link), "_blank");
    }
  };

  const handleSendOffer = async () => {
    if (offer) {
      setOnSending(true);
      try {
        await offerApi.sendOffer(offer.id);
        fetchOffers();
        setAlert({
          type: "success",
          message: "Offer sent successfully",
          display: true,
        });
      } catch (err: any) {
        setAlert({
          type: "error",
          message: err?.message ?? "Failed to sent the offer",
          display: true,
        });
      } finally {
        setOnSending(false);
      }
    }
  };

  return (
    <>
      {offer && (
        <div className="w-full">
          <table className={"table-compact table block w-full shadow-xl"}>
            <tbody>
              <tr className="">
                <th className="select-none rounded-tl-lg border-b-[1px] border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                  Created
                </th>
                <td className="select-none rounded-tr-lg border-b-[1px] border-hover-card-dark bg-card-light text-center text-sm font-semibold dark:border-zinc-500 dark:bg-card-dark">
                  {parseMonthDayYear(offer.createdAt)}
                </td>
              </tr>
              <tr>
                <th className="select-none rounded-tl-lg border-b-[1px] border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                  Teammate
                </th>

                <td className="select-none rounded-tr-lg border-b-[1px] border-hover-card-dark bg-card-light py-2 text-center text-xs font-semibold dark:border-zinc-500 dark:bg-card-dark">
                  <div className="flex items-center justify-center">
                    <UserAvatar isSmall={false} userID={offer.userID} />
                  </div>
                </td>
              </tr>
              <tr>
                <th className="select-none rounded-tl-lg border-b-[1px] border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                  Addressed To
                </th>
                <td className="select-none rounded-tr-lg border-b-[1px] border-hover-card-dark bg-card-light text-center text-sm font-semibold dark:border-zinc-500 dark:bg-card-dark">
                  {offer.addressTo || property.owner1}
                </td>
              </tr>
              <tr>
                <th className="select-none rounded-tl-lg border-b-[1px] border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                  All Cash
                </th>
                <td className="select-none rounded-tr-lg border-b-[1px] border-hover-card-dark bg-card-light text-center text-xs font-semibold dark:border-zinc-500 dark:bg-card-dark">
                  $
                  <AnimatedNumber
                    hasComma={true}
                    size={14}
                    value={offer.offer1}
                  />
                </td>
              </tr>
              {offer.isThreeTiered && (
                <>
                  <tr>
                    <th className="select-none rounded-tl-lg border-b-[1px] border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                      Seller Carry
                    </th>
                    <td className="select-none rounded-tr-lg border-b-[1px] border-hover-card-dark bg-card-light text-center text-xs font-semibold dark:border-zinc-500 dark:bg-card-dark">
                      $
                      <AnimatedNumber
                        hasComma={true}
                        size={14}
                        value={offer.offer2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className="select-none rounded-tl-lg border-b-[1px] border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                      Seller Finance
                    </th>
                    <td className="select-none rounded-tr-lg border-b-[1px] border-hover-card-dark bg-card-light text-center text-xs font-semibold dark:border-zinc-500 dark:bg-card-dark">
                      $
                      <AnimatedNumber
                        hasComma={true}
                        size={14}
                        value={offer.offer3}
                      />
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <th className="select-none rounded-bl-lg rounded-tl-lg border-r-[1px] border-hover-card-dark bg-card-light text-center text-xs uppercase text-secondary dark:border-zinc-500 dark:bg-card-dark">
                  Status
                </th>
                <td className="flex items-center justify-center gap-1 rounded-br-lg border-b-secondary bg-card-light text-center text-base font-semibold text-text-dark dark:bg-card-dark dark:text-text-light">
                  <OfferStatus offer={offer} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mt-4 flex items-center justify-center">
            <Button
              className="btn ml-1  w-28 bg-secondary  text-sm font-bold text-text-light hover:bg-primary hover:drop-shadow-lg dark:text-text-light md:w-40 md:text-lg"
              onClick={() => handlePreviewDownload("html")}
              icon={faFileCode}
            >
              Preview
            </Button>
            <Button
              className="btn ml-1  w-28 bg-secondary  text-sm font-bold text-text-light hover:bg-primary hover:drop-shadow-lg dark:text-text-light md:w-40 md:text-lg"
              onClick={() => handlePreviewDownload("pdf")}
              icon={faFilePdf}
            >
              Download
            </Button>
            {!offer.isSent && (
              <Button
                className="btn ml-1  w-28 bg-secondary  text-sm font-bold text-text-light hover:bg-primary hover:drop-shadow-lg dark:text-text-light md:w-40 md:text-lg"
                onClick={handleSendOffer}
                disabled={onSending}
                loading={onSending}
                icon={onSending ? undefined : faPaperPlane}
              >
                Send
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OfferResultsModal;
