import React from "react";

interface DealioLinkHelpProps {
  props?: any;
}

const DealioLinkHelp = ({ props }: DealioLinkHelpProps) => {
  return (
    <>
      <div className="h-full">
        <p className="h-full">
          Import from Dealio is now as easy as pasting in a URL. The Link should very long like this:
          <br></br>
          <br></br>
          <textarea className="text-wrap bg-back-light dark:bg-back-dark w-full h-96">
            https://app.dealio.pro/Marketing/Leads/Index/Property#/
            property/HA7-83539dfa5d85e6dc1a5008ac3436f971574f45d2?
            leadSearch=%7B%22filterProperties%22%3A%7B%22includeAllLeadType
            s%22%3Atrue,%22places%22%3A%5B%7B%22type%22%3A%22address%22,
            %22text%22%3A%22234%20W%2021%20ST,%20CHESTER,%20PA%2019013%22,
            %22propertyId%22%3A%22114370925%22,
            %22address%22%3A%7B%22hash%22%3A%22HA7-83539dfa5d85e6dc1a5008ac
            3436f971574f45d2%22,%22unitGroupHash%22%3Anull,
            %22address%22%3A%22234%20W%2021%20ST,%20CHESTER,
            %20PA%2019013%22,
            %22streetAndNumber%22%3A%22234%20W%2021%20ST%22,
            %22addressWithoutState%22%3A%22234%20W%2021%20ST,
            %20CHESTER%2019013%22,%22street%22%3A%2221%22,
            %22streetDesignator%22%3A%22ST%22,
            %22streetDirection%22%3A%22W%22,
            %22streetDirectionPost%22%3Anull,%22number%22%3A%22234%22,
            %22unitType%22%3Anull,%22unit%22%3Anull,
            %22city%22%3A%22CHESTER%22,%22county%22%3A%22DELAWARE%22,
            %22fips%22%3A%2242045%22,%22state%22%3A%22PA%22,
            %22zip%22%3A%2219013%22,%22country%22%3A%22US%22,
            %22latitude%22%3A39.860925410287436,%22longitude%22%3A-75.
            36941050656682%7D,%22location%22%3A%7B%22type%22%3A%22Point%22,
            %22coordinates%22%3A%5B-75.36941050656682,39.
            860925410287436%5D%7D,
            %22parcel%22%3A%7B%22type%22%3A%22Polygon%22,
            %22coordinates%22%3A%5B%5B%5B-75.36926787615641,39.
            86086249363336%5D,%5B-75.36924562934384,39.860903330949824%5D,
            %5B-75.36962499159183,39.86101923234688%5D,%5B-75.
            36964615958564,39.860979500873775%5D,%5B-75.36926787615641,39.
            86086249363336%5D%5D%5D%7D%7D%5D,%22leadTypes%22%3A%5B%5D,
            %22ownerTypes%22%3A%5B%5D,%22lienTypes%22%3A%5B%5D,
            %22propertyMainCategory%22%3A%22%22,
            %22propertyTypes%22%3A%7B%22Single%20Family%22%3A%5B%5D,
            %22Condo%22%3A%5B%5D,%22Town%20Home%22%3A%5B%5D,
            %22Multi%20Family%22%3A%5B%5D,%225%2B%20Apartment%22%3A%5B%5D,
            %22Other%22%3A%5B%5D%7D,%22clusterPrecision%22%3A5,
            %22clusterSize%22%3A150,%22polygons%22%3A%5B%5D%7D,
            %22selection%22%3A%7B%22includeAll%22%3Afalse,
            %22include%22%3A%5B143343843,143343844,143343845,143343846,
            143343847,143343848,143343842,143343841,143343801,143343840,
            143343838,143332500,143343839,143343800,143332502,143343799,
            143332499,143343798,143343853,143332309,143343797,143343854,
            143332503,143343835,143332498,145892139,145892092%5D,
            %22exclude%22%3A%5B%5D,%22count%22%3A27%7D%7D

          </textarea>
        </p>
        <br></br>
      </div>
    </>
  );
};

export default DealioLinkHelp;
