import React from "react";

interface NewBadgeProps {
  props?: any;
}

const NewBadge = ({ props }: NewBadgeProps) => {
  return (
    <>
      <div>
        <div className="badge badge-primary font-extrabold text-text-light dark:text-text-dark">
          New
        </div>
      </div>
    </>
  );
};

export default NewBadge;
