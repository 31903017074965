import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLoader from "./ButtonLoader";

interface LoadMoreProps {
  onClick: () => void;
  loading?: boolean;
}
const LoadMore = ({ onClick, loading }: LoadMoreProps) => {

  return (
    <div className="flex w-full flex-wrap justify-center py-5">
      <button
        className="dark:bg-card-dar flex select-none items-center justify-center gap-2 rounded-lg bg-card-light px-6 py-2 text-secondary drop-shadow-lg dark:bg-card-dark"
        disabled={loading}
        onClick={onClick}
      >
        {loading ? (
          <ButtonLoader
            className="mr-0 text-secondary h-4 w-4"
            background="card"
          />
        ) : (
          <FontAwesomeIcon
            icon={faArrowRotateRight}
            className="mr-2 h-4 w-4 text-sm"
          />
        )}
        <span className="font-medium">Load More</span>
      </button>
    </div>
  );
};

export default LoadMore;
