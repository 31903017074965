import { HelpDrawerOptionsProps } from "src/contexts/HelpDrawerContext";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useOnclickOutside from "react-cool-onclickoutside";
import BackButton from "../buttons/BackButton";
import useTheme from "src/hooks/useTheme";
import { motion, AnimatePresence } from "framer-motion";
import useApp from "src/hooks/useAppContext";
import getHostName from "src/helpers/getAppFromHost";
import { useSwipe } from "src/hooks/useSwipe";
import HomeHelp from "./HomeHelp";

const HelpDrawer = ({ title, body }: HelpDrawerOptionsProps) => {
  const {
    showHelpDrawer,
    setShowHelpDrawer,
    helpHistory,
    setHelpHistory,
    backLink,
    helpLink,
  } = useHelpDrawer();

  const { mode } = useTheme();

  const { app } = useApp();

  const ref = useOnclickOutside(() => {
    setShowHelpDrawer(false);
    setHelpHistory([]);
  });

  const backGroundColor = "rgba(0,0,0,0.6)";

  useSwipe({
    onSwipeRight: () => {},
    onSwipeLeft: () => setShowHelpDrawer(false),
    swipeThreshold: 90,
  });

  return (
    <>
      <input
        type="checkbox"
        id="help-modal"
        checked={showHelpDrawer}
        onChange={() => {}}
        className="modal-toggle"
      />
      <label
        htmlFor="help-modal"
        className="modal flex justify-start"
        style={{
          backgroundColor: backGroundColor,
        }}
      >
        <div
          ref={ref}
          className="modal-box h-full max-h-screen w-11/12 max-w-none rounded-none bg-back-light dark:bg-back-dark sm:w-9/12 md:w-8/12 lg:w-7/12 xl:w-5/12 2xl:w-4/12"
        >
          <div className="flex items-center justify-between">
            <img
              src={mode === "light" ? app?.logoPathLight : app?.logoPathDark}
              alt={`${app?.name || getHostName()} Logo`}
              className={app?.id === 1 ? "w-24 sm:w-48" : "w-20"}
            />
            <div className="h-8 w-0.5 bg-primary md:h-11"></div>
            <div
              className="mt-2.5 flex h-full cursor-pointer columns-1 flex-col items-end justify-end"
              onClick={() => helpLink(app?.name || "Home™", <HomeHelp />)}
            >
              <span className="text-sm font-medium italic text-text-dark dark:text-text-light sm:text-base md:text-[24px]">
                Resource Center
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-bold capitalize text-primary lg:text-xl">
              {title}
            </h3>
            <div className="invisible">
              <BackButton onClick={() => {}} />
            </div>
            <AnimatePresence>
              {helpHistory.length > 1 && (
                <motion.div
                  initial={{ x: 15 }}
                  animate={{ x: 0 }}
                  exit={{ opacity: 0 }}
                  className={""}
                >
                  <BackButton onClick={backLink} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          {/* <div className="divider my-0.5"></div> */}
          <div className="shadow-m mt-1.5 rounded-md bg-card-light p-4 text-sm font-normal text-text-dark dark:bg-card-dark dark:text-text-light">
            {body}
          </div>
        </div>
      </label>
    </>
  );
};

export default HelpDrawer;
