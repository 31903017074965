import React from "react";
import useHelpDrawer from "src/hooks/private/useHelpDrawer";
import useApp from "src/hooks/useAppContext";
import FuseCoinsHelp from "./FuseCoinsHelp";

interface ProofOfFundsLetterHelpProps {
  props?: any;
}

const ProofOfFundsLetterHelp = ({ props }: ProofOfFundsLetterHelpProps) => {
  const { helpLink } = useHelpDrawer();

  const { app } = useApp();

  return (
    <div className="">
      <p className="mb-2">
        The Proof of Funds (POF) feature is designed for brokers to create a
        proof of funds letter with their own company image and logo. This letter
        serves as a verification of the user's financial capability to make a
        purchase or investment.
      </p>
      <p className="mb-2">
        The cost of generating a proof of funds letter is 20{" "}
        <span
          className="cursor-pointer font-bold text-primary hover:underline"
          onClick={() => helpLink(app?.name || "FuseCoins™", <FuseCoinsHelp />)}
        >
          {app?.coinName || "FuseCoins™"}
        </span>
        . Once a company logo is uploaded, it will be used for all POF letters,
        including those generated in the past. This ensures consistency in
        branding across all documents.
      </p>
      <p className="mb-2">
        Please note that ${app?.name} not send these letters by mail; they are
        available for download only. Addresses in the states of Nevada,
        Minnesota, South Dakota, Utah, and Vermont cannot be used to generate
        POF letters.
      </p>
      <p className="mb-2">
        Each time a user downloads a POF letter, the date on the letterhead is
        set to the current date, and the expiration date is 16 days in the
        future. This ensures that users can always use the most up-to-date
        version of their POF letter.
      </p>
    </div>
  );
};

export default ProofOfFundsLetterHelp;
