/***** Navigation ******/
import SearchBarMobile from "../components/search/SearchBarMobile";
import Alert from "src/components/alert/Alert";
import useAlert from "src/hooks/useAlert";

/***** Hooks *****/
import useViewport from "../hooks/useViewport";
import HeaderNavBirddogMobile from "src/components/birddog/HeaderNavBirddogMobile";
import HeaderNavBirddog from "src/components/birddog/HeaderNavBirddog";
import SideNavBirddog from "src/components/birddog/SideNavBirddog";
import WatchlistBirddog from "src/components/birddog/WatchlistBirddog";


const BirddogLayout = () => {
  let { isLayoutChange } = useViewport();
  const { alert, setAlert } = useAlert();

  return (
    <>
    {isLayoutChange ? (
        <>
          <HeaderNavBirddogMobile />
          <div
            style={{
              zIndex: 0,
            }}
            className="fixed left-0 right-0 h-full overflow-auto overscroll-none bg-back-light dark:bg-back-dark"
          >
            <div className="relative top-4 z-30 mx-3">
              <SearchBarMobile />
            </div>
            <div
              className={
                alert.display && isLayoutChange
                  ? "fixed top-[63px] left-0 right-0 z-50 mx-1.5  transition-all duration-700"
                  : "fixed top-0 left-0 right-0 z-50 mx-1.5 transition-all duration-700"
              }
            >
              <Alert
                alert={alert}
                onClose={() =>
                  setAlert({
                    display: false,
                    type: alert.type,
                    message: alert.message,
                  })
                }
              />
            </div>
            <div className={"relative z-20 mx-1.5 mt-[118px]"}>
              <WatchlistBirddog />
            </div>
          </div>
        </>
      ) : (
        <>
          <SideNavBirddog />
          <HeaderNavBirddog />
          <div
            className={"fixed left-72 right-0 top-16 bottom-0 overflow-auto overflow-x-hidden overscroll-none bg-back-light px-6 dark:bg-back-dark"
            }
          >
            <WatchlistBirddog />
          </div>
        </>
      )}
    </>
  )
};

export default BirddogLayout;