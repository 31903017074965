// src/redux/slices/marketOrderSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MarketOrder, { MarketOrderSettings } from "src/interfaces/marketOrder";

export interface MarketOrderState {
  emails: string[];
  marketSettings: MarketOrderSettings;
  marketOrders: MarketOrder[];
  hasOnboarded: boolean;
}

const initialState: MarketOrderState = {
  emails: [],
  marketOrders: [],
  marketSettings: {
    name: "",
    address: null,
    email: "",
    phone: "",
    company: "",
    website: "",
  },
  hasOnboarded: false,
};

const marketOrderSlice = createSlice({
  name: "marketOrders",
  initialState,
  reducers: {
    addEmail: (state, action: PayloadAction<string>) => {
      if (!Array.isArray(state.emails)) {
        state.emails = [];
      }

      if (state.emails.length > 0 && state.emails.includes(action.payload)) {
        return;
      }

      state.emails.push(action.payload);
    },
    setMarketSettings: (state, action: PayloadAction<MarketOrderSettings>) => {
      state.marketSettings = action.payload;
    },
    setOrders: (state, action: PayloadAction<MarketOrder[]>) => {
      state.marketOrders = action.payload;
    },
    setHasOnboarded: (state, action: PayloadAction<boolean>) => {
      state.hasOnboarded = action.payload;
    },
  },
});

export const { setOrders, setMarketSettings, addEmail, setHasOnboarded } =
  marketOrderSlice.actions;
export default marketOrderSlice.reducer;
