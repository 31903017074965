import React, { useState } from "react";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCurrentLocation, {
  Location,
} from "src/hooks/private/useCurrentLocation";
import useModal from "src/hooks/useModal";
import PropertiesNearMeModal from "../modals/PropertiesNearMeModal";
import LoadingSpinner from "../loading/LoadingSpinner";
import useAlert from "src/hooks/useAlert";
import classNames from "classnames";
import { propertyApi } from "src/api";
import ResearchingHelp from "../help/ResearchingHelp";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { RealeflowRawProperty } from "src/interfaces/property";
import useDrawer from "src/hooks/private/useDrawer";
import useUserContext from "src/hooks/private/useUserContext";

interface NearMeButtonProps {
  className?: string;
  icon?: IconProp;
}

const NearMeButton = ({ className, icon }: NearMeButtonProps) => {
  const { requestLocation, locationError } = useCurrentLocation();

  const { user, isLoadingUser } = useUserContext();

  const { openModalWith } = useModal();

  const { setAlert } = useAlert();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { openPropertyDrawerWith } = useDrawer();

  const onLocationAcquired = async (loc: Location) => {
    try {
      const properties: RealeflowRawProperty[] =
        await propertyApi.getPropertiesNearMe(loc);
      openModalWith({
        title: "Properties Nearby",
        body: (
          <PropertiesNearMeModal
            properties={properties}
            location={loc}
            openPropertyDrawerWith={openPropertyDrawerWith}
          />
        ),
        helpTitle: "Research",
        helpBody: <ResearchingHelp />,
        hideButtons: true,
      });
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = async () => {
    setIsLoading(true);
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    await requestLocation(onLocationAcquired)
      .catch((err) => {
        console.error("Error requesting location:", err);
        setAlert({
          display: true,
          type: "error",
          message: locationError,
        });
      })
      .finally(() => setIsLoading(false));
  };

  if (isLoadingUser || user?.isBirddog) {
    return null;
  }

  return (
    <div className="relative ">
      {isLoading ? (
        <LoadingSpinner
          className={classNames("absolute -left-[9px] -top-[12px] ", className)}
        />
      ) : (
        <div className="cursor-pointer" onClick={handleClick}>
          <FontAwesomeIcon
            icon={icon || faLocationArrow}
            className={classNames("text-xl", className)}
          />
        </div>
      )}
    </div>
  );
};

export default NearMeButton;
