export interface State {
  name: string;
  label: string;
  value: string;
}

const States: State[] = [
  {
    value: "",
    label: "",
    name: "",
  },
  {
    value: "AL",
    label: "AL",
    name: "alabama",
  },
  {
    value: "AK",
    label: "AK",
    name: "alaska",
  },
  {
    value: "AZ",
    label: "AZ",
    name: "arizona",
  },
  {
    value: "AR",
    label: "AR",
    name: "arkansas",
  },
  {
    value: "CA",
    label: "CA",
    name: "california",
  },
  {
    value: "CO",
    label: "CO",
    name: "colorado",
  },
  {
    value: "CT",
    label: "CT",
    name: "connecticut",
  },
  {
    value: "DE",
    label: "DE",
    name: "delaware",
  },
  {
    value: "FL",
    label: "FL",
    name: "florida",
  },
  {
    value: "GA",
    label: "GA",
    name: "georgia",
  },
  {
    value: "HI",
    label: "HI",
    name: "hawaii",
  },
  {
    value: "ID",
    label: "ID",
    name: "idaho",
  },
  {
    value: "IL",
    label: "IL",
    name: "illinois",
  },
  {
    value: "IN",
    label: "IN",
    name: "indiana",
  },
  {
    value: "IA",
    label: "IA",
    name: "iowa",
  },
  {
    value: "KS",
    label: "KS",
    name: "kansas",
  },
  {
    value: "KY",
    label: "KY",
    name: "kentucky",
  },
  {
    value: "LA",
    label: "LA",
    name: "louisiana",
  },
  {
    value: "ME",
    label: "ME",
    name: "maine",
  },
  {
    value: "MD",
    label: "MD",
    name: "maryland",
  },
  {
    value: "MA",
    label: "MA",
    name: "massachusetts",
  },
  {
    value: "MI",
    label: "MI",
    name: "michigan",
  },
  {
    value: "MN",
    label: "MN",
    name: "minnesota",
  },
  {
    value: "MS",
    label: "MS",
    name: "mississippi",
  },
  {
    value: "MO",
    label: "MO",
    name: "missouri",
  },
  {
    value: "MT",
    label: "MT",
    name: "montana",
  },
  {
    value: "NE",
    label: "NE",
    name: "nebraska",
  },
  {
    value: "NV",
    label: "NV",
    name: "nevada",
  },
  {
    value: "NH",
    label: "NH",
    name: "new hampshire",
  },
  {
    value: "NJ",
    label: "NJ",
    name: "new jersey",
  },
  {
    value: "NM",
    label: "NM",
    name: "new mexico",
  },

  {
    value: "NY",
    label: "NY",
    name: "new york",
  },
  {
    value: "NC",
    label: "NC",
    name: "north carolina",
  },
  {
    value: "ND",
    label: "ND",
    name: "north dakota",
  },

  {
    value: "OH",
    label: "OH",
    name: "ohio",
  },
  {
    value: "OK",
    label: "OK",
    name: "oklahoma",
  },
  {
    value: "OR",
    label: "OR",
    name: "oregon",
  },
  {
    value: "PA",
    label: "PA",
    name: "pennsylvania",
  },
  {
    value: "RI",
    label: "RI",
    name: "rhode island",
  },
  {
    value: "SC",
    label: "SC",
    name: "south carolina",
  },
  {
    value: "SD",
    label: "SD",
    name: "south dakota",
  },
  {
    value: "TN",
    label: "TN",
    name: "tennessee",
  },
  {
    value: "TX",
    label: "TX",
    name: "texas",
  },
  {
    value: "UT",
    label: "UT",
    name: "utah",
  },
  {
    value: "VT",
    label: "VT",
    name: "vermont",
  },
  {
    value: "VA",
    label: "VA",
    name: "virginia",
  },
  {
    value: "WA",
    label: "WA",
    name: "washington",
  },
  {
    value: "WV",
    label: "WV",
    name: "west virginia",
  },
  {
    value: "WI",
    label: "WI",
    name: "wisconsin",
  },
  {
    value: "WY",
    label: "WY",
    name: "wyoming",
  },
  {
    value: "DC",
    label: "DC",
    name: "district-of-columbia",
  }
];

const StatesArray = 
  [
    "AK", "AL", "AR", "AZ", "CA", "CO", "CT", "DC", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NC", "ND", "NE", "NH", "NJ", "NM", "NV", "NY", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VA", "VT", "WI", "WA", "WV", "WY"
  ]

export { StatesArray };

export default States;