import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { appApi } from "src/api";
import useErrorHandler from "src/hooks/private/useErrorHandler";
import App from "src/interfaces/app";

interface AppContextInterface {
  app: App | null | undefined;
  setApp: Dispatch<SetStateAction<App | undefined>>;
  isLoadingApp: boolean;
  appLoaded: boolean;
}

const appContextDefaults: AppContextInterface = {
  app: null,
  setApp: () => {},
  isLoadingApp: false,
  appLoaded: false,
};

const AppContext = React.createContext<AppContextInterface>(appContextDefaults);

interface AppProviderProps {}

export const AppProvider = ({
  children,
}: PropsWithChildren<AppProviderProps>) => {

  const [isLoadingApp, setIsLoadingApp] = useState<boolean>(false);

  const [appLoaded, setAppLoaded] = useState<boolean>(false);

  const [app, setApp] = useState<App>();

  const { handleError } = useErrorHandler();

  const fetchApp = useCallback(async () => {
    try {
      setIsLoadingApp(true);
      const app = await appApi.load();
      setApp(app);
    } catch (err: any) {
      handleError(err);
    } finally {
      setIsLoadingApp(false);
      setAppLoaded(true);
    }
  }, [handleError]);

  useEffect(() => {
    fetchApp();
  }, [fetchApp]);

  return (
    <AppContext.Provider
      value={{
        app,
        setApp,
        isLoadingApp,
        appLoaded,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
