import { PropsWithChildren } from "react";

/***** Context Provider *****/
import { UserProvider } from "src/contexts/private/UserContext";
import { TeamProvider } from "src/contexts/private/TeamContext";
import { PropertiesProvider } from "src/contexts/private/PropertiesContext";
import { SequenceLetterProvider } from "src/contexts/private/SequenceMailingContext";
import { TemplatePostcardsProvider } from "src/contexts/private/TemplatePostcardsContext";
import { TemplateLettersProvider } from "src/contexts/private/TemplateLettersContext";
import { DrawerProvider } from "src/contexts/private/DrawerContext";
import { ModalProvider } from "src/contexts/ModalContext";
import { DebtStackProvider } from "src/contexts/private/DebtStackContext";
import { LoanProvider } from "src/contexts/private/LoanContext";
import { OfferProvider } from "src/contexts/private/OfferContext";
import { CallsEmailsProvider } from "src/contexts/private/CallsEmailsContext";
import { PropertyUpdateProvider } from "src/contexts/private/PropertyUpdateContext";
import { SkipTraceProvider } from "src/contexts/private/SkipTraceContext";
import { SupportTicketProvider } from "src/contexts/private/SupportTicketContext";
import { ChartProvider } from "src/contexts/private/ChartsContext";
import { WatchlistProvider } from "src/contexts/private/WatchlistContext";
import { ImportProvider } from "src/contexts/private/ImportContext";
import { CampaignProvider } from "src/contexts/private/CampaignContext";
import { DriveProvider } from "src/contexts/private/DrivesContext";
import { CurrentSubscriptionProvider } from "src/contexts/private/CurrentSubscriptionContext";
import { RealeflowProvider } from "src/contexts/private/RealeflowContext";
import { BrokersProvider } from "src/contexts/private/BrokersContext";

interface PrivateProviderProps {}

const PrivateProvider = ({
  children,
}: PropsWithChildren<PrivateProviderProps>) => (
  <CurrentSubscriptionProvider>
    <UserProvider>
      <TeamProvider>
        <RealeflowProvider>
          <DriveProvider>
            <PropertiesProvider>
              <ChartProvider>
                <TemplatePostcardsProvider>
                  <TemplateLettersProvider>
                    <SequenceLetterProvider>
                      <CampaignProvider>
                        <DebtStackProvider>
                          <LoanProvider>
                            <OfferProvider>
                              <BrokersProvider>
                                <CallsEmailsProvider>
                                  <SkipTraceProvider>
                                    <PropertyUpdateProvider>
                                      <SupportTicketProvider>
                                        <WatchlistProvider>
                                          <ImportProvider>
                                            <ModalProvider>
                                              <DrawerProvider>
                                                {children}
                                              </DrawerProvider>
                                            </ModalProvider>
                                          </ImportProvider>
                                        </WatchlistProvider>
                                      </SupportTicketProvider>
                                    </PropertyUpdateProvider>
                                  </SkipTraceProvider>
                                </CallsEmailsProvider>
                              </BrokersProvider>
                            </OfferProvider>
                          </LoanProvider>
                        </DebtStackProvider>
                      </CampaignProvider>
                    </SequenceLetterProvider>
                  </TemplateLettersProvider>
                </TemplatePostcardsProvider>
              </ChartProvider>
            </PropertiesProvider>
          </DriveProvider>
        </RealeflowProvider>
      </TeamProvider>
    </UserProvider>
  </CurrentSubscriptionProvider>
);

export default PrivateProvider;
