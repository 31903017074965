import React from "react";

const WatchlistHelp = () => {

  return (
    <>
      <div>
        <p>
          The Watchlist feature gives your team the ability to organize
          addresses before Researching and performing other actions on the
          property. This is especially useful for teams of Birddogs who are
          finding addresses with potential in distributed regions.
        </p>
        <br></br>
        <p>
          To add an address to your team's Watchlist, simply search for it in
          the Search Bar. Choose an option from the dropdown menu and click Add
          To Watchlist in the modal that pops up.
        </p>
        <br></br>
      </div>
    </>
  );
};

export default WatchlistHelp;
