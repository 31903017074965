/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { propertyApi } from "src/api";
import { DebtStack } from "src/interfaces/debtStack";
import useErrorHandler from "./useErrorHandler";

const useDebtStacks = (propertyID: number, fetchOnMount?: boolean) => {
  const [debtStacks, setDebtStacks] = useState<DebtStack[] | []>([]);
  const { handleError } = useErrorHandler();

  const getDebtStacks = useCallback(async () => {
    try {
      const res = await propertyApi.getDebtStacksByProperty(propertyID);
      if (res.length) {
        setDebtStacks(res);
        return debtStacks;
      }
    } catch (err) {
      handleError(err);
    }
  }, [handleError, propertyID]);

  useEffect(() => {
    if (fetchOnMount) {
      getDebtStacks();
    }
  }, [getDebtStacks, fetchOnMount]);

  return { debtStacks };
};

export default useDebtStacks;
